
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FgnOverrides163JComponentService {

  url ={
		saveData :'/saveJsonObject?action_code=gjarxx',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	saveData(baseURL : string, jsonData : any, jsonSetting : any, baseGlobalParams: any, checkLockedObj: any, filterParams: any, ssoId :string) {
		const url = `${baseURL}${this.url.saveData}`;
		const options={
		  withCredentials: true
		};
	
		const data:any = {
		"tax_year":filterParams.tax_year,
		"scenario":filterParams.scenario,
		"jcd_key":baseGlobalParams.jcd_key,
		"sso_id":ssoId,
		"filing_key": filterParams.filing_key,
        "process_name" : 'DELETE_163J_OVERRIDES',
		"clob_data": JSON.stringify(jsonData),
        "clob_settings": JSON.stringify(jsonSetting),
		"scenarioCode":baseGlobalParams.scenarioCode,
		"is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		"checkLockedbj": checkLockedObj,
		};
	 console.log("data-----", data);
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
        return this.http.post<any>(url, formData,options);
	  }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }

}
