<button class="fav-btn-dflt" mat-icon-button
        [matTooltip]="screen.IS_FAV == 'Y' ? 'Mark as UnFavorite': 'Mark as Favorite'"
        matTooltipPosition="below"
        [ngClass]="{'fav-btn-bg': screen.IS_FAV == 'Y'}"
        (click)="favBtnCheck();">
  <!-- <mat-icon>star</mat-icon> -->
  <span class="material-icons screen-icon">
    push_pin
  </span>
</button>


