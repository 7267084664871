import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../screen-video-container/user';
import { UserService } from '../screen-video-container/user.service';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';
import { ReleaseNotesData } from './release-notes-data.model';
import { ReleaseNotesService } from './release-notes.service';

@Component({
  selector: 'gtw-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  releaseNotesList : any = [];
  selectedNotes: any = {};
  notesBody: any = '';
  editorEnable: boolean = false;
  userSettings: any = [];
  access: number = 0;
  notesListSelected: boolean = false;
  enableSaveBtn: boolean = false;
  releaseNotesDataBlockEnable: boolean = false;
  reasonEIN: string = '';
  searchReleaseNotes: any;
  saveParams: any = {};
  rnKey: number = 0;
  durationInSeconds = 5;
  editedNotesBody: string = '';
  editedNotesTitle: string = '';
  reasonsEIN: any[] = [
    {
      data: [
        {
          id: 0,
          text: 'APPLD FOR'
        }
      ]
    }
  ];

  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

  @Input('user')
  set user(user:string){
    try{
      let tmp_user = JSON.parse(user) as User;
      this.userService.setUser(tmp_user);
      this.access = parseInt(tmp_user.access?tmp_user.access:'');
    }catch(error){
      console.log("Not an valid object");
    }
    
  }

  constructor(
    private userService:UserService,
    private releaseNotesService:ReleaseNotesService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  init() {
    this.fetchReleaseNotesList();
  }

  fetchReleaseNotesList(){
		console.log("Get Console Data Called.");
		this.releaseNotesService.getReleaseNotesList(this.baseURLs.api).subscribe((data: ReleaseNotesData) => {
			if (data) {
        this.releaseNotesList = data;
        console.log(data, 'dataaaa');
        
            }
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
  }

  saveReleaseNoteData() {

    let relNotesBody = document.querySelector('.ql-editor');
    if(relNotesBody){
      this.saveParams['rn_body'] = relNotesBody.innerHTML;
    }

		this.saveParams.rn_title = this.editedNotesTitle;

    if(this.editorEnable == true) {
      this.saveParams.rn_key = this.selectedNotes.RN_KEY;
    }
		this.releaseNotesService.saveReleaseNotesList(this.baseURLs.api, this.saveParams).subscribe((data) => {
      if (data) {
        this.openSnackBar("Release Notes Data saved successfully.");
        this.releaseNotesDataBlockEnable = false;
        this.fetchReleaseNotesList();
        console.log(data, 'data savedddd');
      }
      }, () => {
        this.openSnackBar("Error Occured while saving.");
        console.log("Error while saving");
      });
  }

  selectNotes(selectedItem: any) {
    this.releaseNotesDataBlockEnable = true;
    this.editorEnable = false;
    this.enableSaveBtn = false;
    this.notesListSelected = true;
    this.selectedNotes = selectedItem;
  }

  addReleaseNotes () {
    this.releaseNotesDataBlockEnable = true;
    this.editorEnable = true;
    this.notesListSelected = false;
    this.enableSaveBtn = true;
    this.editedNotesTitle = '';
    this.notesBody = '';
    this.selectedNotes = {};
  }

  deleteReleaseNotes() {
    this.releaseNotesDataBlockEnable = false;
    this.rnKey = this.selectedNotes.RN_KEY;
    this.releaseNotesService.deleteReleaseNotesList(this.baseURLs.api, this.rnKey).subscribe((data) => {
      if (data) {
        this.openSnackBar("Release Notes Deleted successfully.");
        this.releaseNotesDataBlockEnable = false;
        this.fetchReleaseNotesList();
        console.log(data, 'data deleted');
      }
      }, () => {
        this.openSnackBar("Error Occured while Deleting.");
        console.log("Error while deleting");
      });
  }

  changeNotesBody(event: any){
    this.editedNotesBody = event;
  }

  editNotes() {
    this.editorEnable = true;
    this.enableSaveBtn = true;
    this.editedNotesTitle = this.selectedNotes.RN_TITLE;
    this.notesBody = this.selectedNotes.RN_BODY;
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }

}
