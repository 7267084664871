import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FxRatesSyncService {

  url ={
		syncFxRatesDataUrl : '/saveJsonObject?action_code=9jb7br',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }
  
  syncFxRatesData(baseURL : string, taxYear: number, scenario: number, jcdKey: number, ssoId: string, processName: string, clobSettings: { tax_year: number; scenario: number; jcd_key: number; batch_process: string; }, clobData: any, baseGlobalParams: any, checkLockedObj: any) {
		const url = `${baseURL}${this.url.syncFxRatesDataUrl}`;
		const options={
		  withCredentials: true
		};
	
		const data:{ [name: string]: any } = {
		  "tax_year":taxYear,
		  "scenario":scenario,
		  "jcd_key":jcdKey,
		  "sso_id":ssoId,
		  "process_name" : processName,
		  "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		  "scenarioCode":baseGlobalParams.scenarioCode,
		  "checkLockedbj": checkLockedObj,
		  "clob_settings": JSON.stringify(clobSettings),
      	  "clob_data": JSON.stringify(clobData)
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));

		console.log('tax_year = ', formData.get("tax_year"));
		console.log('scenario = ', formData.get("scenario"));
		console.log('jcd_key = ', formData.get("jcd_key"));
		console.log('sso_id =', formData.get("sso_id"));
		console.log('process_name =', formData.get("process_name"));
		console.log('checkLockedObj = ', formData.get("checkLockedbj"));
		console.log('scenarioCode = ', formData.get("scenarioCode"));
		console.log('is_issue_key_enabled = ', formData.get("is_issue_key_enabled"));
		console.log('jsonSettings =', formData.get("clob_settings"));
		console.log('jsonObj = ', formData.get("clob_data"));
		
		return this.http.post<any>(url, formData,options);
	  }
	
  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }
}
