import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppNgMaterialModule } from '../app-ng-material.module';

import { ScreenVideoContainerComponent } from './screen-video-container.component';
import { ScreenVideoListComponent } from './screen-video-list/screen-video-list.component';
import { ScreenVideoItemComponent } from './screen-video-list/screen-video-item/screen-video-item.component';
import { ScreenVideoUploadComponent } from './screen-video-upload/screen-video-upload.component';
import { ScreenVideoDialogComponent } from './screen-video-list/screen-video-dialog/screen-video-dialog.component';
import { ScreenVideoDeleteComponent } from './screen-video-list/screen-video-delete.component';
import { ScreenDocumentUploadComponent } from './screen-document-upload/screen-document-upload.component';
import { ScreenDocumentListComponent } from './screen-document-list/screen-document-list.component';
import { DocumentViewDialogComponent } from './screen-document-list/document-view-dialog/document-view-dialog.component';
import { EditorModule } from '../shared/components/editor/editor.module';

@NgModule({
  declarations: [
    ScreenVideoContainerComponent, 
    ScreenVideoListComponent, 
    ScreenVideoItemComponent, 
    ScreenVideoUploadComponent, 
    ScreenVideoDialogComponent,
    ScreenVideoDeleteComponent,
    ScreenDocumentUploadComponent,
    ScreenDocumentListComponent,
    DocumentViewDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgMaterialModule, 
    EditorModule,
    FormsModule
    
  ],
  exports:[
    ScreenVideoContainerComponent
  ]
})
export class ScreenVideoModule { }
