import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SourcingDefaultMeService {

  url ={
		getBaksetIdData : '/loadJsonObject?action_code=xm5oxg',
		getBaseIdData : '/loadJsonObject?action_code=oslque',
		saveSourcingData : '/saveJsonObject?action_code=tlvi8g',
		getSourcingModal : '/loadJsonObject?action_code=mfe9ro',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

  getBaksetIdData(baseURL:string, params: any, ssoid:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getBaksetIdData}`;

		let body = new FormData();
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}


	getBaseIdData(baseURL:string, params: any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getBaseIdData}`;

		let body = new FormData();
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getSourcingModalData(baseURL:string, params: any, acctKey:any, parentKey:any, filingKey:any){
				let clientKey = sessionStorage.getItem('client_key') || '';
				const options= {withCredentials:true}
				const url = `${baseURL}${this.url.getSourcingModal}`;

				let body = new FormData();
				body.append('tax_year', params.tax_year.toString());
				body.append('scenario', params.scenario.toString());
				body.append('jcd_key', params.jcd_key.toString());
				body.append('acct_key', acctKey);
				body.append('me_key', parentKey);
				body.append('filing_key', filingKey);

				
				return this.http.post(url, body, options).pipe(
					map((res:any) => { 
						if(res.callSuccess == "1"){
						return res.jsonObject;
						} 
						this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
						throwError(new Error('oops!'));
						return null;
					}
				));
			}
     

	 /* saveSourcingInfoData(baseURL : string, jsonData : any, baseGlobalParams: any) {
		let clientKey = sessionStorage.getItem('client_key') || '';
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('sso_id', jsonData.SSO_ID);
	    body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());
        body.append('clob_data', JSON.stringify([jsonData]));
		body.append('scenarioCode', baseGlobalParams.scenarioCode);
		body.append('is_issue_key_enabled', baseGlobalParams.is_issue_key_enabled);
		body.append('issue_id', baseGlobalParams.issue_id);
		body.append('issue_short_desc', baseGlobalParams.issue_short_desc);

		const url = `${baseURL}${this.url.saveSourcingData}`;

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					// this.openSnackBar('Data saved successfully.','gtw-snackbar--green');
					// if(res.errorMessage != "null"){
					// 	this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					// }
                    return res;
				}else{
					//this.openSnackBar(res.errorMessage, 'gtw-snackbar--green');
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				
				throwError(new Error('oops!'));
				return null;
				}
			}
		));
	}  */


	saveSourcingInfoData(baseURL : string, jsonData : any, baseGlobalParams: any, checkLockedObj: any) {
		const url = `${baseURL}${this.url.saveSourcingData}`;
		const options={
		  withCredentials: true
		};
	
		const data:any = {
		  "tax_year":jsonData.TAX_YEAR.toString(),
		  "scenario":jsonData.SCENARIO.toString(),
		  "jcd_key":jsonData.JCD_KEY.toString(),
		  "sso_id":jsonData.SSO_ID,
		  "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		  "clob_data": JSON.stringify([jsonData]),
		  "scenarioCode":baseGlobalParams.scenarioCode,
		  "checkLockedbj": checkLockedObj,
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
	
		console.log('formData =', formData);
		console.log('data =', data);
	
	
		console.log('tax_year = ', formData.get("tax_year"));
		console.log('scenario = ', formData.get("scenario"));
		console.log('jcd_key = ', formData.get("jcd_key"));
		console.log('screen_key =', formData.get("screen_key"));
		console.log('sso_id =', formData.get("sso_id"));
		console.log('clob_data = ', formData.get("clob_data"));
	
	
	
		return this.http.post<any>(url, formData,options);
	  }
	
  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }
}
