<div class="a-pl-10 a-pr-10 content-box">
  <div class="a-pb-5 a-pt-5 filters">
    <span class="appkiticon icon-refresh-outline"></span>
    <ap-selector
      [items]="taxYears"
      [type]="'Dropdown'"
      [placeholder]="taxYear"
      (onSelectedChange)="taxYearSelected($event)"
      placeholder="Tax Years"
    >
    </ap-selector>

    <ap-button
      [btnText]="'Filter'"
      class="a-mr-10"
      [config]="primaryConfig"
      (onBtnClick)="filterClick()"
      [btnSize]="'small'"
    >
    </ap-button>

    <div [hidden]="!isDataLoaded">
      <!-- <span class="upload-schema">
        <span class="appkiticon icon-upload-light-outline"></span>
      </span> -->
      <ap-button
        class="a-mr-10"
        [btnSize]="'small'"
        [config]="{ isFloating: true }"
        (click)="showModal()"
      >
        <span class="appkiticon a-btn-icon icon-plus-fill"></span>
      </ap-button>
     
      <ap-search
        [isPrimary]="true"
        [isBase]="true"
        (onKeyUp)="tableSearchFun($event)"
        [minLength]="1"
        placeholder="Search"
      >
      </ap-search>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <ap-loading
      *ngIf="startLoader"
      class="loader-class"
      [colorName]="'a-primary'"
    >
    </ap-loading>
  </div>

  <div class="table dark">
    <h3 *ngIf="!isDataLoaded && !startLoader" class="a-h3 center-header">
      Please select the filters to load submissions.
    </h3>
    <div *ngIf="isDataLoaded" class="table-content">
      <ap-table
        #uploadIRSTable
        [data]="irs"
        [showPage]="false"
        [pageSize]="pageSize"
        [striped]="true"
      >
        <thead class="a-thead">
          <tr>
            <th *ngFor="let thItem of tableColumnConfig" [sort]="thItem.sort">
              <span>{{ thItem.colHeader }}</span>
            </th>
          </tr>
        </thead>

        <tbody class="a-tbody tbody-regular tbody-hover">
          <tr
            *ngFor="let item of uploadIRSTable.data"
            (click)="selectedIRS.push(item)"
          >
            <td>{{ item.SCHEMA_SET_ID }}</td>
            <td>{{ item.TAX_YEAR }}</td>
            <td>{{ item.SCHEMA_VERSION }}</td>
            <td>{{ item.RETURN_TYPE }}</td>
            <td>{{ item.SCHEMA_AGENCY }}</td>
            <td>{{ item.SCHEMA_ROOT }}</td>
            <td>{{ item.MAIN_ELEMENT }}</td>
            <td>{{ item.NAMESPACE }}</td>
            <td>{{ item.ELEMENTS }}</td>
          </tr>
        </tbody>
      </ap-table>
    </div>
  </div>
</div>
<ap-modal
#baseModal
(onCheckModalClose)="modalClose()"
(onCheckModalOk)="modalDetermine()"
appModalId="upload-modal"
>
<div slot="content">
  <div class="modal-dialog a-modal-lg gtw-web-components" role="document" tabindex="-1">
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill">
          <span class="a-h4">Upload Schema</span>
        </div>
        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeModal('upload-modal')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body">
        <div class="row">
          <div class="col-4">
            <ap-selector
            [items]="taxYears"
            [type]="'Dropdown'"
            [placeholder]="taxYearOnModal"
            (onSelectedChange)="modalTaxYearSelected($event)"
            placeholder="Tax Years"
          >
          </ap-selector>
          </div>
          <div class="col-4">
              <ap-selector
              [items]="returnTypes"
              [type]="'Dropdown'"
              [placeholder]="returnTypeOnModal"
              (onSelectedChange)="returnTypeSelected($event)"
              placeholder="Return Type"
            >
          </ap-selector>
          </div>
          <div class="col-4">
            <input type="number" step="0.1" class="a-text-input" [(ngModel)]="uploadParams.schemaVersion" placeholder="Schema Version"> 
          </div>
       </div>
       <div class="a-pb-50 a-pt-50 justify-content-center text-center" *ngIf="!uploadParams.schemaVersion">
        <span class="a-h4">Enter Schema Version</span>
      </div>
       <div class="row a-mt-10" *ngIf="uploadParams.schemaVersion">
         <div class="col-12">
          <ap-fileupload [uploadUrl]="uploadUrl"
              [additionalParameter]="uploadParams"
              [headers]="headers"
              title=""
              uploadDescription=""
              uploadText="" [config]="fileUploadConfig"
              (onNavigateChange)="fileUpload($event)">
          </ap-fileupload>
         </div>
       </div>
      </div>
     
    </div>
  </div>
</div>
</ap-modal>