import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { SpotRatesAllCurrSyncService } from './spotrates-all-curr-sync.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";

@Component({
  selector: 'gtw-spotrates-all-curr-sync',
  templateUrl: './spotrates-all-curr-sync.component.html',
  styleUrls: ['./spotrates-all-curr-sync.component.scss']
})
export class SpotratesAllCurrSyncComponent implements OnInit {
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private checkLockedObj: any = [];
  private scenarioCode: string = '';
  enableButton: boolean = true;

  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }


  @Input('tax-year')
  set _taxYear(taxYear:number){
  try{
		this.taxYear = taxYear;
		}catch(error){
		console.log("Not an valid object");
		}
  }

  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input('screen-key')
  set _screenKey(screenKey:number) {
    this.screenKey = screenKey;
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    this.init();
    }catch(error){
		console.log("Not an valid object");
		}
  }

  @Output('close-currconfirm-info')
  closeCurrconfirmInfo = new EventEmitter<string>();

  @Output('save-currsync-info')
  saveCurrsyncInfo = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;
  constructor(private SpotRatesAllCurrSyncService:SpotRatesAllCurrSyncService ,private _snackBar: MatSnackBar) {
  }
    checkInputType():boolean{
      if(Number.isInteger(parseInt(this.taxYear+"")) &&
        Number.isInteger(parseInt(this.screenKey+'')) &&
        Number.isInteger(parseInt(this.scenario+'')) &&
        Number.isInteger(parseInt(this.jcdKey +'')) 
        )
      {
        return true;
      }
    
      return false;
    }
   

   ngOnInit(): void {
  }

  init() {   
    if(this.checkInputType()){
      }
    } 

    cancel() {
      console.log("click close");
      this.closeCurrconfirmInfo.emit('close-currconfirm-info');
      }

    syncData(){
      this.enableButton = false;
      let tempData = {
        TAX_YEAR : this.baseGlobalParams?.tax_year,
        SCENARIO : this.baseGlobalParams?.scenario,
        JCD_KEY : this.baseGlobalParams?.jcd_key,
        SSO_ID: this.ssoId
      }

     let updateSubscription = this.SpotRatesAllCurrSyncService.syncCurrData(this.baseURLs.api, tempData, this.baseGlobalParams, this.checkLockedObj).subscribe(
           (data) => {
             if (data.callSuccess == "1") {
               this.SpotRatesAllCurrSyncService.openSnackBar('Data Synced Successfully','gtw-snackbar--green');
               this.saveCurrsyncInfo.emit('save-currsync-info');
             } else {
               this.SpotRatesAllCurrSyncService.openSnackBar('Error syncing Data. '+data.errorMessage, 'gtw-snackbar--red');
               this.enableButton = true;
             }
           },  error => { this.SpotRatesAllCurrSyncService.openSnackBar('Error syncing Data. '+error.error.errorMessage, 'gtw-snackbar--red'); },
           () => { console.log("Update Completed");}
         );
           this.subscriptions.add(updateSubscription);
   };

}
