import { HttpClient } from '@angular/common/http';
import _, { filter, map } from 'lodash';

export interface DashbaordInterface{
    loadSheet(): this;
}
class DashboardProtoType implements DashbaordInterface{
    name: string;
    id : number;
    dataSheets:any [];
    safeCopyArr :any [];
    params :any [];
    isLoading : boolean =false;
    isLoaded :boolean = false;
    dataLoading : boolean = false;
    filters : any[];
    footerFilters : any [];
    components : any [];
    dataLoaded: boolean = false;
    kpiData:any[];

    constructor(name: string, id:number,dataSheets:any[],safeCopyArr:any[],params:any[],
        isLoading:boolean,isLoaded:boolean,dataLoading:boolean,filters:any[],footerFilter:any[],
        components:any[],dataLoaded:boolean,kpiData:any[]){
            this.name = name;
            this.id = id;
            this.dataSheets = dataSheets;
            this.safeCopyArr = safeCopyArr;
            this.params = params,
            this.isLoading = isLoading;
            this.isLoaded = isLoaded;
            this.dataLoading = dataLoading;
            this.filters = filters;
            this.footerFilters = footerFilter;
            this.components = components;
            this.dataLoaded = dataLoaded;
            this.kpiData = kpiData;
    }   

 
    loadSheet() :this{
        let promises: any[] = [];
        let filterParams = {
            tax_year: 2020,
            scenario: 40,
            filing_key: 1,
            parent_me_string: '99999999999999999999,6196',
            system: 'A'
          }
       _.forEach(this.dataSheets,(sheet,key) =>{
        if (this.dataSheets[key].data && this.dataSheets[key].data.length > 0) {

        } else {
            this.dataSheets[key].isLoading = true;
            let promise = HttpClient.prototype.post('/gtw/'+ sheet.action_id,filterParams).toPromise()
                .then((res:any)=>{
                    this.dataSheets[key].safeCopyArr = res.jsonObject;
                    this.dataSheets[key].data = res.jsonObject;
                    this.dataSheets[key].isLoaded = true;
                    this.dataSheets[key].isLoading = false;

                });
                promises.push(promise);
            }
            Promise.all(promises).then(function(data){
                    console.log('resolved',data);
            },function(err){
                console.log('error');
            })
        })
        return this;
    }   
    
}
export {DashboardProtoType}