<div class="cd-container">
  <div class="left-panel" [ngClass]="{'hidden-pane': hideLeftPane}">
    <div class="top-control" [style.box-shadow]="hideLeftPane ? 'none' : '0 1px 1px 0 grey'"
         [style.height]="selectedIndex > 0 ? '120px' : '220px'">
      <div class="top-filters">
        <div class="button-group-container">
          <ap-button class="a-mr-10" [btnSize]="'small'" [btnType]="'a-btn-white'" [config]="{isFloating: true}"
                     (onBtnClick)="hideLeftPane = !hideLeftPane">
            <span class="appkiticon icon-menu-outline"></span>
          </ap-button>
          <ap-button-group [groupConfig]="btnGroupConfig" [groupSize]="'middle'" [ngClass]="{'hidden': hideLeftPane}"
                           (onBtnClick)="btnChange($event)">
          </ap-button-group>
        </div>
        <div class="dropdown-row row" [ngClass]="{'hidden': hideLeftPane}">
          <span class="col-md-2" style="line-height: 34px;">Tax Year</span>
          <div class="col-md-4">
            <ap-selector [items]="taxYears" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                         (onSelectedChange)="onTaxYearChanged($event)"></ap-selector>
          </div>
          <span class="col-md-2" style="line-height: 34px;">Tax Period</span>
          <div class="col-md-4">
            <ap-selector [items]="taxYearPeriods" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                         (onSelectedChange)="onPeriodChanged($event)"></ap-selector>
          </div>
        </div>
        <div class="dropdown-row row" *ngIf="selectedIndex === 0" [ngClass]="{'hidden': hideLeftPane}">
          <span class="col-md-2" style="line-height: 34px;">Disclosure</span>
          <div class="col-md-10">
            <ap-selector [items]="disclosureTypes" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                         (onSelectedChange)="onDisclosureTypeChanged($event)"></ap-selector>
          </div>
        </div>
        <div class="dropdown-row row" *ngIf="selectedIndex === 0" [ngClass]="{'hidden': hideLeftPane}">
          <span class="col-md-2" style="line-height: 34px;">Filter By</span>
          <div class="col-md-10">
            <ap-selector [items]="filterByTypes" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                         (onSelectedChange)="onFilterByTypeChanged($event)"></ap-selector>
          </div>
        </div>
      </div>
    </div>

    <div class="list-container pr-0" [ngClass]="{'hidden': hideLeftPane}"
         [style.height]="selectedIndex > 0 ? 'calc(100% - 120px)' : 'calc(100% - 220px)'">
      <ap-search class="list-search" [isPrimary]="false" [isBase]="true" [(ngModel)]="searchTerm" placeholder=" ">
      </ap-search>
      <span *ngIf="loadingData">
        <ap-loading [colorName]="'a-primary'" [size]="'24px'"></ap-loading>
      </span>

      <div *ngIf="!loadingData && selectedFilterId !== 5" class="crt-disclosure-table">
        <h5 *ngIf="formsFiltered.length == 0">No Data Found</h5>
        <table class="ml-0" *ngIf="formsFiltered.length > 0">
          <thead>
          <tr>
            <th class="th-row1"><span>Disclosure</span></th>
            <th class="th-rown"><span>Primary</span></th>
            <th class="th-rown"><span>Secondary</span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let form of formsFiltered | search : searchTerm">
            <td class="th-row1 pt-2 pr-2"><a href="javascript:void(0);" (click)="loadNewTab(form, true)"> {{ form.FORMNAME }} </a></td>
            <td class="th-rown pt-2 pr-2"><a href="javascript:void(0);" (click)="loadNewTab(form, true)"> {{ form.PRIMARYFORMTYPEDESC }} </a></td>
            <td class="th-rown pt-2"><a href="javascript:void(0);" (click)="loadNewTab(form, false)"> {{ form.SECONDARYFORMTYPEDESC }} </a></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!loadingData && selectedFilterId === 5" class="crt-disclosure-table">
        <h5 *ngIf="formsFiltered.length == 0" >No Data Found</h5>
        <table class="ml-0" *ngIf="formsFiltered.length > 0">
          <thead>
            <tr>
              <th class="th-rowa"><span>Disclosure</span></th>
            </tr>
          </thead>  
          <tbody>
            <tr *ngFor="let form of formsFiltered | search : searchTerm">
              <td class="th-rowa pt-2 pr-2"><a href="javascript:void(0);" (click)="downloadFile(form)"> {{ form.FORMNAME }} </a></td>
            </tr>
            </tbody>
        </table>
    </div>
    </div>

  </div>
  <div class="create-template-background" [ngClass]="{'hidden-pane': hideLeftPane}" *ngIf="!showCreateContainer"></div>
  <div class="create-template-container" [ngClass]="{'hidden-pane': hideLeftPane}" *ngIf="showCreateContainer">
    <dst-create-disclosure-shared></dst-create-disclosure-shared>
  </div>
</div>
