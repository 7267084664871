import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form8082Service } from './form8082.service';
import _ from 'lodash';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'gtw-form8082',
  templateUrl: './form8082.component.html',
  styleUrls: ['./form8082.component.scss']
})
export class Form8082Component implements OnInit {
  baseURL!: string;
  checkLockedObj!: string[];
  isLocked!: string;
  isSaving!: boolean;
  taxYear!: number;

  scenario!: number;
  jcdKey!: number;
  combinationKey!: number;
  ssoId!: string;
  scenarioCode!: string;
  isIssueKeyEnabled!: string;
  issueKey!: number;
  isSaveClicked = false;
  public secondaryConfig = {
    isSecondary: true
  }

  public primaryConfig = {
    isPrimary: true
  }
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
  baseURLs: any;
  rowData: any;
  isParent!: boolean;
  formDataRaw: any;
  f8082Header: any;
  f8082List: any;
  f8082OriginalData: any;
  f8082FormData: any;
  f8082OtherData: any = [];
  f8082Part1Data: any = [];
  f8082Part2Data: any = [];
  f8082Part3Data: any = [];
  f8082ColumnHeader: any;
  f8082FinalData: any;
  unAllocatedAmt!: number;
  crudLoading: boolean = true;
  alreadyParsed = false;
  finalData: any[] = [];
  clob_settings: any;
  sso_id: any;
  trans_type_key!: string;
  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();
  dropdownOptions!: { data: any[]; }[];
  dropdownData: any;
  maxLengthMap: any = {
    "Business Name Line 1": 75,
    "Business Name Line 2": 75,
    "Foreigner Address Address Line 1": 35,
    "Foreigner Address Address Line 2": 35,
    "Foreigner Address City Name": 50,
    "Foreigner Address Country Code": 2,
    "Foreigner Address Foreign Postal Code": 16,
    "Foreigner Address Province Or State": 17,
    "US Address Address Line 1": 35,
    "US Address Address Line 2": 35,
    "US Address City Name": 22,
    "US Address State": 2,
    "US Address ZIP Code": 9
  }
  addressMap: any = {
    "Name, address, and ZIP code of pass-through entity": "Name, address, and ZIP code of pass-through entity",
    "Business Name Line 1": "Business Name Line 1",
    "Business Name Line 2": "Business Name Line 2",
    "Foreigner Address Address Line 1": "Address Line 1",
    "Foreigner Address Address Line 2": "Address Line 2",
    "Foreigner Address City Name": "City",
    "Foreigner Address Country Code": "Country Code",
    "Foreigner Address Foreign Postal Code": "Postal/ZIP Code",
    "Foreigner Address Province Or State": "Province or State",
    "US Address Address Line 1": "Address Line 1",
    "US Address Address Line 2": "Address Line 2",
    "US Address City Name": "City",
    "US Address State": "State",
    "US Address ZIP Code": "Postal/ZIP Code"
  }
  isUS = true;

  usIndex = [28, 29, 30, 31, 32, 33];
  foreignIndex = [34, 35, 36, 37, 38];
  currP2Data: any;
  currLen: any;
  showP2 = true;

  @Input('row-data')
  set _row_data(rowData: string) {
    if (!this.alreadyParsed) {
      try {

        let params = JSON.parse(rowData);

        if (params != undefined) {
          this.rowData = JSON.parse(params.rowData);
          this.baseURLs = JSON.parse(params.baseURLs);
          this.sso_id = params.sso_id;
          if (this.rowData.IS_PARENT === 'Y') {
            this.isParent = true;
          }
          this.init();
          this.alreadyParsed = true;

        }
      } catch (e) {
        console.log("rowData Params not stable. Still waiting to get resolved.");
      }
    }

  }

  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
  }
  constructor(private Form8082Service: Form8082Service, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  init() {

    this.rowData = this.rowData;
    console.log(this.rowData);
    this.f8082Header = [];
    this.f8082List = [];
    this.f8082OriginalData = [];
    this.f8082FormData = [];
    this.f8082ColumnHeader = [];
    this.f8082FinalData = [];
    this.unAllocatedAmt = 0;
    this.crudLoading = true;
    this.getF8082Data();
  }

  getF8082Data() {
    this.Form8082Service.fetchF8082Details(this.baseURLs.api, this.rowData).subscribe((res: any) => {
      let multiRowParams = {
        "tax_year": this.rowData.TAX_YEAR,
        "scenario": this.rowData.SCENARIO,
        "jcd_key": this.rowData.JCD_KEY,
        "sso_id": this.sso_id,
        "irs_form_no": 'F8082',
        "part_no": null,
        "combination_key": this.rowData.COMBINATION_KEY
      };

      let params = {
        "tax_year": this.rowData.TAX_YEAR,
        "scenario": this.rowData.SCENARIO,
        "jcd_key": this.rowData.JCD_KEY,
        "sso_id": this.sso_id,
      };
      this.Form8082Service.fetchF8082Dropdown(this.baseURLs.api, params).subscribe((dropdown: any) => {
        console.log("Dropdown data was ::::::::::::::::::::", dropdown);
        this.dropdownData = dropdown.jsonObject;
        this.dropdownOptions = [{ data: [] }];
        this.dropdownData.forEach((element: any) => {
          this.dropdownOptions[0].data.push({
            value: element.EIN ? element.EIN : "",
            text: element.PSHIP_DTLS,
            ...element
          })

        });
      });
      this.f8082Header = res.jsonObject[0];
      this.f8082List = res.jsonObject.slice(1);
      this.trans_type_key = this.f8082List[0]["TRANS_TYPE_KEY"];
      for (let index = 0; index < this.f8082List.length; index++) {
        const element = this.f8082List[index];
        if (element["PART_NO"] == "I") {
          this.f8082Part1Data.push(element);
        } else if (element["PART_NO"] == "II") {
          this.f8082Part2Data.push(element);
        } else if (element["PART_NO"] == "III") {
          this.f8082Part3Data.push(element);
        } else {
          this.f8082OtherData.push(element);
        }

      }
      this.f8082FormData = {
        "P1": this.transformData(this.f8082Part1Data),
        "P2": this.transformData(this.f8082Part2Data),
        "P3": this.transformData(this.f8082Part3Data),
        "O": this.transformData(this.f8082OtherData)
      }
      this.isUS = this.f8082FormData['P1'][31].Line_data[2].ATTRIB_VALUE ? false : true || true;

      this.Form8082Service.fetchF8082MultiRowDataDetails(this.baseURLs.api, multiRowParams).subscribe((res: any) => {
        this.currP2Data = this.f8082FormData["P2"][1].Line_data[0];
        this.f8082FormData["P2"] = [{ ...this.currP2Data }]
        this.f8082FormData["P2"][0].JSON_DATA = [];
        if(res.jsonObject.length) {
          res.jsonObject.forEach((curr: any) => {
            this.f8082FormData['P2'][0].JSON_DATA.push({
              A: curr.F8082_Line_8A,
              B: curr.F8082_Line_8B,
              C: curr.F8082_Line_8C,
              D: curr.F8082_Line_8D,
              E: curr.F8082_Line_8E,
              F: curr.F8082_Line_8F,
              G: curr.F8082_Line_8G
            })
          })
        } else {
          this.addP2Row();
        }
        
        this.f8082OriginalData = _.cloneDeep(this.f8082FormData);
        this.crudLoading = false;
      })


    })

  }
  dropdownSelect(event: any, parent: any, index: any) {
    console.log("The selected value was ::::::::::::", event);
    this.f8082FormData['P1'][parent].Line_data[index].ATTRIB_VALUE = event.selected[0].value;
    this.f8082FormData['P1'][parent].Line_data[index].IS_CHANGED_FLAG = "Y";
    this.f8082FormData['P1'][26].Line_data[index].ATTRIB_VALUE = event.selected[0].BUSINESS_NAME
    this.f8082FormData['P1'][26].Line_data[index].IS_CHANGED_FLAG = "Y";
    this.f8082FormData['P1'][27].Line_data[index].ATTRIB_VALUE = "";
    this.f8082FormData['P1'][27].Line_data[index].IS_CHANGED_FLAG = "Y";
    if (event.selected[0].REG_ADDRESS_COUNTRY == "US") {
      this.isUS = true;
      this.f8082FormData['P1'][34].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_LINE1 || '';
      this.f8082FormData['P1'][34].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][35].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_LINE2 || '';
      this.f8082FormData['P1'][35].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][36].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_CITY || '';
      this.f8082FormData['P1'][36].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][37].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_STATE || '';
      this.f8082FormData['P1'][37].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][38].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_ZIP || '';
      this.f8082FormData['P1'][38].Line_data[index].IS_CHANGED_FLAG = "Y";

      //cleanup 
      this.f8082FormData['P1'][28].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][28].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][29].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][29].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][30].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][30].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][31].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][31].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][32].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][32].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][33].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][33].Line_data[index].IS_CHANGED_FLAG = "N";
    } else {
      this.isUS = false;
      this.f8082FormData['P1'][28].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_LINE1 || '';
      this.f8082FormData['P1'][28].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][29].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_LINE2 || '';
      this.f8082FormData['P1'][29].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][30].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_CITY || '';
      this.f8082FormData['P1'][30].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][31].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_COUNTRY || '';
      this.f8082FormData['P1'][31].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][32].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_ZIP || '';
      this.f8082FormData['P1'][32].Line_data[index].IS_CHANGED_FLAG = "Y";
      this.f8082FormData['P1'][33].Line_data[index].ATTRIB_VALUE = event.selected[0]?.REG_ADDRESS_STATE || '';
      this.f8082FormData['P1'][33].Line_data[index].IS_CHANGED_FLAG = "Y";

      //cleanup
      this.f8082FormData['P1'][34].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][34].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][35].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][35].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][36].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][36].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][37].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][37].Line_data[index].IS_CHANGED_FLAG = "N";
      this.f8082FormData['P1'][38].Line_data[index].ATTRIB_VALUE = '';
      this.f8082FormData['P1'][38].Line_data[index].IS_CHANGED_FLAG = "N";
    }
    this.f8082FormData['P1'][40].Line_data[2].ATTRIB_VALUE = event.selected[0].TAX_YEAR_BEGIN ? new Date(event.selected[0].TAX_YEAR_BEGIN) : '';
    this.f8082FormData['P1'][40].Line_data[2].IS_CHANGED_FLAG = "Y";
    this.f8082FormData['P1'][40].Line_data[3].ATTRIB_VALUE = event.selected[0].TAX_YEAR_END ? new Date(event.selected[0].TAX_YEAR_END) : '';
    this.f8082FormData['P1'][40].Line_data[3].IS_CHANGED_FLAG = "Y";

  }

  addP2Row() {
    this.showP2 = false;
    this.currLen = this.f8082FormData['P2'][0].JSON_DATA.length;
    this.f8082FormData['P2'][0].JSON_DATA.push({
      A: '',
      B: '',
      C: '',
      D: '',
      E: '',
      F: '',
      G: ''
    })
    this.showP2 = true;
  }
  deleteP2Row(index: any) {
    this.showP2 = false;
    this.f8082FormData['P2'][0].JSON_DATA.splice(index, 1);
    this.showP2 = true;
  }

  transformData(data: any) {
    let o = data.reduce((a: { [x: string]: any[]; }, b: { LINE_ORDER: string | number; }) => {
      a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
      a[b.LINE_ORDER].push(b);
      return a;
    }, {});

    let a = Object.keys(o).map(function (k) {
      return { ROW_TYPE: o[k][0].ROW_TYPE, LINE_NO: o[k][0].LINE_NO, DELETE_ROWS: [], Line_data: o[k] };
    });

    let cur = a;
    console.log(cur);

    let c = [];

    for (let i = 0; i < cur.length; i++) {
      if (cur[i].ROW_TYPE == 'DT') {
        for (let j = 0; j < cur[i].Line_data.length; j++) {

          if (cur[i].Line_data[j].IS_EDITABLE_CELL == 'Y' && cur[i].Line_data[j].EDIT_TYPE == 'checkbox' && cur[i].Line_data[j].DATA_TYPE == 'string') {
            cur[i].Line_data[j].ATTRIB_VALUE = parseInt(cur[i].Line_data[j].ATTRIB_VALUE);
          }
          if (cur[i].Line_data[j].ATTRIB_VALUE && cur[i].Line_data[j].EDIT_TYPE === 'custom_date_various' && cur[i].Line_data[j].DATA_TYPE === 'dateVarious') {

            cur[i].Line_data[j].ATTRIB_VALUE = cur[i].Line_data[j].ATTRIB_VALUE ? new Date(cur[i].Line_data[j].ATTRIB_VALUE) : '';
          }
        }
      }
    }

    return cur;

  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  reset() {
    this.getF8082Data();
  }

  valuechanged(parent_index: number, occurrence: any, line_no: any, index: string | number, part: any) {
    console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index);
    for (let i = 0; i < this.f8082FormData[part].length; i++) {
      if (i == parent_index && this.f8082FormData[part][i].Line_data[index] != null && this.f8082FormData[part][i].Line_data[index] != undefined) {
        this.f8082FormData[part][i].Line_data[index].IS_CHANGED_FLAG = "Y";
      }
    }
    console.log(this.f8082FormData[part]);

  }
  updateAmount(parent: any) {
    let val1 = this.f8082FormData['P2'][0].JSON_DATA[parent].D ? this.f8082FormData['P2'][0].JSON_DATA[parent].D : 0;
    let val2 = this.f8082FormData['P2'][0].JSON_DATA[parent].E ? this.f8082FormData['P2'][0].JSON_DATA[parent].E : 0;
    this.f8082FormData['P2'][0].JSON_DATA[parent].F = val1 - val2;

  }

  checkboxSwitch(parent: number, index: number, val: number) {
    if (index == 2) {
      this.f8082FormData['P1'][parent].Line_data[index + 1].ATTRIB_VALUE = val ? 0 : 1;
      this.f8082FormData['P1'][parent].Line_data[index + 1].IS_CHANGED_FLAG = "Y";
    } else {
      this.f8082FormData['P1'][parent].Line_data[index - 1].ATTRIB_VALUE = val ? 0 : 1;
      this.f8082FormData['P1'][parent].Line_data[index - 1].IS_CHANGED_FLAG = "Y";
    }
  }


  preventDotKey(event: any) {
    if (event.key === ".") {
      event.preventDefault();
    }
    else {
      event.target.value = event.target.value.replace(/[^-0-9,]*/g, '');
    }
  }
  save() {
    this.finalData = [];
    this.clob_settings = [];
    console.log(this.f8082FormData);
    console.log("Original Data was ::::::::==========", this.f8082OriginalData);
    if (this.crudLoading) {
      alert("Please wait while we save this request");
      return;
    }
    let transformedData: any = [];
    for (let i = 0; i < this.f8082FormData['P1'].length; i++) {
      transformedData.push(this.f8082FormData['P1'][i]);
    }

    for (let i = 0; i < this.f8082FormData['O'].length; i++) {
      transformedData.push(this.f8082FormData['O'][i]);
    }

    this.f8082FinalData = _.cloneDeep(transformedData);
    if (_.isEqual(transformedData, this.f8082OriginalData)) {
      this.cancel();
    } else {
      if (this.unAllocatedAmt != 0) {
        if (!confirm("Total Depreciation Expense Per Tax Return is not fully adjusted. Confirm to save the changes.")) {
          return false;
        }
      }
      for (let x = 0; x < this.f8082FinalData.length; x++) {
        if (this.f8082FinalData[x].ROW_TYPE == 'TH' || this.f8082FinalData[x].ROW_TYPE == 'DT' || this.f8082FinalData[x].ROW_TYPE == 'RT' || this.f8082FinalData[x].ROW_TYPE == 'MD') {
          for (let j = 0; j < this.f8082FinalData[x].Line_data.length; j++) {
            if (this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE && this.f8082FinalData[x].Line_data[j].EDIT_TYPE === 'custom_date_various' && this.f8082FinalData[x].Line_data[j].DATA_TYPE === 'dateVarious') {
              let val: any;
              if (this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE.startDate) {
                val = this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE.startDate ? this.datePipe.transform(this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE.startDate, 'MM/dd/yyyy') : '';
              } else {
                val = this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE ? this.datePipe.transform(this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE, 'MM/dd/yyyy') : '';
              }
              this.f8082FinalData[x].Line_data[j].ATTRIB_VALUE = this.getDateFormat(val);
            }
            this.finalData.push(this.f8082FinalData[x].Line_data[j]);
          }
        }
      }
      let json_data = [];
      for (let i = 0; i < this.f8082FormData['P2'][0].JSON_DATA.length; i++) {
        let curr = this.f8082FormData['P2'][0].JSON_DATA[i];
        json_data.push(
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8A`,
            ATTRIB_VALUE: curr.A
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8B`,
            ATTRIB_VALUE: curr.B
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8C`,
            ATTRIB_VALUE: curr.C
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8D`,
            ATTRIB_VALUE: curr.D
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8E`,
            ATTRIB_VALUE: curr.E
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8F`,
            ATTRIB_VALUE: curr.F
          },
          {
            OCCURRENCE: i + 1,
            ATTRIB_NAME: `F8082_Line_8G`,
            ATTRIB_VALUE: curr.G
          }
        )
      }
      let p2 = _.cloneDeep(this.f8082FormData['P2'][0])
      p2.IS_CHANGED_FLAG = "Y";
      p2.JSON_DATA = json_data;
      
      this.finalData.push(p2);
      console.log("After changes, the final data was :::::::::::=========", this.f8082FinalData);
      console.log("After changes, the manipulated data was :::::::::::=========", this.f8082FormData);
      console.log("After changes, the final data to send DB was :::::::::::=========", this.finalData);
      let jcd_key = this.rowData.JCD_KEY === undefined ? 250 : this.rowData.JCD_KEY;
      let clob_settings = {
        "tax_year": this.rowData.TAX_YEAR,
        "scenario": this.rowData.SCENARIO,
        "jcd_key": jcd_key,
        "combination_key": this.rowData.COMBINATION_KEY,
        "trans_type_key": this.trans_type_key,
      };
      let data =
      {
        "sso_id": this.sso_id,
        "scenario": this.rowData.SCENARIO,
        "jcd_key": jcd_key,
        "tax_year": this.rowData.TAX_YEAR,
        "process_name": "SAVE_8082_FORM_DATA",
        "clob_settings": JSON.stringify(clob_settings),
        "clob_data": JSON.stringify(this.finalData),
        "issue_key": this.issueKey ? this.issueKey : 0,
        "is_issue_key_enabled": this.isIssueKeyEnabled ? this.isIssueKeyEnabled : "N",
        "scenarioCode": "RAF"
      };
      let message = "Form 8082 Details are successfully updated."
      this.crudLoading = true;
      this.Form8082Service.saveF8082Details(this.baseURLs.api, data).subscribe((data: any) => {
        console.log("Result from data was ---------------::::", data);
        if (data.errorMessage != 'null') {
          this.requestGridUpdate.emit({ type: 'save-error', message: data.errorMessage });
          this.crudLoading = false;
        } else {
          this.requestGridUpdate.emit({ type: 'save-success', message: message });
          this.crudLoading = false;

        }
        return;
      });


    }
    return;

  }
  getDateFormat(date_value: any) {
    console.log("The value of the date for the row that needs to convert is ::::::", date_value);
    if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date_value))
      return date_value;
    let date_selected = null;
    if (date_value != "VARIOUS") {
      date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
    } else date_selected = "VARIOUS";

    return date_selected;
  }
  cancel() {
    this.requestGridUpdate.emit({ type: 'cancel', message: '' });
  }
}
