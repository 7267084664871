import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScreenDocumentList } from './screen-document-upload/screen-document';
import { ScreenVideo } from './screen-video-list/screen-video';
import { User } from './user';
import { UserService } from './user.service';

@Component({
  selector: 'gtw-screen-video-container',
  templateUrl: './screen-video-container.component.html',
  styleUrls: ['./screen-video-container.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ScreenVideoContainerComponent implements OnInit, OnDestroy {

  @Input('base-url')
  baseURL = '/admin';

  private _screenKey:number =0;
  private _clientKey:number =0;
  updateDocSelectedInput!: ScreenDocumentList;
  chidTabSelectedIndex!: number;

  childTabIndex:number =0;



  @Input('screen-key')
  set screenKey(screenKey:number){
    this._screenKey = parseInt(screenKey+"");
    this.refreshScreenVideoList(true);
    this.refreshScreenDocumentList(true);
  }

  get screenKey():number{
    return this._screenKey;
  }

  @Input('client-key')
  set clientKey(clientKey:number){
    this._clientKey = parseInt(clientKey+"");
    this.refreshScreenVideoList(true);
    this.refreshScreenDocumentList(true);

  }

  get clientKey():number{
    return this._clientKey;
  }
 
  @Input('user')
  set user(user:string){
    try{
      let tmp_user = JSON.parse(user ) as User;
      this.userService.setUser(tmp_user);
    }catch(error){
      console.log("Not an valid object");
    }
    
  }

  updateScreenVideoInput!: ScreenVideo;
  refreshScreenVideoListOutput = false;
  refreshScreenVideoListInputOutput = false;

  refreshScreenDocumentOutput = false;

  private allowedRoles = ['admin','super admin','developer'];
  constructor(private userService:UserService) { }

  ngOnInit(): void {    

  }

  updateScreenVideo(video:ScreenVideo){
    this.updateScreenVideoInput =video;
  }

  updateDocSelected(Document: ScreenDocumentList){
    this.updateDocSelectedInput = Document;
    this.chidTabSelectedIndex = 1;
  }



  refreshScreenVideoList(event:boolean){
    if(!isNaN(this._clientKey) && !isNaN(this._screenKey)){
      this.refreshScreenVideoListOutput = event;
      setTimeout(()=>{                           
        this.refreshScreenVideoListOutput = false;
      }, 300);
    }     
  }

  refreshScreenDocumentList(event:boolean){
    if(!isNaN(this._clientKey) && !isNaN(this._screenKey)){
      this.refreshScreenDocumentOutput = event;
      setTimeout(()=>{                           
        this.refreshScreenDocumentOutput = false;
      }, 300);
    }     
  }

  isValidUser(){
    return this.userService.isValidUser(this.allowedRoles);
  }

  ngOnDestroy(): void {
    /**
     * TODO
     * clear time outs
     */
    console.log('to do')
  }
  onTabChanged(event:any){
    this.childTabIndex = event.index;
  }
}
