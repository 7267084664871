import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ActionResponse} from '../../../shared/_models/action-response.model';
import {ActionService} from '../../../shared/_services/action.service';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';
import {DSTSharedService} from '../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class MatchTrackerService {

  constructor(private dstSharedService: DSTSharedService,
              private actionService: ActionService) {
  }

  getExistingStatements(csStatementId: number): Observable<MatchTrackerStatement[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'cs_statement_id': csStatementId.toString(),
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32312, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            console.log(data.jsonObject);
            return data.jsonObject;
          } else {
            throw Error('Something went wrong while retrieving the created statements, please try again!');
          }
        }),
      );
  }

  save(csStatementId: number, statementId: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'cs_statement_id': csStatementId.toString(),
        'statement_id': statementId
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32313, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }
}

export interface MatchTrackerStatement {
  CREATEDBY: string,
  PRIMARYENTITY: string,
  SECONDARYENTITY: string,
  STATEMENTNAME: string,
  STATEMENT_ID: number
}
