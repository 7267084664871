import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec367b-id193',
  templateUrl: './template-sec367b-id193.component.html',
  styleUrls: ['../template-shared.scss', './template-sec367b-id193.component.scss']
})
export class TemplateSec367bId193Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form193!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;

  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  PointII: string = '';
  PointIII: string = '';
  PointIV: string = 'Pursuant to Section 381(a) and the 1.367 regulations, the Transferee will succeed to the amount and character of the earnings and profits of the Transferor, as a result of the reorganization.';
  showDREMsg: boolean = false;
  DREMsg: string = '';
  entityNameChangeOnDate: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.editMode = false;
    this.form193 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        PointI: [''],
        PointII: [''],
        PointIII: [''],
        PointIV: ['Pursuant to Section 381(a) and the 1.367 regulations, the Transferee will succeed to the amount and character of the earnings and profits of the Transferor, as a result of the reorganization']
      }),
    });
    if (this.sharedService.getFormStateByFormId(193)?.isChild) {
      this.sharedService.getLoadStatementData(193).subscribe((data: StmtData) => {
        this.sharedService.setFormData(data, 193);
        this.loadStmtData();
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      });
    } else {
      this.loadStmtData();
    }
  }

  ngAfterViewInit(): void {
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData(193);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.editMode = true;
      
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
            case 1:
              element.fillLEID(data.secondaryleid);
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      },1000);

      this.populateFields(data);
      
    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;
      
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      }
    });
    if (this.attMap['PointII'] && this.attMap['PointII'][0] && this.attMap['PointII'][0] !== '') {
      this.PointII = this.attMap['PointII'][0];
    }
    if (this.attMap['PointIII'] && this.attMap['PointIII'][0] && this.attMap['PointIII'][0] !== '') {
      this.PointIII = this.attMap['PointIII'][0];
    }
    if (this.attMap['PointIV'] && this.attMap['PointIV'][0] && this.attMap['PointIV'][0] !== '') {
      this.PointIV = this.attMap['PointIV'][0];
    }
    this.cdref.detectChanges();
  }

  // query for name change as a child disclosure
  private updateEntitiesNameAsChildDis(entity: DSTEntityDetails): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (trackerTab && trackerTab.parentTransDate) {
      this.entityNameChangeOnDate = true;
      const leids: string = this.sharedService.concatLEIDs([entity]);
      if (!leids) {
        return;
      }
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        leids,
        this.sharedService.getTransDateStr(trackerTab.parentTransDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response) {
          for (let i = 0; i < leids.split(',').length; i++) {
            this.sharedService.updateEntityName(response[i].LEID,
              response[i].LE_NAME, [entity], response[i].TAXTYPECODE);
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;
    this.updateEntitiesNameAsChildDis(this.transferor!);
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;
    this.updateEntitiesNameAsChildDis(this.transferee!);
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': '',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'attachmentFileName': '',
      };
      const jsonObject = {
        'PointII': this.PointII.toString(),
        'PointIII': this.PointIII.toString(),
        'PointIV': this.PointIV.toString()
      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }

  }

  isFormValid(placeholder: boolean): boolean {
    if (placeholder) {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
    } else {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }
    }

    return true;
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }
}


