import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import _, { filter } from 'lodash';
import { map } from 'rxjs/operators';
import { DashboardProtoType } from './dashboard-model';
import { Observable, observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SummaryDiagnosticService {
 
 
  
  private URLS = {
    partnership_form: 'loadJsonObject?action_code=qroj88',
    domestic :'loadJsonObject?action_code=z2u0rj',
    FTC: 'loadJsonObject?action_code=339s3e',
    partnership_flow :'loadJsonObject?action_code=e3f4gm',
    all: 'loadJsonObject?action_code=cz0mqg'
  }

  dashboardPool:any = [];
  sheetsLoaded: EventEmitter<any> = new EventEmitter();
  drillDown: any = {
    isDrillDown: false,
    selectedFilterParms:{},
    screen_key:11461
  };

  
  constructor(private httpClient: HttpClient) { }
  getSheetData(baseUrl: string, sheetData: any,filterParams:any,clientKey: number){
   /*  let headers = new HttpHeaders({
      'client_key': clientKey+''
    });
    const options= {withCredentials:true, 
      headers:headers}; */

    return this.httpClient.post(baseUrl+ sheetData.action_id,filterParams,/* options */).pipe(
      map((responseData:any) =>{
        sheetData.data = responseData && responseData.jsonObject ? responseData.jsonObject : responseData
        console.log('sheet data',sheetData);
        return responseData.jsonObject;
      })
    )
  }
  
  getDashboardComponent(baseUrl:string,clientKey:any){
    let dashBoardURL = '/getDashboardByKey?dashboard_id=67cfkb';//'/getDashboardByKey?dashboard_id=lmxdlu';

    const url = `${baseUrl}${dashBoardURL}`;
    const data = new FormData();
    
  //   data.append('client_key', '92');
    return this.httpClient.post(url,data ).pipe(map((responseData:any) =>{
          return responseData;
    })
    )
  }

  createOrUpdateDashabord(dashboard_name: any, dashboard_id: any, data_sheets: any, components: any): any {
    console.log('datashboard',dashboard_name);
    var dashboardIndex = this.dashboardPool.findIndex(function(item: { id: any; }){ return item.id == dashboard_id});
      var dash =  new DashboardProtoType(dashboard_name,dashboard_id,data_sheets,[],[],false,false,false,[],[],components,false,[])// this.dashboard(dashboard_name, dashboard_id, data_sheets, components);
      if(dashboardIndex >= 0){
          dash.dataLoaded = true;
          this.dashboardPool[dashboardIndex] = dash;
      } else {
          this.dashboardPool.push(dash);
      }
    //  this.loadSheets();
      this.setDashboardPool(this.dashboardPool);
    
  }
  loadSheets(baseUrl:any,filterParams:any) : Observable< any >{
    let promises: any[] = [];
   /*  filterParams = {
      tax_year: 2020
      ,scenario: 40
      ,filing_key: 1
      ,parent_me_string: '99999999999999999999,6196'
      ,system: 'A'
    } */
      const data = new FormData();
      data.append('tax_year',filterParams.tax_year);
      data.append('scenario',filterParams.scenario);
      if(filterParams.filing_key){
        data.append('filing_key',filterParams.filing_key)
      }
      if(filterParams.system){
        data.append('system',filterParams.system)
      }
      if(filterParams.parent_me_string){
        data.append('parent_me_string',filterParams.parent_me_string);
      }
      if(filterParams.jcd_key){
        data.append('jcd_key',filterParams.jcd_key);
      }else{
        data.append('jcd_key','250');

      }

    // data.append('client_key','92');// need to remove


   _.forEach(this.dashboardPool[0].dataSheets,(sheet,key) =>{
    if (this.dashboardPool[0].dataSheets[key].data && this.dashboardPool[0].dataSheets[key].data.length > 0) {


    } else {
      this.dashboardPool[0].dataSheets[key].isLoading = true;
      let sheetApi = '/'+sheet.action_id;

      const url = `${baseUrl}${sheetApi}`;
        let promise = this.httpClient.post(url,data).toPromise()
            .then((res:any)=>{
              this.dashboardPool[0].dataSheets[key].safeCopyArr = _.cloneDeep(res.jsonObject);
              this.dashboardPool[0].dataSheets[key].data = _.filter(res.jsonObject,function(i){return i['FAILCNT']>0})//res.jsonObject;
              this.dashboardPool[0].dataSheets[key].isLoaded = true;
              this.dashboardPool[0].dataSheets[key].isLoading = false;
              this.sheetsLoaded.emit(this.dashboardPool[0].dataSheets[key]);

            });
            this.setDashboardPool(this.dashboardPool);
            promises.push(promise);
        }
        Promise.all(promises).catch(function(err){
          console.log('error');

        }).then((data) =>{
          // console.log('resolved',data);

        })
    });
    console.log('this.dashboardPoolthis.dashboardPool',this.dashboardPool);
    return of(this.dashboardPool);
  }
  setDashboardPool(_dashboardData:any){
    this.dashboardPool = _dashboardData;
  }
  getDashboardPool(){
    return this.dashboardPool;
  }
  calculateKpi(){
    console.log('kpi cal',this.dashboardPool);
  }
  getDiagData(tableConfigData:any,baseUrl:any,rowData: any,selectedFilterParams:any,globalParams:any) {
      console.log('table Rwo Data',rowData);
      let dashboardURL :any;
      dashboardURL = '/loadGridJson?grid_id=6019&action_id=33023';//'/loadGridJson?grid_id=6019&action_id=30227';
      /* if(tableConfigData.extraOptionsJSON && tableConfigData.extraOptionsJSON.modal_api!=null){
        dashboardURL = "/"+tableConfigData.extraOptionsJSON.modal_api;
      } */
      if(tableConfigData.extraOptionsJSON && tableConfigData.extraOptionsJSON.modal_api_config!=null){
        //dashboardURL = "/"+tableConfigData.extraOptionsJSON.modal_api;
        let modal_conig_api:any;
            modal_conig_api = (tableConfigData.extraOptionsJSON.modal_api_config);
        let findUrl:any;
            findUrl = _.find(modal_conig_api,{diag_name:rowData.DIAG_NAME});
        if(findUrl && findUrl.diag_name == rowData.DIAG_NAME){
          dashboardURL = "/"+findUrl.api;

        }
      }
    const gateway = '/gateway/gtw'; //
    const url = `${gateway}${dashboardURL}`;
    const data = new FormData();
   //data.append('client_key', '92');
   _.map(selectedFilterParams,(v,k)=>{
      data.append(k,v);
    })
    data.append('diag_key',rowData.DIAG_KEY);
    data.append('diag_type',rowData.DIAG_NAME);

     data.append('jcd_key',globalParams.jcd_key);
    // data.append('parent_me_string','99999999999999999999,6196');
    // data.append('tax_year','2020');
    // data.append('scenario','40');
    // data.append('system','A');
    // data.append('filing_key','1');
    // data.append('jcd_key','250'); // data.append('jcd_key',globalParams.jcd_key)
    return this.httpClient.post(url,data ).pipe(map((responseData:any) =>{
          return responseData;
    })
    )
  }
}



