import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CreateDisclosureSharedService} from '../../create-disclosure-shared.service';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-leid-selector',
  templateUrl: './template-leid-selector.component.html',
  styleUrls: ['./template-leid-selector.component.scss']
})
export class TemplateLeidSelectorComponent implements OnInit, OnDestroy {

  // @Input() parentFormGroup!: FormGroup;
  // @Input() myFormGroupName!: string;
  // @Input() myFormControlName!: string;
  @Input() label!: string;
  @Input() labelId!: string;
  @Input() isBranchEntity!: boolean;
  @Input() preloadLEID!: string;
  @Output() onSelectedLEChanged: EventEmitter<DSTEntityDetails | null>;

  entityDetailsList: DSTEntityDetails[];
  selectedEntity!: DSTEntityDetails | null;
  selectedLEID!: string;
  showDropdownMenu: boolean = false;
  loading: boolean = false;
  private subject: Subject<any>;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService) {
    this.onSelectedLEChanged = new EventEmitter<DSTEntityDetails | null>();
    this.entityDetailsList = [];
    this.subject = new Subject<any>();
  }

  ngOnInit(): void {
    this.initDebouncer();
    this.fillLEID(this.preloadLEID);
  }

  ngOnDestroy(): void {
    this.subject.unsubscribe();
  }

  // get transferors(): FormArray {
  //   return this.parentFormGroup.get('transferorDetails')?.get('transferors') as FormArray;
  // }
  //
  // addTransferor(): void {
  //   this.transferors.push(this.formBuilder.control(''));
  // }
  //
  // removeTransferorAt(index: number): void {
  //   this.transferors.removeAt(index);
  // }

  private initDebouncer(): void {
    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      this.getEntityList(searchTerm);
    });
  }

  onKeyUpSearch(event: any): void {
    this.subject.next(event.target.value);
  }

  getEntityList(searchTerm: string): void {
    if (searchTerm.trim().length < 2) {
      return;
    }

    this.loading = true;
    this.sharedService.getEntityList(searchTerm, this.isBranchEntity)
      .subscribe((data: DSTEntityDetails[]) => {
        this.entityDetailsList = data;
        this.showDropdownMenu = true;
      }, (e: Error) => {
        this.dstSharedService.showAlert('error', 'Something went wrong while retrieving the entity list, please clear you input and try again!');
      }).add(() => {
      this.loading = false;
    });
  }

  selectEntity(selectedEntity: DSTEntityDetails | null): void {
    this.selectedEntity = selectedEntity;
    if (this.selectedLEID === selectedEntity?.LEID) {
      this.selectedLEID = '';
    }
    setTimeout(() => {
      this.selectedLEID = selectedEntity?.LEID || '';
    });
    this.showDropdownMenu = false;
    this.onSelectedLEChanged.emit(this.selectedEntity);
  }

  onClickOutside(): void {
    this.selectedEntity = null;
    this.showDropdownMenu = false;
    this.onSelectedLEChanged.emit(this.selectedEntity);
  }

  fillLEID(leid: string | undefined): void {
    if (!leid) {
      return;
    }
    this.loading = true;
    this.sharedService.getEntityList(leid, this.isBranchEntity)
      .subscribe((data: DSTEntityDetails[]) => {
        data.length > 0 && this.selectEntity(data[0]);
      }, (e: Error) => {
        this.dstSharedService.showAlert('error', e.message);
      })
      .add(() => {
        this.loading = false;
      });
  }

  preFillLEID(selectedEntity: DSTEntityDetails | null): void {
    if (!selectedEntity) {
      return;
    }
    this.selectEntity(selectedEntity);
  }

  clear(): void {
    this.selectEntity(null);
    this.initDebouncer();
  }

  clearEntity(): void {
    this.selectEntity(null);
  }

}

export interface DSTEntityDetails {
  'ENTITYID': number,
  'ENTITY': string,
  'LEID': string,
  'TAXYEARID': number,
  'TAXYEAR': number,
  'MAINTOWNER': string,
  'EIN': string,
  'COUNTRYCODEINC': string,
  'COUNTRYINC': string,
  'STATE': string,
  'STATECODEINC': string,
  'STATEINC': string,
  'ADDRESS1': string,
  'ADDRESS2': string,
  'CITY': string,
  'COUNTRY': string,
  'COUNTRYNAME': string,
  'TAXTYPECODE': string,
  'ALTTAXTYPECODE': string,
  'ALTTAXTYPE': string,
  'ALTTAXTYPEID': number,
  'TAXTYPE': string,
  'BUSINESSID': number,
  'BUSINESS': string,
  'GEGECSID': number,
  'USTAXTYPE': string,
  'ZIP': string,
  'DISREGARDED_ENTITY': string,
  'DREENDDATE': string,
  'DREENDDATEFORMATTED': string,
  'DREENTITYLEID': string,
  'DRESTARTDATE': string,
  'DRESTARTDATEFORMATTED': string,
  'DRETAXTYPE': string,
  'DREUSTAXDESC': string,
  'DREUSTAXTYPE': string,
  'DREYEAREND': string,
  'DREYEARENDFORMATTED': string
}
