<ap-loading id="overlay" *ngIf="loader" [size]="'24px'" [colorName]="'a-primary'"></ap-loading>
<div class="mainContainer">
    <div style="width: 97.5% !important" *ngIf="initializeComplete">
        <div>
            <form [formGroup]="form" novalidate>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'TAX_YEAR'" [type]="'input'"
                                    [label]="'Tax Year'" formControlName="TAX_YEAR" [isDisabled]="true">
                                </ap-form-field>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'COMBINATION_KEY'" [type]="'input'"
                                    [label]="'Combination Key'" formControlName="COMBINATION_KEY" [isDisabled]="true">
                                </ap-form-field>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'PTR_COMBINATION_KEY'" [type]="'input'"
                                    [label]="'Partner Combination Key'" formControlName="PTR_COMBINATION_KEY"
                                    [isDisabled]="true">
                                </ap-form-field>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12" style="margin-top: -5px;">
                            <div class="form-group">
                                <label for="IS_CURRENT" style="font-size: .75rem;">Is Current Flag</label>
                                <select id="IS_CURRENT" formControlName="IS_CURRENT" class="form-control">
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">
        <div *ngIf="!totalLoading" id="placeholder-field" class="placeholder-field" style="position: fixed; bottom: 10px; left: 2%;">
            <label style="font-size: .85rem; font-weight: 500;">Number of Transactions: {{totalTransactions}}</label>
        </div>
        <ap-loading style="position: fixed; bottom: 10px; left: 2%;" id="footer-spinner" *ngIf="totalLoading" [size]="'12px'" [colorName]="'a-primary'"></ap-loading>
        <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
            [config]="secondaryConfig" (onBtnClick)="cancel()">
        </ap-button>
        <div>
            <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                (onBtnClick)="save()"></ap-button>
        </div>
    </div>
</div>