<div>
<!--  <div [formGroupName]="myFormGroupName">-->
    <!--    <div formArrayName="transferors">-->
    <!--      <div class="ap-form-field-my-wrapper" *ngFor="let transferor of transferors.controls; let i = index;">-->
    <!--        <ap-form-field [label]="'Please specify the LEID of the Transferor'" [id]="i.toString()" [type]="'input'"-->
    <!--                       [placeholder]="'Hint text'" [formControlName]="i">-->
    <!--        </ap-form-field>-->
    <!--        <ap-button class="btn-plus-minus" *ngIf="selectedOptionID === 2" [btnType]="'a-btn-gray'"-->
    <!--                   [config]="{isFloating: true}" [btnSize]="'small'" (onBtnClick)="addTransferor()">-->
    <!--          <span class="appkiticon a-btn-icon icon-plus-fill"></span>-->
    <!--        </ap-button>-->
    <!--        <ap-button class="btn-plus-minus" *ngIf="selectedOptionID === 2 && i > 0" [btnType]="'a-btn-gray'"-->
    <!--                   [config]="{isFloating: true}" [btnSize]="'small'" (onBtnClick)="removeTransferorAt(i)">-->
    <!--          <span class="appkiticon a-btn-icon icon-minus-fill"></span>-->
    <!--        </ap-button>-->
    <!--      </div>-->
    <!--      <div class="transferor-details">-->
    <!--        &lt;!&ndash;              <span>Name of Transferor:</span><span>{{transferor.transferorName}}</span>&ndash;&gt;-->
    <!--      </div>-->
    <!--      <div class="transferor-details">-->
    <!--        &lt;!&ndash;              <span>EIN:</span><span>{{transferor.transferorEIN}}</span>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="form-field-wrapper">
      <label *ngIf="label !== ''" [for]="labelId">{{label}}</label>
      <div class="search-dropdown-wrapper">
        <input type="text" class="search-dropdown-input" [ngModel]="selectedLEID" [id]="labelId"
               (keyup)="onKeyUpSearch($event)" maxlength="6">
        <div class="search-dropdown-loader" *ngIf="loading">
          <ap-loading [colorName]="'a-dark'" [size]="'24px'"></ap-loading>
        </div>
        <div class="search-dropdown-clear" *ngIf="selectedLEID">
          <span class="appkiticon icon-close-outline" title="Clear" (click)="clearEntity()"></span>
        </div>
        <div class="search-dropdown-menu" *ngIf="showDropdownMenu" dstClickOutside (clickOutside)="onClickOutside()">
          <span class="entity-list-item" *ngFor="let detail of entityDetailsList" (click)="selectEntity(detail)">{{detail.LEID}}-{{detail.ENTITY}}</span>
        </div>
      </div>
    </div>
    <!--    <div class="ap-form-field-my-wrapper">-->
    <!--      <ap-form-field [label]="'Please specify the LEID of the Transferee'" [id]="'100'" [type]="'input'" [placeholder]="'Hint text'"-->
    <!--                     formControlName="transferee" required>-->
    <!--      </ap-form-field>-->
    <!--    </div>-->
<!--    <div class="entity-details">-->
<!--      <span>Name of Transferee:</span><span>{{transfereeName}}</span>-->
<!--    </div>-->
<!--    <div class="entity-details">-->
<!--      <span>EIN:</span><span>{{transfereeEIN}}</span>-->
<!--    </div>-->
<!--  </div>-->
</div>
