import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'gtw-home-page-filter',
  templateUrl: './home-page-filter.component.html',
  styleUrls: ['./home-page-filter.component.scss']
})
export class HomePageFilterComponent implements OnInit, OnChanges {
  constructor() { }
  

  @Input('tabList')
  tabList: Array<{id: number, label: string, isSelected: boolean}> = [];

  @Input('index')
  tabSelectedIndex!: number;

  @Output() selectedTab = new EventEmitter<any>();
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  tabChanged(tabEvent: MatTabChangeEvent){
    this.tabList.forEach((item, index) => {
      if (item.isSelected && index != tabEvent.index){
          item.isSelected = false;
      }else if (index === tabEvent.index){
        item.isSelected = true;
      }
    });
    this.selectedTab.emit(this.tabList[tabEvent.index]) ;

  }
}
