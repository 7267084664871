import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {AppkitDropdownOptions} from '../template-sec8883-id215/template-sec8883-id215.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec368-id188',
  templateUrl: './template-sec368-id188.component.html',
  styleUrls: ['../template-shared.scss', './template-sec368-id188.component.scss']
})
export class TemplateSec368Id188Component implements OnInit, AfterViewInit {

  // remove me after commit

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form188!: FormGroup;
  selectedOption!: string;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  target!: DSTEntityDetails | null;
  acquirer!: DSTEntityDetails | null;
  sHolder!: DSTEntityDetails | null;
  beginDate!: Date | string;
  endDate!: Date | string;

  showImport: boolean = false;
  showTotal: boolean = false;
  totalCalc: number = 0;

  Para1: string = '';
  codeSectionDropdown: AppkitDropdownOptions[] = [{data: [{value: -1, text: '** Please select a code section **'}]}];
  selectedCode: number = -1;
  LiquidationDate1: any = '';
  showDate: any = {
    liqDate1: true,
    liqDate2: true,
  };
  LiquidationDate2: any = '';
  importationPropertyDesc: string = '';
  lossDuplicationPropertyDesc: string = '';
  gainLossPropertyDesc: string = '';
  selectedCurrency: number = -1;
  selectedCurrency2: number = -1;
  selectedBasis: number = -1;
  selectedBasisName: string = '';
  currencyOptions: any = [{data: [{index: '', value: -1, text: '** Select Currency **'}]}];
  currencyOptions2: any = [{data: [{index: '', value: -1, text: '** Select Currency **'}]}];
  propertyValBasisOptions: any = [{data: [{value: -1, text: '** Select a basis **'}]}];

  selectedCurrency3: number = -1;
  selectedCurrency4: number = -1;
  selectedBasis2: number = -1;
  selectedBasisName2: string = '';
  currencyOptions3: any = [{data: [{index: '', value: -1, text: '** Select Currency **'}]}];
  currencyOptions4: any = [{data: [{index: '', value: -1, text: '** Select Currency **'}]}];
  propertyValBasisOptions2: any = [{data: [{value: -1, text: '** Select a basis **'}]}];

  stocksSecurityPropertyDesc: string = '';
  PLR: string = 'None.';
  section4text: string = '';

  showDREMsg: boolean = false;
  DREMsg: string = '';

  headerOptions: any[] = [
    {
      data: [
        {
          id: 0,
          text: '-'
        },
        {
          id: 1,
          text: '(a)'
        }
      ],
    }
  ];
  headerOptionVal: string = '';
  entityNameChangeOnDate: boolean = false;
    nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  get additionalPropsDetails(): FormArray {
    return this.form188.get('main')?.get('additionalPropsDetails') as FormArray;
  }

  get additionalPropsDetails2(): FormArray {
    return this.form188.get('main')?.get('additionalPropsDetails2') as FormArray;
  }

  ngOnInit(): void {
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form188 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        Para1: [''],
        LiquidationDate1: [''],
        LiquidationDate2: [''],
        importationPropertyDesc: [''],
        lossDuplicationPropertyDesc: [''],
        gainLossPropertyDesc: [''],
        propertyValBasis: [''],
        currency1: [''],
        currency2: [''],
        additionalPropsDetails: this.formBuilder.array([
          this.formBuilder.group({
            description: [''],
            transferorBasis: [''],
            fairMarketVal: ['']
          })
        ]),
        stocksSecurityPropertyDesc: [''],
        PLR: [''],
        section4text: [''],
        propertyValBasis2: [''],
        currency3: [''],
        currency4: [''],
        additionalPropsDetails2: this.formBuilder.array([
          this.formBuilder.group({
            description: [''],
            transferorBasis: [''],
            fairMarketVal: ['']
          })
        ]),
      })
    });

    this.sharedService.getLiquidationStateDropdown(4)
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.codeSectionDropdown[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        if (this.attMap['CodeSection']) {
          const updatedData = this.updateDropdownList(this.codeSectionDropdown, this.attMap['CodeSection'][0]);
          this.codeSectionDropdown = updatedData.dropdownData;
          this.selectedCode = updatedData.index;
        }
      });
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.propertyValBasisOptions[0].data?.push({
            value: element.id,
            text: element.text,
            selected: false //element.id === 3 // default to GAAP
          });
          this.propertyValBasisOptions2[0].data?.push({
            value: element.id,
            text: element.text,
            selected: false //element.id === 3 // default to GAAP
          });
        });
        this.propertyValBasisOptions = JSON.parse(JSON.stringify(this.propertyValBasisOptions));
        // const gaapExist: boolean = this.propertyValBasisOptions[0].data.findIndex((d: any) => d.value === 3) >= 0;
        // if (gaapExist) {
        //   this.selectedBasis = 3;
        //   this.selectedBasis2 = 3;
        //   this.selectedBasisName = 'GAAP';
        //   this.selectedBasisName2 = 'GAAP';
        // }
      }, () => {

      }, () => {
        if (this.attMap['Basis']) {
          this.attMap['Basis'].forEach((element: any, index: number) => {
            switch (index) {
              case 0:
                let updatedData = this.updateDropdownList(this.propertyValBasisOptions, element);
                this.propertyValBasisOptions = updatedData.dropdownData;
                this.propertyValBasisOptions = JSON.parse(JSON.stringify(this.propertyValBasisOptions));
                this.selectedBasis = updatedData.index;
                break;
              case 1:
                const updatedData2 = this.updateDropdownList(this.propertyValBasisOptions2, element);
                this.propertyValBasisOptions2 = updatedData2.dropdownData;
                this.propertyValBasisOptions2 = JSON.parse(JSON.stringify(this.propertyValBasisOptions2));
                this.selectedBasis2 = updatedData2.index;
                break;
            }
          });
        }
      });

    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach((element, index) => {
          this.currencyOptions[0].data?.push({
            value: element.id,
            text: element.text,
            index: index,
          });
          this.currencyOptions2[0].data?.push({
            value: element.id,
            text: element.text,
            index: index,
          });
          this.currencyOptions3[0].data?.push({
            value: element.id,
            text: element.text,
            index: index,
          });
          this.currencyOptions4[0].data?.push({
            value: element.id,
            text: element.text,
            index: index,
          });
        });
        const usdIndex: number = this.currencyOptions[0].data.findIndex((c: any) => c.value === 84);
        if (usdIndex > 0) {
          this.currencyOptions[0].data[usdIndex].selected = true;
          this.currencyOptions2[0].data[usdIndex].selected = true;
          this.currencyOptions3[0].data[usdIndex].selected = true;
          this.currencyOptions4[0].data[usdIndex].selected = true;
          this.currencyOptions = JSON.parse(JSON.stringify(this.currencyOptions));
          this.currencyOptions2 = JSON.parse(JSON.stringify(this.currencyOptions2));
          this.currencyOptions3 = JSON.parse(JSON.stringify(this.currencyOptions3));
          this.currencyOptions4 = JSON.parse(JSON.stringify(this.currencyOptions4));
          this.selectedCurrency = this.currencyOptions[0].data[usdIndex].value;
          this.selectedCurrency2 = this.currencyOptions2[0].data[usdIndex].value;
          this.selectedCurrency3 = this.currencyOptions3[0].data[usdIndex].value;
          this.selectedCurrency4 = this.currencyOptions4[0].data[usdIndex].value;
        }
      }, () => {

      }, () => {
        if (this.attMap['Currency']) {
          this.attMap['Currency'].forEach((element: any, index: number) => {
            switch (index) {
              case 0:
                let updatedData = this.updateDropdownList(this.currencyOptions, element);
                this.currencyOptions = updatedData.dropdownData;
                this.selectedCurrency = updatedData.index;
                break;
              case 1:
                const updatedData2 = this.updateDropdownList(this.currencyOptions2, element);
                this.currencyOptions2 = updatedData2.dropdownData;
                this.selectedCurrency2 = updatedData2.index;
                break;
              case 2:
                const updatedData3 = this.updateDropdownList(this.currencyOptions3, element);
                this.currencyOptions3 = updatedData3.dropdownData;
                this.selectedCurrency3 = updatedData3.index;
                break;
              case 3:
                const updatedData4 = this.updateDropdownList(this.currencyOptions4, element);
                this.currencyOptions4 = updatedData4.dropdownData;
                this.selectedCurrency4 = updatedData4.index;
                break;
            }
          });
        }
      });
  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data: StmtData | undefined = this.sharedService.getFormData(188);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
            case 1:
              element.fillLEID(data.secondaryleid);
              break;
            case 2:
              if (this.attMap['SHdspTransferorLEID'] && this.attMap['SHdspTransferorLEID'][0]) {
                this.sharedService.getDSTEntityInfo(this.attMap['SHdspTransferorLEID'][0]).subscribe((data: any[]) => {
                  element.fillLEID(data[0].LEID);
                });
              }
              break;
          }
        });
      },1000);

      this.populateFields(data);

    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;

      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
        this.sharedService.checkImportBtnRequired(this.sharedService.leid, (this.sharedService.getActiveFormState()?.taxYear || 2020).toString(), this.sharedService.csStatementId.toString())
          .subscribe((data: boolean) => {
            this.showImport = data;
          }, () => {

          }, () => {

          });
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }
  populateFields(data: StmtData) {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      } else {
        this.attMap[stmt.attributeName]?.push('');
      }
    });
    if (this.attMap['LiquidationDate']) {
      this.attMap['LiquidationDate'].forEach((element: any, index: number) => {
        switch (index) {
          case 0:
            if (element !== '' && element !== undefined) {
              this.LiquidationDate1 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;

          case 1:
            if (element !== '' && element !== undefined) {
              this.LiquidationDate2 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;
        }
      });
    }

    if (this.attMap['Desc'] && this.attMap['Desc'].length) {
      this.additionalPropsDetails.clear();
      this.attMap['Desc'].forEach((element: string, index: number) => {
        this.additionalPropsDetails.push(this.formBuilder.group({
          description: [element],
          transferorBasis: [this.attMap['BasisAmt'][index]],
          fairMarketVal: [this.attMap['FMVAmt'][index]]
        }));
      });
    }
    if (this.attMap['Desc1'] && this.attMap['Desc1'].length) {
      this.additionalPropsDetails2.clear();
      this.attMap['Desc1'].forEach((element: string, index: number) => {
        this.additionalPropsDetails2.push(this.formBuilder.group({
          description: [element],
          transferorBasis: [this.attMap['BasisAmt1'][index]],
          fairMarketVal: [this.attMap['FMVAmt1'][index]]
        }));
      });
    }
    if (this.attMap['Para1']) {
      this.Para1 = this.attMap['Para1'][0];
    }
    if (this.attMap['importationPropertyDesc']) {
      this.importationPropertyDesc = this.attMap['importationPropertyDesc'][0];
    }
    if (this.attMap['lossDuplicationPropertyDesc']) {
      this.lossDuplicationPropertyDesc = this.attMap['lossDuplicationPropertyDesc'][0];
    }
    if (this.attMap['gainLossPropertyDesc']) {
      this.gainLossPropertyDesc = this.attMap['gainLossPropertyDesc'][0];
    }
    if (this.attMap['stocksSecurityPropertyDesc']) {
      this.stocksSecurityPropertyDesc = this.attMap['stocksSecurityPropertyDesc'][0];
    }
    if (this.attMap['PLR']) {
      this.PLR = this.attMap['PLR'][0];
    }
    if (this.attMap['section4text']) {
      this.section4text = this.attMap['section4text'][0];
    }
    this.cdref.detectChanges();
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.target, this.acquirer, this.sHolder])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.target ? this.target?.ENTITYID.toString() : '',
        'secondaryEntityId': this.acquirer ? this.acquirer?.ENTITYID.toString() : '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };
      const desc: string[] = [];
      const basisAmt: string[] = [];
      const fairMarketVal: string[] = [];
      this.additionalPropsDetails.controls.forEach(element => {
        desc.push(element.value.description);
        basisAmt.push(element.value.transferorBasis !== null ? element.value.transferorBasis.toString() : '');
        fairMarketVal.push(element.value.fairMarketVal !== null ? element.value.fairMarketVal.toString() : '');
      });
      const desc2: string[] = [];
      const basisAmt2: string[] = [];
      const fairMarketVal2: string[] = [];
      this.additionalPropsDetails2.controls.forEach(element => {
        desc2.push(element.value.description);
        basisAmt2.push(element.value.transferorBasis !== null ? element.value.transferorBasis.toString() : '');
        fairMarketVal2.push(element.value.fairMarketVal !== null ? element.value.fairMarketVal.toString() : '');
      });

      const jsonObject = {
        'statementOption': this.selectedOption === '-' ? '' : this.selectedOption,
        'SHLEID': this.sHolder ? this.sHolder?.LEID.toString() : '',
        'SHdspTransferorLEID': this.sHolder && this.sHolder?.ENTITYID ? this.sHolder?.ENTITYID.toString() : '',
        'SHBusiness': this.sHolder && this.sHolder?.BUSINESSID ? this.sHolder?.BUSINESSID.toString() : '',
        'Para1': this.Para1.toString(),
        'CodeSection': this.selectedCode.toString(),
        'LiquidationDate': [this.LiquidationDate1 !== null ? this.LiquidationDate1.startDate ? this.LiquidationDate1.startDate.format('YYYY-MM-DD') : this.LiquidationDate1 : '', this.LiquidationDate2 !== null ? this.LiquidationDate2.startDate ? this.LiquidationDate2.startDate.format('YYYY-MM-DD') : this.LiquidationDate2 : ''],
        'importationPropertyDesc': this.importationPropertyDesc.toString(),
        'lossDuplicationPropertyDesc': this.lossDuplicationPropertyDesc.toString(),
        'gainLossPropertyDesc': this.gainLossPropertyDesc.toString(),
        'Basis': [this.selectedBasis.toString(), this.selectedBasis2.toString()],
        'Currency': [this.selectedCurrency.toString(), this.selectedCurrency2.toString(), this.selectedCurrency3.toString(), this.selectedCurrency4.toString()],
        'Desc': desc[0] ? desc : [''],
        'BasisAmt': basisAmt,
        'FMVAmt': fairMarketVal,
        'stocksSecurityPropertyDesc': this.stocksSecurityPropertyDesc.toString(),
        'Desc1': desc2[0] ? desc2 : [''],
        'BasisAmt1': basisAmt2,
        'FMVAmt1': fairMarketVal2,
        'PLR': this.PLR.toString(),
        'section4text': this.section4text.toString()
      };

      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          const childStatements = data.childStatement;
          if (childStatements?.length) {
            this.sharedService.openChildStatements(childStatements, this.onSave);
            trackerTab.parentTransDate = this.LiquidationDate1;
          } else {
            this.sharedService.switchTemplateMode(true);
            this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
          }
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onTargetChanged(selectedTarget: DSTEntityDetails | null): void {
    this.target = selectedTarget;
    this.showImport = true;
    this.headerComponent.loadConsolGroups(selectedTarget?.LEID!,this.dcnId);
    // check for DRE entities
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.target, this.acquirer, this.sHolder]);
    this.showDREMsg = !!this.DREMsg;
    // check for name change
    if (this.LiquidationDate1 && this.target?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.target.LEID,
        this.sharedService.getTransDateStr(this.LiquidationDate1,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.target!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.target!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.LiquidationDate1) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onAcquirerChanged(selectedAcquirer: DSTEntityDetails | null): void {
    this.acquirer = selectedAcquirer;
    // check for DRE entities
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.acquirer, this.target, this.sHolder]);
    this.showDREMsg = !!this.DREMsg;
    // check for name change
    if (this.LiquidationDate1 && this.acquirer?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.acquirer.LEID,
        this.sharedService.getTransDateStr(this.LiquidationDate1,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.acquirer!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.acquirer!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.LiquidationDate1) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onSHolderChanged(selectedSHolder: DSTEntityDetails | null): void {
    this.sHolder = selectedSHolder;
    // check for DRE entities
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.sHolder, this.target, this.acquirer]);
    this.showDREMsg = !!this.DREMsg;
    // check for name change
    if (this.LiquidationDate1 && this.sHolder?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.sHolder.LEID,
        this.sharedService.getTransDateStr(this.LiquidationDate1,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.sHolder!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.sHolder!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.LiquidationDate1) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  updateDropdownList(currentOptions: any, element: string) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      if (parseInt(element) === el.value) {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = el.value;
      } else {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onFormOptionChanged(selectedOptionID: number): void {
    const selected: any = this.headerOptions[0].data.find((o: any) => {
      return o.id === selectedOptionID;
    });
    this.selectedOption = selected.text;
  }

  codeSelected(event: any) {
    this.selectedCode = event.selected[0].value;
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1: {
        this.LiquidationDate1 = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.target!, this.acquirer!, this.sHolder!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.entityNameChangeOnDate = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.sharedService.getTransDateStr(this.LiquidationDate1,1),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.target!, this.acquirer!, this.sHolder!], response[i].TAXTYPECODE);
            }
          }
            this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });
        break;
      }
      case 2: {
        this.LiquidationDate2 = event.startDate.format('YYYY-MM-DD');
        this.PLR = '';
        break;
      }
      default:
        break;
    }
  }

  clearDate(index: number): void {
    switch (index) {
      case 1: {
        this.showDate.liqDate1 = false;
        this.LiquidationDate1 = '';
        setTimeout(() => {
          this.showDate.liqDate1 = true;
        });
        break;
      }
      case 2: {
        this.showDate.liqDate2 = false;
        this.LiquidationDate2 = '';
        setTimeout(() => {
          this.showDate.liqDate2 = true;
        });
        break;
      }
      default:
        break;
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  dummyFunc(event: Event): void {

  }

  addAdditionalPropDetails(): void {
    this.additionalPropsDetails.push(this.formBuilder.group({
      description: [''],
      transferorBasis: [''],
      fairMarketVal: ['']
    }));
  }

  removeAdditionalPropDetailsAt(index: number): void {
    this.additionalPropsDetails.removeAt(index);
  }

  addAdditionalPropDetails2(): void {
    this.additionalPropsDetails2.push(this.formBuilder.group({
      description: [''],
      transferorBasis: [''],
      fairMarketVal: ['']
    }));
  }

  removeAdditionalPropDetailsAt2(index: number): void {
    this.additionalPropsDetails2.removeAt(index);
  }

  currencyOneChange(event: any): void {
    this.selectedCurrency = event.selected[0].value;
    this.currencyOptions[0].data = JSON.parse(JSON.stringify(event.items[0].data));
    this.currencyOptions2[0].data = JSON.parse(JSON.stringify(event.items[0].data));
    this.selectedCurrency2 = event.selected[0].value;
  }

  currencyTwoChange(event: any): void {
    this.selectedCurrency2 = event.selected[0].value;
  }

  selectBasisOption(event: any) {
    this.selectedBasis = event.selected[0].value;
    this.selectedBasisName = event.selected[0].text;
  }

  currencyOneChange2(event: any): void {
    this.selectedCurrency3 = event.selected[0].value;
    this.currencyOptions3[0].data = JSON.parse(JSON.stringify(event.items[0].data));
  }

  currencyTwoChange2(event: any): void {
    this.selectedCurrency4 = event.selected[0].value;
  }

  selectBasisOption2(event: any) {
    this.selectedBasis2 = event.selected[0].value;
    this.selectedBasisName2 = event.selected[0].text;
  }

  onChangeNumber(event: any, index: number): void {
    // let index = event.event.srcElement.id.charAt(6);
    this.additionalPropsDetails.at(index).setValue({
      description: this.additionalPropsDetails.at(index).value.description,
      transferorBasis: event.value,
      fairMarketVal: event.value
    });
    const val: any = (document.getElementById(`input-${index}c`));
    val.value = event.value;
  }

  onChangeNumber2(event: any, index: number): void {
    // let index = event.event.srcElement.id.charAt(7);
    this.additionalPropsDetails2.at(index).setValue({
      description: this.additionalPropsDetails2.at(index).value.description,
      transferorBasis: event.value,
      fairMarketVal: event.value
    });
    const val: any = (document.getElementById(`input-2${index}c`));
    val.value = event.value;
  }

  import(): void {
    this.startLoader = true;
    this.sharedService.getImport(this.target?.LEID, this.sharedService.getActiveFormState()?.taxYear, this.sharedService.getTransDateStr(this.LiquidationDate1))
      .subscribe((data: any) => {
        if (data.length) {
          this.additionalPropsDetails.clear();
          data.forEach((element: any) => {
            this.additionalPropsDetails.push(this.formBuilder.group({
              description: [element.DESCRIPTION],
              transferorBasis: [element.AMOUNTS],
              fairMarketVal: [element.AMOUNTS]
            }));
            this.totalCalc += element.AMOUNTS;
          });
          this.showImport = false;
          this.showTotal = true;
          this.propertyValBasisOptions[0].data[1].selected = true;
          this.selectedBasis = this.propertyValBasisOptions[0].data[1].value;
        }
        this.startLoader = false;
      }, () => {

      }, () => {

      });
  }

  total(event: any): void {
    this.additionalPropsDetails.clear();
    if (event.srcElement.checked) {
      this.additionalPropsDetails.push(this.formBuilder.group({
        description: ['Net Assets'],
        transferorBasis: [this.totalCalc],
        fairMarketVal: [this.totalCalc]
      }));
    } else {
      this.totalCalc = 0;
      this.import();
    }
  }

  isFormValid(placeholder: boolean): boolean {
    if (placeholder) {
      if (!this.target) {
        this.dstSharedService.showAlert('error', 'Please specify the Target');
        return false;
      }
    } else {
      if (!this.target) {
        this.dstSharedService.showAlert('error', 'Please specify the Target');
        return false;
      }
      if (!this.acquirer) {
        this.dstSharedService.showAlert('error', 'Please specify the Acquirer');
        return false;
      }
      if (this.selectedCode === -1) {
        this.dstSharedService.showAlert('error', 'Please select a code section');
        return false;
      }
      if (this.LiquidationDate1 == null || this.LiquidationDate1 === '') {
        this.dstSharedService.showAlert('error', 'Please select Date of reorganization');
        return false;
      }

      if (this.selectedCurrency === -1) {
        this.dstSharedService.showAlert('error', 'Please select Fair Market value Currency type');
        return false;
      }
      if (this.selectedBasis === -1) {
        this.dstSharedService.showAlert('error', 'Please select a Basis section');
        return false;
      }
      if (this.selectedCurrency2 === -1) {
        this.dstSharedService.showAlert('error', 'Please select Fair Market value Currency type');
        return false;
      }
      if (this.sHolder && this.selectedBasis2 === -1) {
        this.dstSharedService.showAlert('error', 'Please select a Basis section');
        return false;
      }
    }

    return true;
  }

}
