<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form188">
    <dst-template-header [parentFormGroup]="form188" [overwriteFormName]="'STATEMENT PURSUANT TO REGULATION 1.368-3'"
                         [allowOptional]="true"
                         [extraLine]="'BY A CORPORATION A PARTY TO A REORGANIZATION AND BY A SIGNIFICANT SHAREHOLDER'"
                         [dropDownOptions]="headerOptions" (formOptionChanged)="onFormOptionChanged($event)"
                         (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <div formGroupName="main">
      Pursuant to Section 1.368-3{{headerOptionVal}} of the Regulations, the following information is furnished:
      <dst-template-leid-selector class="required-field entity-leid-label" #mainLeidSelector
                                  [label]="'Please specify the LEID of the Target Corporation'" [labelId]="'100'"
                                  (onSelectedLEChanged)="onTargetChanged($event)">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="target === undefined">
          Please specify the Target
        </div> -->
      <div class="entity-details">
        <span class="ed-label">The following: <strong>Target Corporation</strong> </span><span class="template-color-blue">{{!target ? ''
                                                                                                                                     : target.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span class="ed-label">EIN:</span><span class="template-color-blue">{{!target ? '' : (target.EIN ? target.EIN : 'N/A')}}</span><span>,&nbsp; a&nbsp;
        </span><span class="template-color-blue"> {{!target ? '' : target?.COUNTRYINC +
        (target | taxtype: target?.TAXTYPECODE: entityNameChangeOnDate)}}</span>
      </div>
      <dst-template-leid-selector class="required-field entity-leid-label" #mainLeidSelector
                                  [label]="'Please specify the LEID of Acquiring'" [labelId]="'100'"
                                  (onSelectedLEChanged)="onAcquirerChanged($event)">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="acquirer === undefined">
          Please specify the Acquirer
        </div> -->
      <div class="entity-details">
        <span class="ed-label">The following: <strong>Acquirer</strong> </span><span class="template-color-blue">{{!acquirer ? '' :
                                                                                                                   acquirer.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span class="ed-label">EIN:</span><span class="template-color-blue">{{!acquirer ? '' : (acquirer.EIN ? acquirer.EIN : 'N/A')}}</span><span>,&nbsp; a
          &nbsp; </span><span class="template-color-blue"> {{!acquirer ? '' : acquirer?.COUNTRYINC +
        (acquirer | taxtype: acquirer?.TAXTYPECODE: entityNameChangeOnDate)}}</span>
      </div>
      <dst-template-leid-selector class="entity-leid-label" #mainLeidSelector [label]="'Please specify the LEID of the Significant Holder'"
                                  [labelId]="'100'" (onSelectedLEChanged)="onSHolderChanged($event)">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span class="ed-label">The following: <strong>Significant Holder</strong> </span><span class="template-color-blue">{{!sHolder ?
                                                                                                                             '' : sHolder.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span class="ed-label">EIN:</span><span class="template-color-blue">{{!sHolder ? '' : (sHolder.EIN ? sHolder.EIN : 'N/A')}}</span><span>,&nbsp; a &nbsp;
        </span><span class="template-color-blue"> {{!sHolder ? '' : sHolder?.COUNTRYINC +
        (sHolder | taxtype: sHolder?.TAXTYPECODE: entityNameChangeOnDate)}}</span>
      </div>

      <ap-form-field [inlineBlock]="true" formControlName="Para1" [(ngModel)]="Para1" [label]="''" [id]="'1'"
                     [type]="'textarea'">
      </ap-form-field>
      <div class="d-flex">
        <span class="inline-para-text">are parties in a transaction that qualified as a reorganization under Code Section
          &nbsp;</span>
        <ap-selector [items]="codeSectionDropdown" [type]="'Dropdown'" class="required-field-dropdown"
                     [placeholder]="'** Please select a code section **'" (onSelectedChange)="codeSelected($event)">
        </ap-selector>

      </div>
      <!-- <div class="a-form-error error-dropdown" *ngIf="selectedCode === -1">
          Please select a code section
        </div> -->
      <ol start="2" style="margin: 15px;">
        <li>
          <div style="display: grid;">
            <div class="col-md-6 pl-0">
              The date of the reorganization was
            </div>
            <div class="col-md-5 pl-0 d-flex">
              <ap-date-picker class="required-field" format="yyyy-MM-DD" type="singleCalendar" size="medium"
                [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)"
                [(ngModel)]="LiquidationDate1" (click)="onDPOpen()" formControlName="LiquidationDate1" *ngIf="showDate.liqDate1 && nameChangeChecked">
              </ap-date-picker>
              <span *ngIf="LiquidationDate1" class="appkiticon icon-close-outline pl-2" title="Clear" (click)="clearDate(1)"></span>
              <!-- <div class="a-form-error" *ngIf="LiquidationDate1 === null || LiquidationDate1 === ''">
                Please select Date of reorganization
              </div> -->
            </div>


          </div>

        </li>

         <!-- <u>Disclosure required for Target/Corporation:</u>&nbsp; -->
        <li>
          The value and basis of the assets, stock or securities
          of the target corporation transferred in the transaction, determined immediately before the transfer and
          aggregated as follows:
          <ol class="inner-list">
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field
                  [label]="'Importation property transferred in a loss importation transaction, as defined in Section 1.362-3(c)(2) and (3), respectively:'"
                  [id]="'310'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true" (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                  [(ngModel)]="importationPropertyDesc" formControlName="importationPropertyDesc" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field [label]="'Loss duplication property as defined in Section 1.362-4(g)(1):'" [id]="'320'"
                               [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]="" [withButton]="true"
                               (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                               [(ngModel)]="lossDuplicationPropertyDesc" formControlName="lossDuplicationPropertyDesc" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field
                  [label]="'Property with respect to which any gain or loss was recognized on the transfer (without regard to whether such property is also identified in paragraph (a)(3)(i) or (ii) of this section)'"
                  [id]="'330'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true" (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                  [(ngModel)]="gainLossPropertyDesc" formControlName="gainLossPropertyDesc" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content">
                <span class="a-form-label d-inline-block" style="cursor: default; font-size: inherit;">Property not described in paragraph (a)(3)(i), (ii), or (iii) of this section;</span>

                <table class="table-layout">
                  <tbody>
                  <tr>
                    <td colspan="3">
                      <ap-button [btnText]="'Import Domestic or Foreign Trial Balance Data'" *ngIf="showImport"
                                 class="import-btn a-mr-10" [config]="{ isSecondary:true }" (onBtnClick)="import()" [isDisabled]="!LiquidationDate1">
                      </ap-button>
                      <ap-checkbox [text]="'Report TB Data on Total Net Assets basis'" class="a-mx-10 import-btn"
                                   (onCheck)="total($event)" *ngIf="showTotal">
                      </ap-checkbox>
                    </td>
                    <td>
                      <ap-selector [items]="propertyValBasisOptions" [type]="'Dropdown'"
                                   class="required-field-dropdown" [placeholder]="'** Select a basis **'"
                                   (onSelectedChange)="selectBasisOption($event)" formControlName="propertyValBasis">
                      </ap-selector>
                      <!-- <div class="a-form-error" *ngIf="selectedBasis === -1">
                          Please select a Basis section
                        </div> -->
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td><span>{{selectedBasisName}} Basis to the Transferor Corporation</span></td>
                    <td><span>Fair Market Value on Date of Transfer</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td class="header-column"><span>Description</span></td>
                    <td>
                      <ap-selector class="required-field-dropdown currency-dropdown field-full-width" formControlName="currency1"
                                   [items]="currencyOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                   [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyOneChange($event)">
                      </ap-selector>
                      <!-- <div class="a-form-error" *ngIf="selectedCurrency === 0">
                          Please select Fair Market value Currency type
                        </div> -->
                    </td>
                    <td>
                      <ap-selector class="required-field-dropdown currency-dropdown field-full-width" formControlName="currency2"
                                   [items]="currencyOptions2" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                   [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyTwoChange($event)">
                      </ap-selector>
                    </td>
                  </tr>
                  <ng-container formArrayName="additionalPropsDetails">
                    <tr *ngFor="let p of additionalPropsDetails.controls; let i = index;">
                      <ng-container [formGroupName]="i">
                        <td style="width: 3%;">
                          <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                                     [btnSize]="'small'" *ngIf="i > 0" (onBtnClick)="removeAdditionalPropDetailsAt(i)">
                            <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                          </ap-button>
                        </td>
                        <td style="width: 3%;">
                          <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                                     [btnSize]="'small'" (onBtnClick)="addAdditionalPropDetails()">
                            <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                          </ap-button>
                        </td>
                        <td style="width: 50%;">
                          <ap-form-field [label]="''" [id]="i + 'a'" [type]="'input'" formControlName="description">
                          </ap-form-field>
                        </td>
                        <td style="width: 22%;">
                          <ap-form-field [label]="''" [id]="i + 'b'" [type]="'inputNumber'" [funType]="'typeTwo'"
                                         formControlName="transferorBasis" (onChangeEvent)="onChangeNumber($event, i)"
                                         class="number-field">
                          </ap-form-field>
                        </td>
                        <td style="width: 22%;">
                          <ap-form-field [label]="''" [id]="i + 'c'" [type]="'inputNumber'" [funType]="'typeTwo'"
                                         formControlName="fairMarketVal" class="number-field">
                          </ap-form-field>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>

            </li>
          </ol>
          <p><u>Disclosure required for Significant Holder</u> (if applicable):</p>
          <p>The value and basis of all the stock or securities of the target corporation held by the significant holder
            that is transferred in the transaction and such holder's basis in that stock or securities, determined
            immediately before the transfer and aggregated as follows:</p>
          <ol class="inner-list">
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field
                  [label]="'Stocks and securities with respect to which an election is made under Section 362(e)(2)(C):'"
                  [id]="'340'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true" (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                  [(ngModel)]="stocksSecurityPropertyDesc" formControlName="stocksSecurityPropertyDesc" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content">
                <span class="a-form-label d-inline-block" style="cursor: default; font-size: inherit;">Stocks and securities not described
                  in Section 1.368-3(b)(3)(i)::</span>

                <table class="table-layout">
                  <tbody>
                  <tr>
                    <td colspan="3">
                    </td>
                    <td>
                      <ap-selector [items]="propertyValBasisOptions2" [type]="'Dropdown'"
                                   class="required-field-dropdown" [placeholder]="'** Select a basis **'"
                                   (onSelectedChange)="selectBasisOption2($event)" formControlName="propertyValBasis2">
                      </ap-selector>
                      <!-- <div class="a-form-error" *ngIf="selectedBasis2 === -1">
                          Please select a Basis section
                        </div> -->
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td><span>{{selectedBasisName2}} Basis to the Significant Holder</span></td>
                    <td><span>Fair Market Value on Date of Transfer</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td class="header-column"><span>Description</span></td>
                    <td>
                      <ap-selector formControlName="currency3" class="required-field-dropdown currency-dropdown field-full-width"
                                   [items]="currencyOptions3" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                   [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyOneChange2($event)">
                      </ap-selector>
                    </td>
                    <td>
                      <ap-selector formControlName="currency4" class="required-field-dropdown currency-dropdown field-full-width"
                                   [items]="currencyOptions4" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                   [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyTwoChange2($event)">
                      </ap-selector>
                    </td>
                  </tr>
                  <ng-container formArrayName="additionalPropsDetails2">
                    <tr *ngFor="let p of additionalPropsDetails2.controls; let i = index;">
                      <ng-container [formGroupName]="i">
                        <td style="width: 3%;">
                          <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                                     [btnSize]="'small'" *ngIf="i > 0" (onBtnClick)="removeAdditionalPropDetailsAt2(i)">
                            <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                          </ap-button>
                        </td>
                        <td style="width: 3%;">
                          <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                                     [btnSize]="'small'" (onBtnClick)="addAdditionalPropDetails2()">
                            <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                          </ap-button>
                        </td>
                        <td style="width: 50%;">
                          <ap-form-field [label]="''" [id]="'2'+i + 'a'" [type]="'input'"
                                         formControlName="description">
                          </ap-form-field>
                        </td>
                        <td style="width: 22%;">
                          <ap-form-field [label]="''" [id]="'2'+i + 'b'" [type]="'inputNumber'" [funType]="'typeTwo'"
                                         formControlName="transferorBasis" (onChangeEvent)="onChangeNumber2($event, i)"
                                         class="number-field">
                          </ap-form-field>
                        </td>
                        <td style="width: 22%;">
                          <ap-form-field [label]="''" [id]="'2'+i + 'c'" [type]="'inputNumber'" [funType]="'typeTwo'"
                                         formControlName="fairMarketVal" class="number-field">
                          </ap-form-field>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
        </li>
        <li>The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in
          connection with this reorganization:
          <div id="step4">
            <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 2)" (click)="onDPOpen()"
                            [(ngModel)]="LiquidationDate2" formControlName="LiquidationDate2" *ngIf="showDate.liqDate2">
            </ap-date-picker>
            <span *ngIf="LiquidationDate2" class="appkiticon icon-close-outline pl-2" title="Clear" (click)="clearDate(2)"></span>
            <ap-form-field [id]="'401'" [type]="'input'" formControlName="PLR" [(ngModel)]="PLR">
            </ap-form-field>
          </div>
        </li>
      </ol>
      <div class="supp-info-wrapper">
        <div>Supplemental Information:</div>
        <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" (onChangeEvent)="dummyFunc($event)"
                       [(ngModel)]="section4text" formControlName="section4text">
        </ap-form-field>
      </div>
      <div class="d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="target" class="template-color-blue">{{ target.LEID }}</span><span *ngIf="acquirer">
            / </span><span *ngIf="acquirer" class="template-color-blue">{{ acquirer.LEID }}</span><span *ngIf="sHolder">
            / </span><span *ngIf="sHolder" class="template-color-blue">{{ sHolder.LEID }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
