import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[syncTableRowHeight]'
})
export class SyncTableRowHeightDirective {
    @Input() rowId = '';
    constructor(private el: ElementRef) {
    }
    
    ngAfterViewInit(){
      setTimeout(() =>this.findAndMatchHeight());
    }

  private findAndMatchHeight() {
    if(this.el.nativeElement.clientHeight === 70) return;

    const row: HTMLElement | null = document.getElementById(this.rowId);
    if (row) {
      row.style.height = this.el.nativeElement.clientHeight + 'px';
      console.log('row found', this.rowId);
    } else {
      console.log('row not found ', this.rowId);
    }
  }
}