<ul class="st-dropdown-menu" [ngClass]="{'after-show': row.showDropdown, 'position-absolute': menuPos.mode === 'absolute'}"
    [style.top]="menuPos.offsetTop + 'px'" [style.left]="menuPos.offsetLeft > 0 ? menuPos.offsetLeft + 'px' : 'unset'"
    [style.transform]="menuPos.expandAbove ? 'translateY(-200px)' : 'translateY(2px)'" [@dropdownOpenClose]="row.showDropdown"
    *ngIf="showStatementOptions()">
  <li *ngFor="let config of filterDropdownMenuConfig(dropdownMenuConfig) | filterHidden" [class]="config.class" (click)="config.actionCallback()"
    [ngClass]="{'cursor-disabled': row.IS_FILED && config.disableIfFiled}">
    <div [ngClass]="{'disabled': row.IS_FILED && config.disableIfFiled}">
      <span class="appkiticon" [ngClass]="config.iconClass"></span>
      <input type="checkbox" [(ngModel)]="row.isReviewedVal" *ngIf="config.checkbox">
      <span>{{config.desc}}</span>
    </div>
  </li>
</ul>

<ul class="st-dropdown-menu" [ngClass]="{'after-show': row.showDropdown, 'position-absolute': menuPos.mode === 'absolute'}"
    [style.top]="menuPos.offsetTop + 'px'" [style.left]="menuPos.offsetLeft > 0 ? menuPos.offsetLeft + 'px' : 'unset'"
    [style.transform]="menuPos.expandAbove ? 'translateY(-125px)' : 'translateY(2px)'" [@createDropdownOpenClose]="row.showDropdown"
    *ngIf="showRequirementOptions()">
  <li class="mark-na" *ngIf="dstSharedService.user && dstSharedService.user.roleLevel >= 2" (mouseenter)="row.showExtendedMenu = true;"
    [ngClass]="{'cursor-disabled': row.IS_FILED}">
    <div [ngClass]="{'disabled': row.IS_FILED}">
      <span class="appkiticon icon-like-outline icon-placeholder"></span>
      <span>Mark as N/A</span>
      <span class="appkiticon icon-right-chevron-outline mark-na-extend"></span>
    </div>
  </li>
  <li *ngFor="let config of createDropdownMenuConfig | filterHidden" (mouseenter)="row.showExtendedMenu = false;" (click)="config.actionCallback()"
    [ngClass]="{'cursor-disabled': row.IS_FILED}">
    <div [ngClass]="{'disabled': row.IS_FILED}">
      <span class="appkiticon" [ngClass]="config.iconClass"></span>
      <span>{{config.desc}}</span>
    </div>
  </li>
</ul>


