import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StmtAttrData, StmtData} from 'projects/gtw-elements/src/app/DST/shared/models/dst-form-state.model';
import {CreateDisclosureSharedService, DropDownType} from '../../../create-disclosure-shared.service';
import {TemplateAttachedService} from '../../template-attached/template-attached.service';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';
import { DSTEntityDetails } from '../../template-leid-selector/template-leid-selector.component';

@Component({
  selector: 'gtw-view-template-form8886-id235',
  templateUrl: './view-template-form8886-id235.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form8886-id235.component.scss']
})
export class ViewTemplateForm8886Id235Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  startLoader: boolean = false;
  disableActions: boolean = false;
  loaded: boolean = false;
  stmtData!: any;
  form235!: FormGroup;
  attMap: any;

  formModel: any = {
    statementNumber: undefined,
    totalStatementNumber: undefined,
    formNumberOfTaxReturn: undefined,
    forTaxYear: undefined,
    attachedToAmendedReturn: undefined,
    initialYearFiler: undefined,
    protectiveDisclosure: undefined,
    reportableTransactionInfoNameOfReportableTransaction: undefined,
    reportableTransactionInfoInitialYearParticipated: undefined,
    reportableTransactionInfoTransactionOrTaxShelterNumber: undefined,
    listed: undefined,
    contractualProtection: undefined,
    transactionOfInterest: undefined,
    confidential: undefined,
    loss: undefined,
    publishedGuidanceNumber: undefined,
    numberOfSameOrSimilarTr: undefined,
    deductions: undefined,
    exclusionsFromGrossIncome: undefined,
    capitalLoss: undefined,
    nonrecognitionOfGain: undefined,
    ordinaryLoss: undefined,
    adjustmentsToBasis: undefined,
    line7aOther: undefined,
    line7aOtherText: undefined,
    taxCredits: undefined,
    deferral: undefined,
    absenceOfAdjustmentsToBasis: undefined,
    totalTaxBenefitAmt: undefined,
    taxBnftAnticipatedYrCnt: undefined,
    totalInvestmentOrBasisAmt: undefined,
  };
  stateValue: string = '';
  StateOptions: any[] = [{
    data: []
  }];

  constructor(private formBuilder: FormBuilder,
    private dstSharedService: DSTSharedService,
    private sharedService: CreateDisclosureSharedService,
    private attachedService: TemplateAttachedService) {

    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
  }

  ngOnInit(): void {
    this.form235 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    this.startLoader = true;
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
    });

    //load Stmt data
    this.sharedService.getLoadStatementData(235)
      .subscribe((response: StmtData) => {
        this.stmtData = response;

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {
            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            this.attMap[stmt.attributeName]?.push('');
          }
        });

        if (this.stmtData.attachmentpath && this.stmtData.attachmentname) {
          this.attachedService.getDSTPresignedUrl(this.stmtData.attachmentpath, this.stmtData.attachmentname)
          .subscribe((downloadUrl: string) => {
            this.stmtData.downloadUrl = downloadUrl;
            this.sharedService.setFormData(this.stmtData);
          }, (e: Error) => {
            this.dstSharedService.showAlert('error', e.message);
          });
        }

        this.loadData();

      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      });

  }

  loadData(): void {
    //Section 1
    //A
    if (this.attMap['StatementNumber'] !== undefined && this.attMap['StatementNumber'][0] !== '') {
      this.formModel.statementNumber = this.attMap['StatementNumber'][0];
    }
    if (this.attMap['TotalStatementNumber'] !== undefined && this.attMap['TotalStatementNumber'][0] !== '') {
      this.formModel.totalStatementNumber = this.attMap['TotalStatementNumber'][0];
    }

    //B
    if (this.attMap['FormNumberOfTaxReturn'] !== undefined && this.attMap['FormNumberOfTaxReturn'][0] !== '') {
      this.formModel.formNumberOfTaxReturn = this.attMap['FormNumberOfTaxReturn'][0];
    }
    if (this.attMap['ForTaxYear'] !== undefined && this.attMap['ForTaxYear'][0] !== '') {
      this.formModel.forTaxYear = this.attMap['ForTaxYear'][0];
    }
    if (this.attMap['AttachedToAmendedReturn'] !== undefined && this.attMap['AttachedToAmendedReturn'][0] !== '') {
      this.formModel.attachedToAmendedReturn = this.attMap['AttachedToAmendedReturn'][0] === '1' ? true : (this.attMap['AttachedToAmendedReturn'][0] === '0' ? false : undefined);
    }

    //C
    if (this.attMap['InitialYearFiler'] !== undefined && this.attMap['InitialYearFiler'][0] !== '') {
      this.formModel.initialYearFiler = this.attMap['InitialYearFiler'][0] === '1' ? true: false;
    }
    if (this.attMap['ProtectiveDisclosure'] !== undefined && this.attMap['ProtectiveDisclosure'][0] !== '') {
      this.formModel.protectiveDisclosure = this.attMap['ProtectiveDisclosure'][0] === '1' ? true: false;
    }

    //Section 2
    //1b
    if (this.attMap['ReportableTransactionInfoInitialYearParticipated'] !== undefined && this.attMap['ReportableTransactionInfoInitialYearParticipated'][0] !== '') {
      this.formModel.reportableTransactionInfoInitialYearParticipated = this.attMap['ReportableTransactionInfoInitialYearParticipated'][0];
    }
    //1c
    if (this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'] !== undefined && this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'][0] !== '') {
      this.formModel.reportableTransactionInfoTransactionOrTaxShelterNumber = this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'][0];
    }

    //Section 3
    //2
    if (this.attMap['Listed'] !== undefined && this.attMap['Listed'][0] !== '') {
      this.formModel.listed = this.attMap['Listed'][0] === '1' ? true : false;
    }
    if (this.attMap['Confidential'] !== undefined && this.attMap['Confidential'][0] !== '') {
      this.formModel.confidential = this.attMap['Confidential'][0] === '1' ? true : false;
    }
    if (this.attMap['ContractualProtection'] !== undefined && this.attMap['ContractualProtection'][0] !== '') {
      this.formModel.contractualProtection = this.attMap['ContractualProtection'][0] === '1' ? true : false;
    }
    if (this.attMap['TransactionOfInterest'] !== undefined && this.attMap['TransactionOfInterest'][0] !== '') {
      this.formModel.transactionOfInterest = this.attMap['TransactionOfInterest'][0] === '1' ? true : false;
    }
    if (this.attMap['Loss'] !== undefined && this.attMap['Loss'][0] !== '') {
      this.formModel.loss = this.attMap['Loss'][0] === '1' ? true : false;
    }

    //3
    if (this.attMap['PublishedGuidanceNumber'] !== undefined && this.attMap['PublishedGuidanceNumber'][0] !== '') {
      this.formModel.publishedGuidanceNumber = this.attMap['PublishedGuidanceNumber'][0];
    }
    //4
    if (this.attMap['NumberOfSameOrSimilarTr'] !== undefined && this.attMap['NumberOfSameOrSimilarTr'][0] !== '') {
      this.formModel.numberOfSameOrSimilarTr = this.attMap['NumberOfSameOrSimilarTr'][0];
    }

    //Section 6
    //7a
    if (this.attMap['Deductions'] !== undefined && this.attMap['Deductions'][0] !== '') {
      this.formModel.deductions = this.attMap['Deductions'][0] === '1' ? true : false;
    }
    if (this.attMap['ExclusionsFromGrossIncome'] !== undefined && this.attMap['ExclusionsFromGrossIncome'][0] !== '') {
      this.formModel.exclusionsFromGrossIncome = this.attMap['ExclusionsFromGrossIncome'][0] === '1' ? true : false;
    }
    if (this.attMap['CapitalLoss'] !== undefined && this.attMap['CapitalLoss'][0] !== '') {
      this.formModel.capitalLoss = this.attMap['CapitalLoss'][0] === '1' ? true : false;
    }
    if (this.attMap['NonrecognitionOfGain'] !== undefined && this.attMap['NonrecognitionOfGain'][0] !== '') {
      this.formModel.nonrecognitionOfGain = this.attMap['NonrecognitionOfGain'][0] === '1' ? true : false;
    }
    if (this.attMap['OrdinaryLoss'] !== undefined && this.attMap['OrdinaryLoss'][0] !== '') {
      this.formModel.ordinaryLoss = this.attMap['OrdinaryLoss'][0] === '1' ? true : false;
    }
    if (this.attMap['AdjustmentsToBasis'] !== undefined && this.attMap['AdjustmentsToBasis'][0] !== '') {
      this.formModel.adjustmentsToBasis = this.attMap['AdjustmentsToBasis'][0] === '1' ? true : false;
    }
    if (this.attMap['AbsenceOfAdjustmentsToBasis'] !== undefined && this.attMap['AbsenceOfAdjustmentsToBasis'][0] !== '') {
      this.formModel.absenceOfAdjustmentsToBasis = this.attMap['AbsenceOfAdjustmentsToBasis'][0] === '1' ? true : false;
    }
    if (this.attMap['TaxCredits'] !== undefined && this.attMap['TaxCredits'][0] !== '') {
      this.formModel.taxCredits = this.attMap['TaxCredits'][0] === '1' ? true : false;
    }
    if (this.attMap['Deferral'] !== undefined && this.attMap['Deferral'][0] !== '') {
      this.formModel.deferral = this.attMap['Deferral'][0] === '1' ? true : false;
    }
    if (this.attMap['Other'] !== undefined && this.attMap['Other'][0] !== '') {
      this.formModel.line7aOther = this.attMap['Other'][0] === '1' ? true : false;
    }
    if (this.attMap['OtherText'] !== undefined && this.attMap['OtherText'][0] !== '') {
      this.formModel.line7aOtherText = this.attMap['OtherText'][0];
    }

    //7b
    if (this.attMap['TotalTaxBenefitAmt'] !== undefined && this.attMap['TotalTaxBenefitAmt'][0] !== '') {
      this.formModel.totalTaxBenefitAmt = this.attMap['TotalTaxBenefitAmt'][0];
    }

    //7c
    if (this.attMap['TaxBnftAnticipatedYrCnt'] !== undefined && this.attMap['TaxBnftAnticipatedYrCnt'][0] !== '') {
      this.formModel.taxBnftAnticipatedYrCnt = this.attMap['TaxBnftAnticipatedYrCnt'][0];
    }

    //7d
    if (this.attMap['TotalInvestmentOrBasisAmt'] !== undefined && this.attMap['TotalInvestmentOrBasisAmt'][0] !== '') {
      this.formModel.totalInvestmentOrBasisAmt = this.attMap['TotalInvestmentOrBasisAmt'][0];
    }

    this.StateOptions[0].data.forEach((val: any) => {

      if (val.value == this.stmtData.primarystate) {
        this.stateValue = val.text;
      }
    });

    if ((this.attMap['IdentifyAllInvolvedInTrTaxExempt'])) {
      
      this.initPart8Entty();
      this.attMap['IdentifyAllInvolvedInTrTaxExempt'].forEach((element: string, index: number) => {
        if ((this.attMap['InGOLD8'] !== undefined) && (this.attMap['InGOLD8'][index] === '1')) {
          // (index == 0) && 
          this.getDSTEntityInfo(this.attMap['IdentifyAllInvolvedInTrEntityId'][index], index);
        }else{
          this.addEmptyPart8Entity();//if not gold then insert empty entry
        }
      });
    }
  }

  getDSTEntityInfo(entity: any, index: number): void {
    if (!isNaN(entity)) {
      this.sharedService.getDSTEntityInfo(entity)
        .subscribe((data: any[]) => {
          data.forEach(element => {
            this.savePart8EntityInfo(element, index);
          });
        }, () => {

        }, () => {

        });
    } else {
      this.sharedService.getEntityList(entity, false)
        .subscribe((data: DSTEntityDetails[]) => {
          data.forEach(element => {
            this.savePart8EntityInfo(element, index);
          });
        }, () => {

        }, () => {

        });
    }
  }

  initPart8Entty(): void {
    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine1'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine2'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityCity'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityState'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityCountry'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityZIPCode'] = [];
    this.attMap['IdentifyAllInvolvedInTrEntityUSEntity'] = [];
  }

  addEmptyPart8Entity(): void {
    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine1'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine2'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityCity'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityState'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityCountry'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityZIPCode'].push('');
    this.attMap['IdentifyAllInvolvedInTrEntityUSEntity'].push('');
  }

  savePart8EntityInfo(element: any, index: number): void {

    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine1']?.splice(index, 0, element.ADDRESS1 || '')
    this.attMap['IdentifyAllInvolvedInTrEntityAddressLine2']?.splice(index, 0, element.ADDRESS2 || '');
    this.attMap['IdentifyAllInvolvedInTrEntityCity']?.splice(index, 0, element.CITY || '');
    if (element.STATE) {
      const selectedState: any = this.StateOptions[0].data.find((s: any) => s.value === element.STATE);
      this.attMap['IdentifyAllInvolvedInTrEntityState']?.splice(index, 0, selectedState ? selectedState.text : '');
    } else {
      this.attMap['IdentifyAllInvolvedInTrEntityState']?.splice(index, 0, '');
    }
    this.attMap['IdentifyAllInvolvedInTrEntityCountry']?.splice(index, 0, element.COUNTRYNAME || '');
    this.attMap['IdentifyAllInvolvedInTrEntityZIPCode']?.splice(index, 0, element.ZIP || '');
    this.attMap['IdentifyAllInvolvedInTrEntityUSEntity']?.splice(index, 0, (element.COUNTRY === 'US'));
  }

  download(): void {
    this.attachedService.getDSTPresignedUrl(this.stmtData.attachmentpath!, this.stmtData.attachmentname!)
      .subscribe((presignedS3Url: string) => {
        window.open(presignedS3Url, '_blank');
      });
  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
