<mat-progress-bar *ngIf = "autoRunDataLoad"
color="primary"
mode="indeterminate">
</mat-progress-bar>

<div class="modal-body" *ngIf = "!autoRunDataLoad">
    <div class="panel-body" >   
                <table *ngIf="scenarioDesc !== 'RAF'">
                    <tr> <div style="margin-right: 5px">Issue Id:</div> 
                        <td>
                            <div class="a-m-10">
                                <ap-selector class="m-l-10 issueIdDropdown" [items]="issueIdList" [type]="'Dropdown'" [disabled] = "isEditFlag" [placeholder]="'Select Issue Id'" (onSelectedChange)="setIssueId($event,0);"></ap-selector>
                            </div>
                        </td>
                        <td *ngIf = "issueIdCheckFlag">
                           <div> 
                               <span class="noRecords">
                                     Please select Issue Id
                               </span>
                            </div> 
                        </td> 
                    </tr> 	 				
                </table>		
        <div>
            <table class="table table-striped blue" style="text-align: left; width: 100%;" >
            <thead>
                    <tr>
                        <th colspan="11" style="text-align: center;" >Supplemental Info Details</th>
                    </tr>
            </thead>
            <tbody>		
                    <tr style=" border: 1px solid #ddd;padding: 4px;" >						
                        <td>Illegal Payments </td>						
                        <td>
                            <div class="text-center a-m-10">
                                <input type = "checkbox" [(ngModel)]="illegalPayments" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Sanctioned Country  </td>
                        <td>
                            <div class="text-center a-m-10">
                                <input type = "checkbox" [(ngModel)]="sanctionedCountry"  [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Purchase Inv US Related  </td>
                        <td>
                            <div class="a-m-10">
                                <input type = "number" [(ngModel)]="PurInvUsRel" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Purchase Inv Non US Related  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="PurInvNonUsRel" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>											
                    </tr>
                    <tr>
                        <td *ngIf="isEditFlag">Cost Of Sales  </td>
                        <td *ngIf="isEditFlag">
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="CostSales" style="text-align: right !important;" [disabled]="isEditFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td *ngIf="isEditFlag"></td>						
                        <td>Wages Cost Of Sales  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="WageCostSales" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Sales Tangible Prop US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="SalesTangPropUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Sales Tangible Prop Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="SalesTangPropNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>											
                    </tr>
                    <tr>						
                        <td>Purchase Tangible Prop US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="PurTangPropUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Purchase Tangible Prop Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="PurTangPropNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Buy In Receive US  </td>
                        <td>
                             <div class="a-p-20">
                                    <input type = "number" [(ngModel)]="BuyInRecveUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                             </div>
                        </td>
                        <td></td>
                        <td>Buy In Receive Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="BuyInRecveNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>												
                    </tr>
                    <tr>						
                        <td>Buy In Paid US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="BuyInPaidUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Buy In Paid Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="BuyInPaidNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Cost Sharing Receive US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="CostSharRecveUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Cost Sharing Receive Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="CostSharRecveNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>											
                    </tr>
                    <tr>						
                        <td>Cost Sharing Paid US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="CostSharPaidUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td>Cost Sharing Paid Non US  </td>
                        <td>
                            <div class="a-p-20">
                                <input type = "number" [(ngModel)]="CostSharPaidNonUs" style="text-align: right !important;" [disabled]="rafDataFlag" (change) = "getTestData();">
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>											
                    </tr>	
                </tbody>			
            </table>
        </div>
        <mat-progress-bar *ngIf = "!tableLoadingData"
        color="primary"
        mode="indeterminate">
        </mat-progress-bar>
        <div class="modal-body no-body-scroll" *ngIf="tableData">
            <div class="divShadow1">  
                    <div class="table table-hover table-bordered table-striped table-condensed" style="border:none;">
                        <ap-table #basicTable [data]="SavedData" [showPage]="false" [pageSize]="SavedData.length" [striped]="true">
                            <thead style="white-space:nowrap;">
                                <tr>
                                    <th>Delete</th>
                                    <th>Edit</th>
                                    <th rowspan="2" style="width: 5%;" *ngIf="scenarioDesc !== 'RAF'">Issue Id</th>
                                    <th rowspan="2" style="width: 5%;" >Illegal Payments</th>
                                    <th rowspan="2" style="width: 5%;" >Sanctioned Country</th>
                                    <th rowspan="2" style="width: 6%;" >Purchase Inv US Related</th>
                                    <th rowspan="2" style="width: 6%;" >Purchase Inv Non US Related</th>
                                    <th rowspan="2" style="width: 6%;" >Cost Of Sales</th>
                                    <th rowspan="2" style="width: 6%;" >Wages Cost Of Sales</th>
                                    <th rowspan="2" style="width: 6%;" >Sales Tangible Prop US</th>
                                    <th rowspan="2" style="width: 5%;" >Sales Tangible Prop Non US</th>
                                    <th rowspan="2" style="width: 5%;" >Purchase Tangible Prop US</th>
                                    <th rowspan="2" style="width: 5%;" >Purchase Tangible Prop Non US</th>
                                    <th rowspan="2" style="width: 5%;" >Buy In Receive US</th>
                                    <th rowspan="2" style="width: 5%;" >Buy In Receive Non US</th>
                                    <th rowspan="2" style="width: 5%;" >Buy In Paid US</th>
                                    <th rowspan="2" style="width: 5%;" >Buy In Paid Non US</th>
                                    <th rowspan="2" style="width: 5%;" >Cost Sharing Receive US</th>
                                    <th rowspan="2" style="width: 5%;" >Cost Sharing Receive Non US</th>
                                    <th rowspan="2" style="width: 5%;" >Cost Sharing Paid US</th>
                                    <th rowspan="2" style="width: 5%;" >Cost Sharing Paid Non US</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of basicTable.data; let i = index;" style=" border: 1px solid #ddd;padding: 4px; background-color: white;">
                                    <td >
                                        <ap-button class = "actioniconssuppl" [config]="{isPrimary: true}" [btnSize]="'small'" [btnType]="'a-btn-white'"  [isDisabled]="scenarioDesc !== 'RAF' && item.ISSUE_KEY === 0" (onBtnClick)="btnClickDelete(item)">
                                            <span class="appkiticon icon-circle-minus-fill"></span>
                                        </ap-button>
                                    </td>
                                    <td >
                                        <ap-button class = "actioniconssuppl" [config]="{isPrimary: true}" [btnSize]="'small'" [btnType]="'a-btn-white'"  [isDisabled]="scenarioDesc !== 'RAF' && item.ISSUE_KEY === 0" (onBtnClick)="btnClickEdit(item)">
                                            <span class="appkiticon icon-edit-fill"></span>
                                        </ap-button>
                                    </td>
                                    <td *ngIf="scenarioDesc !== 'RAF'">{{item.ISSUE_KEY}}</td>
                                    <td >{{item.ILLEGAL_PAYMENTS}}</td>
                                    <td>{{item.SANCTIONED_COUNTRY}}</td>
                                    <td class="amountALign">{{item.PURCHASE_INV_US_RELATED |number}}</td>
                                    <td class="amountALign">{{item.PURCHASE_INV_NONUS_RELATED | number}}</td>
                                    <td class="amountALign">{{item.COST_OF_SALES | number}}</td>
                                    <td class="amountALign">{{item.WAGES_COST_OF_SALES | number}}</td>
                                    <td class="amountALign">{{item.SALES_TANGIBLE_PROP_US | number}}</td>
                                    <td class="amountALign">{{item.SALES_TANGIBLE_PROP_NONUS | number}}</td>
                                    <td class="amountALign">{{item.PURCHASE_TANGIBLE_PROP_US | number}}</td>
                                    <td class="amountALign">{{item.PURCHASE_TANGIBLE_PROP_NONUS | number}}</td>
                                    <td class="amountALign">{{item.BUY_IN_RECEIVE_US | number}}</td>
                                    <td class="amountALign">{{item.BUY_IN_RECEIVE_NONUS | number}}</td>
                                    <td class="amountALign">{{item.BUY_IN_PAID_US | number}}</td>
                                    <td class="amountALign">{{item.BUY_IN_PAID_NONUS | number}}</td>
                                    <td class="amountALign">{{item.COST_SHARING_RECEIVE_US | number}}</td>
                                    <td class="amountALign">{{item.COST_SHARING_RECEIVE_NONUS | number}}</td>
                                    <td class="amountALign">{{item.COST_SHARING_PAID_US | number}}</td>
                                    <td class="amountALign">{{item.COST_SHARING_PAID_NONUS | number}}</td>
                                </tr>
                            </tbody>
                        </ap-table> 
                    </div>	
           </div>
        </div>

        <div>
            <div class="col-md-4 col-md-offset-10" >
                <div>
                    <ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="saveData()"></ap-button>
                    <ap-button [btnText]="'Reset'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="resetData()"></ap-button>
                    <ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="modalClose()"></ap-button>
                </div>  
            </div>
        </div>
    </div>
</div>	