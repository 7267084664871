import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import _ from 'lodash';
import { environment } from 'projects/gtw-elements/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Form8082Service {

    environment: any = {};
    chartKey!: string;
    userSettings: any;
    logged_in_user: any;
    formConfigData: any;

    constructor(private http: HttpClient) {
        this.environment = environment;

    }

    url: any = {
        getForm8082Data: '/loadJsonObject?action_code=ooqpoc',
        save: '/saveJsonObject?action_code=oaiqco',
        getDropdown: '/loadJsonObject?action_code=uj4ad5',
        getMultiRowData: '/loadJsonObject?action_code=6fohvf',

    };

    fetchF8082Details(baseURL: string, data: any) {
        let jcd_key = data.JCD_KEY === undefined ? 250 : data.JCD_KEY;
        let params = {
            "tax_year": data.TAX_YEAR,
            "scenario": data.SCENARIO,
            "jcd_key": jcd_key,
            "combination_key": data.COMBINATION_KEY,
        };
        

        return this.http.post(`${baseURL}${this.url.getForm8082Data}`, this.toFormData(params));
    }

    fetchF8082MultiRowDataDetails(baseURL: string, params: any) {
        params.jcd_key = params.JCD_KEY === undefined ? 250 : params.JCD_KEY;
        
        return this.http.post(`${baseURL}${this.url.getMultiRowData}`, this.toFormData(params));
    }

    fetchF8082Dropdown(baseURL: string, data: any) {
        let jcd_key = data.jcd_key === undefined ? 250 : data.jcd_key;
        let params = {
            "tax_year": data.tax_year,
            "scenario": data.scenario,
            "jcd_key": jcd_key,
            "sso_id": data.sso_id,
        };
        

        return this.http.post(`${baseURL}${this.url.getDropdown}`, this.toFormData(params));
    }

    saveF8082Details(baseURL: string, data: any) {
        return this.http.post(`${baseURL}${this.url.save}`, this.toFormData(data));
    }
    toFormData(obj: { [k: string]: any }): FormData {
        let formData = new FormData();
        for (let key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
    }
}
