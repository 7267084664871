import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { SourcingDefaultMeService } from './sourcing-default-me.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";

@Component({
  selector: 'gtw-sourcing-default-me',
  templateUrl: './sourcing-default-me.component.html',
  styleUrls: ['./sourcing-default-me.component.scss']
})
export class SourcingDefaultMeComponent implements OnInit {
 
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private filingKey :number = 0;
  private acctKey :number = 0;
  private ssoId: string = '';
  acctType:string = '';
  private parentMeKey :number = 0;
  private scenarioCode: string = '';
  private checkLockedObj: any = [];
  basket_id_value: any;


  

  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }


  @Input('tax-year')
  set _taxYear(taxYear:number){
  try{
		this.taxYear = taxYear;
		}catch(error){
		console.log("Not an valid object");
		}
  }


  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input("acct-type")
  set _acctType(acctType:string) {
    this.acctType = acctType;
    this.init();
  }

  @Input('filing-key')
  set _filingKey(filingKey:number){
		this.filingKey = filingKey;	
    this.init();	
  }

  @Input('screen-key')
  set _screenKey(screenKey:number) {
    this.screenKey = screenKey;
    this.init();
  }


  @Input('parent-me-key')
  set _parentKey(parentKey:number) {
    this.parentMeKey = parentKey;
    this.init();
  }
  
  @Input('acct-key')
  set _acctKey(acctKey:number) {
    this.acctKey = acctKey;
    this.init();
  }


  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    this.init();
    }catch(error){
		console.log("Not an valid object");
		}
  }

  

  @Output('open-sourcing-info')
  openSourcingInfo = new EventEmitter<string>();

  @Output('save-sourcing-info')
  saveSourcingInfo = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;


  constructor( private SourcingDefaultMeService:SourcingDefaultMeService ,private _snackBar: MatSnackBar) { }

  SavedData : any = [];
  basketIdList : any = [{	data: []}]; 
  baseIdList : any = [{data: []}];
  selectedBasketid  : any = [];
  selectedBaseid  : any = [];
  scenarioDesc: string = '';  
	durationInSeconds= 5;
	CheckBasketFlag = false;
  BasketCheck: any = [];
  BaseCheck:any = [];
  tempGroupData : any = { };
  isResetBasketFlag = false;
  isResetBaseFlag = false;

  checkInputType():boolean{
		if(Number.isInteger(parseInt(this.taxYear+"")) &&
		  Number.isInteger(parseInt(this.scenario+'')) &&
		  Number.isInteger(parseInt(this.jcdKey +'')) &&
		  Number.isInteger(parseInt(this.screenKey+'')) &&
		  Number.isInteger(parseInt(this.ssoId+""))&&
      Number.isInteger(parseInt(this.parentMeKey+"")) &&
      Number.isInteger(parseInt(this.filingKey+""))&&
      Number.isInteger(parseInt(this.acctKey+""))&&
      Number.isInteger(parseInt(this.acctType+""))
		  )
		{
		  return true;
		}
	
		return false;
	}


  ngOnInit(): void {
  }

  init() {   
    if(this.checkInputType()){
			this.getBaksetIdData();
     // this.getBaseIdData();
     // this.getModalSaveData();
      }
    } 


  modalClose() {
      console.log("click close");
      this.openSourcingInfo.emit('open-sourcing-info');
      }
   
    setBasketId(group : any){
        if(this.isResetBasketFlag == true){
          for(let i = 0; i < this.basketIdList[0].data.length; i++){
             var selectedObjIndex= _.findIndex(this.basketIdList[0].data, { 'selected': true});
             if(i == selectedObjIndex ){
              this.basketIdList[0].data[i].selected = false;
              this.isResetBasketFlag = false;
              this.CheckBasketFlag = false;
             }
            }            
          }else{
          this.selectedBasketid = group.selected[0].value;
          this.CheckBasketFlag = false;
        }
        console.log(this.basketIdList);
      }

    setBaseId(group : any){
      if(this.isResetBaseFlag == true){
        for(let i = 0; i < this.baseIdList[0].data.length; i++){
          var selectedBaseIndex= _.findIndex(this.baseIdList[0].data, { 'selected': true});
          if(i == selectedBaseIndex ){
            this.baseIdList[0].data[i].selected = false;
            this.isResetBaseFlag = false;
            this.CheckBasketFlag = false;
            }
          }
        }else{
          this.selectedBaseid = group.selected[0].value;
          this.CheckBasketFlag = false;
           }
           console.log(this.baseIdList);
      }


      getBaksetIdData(){
        this.basketIdList = [{ data: [] }];
        this.SourcingDefaultMeService.getBaksetIdData(this.baseURLs.api, this.baseGlobalParams,this.ssoId).subscribe((data: any) => {
          if (data) {
            let tempIssueId : any = [];
            data.forEach( (value : any, i: any) => {
                let tempChannel = {
                  value: value.BASKET_ID,
				          index: i,
                  text: value.BASKET_NAME,
                  selected:false
                }
                tempIssueId.push(tempChannel);
            });
            this.basketIdList[0].data = tempIssueId;
            console.log(this.basketIdList);
            this.getBaseIdData();
          }
        },error => {
			console.log(error);
		});
    
        
      }  


      getBaseIdData(){
        this.baseIdList[0].data = [];
        this.SourcingDefaultMeService.getBaseIdData(this.baseURLs.api, this.baseGlobalParams).subscribe((data: any) => {
          if (data) {
            //let tempBaseId : any = [];
            data.forEach( (dataValue : any, i: any) => {
				    this.baseIdList[0].data.push(
				      {
                  value: dataValue.BASE_ID,
				          index: i,
                  text: dataValue.BASE_NAME,
                  selected:false
                }
				);
                
            });
           // this.baseIdList[0].data[0].selected =  true;
            console.log(this.baseIdList);
            this.getModalSaveData();
          }
        },error => {
          console.log(error);
        });
    }  


      getModalSaveData(){
        if(this.SavedData.length == 0 ) {
          this.SourcingDefaultMeService.getSourcingModalData(this.baseURLs.api, this.baseGlobalParams, this.acctKey,this.parentMeKey,this.filingKey).subscribe((data: any) => {
            if (data.length > 0) {
              console.log(data);
              this.SavedData = data;
              ///this.getModalPopulateData();

        this.basket_id_value = this.SavedData[0].BASKET_ID;
        var base_id_value = this.SavedData[0].BASE_ID;

        if(this.SavedData[0].BASKET_ID !== 0 ){
          var selectedbasketIndex= _.findIndex(this.basketIdList[0].data, { value: this.basket_id_value});
          this.basketIdList[0].data[selectedbasketIndex].selected = true;
          this.basketIdList = JSON.parse(JSON.stringify(this.basketIdList));
          this.selectedBasketid = this.basketIdList[0].data[selectedbasketIndex].value;


        }
          if (this.SavedData[0].BASE_ID !==0){        
            var selectedbaseIndex= _.findIndex(this.baseIdList[0].data, { value: base_id_value});
          this.baseIdList[0].data[selectedbaseIndex].selected = true;
          this.baseIdList = JSON.parse(JSON.stringify(this.baseIdList));
          this.selectedBaseid = this.baseIdList[0].data[selectedbaseIndex].value;
          }  
            }
          },
            (error: any) => {
            console.log(error);
          }, () => {
            console.log('Completed loading');
          });
        }
      }

      getModalPopulateData(){
        
      }
      


  saveData(){ 
    this.BasketCheck  = this.basketIdList[0].data[_.findIndex(this.basketIdList[0].data, { 'selected': true})] == undefined ? 0 :this.basketIdList[0].data[_.findIndex(this.basketIdList[0].data, { 'selected': true})].value ;
    this.BaseCheck = this.baseIdList[0].data[_.findIndex(this.baseIdList[0].data, { 'selected': true})] == undefined ?0 : this.baseIdList[0].data[_.findIndex(this.baseIdList[0].data, { 'selected': true})].value;


    if(this.BasketCheck == 0 && this.BaseCheck ==0){
       this.CheckBasketFlag = true;
          return;
        }else{
          this.CheckBasketFlag = false;
        };

    

   let tempData = {
     TAX_YEAR : this.baseGlobalParams?.tax_year,
     SCENARIO : this.baseGlobalParams?.scenario,
     JCD_KEY : this.baseGlobalParams?.jcd_key,
     SSO_ID: this.ssoId,
     ME_KEY:this.parentMeKey,
     ACCT_KEY:this.acctKey,
     FILING_KEY:this.filingKey,
     BASKET_ID : this.BasketCheck,//this.basketIdList[0].data[_.findIndex(this.basketIdList[0].data, { 'selected': true})].value ,
     BASE_ID: this.BaseCheck//this.baseIdList[0].data[_.findIndex(this.baseIdList[0].data, { 'selected': true})].value 
   }

  
   let updateSubscription = this.SourcingDefaultMeService.saveSourcingInfoData(this.baseURLs.api, tempData, this.baseGlobalParams, this.checkLockedObj).subscribe(
         (data) => {
           if (data.callSuccess == "1") {
             this.SourcingDefaultMeService.openSnackBar('Data Saved Successfully','gtw-snackbar--green');
             this.saveSourcingInfo.emit('save-sourcing-info');
           } else {
             this.SourcingDefaultMeService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
           }
         },  error => { this.SourcingDefaultMeService.openSnackBar('Entity Chart update failed. '+error.error.errorMessage, 'gtw-snackbar--red'); },
         () => { console.log("Update Completed");}
       );
         this.subscriptions.add(updateSubscription);
 };


 resetData(){
  this.isResetBasketFlag = true;
  this.isResetBaseFlag = true;
  this.CheckBasketFlag = false;
  this.setBasketId(this.tempGroupData);
  this.setBaseId(this.tempGroupData);
}
 

}

