<div class="main-container">
  <div class="app-nav">
    <ul>
      <li><a style="background-color: #c25423" (click)="showTracker()">Statement Tracker</a></li>
      <li class="separator"><span class="appkiticon icon-vertical-more-outline"></span></li>
      <li class="tabs" [ngClass]="{'disabled': tableDataLoading}" *ngIf="createNewTabIsOpen">
        <a [ngClass]="{'active': activeTab?.id === idCreateNew}"
           (click)="addTabOrNavigate(idCreateNew)">
          Create New
          <span class="appkiticon icon-close-outline" (click)="closeTab(idCreateNew)"></span>
        </a>
      </li>
      <li class="tabs" [ngClass]="{'disabled': tableDataLoading}" *ngFor="let tab of tabs">
        <a [ngClass]="{'active': tab.id === activeTab?.id}"
           (click)="addTabOrNavigate(tab.id)">
          {{ tab.id }}
          <span class="appkiticon icon-close-outline" (click)="closeTab(tab.id)"></span>
        </a>
      </li>
    </ul>
  </div>
  <div class="grid-container" id="p-grid-container" [ngStyle]="{'display': !activeTab ? 'block' : 'none'}">
    <div class="top-toolbar">
      <div class="float-left">
        <span class="appkiticon icon-refresh-outline"></span>
        <ap-selector [items]="taxYears" [type]="'Dropdown'" (onSelectedChange)="onTaxYearChanged($event)" style="margin-right: 5px;"></ap-selector>
        <gtw-df-me-dropdown class="top-filter-select" [customActionCode]="32136" (selectedMes)="selectedMeChanged($event)"></gtw-df-me-dropdown>
        <ap-button [btnText]="'Filter'" class="a-mr-10" [config]="{isPrimary: true}" (onBtnClick)="refresh(true)"></ap-button>
      </div>
      <div class="float-right">
        <ap-button class="a-mr-10" [config]="{isPrimary: true}" [isDisabled]="dstSharedService.user.roleLevel < 2" (onBtnClick)="createDisclosureNew()"
          *ngIf="!(dstSharedService.isSystemLock && (dstSharedService.user.roleLevel <= 3))">
          <span class="appkiticon a-btn-icon icon-plus-fill"></span>Create New
        </ap-button>
        <a href="#/custom-report" style="text-decoration: none;">
          <ap-button class="a-mr-10" [config]="{isPrimary: true}">
            <span class="appkiticon a-btn-icon icon-platform-outline"></span>View All
          </ap-button>
        </a>
        <ap-search [isPrimary]="true" [isBase]="true" (onKeyUp)="searchg($event)"
                   placeholder=" ">
        </ap-search>
      </div>
    </div>
    <div class="grid-toolbar">
      <div class="float-left">
        <span class="appkiticon icon-view-outline"></span>
        <span class="appkiticon icon-down-chevron-outline"></span>
        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">
          &nbsp;Export to Excel
          <span class="ml-1" *ngIf="exportingExcel"> <i class="fa fa-spinner fa-spin"></i> </span>
        </span>
        <span class="appkiticon icon-openinnewwindow-outline"></span>
        <span class="appkiticon icon-refresh-outline" (click)="resetColumnFilters()">&nbsp;Clear Grid Filters</span>
      </div>
      <div class="float-right" style="display: flex;">
      <span *ngIf="tableActionStatus.inProgress">
        <ap-loading [colorName]="'a-dark'" [size]="'14px'"></ap-loading>
      </span>
        <span>Total records: {{totalRecords}}</span>
      </div>
    </div>
    <p-table class="dst-prime-grid" #dt [columns]="cols" [value]="tableData"
             [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll" (onSort)="ddRow && hideStmtIdButtonDropdown()"
             [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-gridlines p-datatable-striped"
             [virtualScroll]="true" [virtualRowHeight]="70" frozenWidth="450px" [frozenColumns]="frozenCols"
             [loading]="tableDataLoading" (onFilter)="handleFilter($event)" [globalFilterFields]="['statementId','leId', 'entityName',
             'fiscalEnd','TAXYEAR','businessName', 'disclosureShortName', 'creationSource', 'gtw_source_desc', 'associatedStatements',
             'projectName', 'projectStepName', 'PROJECTBSLACODE', 'dcnName', 'createdBy', 'createdOnFormatted', 'modifiedBy', 'modifiedFormatted']">
      <ng-template pTemplate="frozenheader" let-frozenColumns>
          <tr [style.height]="'45px'">
            <th *ngFor="let col of frozenColumns; index as i" pResizableColumn [pSortableColumn]="col.field" [width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
          <tr [style.height]="'45px'">
            <th>
              <input pInputText type="text" class="p-column-filter" [(ngModel)]="frozenCols[0].filterUserInput" (input)="filterCol($event, 'statementId', 'contains')">
            </th>
            <th>
              <p-multiSelect styleClass="p-column-filter" optionLabel="val" placeholder="All"
                [virtualScroll]="true" [itemSize]="40"
                [options]="getFilterOptionByID('leid')" [appendTo]="'body'"
                [(ngModel)]="frozenCols[1].filterUserInput" (onChange)="filterLeidCol($event, 'leId', ' ')">
                <ng-template let-option pTemplate="item">
                  <div class="p-multiselect-tax-year-option">
                    <span class="p-ml-1">{{option.label}}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="frozenbody" let-rowData>
          <tr style="height: 70px;">
            <td style="overflow: visible; width: 150px;">
              <dst-stmt-id-btn [mode]="'statementTracker'" [gridData]="tableData" [rowData]="rowData" [markAsOptions]="markAsOptions"
                               (actionClick)="onActionClicked($event)">
              </dst-stmt-id-btn>
            </td>
            <td style="width: 300px;">
              <span class="text-color-primary">{{rowData.leId}}</span><br>
              <span>{{rowData.entityName}}</span><br>
              <span>{{rowData.fiscalEnd ? 'Year End: ' + rowData.fiscalEnd : ''}}</span>
            </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'45px'">
          <th *ngFor="let col of columns; index as i" pResizableColumn [pSortableColumn]="col.field" [width]="col.width">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr [style.height]="'45px'">
          <th>
            <p-multiSelect styleClass="p-column-filter" optionLabel="val" placeholder="All"
                           [options]="getFilterOptionByID('tax_year')" [appendTo]="'body'"
                           [(ngModel)]="cols[0].filterUserInput" (onChange)="filterMultiSelectCol($event, 'TAXYEAR')">
              <ng-template let-option pTemplate="item">
                <div class="p-multiselect-tax-year-option">
                  <span class="p-ml-1">{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </th>
          <th>
            <input pInputText type="text" class="p-column-filter" [(ngModel)]="cols[1].filterUserInput" (input)="filterCol($event, 'businessName', 'contains')">
          </th>
          <th style="overflow: visible">
            <p-multiSelect styleClass="p-column-filter" optionLabel="val" placeholder="All"
                           [options]="getFilterOptionByID('disclosure')" [appendTo]="'body'"
                           [(ngModel)]="cols[2].filterUserInput" (onChange)="filterMultiSelectCol($event, 'disclosureShortName')">
              <ng-template let-option pTemplate="item">
                <div class="p-multiselect-tax-year-option">
                  <span class="p-ml-1">{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </th>
          <th>
            <p-multiSelect styleClass="p-column-filter" optionLabel="val" placeholder="All"
                           [options]="getFilterOptionByID('creationSrc')" [appendTo]="'body'"
                           [(ngModel)]="cols[3].filterUserInput" (onChange)="filterMultiSelectCol($event, 'creationSource')">
              <ng-template let-option pTemplate="item">
                <div class="p-multiselect-tax-year-option">
                  <span class="p-ml-1">{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </th>
          <th>
            <button pButton type="button" label="All" class="p-column-filter-dummy"></button>
          </th>
          <th>
            <button pButton type="button" label="All" class="p-column-filter-dummy"></button>
          </th>
          <th>
            <p-multiSelect styleClass="p-column-filter" optionLabel="val" placeholder="All"
                           [options]="getFilterOptionByID('project')" [appendTo]="'body'"
                           [(ngModel)]="cols[6].filterUserInput" (onChange)="filterMultiSelectCol($event, 'projectName')">
              <ng-template let-option pTemplate="item">
                <div class="p-multiselect-tax-year-option">
                  <span class="p-ml-1">{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </th>
          <th>
            <input pInputText type="text" class="p-column-filter" [(ngModel)]="cols[7].filterUserInput" (input)="filterCol($event, 'dcnName', 'startsWith')">
          </th>
          <th>
            <input pInputText type="text" class="p-column-filter" [(ngModel)]="cols[8].filterUserInput" (input)="filterCol($event, 'createdBy', 'contains')">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
        <tr style="height: 70px;">
          <td style="width: 80px; text-align: center;">
            <span>{{rowData.TAXYEAR}}</span>
          </td>
          <td style="width: 240px; white-space: initial;">
            <span>{{rowData.businessName}}</span>
          </td>
          <td style="width: 140px;">
            <span class="text-color-primary">{{rowData.disclosureShortName}}</span>
          </td>
          <td style="width: 250px;">
            <div class="text-color-primary three-line-ellipse">
              {{rowData.creationSource}}</div>
          </td>
          <td style="width: 250px;">
            <a [href]="rowData.gtw_source_link" style="color: red;">{{rowData.gtw_source_desc}}</a>
          </td>
          <td style="width: 200px;">
            <div style="text-overflow: ellipsis;overflow:hidden;width:100%" title="{{rowData.associatedStatements ? rowData.associatedStatements.split(',')[0]: ''}}"
              *ngIf="rowData.associatedStatements">
              <a href="javascript:void(0);" (click)="openChildDisclosure(rowData.associatedStatements.split(',')[0], rowData)">
                {{rowData.associatedStatements.split(',')[0]}}
              </a>
            </div>
            <div style="text-overflow: ellipsis;overflow:hidden;width:100%" title="{{rowData.associatedStatements && rowData.associatedStatements.split(',').length > 1? rowData.associatedStatements.split(',')[1]: ''}}"
              *ngIf="rowData.associatedStatements && rowData.associatedStatements.split(',').length > 1">
              <a href="javascript:void(0);" (click)="openChildDisclosure(rowData.associatedStatements.split(',')[1], rowData)">
                {{rowData.associatedStatements.split(',')[1]}}
              </a>
            </div> 
          </td>
          <td style="width: 180px;">
            <span *ngIf="rowData.projectName">{{rowData.projectName}} - {{rowData.projectStepName}}<br>{{rowData.PROJECTBSLACODE}}</span>
          </td>
          <td style="width: 315px;">
            <span class="text-color-primary">{{rowData.dcnName}}</span>
          </td>
          <td style="width: 380px;">
            <span *ngIf="rowData.createdBy">
              <span class="text-color-primary">Created By: </span>
              {{rowData.createdBy}}&nbsp;
              {{rowData.createdOnFormatted ? '(' + rowData.createdOnFormatted + ')' : ''}}<br>
            </span>
            <span *ngIf="rowData.modifiedBy">
              <span class="text-color-primary">Modified By: </span>
              {{rowData.modifiedBy}}&nbsp;
              {{rowData.modifiedFormatted ? '(' + rowData.modifiedFormatted + ')' : ''}}
            </span>
          </td>
          <td style="width: 105px; text-align: center;">
            <i class="pi pi-file-pdf" style="cursor: pointer; color: #D04A02;"
              (click)="showPDFView('pdf-viewer', rowData.statementId)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr [style.height]="'35px'" class="no-records">
          <td colspan="12" class="text-center"><span>No Records Found.</span></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- tab containers as no-cache workaround -->
  <div class="create-container" *ngIf="createNewTabIsOpen" [ngStyle]="{'display': activeTab?.id === idCreateNew ? 'block' : 'none'}">
    <dst-create-disclosure></dst-create-disclosure>
  </div>
  <div class="create-container" *ngFor="let tab of tabs;" [ngStyle]="{'display': activeTab?.id === tab.id ? 'block' : 'none'}">
    <dst-create-disclosure-shared *ngIf="userClickedRow" [rowObj]="userClickedRow"></dst-create-disclosure-shared>
  </div>
  <!-- end of tab containers -->
  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>
</div>

<!-- stmt id button sub component addition for dropdown hidding issue fix caused by stmt id freezed column-->
  <dst-dropdown-menu *ngIf="ddRow && ddRow.showDropdown" [rowObject]="ddRow" [mode]="'statementTracker'" [menuPos]="menuPos"
    (taggingProject)="onProjectTagging()" (copyReplicate)="onCopyOrReplicate($event)"
    (showUnmatchConfirm)="onShowUnmatchConfirm()" (showDeleteConfirm)="onShowDeleteConfirm()"
    (matchTracker)="onMatchStatementTracker()" (saveSuccess)="onSaveSuccess()">
  </dst-dropdown-menu>
  <dst-mark-as-template *ngIf="ddRow && ddRow.showExtendedMenu" (showMarkOtherReason)="onShowMarkOtherReason()" [rowObject]="ddRow"
    [markAsOptions]="markAsOptions" [menuPos]="menuPos"></dst-mark-as-template>

<!-- clean up and store modal templates in dedicated files -->
<!-- this requires Appkit Team to update their modal component -->
<ap-modal #dstDeleteConfirmDialog *ngIf="showConfirmDeleteModal" (onCheckModalOk)="onDSTDeleteConfirmDialogOK()" appModalId="confirm-delete">
  <div #modalRef1 slot="content">
    <div class="modal-dialog" style="max-width: 31.25rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <div class="a-badge a-badge-warning a-mr-10">WARNING</div>
            <span class="a-color-dark a-font-12">Statement Tracker Action</span>
          </div>
          <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeDSTDeleteConfirmDialog('confirm-delete')">
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-h3">Delete Statement</div>
          <div class="a-p4 a-mt-10 text-description">Are you sure you want to delete the statement record?</div>
        </div>
        <div class="a-modal-footer a-border-tp">
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10" data-dismiss="modal"
                  (click)="closeDSTDeleteConfirmDialog('confirm-delete')">NO
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg" data-dismiss="modal" (click)="closeDSTDeleteConfirmDialogOk('confirm-delete')">
            YES
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
<ap-modal #dstMarkOtherReasonDialog *ngIf="showMarkOtherReasonModal" (onCheckModalOk)="onDSTMarkOtherReasonDialogOK()" appModalId="mark-other-reason">
  <div #modalRef2 slot="content">
    <div class="modal-dialog" style="max-width: 31.25rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <span class="a-color-dark a-font-12">Statement Tracker Action</span>
          </div>
          <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeDSTDeleteConfirmDialog('mark-other-reason')">
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-h3">Mark As N/A</div>
          <div class="a-form-label">Please provide details for N/A</div>
          <input aria-label="val3" class="a-text-input w-100" [(ngModel)]="markAsReason">
        </div>
        <div class="a-modal-footer a-border-tp">
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10" data-dismiss="modal"
                  (click)="closeDSTMarkOtherReasonDialog('mark-other-reason')">CANCEL
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg" data-dismiss="modal"
                  (click)="closeDSTMarkOtherReasonDialogOk('mark-other-reason')">SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
<ap-modal #dstUnmatchConfirmDialog *ngIf="showConfirmUnMatchModal" (onCheckModalOk)="onDSTUnmatchConfirmDialogOK()" appModalId="confirm-unmatch">
  <div #modalRef3 slot="content">
    <div class="modal-dialog" style="max-width: 31.25rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <div class="a-badge a-badge-warning a-mr-10">WARNING</div>
            <span class="a-color-dark a-font-12">Statement Tracker Action</span>
          </div>
          <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeDSTUnmatchConfirmDialog('confirm-unmatch')">
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-h3">Unmatch Statement</div>
          <div class="a-p4 a-mt-10 text-description">Are you sure you want to unmatch this statement with requirement?</div>
        </div>
        <div class="a-modal-footer a-border-tp">
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10" data-dismiss="modal"
                  (click)="closeDSTUnmatchConfirmDialog('confirm-unmatch')">NO
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg" data-dismiss="modal" (click)="closeDSTUnmatchConfirmDialogOk('confirm-unmatch')">
            YES
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
<ap-modal #pdfViewerModal *ngIf="showPDFViewerModal" appModalId="pdf-viewer">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">

        <div class="a-modal-body" [style.height]="'80vh'">
          <gtw-form-review-pdf *ngIf="stmtDetailsLoaded" [rowData]="dstProformaData"
                               [divHeight]="'80vh'"></gtw-form-review-pdf>
        </div>

        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
                  (click)="closePDFViewerModalOk('pdf-viewer')">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>

<dst-project-tagging *ngIf="showProjectTaggingModal" [rowObject]="activeRow" (afterModalClosed)="afterProjectTaggingModalClosed()"></dst-project-tagging>
<dst-copy-replicate *ngIf="showCopyRepModal" [rowObject]="activeRow" [mode]="copyMode" (afterModalClosed)="afterCopyRepModalClosed()"></dst-copy-replicate>
<dst-match-tracker *ngIf="showMatchTrackerModal" [rowObject]="activeRow"
                   (afterModalClosed)="afterMatchTrackerModalClosed()" (onSaveCompleted)="refresh(true)">
</dst-match-tracker>
<dst-create-options *ngIf="showOptionsModal" [options]="formOptions" [rowObject]="activeRow" (afterModalClosed)="afterOptionsModalClosed($event)">
</dst-create-options>
