
<div class="modal-body" >
    <form [formGroup]="firf5471h1form" #form="ngForm" novalidate>
        <div class="panel panel-default" style="margin-top:12px">
           <div class="panel-body">
               <div class="form-group">
                   <div *ngIf= "con_fin_stmt_flg">
                        <div class="row" >
                            <div class="input-group">
                                <label  style = "color:black; font-weight:bold; margin: auto;">Consolidated Financial Statement Amount : </label>
                                <input type="text" class="form-control input-sm amt-input" formControlName="confinstmtamt" [(ngModel)]="con_fin_stmt_amt" >
                                
                            </div>
                        </div>
                        <br>
                        <div class="row" >
                            <div class="input-group">
                                <label  style = "color:black; font-weight:bold;margin: auto;">Consolidated Financial Statement Comment : </label>
                                <input type="text" format="number" class="form-control input-sm amt-input" formControlName="confinstmtcmt" [(ngModel)]="con_fin_stmt_comment" >
                            </div>
        
                        </div>
                    </div>
                   
                    <div *ngIf= "con_rtn_flg">
                        <div class="row" >
                            <div class="input-group">
                                <label for="project" style = "color:black; font-weight:bold;margin: auto;">Consolidated Return Amount : </label>
                                <input type="text" format="number" class="form-control input-sm amt-input" formControlName="conrtnamt" [(ngModel)]="con_rtn_amt">
                                
                            </div>
                        </div>
                        <br>
                        <div class="row" >
                            <div class="input-group">
                                <label for="project" style = "color:black; font-weight:bold;margin: auto;">Consolidated Return Comment : </label>
                                <input type="text" class="form-control input-sm amt-input" formControlName="conrtncmt" [(ngModel)]="con_rtn_comment" >
                            </div>
                        </div>
                    </div>
                    <div *ngIf= "oth_adj_flg">
                        <div class="row" >
                            <div class="input-group">
                                <label for="project" style = "color:black; font-weight:bold;margin: auto;">Other Adjustments Amount : </label>
                                <input type="text" format="number" class="form-control input-sm amt-input" formControlName="othadjamt" [(ngModel)]="oth_adj_amt">
                                
                            </div>
                        </div>
                        <br>
                        <div class="row" >
                            <div class="input-group">
                                <label for="project" style = "color:black; font-weight:bold;margin: auto;">Other Adjustments Comment : </label>
                                <input type="text" class="form-control input-sm amt-input" formControlName="othadjcmt" [(ngModel)]="oth_adj_comment" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </form>
</div>
<div class="modal-footer">
            <ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [isDisabled]="!enableButton" [config]="{isPrimary: true}"
            (onBtnClick)="save()"><i class="fa fa-spin fa-spinner" *ngIf="!enableButton" ></i></ap-button>
           <ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [isDisabled]="!enableButton" [btnType]="'a-btn-gray'"
           [config]="{isPrimary: true}" (onBtnClick)="cancel()"></ap-button>
</div>
   
    
    