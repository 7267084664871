import { Component, EventEmitter, OnInit, Output,Input, ChangeDetectorRef, ElementRef, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { FADE_IN_OUT } from '../../shared/animation/fade-in-out-animation';
import { DashboardFilterService } from './dashboard-filter.service';
import _, { filter, values } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription, of } from 'rxjs';
import { SummaryDiagnosticService } from '../summary-diagnostic/summary-diagnostic.service';


@Component({
  selector: 'gtw-dashboard-filter',
  templateUrl: './dashboard.filter.component.html',
  styleUrls: ['./dashboard.filter.component.scss'],
  animations: [FADE_IN_OUT]
})
export class DashboardFilterComponent implements OnInit /*, AfterViewInit*/ {

  isPanelVisible:boolean = false;
  public primaryConfig = {
    isPrimary: true
}
  @Output('close-dashboard-filter') closeFilterPanel = new EventEmitter<any>();
  @Input('filter-group-key')
  filterGroupKey: number = 0;
  @Input('screen-key')
  screenKey: number = 0;
  @Input('client-key')
  clientKey:number=0;

  baseURLs:any = {};
  @Input('base-urls')
  set _base_url(urls : any){
	try{
		this.baseURLs = urls;//JSON.parse(urls);
	}catch(error){
		console.log("Not an valid object");
	}
  }


  tax_year:any = [{data:[]}];
  @Input('tax-years')
  set _tax_years(params : any){
	try{
		this.tax_year = JSON.parse(params);
		this.init();
	}catch(error){
		console.log("Not an valid object");
	}
  }

  globalParams:any ={};
  @Input('global-params')
  set _global_params(params : any){
	try{
		this.globalParams = params;//JSON.parse(params);
		
	}catch(error){
		console.log("Not an valid object");
	}
  }

  filtersData:any = {};
  //loading:boolean = true;
  count: number = 0;
  scenarioList: any = [{data:[],loading:false}];
  filingGroupList: any = [{data:[],loading:false}];
  systemList: any = [{data:[],loading:false}];
  taxYearList:any =  [{data:[]}]
  @Input() meList:any=[{data:[],loading:false}]
  durationInSeconds= 5;
  	@Input() menuObj1 : any = [];
  	childMenuObj : any = []; 
	totalSelected = 0;
	selected : any = [];
	selectedArray : any  = [];
	showMenu = false;
	showRightMenu = false;
  	showBusinessMenu = false;
	showNotificationsPanel = false;
	showSubBusinessMenu = false;
	subBusinessMeData : any = [];
	tempTotalMeSelected = 0;
	singleMeGrpCount = 0;
	totalMeSelected = 0;
	businessfilters :any = [];
	businessMeData : any = [];
    datafiltersLoading:boolean = false;
	businesFilterLoading:boolean = false;
  	filterParams:any = {};  
  @Output() filterSelectedEmitter = new EventEmitter<any>();
  @Output() loadDataTableEmitter = new EventEmitter<any>();
  
  activeScreenFilter:any = {}
	

  constructor(public filterService: DashboardFilterService,private _snackBar: MatSnackBar,
	public changeDetection: ChangeDetectorRef,
	public summaryDiagService: SummaryDiagnosticService
	) {
    if(!this.filterService.filtersLoaded){
      this.filterService.dataFiltersLoaded.subscribe((data:any)=>{
        let loadApiLength = _.filter(this.filtersData,{'data_type':'load'}).length;
		
		  this.filtersData = data.screenFilters.filters
          this.count++;
          if(this.count == loadApiLength && loadApiLength>0){
           // this.loading = false;
            this.filterService.filtersLoaded = true;
            console.log('this filters final', this.filterService.screenFilters);
            this.processFilters({isAllLoaded:true});
          }else if(data.isOnlyFilterLoaded){
			this.processFilters({isAllLoaded:false});
		  }
      });
    }
      
   }
   processFilters(loaded:any){
	console.log('Process filter',this.filterService.screenFilters);
	console.log('Summary diag service',this.summaryDiagService.drillDown);

	let screenObj:any ={};
		screenObj = _.find(this.filterService.screenFilters,{screen_key:this.globalParams.screen_key});

		_.each(this.filterService.screenFilters,(screenFilterObj)=>{
			if(screenFilterObj.screen_key == this.globalParams.screen_key){
				_.each(screenFilterObj.filters,(filter) => {

					if(filter.param_name == 'tax_year'){
						this.taxYearList[0] = filter;
					}
					if(filter.param_name == 'scenario'){
						this.scenarioList[0]= filter;
					}else if(filter.param_name == 'filing_key'){
						this.filingGroupList[0]= filter;

					}else if(filter.param_name == 'system'){ 
					this.systemList[0] = filter;
		
					}else if(filter.param_name == 'parent_me_string'){
						if((screenFilterObj.selectedBusiness!= undefined && screenFilterObj.selectedBusiness.length>0) ){	
							this.selectedArray = screenFilterObj.selectedBusiness;
							this.businessfilters.selected =  this.selectedArray;
						}
						this.childMenuObj = [];
						this.menuObj1 = filter;
						if(this.selectedArray.length == 0){
							let firstLoad = 0;
							this.selectAll(firstLoad);
						}
					//	this.selectAll(); need to look;
					
					}
				});
				this.activeScreenFilter = screenFilterObj;
				this.datafiltersLoading= screenFilterObj.datafiltersLoading;
			}
			
		});
		if(loaded.isAllLoaded || loaded.isLoaded){
			this.activeScreenFilter =	_.find(this.filterService.screenFilters,(ii)=>{
				if(ii.screen_key == this.screenKey){
					ii.datafiltersLoading = false;
					this.datafiltersLoading = ii.datafiltersLoading;

				}
			})
			if(this.summaryDiagService.drillDown.isDrillDown){
				this.summaryDiagService.drillDown.isDrillDown = false;
				this.filerSave(false)
			}
		}
		
        console.log('scenario filter',this.scenarioList);
        console.log('filingGroupList filter',this.filingGroupList);
        console.log('systemList filter',this.systemList);
		console.log('menuObj1',this.menuObj1.data);

		console.log('this.activeScreenFilterthis.activeScreenFilter',this.activeScreenFilter)
   	}
	public subscription!: Subscription;

   ngOnInit(): void {
	   // this.init();
		console.log('I am data filters');
		this.subscription = this.filterService.notifyObservable$.subscribe((res)=>{
			if(res.screen_key == this.screenKey && res.isEnabled){
				console.log('this is from data filters sub');
				this.filterService.notifyOther({screen_key:this.screenKey,isEnabled:false});
				this.init();
			}
		});

	}

	
  init() {
	console.log('from detail',this.screenKey);
	console.log('sumission droll',this.summaryDiagService.drillDown);
	/* if(_.isEmpty(this.globalParams)){
		this.globalParams = {
			"activity_key" : "7687",
			"is_locked" : "N",
			"issue_id" : "",
			"issue_id_for_tx_save" : "",
			"issue_short_desc" : "",
			"jcd_key" : "250",
			"jcd_ta_key" : "5542",
			"scenario" : "40",
			"screen_key" : "11461" ,
			"screen_type" : "custom-tab",
			"tax_year" : "2020"
			}
	} */
	let screenFilterCacheObj:any = {};
	screenFilterCacheObj = _.find(this.filterService.screenFilters,(i)=>{
		return i.screen_key == this.screenKey 
	});
	if(this.summaryDiagService.drillDown.isDrillDown){
		let currentScreenFilters:any;
		let drillDownedScreenFilters:any;
		let selectedSummaryBusiness:any;

		_.each(this.filterService.screenFilters,(i)=>{
			if(i.screen_key == this.summaryDiagService.drillDown.screen_key){
				drillDownedScreenFilters = _.cloneDeep(i.filters);
				selectedSummaryBusiness = _.cloneDeep(i.selectedBusiness);
			}else{
				currentScreenFilters = _.cloneDeep(i.filters);
			}
		});
		let getCurrScreenfilterParams = this.filterService.getFilterParams(this.screenKey);
		let getSummaryFilterParams = this.filterService.getFilterParams(11461);
		if(_.isEqual(drillDownedScreenFilters,currentScreenFilters)){ 
			this.summaryDiagService.drillDown.isDrillDown = false;
			this.filerSave(false);
		}
		/* else if(getCurrScreenfilterParams['tax_year']== getSummaryFilterParams['tax_year'] && 
				getCurrScreenfilterParams['scenario']== getSummaryFilterParams['scenario']){ */
		else if(screenFilterCacheObj && screenFilterCacheObj.filters && screenFilterCacheObj.filters.length){
					// _.each(currentScreenFilters,(ii)=>{
					// 	if(ii.ui_type=='df-select' && ii.data_type != 'inherit'){

					// 		_.each(ii.data,(kk)=>{
					// 			if((kk.value || kk.VALUE) == getSummaryFilterParams[ii.param_name]){
					// 				kk['selected'] = true;
					// 			}else{
					// 				if(kk['selected']){
					// 					kk['selected'] = false;
					// 				}
					// 			}
					// 		});
					// 	}else if(ii.param_name == 'parent_me_string'){
					// 		let summayParentMe = _.find(drillDownedScreenFilters,{filter_key:ii.filter_key});
					// 		ii.data = _.cloneDeep(summayParentMe.data);
					// 		_.filter(this.filterService.screenFilters,(jj)=>{
					// 			if(jj.screen_key == this.screenKey){
					// 				jj['selectedBusiness'] = selectedSummaryBusiness;
					// 				this.selectedArray = selectedSummaryBusiness;
					// 			}
					// 		})
					// 	}
					// });
					_.filter(this.filterService.screenFilters,(ll)=>{
						if(ll.screen_key == screenFilterCacheObj.screen_key){
							ll.filters = _.cloneDeep(drillDownedScreenFilters);//_.cloneDeep(currentScreenFilters);
							ll['selectedBusiness'] = selectedSummaryBusiness;
					 		this.selectedArray = selectedSummaryBusiness;
						}
					});
					this.summaryDiagService.drillDown.isDrillDown = false;

					this.filerSave(false);
		}
		/* else if(screenFilterCacheObj && screenFilterCacheObj.filters && screenFilterCacheObj.filters.length){
			//event.selected[0].value
			let tax_year: any = {selected:[]};
			tax_year.selected.push({value:getSummaryFilterParams['tax_year']});
			console.log('drill taxyear',tax_year);
			_.each(currentScreenFilters,(ii)=>{
				if(ii.ui_type=='df-select' && ii.data_type == 'inherit'){
					_.each(ii.data,(kk)=>{
						if((kk.value || kk.VALUE) == getSummaryFilterParams['tax_year']){
							kk['selected'] = true;
						}else{
							if(kk['selected']){
								kk['selected'] = false;
							}
						}
					});
				}
			})
			this.taxYearSelection(tax_year,null,true);
		} */
		else{//initial load
			this.globalParams['tax_year']=this.summaryDiagService.drillDown.selectedFilterParms.tax_year;
			this.globalParams['scenario']=this.summaryDiagService.drillDown.selectedFilterParms.scenario;
			this.globalParams['filing_key']=this.summaryDiagService.drillDown.selectedFilterParms.filing_key;
			console.log('this drilldown globalPrams',this.globalParams);
			this.getCurrentFilters({isFromDrillDown:true})		

		}
	}else{
		if(screenFilterCacheObj != undefined && screenFilterCacheObj.screen_key != undefined){
			if(screenFilterCacheObj['scenario']== undefined ||screenFilterCacheObj['filing_group']== undefined  || 
				screenFilterCacheObj['system']== undefined  || screenFilterCacheObj['taxYearList']== undefined  || screenFilterCacheObj['business']== undefined ){
				this.processFilters({isAllLoaded:false,isLoaded:true,fromCache:true});
			}
	
		}else{
			this.getCurrentFilters({isFromDrillDown:false});
		}
	}
  
   
  }
  getCurrentFilters(fromDrillDown:any){
	if(_.filter(this.filterService.screenFilters,{screen_key:this.screenKey}).length == 0){
		this.filterService.screenFilters.push({screen_key:this.screenKey,datafiltersLoading:true});		
	}
	this.activeScreenFilter = _.find(this.filterService.screenFilters,{screen_key:this.screenKey});
	this.datafiltersLoading = true;
		
	let datas:any = [];
	let tempTaxYearList:any = [];
/* 	if(this.tax_year[0].data.length == 0){
			
			let data = 
				[{text: '2023', value: '2023', scenario: '66', scenario_desc: 'Return As Filed'},  
				{text: '2022', value: '2022', scenario: '52', scenario_desc: 'Return As Filed'},  
				{text: '2021', value: '2021', scenario: '42', scenario_desc: 'Return As Filed'},  
				{text: '2020', value: '2020', scenario: '40', scenario_desc: 'Return As Filed',selected:true},  
				{text: '2019', value: '2019', scenario: '32', scenario_desc: 'Return As Filed'}, 
				{text: '2018', value: '2018', scenario: '28', scenario_desc: 'Return As Filed'}];
				tempTaxYearList = data;
				datas = data;
		}else{ */
			tempTaxYearList = this.tax_year;
			_.each(tempTaxYearList,(v,key) =>{
				let obj: any = {};
				if(v.label == this.globalParams.tax_year){
					obj['selected'] = true;
				}
				obj.text = v.label;
				obj.value = v.value;
				datas.push(obj);
			})
	//	}
        this.filterService.getFilters(this.baseURLs.api,this.filterGroupKey,this.clientKey,this.screenKey,fromDrillDown)
        .subscribe((data:any)=>{
            console.log('data filter',data);
			 if(_.filter(this.filterService.screenFilters,(val,key)=>{
				if(val.screen_key == this.screenKey){
					this.filterService.screenFilters[key]['filters'] = data.filterGroup.filters
				}
			})) 
			this.filterService.getFilterData(datas,this.baseURLs.api,data.filterGroup.filters,this.clientKey, this.globalParams,fromDrillDown).subscribe((filters:any) =>{
			console.log('filter completed apis data',filters);  
			})
        });
  }


  close(){
    this.isPanelVisible = false;;
    console.log('closed');
    this.count = 0;
     this.closeFilterPanel.emit(this.isPanelVisible);
  }
  btnClick() {
    console.log('button is clicked')
  }
  getSelected(){
		this.selected  = [];
		let selected = 0;
		let tempSelectedArray :any = [];

		this.menuObj1.data.forEach( (item : any) => {
			if(item.selected ){
				selected++;
				tempSelectedArray.push(item.key);
			}
			if(item.children && item.children.length > 0) {
				item.numSelected = recursiveSelected(item);
			}
		})

		function recursiveSelected(collection : any){
			var selected = 0;
			function recursiveSelectedInner(collection : any) {
				collection.children.forEach( (item : any) => {
					if(item.selected ){
						//this.selected.push(item.key);
						tempSelectedArray.push(item.key);
						selected++;
					}
					if(item.children && item.children.length > 0) {
						recursiveSelectedInner(item);
					}
				});
			}
			recursiveSelectedInner(collection)
			return selected;
		}
		this.selected = tempSelectedArray;
		return selected;
	}

	checkParent(_item : any){
		let allSelected = true;
		if( _item.parent ){
			_item.parent.children.forEach( (item : any) => {
				if(!item.selected ){
					 allSelected = false;
				}
			});
			_item.parent.selected = allSelected;

			if( _item.parent.parent ){
				this.checkParent(_item.parent);
			}
		}
	}
  change() {
    this.createdSelectedArray();
    this.businessfilters.selected = this.selectedArray;

	_.each(this.filterService.screenFilters,(i)=>{
		if(i.screen_key == this.screenKey){
			//i['business'] = this.menuObj1;
			i['selectedBusiness'] = this.selectedArray;
		}
	});
  }
  createdSelectedArray(){
    this.selectedArray = [];
    for (var i = 0; i < this.selected.length; i++) {
      let newObj :any = {}
      newObj.VALUE = this.selected[i];
      newObj.parnet = this.selected[i];
      newObj.level = this.selected[i];
      this.selectedArray.push(newObj);
    }
  }
	selectedMe(event: any, collection: any, isParent : boolean){
		event.preventDefault();
		let selected = false;
		this.showSubBusinessMenu = true;

		collection.selected = !collection.selected;
		if(collection.selected){
			selected = collection.selected;
		}

		function setEm(_collection: any){
			if(_collection.children && _collection.children.length > 0){
				_collection.children.forEach( (item : any) => {
					item.selected = selected;
					if(item.children && item.children.length > 0){
						setEm(item);
					}
				});
			};
		}

		setEm(collection);
		this.checkParent(collection);
		this.getSelected();
		this.change();

    
  
    
		if(isParent){
			this.selectParentMe(collection);
		}
	}
  selectParentMe(item:any){
		this.setTreeAttribute(this.menuObj1.data,'active','children',false);
		item.active = true;
		this.childMenuObj = item.children;
		this.showSubBusinessMenu = true;
	};
  setTreeAttribute(_collection:any,_predicate:any,_children:any,value:any){
		for (var i = 0; i < _collection.length; i++) {
			var  n = _collection[i][_predicate];
			var children = _collection[i][_children];
			if(children && children.length ){
				this.setTreeAttribute(children,_predicate,_children,value );
			}
			_collection[i][_predicate] = value;
		}
	}
	setMeSelection(me : any, value : boolean){
		//this.tempTotalMeSelected = 0;
		me.forEach( (me : any) => {
			this.tempTotalMeSelected++;
			me.selected = value;
			if(me.children){
				//this.totalMeSelected = value ? this.totalMeSelected + me.children.length : this.totalMeSelected - me.children.length;
				this.setMeSelection(me.children, value);
			};
		});
	};

	getAllMeCounts(me : any){
		let meCount = 0;
		//if(me.children){
		//	meCount += me.children.length;
		me.forEach( (me : any) => {
			meCount++;
			if(me.children){
				getMeCounts(me.children);
			}
		});

		function getMeCounts(me: any){
			me.children.forEach( (me : any) => {

				if(me.children){
					meCount += me.children.length;
					getMeCounts(me.children);
				};
			});
		};
		return meCount;
	};

	meSelection(value: boolean){
		this.tempTotalMeSelected = 0;

		this.businessMeData.forEach( (me : any) => {
			//meCount++;
			//if(me.children){
				this.selectedMe(null, me, true);
			//}
		});

		if(value){
			this.totalMeSelected = this.tempTotalMeSelected;
			console.log(this.singleMeGrpCount);
		}else{
			this.totalMeSelected = 0;
		}
	}

	getTotalSelected(){
		this.totalSelected = this.getSelected();
	}

	selectedSubMe(subMe: any){
		console.log(subMe);
	}

	selectedSubChildMe(subChildMe: any){
		console.log(subChildMe);
	}
  unselectAll(){
		this.setTreeAttribute(this.menuObj1.data,'selected','children',false);
		this.getSelected();
		this.change();
	}
  selectAll(firstLoad:any){
		this.setTreeAttribute(this.menuObj1.data,'selected','children',true);
		this.getSelectedAll();
		this.change();
		if(firstLoad == 0){
			this.filerSave(true);
		}
	}
  getSelectedAll(){
		this.selected  = [];
		var selected = 0;
		let tempSelectedArray :any = [];

		this.menuObj1.data.forEach( (item : any) => {
			if(item.selected ){
				selected++;
				//this.selected.push(item.key);
				tempSelectedArray.push(item.key);
			}
			if(item.children && item.children.length > 0) {
				item.numSelected = recursiveSelected(item);
			}
		})

		function recursiveSelected(collection:any){
			var selected = 0;
			function recursiveSelectedInner(collection:any) {
				collection.children.forEach( (item : any) => {
					if(item.selected){
						//this.selected.push(item.key);
						tempSelectedArray.push(item.key);
						selected++;
					}
					if(item.children && item.children.length > 0) {
						recursiveSelectedInner(item);
					}
				});
			}
			recursiveSelectedInner(collection)
			return selected;
		}
		this.selected = tempSelectedArray;
		return selected;		
	}
  filerSave(isFromFilter:any){
	console.log('filterParams', this.filterService.getFilterParams(this.globalParams.screen_key));
	let filterParams =  this.filterService.getFilterParams(this.globalParams.screen_key)
	  let missingParamLength = 0;
	  _.map(filterParams,(val,key) =>{
		if(val==""|| val==undefined){
			missingParamLength++;
			if(key=="filing_key"){
				this.openSnackBar('Please Select a Filing Group to Proceed','gtw-snackbar--red');
			}
			else if(key=="parent_me_string"){
				this.openSnackBar('Please Select a Business to Proceed','gtw-snackbar--red');
			}
			else{
				this.openSnackBar(key + ' selection missing','gtw-snackbar--red');
			}
			return;
		}
	  })
	  console.log('filterParams', filterParams);
	  filterParams.isFromFilter = isFromFilter;
	  if(missingParamLength == 0){
		if(this.globalParams.screen_key == 11460){//detailed screen
			this.filterSelectedEmitter.emit(filterParams);
		  }else{//submission 
			this.loadDataTableEmitter.emit(filterParams);
		  }
	  }
	 
  }
  selectedSystem(event:EventListener,obj:any,isSelected:boolean,systemList:any,index:any){
    console.log('event',event);
    console.log('obj', obj);
    this.filterParams['system'] = obj.value;
	this.systemList[0].data[index].selected = this.systemList[0].data[index].selected ? !isSelected :isSelected;
	this.filterService.filterCache['system'] = this.systemList;

  }
  taxYearSelection(event:any,filter:any,isFromDrillDown:any){
		let scenarioFilter:any = [];
		let screenObj: any ;
		screenObj = _.filter(this.filterService.screenFilters,{screen_key:this.screenKey});
		scenarioFilter = _.filter(screenObj[0].filters,{param_name:'scenario'});
		let scenarioFilterIndex:any;
		scenarioFilterIndex = _.findIndex(screenObj[0].filters,{param_name:'scenario'});
		let params: any = {};
		params.tax_year = event.selected[0].value;
		if(_.isString((this.globalParams))){
			this.globalParams = JSON.parse(this.globalParams)
		}
		params = _.merge({}, this.globalParams, params);
		params.client_key = this.clientKey;
		let filter_key =  _.filter(screenObj[0].filters,{param_name:'tax_year'})[0].filter_key;

		this.filterService.setFilterLoading(this.screenKey)
		this.filterService.getScenarioRefresh(this.baseURLs.api,scenarioFilter[0],params,scenarioFilterIndex,isFromDrillDown)
		.subscribe((data:any)=>{
			if(this.summaryDiagService.drillDown.isDrillDown){
				params.scenario = this.summaryDiagService.drillDown.selectedFilterParms['scenario'];
			}else{
				params.scenario = this.scenarioList[0].data[0].value;
			}
			params.screen_key = this.screenKey;
			this.filterService.changeRefreshFilters(this.baseURLs.api,filter_key,params,true,isFromDrillDown).subscribe((data:any)=>{
				console.log('tax refresh comp');
				if(isFromDrillDown){
					this.summaryDiagService.drillDown.isDrillDown = false;
				}

			})
		})		
		console.log('tax event',event);
  	}

  scenarioSelection(event:any){
	let params: any = {};
	let screenObj: any ;
	screenObj = _.filter(this.filterService.screenFilters,(i)=>{
		if(i.screen_key == this.screenKey){
			i.scenario = this.scenarioList;
			return i;
		}
	});
	this.meList[0].loading = false;

		_.filter(this.taxYearList[0].data,(item)=>{
			if(item.selected){
				params['tax_year'] = item.value;
			}
		});
		params.scenario = event.selected[0].value;
		if(_.isString((this.globalParams))){
			this.globalParams = JSON.parse(this.globalParams)
		}
		params = _.merge({}, this.globalParams, params);
		params.client_key = this.clientKey;
		params.screen_key = this.screenKey;
	/* 	_.each(this.filterService.screenFilters,(ii)=>{
			if(ii.screen_key == params.screen_key){
				ii.businessLoading = true;
				this.meList[0].loading = ii.businessLoading;
			}
		}) */
		this.filterService.setFilterLoading(this.screenKey)


		this.filterService.changeRefreshFilters(this.baseURLs.api, this.scenarioList[0].filter_key,params,true,false).subscribe((data:any)=>{

		})
	}

		  openSnackBar(message: string, panelClass:string='') {
			this._snackBar.open(message, '' , {
				duration: this.durationInSeconds * 1000,
				"panelClass": [panelClass]
			});
		}
		selectAllSystem(){
			this.systemList[0].loading = true;

			_.each(this.systemList[0].data,(function(i){
				i['selected'] = true;
			}));
			this.systemList[0].loading = false;
		}
		unselectAllSystem(){
			this.systemList[0].loading = true;

			_.each(this.systemList[0].data,(function(i){
				i['selected'] = false;
			}))
			this.systemList[0].loading = false;

		}
}

