import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class PartnershipDashboardService {

  private URLS = {
    ho_ti: 'loadJsonObject?action_code=aux5gs',
    ho_credits: 'loadJsonObject?action_code=4v7pgy',
    ho_ti_details: 'loadJsonObject?action_code=ft6hwq',
    ho_credit_details: 'loadJsonObject?action_code=6jhhuw',
    ho_sourcing: 'loadJsonObject?action_code=2whtw5',
    ho_sourcing_details: 'loadJsonObject?action_code=1xekf8',
  }

  HO_DETAILS_URL = 'loadJsonObject?action_code=tmr62v';
  screenDetails: any;
  hoLoading: boolean = true;
  contentLoading: boolean = true;
  hoDetails: any;

  ti_card_details: any = {
    total_amt: 0,
    total_flowthru_amt: 0,
    total_exclusion_amt: 0,
    total_ext_ptr_amt: 0,
    variance: 0,
  }

  credits_card_details: any = {
    total_amt: 0,
    total_flowthru_amt: 0,
    total_exclusion_amt: 0,
    total_ext_ptr_amt: 0,
    variance: 0,
  }

  sourcing_card_details: any = {
    total_amt: 0,
    total_flowthru_amt: 0,
    total_exclusion_amt: 0,
    total_ext_ptr_amt: 0,
    variance: 0,
  }

  gridTitle = "Taxable Income";

  private partnershipsMap = {};
  private partnerships = [];
  private partners = [];
  private selectedPartnership = null;
  private tableData = {};
  selectedFilterParams: any;

  private cardDetails = {
    ho_credits: {},
    ho_sourcing: {},
    ho_ti: {}
  } as any;

  private cardDetailsSubject = new BehaviorSubject(this.cardDetails);

  constructor(private httpClient: HttpClient,
    private gtwSnackBarService: GtwSnackbarService) { }

  getHoDetails(baseUrl: string, filterParams: any): Observable<any> {

    const data = new FormData();
    data.append('tax_year', filterParams.tax_year);
    data.append('scenario', filterParams.scenario);
    const gateway = '/gateway/gtw/'; 
    //const gateway = '/gtw/';
    const url = `${gateway}${this.HO_DETAILS_URL}`;
    if (filterParams.filing_key) {
      data.append('filing_key', filterParams.filing_key)
    }
    if (filterParams.parent_me_string) {
      data.append('parent_me_string', filterParams.parent_me_string);
    }
    if (filterParams.jcd_key) {
      data.append('jcd_key', filterParams.jcd_key);
    } else {
      data.append('jcd_key', '250');

    }
    return this.httpClient.post(url, data).pipe(map((responseData: any) => {
      if (!responseData.jsonObject || Object.keys(responseData.jsonObject).length === 0) {
        console.log(`Empty jsonObject received from ${url}`);
        this.gtwSnackBarService.openSnackBar("No Data Found for Selected Filters","gtw-snackbar--red");
      }
      this.hoDetails = responseData;
      return responseData;
    })
    )
  }

  loadProcs(baseUrl: any, filterParams: any, ho_combination_key: string): Observable<any> {
    const gateway = '/gateway/gtw/'; 
    //const gateway = '/gtw/';
    const data = new FormData();
    data.append('tax_year', filterParams.tax_year);
    data.append('scenario', filterParams.scenario);
    data.append('ho_combination_key', ho_combination_key);
    if (filterParams.jcd_key) {
      data.append('jcd_key', filterParams.jcd_key);
    } else {
      data.append('jcd_key', '250');
    }
    this.screenDetails = {};

    const observables: Observable<any>[] = [];

    _.forEach(this.URLS, (value, key) => {
      const url = `${gateway}/${value}`;

      const obs = this.httpClient.post(url, data).pipe(
        tap((res: any) => {
          console.log("running procs" + res);
          if (!res.jsonObject || Object.keys(res.jsonObject).length === 0) {
            console.log(`Empty jsonObject received from ${url}`);
          }
          this.screenDetails[key] = res.jsonObject;
        })
      );

      observables.push(obs);
    });

    return forkJoin(observables);
  }

  populateCards() {
    ['ho_credits', 'ho_sourcing', 'ho_ti'].forEach((key, index) => {
      let cardDetails = {
        total_amt: 0,
        total_flowthru_amt: 0,
        total_exclusion_amt: 0,
        total_ext_ptr_amt: 0,
        variance: 0
      };

      if (this.screenDetails[key] && this.screenDetails[key].length > 0) {
        this.screenDetails[key].forEach((item: { TOTAL_PSHIP_AMT: any; TOTAL_FLOWTHRU_AMT: any; TOTAL_EXCLUSION_AMT: any; TOTAL_EXT_PTR_AMT: any; VARIANCE: any; }) => {
          cardDetails.total_amt = item.TOTAL_PSHIP_AMT || 0;
          cardDetails.total_flowthru_amt = item.TOTAL_FLOWTHRU_AMT || 0;
          cardDetails.total_exclusion_amt = item.TOTAL_EXCLUSION_AMT || 0;
          cardDetails.total_ext_ptr_amt = item.TOTAL_EXT_PTR_AMT || 0;
          cardDetails.variance = item.VARIANCE || 0;
        });
      }

      this.updateCardDetails(key, cardDetails);
    });
  }

  getPartnershipsMap() {
    return this.partnershipsMap;
  }

  getPartnerships() {
    return this.partnerships;
  }

  getPartners() {
    return this.partners;
  }

  getSelectedPartnership() {
    return this.selectedPartnership;
  }

  getTableData() {
    return this.tableData;
  }

  getSelectedFilterParams() {
    return this.selectedFilterParams;
  }

  updateSelectedFilterParams(data: any) {
    this.selectedFilterParams = data;
  }

  updateTableData(data: any) {
    this.tableData = data;
  }

  updatePartnershipsMap(data: any) {
    this.partnershipsMap = data;
  }

  updatePartnerships(data: any) {
    this.partnerships = data;
  }

  updatePartners(data: any) {
    this.partners = data;
  }

  updateSelectedPartnership(data: any) {
    this.selectedPartnership = data;
  }

  getCardDetails() {
    return this.cardDetailsSubject.asObservable();
  }

  updateCardDetails(key: string, data: any) {
    this.cardDetails[key] = data;
    this.cardDetailsSubject.next(this.cardDetails);
  }
}
