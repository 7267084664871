<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false"
  [displayNotification]="showDREMsg" [notifMsg]="DREMsg" (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form237" class="ap-form-field-my-wrapper">
    <dst-template-header [parentFormGroup]="form237" (formOptionChanged)="onFormOptionChanged($event)"
      (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <dst-template-leid-selector [label]="'Please specify the LEID of the Reporting Entity'" [labelId]="'100'"
      (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field">
    </dst-template-leid-selector>
    <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
      Please specify the LEID for the Reporting Entity
    </div> -->
    <!-- </div> -->
    <div class="entity-details">
      <span>Name as shown on tax return:</span><span class="template-color-blue">{{!transferor ? '' :
        transferor.ENTITY}}</span>
    </div>
    <div class="entity-details">
      <span>Identifying number:</span><span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ?
        transferor.EIN : 'N/A')}}</span>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I &nbsp;&nbsp;</strong>
            <strong>Information on the Like-Kind Exchange</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div formGroupName="partOne" class="section-content ap-form-field-my-wrapper">
      <div>
        <strong>Note:&nbsp;</strong><span>Generally, only real property should be described on line 1 or 2. However, you
          may describe personal
          and/or real property on line 1 or 2 if you are filling this form to report the disposition of property
          exchanged
          in a previously reported related part Like-Kind exchange. If the property described on line 1 or line 2 is
          real
          or personal property located outside the United States, indicate the country.</span>
      </div>
      <ol class="section-content">
        <li>
          <div class="d-flex">
            <div class="col-md-12 pl-0">
              Description of Like-Kind Property Given Up
            </div>
          </div>
          <div class="d-flex justify-content-between row">
            <div class="col-md-12">
              <div class="a-textarea-box a-field-length-validation form-inlines">
                <dst-custom-input class="custom-input-100 p-0" [type]="'textarea'" [label]="''" [maxLength]="250"
                  [value]="DescOfLikeKindPropertyGivenUp"
                  (valChange)="DescOfLikeKindPropertyGivenUp = $event;"></dst-custom-input>

              </div>
              <!-- <div class="a-form-error" *ngIf="DescOfLikeKindPropertyGivenUp === ''">
                Please specify the Description of the Like-Kind Property Given Up
              </div> -->
            </div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="col-md-12 pl-0">
              Description of Like-Kind Property Received
            </div>
          </div>
          <div class="d-flex justify-content-between row">
            <div class="col-md-12">
              <div class="a-textarea-box a-field-length-validation form-inlines">
                <dst-custom-input class="custom-input-100 p-0" [type]="'textarea'" [label]="''" [maxLength]="250"
                  [value]="DescOfLikeKindPropertyReceived"
                  (valChange)="DescOfLikeKindPropertyReceived = $event;"></dst-custom-input>
              </div>
              <!-- <div class="a-form-error" *ngIf="DescOfLikeKindPropertyReceived === ''">
                Please specify the Description of the Like-Kind Property Received
              </div> -->
            </div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="col-md-9 pl-0">
              Date Like-Kind property given up was originally acquired
            </div>
            <div class="col-md-3 date-fields pr-0">
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                class="a-datepicker-margin" [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()"
                formControlName="DateLikeKindPropertyGivenUpAcq" [(ngModel)]="DateLikeKindPropertyGivenUpAcq"
                (onChange)="dateChange($event, 1)">
              </ap-date-picker>
            </div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="col-md-9 pl-0">
              Date you actually transferred your property to other party
            </div>
            <div class="col-md-3 date-fields pr-0">
              <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                class="a-datepicker-margin" [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()"
                formControlName="DatePropActuallyTransferred" [(ngModel)]="DatePropActuallyTransferred"
                (onChange)="dateChange($event, 2)">
              </ap-date-picker>
            </div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="col-md-9 pl-0">
              Date Like-Kind property you received was identified by written notice to another party. See instructions
              for 45-day written identification requirement
            </div>
            <div class="col-md-3 date-fields pr-0">
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                class="a-datepicker-margin" [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()"
                formControlName="DateLikeKindPropRcvdIdentified" [(ngModel)]="DateLikeKindPropRcvdIdentified"
                (onChange)="dateChange($event, 3)">
              </ap-date-picker>
            </div>
          </div>
        </li>

        <li class="mt-3">
          <div class="d-flex">
            <div class="col-md-9 pl-0">
              Date you actually received the Like-Kind property from other party
            </div>
            <div class="col-md-3 date-fields pr-0">
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                class="a-datepicker-margin" [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()"
                formControlName="DatePropertyActuallyReceived" [(ngModel)]="DatePropertyActuallyReceived"
                (onChange)="dateChange($event, 4)">
              </ap-date-picker>
            </div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="col-md-10 p-0">
              Was the exchange of the property given up or received made with a related party, either directly or
              indirectly (such as through an intermediary?)
            </div>
            <div class="col-md-2">
              <ap-radio [name]="'radio-group-1'" [text]="'Yes'"
                [checked]="editMode && (ExchangeMadeWithRelatedParty === true) ? true : false" (onCheck)="check7(true)"
                class="a-mx-10" style="margin-left: 20px;">
              </ap-radio>
              <ap-radio [name]="'radio-group-1'" [text]="'No'"
                [checked]="editMode && (ExchangeMadeWithRelatedParty === false) ? true : false"
                (onCheck)="check7(false)" class="a-mx-10">
              </ap-radio>
            </div>
          </div>
          <div>If yes, complete Part II otherwise proceed to Part III</div>
        </li>

      </ol>
      <div>
        <strong>Note:&nbsp;</strong><span>Do not file this form if a related party sold property into the exchange,
          directly or
          indirectly(such as through an intermediary); that property became your replacement property;and none of the
          exceptions in line 11 applies to the exchange, Instead, report the disposition of the property as if the
          exchange had been a sale. If one of the exceptions on line 11 applies to the exchange, complete Part II</span>
      </div>
    </div>
    <div formGroupName="partTwo" [hidden]="ExchangeMadeWithRelatedParty !== true">
      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td class="header-text">
              <strong>&nbsp;&nbsp;Part II &nbsp;&nbsp;</strong>
              <strong>Related Party Exchange Information</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content mt-0 mb-0" start="8">
          <li class="related-party">
            <dst-template-leid-selector [label]="'Please specify the LEID of the Related Party'" [labelId]="'100'"
              (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field">
            </dst-template-leid-selector>

            <div class="entity-details">
              <span>Name of Related Party:</span>
              <span class="template-color-blue">{{!transferee ? '' :
                transferee.ENTITY}}</span>
            </div>

            <div class="entity-details">
              <span>Identifying number:</span>
              <span class="template-color-blue">{{!transferee ? '' :
                (transferee.EIN ? transferee.EIN : 'N/A')}}</span>
            </div>

            <div class="entity-details">
              <span>Relationship to you</span>
              <div>
                <ap-form-field [inlineBlock]="true" [label]="''" formControlName="RelationshipToYou"
                  [(ngModel)]="RelationshipToYou" [id]="'3'" [type]="'input'" [placeholder]="''" class="label-margin">
                </ap-form-field>
              </div>
            </div>
          </li>
          <br />
          <li>
            <div class="d-flex">
              <div class="col-md-10 pl-0">
                During this tax year (and before the date that is 2 years after the last transfer of property that was
                part of the exchange), did the related party sell or dispose of any part of the Like-Kind property
                received from you (or an intermediary) in the exchange ?
              </div>
              <div class="col-md-2 pr-0">
                <ap-radio [name]="'radio-group-2'" [text]="'Yes'"
                  [checked]="editMode && RelatedPartySoldPropReceived ? true : false" (onCheck)="check9(true)"
                  class="a-mx-10">
                </ap-radio>
                <ap-radio [name]="'radio-group-2'" [text]="'No'"
                  [checked]="editMode && !RelatedPartySoldPropReceived ? true : false" (onCheck)="check9(false)"
                  class="a-mx-10">
                </ap-radio>
              </div>
            </div>
          </li>
          <br />
          <li>
            <div class="d-flex ">
              <div class="col-md-10 pl-0">
                During this tax year (and before the date that is 2 years after the last transfer of property that was
                part of the exchange) did you sell or dispose of any part of the Like-Kind property you received?
              </div>
              <div class="col-md-2 pr-0">
                <ap-radio [name]="'radio-group-3'" [text]="'Yes'"
                  [checked]="editMode && YouSoldPropertyReceived ? true : false" (onCheck)="check10(true)"
                  class="a-mx-10">
                </ap-radio>
                <ap-radio [name]="'radio-group-3'" [text]="'No'"
                  [checked]="editMode && !YouSoldPropertyReceived ? true : false" (onCheck)="check10(false)"
                  class="a-mx-10">
                </ap-radio>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-md-12 pl-0">
                <br />
                If both lines 9 and 10 are No and this is the year of the exchange, go to Part III. If both lines 9 and
                10 are No and this is not the year of the exchange, stop here. If either line 9 or line 10 is Yes
                complete Part III and report on this year's tax return the deferred gain or (loss) from line 24 unless
                one of the exceptions on line 11 applies.
              </div>
            </div>
          </li>
          <br />
          <li class="related-party">If one of the exceptions below applies to the disposition, check the applicable box
            below (a, b, or c)
            <ol class="inner-list section-content">
              <li class="p-0 mb-3">
                <div class="d-flex">
                  <ap-checkbox [text]="''" [checked]="editMode && DisposWasAfterDeathRltdParties ? true : false"
                    (onCheck)="check11a()" class="a-mx-10">
                  </ap-checkbox>
                  <div class="pl-2">The disposition was after the death of either of the related parties</div>
                </div>
              </li>

              <li class="p-0 mb-3">
                <div class="d-flex">
                  <ap-checkbox [text]="''" [checked]="editMode && DisposWasInvoluntaryConversion ? true : false"
                    class="a-mx-10" (onCheck)="check11b()">
                  </ap-checkbox>
                  <div class="pl-2">The disposition was an involuntary conversion, and the threat of conversion occurred
                    after the exchange</div>
                </div>
              </li>

              <li class="p-0">
                <div class="d-flex">
                  <ap-checkbox [text]="''" [checked]="editMode && ExchangeDisposNotTaxAvoidance ? true : false"
                    class="a-mx-10" (onCheck)="check11c()">
                  </ap-checkbox>

                  <div class="pl-2">
                    You can establish to the satisfaction of the IRS that nether the exchange nor the disposition had
                    tax avoidance as its principal purpose. If this box is checked, specify an explanation
                  </div>
                </div>
                <br />

                <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                  <dst-custom-input class="custom-input-100" style="padding-left: 36px;" [type]="'textarea'"
                    [label]="''" [maxLength]="1000" [value]="PrincipalPrpsRltdPartyExchStmtExplanation"
                    (valChange)="PrincipalPrpsRltdPartyExchStmtExplanation = $event;"></dst-custom-input>
                </div>

              </li>
            </ol>
          </li>
        </ol>

        <!-- <div class="a-form-error" *ngIf="ExchangeDisposNotTaxAvoidance === true && PrincipalPrpsRltdPartyExchStmtExplanation === ''">
          Please specify an explanation
        </div> -->
      </div>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III &nbsp;&nbsp;</strong>
            <strong>Realized Gain or (Loss), Recognized Gain, and Basis of Like-Kind Property Received</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div formGroupName="partThree" class="section-content ap-form-field-my-wrapper">
      <strong>Caution:&nbsp;</strong><span>If you transferred and received (a) more than one group of Like-Kind
        properties or (b) cash or other (not
        like-kind) property, see Reporting of multi-asset exchanges in the instructions.</span><span
        class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'"
        [isWhite]="true"></span>
      <div class="d-flex justify-content-between row">
        <br />
      </div>
      <div class="d-flex">
        <div class="col-md-10 pl-0">Amount of the Gain on Multi-Asset Exchange</div>
        <div>
          <ap-form-field [inlineBlock]="true" [label]="''" class="number-field"
            formControlName="GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount"
            [(ngModel)]="GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount" [id]="'5'" [type]="'inputNumber'"
            [funType]="'typeTwo'" [placeholder]="''">
          </ap-form-field>
        </div>
      </div>
      <br />
      <div class="d-flex justify-content-between row">
        <div class="col-md-12">
          Include the details of the calculation of realized and recognized gain on multi-asset exchanges in text box
          below
        </div>
      </div>

      <div class="list-content a-textarea-box a-field-length-validation form-inlines">
        <dst-custom-input class="custom-input-100 p-0" [type]="'textarea'" [label]="''" [maxLength]="1000"
          [value]="GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation"
          (valChange)="GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation = $event;"></dst-custom-input>

      </div>
      <!-- <div class="a-form-error" *ngIf="GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount !== '' && GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation === ''">
        Please specify the details of the calculation
      </div> -->
      <div>
        <strong>Note:&nbsp;</strong><span>Complete lines 12 through 14 ONLY if you gave up property that was not
          Like-Kind. Otherwise, go to line
          15.</span>
      </div>
      <ol class="section-content part-three-fields" start="12" style="padding-left: 20px;">
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Fair market value (FMV) of other property given up
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'7'" class="number-field" [type]="'inputNumber'"
                [funType]="'typeTwo'" formControlName="FMVOfOtherPropertyGivenUp"
                [(ngModel)]="FMVOfOtherPropertyGivenUp" (onChangeEvent)="updateValue12($event)" [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex mt-2 justify-content-between row">
            <div class="col-md-12">
              a. Description of other property given up
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="list-content a-textarea-box a-field-length-validation form-inlines">
            <dst-custom-input class="custom-input-100 p-0" [type]="'textarea'" [label]="''" [maxLength]="1000"
              [value]="DescriptionOfOtherPropertyGivenUp" (valChange)="DescriptionOfOtherPropertyGivenUp = $event;">
            </dst-custom-input>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Adjusted basis of other property given up
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'8'" class="number-field" [type]="'inputNumber'"
                [funType]="'typeTwo'" formControlName="AdjBasisOfOtherPropertyGivenUp"
                [(ngModel)]="AdjBasisOfOtherPropertyGivenUp" (onChangeEvent)="updateValue13($event)" [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />

        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Gain or (loss) recognized on other property given up. Subtract line 13 from line 12. Report the gain or
              (loss) in the same manner as if the exchange had been a sale
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'9'" class="number-field"
                [type]="'inputNumber'" formControlName="GainLossOnOtherPropertyGivenUp"
                [(ngModel)]="GainLossOnOtherPropertyGivenUp" [funType]="'typeTwo'" [placeholder]="''"
                class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Cash received, FMV of other property received, plus net liabilities assumed by other party, reduced (but
              not below zero) by any exchange expenses you incurred
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'10'" (onChangeEvent)="updateValue15($event)"
                class="number-field" [type]="'inputNumber'" [funType]="'typeTwo'"
                formControlName="CashFMVAndNetLiabRedByExpenses" [(ngModel)]="CashFMVAndNetLiabRedByExpenses"
                [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex mt-2 justify-content-between row">
            <div class="col-md-12">
              a. Description of other property received
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="list-content a-textarea-box a-field-length-validation form-inlines">
            <dst-custom-input class="custom-input-100 p-0" [type]="'textarea'" [label]="''" [maxLength]="1000"
              [value]="DescriptionOfOtherPropertyRcvd" (valChange)="DescriptionOfOtherPropertyRcvd = $event;">
            </dst-custom-input>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              FMV of Like-Kind property you received
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'11'" (onChangeEvent)="updateValue16($event)"
                class="number-field" [type]="'inputNumber'" [funType]="'typeTwo'"
                formControlName="FMVOfLikeKindPropertyReceived" [(ngModel)]="FMVOfLikeKindPropertyReceived"
                [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />

        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Add lines 15 and 16
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'12'" [isDisabled]="true" class="number-field"
                [type]="'inputNumber'" formControlName="AmountRealized" [(ngModel)]="AmountRealized"
                [funType]="'typeTwo'" [placeholder]="''" class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Adjusted basis of Like-Kind property you gave up, net amounts paid to other party, plus any exchange
              expenses not used on line 15
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'13'" (onChangeEvent)="updateValue18($event)"
                [type]="'inputNumber'" [funType]="'typeTwo'" formControlName="AdjBasisOfLikeKindPropGivenUp"
                class="number-field" [(ngModel)]="AdjBasisOfLikeKindPropGivenUp" [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Realized gain or (loss). subtract line 18 from line 17
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'14'" [type]="'inputNumber'"
                formControlName="RealizedGainOrLoss" class="number-field" [(ngModel)]="RealizedGainOrLoss"
                [funType]="'typeTwo'" [placeholder]="''" class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Enter the smaller of line 15 or line 19, but not less than zero
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'15'" [type]="'inputNumber'"
                formControlName="SmallerGainOrLoss" class="number-field" [(ngModel)]="SmallerGainOrLoss"
                [funType]="'typeTwo'" [placeholder]="''" class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Ordinary income under recapture rule. Enter here and on Form 4797, line 16
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'16'" [type]="'inputNumber'"
                (onChangeEvent)="updateValue21($event)" [funType]="'typeTwo'"
                formControlName="OrdinaryIncmUndRecaptureRules" class="number-field"
                [(ngModel)]="OrdinaryIncmUndRecaptureRules" [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Subtract line 21 from line 20. If zero or less, enter -0-. If more than zero, enter here and on Schedule D
              or Form 4797, unless the installment method applies
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'17'" [type]="'inputNumber'"
                formControlName="SmallerGainLossLessOrdnryIncm" class="number-field"
                [(ngModel)]="SmallerGainLossLessOrdnryIncm" [funType]="'typeTwo'" [placeholder]="''"
                class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Recognized gain. Add lines 21 and 22
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'18'" [type]="'inputNumber'"
                formControlName="RecognizedGain" class="number-field" [(ngModel)]="RecognizedGain" [funType]="'typeTwo'"
                [placeholder]="''" class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Deferred gain or (loss). Subtract line 23 from line 19
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'19'" [type]="'inputNumber'"
                formControlName="DeferredGainOrLoss" class="number-field" [(ngModel)]="DeferredGainOrLoss"
                [funType]="'typeTwo'" [placeholder]="''" class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Basis of Like-Kind property received. Subtract line 15 from the sum of lines 18 and 23
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [isDisabled]="true" [id]="'20'" [type]="'inputNumber'"
                formControlName="BasisOfLikeKindPropertyRcvd" class="number-field"
                [(ngModel)]="BasisOfLikeKindPropertyRcvd" [funType]="'typeTwo'" [placeholder]="''"
                class="total-field number-field">
              </ap-form-field>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex mt-2">
            <div class="col-md-10 pl-0">
              a. Basis of Like-Kind section 1250 property recieved
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'20'" [type]="'inputNumber'"
                formControlName="BasisOfLikeKindSect1250PropertyRcvd" class="number-field"
                [(ngModel)]="BasisOfLikeKindSect1250PropertyRcvd" [funType]="'typeTwo'" [placeholder]="''"
                class=" number-field">
              </ap-form-field>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex mt-2">
            <div class="col-md-10 pl-0">
              b. Basis of like-kind section 1245 property recieved
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'20'" [type]="'inputNumber'"
                formControlName="BasisOfLikeKindSect1245PropertyRcvd" class="number-field"
                [(ngModel)]="BasisOfLikeKindSect1245PropertyRcvd" [funType]="'typeTwo'" [placeholder]="''"
                class=" number-field">
              </ap-form-field>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex mt-2">
            <div class="col-md-10 pl-0">
              c. Basis of like-kind intangible property recieved
            </div>
            <div class="col-md-2">
              <ap-form-field [inlineBlock]="true" [label]="''" [id]="'20'" [type]="'inputNumber'"
                formControlName="BasisOfLikeKindIntagblePropertyRcvd" class="number-field"
                [(ngModel)]="BasisOfLikeKindIntagblePropertyRcvd" [funType]="'typeTwo'" [placeholder]="''"
                class=" number-field">
              </ap-form-field>
            </div>
          </div>
        </li>
      </ol>
      <div class="leid-list d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor"> /
          </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
        </div>
      </div>
    </div>

  </div>
  <ng-template #template1>
    <div class="tooltip-inner a-p-20 d-block a-min-width-340">
      <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
        Reporting of multi-asset exchanges. If you transferred and received (a) more than one group of like-kind
        properties or (b) cash or other (not like-kind) property, do not complete lines 12 through 18 of Form 8824.
        Instead, attach your own statement showing how you figured the realized and recognized gain, and enter the
        correct amount on lines 19 through 25. Report any recognized gains on your Schedule D; Form 4797, Sales of
        Business Property; or Form 6252, Installment Sale Income, whichever applies.
      </div>
    </div>
  </ng-template>
</div>
