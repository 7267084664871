import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../../create-disclosure-shared.service';
import {switchMap, tap} from 'rxjs/operators';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'view-template-sec367b-id-192',
  templateUrl: './view-template-sec367b-id-192.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec367b-id-192.component.scss']
})
export class ViewTemplateSec367bIdNeg192Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formData!: StmtData;
  attMap: any = {};
  shEntity: any = {};
  loading: boolean = false;
  s3Uploading: boolean = false;
  stockTypeOptions: any = [{data: []}];
  selStockTypeTxt: string = '';
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  primaryEntityName: string = '';
  secondaryEntityName: string = '';
  shCorpTypeTxt: string = '';

  constructor(private sharedService: CreateDisclosureSharedService,
              private dstSharedService: DSTSharedService,
              private taxTypePipe: TaxTypePipe) {
    this.loading = true;
    this.sharedService.getStockTypeListDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.stockTypeOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
      });

    this.sharedService.getLoadStatementData(-192).pipe(
      switchMap((data) => {
        this.formData = data;
        
        this.primaryEntityName = this.formData.primaryentity || '';
        this.secondaryEntityName = this.formData.secondaryentity || '';
        //load all attributes into new object for showing on UI as key value pair
        this.formData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }
          if (stmt.attributeValue !== undefined) {
            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            this.attMap[stmt.attributeName]?.push('');
          }
        });
        
        if(this.attMap['StockType304'] && this.attMap['StockType304'][0]) {
          const selectedStockType: any = this.stockTypeOptions[0].data.find((s: any) => s.id.toString() === this.attMap['StockType304'][0]);
          selectedStockType && (this.selStockTypeTxt = selectedStockType.text || '');
        }

        // console.log(this.attMap);
        return this.sharedService.getDSTEntityInfo(this.attMap.SHdspTransferorLEID);
      })
    ).subscribe((entity: any[]) => {
      if (entity[0]) {
        this.shEntity.entity = entity[0].ENTITY;
        this.shEntity.ein = entity[0].EIN;
        this.shEntity.countryInc = entity[0].COUNTRYINC;
        
        forkJoin([

          //corpTypeTxt
        this.updateEntitiesInfo(this.formData?.primaryleid!, 1),
        this.updateEntitiesInfo(this.formData?.secondaryleid!, 2),
        this.updateEntitiesInfo(entity[0].LEID!, 3)//corpTypeTxt
        
        ]).subscribe(([response1, response2, response3]) => {
          setTimeout(() => {
            const html = document.querySelector('#temp367bform_192');
            const htmlContent = html!.outerHTML;
            this.sharedService.getFormStateByFormId(-192)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
          });
        }, (error: HttpErrorResponse) => {
        
          console.log(error);
        
          this.dstSharedService.showAlert('error', error.message);
        
        }).add(() => {
          this.loading = false;
        });
        
      }
    }, (error: any) => {
      console.error(error);
      this.dstSharedService.showAlert('error', error.toString());
    }).add(() => {
      this.loading = false;
    });
    // .add(() => {
    //   this.loading = false;
    //   setTimeout(() => {    
    //     const html = document.querySelector('#temp367bform_192');
    //     const htmlContent = html!.outerHTML;
        
    //     this.sharedService.getFormStateByFormId(-192)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
    //   });
    // })
    
  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   this.loading = true;
  //   this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
  //     leid,
  //     this.attMap['DisplayDate'] != undefined ? this.attMap['DisplayDate'][0]: null,
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //       if (entityType === 1) { //primary entity
  //         (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
  //         this.primaryCorpTypeTxt = corpType;
  //       } else if (entityType === 2) { //secondary entity
  //         (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
  //         this.secondaryCorpTypeTxt = corpType;
  //       } else if (entityType === 3) { //target entity
  //         (response[0].LE_NAME) && (this.shEntity.entity = response[0].LE_NAME);
  //         this.shCorpTypeTxt = corpType;
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.loading = false;
  //   });
  // }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    this.loading = true;
    return this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
      leid,
      this.attMap['DisplayDate'] != undefined ? this.attMap['DisplayDate'][0]: null,
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
          if (response && response[0]) {
            //override entity type
            const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
            if (entityType === 1) { //primary entity
              (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
              this.primaryCorpTypeTxt = corpType;
            } else if (entityType === 2) { //secondary entity
              (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
              this.secondaryCorpTypeTxt = corpType;
            } else if (entityType === 3) { //target entity
              (response[0].LE_NAME) && (this.shEntity.entity = response[0].LE_NAME);
              this.shCorpTypeTxt = corpType;
            }
          }
        })
      })
    )
  }
  
  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 
      'Section367b-StatementPursuant351to304.pdf', this.formData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading =  false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp367bform_192');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  ngOnInit(): void {
  }

  save(): void {
    this.sharedService.setFormData(this.formData);
    this.sharedService.setSavedStatementId(this.formData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
