<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" (onSaveClick)="edit()" (onCancelClick)="cancel()" [disabled]="loading" [statementId]="viewData?.statementid" [isFiled]="viewData?.isfiled" [isQAR]="viewData?.isqar"></dst-create-disclosure-actions>
<div class="form-template-wrapper">
  <div>
    <div class="template-header-wrapper container">
      <div class="row">
        <span class="tax-return-desc">{{viewData?.dcnname}}</span>
      </div>
      <div class="row">
        <span class="tax-return-desc one-point-four">{{viewData?.taxyear}} {{taxReturnDetails}}</span>
      </div>
      <div class="row">
        <span class="form-name one-point-four">{{viewData?.statementname}}</span>
      </div>
    </div>
    <div class="section">
      <div class="entity-details">
        <span>Primary LEID:</span><span>{{viewData?.primaryleid}}</span>
      </div>
      <div class="entity-details">
        <span>Primary Name:</span><span class="template-color-blue">{{viewData?.primaryentity}}</span>
      </div>
      <div class="entity-details">
        <span>EIN:</span><span class="template-color-blue">{{viewData?.primaryein}}</span>
      </div>
      <!--    <div class="entity-details">-->
      <!--      <span></span><span class="template-color-blue">{{viewData?.primarycountryincdesc + getCorpType(viewData?.primaryalttaxtype)}}</span>-->
      <!--    </div>-->
    </div>
    <div class="section">
      <div class="entity-details">
        <span>Branch Entity LEID:</span><span class="template-color-blue">{{viewData?.branchleid}}</span>
      </div>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{viewData?.branchentity}}</span>
      </div>
      <!--    <div class="entity-details">-->
      <!--      <span>EIN:</span><span class="template-color-blue">{{viewData?.secondaryein}}</span><span>&nbsp;,&nbsp;a</span>-->
      <!--    </div>-->
      <!--    <div class="entity-details">-->
      <!--      <span></span><span class="template-color-blue">{{viewData?.secondarycountryincdesc}}</span><span>&nbsp;Corporation.</span>-->
      <!--    </div>-->
    </div>
    <div class="section">
      <span>Statement ID:</span><span class="template-color-blue">{{viewData?.statementid}}</span>
    </div>
    <div class="section">
      <span style="padding-left: 400px;"><a style="color: #d04a02; cursor: pointer;" (click)="download()">{{viewData?.attachmentname}}</a></span>
    </div>
    <div class="section" *ngIf="formType === 'XML Text Box'">
      <ap-form-field [inlineBlock]="true" [label]="'E-filing Data - Contents of the attachment:'" [id]="'1'" [type]="'textarea'"
                     [value]="viewData?.comments">
      </ap-form-field>
    </div>
  </div>
</div>
