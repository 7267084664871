<ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="dst-disclosure-viewer">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-body" [style.height]="'80vh'">
          <div class="viewport-wrapper" *ngIf="ready">
            <dst-create-disclosure-shared class="modal-viewer" [rowObj]="rowObject"></dst-create-disclosure-shared>
          </div>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('dst-disclosure-viewer')">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>

