import { Component, OnInit, Input, ViewEncapsulation, forwardRef, ViewChild, ElementRef } from '@angular/core';
// import * as Quill from './quill.min.js';
// const QuillPlugin = Quill;
declare var require: any;
let QuillPlugin = require('./quill.min.js');
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditorComponent),
  multi: true
};
@Component({
  selector: 'ap-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [EDITOR_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements OnInit, ControlValueAccessor {

  @Input() isFull: boolean = false;
  @Input() editorId: string = '';
  @Input() placeholderText: string = '';
  @ViewChild('divInput', { static: false }) divInput!: ElementRef;
  targetEdit!: HTMLElement | null;
  value: string = '';
  isFocus = false;
  isEdited = false;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};
  isFontShow: boolean = false;
  isFontSizeShow: boolean = false;
  quillPlugin: any;
  selectedFont: string = 'Helvetica';
  selectedSize: number = 3;
  textContent: string = '';
  htmlContent: string = '';
  hoverIndex = 0;
  isKeydownEventTriggered = false;

  public fonts = [
    {
        name: 'Roboto',
        value: 'Roboto'
    },
    {
        name: 'serif',
        value: 'Serif'
    },
    {
        name: 'Sans',
        value: 'Sans'
    },
    {
        name: 'Arial',
        value: 'Arial'
    },
    {
        name: 'Courier',
        value: 'Courier'
    },
    {
        name: 'Comic Sans MS',
        value: 'comic-sans-ms'
    },
    {
        name: 'Helvetica',
        value: 'Helvetica'
    },
    {
        name: 'Impact',
        value: 'Impact'
    },
    {
        name: 'Lucida Grande',
        value: 'lucida-grande'
    },
    {
        name: 'Lucida Sans',
        value: 'lucida-sans'
    },
    {
        name: 'Tahoma',
        value: 'Tahoma'
    },
    {
        name: 'Times',
        value: 'Times'
    },
    {
        name: 'Times New Roman',
        value: 'times-new-roman'
    },
    {
        name: 'Verdana',
        value: 'Verdana'
    }
  ];

  onTextAreaFocus(event: any, shouldFocus = false) {
    if(shouldFocus) {
        this.isFocus = true;
        if(this.element.nativeElement.querySelector('.ql-tooltip').classList.contains('ql-hidden'))
            this.divInput?.nativeElement.children[0].focus();
    }
  }

  enterFocus(event: any) {
      setTimeout(() => {
        event.target.focus();
      });
  }

  onTextAreaBlur(event: any) {
    this.isFocus = false;
    this.onContentChange(this.targetEdit);
    let el = this.targetEdit?.querySelector('.ql-editor');
    if (el && el.textContent?.trim() === '')
        this.isEdited = false;
  }
   
  onContentChange(element: any): void {
    this.isEdited = true;
    this.htmlContent = this.quillPlugin.root.innerHTML;
    setTimeout(() => {
      let html = '';
      let el = element.querySelector('.ql-editor');
      if (el === null) return;
      html = el.innerHTML;
      this.textContent = (el as HTMLElement).innerText;
      if(this.htmlContent === '<ol><li><br></li></ol>' ||
      this.htmlContent === '<ul><li><br></li></ul>')
        this.isFocus = true;
      if ((!html || html === '<br>'))
        html = '';
      if (typeof this.onModelChange === 'function') {
        this.onModelChange(html);
      }
    });
  }

  constructor(private element: ElementRef) { }

  ngOnInit() {
  }

  writeValue(value: any) : void {
    this.value = value;
    if (!value)
      this.isEdited = false;
    else
      this.isEdited = true;
    if (this.quillPlugin) {
        if (value)
            // this.quillPlugin.root.innerHTML = value;
            this.quillPlugin.pasteHTML(value);
        else
            this.quillPlugin.setText('');
        this.htmlContent = this.quillPlugin.root.innerHTML;
    }
    setTimeout(() => {
    let html = '';
    let el = this.targetEdit?.querySelector('.ql-editor');
    if (el === null) return;
    html = el!.innerHTML;
    if ((!html || html === '<br>'))
        html = '';
    if (typeof this.onModelChange === 'function') {
        this.onModelChange(html);
    }
    });
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  onClickPlaceHolder(event: any) {
    if (event.target.className.includes('editor-placeholder') || event.target.textContent === '')
      this.onTextAreaFocus(event.ParentNode, true);
  }

  ngAfterViewInit(): void {
    this.targetEdit = document.querySelector(`#editorContainer${this.editorId}`);
    var FontAttributor = QuillPlugin.import('attributors/style/font');
    FontAttributor.whitelist = null;
    QuillPlugin.register(FontAttributor, true);

    this.initTextEditor();
    window.document.body.addEventListener('click', (e: any) => {
      if (!e.target.className.includes('ql-editor') && !e.target.className.includes('editor-placeholder')
        && !(e.target.textContent === '' || e.target.innerHTML === '<br>'))
            this.onTextAreaBlur(event);
    });
}


keydownEditorEvent(event: any) {
    if (event.keyCode === 9 && !event.shiftKey && document.activeElement?.classList.contains('ql-editor'))
        this.onTextAreaFocus(null, true);
    else if (event.keyCode === 9 && event.shiftKey && document.activeElement?.classList.contains('ql-editor'))
    {
        // To focus the last button when shidt tab
        const controls = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        let focusable = this.element.nativeElement.querySelectorAll(controls);
        const length = [...focusable].filter(element => element.className.indexOf('btn') > -1).length;
        focusable[length - 1].focus();
        event.preventDefault();
    }
}

onKeyDownEvent(event: any) {
    let list = event.target.nextElementSibling.querySelectorAll('li');
    let hoverIndexCurrent = event.target.nextElementSibling.classList.contains('show')? this.hoverIndex : 0;
    const checkCurrentIndex = (hoverIndexCurrent: any) => {
        if(hoverIndexCurrent < 0)
                hoverIndexCurrent = 0;
        if(hoverIndexCurrent > list.length)
                hoverIndexCurrent = list.length - 1;
        return hoverIndexCurrent;
    };
    switch(event.keyCode) {
        case 38:
            if(hoverIndexCurrent > 0)
                hoverIndexCurrent -= 1;
            hoverIndexCurrent = this.isKeydownEventTriggered === false? this.hoverIndex : checkCurrentIndex(hoverIndexCurrent);
            list.forEach((element: any) => {
                element.classList.remove('hovered');
            });
            list[hoverIndexCurrent].classList.add('hovered');
            this.hoverIndex = hoverIndexCurrent;
            this.isKeydownEventTriggered = true;
            event.preventDefault();
            break;
        case 40:
            if(hoverIndexCurrent < list.length - 1)
                hoverIndexCurrent += 1;
            hoverIndexCurrent = this.isKeydownEventTriggered === false? this.hoverIndex : checkCurrentIndex(hoverIndexCurrent);
            list.forEach((element: any) => {
                element.classList.remove('hovered');
            });
            list[hoverIndexCurrent].classList.add('hovered');
            this.hoverIndex = hoverIndexCurrent;
            this.isKeydownEventTriggered = true;
            event.preventDefault();
            break;
        case 13:
            if(event.target.nextElementSibling.classList.contains('show')) {
                if(event.target.name === 'selFontSize') {
                    const sizes = [5, 3, 1];
                    this.selectFontSizeByNumber(sizes[hoverIndexCurrent]);
                } else if(event.target.name === 'selFontName') {
                    this.selectFontByName(this.fonts[hoverIndexCurrent].name);
                }
                setTimeout(() => {
                    event.target.focus();
                });
                event.target.nextElementSibling.classList.remove('show');
                event.preventDefault();
            } else if(event.target.name === 'selFontSize' || event.target.name === 'selFontName') {
                setTimeout(() => {
                    event.target.focus();
                });
                if(!event.target.nextElementSibling.classList.contains('show'))
                    event.target.nextElementSibling.classList.add('show');
            }
            this.hoverIndex = 0;
            this.isKeydownEventTriggered = false;
            break;
        case 9:
            event.target.nextElementSibling.classList.remove('show');
            this.hoverIndex = 0;
            this.isKeydownEventTriggered = false;
            break;
    }
}

selectFont() {
  this.isFontShow = true;
  this.isFontSizeShow = false;
}

hideSelectFont() {
  this.isFontShow = false;
}

selectFontSize() {
  this.isFontSizeShow = true;
  this.isFontShow = false;
}

hideSelectFontSize() {
  this.isFontSizeShow = false;
}

// initSimpleTextEditor() {
//     this.quillPlugin = new QuillPlugin(`#editorContainer${this.editorId}`, {
//         modules: {
//             toolbar: `#toolbar${this.editorId}`
//         },
//         history: {
//             delay: 0,
//             maxStack: 1000,
//             userOnly: true
//         },
//         theme: 'snow'
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'B',
//         ctrlKey: true
//     }, (range, context) => {
//         let { bold } = this.quillPlugin.getFormat(range);
//         if (!bold)
//             this.quillPlugin.format('bold', true);
//         else
//             this.quillPlugin.format('bold', !bold);
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'I',
//         ctrlKey: true
//     }, (range, context) => {
//         let { italic } = this.quillPlugin.getFormat(range);
//         if (!italic)
//             this.quillPlugin.format('italic', true);
//         else
//             this.quillPlugin.format('italic', !italic);
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'L',
//         ctrlKey: true
//     }, (range, context) => {
//         this.quillPlugin.format('align', '');
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'E',
//         ctrlKey: true
//     }, (range, context) => {
//         this.quillPlugin.format('align', 'center');
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'R',
//         ctrlKey: true
//     }, (range, context) => {
//         this.quillPlugin.format('align', 'right');
//     });
//
//     this.quillPlugin.keyboard.addBinding({
//         key: 'J',
//         ctrlKey: true
//     }, (range, context) => {
//         this.quillPlugin.format('align', 'justify');
//     });
//
//     let selectStart, selectLength;
//     // Font select
//     this.quillPlugin.on('selection-change', (range, oldRange, source) => {
//         if (range) {
//             selectStart = range.index;
//             selectLength = range.length;
//             const text = this.quillPlugin.getFormat(range.index, range.length || 1);
//             const { font, size } = text;
//             this.selectedFont = font? font : 'Helvetica';
//             switch (size) {
//               case 'huge':
//                 this.selectedSize = 5;
//                 break;
//               case 'small':
//                 this.selectedSize = 1;
//                 break;
//               default:
//                 this.selectedSize = 3;
//                 break;
//             }
//         }
//     });
//     // Fix text selection change issue
//     document.querySelector(`#toolbar${this.editorId}`).querySelector('[name*=selFont]').addEventListener('click', () => {
//         this.quillPlugin.setSelection(selectStart, selectLength);
//     });
// }

selectFontByName(name: string) {
  this.quillPlugin.format('font', name);
  this.selectedFont = name;
}

selectFontSizeByNumber(size: number) {
    switch (size) {
      case 5:
            this.quillPlugin.format('size', 'huge');
            break;
      case 3:
          this.quillPlugin.format('size', false);
          break;
      case 1:
            this.quillPlugin.format('size', 'small');
            break;
      default:
        break;
    }
    this.selectedSize = size;
}

initTextEditor() {
    this.quillPlugin = new QuillPlugin(`#editorContainer${this.editorId}`, {
        modules: {
            toolbar: `#toolbar${this.editorId}`
        },
        history: {
            delay: 0,
            maxStack: 1000,
            userOnly: true
        },
        theme: 'snow'
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'B',
        ctrlKey: true
    }, (range: any, context: any) => {
        let { bold } = this.quillPlugin.getFormat(range);
        if (!bold)
            this.quillPlugin.format('bold', true);
        else
            this.quillPlugin.format('bold', !bold);
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'I',
        ctrlKey: true
    }, (range: any, context: any) => {
        let { italic } = this.quillPlugin.getFormat(range);
        if (!italic)
            this.quillPlugin.format('italic', true);
        else
            this.quillPlugin.format('italic', !italic);
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'U',
        ctrlKey: true
    }, (range: any, context: any) => {
        let { underline } = this.quillPlugin.getFormat(range);
        if (!underline)
            this.quillPlugin.format('underline', true);
        else
            this.quillPlugin.format('underline', !underline);
    });

    /*Tab key indent*/
    let indent = 0;
    this.quillPlugin.keyboard.bindings[9].unshift({
        key: 9,
        handler: (range: any) => {
            this.isFocus = true;
            this.quillPlugin.format('indent', ++indent);
            return false;
        }
    });
    this.quillPlugin.keyboard.bindings[9].unshift({
        key: 9,
        shiftKey: true,
        handler: (range: any) => {
            this.quillPlugin.format('indent', --indent);
            return false;
        }
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'L',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.format('align', '');
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'E',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.format('align', 'center');
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'R',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.format('align', 'right');
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'J',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.format('align', 'justify');
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'Y',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.history.redo();
    });

    this.quillPlugin.keyboard.addBinding({
        key: 'Z',
        ctrlKey: true
    }, (range: any, context: any) => {
        this.quillPlugin.history.undo();
    });

    let selectStart: any, selectLength: any;
    this.quillPlugin.on('selection-change', (range: any, oldRange: any, source: any) =>  {
        if (range) {
            selectStart = range.index;
            selectLength = range.length;
            const text = this.quillPlugin.getFormat(range.index, range.length || 1);
            const { font, size } = text;
            this.selectedFont = font? font : 'Helvetica';
            switch (size) {
              case 'huge':
                this.selectedSize = 5;
                break;
              case 'small':
                this.selectedSize = 1;
                break;
              default:
                this.selectedSize = 3;
                break;
            }
        }
    });
    // Fix text selection change issue
    document.querySelector(`#toolbar${this.editorId}`)?.querySelector('[name*=selFont]')?.addEventListener('click', () => {
        this.quillPlugin.setSelection(selectStart, selectLength);
    });

    document.querySelector(`#toolbar${this.editorId}`)?.querySelector('.btn.fa.fa-repeat')?.addEventListener('click', () => {
        let [startLatest, lengthLatest] = [selectStart, selectLength];
        this.quillPlugin.history.redo();
        this.quillPlugin.setSelection(startLatest, lengthLatest);
    });

    document.querySelector(`#toolbar${this.editorId}`)?.querySelector('.btn.fa.fa-undo')?.addEventListener('click', () => {
        let [startLatest, lengthLatest] = [selectStart, selectLength];
        this.quillPlugin.history.undo();
        this.quillPlugin.setSelection(startLatest, lengthLatest);
    });
}
}
