import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplementalInfoComponent } from './components/supplemental-info/supplemental-info.component';
import { FormFieldModule } from 'appkit-angular/form-field';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { SelectorModule } from 'appkit-angular/selector';
import { ProgressModule } from 'appkit-angular/progress';
import { FilterModule } from 'appkit-angular/filter';
import { ToggleModule } from 'appkit-angular/toggle';
import {ButtonModule} from 'appkit-angular/button';
import { DatePickerModule } from 'appkit-angular/date-picker';
import { TableModule } from 'appkit-angular/table';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { LoadingModule } from 'appkit-angular/loading';
import {ResponsiveTabModule} from 'appkit-angular/responsive-tab';
import { ModalModule } from 'appkit-angular/modal';
import { CheckboxModule } from 'appkit-angular/checkbox';
import { TooltipModule } from 'appkit-angular/tooltip';
import {AppNgAppkitModule} from '../app-ng-appkit.module';
import { SourcingDefaultMeComponent } from './components/sourcing-default-me/sourcing-default-me.component';
import { SpotratesAllCurrSyncComponent } from './components/spotrates-all-curr-sync/spotrates-all-curr-sync.component';
import { FxAmtSyncComponent } from './components/fx-amt-sync/fx-amt-sync.component';
import { BatchSourcingDefaultComponent } from './components/batch-sourcing-default/batch-sourcing-default.component';
import { SourcingDeleteRecordsComponent } from './components/sourcing-delete-records/sourcing-delete-records.component';
import { TestedUnitsSyncComponent } from './components/tested-units-sync/tested-units-sync.component';
import { FxRatesSyncComponent } from './components/fx-rates-sync/fx-rates-sync.component';
import { SourcingProjectTaggingComponent} from './components/sourcing-project-tagging/sourcing-project-tagging.component';
import { DomesticProjectTaggingComponent } from './components/domestic-project-tagging/domestic-project-tagging.component';
import { GhostUpdateCombinationComponent } from './components/ghost-update-combination/ghost-update-combination.component';
import { FgnF5471H1Component } from './components/fgn-f5471-h1/fgn-f5471-h1.component';
import { FgnOverrides163jComponent } from './components/fgn-overrides163j/fgn-overrides163j.component';
import { SettlementRedirectionComponent } from './components/settlement-redirection/settlement-redirection.component';
@NgModule({
  declarations: [SupplementalInfoComponent, SourcingDefaultMeComponent, SpotratesAllCurrSyncComponent, FxAmtSyncComponent, BatchSourcingDefaultComponent, SourcingDeleteRecordsComponent, TestedUnitsSyncComponent, FxRatesSyncComponent,SourcingProjectTaggingComponent, DomesticProjectTaggingComponent, GhostUpdateCombinationComponent, FgnF5471H1Component, FgnOverrides163jComponent,SettlementRedirectionComponent],

  imports: [
    CommonModule,
    FormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    SelectorModule,
    ProgressModule,
    FilterModule,
    ToggleModule,
    ButtonModule,
    DatePickerModule,
    TableModule,
    AppNgMaterialModule,
    LoadingModule,
    ResponsiveTabModule,
    ModalModule,
    CheckboxModule,
    TooltipModule,
    AppNgAppkitModule
  ]
})
export class ForeignModule { }
