import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import { StatementTrackerService } from '../../../../../statement-tracker.service';
import { CreateDisclosureSharedService } from '../../../create-disclosure-shared.service';

@Component({
  selector: 'gtw-view-template-form8824-id237',
  templateUrl: './view-template-form8824-id237.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form8824-id237.component.scss']
})
export class ViewTemplateForm8824Id237Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form237!: FormGroup;
  stmtData!: StmtData;
  attMap: any;
  loaded: boolean = false;
  startLoader: boolean = false;
  pshipEinMissReason: string = '';
  disableActions: boolean = false;
  partII: boolean = false;
  taxYear: string;

  constructor(private formBuilder: FormBuilder,
    private sharedService: CreateDisclosureSharedService,
    private statementTrackerService: StatementTrackerService) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
    this.taxYear = this.sharedService.getActiveFormState().taxYear.toString();
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form237 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    //load Stmt data
    this.sharedService.getLoadStatementData(237)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};
        //console.log('Data---' + this.stmtData);
        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }

        });
        if (this.attMap['ExchangeMadeWithRelatedParty'] !== undefined && this.attMap['ExchangeMadeWithRelatedParty'][0] === '1') {
          this.partII = true;
        }

      }, () => {

      }, () => {

      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      });

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
