<div id="overlay" *ngIf="startLoader">
  <ap-loading id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"></dst-create-disclosure-actions>
<div class="form-template-wrapper li-apdix-wrapper-temp">
  <div class="ap-form-field-my-wrapper">

    <div align=center>
      <b>
        <span class="template-color-blue" *ngIf="loaded">
          {{ stmtData.dcnname }}
        </span>
      </b>
    </div>
    <div align="center">
      <B>
        <span class="template-color-blue" *ngIf="loaded">
          {{ stmtData.taxyear }} CONSOLIDATED FEDERAL INCOME TAX RETURN<br/>
          Form 8883 - Asset Allocation Statement Under Section 338
          <br/>
        </span>
      </B>
    </div>
    <br/>

    <div id="form8883" *ngIf="loaded">

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I&nbsp;&nbsp;</strong>
            <strong>Filer's Identifying Information</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content">
          <li>
            <ol class="inner-list">
              <li>
                <div class="entity-details">
                  <span>Name of Filer:</span><span class="template-color-blue">{{ stmtData.secondaryentity == null ? '' :
                                                                                  stmtData.secondaryentity }}</span>
                </div>
              </li>
              <li>
                <div class="entity-details">
                  <span>Identifying number:</span><span class="template-color-blue">{{ stmtData.secondaryein == null ? 'NA'
                                                                                                                     : stmtData.secondaryein }}</span>
                </div>
              </li>
              <li>
                <div class="entity-details">
                  <span>Check applicable box:</span><span class="template-color-blue">
                    {{ attMap['Choice1cTarget'] !== undefined ? (attMap['Choice1cTarget'][0] === 'Old' ? 'Old Target': (attMap['Choice1cTarget'][0] === 'New' ? 'New Target': '')) : '' }}</span>
                </div>
              </li>
              <li>
                <div class="entity-details">
                  <span>Was a valid and timely Form 8023 filed?</span><span class="template-color-blue">
                    {{ attMap['Choice1dDate'] !== undefined ? attMap['Choice1dDate'][0] : '' }} </span>
                </div>
                <div class="entity-details">
                  <span> If yes, enter date filed: </span>
                  <span class="template-color-blue" *ngIf="(attMap['Choice1dDate'] !== undefined) && (attMap['Choice1dDate'][0] === 'Yes')">
                    {{ attMap['Yes1dDateText'] !== undefined ? attMap['Yes1dDateText'][0] : '' }} </span>
                </div>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part II&nbsp;&nbsp;</strong>
            <strong>Other Party's Identifying Information</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content" start="2" [hidden]="OtherPartyUSEnt === undefined">
          <li>
            <ol class="inner-list">
              <li>
                <div class="entity-details">
                  <span>Name of other party to the transaction</span><span
                  class="template-color-blue">{{ attMap['OtherParty'] !== undefined ? attMap['OtherParty'][0] : '' }}</span>
                </div>
              </li>
              <li>
                <div class="part-two">
                  <div class="entity-details">
                    <span>EIN:</span><span class="template-color-blue">{{ attMap['EINAppOP'] !== undefined && attMap['EINAppOP'][0] ? attMap['EINAppOP'][0] : (attMap['SNAppOP'] !== undefined && attMap['SNAppOP'][0] ? attMap['SNAppOP'][0] : 'N/A') }}</span>
                  </div>
                </div>
              </li>

              <li class="list-style-none" style="list-style: none;">
                <div class="part-two" *ngIf="((attMap['EINAppOP'] !== undefined) && (!attMap['EINAppOP'][0])) && ((attMap['SNAppOP'] !== undefined) && (!attMap['SNAppOP'][0])) && ((attMap['EINAppMROP'] !== undefined) && (attMap['EINAppMROP'][0] != '-1'))">
                  <div class="entity-details">
                    <span>EIN Missing Reason:</span><span class="template-color-blue">{{ attMap['EINAppMROP'][0] }}</span>
                  </div>
                </div>
                <div class="part-two">
                  <div class="entity-details">
                    <span>Address Line 1:</span><span
                    class="template-color-blue">{{ attMap['AddressOfOtherParty1'] !== undefined ? attMap['AddressOfOtherParty1'][0] : '' }}</span>
                  </div>
                </div>
                <div class="part-two">
                  <div class="entity-details">
                    <span>Address Line 2:</span><span
                    class="template-color-blue">{{ attMap['AddressOfOtherParty2'] !== undefined ? attMap['AddressOfOtherParty2'][0] : '' }}</span>
                  </div>
                </div>
                <div class="part-two">
                  <div class="entity-details">
                    <span>City:</span><span
                    class="template-color-blue">{{ attMap['CityOfOtherParty'] !== undefined ? attMap['CityOfOtherParty'][0] : '' }}</span>
                  </div>
                </div>
                <div class="part-two" *ngIf="attMap['OtherPartyUSEnt'] !== undefined && attMap['OtherPartyUSEnt'][0] === 'Yes'">
                  <div class="entity-details">
                    <span>State:</span><span
                    class="template-color-blue">{{ stateValue }}</span>
                  </div>
                </div>
                <div class="part-two" *ngIf="attMap['OtherPartyUSEnt'] !== undefined && attMap['OtherPartyUSEnt'][0] === 'No'">
                  <div class="entity-details">
                    <span> Province or State:</span><span
                    class="template-color-blue">{{ attMap['ProvOrStateOP'] !== undefined ? attMap['ProvOrStateOP'][0] : '' }}</span>
                  </div>
                </div>
                <div class="part-two" *ngIf="attMap['OtherPartyUSEnt'] !== undefined && attMap['OtherPartyUSEnt'][0] === 'No'">
                  <div class="entity-details">
                    <span> Country:</span><span class="template-color-blue">{{ countryValue }}</span>
                  </div>
                </div>
                <div class="part-two" *ngIf="attMap['OtherPartyUSEnt'] !== undefined && attMap['OtherPartyUSEnt'][0] === 'No'">
                  <div class="entity-details">
                    <span> Postal Code:</span><span
                    class="template-color-blue">{{ attMap['ZIPCodeOfOtherParty'] !== undefined ? attMap['ZIPCodeOfOtherParty'][0] : '' }}</span>
                  </div>
                </div>
                <div class="part-two" *ngIf="attMap['OtherPartyUSEnt'] !== undefined && attMap['OtherPartyUSEnt'][0] === 'Yes'">
                  <div class="entity-details">
                    <span> Zip Code:</span><span
                    class="template-color-blue">{{ attMap['ZIPCodeOfOtherParty'] !== undefined ? attMap['ZIPCodeOfOtherParty'][0] : ''}}</span>
                  </div>
                </div>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III&nbsp;&nbsp;</strong>
            <strong>Target Corporation's Identifying Information</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content" start="3" [hidden]="TargetCorpUSEnt === undefined">
          <li>
            <ol class="inner-list">
              <li>

                <div class="entity-details">
                  <span>Name of Target Corporation:</span><span
                  class="template-color-blue">{{ stmtData.primaryentity == null ? '' : stmtData.primaryentity }}</span>
                </div>
              </li>
              <li>
                <div class="entity-details">
                  <span>Employer identification number:</span><span
                  class="template-color-blue">{{ stmtData.primaryein == null ? 'N/A' : stmtData.primaryein }}</span>
                </div>
                <div class="entity-details" *ngIf="!stmtData.primaryein">
                  <span>EIN Missing Reason:</span><span
                  class="template-color-blue">{{ attMap['EINAppTC'] !== undefined ? attMap['EINAppTC'][0] : '' }}</span>
                </div>
              </li>
              <li>
                <div class="entity-details">
                  <span *ngIf="TargetCorpUSEnt === true">State:</span><span *ngIf="TargetCorpUSEnt === false">Province or
                    State</span><span class="template-color-blue">{{ stmtData.primarystate == null ? '' : stmtData.primarystate }}</span>
                </div>
                <div class="entity-details">
                  <span>Address Line 1:</span><span class="template-color-blue">{{ stmtData.primaryaddress1 == null ? '' : stmtData.primaryaddress1 }}</span>
                </div>
                <div class="entity-details">
                  <span>Address Line 2:</span><span class="template-color-blue">{{ stmtData.primaryaddress2 == null ? '' : stmtData.primaryaddress2 }}</span>
                </div>
                <div class="entity-details">
                  <span>City / Town:</span><span class="template-color-blue">{{ stmtData.primarycity == null ? '' : stmtData.primarycity }}</span>
                </div>
                <div class="entity-details">
                  <span>Country:</span><span class="template-color-blue">{{ stmtData.primarycountryname == null ? '' : stmtData.primarycountryname }}</span>
                </div>
                <div class="entity-details">
                  <span *ngIf="TargetCorpUSEnt === true">Zip Code:</span><span *ngIf="TargetCorpUSEnt === false">Postal
                    Code:</span><span class="template-color-blue">{{ stmtData.primaryzip == null ? '' : stmtData.primaryzip }}</span>
                </div>
                <div class="entity-details">
                  <span>State of Incorportation:</span><span
                  class="template-color-blue">{{ stmtData.primarystateincdesc == null ? '' : stmtData.primarystateincdesc }}</span>
                </div>
                <div class="entity-details">
                  <span>Country of Incorporation:</span><span
                  class="template-color-blue">{{ stmtData.primarycountryincdesc == null ? '' : stmtData.primarycountryincdesc }}</span>
                </div>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part IV&nbsp;&nbsp;</strong>
            <strong>General Information</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper" style="padding-left: 30px;">

        <ol class="mb-0 list-content inline-list items-2 clearfix" start="4">
          <li class="pt-3 li-apdix-a" style="counter-reset: count 3;">
            <div class="row">
              <div class="col-md-3">
                Acquisition Date
              </div>
              <div class="col-md-6 align-date">
                <span class="template-color-blue">{{ attMap['AcquisionDate'] !== undefined ? attMap['AcquisionDate'][0] : '' }}</span>
              </div>
            </div>
          </li>
          <li class="pt-3 li-apdix-b" style="counter-reset: count 3;">
            What percentage of target corporation stock was purchased:
          </li>
        </ol>

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <span>(i) &nbsp;</span><span>During the 12-month acquisition period? </span>&nbsp;<span
            class="template-color-blue">{{ PctDuring12Month }}</span>&nbsp;%
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <span>(ii) &nbsp;</span><span>On the acquisition date? &nbsp;&nbsp; </span><span
            class="template-color-blue">{{ PctOnAcquisition }}</span>&nbsp;%
          </div>
        </div>

        <ol class="mb-0 list-content inline-list items-4 clearfix" start="5">
          <li class="pt-3 li-apdix-a" style="counter-reset: count 4;">
            <div>
              <div>
                Stock price?
              </div>
              <div class=" part-two">
                <span *ngIf="(attMap['StockPrice'] !== undefined) && (attMap['StockPrice'][0] !== '')">$ &nbsp;</span> <span
                class="template-color-blue">{{ attMap['StockPrice'][0] | amount }}</span>
              </div>
            </div>
          </li>
          <li class="pt-3 li-apdix-b" style="counter-reset: count 4;">
            <div>
              <div>
                Acquisition costs/Selling costs
              </div>
              <div class="">
                <span *ngIf="(attMap['AcquisitionCostSC'] !== undefined) && (attMap['AcquisitionCostSC'][0] !== '')">$ &nbsp;</span><span
                class="template-color-blue">{{ attMap['AcquisitionCostSC'][0] | amount }}</span>
              </div>
            </div>
          </li>
          <li class="pt-3 li-apdix-c" style="counter-reset: count 4;">
            <div>
              <div>
                Target liabilities
              </div>
              <div class="">
                <span *ngIf="(attMap['TargetLiabilities'] !== undefined) && (attMap['TargetLiabilities'][0] !== '')">$ &nbsp;</span><span
                class="template-color-blue">{{ attMap['TargetLiabilities'][0] | amount }}</span>
              </div>
            </div>
          </li>
          <li class="pt-3 li-apdix-d" style="counter-reset: count 4;">
            <div>
              <div class="">
                AGUB/ADSP
              </div>
              <div class="">
                <span *ngIf="(attMap['AGUBorADSP'] !== undefined) && (attMap['AGUBorADSP'][0] !== '')">$ &nbsp;</span><span
                class="template-color-blue">{{attMap['AGUBorADSP'][0] | amount}}</span>
              </div>
            </div>
          </li>
        </ol>

        <ol start="6">

          <li>
            <div class="row">
              <div class="col-md-9">
                Was the filer listed in Part I, above a member of an affiliated group of corporation before the
                acquisition date?
              </div>
              <div class="col-md-1" style="text-align: center;">
                <span class="template-color-blue">{{ attMap['Choice6'] !== undefined ? attMap['Choice6'][0] : '' }}</span>
              </div>
            </div>
          </li>

          <li>
            <div class="row">
              <div class="col-md-9">
                Was the target corporation a member of an affiliated group before the acquisition date?
              </div>
              <div class="col-md-1" style="text-align: center;">
                <span class="template-color-blue">{{ attMap['Choice7'] !== undefined ? attMap['Choice7'][0] : '' }}</span>
              </div>
            </div>
          </li>

          <li>
            Is the target corporation or any target affiliate:
            <ol class="inner-list section-content pr-5">
              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A controlled foreign corporation?
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8a'] !== undefined ? attMap['Choice8a'][0] : '' }}</span>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="col-md-9">
                    If "No", check here if it was a CFC at any time during the preceding 5 years
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['IsCFCPrev5Yrs'] !== undefined ? attMap['IsCFCPrev5Yrs'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A foreign corporation with income, gain, or loss effectively connected with the conduct of a trade
                    or
                    business within the United States (including U.S. real property interests)?
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8b'] !== undefined ? attMap['Choice8b'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A qualifying foreign target under Regulations section 1.338-2(e)(1)(iii)
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8c'] !== undefined ? attMap['Choice8c'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A corporation electing under section 1504(d) or section 953(d)?
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8e'] !== undefined ? attMap['Choice8e'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A domestic international sales corporation (DISC)?
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8f'] !== undefined ? attMap['Choice8f'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div class="col-md-9">
                    A passive foreign investment company (PFIC)?
                  </div>
                  <div class="col-md-2" style="text-align: center;">
                    <span class="template-color-blue">{{ attMap['Choice8g'] !== undefined ? attMap['Choice8g'][0] : '' }}</span>
                  </div>
                </div>
              </li>

              <li [hidden]="Choice8g !== true">
                <div class="d-flex">
                  <div class="col-md-9">
                    If the answer to item 8f is "Yes", is the PFIC a pedigreed qualified electing fund?
                  </div>
                  <div class="col-md-2">
                    <span class="template-color-blue">{{ attMap['Choice8h'] !== undefined ? attMap['Choice8h'][0] : '' }}</span>
                  </div>
                </div>
              </li>
            </ol>
          </li>

        </ol>

      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part V&nbsp;&nbsp;</strong>
            <strong>Original Statement of Assets Transferred</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content" start="9">
          <li>
            <table class="table-layout" style="width: 80%;">
              <thead>
              <tr>
                <td class="col-md-4">
                  Assets
                </td>
                <td class="col-md-4">
                  Aggregate fair market value<br/>
                  (actual amount for Class I)<br/>
                </td>
                <td class="col-md-4" style="text-align: end;">
                  Allocation of AGUB or ADSP<br/>
                </td>
              </tr>
              </thead>
              <tbody>
              <br/>
              <tr>
                <td class="col-md-4 row-label">
                  Class I
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class1AFMV'] !== undefined) && ((attMap['Class1AFMV'][0] | amount) !== '')">
                      {{ attMap['Class1AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class1AGUBOrADSP'] !== undefined) && ((attMap['Class1AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class1AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Class II
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class2AFMV'] !== undefined) && ((attMap['Class2AFMV'][0] | amount) !== '')">
                      {{ attMap['Class2AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class2AGUBOrADSP'] !== undefined) && ((attMap['Class2AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class2AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Class III
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class3AFMV'] !== undefined) && ((attMap['Class3AFMV'][0] | amount) !== '')">
                      {{ attMap['Class3AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class3AGUBOrADSP'] !== undefined) && ((attMap['Class3AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class3AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Class IV
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class4AFMV'] !== undefined) && ((attMap['Class4AFMV'][0] | amount) !== '')">
                      {{ attMap['Class4AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class4AGUBOrADSP'] !== undefined) && ((attMap['Class4AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class4AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Class V
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue" *ngIf="(attMap['Class5AFMV'] !== undefined) && ((attMap['Class5AFMV'][0] | amount) !== '')">
                      {{ attMap['Class5AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class5AGUBOrADSP'] !== undefined) && ((attMap['Class5AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class5AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Class VI and VII
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class6N7AFMV'] !== undefined) && ((attMap['Class6N7AFMV'][0] | amount) !== '')">
                      {{ attMap['Class6N7AFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class6N7AGUBOrADSP'] !== undefined) && ((attMap['Class6N7AGUBOrADSP'][0] | amount) !== '')">
                      {{ attMap['Class6N7AGUBOrADSP'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-4 row-label">
                  Total
                </td>
                <td class="col-md-4 pr-5">
                  <div class="text-align-end">
                    <span *ngIf="(attMap['TotalAFMV'] !== undefined) && ((attMap['TotalAFMV'][0] | amount) !== '')">$ &nbsp;</span><span
                    class="template-color-blue">{{ attMap['TotalAFMV'][0] | amount }}</span>
                  </div>
                </td>
                <td class="col-md-4 pl-5">
                  <div class="text-align-end">
                    <span *ngIf="(attMap['TotalAGUB'] !== undefined) && ((attMap['TotalAGUB'][0] | amount) !== '')">$ &nbsp;</span><span
                    class="template-color-blue">{{ attMap['TotalAGUB'][0] | amount }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </li>
        </ol>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part VI&nbsp;&nbsp;</strong>
            <strong>Supplemental Statement of Assets Transferred</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content" start="10">
          <li>
            <div class="">
              <div class="col-md-6">
                Enter the tax year and tax return form number with the original Form 8023 or Form 8883 and any
                supplemental statements that were filed.
              </div>
              <div class="col-md-3">
                <span>Year:</span><span class="template-color-blue pl-2">{{ attMap['TaxYear'] !== undefined ? attMap['TaxYear'][0] : '' }}</span>
              </div>
              <div class="col-md-3">
                <span>Form:</span><span
                class="template-color-blue pl-2">{{ attMap['TaxReturnFormNumber'] !== undefined ? attMap['TaxReturnFormNumber'][0] : '' }}</span>
              </div>
            </div>
          </li>
          <br/>
          <li>
            <table class="table-layout supp-table">
              <thead>
              <tr>
                <td class="col-md-3">
                  Assets
                </td>
                <td class="col-md-3">
                  Allocation of sales price as previously reported
                </td>
                <td class="col-md-3">
                  Increase or (decrease)
                </td>
                <td class="col-md-3">
                  Redetermination of allocation of AGUB or ADSP
                </td>
              </tr>
              </thead>
              <tbody>
              <br/>
              <tr>
                <td class="col-md-3 row-label">
                  Class I
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class1ASPPR'] !== undefined) && ((attMap['Class1ASPPR'][0] | amount) !== '')">
                      {{attMap['Class1ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class1IncDec'] !== undefined) && ((attMap['Class1IncDec'][0] | amount) !== '')">
                      {{attMap['Class1IncDec'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class1RtnAllAGUB'] !== undefined) && ((attMap['Class1RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class1RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Class II
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class2ASPPR'] !== undefined) && ((attMap['Class2ASPPR'][0] | amount) !== '')">
                      {{attMap['Class2ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class2IncDec'] !== undefined) && ((attMap['Class2IncDec'][0] | amount) !== '')">
                      {{attMap['Class2IncDec'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class2RtnAllAGUB'] !== undefined) && ((attMap['Class2RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class2RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Class III
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class3ASPPR'] !== undefined) && ((attMap['Class3ASPPR'][0] | amount) !== '')">
                      {{attMap['Class3ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class3IncDec'] !== undefined) && ((attMap['Class3IncDec'][0] | amount) !== '')">
                      {{attMap['Class3IncDec'][0] | amount}}</span>

                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class3RtnAllAGUB'] !== undefined) && ((attMap['Class3RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class3RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Class IV
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class4ASPPR'] !== undefined) && ((attMap['Class4ASPPR'][0] | amount) !== '')">
                      {{attMap['Class4ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class4IncDec'] !== undefined) && ((attMap['Class4IncDec'][0] | amount) !== '')">
                      {{attMap['Class4IncDec'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class4RtnAllAGUB'] !== undefined) && ((attMap['Class4RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class4RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Class V
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class5ASPPR'] !== undefined) && ((attMap['Class5ASPPR'][0] | amount) !== '')">
                      {{attMap['Class5ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class5IncDec'] !== undefined) && ((attMap['Class5IncDec'][0] | amount) !== '')">
                      {{attMap['Class5IncDec'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class5RtnAllAGUB'] !== undefined) && ((attMap['Class5RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class5RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Class VI and VII
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class6N7ASPPR'] !== undefined) && ((attMap['Class6N7ASPPR'][0] | amount) !== '')">
                      {{attMap['Class6N7ASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue" *ngIf="(attMap['Class6N7IncDec'] !== undefined) && ((attMap['Class6N7IncDec'][0] | amount) !== '')">
                      {{attMap['Class6N7IncDec'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span class="template-color-blue"
                          *ngIf="(attMap['Class6N7RtnAllAGUB'] !== undefined) && ((attMap['Class6N7RtnAllAGUB'][0] | amount) !== '')">
                      {{attMap['Class6N7RtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 row-label">
                  Total
                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span *ngIf="(attMap['TotalASPPR'] !== undefined) && ((attMap['TotalASPPR'][0] | amount) !== '')">$ &nbsp;</span><span
                    class="template-color-blue">{{attMap['TotalASPPR'][0] | amount}}</span>
                  </div>
                </td>
                <td class="col-md-3">

                </td>
                <td class="col-md-3">
                  <div class="" style="text-align: right;">
                    <span *ngIf="(attMap['TotalRtnAllAGUB'] !== undefined) && ((attMap['TotalRtnAllAGUB'][0] | amount) !== '')">$ &nbsp;</span><span
                    class="template-color-blue">{{attMap['TotalRtnAllAGUB'][0] | amount}}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </li>
          <br/>
          <li>
            <span>Reason(s) for increase or decrease:</span><br/>
            <!-- <textarea rows="5" cols="80" class="txtborder w-100" readonly>{{attMap['RsnForIncOrDec'] == undefined ? '' : (attMap['RsnForIncOrDec'][0] ? attMap['RsnForIncOrDec'][0] : 'None')}}</textarea> -->
            <div class="mt-2 mb-2">{{attMap['RsnForIncOrDec'] == undefined ? '' : (attMap['RsnForIncOrDec'][0] ? attMap['RsnForIncOrDec'][0] : '')}}</div>
          </li>
        </ol>
      </div>

      <table class="w-100">
        <tbody>
        <tr>
          <td class="footer-left">
            LEIDs: <span class="template-color-blue"> {{ stmtData.secondaryleid == null ? '' : stmtData.secondaryleid }}</span> / <span
            class="template-color-blue"> {{ stmtData.primaryleid == null ? '' : stmtData.primaryleid }}</span>
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{ stmtData.statementid }}</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>

  </div>
</div>
