import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ScreenDocumentsComponent } from '../../screen-docs/screen-documents.component';
import { ScreenVideo } from '../../screen-video-container/screen-video-list/screen-video';
import { ScreenVideoDialogComponent } from '../../screen-video-container/screen-video-list/screen-video-dialog/screen-video-dialog.component';
import { ScreenVideoListService } from '../../screen-video-container/screen-video-list/screen-video-list.service';
import { ActiveScreensService } from '../../shared/_services/active-screens.service';
import { GtwSnackbarService } from '../../shared/_services/gtw-snackbar.service';
import { ScreenDetailsModel } from './screen-details-model';
import { ScreenDetailsService } from './screen-details.service';


interface ScreenDocument extends Document {
  pictureInPictureElement:any;
  requestPictureInPicture(): any;
  exitPictureInPicture: (() => Promise<any>) ;
}
@Component({
  selector: 'gtw-screen-details',
  templateUrl: './screen-details.component.html',
  styleUrls: ['./screen-details.component.scss']
})
export class ScreenDetailsComponent implements OnInit {

  @Input('is-card')
  isCard = false;
  
  dialogRef:MatDialogRef<ScreenVideoDialogComponent, any> | undefined ;
  dialogRefDocument:MatDialogRef<ScreenDocumentsComponent, any> | undefined;

  @Input('screen-details')
  screenDetails!: ScreenDetailsModel;

  @Output('screen-details')
  screenDetailsChange = new EventEmitter<ScreenDetailsModel>();

  @Input('client-key')
  clientKey: number = 0;

  @Input('base-url-admin')
  baseUrlAdmin = '/admin';

  @Input('base-url')
  baseUrl = '/gtw';

  scenario: string ='';
  @Input('scenario')
  set _scenario(value:string){
    this.scenario = value;
  }
  
  tax_year: string ='';
  @Input('tax_year') 
  set _tax_year(value:string){
    this.tax_year = value;
  }

  @Output('load-screen')
  loadScreen = new EventEmitter<string>();

  breadCrumbs: any = [];

  constructor(private screenVideoListService : ScreenVideoListService,
              private gtwSnackbarService:GtwSnackbarService,
              public matDialog: MatDialog,
              public activeScreenService: ActiveScreensService,
              private screenDetailsService:ScreenDetailsService) { }

  ngOnInit(): void {
  }

  getVideo() {

    let videoId = -1;

    if(!this.screenDetails.HASACCESS || !this.screenDetails.HAS_VIDEO){
      this.gtwSnackbarService.openSnackBar("Sorry!! You don't have the access","gtw-snackbar--yellow");
      return ;
    }
    if(this.screenDetails.VIDEO_IDS && this.screenDetails.VIDEO_IDS.indexOf(',') >0 ){
       videoId = parseInt(this.screenDetails.VIDEO_IDS?.split(',')[0]);
    }else if(this.screenDetails.VIDEO_IDS){
      videoId = parseInt(this.screenDetails.VIDEO_IDS)
    }

    this.screenVideoListService
    .getVideoById(this.baseUrlAdmin,videoId)
    .subscribe((video:any)=>{
        if(video.callSuccess == 1 && video.data) {
          this.playVideo(video.data);
        } else {
          this.gtwSnackbarService.openSnackBar('Sorry!! No video found','gtw-snackbar--yellow');
        }
    },()=>{
      this.gtwSnackbarService.openSnackBar('Sorry!! No video found','gtw-snackbar--yellow');
    },()=>{

    });

  }

  async playVideo(video:ScreenVideo){

      this.matDialog.closeAll();
      this.dialogRef?.close();

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {...video,...{baseURL:this.baseUrlAdmin}};
      dialogConfig.panelClass ='remove-dialog-css';
      dialogConfig.hasBackdrop = false;
      dialogConfig.width = '30vw';
      dialogConfig.position= { right: '0px', top: '55vh' },
      dialogConfig.id = video?.video_id?.toString();

      if ((document as ScreenDocument).pictureInPictureElement) {
        await ((document as ScreenDocument).exitPictureInPicture());
      }

      this.dialogRef = undefined;
      this.dialogRef = this.matDialog.open(ScreenVideoDialogComponent,dialogConfig);
  }

  loadClickedScreen(screenDetails:ScreenDetailsModel){
    this.activeScreenService.markOpenSelectedScreen(screenDetails);
    this.loadScreen.emit('loadClickedScreen');
  }

  downloadTemplate() {
    this.screenDetailsService.downloadTemplate(this.baseUrl,this.screenDetails.SCREEN_KEY+"", this.clientKey, this.tax_year, this.scenario);
  }
  
  checkImportScreen(){

    if( this.screenDetails.SCREENS_CATEGORY_LIST &&
      this.screenDetails.SCREENS_CATEGORY_LIST?.split(',').indexOf('2') >= 0){
        return true;
    }

    return false;
  }

  onDestroy(){
  }
  openDocumentTemplate(){
    if(this.screenDetails.HAS_DOCUMENT != '0' || this.screenDetails.HAS_VIDEO != '0'){
      const dialogConfig = new MatDialogConfig();
      //  width: 1200px;
      dialogConfig.width = '80vw';

      this.dialogRefDocument?.close();
      dialogConfig.data = {screen_key:this.screenDetails.SCREEN_KEY,client_key:this.screenDetails.CLIENT_KEY,
       screenName:this.screenDetails.SCREEN_NAME,baseUrl:this.baseUrlAdmin};
     this.dialogRefDocument = this.matDialog.open(ScreenDocumentsComponent,dialogConfig);
    }else{
      this.gtwSnackbarService.openSnackBar('Sorry!! No Document found','gtw-snackbar--yellow');

    }
    
  }
}
