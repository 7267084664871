import { Component } from '@angular/core';

@Component({
    selector: 'dialog-content-example-dialog',
    template: `<mat-dialog-content class="mat-typography">
                    <p> Are you sure you want to delete the video? </p>
               </mat-dialog-content>
               <mat-dialog-actions align="end">
                    <button mat-button [mat-dialog-close]="false">Cancel</button>
                    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="primary"> Delete </button>
                </mat-dialog-actions>`,
  })
export class ScreenVideoDeleteComponent {}
