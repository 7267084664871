import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenVideoUploadService {

  UPLOAD_URL = "/uploadVideoFile";
  UPDATE_URL = "/updateVideoFile";

  constructor(private httpClient:HttpClient) { }

  public upload(baseURL:string, formData:FormData,clientKey:number) {
    let completeURL="";
    if(formData.get('videoId') && formData.get('videoId') != 'null'){
      completeURL = `${baseURL}${this.UPDATE_URL}?client_key=${clientKey}`;
    }
    else{
      formData.append('videoId','');
      completeURL = `${baseURL}${this.UPLOAD_URL}?client_key=${clientKey}`;
    }
    
    
    const headers = new HttpHeaders({
      client_key: clientKey.toString()
    });
    const options={
      // headers:headers,
      withCredentials: true
    };
    
    return this.httpClient.post<any>(completeURL, formData,options);
    
    // , {
    //   reportProgress: true,
    //   observe: 'events'
    // }).pipe(map((event) => {

    //   switch (event.type) {

    //     case HttpEventType.UploadProgress:
    //       const progress = Math.round((100 * event.loaded) / event.total);
    //       return { status: 'progress', message: progress };
    //     case HttpEventType.Response:
    //       return event.body;
    //     default:
    //       return `Unhandled event: ${event.type}`;
    //   }
    // })
    // );
  }

}
