import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveScreensService } from '../../shared/_services/active-screens.service';
import { GtwSnackbarService } from '../../shared/_services/gtw-snackbar.service';
import { FavoriteButtonService } from '../screen-details/favorite-button/favorite-button.service';
import { ScreenDetailsModel } from '../screen-details/screen-details-model';
import { ScreenUpdateService } from './screen-update.service';

@Component({
  selector: 'gtw-home-page-screens-updates',
  templateUrl: './home-page-screens-updates.component.html',
  styleUrls: ['./home-page-screens-updates.component.scss']
})
export class HomePageScreensUpdatesComponent implements OnInit {
  
  
  recentScreensActivityList$ !:Observable<any[]>;
  
  lasViewedScreens$ !:Observable<any[]>;
  
  topFiveScreens$ !:Observable<any[]>;

  favScreens:ScreenDetailsModel[] = [];
  
  showViewedScreenList:boolean = true;
  showFavModScreenList:boolean = true;
  showTopDiagScreenList:boolean = true;
  @Output()
  screenChange = new EventEmitter<ScreenDetailsModel>();

  @Input('client-key')
  clientKey : number=0;
  
  @Input('all-screens')
  set _favScreen( allScreens :ScreenDetailsModel[]){
    this.allScreens = allScreens;
    let favScreens = JSON.parse(JSON.stringify(this.favScreens));
    if(allScreens.length > 0 ){
      this.favScreens = allScreens.filter((screen:ScreenDetailsModel) => screen.IS_FAV == 'Y');
      if(this.favScreens.length ==0){
        this.favScreens = [{SCREEN_NAME:'No screen selected as favorite'}];
      }
      if(favScreens.length != this.favScreens.length)
        this._screenUpdateService.allFavScreens$.next(this.favScreens);
    }
  }
  
  allScreens!:ScreenDetailsModel[];
  baseUrl = '';

  @Input('base-url')
  set _baseUrl(url:string){
    if(url.length>0){
      this.baseUrl = url;
      this.init();
    }
  }
  activeScreenKeys!:[];

  @Input('active-screen-keys')
  set _activeScreen(list:string){
    try{
      //this.activeScreenKeys? this.activeScreenKeys = JSON.parse(list) : [];
      this.activeScreenKeys = list != '{{ctrl.listOfActiveScreens}}'? JSON.parse(list): [];
      this.init();
    }catch(e){
      console.log('Input not stable yet');
    }
  }

  @Output('load-screen') loadScreen =  new EventEmitter<ScreenDetailsModel>();

  constructor(private _screenUpdateService : ScreenUpdateService,
    private _activeScreenService: ActiveScreensService,
    private favoriteSrvice:FavoriteButtonService,
    private _gtwSnackbarService:GtwSnackbarService) { 

  }
  
  ngOnInit(): void {
  }

  init(){
    if(this.baseUrl.length>0 ){
      this.recentScreensActivityList$ = this._screenUpdateService.getTopDiagnostics(this.baseUrl);
      if(Array.isArray(this.activeScreenKeys)){
        this.lasViewedScreens$ =this._screenUpdateService.getLastViewedScreens(this.baseUrl, this.activeScreenKeys);
      }
    }      
    // this.topFiveScreens$ = this._screenUpdateService.getTopFiveScreens(this.baseUrl);
  }

  loadClickedScreen(clickedScreen:any){
    if( Object.keys(clickedScreen).indexOf('SCREEN_NAME') < 0 && Object.keys(clickedScreen).indexOf('screenKey') < 0 ){
      this.allScreens.forEach((screen) => {
        if(screen.SCREEN_NAME == clickedScreen.name){
          this._activeScreenService.markOpenSelectedScreen(screen);
          this.loadScreen.emit(screen);
        }
      });
    }else{
      let key = clickedScreen?.screenKey || clickedScreen?.SCREEN_KEY;
      this.allScreens.forEach(screen => {
        if(screen.SCREEN_KEY == key){
          this._activeScreenService.markOpenSelectedScreen(screen);
          this.loadScreen.emit(screen);
        }
      });
    }
  }
  public favBtnCheck (screen:ScreenDetailsModel): void {
    let temp  = 'N';
    if(screen.IS_FAV === 'Y') {
      temp = 'N';
    } 
    
    this.favoriteSrvice.updateFavorite(this.baseUrl,this.clientKey,screen.SCREEN_KEY!,temp)
      .subscribe((data:any) => {
        if(data && data.callSuccess==1){
          screen.IS_FAV = temp;
          this.screenChange.emit(screen);
        }else{
          this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
        }
      },(error)=>{
        this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
      });
  }
}
