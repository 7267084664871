<div class="main-container">
  <div class="grid-container" id="351report-grid-container" style="height: calc(100vh - 124px);">
    <div class="top-toolbar">
      <div class="float-left ml-2">
        <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
          (onChange)="onTaxYearChange()">
        </p-dropdown>
        <!-- <p-dropdown *ngIf="scenarioLoaded" class="mr-2" [options]="scenarios" optionLabel="text" [(ngModel)]="scenario"
          (onChange)="onScenarioChange()">
        </p-dropdown> -->
        <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
          (selectedMes)="selectedMeChanged($event)">
        </gtw-df-me-dropdown>
        <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
      </div>
    </div>

    <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName" [loading]="loading"
      [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
      [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">

      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'45px'">
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="height: 70px;">
          <td style="width: 145px;">
            <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)">
              <span>{{ rowData.STATEMENTID }}</span>
            </a>
          </td>
          <td style="width: 105px;">
            <span>{{ rowData.PROJECT_NAME }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.PRIMARYLEID }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 180px;">
            <span>{{ rowData.PRIMARYENTITY }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 260px;">
            <span>{{ rowData.PRIMARYBUSINESS }}</span>
          </td>
          <td style="width: 145px;">
            <span>{{ rowData.FILLING_GROUP }}</span>
          </td>
          <td style="width: 175px;">
            <span>{{ rowData.SECONDARYLEID }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 260px;">
            <span>{{ rowData.SECONDARYENTITY }}</span>
          </td>
          <td style="width: 170px;">
            <span gtwLineSplitter [inputString]="rowData.DATE_OF_TRANSFER" [sepearator]="'||'" [formatter]="'date'">
              {{ rowData.DATE_OF_TRANSFER }}
            </span>
          </td>
          <td style="width: 275px;">
            <span>{{ rowData.IMPORTATIONPROPERTYDESC }}</span>
          </td>
          <td style="width: 236px;">
            <span>{{ rowData.LOSSDUPLICATIONPROPERTYDESC }}</span>
          </td>
          <td style="width: 330px;">
            <span>{{ rowData.GAINLOSSPROPERTYDESC }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 375px;">
            <span gtwLineSplitter [inputString]="rowData.DESCRIPTION" [sepearator]="'||'">
              {{ rowData.DESCRIPTION }}
            </span>
          </td>
          <td style="width: 155px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.BASIS_AMOUNT" [sepearator]="'||'" [formatter]="'number'">
              {{ rowData.BASIS_AMOUNT }}
            </span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.BASIS_CURRENCY }}</span>
          </td>
          <td style="width: 125px;">
            <span>{{ rowData.BASISTYPE }}</span>
          </td>
          <td style="width: 150px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.FMV_AMOUNT" [sepearator]="'||'" [formatter]="'number'">
              {{ rowData.FMV_AMOUNT }}
            </span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.FMV_CURRENCY }}</span>
          </td>
          <td style="width: 130px;">
            <span gtwLineSplitter [inputString]="rowData.STOCK_TYPE" [sepearator]="'||'">
              {{ rowData.STOCK_TYPE }}
            </span>
          </td>
          <td style="width: 185px;">
            <span gtwLineSplitter [inputString]="rowData.SHARE_X" [sepearator]="'||'" [formatter]="'number'">
              {{ rowData.SHARE_X }}
            </span>
          </td>
          <td style="width: 235px;">
            <span gtwLineSplitter [inputString]="rowData.PERCENTAGE_X" [sepearator]="'||'" [formatter]="'percent'">
              {{ rowData.PERCENTAGE_X }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.SHARE_Y" [sepearator]="'||'" [formatter]="'number'">
              {{ rowData.SHARE_Y }}
            </span>
          </td>
          <td style="width: 220px;">
            <span gtwLineSplitter [inputString]="rowData.PERCENTAGE_Y" [sepearator]="'||'" [formatter]="'percent'">
              {{ rowData.PERCENTAGE_Y }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.LIABILITIES_DESC" [sepearator]="'||'" >
              {{ rowData.LIABILITIES_DESC }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.LIABILITIESAMT" [sepearator]="'||'" [formatter]="'number'">
              {{ rowData.LIABILITIESAMT }}
            </span>
          </td>
          <td style="width: 180px;">
            <span>{{ rowData.IS_362E }}</span>
          </td>
          <td style="width: 100px;">
            <span>{{ rowData.IS_304 }}</span>
          </td>
          <td style="width: 135px;">
            <span>{{ rowData.TARGETLEID }}</span>
          </td>
          <td style="width: 180px;">
            <span>{{ rowData.TARGETNAME }}</span>
          </td>
          <td style="width: 135px;">
            <span gtwLineSplitter [inputString]="rowData.NOOFSHARES" [sepearator]="'||'" [formatter]="'number'">{{ rowData.NOOFSHARES }}</span>
          </td>
          <td style="width: 180px;">
            <span>{{ rowData.STOCKTRANSFERRED }}</span>
          </td>
          <td style="width: 205px;">
            <span>{{ rowData.TRANSFEREESNOTE }}</span>
          </td>
          <td style="width: 195px;">
            <span gtwLineSplitter [inputString]="rowData.AMTFOR351TRANSFER" [sepearator]="'||'" [formatter]="'number'">{{ rowData.AMTFOR351TRANSFER }}</span>
          </td>
          <td style="width: 225px;">
            <span>{{ rowData.TRANSDESCRIPTION }}</span>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>