  <form *ngIf="countries$" #countryForm="ngForm">
    <div class="form-group">
      <label>Countries</label>
      <ap-selector [items]="countries$ | async" (onSelectedChange)="countrySelected($event)" [type]="'SingleSelector'" [searchMethod]="'inline'">
      </ap-selector>
    </div>
    <div class="form-group">
      <label>CbyC TIN</label>
      <ap-form-field [inlineBlock]="true" [id]="'tin'" [type]="'input'"
        [isSecondary]="true" [(ngModel)]="request.tin" #tin="ngModel" name="tin">
      </ap-form-field>
    </div>
    <div class="form-group">
      <label>LE Name</label>
      <ap-form-field [inlineBlock]="true" [id]="'tin'" [type]="'input'"
        [isSecondary]="true" [(ngModel)]="request.leName" #leName="ngModel" name="leName">
      </ap-form-field>
    </div>
    <div class="full-width text-right">
      <ap-button [btnText]="'Update'" [config]="{isPrimary: true}" (onBtnClick)="updateCountry()"></ap-button>
    </div>
  </form>

