
<!--<div class="modal-body">
    <div class="panel-body" > 
        <div class="message-box-middle">
            <span class="msg-title"><i class="appkiticon icon-logout-fill"></i>Confirm </span>
            <p class="p-text">Are you sure you want to sync the currencies?</p>
            <div class="message-box-button-section">
                <ap-button [btnText]="'No'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" [isDisabled]="!enableButton" (onBtnClick)="cancel()"></ap-button>
                <ap-button [btnText]="'Yes'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" [isDisabled]="!enableButton" (onBtnClick)="syncData()"></ap-button>
            </div>
        </div>
    </div>
 </div>-->

<!-- 
<div class="p-3 a-bg-light custom-selector">
    <ap-selector [items]="searchInlineList" [type]="'SingleSelector'" [searchMethod]="'inline'">
    </ap-selector>
</div> -->
<!--<h1 mat-dialog-title></h1>-->
<div mat-dialog-content>
    <div class="confirm-message">
        <span class="msg-title"><i class="appkiticon icon-logout-fill"></i>Confirm </span> <br>
        Are you sure you want to sync the currencies?
    </div>
  
</div>
<div mat-dialog-actions>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">
        <ap-button [btnText]="'Cancel'" class="a-mr-10 a-btn-transparent a-btn-gray" [isDisabled]="!enableButton" [btnType]="'a-btn-gray'"
            [config]="{isPrimary: true}" (onBtnClick)="cancel()"></ap-button>
        <ap-button [btnText]="'Yes'" class="a-mr-10 a-btn-transparent" [isDisabled]="!enableButton" [config]="{isPrimary: true}"
        (onBtnClick)="syncData()"><i class="fa fa-spin fa-spinner" *ngIf="!enableButton" ></i></ap-button>

    </div>
  
</div>