import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {CreateDisclosureSharedService, DropDownType} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'dst-view-template-form8594-id217',
  templateUrl: './view-template-form8594-id217.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form8594-id217.component.scss']
})
export class ViewTemplateSec8594Id217Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form217!: FormGroup;
  disableActions: boolean = false;
  stmtData!: StmtData;
  stmtAttrData: any = [];
  loaded: boolean = false;
  startLoader: boolean = false;
  attMap: any;
  stateValue: string = '';
  countryValue: string = '';
  StateOptions: any[] = [{
    data: []
  }];

  CountryOptions: any[] = [{
    data: []
  }];
  SSNOfOtherParty1: string = '';
  EinOfOtherParty1: string = '';

  constructor(private formBuilder: FormBuilder,
              private sharedService: CreateDisclosureSharedService) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false; // reset immediately after consumed
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form217 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });
    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
      });
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
    });
    //load Stmt data
    this.sharedService.getLoadStatementData(217)
      .subscribe((response: StmtData) => {
        this.stmtData = response;

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }
          if (stmt.attributeValue !== undefined) {
            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }
        });
        this.SSNOfOtherParty1 = this.attMap['SSNOfOtherParty1'] == undefined ? '' : this.attMap['SSNOfOtherParty1'][0];
        this.EinOfOtherParty1 = this.attMap['EinOfOtherParty1'] == undefined ? '' : this.attMap['EinOfOtherParty1'][0];
        this.StateOptions[0].data.forEach((val: any) => {

          if ((this.attMap['state_Us'] !== undefined) && (val.value == this.attMap['state_Us'][0])) {
            this.stateValue = val.text;
          }

        });

        this.CountryOptions[0].data.forEach((val: any) => {

          if ((this.attMap['country1'] !== undefined) && (val.value == this.attMap['country1'][0])) {
            this.countryValue = val.text;
          }

        });
      }, () => {

      }, () => {

      }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });
  }

  dummyFunc(event: any): void {

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}


