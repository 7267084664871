import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'dst-view-template-sec332-id190',
  templateUrl: './view-template-sec332-id190.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec332-id190.component.scss']
})
export class ViewTemplateSec332Id190Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form190!: FormGroup;
  stmtData!: StmtData;
  attMap: any;
  loaded: boolean = false;
  pickListVal: string = '';
  propertyValBasisOptions: any = [{data: []}];
  currencyOptions: any = [{data: []}];
  descTotalAvl: boolean = false;
  selBasisTxt: string = '';
  corpTypeTxt: string = '';
  startLoader: boolean = false;
  disableActions: boolean = false;
  s3Uploading: boolean = false;
  primaryEntityName: string = '';
  secondaryEntityName: string = '';
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private taxTypePipe: TaxTypePipe) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form190 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    //load required Filters
    //PropertyValBasis Options
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        //console.log(data);
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //load currencies
    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.currencyOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //load dropdown values
    //PropertyValBasis Options
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        //console.log(data);
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });


    //load Stmt data
    this.sharedService.getLoadStatementData(190)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        this.primaryEntityName = this.stmtData.primaryentity || '';
        this.secondaryEntityName = this.stmtData.secondaryentity || '';

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push(undefined);
          }


        });
        console.log('After loop Attr data 332-CTB: ' + JSON.stringify(this.attMap));

        /*//check totals are available
        if (this.attMap['Desc'].length >= 1) { this.descTotalAvl = true }
        if (this.attMap['Desc1'].length >= 1) { this.basisAmt1Total = true }*/

        if (this.attMap['LiquidationStatus'] !== undefined && this.attMap['LiquidationStatus'][0] != '') {
          //Select pickList Val
          this.sharedService.getLiquidationStateDropdown(1)
            .subscribe((data: DropDownType[]) => {
              data.forEach(element => {
                if (element.id == this.attMap['LiquidationStatus'][0]) {
                  this.pickListVal = element.text;
                }

              });
            }, () => {

            }, () => {

            });
        }

        //check totals are available
        if (this.attMap['Desc'].length >= 1) {
          this.descTotalAvl = true;
        }

        //Set all the values that needed from the service calls
        this.propertyValBasisOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['Basis'] !== undefined) && (val.id == this.attMap['Basis'][0])) {
            this.selBasisTxt = val.text;
          }

        });

        //corpTypeTxt
        //this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1);
        //this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2);
        forkJoin([

          this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1),
          this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2)

        ]).subscribe(([response1, response2]) => {
          setTimeout(() => {
            const html = document.querySelector('#temp332');
            const htmlContent = html!.outerHTML;
            this.sharedService.getFormStateByFormId(190)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
          });
        }, (error: HttpErrorResponse) => {

          console.log(error);
          this.dstSharedService.showAlert('error', error.message);

        }).add(() => {
          this.loaded = true;
          this.startLoader = false;
        });

      }, () => {

      }, () => {

      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      });

  }

  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 'Section332-CheckTheBox.pdf', this.stmtData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading =  false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp332');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  //getCurrencyText
  getSelCurrency(valFor?: number): string {
    let retVal = '';
    this.currencyOptions[0].data.forEach((val: DropDownType) => {
      if (val.id == this.attMap['Currency'][0] && valFor == 0) {
        retVal = val.text;
      } else if (val.id == this.attMap['Currency'][1] && valFor == 1) {

        retVal = val.text;
      } else if (val.id == this.attMap['Currency'][2] && valFor == 2) {

        retVal = val.text;
      }

    });

    return retVal;
  }

  totalAmt(amtFor?: String): number {
    let totalAmt = 0;
    if (amtFor == 'BasisAmt') {
      this.attMap['BasisAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });

    } else if (amtFor == 'FMVAmt') {
      this.attMap['FMVAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });
    } else if (amtFor == 'BasisAmt1') {

      this.attMap['BasisAmt1'].forEach((amt: number) => {
        //console.log(amt);
        totalAmt += Number(amt);
      });
    }
    return totalAmt;
  }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    this.startLoader = true;
    return this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
      leid,
      this.sharedService.getTransDateStr(this.attMap['EffectiveDate'][0], 1),
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
          if (response && response[0]) {
            //override entity type
            const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
            if (response && response[0]) {
				//override entity name & entity type
				const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
				if (entityType === 1) { //primary entity
				  (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
				  this.primaryCorpTypeTxt = corpType;
				} else if (entityType === 2) { //secondary entity
				  (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
				  this.secondaryCorpTypeTxt = corpType;
				}
			  }
          }
        })
      })
    )
  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
