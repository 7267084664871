<div class="ghostContainer">
    <ap-responsive-tab>
        <ap-res-tab [label]="'Foreign Calc Admin'">
            <div class="ghost-console-wrapper">
                <div class="ghost-console-header">
                    <div class="manualRunContainer">
                        Manual Run
                    </div>
                    <div class="progressJobContainer">
                        <div class="jobIdBlock">
                            <span class="appkiticon icon-hb-full"></span> <span class="jobTxt">Progress Job Id : <span class="jobId">{{progressBar? progressBar.JOB_ID : ''}}</span></span>
                        </div>
                        <div class="progressBarBlock">
                            <ap-progress [progress]="progressBar ? progressBar.PER_COMP : 0" type="basic"></ap-progress>
                        </div>
                        <div class="progressBarDetails">
                           {{progressBar ? progressBar.PER_COMP : 0}}%
                        </div>
                    </div>
                </div>
            
                <div class="ghost-console-content">
                    <div class="manualRunContentContainer">
                        <div class="manualButtons" *ngFor="let options of manualSetupOptions">
                            <ng-container>
                                <span class="buttonTxt">{{options.text}}</span>
                                <div class="toggleBtn">
                                    <ap-toggle (onCheck)="switch(options)" [(ngModel)]="options.value" [checked]="options.value" class="a-m-5 a-toggle-small" [disabled]="options.isDisabled"></ap-toggle>
                                </div>
                            </ng-container>
                        </div>
                        <div class="manualButtons">
                            <div class="ml">
                                <span class="buttonTxt">No Of Runs: </span>
                                <div class="runsSelector">
                                    <ap-selector class="m-l-10" [items]="noOfRunsManual" [type]="'Dropdown'" (onSelectedChange)="setRuns($event, 'manual');"></ap-selector>
                                </div>
                            </div>
                            <div class="toggleBtn">
                                <ap-button [btnText]="'RUN'" class="a-mr-10" [config]="secondaryConfig" (onBtnClick)="saveManualRunData()" [isDisabled]="manualRunSaveBtn || scenarioStatus == 'Y'"></ap-button>
                                <ap-loading *ngIf="manualRunSaveLoader" [size]="'24px'" [colorName]="'a-primary'"></ap-loading>
                            </div>
                        </div>
                    </div>
                    
                    <div class="autoRunSetup">
                        <div class="autoRunTxt">
                            <div class="autoRunTxtBlock">
                                <div style="margin-top: 4px;">Auto Run Setup</div>
                                <div class="ownershipError" *ngIf="ownershipSetupReady == 'N'">
                                    <span class="appkiticon icon-alert-outline" style="font-size: 28px; margin-right: 8px;"></span>
                                    <span style="font-size: 16px;margin-left: 10px;">Ownership has not been setup.</span>
                                </div>
                                <div class="scenario-lock-status" *ngIf="scenarioStatus == 'S'">
                                    <span class="appkiticon icon-location-locked-outline" style="font-size: 28px; margin-right: 8px;"></span>
                                    <span style="font-size: 16px;">This Filing Group is locked and can not be run.</span>
                                </div>
                            </div>
                            <div class="keyListingsBlock" *ngIf="!autoRunDataLoad">
                                <div style="display: flex;">Tax Year : 
                                    <span class="keyValues">&nbsp;{{baseGlobalParams? baseGlobalParams.tax_year : 'NA'}}</span> 
                                    <!-- <ap-selector class="m-l-10" [items]="taxYearList" [type]="'Dropdown'" (onSelectedChange)="setJobId($event);"></ap-selector> -->
                                    Scenario : 
                                    <span class="keyValues">&nbsp;{{baseGlobalParams? baseGlobalParams.scenario : 'NA'}}</span> 
                                    <!-- <ap-selector class="m-l-10" [items]="scenarioList" [type]="'Dropdown'" (onSelectedChange)="setJobId($event);"></ap-selector> -->
                                    Filing Group : 
                                    <!-- <span class="keyValues">{{tableData[0].FILING_GROUP}}</span> -->
                                    &nbsp;<ap-selector style="width: 345px;" class="m-l-10" [items]="filingList" [type]="'Dropdown'" (onSelectedChange)="setFilingGroup($event);"></ap-selector>
                                </div>
                            </div>
                        </div>
                        <div class="autoConfigs">
                            <div class="autoRunButtonsBlock">
                                <div class="autoRunButtons" *ngFor="let option of autoRunSetupOptions">
                                    <ng-container>
                                        <span class="buttonTxt">{{option.text}}</span>
                                        <div class="toggleBtn">
                                            <ap-toggle (onCheck)="switch(option)" [(ngModel)]="option.value" [checked]="option.value" class="a-m-5 a-toggle-small" [disabled]="option.isDisabled"></ap-toggle>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="autoRunButtons">
                                    <span style="line-height: 28px;">No Of Runs: </span>
                                    <ap-selector class="m-l-10 runsDropdown" [items]="noOfRunsAuto" [type]="'Dropdown'" (onSelectedChange)="setRuns($event, 'Auto');"></ap-selector>
                                </div>
                                <!-- <div class="autoRunButtons" *ngIf="ownershipSetupReady == 'N'">
                                    <span class="appkiticon icon-alert-outline" style="font-size: 28px; margin-right: 8px;"></span>
                                    <span style="font-size: 16px;">Ownership has not been setup.</span>
                                </div> -->
                            </div>
                            <div class="scheduleBlock">
                                <div class="scheduleBtn">
                                    <ng-container>
                                        <span class="buttonTxt">{{fixedSchedule.name}}</span>
                                        <div class="toggleBtn">
                                            <ap-toggle (onCheck)="scheduleJob(fixedSchedule)" [(ngModel)]="fixedSchedule.value" [checked]="fixedSchedule.value" class="a-m-5 a-toggle-small"></ap-toggle>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="ml" *ngIf="showScheduler">
                                    <span>Hours: </span>
                                    <ap-selector class="m-l-10" [items]="hoursList" [type]="'Dropdown'" (onSelectedChange)="setHour($event);"></ap-selector>
                                </div>
                                <div class="ml" *ngIf="showScheduler">
                                    <span>Minutes: </span>
                                    <ap-selector class="m-l-10" [items]="secondsList" [type]="'Dropdown'" (onSelectedChange)="setMinute($event);"></ap-selector>
                                </div>
                                <ap-button [btnText]="'ADD'" *ngIf="showScheduler" class="a-mr-10 addSchBtn" [config]="primaryConfig" (onBtnClick)="addSchedules()"></ap-button>
                                <div *ngIf="showScheduler" style="color: red; margin-top: 16px;">{{scheduleMsg}}</div>
                            </div>
                        </div>
            
                        <div class="startRunningContainer">
                            <div class="startDateBlock">
                                <div class="date-field-container">
                                    <div class="datePickerTxt">
                                        Start Running On
                                    </div>
                                    <div class="datePicker">
                                        <!-- <ap-date-picker type="singleCalendar" dateFormat="yyyy-MM-dd" (onChange)="datePickerChange($event)" [(ngModel)]="dateRangeSelection.startDate" themeLevel="a-apply-theme-level-3" [minDate]="minDate"></ap-date-picker> -->
                                        <ap-date-picker type="singleCalendar" (onChange)="datePickerChange($event)" [(ngModel)]="dateRangeSelection.START_DATE" themeLevel="a-apply-theme-level-3"></ap-date-picker>
                                    </div>
                                </div>

                                <div class="date-field-container" style="margin-left: 20px;">
                                    <div class="datePickerTxt">
                                        End Running On
                                    </div>
                                    <div class="datePicker">
                                        <ap-date-picker type="singleCalendar" (onChange)="datePickerChange($event)" [(ngModel)]="dateRangeSelection.END_DATE" themeLevel="a-apply-theme-level-3"></ap-date-picker>
                                    </div>
                                </div>

                                <div class="a-pl-20 autoRunActions" style="margin-top: 19px;display: flex;">
                                    <ap-button [btnText]="'SAVE'" class="a-mr-10" [config]="primaryConfig" (onBtnClick)="saveAutoRunData()" [isDisabled]="autoRunSaveBtn || scenarioStatus == 'Y'"></ap-button>
                                    <!-- <ap-loading *ngIf="autoRunSaveLoader" class="saveLoader" [size]="'24px'" [colorName]="'a-primary'"></ap-loading> -->
                                    <div *ngIf="autoRunSaveLoader" class="text-center rounded"><span class="a-loading a-primary" style="width: 24px; height: 24px; margin: 2px;"></span></div>
                                </div>

                                <!-- <div class="scenario-lock-status" *ngIf="scenarioStatus == 'S'">
                                    <span class="appkiticon icon-location-locked-outline" style="font-size: 28px; margin-right: 8px;"></span>
                                    <span style="font-size: 16px;">This Scenario is locked and can not be run.</span>
                                </div> -->
                            </div>
                            <div class="tagsBlock" *ngIf="showScheduler">
                                <div class="tagsContainer" style="margin-top: 12px;">
                                    <div style="text-align: center;" *ngIf="schedulesList.length == 0">No Schedules Added.</div>
                                    <div class="tags" *ngFor="let sch of schedulesList; let i = index">
                                        <span class="" style="padding-left: 10px;">{{sch}}</span>
                                        <ap-button [btnText]="'x'" class="a-mr-10 addSchBtn" [config]="primaryConfig" (onBtnClick)="removeSchedules(i)"></ap-button>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="endRunningContainer">
                            <div class="actionBtns">
                                <!-- <div class="datePickerTxt">
                                    End Running On
                                </div> -->
                                <div class="datePicker display">
                                    <!-- <ap-date-picker type="singleCalendar" dateFormat="yyyy-MM-dd" (onChange)="datePickerChange($event)" [(ngModel)]="dateRangeSelection.endDate" themeLevel="a-apply-theme-level-3" [minDate]="minDate"></ap-date-picker> -->
                                    <!-- <div class="a-pl-20 autoRunActions">
                                        <ap-button [btnText]="'SAVE'" class="a-mr-10" [config]="primaryConfig" (onBtnClick)="saveAutoRunData()" [isDisabled]="autoRunSaveBtn || scenarioStatus == 'Y'"></ap-button>
                                        <ap-loading *ngIf="autoRunSaveLoader" [size]="'24px'" [colorName]="'a-primary'"></ap-loading>
                                    </div> -->
                                    <div class="toggleBtn autoRunActions">
                                        <ap-button [btnText]="'Refresh Table Data'" class="a-mr-10" [config]="secondaryConfig" (onBtnClick)="getGhostJobTableData();"></ap-button>
                                    </div>
                                    <div class="toggleBtn autoRunActions">
                                        <!-- <ap-button [btnText]="'Compare Data'" class="a-mr-10" [config]="secondaryConfig" [isDisabled]="compareBtn" (onBtnClick)="compareData();"></ap-button> -->
                                        <ap-button [btnText]="'Compare Data'" class="a-mr-10" [config]="secondaryConfig" (onBtnClick)="compareData();"></ap-button>
                                    </div>
                                    <!-- <div *ngIf="compareBtn"><span *ngIf="!compareBtn" class="appkiticon icon-alert-fill compareWarning"></span> <span class="compareWarning"> {{compareJobsError}}</span></div> -->
                                    <!-- <div class="ml">
                                        <div class="autoRunsContainer">
                                            <span style="line-height: 28px;">No Of Runs: </span>
                                            <ap-selector class="m-l-10 runsDropdown" [items]="noOfRunsAuto" [type]="'Dropdown'" (onSelectedChange)="setRuns($event, 'Auto');"></ap-selector>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="errorMsgBlock">
                                <div class="noRecordsContainer" *ngIf="showFailedMsg" style="font-size: 12px; padding: 8px;margin: 0;">
                                    {{failedJobErrorMsg}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="autoRunDataLoad">
                                <ap-loading [size]="'24px'" [colorName]="'a-primary'"></ap-loading>
                            </div>
                            <div class="noRecordsContainer" *ngIf="!autoRunDataLoad && tableData.length == 0">
                                No Records Found
                            </div>
                            <div class="a-panel code-wrapper table" *ngIf="!autoRunDataLoad && tableData.length > 0">
                                <div class="col-12  gray-container table-content">
                                    <ap-table #basicTable [data]="tableData" (onTablePageChange)="tablePageChange($event)"
                                        (onTablePageNumberChange)="tablePageNumberChange($event)" [page]="1" [pageSize]="8" [step]="1"
                                        [pageDropdownList]="tablePageDropdownList" [isFrontPagination]="true" [total]="6">
                                        <thead class="a-thead">
                                            <tr>
                                                <!-- <th ></th> -->
                                                <th>Request Id</th>
                                                <th>Job Id</th>
                                                <th>Status</th>
                                                <!-- <th>Tax Year</th>
                                                <th>Scenario</th>
                                                <th>Filing Group</th> -->
                                                <th>Run By</th>
                                                <th>Run Type</th>
                                                <th>Run Date</th>
                                                <th>Run Time (Min)</th>
                                                <th>Look Through</th>
                                                <th>FTC</th>
                                                <th>Main Calcs</th>
                                                <th>GILTI</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody class="a-tbody tbody-regular tbody-hover">
                                            <tr *ngFor="let item of basicTable.data; let i = index">
                                                <!-- <td ><ap-checkbox [checked]="item.selected" [value]="item.selected" [(ngModel)]="item.selected" (onCheck)="selectedJobId($event, item, i, item.selected);"> </ap-checkbox></td> -->
                                                <td>{{item.REQUEST_KEY}}</td>
                                                <td>{{item.JOB_ID}}</td>
                                                <td>
                                                    <div class="status yellow" *ngIf="item.STATUS == 'STARTED'"></div>
                                                    <div class="status blue" *ngIf="item.STATUS == 'CREATED'"></div>
                                                    <div class="status red" *ngIf="item.STATUS == 'FAILED' || item.STATUS == 'ERROR'"></div>
                                                    <div class="status green" *ngIf="item.STATUS == 'SUCCESS' || item.STATUS == 'PASSED'"></div>
                                                </td>
                                                <!-- <td [tooltip-container]="template1" [placement]="'right'" [trigger]="'click'">{{item.STATUS}}</td> -->
                                                <!-- <td [tooltip-container]='item.STATUS' [placement]="'top'">{{item.STATUS}}</td> -->
                                                <!-- <ng-template #template1>
                                                    <div class="tooltip-inner a-p-30 d-block a-min-width-360">
                                                        <div class="a-h3 text-white text-left">Lorem ipsum dolor sit amet</div>
                                                        <div class="a-h4 customize-tooltip-text font-weight-normal text-left a-mt-10">consect adipiscing elit. In qua si
                                                            nihil est praeter rationem, sit in una virtute finis bonorum; Atque his de rebus et splendida
                                                            est eorum et illustris oratio.
                                                        </div>
                                                    </div>
                                                </ng-template> -->
                                                <!-- <td>{{item.TAX_YEAR}}</td>
                                                <td>{{item.SCENARIO}}</td>
                                                <td>{{item.FILING_GROUP}}</td> -->
                                                <td>{{item.CREATED_BY}}</td>
                                                <td>{{item.REQUEST_MODE}}</td>
                                                <td>{{item.CREATED_ON}}</td>
                                                <td>{{item.TIME_TO_COMPLETE}}</td>
                                                <td>{{item.LOOK_THROUGH}}</td>
                                                <td>{{item.ELIGIBILITY}}</td>
                                                <td>{{item.MAIN_CALCS}}</td>
                                                <td>{{item.GILTI_FLAG ? item.GILTI_FLAG : ''}}</td>
                                                <td class="hyperLink" style="cursor: pointer;padding-right: 0;" (click)="showError(item)"><span style="float: left;"><span class="appkiticon icon-view-fill" style="color: blue;font-size: 18px;"></span></span><ap-button [btnText]="'x'" *ngIf="item.STATUS == 'CREATED'" class="a-mr-10 addSchBtn" style="" [config]="primaryConfig" (onBtnClick)="removeJob(item.REQUEST_KEY)"></ap-button></td>
                                                <!-- <td class="hyperLink" (click)="showMore('compare-data-modal')">Compare</td> -->
                                            </tr>
                                        </tbody>
                                    </ap-table>
                                </div>
                            </div>
                            <div class="legendsContainer" *ngIf="tableData.length > 0">
                                <div class="legendBlock">
                                    <div class="status yellow"></div> 
                                    <div class="txt">Started</div>
                                </div>
                                <div class="legendBlock">
                                    <div class="status blue"></div>
                                    <div class="txt">Created</div>
                                </div>
                                <div class="legendBlock">
                                    <div class="status red"></div> 
                                    <div class="txt">Failed / Error</div>
                                </div>
                                <div class="legendBlock">
                                    <div class="status green"></div> 
                                    <div class="txt">Success / Passed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <!-- <div class="text-center a-p-10">
                    <ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="show-more-modal">
                        <div slot="content">
                            <div class="modal-dialog" role="document" tabindex="-1">
                                <div class="modal-content">
                                    <div class="a-modal-header">
                                        <div class="flex-fill">
                                            <span class="a-h4">Title</span>
                                        </div>
                                        <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeModal('show-more-modal')">
                                            <span class="appkiticon icon-close-fill a-close-btn"></span>
                                        </button>
                                    </div>
                                    <div class="a-modal-body">
                                        Lorem ipsum dolor sit amet, @firstlastname adipiscing elit. Sed ac diam elit. Vivamus enim diam, luctus et scelerisque
                                        vitae, congue sed purus
                                    </div>
                                    <div class="a-modal-footer justify-content-end">
                                        <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('show-more-modal')">OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ap-modal>
                </div> -->

            </div>        
        </ap-res-tab>
        <ap-res-tab [label]="'Change Details'">
            <div class="a-panel-wrapper p-3 change-details-wrapper">
                <div style="padding-top: 6px;"><span>Select Job Id: </span></div>
                <ap-selector class="m-l-10" [items]="dropdownList" [type]="'Dropdown'" (onSelectedChange)="setJobId($event);"></ap-selector>
                <div class="toggleBtn autoRunActions m-l-10">
                    <ap-button [btnText]="'View Details'" class="a-mr-10" [config]="secondaryConfig" (onBtnClick)="viewDetails();"></ap-button>
                </div>
                <ap-loading class="changeDetailsLoader" *ngIf="changeDetailsLoader" [size]="'24px'" [colorName]="'a-primary'"></ap-loading>
            </div>
            <div class="noRecordsContainer" *ngIf="jobData.length < 1">
                No Records Found
            </div>
            <div class="a-panel-wrapper p-3 change-details-wrapper-table" *ngIf="showJobData">
                <div class="a-panel code-wrapper table">
                    <div class="col-12  gray-container table-content">
                        <ap-table #basicTable [data]="jobData" (onTablePageChange)="tablePageChange($event)"
                            (onTablePageNumberChange)="tablePageNumberChange($event)" [page]="1" [pageSize]="8" [step]="1"
                            [pageDropdownList]="tablePageDropdownList" [isFrontPagination]="true" [total]="6">
                            <thead class="a-thead">
                                <tr>
                                    <th>JOB ID</th>
                                    <th>PROCESS NAME</th>
                                    <th>STATUS</th>
                                    <th>ESTIMATED COMPLETION TIME</th>
                                    <th>COMPLETION PERCENTAGE</th>
                                </tr>
                            </thead>
                            <tbody class="a-tbody tbody-regular tbody-hover">
                                <tr *ngFor="let item of basicTable.data; let i = index">
                                    <td>{{item.JOB_ID}}</td>
                                    <td>{{item.PROCESS_NAME}}</td>
                                    <td>{{item.STATUS}}</td>
                                    <td>{{item.EXPECTED_TIME_TO_COMPLETE}}</td>
                                    <td>{{item.COMPLETION_PERCENTAGE}}</td>
                                </tr>
                            </tbody>
                        </ap-table>
                    </div>
                </div>
            </div>
        </ap-res-tab>
    </ap-responsive-tab>
</div>
