import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { FormReivewPdf, FormReivewPdfService } from '../form-review-pdf/form-review-pdf.service';

@Component({
  selector: 'gtw-form-review-pdf',
  templateUrl: './form-review-pdf.component.html',
  styleUrls: ['./form-review-pdf.component.scss']
})
export class FormReivewPdfComponent implements OnInit {

  private readonly ERROR_MSG = "Error loading the requested Form/PDF!";
  private readonly NO_PDF_MSG = "No PDF file available for this statement!";
  private readonly NO_DATA_PUSHED_MSG = "Form Data is not pushed to EFile!";

  @Input() rowData!: FormReivewPdf;
  @Input() divHeight!: string;
  loading: boolean = false;
  pdfLoaded: boolean = false;
  noDataAvail: boolean = false;
  formNotAvail: boolean = false;
  fileContent: any = undefined;
  formNotAvailText: string = this.NO_PDF_MSG;
  

  constructor(private formReviewPdfService: FormReivewPdfService,
    private domSanitizer: DomSanitizer) {};

  ngOnInit(): void {
    this.loadPDF();
  };

  loadPDF(): void {
    this.loading = true;
    this.formNotAvailText =  this.NO_PDF_MSG;

    if (this.rowData.FORMTYPE === 'IRSFORM') {

      this.formReviewPdfService.doesTaxFormExist(this.rowData.TAX_YEAR, this.rowData.IRSFORM).subscribe((response: any) => {
        
        const formAvail = this.formAvailCheck(response);
        if (!formAvail) {
          this.formNotAvail = true;
          this.loading = false;
          return;
        }
  
        this.formReviewPdfService.getPDFData(this.rowData.TAX_YEAR, this.rowData.SUBMISSION_ID, this.rowData.KEY_ID, this.rowData.IRSFORM
          , this.rowData.FORM_SUB, response.result, this.rowData.JCD_KEY, this.rowData.SCENARIO, this.rowData.FORM_SUB_CAT, this.rowData.LEID
          , this.rowData.PARENT_LEID, this.rowData.CDR_NO, this.rowData.RPT_PERIOD, this.rowData.SYSTEM_FLAG, this.rowData.WP_IRS_FORM_NO
          , this.rowData.SCHEDULE, this.rowData.LINE, this.rowData.PART_NO, this.rowData.LE_NAME, this.rowData.LE_TYPE, this.rowData.EIN
          , this.rowData.JOB_ID, this.rowData.SRC_TYPE)
          .subscribe((response: any) => {
            this.setFileContent(response);
       
          }, (eMsg: string) => {
            console.log(eMsg);
            this.formNotAvailText = this.ERROR_MSG;
            this.fileContent = undefined;
          }).add(() => {
            this.loading = false;
            this.pdfLoaded = true;
          });
  
      }, (eMsg: string) => {
        console.log(eMsg);
        this.formNotAvailText = this.ERROR_MSG;
        this.fileContent = undefined;
      });

    } else if (this.rowData.FORMTYPE === 'ATTACHMENT' || this.rowData.FORMTYPE === 'TEMPLATE') {

      const fileName = this.rowData.FORMTYPE === 'TEMPLATE' ? this.rowData?.HTML_ATTACHMENT_NAME: this.rowData.ATTACHMENT_NAME;
      const filePath = this.rowData.FORMTYPE === 'TEMPLATE' ? this.rowData?.HTML_ATTACHMENT_PATH: this.rowData.ATTACHMENT_PATH;

      const extName = fileName?.substring(fileName.lastIndexOf('.') + 1) || '';
      if (extName !== 'pdf') {
        this.formNotAvail = true;
        this.loading = false;
        return;
      }

      this.formReviewPdfService.getDSTAttachmentsData(fileName, filePath)
        .subscribe((response: any) => {
          this.setFileContent(response);
     
        }, (eMsg: string) => {
          console.log(eMsg);
          this.formNotAvailText = this.ERROR_MSG;
          this.fileContent = undefined;
        }).add(() => {
          this.loading = false;
          this.pdfLoaded = true;
        });

    } else {
      this.formNotAvail = true;
      this.loading = false;
    }

  }

  setFileContent(response: any): void {
    const dataNotFound = this.checkFileData(response);
    if (dataNotFound) {
      return;
    }

    const file = new Blob([response], {type: 'application/pdf'});
    const pdfPath = URL.createObjectURL(file);
    this.fileContent = this.domSanitizer.bypassSecurityTrustResourceUrl(pdfPath);
    this.pdfLoaded = true;
  }

  checkFileData(response: any): boolean {
    if(response && response.byteLength === 0){
      this.noDataAvail = true;
      this.loading = false;
      return true;
    }
    return false;
  }

  formAvailCheck(response: any): boolean {
    if (_.isEqual({}, response) || response.fileNotFound.length > 0) {
      return false;
    } else if (this.rowData.SUBMISSION_ID === undefined || this.rowData.KEY_ID === undefined) { 
      this.formNotAvailText = this.NO_DATA_PUSHED_MSG;
      return false;
    } else if (!this.rowData.TAX_YEAR || !this.rowData.SUBMISSION_ID || this.rowData.KEY_ID === undefined || !this.rowData.IRSFORM
      || !this.rowData.FORM_SUB || !this.rowData.JCD_KEY || !this.rowData.SCENARIO) {
      return false;
    }
    return true;
  }

}
