import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CreateDisclosureSharedService} from '../../../create-disclosure-shared.service';
import {TemplateAttachedService} from '../template-attached.service';
import {StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';
import {TrackerTabsService} from '../../../../../tracker-tabs.service';

@Component({
  selector: 'gtw-view-template-pdf',
  templateUrl: './view-template-attached.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-attached.component.scss']
})
export class ViewTemplateAttachedComponent implements OnInit, AfterViewInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formType!: string;
  viewData!: any;
  taxReturnDetails: string = 'CONSOLIDATED FEDERAL INCOME TAX RETURN';
  loading!: boolean;

  constructor(private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private attachedService: TemplateAttachedService) {
    this.formType = this.sharedService.getActiveFormState().formType;
    this.loading = true;
    this.sharedService.getLoadStatementData().subscribe((data: StmtData) => {
      this.viewData = data;
      (this.formType === undefined) && (this.formType = this.viewData?.formtype); //custom reports pop-up edge case: formtype undefined
      
      if (this.viewData.attachmentpath && this.viewData.attachmentname) {
        this.attachedService.getDSTPresignedUrl(this.viewData.attachmentpath, this.viewData.attachmentname)
          .subscribe((downloadUrl: string) => {
            this.viewData.downloadUrl = downloadUrl;
            this.sharedService.setFormData(this.viewData);
          }, (e: Error) => {
            this.dstSharedService.showAlert('error', e.message);
          });
      }
    }).add(() => {
      this.loading = false;
    });
  }

  ngOnInit(): void {
    console.log(this.sharedService.getActiveFormState().doPDFSync);
  }

  ngAfterViewInit(): void {
    document.querySelector('ap-form-field > textarea')?.setAttribute('readonly', '');
  }

  getCorpType(taxType: string): string {
    if (taxType === 'FPS' || taxType === 'DPS') {
      return ' Partnership';
    } else if (taxType === 'TRT' || taxType === 'ITR') {
      return ' Trust';
    }
    return ' Corporation';
  }

  download(): void {
    this.attachedService.getDSTPresignedUrl(this.viewData.attachmentpath, this.viewData.attachmentname)
      .subscribe((presignedS3Url: string) => {
        window.open(presignedS3Url, '_blank');
      });
  }

  edit(): void {
    this.sharedService.switchTemplateMode();
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
