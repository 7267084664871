<!-- <ap-breadcrumbs type="file" [data]="breadcrumbsDataFile" (onClickItem)="    ($event)">
</ap-breadcrumbs> -->
<!-- {{breadCrumbLinks}}
<gtw-bread-crumbs [breadCrumbLinks]="breadCrumbLinks"></gtw-bread-crumbs> -->
<div class="path-input a-px-20" *ngIf="!s3FileUploadInput.hidePath">
  <!-- <div>Path:</div> -->
  <div
    class="gtw-app-nav-bread-crumbs a-px-5"
    [ngClass]="{ 'max-width-60p': isNewFile }"
  >
    <span>
      {{ rootFolder }}
    </span>
    <span *ngFor="let label of breadCrumbLinks; let i = index">
      {{ label }}
    </span>
  </div>
  <ap-form-field
    *ngIf="isNewFile && s3FileUploadInput.canEditPath"
    [inlineBlock]="true"
    [id]="'folderInput'"
    [type]="'input'"
    [(ngModel)]="newFolder"
    [placeholder]="'Enter new folder name'"
    [withButton]="true"
    [buttonName]="'Add'"
    (onClickButtonEvent)="onClickButtonEvent($event)"
  >
  </ap-form-field>
</div>
<hr *ngIf="!s3FileUploadInput.hidePath"/>
<div class="text-center a-mb-10" *ngIf="isNewFile && !s3FileUploadInput.onlyCore && !s3FileUploadInput.hidePath">
  <ap-checkbox
    [text]="'Client specific file'"
    [(ngModel)]="isClientSpecificFile"
    class="a-mx-10"
    (onCheck)="onClientSpecificFlagChanged($event)"
  >
  </ap-checkbox>
</div>
<div
  class="upload-container"
  [ngClass]="{ disabled: disabledText }"
  [attr.data-disabled-text]="disabledText"
  >
  <span class="appkiticon icon-download-light-outline" *ngIf="!isNewFile" title="Download file from S3" (click)="downloadFileFromS3()"></span>
  <ap-fileupload
    uploadUrl="{{ uploadAPIEndPoint }}"
    title="{{ title }}"
    [additionalParameter]="s3FileUploadRequest"
    uploadDescription="{{ description }}"
    uploadText=""
    [config]="fileuploadConfig"
    (onNavigateChange)="navigateToMapping($event)"
    (onError)="erroredFun($event)"
  >
  </ap-fileupload>

</div>

<ap-alert [(show)]="alertDisplay" [alertType]="'error'" [iconName]="'icon-alert-fill'" [timer]="5000">
    Error occured while downloading the file.
</ap-alert>
<ap-alert [(show)]="alertSuccessDisplay" [alertType]="'success'" [iconName]="'icon-alert-fill'" [timer]="5000">
    File Uploaded Successfully
</ap-alert>


