import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import _, { filter } from 'lodash';
import { HeaderService } from '../../header/header.service';
import { SummaryDiagnosticService } from '../summary-diagnostic/summary-diagnostic.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardFilterService {
 
 
  constructor(private httpClient: HttpClient,
  private headerServie: HeaderService,
  public summaryDiagService: SummaryDiagnosticService) { }
  //screenFilters :any =  {};
  screenFilters: any = [{screen_key:'',filters:[],businessLoading:false,selectedFilterParams:{},selectedBusiness:[]}]
  dataFiltersLoaded: EventEmitter<any> = new EventEmitter();
  filtersLoaded:boolean =false;
  filterCache: any = [{screen_key:'',system:[],scenario:[],filing_group:[],business:[],taxYearList:[]}]
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  getFilters(baseUrl:string, filterKey:any,clientKey:number,screenKey:number,fromDrillDown:any){

    let filterGroupURL = '/getFilters?filter_group_key='+filterKey;

    const url = `${baseUrl}${filterGroupURL}`;
    let params:any = {};
    //params.client_key = 0;
    params.screen_key = screenKey;
    return this.httpClient.get(url,{params}).pipe(map((responseData:any) =>{
          return responseData;
    })
    )
  }
  getFilterData(tempTaxYearList:any,baseUrl:string,filters:any,clientKey:any, globalParams:any,fromDrillDown:any) : Observable<any> {
    let params:any = {};
    let promises: any = [];
   // params.client_key = 0;
    
  _.each(this.screenFilters,(i)=>{
    if(i.screen_key == globalParams.screen_key){
      _.each(i.filters,(filter)=>{
        if(filter.data_type == 'inherit'){
            filter.data = tempTaxYearList;

        }else if(filter.data_type == 'static' && filter.static_values && filter.static_values.length > 0){
            let values =  JSON.parse(filter.static_values);
            _.each(values,(item)=>{
              item.text = item.LABEL || item.label;
              item.value = item.VALUE || item.value;
              if(fromDrillDown.isFromDrillDown){
                if(this.summaryDiagService.drillDown.selectedFilterParms[filter.param_name] == item.value){
                  item['selected']= true;
                }
              }
            });
            if(!fromDrillDown.isFromDrillDown){
              values[0]['selected'] = true;
            }
            filter.data = values;
        }else if(filter.data_type == 'load'){
            params = _.merge({}, globalParams, params);
            let filterApi = '/'+filter.data_url;
            const url = `${baseUrl}${filterApi}`;
            filter.isLoading = true;
            filter.isLoaded = false;
            let promise = this.httpClient.get(url,{params}).toPromise()
            .then((res:any) => {            
              if(filter.param_name != 'parent_me_string'){
                if(res.jsonObject && res.jsonObject.length>0){
                  _.each(res.jsonObject,(i)=>{
                    i.text =  i.LABEL || i.label;
                    i.value = i.VALUE || i.value;       
                    if(fromDrillDown.isFromDrillDown){
                      if(this.summaryDiagService.drillDown.selectedFilterParms[filter.param_name] == i.value){
                        i['selected']= true;
                      }
                    }           
                  });
                  if(!fromDrillDown.isFromDrillDown){
                      res.jsonObject[0]['selected'] = true;
                  }
                }     
              }
              if(filter.param_name == 'parent_me_string'){
                filter.bizzLoaded = true;
                i.selectedBusiness = [];
                if(fromDrillDown.isFromDrillDown){
                    let selectedSummaryBusiness:any;
                  _.each(this.screenFilters,(ik)=>{
                    if(ik.screen_key == this.summaryDiagService.drillDown.screen_key){
                      selectedSummaryBusiness = ik.selectedBusiness;
                      _.find(ik.filters,(jj)=>{
                        if(jj.param_name=='parent_me_string'){
                          res.jsonObject = jj.data;
                        }
                      })
                    }
                  });
                  i.selectedBusiness=selectedSummaryBusiness;
                 
                }
              }
              filter.data = res.jsonObject;
              filter.isLoaded = true;
              if(_.filter(filters,{'data_type':'load'}).length == promises.length){
                let screenFilterObj : any= {};             
                screenFilterObj =   _.find(this.screenFilters,function(i){
                  if(i.screen_key == params.screen_key){
                      i.filters = filters;
                      i.datafiltersLoading = true;
                      return i;
                  }
                })
                console.log('emitted');
                this.dataFiltersLoaded.emit({screenFilters:screenFilterObj, isOnlyFilterLoaded:false});
              }
          });
          promises.push(promise);
        }        
        Promise.all(promises).catch(function(){

        }).then(() =>{
          
        })
      });
    }
   
  })
  
    return of(this.screenFilters);
  }

  changeRefreshFilters(api: any, filter_id: any,  params: any,scenarioRefreshed:any,isFromDrillDown:any):Observable<any>{
    //let filter_id = filter.filter_key;
    let promises:any = [];
    let screenObj:any ;
    screenObj = _.filter(this.screenFilters,(i)=>{
        if(i.screen_key == params.screen_key){
          i.businesFilterLoading = true;
          return i;
        }
    })
    
    _.each(this.screenFilters,(i,index)=>{
      if(i.screen_key == params.screen_key){
        _.each(i.filters,(item,key)=>{
          if(item.param_name=='scenario'){
            item.loading=true;
            item.isLoaded= true;
          }
          if(item.data_type == 'load' && item.refresh_list && item.refresh_list.length >0 && item.param_name!= 'scenario'){
            let refresh_list = item.refresh_list.split(',');
              if(_.includes(refresh_list,filter_id)){
                item.loading = true;
                let filterApi = '/'+item.data_url;
                const url = `${api}${filterApi}`;
                item.isLoading = true;
                item.isLoaded = false;

                let promise = this.httpClient.get(url,{params}).toPromise()
                .then((res:any) => {
                  if(item.param_name != 'parent_me_string'){
                    if(res.jsonObject && res.jsonObject.length>0){
                      _.each(res.jsonObject,(i)=>{
                        i.text =  i.LABEL || i.label;
                        i.value = i.VALUE || i.value;   
                        if(isFromDrillDown){
                          if(this.summaryDiagService.drillDown.selectedFilterParms[item.param_name] == i.value){
                            i['selected']= true;
                          }
                        }               
                      });
                      if(!isFromDrillDown){
                        res.jsonObject[0]['selected'] = true;
                      }
                    }   
                    item.filterDataLoaded = true;  

                  }
                  if(item.param_name == 'parent_me_string'){
                    item.bizzLoaded = true;       
                    i.selectedBusiness = [];
                    if(isFromDrillDown){
                        let selectedSummaryBusiness:any;
                      _.each(this.screenFilters,(ik)=>{
                        if(ik.screen_key == this.summaryDiagService.drillDown.screen_key){
                          selectedSummaryBusiness = _.cloneDeep(ik.selectedBusiness);
                          _.find(ik.filters,(jj)=>{
                            if(jj.param_name=='parent_me_string'){
                              res.jsonObject = _.cloneDeep(jj.data);
                            }
                          })
                        }
                      });
                      i.selectedBusiness=selectedSummaryBusiness;
                    
                  }
          
                  }
                  item.data = res.jsonObject;
                  item.isLoaded = true;

                  if(item.ui_type == 'df-select'){
                    params[item['param_name']] = res.jsonObject[0].VALUE || res.jsonObject[0].value;
                  }
                  screenObj[0].filters[key] = item;
                    console.log('emitted');
                    //this.screenFilters[index]= screenObj[0];
                    this.dataFiltersLoaded.emit({screenFilters:screenObj, isOnlyFilterLoaded:true});
  
                });
                
                promises.push(promise);
  
              }else{
                item.loading=true;
                item.isLoaded= true;
              }
              Promise.all(promises).catch(function(err){
  
              }).then((data) =>{
                this.screenFilters.allFiltersLoaded = true;
              })
            }
        }) 
      }
    })
     
      return of(this.screenFilters);
    }

   
      getScenarioRefresh(api: any, filter: any, params: any,index:any,isFromDrillDown:any){
        let filterApi = '/'+ filter.data_url;
        const url = `${api}${filterApi}`;
        return this.httpClient.get(url, {params}).pipe(map((data:any)=>{
          if (filter.ui_type === "df-select" && data.jsonObject && data.jsonObject.length) {
            if(filter && filter.param_name!=undefined && params!=undefined){
              params[filter.param_name] = data.jsonObject[0].VALUE||data.jsonObject[0].value;
          }
          let screenObj :any = {}
          if(data.jsonObject && data.jsonObject.length>0){
            _.each(data.jsonObject,(i)=>{
              i.text =  i.LABEL || i.label;
              i.value = i.VALUE || i.value;   
              if(isFromDrillDown){
                if(this.summaryDiagService.drillDown.selectedFilterParms['scenario'] == i.value){
                  i['selected']= true;
                }
              }                 
            });
            if(!isFromDrillDown){
              data.jsonObject[0]['selected'] = true;
            }

            
          }    
          screenObj =   _.find(this.screenFilters,function(i){
            if(i.screen_key == params.screen_key){
              i.filters[index]['isLoading'] = true;
               i.filters[index]['data'] = data.jsonObject;
               i.filters[index]['isLoaded'] = true;
               return i;
            }
          }) 
          this.dataFiltersLoaded.emit({screenFilters:screenObj, isOnlyFilterLoaded:true});

          }        
        }))   
      }
      getScreenCachedFilters(filterArry:any,screen_key:any){
        _.find(this.screenFilters,(i) => {
          if(i.screen_key == screen_key){
          return  i;
          }
       })
      }
      setScreenFilters(data:any,screen_key:any){

      }
      setFilterLoading(screenKey:any){
       let dataFilter:any;
       dataFilter =  _.each(this.screenFilters,(i,index)=>{
          if(i.screen_key == screenKey ){
            _.each(i.filters,(fil)=>{
              if(fil['param_name'] != 'tax_year'){
                fil.isLoading = false;
                fil.isLoaded = false;
                fil.bizzLoaded = false;
              }               
            })               
          }
          i.selectedBusiness = [];
        });
        this.screenFilters = dataFilter
      }    

      getFilterParams(screenKey:any){
        let filterParams:any = {};
        _.each(this.screenFilters,(ii)=>{
          if(ii.screen_key == screenKey){
            _.each(ii.filters,(item)=>{
              if(item.param_name != 'parent_me_string'){
                _.filter(item.data,(yy)=>{
                  if(yy.selected){
                    filterParams[item.param_name] = yy.value;
                  }
                })
              }else if(item.param_name == 'parent_me_string'){
                filterParams['parent_me_string'] = _.map(ii.selectedBusiness,'VALUE').join(',')
              }
            })
            filterParams['screen_key']= ii.screen_key;
            ii.filterParams = filterParams;
          }
        });
        return filterParams;
      }

}