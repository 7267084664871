import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GhostConsoleService {
	url ={
		getGhostConsoleData : '/loadJsonObject?action_code=ulyhus',
		saveGhostConsoleData : '/saveJsonObject?action_code=ck0gzt',
		getGhostJobData : '/loadJsonObject?action_code=i7bcxm',
		saveGhostManualConfig : '/saveJsonObject?action_code=dzh840',
		getGhostConsoleProgress : '/loadJsonObject?action_code=ifgdl2',
		getRequestDetails : '/loadJsonObject?action_code=nws3ti',
		deleteJob : '/saveJsonObject?action_code=dgya3v',
		getFilingData : '/loadJsonObject?action_code=gk7crv'
	}
	durationInSeconds= 5;
	constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	getGhostConsoleProgressData(baseURL:string, params: any, filingGroup: any) {
		// let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getGhostConsoleProgress}`;

		let body = new FormData();
		body.append('jcd_key', params.jcd_key.toString());
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('filing_key', filingGroup.toString());

		return this.http.post(url, body, options).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
				return res.jsonObject;
				}
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));;
	}

	getGhostAutoRunData(baseURL:string, params: any, filingGroup: any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		// let headers = new HttpHeaders({
		// 	'client_key': clientKey
		// });
		//const options= {withCredentials:true, headers:headers}
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getGhostConsoleData}`;

		let body = new FormData();
		body.append('jcd_key', params.jcd_key.toString());
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
    body.append('scenarioCode', params.scenarioCode.toString());
		body.append('is_issue_key_enabled', params.is_issue_key_enabled.toString());
		body.append('filing_key', filingGroup.toString());

		return this.http.post(url, body, options).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
				return res.jsonObject;
				}
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));;
	}

	saveGhostAutoRunData(baseURL : string, jsonData : any) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		//headers.append('client_key', clientKey ? clientKey : '');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_data', JSON.stringify([jsonData]));
		body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());
		body.append('is_issue_key_enabled', jsonData.IS_ISSUE_KEY.toString());
		body.append('scenarioCode', jsonData.SCENARIO_CODE.toString());

		const url = `${baseURL}${this.url.saveGhostConsoleData}`;

		//return this.httpClient.post(url, body, { headers });

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
					if(res.errorMessage != "null"){
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					return res;
				}else{
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getGhostJobTableData(baseURL:string, params: any, filingGroup: any){
		let clientKey = sessionStorage.getItem('client_key') || '';

		// let headers = new HttpHeaders({
		// 	'client_key': clientKey
		// });

		const options = {withCredentials: true};
		const url = `${baseURL}${this.url.getGhostJobData}`;

		let body = new FormData();
		body.append('jcd_key', params.jcd_key.toString());
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('filing_key', filingGroup.toString());

		return this.http.post(url, body, options).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
					return res.jsonObject;
				}
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getRequestIdData(baseURL:string, params: any, JobId: number){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options = {withCredentials: true};
		const url = `${baseURL}${this.url.getRequestDetails}`;

		let body = new FormData();
		body.append('jcd_key', params.jcd_key.toString());
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('job_id', JobId.toString());

		return this.http.post(url, body, options).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
					return res.jsonObject;
				}
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	saveGhostManualData(baseURL : string, jsonData : any) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		//headers.append('client_key', clientKey ? clientKey : '');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_data', JSON.stringify([jsonData]));
		body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());
		body.append('issue_id', jsonData.ISSUE_ID.toString());
		body.append('is_issue_key_enabled', jsonData.IS_ISSUE_KEY.toString());
		body.append('scenarioCode', jsonData.SCENARIO_CODE.toString());
		body.append('mode', 'MANUAL');

		const url = `${baseURL}${this.url.saveGhostManualConfig}`;

		//return this.httpClient.post(url, body, { headers });

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
					if(res.errorMessage != "null"){
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					return res;
				}else{
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	deleteGhostJob(baseURL : string, id : number, jsonData : any) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		//headers.append('client_key', clientKey ? clientKey : '');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('request_key', JSON.stringify(id));
		body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());

		const url = `${baseURL}${this.url.deleteJob}`;

		return this.httpClient.post(url, body, { headers });
	}

	getGhostFilingData(baseURL:string, params: any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getFilingData}`;

		let body = new FormData();
		body.append('jcd_key', params.jcd_key.toString());
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());

		return this.http.post(url, body, options).pipe(
			map((res:any) => {
				if(res.callSuccess == "1"){
				return res.jsonObject;
				}
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));;
	}

	openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}
}
