<ul class="st-extended-menu" *ngIf="row.showExtendedMenu"
  [style.top]="menuPos.offsetTop + 'px'" [style.left]="menuPos.offsetLeft > 0 ? menuPos.offsetLeft + 'px' : 'unset'"
  [style.transform]="menuPos.expandAbove ? 'translate(205px, -222px)' : 'translate(205px, 6px)'">
  <li *ngFor="let template of templateConfig | filterHidden" (click)="changeStatus(template)">
    <label style="cursor: pointer;">
      <input type="radio" [value]="template.REASONID" [(ngModel)]="row.ignoredId">
      {{template.REASON}}
    </label>
  </li>
</ul>
