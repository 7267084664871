import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators'

@Injectable()
export class S3FileUploadService {

  constructor(private httpClient: HttpClient) {}

  downloadFile(fileKey: string | undefined,clientKey:string | undefined, baseUrl: string) {
    let params = new HttpParams({
      fromObject: {
        fileKey: fileKey?.toString() || '',
        clientKey: clientKey?.toString() || ''
      },
    });

    !clientKey?.toString() && (params = params.delete('clientKey'))


    const url = `${baseUrl}/s3/download`;
    return this.httpClient
      .get<any>(url, { withCredentials: true, params: params,responseType: 'blob' as 'json',observe:'response' })
      .pipe(
        tap((response) => {
          if (response.body) {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(response.body as Blob);
            const fileName = response.headers.get('content-disposition')?.replace('attachment;filename=','');
            if (fileName)
            downloadLink.setAttribute('download', fileName);
            downloadLink.target = "_blank";
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
        })
      )
  }
}
