import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {StatementTrackerService} from '../../../../../statement-tracker.service';
import {CreateDisclosureSharedService, DropDownType} from '../../../create-disclosure-shared.service';
import {DSTEntityDetails} from '../../template-leid-selector/template-leid-selector.component';

@Component({
  selector: 'dst-view-template-form926-id214',
  templateUrl: './view-template-form926-id214.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form926-id214.component.scss']
})
export class ViewTemplateForm926Id214Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form214!: FormGroup;
  stmtData!: StmtData;
  attMap: any;
  loaded: boolean = false;
  startLoader: boolean = false;
  choice1b: boolean = false;
  choice1bEin: boolean = false;
  showParentCorporation: boolean = false;
  dcnName: string = '';
  dcnIDNum: string = '';
  f926Pship: boolean = false;
  goldAddr: boolean = false;
  manualAddr: boolean = false;
  line12b: boolean = false;
  line12c: boolean = false;
  line12d: boolean = false;
  line21b: boolean = false;
  pshipName: string = '';
  pshipEin: string = '';
  pshipEinMissReason: string = '';
  disableActions: boolean = false;
  taxTypesOptions: any = [{data: []}];
  line8TaxType: string | undefined = '';
  countryValue: string = '';
  TrCountryValue: string = '';
  CountryOptions: any[] = [{
    data: []
  }];

  constructor(private formBuilder: FormBuilder,
              private sharedService: CreateDisclosureSharedService) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form214 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
      });

    //load Stmt data
    this.sharedService.getLoadStatementData(214)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};
        //console.log('Data---' + this.stmtData);
        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }


        });
        //console.log(this.attMap);
        //console.log('After loop Attr data' + JSON.stringify(this.attMap));

        /*//check totals are available
        if (this.attMap['Desc'].length >= 1) { this.descTotalAvl = true }
        if (this.attMap['Desc1'].length >= 1) { this.basisAmt1Total = true }*/

        if (this.attMap['Choice1b'] !== undefined && this.attMap['Choice1b'][0] === 'No') {
          this.choice1b = true;
        }

        if (this.attMap['ShareholderEinMissingReason'] !== undefined && this.attMap['ShareholderEinMissingReason'][0] !== '') {
          this.choice1bEin = true;
        }

        if (this.attMap['Choice1c'] !== undefined && this.attMap['Choice1c'][0] === 'No') {
          this.showParentCorporation = true;
          const splits: string[] | undefined = this.stmtData.dcnname?.split('- (');
          this.dcnName = splits![0].trim();
          //this.dcnIDNum = splits![1].slice(0, -1).trim();
          this.dcnIDNum = splits![1].split(')')[0].trim();
        }
        if (this.attMap['NameOfPartnership'] !== undefined && this.attMap['NameOfPartnership'][0] !== '') {
          this.f926Pship = true;

        }
        if (this.attMap['IsForeignCorpInGold'] !== undefined && this.attMap['IsForeignCorpInGold'][0] === 'Yes') {
          this.goldAddr = true;
          this.line8TaxType = this.stmtData.secondarytaxtype || '';
          this.loadTaxTypesDescs();
        } else {
          this.manualAddr = true;
          if (this.attMap['Country'] !== undefined && this.attMap['Country'][0] !== '') {
            this.countryValue = this.attMap['Country'][0];
            const selectedCountry: any = this.CountryOptions[0].data.find((s: any) => s.value.toString() === this.attMap['Country'][0]);
            selectedCountry && (this.countryValue = selectedCountry.text || '');
          }
          if (this.attMap['TransfereeCountry'] !== undefined && this.attMap['TransfereeCountry'][0] !== '') {
            this.TrCountryValue = this.attMap['TransfereeCountry'][0];
            const selectedCountry: any = this.CountryOptions[0].data.find((s: any) => s.value.toString() === this.attMap['TransfereeCountry'][0]);
            selectedCountry && (this.TrCountryValue = selectedCountry.text || '');
          }
        }
        if (this.attMap['domesticcorptransfer'] !== undefined && this.attMap['domesticcorptransfer'][0] === '1') {
          this.line12b = true;
        }
        if (this.attMap['transfereeshareholder'] !== undefined && this.attMap['transfereeshareholder'][0] === '1') {
          this.line12c = true;
        }
        if (this.attMap['transferredlossamount'] !== undefined && this.attMap['transferredlossamount'][0] != '') {
          this.line12d = true;
        }
        if (this.attMap['domesticcorpsec367e'] !== undefined && this.attMap['domesticcorpsec367e'][0] === '1') {
          this.line21b = true;
        }

        if (this.attMap['NameOfPartnership'] !== undefined && this.attMap['NameOfPartnership'][0] !== '') {
          if (!isNaN(this.attMap['NameOfPartnership'][0])) {
            this.sharedService.getDSTEntityInfo(this.attMap['NameOfPartnership'][0])
              .subscribe((data: any[]) => {
                data.forEach(element => {
                  console.log(element);
                  this.pshipName = element.ENTITY;
                  if (element.ein !== '') {
                    this.pshipEin = element.EIN;
                  }

                });
              }, () => {

              }, () => {

              });
          } else {
            this.sharedService.getEntityList(this.attMap['NameOfPartnership'][0], false)
              .subscribe((data: DSTEntityDetails[]) => {
                data.forEach(element => {
                  console.log(element);
                  this.pshipName = element.ENTITY;
                  if (element.EIN !== '') {
                    this.pshipEin = element.EIN;
                  }

                });
              }, () => {

              }, () => {

              });
          }

        }

      }, () => {

      }, () => {

      }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });
  }

  loadTaxTypesDescs() : void {
    this.sharedService.getDSTTaxTypesDesc()
      .subscribe((data: any[]) => {
        data.forEach(element => {
          this.taxTypesOptions[0].data.push({
            id: element.ID,
            text: element.TEXT
          });
        });
      }, () => {

      }, () => {

      }).add(() => {
        this.taxTypesOptions[0].data.forEach((val: any) => {

          if (this.line8TaxType && val.id == this.line8TaxType) {
            this.line8TaxType = val.text;
          }

        });
      });
  }

  selDescForProp(selProp?: String): string {
    let selVal = '';
    if (selProp == 'stockssecurities') {
      selVal = 'Stocks And Securities';
    } else if (selProp == 'inventory') {
      selVal = 'Inventory';
    } else if (selProp == 'otherProp926') {
      selVal = 'Other Property (not listed under another category)';
    } else if (selProp == 'lossProp') {
      selVal = 'Property with built-in loss';
    } else if (selProp == 'cash') {
      selVal = 'Cash';
    } else if (selProp == 'propdescribed367d4') {
      selVal = 'Property described in sec.367(d)(4)';
    }
    return selVal;
  }

  identifyingType(selProp?: String): string {
    let selVal = '';
    if (selProp == 'ein') {
      selVal = 'EIN';
    } else if (selProp == 'inssnventory') {
      selVal = 'SSN';
    }
    return selVal;
  }

  propertySno(selProp?: number, val?: any): number {
    let selVal = 0;
    if (selProp != undefined) {
      selVal = selProp + 1;
    }
    return selVal;
  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
