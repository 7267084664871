import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdminService {

  private sync = new BehaviorSubject('');
  currentMessage = this.sync.asObservable();

  private closePopup = new BehaviorSubject('');
  initiate = this.closePopup.asObservable();

  constructor() { }

  informSync(process: string) {
    this.sync.next(process)
  }

  informClose() {
    this.closePopup.next('');
  }

}