import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { MessageService } from 'projects/gtw-elements/src/app/DST/shared/services/message.service';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import { TrackerTabsService, TrackerTabState } from '../../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, EntityNameChange} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'gtw-view-template-sec368-id186',
  templateUrl: './view-template-sec368-id186.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec368-id186.component.scss']
})
export class ViewTemplateSec368Id186Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formData!: StmtData;
  formState: number = 2; // 2 = reorganization, 3 = reorganization with name change only
  attMap: any = {};
  s3Uploading: boolean = false;
  startLoader: boolean = false;
  primaryEntityName: string = '';
  transfereeName:string = '';

  constructor(private sharedService: CreateDisclosureSharedService,
    private taxTypePipe: TaxTypePipe,
    private dstSharedService: DSTSharedService,
    private trackerTabsService: TrackerTabsService,
    private messageService: MessageService) {
    this.sharedService.getLoadStatementData(186).subscribe((data: StmtData) => {
      this.formData = data;
      this.primaryEntityName = this.formData.primaryentity || '';
      
      // load all attributes into new object for showing on UI as key value pair
      this.formData.children?.forEach((stmt: StmtAttrData) => {
        if (!this.attMap[stmt.attributeName]) {
          this.attMap[stmt.attributeName] = [];
        }
        if (stmt.attributeValue !== undefined) {
          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        } else {
          this.attMap[stmt.attributeName]?.push('');
        }
      });
      console.log(this.attMap);
      if(this.attMap['TransfereeName'] && this.attMap['TransfereeName'][0]) {
        this.formState = 2;
      } else {
        this.formState = 3;
      }
      this.transfereeName = this.attMap['TransfereeName'] || '';     
      
      //EffectiveDate for 367 child
      if(this.attMap['EffectiveDate'] && this.attMap['EffectiveDate'][0]){
        const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();

        trackerTab!.parentTransDate = this.attMap['EffectiveDate'][0];

        this.messageService.broadcast(trackerTab?.id!,"368-on-transDate-set");
      }

      forkJoin([

        this.updateEntitiesInfo(this.formData?.primaryleid || '')
      
      ]).subscribe(([response1]) => {
        setTimeout(() => {
          const html = document.querySelector('#temp368form186');
          const htmlContent = html!.outerHTML;      
          this.sharedService.getFormStateByFormId(186)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
        });
      }, (error: HttpErrorResponse) => {
      
        console.log(error);      
        this.dstSharedService.showAlert('error', error.message);
      
      }).add(() => {
        this.startLoader = false;
      });


      // setTimeout(() => {    
      //   const html = document.querySelector('#temp368form186');
      //   const htmlContent = html!.outerHTML;
        
      //   this.sharedService.getFormStateByFormId(186)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
      // });
    });
  }

  // updateEntitiesInfo(leid: string): void {
  //   this.startLoader = true;
  //   this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
  //     leid,
  //     //this.sharedService.getTransDateStr((this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1]), 1),
  //     this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1],
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);

  //       const nameChangedDate: Date = new Date(response[0].EFFECTIVEDATE!);
  //       const date = this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1];
  //       const transDate: Date = new Date(date.replace(/-/g, '\/'));

  //       if (transDate.getTime() >= nameChangedDate.getTime()) {
  //         nameChangedDate.setDate(nameChangedDate.getDate() - 1);
  //         this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
  //           leid,
  //           nameChangedDate,
  //           this.sharedService.getActiveFormState().taxYear.toString()
  //         ).subscribe((oldEntityResponse: EntityNameChange[]) => {
  //           (response[0].LE_NAME) && (this.transfereeName = oldEntityResponse[0].LE_NAME!);
  //         });
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.startLoader = false;
  //   });
  // }

  updateEntitiesInfo(leid: string): Observable<EntityNameChange[]> {
    this.startLoader = true;
    return this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
      leid,
      //this.sharedService.getTransDateStr((this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1]), 1),
      this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1],
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
           if (response && response[0]) {
              //override entity type
              (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);

              const nameChangedDate: Date = new Date(response[0].EFFECTIVEDATE!);
              const date = this.formState === 2 ? this.attMap['EffectiveDate'][0]: this.attMap['EffectiveDate'][1];
              const transDate: Date = new Date(date.replace(/-/g, '\/'));

              if (transDate.getTime() >= nameChangedDate.getTime()) {
                nameChangedDate.setDate(nameChangedDate.getDate() - 1);
                this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
                leid,
                nameChangedDate,
                this.sharedService.getActiveFormState().taxYear.toString()
                ).subscribe((oldEntityResponse: EntityNameChange[]) => {
                (response[0].LE_NAME) && (this.transfereeName = oldEntityResponse[0].LE_NAME!);
                });
              }
            }
        })
      })
    )
  }

  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 'Section368-Reorganization.pdf', this.formData.statementid)
    .subscribe((response: any) => {
      isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
      isS3upload && (this.s3Uploading =  false);
    }, (eMsg: string) => {
      console.log(eMsg);
      isS3upload && (this.dstSharedService.showAlert('error', eMsg));
    });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp368form186');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  ngOnInit(): void {

  }

  save(): void {
    this.sharedService.setFormData(this.formData);
    this.sharedService.setSavedStatementId(this.formData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
