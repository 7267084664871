<ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="copy-replicate">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div>
            <span class="a-h4">{{mode}} Disclosure</span>
          </div>
        </div>
        <div class="a-modal-body" [style.height]="showDisclosure ? '80vh' : 'unset'">
          <div *ngIf="!showDisclosure">
            <div class="flex-container">
              <div class="step-number">1.</div>
              <div class="filter-container">
                <span class="label">Please select the Tax Year</span>
                <ap-selector [items]="taxYears" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                             (onSelectedChange)="onTaxYearChanged($event)"></ap-selector>
              </div>
            </div>
            <div class="flex-container">
              <div class="filter-container">
                <div class="step-number">2.</div>
                <span class="label">Please select the Return Period</span>
                <ap-selector [items]="taxYearPeriods" [type]="'Dropdown'" [placeholder]="selectorDefaultText"
                             (onSelectedChange)="onPeriodChanged($event)"></ap-selector>
              </div>
            </div>
          </div>
          <div class="viewport-wrapper" *ngIf="showDisclosure">
            <dst-create-disclosure-template-viewport [rowObject]="rowObject"></dst-create-disclosure-template-viewport>
          </div>
        </div>
        <div class="a-modal-footer justify-content-end">
          <div class="a-mt-5">
            <span *ngIf="loadingStatementId || loadingStatementData">
              <ap-loading [colorName]="'a-primary'" [size]="'16px'"></ap-loading>
            </span>
          </div>
          <button class="a-btn a-btn-transparent" *ngIf="!showDisclosure"
                  [disabled]="!filtersLoaded || loadingStatementId || loadingStatementData" (click)="continue()">
            <span class="appkiticon a-btn-icon icon-duplicate-outline"></span>CONTINUE TO COPIED DISCLOSURE
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('copy-replicate')">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>
</ap-modal>
