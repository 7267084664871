<div class="header">
    <div class="header-container">
        <div class="multiOptions">
            <div class="chevronContainer" (click)="goBack()">
                <span class="appkiticon icon-left-chevron-outline font-weight"></span>
            </div>
            <div class="moreContainer">
                <span class="link-icon" (gtwClickOutside)="hideScreenList()">
                    <span class="" (click)="setActiveScreens()">
                        <span class="appkiticon icon-vertical-more-outline font-weight"></span>
                    </span>
                    <div class="dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one" 
                        [ngClass]="{'show':isShowing}" >
                        <ul class="no-bullets">
                            <li *ngFor="let screen of activeScreenKeys | reverseArray;let i=index; trackBy : trackByMethod">
                                <a href="#" class="a-color" (click)="loadScreen(screen, i)">
                                    <span class="material-icons active">circle</span>
                                    {{screen.label || screen.SCREEN_NAME }}
                                </a>
                            </li> 
                            <li *ngIf="activeScreenKeys.length == 0">
                                <a class="a-color">
                                    <span class="material-icons not-active">circle</span>
                                    No data found
                                </a>
                            </li>                   
                        </ul>
                        <div class=""></div>
                    </div>
                </span>    
            </div>
            
            
        </div>
        <!-- <div class="filter">
            <span class="appkiticon icon-filter-outline filterIcon"></span>
        </div> -->
        <!-- <div class="screensContainer">
            <ng-content>

            </ng-content>
        </div> -->
        <!-- <div class="comments">
            <span class="appkiticon icon-comment-outline commentIcon"></span>
        </div> -->
    </div>
</div>