export const HomePageObject = ({
        "home-navigator": {
            "child_id": 22505321,
            "label": "Home",
            "type": "task",
            "level": "2",
            "default_activity": null,
            "jcd_key": "250",
            "jcd_ta_key": null,
            "activity_key": null,
            "screen_key": null,
            "filter_key": null,
            "filter_type": null,
            "filter_inherit": 0,
            "loading_data": false,
            "parentTaskKey": null,
            "id": "5321",
            "split_call_param": null,
            "screen_staus": null,
            "screen_type": null,
            "is_filter_refresh": null,
            "screens_refresh_list": null,
            "icon_css": null,
            "parent_id": 12505,
            "children": [
                {
                    "child_id": 32507069,
                    "label": "Home Page",
                    "type": "activity",
                    "level": "3",
                    "default_activity": "N",
                    "jcd_key": "250",
                    "jcd_ta_key": "5392",
                    "activity_key": "7069",
                    "screen_key": null,
                    "filter_key": null,
                    "filter_type": null,
                    "filter_inherit": 0,
                    "loading_data": false,
                    "parentTaskKey": null,
                    "id": "7069",
                    "split_call_param": null,
                    "screen_staus": null,
                    "screen_type": null,
                    "is_filter_refresh": null,
                    "screens_refresh_list": null,
                    "icon_css": null,
                    "parent_id": 22505321,
                    "screens": [
                        {
                            "child_id": 425010906,
                            "label": "Modules",
                            "type": "screen",
                            "level": "4",
                            "default_activity": null,
                            "jcd_key": "250",
                            "jcd_ta_key": "5392",
                            "activity_key": "7069",
                            "screen_key": "10906",
                            "filter_key": null,
                            "filter_type": null,
                            "filter_inherit": 0,
                            "loading_data": false,
                            "parentTaskKey": null,
                            "id": "10906",
                            "split_call_param": null,
                            "screen_staus": "A",
                            "screen_type": "custom-tab",
                            "is_filter_refresh": null,
                            "screens_refresh_list": null,
                            "icon_css": null,
                            "link": "workspace.modules",
                            "parent_id": 32507069
                        }
                    ]
                }
            ]
        },
        "home-routes":{
            "workspace.modules":{
                "url": "/modules",
                "templateUrl": "app/components/Report_module/report_module_tpl.html",
                "controller": "reportModuleController as ctrl",
                "type": "custom-tab",
                "api": {
                    "get": ""
                },
                "params": {
                    "drillDown": false,
                    "gridFilters": "",
                    "launchBuoy": false,
                    "filters": "",
                    "data": ""
                }
            }
        }
    })

