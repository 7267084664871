import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { values } from 'lodash';
import { Table } from 'primeng-lts/table';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { DfMeDropdownComponent } from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import {
  JSONExportHeader,
  JSONExportService,
} from '../../../shared/_services/JSONExport.service';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import {
  CreateDisclosureService,
  DSTTaxYear,
} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { DSTDynamicComponentResolverService } from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import { TrackerTabsService } from '../../statement-tracker/tracker-tabs.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'gtw-count-by-business',
  templateUrl: './count-by-business.component.html',
  styleUrls: [
    '../../shared/styles/dst-shared.scss',
    './count-by-business.component.scss',
  ],
})
export class CountByBusinessComponent implements OnInit {
  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent)
  modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent)
  dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;

  data: any[] = [];
  
  loading: boolean = false;
  isLoadingExcel: boolean = false;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  previousTaxYears: DSTTaxYear[] = [];
  taxYear: any ;
  previousTaxYear: any;
  selectedBusinessObj: any;
  businessesNameObj: any[] = [];
  currentYear: Number | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(
    private cDSerivce: CreateDisclosureService,
    private dstSharedService: DSTSharedService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerTabsService: TrackerTabsService,
    private dCRService: DSTDynamicComponentResolverService,
    private jsonExportService: JSONExportService,
    private reportsService: ReportsService
  ) {
    this.excelExportFileName =
      'Reports-Count_By_Business_' +
      this.dstSharedService.getExcelExportDateString();
    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine',
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setDSTUser('400368836', 2);
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400368836']);
    // this.dstSharedService.setTaxYearScenario('2020', '40');
  }

  ngOnInit(): void {
    // this.dstSharedService.setDSTUser('400368836', 2);

    //local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();

    // zip(getTaxYears$)
    //   .pipe(take(1))
    //   .subscribe((data) => {
    //     this.taxYears = data[0];
    //     this.setTaxYear('2020');
    //     this.previousTaxYears = this.taxYears.filter((x) => x.text<this.taxYear.text);
    //     this.previousTaxYear = this.previousTaxYears[0];
    //     this.loading = true;
    //     this.reportsService.loadBusinesses(this.taxYear?.text.toString()!, this.previousTaxYear?.text.toString()!)
    //       .subscribe((result: any) => {
    //         console.log('Testing : ', result);
    //         this.businessesNameObj = result;
    //         this.loading = false;
    //       });
    //   });


    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
      
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(
          param.ssoId,
          Number.parseInt(param.roleLevel)
        );
        this.dstSharedService.setSystemLockUnlockStatus(
          param.systemLockStatus === '1',
          Number.parseInt(param.roleLevel)
        );
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);

        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$)
          .pipe(take(1))
          .subscribe((data) => {
            this.taxYears = data[0];
            this.setTaxYear(param.taxYear);
            this.previousTaxYears = this.taxYears.filter((x) => x.text<this.taxYear.text);
            this.previousTaxYear = this.previousTaxYears[0];
            this.reportsService.loadBusinesses(this.taxYear?.text.toString()!, this.previousTaxYear?.text.toString()!).subscribe((result: any) => {
              console.log('Testing : ', result);
              this.businessesNameObj = result;
            });
          });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert(
          'error',
          'Missing gtw input params, please contact system administrator for assistance!'
        );
      }
    }
  }



  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    
    this.previousTaxYears = this.taxYears.filter((x) => x.text<this.taxYear.text);
    this.previousTaxYear = this.previousTaxYears[0];
    this.loading = true;
    this.selectedBusinessObj = '';
    this.reportsService.loadBusinesses(this.taxYear?.text.toString()!, this.previousTaxYear?.text.toString()!).subscribe((result: any) => {
      console.log('Testing : ', result);
      this.businessesNameObj = result;
      this.loading = false;
    });
  }

  onPreviousTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.loading = true;
    this.selectedBusinessObj = '';
    this.reportsService.loadBusinesses(this.taxYear?.text.toString()!, this.previousTaxYear?.text.toString()!).subscribe((result: any) => {
      console.log('Testing : ', result);
      this.businessesNameObj = result;
      this.loading = false;
    });
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

   private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> =
      document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden =
        (cdkScrollerEles[0] as HTMLElement).scrollHeight >
        (cdkScrollerEles[0] as HTMLElement).offsetHeight
          ? false
          : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

 
  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  isFormValid(): boolean {
    if (!this.selectedBusinessObj) {
      this.dstSharedService.showAlert('error', 'Please select Business Name');
      return false;
    }
    return true;
  } 

  exportToExcel(): void {
    
    if (!this.isFormValid()) {
      return;
    } else {
      this.isLoadingExcel = true;
      console.log(this.selectedBusinessObj)
      var selectedBusinessesString = '';
      this.selectedBusinessObj.forEach((x: any , index: any) => {
        console.log(x);
        if(index === this.selectedBusinessObj.length -1){
          selectedBusinessesString = selectedBusinessesString + x.key.toString();
        }else{
          selectedBusinessesString = selectedBusinessesString + x.key.toString()+', ';
        }
        
      });
      //console.log(selectedBusinessesString);
      //console.log(this.taxYear.text.toString(), this.previousTaxYear.text.toString(), selectedBusinessesString);

      this.reportsService
      .loadStatementsByYearandBusiness(this.taxYear.text.toString(), selectedBusinessesString)
      .subscribe((result1: any) => {
        console.log('Testing1 : ', result1);
        this.reportsService
          .loadStatementsByYearandBusiness(this.previousTaxYear.text.toString(), selectedBusinessesString)
          .subscribe((result2: any) => {
            console.log('Testing2 : ', result2);
            this.reportsService
              .loadCountByBusiness(this.taxYear.text.toString(), this.previousTaxYear.text.toString(), selectedBusinessesString)
              .subscribe((result3: any) => {
                console.log('Testing3 : ', result3);
                
                var headersMaps: any[] = [];
                const headerMap: JSONExportHeader[] = [
                  {
                    field: 'STATEMENTID',
                    header: 'Statement Id',
                  },
                  {
                    field: 'TAXYEAR',
                    header: 'Year',
                  },
                  {
                    field: 'FORM_TYPE',
                    header: 'Form Type',
                  },
                  {
                    field: 'STATEMENTNAME',
                    header: 'Disclosure',
                  },
                  {
                    field: 'PRIMARYLEID',
                    header: 'Primary LEID',
                  },
                  {
                    field: 'SECONDARYLEID',
                    header: 'Secondary LEID',
                  },
                  {
                    field: 'PRIMARYENTITY',
                    header: 'Primary Name',
                  },
                  {
                    field: 'SECONDARYENTITY',
                    header: 'Secondary Name',
                  },
                  {
                    field: 'PRIMARYBUSINESS',
                    header: 'Primary Business',
                  },
                  {
                    field: 'SECONDARYBUSINESS',
                    header: 'Secondary Business',
                  },
                  {
                    field: 'BRANCHLEID',
                    header: 'Branch LEID',
                  },
                  {
                    field: 'CREATEDBY',
                    header: 'Created By',
                  },
                  {
                    field: 'MODIFIEDBY',
                    header: 'Modified By',
                  },
                  {
                    field: 'CREATEDON',
                    header: 'Created On',
                  },
                  {
                    field: 'MODIFIEDON',
                    header: 'Modified On',
                  },
                  {
                    field: 'ISFILED',
                    header: 'Is Filed',
                  },
                  {
                    field: 'ISQAR',
                    header: 'Is QAR',
                  },
                  {
                    field: 'DCNNAME',
                    header: 'DCN',
                  },
                  {
                    field: 'SIGNATUREFLAG',
                    header: 'Signature Flag',
                  },
                  {
                    field: 'ELECTIONFLAG',
                    header: 'Election Flag',
                  },
                ];
            
                const headerMap_sheet3: JSONExportHeader[] = [
                  {
                    field: 'FORMID',
                    header: 'Form Id',
                  },
                  {
                    field: 'FORM_TYPE',
                    header: 'Form Type',
                  },
                  {
                    field: 'STMTTYPE',
                    header: 'Stmt Type',
                  },
                  {
                    field: 'FORMNAME',
                    header: 'Form Name',
                  },
                  {
                    field: 'PRIORYEARCOUNT',
                    header: this.previousTaxYear.text.toString()+' Year Count',
                  },
                  {
                    field: 'CURRENTYEARCOUNT',
                    header: this.taxYear.text.toString()+' Year Count',
                  },
                ];
            
                headersMaps.push(headerMap, headerMap, headerMap_sheet3);
            
                this.jsonExportService.exportToExcelCountByBusiness(
                  this.excelExportFileName, [result1,result2,result3], headersMaps
                );
                this.isLoadingExcel = false;
              });
          });
      });
    }  
  }

  

 
}
