import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CreateDisclosureSharedService} from './create-disclosure-shared.service';
import {DSTStatement} from '../../../shared/models/dst-statement.model';
import {DSTDynamicComponentResolverService, DSTSavePayload} from './dst-dynamic-component-resolver.service';
import {Subscription} from 'rxjs';
import {TrackerTabsService, TrackerTabState} from '../../tracker-tabs.service';
import {DSTFormState} from '../../../shared/models/dst-form-state.model';
import { MessageService } from '../../../shared/services/message.service';
import { ModalComponent } from 'appkit-angular/modal/modal.component';

@Component({
  selector: 'dst-create-disclosure-shared',
  templateUrl: './create-disclosure-shared.component.html',
  styleUrls: ['./create-disclosure-shared.component.scss']
})
export class CreateDisclosureSharedComponent implements OnInit {

  @Input() rowObj!: DSTStatement;

  activeIndex: number = 0;
  formStateArray!: DSTFormState[];
  private trackerTabId!: string;
  private onSaveSubs!: Subscription;
  isChildEditing: boolean = false;

  constructor(private sharedService: CreateDisclosureSharedService,
              private trackerTabsService: TrackerTabsService,
              private dCRService: DSTDynamicComponentResolverService,
              private messageService: MessageService) {
    this.formStateArray = this.sharedService.getFormStateArray();
    this.activeIndex = this.trackerTabsService.getActiveTrackerTabChildIndex() || 0;
    if(this.activeIndex) this.sharedService.setActiveIndex(this.activeIndex);
    this.onSaveSubs = this.dCRService.onDisclosureSave.subscribe((payload: DSTSavePayload) => {
      // add child statement tabs
      if (payload && payload.trackerTab.id === this.trackerTabId) {
        (!payload.redirectToChild) && payload.childFormStates.forEach((childFormState: DSTFormState) => {
          payload.trackerTab.addFormState(childFormState.taxYear, childFormState.taxYearId,
            childFormState.returnPeriodId || 1, childFormState.statementId, childFormState.formId,
            childFormState.formName, childFormState.formType, childFormState.formTypeId, childFormState.cseId || 0, true);
        });
        this.activeIndex = this.trackerTabsService.getActiveTrackerTabChildIndex()!; // set first child form as active
        (payload.redirectToChild) && this.sharedService.redirectToNextChild(this.trackerTabsService.getActiveTrackerTabChildIndex(), false);
      } else if (this.trackerTabsService.getActiveTrackerTab()?.activeTabIndex === 1) { //set second child form as active
        this.activeIndex = 2;//this.trackerTabsService.getActiveTrackerTabChildIndex()!;
        this.sharedService.redirectToNextChild(this.activeIndex, false);
        // this.trackerTabsService.setActiveTrackerTabChildIndex(0);//reset child index
      }
    });
  }

  ngOnInit() {
    const tab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    tab && (this.trackerTabId = tab.id);
    if (this.rowObj) {
      if (this.rowObj.placeHolder || this.rowObj.statementId === '0') {
        // create;
        this.sharedService.setCSStatementId(this.rowObj.csStatementId);
        this.sharedService.setLeId(this.rowObj.leId);
      } else {
        // view/update
        this.sharedService.setCSStatementId(0);
        this.sharedService.setLeId('');
      }
      this.formStateArray = this.sharedService.getFormStateArray();
    }


    this.messageService.subscribe(this.trackerTabId,"is-child-statement-edit",(payload)=>{
      this.isChildEditing = payload;

      this.messageService.broadcast(this.trackerTabId,"is-child-statement-edit-parent",payload);
    });
  }

  ngOnDestroy(): void {
    this.onSaveSubs?.unsubscribe();
    this.messageService.unsubscribe(this.trackerTabId, "is-child-statement-edit");
  }

  onTabChanged(event: any): void {
    this.sharedService.setActiveIndex(event.index);
  }

}
