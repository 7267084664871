import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ScreenVideo } from '../../../screen-video-container/screen-video-list/screen-video';
import { ScreenVideoUploadService } from '../../../screen-video-container/screen-video-upload/screen-video-upload.service';

@Component({
  selector: 'gtw-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent implements OnInit {

  inProgress=false;
  durationInSeconds = 5;

  private video!:HTMLVideoElement;
  private canvas!:HTMLCanvasElement ;
  private ctx!: CanvasRenderingContext2D | null ;

  @ViewChild('posterFile')
  posterFile!: ElementRef<HTMLImageElement>;
  
  @ViewChild('videoFileInput')
  videoFileInput!: ElementRef<HTMLInputElement>;

  @Input('screen-key')
  screenKey!: number;
  
  @Input('client-key')
  clientKey!: number;
  
  @Input('base-url')
  baseUrl = 'http://localhost:8087';

  @Output()
  cancelDocModal = new EventEmitter<string>();
  @Output('close-model') closeModal = new EventEmitter<any>();


  
  @Input('updateScreenVideo')
  set updateScreenVideoInput (video:ScreenVideo){

    if(video && Object.keys(video).length > 0){
      this.uploadScreenVideoForm = new FormGroup({
        videoId: new FormControl(video.video_id, [Validators.required]),
        videoName : new FormControl(video.video_name, [Validators.required]),
        videoDescription: new FormControl(video.video_desc,[Validators.required]),
        clientKey : new FormControl(this.clientKey, [Validators.required]),
        screenKey : new FormControl(this.screenKey, [Validators.required]),
        videoFile : new FormControl('',),
        posterFile: new FormControl('',),
        is_client_specific: new FormControl(video.is_client_specific == "false"? '':"true")
      });
    }
    this.screenVideoFileName ='';
    this.canvas = this.renderer.createElement('video');
  }

  @Output()
  refreshScreenVideoList = new EventEmitter<boolean>();

  screenVideoFileName: string = "";
  selectedTime = 1;
  step = 1;
  selectedTimeChanged: Subject<number> = new Subject<number>();
  selectedVideoDuration = 0;
  progress =75;

  uploadScreenVideoForm = new FormGroup({
    videoId:new FormControl('',),
    videoName : new FormControl('', [Validators.required]),
    videoDescription: new FormControl('',[Validators.required]),
    clientKey : new FormControl(0, [Validators.required]),
    screenKey : new FormControl(0, [Validators.required]),
    videoFile : new FormControl('',[Validators.required]),
    posterFile: new FormControl('',[Validators.required]),
    is_client_specific: new FormControl(false)
  });  

  constructor(private changeDetectRef : ChangeDetectorRef,
              private screenVideoUploadService:ScreenVideoUploadService,
              private renderer: Renderer2,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {}

  get f(){
    return this.uploadScreenVideoForm.controls;
  }

  onFileSelected() {
    const inputFile: any = this.videoFileInput.nativeElement.files?.[0] || "";
    this.screenVideoFileName = inputFile.name;
    this.selectedTime=1;
    this.extractFrames(inputFile);
    this.uploadScreenVideoForm.patchValue({
      videoFile: inputFile,
      screenKey: this.screenKey,
      clientKey:this.clientKey
    });
    this.changeDetectRef.detectChanges();
  }
  
  selectFrame(event:any) {
    this.selectedTimeChanged.next(event.value);
  }

  private extractFrames(file:any) {

    this.video = this.renderer.createElement('video');//document.createElement('video');
    this.canvas = this.renderer.createElement('canvas');// document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');

    this.video.muted = true;
    this.video.src = URL.createObjectURL(file);

    this.video.addEventListener('loadedmetadata', this.initCanvas, false);
    this.video.addEventListener('timeupdate', this.drawFrame, false);
    this.video.currentTime = 1;
    this.video.play();

    this.selectedTimeChanged.pipe(
            debounceTime(1000), // wait 1 sec after the last event before emitting last event
            distinctUntilChanged()
            ).subscribe((value:any) => {
              // console.log(value);
              this.video.currentTime = value;
              this.selectedTime = value;
              this.video.play();
            });
  }

  private initCanvas = ()=>{
    this.canvas.width = this.video.videoWidth;
    this.canvas.height = this.video.videoHeight;
    this.selectedVideoDuration = Math.trunc(this.video.duration);
  }  

  private drawFrame = ()=>{
    this.video.pause();
    this.ctx?.drawImage(this.video, 0, 0);
    
    this.canvas.toBlob(this.saveFrame, 'image/jpeg'); // this for blob
    //this.saveFrame();
  }

  private saveFrame = (blob: any) => {
    
    this.posterFile.nativeElement.onload = this.revokeURL;
    this.posterFile.nativeElement.src = URL.createObjectURL(blob); // this is for blob
    // this.posterFile.nativeElement.src = this.canvas.toDataURL('image/jpeg');
    this.uploadScreenVideoForm.patchValue({
      posterFile: blob
    });
    this.changeDetectRef.detectChanges();
  }

  private revokeURL = ()=>{
    // we don't need the video's objectURL anymore
    // URL.revokeObjectURL(this.video.src);
    // URL.revokeObjectURL(this.video.src);
  }

  uploadScreenVideo() {
    
    if(!this.uploadScreenVideoForm.valid){
      return ;
    }
    this.inProgress=true;
    let uploadFormData = new FormData();
    
    uploadFormData.append('videoId',this.uploadScreenVideoForm.get('videoId')?.value);
    uploadFormData.append('videoName',this.uploadScreenVideoForm.get('videoName')?.value);
    if(this.uploadScreenVideoForm.get('videoFile')?.value){
      uploadFormData.append('file',this.uploadScreenVideoForm.get('videoFile')?.value);
    }      
    uploadFormData.append('screenKey',this.uploadScreenVideoForm.get('screenKey')?.value);
    uploadFormData.append('clientKey',this.uploadScreenVideoForm.get('clientKey')?.value);
    uploadFormData.append('videoDesc',this.uploadScreenVideoForm.get('videoDescription')?.value);
    if(this.canvas.toDataURL && this.canvas){
      uploadFormData.append('posterFile',this.canvas?.toDataURL('image/jpeg'));
    } 
    const isCLientSpecific = this.uploadScreenVideoForm.get('is_client_specific')?.value;
    
    if(isCLientSpecific == true || isCLientSpecific == "true"){
      uploadFormData.append('is_client_specific', 'true');
    }else{
      uploadFormData.append('is_client_specific', 'false');
    }

    this.screenVideoUploadService.upload(this.baseUrl, uploadFormData, this.clientKey)
            .subscribe( 
              res => {
                console.log("this the response",res);
                this.inProgress=false;
                
                if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
                  this.openSnackBar("Video Uploaded Successfully !!");
                  this.refreshScreenVideoList.emit(true);
               //   (document.querySelector('button.reset_button[mat-raised-button]') as HTMLElement).click();
               //reset_button 
              // (document.getElementById('reset_button') as HTMLElement).click()
                } else {
                  this.openSnackBar("Video Upload Failed " + res.errorMessage);
                }
                this.cancel()

              },
              error => {
                this.openSnackBar("Video Upload Failed");
                console.log("this is the error",error);
                this.inProgress=false;
              });
  }

  resetUploadScreenVideoForm() {
    this.screenVideoFileName = "";
    this.uploadScreenVideoForm.get('videoId')?.clearValidators();
    this.uploadScreenVideoForm.get('videoFile')?.setValidators([Validators.required]);
    this.uploadScreenVideoForm.get('posterFile')?.setValidators([Validators.required]);
    this.uploadScreenVideoForm.patchValue({'is_client_specific': false,'videoId':''});
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }
  cancel(){
      this.closeModal.emit('true');
      this.cancelDocModal.emit('document-modal-cancel');
     }

}
