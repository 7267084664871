<div class="loader-overlay" *ngIf="saving">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [disabled]="!isValidAttachment || isValidPdf === false || !primaryEntity || !dcnId"
                               (onSaveClick)="save()" (onCancelClick)="cancel()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper">
  <div [formGroup]="formAttached">
    <dst-template-header [parentFormGroup]="formAttached" (consolGroupChanged)="OnConsolGroupChanged($event)"></dst-template-header>
    <div style="margin-top: 30px;">
      <dst-template-leid-selector [label]="'Please specify the primary LEID'" [labelId]="'1'"
                                  (onSelectedLEChanged)="onPrimaryEntityChanged($event)">
      </dst-template-leid-selector>
      <div class="a-form-error entity-details" *ngIf="!primaryEntity">
        <span>&nbsp;</span>
        <span>Please specify the Primary Entity</span>
      </div>
      <div class="entity-details">
        <span>Primary Name:</span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.ENTITY}}</span>
      </div>
      <div class="entity-details mt-3">
        <span>EIN:</span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.EIN}}</span>
      </div>
<!--      <div class="entity-details">-->
<!--        <span></span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.COUNTRYINC + getCorpType()}}</span><span>&nbsp;to</span>-->
<!--      </div>-->
      <dst-template-leid-selector [label]="'Please specify the LEID of the Branch Entity'" [labelId]="'2'" [isBranchEntity]="true"
                                  (onSelectedLEChanged)="onBranchEntityChanged($event)">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.ENTITY}}</span>
      </div>
      <div class="entity-details mt-3">
        <span>Branch Entity LEID:</span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.EIN}}</span>
      </div>
<!--      <div class="entity-details">-->
<!--        <span>EIN:</span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.EIN}}</span><span>&nbsp;a</span>-->
<!--      </div>-->
<!--      <div class="entity-details">-->
<!--        <span></span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.COUNTRYCODEINC}}</span><span>&nbsp;Corporation.</span>-->
<!--      </div>-->
    </div>
    <div class="a-mt-30" *ngIf="createdFormData">
      <span style="width: 400px">Statement ID:</span><span class="template-color-blue">{{createdFormData.statementid}}</span>
    </div>
    <div class="a-text-primary" style="margin-top: 30px" *ngIf="createdFormData?.downloadUrl">
      <span style="padding-left: 400px;"><a style="color: #d04a02; cursor: pointer;" (click)="download()">{{createdFormData.attachmentname}}</a></span>
      <span class="appkiticon icon-delete-fill" style="margin-left: 10px" (click)="deleteFileUrl()"></span>
    </div>
    <div class="file-upload-wrapper a-mt-30" formGroupName="fileUploader">
      <label for="uploadPdf" *ngIf="formType !== 'XML Text Box'">E-filing Data</label>
      <ap-form-field [inlineBlock]="true" [id]="'1'" [type]="'textarea'" *ngIf="formType === 'XML Text Box'"
        [label]="'E-filing Data - Please paste the contents of the attachment here for e-filing purposes:'"
        formControlName="filingDataTxtCtrl" [(ngModel)]="displayContent">
      </ap-form-field>
      <button id="uploadPdf" class="file-upload-btn" [ngClass]="{'error': (fileCtrl!.dirty || fileCtrl!.touched) && (!isValidAttachment || isValidPdf === false)}"
              (click)="fileUpload.click()">
        <span>
          <span *ngIf="file" style="margin-right: 5px;">{{file.name}}</span><span class="browse-span">Choose your file</span>
        </span>
        <input type="file" name="upload" [accept]="fileAcceptStr" (change)="onFileChanged($event)" #fileUpload formControlName="file">
        <div class="validating-loader" *ngIf="validatingPdf">
          <ap-loading [colorName]="'a-primary'" [size]="'20px'"></ap-loading>
        </div>
      </button>
      <div class="a-form-error" *ngIf="(fileCtrl!.dirty || fileCtrl!.touched) && !isValidAttachment">
        Please select a valid file with any of the following extension names: {{fileAcceptShort?.join(',')}}
      </div>
      <div class="a-form-error" *ngIf="isValidPdf === false">
        You have selected an invalid pdf file, please choose one without any fillable fields!
      </div>
    </div>
  </div>
</div>
