<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form194" class="ap-form-field-my-wrapper">
    <dst-template-header [parentFormGroup]="form194" [overwriteFormName]="'STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS'" (formOptionChanged)="onFormOptionChanged($event)"
      (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <div formGroupName="main" class="section-content ap-form-field-my-wrapper">

      <div class="">
        The parties:
      </div>
      <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee'" [labelId]="'100'"
        (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field entity-label">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="transferee === undefined">
        Please specify a LEID of Transferee
      </div> -->
      <div class="">
        <span class="template-color-blue">{{!transferee ? '' :
          transferee.ENTITY}}</span><span>&nbsp;EIN (</span><span class="template-color-blue">{{!transferee ? '' :
          (transferee.EIN ? transferee.EIN : 'N/A')}}</span><span>)&nbsp;a &nbsp;</span><span class="template-color-blue">{{!transferee ? '' :
          transferee.COUNTRYINC + (transferee | taxtype: transferee?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>, adopted a resolution to
          dissolve</span>
      </div>
      <dst-template-leid-selector [label]="'Please specify the LEID of the Transferor'" [labelId]="'100'"
        (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field entity-label">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
        Please specify a LEID of transferor
      </div> -->
      <div class="">
        <span class="template-color-blue">{{!transferor ? '' :
          transferor.ENTITY}}</span><span>&nbsp;EIN (</span><span class="template-color-blue">{{!transferor ? '' :
          (transferor.EIN ? transferor.EIN : 'N/A')}}</span><span>)&nbsp;a &nbsp;</span><span class="template-color-blue">{{!transferor ? '' :
          transferor.COUNTRYINC + (transferor | taxtype: transferor?.TAXTYPECODE: entityNameChangeOnDate)}}</span>
      </div>
      <br />


      <ol class="section-content">
        <li>
          The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the "Code")
          applies.
        </li>
        <li>
          <div class="inline-paragraphs">
            On
            <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
              [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)" [(ngModel)]="AdoptedDate"
              (click)="onDPOpen()" formControlName="AdoptedDate" class="date-picker">
            </ap-date-picker>
            <span class="template-color-blue">&nbsp;{{!transferee ? '' :
              transferee.ENTITY}}</span>, a <span class="template-color-blue">{{!transferee ? '' :transferee.COUNTRYINC}}</span> corporation, adopted a resolution to dissolve&nbsp;<span
              class="template-color-blue">{{!transferor ? '' :
              transferor.ENTITY}}</span>, a  <span class="template-color-blue">{{!transferor ? '' :transferor.COUNTRYINC}}</span> corporation, in a liquidation to which Code Sections 332 and 367(b) and
            Treasury Reg. Section 1.367(b)-1(c)(4) apply. Following the adoption of the plan of liquidation, <span
              class="template-color-blue">{{!transferor ? '' :
              transferor.ENTITY}} </span> transferred all of its assets and liabilities to its parent, <span
              class="template-color-blue">{{!transferee ? '' :
              transferee.ENTITY}}</span>. All of the assets and liabilities of <span
              class="template-color-blue">{{!transferor ? '' :
              transferor.ENTITY}}</span>, both before and after the
            liquidation, were located in  <span
            class="template-color-blue">{{!transferor ? '' :
            transferor.COUNTRYINC}}</span>. <span class="template-color-blue">{{!transferee ? '' :
              transferee.ENTITY}}</span>
              <span class="in-line"> owned <ap-form-field [inlineBlock]="true" *ngIf="ownedPctField" [id]="'f367bOwnedPctVal'" [type]="'inputNumber'" class="number-field number-field-w100 pct-margin required-field" [funType]="'typeTwo'"
                           [minNum]="0" [maxNum]="100" [label]="''" formControlName="OwnedPctVal" [(ngModel)]="OwnedPctVal" >
              </ap-form-field>
             <span *ngIf="!ownedPctField"> &nbsp;100</span></span>% of the capital stock issued and outstanding as of the date of the
            adoption of the plan of liquidation, and at all times since, up to and including the date of distribution in
            liquidation.
          </div>

        </li>
        <li>
          Not applicable.
        </li>
        <li>
          As a result of the liquidation, pursuant to Code Section 381(a) and Regulations under Section 367(b), <span
            class="template-color-blue">{{!transferee ? '' :
            transferee.ENTITY}}</span> will either succeed to the amount and character of the earnings and profits of
          <span class="template-color-blue">{{!transferor ? '' :
            transferor.ENTITY}}</span> (with appropriate adjustments under § 1.367(b)-7, as applicable), or
          include any amount required to be taken into income or loss or as an adjustment to basis, or any other tax
          attribute, as applicable.
        </li>
        <li>
          For information required pursuant to regulations under Code Section 332, see the separate statement.
        </li>
        <li>
          Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or
          the regulations under those sections has been provided with this tax return on Form 5471, Information Return
          of U.S. Persons with Respect to Certain Foreign Corporations.
        </li>
        <li>
          Not applicable.
        </li>
      </ol>
      <dst-template-leid-selector [isBranchEntity]="true" [label]="'Please specify the LEID of the Branch Entity:'"
        [labelId]="'3'" (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' :
          branchEntity.ENTITY}}</span>
      </div>
      <div class="d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor">
            / </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
