<ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="project-tagging">
  <div slot="content">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div>
            <span class="a-h4">Project Tagging</span>
          </div>
          <div>
            <span *ngIf="savingSelection">
              <ap-loading [colorName]="'a-primary'" [size]="'18px'"></ap-loading>
            </span>
          </div>
          <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeModal('project-tagging')">
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body">
          <div style="margin-bottom: 10px;">
            <span class="a-h-12">Please select a Project, Project Step (as applicable) and Business level used for tax return review</span>
          </div>
          <div class="project-info row">
            <div class="col-md-3">
              <span class="font-weight-bold">Project</span>
            </div>
            <div class="col-md-7">
              <span class="">{{getProjectName()}}</span>
            </div>
          </div>
          <div class="project-info row">
            <div class="col-md-3">
              <span class="font-weight-bold">Project Step</span>
            </div>
            <div class="col-md-7">
              <span class="">{{getProjectStepName()}}</span>
            </div>
          </div>
          <div class="project-info row">
            <div class="col-md-3">
              <span class="font-weight-bold">Project Business</span>
            </div>
            <div class="col-md-7">
              <span class="">{{selectedBusiness?.name}}</span>
            </div>
          </div>
          <div class="trees row">
            <div class="col-md-3">
              <input class="d-flex w-100 search-box" style="border: 1px solid #333333;padding-left: 10px;" id="filter" #filter (keyup)="projectsTree.treeModel.filterNodes(filter.value)" placeholder="Search Projects"/>
            </div>
            <div class="col-md-3" style="text-align: right;">
              <button aria-label="Close" class="a-btn a-btn-transparent" (click)="collapseAllProjectTrees()">Collapse All</button>
            </div>
            <div class="col-md-3">
              <input class="d-flex w-100 search-box" style="border: 1px solid #333333;padding-left: 10px;" id="filter1" #filter1 (keyup)="businessesTree.treeModel.filterNodes(filter1.value)" placeholder="Search Businesses"/>
            </div>
            <div class="col-md-3" style="text-align: right;" >
              <button aria-label="Close" class="a-btn a-btn-transparent" (click)="collapseAllBusinessTrees()">Collapse All</button>
            </div>
          </div>
          <div class="trees row">
            <div class="col-md-6">
              <div class="tree-container">
                <span *ngIf="loadingData">
                  <ap-loading [colorName]="'a-primary'" [size]="'24px'"></ap-loading>
                </span>
                
                <!-- <button (click)="projectsTree.treeModel.clearFilter()">Clear Filter</button> -->
                
                <!-- <tree-root #projectsTree [focused]="true" *ngIf="!loadingData" [nodes]="projects" [options]="options" ></tree-root> -->
                <tree-root #projectsTree *ngIf="!loadingData" [nodes]="projects" [options]="options" (activate)="onProjectSelected($event)"></tree-root>
              </div>
            </div>
            <div class="col-md-6">
              <div class="tree-container">
                <span *ngIf="loadingData">
                  <ap-loading [colorName]="'a-primary'" [size]="'24px'"></ap-loading>
                </span>
                
                <!-- <button (click)="businessesTree.treeModel.clearFilter()">Clear Filter</button> -->
                <!-- <tree-root #businessesTree  *ngIf="!loadingData" [nodes]="businesses" [options]="options"></tree-root> -->
                <tree-root #businessesTree *ngIf="!loadingData" [nodes]="businesses" [options]="options" (activate)="onBusinessSelected($event)"></tree-root>
              </div>
            </div>
          </div>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="OkSubmit('project-tagging')">SAVE</button>&nbsp;&nbsp;
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('project-tagging')">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>
</ap-modal>
