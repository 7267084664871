import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ScreenVideoListService {

  private videoListURL = '/getVideoMetadata';
  private getVideoURL = '/getVideoMetadataById';
  private deleteVideoURL = '/deleteVideoDetails';

  constructor(private http:HttpClient) { }
  
  getAllVideos(baseURL:string,clientKey:number, screenKey:number):Observable<Object>{
    const options ={ 
                    //  headers : {"client_key": clientKey.toString()},
                     withCredentials: true
                    };
    const data = new FormData();
    data.append('clientKey',clientKey.toString());
    data.append('screen_key',screenKey.toString());
    
    return this.http.post(`${baseURL}${this.videoListURL}`,data,options)
            .pipe(map((videoList:any) => videoList));
  }

  getVideoById(baseURL:string, id:number):Observable<Object>{
    const options ={ 
                    //  headers : {"client_key": clientKey.toString()},
                     withCredentials: true
                    };
    
    return this.http.get(`${baseURL}${this.getVideoURL}?videoId=${id}`,options)
            .pipe(map((video:any) => video));
  }

  deleteScreenVideo(baseURL:string,clientKey:number, videoId:number){
    const option = {
                    withCredentials:true
                   }
    return this.http.post(`${baseURL}${this.deleteVideoURL}/${videoId}/${clientKey}`,option);

  }

  
}
