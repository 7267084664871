import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenDetailsModel } from '../screen-details/screen-details-model';

@Injectable({
  providedIn: 'root'
})
export class ScreenUpdateService {

  private URLs = {
    getTopDiagnosticsData: '/getTopFiveEndpoints?top_five=true',
    getTopFiveScreens: '/getTopFiveScreens',
    getLastViewedScreens: '/getLastViewedScreen'
  };
  //TODO:: replace with redux
  allFavScreens$:BehaviorSubject<ScreenDetailsModel[]> = new BehaviorSubject<ScreenDetailsModel[]>([]);
  lastViewedScreen: any[]=[];
  constructor(private _httpClient: HttpClient) { }

  public getLastViewedScreens(baseURL:string, activeScreens:[]=[]) {
    const options = {withCredentials: true};
    const url = `${baseURL}${this.URLs.getLastViewedScreens}`;
    return this._httpClient.get(url, options).pipe(map((data: any) => {

      data.forEach((lastScreen:any) => {
          lastScreen.isActive = false;
          activeScreens.forEach((activeScreen:any) => {
            if(lastScreen.screenKey == activeScreen.screen_key){
              lastScreen.isActive = true;
              lastScreen.records = activeScreen.record_count;
            }
          })
      });
      this.lastViewedScreen = data;

      return data;
    }));
  }

  public getTopFiveScreens(baseURL:string) {
    
    const options = {withCredentials: true};
    
    const url = `${baseURL}${this.URLs.getTopFiveScreens}`;
    
    return this._httpClient.get(url, options).pipe(map((data: any) => {
      
      let result = data;
      return Object.keys(result.jsonObject).map((key)=>{
        return {
          value:result.jsonObject[key] ,
          name: key
        }
      });
    
    }));
  }

  public getTopDiagnostics(baseURL:string) {
  
    const options = {withCredentials: true};
  
    const url = `${baseURL}${this.URLs.getTopDiagnosticsData}`;

    return this._httpClient.get(url, options).pipe(map((data: any) => {
      let result = data;
      return Object.keys(result.screen_stats).map((key) => {
        return {
          value:result.screen_stats[key] ,
          name: key
        }
      });
      
    }));
  }

}
