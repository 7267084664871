import {Injectable} from '@angular/core';
import {JSON2SheetOpts, WorkBook, WorkSheet} from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class JSONExportService {

  // xlsx: any;

  constructor() {
    // import('xlsx').then((xlsx: any) => this.xlsx = xlsx);
  }

  exportToExcel(fileName: string, data: any[], headersRemap: JSONExportHeader[], opts?: JSON2SheetOpts): void {
    if (headersRemap) {
      data = this.remapJSON(data, headersRemap);
    }
    import('xlsx').then((xlsx) => {
      
      for (let row = 0; row < data.length; row++) {
        for (let col = 0; col < headersRemap.length; col++) {
          if(headersRemap[col].format != undefined && headersRemap[col].format == 'number'){
            if(data[row][headersRemap[col].header] != undefined)
            data[row][headersRemap[col].header] = this.numberWithCommas(data[row][headersRemap[col].header]);
          }else if(headersRemap[col].format != undefined && headersRemap[col].format == 'percent'){
            if(data[row][headersRemap[col].header] != undefined)
            data[row][headersRemap[col].header] += '%';
          }
        }
      }
      const worksheet: WorkSheet = xlsx.utils.json_to_sheet(data, opts);
      const workbook: WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      xlsx.writeFileXLSX(workbook, fileName + '.xlsx');
    });
  }
  numberWithCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  exportToExcelCountByBusiness(fileName: string, data: any[], headersRemap: JSONExportHeader[][], opts?: JSON2SheetOpts): void {
    
    headersRemap.forEach((value, index) => {
        data[index] = this.remapJSON(data[index], headersRemap[index]);
        console.log(data[index]); // 0, 1, 2
        console.log(value); // 9, 2, 5
    });

    import('xlsx').then((xlsx) => {
      
      const worksheet1: WorkSheet = xlsx.utils.json_to_sheet(data[0], opts);
      const worksheet2: WorkSheet = xlsx.utils.json_to_sheet(data[1], opts);
      const worksheet3: WorkSheet = xlsx.utils.json_to_sheet(data[2], opts);
      const wb: WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, worksheet1, 'Current Year');
      xlsx.utils.book_append_sheet(wb, worksheet2, 'Previous Year');
      xlsx.utils.book_append_sheet(wb, worksheet3, 'Count By Business');
      xlsx.writeFileXLSX(wb, fileName + '.xlsx');
    });
  }

  // exportToExcel(fileName: string, data: any[], headersRemap?: JSONExportHeader[]): void {
  //   if (headersRemap) {
  //     data = this.remapJSON(data, headersRemap);
  //   }
  //
  //   const workbook: Workbook = new Workbook();
  //   const worksheet: Worksheet = workbook.addWorksheet('data');
  //
  // }

  private remapJSON(data: any[], headersRemap: JSONExportHeader[]): any[] {
    const result: any[] = [];
    data.forEach((dataRow: any) => {
      const resRow: any = {};
      headersRemap.forEach((h: JSONExportHeader) => {
        resRow[h.header] = dataRow[h.field];
      });
      result.push(resRow);
    });
    return result;
  }

}

export interface JSONExportHeader {
  header: string;
  field: string;
  format?: string;
}
