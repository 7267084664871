import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isObject } from 'lodash';
import { first } from 'rxjs/operators';
import { S3FolderManagerInput } from '../../admin/components/s3-folder-manager/s3-folder-manager.component';
import { S3FolderManagerService } from '../../admin/components/s3-folder-manager/s3-folder-manager.service';
import { ActionService } from '../../shared/_services/action.service';
import { GtwSnackbarService } from '../../shared/_services/gtw-snackbar.service';

interface EfileReviewAttachmentUploadInput extends S3FolderManagerInput {
  isReadOnlyMode: boolean;
  isProdSubmissionUser: boolean;
  submissionId: string;
  irsSubmissionId: string;
  ssoId: string;
}

@Component({
  selector: 'gtw-efile-review-attachment-upload',
  templateUrl: './review-attachment-upload.component.html',
  styleUrls: ['./review-attachment-upload.component.scss']
})
export class EfileReviewAttachmentUpload implements OnInit {
  loadingAttachments: boolean = false;

  @Input('efileReviewAttachmentUploadInput')
  set setEfileReviewAttachmentUploadInput(s3FolderManagerInput: string | EfileReviewAttachmentUploadInput) {
    if (isObject(s3FolderManagerInput)) {
      this.efileReviewAttachmentUploadInput = s3FolderManagerInput;
      this.init();
    } else {
      try {
        this.efileReviewAttachmentUploadInput = JSON.parse(s3FolderManagerInput);

        this.efileReviewAttachmentUploadInput.canDelete = this.efileReviewAttachmentUploadInput.isProdSubmissionUser;
        this.efileReviewAttachmentUploadInput.isReadOnlyMode = !this.efileReviewAttachmentUploadInput.isProdSubmissionUser && !!this.efileReviewAttachmentUploadInput.irsSubmissionId;
        this.efileReviewAttachmentUploadInput.canUploadMultipleFiles = this.efileReviewAttachmentUploadInput.isProdSubmissionUser;
        this.efileReviewAttachmentUploadInput.path = `/gtw/binaries/${this.efileReviewAttachmentUploadInput.clientKey}/${this.efileReviewAttachmentUploadInput.submissionId}/review-attachments/`;
        this.efileReviewAttachmentUploadInput.readOnlyS3Paths = "...";

        this.init();
      } catch (e) {
        console.log(
          'base URLs Params not stable. Still waiting to get resolved.'
        );
      }
    }
  }

  efileReviewAttachmentUploadInput!: EfileReviewAttachmentUploadInput;


  @Output('on-efile-review-attachment-upload')
  onFileUploadEvent = new EventEmitter<string>();

  constructor(
    private actionService: ActionService,
    private alertService: GtwSnackbarService,
    private s3FolderManagerService: S3FolderManagerService) { }

  ngOnInit(): void {

  }

  init() {
    this.loadReviewAttachments();
  }

  onFileDelete(file: any){

    file.isDeleting = true;
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.efileReviewAttachmentUploadInput.ssoId,
        'attachment_key':file.ATTACHMENT_KEY.toString(),
      }
    });

    this.actionService.saveActionData(this.efileReviewAttachmentUploadInput.baseUrls.api, 33325, +this.efileReviewAttachmentUploadInput.clientKey, params)
      .subscribe((response) => {
        if(response.callSuccess === '1'){
          this.alertService.openSnackBar('Successfully Deleted File!', 'gtw-snackbar--green');
          this.loadReviewAttachments();
        }
        else{
          this.alertService.openSnackBar('Failed to Delete file!', 'gtw-snackbar--red');
        }
        file.isDeleting = false;
      });
  }

  onFileUpload(uploadResponseString: string) {

    if (!uploadResponseString) return;

    const uploadResponse: { fileName: string, callSuccess: string, filePath: string } = JSON.parse(uploadResponseString);

    if(!this.efileReviewAttachmentUploadInput.irsSubmissionId){
          this.onFileUploadEvent.emit(JSON.parse(uploadResponseString));
          return;
    }

    const pathChunks = uploadResponse.filePath.split("/");

    const params = new HttpParams({
      fromObject: {
        'irs_id': this.efileReviewAttachmentUploadInput.irsSubmissionId,
        'sso_id': this.efileReviewAttachmentUploadInput.ssoId,
        'file_key': pathChunks[pathChunks.length - 1]
      }
    });

    this.actionService.saveActionData(this.efileReviewAttachmentUploadInput.baseUrls.api, 33324, +this.efileReviewAttachmentUploadInput.clientKey, params)
      .subscribe((response) => {
        if (response.callSuccess === '1') {
          this.alertService.openSnackBar('Successfully Uploaded Review Attachment', 'gtw-snackbar--green');
          this.loadReviewAttachments();
        }
        else {
          this.alertService.openSnackBar('Failed to Upload file!', 'gtw-snackbar--red');
        }
      });
  }
  loadReviewAttachments() {

    this.loadingAttachments = true;

    const params = new HttpParams({
      fromObject: {
        'sso_id': this.efileReviewAttachmentUploadInput.ssoId,
        'irs_id': this.efileReviewAttachmentUploadInput.irsSubmissionId,
      }
    });
    this.actionService.getActionData(this.efileReviewAttachmentUploadInput.baseUrls.api, 33323, +this.efileReviewAttachmentUploadInput.clientKey, params)
      .pipe(first())
      .subscribe((data) => {
        this.loadingAttachments = false;
        this.s3FolderManagerService.loadAttachments(data.jsonObject);
      })
  }
}
