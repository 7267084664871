import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: number|string, ...args: unknown[]): string {
    if (value === null || value === undefined || value === '') return "";
  //   let amountNum = Number(value).toLocaleString();
  //  console.log("AMOUNT PIPE1 :" + amountNum);
    return Number(value).toLocaleString();
  }

}
