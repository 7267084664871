import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailDiagnosticComponent } from './detail-diagnostic/detail-diagnostic.component';
import { SummaryDiagnosticComponent } from './summary-diagnostic/summary-diagnostic.component';
import { SelectorModule } from 'appkit-angular/selector';
import { SearchModule } from 'appkit-angular/search';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'appkit-angular/checkbox';
import { DiagnosticFilterComponent } from './diagnostic-filter/diagnostic-filter.component';
import { KpiBoxComponent } from './summary-diagnostic/kpi-box/kpi-box.component';
import { DataTableComponent } from './summary-diagnostic/data-table/data-table.component';
import { TableModule } from 'primeng-lts/table';
import {ButtonModule} from 'appkit-angular/button';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { AppNgMaterialModule } from '../app-ng-material.module';
// import { AddEditComponent } from './add-edit/add-edit.component';
import { AppNgAppkitModule } from '../app-ng-appkit.module';
// import { EditDashboardChartDetailsComponent } from './add-edit/edit-dashboard-chart-details/edit-dashboard-chart-details.component';
import { DashboardFilterComponent } from './dashboard-filter/dashboard.filter.component';
import { DetailDiagViewComponent } from './summary-diagnostic/data-table/detail-diag-view/detail-diag-view.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MultiSelectModule } from 'primeng-lts/multiselect';

@NgModule({
  declarations: [
    DetailDiagnosticComponent, 
    SummaryDiagnosticComponent,
    DiagnosticFilterComponent, KpiBoxComponent, DataTableComponent , 
    // AddEditComponent, EditDashboardChartDetailsComponent, 
    DashboardFilterComponent, DetailDiagViewComponent],
  imports: [
    FormsModule,
    CheckboxModule,
    TableModule,
    ButtonModule,
    SharedComponentsModule,
    AppNgMaterialModule,
    AppNgAppkitModule,
    ScrollingModule,
    MultiSelectModule
  ],
  exports: [DetailDiagnosticComponent, SummaryDiagnosticComponent,
    DiagnosticFilterComponent, KpiBoxComponent,DashboardFilterComponent]
})
export class DiagnosticDashboardModule { }
