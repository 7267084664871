import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class MefEfileService {
	url = {

		deleteMEf: '/saveJsonObject?action_code=g46j6i',
		syncJob: '/saveJsonObject?action_code=p2u3sn',
		insertFilingData: '/saveJsonObject?action_code=4erq72'
	}
	durationInSeconds = 5;
	constructor(private httpClient: HttpClient, private http: HttpClient, private _snackBar: MatSnackBar) { }



	deleteMEf(baseURL: string, params: any, jsonSettings: any, jsonData: any, scenarioCode: string, isIssueKeyEnabled: string, issueKey: number) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([jsonSettings]));
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('jcd_key', params.jcd_key.toString());
		body.append('le_type', params.le_type.toString());
		body.append('process_name', 'delete-mef-data');
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		body.append('issue_key', issueKey.toString());
		body.append('scenarioCode', scenarioCode);

		const url = `${baseURL}${this.url.deleteMEf}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					if (res.errorMessage != "null") {
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.openSnackBar("Success!", 'gtw-snackbar--green');
					return res;
				} else {
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return "error";
				return null;
			}
			));
	}

	sync(baseURL: string, params: any, scenarioCode: string, isIssueKeyEnabled: string, issueKey: number,) {
		if (params.filing_grp_key == null) {
			this.openSnackBar("Cannot sync for this entity!", 'gtw-snackbar--red');
			return throwError("error");
		}

		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('jcd_key', params.jcd_key.toString());
		body.append('le_key', params.le_key.toString());
		body.append('filing_grp_key', params.filing_grp_key ? params.filing_grp_key.toString() : '');
		body.append('consol_grp_key', params.consol_grp_key ? params.consol_grp_key.toString() : '');
		body.append('mef_grp_key', params.mef_grp_key ? params.mef_grp_key.toString() : '');
		body.append('grp_type', params.grp_type ? params.grp_type.toString() : '');
		body.append('process_name', params.process_name.toString());
		body.append('form_filer_ind', params.form_filer_ind.toString());
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		body.append('issue_key', issueKey.toString());
		body.append('scenarioCode', scenarioCode);

		const url = `${baseURL}${this.url.syncJob}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					if (res.errorMessage != "null") {
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.openSnackBar("Success!", 'gtw-snackbar--green');
					return res;
				} else {
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					return "error";
				}
				throwError(new Error('oops!'));
				return null;
			}
			));


	}

	saveFilingData(baseURL: string, params: any, jsonSettings: any, jsonData: any, scenarioCode: string, isIssueKeyEnabled: string, issueKey: number,) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([jsonSettings]));
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('jcd_key', params.jcd_key.toString());
		body.append('type', params.type.toString());
		body.append('process_name', 'insert-filing-data');
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		body.append('issue_key', issueKey.toString());
		body.append('scenarioCode', scenarioCode);

		const url = `${baseURL}${this.url.insertFilingData}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					if (res.errorMessage != "null") {
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.openSnackBar("Success!", 'gtw-snackbar--green');
					return res;
				} else {
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					return "error";
				}
				throwError(new Error('oops!'));
				return null;
			}
			));
	}


	openSnackBar(message: string, panelClass: string = '') {
		this._snackBar.open(message, '', {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}
}
