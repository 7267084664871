import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { FgnF5471H1ComponentService } from './fgn-f5471-h1.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';
import { GtwSnackbarService  } from '../../../shared/_services/gtw-snackbar.service';


@Component({
  selector: 'gtw-fgn-f5471-h1',
  templateUrl: './fgn-f5471-h1.component.html',
  styleUrls: ['./fgn-f5471-h1.component.scss']
})
export class FgnF5471H1Component implements OnInit {

  //baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;
  baseGlobalParams:any = {};
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private ssoId: string = '';
  private checkLockedObj: any = [];
  private rowData: any = {};
  private scenarioCode: string = '';
  private filterParams : any= {};
  isViewOnly: boolean = false;
  enableButton: boolean = true;
  private modalName: string = '';
  firf5471h1form ! : FormGroup;
  con_fin_stmt_amt = 0;
  con_fin_stmt_comment = "";
  con_rtn_amt = 0;
  con_rtn_comment = "";
  oth_adj_amt = 0;
  oth_adj_comment = "";
  cfc_pship_dre_val = "";
  con_fin_stmt_flg:boolean = false;
  con_rtn_flg:boolean = false;
  oth_adj_flg:boolean = false;
  validData :boolean = true;
  title:string = '5471 H-1 Worksheet';
  clob_settings: { tax_year: number; scenario: number; jcd_key: number; } = {
    tax_year: 0,
    scenario: 0,
    jcd_key: 0
  };
  clob_data: any = [];


  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}
  @Input('row-data')
  set _rowData(rowData:any)
  {
    if(rowData !== "{{ctrl.rowData}}" && rowData.length > 0)
    {
      let params = JSON.parse(rowData);
      if(params != undefined){
        this.rowData = params;
        this.init();
      }
    }
  }

  @Input('modal-name')
  set _modalName(modalName:string){
    this.modalName = modalName;
    console.log("modalName---" , modalName);
    this.init();
  }

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
  @Input('filter-params')
	set _filter_params(filterParams: string){
    try{
			let params = JSON.parse(filterParams);
			if(params != undefined){
				this.filterParams = params;
			}
			this.init();
		}catch(e){
			console.log("Filter Params not stable. Still waiting to get resolved.");
		}
	}
  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    }catch(error){
		console.log("Not an valid object");
		}
    this.init();
  }

  @Output('close-firf5471h1confirm-info')
  closefirf5471h1confirmInfo = new EventEmitter<string>();

  @Output('save-firf5471h1save-info')
  savefirf5471h1Info = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;

  constructor(private FgnF5471H1ComponentService: FgnF5471H1ComponentService, private _snackBar: MatSnackBar, private alertService:GtwSnackbarService) { 
    this.firf5471h1form = new FormGroup({
      confinstmtcmt: new FormControl(),
      confinstmtamt : new FormControl(),
      conrtnamt : new FormControl(),
      conrtncmt : new FormControl(),
      othadjamt : new FormControl(),
      othadjcmt : new FormControl()
    });  }

  ngOnInit(): void {
  }

  checkInputType():boolean{
		if(
		  Number.isInteger(parseInt(this.ssoId+"") ) &&
      this.rowData != '{}' && 
      (this.modalName != null && this.modalName != '{{ctrl.modalName}}') &&
      JSON.stringify(this.filterParams) != '{}'
    )
		{
		  return true;
		}
		return false;
	}

  init() {   
    if(this.checkInputType()){
      
      console.log("modalName---" , this.modalName);
      if(this.modalName == 'conFinStmtCFC' || this.modalName == 'conFinStmtPSHIP' || this.modalName == 'conFinStmtDRE')
      {
        this.con_fin_stmt_flg = true;
        this.con_fin_stmt_amt = (this.rowData.CON_FIN_STMT == null)? 0 : this.rowData.CON_FIN_STMT;
        this.con_fin_stmt_comment = (this.rowData.CON_FIN_STMT_CMMENTS == null)? "" : this.rowData.CON_FIN_STMT_CMMENTS;
      }
      else if(this.modalName == 'conRtnCFC' || this.modalName == 'conRtnPSHIP' || this.modalName == 'conRtnDRE')
      {
        this.con_rtn_flg = true;
        this.con_rtn_amt = (this.rowData.CON_RTN == null)? 0 : this.rowData.CON_RTN;
        this.con_rtn_comment = (this.rowData.CON_RTN_CMMENTS == null)? "" : this.rowData.CON_RTN_CMMENTS;
      }
      else if(this.modalName == 'othAdjCFC' || this.modalName == 'othAdjPSHIP' || this.modalName == 'othAdjDRE')
      {
        this.oth_adj_flg = true;
        this.oth_adj_amt = (this.rowData.OTH_ADJ == null)? 0 : this.rowData.OTH_ADJ;
        this.oth_adj_comment = (this.rowData.OTH_ADJ_CMMENTS == null)? "" : this.rowData.OTH_ADJ_CMMENTS;
      }
      
    }
  } 

    save(){ 
      this.clob_data = [];
      this.validData = true;
      if(this.con_fin_stmt_flg && this.con_fin_stmt_amt != 0 && this.con_fin_stmt_comment == "")
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter the comments. ','gtw-snackbar--red');
      }
      if(this.con_fin_stmt_flg && isNaN(+this.con_fin_stmt_amt) )
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter numeric value for amount field. ','gtw-snackbar--red');
      }

      if(this.con_rtn_flg && this.con_rtn_amt != 0 && this.con_rtn_comment == "")
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter the comments. ','gtw-snackbar--red');
      }

      if(this.con_rtn_flg && isNaN(+this.con_rtn_amt) )
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter numeric value for amount field. ','gtw-snackbar--red');
      }

      if(this.oth_adj_flg && this.oth_adj_amt != 0 && this.oth_adj_comment == "")
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter the comments. ','gtw-snackbar--red');
      }

      if(this.oth_adj_flg && isNaN(+this.oth_adj_amt))
      {
        this.validData = false;
        this.alertService.openSnackBar('Please enter numeric value for amount field. ','gtw-snackbar--red');
      }
      if(this.validData)
      {
      //////
      this.enableButton = false;
       if( this.modalName == 'conFinStmtCFC' || this.modalName == 'conRtnCFC' || this.modalName == 'othAdjCFC' )
           this.cfc_pship_dre_val = 'CFC';
        else if( this.modalName == 'conFinStmtPSHIP' || this.modalName == 'conRtnPSHIP' || this.modalName == 'othAdjPSHIP' )
           this.cfc_pship_dre_val = 'PSHIP';
        else if ( this.modalName == 'conFinStmtDRE' || this.modalName == 'conRtnDRE' || this.modalName == 'othAdjDRE')
           this.cfc_pship_dre_val = 'DRE';
        else
           this.cfc_pship_dre_val = "";

      if(this.con_fin_stmt_flg)
      {
        var tempObj = {
          COMBINATION_KEY : this.rowData.COMBINATION_KEY,
          CON_FIN_STMT_FLG : (this.con_fin_stmt_flg) ? 'Y' : 'N',
          CON_RTN_FLG : (this.con_rtn_flg ) ? 'Y' : 'N',
          OTH_ADJ_FLG :  (this.oth_adj_flg ) ? 'Y' : 'N',
          CFC_PSHIP_DRE_VAL : this.cfc_pship_dre_val,
          AMOUNT : this.con_fin_stmt_amt,
          COMMENTS : this.con_fin_stmt_comment,
          TRANS_HEADER_KEY : (this.rowData.CON_FIN_THK == undefined) ? 0 : this.rowData.CON_FIN_THK,
          TRANS_DETAILS_KEY : (this.rowData.CON_FIN_TDK == undefined) ? 0 : this.rowData.CON_FIN_TDK
       }
       this.clob_data.push(tempObj);
      }
      if(this.con_rtn_flg)
      {
        var tempObjconRtn = {
          COMBINATION_KEY : this.rowData.COMBINATION_KEY,
          CON_FIN_STMT_FLG : (this.con_fin_stmt_flg ) ? 'Y' : 'N',
          CON_RTN_FLG : (this.con_rtn_flg ) ? 'Y' : 'N',
          OTH_ADJ_FLG :  (this.oth_adj_flg ) ? 'Y' : 'N',
          CFC_PSHIP_DRE_VAL : this.cfc_pship_dre_val,
          AMOUNT : this.con_rtn_amt,
          COMMENTS : this.con_rtn_comment,
          TRANS_HEADER_KEY : this.rowData.CON_RTN_THK == undefined ? 0 : this.rowData.CON_RTN_THK,
          TRANS_DETAILS_KEY : this.rowData.CON_RTN_TDK == undefined ? 0 : this.rowData.CON_RTN_TDK 
       }
       this.clob_data.push(tempObjconRtn);
      }
      if(this.oth_adj_flg)
      {
        var tempObjOthAdj = {
          COMBINATION_KEY : this.rowData.COMBINATION_KEY,
          CON_FIN_STMT_FLG : (this.con_fin_stmt_flg) ? 'Y' : 'N',
          CON_RTN_FLG : (this.con_rtn_flg) ? 'Y' : 'N',
          OTH_ADJ_FLG :  (this.oth_adj_flg) ? 'Y' : 'N',
          CFC_PSHIP_DRE_VAL : this.cfc_pship_dre_val,
          AMOUNT : this.oth_adj_amt,
          COMMENTS : this.oth_adj_comment,
          TRANS_HEADER_KEY : this.rowData.OTH_ADJ_THK == undefined ? 0 : this.rowData.OTH_ADJ_THK,
          TRANS_DETAILS_KEY : this.rowData.OTH_ADJ_TDK == undefined ? 0 : this.rowData.OTH_ADJ_TDK
       }
       this.clob_data.push(tempObjOthAdj);
      }
      this.clob_settings.tax_year = this.filterParams.tax_year;
      this.clob_settings.scenario = this.filterParams.scenario;
      this.clob_settings.jcd_key = this.baseGlobalParams.jcd_key;
      console.log("clob_data 1------", this.clob_data);
      console.log("this.rowData.COMBINATION_KEY 1------", this.rowData.COMBINATION_KEY);
        this.FgnF5471H1ComponentService.saveData(this.baseURLs.api, this.clob_data, this.clob_settings, this.baseGlobalParams, this.checkLockedObj, this.filterParams, this.ssoId).subscribe(
          (data) => 
          {
            if (data.callSuccess == "1") {
              this.FgnF5471H1ComponentService.openSnackBar('Data Saved Successfully','gtw-snackbar--green');
              this.savefirf5471h1Info.emit('save-firf5471h1save-info');
            } else {
              this.FgnF5471H1ComponentService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
            }
          }, () => {
            console.log("Error while saving");
        });
      }
  }

  cancel() {
    console.log("click close");
    this.closefirf5471h1confirmInfo.emit('close-firf5471h1confirm-info');
    }

}
