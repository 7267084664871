import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {StatementTrackerService} from '../../../../../statement-tracker.service';
import {CreateDisclosureSharedService, DropDownType} from '../../../create-disclosure-shared.service';
import {DSTEntityDetails} from '../../template-leid-selector/template-leid-selector.component';

@Component({
  selector: 'gtw-view-template-form8883-id215',
  templateUrl: './view-template-form8883-id215.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form8883-id215.component.scss']
})
export class ViewTemplateForm8883Id215Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();


  form215!: FormGroup;
  stmtData!: StmtData;
  attMap: any;
  loaded: boolean = false;
  startLoader: boolean = false;
  pshipEinMissReason: string = '';
  disableActions: boolean = false;
  partII: boolean = false;
  OtherPartyUSEnt!: boolean;
  TargetCorpUSEnt!: boolean;
  Choice8g!: boolean;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  stateValue: string = '';
  countryValue: string = '';
  StateOptions: any[] = [{
    data: []
  }];
  CountryOptions: any[] = [{
    data: []
  }];
  PctDuring12Month: string = '';
  PctOnAcquisition: string = '';

  constructor(private formBuilder: FormBuilder,
              private sharedService: CreateDisclosureSharedService,
              private statementTrackerService: StatementTrackerService) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form215 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });
    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
      });
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
    });

    //load Stmt data
    this.sharedService.getLoadStatementData(215)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};
        //console.log('Data---' + this.stmtData);
        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }


        });
        //console.log(this.attMap);
        //console.log('After loop Attr data' + JSON.stringify(this.attMap));

        if (this.attMap['ExchangeMadeWithRelatedParty'] !== undefined && this.attMap['ExchangeMadeWithRelatedParty'][0] === '1') {
          this.partII = true;
        }
        if (this.attMap['OtherPartyUSEnt'] && this.attMap['OtherPartyUSEnt'][0]) {
          this.OtherPartyUSEnt = this.attMap['OtherPartyUSEnt'][0] === 'Yes';
        }
        if (this.attMap['TargetCorpUSEnt'] && this.attMap['TargetCorpUSEnt'][0]) {
          this.TargetCorpUSEnt = this.attMap['TargetCorpUSEnt'][0] === 'Yes';
        }
        if (this.attMap['Choice8g'] && this.attMap['Choice8g'][0]) {
          this.Choice8g = this.attMap['Choice8g'][0] === 'Yes';
        }
        if (this.attMap['PctDuring12Month'] && this.attMap['PctDuring12Month'][0]) {
          this.PctDuring12Month = (this.attMap['PctDuring12Month'][0] !== '' ? (parseFloat(this.attMap['PctDuring12Month'][0]) * 100) + '' : '');
        }
        if (this.attMap['PctOnAcquisition'] && this.attMap['PctOnAcquisition'][0]) {
          this.PctOnAcquisition = (this.attMap['PctOnAcquisition'][0] !== '' ? (parseFloat(this.attMap['PctOnAcquisition'][0]) * 100) + '' : '');
        }

        this.StateOptions[0].data.forEach((val: any) => {

          if ((this.attMap['StateNameOfOtherParty'] !== undefined) && (val.value == this.attMap['StateNameOfOtherParty'][0])) {
            this.stateValue = val.text;
          }

        });
        this.CountryOptions[0].data.forEach((val: any) => {

          if ((this.attMap['CountryOfOP'] !== undefined) && (val.value == this.attMap['CountryOfOP'][0])) {
            this.countryValue = val.text;
          }

        });

      }, () => {

      }, () => {

      }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });

  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
