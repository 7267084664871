import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datafilter',
  pure:false
})
export class DataFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {


      if (!filter){
        return items;
      }
  
      if (!Array.isArray(items)){
        return items;
      }
  
      if (filter && Array.isArray(items)) {
        // console.log('fililter values****',filter)

        let filterKeys = Object.keys(filter);

          return items.filter(item => {
            return filterKeys.some((keyName) => {
              if(filter[keyName]!=undefined){
                /* g = global, match all instances of the pattern in a string, not just one
                   i = case-insensitive (so, for example, /a/i will match the string "a" or "A". 
                   test: search */

                   return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
                   
              }else{
                return true;
              }
            });
          });
        //}
      }
    }
   
}
