import { Component, Input, OnInit } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';
import { ExternalLinkService } from './external-link.service';

@Component({
  selector: 'gtw-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {

  baseURLs: GtwElementsBaseUrlsModel={
		api : '/gtw',
		custom: '/custom',
		admin: '/admin',
		efile:'/gtw-efile-api',
    pdfEngine: '/pdf-engine',
    bulkPdfEngine:'/bulk-pdf-engine'
	};

  @Input('base-urls')
	set _base_urls(baseUrls: any){
		try{
			let urls:GtwElementsBaseUrlsModel = JSON.parse(JSON.stringify(baseUrls));
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
        this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

  isShowing =false;
  externalAppList:Array<{'app_name':string, 'app_url':string}>= [];


  constructor(private externalLinkService:ExternalLinkService) { }

  ngOnInit(): void {
    // this.getExternalApplications();
  }

  init(){
    this.getExternalApplications();
  }

  getExternalApplications(){
    console.log(this.baseURLs);
    this.externalLinkService.getExternalApplications(this.baseURLs.api).subscribe(
      res=>{
        this.externalAppList = res;
      }
    )
  }

  openApp(url:string){
    window.open(url, '_blank');
    this.isShowing=false;
  }

}
