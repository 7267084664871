<!--NOTE: f1118-schd-c component is common for CDE schedules, making changes here will affect all the CDE schedules. 
Incase adhoc feature is required for E or any change of requirement in C/D, consider spinning a new component for the schd-->
<mat-progress-bar *ngIf = "isModalLoading"
color="primary"
mode="indeterminate">
</mat-progress-bar>
<ap-alert [(show)]="isValidationError" [alertType]="'error'" [iconName]="'icon-alert-fill'">
    <div>
        <ul>
            <ng-container *ngFor="let item of errorMessages">
                <li>{{item}}</li>
            </ng-container>
        </ul>
    </div>
</ap-alert>

<div class="modal-body no-body-scroll" *ngIf = "!isModalLoading">
    <div class="divShadow1">  
            <div style="border:none;">
                <table class="table table-striped blue" style="text-align: left; width: 100%;" >
                    <thead>
                        <tr>
                            <th colspan="11" style="text-align: center;">{{panelHeader}}</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr>
                                <td colspan = 1>Basket:</td>
                                <td colspan = 1>
                                    <ap-selector class="m-l-10 issueIdDropdown" [items]="basketList" [type]="'Dropdown'" [disabled]="!isCustomOverrideWindow"
                                        [placeholder]="'Select Basket'" (onSelectedChange)="setBasketId($event);" required></ap-selector>
                                </td>
                                <td colspan=8></td>
                            </tr>
                            <tr  *ngFor = "let trItem of [].constructor((noOfFields/fieldsPerRow)+1); let trIndex = index;" style=" border: 1px solid #ddd;padding: 4px; ">
                            <!-- <tr  *ngFor="let item of lineColDtls.jsonObject; let i = index;" style=" border: 1px solid #ddd;padding: 4px; background-color: white;"> -->                                    
                                    <ng-container *ngFor="let item of lineColDtls | slice:(trIndex * fieldsPerRow) : (trIndex * fieldsPerRow)+fieldsPerRow; let i = index;">
                                        <td>{{item.COL_LINE_DESC}}</td>
                                        <td>
                                            <!-- <label>{{item.COL_LINE_DESC}}</label>&nbsp;&nbsp; -->
                                            <div *ngIf="item.IS_AMT == '1'">
                                                <input type = "number" [(ngModel)]="item.ELEMENT_VALUE" [disabled]="item.IS_READ_ONLY || isViewOnly" 
                                                style="text-align: right !important;" (change) = "getTestData();">
                                            </div>
                                            <div *ngIf="item.IS_AMT == '0'">
                                                <input type = "text" [(ngModel)]="item.ELEMENT_VALUE" [disabled]="item.IS_READ_ONLY || isViewOnly" 
                                                    style="text-align: right !important;" (change) = "getTestData();">
                                            </div>
                                        </td>
                                    </ng-container>
                            </tr>
                    </tbody>
                </table> 
            </div>	
   </div>
    <div>
        <div *ngIf = "isCustomOverrideWindow && isEdit" style="float: left;">
            <ap-button [btnText]="'Delete'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="deleteData(0)"></ap-button>
        </div>
        <div style="float: right;">
            <ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [isDisabled]="isViewOnly" [config]="{isPrimary: true}" (onBtnClick)="saveData()"></ap-button>
            <ap-button [btnText]="'Reset'"  *ngIf = "!isCustomOverrideWindow" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="resetAll()"></ap-button>
            <ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="modalClose()"></ap-button>
        </div>  
    </div>
    <div style="display:block;height: 26px;"></div>

    <!-- <mat-progress-bar *ngIf = "!tableLoadingData"
    color="primary"
    mode="indeterminate">
    </mat-progress-bar> -->
    <ap-loading *ngIf="isTableLoading" [colorName]="'a-dark'" loadingText="Loading data...">
    </ap-loading>
    <div *ngIf="!isTableLoading">
        <div class="modal-body" *ngIf="!isCustomOverrideWindow" style="overflow: scroll;width:100%;">
            <div class="divShadow1">  
                    <div class="table table-hover table-bordered table-striped table-condensed" style="border:none;">
                        <ap-table #basicTable [data]="processedTableData" [showPage]="false" [pageSize]="processedTableData.length" [striped]="true">
                            <thead style="white-space:nowrap;">
                                <tr>
                                    <th style="width: 1%;" >View/Edit</th>
                                    <th style="width: 1%;" >Delete</th>
                                    <th *ngFor="let col of lineColDtls; let i = index;"  rowspan="2" style="width: 6%;" >{{col.COL_DESC}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr *ngFor="let lineWithSno of basicTable.data; let i = index;" style=" border: 1px solid #ddd;padding: 4px; background-color: white;">
                                            <td >
                                                <!-- <ap-button class = "actioniconssuppl" [config]="{isPrimary: true}" [btnSize]="'small'" [btnType]="'a-btn-white'"  [isDisabled]="false" (onBtnClick)="viewICalcData(lineWithSno)">
                                                    <span class="appkiticon icon-view-outline" style="color: black;"></span>
                                                </ap-button> -->
                                                <ap-button class = "actioniconssuppl" [config]="{isPrimary: true}" [btnSize]="'small'" [btnType]="'a-btn-white'"  [isDisabled]="false" (onBtnClick)="editICalcDelta(lineWithSno)">
                                                    <span class="appkiticon icon-edit-fill"></span>
                                                </ap-button>
                                            </td>
                                            <td>
                                                <ap-button class = "actioniconssuppl" [config]="{isPrimary: true}" [btnSize]="'small'" [btnType]="'a-btn-white'" (onBtnClick)="deleteData(lineWithSno.SNO)">
                                                    <span class="appkiticon icon-circle-minus-fill"></span>
                                                </ap-button>
                                            </td>
                                            <td  *ngFor="let item of lineWithSno.items; let j = index;" class="amountALign" style="white-space:nowrap;">{{item.ELEMENT_VALUE}}</td>
                                    </tr>
                            </tbody>
                        </ap-table> 
                    </div>	
        </div>
        </div>
    </div>

</div>
