import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {CreateDisclosureService} from '../create-disclosure/create-new/create-disclosure.service';
import {HttpParams} from '@angular/common/http';
import {ActionResponse} from '../../../shared/_models/action-response.model';
import {ActionService} from '../../../shared/_services/action.service';
import {DSTSharedService} from '../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class CopyReplicateService {

  constructor(private dstSharedService: DSTSharedService,
              private cDService: CreateDisclosureService,
              private actionService: ActionService) {
  }

  getFilterValues(): Observable<any[]> {
    return zip(this.cDService.getTaxYears(), this.cDService.getTaxReturnPeriods()).pipe(
      take(1),
      catchError((e: any, ob: Observable<any[]>) => {
        throw Error('Something went wrong while retrieving the filter values, please close the modal and try again!');
      })
    );
  }

  getNewStatementId(statementId: string, taxYearId: number, periodId: number): Observable<string> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id': statementId,
        'tax_year_id': taxYearId.toString(),
        'return_period_id': periodId.toString()
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32311, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject[0].STATEMENTID;
          } else {
            throw Error('Something went wrong while retrieving the new statement id, please try again!');
          }
        }),
      );
  }
}
