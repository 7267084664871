import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Table} from 'primeng-lts/table';
import {CreateDisclosureService, DSTTaxYear} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import {Alert} from '../../statement-tracker/statement-tracker.service';
import {DiagnosticsService} from '../diagnostics.service';
import {Subscription, zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {StmtBtnActionPayload} from '../../shared/components/stmt-id-btn/stmt-id-btn.component';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {CopyReplicateComponent} from '../../statement-tracker/copy-replicate/copy-replicate.component';
import {ModalComponent} from 'appkit-angular/modal';
import {DSTSharedService} from '../../shared/dst-shared.service';
import {DSTChildFormState} from '../../shared/models/dst-child-form-state.model';
import {StmtIdBtnService} from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import {DiscModalViewerComponent} from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';
import {JSONExportHeader, JSONExportService} from '../../../shared/_services/JSONExport.service';

@Component({
  selector: 'gtw-data-collect-report',
  templateUrl: './data-collect-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './data-collect-report.component.scss']
})
export class DataCollectReportComponent implements OnInit, OnChanges {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild('dt') table!: Table;
  @ViewChild('otherExplanationModal') otherExplanationModal!: ModalComponent;

  first: number = 0;
  data: any[] = [];
  cols: any[] = [
    {
      field: 'TAXYEAR',
      header: 'Year',
      hidden: false,
      width: '60px'
    },
    {
      field: 'STATEMENTID',
      header: 'Stmt ID',
      hidden: false,
      width: '100px'
    },
    {
      field: 'STATEMENTNAME',
      header: 'Statement',
      hidden: false,
      width: '290px'
    },
    {
      field: 'DATACOLLECT_ACTION_FLAG',
      header: 'User Action Required',
      hidden: false,
      width: '125px'
    },
    {
      field: 'DATACOLLECT_ACTION_EXPL',
      header: 'Action Required',
      hidden: false,
      width: '280px'
    },
    {
      field: 'REVIEWNAME',
      header: 'Reviewer Explanation',
      hidden: false,
      width: '240px'
    },
    {
      field: 'reviewerOtherFieldExplanation',
      header: 'Reviewer Other Field Explanation',
      hidden: true,
    },
    {
      field: 'ISREVIEWED',
      header: 'Mark Statement as Reviewed',
      hidden: false,
      width: '130px'
    },
    {
      field: 'MODIFIEDBY',
      header: 'Modified By',
      hidden: true,
    },
    {
      field: 'MODIFIEDONFORMATTED',
      header: 'Modified On',
      hidden: true,
    },
    {
      field: 'CREATEDBY',
      header: 'Created By',
      hidden: true,
    },
    {
      field: 'CREATEDONFORMATTED',
      header: 'Created On',
      hidden: true,
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;
  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;

  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;
  alert: Alert;
  fStatementId: string = '';
  fStatementName: string = '';
  fActionFlag: string = '';
  fActionRequired: string = '';

  revExplanations: any = [
    {
      data: [
        {text: 'Section Option', value: '', reviewTypeId: 0},
      ]
    }
  ];
  otherExplanationText: string = '';
  otherExplActiveRow: any;
  showOtherExplanationModal: boolean = false;

  constructor(private cDSerivce: CreateDisclosureService,
              public dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private trackerTabsService: TrackerTabsService,
              private diagnosticsService: DiagnosticsService,
              private jsonExportService: JSONExportService,
              private dCRService: DSTDynamicComponentResolverService) {

    this.excelExportFileName = 'Data_Collect_Diagnostics_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // // this.dstSharedService.setSSO('400394467');
    // this.dstSharedService.setTaxYearScenario('2021', '40');
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);

    this.alert = dstSharedService.alert;
  }

  ngOnInit(): void {
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data: any) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2021');
    //   this.loadDiagReviewOptions();
    //   this.loadReport();
    // });
    // this.dstSharedService.setDSTUser('400394467', 5);

    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data: any) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          this.loadDiagReviewOptions();
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.loadReport();
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  loadDiagReviewOptions(): void {
    this.diagnosticsService.getDiagnosticsReviewOptions('dataCollect').subscribe((data) => {
      data.forEach((item: any) => {
        this.revExplanations[0].data.push({
          text: item.REVIEW_NAME,
          value: item.REVIEW_NAME,
          reviewTypeId: item.REVIEW_TYPE_ID,
        });
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while loading Review options, please try again!');
    }).add(() => {
    });
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.diagnosticsService.loadDataCollectReport(this.taxYear?.text.toString()!).subscribe((data) => {
      this.data = data;
      this.data.forEach((element, index) => { //creating RevExpl array for each row
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;

        element.id = index + 1;
        element['revExplanations_' + element.id] = [{data: []}];
        this.revExplanations[0].data.forEach((item: any) => {
          element['revExplanations_' + element.id][0].data.push({
            text: item.text,
            value: item.value,
            reviewTypeId: item.reviewTypeId,
            selected: element.REVIEWNAME === item.value ? true : false,
          });
        });
      });
      this.table.clear();
      this.resetColFilters();
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the Data Collect grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  filterCol(event: Event, field: string, matchMode: string): void {
    this.table.filter((event.target as HTMLInputElement).value, field, matchMode);
  }

  private resetColFilters(): void {
    this.fStatementId = '';
    this.fStatementName = '';
    this.fActionFlag = '';
    this.fActionRequired = '';
  }

  otherExplanation(rowData: any): void {
    this.showOtherExplanationModal = true;
    this.otherExplActiveRow = rowData;
    this.otherExplanationText = rowData.REVIEWEXPL || '';
    setTimeout(() => {
      this.otherExplanationModal.showMore('rev-other-exp');
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="rev-other-exp"]');
      modalEle?.classList.add('gtw-web-components');
      modalEle?.classList.add('dst-modal-sm');
    });
  }

  onOtherExplanationSave(id: string): void {
    this.otherExplActiveRow.REVIEWEXPL = this.otherExplanationText || '';

    //Save other explanation text
    this.updateOtherExplReviewComment(this.otherExplActiveRow.STATEMENTID, 'dataCollect', this.otherExplActiveRow.REVIEWTYPEID
      || '13', this.otherExplanationText || '');

    this.otherExplanationModal.closeModalOk(id);
    setTimeout(() => {
      this.showOtherExplanationModal = false;
    });
  }

  onOtherExplanationCancel(id: string): void {
    this.otherExplanationModal.closeModalOk(id);
    setTimeout(() => {
      this.showOtherExplanationModal = false;
    });
  }

  statementReviewedChange(event: any, rowData: any): void {
    //Save- mark statement as reviewed change
    rowData.markStatementAsReviewed = event.srcElement.checked ? 1 : 0;
    this.stmtIdBtnService.setReviewed(rowData.statementId, rowData.markStatementAsReviewed)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Statement saved successfully for reviewed status.');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while reviewing status, please try again later!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      });
  }

  reviewerExplanationChange(event: any, rowData: any): void {
    rowData.REVIEWNAME = event.selected[0].value;

    if (rowData.REVIEWNAME === 'Other: Explain') { //open other expl pop-up when others is selected
      this.otherExplanation(rowData);
    } else {
      rowData.REVIEWEXPL = '';
    }

    //Save reviewer Explanation change
    this.updateOtherExplReviewComment(rowData.STATEMENTID, 'dataCollect', event.selected[0].reviewTypeId, rowData.REVIEWEXPL || '');
  }

  updateOtherExplReviewComment(statementId: string, reviewType: string, reviewTypeId: string, otherExpl: string): void {
    this.diagnosticsService.dstUpdateReviewComment(statementId, reviewType, reviewTypeId, otherExpl)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Review Type Saved Successfully.');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving review type, please try again later!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      });
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'TAXYEAR',
        header: 'Year',
      },
      {
        field: 'STATEMENTID',
        header: 'Stmt ID',
      },
      {
        field: 'STATEMENTNAME',
        header: 'Statement',
      },
      {
        field: 'DATACOLLECT_ACTION_FLAG',
        header: 'User Action Required',
      },
      {
        field: 'DATACOLLECT_ACTION_EXPL',
        header: 'Action Required',
      },
      {
        field: 'REVIEWNAME',
        header: 'Reviewer Explanation',
      },
      {
        field: 'reviewerOtherFieldExplanation',
        header: 'Reviewer Other Field Explanation',
      },
      {
        field: 'ISREVIEWED',
        header: 'Mark Statement as Reviewed',
      },
      {
        field: 'MODIFIEDBY',
        header: 'Modified By',
      },
      {
        field: 'MODIFIEDONFORMATTED',
        header: 'Modified On',
      },
      {
        field: 'CREATEDBY',
        header: 'Created By',
      },
      {
        field: 'CREATEDONFORMATTED',
        header: 'Created On',
      }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
