import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CreateDisclosureTemplateHostDirective} from './create-disclosure-template-host.directive';
import {DSTDynamicComponentResolverService} from '../dst-dynamic-component-resolver.service';
import {CreateDisclosureSharedService} from '../create-disclosure-shared.service';
import {TrackerTabsService} from '../../../tracker-tabs.service';
import {DSTStatement} from '../../../../shared/models/dst-statement.model';
import {DSTFormState} from '../../../../shared/models/dst-form-state.model';

@Component({
  selector: 'dst-create-disclosure-template-viewport',
  templateUrl: './create-disclosure-template-viewport.component.html',
  styleUrls: ['./create-disclosure-template-viewport.component.scss']
})
export class CreateDisclosureTemplateViewportComponent implements OnInit {

  @Input() tabIndex!: number;
  @Input() rowObject!: DSTStatement;
  @ViewChild(CreateDisclosureTemplateHostDirective, {static: true}) templateHost!: CreateDisclosureTemplateHostDirective;

  private static stDCRService: DSTDynamicComponentResolverService;

  constructor(private dCRService: DSTDynamicComponentResolverService,
              private trackerTabsService: TrackerTabsService,
              private sharedService: CreateDisclosureSharedService) {
    CreateDisclosureTemplateViewportComponent.stDCRService = this.dCRService;
  }

  ngOnInit() {
    let formState: DSTFormState;
    // if (this.trackerTabsService.getActiveTrackerTab()?.id === StatementTrackerService.ID_MODAL) {
    //   // initialize form template for modal tab -- copy/replicate
    //   this.sharedService.setTemplateHost(this.templateHost, 0);
    //   formState = this.sharedService.getActiveFormState();
    // } else {
      // initialize form template for current form tab -- create new/edit/view
      this.sharedService.setTemplateHost(this.templateHost, this.tabIndex);
      formState = this.sharedService.getFormStateArray()[this.tabIndex || 0];
    // }
    if (formState?.formId) {
      this.loadComponent(
        this.templateHost.viewContainerRef,
        formState.formTypeId,
        formState.formId,
        formState.uploadType,
        formState.taxYear,
        formState.isView);
    }
  }

  ngOnDestroy() {
  }

  switchTemplateMode(isView?: boolean): void {
    const activeHost: CreateDisclosureTemplateHostDirective | undefined = this.trackerTabsService.getActiveTrackerTab()?.getActiveTemplateHost();
    const activeFormState: DSTFormState | undefined = this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState();
    if (!activeHost) {
      console.error('Active template host is missing, unable to proceed!');
      return;
    }
    if (!activeFormState) {
      console.error('No shared form state, unable to proceed!');
      return;
    }
    this.loadComponent(activeHost.viewContainerRef,
      activeFormState.formTypeId,
      activeFormState.formId,
      activeFormState.uploadType,
      activeFormState.taxYear,
      isView);
  }

  static getComponent(typeId: number, formId: number, uploadType: string, taxYear?: number, isView?: boolean): () => Promise<any> {
    if (typeId === 1) {
      // load Template Form
      return CreateDisclosureTemplateViewportComponent.getTemplateComponent(formId, taxYear, isView);
    } else if (typeId >= 2 && typeId <= 4) {
      // load PDF or XML Text Box or Data Collect
      if (!uploadType) {
        // load Attached Template
        return CreateDisclosureTemplateViewportComponent.getAttachedTemplateComponent(isView);
      }
      if (uploadType === 'Bulk Upload') {
        // load Bulk Upload Template
        return CreateDisclosureTemplateViewportComponent.getBulkUploadTemplateComponent();
      } else if (uploadType === 'Bulk Supersede') {
        // load Bulk Supersede Template
        return CreateDisclosureTemplateViewportComponent.getBulkSupersedeTemplateComponent();
      }
    }

    throw Error('No matching template found!');
  }

  static getTemplateComponent(formId: number, taxYear?: number, isView?: boolean): () => Promise<Function> {
    switch (formId) {
      // "Section 351 - Statement Pursuant to Section 1.351-3"
      case 184: {
        if (isView) {
          return () => import('../templates/template-sec351-id184/view-template-sec351-id184/view-template-sec351-id184.component').then(
            (m) => {
              return m.ViewTemplateSec351Id184Component;
            }
          );
        } else {
          return () => import('../templates/template-sec351-id184/template-sec351-id184.component').then(
            (m) => {
              return m.TemplateSec351Id184Component;
            }
          );
        }
      }
      // "Statement 368 - 368(a)(1)(F) Reorganization"
      case 186: {
        if (isView) {
          return () => import('../templates/template-sec368-id186/view-template-sec368-id186/view-template-sec368-id186.component').then(
            (m) => {
              return m.ViewTemplateSec368Id186Component;
            }
          );
        } else {
          return () => import('../templates/template-sec368-id186/template-sec368-id186.component').then(
            (m) => {
              return m.TemplateSec368Id186Component;
            }
          );
        }
      }
      // "Section 368 - Statement Pursuant to Section 1.368"
      case 188: {
        if (isView) {
          return () => import('../templates/template-sec368-id188/view-template-sec368-id188/view-template-sec368-id188.component').then(
            (m) => {
              return m.ViewTemplateSec368Id188Component;
            }
          );
        } else {
          return () => import('../templates/template-sec368-id188/template-sec368-id188.component').then(
            (m) => {
              return m.TemplateSec368Id188Component;
            }
          );
        }
      }
      // "Section 332 - Statement Pursuant to Section 332 / 332 Standard"
      case 189: {
        if (isView) {
          return () => import('../templates/template-sec332-id189/view-template-sec332-id189/view-template-sec332-id189.component').then(
            (m) => {
              return m.ViewTemplateSec332Id189Component;
            }
          );
        } else {
          return () => import('../templates/template-sec332-id189/template-sec332-id189.component').then(
            (m) => {
              return m.TemplateSec332Id189Component;
            }
          );
        }
      }
      // "Section 332 - Statement Pursuant to Section 332 / 332 Check-The-Box"
      case 190: {
        if (isView) {
          return () => import('../templates/template-sec332-id190/view-template-sec332-id190/view-template-sec332-id190.component').then(
            (m) => {
              return m.ViewTemplateSec332Id190Component;
            }
          );
        } else {
          return () => import('../templates/template-sec332-id190/template-sec332-id190.component').then(
            (m) => {
              return m.TemplateSec332Id190Component;
            }
          );
        }
      }
      // "Section 367(b) - Statement Required Pursuant to Section 1.367(b)-1(c)(4) / 351 for 304"
      case -192: {
        if (isView) {
          return () => import('../templates/template-sec367b-id-192/view-template-sec367b-id-192/view-template-sec367b-id-192.component').then(
            (m) => {
              return m.ViewTemplateSec367bIdNeg192Component;
            }
          );
        } else {
          return () => import('../templates/template-sec367b-id-192/template-sec367b-id-192.component').then(
            (m) => {
              return m.TemplateSec367bIdMinus192Component;
            }
          );
        }
      }
      // "Section 367(b) - Statement Required Pursuant to Section 1.367(b)-1(c)(4) / 351"
      case 192: {
        if (isView) {
          return () => import('../templates/template-sec367b-id192/view-template-sec367b-id192/view-template-sec367b-id192.component').then(
            (m) => {
              return m.ViewTemplateSec367bId192Component;
            }
          );
        } else {
          return () => import('../templates/template-sec367b-id192/template-sec367b-id192.component').then(
            (m) => {
              return m.TemplateSec367bId192Component;
            }
          );
        }
      }
      // "Section 367(b) - Statement Required Pursuant to Section 1.367(b)-1(c)(4) / 368 Other"
      case 193: {
        if (isView) {
          return () => import('../templates/template-sec367b-id193/view-template-sec367b-id193/view-template-sec367b-id193.component').then(
            (m) => {
              return m.ViewTemplateSec367bId193Component;
            }
          );
        } else {
          return () => import('../templates/template-sec367b-id193/template-sec367b-id193.component').then(
            (m) => {
              return m.TemplateSec367bId193Component;
            }
          );
        }
      }
      // "Section 367(b) - Statement Required Pursuant to Section 1.367(b)-1(c)(4) / 332 / 332 Standard"
      case 194: {
        if (isView) {
          return () => import('../templates/template-sec367b-id194/view-template-sec367b-id194/view-template-sec367b-id194.component').then(
            (m) => {
              return m.ViewTemplateSec367bId194Component;
            }
          );
        } else {
          return () => import('../templates/template-sec367b-id194/template-sec367b-id194.component').then(
            (m) => {
              return m.TemplateSec367bId194Component;
            }
          );
        }
      }
      // "Section 367(b) - Statement Required Pursuant to Section 1.367(b)-1(c)(4) / 332 / 332 Check-The-Box"
      case 195: {
        if (isView) {
          return () => import('../templates/template-sec367b-id195/view-template-sec367b-id195/view-template-sec367b-id195.component').then(
            (m) => {
              return m.ViewTemplateSec367bId195Component;
            }
          );
        } else {
          return () => import('../templates/template-sec367b-id195/template-sec367b-id195.component').then(
            (m) => {
              return m.TemplateSec367bId195Component;
            }
          );
        }
      }
      // "Form 926 - Return by a U.S. Transferor of Property to a Foreign Corporation"
      case 214: {
        if (isView) {
          return () => import('../templates/template-sec926-id214/view-template-form926-id214/view-template-form926-id214.component').then(
            (m) => {
              return m.ViewTemplateForm926Id214Component;
            }
          );
        } else {
          return () => import('../templates/template-sec926-id214/template-sec926-id214.component').then(
            (m) => {
              return m.TemplateSec926Id214Component;
            }
          );
        }
      }
      // "Form 8883 - Asset Allocation Statement Under Section 338"
      case 215: {
        if (isView) {
          return () => import('../templates/template-sec8883-id215/view-template-form8883-id215/view-template-form8883-id215.component').then(
            (m) => {
              return m.ViewTemplateForm8883Id215Component;
            }
          );
        } else {
          return () => import('../templates/template-sec8883-id215/template-sec8883-id215.component').then(
            (m) => {
              return m.TemplateSec8883Id215Component;
            }
          );
        }
      }
      // "Form 8594 - Asset Allocation Statement Under Section 1060"
      case 217: {
        if (isView) {
          return () => import('../templates/template-sec8594-id217/view-template-form8594-id217/view-template-form8594-id217.component').then(
            (m) => {
              return m.ViewTemplateSec8594Id217Component;
            }
          );
        } else {
          return () => import('../templates/template-sec8594-id217/template-sec8594-id217.component').then(
            (m) => {
              return m.TemplateSec8594Id217Component;
            }
          );
        }
      }
      // "Form 8621 - Return by a Shareholder of a Passive Foreign Investment Company or Qualified Electing Fund"
      case 236: {
        if (isView) {
          return () => import('../templates/template-form8621-id236/view-template-form8621-id236/view-template-form8621-id236.component').then(
            (m) => {
              return m.ViewTemplateForm8621Id236Component;
            }
          );
        } else {
          return () => import('../templates/template-form8621-id236/template-form8621-id236.component').then(
            (m) => {
              return m.TemplateForm8621Id236Component;
            }
          );
        }
      }
      // "Form 8824 - Like-Kind Exchanges"
      case 237: {
        if (isView) {
          return () => import('../templates/template-sec8824-id237/view-template-form8824-id237/view-template-form8824-id237.component').then(
            (m) => {
              return m.ViewTemplateForm8824Id237Component;
            }
          );
        } else {
          return () => import('../templates/template-sec8824-id237/template-sec8824-id237.component').then(
            (m) => {
              return m.TemplateSec8824Id237Component;
            }
          );
        }
      }
      // "Form 8886 - Reportable Transaction Disclosure Statement"
      case 235: {
        if (isView) {
          return () => import('../templates/template-sec8886-id235/view-template-form8886-id235/view-template-form8886-id235.component').then(
            (m) => {
              return m.ViewTemplateForm8886Id235Component;
            }
          );
        } else {
          return () => import('../templates/template-sec8886-id235/template-sec8886-id235.component').then(
            (m) => {
              return m.TemplateSec8886Id235Component;
            }
          );
        }
      }
      // other form templates goes here
      // case 0: {
      //   if (taxYear === 2017) {
      //     // 2017 tax year template
      //   }
      // }
      default:
        throw Error('No matching template found!');
    }
  }

  private static getAttachedTemplateComponent(isView?: boolean): () => Promise<Function> {
    if (isView) {
      return () => import('../templates/template-attached/view-template-attached/view-template-attached.component').then(
        (m) => {
          return m.ViewTemplateAttachedComponent;
        }
      );
    } else {
      return () => import('../templates/template-attached/template-attached.component').then(
        (m) => {
          return m.TemplateAttachedComponent;
        }
      );
    }
  }

  private static getBulkUploadTemplateComponent(): () => Promise<Function> {
    return () => import('../templates/template-attached/template-bulk-upload/template-bulk-upload.component').then(
      (m) => {
        return m.TemplateBulkUploadComponent;
      }
    );
  }

  private static getBulkSupersedeTemplateComponent(): () => Promise<Function> {
    return () => import('../templates/template-attached/template-bulk-supersede/template-bulk-supersede.component').then(
      (m) => {
        return m.TemplateBulkSupersedeComponent;
      }
    );
  }

  loadComponent(vcr: ViewContainerRef, typeId: number, formId: number, uploadType: string, taxYear?: number, isView?: boolean): void {
    vcr.clear();
    this.dCRService.resolve(vcr, {
      loadChildren: CreateDisclosureTemplateViewportComponent.getComponent(typeId, formId, uploadType, taxYear, isView)
    });
  }

}
