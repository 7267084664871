<div class="main-container">
  <div class="grid-container" id="nareport-grid-container" style="height:calc(100% - 55px);">
      <div class="top-toolbar">
          <div class="float-left ml-2">
            <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear" 
              (onChange)="onTaxYearChange()">
            </p-dropdown>
  
            <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
          </div>
      </div>
  
      <p-table #dt [columns]="cols" [value]="data" [rows]="rows" dataKey="ID" [exportFilename]="excelExportFileName"
        [loading]="loading" [scrollable]="true" scrollHeight="calc(100vh - 225px)" responsiveLayout="scroll"
        styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="34"
        [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">
  
          <ng-template pTemplate="header" let-columns>
              <tr [style.height]="'45px'">
                <ng-container *ngFor="let col of columns; index as i">
                  <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
          </ng-template>
  
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="height: 70px;">
                  <td style="width: 80px;">
                    <span>{{ rowData.TAXYEAR }}</span>
                  </td>
                  <td style="width: 100px;">
                    <span>{{ rowData.LEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 290px;">
                    <span>{{ rowData.FORMNAME }}</span>
                  </td>
                  <td style="width: 180px;">
                    <span>{{ rowData.CODESECTIONNAME }}</span>
                  </td>
                  <td style="width: 220px;">
                    <span>{{ rowData.BUSINESSNAME }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 210px;">
                    <span>{{ rowData.ENTITYNAME }}</span>
                  </td>
                  <td style="width: 180px;">
                    <span>{{ rowData.IGNOREREASON }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 180px;">
                    <span>{{ rowData.IGNOREREASONDETAILS }}</span>
                  </td>
              </tr>
          </ng-template>
  
      </p-table>
  </div>
</div>