import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { ScreenDocumentList } from './screen-document';
import { ScreenDocumentService } from './screen-document.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'gtw-screen-document-upload',
  templateUrl: './screen-document-upload.component.html',
  styleUrls: ['./screen-document-upload.component.scss']
})
export class ScreenDocumentUploadComponent implements OnInit, OnChanges, AfterViewInit {

  data: any;
  hdKey: number | undefined;
  durationInSeconds = 5;

  @Input('childTabIndex')
  childTabIndex: number =0;
  @Input('base-url')
  baseURL = '/admin';

  private _screenKey:number =0;
  private _clientKey:number =0;
  public docTitle: string = '';
  public editData: any;

  @Input() attachmentList: any = [];
  @Input() fromUpload: boolean  = false;
  
  screenDocumentAttachedList: Array<ScreenDocumentList> = [];
  @Input('screen-key')
  set screenKey(screenKey:number){
    this._screenKey = parseInt(screenKey+"");
    
  }

  get screenKey():number{
    return this._screenKey;
  }

  @Input('client-key')
  set clientKey(clientKey:number){
    this._clientKey = parseInt(clientKey+"");
  }

  @Input('updateDocSelected')
  set updateDocSelectedInput (Document:ScreenDocumentList){

    if(Document) {
      this.docTitle = Document.hd_msg_title;
      this.data = Document.hd_body;
      this.text1 = Document.hd_body;
      this.hdKey = Document.hd_key;
    }
  }


  get clientKey():number{
    return this._clientKey;
  }

  @Input('user')
  set user(user:string){
    try{
      let tmp_user = JSON.parse(user ) as User;
      this.userService.setUser(tmp_user);
    }catch(error){
      console.log("Not an valid object");
    }
    
  }
  currentFileUpload: any;
  @Output()
  refreshScreenDocumentList = new EventEmitter<boolean>();

  text1: string = `Lorem ipsum dolor sit amet, consectetur
  adipiscing elit.Non modo carum sibi quemque, verum etiam vehementer carum esse? Poterat
  autem inpune; Hanc ergo intuens debet institutum illud quasi signum absolvere.Cur post Tarentum ad
  Archytam? Isto modo ne improbos quidem, si essent boni viri.Duo Reges: constructio interrete.`;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.data = [...this.data];
    console.log('text tags',this.text1);

  }

  onChange(event: any){
    console.log('text tags',this.text1);

  }
  ngAfterViewInit(): void {
    console.log('text tags',this.text1);

  }
  private allowedRoles = ['admin','super admin','developer'];
  constructor(private userService:UserService, 
    private screenDocumentService: ScreenDocumentService,
    private _snackBar: MatSnackBar) { }
    
    loadDocUploadTab(event: any){
      console.log('I am listed',event);
    }
  

  saveDocument(event: any){
    console.log("I am saving",this.data);
    let hdKey =  this.hdKey!=0?this.hdKey : '';
    if(this.docTitle == '' || this.editData == ''){
      this.openSnackBar("Please add the title and doc content !!");
      return;
    }
    this.screenDocumentService.uploadDocument(this.baseURL,this.clientKey, this.screenKey, this.docTitle, 
      this.editData, hdKey).subscribe( 
      res => {
        console.log("this the response",res);
        
        if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
          this.docTitle = '';
          this.text1 = '';
          this.openSnackBar("Document Saved Successfully !!");

          this.refreshScreenDocumentList.emit(true);

          } else {
            this.openSnackBar(res.errorMessage);

        }
      },
      error => {
      });

  }
  deleteDocument(event: any, object: any){
    //this.attachmentList.splice(index, 0);
    console.log("I am deleted",event);
    console.log("I am deleted",object);


  }
  /* upload documents ..... */
  uploadDocument(event: any) {
  for (var i = 0; i < event.target.files.length; i++) { 
    this.attachmentList.push(event.target.files[i]);
  }
  console.log("I amcalled",this.attachmentList);
}
  ngOnDestroy(): void {
    console.log('to do')
  }
  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }
  clearContent(event:any){
     this.docTitle = '';
      this.data = '';
      this.text1 ='';
      this.hdKey = 0;
  }

  ngDoCheck(){
    this.editData = this.text1;
  }

}
