import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CreateDisclosureService, DSTTaxYear} from '../../../create-new/create-disclosure.service';
import {CreateDisclosureSharedService, DropDownType} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {Observable, zip} from 'rxjs';
import {take} from 'rxjs/operators';
import _ from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {TemplateAttachedService} from '../template-attached/template-attached.service';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';
import {DSTFormState, StmtAttrData, StmtData} from 'projects/gtw-elements/src/app/DST/shared/models/dst-form-state.model';

@Component({
  selector: 'gtw-template-sec8886-id235',
  templateUrl: './template-sec8886-id235.component.html',
  styleUrls: ['../template-shared.scss', './template-sec8886-id235.component.scss']
})
export class TemplateSec8886Id235Component implements OnInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  taxReturnYears: any[] = [{data: []}];
  taxYearsPT: any[] = [{data: []}];
  countries: any[] = [{data: []}];
  states: any[] = [{data: []}];
  involvedTrCountries: any[] = [{data: []}];
  involvedTrStates: any[] = [{data: []}];

  reasonsEIN: any[] = [
    {
      data: [
        { value: '', text: '** Please select the missing reason **' },
        {
          id: 0,
          value: 'APPLD FOR',
          text: 'APPLD FOR',
          selected: false
        },
        {
          id: 1,
          value: 'FOREIGNUS',
          text: 'FOREIGNUS',
          selected: false
        }
      ]
    }
  ];
  partFiveReasonsEIN: any[] = [
    {
      data: [
        { value: '', text: '** Please select the missing reason **' },
        {
          id: 1,
          value: 'APPLD FOR',
          text: 'APPLD FOR',
          selected: false
        },
        {
          id: 2,
          value: 'FOREIGNUS',
          text: 'FOREIGNUS',
          selected: false
        }
      ]
    }
  ];
  partSevenReasonsEIN: any[] = [
    {
      data: [
        { value: '', text: '** Please select the missing reason **' },
        {
          id: 1,
          value: 'APPLD FOR',
          text: 'APPLD FOR',
          selected: false
        },
        {
          id: 2,
          value: 'FOREIGNUS',
          text: 'FOREIGNUS',
          selected: false
        }
      ]
    }
  ];
  partSevenInvolvedReasonsEIN: any[] = [
    {
      data: [
        { value: '', text: '** Please select the missing reason **' },
        {
          id: 1,
          value: 'APPLD FOR',
          text: 'APPLD FOR',
          selected: false
        },
        {
          id: 2,
          value: 'FOREIGNUS',
          text: 'FOREIGNUS',
          selected: false
        }
      ]
    }
  ];

  dcnId!: number;
  loading: boolean = false;
  transferor!: DSTEntityDetails | null;
  // participatingLEID!: DSTEntityDetails | null;
  // involvedTrEntity!: DSTEntityDetails | null;
  statementId: string = '';

  formModel: any = {
    statementNumber: undefined,
    totalStatementNumber: undefined,
    formNumberOfTaxReturn: undefined,
    forTaxYear: undefined,
    attachedToAmendedReturn: undefined,
    initialYearFiler: undefined,
    protectiveDisclosure: undefined,
    reportableTransactionInfoNameOfReportableTransaction: undefined,
    reportableTransactionInfoInitialYearParticipated: undefined,
    reportableTransactionInfoTransactionOrTaxShelterNumber: '',
    listed: undefined,
    contractualProtection: undefined,
    transactionOfInterest: undefined,
    confidential: undefined,
    loss: undefined,
    publishedGuidanceNumber: undefined,
    numberOfSameOrSimilarTr: undefined,
    deductions: undefined,
    exclusionsFromGrossIncome: undefined,
    capitalLoss: undefined,
    nonrecognitionOfGain: undefined,
    ordinaryLoss: undefined,
    adjustmentsToBasis: undefined,
    line7aOther: undefined,
    line7aOtherText: undefined,
    taxCredits: undefined,
    deferral: undefined,
    absenceOfAdjustmentsToBasis: undefined,
    totalTaxBenefitAmt: undefined,
    taxBnftAnticipatedYrCnt: undefined,
    totalInvestmentOrBasisAmt: undefined,
  };

  beginDate!: Date;
  endDate!: Date;
  showDate: boolean = true;
  USForeignFeesRad: boolean = true;
  AttachedToAmendedReturn: boolean | undefined = undefined;
  invlovedEntityInGOLD: boolean | undefined = undefined;
  InvolvedUSForeignEntity: boolean | undefined = undefined;
  attMap: { [key: string]: string[] } = {};

  partFourTabs: any;
  partFourSelected = new FormControl(0);
  partFiveTabs: any;
  partFiveSelected = new FormControl(0);
  partSevenTabs: any;
  partSevenSelected = new FormControl(0);

  fileAcceptStr = 'application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12';
  fileAcceptShort = ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
  downloadUrl: any;
  attachmentname: any;
  attachmentpath: any;
  file!: File;

  constructor(private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private trackerTabsService: TrackerTabsService,
              private attachedService: TemplateAttachedService,
              private cdr: ChangeDetectorRef,
              private elr: ElementRef,
              private cDService: CreateDisclosureService,) {

    this.partFourTabs = [
      {
        tab: '1',
        selectedIndex: 0,
        ParticipatingLEID: '',
        typeOfEntityInformationPartnership: '',
        typeOfEntityInformationSCorporation: '',
        typeOfEntityInformationTrust: '',
        typeOfEntityInformationForeign: '',
        typeOfEntityInformationMissingEINReason: '',
        typeOfEntityInformationScheduleK1ReceivedDate: '',
        typeOfEntityInformationScheduleK1RcvDate: '',
        typeOfEntityInformationNoScheduleK1Received: 'None',
      }
    ];

    this.partFiveTabs = [
      {
        tab: '1',
        selectedIndex: 0,
        personsYouPaidAFeeInfoNameOfPersonYouPaidFee: '',
        personsYouPaidAFeeInfoSSNNumberPersonPaidFee: '',
        personsYouPaidAFeeInfoEINNumberPersonPaidFee: '',
        personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee: '',
        personsYouPaidAFeeInfoFeesPaid: '',
        USFeesRad: true,
        ForeignFeesRad: undefined || false,
        personsYouPaidAFeeInfoAddressLine1: '',
        personsYouPaidAFeeInfoAddressLine2: '',
        personsYouPaidAFeeInfoCity: '',
        personsYouPaidAFeeInfoState: '',
        personsYouPaidAFeeInfoProvinceOrState: '',
        personsYouPaidAFeeInfoCountry: '',
        personsYouPaidAFeeInfoZip: '',
      }
    ];

    this.partSevenTabs = [
      {
        tab: '1',
        selectedIndex: 0,
        identifyAllInvolvedInTrTaxExempt: undefined,
        identifyAllInvolvedInTrForeign: undefined,
        identifyAllInvolvedInTrRelated: undefined,
        inGOLDYes: undefined,
        inGOLDNo: undefined,
        InvolvedTrLEID: '',
        identifyAllInvolvedInTrMissingEINReason: '',
        nameInvolvedInTransactionBusinessNameLine1: '',
        identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2: '',
        identifyAllInvolvedInTrSSNNumberInvolvedInTransaction: '',
        identifyAllInvolvedInTrEINNumberInvolvedInTransaction: '',
        identifyAllInvolvedInTrMissingEINReasonInvolvedInTr: '',
        USForPart8Rad: '',
        ForeignForPart8Rad: '',
        identifyAllInvolvedInTrAddressLine1: '',
        identifyAllInvolvedInTrAddressLine2: '',
        identifyAllInvolvedInTrCity: '',
        identifyAllInvolvedInTrState: '',
        identifyAllInvolvedInTrProvinceOrState: '',
        identifyAllInvolvedInTrCountry: '',
        identifyAllInvolvedInTrPostalCode: '',
        identifyAllInvolvedInTrDescriptionOfInvolvement: '',
      }
    ];
  }

  ngOnInit(): void {
    this.loading = true;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);

    const getTaxYears$: Observable<DSTTaxYear[]> = this.cDService.getTaxYears();
    const getCountries$: Observable<DropDownType[]> = this.sharedService.getCountryDropdown(true);
    const getStates$: Observable<DropDownType[]> = this.sharedService.getStateDropdown();
    zip(getTaxYears$, getCountries$, getStates$).pipe(take(1))
      .subscribe((data: [DSTTaxYear[], DropDownType[], DropDownType[]]) => {
        this.taxReturnYears = [{data: []}];
        this.taxYearsPT = [{data: []}];
        this.countries = [{data: []}];
        this.states = [{data: []}];
        this.involvedTrCountries = [{data: []}];
        this.involvedTrStates = [{data: []}];
        data[0].forEach(element => {
          this.taxReturnYears[0].data.push({
            id: element.id,
            text: element.text,
            selected: element.text === this.sharedService.getActiveFormState()?.taxYear
          });
          this.taxYearsPT[0].data.push({
            id: element.id,
            text: element.text,
            selected: element.text === this.sharedService.getActiveFormState()?.taxYear
          });
        });
        data[1].forEach(element => {
          this.countries[0].data.push({
            id: element.id,
            text: element.text
          });
          this.involvedTrCountries[0].data.push({
            id: element.id,
            text: element.text,
            selected: false
          });
        });
        data[2].forEach(element => {
          this.states[0].data.push({
            id: element.id,
            text: element.text
          });
          this.involvedTrStates[0].data.push({
            id: element.id,
            text: element.text,
            selected: false
          });
        });
        this.formModel.forTaxYear = this.sharedService.getActiveFormState()?.taxYear;
        this.formModel.reportableTransactionInfoInitialYearParticipated = this.sharedService.getActiveFormState()?.taxYear;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
      if (this.attMap['ForTaxYear']) {
        const updatedData = this.updateDropdownList(this.taxReturnYears, this.attMap['ForTaxYear'][0]);
        this.taxReturnYears = updatedData.dropdownData;
      }
      if (this.attMap['ReportableTransactionInfoInitialYearParticipated']) {
        const updatedData = this.updateDropdownList(this.taxYearsPT, this.attMap['ReportableTransactionInfoInitialYearParticipated'][0]);
        this.taxYearsPT = updatedData.dropdownData;
      }
      if (this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState']) {
        const updatedData = this.updateDropdownList(this.states, this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState'][0], true);
        this.states = updatedData.dropdownData;
      }
      if (this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry']) {
        const updatedData = this.updateDropdownList(this.countries, this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry'][0], true);
        this.countries = updatedData.dropdownData;
      }
      if (this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState']) {
        const updatedData = this.updateDropdownList(this.involvedTrStates, this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState'][0], true);
        this.involvedTrStates = updatedData.dropdownData;
      }
      if (this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry']) {
        const updatedData = this.updateDropdownList(this.involvedTrCountries, this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry'][0], true);
        this.involvedTrCountries = updatedData.dropdownData;
      }
      this.loading = false;
    });

  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data: any = this.sharedService.getFormData();
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.downloadUrl = data?.downloadUrl;
      this.attachmentname = data.attachmentname;
      this.attachmentpath = data.attachmentpath;

      data.children?.forEach((stmt: StmtAttrData) => {
        if (this.attMap[stmt.attributeName] == undefined) {
          this.attMap[stmt.attributeName] = [];
        }

        if (stmt.attributeValue !== undefined) {
          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        } else {
          this.attMap[stmt.attributeName]?.push('');
        }
      });
      this.loadData(data.primaryleid);
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(data.primaryleid);//primary leid dd

        if (this.attMap['ParticipatingLEID'] && this.attMap['ParticipatingLEID'].length > 0) {
          const part4tab1LeIdDD = this.leidComponents.toArray().find(dd => dd.labelId === `participatingEntity_0`);
          part4tab1LeIdDD!.fillLEID(this.attMap['ParticipatingLEID'][0]);
          this.attMap['ParticipatingLEID'].slice(1).forEach((leid,index) => this.fillLEID(leid,index+1,(tabIndex:number,data:DSTEntityDetails[])=>{
            this.partFourTabs[tabIndex]['participatingEntity'] = data[0];
            this.partFourTabs[tabIndex]['ParticipatingLEID'] = data[0]?.LEID;
          }));
        }
      
        if (this.attMap['IdentifyAllInvolvedInTrLEID'] && this.attMap['IdentifyAllInvolvedInTrLEID'].length > 0) {
          setTimeout(() => {
            const part7tab1LeIdDD = this.leidComponents.toArray().find(dd => dd.labelId === `identifyAllInvolvedInTrLEID_0`);
            part7tab1LeIdDD?.fillLEID(this.attMap['IdentifyAllInvolvedInTrLEID'][0]);
            this.attMap['IdentifyAllInvolvedInTrLEID'].slice(1).forEach((leid,index) => this.fillLEID(leid,index+1,
              (tabIndex:number,data:DSTEntityDetails[])=>{
              this.partSevenTabs[tabIndex]['involvedTrEntity'] = data[0];
              this.partSevenTabs[tabIndex]['InvolvedTrLEID'] = data[0]?.LEID;
            }));
          });
        }
      });

      this.cdr.detectChanges();
    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
      });
    }

  }

  loadData(primaryleid: string | undefined): void {
    //Section 0
    if (primaryleid !== undefined) {
      this.formModel.Transferor = primaryleid;
    }

    //Section 1
    //A
    if (this.attMap['StatementNumber'] !== undefined && this.attMap['StatementNumber'][0] !== '') {
      this.formModel.statementNumber = this.attMap['StatementNumber'][0];
    }
    if (this.attMap['TotalStatementNumber'] !== undefined && this.attMap['TotalStatementNumber'][0] !== '') {
      this.formModel.totalStatementNumber = this.attMap['TotalStatementNumber'][0];
    }

    //B
    if (this.attMap['FormNumberOfTaxReturn'] !== undefined && this.attMap['FormNumberOfTaxReturn'][0] !== '') {
      this.formModel.formNumberOfTaxReturn = this.attMap['FormNumberOfTaxReturn'][0];
    }
    if (this.attMap['ForTaxYear'] !== undefined && this.attMap['ForTaxYear'][0] !== '') {
      this.formModel.forTaxYear = this.attMap['ForTaxYear'][0];
    }
    if (this.attMap['AttachedToAmendedReturn'] !== undefined && this.attMap['AttachedToAmendedReturn'][0] !== '') {
      this.formModel.attachedToAmendedReturn = this.attMap['AttachedToAmendedReturn'][0] === '1' ? true : (this.attMap['AttachedToAmendedReturn'][0] === '0' ? false : undefined);
      this.AttachedToAmendedReturn = this.attMap['AttachedToAmendedReturn'][0] === '1' ? true : (this.attMap['AttachedToAmendedReturn'][0] === '0' ? false : undefined);
    }

    //C
    if (this.attMap['InitialYearFiler'] !== undefined && this.attMap['InitialYearFiler'][0] !== '') {
      this.formModel.initialYearFiler = this.attMap['InitialYearFiler'][0] === '1' ? true : false;
    }
    if (this.attMap['ProtectiveDisclosure'] !== undefined && this.attMap['ProtectiveDisclosure'][0] !== '') {
      this.formModel.protectiveDisclosure = this.attMap['ProtectiveDisclosure'][0] === '1' ? true : false;
    }

    //Section 2
    //1a
    if (this.attMap['ReportableTransactionInfoNameOfReportableTransaction'] !== undefined && this.attMap['ReportableTransactionInfoNameOfReportableTransaction'][0] !== '') {
      this.formModel.reportableTransactionInfoNameOfReportableTransaction = this.attMap['ReportableTransactionInfoNameOfReportableTransaction'][0];
    }
    //1b
    if (this.attMap['ReportableTransactionInfoInitialYearParticipated'] !== undefined && this.attMap['ReportableTransactionInfoInitialYearParticipated'][0] !== '') {
      this.formModel.reportableTransactionInfoInitialYearParticipated = this.attMap['ReportableTransactionInfoInitialYearParticipated'][0];
    }
    //1c
    if (this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'] !== undefined && this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'][0] !== '') {
      this.formModel.reportableTransactionInfoTransactionOrTaxShelterNumber = this.attMap['ReportableTransactionInfoTransactionOrTaxShelterNumber'][0];
    }

    //Section 3
    //2
    if (this.attMap['Listed'] !== undefined && this.attMap['Listed'][0] !== '') {
      this.formModel.listed = this.attMap['Listed'][0] === '1' ? true : false;
    }
    if (this.attMap['Confidential'] !== undefined && this.attMap['Confidential'][0] !== '') {
      this.formModel.confidential = this.attMap['Confidential'][0] === '1' ? true : false;
    }
    if (this.attMap['ContractualProtection'] !== undefined && this.attMap['ContractualProtection'][0] !== '') {
      this.formModel.contractualProtection = this.attMap['ContractualProtection'][0] === '1' ? true : false;
    }
    if (this.attMap['TransactionOfInterest'] !== undefined && this.attMap['TransactionOfInterest'][0] !== '') {
      this.formModel.transactionOfInterest = this.attMap['TransactionOfInterest'][0] === '1' ? true : false;
    }
    if (this.attMap['Loss'] !== undefined && this.attMap['Loss'][0] !== '') {
      this.formModel.loss = this.attMap['Loss'][0] === '1' ? true : false;
    }

    //3
    if (this.attMap['PublishedGuidanceNumber'] !== undefined && this.attMap['PublishedGuidanceNumber'][0] !== '') {
      this.formModel.publishedGuidanceNumber = this.attMap['PublishedGuidanceNumber'][0];
    }
    //4
    if (this.attMap['NumberOfSameOrSimilarTr'] !== undefined && this.attMap['NumberOfSameOrSimilarTr'][0] !== '') {
      this.formModel.numberOfSameOrSimilarTr = this.attMap['NumberOfSameOrSimilarTr'][0];
    }

    //Section 4
    //5
    if ((this.attMap['TypeOfEntityInformationPartnership'] && this.attMap['TypeOfEntityInformationPartnership'][0])
      || (this.attMap['TypeOfEntityInformationSCorporation'] && this.attMap['TypeOfEntityInformationSCorporation'][0])
      || (this.attMap['TypeOfEntityInformationTrust'] && this.attMap['TypeOfEntityInformationTrust'][0])
      || (this.attMap['TypeOfEntityInformationForeign'] && this.attMap['TypeOfEntityInformationForeign'][0])
      || (this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'] && this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][0])) {
      this.partFourTabs = [];
      this.attMap['TypeOfEntityInformationPartnership'].forEach((element: string, index: number) => {
        this.partFourTabs.push({
          tab: index + 1 + '',
          selectedIndex: index + 1,
          ParticipatingLEID: this.attMap['ParticipatingLEID'][index],
          typeOfEntityInformationPartnership: this.attMap['TypeOfEntityInformationPartnership'] ? this.attMap['TypeOfEntityInformationPartnership'][index] : '',
          typeOfEntityInformationSCorporation: this.attMap['TypeOfEntityInformationSCorporation'] ? this.attMap['TypeOfEntityInformationSCorporation'][index] : '',
          typeOfEntityInformationTrust: this.attMap['TypeOfEntityInformationTrust'] ? this.attMap['TypeOfEntityInformationTrust'][index] : '',
          typeOfEntityInformationForeign: this.attMap['TypeOfEntityInformationForeign'] ? this.attMap['TypeOfEntityInformationForeign'][index] : '',
          typeOfEntityInformationMissingEINReason: this.attMap['TypeOfEntityInformationMissingEINReason'] ? this.attMap['TypeOfEntityInformationMissingEINReason'][index] : '',
          typeOfEntityInformationScheduleK1ReceivedDate: (this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'] && this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][index] === undefined) ? '' : (new Date(this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][index].replace(/-/g, '\/').replace(/T.+/, ''))),
          typeOfEntityInformationScheduleK1RcvDate: this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'] ? this.attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][index] : '',
          typeOfEntityInformationNoScheduleK1Received: this.attMap['TypeOfEntityInformationNoScheduleK1Received'] ? this.attMap['TypeOfEntityInformationNoScheduleK1Received'][index] : '',
        });
      });
      this.selectTab(0, 'partFour', true);
    }

    //Section 5
    //6
    if (this.attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'] && this.attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'][0]) {
      this.partFiveTabs = [];
      this.attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'].forEach((element: string, index: number) => {
        this.partFiveTabs.push({
          tab: index + 1 + '',
          selectedIndex: index + 1,
          personsYouPaidAFeeInfoNameOfPersonYouPaidFee: this.attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'] ? this.attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'][index] : '',
          personsYouPaidAFeeInfoSSNNumberPersonPaidFee: this.attMap['PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee'] ? this.attMap['PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee'][index] : '',
          personsYouPaidAFeeInfoEINNumberPersonPaidFee: this.attMap['PersonsYouPaidAFeeInfoEINNumberPersonPaidFee'] ? this.attMap['PersonsYouPaidAFeeInfoEINNumberPersonPaidFee'][index] : '',
          personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee: this.attMap['PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'] ? this.attMap['PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'][index] : '',
          personsYouPaidAFeeInfoFeesPaid: this.attMap['PersonsYouPaidAFeeInfoFeesPaid'] ? this.attMap['PersonsYouPaidAFeeInfoFeesPaid'][index] : '',
          USFeesRad: this.attMap['USForeignFees'] && this.attMap['USForeignFees'][index] === 'US',
          ForeignFeesRad: this.attMap['USForeignFees'] && this.attMap['USForeignFees'][index] === 'FOREIGN',
          personsYouPaidAFeeInfoAddressLine1: this.attMap['USForeignFees'][index] === 'US' ? this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1'][index]
            : (this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1'][index] : ''),
          personsYouPaidAFeeInfoAddressLine2: this.attMap['USForeignFees'][index] === 'US' ? this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2'][index]
            : (this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2'][index] : ''),
          personsYouPaidAFeeInfoCity: this.attMap['USForeignFees'][index] === 'US' ? this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity'][index]
            : (this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity'][index] : ''),
          personsYouPaidAFeeInfoState: this.attMap['USForeignFees'][index] === 'US' ? this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState'][index] : '',
          personsYouPaidAFeeInfoProvinceOrState: this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState'][index] : '',
          personsYouPaidAFeeInfoCountry: this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry'][index] : '',
          personsYouPaidAFeeInfoZip: this.attMap['USForeignFees'][index] === 'US' ? this.attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode'][index]
            : (this.attMap['USForeignFees'][index] === 'FOREIGN' ? this.attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode'][index] : '')
        });
      });
      this.selectTab(0, 'partFive', true);
    }

    //Section 6
    //7a
    if (this.attMap['Deductions'] !== undefined && this.attMap['Deductions'][0] !== '') {
      this.formModel.deductions = this.attMap['Deductions'][0] === '1' ? true : false;
    }
    if (this.attMap['ExclusionsFromGrossIncome'] !== undefined && this.attMap['ExclusionsFromGrossIncome'][0] !== '') {
      this.formModel.exclusionsFromGrossIncome = this.attMap['ExclusionsFromGrossIncome'][0] === '1' ? true : false;
    }
    if (this.attMap['CapitalLoss'] !== undefined && this.attMap['CapitalLoss'][0] !== '') {
      this.formModel.capitalLoss = this.attMap['CapitalLoss'][0] === '1' ? true : false;
    }
    if (this.attMap['NonrecognitionOfGain'] !== undefined && this.attMap['NonrecognitionOfGain'][0] !== '') {
      this.formModel.nonrecognitionOfGain = this.attMap['NonrecognitionOfGain'][0] === '1' ? true : false;
    }
    if (this.attMap['OrdinaryLoss'] !== undefined && this.attMap['OrdinaryLoss'][0] !== '') {
      this.formModel.ordinaryLoss = this.attMap['OrdinaryLoss'][0] === '1' ? true : false;
    }
    if (this.attMap['AdjustmentsToBasis'] !== undefined && this.attMap['AdjustmentsToBasis'][0] !== '') {
      this.formModel.adjustmentsToBasis = this.attMap['AdjustmentsToBasis'][0] === '1' ? true : false;
    }
    if (this.attMap['AbsenceOfAdjustmentsToBasis'] !== undefined && this.attMap['AbsenceOfAdjustmentsToBasis'][0] !== '') {
      this.formModel.absenceOfAdjustmentsToBasis = this.attMap['AbsenceOfAdjustmentsToBasis'][0] === '1' ? true : false;
    }
    if (this.attMap['TaxCredits'] !== undefined && this.attMap['TaxCredits'][0] !== '') {
      this.formModel.taxCredits = this.attMap['TaxCredits'][0] === '1' ? true : false;
    }
    if (this.attMap['Deferral'] !== undefined && this.attMap['Deferral'][0] !== '') {
      this.formModel.deferral = this.attMap['Deferral'][0] === '1' ? true : false;
    }
    if (this.attMap['Other'] !== undefined && this.attMap['Other'][0] !== '') {
      this.formModel.line7aOther = this.attMap['Other'][0] === '1' ? true : false;
    }
    if (this.attMap['OtherText'] !== undefined && this.attMap['OtherText'][0] !== '') {
      this.formModel.line7aOtherText = this.attMap['OtherText'][0];
    }

    //7b
    if (this.attMap['TotalTaxBenefitAmt'] !== undefined && this.attMap['TotalTaxBenefitAmt'][0] !== '') {
      this.formModel.totalTaxBenefitAmt = this.attMap['TotalTaxBenefitAmt'][0];
    }

    //7c
    if (this.attMap['TaxBnftAnticipatedYrCnt'] !== undefined && this.attMap['TaxBnftAnticipatedYrCnt'][0] !== '') {
      this.formModel.taxBnftAnticipatedYrCnt = this.attMap['TaxBnftAnticipatedYrCnt'][0];
    }

    //7d
    if (this.attMap['TotalInvestmentOrBasisAmt'] !== undefined && this.attMap['TotalInvestmentOrBasisAmt'][0] !== '') {
      this.formModel.totalInvestmentOrBasisAmt = this.attMap['TotalInvestmentOrBasisAmt'][0];
    }

    //Section 7
    //8
    if ((this.attMap['IdentifyAllInvolvedInTrTaxExempt'])
      || (this.attMap['IdentifyAllInvolvedInTrForeign'])
      || (this.attMap['IdentifyAllInvolvedInTrRelated'])) {

      this.partSevenTabs = [];
      this.attMap['IdentifyAllInvolvedInTrTaxExempt'].forEach((element: string, index: number) => {
        this.partSevenTabs.push({
          tab: index + 1 + '',
          selectedIndex: index + 1,
          identifyAllInvolvedInTrTaxExempt: this.attMap['IdentifyAllInvolvedInTrTaxExempt'] && this.attMap['IdentifyAllInvolvedInTrTaxExempt'][index] === '1' ? true : '',
          identifyAllInvolvedInTrForeign: this.attMap['IdentifyAllInvolvedInTrForeign'] && this.attMap['IdentifyAllInvolvedInTrForeign'][index] === '1' ? true : '',
          identifyAllInvolvedInTrRelated: this.attMap['IdentifyAllInvolvedInTrRelated'] && this.attMap['IdentifyAllInvolvedInTrRelated'][index] === '1' ? true : '',
          inGOLDYes: this.attMap['InGOLD8'] && this.attMap['InGOLD8'][index] === '1',
          inGOLDNo: this.attMap['InGOLD8'] && this.attMap['InGOLD8'][index] === '0',
          InvolvedTrLEID: this.attMap['InGOLD8'][index] === '1' ? this.attMap['IdentifyAllInvolvedInTrLEID'][index] : '',
          identifyAllInvolvedInTrMissingEINReason: this.attMap['InGOLD8'][index] === '1' ? this.attMap['IdentifyAllInvolvedInTrMissingEINReason'][index] : '',
          nameInvolvedInTransactionBusinessNameLine1: this.attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1'][index],
          identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2: this.attMap['InGOLD8'][index] === '0' ? this.attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2'][index] : '',
          identifyAllInvolvedInTrSSNNumberInvolvedInTransaction: this.attMap['InGOLD8'][index] === '0' ? this.attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'][index] : '',
          identifyAllInvolvedInTrEINNumberInvolvedInTransaction: this.attMap['InGOLD8'][index] === '0' ? this.attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'][index] : '',
          identifyAllInvolvedInTrMissingEINReasonInvolvedInTr: this.attMap['InGOLD8'][index] === '0' ? this.attMap['IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr'][index] : '',
          USForPart8Rad: this.attMap['InGOLD8'][index] === '0' ? this.attMap['USForPart8'][index] === 'US' : false,
          ForeignForPart8Rad: this.attMap['InGOLD8'][index] === '0' ? this.attMap['USForPart8'][index] === 'FOREIGN' : false,
          identifyAllInvolvedInTrAddressLine1: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'US' ? this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1'][index]
            : (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1'][index] : '')) : '',
          identifyAllInvolvedInTrAddressLine2: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'US' ? this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2'][index]
            : (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2'][index] : '')) : '',
          identifyAllInvolvedInTrCity: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'US' ? this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity'][index]
            : (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity'][index] : '')) : '',
          identifyAllInvolvedInTrState: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'US' ? this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState'][index] : '') : '',
          identifyAllInvolvedInTrProvinceOrState: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState'][index] : '') : '',
          identifyAllInvolvedInTrCountry: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry'][index] : '') : '',
          identifyAllInvolvedInTrPostalCode: this.attMap['InGOLD8'][index] === '0' ? (this.attMap['USForPart8'][index] === 'US' ? this.attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode'][index]
            : (this.attMap['USForPart8'][index] === 'FOREIGN' ? this.attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode'][index] : '')) : '',
          identifyAllInvolvedInTrDescriptionOfInvolvement: this.attMap['IdentifyAllInvolvedInTrDescriptionOfInvolvement'][index] || '',
        });
      });
      this.selectTab(0, 'partSeven', true);
    }
  }

  download(): void {
    this.attachedService.getDSTPresignedUrl(this.attachmentpath, this.attachmentname)
      .subscribe((presignedS3Url: string) => {
        window.open(presignedS3Url, '_blank');
      });
  }

  deleteFileUrl(): void {
    this.downloadUrl = '';
  }

  onFileChanged(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.file = files[0];
    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  updateDropdownList(currentOptions: any, element: string, useId: boolean = false) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      const elementValue = (el.value) ? el.value : (useId ? el.id.toString() : el.text.toString());
      if (element === elementValue) {
        updateList.push({
          id: el.id,
          value: elementValue,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = elementValue;
      } else {
        updateList.push({
          id: el.id,
          value: elementValue,
          text: el.text,
          index: elementValue,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  onConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onForTaxYearChanged(event: any): void {
    this.formModel.forTaxYear = event.selected[0].text;
  }

  onInitialYearParticipatedInTrChanged(event: any): void {
    this.formModel.reportableTransactionInfoInitialYearParticipated = event.selected[0].text;
  }

  onReportingEntityChanged(selectedEntity: DSTEntityDetails | null): void {
    this.transferor = selectedEntity;
    if (!selectedEntity) {
      return;
    }
    this.headerComponent.loadConsolGroups(this.transferor!.LEID,this.dcnId);
  }

  onParticipatingLEIDChanged(selectedEntity: DSTEntityDetails | null, tabIndex: number): void {
    // this.participatingLEID = selectedEntity;
    this.partFourTabs[tabIndex]['participatingEntity'] = selectedEntity;
    this.partFourTabs[tabIndex]['ParticipatingLEID'] = selectedEntity?.LEID;
  }

  onInvInTrMissingEINChange(event: any, tabIndex: number) {
    this.partFourTabs[tabIndex]['typeOfEntityInformationMissingEINReason'] = event.selected[0].text;
  }

  personsYouPaidAFeeInfoMissingEINReasonChange(event: any, tabIndex: number): void {
    this.partFiveTabs[tabIndex]['personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'] = event.selected[0].value;
  }

  personsYouPaidAFeeInfoEINChange(event: any, tabIndex: number): void {
    if (event.value) {
      this.resetDropdowns(this.partFiveReasonsEIN[0].data);
      this.partFiveTabs[tabIndex]['personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'] = '';
    }
  }

  personsYouPaidAFeeInfoStateChange(event: any, tabIndex: number): void {
    this.partFiveTabs[tabIndex]['personsYouPaidAFeeInfoState'] = event.selected[0].id;
  }

  personsYouPaidAFeeInfoCountryChange(event: any, tabIndex: number): void {
    this.partFiveTabs[tabIndex]['personsYouPaidAFeeInfoCountry'] = event.selected[0].id;
  }

  onInvolvedTrEntityChanged(selectedEntity: DSTEntityDetails | null, tabIndex: number): void {
    // this.involvedTrEntity = selectedEntity;
    this.partSevenTabs[tabIndex]['involvedTrEntity'] = selectedEntity;
    this.partSevenTabs[tabIndex]['InvolvedTrLEID'] = selectedEntity?.LEID;
  }

  missingEINReasonInGoldInvolvedInTrChange(event: any, tabIndex: number): void {
    this.partSevenTabs[tabIndex]['identifyAllInvolvedInTrMissingEINReason'] = event.selected[0].value;
  }

  missingEINReasonInvolvedInTrChange(event: any, tabIndex: number): void {
    this.partSevenTabs[tabIndex]['identifyAllInvolvedInTrMissingEINReasonInvolvedInTr'] = event.selected[0].value;
  }

  identifyAllInvolvedInTrStateChange(event: any, tabIndex: number): void {
    this.partSevenTabs[tabIndex]['identifyAllInvolvedInTrState'] = event.selected[0].id;
  }

  identifyAllInvolvedInTrCountryChange(event: any, tabIndex: number): void {
    this.partSevenTabs[tabIndex]['identifyAllInvolvedInTrCountry'] = event.selected[0].id;
  }

  check2(option: boolean): void {
    this.AttachedToAmendedReturn = option;
  }

  check3(option: boolean, tabIndex: number): void {
    this.USForeignFeesRad = option;
    this.partFiveTabs[tabIndex].USFeesRad = (option === true);
    this.partFiveTabs[tabIndex].ForeignFeesRad = (option === false);
  }

  check4(option: boolean, tabIndex: number): void {
    this.invlovedEntityInGOLD = option;
    this.partSevenTabs[tabIndex].inGOLDYes = (option === true);
    this.partSevenTabs[tabIndex].inGOLDNo = (option === false);
  }

  check5(option: boolean, tabIndex: number): void {
    this.InvolvedUSForeignEntity = option;
    this.partSevenTabs[tabIndex].USForPart8Rad = (option === true);
    this.partSevenTabs[tabIndex].ForeignForPart8Rad = (option === false);
  }

  clearDate(index: number): void {
    this.showDate = false;
    this.partFourTabs[index].typeOfEntityInformationScheduleK1ReceivedDate = '';
    setTimeout(() => {
      this.showDate = true;
    });
  }

  clearEntity(partName: string, tabIndex: number): void {

    if (partName === 'partFour') {
      // this.participatingLEID = null;
      this.leidComponents.toArray()[tabIndex + 1].clear();
      this.partFourTabs[tabIndex].ParticipatingLEID = '';
      this.partFourTabs[tabIndex].participatingEntity && (this.partFourTabs[tabIndex].participatingEntity = null);
      this.partFourTabs[tabIndex].typeOfEntityInformationMissingEINReason = '';
      this.clearDate(tabIndex);
      this.partFourTabs[tabIndex].typeOfEntityInformationNoScheduleK1Received = 'None';
      this.resetDropdowns(this.reasonsEIN);
    } else if (partName === 'partSeven') {
      // this.involvedTrEntity = null;
      this.leidComponents.toArray()[this.partFourTabs.length + tabIndex + 1].clear();
      this.partSevenTabs[tabIndex].InvolvedTrLEID = '';
      this.partSevenTabs[tabIndex].involvedTrEntity && (this.partSevenTabs[tabIndex].involvedTrEntity = null);
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrMissingEINReason = '';
      this.resetDropdowns(this.partSevenReasonsEIN);
      this.partSevenTabs[tabIndex].inGOLDYes = undefined;
      this.partSevenTabs[tabIndex].inGOLDNo = undefined;
      this.partSevenTabs[tabIndex].nameInvolvedInTransactionBusinessNameLine1 = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2 = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrSSNNumberInvolvedInTransaction = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrEINNumberInvolvedInTransaction = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrMissingEINReasonInvolvedInTr = '';
      this.resetDropdowns(this.partSevenInvolvedReasonsEIN);
      this.partSevenTabs[tabIndex].USForPart8Rad = '';
      this.partSevenTabs[tabIndex].ForeignForPart8Rad = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrAddressLine1 = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrAddressLine2 = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrCity = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrState = '';
      this.resetDropdowns(this.involvedTrStates);
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrProvinceOrState = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrCountry = '';
      this.resetDropdowns(this.involvedTrCountries);
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrPostalCode = '';
      this.partSevenTabs[tabIndex].identifyAllInvolvedInTrDescriptionOfInvolvement = '';
    }
  }

  addTab(partName: string) {

    switch (partName) {
      case 'partFour':
        this.partFourTabs.push(
          {
            tab: this.partFourTabs.length + 1 + '',
            selectedIndex: -1,
            ParticipatingLEID: '',
            typeOfEntityInformationPartnership: '',
            typeOfEntityInformationSCorporation: '',
            typeOfEntityInformationTrust: '',
            typeOfEntityInformationForeign: '',
            typeOfEntityInformationMissingEINReason: '',
            typeOfEntityInformationScheduleK1ReceivedDate: '',
            typeOfEntityInformationScheduleK1RcvDate: '',
            typeOfEntityInformationNoScheduleK1Received: 'None',
          }
        );
        this.partFourSelected.setValue(this.partFourTabs.length - 1);
        break;

      case 'partFive':
        this.partFiveTabs.push({
          tab: this.partFiveTabs.length + 1 + '',
          selectedIndex: -1,
          personsYouPaidAFeeInfoNameOfPersonYouPaidFee: '',
          personsYouPaidAFeeInfoSSNNumberPersonPaidFee: '',
          personsYouPaidAFeeInfoEINNumberPersonPaidFee: '',
          personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee: '',
          personsYouPaidAFeeInfoFeesPaid: '',
          USFeesRad: true,
          ForeignFeesRad: undefined || false,
          personsYouPaidAFeeInfoAddressLine1: '',
          personsYouPaidAFeeInfoAddressLine2: '',
          personsYouPaidAFeeInfoCity: '',
          personsYouPaidAFeeInfoState: '',
          personsYouPaidAFeeInfoProvinceOrState: '',
          personsYouPaidAFeeInfoCountry: '',
          personsYouPaidAFeeInfoZip: '',
        });
        this.partFiveSelected.setValue(this.partFiveTabs.length - 1);
        break;

      case 'partSeven':
        this.partSevenTabs.push({
          tab: this.partSevenTabs.length + 1 + '',
          selectedIndex: -1,
          identifyAllInvolvedInTrTaxExempt: undefined,
          identifyAllInvolvedInTrForeign: undefined,
          identifyAllInvolvedInTrRelated: undefined,
          inGOLDYes: undefined,
          inGOLDNo: undefined,
          identifyAllInvolvedInTrMissingEINReason: '',
          nameInvolvedInTransactionBusinessNameLine1: '',
          identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2: '',
          identifyAllInvolvedInTrSSNNumberInvolvedInTransaction: '',
          identifyAllInvolvedInTrEINNumberInvolvedInTransaction: '',
          identifyAllInvolvedInTrMissingEINReasonInvolvedInTr: '',
          USForPart8Rad: '',
          ForeignForPart8Rad: '',
          identifyAllInvolvedInTrAddressLine1: '',
          identifyAllInvolvedInTrAddressLine2: '',
          identifyAllInvolvedInTrCity: '',
          identifyAllInvolvedInTrState: '',
          identifyAllInvolvedInTrProvinceOrState: '',
          identifyAllInvolvedInTrCountry: '',
          identifyAllInvolvedInTrPostalCode: '',
          identifyAllInvolvedInTrDescriptionOfInvolvement: '',
        });
        this.partSevenSelected.setValue(this.partSevenTabs.length - 1);
        break;

      default:
        break;
    }

  }

  removeTab(index: number, partName: string) {

    switch (partName) {
      case 'partFour':
        this.partFourTabs.splice(index, 1);
        break;

      case 'partFive':
        this.partFiveTabs.splice(index, 1);
        break;

      case 'partSeven':
        this.partSevenTabs.splice(index, 1);
        break;

      default:
        break;
    }
  }

  selectTab(event: any, partName: string, isEdit: boolean) {

    switch (partName) {
      case 'partFour':
        this.partFourSelected.setValue(event);
        const participatingEntity = this.partFourTabs[event]['participatingEntity'];
        const part4CurrentTabLEDD = this.leidComponents.toArray().find(dd => dd.labelId === `participatingEntity_${event}`);
        if (participatingEntity === undefined && !isEdit && this.partFourTabs[event]['ParticipatingLEID']) {
          part4CurrentTabLEDD!.fillLEID(this.partFourTabs[event]['ParticipatingLEID']);
        }else if(!part4CurrentTabLEDD?.selectedEntity && participatingEntity){
          part4CurrentTabLEDD?.preFillLEID(participatingEntity);
        }

        this.resetDropdowns(this.reasonsEIN[0].data);
        const selectedPart4MissingEIN = this.reasonsEIN[0].data.find((item: any) => {
          return item.text === this.partFourTabs[event]['typeOfEntityInformationMissingEINReason'];
        });
        (selectedPart4MissingEIN) && (selectedPart4MissingEIN.selected = true);
        break;

      case 'partFive':
        this.partFiveSelected.setValue(event);

        this.resetDropdowns(this.partFiveReasonsEIN[0].data);
        const selectedMissingEIN = this.partFiveReasonsEIN[0].data.find((item: any) => {
          return item.text === this.partFiveTabs[event]['personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'];
        });
        (selectedMissingEIN) && (selectedMissingEIN.selected = true);

        this.USForeignFeesRad = this.partFiveTabs[event]['USFeesRad'] === true;
        this.resetDropdowns(this.states[0].data);
        const selectedState = this.states[0].data.find((item: any) => {
          return item.id === this.partFiveTabs[event]['personsYouPaidAFeeInfoState'];
        });
        (selectedState) && (selectedState.selected = true);

        this.resetDropdowns(this.countries[0].data);
        const selectedCountry = this.countries[0].data.find((item: any) => {
          return item.id === this.partFiveTabs[event]['personsYouPaidAFeeInfoCountry'];
        });
        (selectedCountry) && (selectedCountry.selected = true);
        break;

      case 'partSeven':
        this.partSevenSelected.setValue(event);
        const involvedTrEntity = this.partSevenTabs[event]['involvedTrEntity'];
        const currentTabLEDD = this.leidComponents.toArray().find(dd => dd.labelId === `identifyAllInvolvedInTrLEID_${event}`);
        if (involvedTrEntity === undefined && !isEdit && this.partSevenTabs[event]['InvolvedTrLEID']) {
          currentTabLEDD?.fillLEID(this.partSevenTabs[event]['InvolvedTrLEID']);
        }else if(!currentTabLEDD?.selectedEntity && involvedTrEntity){
          currentTabLEDD?.preFillLEID(involvedTrEntity);
        }

        this.invlovedEntityInGOLD = (this.partSevenTabs[event]['inGOLDYes'] === true ? true :
          (this.partSevenTabs[event]['inGOLDNo'] === true ? false : undefined));

        this.resetDropdowns(this.partSevenReasonsEIN[0].data);
        const selectedPart8InGoldMissingEIN = this.partSevenReasonsEIN[0].data.find((item: any) => {
          return item.text === this.partSevenTabs[event]['identifyAllInvolvedInTrMissingEINReason'];
        });
        (selectedPart8InGoldMissingEIN) && (selectedPart8InGoldMissingEIN.selected = true);

        this.resetDropdowns(this.partSevenInvolvedReasonsEIN[0].data);
        const selectedPart8MissingEIN = this.partSevenInvolvedReasonsEIN[0].data.find((item: any) => {
          return item.text === this.partSevenTabs[event]['identifyAllInvolvedInTrMissingEINReasonInvolvedInTr'];
        });
        (selectedPart8MissingEIN) && (selectedPart8MissingEIN.selected = true);

        this.InvolvedUSForeignEntity = this.partSevenTabs[event]['USForPart8Rad'] === true ? true : (this.partSevenTabs[event]['ForeignForPart8Rad'] === true ? false : undefined);
        this.resetDropdowns(this.involvedTrStates[0].data);
        const selectedPart8State = this.involvedTrStates[0].data.find((item: any) => {
          return item.id === this.partSevenTabs[event]['identifyAllInvolvedInTrState'];
        });
        (selectedPart8State) && (selectedPart8State.selected = true);

        this.resetDropdowns(this.involvedTrCountries[0].data);
        const selectedPart8Country = this.involvedTrCountries[0].data.find((item: any) => {
          return item.id === this.partSevenTabs[event]['identifyAllInvolvedInTrCountry'];
        });
        (selectedPart8Country) && (selectedPart8Country.selected = true);
        break;

      default:
        break;
    }
  }

  resetDropdowns(collection: any): void {
    _.each(collection, function(item) {
      item.selected = false;
    });
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  dateChange(event: any, tabIndex: number) {
    this.partFourTabs[tabIndex].typeOfEntityInformationScheduleK1RcvDate = event.startDate.format('YYYY-MM-DD');
    this.partFourTabs[tabIndex].typeOfEntityInformationNoScheduleK1Received = "";
  }

  isFormValid(): boolean {

    //Section 0
    if (!this.transferor) {
      this.dstSharedService.showAlert('error', 'Please specify the LEID for the Reporting Entity');
      return false;
    }

    //Section 4 line 5 tabs
    for (let i = 0; i < this.partFourTabs.length; i++) {
      if (this.partFourTabs[i].typeOfEntityInformationPartnership === true || this.partFourTabs[i].typeOfEntityInformationSCorporation === true
        || this.partFourTabs[i].typeOfEntityInformationTrust === true || this.partFourTabs[i].typeOfEntityInformationForeign === true) {

        if (!this.partFourTabs[i].ParticipatingLEID) {
          this.dstSharedService.showAlert('error', `Line 5 Tab-${this.partFourTabs[i].tab}: Please specify the LEID for the Reporting Entity`);
          return false;
        } else {
          if (this.partFourTabs[i].participatingEntity && this.partFourTabs[i].participatingEntity.EIN === undefined
            && (this.partFourTabs[i].typeOfEntityInformationMissingEINReason === '' || typeof this.partFourTabs[i].typeOfEntityInformationMissingEINReason === 'object')) {

            this.dstSharedService.showAlert('error', `Line 5 Tab-${this.partFourTabs[i].tab}: Please specify the reason the Identifying number is missing for the Participating Entity`);
            return false;
          }
        }
      }
    }

    //Section 5 line 6 tabs
    for (let i = 0; i < this.partFiveTabs.length; i++) {
      if (this.partFiveTabs[i].personsYouPaidAFeeInfoNameOfPersonYouPaidFee !== '') {

        if ((this.partFiveTabs[i].personsYouPaidAFeeInfoSSNNumberPersonPaidFee === '' || this.partFiveTabs[i].personsYouPaidAFeeInfoSSNNumberPersonPaidFee == null) 
          && (this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee === '' || this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee == null)
          && (this.partFiveTabs[i].personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee === '' || typeof this.partFiveTabs[i].personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee === 'object')) {

          this.dstSharedService.showAlert('error', `Line 6 Tab-${this.partFiveTabs[i].tab}: Please specify the reason the Identifying number is missing for line 6`);
          return false;
        }
      }
      if(this.partFiveTabs[i].personsYouPaidAFeeInfoNameOfPersonYouPaidFee === '' && (this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee !== '' || this.partFiveTabs[i].personsYouPaidAFeeInfoSSNNumberPersonPaidFee
        || this.partFiveTabs[i].personsYouPaidAFeeInfoSSNNumberPersonPaidFee  || this.partFiveTabs[i].personsYouPaidAFeeInfoAddressLine1)){
        this.dstSharedService.showAlert('error', `Line 6 Tab-${this.partFiveTabs[i].tab}: Please enter Name`);
          return false;
      }
      
      if(this.partFiveTabs[i].USFeesRad){
        if(this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee !== '' && !this.isValidNumber(this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee)){
          this.dstSharedService.showAlert('error', `Line 6 Tab-${this.partFiveTabs[i].tab}: Please enter Valid EIN`);
          return false;
        }
        if(this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee !== '' && this.partFiveTabs[i].personsYouPaidAFeeInfoEINNumberPersonPaidFee.length !== 9 ){
          this.dstSharedService.showAlert('error', `Line 6 Tab-${this.partFiveTabs[i].tab}: Please enter 9 digits EIN Number`);
          return false;
        }
      }
      
    }

    //Section 6, line 7
    if (this.formModel.totalTaxBenefitAmt === undefined || this.formModel.totalTaxBenefitAmt === '') {
      this.dstSharedService.showAlert('error', 'Please specify the total dollar amount of your tax benefits in 7a');
      return false;
    }
    if (this.formModel.taxBnftAnticipatedYrCnt === undefined || this.formModel.taxBnftAnticipatedYrCnt === '') {
      this.dstSharedService.showAlert('error', 'Please specify the anticipated number of years provides the tax benefits stated in 7b');
      return false;
    }
    if (this.formModel.totalInvestmentOrBasisAmt === undefined || this.formModel.totalInvestmentOrBasisAmt === '') {
      this.dstSharedService.showAlert('error', 'Please specify your total investment or basis in the transaction.');
      return false;
    }

    //Section 7 line 8 tabs
    for (let i = 0; i < this.partSevenTabs.length; i++) {
      if ((this.partSevenTabs[i].identifyAllInvolvedInTrTaxExempt === true || this.partSevenTabs[i].identifyAllInvolvedInTrForeign === true
        || this.partSevenTabs[i].identifyAllInvolvedInTrRelated === true) || ((this.partSevenTabs[i].inGOLDYes !== true || this.partSevenTabs[i].inGOLDNo !== true)
        && (this.partSevenTabs[i].InvolvedTrLEID !== '' || this.partSevenTabs[i].nameInvolvedInTransactionBusinessNameLine1 !== ''))) {

        if (this.partSevenTabs[i].inGOLDYes === true) { //If Entity In Gold
          if (!this.partSevenTabs[i].InvolvedTrLEID) {
            this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the LEID for the Participated Entity or uncheck the type of entity checkboxes for Part 8`);
            return false;
          } else {
            if (this.partSevenTabs[i].involvedTrEntity && this.partSevenTabs[i].involvedTrEntity.EIN === undefined
              && (this.partSevenTabs[i].identifyAllInvolvedInTrMissingEINReason === '' || typeof this.partSevenTabs[i].identifyAllInvolvedInTrMissingEINReason === 'object')) {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the reason the Identifying number is missing for Part 8`);
              return false;
            }
          }

        } else if (this.partSevenTabs[i].inGOLDNo === true) { //If Entity Not In Gold

          if (this.partSevenTabs[i].nameInvolvedInTransactionBusinessNameLine1 === undefined || this.partSevenTabs[i].nameInvolvedInTransactionBusinessNameLine1 === '') {
            this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the name of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
            return false;
          }
          if ((this.partSevenTabs[i].identifyAllInvolvedInTrSSNNumberInvolvedInTransaction === '' || this.partSevenTabs[i].identifyAllInvolvedInTrSSNNumberInvolvedInTransaction == null) 
              && (this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction === '' || this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction == null)
            && (this.partSevenTabs[i].identifyAllInvolvedInTrMissingEINReasonInvolvedInTr === '' || typeof this.partSevenTabs[i].identifyAllInvolvedInTrMissingEINReasonInvolvedInTr === 'object')) {

            this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the reason the Identifying number is missing for line 8`);
            return false;
          }

          if(this.partSevenTabs[i].USForPart8Rad){
            if(this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction !== '' && !this.isValidNumber(this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction)){
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please enter Valid EIN`);
              return false;
            }
            if(this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction !== '' && this.partSevenTabs[i].identifyAllInvolvedInTrEINNumberInvolvedInTransaction.length !== 9 ){
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please enter 9 digits EIN Number`);
              return false;
            }
          }

          //If Entity has US Address
          if (this.partSevenTabs[i].USForPart8Rad === true) {
            if (this.partSevenTabs[i].identifyAllInvolvedInTrAddressLine1 === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrAddressLine1 === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the Address of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
            if (this.partSevenTabs[i].identifyAllInvolvedInTrCity === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrCity === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the City of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
            if (this.partSevenTabs[i].identifyAllInvolvedInTrState === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrState === '' || typeof this.partSevenTabs[i].identifyAllInvolvedInTrState === 'object') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the State of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
            if (this.partSevenTabs[i].identifyAllInvolvedInTrPostalCode === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrPostalCode === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the Zip of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
          } else if (this.partSevenTabs[i].ForeignForPart8Rad === true) { //If Entity has Foreign Address
            if (this.partSevenTabs[i].identifyAllInvolvedInTrAddressLine1 === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrAddressLine1 === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the Address of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
            if (this.partSevenTabs[i].identifyAllInvolvedInTrCity === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrCity === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the City of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
           /* if (this.partSevenTabs[i].identifyAllInvolvedInTrProvinceOrState === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrProvinceOrState === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the State of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }*/
            if (this.partSevenTabs[i].identifyAllInvolvedInTrCountry === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrCountry === '' || typeof this.partSevenTabs[i].identifyAllInvolvedInTrCountry === 'object') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the Country of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
            if (this.partSevenTabs[i].identifyAllInvolvedInTrPostalCode === undefined || this.partSevenTabs[i].identifyAllInvolvedInTrPostalCode === '') {
              this.dstSharedService.showAlert('error', `Line 8 Tab-${this.partSevenTabs[i].tab}: Please specify the Postal Code of the Participating Entity or uncheck the type of entity checkboxes for Part 8`);
              return false;
            }
          }

        }

      }
    }

    return true;
  }

  isValidNumber(input: string) {
    const regex = /^\d+$/; 
    input = input+'';
    if(regex.test(input)){
      return true; 
    }
    else return false;
  }

  save(event: any): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;

    if (!this.isFormValid()) {
      return;
    } else {
      this.loading = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId || '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString()! : '',
        'secondaryEntityId': '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': this.file ? this.file.name : '',
        // 'attachmentPath': this.file,
      };

      const TypeOfEntityInformationPartnership: string[] = []; //5a
      const TypeOfEntityInformationSCorporation: string[] = []; //5a
      const TypeOfEntityInformationTrust: string[] = []; //5a
      const TypeOfEntityInformationForeign: string[] = []; //5a
      const ParticipatingLEID: string[] = []; //5 leid
      const TypeOfEntityInformationEntityId: string[] = []; //5 entityid
      const TypeOfEntityInformationEntityNameBusinessNameLine1: string[] = []; //5b
      const TypeOfEntityInformationEntityEIN: string[] = []; //5c
      const TypeOfEntityInformationMissingEINReason: string[] = []; //5c missing ein
      const TypeOfEntityInformationScheduleK1ReceivedDate: string[] = [];  //5d date
      const TypeOfEntityInformationNoScheduleK1Received: string[] = []; //5d
      this.partFourTabs.forEach((tab: any) => {
        TypeOfEntityInformationPartnership.push(tab.typeOfEntityInformationPartnership ? '1' : '');
        TypeOfEntityInformationSCorporation.push(tab.typeOfEntityInformationSCorporation ? '1' : '');
        TypeOfEntityInformationTrust.push(tab.typeOfEntityInformationTrust ? '1' : '');
        TypeOfEntityInformationForeign.push(tab.typeOfEntityInformationForeign ? '1' : '');
        ParticipatingLEID.push(tab.ParticipatingLEID?.toString());
        TypeOfEntityInformationEntityId.push(tab.participatingEntity?.ENTITYID.toString()! || '');
        TypeOfEntityInformationEntityNameBusinessNameLine1.push(tab.participatingEntity?.ENTITY.toString()! || '');
        TypeOfEntityInformationEntityEIN.push(tab.participatingEntity?.EIN === undefined ? '' : tab.participatingEntity?.EIN?.toString()! || '');
        TypeOfEntityInformationMissingEINReason.push((typeof tab.typeOfEntityInformationMissingEINReason !== 'object') ? tab.typeOfEntityInformationMissingEINReason?.toString() : '');
        TypeOfEntityInformationScheduleK1ReceivedDate.push(tab.typeOfEntityInformationScheduleK1RcvDate?.toString());
        TypeOfEntityInformationNoScheduleK1Received.push(tab.typeOfEntityInformationScheduleK1RcvDate === '' ? tab.typeOfEntityInformationNoScheduleK1Received?.toString() : '');
      });

      const PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee: string[] = []; //6
      const PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee: string[] = []; //6
      const PersonsYouPaidAFeeInfoEINNumberPersonPaidFee: string[] = []; //6
      const PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee: string[] = []; //6
      const PersonsYouPaidAFeeInfoFeesPaid: string[] = []; //6
      // const USForeignFeesRad: string[] = []; //6
      const USForeignFees: string[] = []; //6
      const PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1: string[] = []; //6
      const PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2: string[] = []; //6
      const PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity: string[] = []; //6
      const PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState: string[] = []; //6
      const PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry: string[] = []; //6
      const PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode: string[] = []; //6
      this.partFiveTabs.forEach((tab: any) => {
        PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee.push(tab.personsYouPaidAFeeInfoNameOfPersonYouPaidFee?.toString());
        PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee.push(tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee?.toString());
        PersonsYouPaidAFeeInfoEINNumberPersonPaidFee.push(tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee?.toString());
        PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee.push((typeof tab.personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee !== 'object') ? tab.personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee?.toString() : '');
        PersonsYouPaidAFeeInfoFeesPaid.push(tab.personsYouPaidAFeeInfoFeesPaid?.toString());
        // USForeignFeesRad.push(tab.USFeesRad === true ? 'US': (tab.ForeignFeesRad === true ? 'FOREIGN': ''));
        USForeignFees.push(tab.USFeesRad === true ? 'US' : (tab.ForeignFeesRad === true ? 'FOREIGN' : ''));
        PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1.push(tab.USFeesRad === true ? tab.personsYouPaidAFeeInfoAddressLine1?.toString() : '');
        PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2.push(tab.USFeesRad === true ? tab.personsYouPaidAFeeInfoAddressLine2?.toString() : '');
        PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity.push(tab.USFeesRad === true ? tab.personsYouPaidAFeeInfoCity?.toString() : '');
        PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState.push(tab.USFeesRad === true ? ((typeof tab.personsYouPaidAFeeInfoState !== 'object') ? tab.personsYouPaidAFeeInfoState?.toString() : '') : '');
        PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode.push(tab.USFeesRad === true ? tab.personsYouPaidAFeeInfoZip?.toString() : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1.push(tab.ForeignFeesRad === true ? tab.personsYouPaidAFeeInfoAddressLine1?.toString() : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2.push(tab.ForeignFeesRad === true ? tab.personsYouPaidAFeeInfoAddressLine2?.toString() : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity.push(tab.ForeignFeesRad === true ? tab.personsYouPaidAFeeInfoCity?.toString() : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState.push(tab.ForeignFeesRad === true ? tab.personsYouPaidAFeeInfoProvinceOrState?.toString() : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry.push(tab.ForeignFeesRad === true ? ((typeof tab.personsYouPaidAFeeInfoCountry !== 'object') ? tab.personsYouPaidAFeeInfoCountry?.toString() : '') : '');
        PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode.push(tab.ForeignFeesRad === true ? tab.personsYouPaidAFeeInfoZip?.toString() : '');
      });

      const IdentifyAllInvolvedInTrTaxExempt: string[] = []; //8a
      const IdentifyAllInvolvedInTrForeign: string[] = []; //8a
      const IdentifyAllInvolvedInTrRelated: string[] = []; //8a
      const InGOLD8: string[] = []; //8 radio button
      const IdentifyAllInvolvedInTrLEID: string[] = []; //8 leid
      const IdentifyAllInvolvedInTrEntityId: string[] = []; //8 entityid
      const IdentifyAllInvolvedInTrEntityName: string[] = []; //8b
      const IdentifyAllInvolvedInTrEntityEIN: string[] = []; //8c
      const IdentifyAllInvolvedInTrMissingEINReason: string[] = []; //8c missing ein
      const IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1: string[] = []; //8
      const IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2: string[] = []; //8
      const IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction: string[] = []; //8
      const IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction: string[] = []; //8
      const IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr: string[] = []; //8
      const USForPart8: string[] = []; //8
      const IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1: string[] = []; //8
      const IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2: string[] = []; //8
      const IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity: string[] = []; //8
      const IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState: string[] = []; //8
      const IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry: string[] = []; //8
      const IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode: string[] = []; //8
      const IdentifyAllInvolvedInTrDescriptionOfInvolvement: string[] = []; //8
      this.partSevenTabs.forEach((tab: any) => {
        IdentifyAllInvolvedInTrTaxExempt.push(tab.identifyAllInvolvedInTrTaxExempt === true ? '1' : '');
        IdentifyAllInvolvedInTrForeign.push(tab.identifyAllInvolvedInTrForeign === true ? '1' : '');
        IdentifyAllInvolvedInTrRelated.push(tab.identifyAllInvolvedInTrRelated === true ? '1' : '');
        InGOLD8.push(tab.inGOLDYes === true ? '1' : (tab.inGOLDNo === true ? '0' : ''));
        IdentifyAllInvolvedInTrLEID.push(tab.inGOLDYes === true ? tab.InvolvedTrLEID?.toString() : '');
        IdentifyAllInvolvedInTrEntityId.push(tab.inGOLDYes === true ? tab.involvedTrEntity?.ENTITYID.toString()! : '');
        IdentifyAllInvolvedInTrEntityName.push(tab.inGOLDYes === true ? tab.involvedTrEntity?.ENTITY.toString()! : '');
        IdentifyAllInvolvedInTrEntityEIN.push(tab.inGOLDYes === true ? (tab.involvedTrEntity?.EIN === undefined ? '' : tab.involvedTrEntity?.EIN?.toString()!) : '');
        IdentifyAllInvolvedInTrMissingEINReason.push(tab.inGOLDYes === true ? ((typeof tab.identifyAllInvolvedInTrMissingEINReason !== 'object') ? tab.identifyAllInvolvedInTrMissingEINReason?.toString() : '') : '');
        IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1.push(tab.inGOLDNo === true ? tab.nameInvolvedInTransactionBusinessNameLine1?.toString() : '');
        IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2.push(tab.inGOLDNo === true ? tab.identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2?.toString() : '');
        IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction.push(tab.inGOLDNo === true ? tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction?.toString() : '');
        IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction.push(tab.inGOLDNo === true ? tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction?.toString() : '');
        IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr.push(tab.inGOLDNo === true ? ((typeof tab.identifyAllInvolvedInTrMissingEINReasonInvolvedInTr !== 'object') ? tab.identifyAllInvolvedInTrMissingEINReasonInvolvedInTr?.toString() : '') : '');
        USForPart8.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? 'US' : (tab.ForeignForPart8Rad === true ? 'FOREIGN' : '')) : '');
        IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? tab.identifyAllInvolvedInTrAddressLine1?.toString() : '') : '');
        IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? tab.identifyAllInvolvedInTrAddressLine2?.toString() : '') : '');
        IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? tab.identifyAllInvolvedInTrCity?.toString() : '') : '');
        IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? ((typeof tab.identifyAllInvolvedInTrState !== 'object') ? tab.identifyAllInvolvedInTrState?.toString() : '') : '') : '');
        IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode.push(tab.inGOLDNo === true ? (tab.USForPart8Rad === true ? tab.identifyAllInvolvedInTrPostalCode?.toString() : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? tab.identifyAllInvolvedInTrAddressLine1?.toString() : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? tab.identifyAllInvolvedInTrAddressLine2?.toString() : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? tab.identifyAllInvolvedInTrCity?.toString() : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? tab.identifyAllInvolvedInTrProvinceOrState?.toString() : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? ((typeof tab.identifyAllInvolvedInTrCountry !== 'object') ? tab.identifyAllInvolvedInTrCountry?.toString() : '') : '') : '');
        IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode.push(tab.inGOLDNo === true ? (tab.ForeignForPart8Rad === true ? tab.identifyAllInvolvedInTrPostalCode?.toString() : '') : '');
        IdentifyAllInvolvedInTrDescriptionOfInvolvement.push(tab.identifyAllInvolvedInTrDescriptionOfInvolvement?.toString() || '');
      });

      const jsonObject = {
        'TransferorLEID': this.transferor ? this.transferor?.LEID.toString() : '',
        'Business': this.transferor ? this.transferor?.BUSINESSID.toString() : '',
        'StatementNumber': this.formModel.statementNumber?.toString() || '', //A
        'TotalStatementNumber': this.formModel.totalStatementNumber?.toString() || '', //A
        'FormNumberOfTaxReturn': this.formModel.formNumberOfTaxReturn?.toString() || '', //B
        'ForTaxYear': this.formModel.forTaxYear?.toString() || '', //B year dropdown
        'AttachedToAmendedReturn': this.AttachedToAmendedReturn === true ? '1' : (this.AttachedToAmendedReturn === false ? '0' : ''), //B radio button
        'InitialYearFiler': this.formModel.initialYearFiler === true ? '1' : '', //C checkbox
        'ProtectiveDisclosure': this.formModel.protectiveDisclosure === true ? '1' : '', //C checkbox
        'ReportableTransactionInfoNameOfReportableTransaction': this.formModel.reportableTransactionInfoNameOfReportableTransaction?.toString() || '', //1a
        //'SecondaryEntityEX': '', //
        'ReportableTransactionInfoInitialYearParticipated': this.formModel.reportableTransactionInfoInitialYearParticipated?.toString() || '', //1b
        'ReportableTransactionInfoTransactionOrTaxShelterNumber': this.formModel.reportableTransactionInfoTransactionOrTaxShelterNumber?.toString() || '', //1c
        'Listed': this.formModel.listed === true ? '1' : '0', //2a
        'ContractualProtection': this.formModel.contractualProtection === true ? '1' : '', //2b
        'TransactionOfInterest': this.formModel.transactionOfInterest === true ? '1' : '', //2c
        'Confidential': this.formModel.confidential === true ? '1' : '', //2d
        'Loss': this.formModel.loss === true ? '1' : '', //2e
        'PublishedGuidanceNumber': this.formModel.publishedGuidanceNumber?.toString() || '', //3
        'NumberOfSameOrSimilarTr': this.formModel.numberOfSameOrSimilarTr?.toString() || '', //4
        'TypeOfEntityInformationPartnership': TypeOfEntityInformationPartnership, //5a
        'TypeOfEntityInformationSCorporation': TypeOfEntityInformationSCorporation, //5a
        'TypeOfEntityInformationTrust': TypeOfEntityInformationTrust, //5a
        'TypeOfEntityInformationForeign': TypeOfEntityInformationForeign, //5a
        'ParticipatingLEID': ParticipatingLEID, //5 leid
        'TypeOfEntityInformationEntityId': TypeOfEntityInformationEntityId, //5 entityid
        'TypeOfEntityInformationEntityNameBusinessNameLine1': TypeOfEntityInformationEntityNameBusinessNameLine1, //5b
        'TypeOfEntityInformationEntityEIN': TypeOfEntityInformationEntityEIN, //5c
        'TypeOfEntityInformationMissingEINReason': TypeOfEntityInformationMissingEINReason, //5c missing ein
        'TypeOfEntityInformationScheduleK1ReceivedDate': TypeOfEntityInformationScheduleK1ReceivedDate, //5d date
        'TypeOfEntityInformationNoScheduleK1Received': TypeOfEntityInformationNoScheduleK1Received, //5d
        'PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee': PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee, //6
        'PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee': PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee, //6
        'PersonsYouPaidAFeeInfoEINNumberPersonPaidFee': PersonsYouPaidAFeeInfoEINNumberPersonPaidFee, //6
        'PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee': PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee, //6
        'PersonsYouPaidAFeeInfoFeesPaid': PersonsYouPaidAFeeInfoFeesPaid, //6
        // 'USForeignFeesRad': USForeignFeesRad, //6
        'USForeignFees': USForeignFees, //6
        'PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1': PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1, //6
        'PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2': PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2, //6
        'PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity': PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity, //6
        'PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState': PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState, //6
        'PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode': PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry, //6
        'PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode': PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode, //6
        'Deductions': this.formModel.deductions === true ? '1' : '', //7a
        'ExclusionsFromGrossIncome': this.formModel.exclusionsFromGrossIncome === true ? '1' : '', //7a
        'CapitalLoss': this.formModel.capitalLoss === true ? '1' : '', //7a
        'NonrecognitionOfGain': this.formModel.nonrecognitionOfGain === true ? '1' : '', //7a
        'OrdinaryLoss': this.formModel.ordinaryLoss === true ? '1' : '', //7a
        'AdjustmentsToBasis': this.formModel.adjustmentsToBasis === true ? '1' : '', //7a
        'TaxCredits': this.formModel.taxCredits === true ? '1' : '', //7a
        'Deferral': this.formModel.deferral === true ? '1' : '', //7a
        'AbsenceOfAdjustmentsToBasis': this.formModel.absenceOfAdjustmentsToBasis === true ? '1' : '', //7a
        'Other': this.formModel.line7aOther === true ? '1' : '', //7a
        'OtherText': this.formModel.line7aOther === true ? this.formModel.line7aOtherText?.toString() : '', //7a textbox
        'TotalTaxBenefitAmt': this.formModel.totalTaxBenefitAmt?.toString() || '', //7b
        'TaxBnftAnticipatedYrCnt': this.formModel.taxBnftAnticipatedYrCnt?.toString() || '', //7c
        'TotalInvestmentOrBasisAmt': this.formModel.totalInvestmentOrBasisAmt?.toString() || '', //7d
        'IdentifyAllInvolvedInTrTaxExempt': IdentifyAllInvolvedInTrTaxExempt, //8a
        'IdentifyAllInvolvedInTrForeign': IdentifyAllInvolvedInTrForeign, //8a
        'IdentifyAllInvolvedInTrRelated': IdentifyAllInvolvedInTrRelated, //8a
        'InGOLD8': InGOLD8, //8 radio button
        'IdentifyAllInvolvedInTrLEID': IdentifyAllInvolvedInTrLEID, //8 leid
        'IdentifyAllInvolvedInTrEntityId': IdentifyAllInvolvedInTrEntityId, //8 entityid
        'IdentifyAllInvolvedInTrEntityName': IdentifyAllInvolvedInTrEntityName, //8b
        'IdentifyAllInvolvedInTrEntityEIN': IdentifyAllInvolvedInTrEntityEIN, //8c
        'IdentifyAllInvolvedInTrMissingEINReason': IdentifyAllInvolvedInTrMissingEINReason, //8c missing ein
        'IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1': IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1, //8
        'IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2': IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2, //8
        'IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction': IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction, //8
        'IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction': IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction, //8
        'IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr': IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr, //8
        'USForPart8': USForPart8, //8
        'IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1': IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1, //8
        'IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2': IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2, //8
        'IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity': IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity, //8
        'IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState': IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState, //8
        'IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode': IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry, //8
        'IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode': IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode, //8
        'IdentifyAllInvolvedInTrDescriptionOfInvolvement': IdentifyAllInvolvedInTrDescriptionOfInvolvement, //8
        //'decorator': dialog,
        //'dialogId': ststatementdialog1415405,
      };

      this.sharedService.saveStatemenet(params, jsonObject, this.file).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, (error: HttpErrorResponse) => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.loading = false;
      }).add(() => {
        this.loading = false;
      });

    }

  }

  cancel(): void {
    if (this.statementId !== '') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }
  

  fillLEID(leid: string | undefined,tabIndex: number,callback:(tabIndex:number, data:DSTEntityDetails[])=>void): void {
    if (!leid) {
      return;
    }
    this.loading = true;
    this.sharedService.getEntityList(leid)
      .subscribe((data: DSTEntityDetails[]) => {
        if(data.length > 0){
          callback(tabIndex,data);
        }
      }, (e: Error) => {
        this.dstSharedService.showAlert('error', e.message);
      })
      .add(() => {
        this.loading = false;
      });
  }

}
