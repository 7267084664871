<div class="statement-action-wrapper" [ngStyle]="{'min-width': mode === 'statementTracker' ? '130px' : '100px'}"
     dstClickOutside (clickOutside)="rowData.showDropdown = false; rowData.showExtendedMenu = false;">
  <span class="appkiticon hover-o" *ngIf="mode === 'statementTracker'" [ngClass]="getReviewClassName()"
        [ngStyle]="{'color': (rowData.isReviewedVal) ? ' green' : ' red', 'pointer-events': rowData.ignoredId !== 0 || rowData.statementId === '0' ? 'none' : 'unset'}"
        (click)="onToggleReview()">
  </span>
  <span class="hover-o" [ngStyle]="{'pointer-events': rowData.ignoredId && rowData.ignoredId !== 0 ? 'none' : 'unset'}"
        (click)="showViewUpdate()">{{getCreateBtnText()}}</span>
  <div class="st-dropdown-button">
    <span class="hover-o a-icon appkiticon icon-down-chevron-fill" getMenuPos [menuPosMode]="'fixed'"
          (menuClick)="showDropdown($event, rowData)"></span>
    <!-- Hide below sub componenet when grid has stmt id column freezed (ex: stmt trck) for dropdown hidding Issue fix (see stmt trck html for sub component addition)-->
    <dst-dropdown-menu *ngIf="mode !== 'statementTracker' && rowData.showDropdown" [rowObject]="rowData" [mode]="mode" [menuPos]="menuPos"
                       (taggingProject)="onTaggingProject()" (copyReplicate)="onCopyReplicate($event)"
                       (showUnmatchConfirm)="onShowUnmatchConfirm()" (showDeleteConfirm)="onShowDeleteConfirm()"
                       (matchTracker)="onMatchTracker()" (saveSuccess)="onSaveSuccess()">
    </dst-dropdown-menu>
    <dst-mark-as-template *ngIf="mode !== 'statementTracker' && rowData.showExtendedMenu" (showMarkOtherReason)="onShowMarkOtherReason()" [rowObject]="rowData"
                        [markAsOptions]="markAsOptions" [menuPos]="menuPos"></dst-mark-as-template>
  </div>
</div>
