<div class="main-container">
    <div class="header-container">

        <div class="title-container">
            <h1>Partnership Dashboard</h1>
        </div>

        <div class="action-items" style="justify-content: end;">

            <div class="entity-filter-container">

                <div class="entity-container" *ngIf="!hoLoading && !contentLoading && selectedPartnership.le_name">
                    <div class="entity-info">
                        <h2>{{selectedPartnership.le_name}}</h2>
                        <p>{{selectedPartnership.ein_num}} |
                            {{selectedPartnership.year_begin}}-{{selectedPartnership.year_end}}</p>
                    </div>
                </div>
            </div>
            <span style="font-weight: 700;cursor:pointer; margin-left:10px" class="filter-icon"
                (click)="showFilter()">Filter
                <span class=" appkiticon icon-filter-outline">
                    <i *ngIf="selectedFilterParams!=undefined" class="fa fa-circle"
                        style="font-size: 8px;color: #D04A02;padding-left: 2px;padding-bottom: 6px;padding-top: 0px;"></i>
                </span>
            </span>
        </div>

    </div>
    <div [hidden]="isShow">

        <gtw-dashboard-filter [filter-group-key]="701" [screen-key]="11588" [client-key]="clientKey"
            [base-urls]="baseURLs" [global-params]="globalParams" [tax-years]="taxYears"
            (close-dashboard-filter)="closeFilterPanel($event)"
            (loadDataTableEmitter)="saveFilters($event)"></gtw-dashboard-filter>
    </div>

    <app-loader [loaded]="!hoLoading" style="position: relative;top:25%">

    </app-loader>
    <div class="gg-loader" style="width: 100% !important;height: calc(100vh - 230px)!important;" *ngIf="hoLoading">
        <div class="gg-loader-text">
            <img src="assets/img/arrow-filter-sm.png" *ngIf="loading">
        </div>
    </div>
    <div class="main-content" *ngIf="!hoLoading">
        <div class="partnership-sidebar">
            <div class="partnership-container">
                <div class="pship-toggle">
                    <span style="margin-right: 5px;">Partnership</span>
                    <ap-toggle (onCheck)="switch($event)" class="a-m-5" [disabled]="true">
                        Partners
                    </ap-toggle>
                </div>
                <hr class="dropdown-divider">
                <div class="list-section">
                    <div class="list-container-left">
                        <p-listbox [options]="partnerships" [(ngModel)]="selectedPartnership"
                            (onChange)="selectPartnership($event)">
                            <ng-template let-partnership pTemplate="item">
                                <div class="partnership-item">
                                    <div>
                                        <span *ngFor="let key of objectKeys(partnership.value.leidMap)">
                                            {{key}}
                                        </span>
                                    </div>
                                    <!-- <span class="appkiticon icon-right-chevron-outline"></span> -->
                                </div>
                            </ng-template>
                        </p-listbox>
                    </div>
                    <div class="list-container-right">
                        <p-listbox [options]="partnersOptions" [(ngModel)]="selectedPartner" [disabled]="true">
                            <ng-template let-partner pTemplate="item">
                                <div class="partner-item">
                                    <div>
                                        {{partner.label}}%
                                    </div>
                                </div>
                            </ng-template>
                        </p-listbox>
                    </div>

                </div>
            </div>

        </div>

        <div class="content">
            <app-loader [loaded]="!contentLoading" style="position: fixed;top:25%;left:55%;">

            </app-loader>
            <div *ngIf="!contentLoading">
                <div class="financial-cards">
                    <div class="cards-spacing card-details">

                        <div id="ti" class="card-header-cust" (click)="selectCard($event)"
                            (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)"
                            [class.clickable-cursor]="isClickable">
                            <div class="taxable-icon">
                                <i class="pi pi-money-bill" style="font-size: 1rem; color: #175C2C;"></i>
                            </div>

                            <span style="font-weight: 500 !important;"> Taxable Income</span>
                        </div>
                        <div class="card-content">
                            <div class="content-row">
                                <span>Partnership</span>
                                <span>{{ti_card_details.total_amt | numberWithCommas }}</span>
                            </div>
                            <div class="content-row">
                                <span>Flowthrough</span>
                                <span>{{ti_card_details.total_flowthru_amt | numberWithCommas }}</span>
                            </div>
                            <div class="content-row">
                                <span>Exclusion</span>
                                <span>{{ti_card_details.total_exclusion_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>External</span>
                                <span>{{ti_card_details.total_ext_ptr_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Variance</span>
                                <span>{{ti_card_details.variance | numberWithCommas}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="cards-spacing card-details">

                        <div id="creds" class="card-header-cust" (click)="selectCard($event)"
                            (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)"
                            [class.clickable-cursor]="isClickable">
                            <div class="credits-icon">
                                <i class="pi pi-credit-card" style="font-size: 1rem; color: #003DAB;"></i>
                            </div>
                            <span> Credits</span>
                        </div>
                        <div class="card-content">
                            <div class="content-row">
                                <span>Partnership</span>
                                <span>{{credits_card_details.total_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Flowthrough</span>
                                <span>{{credits_card_details.total_flowthru_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Exclusion</span>
                                <span>{{credits_card_details.total_exclusion_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>External</span>
                                <span>{{credits_card_details.total_ext_ptr_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Variance</span>
                                <span>{{credits_card_details.variance | numberWithCommas}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cards-spacing card-details">

                        <div id="source" class="card-header-cust" (click)="selectCard($event)"
                            (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)"
                            [class.clickable-cursor]="isClickable">
                            <div class="sourcing-icon">
                                <i class="pi pi-box" style="font-size: 1rem; color: #141414;"></i>
                            </div>

                            <span> Sourcing</span>
                        </div>
                        <div class="card-content">
                            <div class="content-row">
                                <span>Partnership</span>
                                <span>{{sourcing_card_details.total_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Flowthrough</span>
                                <span>{{sourcing_card_details.total_flowthru_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Exclusion</span>
                                <span>{{sourcing_card_details.total_exclusion_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>External</span>
                                <span>{{sourcing_card_details.total_ext_ptr_amt | numberWithCommas}}</span>
                            </div>
                            <div class="content-row">
                                <span>Variance</span>
                                <span>{{sourcing_card_details.variance | numberWithCommas}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-table">

                    <p-table #dt [columns]="cols" [value]="tableData" [scrollable]="true" scrollHeight="600px">

                        <ng-template pTemplate="caption" style="background: #FFFFFF !important">
                            <span style="vertical-align: middle;">
                                {{gridTitle}}
                            </span>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Partnership Entity</th>
                                <th>Tax Code</th>
                                <th>Reporting Period</th>
                                <th>Business Unit</th>
                                <th style="text-align: right;">Partnership Total</th>
                                <th style="text-align: right;">Internal Partner Flowthrough</th>
                                <th style="text-align: right;">Exclusion</th>
                                <th style="text-align: right;">External Partner Flowthrough</th>
                                <th style="text-align: right;">Variance</th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{item.LEID}}</td>
                                <td>{{item.CDR_NO}}</td>
                                <td>{{item.REPORTING_PERIOD}}</td>
                                <td>{{item.ME_CODE}}</td>
                                <td style="text-align: right;">{{item.TOTAL_PSHIP_AMT | numberWithCommas}}</td>
                                <td style="text-align: right;">{{item.TOTAL_FLOWTHRU_AMT | numberWithCommas }}</td>
                                <td style="text-align: right;">{{item.TOTAL_EXCLUSION_AMT | numberWithCommas}}</td>
                                <td style="text-align: right;">{{item.TOTAL_EXT_PTR_AMT | numberWithCommas}}</td>
                                <td style="text-align: right;">{{item.VARIANCE | numberWithCommas}}</td>

                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </div>



        </div>
    </div>
</div>