<div class="loader-overlay" *ngIf="saving">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<div class="top-action-bar">
  <ap-button [btnText]="'Go Back'" [config]="{isPrimary: true}" (onBtnClick)="cancel()">
  </ap-button>
</div>
<div class="form-template-wrapper">
  <div class="bulk-supersede">
    <div class="title">DST Bulk Supersede Upload</div>
    <div class="statements-container a-mt-30">
      <div class="dnd-text-info">
        Drag and drop the file disclosure data to be converted to XML into the box below. Only {{fileAcceptShort.join(',')}} files are accepted.
      </div>
      <div class="statement-details" *ngFor="let s of statements" [ngClass]="{'error': isValidPdf === false}"
           dragAndDropUpload [maxLength]="1" [allowedExts]="fileAcceptShort" [disabled]="!s.isEdit"
           (maxLenExceeded)="onMaxLenExceeded()" (extNotMet)="onExtNotMet()" (filesDropped)="onFilesDropped($event, s)">
        <div class="edit-box">
          <ap-button [config]="{isPrimary: true}" [btnSize]="'small'" (onBtnClick)="toggle(s)">
            <span class="appkiticon a-btn-icon icon-pencil-fill" *ngIf="!s.isEdit"></span>
            <span class="appkiticon a-btn-icon icon-end-process-fill" *ngIf="s.isEdit"></span>
            {{s.isEdit ? 'Close' : 'Edit'}}
          </ap-button>
        </div>
        <table>
          <thead>
          <tr>
            <th>Statement Id</th>
            <th>Primary LEID</th>
            <th>Branch Entity</th>
            <th style="width: 30%;">File Name</th>
            <th>Provided By</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{s.STATEMENTID}}</td>
            <td>
              <span *ngIf="!s.isEdit">{{s.PRIMARYLEID}}</span>
              <dst-template-leid-selector *ngIf="s.isEdit" [preloadLEID]="s.PRIMARYLEID" [label]="''" [labelId]="'1'"
                                          (onSelectedLEChanged)="onPrimaryEntityChanged($event, s)">
              </dst-template-leid-selector>
            </td>
            <td>
              <span *ngIf="!s.isEdit">{{s.BRANCHENTITY}}</span>
              <dst-template-leid-selector *ngIf="s.isEdit" [preloadLEID]="s.BRANCHENTITY" [isBranchEntity]="true" [label]="''" [labelId]="'1'"
                                          (onSelectedLEChanged)="onBranchEntityChanged($event, s)">
              </dst-template-leid-selector>
            </td>
            <td>
              <span *ngIf="!s.hideFileName">{{s.FILENAME}}</span>
              <span class="appkiticon icon-delete-fill" style="margin-left: 10px" *ngIf="s.isEdit" (click)="delete(s)"></span>
            </td>
            <td>{{s.PROVIDEDBY}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
