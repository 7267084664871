import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { EFileService } from "../efile.service";
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';
import { Subscription } from "rxjs";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { tableColumnConfig, taxYears, actionsDropDown, returnTypes } from "./dataStub";
import { TableTitle } from "../models/table.model";
import { UploadIRS, UploadRequest } from "../models/upload-irs.model";

@Component({
  selector: 'efile-upload-irs',
  templateUrl: './upload-irs.component.html',
  styleUrls: ['./upload-irs.component.scss']
})
export class UploadIRSComponent implements OnInit, OnDestroy {

  selectedIRS: UploadIRS[] = [];
  returnTypes= returnTypes; //todo: create dropdown list model
  baseURLs: GtwElementsBaseUrlsModel = {
    api: '/gtw',
    custom: '/custom',
    admin: '/admin',
    efile:'/gtw-efile-api',
    pdfEngine: '/pdf-engine',
    bulkPdfEngine: '/bulk-pdf-engine'
  };
  isDataLoaded: boolean = false;
  page: number = 1;
  pageSize: number = 7;
  tableSorting: boolean = false;
  tableSearch: string = '';
  total: number = 19;
  taxYear: string = '2020';
  startLoader: boolean = false;
  irs: UploadIRS[] = [];
  irsSafeCopy: UploadIRS[] = [];
  primaryConfig = {
    isPrimary: true
  }
  tableColumnConfig: TableTitle[] = tableColumnConfig;
  taxYears = taxYears;
  actionsDropDown = actionsDropDown;
  $subscription!: Subscription;
  fileUploadConfig: any = {
    multiple: false,
    size: false,
    trigger: true,
    isGreyBackground: true
  };
  @ViewChild('baseModal', { static: false }) baseModal: any;
  taxYearOnModal: string = '2020';
  returnTypeOnModal: string = '7004';
  uploadParams:UploadRequest ={
    filingYear: this.taxYearOnModal,
    formType: this.returnTypeOnModal,
    schemaVersion:undefined,
    schemaZipFile:''
  };
  headers:Array<{name:string,value:string}> = [
    // {
    //   "name":"client_key",
    //   "value":"0"
    // }
  ];
  uploadUrl:string = '';


  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let urls: GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
      if (Object.keys(urls).length > 0) {
        this.baseURLs = urls;
        this.uploadUrl = `${this.baseURLs.efile}/loadSchema`;
        this.efileService.setBaseUrls(this.baseURLs);
        this.loadUploadIRSData();
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Input('client-key')
  clientKey: number = 0;

  constructor(private efileService: EFileService) { }

  ngOnInit(): void {
    // this.baseModal.closeModal('upload-modal');
    
  }

  showModal() {
    this.baseModal.showMore('upload-modal');
    this.taxYearOnModal = this.taxYear;
  }
  closeModal(id: string) {
    this.baseModal.closeModal(id);
  }

  closeModalOk(id: string) {
    this.baseModal.closeModalOk(id);
  }

  modalDetermine() {
    console.log("click ok");
  }

  modalClose() {
    console.log("click close");
  }

  fileUpload(event: any){
    if(event.fileInfoList.length > 0 ){
      let response = JSON.parse(event.fileInfoList[0]);
      if(response.callSuccess === '1'){
        this.closeModal('upload-modal');

        this.loadUploadIRSData();
      }
    }
    
  }
  resolveData(data: UploadIRS[]) {
    this.irs = data;
    this.irsSafeCopy = [...this.irs];
    this.pageSize = this.irs.length;
    this.isDataLoaded = true;
    this.startLoader = false;
  }

  loadUploadIRSData(): void {
    this.startLoader = true;
    this.isDataLoaded = false;
    this.$subscription = this.efileService.uploadIRSData(
      this.clientKey,
      this.taxYear).subscribe(
        (data: UploadIRS[]) => this.resolveData(data),
        (error: HttpErrorResponse) => this.handleError(error));
  }

  handleError(error: HttpErrorResponse): void {
    console.log(error)
  }

  taxYearSelected(event: any): void {
    console.log(event);
    this.taxYear = event.change.value;
  }
  modalTaxYearSelected(event: any): void {
    console.log(event);
    this.taxYearOnModal = event.change.value;
    this.uploadParams.filingYear = event.change.value;
  }
  returnTypeSelected(event: any): void {
    console.log(event);
    this.returnTypeOnModal = event.change.value;
    this.uploadParams.formType = event.change.value;
  }

  filterClick(): void {
    this.loadUploadIRSData();
  }

  onActionSelected(event: any): void {
    console.log(event);
  }

  tableSearchFun(event: { query: string }): void {
    this.page = 1;
    this.tableSearch = event.query;
    this.irs = this.filterBase(this.irsSafeCopy, this.tableSearch);
  }

  filterBase(data: UploadIRS[], tableSearch: string): UploadIRS[] {
    const returnData = data.filter((item: UploadIRS | any) => {
      const keys = Object.keys(item);
      return keys.some(key => {
        let itemKey = item[key];
        if (itemKey === undefined) {
          return false;
        }
        if (typeof (item[key]) !== 'string') {
          itemKey = item[key].toString();
        }

        return itemKey.toLowerCase().indexOf(tableSearch.toLowerCase()) !== -1;
      })
    });
    return returnData;
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

}