import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DiscModalViewerComponent} from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {DfMeDropdownComponent} from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import {Table} from 'primeng-lts/table';
import {CreateDisclosureService, DSTTaxYear} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {Subscription, zip} from 'rxjs';
import {DSTSharedService} from '../../shared/dst-shared.service';
import {StmtIdBtnService} from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {JSONExportService} from '../../../shared/_services/JSONExport.service';
import {ReportsService} from '../reports.service';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTChildFormState} from '../../shared/models/dst-child-form-state.model';

@Component({
  selector: 'gtw-form8594-report',
  templateUrl: './form-8594-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './form-8594-report.component.scss']
})
export class Form8594ReportComponent implements OnInit {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;

  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTID',
      header: 'Statement Id',
      hidden: false,
      width: '145px'
    },
    {
      field: 'PROJECT_NAME',
      header: 'Project',
      hidden: false,
      width: '105px'
    },
    {
      field: 'PRIMARYLEID', //
      header: 'Purchaser or Seller LEID',
      hidden: false,
      width: '120px'
    },
    {
      field: 'PRIMARYENTITY', //
      header: 'Purchaser or Seller Name',
      hidden: false,
      width: '180px'
    },
    {
      field: 'PURCHASERORSELLER_VAL', //
      header: 'Indicate if Purchaser or Seller',
      hidden: false,
      width: '120px'
    },
    {
      field: 'FILING_GROUP',
      header: 'Filing Group',
      hidden: false,
      width: '145px'
    },
    {
      field: 'SECONDARYLEID', //
      header: 'Other Party Name',
      hidden: false,
      width: '175px'
    },
    {
      field: 'EINOFOTHERPARTY1_VAL', //
      header: 'Other Party EIN',
      hidden: false,
      width: '180px'
    },
    {
      field: 'EIN_VAL', //
      header: 'Other Party Missing EIN Reason',
      hidden: false,
      width: '170px'
    },
    {
      field: 'ADDRESSOFOTHERPARTY1_VAL', //
      header: 'Address Line 1',
      hidden: false,
      width: '275px'
    },
    {
      field: 'ADDRESSOFOTHERPARTY2_VAL', //
      header: 'Address Line 2',
      hidden: false,
      width: '236px'
    },
    {
      field: 'CITYOFOTHERPARTY_VAL', //
      header: 'City',
      hidden: false,
      width: '200px'
    },
    {
      field: 'STATE_US_VAL', //
      header: 'US State (if applicable)',
      hidden: false,
      width: '155px'
    },
    {
      field: 'COUNTRY1_VAL', //
      header: 'Country Code',
      hidden: false,
      width: '155px'
    },
    {
      field: 'ZIPCODE5_VAL', //
      header: 'Postal Code',
      hidden: false,
      width: '155px'
    },
    {
      field: 'DATEOFSALE_VAL', //
      header: 'Sales Date',
      hidden: false,
      width: '125px'
    },
    {
      field: 'TOTALSALESPRICE_VAL', //
      header: 'Sales Price',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS1FMV_VAL', //
      header: 'FMV Class I',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS2FMV_VAL', //
      header: 'FMV Class II',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS3FMV_VAL', //
      header: 'FMV Class III',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS4FMV_VAL', //
      header: 'FMV Class IV',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS5FMV_VAL', //
      header: 'FMV Class V',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS6N7FMV_VAL', //
      header: 'FMV Class VI & VII',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'TOTALFMV_VAL', //
      header: 'Total FMV',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS1ASP_VAL', //
      header: 'Allocation Class I',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS2ASP_VAL', //
      header: 'Allocation Class II',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS3ASP_VAL', //
      header: 'Allocation Class III',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS4ASP_VAL', //
      header: 'Allocation Class IV',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS5ASP_VAL', //
      header: 'Allocation Class V',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CLASS6N7ASP_VAL', //
      header: 'Allocation Class VI & VII',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'TOTALASP_VAL', //
      header: 'Total Allocation',
      hidden: false,
      width: '170px',
      format: 'number'
    },
    {
      field: 'CHOICE204A_VAL', //
      header: 'Question 5: Sales Contract or Written Document (Y/N)',
      hidden: false,
      width: '170px'
    },
    {
      field: 'CHOICE204B_VAL', //
      header: 'If Yes, Aggregate FMV specified agrees to Sales Contract? (Y/N)',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE204C_VAL', //
      header: 'Question 6: Purchaser also purchase license/convenant not to compete, or enter into lease agreement, employment contract, etc with seller? (Y/N)',
      hidden: false,
      width: '300px'
    }
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
              private dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private trackerTabsService: TrackerTabsService,
              private dCRService: DSTDynamicComponentResolverService,
              private jsonExportService: JSONExportService,
              private reportsService: ReportsService) {

    this.excelExportFileName = 'Reports-8594_Report_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setDSTUser('400394467', 2);
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
    // this.dstSharedService.setTaxYearScenario('2020', '40');
  }

  ngOnInit(): void {
    // this.dstSharedService.setDSTUser('400394467', 5);
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2020');
    //   this.loadReport();
    // });
    // end of local test only
    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          // this.setScenarios(param.taxYear);
          this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    console.log('Selected M/Es:- ' + this.selectedMes);
    this.reportsService.loadForm8594Report(this.taxYear?.text.toString()!, this.selectedMes || '').subscribe((data: any) => {
      this.data = data;
      this.data?.forEach((element) => { //params for opening statement
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;
      });
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the 8594 Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.dstSharedService.setTableActionStatus(true);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  exportToExcel(): void {
    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, this.cols);
  }
}
