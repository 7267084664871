import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec367b-id195',
  templateUrl: './template-sec367b-id195.component.html',
  styleUrls: ['../template-shared.scss', './template-sec367b-id195.component.scss']
})
export class TemplateSec367bId195Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form195!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  beginDate!: Date;
  endDate!: Date;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  TransferDate: any = '';
  additionaltext: string = '';
  showDREMsg: boolean = false;
  DREMsg: string = '';
  entityNameChangeOnDate: boolean = false;
    nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.editMode = false;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form195 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        TransferDate: [''],
        additionaltext: ['']
      }),
    });
    if (this.sharedService.getFormStateByFormId(195)?.isChild) {
      this.sharedService.getLoadStatementData(195).subscribe((data: StmtData) => {
        this.sharedService.setFormData(data, 195);
        this.loadStmtData();
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      });
    } else {
      this.loadStmtData();
    }
  }

  ngAfterViewInit(): void {
  }

  private loadStmtData(): void {
    const data: StmtData | undefined = this.sharedService.getFormData(195);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.editMode = true;

      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.secondaryleid);
              break;
            case 1:
              element.fillLEID(data.primaryleid);
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      },1000);

      this.populateFields(data);

    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;
      setTimeout(() => {
        this.leidComponents.toArray()[1].fillLEID(this.sharedService.leid);
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      }
    });
    if (this.attMap['additionaltext'] && this.attMap['additionaltext'][0]) {
      this.additionaltext = this.attMap['additionaltext'][0];
    }

    if (this.attMap['TransferDate'] && this.attMap['TransferDate'][0] && this.attMap['TransferDate'][0] !== '') {
      this.TransferDate = new Date(this.attMap['TransferDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    }
    this.cdref.detectChanges();
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.TransferDate = event.startDate.format('YYYY-MM-DD');

        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.entityNameChangeOnDate = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.sharedService.getTransDateStr(this.TransferDate,1),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });

        break;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.TransferDate && this.transferor?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.sharedService.getTransDateStr(this.TransferDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferor!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferor!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.TransferDate) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    if (!selectedTransferor) {
      return;
    }

    if (this.TransferDate && this.transferee?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.sharedService.getTransDateStr(this.TransferDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferee!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferee!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.TransferDate) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferee, this.transferor])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': '',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'attachmentFileName': '',
      };
      const jsonObject = {
        'TransferDate': this.TransferDate !== null && this.TransferDate !== '' ? this.TransferDate.startDate ? this.TransferDate.startDate.format('YYYY-MM-DD') : this.TransferDate : '',
        'additionaltext': this.additionaltext.toString()
      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }

  }

  isFormValid(plceholder: boolean): boolean {
    if (plceholder) {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
    } else {
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
    }
    return true;
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }
}


