<mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTabChange($event)">
  <mat-tab label="Submission Dashboard">
    <ng-template mat-tab-label>
      <span>Submissions</span>
      <span class="appkiticon icon-hb-full"></span>
    </ng-template>
    <div class="a-pl-10 a-pr-10 content-box">
      <div class="a-pb-5 a-pt-5 filters">
        <span class="appkiticon icon-refresh-outline"></span>
        <ap-selector [items]="taxYears" [placeholder]="taxYear" [type]="'Dropdown'" (onSelectedChange)="taxYearSelected($event)"
          placeholder="Tax Years">
        </ap-selector>

        <ap-button [btnText]="'Filter'" class="a-mr-10" [config]="primaryConfig" (onBtnClick)="filterClick()"
          [btnSize]="'small'">
        </ap-button>

        <div [hidden]="!isDataLoaded">
          <span>No. of Submissions: {{submissions.length}}.</span>
          <ap-search [isPrimary]="true" [isBase]="true" (onKeyUp)="tableSearchFun($event)" [minLength]="1"
            placeholder="Search">
          </ap-search>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <ap-loading *ngIf="startLoader" class="loader-class" [colorName]="'a-primary'">
        </ap-loading>
      </div>
      <!-- <ap-panels-cards>
              
          </ap-panels-cards> -->
      <div class="table dark">
        <!-- <div class="text-left">
                  <div class="a-p-20">
                      <ap-selector [items]="TaxYears" [type]="'Dropdown'" (onSelectedChange)="taxYearSelected($event)" placeholder="Tax Years">
                      </ap-selector>
                  </div>
              </div> -->
        <h3 *ngIf="!isDataLoaded && !startLoader" class="a-h3 center-header">
          Please select the filters to load submissions.
        </h3>
        <div *ngIf="isDataLoaded" class="table-content">
          <ap-table #submissionsTable [data]="submissions" [showPage]="false" [pageSize]="pageSize" [striped]="true">
            <thead class="a-thead">
              <!-- <tr>
                <th
                  *ngFor="let thItem of tableColumnConfig"
                  [sort]="thItem.sort"
                >
                  <span>{{ thItem.title }}</span>
                </th>
              </tr> -->
              <tr>
                <th *ngFor="let thItem of tableColumnConfig" attr.rowspan="{{thItem.title ? 1 : 2}}"
                  [hidden]="thItem.hidden"
                  class="text-center" 
                  [attr.colspan]="thItem.colSpan"
                  [sort]="thItem.sort">
                  <span  >{{ thItem.title ? thItem.title : thItem.colHeader }}</span>
                </th>
              </tr>
              <tr>
                <th *ngFor="let thItem of tableColumnConfig" [sort]="thItem.sort" [hidden]="!thItem.title && !thItem.hidden" >
                  <span>{{ thItem.colHeader }}</span>
                </th>
              </tr>
            </thead>

            <tbody class="a-tbody tbody-regular tbody-hover">
              <tr *ngFor="let item of submissionsTable.data">
                <td>
                  {{ item.SUBMISSION_ID }}

                  <span class="a-dropdown down header-dropdown" [ngClass]="{ 'a-show': item.SHOW_DROPDOWN_MENU }">
                    <span class="appkiticon icon-vertical-more-outline"
                      (click)="item.SHOW_DROPDOWN_MENU = !item.SHOW_DROPDOWN_MENU"></span>
                    <div class="a-dropdown-menu">
                      <ng-container>
                        <div class="a-dropdown-item align-items-center" [ngClass]="{ active: item.active }"
                          (click)="switchToTab(item);">
                          <span class="appkiticon icon-bar-chart-outline"></span>
                          View Dashboard
                        </div>
                        <div class="a-dropdown-item align-items-center" [ngClass]="{ active: item.active }">
                          <span class="appkiticon icon-building-outline"></span>
                          View Entities
                        </div>
                        <div class="a-dropdown-item align-items-center" [ngClass]="{ active: item.active }">
                          <span class="appkiticon icon-table-data-outline"></span>
                          View Data
                        </div>
                        <div class="a-dropdown-item align-items-center" [ngClass]="{ active: item.active }">
                          <span class="appkiticon icon-login-outline"></span>
                          Submit to IRS
                        </div>
                      </ng-container>
                    </div>
                  </span>
                </td>
                <td>{{ item.RETURN_TYPE }}</td>
                <!-- <td>{{ item.JURISDICTION }}</td> -->
                <td class="hyper-link" (click)="switchToTab(item);">{{ item.TAX_PAYER_NAME }}</td>
                <td>{{ item.TAX_PAYER }}</td>
                <td>{{ item.ENTITY_COUNT }}</td>
                <td cellHighlight [value]="item.EXTENSION_ERROR_COUNT">
                  {{ item.EXTENSION_ERROR_COUNT }}
                </td>
                <!-- <td cellHighlight [value]="item.Diagnostics">
                  {{ item.Diagnostics }}
                </td> -->
                <td>{{ item.E_CREATED_TS }}</td>
                <td>{{ item.E_SUBMISSION_STATUS }}</td>
                <td>{{ item.E_CREATED_BY }}</td>
                <td>{{ item.CREATED_TS }}</td>
                <td cellHighlight [value]="item.RETURN_ERROR_COUNT">
                  {{ item.RETURN_ERROR_COUNT }}
                </td>
                <td>{{ item.SUBMISSION_STATUS }}</td>
                <td>{{ item.CREATED_BY }}</td>
              </tr>
            </tbody>
          </ap-table>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab class="closable-tab" label="{{ submission.TAX_PAYER_NAME }}"
    *ngFor="let submission of selectedSubmissions;let i = index" (click)="selectedTab = i + 1">
    <ng-template mat-tab-label>
      <span class="tab-title" [tooltip-container]="submission.TAX_PAYER_NAME" [placement]="'bottom'">{{
        submission.TAX_PAYER_NAME }} ({{ submission.TAX_PAYER }})</span>
      <!-- <mat-icon
        class="material-icons"
        
        >{{ submission.ICON }}</mat-icon
      > -->
      <span class="appkiticon" [ngClass]="submission.ICON" (mouseover)="submission.ICON = 'icon-close-outline'"
        (mouseout)="submission.ICON = 'icon-hb-full'" (click)="
          selectedSubmissions.splice(selectedSubmissions.indexOf(submission), 1)
        "></span>
    </ng-template>
    <submission-detail [submission]="submission" [taxYear]="taxYear"></submission-detail>

  </mat-tab>
</mat-tab-group>