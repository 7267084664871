import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveParams } from './posting-partners.component';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

@Injectable({
	providedIn: 'root'
})
export class PostingPartnersService {

	url = {
		saveCurrent: '/saveJsonObject?action_code=qp6c2b',
		getTotalUrl: '/loadJsonObject?action_code=0vsgl5'
	}

	constructor(private httpClient: HttpClient, private http: HttpClient, private gtwSnackbarService: GtwSnackbarService) { }

	saveLineChanges(baseURL: string, params: saveParams, jsonSettings: saveParams, jsonData: any, ssoId: string, isIssueKeyEnabled: any, issueKey: any, scenarioCode: any) {

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([jsonSettings]));
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year ? params.tax_year.toString() : '');
		body.append('sso_id', ssoId);
		body.append('scenario', params.scenario ? params.scenario.toString() : '');
		body.append('jcd_key', params.jcd_key ? params.jcd_key.toString() : '');
		body.append('process_name', 'Save_Is_Current');
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		// body.append('issue_key', issueKey.toString());
		body.append('scenarioCode', scenarioCode);


		const url = `${baseURL}${this.url.saveCurrent}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					if (res.errorMessage != "null") {
						this.gtwSnackbarService.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.gtwSnackbarService.openSnackBar("Data Saved Successfully", 'gtw-snackbar--green');
					return res;
				} else {
					this.gtwSnackbarService.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					return "error";
				}
				throwError(new Error('oops!'));
				return null;
			}
			));
	}
	getTransactionCount(baseUrl: string, params: any): any {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers = headers.append('withCredentials', 'true');
		let httpParams = new HttpParams();
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				httpParams = httpParams.set(key, params[key]);
			}
		}
		// httpParams = httpParams.set("pship_cc_key", pshipCCKey);
		const totalUrl = `${baseUrl}${this.url.getTotalUrl}`;
		return this.httpClient.get(totalUrl, { params: httpParams, headers: headers });
	}
}
