<mat-progress-bar *ngIf="isLoading"
                  color="primary"
                  mode="indeterminate">
</mat-progress-bar>

<div class="max-height">
  <div class="a-p-20">

    <ap-table #table *ngIf="!isLoading" [data] = "firstRow" [showPage]="false" [pageSize]="firstRow.length" [striped]="false" >
      <thead class="a-thead">
        <tr>
          <th><span>LEID</span></th>
          <th><span>Tax Code</span></th>
          <th><span>Reporting Period</span></th>
          <th><span>Entity Name</span></th>
          <th><span>Tax Type</span></th>
          <th><span>ME Code</span></th>
          <th><span>Currency</span></th>
          <th><span>HO LEID</span></th>
          <th><span>HO Tax Code</span></th>
          <th><span>HO Reporting Period</span></th>
          <th><span>HO Entity Name</span></th>
          <th><span>HO Tax Type</span></th>
          <th><span>Tax Year Begin</span></th>
          <th><span>Tax Year End</span></th>
          <th><span>Source System Flag</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of firstRow">
          <td>{{ item.LEID }}</td>
          <td>{{ item.CDR_NO }}</td>
          <td>{{ item.REPORTING_PERIOD }}</td>
          <td>{{ item.LE_NAME }}</td>
          <td>{{ item.LE_TAX_TYPE }}</td>
          <td>{{ item.ME_CODE }}</td>
          <td>{{ item.CURR }}</td>
          <td>{{ item.HO_LEID }}</td>
          <td>{{ item.HO_CDR_NO }}</td>
          <td>{{ item.HO_REPORTING_PERIOD }}</td>
          <td>{{ item.HO_LE_NAME }}</td>
          <td>{{ item.HO_TAX_TYPE }}</td>
          <td>{{ item.TAX_YEAR_BEGIN }}</td>
          <td>{{ item.TAX_YEAR_END }}</td>
          <td>{{ item.SOURCE_SYSTEM_FLAG }}</td>
        </tr>
      </tbody>
    </ap-table>

  </div>

  <section class="a-p-20">

    <!--<div class="scrollable">-->
      <ap-table #basicTable *ngIf="!isLoading" [data] = "splAllocPopupData" [showPage]="false" [pageSize]="splAllocPopupData.length" [striped]="true">
          <thead class="a-thead">
            <th *ngFor="let header of headers" id="{{header.key}}">
              <label style="width:100px!important;" *ngIf="header.key === 'ACCT' "><b>{{header.value}}</b></label>
              <label style="width:350px!important;" *ngIf="header.key === 'ACCT_DESC' "><b>{{header.value}}</b></label>
              <label style="width:150px!important;" *ngIf="header.key === 'TRADING_PARTNER' "><b>{{header.value}}</b></label>
              <label style="width:50px!important;" *ngIf="header.key === 'ISSUE_KEY' "><b>{{header.value}}</b></label>
              <label style="width:210px!important; text-align: right !important;" *ngIf="header.key === 'PSHIP_AMT' " ><b>{{header.value}}</b></label>
              <label style="width:210px!important; text-align: right !important;" *ngIf="header.key === 'PSHIP_AMT_USD' "><b>{{header.value}}</b></label>
              <label style="width:210px!important; text-align: right !important;" *ngIf="header.key === 'TOTAL_ALLOCATION' "><b>{{header.value}}</b></label>
              <label style="width:180px!important; text-align: right !important;" *ngIf="header.key === 'VARIANCE' "><b>{{header.value}}</b></label>
              <label style="width:150px!important;" *ngIf="header.key === 'OVERRIDE_TYPE' "><b>{{header.value}}</b></label>
            </th>
            <th style="padding: 0px !important;" *ngFor="let ptrHeader of ptrHeaders; let prop_key = index ; "  id="{{ptrHeader.key}}">
                <span style="width:570px!important;" *ngIf="ptrHeader.key.includes('/')">
                    <table classs="partnerCell">
                      <thead>
                        <tr>
                          <th style="border:0px !important; text-align: center" colspan="3"><b>{{ptrHeader.value}}</b></th>
                        </tr>
                        <tr>
                          <th style="width:210px!important; text-align: center !important; border:0px !important;">Default Allocation</th>
                          <th style="width:180px!important ; text-align: center !important; border:0px !important;">% Override</th>
                          <th style="width:180px!important; text-align: center !important; border:0px !important;">Amount Override</th>
                        </tr>
                        <tr>
                          <th style="width:210px!important; border:0px !important; text-align: right !important;">&nbsp;</th>
                          <th style="width:180px!important; text-align: center; border:0px !important;">({{ptrRatioArray[prop_key]}})</th>
                          <th style="width:180px!important; ; border:0px !important">&nbsp;</th>
                        </tr>
                      </thead>
                    </table>
                  </span>
            </th>
          </thead>
          <tbody class="a-tbody tbody-regular tbody-hover"  *ngIf="splAllocPopupData.length > 0">
          <tr *ngFor="let item of splAllocPopupData; let i = index;">
            <td *ngFor="let header of headers">
              <label *ngIf="header.key === 'ACCT' ">{{item[header.key]}}</label>
              <label *ngIf="header.key === 'ACCT_DESC' ">{{item[header.key]}}</label>
              <label *ngIf="header.key === 'TRADING_PARTNER' ">{{(item[header.key] === '0' ? '': item[header.key])}}</label>
              <label *ngIf="header.key === 'ISSUE_KEY' " style="width:50px!important;">{{item[header.key]}}</label>
              <label *ngIf="header.key === 'PSHIP_AMT' " style="width:210px!important;text-align: right !important;">{{item[header.key] | number}}</label>
              <label *ngIf="header.key === 'PSHIP_AMT_USD' " style="width:210px!important;text-align: right !important;">{{item[header.key] | number}}</label>
              <label *ngIf="header.key === 'TOTAL_ALLOCATION' " style="width:210px!important;text-align: right !important;">{{item[header.key] | number}}</label>
              <label *ngIf="header.key === 'VARIANCE' " style="width:180px!important;text-align: right !important;" [style.color]="item[header.key] !== 0 ? 'red' : null"><b>{{item[header.key]}}</b></label>
              <span *ngIf="header.key === 'OVERRIDE_TYPE' " style="width:150px!important;text-align: center !important;">
                    <ap-radio [name]="'radio-group-'+i" [text]="'%'" [checked] = "item[header.key] !== 1" class="a-mx-10" (onCheck)="setOverrideOption('ratio', i)">
                    </ap-radio>
                    <ap-radio [name]="'radio-group-'+i" [text]="'Amount'" [checked]="item[header.key] === 1" class="a-mx-10"  (onCheck)="setOverrideOption('amount', i)">
                    </ap-radio>
               </span>
            </td>
            <td style="padding: 0px !important;" *ngFor="let ptrHeader of ptrHeaders" >
                  <span style="width:570px!important;" *ngIf="ptrHeader.key.includes('/')">
                    <table class="partnerCell">
                      <tbody>
                        <tr>
                          <td style="width:210px!important; text-align: right !important; border:0px !important; "><label>{{item[ptrHeader.key].split(',')[0] | number}}</label></td>
                          <td style="width:180px!important; ; border:0px !important; padding-top: 0px !important;"><ap-form-field [id] = "'ratio-override-'+i+'-'+ptrHeader.key"  #ratioOverrides class="ap-form-field-my-wrapper input-fields" [isSecondary]="true" [type]="'input'" [isDisabled]="item['OVERRIDE_TYPE'] === 1" [(ngModel)]="item[ptrHeader.key].split(',')[2]"   (keypress)="keyPressDecimal($event)" (onChangeEvent)="calcVariance($event, i, ptrHeader.key,'ratio')"></ap-form-field></td> <!--  -->
                          <td style="width:180px!important; ; border:0px !important; padding-top: 0px !important;"><ap-form-field [id] = "'amount-override-'+i+'-'+ptrHeader.key" #amountOverrides class="ap-form-field-my-wrapper input-fields" [isSecondary]="true" [type]="'input'" [isDisabled]="item['OVERRIDE_TYPE'] !== 1" [(ngModel)]="item[ptrHeader.key].split(',')[1]" (keypress)="keyPressNumbers($event)" (onChangeEvent)="calcVariance($event, i, ptrHeader.key,'amount')"></ap-form-field></td> <!--   -->
                        </tr>
                      </tbody>
                    </table>
                  </span>
            </td>
          </tr>
          </tbody>
      </ap-table>
    <!--</div>-->
  </section>

</div>

<div  class="row">
  <div class="col-md-9"></div>
  <div class="col-md-3">
    <ap-button [btnText]="'Save'" class="a-mr-10"
               [config]="primaryConfig"
               (onBtnClick)="saveSplAllocData()" [isDisabled]="isSaving"></ap-button>
    <ap-button [btnText]="'Cancel'" class="a-mr-10"
               [btnType]="'a-btn-gray'"
               [config]="primaryConfig"
               (onBtnClick)="modalClose() " [isDisabled]="isSaving">
    </ap-button>

  </div>
</div>
