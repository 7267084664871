import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialog, MefEfileChangesComponent } from './components/mef-efile-changes/mef-efile-changes.component';
import {AppNgAppkitModule} from '../app-ng-appkit.module';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from './admin.service';
import { NaicsMaintenanceComponent } from './components/naics-maintenance/naics-maintenance.component';
import { EntityChartMapCleanUpComponent } from './components/entity-chart-map-clean-up/entity-chart-map-clean-up.component';
import { S3FileUpload } from './components/s3-file-upload/s3-file-upload.component';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { S3FileUploadService } from './components/s3-file-upload/s3-file-upload.service';
import { DfMappingChangesComponent } from './components/df-mapping-changes/df-mapping-changes.component';
import { S3FolderManagerComponent } from './components/s3-folder-manager/s3-folder-manager.component';
import { PostingPartnersComponent } from './components/posting-partners/posting-partners.component';

@NgModule({
  declarations: [MefEfileChangesComponent, ConfirmDialog, NaicsMaintenanceComponent, EntityChartMapCleanUpComponent,S3FileUpload, DfMappingChangesComponent,S3FolderManagerComponent, PostingPartnersComponent],
  imports: [
    CommonModule,
    AppNgAppkitModule,
    AppNgMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ],
  exports: [MefEfileChangesComponent, ConfirmDialog, NaicsMaintenanceComponent,S3FileUpload,DfMappingChangesComponent,S3FolderManagerComponent],
  providers: [AdminService,S3FileUploadService],
})
export class AdminModule { }
