import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DfMeDropdownNode} from '../../models/DfMeDropdownNode';

@Component({
  selector: 'gtw-df-me-dropdown-node',
  templateUrl: './df-me-dropdown-node.component.html',
  styleUrls: ['./df-me-dropdown-node.component.scss']
})
export class DfMeDropdownNodeComponent implements OnInit {

  @Input() node!: DfMeDropdownNode;
  @Output() nodeSelectionChanged: EventEmitter<DfMeDropdownChanges>;

  constructor() {
    this.nodeSelectionChanged = new EventEmitter<DfMeDropdownChanges>();
  }

  ngOnInit(): void {
  }

  onNodeSelected(): void {
    let changedNodes = [this.node];
    this.setChildren(this.node, this.node.selected, changedNodes);
    this.nodeSelectionChanged.emit({target: this.node, select: this.node.selected, changedNodes: changedNodes});
  }

  // set children selection recursively
  private setChildren(node: DfMeDropdownNode, select: boolean, changedNodes: DfMeDropdownNode[]): void {
    if (!node.children) {
      return;
    }
    node.children.forEach((child: DfMeDropdownNode) => {
      if (child.selected !== select) {
        child.selected = select;
        changedNodes.push(child);
        select ? node.numSelected!++ : node.numSelected!--;
      }
      this.setChildren(child, select, changedNodes);
    });
  }

  onChildrenSelectionChanged(changes: DfMeDropdownChanges): void {
    if (!changes.select) {
      // unselect only when selected
      if (this.node.selected) {
        this.node.selected = false;
        changes.changedNodes.push(this.node);
      }
      this.node.numSelected!--;
    } else {
      changes.target.selected && this.node.numSelected!++;
      if (this.node.numSelected === this.node.children!.length) {
        this.node.selected = true;
        changes.changedNodes.push(this.node);
      }
    }
    changes.target = this.node;

    this.nodeSelectionChanged.emit(changes);
  }

  getPadding(level: number): number {
    const paddingVal = level - 2;
    return paddingVal < 0 ? 0 : paddingVal;
  }

}

export interface DfMeDropdownChanges {
  target: DfMeDropdownNode;
  select: boolean;
  changedNodes: DfMeDropdownNode[];
}
