import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DSTStatement} from '../../../models/dst-statement.model';
import {DSTSharedService} from '../../../dst-shared.service';
import {StmtIdBtnService} from '../stmt-id-btn.service';
import {MenuPosPayload} from '../smartMenuPos.directive';

@Component({
  selector: 'dst-mark-as-template',
  templateUrl: './dst-mark-as-template.component.html',
  styleUrls: ['./dst-mark-as-template.component.scss']
})
export class DSTMarkAsTemplateComponent implements OnInit {

  // @Input('modalTemplateRef') modalRef!: ModalComponent;
  @Input('rowObject') row!: DSTStatement;
  @Input('markAsOptions') templateConfig!: DSTMarkAsOption[];
  @Input() menuPos!: MenuPosPayload;
  @Output('showMarkOtherReason') showMarkOtherReason: EventEmitter<null>;

  constructor(private dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService) {
    this.showMarkOtherReason = new EventEmitter<null>();
  }

  ngOnInit(): void {
  }

  changeStatus(reason: DSTMarkAsOption): void {
    if (reason.REASONID === 4) {
      // show popup for user to fill in other reason
      this.showMarkOtherReason.emit();
      return;
    }
    this.stmtIdBtnService.markAs(this.row, reason);
  }

}

export interface DSTMarkAsOption {
  REASONID: number;
  REASON: string;
  REASONDESC: string;
}
