export const HomeScreenObject  = {
    "child_id": 425010906,
    "label": "Modules",
    "type": "screen",
    "level": "4",
    "default_activity": null,
    "jcd_key": "250",
    "jcd_ta_key": "5392",
    "activity_key": "7069",
    "screen_key": "10906",
    "filter_key": null,
    "filter_type": null,
    "filter_inherit": 0,
    "loading_data": false,
    "parentTaskKey": null,
    "id": "10906",
    "split_call_param": null,
    "screen_staus": "A",
    "screen_type": "custom-tab",
    "is_filter_refresh": null,
    "screens_refresh_list": null,
    "icon_css": null,
    "link": "workspace.modules",
    "parent_id": 32507069
};