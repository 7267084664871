<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="formMinus192" class="ap-form-field-my-wrapper">
    <dst-template-header [parentFormGroup]="formMinus192" [overwriteFormName]="'STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS'"
                         (formOptionChanged)="onFormOptionChanged($event)" (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <div formGroupName="main" class="section-content ap-form-field-my-wrapper">
      <ol class="section-content">
        <li>
          The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the "Code")
          applies.
        </li>
        <li>
          <div class="">
            <div class="col-md-1 pr-0">
              On
            </div>
            <div class="col-md-4 p-0 date-align">
              <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate" class="required-field"
                [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)" [(ngModel)]="DisplayDate"
                (click)="onDPOpen()" formControlName="DisplayDate">
              </ap-date-picker>
              <!-- <div class="a-form-error" *ngIf="DisplayDate === '' || DisplayDate === null">
                Please Enter a Date
              </div> -->
            </div>
          </div>
          <br />
          <br />

          <dst-template-leid-selector [label]="'Please specify the LEID of the Transferor'" [labelId]="'100'"
            (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field ent-label">
          </dst-template-leid-selector>
          <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
            Please specify a LEID of transferor
          </div> -->
          <div class="">
            <span class="template-color-blue">{{!transferor ? '' :
              transferor.ENTITY}}</span><span>&nbsp;EIN:&nbsp;</span><span class="template-color-blue">{{!transferor ?
              '' :
              (transferor.EIN ? transferor.EIN : 'N/A')}}</span><span>&nbsp;a&nbsp;</span><span class="template-color-blue">{{!transferor ? '' :
              transferor.COUNTRYINC + (transferor | taxtype: transferor?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>&nbsp;(the "Transferor"), transferred the net assets listed below
              to</span>
          </div>
          <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee'" [labelId]="'100'"
            (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field ent-label">
          </dst-template-leid-selector>
          <!-- <div class="a-form-error error-leid" *ngIf="transferee === undefined">
            Please specify a LEID of Transferee
          </div> -->
          <div class="">
            <span class="template-color-blue">{{!transferee ? '' :
              transferee.ENTITY}}</span><span>&nbsp;EIN:&nbsp;</span><span class="template-color-blue">{{!transferee ?
              '' :
              (transferee.EIN ? transferee.EIN : 'N/A')}}</span><span>&nbsp;a&nbsp;</span>
              <span class="template-color-blue">{{!transferee ? '' :
                transferee.COUNTRYINC }} </span><span>&nbsp;Corporation</span>
              <!-- <span class="template-color-blue">{{!transferee ? '' :
              transferee.COUNTRYINC + (transferee | taxtype: transferee?.TAXTYPECODE: entityNameChangeOnDate)}}</span> -->
              <span>,&nbsp;(the "Transferee") in an exchange pursuant to Sec.
              351 of the Code</span>
          </div>
          <dst-template-leid-selector [label]="'Please specify the LEID of the Target'" [labelId]="'100'"
            (onSelectedLEChanged)="onTargetChanged($event)" class="ent-label">
          </dst-template-leid-selector>
          <div class="">
            <span class="template-color-blue">{{!target ? '' :
              target.ENTITY}}</span><span>&nbsp;EIN:&nbsp;</span><span class="template-color-blue">{{!target ?
              '' :
              (target.EIN ? target.EIN : 'N/A')}}</span><span>&nbsp;a&nbsp;</span><span class="template-color-blue">{{!target ? '' :
              target.COUNTRYINC + (target | taxtype: target?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>,&nbsp;(the "Target") in an exchange pursuant to Sec. 351 of
              the Code.</span>
          </div>

          <br />
          <div class="supp-info-wrapper">
            <div><span
              class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'"
              [isWhite]="true"></span>Additional Information:</div>
            <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" formControlName="section4text"
              [(ngModel)]="section4text">
            </ap-form-field>
          </div>
        </li>
        <li>
          Pursuant to 304(a), shares of the Transferee were deemed issued to the Transferor in the exchange. The
          Transferee in turn is treated as redeeming the stock deemed issued to the Transferor in the exchange.
        </li>
        <li>
          None.
        </li>
        <li>
          For information required pursuant to regulations under Code Section 351, see the separate statement.
        </li>
        <li>
          Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or
          the regulations under those sections has been provided with this tax return on Form 5471, Information Return
          of U.S. Persons with Respect to Certain Foreign Corporations..
        </li>
        <li>
          Not applicable.
        </li>
      </ol>

      <dst-template-leid-selector [isBranchEntity]="true" [label]="'Please specify the LEID of the Branch Entity:'"
        [labelId]="'3'" (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' :
          branchEntity.ENTITY}}</span>
      </div>
      <div class="d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor">
            / </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Additional information to further describe the transaction may be added in text box
    </div>
  </div>
</ng-template>
