<div class="grid-container" id="cr-grid-container">
  <div class="top-toolbar">
    <div class="float-left ml-2">
      <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
        (onChange)="onTaxYearChange()">

      </p-dropdown>
      <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
        (selectedMes)="selectedCustomMeChanged($event)">
      </gtw-df-me-dropdown>
      <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}"
        (onBtnClick)="loadCustomFilteringData()"></ap-button>

      <span class="appkiticon icon-view-outline"></span>
      <span class="appkiticon icon-down-chevron-outline"></span>
      <span class="appkiticon icon-save-outline" (click)="dt.exportCSV()">&nbsp;Export to Excel</span>
      <span class="appkiticon icon-refresh-outline" (click)="resetAllFilters()">&nbsp;Reset All Filters</span>
      <span class="appkiticon icon-openinnewwindow-outline"></span>
      <ap-button class="a-mr-10" [btnText]="'Disclosure Properties'" [config]="{isPrimary: true}" [isDisabled]="loading"
        (onBtnClick)="showFilters()">
      </ap-button>
    </div>
    <div class="float-right">
      <!--      <ap-search [isPrimary]="true" [isBase]="true" [(ngModel)]="tableSearchTerm" (onKeyUp)="onKeyUpSearch($event, 'table')"-->
      <!--                 placeholder=" ">-->
      <!--      </ap-search>-->
      <!--                 [scrollable]="true" scrollHeight="flex" [rows]="10"-->
      <!--                 [virtualScroll]="true" [virtualRowHeight]="80"-->
    </div>
  </div>
  <p-table #dt [columns]="cols" [value]="data" dataKey="statementId" [scrollable]="true" scrollHeight="flex"
    responsiveLayout="scroll" [resizableColumns]="true" columnResizeMode="expand"
    styleClass="p-datatable-gridlines p-datatable-striped" [paginator]="true" [rows]="rows"
    [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [loading]="loading">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr [style.height]="'45px'">
        <th style="width: 30px;"></th>
        <th *ngFor="let col of columns; index as i" pResizableColumn [pSortableColumn]="col.field" [width]="col.width">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
      <tr [style.height]="'45px'">
        <th></th>
        <th>
          <input pInputText type="text" [(ngModel)]="fStatementId" (input)="filterByStatementId($event)"
            class="p-column-filter" style="width: 140px">
        </th>
        <th>
          <p-multiSelect styleClass="p-column-filter" optionLabel="text" placeholder="All" [options]="taxYears"
            [appendTo]="'body'" [(ngModel)]="selectedTaxYearsObj" (onChange)="onTaxYearsChange($event)">
            <ng-template let-option pTemplate="item">
              <div class="p-multiselect-tax-year-option">
                <span class="p-ml-1">{{option.label}}</span>
              </div>
            </ng-template>
          </p-multiSelect>
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fFormName" (input)="filterCol($event, 'formName', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fDisclosureType"
            (input)="filterCol($event, 'disclosureType', 'contains')" class="p-column-filter">
        </th>
        <th style="overflow: visible">
          <gtw-df-me-dropdown class="me-filter-select" [customActionCode]="32136"
            (selectedMes)="selectedMeChanged($event)"></gtw-df-me-dropdown>
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fPrimaryLeid"
            (input)="filterCol($event, 'primaryLeid', 'startsWith')" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fPrimaryEntity"
            (input)="filterCol($event, 'primaryEntity', 'contains')" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fFilingGroup"
            (input)="filterCol($event, 'FilingGroup', 'startsWith')" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fFilingGroupReturnType"
            (input)="filterCol($event, 'FilingGroupReturnType', 'startsWith')" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fStatus" (input)="filterCol($event, 'status', 'startsWith')"
            class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fProjectName"
            (input)="filterCol($event, 'projectName', 'contains')" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input type="text" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fProjectName"
            (input)="filterCol($event, 'projectName', 'contains')" class="p-column-filter" disabled>
        </th>

        <th>
          <input pInputText type="text" [(ngModel)]="fSignatureFlag"
            (input)="filterCol($event, 'signatureFlag', 'startsWith')" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fElectionFlag"
            (input)="filterCol($event, 'electionFlag', 'startsWith')" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" [(ngModel)]="fCreatedBy" (input)="filterCol($event, 'createdBy', 'contains')"
            class="p-column-filter">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
      <tr style="height: 70px;">
        <td style="width: 30px;">
          <a href="#" [pRowToggler]="rowData" *ngIf="rowData.associatedStmt">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"></i>
          </a>
        </td>
        <td style="overflow: visible; width: 120px;">
          <dst-stmt-id-btn [gridData]="data" [rowData]="rowData" [mode]="'customReport'"
            (actionClick)="onActionClicked($event)"></dst-stmt-id-btn>
        </td>
        <td style="width: 80px; text-align: center;">
          <span>{{rowData.TAXYEAR}}</span>
        </td>
        <td style="width: 180px;">
          <span>{{rowData.formName}}</span>
        </td>
        <td style="width: 150px;">
          <span>{{rowData.disclosureType}}</span>
        </td>
        <td style="width: 280px;">
          <span>{{rowData.primaryBusiness}}<br><span
              style="font-style: italic">{{rowData.secondaryBusiness}}</span></span>
        </td>
        <td style="width: 80px;">
          <span>{{rowData.primaryLeid}}<br><span style="font-style: italic">{{rowData.secondaryLeid}}</span></span>
        </td>
        <td style="width: 300px;">
          <span>{{rowData.primaryEntity}}<br><span style="font-style: italic">{{rowData.secondaryEntity}}</span></span>
        </td>
        <td style="width: 360px;">
          <span>{{rowData.FilingGroup}}</span>
        </td>
        <td style="width: 60px;">
          <span>{{rowData.FilingGroupReturnType}}</span>
        </td>
        <td style="width: 80px;">
          <span>{{rowData.status}}</span>
        </td>
        <td style="width: 280px;">
          <span *ngIf="rowData.projectName">
            {{rowData.projectName}} - {{rowData.projectStepName}}
            <br>
            <span style="font-style: italic">{{rowData.PROJECTBSLACODE}}</span>
          </span>
        </td>
        <td style="width: 180px;">
          <span>{{rowData.ignoredId}}</span>
        </td>
        <td style="width: 180px;">
          <span>{{rowData.signatureFlag}}</span>
        </td>
        <td style="width: 180px;">
          <span>{{rowData.electionFlag}}</span>
        </td>
        <td style="width: 300px;">
          <span class="text-color-primary">Created By: </span>
          {{rowData.createdBy}}&nbsp;
          {{rowData.createdOn ? '(' + rowData.createdOn + ')' : ''}}<br>
          <span class="text-color-primary" style="font-style: italic">Modified By: </span>
          <span style="font-style: italic">
            {{rowData.modifiedBy}}&nbsp;
            {{rowData.modifiedOn ? '(' + rowData.modifiedOn + ')' : ''}}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
      <tr>
        <td [attr.colspan]="cols.length + 1">
          <div class="p-p-3">
            <p-table [columns]="childCols" [value]="rowData.associatedStmt" dataKey="statementId">
              <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="overflow: unset">
          <dst-stmt-id-btn [gridData]="data" [rowData]="rowData" [mode]="'customReport'"
            (actionClick)="onActionClicked($event)"></dst-stmt-id-btn>
        </td>
        <td>
          <span>{{rowData.TAXYEAR}}</span>
        </td>
        <td>
          <span>{{rowData.formName}}</span>
        </td>
        <td>
          <span>{{rowData.primaryBusiness}}<br><span
              style="font-style: italic">{{rowData.secondaryBusiness}}</span></span>
        </td>
        <td>
          <span>{{rowData.primaryLeid}}<br><span style="font-style: italic">{{rowData.secondaryLeid}}</span></span>
        </td>
        <td>
          <span>{{rowData.primaryEntity}}<br><span style="font-style: italic">{{rowData.secondaryEntity}}</span></span>
        </td>
        <td>
          <span>{{rowData.FilingGroup}}</span>
        </td>
        <td>
          <span>{{rowData.FilingGroupReturnType}}</span>
        </td>
        <td>
          <span>{{rowData.createdBy}}<br><span style="font-style: italic">{{rowData.modifiedBy}}</span></span>
        </td>
        <td>
          <span>{{rowData.status}}</span>
        </td>
        <td>
          <span *ngIf="rowData.projectName">
            {{rowData.projectName}} - {{rowData.projectStepName}}
            <br>
            <span style="font-style: italic">{{rowData.PROJECTBSLACODE}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>
</div>

<ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
  {{alert.message}}
</ap-alert>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow"
  (afterModalClosed)="afterModalClosed()"></dst-disc-modal-viewer>
<dst-project-tagging *ngIf="showProjectTaggingModal" [rowObject]="activeRow"
  (afterModalClosed)="afterProjectTaggingModalClosed()"></dst-project-tagging>
<dst-copy-replicate *ngIf="showCopyRepModal" [rowObject]="activeRow" [mode]="copyMode"
  (afterModalClosed)="afterCopyRepModalClosed()"></dst-copy-replicate>
<p-dialog position="top" header="Filter Custom Reports" [draggable]="false" [style]="{width: '40vw'}"
  [(visible)]="showGridFilters">
  <div class="row">
    <div class="col-2">Tax Year</div>
    <div class="col-10">
      <p-multiSelect appendTo="body" placeholder="Select Tax Year" styleClass="p-column-filter" optionLabel="text"
        [options]="taxYears" [(ngModel)]="selectedTaxYearsObj" [disabled]="true"
        (onChange)="onTaxYearsFilterChange($event)">
        <ng-template let-option pTemplate="item">
          <div>
            <span class="p-ml-1">{{option.label}}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <div class="row">
    <div class="col-2">Disclosure</div>
    <div class="col-10">
      <p-multiSelect appendTo="body" placeholder="All" styleClass="p-column-filter" optionLabel="formName"
        [options]="allDisclosures" [virtualScroll]="true" [itemSize]="40" [(ngModel)]="selectedDisclosuresObj"
        (onChange)="onDiscFilterChange($event)">
        <ng-template let-option pTemplate="item">
          <div>
            <span class="p-ml-1">{{option.label}}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <div class="row">
    <div class="col-2">Business</div>
    <div class="col-10">
      <gtw-df-me-dropdown class="me-filter-select" [customActionCode]="32136"
        (selectedMes)="selectedMeFilterChanged($event)"></gtw-df-me-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-2">Filing Status</div>
    <div class="col-10">
      <p-dropdown [options]="fStatusList" [(ngModel)]="selectedFStatus" optionLabel="name"></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-2">QAR Status</div>
    <div class="col-10">
      <p-dropdown [options]="QARStatusList" [(ngModel)]="selectedQARStatus" optionLabel="name"></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-2">Signature Flag</div>
    <div class="col-10">
      <p-dropdown [options]="sigElecFlagList" [(ngModel)]="selectedSigFlag" optionLabel="name"></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-2">Election Flag</div>
    <div class="col-10">
      <p-dropdown [options]="sigElecFlagList" [(ngModel)]="selectedElecFlag" optionLabel="name"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <ap-button [btnText]="'Reset'" class="a-mr-10" [config]="{isPrimary: true}"
      (onBtnClick)="resetGridFilters()"></ap-button>
    <ap-button [btnText]="'Close'" class="a-mr-10" [config]="{isPrimary: true}"
      (onBtnClick)="showGridFilters = false;"></ap-button>
    <ap-button [btnText]="'Filter'" class="a-mr-10" [config]="{isPrimary: true}" (onBtnClick)="refresh()"></ap-button>
  </p-footer>
</p-dialog>

<!--<p-table [columns]="testCols" [value]="testData" [virtualScroll]="true" [virtualRowHeight]="34" [scrollable]="true" scrollHeight="flex" [rows]="100">-->
<!--  <ng-template pTemplate="header" let-columns>-->
<!--    <tr>-->
<!--      <th style="width: 60px;"></th>-->
<!--      <th>Test</th>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="body" let-rowData  let-expanded="expanded" let-columns="columns">-->
<!--    <tr style="height:34px">-->
<!--      <td style="width: 60px;">-->
<!--        <a href="#" [pRowToggler]="rowData">-->
<!--          <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"></i>-->
<!--        </a>-->
<!--      </td>-->
<!--      <td>-->
<!--        {{rowData.i}}-->
<!--      </td>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">-->
<!--    <tr>-->
<!--      <td [attr.colspan]="2">-->
<!--        <div class="p-p-3" style="width: 100px; height: 100px; background-color: black;"></div>-->
<!--      </td>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--</p-table>-->
