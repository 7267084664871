import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Alert, StatementTrackerService} from '../../../statement-tracker/statement-tracker.service';
import {ModalComponent} from 'appkit-angular/modal';
import {ITreeOptions, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import {zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTStatement} from '../../models/dst-statement.model';
import {SaveActionResponse} from '../../../../shared/_models/save-action-response.model';
import {DSTSharedService} from '../../dst-shared.service';
import {StmtIdBtnService} from '../stmt-id-btn/stmt-id-btn.service';

@Component({
  selector: 'dst-project-tagging',
  templateUrl: './project-tagging.component.html',
  styleUrls: ['./project-tagging.component.scss']
})
export class ProjectTaggingComponent implements OnInit {

  @Input() rowObject!: DSTStatement;
  @Output() afterModalClosed: EventEmitter<null>;

  @ViewChild('baseModal', {static: false}) baseModal!: ModalComponent;
  @ViewChild('projectsTree') projectsTree!: TreeComponent;
  @ViewChild('businessesTree') businessesTree!: TreeComponent;

  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        // dblClick: (tree, node, $event) => {
        //   if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        // }
      }
    },
    useVirtualScroll: true,
    scrollOnActivate: false,
  };

  projects!: ProjectAndSteps[];
  businesses!: Business[];

  selectedProject: ProjectAndSteps | null;
  selectedBusiness: Business | null;
  loadingData: boolean;
  savingSelection: boolean;
  alert!: Alert;

  constructor(private dstSharedSerivce: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private statementTrackerService: StatementTrackerService) {
    this.afterModalClosed = new EventEmitter<null>();
    this.selectedBusiness = null;
    this.selectedProject = null;
    this.loadingData = false;
    this.savingSelection = false;
  }

  ngOnInit(): void {
    this.alert = this.dstSharedSerivce.alert;

    this.projects = [];
    this.businesses = [];
    const loadProjects$ = this.statementTrackerService.loadProjects(this.rowObject?.TAXYEAR.toString());
    const loadBusinessTiers$ = this.statementTrackerService.loadBusinessTiers(this.rowObject?.TAXYEAR.toString());
    this.loadingData = true;
    zip(loadProjects$, loadBusinessTiers$).pipe(take(1))
      .subscribe((data: [ProjectsAndStepsResponse[], BusinessResponse[]]) => {
        try {
          this.projects.push({id: -1, name: 'None'});
          data[0].forEach((p: ProjectsAndStepsResponse) => {
            this.projects.push(JSON.parse(p.PROJECTSANDSTEPS));
          });
          data[1].forEach((b: BusinessResponse) => {
            this.businesses.push(JSON.parse(b.BUS_HIERARCHY));
          });
        } catch (e: any) {
          if (e instanceof SyntaxError) {
            console.log('JSON parse Syntax Error: ' + e);
          }
          this.projects = [];
          this.businesses = [];
          this.dstSharedSerivce.showAlert('error', 'Something went wrong while retrieving the projects/business data!');
        }
      }, (e: HttpErrorResponse) => {
        this.dstSharedSerivce.showAlert('error', e.message);
      }).add(() => {
      this.loadingData = false;
      setTimeout(() => {
        // this.projectsTree.treeModel.update();
        // this.businessesTree.treeModel.update();
        this.projectsTree.treeModel.expandAll();
        this.businessesTree.treeModel.expandAll();
      });
    });
    // ** remove below when Appkit provides onModalDismissed API **
    // notify tracker to destroy modal when dismissed (user clicked on the backdrop)
    setTimeout(() => {
      const backdropEle: HTMLCollectionOf<Element> = document.getElementsByClassName('modal');
      if (backdropEle[0]) {
        backdropEle[0].addEventListener('click', () => {
          //this.notifyAfterModalClosed();
        });
      }
    });
  }

  private getProjectFromRow(): ProjectAndSteps {
    if (!this.rowObject.PROJECTID) {
      return {name: ''};
    }
    if (!this.rowObject.projectStepName || this.rowObject.projectStepName === '') {
      return {
        id: this.rowObject.PROJECTID,
        name: this.rowObject.projectName
      };
    } else {
      return {
        id: this.rowObject.projectStepId,
        name: this.rowObject.projectStepName,
        parentProjectId: this.rowObject.PROJECTID,
        parentProjectName: this.rowObject.projectName
      };
    }
  }

  private getBusinessFromRow(): Business {
    if (!!this.rowObject.bslaDesc) {
      return {
        id: this.rowObject.PROJECTBSLACODE,
        name: this.rowObject.bslaDesc
      };
    }
    return {
      name: ''
    };
  }

  showMore(id: string): void {
    this.baseModal.showMore(id);
    this.selectedProject = this.getProjectFromRow();
    this.selectedBusiness = this.getBusinessFromRow();
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="project-tagging"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-lg');
    });
  }

  closeModal(id: string): void {
    this.baseModal.closeModal(id);
    //this.notifyAfterModalClosed();
  }

  closeModalOk(id: string): void {
    this.baseModal.closeModalOk(id);
   // this.notifyAfterModalClosed();
  }

  modalDetermine(): void {
  }

  modalClose(): void {
    //this.notifyAfterModalClosed();
  }

  private notifyAfterModalClosed(): void {
    this.afterModalClosed.emit();
  }

  onProjectSelected(event: TreeNodeEvent): void {
    this.selectedProject = event.node.data;
    //this.saveSelection();
  }

  getProjectName(): string {
    if (!this.selectedProject || this.selectedProject.name === 'None') {
      return '';
    }
    if (this.selectedProject.parentProjectName) {
      return this.selectedProject.parentProjectName;
    }
    return this.selectedProject.name;
  }

  getProjectStepName(): string {
    if (!this.selectedProject || !this.selectedProject.parentProjectName) {
      return '';
    }
    return this.selectedProject.name;
  }

  onBusinessSelected(event: TreeNodeEvent): void {
    this.selectedBusiness = event.node.data;
    //this.saveSelection();
  }

  OkSubmit(id: string): void {
    this.saveSelection();
    this.baseModal.closeModalOk(id);
  }

  private saveSelection(): void {
    let projectId = '', projectStepId = '', projectBSLACode = '';
    // for business only
    if (this.selectedProject?.name === '') {
      projectBSLACode = this.selectedBusiness!.id!;
    } else if (!this.selectedProject?.parentProjectName) {
      // for project only
      projectId = this.selectedProject!.name === 'None' ? '' : this.selectedProject!.id!.toString();
      projectBSLACode = this.selectedBusiness!.id!;
    } else if (this.selectedProject?.parentProjectName) {
      // for step
      projectId = this.selectedProject!.parentProjectId!.toString();
      projectStepId = this.selectedProject!.id!.toString();
      projectBSLACode = this.selectedBusiness!.id!;
    }
    this.savingSelection = true;
    this.stmtIdBtnService.saveProjectTagging(this.rowObject.statementId, projectId, projectStepId, projectBSLACode)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess === '1') {
          this.rowObject.PROJECTID = Number.parseInt(projectId);
          this.rowObject.projectName = this.getProjectName();
          this.rowObject.projectStepId = Number.parseInt(projectStepId);
          this.rowObject.projectStepName = this.getProjectStepName();
          this.rowObject.PROJECTBSLACODE = projectBSLACode;
          //this.rowObject.PROJECTBSLACODE = this.selectedBusiness?.name || '';
          this.dstSharedSerivce.showAlert('success', 'Your project and business data are updated successfully!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error.message);
        this.dstSharedSerivce.showAlert('error', 'Something went wrong while saving your selection, please try again!');
      }).add(() => {
      this.savingSelection = false;
    });
  }

  collapseAllProjectTrees() {
    this.projectsTree.treeModel.collapseAll();
    
  }
  collapseAllBusinessTrees() {
    this.businessesTree.treeModel.collapseAll();
    
  }

}

interface ProjectsAndStepsResponse {
  PROJECTSANDSTEPS: string;
}

interface BusinessResponse {
  BUS_HIERARCHY: string;
}

interface ProjectAndSteps {
  id?: number | string,
  name: string,
  parentProjectId?: number | string,
  parentProjectName?: string
  children?: ProjectAndSteps[]
}

interface Business {
  id?: string,
  name: string,
  children?: Business[]
}

export interface TreeNodeEvent {
  eventName: string,
  node: TreeNode,
  treeModel: TreeModel
}
