import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActiveScreensService } from '../shared/_services/active-screens.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
	private URLs = {
		getBizHeirarchy : '/getBizzHierarchyFilter?action_id=31710',
		getSystemNotifByUser : '/getSystemNotifBySso',
		getUnreadMessageCount : '/getUnreadMesageCount',
		signout: "/logoutUser",
		getIssues : '/getIssues'
	};

	constructor(private http:HttpClient) { }

	url={
		updateUserTheme: '/updateUserTheme',
		getScreenBreadcrumbs : '/loadJsonObject?action_code=ephav3'
	}
	selectedChannel:string = '';

	///gateway/gtw/getIssues?tax_year=2020&scenario=47

	// getScreenBreadcrumbsData(baseUrl:string){
	// 	let clientKey = sessionStorage.getItem('client_key') || '';
	// 	const options= {withCredentials:true}
	// 	const url = `${baseUrl}${this.url.getScreenBreadcrumbs}`;

	// 	return this.http.get(url, options).pipe(
	// 		map((res:any) => {
	// 			if(res.callSuccess == "1"){
	// 				return res.jsonObject;
	// 			}
	// 		}
	// 	));
	// }

	setTheme(baseUrl:String, selectedTheme:'white'|'black') {
		const options = {withCredentials: true};
		let body = new FormData();

		body.append('selected_theme', "theme-"+selectedTheme);
		return this.http.post(baseUrl+this.url.updateUserTheme, body,options)
	}

	getBizHeirarchy(baseURL:string, taxYear:string, scenario:string, jcdKey:string){
		let params = new HttpParams();
		params = params.append('tax_year', taxYear);
		params = params.append('scenario', scenario);
		params = params.append('jcd_key', jcdKey);
		return this.http.get(baseURL + this.URLs.getBizHeirarchy, {params})
			.pipe(
			map((res:any)=>{
				return res;
			},
		))
	}

	getJSONObj(baseURL:string){
		const options = {withCredentials: true};
		return this.http.get(baseURL + this.URLs.getUnreadMessageCount, options)
	}

	getSystemNotifByUser(baseURL:string, data:any){
		const options = {withCredentials: true};
		let body = new FormData();

		body.append('sso_id', data.data.sso_id);
		body.append('client_key', data.data.client_key);
		body.append('user_type', data.data.user_type);

		return this.http.post(baseURL+this.URLs.getSystemNotifByUser, body, options)
	}

	getIssues(baseURL:string, taxYear:string, scenario:string){
		const options = {withCredentials: true};
		let params = new HttpParams();
		params = params.append('tax_year', taxYear);
		params = params.append('scenario', scenario);

		return this.http.get(baseURL+this.URLs.getIssues, {params})
	}

	saveSystemDontShowMsg(baseURL:string, data:any) {
		const options = {withCredentials: true};
		return this.http.post(baseURL + this.URLs.getUnreadMessageCount, options)
	}

	signout(baseURL:string){
		const options = {withCredentials: true};
		let url = baseURL + "/logoutUser";
		return this.http.get(url)
	}

  endSession(url: string) {
    return this.http.get(url);
  }
	setChannel(channelKey:any){
		this.selectedChannel = channelKey;
	}
	getChannel(){
		return this.selectedChannel;
	}
}
