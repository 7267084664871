<div class="main-container">
  <div class="grid-container" id="tr-report-grid-container" style="height: calc(100% - 55px) !important">
    <div class="top-toolbar">
      <div class="float-left ml-2">
        <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
          (onChange)="onTaxYearChange()">
        </p-dropdown>

        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
      </div>
    </div>

    <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName"
      [loading]="loading" [scrollable]="true" scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
      class="transfers-review-table" [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }"
      frozenWidth="198px" [frozenColumns]="frozenCols">

      <ng-template pTemplate="frozenheader" let-frozenColumns>
        <tr [style.height]="'90px'">
          <th *ngFor="let col of frozenColumns; index as i" pResizableColumn [pSortableColumn]="col.field" [width]="col.width">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr [style.height]="'45px'">
          <th></th>
          <th>
            <input pInputText type="text" [(ngModel)]="fStatementId"
              (input)="filterCol($event, 'STATEMENTID', 'contains')" class="p-column-filter">
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="frozenbody" let-rowData>
        <tr [style.height]="'70px'" id="{{ rowData.STATEMENTID }}">
          <td style="width: 81px;">
            <span>{{ rowData.TAXYEAR }}</span>
          </td>
          <td style="overflow: visible; width: 117px;">
            <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENTID }}</span></a>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'90px'">
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
        <tr [style.height]="'45px'">
          <th>
            <input pInputText type="text" [(ngModel)]="fStatementName"
              (input)="filterCol($event, 'STATEMENTNAME', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fBusiness"
              (input)="filterCol($event, 'PRIMARYBUSINESS', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fLeid"
              (input)="filterCol($event, 'PRIMARYLEID', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fEntityName"
              (input)="filterCol($event, 'PRIMARYENTITY', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fTransactionType"
              (input)="filterCol($event, 'TRANSACTION_TYPE', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fDateofTransfer"
              (input)="filterCol($event, 'LIQUIDATIONDATE', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fCurrency"
              (input)="filterCol($event, 'CURRENCYNAME', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fDescPropTransfered"
              (input)="filterCol($event, 'BASISDESC', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fBasisPropTransferred"
              (input)="filterCol($event, 'BASISNAME', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fBasisAmount"
              (input)="filterCol($event, 'BASISAMT', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fLiabilitiesAssumed"
              (input)="filterCol($event, 'LIABILITIES', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fNetAmount"
              (input)="filterCol($event, 'NETAMOUNT', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fFmvAmount"
              (input)="filterCol($event, 'FMVAMT', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fFmvBasisAlert"
              (input)="filterCol($event, 'BASISFMVALERT', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fImpPropDesc"
              (input)="filterCol($event, 'IMPORTATIONPROPERTYDESC', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fLossDupliPropDesc"
              (input)="filterCol($event, 'LOSSDUPLICATIONPROPERTYDESC', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fRecogGlPropDesc"
              (input)="filterCol($event, 'GAINLOSSPROPERTYDESC', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fPotentialBilLossImport"
              (input)="filterCol($event, 'POTENTIAL_BIL_LOSS_IMPORT', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fPotential362EAlert"
              (input)="filterCol($event, 'POTENTIAL_362E_ALERT', 'contains')" class="p-column-filter">
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <input pInputText type="text" [(ngModel)]="fIsQar"
              (input)="filterCol($event, 'ISQAR', 'contains')" class="p-column-filter">
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [style.height]="'70px'" syncTableRowHeight [rowId]="rowData.STATEMENTID" >
          <td style="width: 290px;">
            <span>{{ rowData.STATEMENTNAME }}</span>
          </td>
          <td style="width: 250px;">
            <span>{{ rowData.PRIMARYBUSINESS }}</span>
          </td>
          <td style="width: 110px;">
            <span>{{ rowData.PRIMARYLEID }}</span> <br/>
            <span style="font-style: italic">{{ rowData.SECONDARYLEID }}</span>
          </td>
          <td style="width: 280px;">
            <span>{{ rowData.PRIMARYENTITY }}</span> <br/>
            <span style="font-style: italic">{{ rowData.SECONDARYENTITY }}</span>
          </td>
          <td style="width: 172px;">
            <span>{{ rowData.TRANS_TYPE }}</span>
          </td>
          <td style="width: 115px;">
            <span>{{ rowData.LIQUIDATIONDATE }}</span>
          </td>
          <td style="width: 115px;">
            <span>{{ rowData.CURRENCYNAME }}</span>
          </td>
          <td style="width: 230px;">
            <span>{{ rowData.BASISDESC }}</span>
          </td>
          <td style="width: 146px;">
            <span>{{ rowData.BASISNAME }}</span>
          </td>
          <td style="width: 150px;">
            <span>{{ rowData.BASISAMT | number}}</span>
          </td>
          <td style="width: 120px;">
            <span>{{ rowData.LIABILITIES | number }}</span>
          </td>
          <td style="width: 150px;">
            <span>{{ rowData.NETAMOUNT | number }}</span>
          </td>
          <td style="width: 150px;">
            <span>{{ rowData.FMVAMT | number }}</span>
          </td>
          <td style="text-align: center; width: 106px;">
            <span>{{ rowData.BASISFMVALERT }}</span>
          </td>
          <td style="width: 220px;">
            <span>{{ rowData.IMPORTATIONPROPERTYDESC }}</span>
          </td>
          <td style="width: 220px;">
            <span>{{ rowData.LOSSDUPLICATIONPROPERTYDESC }}</span>
          </td>
          <td style="width: 220px;">
            <span>{{ rowData.GAINLOSSPROPERTYDESC }}</span>
          </td>
          <td style="text-align: center; width: 145px;">
            <span>{{ rowData.POTENTIAL_BIL_IMPORTATION }}</span>
          </td>
          <td style="text-align: center; width: 135px;">
            <span>{{ rowData.POTENTIAL_362_E_ALERT }}</span>
          </td>
          <td style="width: 293px;">
            <div class="row">
              <div class="col-md-10 pr-0 rev-Expl">
                <ap-selector class="field-full-width" [items]="rowData['revExplanations']" 
                  [type]="'SingleSelector'" [searchMethod]="'inline'" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled"
                  (onSelectedChange)="reviewerExplanationChange($event, rowData)"></ap-selector>
              </div>
              <div class="col-md-2" *ngIf="rowData.TRANSFER_REIVEW_NAME === 'Yes (provide Stmt ID in the textbox)'">
                <span class="appkiticon icon-pencil-outline pt-2"
                  (click)="yesExplanation(rowData)"></span>
              </div>
            </div>
          </td>
          <td style="width: 240px;">
            <span>{{ rowData.TRANSFER_REVIEW_EXPLANATION }}</span>
          </td>
          <td class="item-aligned" style="width: 140px;">
            <ap-checkbox class="a-mx-10" [checked]="rowData.ISREVIEWED === 'Reviewed'"
              (onCheck)="statementReviewedChange($event, rowData)" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">
            </ap-checkbox>
          </td>
          <td class="item-aligned" style="width: 100px;">
            <span>{{ rowData.ISQAR }}</span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr [style.height]="'41px'" class="no-records">
          <td colspan="25" class="text-center"><span>No Records Found.</span></td>
        </tr>
      </ng-template>

    </p-table>
  </div>


  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>

  <dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
  </dst-disc-modal-viewer>
</div>

<ap-modal #yesExplanationModal *ngIf="showYesExplanationModal" appModalId="rev-yes-exp">
  <div #modalRef2 slot="content">
    <div class="modal-dialog" style="max-width: 31.25rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">{{yesExplActiveRow.STATEMENTID}} - Yes:</span>
          </div>
        </div>
        <div class="a-modal-body">
          <p>Please Enter Statement Id to Match.</p>
          <ap-form-field class="yes-expl-height" [inlineBlock]="true" [type]="'textarea'" 
            [(ngModel)]="yesExplanationText" [isDisabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">
          </ap-form-field>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
            (click)="onYesExplanationSave('rev-yes-exp')" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">Save
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
            (click)="onYesExplanationCancel('rev-yes-exp')">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
