import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplAllocService {

  durationInSeconds= 5;

  private getSplAllocPopupDataUrl = '/loadJsonObject?action_code=y3a1hz';
  private saveSplAllocData = '/saveJsonObject?action_code=m2mbh1';
  private getPtrRatios = '/loadJsonObject?action_code=vdahiu';

  constructor(private http:HttpClient, private _snackBar: MatSnackBar) { }

  getSplAllocPopupData(baseURL:string, taxYear:number, scenario:number, jcdKey:number, combinationKey:number, ssoId:string) {
    const options = {withCredentials: true};

    const url = `${baseURL}${this.getSplAllocPopupDataUrl}&tax_year=${taxYear}&scenario=${scenario}&jcd_key=${jcdKey}&combination_key=${combinationKey}&sso_id=${ssoId}`;

    console.log('url = ', url);

    return this.http.get(url, options).pipe(
      map((res: any) => {
          if (res.callSuccess == '1') {
            console.log('callSuccess:: getSplAllocPopupData() ' , res.jsonObject);
            return res.jsonObject;
          }
          this.openSnackBar('Unable to fetch data.', 'gtw-snackbar--red');
          throwError(new Error('oops!'));
          return null;
        }
      ));

  }

  getPtrRatio(baseURL:string, taxYear:number, scenario:number, jcdKey:number, combinationKey:number, ssoId:string) {
    const options = {withCredentials: true};

    const url = `${baseURL}${this.getPtrRatios}&tax_year=${taxYear}&scenario=${scenario}&jcd_key=${jcdKey}&combination_key=${combinationKey}&sso_id=${ssoId}`;

    console.log('url = ', url);

    return this.http.get(url, options).pipe(
      map((res: any) => {
          if (res.callSuccess == '1') {
            console.log('callSuccess:: getSplAllocPopupData() ' , res.jsonObject);
            return res.jsonObject;
          }
          this.openSnackBar('Unable to fetch data.', 'gtw-snackbar--red');
          throwError(new Error('oops!'));
          return null;
        }
      ));

  }



  saveSplAllocationData(baseURL:string, taxYear:number, scenario:number, jcdKey:number,  ssoId:string, processName:string, checkLockedObj: any, scenarioCode: string, isIssueKeyEnabled: string, issueKey: number, clobSettings:any, clobData:any) {
    const url = `${baseURL}${this.saveSplAllocData}`;
    const options={
      withCredentials: true
    };

    const data:any = {
      "tax_year":taxYear,
      "scenario":scenario,
      "jcd_key":jcdKey,
      "sso_id":ssoId,
      "process_name": processName,
      "checkLockedbj": checkLockedObj,
      "scenarioCode":scenarioCode,
      "is_issue_key_enabled":isIssueKeyEnabled,
      "issue_id":issueKey,
      "jsonSettings":JSON.stringify(clobSettings),
      "jsonObj": JSON.stringify(clobData)
    };

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    console.log('formData =', formData);
    console.log('data =', data);


    console.log('tax_year = ', formData.get("tax_year"));
    console.log('scenario = ', formData.get("scenario"));
    console.log('jcd_key = ', formData.get("jcd_key"));
    console.log('sso_id =', formData.get("sso_id"));
    console.log('process_name =', formData.get("process_name"));
    console.log('checkLockedObj = ', formData.get("checkLockedbj"));
    console.log('scenarioCode = ', formData.get("scenarioCode"));
    console.log('is_issue_key_enabled = ', formData.get("is_issue_key_enabled"));
    console.log('issue_id = ', formData.get("issue_id"));
    console.log('jsonSettings =', formData.get("jsonSettings"));
    console.log('jsonObj = ', formData.get("jsonObj"));

    return this.http.post<any>(url, formData,options);
  }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }
}
