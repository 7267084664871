import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import _ from 'lodash';
import { HeaderService } from '../header/header.service';
import { User } from '../screen-video-container/user';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';
import { HomePageContainerService } from './home-page-container.service';
import { ScreenUpdateService } from './home-page-screens-updates/screen-update.service';
import { ScreenDetailsModel } from './screen-details/screen-details-model';

@Component({
  selector: 'gtw-home-page-container',
  templateUrl: './home-page-container.component.html',
  styleUrls: ['./home-page-container.component.scss']
})
export class HomePageContainerComponent implements OnInit {
  
  user: User = {sso:'000000000', role:'none', system_access_level:'0'};
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  
  @Input('tagName')
  tagName !: string;

  @Input('client-key')
  clientKey : number=0;

  @Input('user')
  set _user(user:string){
    try{
      let temp:User = JSON.parse(user);
      if(Object.keys(user).length ==2){
        this.user =temp;
        // this.init();
      }
    }catch(e){
      console.log('Input not stable yet');
    }
  }

  @Input('base-urls')
  set _base_urls(baseUrls: string){
    try{
      let urls:GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
      if(Object.keys(urls).length >= 3){
        this.baseURLs = urls;
        this.init();
      }
    }catch(e){
      console.log('Input not stable yet');
    }
  }

  @Input('active-screen-keys')
  activeScreenKeys: string = '';

  scenario: string ='';
  @Input('scenario')
  set _scenario(value:string){
    this.scenario = value;
  }
  
  tax_year: string ='';
  @Input('tax_year') 
  set _tax_year(value:string){
    this.tax_year = value;
  }

  @Input('channel-key')
  set _channel_key(keys : any){
    try{
      console.log('channle keys',keys);
      this.init();
    }catch(error){
      console.log("Not an valid object");
    }
  }

  @Output('load-screen')
  loadScreen = new EventEmitter<{activity_key?:string, screen_key?:string}>();


  
  taxTypesList: any[] = [];
  categoryList: any[] = [];

  allScreens: any[] = [];
  filteredScreens: any[] = [];

  homePageSortList: any [] = [{
		data : []
	}];
  
  openActivityListPanel: boolean = true;
  isLoaded: boolean = false;
  taxTypeIndex!:number;
    catIndex!: number;
  
  isCard =false;
  isAsceding = true;

  constructor(private homePageContainerService: HomePageContainerService,
    private headerService: HeaderService,
    private screenUpdateService: ScreenUpdateService) { 
  }

  ngOnInit(): void {
    this.homePageSortList[0].data = [
      {text: "Screen Name",value:"SCREEN_NAME"},
      {text: "Task Name / Activity / Screen",value:"BRDCUMB"},
      {text: "Last Viewed", value:"LAST_VIEWED"},
      {text: "Most Popular",value:"MOST_POPULAR"}
    ]
    if(this.homePageContainerService.sortingFilterKey){
      let selectedSortingKey = this.homePageContainerService.sortingFilterKey.value;
      this.homePageSortList[0].data = _.each(this.homePageSortList[0].data,function(i){
        if(i.value == selectedSortingKey){
          i.selected = true;
        }else{
          i.selected = false;
        }
      })
    }

  }

  init() {
    if(this.homePageContainerService.screenCategorList && !_.isEmpty(this.homePageContainerService.screenCategorList)){
      this.taxTypesList = this.homePageContainerService.screenCategorList.taxTypesList;
      this.categoryList = this.homePageContainerService.screenCategorList.categoryList;
      this.taxTypeIndex = this.taxTypesList.findIndex((i)=>{return i.isSelected == true});
      this.catIndex = this.categoryList.findIndex((i)=>{return i.isSelected == true});
    }else{
      this.homePageContainerService.getTaxTypesCategories(this.baseURLs.admin,this.clientKey).subscribe((data: any) => {
        this.taxTypesList = data.taxTypesList;
        this.categoryList = data.categoryList;
      },
      error => {
          console.error(error);
      },
      () => {
          console.log('Completed all tabs');  
      });
    }
      if(this.homePageContainerService.screenList){
        this.allScreens = this.homePageContainerService.getScreenList(); 
        if(this.headerService.getChannel()!=''){
          this.allScreens = this.homePageContainerService.screenList.filter((i:any) => i.CHANNEL_KEY == this.headerService.getChannel())          
        }      
        this.isLoaded = true;
        this.filterData();
      }else{ 
        this.homePageContainerService.getAllScreens(this.baseURLs.api,this.clientKey).subscribe((data: any) => {
        this.allScreens = data;
        this.homePageContainerService.screenList = this.allScreens;
        if(this.headerService.getChannel()!=''){
          this.allScreens = this.homePageContainerService.screenList.filter((i:any) => i.CHANNEL_KEY == this.headerService.getChannel())  
      
        }
        this.isLoaded = true;

        this.filterData();

    },error => {
        console.error(error);
    },() => {
        console.log('Completed all tabs');
    });
  }
    
  
  

  }

  onHomePageListChanged(evt: any) {
    console.log(evt, 'evt');
    this.homePageContainerService.sortingFilterKey = evt.selected[0];
    this.orderByCategory(evt.selected[0].value)

  }

  filterData(){
    let taxTypesCategory: any = {};
    let taxTypeScreens = [];
    
    taxTypeScreens = this.allScreens;
    this.filteredScreens = taxTypeScreens;

    const selectedCategory = this.categoryList.filter(item => {
      return item.isSelected === true;
    });
    const selectedTaxType = this.taxTypesList.filter(item => {
      return item.isSelected === true;
    });

    taxTypesCategory["taxTypesList"] = this.taxTypesList;
    taxTypesCategory["categoryList"] = this.categoryList;
   
    this.homePageContainerService.setScreenTaxCateList(taxTypesCategory);

    if(selectedTaxType[0] && selectedTaxType[0].id>=0){
      taxTypeScreens = this.allScreens.filter(item => {
       return (((item.SCREENS_TAX_TYPES_LIST.indexOf(selectedTaxType[0].SCREENS_TAX_TYPES_LIST))>-1));
      });
    }
   
    if(selectedCategory[0] && selectedCategory[0].id >=0){
      this.filteredScreens = taxTypeScreens.filter(item => {
        return (((item.SCREENS_CATEGORY_LIST.indexOf(selectedCategory[0].SCREENS_CATEGORY_LIST))>-1));
      });
    }else if(taxTypeScreens.length>0){
      this.filteredScreens = taxTypeScreens;
    }
    let sortingKey =  this.homePageSortList[0].data.find((key:any) => key.selected == true)
    if(sortingKey){
      this.isAsceding = !this.isAsceding;
      this.orderByCategory(sortingKey.value);

    }
  }

  orderByKey(key:any){
    if(this.isAsceding){
      /* this.filteredScreens = this.filteredScreens.sort((a: any, b: any) => {
        return (a[key["field"]] < b[key["field"]]) ? 1 : -1;

      });
    }else{
      this.filteredScreens = this.filteredScreens.sort((a: any, b: any) => {
        return (a[key["field"]] < b[key["field"]]) ? -1 : 1;

      });
    }     */
    //this.filteredScreens =  _.sortBy(this.filteredScreens, ['TASK_NAME', 'ACTIVITY_NAME','SCREEN_NAME']);
    this.filteredScreens =  _.sortBy(this.filteredScreens, ['BREADCRUMB' , 'SCREEN_NAME']);
  }else{
    this.filteredScreens =  _.sortBy(this.filteredScreens, ['BREADCRUMB','SCREEN_NAME']).reverse();

  }
    this.isAsceding = !this.isAsceding;
  }
  favScreens: any[] = [];
  
  orderByCategory(key:any){
      let searchKey = [];
      let merged: any[] = [];
      if(this.filteredScreens){
        let hasNoFavouriteScreen = _.find(this.filteredScreens,{SCREEN_NAME:'No screen selected as favorite'});
        if(hasNoFavouriteScreen){
            this.filteredScreens = _.filter(this.filteredScreens,function(i){return i.SCREEN_NAME != 'No screen selected as favorite'});
        }
      }
      switch(key){
        case "SCREEN_NAME":
              searchKey  = ['BREADCRUMB',key];
              this.doSorting(searchKey);
              break;
        case "BRDCUMB":
            searchKey.push(key);
            this.doSorting(searchKey);
            break;
        case 'LAST_VIEWED':
              console.log('lastViewedScreen',this.screenUpdateService.lastViewedScreen);
              if(this.screenUpdateService.lastViewedScreen && this.screenUpdateService.lastViewedScreen.length>0){
                let lastViewdscreenKeys = _.map(this.screenUpdateService.lastViewedScreen,'screenKey');
                lastViewdscreenKeys = lastViewdscreenKeys.map(Number);
                let filterList = this.filteredScreens;
                _.each(lastViewdscreenKeys,function(i){
                    _.each(filterList,function(y){
                      if(i == y.SCREEN_KEY){
                        merged.push(y);
                      }
                    })
                })
                 this.filteredScreens.filter((item) =>  lastViewdscreenKeys?.includes(item.SCREEN_KEY));
                 this.filteredScreens = _.unionBy(merged, this.filteredScreens, 'SCREEN_KEY');
              }else{
                this.filteredScreens = this.filteredScreens;
              }
              break;
        case 'MOST_POPULAR':
            console.log('fav screens',this.screenUpdateService.allFavScreens$.value);
            let noScreenObj :any= {};

            if(this.screenUpdateService.allFavScreens$.value && this.screenUpdateService.allFavScreens$.value.length){
              if(this.screenUpdateService.allFavScreens$.value.length ==1 && this.screenUpdateService.allFavScreens$.value[0].SCREEN_NAME == 'No screen selected as favorite'){
                  noScreenObj = this.screenUpdateService.allFavScreens$.value[0];
                  noScreenObj["HASACCESS"] = 1;
                  noScreenObj["HAS_DOCUMENT"] = 0;
                  noScreenObj["HAS_VIDEO"] = 0;
                  this.filteredScreens = _.unionBy([noScreenObj], this.filteredScreens, 'SCREEN_KEY');
              }else{
                let findFavScreenList: any = []
                let favoriteList: any = []
                favoriteList= this.screenUpdateService.allFavScreens$.value.map(a => a.SCREEN_NAME);
                _.filter(this.filteredScreens,function(i){ 
                  _.each(favoriteList,function(j){
                      if(i.SCREEN_NAME == j){
                        findFavScreenList.push(i);
                      }})
                  })
                  this.filteredScreens = findFavScreenList.length ? _.unionBy(findFavScreenList, this.filteredScreens, 'SCREEN_KEY') : this.filteredScreens;
              }
            }else{
              this.filteredScreens = this.filteredScreens;
            }
          break;

      }

  }

  doSorting(searchKey:any){
    if(this.isAsceding ){
      this.filteredScreens =  _.sortBy(this.filteredScreens, searchKey).reverse();
    }else{
      this.filteredScreens =  _.sortBy(this.filteredScreens, searchKey);
    }
    this.isAsceding = !this.isAsceding;
  }


  loadClickedScreen(screen:ScreenDetailsModel){
    this.loadScreen.emit({activity_key:screen.ACTIVITY_KEY,screen_key:screen.SCREEN_KEY});
  }

  ngDoCheck(){
    this.allScreens = [...this.allScreens];
    this.filteredScreens = [... this.filteredScreens];
  }

}
