
<div class="video-dialog" mat-dialog-content style="max-height: 100%;">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

        
        <div class="video-header-flex-container">
            <div>
                <button mat-icon-button aria-label="Drag video close" on-click="closeVideoModal()">
                    <mat-icon>close</mat-icon>
                </button>
                <button class="ytp-miniplayer-button ytp-button" mat-icon-button data-tooltip-target-id="ytp-miniplayer-button" aria-label="Miniplayer (i)" on-click="enablePipMode()">
                    <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><use class="ytp-svg-shadow" xlink:href="#ytp-id-20"></use>
                        <path d="M25,17 L17,17 L17,23 L25,23 L25,17 L25,17 Z M29,25 L29,10.98 C29,9.88 28.1,9 27,9 L9,9 C7.9,9 7,9.88 7,10.98 L7,25 C7,26.1 7.9,27 9,27 L27,27 C28.1,27 29,26.1 29,25 L29,25 Z M27,25.02 L9,25.02 L9,10.97 L27,10.97 L27,25.02 L27,25.02 Z" fill="#fff" id="ytp-id-20">
                        </path>
                    </svg>
                </button>
            </div>            
            <div>
                {{data.video_name}}
                <span class="drag_video_span">
                    <button  class="drag_video_modal" mat-icon-button aria-label="Drag Video Modal">
                        <mat-icon>more_vert</mat-icon>
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </span>
            </div>
        </div>        
    </div>
    <video id="screen_video" controls #screenVideo controlslist="nodownload" autoplay   
         [poster]="safeURL()">
        <source [src]="streamURL">
        Video not supported.
    </video>
</div>