import {ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, ViewContainerRef} from '@angular/core';
import {from, Observable, Subject} from 'rxjs';
import {DSTFormState} from '../../../shared/models/dst-form-state.model';
import {TrackerTabState} from '../../tracker-tabs.service';

@Injectable({
  providedIn: 'root'
})
export class DSTDynamicComponentResolverService {

  private _onDisclosureSave: Subject<DSTSavePayload> = new Subject<DSTSavePayload>();
  private _onDisclosureCancel: Subject<null> = new Subject<null>();

  get onDisclosureSave(): Observable<DSTSavePayload> {
    return this._onDisclosureSave.asObservable();
  }

  get onDisclosureCancel(): Observable<null> {
    return this._onDisclosureCancel.asObservable();
  }

  constructor(private cfr: ComponentFactoryResolver) {

  }

  resolve(vcr: ViewContainerRef, cl: ComponentLoader): void {
    from(cl.loadChildren()).subscribe((component: any) => {
      const componentFactory: ComponentFactory<unknown> = this.cfr.resolveComponentFactory(component);
      const ref: ComponentRef<any> = vcr.createComponent(componentFactory);
      const onSave: EventEmitter<DSTSavePayload> | undefined = ref.instance.onSave;
      const onCancel: EventEmitter<null> | undefined = ref.instance.onCancel;
      onSave?.subscribe((payload: DSTSavePayload) => {
        this._onDisclosureSave.next(payload);
      });
      onCancel?.subscribe(() => {
        this._onDisclosureCancel.next();
      });
      ref.onDestroy(() => {
        onSave?.unsubscribe();
        onCancel?.unsubscribe();
      });
    });
  }

}

interface ComponentLoader {
  loadChildren: () => Promise<any>;
}

export interface DSTSavePayload {
  trackerTab: TrackerTabState;
  childFormStates: DSTFormState[];
  redirectToChild: boolean;
}

export enum TemplateComponents {

}
