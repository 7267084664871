import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng-lts/table';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { JSONExportHeader, JSONExportService } from '../../../shared/_services/JSONExport.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { StatementTrackerService } from '../../statement-tracker/statement-tracker.service';
import { DiagnosticsService } from '../diagnostics.service';
import {DfMeDropdownComponent} from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {Alert} from '../../statement-tracker/statement-tracker.service';
import {StmtBtnActionPayload} from '../../shared/components/stmt-id-btn/stmt-id-btn.component';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {CopyReplicateComponent} from '../../statement-tracker/copy-replicate/copy-replicate.component';
import {ModalComponent} from 'appkit-angular/modal';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';

@Component({
  selector: 'gtw-dups-stmt-report',
  templateUrl: './dups-stmt-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss','./dups-stmt-report.component.scss']
})
export class DupsStmtReportComponent implements OnInit, OnChanges {
   // params to be passed from gtw-ui
   @Input('gtw-param') gtwInputParam!: string;
   @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
   @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
   @ViewChild('dt') table!: Table;
   rows: number = 15;
   first: number = 0;
   data: any[] = [];
   cols: any[] = [
     {
       field: 'TAXYEAR',
       header: 'Year',
       hidden: false,
       width: '80px'
     },
     {
       field: 'STATEMENTID',
       header: 'Stmt Id',
       hidden: false,
       width: '100px'
     },
     {
       field: 'STATEMENTNAME',
       header: 'Statement',
       hidden: false,
       width: '290px'
     },
     {
       field: 'PRIMARYBUSINESS',
       header: 'Primary Business',
       hidden: false,
       width: '180px'
     },
     {
       field: 'PRIMARYLEID',
       header: 'Primary LEID',
       hidden: false,
       width: '100px'
     },
     {
       field: 'PRIMARYENTITY',
       header: 'Primary Name',
       hidden: false,
       width: '220px'
     },
     {
       field: 'SECONDARYBUSINESS',
       header: 'Secondary Business',
       hidden: false,
       width: '180px'
     },
     {
      field: 'SECONDARYLEID',
      header: 'Secondary LEID',
      hidden: false,
      width: '100px'
    },
    {
      field: 'SECONDARYENTITY',
      header: 'Secondary Name',
      hidden: false,
      width: '180px'
    },
     {
       field: 'ISQARSTR',
       header: 'QAR?',
       hidden: false,
       width: '90px'
     },
     {
       field: 'MODIFIEDBY',
       header: 'Modified By',
       hidden: false,
       width: '180px'
     },
     {
       field: 'MODIFIEDON',
       header: 'Modified On',
       hidden: false,
       width: '180px'
     },
     {
      field: 'CREATEDBY',
      header: 'Created By',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CREATEDON',
      header: 'Created On',
      hidden: false,
      width: '180px'
    },
   ];
   loading: boolean = false;
   taxyearLoaded: boolean = false;
   excelExportFileName: string;
   scrollBarHidden: boolean = false;
   private selectedMes: string;
   activeRow!: DSTStatement;
   showModalViewer: boolean = false;
 
   taxYears: DSTTaxYear[] = [];
   taxYear: DSTTaxYear | undefined;
   private taxYearVal: string;
   private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
    private dstSharedService: DSTSharedService,
    private diagnosticsService: DiagnosticsService,
    private jsonExportService: JSONExportService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerService: StatementTrackerService,
    private trackerTabsService: TrackerTabsService,
    private dCRService: DSTDynamicComponentResolverService) { 
      this.excelExportFileName = 'Duplicate_Stmt_Diagnostics_' + this.dstSharedService.getExcelExportDateString();
      this.selectedMes = '';
      this.taxYearVal = '';
    }

    ngOnChanges(changes: SimpleChanges): void {
      // Angular Elements input is not defined on Init
      // see for details https://github.com/angular/angular/issues/29050
      // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
      if (!changes.gtwInputParam?.isFirstChange()) {
        try {
          const param = JSON.parse(changes.gtwInputParam.currentValue);
          this.dstSharedService.setClientKey(param.clientKey);
          this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
          this.dstSharedService.setBaseURLs(param.baseURLs);
          this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
          this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
          this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
          this.dfMeDropdownComponent.loadMEsCustom(param.taxYear);
          const getTaxYears$ = this.cDSerivce.getTaxYears();
          zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
            this.taxYears = data[0];
            this.setTaxYear(param.taxYear);
            //this.loadReport();
          });
        } catch (e) {
          if (e instanceof SyntaxError) {
            console.error('JSON parse Syntax Error' + e);
          }
          this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
        }
      }
    }

  ngOnInit(): void {
    // local test only
    //  const getTaxYears$ = this.cDSerivce.getTaxYears();
    //  zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //    this.taxYears = data[0];
    //    this.setTaxYear('2021');
    //    this.loadReport();
    //  });
    //  this.dstSharedService.setDSTUser('400394467', 5);
    //  this.dfMeDropdownComponent.loadMEsCustom('2021');

     this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  refresh(): void {
    this.taxyearLoaded = true;
    this.loadReport();
  }
  
  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  onTaxYearChange(object: any): void {
    this.taxyearLoaded = true;
    this.taxYearVal = object.value.text;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYearVal);
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.diagnosticsService.loadDupsStmtReport(this.taxYear?.text.toString()!, this.selectedMes).subscribe((data) => {
      this.data = data;
      this.data.forEach((element, index) => { //creating RevExpl array for each row
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.STATEMENTNAME;

        element.id = index + 1;
        
        
      });
      this.table.clear();
      
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the DST Duplicate Statements Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }
    
    showViewUpdateStatement(rowData: any): void {
      this.activeRow = rowData;
      this.dstSharedService.setTableActionStatus(true);
      this.dstSharedService.setIsFiled(rowData.IS_FILED!);
      this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
        this.showModalViewer = true;
        setTimeout(() => {
          this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
        });
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      }).add(() => {
        this.dstSharedService.setTableActionStatus(false);
      });
    }

    private changeScrollBarStyling(): void {
      const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
      if (cdkScrollerEles.length) {
        this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
      } else {
        this.scrollBarHidden = true;
      }
    }
    
    afterModalClosed(): void {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    }
    exportToExcel(): void {
      const headersMap: JSONExportHeader[] = [
        {
          field: 'TAXYEAR',
          header: 'Year',
        },
        {
          field: 'STATEMENTID',
          header: 'Stmt Id',
        },
        {
          field: 'STATEMENTNAME',
          header: 'Statement',
        },
        {
          field: 'PRIMARYBUSINESS',
          header: 'Primary Business',
        },
        {
          field: 'PRIMARYLEID',
          header: 'Primary LEID',
        },
        {
          field: 'PRIMARYENTITY',
          header: 'Primary Name',
        },
        {
          field: 'SECONDARYBUSINESS',
          header: 'Secondary Business',
        },
        {
          field: 'SECONDARYLEID',
          header: 'Secondary LEID',
        },
        {
          field: 'SECONDARYENTITY',
          header: 'Secondary Name',
        },
        {
          field: 'ISQARSTR',
          header: 'QAR?',
        },
        {
          field: 'MODIFIEDBY',
          header: 'Modified By',
        },
        {
          field: 'MODIFIEDON',
          header: 'Modified On',
        },
        {
          field: 'CREATEDBY',
          header: 'Created By',
        },
        {
          field: 'CREATEDON',
          header: 'Created On',
        }
      ];
  
      this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
    }
    
 

}
