import {NgModule} from '@angular/core';
import {DSTClickOutsideDirective} from './dstClickOutside.directive';
import {DragAndDropUploadDirective} from './drag-and-drop-upload.directive';

@NgModule({
  declarations: [
    DSTClickOutsideDirective,
    DragAndDropUploadDirective
  ],
  imports: [],
  exports: [
    DSTClickOutsideDirective,
    DragAndDropUploadDirective
  ]
})
export class SharedDirectivesModule {
}
