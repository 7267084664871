import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { SupplementalInfoService } from './supplemental-info.service';
import {CurrencyPipe} from "@angular/common";
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gtw-supplemental-info',
  templateUrl: './supplemental-info.component.html',
  styleUrls: ['./supplemental-info.component.scss'],
  providers: [ CurrencyPipe ]
})
export class SupplementalInfoComponent implements OnInit {

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;

  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  
  private comboKey :number = 0;
  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private scenarioCode: string = '';
  private checkLockedObj: any = [];
  
  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }

  @Input('tax-year')
  set _taxYear(taxYear:number){
    this.taxYear = taxYear;
    this.init();
  }

  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input('screen-key')
  set _screenKey(screenKey:number) {
    this.screenKey = screenKey;
    this.init();
  }

  @Input('cc-key')
	set _comboKey(comboKey:number){
	  this.comboKey = comboKey;
	  this.init();
	}

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    this.ssoId = ssoId;
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode:string) {
    this.scenarioCode = scenarioCode;
    this.init();
  }

  @Output('open-supplemental-info')
  openSupplementalInfo = new EventEmitter<string>();

  @Output('save-supplemental-info')
  saveSupplementalInfo = new EventEmitter<string>();

  

  constructor(private SupplementalInfoService: SupplementalInfoService, private currencyPipe: CurrencyPipe , private _snackBar: MatSnackBar) { }

  issueIdList : any = [
		{
			data: []
		}
	];
	
	SavedData : any = [];
	tempGroupData : any = { };
	tableData = false;
    PurInvUsRel : any;
	PurInvNonUsRel : any;
	CostSales: any;
	WageCostSales : any;
	SalesTangPropUs : any;
	SalesTangPropNonUs : any;
	PurTangPropUs : any;
	PurTangPropNonUs : any;
	BuyInRecveUs : any;
	BuyInRecveNonUs : any;
	BuyInPaidUs : any;
	BuyInPaidNonUs : any;
	CostSharRecveUs : any;
	CostSharRecveNonUs : any;
	CostSharPaidUs : any;
	CostSharPaidNonUs : any;
	sanctionedCountry : any;
    illegalPayments : any;
	editIssueid : any;
	selectedIssueid  : any = [];
	autoRunDataLoad = false;
	scenarioDesc: string = '';
	isEditFlag = false;
	isResetFlag = false;
	tableLoadingData = false;
	rafDataFlag = false;
	durationInSeconds= 5;
	issueIdCheckFlag = false;
	
	checkInputType():boolean{
		if(Number.isInteger(parseInt(this.taxYear+"")) &&
		  Number.isInteger(parseInt(this.scenario+'')) &&
		  Number.isInteger(parseInt(this.jcdKey +'')) &&
		  Number.isInteger(parseInt(this.screenKey+'')) &&
		  Number.isInteger(parseInt(this.comboKey+'')) &&
		  Number.isInteger(parseInt(this.ssoId+"")) &&
		  (this.scenarioCode === "RAF" || this.scenarioCode === "EXAM") 
		  )
		{
		  return true;
		}
	
		return false;
	}

  ngOnInit(): void {
  }

  init() {
	    if(this.checkInputType()){
			this.getIssueIdData();
			this.getModalSaveData();
		}
	}

    onChangeEvent(event: any) {
      console.log(event);
  }

  onClickButtonEvent(event: any) {
      console.log(event);
  }

  public floatingConfig = {
    isFloating: true
	}

  btnClickDelete(dataf : any) {
	let tempDataDel = {
		TAX_YEAR : this.baseGlobalParams?.tax_year,
		SCENARIO : this.baseGlobalParams?.scenario,
		JCD_KEY : this.baseGlobalParams?.jcd_key,
		COMBINATION_KEY: this.comboKey,
		SSO_ID: this.ssoId,
		ISSUE_KEY : dataf.ISSUE_KEY
	}
	this.SupplementalInfoService.deleteSupplInfoData(this.baseURLs.api, tempDataDel, this.baseGlobalParams, this.checkLockedObj).subscribe((data) => {
		if (data.callSuccess == "1") {
			this.SupplementalInfoService.openSnackBar('Data Deleted Successfully','gtw-snackbar--green');
			this.saveSupplementalInfo.emit('save-supplemental-info');
		} else {
			this.SupplementalInfoService.openSnackBar('Error Deleting Data. '+data.errorMessage, 'gtw-snackbar--red');
		}
			}, () => {
				console.log("Error while saving");
		});
	}

	btnClickEdit(dataedit : any) {
		this.isEditFlag = true;
		this.rafDataFlag = false;
		this.editIssueid = dataedit.ISSUE_KEY;
		this.illegalPayments = (dataedit.ILLEGAL_PAYMENTS == "Yes") ? "true": "";
		this.sanctionedCountry = (dataedit.SANCTIONED_COUNTRY == "Yes") ? "true": "";
		this.PurInvUsRel = dataedit.PURCHASE_INV_US_RELATED;
		this.PurInvNonUsRel = dataedit.PURCHASE_INV_NONUS_RELATED;
		this.CostSales = dataedit.COST_OF_SALES;
		this.WageCostSales = dataedit.WAGES_COST_OF_SALES;
		this.SalesTangPropUs = dataedit.SALES_TANGIBLE_PROP_US;
		this.SalesTangPropNonUs = dataedit.SALES_TANGIBLE_PROP_NONUS;
		this.PurTangPropUs = dataedit.PURCHASE_TANGIBLE_PROP_US;
		this.PurTangPropNonUs = dataedit.PURCHASE_TANGIBLE_PROP_NONUS;
		this.BuyInRecveUs = dataedit.BUY_IN_RECEIVE_US;
		this.BuyInRecveNonUs = dataedit.BUY_IN_RECEIVE_NONUS;
		this.BuyInPaidUs = dataedit.BUY_IN_PAID_US;
		this.BuyInPaidNonUs = dataedit.BUY_IN_PAID_NONUS;
		this.CostSharRecveUs  = dataedit.COST_SHARING_RECEIVE_US;
		this.CostSharRecveNonUs = dataedit.COST_SHARING_RECEIVE_NONUS;
		this.CostSharPaidUs = dataedit.COST_SHARING_PAID_US;
		this.CostSharPaidNonUs = dataedit.COST_SHARING_PAID_NONUS;
		if(this.scenarioDesc !== 'RAF'){
            this.setIssueId(this.tempGroupData,dataedit.ISSUE_KEY);
		}
	}

	resetData(){
		    this.isResetFlag = true;
			this.autoRunDataLoad = false;
			this.selectedIssueid = [];
			this.illegalPayments = '';
			this.sanctionedCountry = '';
			this.PurInvUsRel = '';
			this.PurInvNonUsRel = '';
			this.WageCostSales = '';
			this.SalesTangPropUs = '';
			this.SalesTangPropNonUs = '';
			this.PurTangPropUs = '';
			this.PurTangPropNonUs = '';
			this.BuyInRecveUs = '';
			this.BuyInRecveNonUs = '';
			this.BuyInPaidUs = '';
			this.BuyInPaidNonUs = '';
			this.CostSharRecveUs  = '';
			this.CostSharRecveNonUs = '';
			this.CostSharPaidUs = '';
			this.CostSharPaidNonUs = '';
			if(this.scenarioDesc !== 'RAF'){
				this.setIssueId(this.tempGroupData,this.editIssueid);
			}
	};
  
  getTestData(){
	
  }

  saveData(){ 
	         if(this.scenarioDesc !=='RAF' && this.issueIdList[0].data[_.findIndex(this.issueIdList[0].data, { 'selected': true})] == undefined){
				// this.SupplementalInfoService.openSnackBar('Please select Issue Id','gtw-snackbar--red');
				   this.issueIdCheckFlag = true;
				   return;
			   }else{
				this.issueIdCheckFlag = false;
			   }
			
			let tempData = {
				TAX_YEAR : this.baseGlobalParams?.tax_year,
				SCENARIO : this.baseGlobalParams?.scenario,
				JCD_KEY : this.baseGlobalParams?.jcd_key,
				COMBINATION_KEY: this.comboKey,
				SSO_ID: this.ssoId,
				ISSUE_KEY: (this.scenarioDesc !== 'RAF') ? this.issueIdList[0].data[_.findIndex(this.issueIdList[0].data, { 'selected': true})].value : 0,
				PURCHASE_INV_US_RELATED : this.PurInvUsRel,
				PURCHASE_INV_NONUS_RELATED : this.PurInvNonUsRel,
				WAGES_COST_OF_SALES : this.WageCostSales,
				SALES_TANGIBLE_PROP_US : this.SalesTangPropUs,
				SALES_TANGIBLE_PROP_NONUS : this.SalesTangPropNonUs,
				PURCHASE_TANGIBLE_PROP_US : this.PurTangPropUs,
				PURCHASE_TANGIBLE_PROP_NONUS : this.PurTangPropNonUs,
				BUY_IN_RECEIVE_US : this.BuyInRecveUs,
				BUY_IN_RECEIVE_NONUS : this.BuyInRecveNonUs,
				BUY_IN_PAID_US : this.BuyInPaidUs,
				BUY_IN_PAID_NONUS : this.BuyInPaidNonUs,
				COST_SHARING_RECEIVE_US : this.CostSharRecveUs,
				COST_SHARING_RECEIVE_NONUS : this.CostSharRecveNonUs,
				COST_SHARING_PAID_US : this.CostSharPaidUs,
				COST_SHARING_PAID_NONUS : this.CostSharPaidNonUs,
				SANCTIONED_COUNTRY : this.sanctionedCountry,
				ILLEGAL_PAYMENT: this.illegalPayments
			}
					
					this.SupplementalInfoService.saveSupplInfoData(this.baseURLs.api, tempData, this.baseGlobalParams, this.checkLockedObj).subscribe(
						(data) => {
							if (data.callSuccess == "1") {
								this.SupplementalInfoService.openSnackBar('Data Saved Successfully','gtw-snackbar--green');
								this.saveSupplementalInfo.emit('save-supplemental-info');
							} else {
								this.SupplementalInfoService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
							}
						}, () => {
							console.log("Error while saving");
					});
    };

  setIssueId(group : any,editvalue:any){
		if (this.isEditFlag == true && this.isResetFlag == false){
			for(let i = 0; i <= this.issueIdList[0].data.length; i++){
				var selectedObjIndex= _.findIndex(this.issueIdList[0].data, { 'value': editvalue});
				if(i == selectedObjIndex ){
					this.issueIdList[0].data[selectedObjIndex].selected = true;
				}else{
					this.issueIdList[0].data[i].selected = false;
				}
			}
	    } else if(this.isResetFlag == true && this.isEditFlag == true){
					for(let i = 0; i <= this.issueIdList[0].data.length; i++){
						var selectedObjIndex= _.findIndex(this.issueIdList[0].data, { 'value': editvalue});
						if(i == selectedObjIndex ){
							this.issueIdList[0].data[selectedObjIndex].selected = false;
							this.isResetFlag = false;
							this.isEditFlag = false;
						}else{
							this.issueIdList[0].data[i].selected = false;
						}
					}
		}else if(this.isResetFlag == true && this.isEditFlag == false){
				for(let i = 0; i <= this.issueIdList[0].data.length; i++){
					var selectedObjIndex= _.findIndex(this.issueIdList[0].data, { 'selected': true});
					if(i == selectedObjIndex ){
						this.issueIdList[0].data[selectedObjIndex].selected = false;
						this.isResetFlag = false;
					}else{
						this.issueIdList[0].data[i].selected = false;
					}
				}
		}else{
            this.selectedIssueid = group.selected[0].value;
			this.issueIdCheckFlag = false;
		}
	}

  getIssueIdData(){
		this.issueIdList = [{ data: [] }];
		this.scenarioDesc = this.scenarioCode;
		this.SupplementalInfoService.getIssueIdData(this.baseURLs.api, this.baseGlobalParams,this.ssoId).subscribe((data: any) => {
			if (data) {
				let tempIssueId : any = [];
				data.forEach( (value : any) => {
						let tempChannel = {
							value: value.ISSUE_ID,
							text: value.ISSUE_SHORT_DESC,
							selected: false
						}
						tempIssueId.push(tempChannel);
				});
				this.issueIdList[0].data = tempIssueId;
				console.log(this.issueIdList);
			}
		},
		error => {
		  this.autoRunDataLoad = false;
		  console.log(error);
		}, () => {
		  this.autoRunDataLoad = false;
		});

		
	}

	getModalSaveData(){
		if(this.SavedData.length == 0 && !this.autoRunDataLoad) {
			this.autoRunDataLoad = true;
			this.SupplementalInfoService.getSupplModalData(this.baseURLs.api, this.baseGlobalParams, this.comboKey,this.ssoId).subscribe((data: any) => {
				if (data.length > 0) {
					this.autoRunDataLoad = false;
					this.tableLoadingData = true;
					this.tableData = true;
					console.log(data);
					this.SavedData = data;
					if(this.scenarioDesc == 'RAF' && data.length == 1){
                              this.rafDataFlag = true;
					  }
				}else{
					this.tableLoadingData = true;
					this.tableData = false;
					this.SavedData = [];
				}
			},
			error => {
			  this.autoRunDataLoad = false;
			  console.log(error);
			}, () => {
			  this.autoRunDataLoad = false;
			  console.log('Completed loading');
			});
		}
	}

	modalClose() {
		console.log("click close");
		this.openSupplementalInfo.emit('cancel-suppl-update');
	  }

}
