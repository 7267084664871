import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActiveScreensService } from '../shared/_services/active-screens.service';
@Component({
  selector: 'gtw-screens',
  templateUrl: './screens.component.html',
  styleUrls: ['./screens.component.scss']
})
export class ScreensComponent implements OnInit {

  @Output('load-screens') showScreensEvent = new EventEmitter<any>();
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  activeScreenKeys:Array<any>=[];

  transparentConfig = {isTransparent: true};
  isPrimary={isPrimary: true};
  isShowing = false;

  constructor(private activeScreenService: ActiveScreensService) { }

  ngOnInit(): void {
  }

  setActiveScreens(){
    this.activeScreenKeys = [];
    this.activeScreenKeys = this.activeScreenService.getActiveScreens();
    // this.trigger.openMenu();
    this.isShowing=!this.isShowing;
  }

  loadScreen(screen : any, i: number){
    this.activeScreenService.clickScreen(i);
    this.showScreensEvent.emit(screen);
  }

  goBack(){
    const screen = this.activeScreenService.goBack();
    this.showScreensEvent.emit(screen);
  }

  trackByMethod(index:number, screen:any){
    return screen.id;
  }
  hideScreenList(){
    this.isShowing =false;
  }

}
