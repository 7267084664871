import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import { CreateDisclosureSharedService, DropDownType, dstLookupEntity, EntityNameChange } from '../../../create-disclosure-shared.service';
import { DSTEntityDetails } from '../../template-leid-selector/template-leid-selector.component';

@Component({
  selector: 'dst-view-template-sec351-id184',
  templateUrl: './view-template-sec351-id184.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec351-id184.component.scss']
})
export class ViewTemplateSec351Id184Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form184!: FormGroup;
  selectedOptionID!: number;
  beginDate!: Date;
  endDate!: Date;
  defaultDate!: Date;
  isSection304Related: boolean;
  stmtData!: StmtData;
  stmtAttrData: any = [];
  loaded: boolean = false;
  attrData: any = [];
  pickListVal: string = '';
  liquidationDate: any = [];
  attMap: any;
  isBranchEntity!: boolean;
  selTransferee: DSTEntityDetails[] = [];
  selTransferor: DSTEntityDetails[] = [];
  lookupdata: any = [];
  currencyOptions: any = [{ data: [] }];
  stockTypeOptions: any = [{ data: [] }];
  liquidationStateOptions: any = [{ data: [] }];
  decsArray: any = [];
  sec304Check: boolean = false;
  descTotalAvl: boolean = false;
  basisAmt1Total: boolean = false;
  propertyValBasisOptions: any = [{ data: [] }];
  selBasisTxt: string = '';
  selStockTypeTxt: string = '';
  startLoader: boolean = false;
  disableActions: boolean = false;
  displaySharedOwnershipInfo: boolean = false;
  s3Uploading: boolean = false;
  noAddShares: boolean = false;
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  shCorpTypeTxt: string = '';
  primaryEntityName: string = '';
  secondaryEntityName: string = '';

  constructor(private formBuilder: FormBuilder,
    private dstSharedService: DSTSharedService,
    private sharedService: CreateDisclosureSharedService,
    private taxTypePipe: TaxTypePipe) {
    this.isSection304Related = false;
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false; // reset immediately after consumed
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form184 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    //load currencies
    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.currencyOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //
    this.sharedService.getStockTypeListDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.stockTypeOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        this.selStockTypeTxt = this.stockTypeOptions[0]?.data[0]?.text || '';
      });

    //PropertyValBasis Options
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        //console.log(data);
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //load Stmt data
    this.sharedService.getLoadStatementData(184)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        this.primaryEntityName = this.stmtData.primaryentity || '';
        this.secondaryEntityName = this.stmtData.secondaryentity || '';

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }


        });
        console.log('After loop Attr data' + JSON.stringify(this.attMap));

        //check totals are available
        if (this.attMap['Desc'] && this.attMap['Desc'].length >= 1) {
          this.descTotalAvl = true;
        }
        if (this.attMap['Desc1'] && this.attMap['Desc1'].length >= 1 && this.checkArrayHasNonEmptyValues(this.attMap['Desc1'])) {
          this.basisAmt1Total = true;
        }

        //shared/ownership info
        if ((this.attMap['ShareX'] !== undefined && this.attMap['ShareX'][0] !== '')
          || (this.attMap['ShareY'] !== undefined && this.attMap['ShareY'][0] !== '')
          || (this.attMap['PercentageX'] !== undefined && this.attMap['PercentageX'][0] !== '')
          || (this.attMap['PercentageY'] !== undefined && this.attMap['PercentageY'][0] !== '')) {

          if ((this.attMap['ShareX'][0] !== undefined && this.attMap['ShareY'][0] !== undefined) && (this.attMap['ShareX'][0] === this.attMap['ShareY'][0])) {
            this.noAddShares = true;
          } else {
            this.displaySharedOwnershipInfo = true;
          }
        }

        //check for 351 - 304 Transaction
        this.check304Type();

        //Set all the values that needed from the service calls
        this.propertyValBasisOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['Basis'] !== undefined) && (val.id == this.attMap['Basis'][0])) {
            this.selBasisTxt = val.text;
          }

        });

        this.stockTypeOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['StockType'] !== undefined) && (val.id == this.attMap['StockType'][0])) {
            this.selStockTypeTxt = val.text;
          }

        });

        //corpTypeTxt
        //this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1);
        //this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2);
        

      }, () => {

      }, () => {

      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      });

  }

  private loadOtherEntityInfoAndUploadPdf() {
    forkJoin([
      this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1),
      this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2)
    ]).subscribe(([response1, response2]) => {
      setTimeout(() => {
        const html = document.querySelector('#temp351form184');
        const htmlContent = html!.outerHTML;
        this.sharedService.getFormStateByFormId(184)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
      });
    }, (error: HttpErrorResponse) => {

      console.log(error);

      this.dstSharedService.showAlert('error', error.message);

    }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });
  }

  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 'Section351-StatementPursuant.pdf', this.stmtData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading = false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp351form184');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   this.startLoader = true;
  //   this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
  //     leid,
  //     this.attMap['LiquidationDate'] != undefined ? this.attMap['LiquidationDate'][0]: null,
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //       if (entityType === 1) { //primary entity
  //         (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
  //         this.primaryCorpTypeTxt = corpType;
  //       } else if (entityType === 2) { //secondary entity
  //         (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
  //         this.secondaryCorpTypeTxt = corpType;
  //       } else if (entityType === 3) { //share holder entity
  //         this.shCorpTypeTxt = corpType;
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.startLoader = false;
  //   });
  // }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    this.startLoader = true;
    return this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
      leid,
      this.attMap['LiquidationDate'] != undefined ? this.attMap['LiquidationDate'][0] : null,
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
          if (response && response[0]) {
            //override entity type
            const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
            if (entityType === 1) { //primary entity
              (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
              this.primaryCorpTypeTxt = corpType;
            } else if (entityType === 2) { //secondary entity
              (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
              this.secondaryCorpTypeTxt = corpType;
            } else if (entityType === 3) { //share holder entity
              this.shCorpTypeTxt = corpType;
            }
          }
        })
      })
    )
  }

  checkArrayHasNonEmptyValues(item: any[]): boolean {
    return !item.every(ele => ele.length < 1);
  }

  check304Type(): void {

    if (this.attMap['' +
      'CheckFor351Transfer'] !== undefined && this.attMap['CheckFor351Transfer'][0] === '1') {

      this.lookupdata = [];

      //populateSHFields
      if (this.attMap['SHdspTransferorLEID'] !== undefined) {
        this.sharedService.getDSTEntityInfo(this.attMap['SHdspTransferorLEID'][0])
          .subscribe((data: dstLookupEntity[]) => {

            this.lookupdata.push(data[0]);
            this.updateEntitiesInfo(this.lookupdata[0].LEID, 3).subscribe(response =>{
              this.loadOtherEntityInfoAndUploadPdf();
            }); //corpTypeTxt

          }, () => {

          }, () => {

          });
      }else{
        this.loadOtherEntityInfoAndUploadPdf();
      }

      //Select pickList Val
      this.sharedService.getLiquidationStateDropdown(2)
        .subscribe((data: DropDownType[]) => {
          data.forEach(element => {
            if (this.attMap['TransfereesNote'] && element.id == this.attMap['TransfereesNote'][0]) {
              this.pickListVal = element.text;
            }

          });
        }, () => {

        }, () => {

        });
      this.sec304Check = true;
    } else {
      this.loadOtherEntityInfoAndUploadPdf();
    }
  }

  /*//getSelectedBasis
  getSelBasis(): string {
    console.log('TEst---');
    let retnValue = '';
    this.propertyValBasisOptions[0].data.forEach((val: DropDownType) => {

      if (val.id == this.attMap['Basis'][0]) {
         retnValue = val.text;
      }

    });
    return retnValue;
  }*/

  //getCurrencyText
  getSelCurrency(valFor?: number): string {
    let retVal = '';
    this.currencyOptions[0].data.forEach((val: DropDownType) => {
      if (this.attMap['Currency'] && val.id == this.attMap['Currency'][0] && valFor == 0) {
        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][1] && valFor == 1) {

        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][2] && valFor == 2) {

        retVal = val.text;
      }

    });

    return retVal;
  }

  getSelShares(): string {
    let retVal = '';
    this.stockTypeOptions[0].data.forEach((val: DropDownType) => {
      if (this.attMap['StockType304'] && val.id == this.attMap['StockType304'][0]) {
        retVal = val.text;
      }
    });

    return retVal;
  }

  totalAmt(amtFor?: String): number {
    let totalAmt = 0;
    if (amtFor == 'BasisAmt' && this.attMap['BasisAmt'] !== undefined) {
      this.attMap['BasisAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });

    } else if (amtFor == 'FMVAmt' && this.attMap['FMVAmt'] !== undefined) {
      this.attMap['FMVAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });
    } else if (amtFor == 'BasisAmt1' && this.attMap['BasisAmt1'] !== undefined) {

      this.attMap['BasisAmt1'].forEach((amt: number) => {
        //console.log(amt);
        totalAmt += Number(amt);
      });
    }
    return totalAmt;
  }

  dummyFunc(event: any): void {

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
