
<!-- <div class="gt-dashboard-ex" [ngStyle]="{'height': expand ? '100%' : '35px'}"> -->
<div class="gt-dashboard-ex"> 
  
  <div class="gt-dashboard-ex-header" >
      
        <span class="chev pull-left  appkiticon" (click)="expandClose()" 
                [ngClass]="{'icon-down-chevron-outline':expand, 'icon-right-chevron-outline':!expand
                            ,'expand-trans':expand}">
        </span>   
      
        <h5 class="pull-left">{{tableSheet[0].name}}</h5>
        
        <!-- <div class="action-items">
          <span ><mat-slide-toggle [(ngModel)]="checked" (change)="toggle()"></mat-slide-toggle></span>
          <span> Hide Resolved Diagnostics</span>   -->
         <!--  <span class="appkiticon icon-table-data-outline" matTooltip="View Raw Data" ></span>
          <span class="appkiticon icon-edit-outline" data-toggle="modal" 
                (click)="showEditComponent(tableSheet)"
          ></span>    -->
          <!-- <span class="appkiticon icon-expand-outline"></span> -->
        <!-- </div> -->
    </div>
    <div class="gt-dashboard-ex-body" *ngIf="expand" >
          <ng-container >
            <p-table #dt [columns]="tableSheet[0].extraOptionsJSON.componentSettings.tableOptions.columns"
            [value]="tableSheet[0].sheet.data" 
             [loading]="!tableSheet[0].sheet.isLoaded"
                 [virtualRowHeight]="40">
                 <ng-template pTemplate="header" let-columns>
                   <!-- <tr [style.height]="'35px'"> -->
                     <tr style="height: 30px;padding: 5px;">
 
                     <ng-container *ngFor="let col of columns; index as i" >
                       <th style="width:30px" *ngIf="col.data === null"></th>
                       <th *ngIf="col.data != null && col.visible" [pSortableColumn]="col.data" >
                         <span>{{ col.title }}
                         <p-sortIcon [field]="col.data"></p-sortIcon>
                         </span>
                       </th>
                     </ng-container>
                   </tr>
               </ng-template>
               <ng-template #template3>
                <div class="tooltip-inner a-p-10 d-block a-min-width-300">
                    <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
                      Click to navigate to the area where you can resolve Diagnostics
                    </div>
                </div>
            </ng-template>
            <ng-template #template4>
              <div class="tooltip-inner a-p-10 d-block a-min-width-300">
                  <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
                    Click to here to view these entites in Detail Diagnostis
                  </div>
              </div>
          </ng-template>
               <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
                 <tr style="height: 30px;padding: 5px;">
                   <td *ngFor="let col of columns" [ngClass]="{'drill-down': col.data == null}">
                           <span *ngIf="(col.data != null && rowData[col.data] === 'Critical') || 
                                        (col.data != null && rowData[col.data] === 'Informational')" 
                          
                                       class="appkiticon"     
                                       [ngClass]="{'icon-alert-fill': rowData[col.data] === 'Critical',
                                           'icon-information-fill': rowData[col.data] === 'Informational'}"
                                           [ngStyle]="{'color': rowData[col.data] === 'Critical' ? '#D04A02' : '#212121',
                                         'margin-right':'10px'}">
                           </span>
                           <!-- [tooltip-container]="template4"
                           [placement]="'bottom'" -->
                             <span *ngIf="col.data == 'FAILCNT'" [ngStyle]="{'color':(rowData[col.data] > 0 && rowData.DIAG_MSG == 'Critical') ? '#D04A02' : '#212121'}"
                            
                                   (click)="redirectDrillDown(rowData,i)">
                              {{rowData[col.data]}}</span>
                            <span *ngIf="col.data != null && col.data != 'FAILCNT'" [ngStyle]="{'color':rowData[col.data] > 0 ? 'red' : 'black'}">
                                {{rowData[col.data]}}</span>
                             <!-- <span  style="padding: 0px 8px 0px 8px" class="appkiticon icon-link-outline" 
                                   *ngIf="col.data == null && col.className != undefined" 
                                   [tooltip-container]="template3"
                                   [placement]="'bottom'"
                                   (click)="redirectDrillDown(rowData,i)"></span> -->
                               <span style="padding: 0px 8px 0px 8px" class="appkiticon icon-pencil-outline"
                               *ngIf="col.data == null && col.className != undefined" 
                               [tooltip-container]="template3"
                               [placement]="'bottom'"
                               (click)="showEditComponent(rowData, baseURLs)"></span>
                   </td>
                 </tr>
               </ng-template>
               <ng-template pTemplate="emptymessage" let-columns>
                 <tr>
                     <td [attr.colspan]="columns.length" style="text-align: center">
                         No records found
                     </td>
                 </tr>
             </ng-template>
            </p-table>
          </ng-container>
                         
          
      <gtw-detail-diag-view (loadDrillDownScreen)="loadDrillDownScreen($event)" *ngIf="showAddEditModalViewer" (afterModalClosed)="afterModalClosed()"></gtw-detail-diag-view>
<!--       <gtw-add-edit *ngIf="showAddEditModalViewer" (afterModalClosed)="afterModalClosed()"></gtw-add-edit>
 -->

       