import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponsiveTabComponent } from 'appkit-angular/responsive-tab';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { MefEfileService } from './mef-efile-changes.service';
import { AdminService } from '../../admin.service';


@Component({
  selector: 'gtw-mef-efile-changes',
  templateUrl: './mef-efile-changes.component.html',
  styleUrls: ['./mef-efile-changes.component.scss']
})
export class MefEfileChangesComponent implements OnInit, AfterViewInit {
  disabled: boolean = true;
  isParent: boolean = false;
  syncButtonsEnabled: boolean = false;
  clobDataEmpty: boolean = true;
  allCheckboxesDisable: boolean = false;
  fgEmpty: boolean = false;
  mefTablesDisable: boolean = false;
  disableSave: boolean = false;
  beginDate = new Date(2019, 0, 1);
  endDate = new Date();
  private scenarioCode: string = '';
  private isIssueKeyEnabled: string = '';
  private isLocked: string = '';
  private issueKey: number = 0;

  @ViewChildren(ResponsiveTabComponent) resTabs!: QueryList<ResponsiveTabComponent>;
  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();

  public secondaryConfig = {
    isSecondary: true
  }

  public primaryConfig = {
    isPrimary: true
  }
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
  @Input('row-data')
  set _row_data(rowData: string) {
    try {

      let params = JSON.parse(rowData);

      if (params != undefined) {
        this.rowData = params;
        if (this.rowData.IS_PARENT === 'Y') {
          this.isParent = true;
        }
        this.initializeValues();

      }
    } catch (e) {
      console.log("rowData Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
  }

  indexZero = 0;
  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
  rowData: any;
  clob_data: any[] = [];
  loader: boolean = false;
  formOne!: FormGroup;
  formTwo!: FormGroup;
  constructor(private mefEfileService: MefEfileService, private datePipe: DatePipe, private _snackBar: MatSnackBar, public dialog: MatDialog, public service: AdminService) {
    this.formOne = new FormGroup({
      consolGroupKey: new FormControl('', [Validators.required]),
      filingGroupName: new FormControl(''),
      parentLeKey: new FormControl(''),
      returnDate: new FormControl('', [Validators.required]),
      irsCenterName: new FormControl('VERMONT', [Validators.required]),
      filterTaxYearBegin: new FormControl('', [Validators.required]),
      filterTaxYearEnd: new FormControl('', [Validators.required])
    });
    this.formTwo = new FormGroup({
      leKey: new FormControl(''),
      filerStatus: new FormControl('A'),
      dateFiled: new FormControl('', [Validators.required]),
      lockYN: new FormControl(''),
      fgnDomFlag: new FormControl(''),
      leConsolTypeFlag: new FormControl(''),
      filingGroupKey: new FormControl('', [Validators.required])

    });
    this.service.currentMessage.subscribe(process_name => this.sync(process_name));
    this.service.initiate.subscribe(val => this.dialog.closeAll());
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }


  initializeValues(): void {
    this.formOne.patchValue({
      consolGroupKey: this.rowData.CONSOL_GROUP_KEY,
      filingGroupName: this.rowData.LE_NAME,
      parentLeKey: this.rowData.LE_KEY,
      returnDate: new Date(this.rowData.RETURN_DATE),
      filterTaxYearBegin: new Date(this.rowData.FILER_TAX_YEAR_BEGIN),
      filterTaxYearEnd: new Date(this.rowData.FILER_TAX_YEAR_END)
    });
    this.formTwo.patchValue({
      leKey: this.rowData.LE_KEY,
      lockYN: 'N',
      fgnDomFlag: this.rowData.SOURCE_SYSTEM_FLAG,
      dateFiled: '',
      filingGroupKey: this.rowData.FILING_GROUP_KEY,
    });

    if (['HO', 'BR', 'TXN'].includes(this.rowData.LE_TYPE)) {
      this.syncButtonsEnabled = true;
      this.mefTablesDisable = false;
    }
    else {
      this.syncButtonsEnabled = false;
      this.mefTablesDisable = true;
    }

    if (!("FILING_GROUP_KEY" in this.rowData) && !("MEF_GROUP_KEY" in this.rowData)) {
      this.allCheckboxesDisable = true;
    }
    else {
      this.allCheckboxesDisable = false;
    }

    if (!("FILING_GROUP_KEY" in this.rowData)) {
      this.fgEmpty = true;
    }
    else {
      this.fgEmpty = false;
    }

    if (this.rowData.LE_TYPE === 'HO') {
      if (this.fgEmpty) {
        this.disableSave = false;
      }
      else {
        this.disableSave = true;
      }
    }
    else {
      this.disableSave = true;
    }


  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  clicked(event: EventEmitter<Object> | any, tableName: string, priority: number) {
    if (event.srcElement.checked) {
      this.add(tableName, priority);
    }
    else {
      this.remove(tableName);
    }
    this.sortData();
    if (this.clob_data.length > 0) {
      this.clobDataEmpty = false;
    }
    else {
      this.clobDataEmpty = true;
    }
  }

  add(tableName: string, priority: number): void {

    this.clob_data.push({
      ...this.rowData,
      table_name: tableName,
      type: 'D',
      priority: priority
    })
  }

  remove(tableName: string): void {
    this.clob_data.forEach((data: any, index: number) => {
      if (tableName == data.table_name) {
        this.clob_data.splice(index, 1);
      }
    })
  }

  sortData(): void {
    this.clob_data.sort((a, b) => {
      return a.priority - b.priority;
    })
  }

  deleteMefEfileData() {
    if (this.clobDataEmpty) {
      this.mefEfileService.openSnackBar("Please select a table to delete!", 'gtw-snackbar--red');

    } else {
      this.loader = true;
      let params = {
        tax_year: this.baseGlobalParams?.tax_year,
        scenario: this.baseGlobalParams?.scenario,
        jcd_key: this.baseGlobalParams?.jcd_key,
        le_type: this.rowData.LE_TYPE,
      }
      let clob_settings = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
      this.mefEfileService.deleteMEf(this.baseURLs.api, params, clob_settings, this.clob_data, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey,).subscribe((data) => {
        if (data !== 'error') {
          this.requestGridUpdate.emit('refresh');
          this.loader = false;
        }
        else {
          console.log("Error while saving");
          this.loader = false;
        }
      }, () => {
        console.log("Error while saving");
        this.loader = false;
      });
    }

  }

  public sync(process_name: string) {
    if (this.rowData) {
      let params = {
        tax_year: this.baseGlobalParams?.tax_year,
        scenario: this.baseGlobalParams?.scenario,
        jcd_key: this.baseGlobalParams?.jcd_key,
        le_key: this.rowData.LE_KEY,
        filing_grp_key: this.rowData.FILING_GROUP_KEY ? this.rowData.FILING_GROUP_KEY : null,
        consol_grp_key: this.rowData.CONSOL_GROUP_KEY,
        mef_grp_key: this.rowData.MEF_GROUP_KEY ? this.rowData.MEF_GROUP_KEY : null,
        grp_type: this.rowData.GROUP_TYPE,
        process_name: process_name,
        form_filer_ind: this.rowData.FORM_FILER_IND,
      }
      this.mefEfileService.sync(this.baseURLs.api, params, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey,)?.subscribe((data) => {
        if (data !== 'error') {
          this.requestGridUpdate.emit('refresh');
          this.loader = false;
        }
        else {
          console.log("Error while saving");
          this.loader = false;
        }
      }, () => {
        console.log("Error while saving");
        this.loader = false;
      });
      this.dialog.closeAll();
    }

  }

  insertFilingData() {
    if(this.formOne.status === 'INVALID') {
      this.mefEfileService.openSnackBar("Please enter all the required fields!", 'gtw-snackbar--red');
      return;
    }
    this.loader = true;
    
    let params = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      type: "I",
    }
    let clob_settings = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
    let clob_data = [{
      le_key: this.rowData.LE_KEY,
      consol_group_key: this.rowData.CONSOL_GROUP_KEY,
      le_name: this.rowData.LE_NAME,
      parent_le_key: this.rowData.parent_le_key,
      return_date: this.formOne.value.returnDate.startDate.format('yyyy-MM-DD'),
      irs_center_name: this.formOne.value.irsCenterName,
      filer_tax_year_begin: this.formOne.value.filterTaxYearBegin.startDate.format('yyyy-MM-DD'),
      filter_tax_year_end: this.formOne.value.filterTaxYearEnd.startDate.format('yyyy-MM-DD'),
      table_name: 'TGT_FILING_GROUP'
    }]
    this.mefEfileService.saveFilingData(this.baseURLs.api, params, clob_settings, clob_data, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey,).subscribe((data) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        console.log("Error while saving");
        this.loader = false;
      }
    }, () => {
      console.log("Error while saving");
      this.loader = false;
    });
  }

  insertEntityAssignData() {
    if(this.formTwo.status === 'INVALID') {
      this.mefEfileService.openSnackBar("Please enter all the required fields!", 'gtw-snackbar--red');
      return;
    }
    this.loader = true;
    console.log(this.formOne)
    let params = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      type: "I",
    }
    let clob_settings = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
    let clob_data = [{
      filing_group_key: this.formTwo.value.filingGroupKey,
      le_key: this.rowData.LE_KEY,
      filer_status: 'A',
      date_filed: this.formTwo.value.dateFiled.startDate.format('yyyy-MM-DD'),
      lock_y_n: this.formTwo.value.lockYN,
      source_system_flag: this.formTwo.value.fgnDomFlag,
      le_consol_type_flag: this.formTwo.value.leConsolTypeFlag,
      table_name: 'TGT_FILING_ENTITY_ASSIGN'
    }]
    this.mefEfileService.saveFilingData(this.baseURLs.api, params, clob_settings, clob_data, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey,).subscribe((data) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        console.log("Error while saving");
        this.loader = false;
      }
    }, () => {
      console.log("Error while saving");
      this.loader = false;
    });
  }

  cancel() {
    this.requestGridUpdate.emit('');
  }

  onTabChanged(event: any): void {
    this.resTabs.last.onFocusTabItem(event);
  }
  openDialog(): void {
    this.dialog.open(ConfirmDialog, {
      width: '400px',
    });
  }
}


@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
  styleUrls: ['./mef-efile-changes.component.scss']
})
export class ConfirmDialog {
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, public service: AdminService) { }
  public sync(process_name: string) {
    this.service.informSync(process_name);
  }
  cancel() {
    this.service.informClose();
  }
  public secondaryConfig = {
    isSecondary: true
  }

}