<mat-tab-group mat-align-tabs="start" #tabGroup>
    <mat-tab label="Videos">
        <gtw-screen-video-list [screen-key]=screenKey [client-key]=clientKey [base-url]=baseURL
            (updateScreenVideoEvent)="updateScreenVideo($event);tabGroup.selectedIndex=1;" 
            [refresh]="refreshScreenVideoListOutput"
            #screenVideoList>
        </gtw-screen-video-list>
    </mat-tab>
    <mat-tab label="Upload" *ngIf="isValidUser()">
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="chidTabSelectedIndex"
        (selectedTabChange)="onTabChanged($event)">
            <mat-tab label="Video">
                 <gtw-screen-video-upload [screen-key]=screenKey [client-key]=clientKey [base-url]=baseURL
                        [updateScreenVideo]="updateScreenVideoInput"
                        (refreshScreenVideoList)="refreshScreenVideoList($event);" ></gtw-screen-video-upload>
            </mat-tab>
            <mat-tab label="Document">
                <gtw-screen-document-upload [childTabIndex]="childTabIndex" [screen-key]="screenKey" [client-key]="clientKey" [base-url]=baseURL
                    [updateDocSelected]="updateDocSelectedInput"
                    (refreshScreenDocumentList)="refreshScreenDocumentList($event);"
                >
                    </gtw-screen-document-upload>
                </mat-tab>
        </mat-tab-group>
     </mat-tab>

    <mat-tab label="Documents">
        <gtw-screen-document-list [screen-key]=screenKey [client-key]=clientKey
                [base-url]=baseURL [refresh]="refreshScreenDocumentOutput"
                (updateDocSelectedEvent)="updateDocSelected($event); tabGroup.selectedIndex = 1" 
            ></gtw-screen-document-list>
    </mat-tab>
</mat-tab-group>
