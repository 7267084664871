
import { DfAssignEntityService } from './df-assign-entity.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import _ from 'lodash';
@Component({
  selector: 'gtw-df-assign-entity',
  templateUrl: './df-assign-entity.component.html',
  styleUrls: ['./df-assign-entity.component.scss']
})
export class DfAssignEntityComponent implements OnInit {
  formRows: any;

  constructor(private dfAssignEntityService: DfAssignEntityService, private gtwSnackbarService: GtwSnackbarService, private fb: FormBuilder
  ) {



  }


  public secondaryConfig = {
    isSecondary: true
  }
  public primaryConfig = {
    isPrimary: true
  }

  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();
  form: FormGroup = new FormGroup({});
  initializeComplete: boolean = true;
  loader: boolean = true;
  rowData: any;
  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
  entityAssignData: any;
  tax_year: any;
  scenario: any = "";
  private scenarioCode: any = '';
  private isIssueKeyEnabled: string = '';
  private issueKey: number = 0;
  ssoId: string = '';
  dfDetails: any;
  ptrDropdown: any;
  pshipDropdown: any;
  details: any;
  isLoadingMap: { [key: number]: boolean } = {};
  ptrDropdownMap: { [key: number]: any[] } = [];

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
  @Input('row-data')
  set _row_data(rowData: string) {
    try {
      if (rowData != '{{ctrl.rowData}}') {
        this.initializeComplete = true;
      }
      let params = JSON.parse(rowData);

      if (params != undefined && params != '') {
        this.rowData = params;

      }
    } catch (e) {
      console.log("rowData Params not stable. Still waiting to get resolved.");
    }
  }


  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("tax-year")
  set _tax_year(tax_year: string) {
    try {
      let params = JSON.parse(tax_year);
      if (params != undefined) {
        this.tax_year = tax_year;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario")
  set _scenario(scenario: string) {
    try {
      let params = JSON.parse(scenario);
      if (params != undefined) {
        this.scenario = scenario;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }


  @Input("sso-id")
  set _ssoId(ssoId: string) {
    if (!_.startsWith(ssoId, "{{")) {
      this.ssoId = ssoId;
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }


  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
        this.loadData();
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }


  ngOnInit(): void {

  }

  loadData() {
    let params = {
      tax_year: this.tax_year,
      scenario: this.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      stage_key: this.rowData?.TRANS_NO,
    }
    this.dfAssignEntityService.getEntities(this.baseURLs.api, params).pipe(
      catchError(error => {
        console.error('An error occurred:', error);
        this.loader = false;
        return of(null);
      })
    ).subscribe((data: any) => {
      if (data) {
        this.entityAssignData = data;
        this.dfDetails = data[0].jsonObject;
        if (!this.dfDetails || this.dfDetails.length === 0) {
          this.gtwSnackbarService.openSnackBar("No data found", 'gtw-snackbar--red');
          return;
        }
        this.ptrDropdownMap = {};

        forkJoin(
          this.dfDetails.map((detail: any, index: any) =>
            this.dfAssignEntityService.fetchPtrDropdown(detail.PSHIP_CC_KEY, this.baseURLs.api, params).pipe(
              catchError(error => {
                console.error(`Error fetching dropdown for detail at index ${index}:`, error);
                return of([]);
              })
            )
          )
        ).subscribe(results => {
          results.forEach((result: any, index) => {
            this.ptrDropdownMap[index] = result.jsonObject as any[];
          });
          this.pshipDropdown = data[1].jsonObject;
          if (!this.pshipDropdown || this.pshipDropdown.length === 0) {
            console.log('pshipDropdown is empty');
          }

          this.formRows = this.fb.array([]);
          this.form = this.fb.group({
            rows: this.formRows
          });
          this.dfDetails.forEach((detail: { PSHIP_NAME: any; PSHIP_EIN: any; PTR_NAME: any; PTR_EIN: any; PSHIP_CC_KEY: any; PTR_CC_KEY: any; }) => {
            this.details = this.findDetails(detail, this.ptrDropdownMap, this.formRows.length);
            const row = this.fb.group({
              TaxYear: [this.tax_year, Validators.required],
              InvestmentName: [detail.PSHIP_NAME, Validators.required],
              InvestmentTaxId: [detail.PSHIP_EIN, Validators.required],
              InvestorName: [detail.PTR_NAME, Validators.required],
              InvestorTaxId: [detail.PTR_EIN, Validators.required],
              Old_Pship_CC_Key: [detail.PSHIP_CC_KEY || ''],
              Old_Ptr_CC_key: [detail.PTR_CC_KEY || ''],
              Pship_CC_Key: [detail.PSHIP_CC_KEY || ''],
              Ptr_CC_key: [detail.PTR_CC_KEY || ''],
              Posting_Ptr: [this.details.ptrDetail?.POSTING_PTR || ''],
              Stage_key: [this.rowData.TRANS_NO || '']
            });
            this.formRows.push(row);
          });
          this.loader = false;
        });
      }
    });
  }

  findDetails(dfDetails: any, ptrDropdownMap: { [key: number]: any[] }, mapKey: number) {
    let ptrDetail;
    const ptrDropdown = ptrDropdownMap[mapKey];

    if (ptrDropdown) {
      let ptrItem = ptrDropdown.find(item => item.PTR_CC_KEY === dfDetails.PTR_CC_KEY);
      if (ptrItem) {
        ptrDetail = {
          PTR_DTLS: ptrItem.PTR_DTLS,
          POSTING_PTR: ptrItem.POSTING_PTR
        };
      }
    }

    return { ptrDetail };
  }

  onSelectChange(event: any, rowIndex: number) {
    const selectedValue = parseInt(event.target.value, 10);
    let items = this.ptrDropdownMap[rowIndex];
    if (items) {
      let selectedItem = items.find(item => item.PTR_CC_KEY === selectedValue);
      if (selectedItem) {
        const group = <FormGroup>this.formRows.controls[rowIndex];
        group.get('Posting_Ptr')?.setValue(selectedItem.POSTING_PTR);
      }
    }
  }
  onPshipDropdownChange(event: any, rowIndex: number): void {
    this.isLoadingMap[rowIndex] = true;
    const selectedPshipCCKey = event.target.value;

    const group = <FormGroup>this.formRows.controls[rowIndex];
    group.get('Posting_Ptr')?.setValue('');
    group.get('Ptr_CC_key')?.setValue('');
    let params = {
      tax_year: this.tax_year,
      scenario: this.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      stage_key: this.rowData?.TRANS_NO,
    }
    this.dfAssignEntityService.fetchPtrDropdown(selectedPshipCCKey, this.baseURLs.api, params).subscribe(ptrDropdownResult => {
      this.ptrDropdownMap[rowIndex] = ptrDropdownResult.jsonObject;
      this.isLoadingMap[rowIndex] = false;
    }, error => {
      console.error('Error fetching dropdown data:', error);
      this.isLoadingMap[rowIndex] = false;
    });
  }

  cancel() {
    this.requestGridUpdate.emit('');
  }

  save(): void {

    if (this.form.status === 'INVALID' || this.loader == true) {
      this.gtwSnackbarService.openSnackBar("Please check form details and try again", 'gtw-snackbar--red');
      return;
    }
    this.loader = true;
    const formValues = this.formRows.controls.map((control: { value: any; }) => control.value);
    let params = {
      tax_year: this.tax_year,
      scenario: this.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key
    }
    let clob_settings = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
    let clob_data = formValues;
    this.dfAssignEntityService.saveEntityAssign(this.baseURLs.api, params, clob_settings, clob_data, this.scenarioCode, this.isIssueKeyEnabled).subscribe((data: any) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        this.gtwSnackbarService.openSnackBar("Please check form details and try again", 'gtw-snackbar--red');
        this.loader = false;
      }
    }, () => {
      this.gtwSnackbarService.openSnackBar("Error while saving", 'gtw-snackbar--red');
      this.loader = false;
    });
  }

}

