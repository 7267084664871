import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng-lts/table';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { JSONExportHeader, JSONExportService } from '../../../shared/_services/JSONExport.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { StatementTrackerService } from '../../statement-tracker/statement-tracker.service';
import { DiagnosticsService } from '../diagnostics.service';

@Component({
  selector: 'gtw-na-report',
  templateUrl: './na-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './na-report.component.scss']
})
export class NaReportComponent implements OnInit, OnChanges {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild('dt') table!: Table;
  rows: number = 15;
  first: number = 0;
  data: any[] = [];
  cols: any[] = [
    {
      field: 'TAXYEAR',
      header: 'Year',
      hidden: false,
      width: '80px'
    },
    {
      field: 'LEID',
      header: 'LEID',
      hidden: false,
      width: '100px'
    },
    {
      field: 'FORMNAME',
      header: 'Form',
      hidden: false,
      width: '290px'
    },
    {
      field: 'CODESECTIONNAME',
      header: 'Code Section',
      hidden: false,
      width: '180px'
    },
    {
      field: 'BUSINESSNAME',
      header: 'Business',
      hidden: false,
      width: '220px'
    },
    {
      field: 'ENTITYNAME',
      header: 'Entity Name',
      hidden: false,
      width: '210px'
    },
    {
      field: 'IGNOREREASON',
      header: 'Reasons Ignored For',
      hidden: false,
      width: '180px'
    },
    {
      field: 'IGNOREREASONDETAILS',
      header: 'Details of Reasons Ignored',
      hidden: false,
      width: '180px'
    },
    {
      field: 'MODIFIEDBY',
      header: 'Marked N/A By',
      hidden: true,
    },
    {
      field: 'MODIFIEDON',
      header: 'Modified On',
      hidden: true,
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;

  constructor(private cDSerivce: CreateDisclosureService,
    private dstSharedService: DSTSharedService,
    private diagnosticsService: DiagnosticsService,
    private jsonExportService: JSONExportService,
    private trackerService: StatementTrackerService) {

    this.excelExportFileName = 'Tracker-NA_Diagnostics_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setSSO('400394467');
    // this.dstSharedService.setTaxYearScenario('2020', '40');
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
  }

  ngOnInit(): void {

    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2020');
    //   this.loadReport();
    // });
    // this.dstSharedService.setDSTUser('400394467', 5);

  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.loadReport();
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.diagnosticsService.loadNAReport(this.taxYear?.text.toString()!).subscribe((data) => {
      this.data = data;
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the N/A Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'TAXYEAR',
        header: 'Year',
      },
      {
        field: 'LEID',
        header: 'LEID',
      },
      {
        field: 'FORMNAME',
        header: 'Form',
      },
      {
        field: 'CODESECTIONNAME',
        header: 'Code Section',
      },
      {
        field: 'BUSINESSNAME',
        header: 'Business',
      },
      {
        field: 'ENTITYNAME',
        header: 'Entity Name',
      },
      {
        field: 'IGNOREREASON',
        header: 'Reasons Ignored For',
      },
      {
        field: 'IGNOREREASONDETAILS',
        header: 'Details of Reasons Ignored',
      },
      {
        field: 'MODIFIEDBY',
        header: 'Marked N/A By',
      },
      {
        field: 'MODIFIEDON',
        header: 'Modified On',
      }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
