<dst-create-disclosure-actions [isView]="true" (onSaveClick)="save()" (onCancelClick)="cancel()"
  [disableS3Upload]="false" (onS3UploadClick)="s3Upload()" 
  [isFiled]="formData?.isfiled" [isQAR]="formData?.isqar"
  [disabledS3]="s3Uploading" [statementId]="formData?.statementid">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp368form186">
  <div>
    <div align=center>
      <b>
      <span class="template-color-blue">
        {{formData?.dcnname}}
      </span>
      </b>
    </div>
    <div align="center">
      <B>
      <span class="template-color-blue">
        {{formData?.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN
        <br/>
        STATEMENT PURSUANT TO REGULATION 1.368-3{{formState === 3 ? '(a)' : ''}} BY A CORPORATION,&nbsp;
        <br/>
        A PARTY TO A REORGANIZATION AND BY A SIGNIFICANT SHAREHOLDER
      </span>
      </B>
    </div>
    <br/>
    
    <div class="statement-body" *ngIf="formState === 3">
      <p>
        Pursuant to Section 1.368-3(a) of the Regulations, the following information is furnished:
      </p>
      <p>
        On <span class="template-color-blue">{{(attMap.EffectiveDate && attMap.EffectiveDate[1]) || ''}}, {{primaryEntityName}}
        , EIN: {{(formData?.primaryein) || 'N/A'}}</span><br>
        <span class="template-color-blue">{{attMap.NameChangeDesc}}</span> in a reorganization which qualifies under Internal Revenue Code Section
        368(a)(1)(F), as a mere change in identity, form or place of organization.
      </p>
      <p>
        Records regarding the change are maintained by the taxpayer as required under Treasury Regulation Section 1.368-3(d) and are available upon request.
      </p>
      <p>
        The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in connection with this reorganization:
        <span class="template-color-blue">{{(attMap.LiquidationDate && attMap.LiquidationDate[0]) || 'None.'}}</span>
      </p>
    </div>
    <div class="statement-body" *ngIf="formState === 2">
      <p>
        Pursuant to Section 1.368-3 of the Regulations, the following information is furnished:
      </p>
      <ol>
        <li class="pl-3">
          <p><span class="template-color-blue">{{primaryEntityName}}</span>, EIN:<span class="template-color-blue">{{formData?.primaryein}}</span>
            (formerly known as <span class="template-color-blue">{{transfereeName}}</span>)</p>
        </li>
        <li class="pl-3">
          <p>On <span class="template-color-blue">{{(attMap.EffectiveDate && attMap.EffectiveDate[0]) || ''}},</span>&nbsp;<span class="template-color-blue">{{transfereeName}}</span>
          <span *ngIf="attMap.additionaltext && attMap.additionaltext[0]"> converted to a <span class="template-color-blue">{{attMap.additionaltext}}</span> and</span> changed its name to
          <span class="template-color-blue">{{primaryEntityName}}</span>, in a reorganization which qualifies under
          Internal Revenue Code Section 368(a)(1)(F), as a mere change in identity, form or place of organization.</p>
        </li>
        <li class="pl-3">
          <p>Records regarding the name change are maintained by the taxpayer as required under Treasury Regulation Section 1.368-3(d) and are available upon request.</p>
        </li>
        <li class="pl-3">
          <p>The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in connection with this reorganization.</p>
          <p>{{(attMap.LiquidationDate && attMap.LiquidationDate[0]) || 'None.'}}</p>
        </li>
      </ol>
    </div>
    <div class="statement-footer">
      <div class="d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <span class="template-color-blue">{{formData?.primaryleid}}</span>
      </div>
      <div class="d-flex" style="margin-top: 15px;">
        <span>Statement ID: </span>
        <span class="template-color-blue">{{formData?.statementid}}</span>
      </div>
    </div>
  </div>
</div>
