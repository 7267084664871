import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ScreenVideoDialogModel } from '../../screen-video-list/screen-video-dialog/screen-video-dialog-model';
import { ScreenVideoDialogComponent } from '../../screen-video-list/screen-video-dialog/screen-video-dialog.component';

@Component({
  selector: 'gtw-document-view-dialog',
  templateUrl: './document-view-dialog.component.html',
  styleUrls: ['./document-view-dialog.component.scss']
})
export class DocumentViewDialogComponent implements OnInit {

  correct = false
  documentData: any;
  constructor(public dialogRef: MatDialogRef<ScreenVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScreenVideoDialogModel) { }

  ngOnInit() {
    if(this.data){
      this.documentData  = this.data;

    }


  }

}
