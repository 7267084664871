import {Injectable} from '@angular/core';
import {DSTSharedService} from '../../dst-shared.service';
import {SaveActionResponse} from '../../../../shared/_models/save-action-response.model';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {DSTStatement} from '../../models/dst-statement.model';
import {StatementTrackerService} from '../../../statement-tracker/statement-tracker.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActionService} from 'projects/gtw-elements/src/app/shared/_services/action.service';
import {DSTMarkAsOption} from './dst-mark-as-template/dst-mark-as-template.component';
import {ActionResponse} from '../../../../shared/_models/action-response.model';
import {DSTChildFormState} from '../../models/dst-child-form-state.model';

@Injectable({
  providedIn: 'root'
})
export class StmtIdBtnService {

  // remove me after commit

  deleteAction: string = '';

  constructor(private dstSharedService: DSTSharedService,
              private statementTrackerService: StatementTrackerService,
              private actionService: ActionService) {

  }

  setReviewed(statementId: string, reviewed: number | boolean): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'statementId': statementId,
        'reviewed': reviewed ? '1' : '0'
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32140, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  delete(rowObject: DSTStatement): Observable<boolean> {
    const subject: Subject<boolean> = new Subject<boolean>();
    this.dstSharedService.setTableActionStatus(true);
    if (this.deleteAction === 'deactivate') {
      this.deactivateStatement(rowObject.statementId)
        .subscribe((response: SaveActionResponse) => {
          if (response.callSuccess == 1) {
            this.dstSharedService.showAlert('success', 'Statement has been deleted successfully!');
            subject.next(true);
          } else {
            this.dstSharedService.showAlert('error', 'Something went wrong while processing your request, please try again!');
            subject.next(false);
          }
        }, (error: HttpErrorResponse) => {
          this.dstSharedService.showAlert('error', error.message);
          subject.next(false);
        })
        .add(() => {
          this.dstSharedService.setTableActionStatus(false);
        });
    } else if (this.deleteAction === 'delete') {
      this.deleteStatement(rowObject.csStatementId)
        .subscribe((response: SaveActionResponse) => {
          if (response.callSuccess == 1) {
            this.dstSharedService.showAlert('success', 'Statement requirement has been deleted successfully!');
            subject.next(true);
          } else {
            this.dstSharedService.showAlert('error', 'Something went wrong while processing your request, please try again!');
            subject.next(false);
          }
        }, (error: HttpErrorResponse) => {
          this.dstSharedService.showAlert('error', error.message);
          subject.next(false);
        })
        .add(() => {
          this.dstSharedService.setTableActionStatus(false);
        });
    }
    return subject.asObservable();
  }

  unmatch(rowObject: DSTStatement): void {
    this.dstSharedService.setTableActionStatus(true);
    this.unmatchStatement(rowObject.statementId, rowObject.csStatementId)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Statement has been unmatched successfully!');
          // this.saveSuccess.emit(); TODO
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while processing your request, please try again!');
        }
      }, (error: HttpErrorResponse) => {
        this.dstSharedService.showAlert('error', error.message);
      })
      .add(() => {
        rowObject.showDropdown = false;
        this.dstSharedService.setTableActionStatus(false);
      });
  }

  deactivateStatement(statementId: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'statementId': statementId.toString(),
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32139, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  saveProjectTagging(statementId: string, projectId: number | string, projectStepId: number | string, projectBSLACode: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'statementId': statementId,
        'projectId': projectId.toString(),
        'projectbslacode': projectBSLACode,
        'projectStepId': projectStepId.toString()
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32150, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  markAs(rowObject: DSTStatement, reason: DSTMarkAsOption): void {
    rowObject.showDropdown = false;
    rowObject.showExtendedMenu = false;
    rowObject.ignoredId = reason.REASONID;
    rowObject.ignoreReasonDetails = reason.REASON;
    this.dstSharedService.setTableActionStatus(true);
    this.markAsNAForReason(rowObject.cseId, reason.REASONID, reason.REASON)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Status change saved successfully!');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving your changes, please try again!');
        }
      }, (error: HttpErrorResponse) => {
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  getChildStatements(statementId: string): Observable<DSTChildFormState[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id': statementId
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32483, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('Failed to get child statement information, please try again!');
          }
        })
      );
  }

  private markAsNAForReason(cseId: number, reasonId: number, reasonDetails: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'cseId': cseId.toString(),
        'reasonId': reasonId.toString(),
        'reasonDetails': reasonDetails
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32138, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  private deleteStatement(csStatementId: number): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'csStatementId': csStatementId.toString(),
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32137, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  public hardDeleteStatementWithChild(csStatementId: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'statement_id': csStatementId.toString(),
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32805, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  private unmatchStatement(statementId: string, csStatementId: number): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id': statementId,
        'cs_statement_id': csStatementId.toString()
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32314, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

}
