import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DSTStatement} from '../../models/dst-statement.model';
import {MenuPosPayload} from './smartMenuPos.directive';
import {DSTMarkAsOption, DSTMarkAsTemplateComponent} from './dst-mark-as-template/dst-mark-as-template.component';
import {DSTDropdownMenuComponent} from './dst-dropdown-menu/dst-dropdown-menu.component';
import {StmtIdBtnService} from './stmt-id-btn.service';
import {DSTSharedService} from '../../dst-shared.service';

@Component({
  selector: 'dst-stmt-id-btn',
  templateUrl: './stmt-id-btn.component.html',
  styleUrls: ['./stmt-id-btn.component.scss']
})
export class StmtIdBtnComponent implements OnInit {

  @Input() mode: string = '';
  @Input() gridData: DSTStatement[] = [];
  @Input() rowData!: DSTStatement;
  @Input() markAsOptions: DSTMarkAsOption[] = [];
  @Output() actionClick: EventEmitter<StmtBtnActionPayload> = new EventEmitter<StmtBtnActionPayload>();

  @ViewChild(DSTDropdownMenuComponent) dropdownMenuComp!: DSTDropdownMenuComponent;
  @ViewChild(DSTMarkAsTemplateComponent) markAsTempalteComp!: DSTMarkAsTemplateComponent;

  menuPos!: MenuPosPayload;

  constructor(private dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService) {
  }

  ngOnInit(): void {
  }

  showDropdown(payload: MenuPosPayload, thisRow: DSTStatement): void {
    this.menuPos = payload;
    this.gridData.forEach((row: DSTStatement) => {
      row.showDropdown = false;
      row.showExtendedMenu = false;
    });
    thisRow.showDropdown = true;

    this.actionClick.emit({name: 'show-dropdown', data: {
      menuPos:this.menuPos,
      row: thisRow,
    }});

  }

  showViewUpdate(): void {
    this.rowData.showDropdown = false; this.rowData.showExtendedMenu = false;
    this.actionClick.emit({name: 'show-view-update', data: this.rowData});
  }

  onTaggingProject(): void {
    this.actionClick.emit({name: 'tagging-project', data: this.rowData});
  }

  onCopyReplicate(mode: string): void {
    this.actionClick.emit({name: mode.toLowerCase(), data: this.rowData});
  }

  onToggleReview(): void {
    this.actionClick.emit({name: 'toggle-review', data: this.rowData});
  }

  onShowDeleteConfirm(): void {
    this.actionClick.emit({name: 'deactivate', data: this.rowData});
  }

  onShowUnmatchConfirm(): void {
    this.actionClick.emit({name: 'unmatch-tracker', data: this.rowData});
  }

  onShowMarkOtherReason(): void {
    this.actionClick.emit({name: 'mark-other-reason', data: this.rowData});
  }

  onMatchTracker(): void {
    this.actionClick.emit({name: 'match-tracker', data: this.rowData});
  }

  onSaveSuccess(): void {
    this.actionClick.emit({name: 'save-success', data: undefined});
  }

  getCreateBtnText(): string {
    if (this.rowData.placeHolder || this.rowData.statementId === '0') {
      return this.rowData.ignoredId !== 0 ? 'N/A' : 'Create';
    }
    return this.rowData.statementId;
  }

  getReviewClassName(): string {
    if (this.rowData.placeHolder || this.rowData.statementId === '0') {
      return this.rowData.ignoredId !== 0 ? 'icon-end-process-fill' : 'icon-alert-fill';
    }
    return 'icon-check-mark-fill';
  }

  markAs(reason: DSTMarkAsOption): void {
    this.stmtIdBtnService.markAs(this.rowData, reason);
  }

}

export interface StmtBtnActionPayload {
  name: string,
  data: any
}
