import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Submission } from './models/submission.model';
import { Observable, throwError } from 'rxjs';
import { ActionService } from '../shared/_services/action.service';
import { ActionResponse } from '../shared/_models/action-response.model';
import { UploadIRS } from './models/upload-irs.model';
import { ApiResponse } from './models/api-response.model';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';


enum URLS {
  PING_IRS='/pingServer',
  SUBMIT_TO_IRS='/submit',
  POLL_ACK='/pollAck'
}

@Injectable({
  providedIn: 'root'
})
export class EFileService {

  baseURLs: GtwElementsBaseUrlsModel = {
    api: '/gtw',
    custom: '/custom',
    admin: '/admin',
    efile:'/gtw-efile-api',
    pdfEngine: '/pdf-engine',
    bulkPdfEngine: '/bulk-pdf-engine'
  };

  constructor(private httpClient: HttpClient,
    private actionService: ActionService) { }

  setBaseUrls(baseURLs: GtwElementsBaseUrlsModel){
    this.baseURLs = baseURLs;
  }
  getSubmissionData(clientKey: number, taxYear: string): Observable<Submission[]> {

    const params = new HttpParams({
      fromObject:{
        'tax_year':taxYear
      }
    });
    return this.actionService.getActionData(this.baseURLs.api,
      32096,
      clientKey,
      params
    ).pipe(
      map((data: ActionResponse) => {
        if(data.jsonObject === null){
          throw throwError(data); 
        }
        return data.jsonObject;
      })
    );
  }

  uploadIRSData(clientKey: number, taxYear: string): Observable<UploadIRS[]> {

    const params = new HttpParams({
      fromObject:{
        'tax_year':taxYear
      }
    });
    return this.actionService.getActionData(this.baseURLs.api,
      32090,
      clientKey,
      params
    ).pipe(
      map((data: ActionResponse) => {
        return data.jsonObject;
      })
    );
  }
  pingIrsServer(clientKey: number, envType: string): Observable<ApiResponse> {

    const headers = new HttpHeaders();
    // headers.append('client_key',clientKey.toString());

    const params = new HttpParams({
      fromObject:{
        'envType':envType
      }
    });
    return this.httpClient.get<ApiResponse>(`${this.baseURLs.efile}${URLS.PING_IRS}`,{
      params:params,
      headers:headers
    });
  }
  submitToIRS(clientKey: number, envType: string,submissionId:string): Observable<ApiResponse> {

    const headers = new HttpHeaders();
    // headers.append('client_key',clientKey.toString());

    const params = new HttpParams({
      fromObject:{
        'env':envType,
        'subId':submissionId
      }
    });
    return this.httpClient.get<ApiResponse>(`${this.baseURLs.efile}${URLS.SUBMIT_TO_IRS}`,{
      params:params,
      headers:headers
    });
  }
  pollAck(clientKey: number,submissionId:string): Observable<ApiResponse> {

    const headers = new HttpHeaders();
    // headers.append('client_key',clientKey.toString());

    const params = new HttpParams({
      fromObject:{
        'subId':submissionId
      }
    });
    return this.httpClient.get<ApiResponse>(`${this.baseURLs.efile}${URLS.POLL_ACK}`,{
      params:params,
      headers:headers
    });
  }
}
