<!-- <dst-create-disclosure-actions [disabled]="false" (onSaveClick)="save()" (onCancelClick)="cancel()"></dst-create-disclosure-actions> -->
<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()" [hideBackBtn]="formState == 1" (onBackClick)="back(1)">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <!-- <div class="d-flex align-items-center justify-content-center"> -->
  <div class="template-header-wrapper container" *ngIf="formState === 1">
    <div class="row header-text">
      Does this Reorganization consist of a Name Change only?
    </div>
    <div class="row">
      <ap-button class="a-mr-10" [config]="{ isPrimary: true }" (onBtnClick)="back(2)">
        <span class="appkiticon icon-check-mark-outline"></span>&nbsp;Yes
      </ap-button>
      <ap-button class="a-mr-10" [config]="{ isPrimary: true }" (onBtnClick)="back(3)">
        <span class="appkiticon icon-close-outline"></span>&nbsp;No
      </ap-button>
    </div>

  </div>
  <!-- </div> -->
  <div *ngIf="formState === 2">
    <div [formGroup]="form186">
      <dst-template-header #headerComp1 [parentFormGroup]="form186"
        [overwriteFormName]="'STATEMENT PURSUANT TO REGULATION 1.368-3 BY A CORPORATION,'"
        [extraLine]="'A PARTY TO A REORGANIZATION AND BY A SIGNIFICANT SHAREHOLDER'"
        (formOptionChanged)="onFormOptionChanged($event)" (consolGroupChanged)="OnConsolGroupChanged($event)">
      </dst-template-header>
      <div formGroupName="main">
        Pursuant to Section 1.368-3 of the Regulations, the following information is furnished:
        <ol class="section-content pb-0 mb-0">
          <li class="pl-3">
            <dst-template-leid-selector #mainLeidSelector [label]="'Please specify the LEID of the entity'"
              [labelId]="'100'" (onSelectedLEChanged)="onTargetChanged($event)" class="required-field entity-label">
            </dst-template-leid-selector>
            <!-- <div class="a-form-error error-leid" *ngIf="target === undefined">
              Please specify the Transferor
            </div> -->
            <div class="entity-details">
              <span>Entity Name:</span><span class="template-color-blue">{{!target ? '' : target.ENTITY}}</span>
            </div>
            <div class="entity-details">
              <span>EIN:</span><span class="template-color-blue">{{!target ? '' : (target.EIN ? target.EIN : 'N/A')}}</span>
            </div>

            <div class="inline-paragraphs pl-0">
              (formerly known as &nbsp;
              <div class="long-field">
                <ap-form-field [label]="''" [id]="'a'" [type]="'input'" formControlName="TransfereeName"
                  [(ngModel)]="TransfereeName" (focusout)="onTransfereeNameChanged()" class="required-field">
                </ap-form-field>
              </div>
              &nbsp;)
            </div>
            <!-- <div class="a-form-error align-fields" *ngIf="TransfereeName === ''">
              Please enter the formerly known as name
            </div> -->
          </li>
          <li class="pl-3">
            <div class="inline-paragraphs-fields">
              On
              <ap-date-picker  *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)" [(ngModel)]="EffectiveDate"
                (click)="onDPOpen()" formControlName="EffectiveDate" class="required-field" style="margin-left: 5px; margin-right: 5px;">
              </ap-date-picker>
              <!-- <div class="a-form-error" *ngIf="EffectiveDate === ''">
                Please select the date from a calendar
              </div> -->
              <div class="long-field ml-1">
                <ap-form-field [label]="''" [id]="'a'" [type]="'input'" formControlName="dspTransfereeName"
                  [(ngModel)]="dspTransfereeName" class="required-field" style="margin-right: 5px;">
                </ap-form-field>
                <span class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'"
                  [isWhite]="true"></span>
              </div>
              converted to a
              <div class="short-field">
                <ap-form-field [label]="''" [id]="'a'" [type]="'input'" formControlName="additionaltext"
                  [(ngModel)]="additionaltext">
                </ap-form-field>
              </div>
              and changed its name to <span class="template-color-blue">{{!target ? '' : target.ENTITY}}</span>, in a reorganization which qualifies under Internal Revenue Code Section 368(a)(1)(F), as a mere change in identity, form or place of organization.
            </div>
          </li>
          <li class="pl-3">
            Records regarding the name change are maintained by the taxpayer as required under Treasury Regulation
            Section 1.368-3(d) and are available upon request.
          </li>
          <li class="pl-3 mb-0">The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in
            connection with this reorganization
            <div class="inline-paragraphs-fields">
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 2)" (click)="onDPOpen()"
                [(ngModel)]="LiquidationDate1" formControlName="LiquidationDate1">
              </ap-date-picker>
              <div class="short-field ml-1">
                <ap-form-field [id]="'401'" [type]="'input'" formControlName="PLR"
                  [(ngModel)]="PLR">
                </ap-form-field>
              </div>

            </div>
          </li>
        </ol>
        <div class="d-flex">
          <span>LEIDs: </span>
          <div>
            <span *ngIf="target" class="template-color-blue">{{ target.LEID }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="formState === 3">
    <div [formGroup]="form186Two">
      <dst-template-header #headerComp2 [parentFormGroup]="form186"
        [overwriteFormName]="'STATEMENT PURSUANT TO REGULATION 1.368-3 BY A CORPORATION,'"
        [extraLine]="'A PARTY TO A REORGANIZATION AND BY A SIGNIFICANT SHAREHOLDER'"
        (formOptionChanged)="onFormOptionChanged($event)" (consolGroupChanged)="OnConsolGroupChanged($event)">
      </dst-template-header>
      <div formGroupName="main">
        Pursuant to Section 1.368-3 of the Regulations, the following information is furnished:
        <ol class="section-content pb-0 mb-0">
          <li>
            <dst-template-leid-selector #mainLeidSelector [label]="'Please specify the LEID of the entity'"
              [labelId]="'100'" (onSelectedLEChanged)="onTargetChanged($event)" class="required-field entity-label">
            </dst-template-leid-selector>
            <div class="inline-paragraphs-fields">
              On
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                class="required-field date-margin-0" [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)"
                [(ngModel)]="EffectiveDate" (click)="onDPOpen()" formControlName="EffectiveDate" style="margin-left: 5px; margin-right: 5px;">
              </ap-date-picker>
              <span class="template-color-blue">{{target ? leidEntity: ''}},</span>
              <!-- <div class="long-field">
                <ap-form-field [label]="''" [id]="'a'" [type]="'input'" [isDisabled]="true" formControlName="leidEntity"
                  [(ngModel)]="leidEntity">
                </ap-form-field>
              </div> -->
              EIN <span class="template-color-blue">{{target ? (leidEIN ? leidEIN: 'N/A') : ''}}</span>
              <!-- <div class="short-field">
                <ap-form-field [label]="''" [id]="'a'" [type]="'input'" [isDisabled]="true" formControlName="leidEIN"
                  [(ngModel)]="leidEIN">
                </ap-form-field>
              </div> -->
            </div>
            <ap-form-field [label]="''" [id]="'a'" [type]="'textarea'" [(ngModel)]="NameChangeDesc"
              formControlName="NameChangeDesc" class="required-field name-change-desc" [inlineBlock]="true">
            </ap-form-field>
            <p>
              in a reorganization which qualifies under Internal Revenue Code Section 368(a)(1)(F), as a mere change in
              identity, form or place of organization.
            </p>
            <p>
              Records regarding the change are maintained by the taxpayer as required under Treasury Regulation Section
              1.368-3(d) and are available upon request.
            </p>
            <p class="mb-0">
              The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in
              connection with this reorganization
            </p>
            <div class="inline-paragraphs-fields">
              <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 2)" (click)="onDPOpen()"
                [(ngModel)]="LiquidationDate1" formControlName="LiquidationDate1">
              </ap-date-picker>
              <!-- <div class="a-form-error" *ngIf="EffectiveDate === '' || EffectiveDate === null">
                Please select the date from a calendar
              </div> -->
              <div class="short-field ml-1">
                <ap-form-field [id]="'401'" [type]="'input'" formControlName="PLR"
                  [(ngModel)]="PLR">
                </ap-form-field>
              </div>

            </div>
          </li>
        </ol>
        <div class="d-flex">
          <span>LEIDs: </span>
          <div>
            <span *ngIf="target" class="template-color-blue">{{ target.LEID }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If there was no legal entity type conversion under local law, this textbox does not need to be completed and the
      disclosure will not mention an entity type conversion.
    </div>
  </div>
</ng-template>
