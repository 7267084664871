import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {FileDownloadResponse} from './models/FileDownloadResponse';
import {SubmitConfirmationService} from './submit-confirmation.service';

@Component({
  selector: 'gtw-efile-submit-confirmation',
  templateUrl: './submit-confirmation.component.html',
  styleUrls: ['./submit-confirmation.component.scss']
})
export class EfileSubmitConfirmation implements OnChanges {

  lastSnapshotDate: string;

  @Input()
  efileApiUrl: string;

  @Input()
  submissionId!: string;

  @Input()
  clientKey!: string;

  @Output()
  onProceed: EventEmitter<void>;

  @Output()
  onCancel: EventEmitter<void>;

  xmlComparisionAction$!: Observable<FileDownloadResponse>;


  isAccepted: boolean;

  constructor(private efileSubmissionService: SubmitConfirmationService) {
    this.lastSnapshotDate = '12/2/2024 23:34';
    this.efileApiUrl = '/efile-api';
    this.onProceed = new EventEmitter();
    this.onCancel = new EventEmitter();
    this.isAccepted = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //local changes
    this.xmlComparisionAction$ = this.efileSubmissionService.xmlComparision(this.submissionId, this.clientKey);

    if (!changes.submissionId.isFirstChange) {
      this.xmlComparisionAction$ = this.efileSubmissionService.xmlComparision(this.submissionId, this.clientKey);
    }
  }

  downloadReport() {
    this.xmlComparisionAction$.subscribe();
  }

  proceed() {
    this.onProceed.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
