import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {FormFieldType} from '../template-sec8824-id237/template-sec8824-id237.component';
import {AppkitDropdownOptions} from '../template-sec8883-id215/template-sec8883-id215.component';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'dst-template-sec8594-id217',
  templateUrl: './template-sec8594-id217.component.html',
  styleUrls: ['../template-shared.scss', './template-sec8594-id217.component.scss']
})
export class TemplateSec8594Id217Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form217!: FormGroup;
  selectedOptionID!: number;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  beginDate!: Date | string;
  endDate!: Date | string;
  dcnName: string = '';
  dcnIDNum: string = ''; // Identifying number shown next to the "Name as shown on return"
  PurchaserOrSeller: string = '';
  Choice204e: string = '';
  Choice204f: string = '';

  NameOfOP: string = '';
  einSsnRadio: string = '';
  SSNOfOtherParty1: string = '';
  EinOfOtherParty1: string = '';
  selectedMissingReason1: string | number = '';
  selectedCountry: number = -1;
  selectedState: number = -1;
  AddressOfOtherParty1: string = '';
  AddressOfOtherParty2: string = '';
  CityOfOtherParty: string = '';
  ZIPCodeOfOtherParty: string = '';
  ProvOrStateOP: string = '';
  DateOfSale: any = '';
  TotalSalesPrice: string = '';
  Choice204a: string = '';
  Choice204b: string = '';
  Choice204c: string = '';
  ConsiderationCmptStatement: string = '';

  Class1FMV: string = '';
  Class1ASP: string = '';
  Class2FMV: string = '';
  Class2ASP: string = '';
  Class3FMV: string = '';
  Class3ASP: string = '';
  Class4FMV: string = '';
  Class4ASP: string = '';
  Class5FMV: string = '';
  Class5ASP: string = '';
  Class6N7FMV: string = '';
  Class6N7ASP: string = '';
  TotalFMV: string = '';
  TotalASP: string = '';

  Choice204d: boolean = false;
  taxyear1: string = '';
  form1: string = '';
  RsnForIncOrDec: string = '';
  Class1PPR: string = '';
  Class1RASP: string = '';
  Class2PPR: string = '';
  Class2RASP: string = '';
  Class3PPR: string = '';
  Class3RASP: string = '';
  Class4PPR: string = '';
  Class4RASP: string = '';
  Class5PPR: string = '';
  Class5RASP: string = '';
  Class6N7PPR: string = '';
  Class6N7RASP: string = '';
  TotalPPR: string = '';
  TotalRASP: string = '';
  Class1IncDec: string = '';
  Class2IncDec: string = '';
  Class3IncDec: string = '';
  Class4IncDec: string = '';
  Class5IncDec: string = '';
  Class6N7IncDec: string = '';

  showDREMsg: boolean = false;
  DREMsg: string = '';

  EINMissingReason1: AppkitDropdownOptions[] = [{
    data: [
      { value: '', text: '** Please select the missing reason **' },
      {
        value: 'APPLD FOR',
        text: 'APPLD FOR'
      },
      {
        value: 'FOREIGNUS',
        text: 'FOREIGNUS'
      },
    ]
  }];

  StateOptions: AppkitDropdownOptions[] = [{
    data: []
  }];

  CountryOptions: AppkitDropdownOptions[] = [{
    data: []
  }];
  nameChangeChecked: boolean = false;

  ngOnInit(): void {
    this.editMode = false;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form217 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      partOne: this.formBuilder.group({
        NameOfOP: [''],
        SSNOfOtherParty1: [''],
        EinOfOtherParty1: [''],
        AddressOfOtherParty1: [''],
        AddressOfOtherParty2: [''],
        CityOfOtherParty: [''],
        ZIPCodeOfOtherParty: [''],
        ProvOrStateOP: [''],
        DateOfSale: [''],
        TotalSalesPrice: [''],
      }),
      partTwo: this.formBuilder.group({
        Class1FMV: [''],
        Class1ASP: [''],
        Class2FMV: [''],
        Class2ASP: [''],
        Class3FMV: [''],
        Class3ASP: [''],
        Class4FMV: [''],
        Class4ASP: [''],
        Class5FMV: [''],
        Class5ASP: [''],
        Class6N7FMV: [''],
        Class6N7ASP: [''],
        TotalFMV: [''],
        TotalASP: [''],
        ConsiderationCmptStatement: [''],
      }),
      partThree: this.formBuilder.group({
        taxyear1: [''],
        form1: [''],
        RsnForIncOrDec: [''],
        Class1PPR: [''],
        Class1RASP: [''],
        Class2PPR: [''],
        Class2RASP: [''],
        Class3PPR: [''],
        Class3RASP: [''],
        Class4PPR: [''],
        Class4RASP: [''],
        Class5PPR: [''],
        Class5RASP: [''],
        Class6N7PPR: [''],
        Class6N7RASP: [''],
        TotalPPR: [''],
        TotalRASP: [''],
        Class1IncDec: [''],
        Class2IncDec: [''],
        Class3IncDec: [''],
        Class4IncDec: [''],
        Class5IncDec: [''],
        Class6N7IncDec: [''],
      })

    });
    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        if (this.attMap['country1'] && this.attMap['country1'][0]) {
          const updatedData = this.updateDropdownList(this.CountryOptions, this.attMap['country1'][0]);
          this.CountryOptions = updatedData.dropdownData;
          this.selectedCountry = updatedData.index;
        }
      });
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
      if (this.attMap['state_Us'] && this.attMap['state_Us'][0]) {
        const updatedData = this.updateDropdownList(this.StateOptions, this.attMap['state_Us'][0]);
        this.StateOptions = updatedData.dropdownData;
        this.selectedState = updatedData.index;
      }
    });
  }

  updateDropdownList(currentOptions: any, element: string) {
    const updateList: any[] = [];
    let selectedIndex: string | number = -1;
    currentOptions[0].data.forEach((el: any, index: any) => {
      if (element === el.value) {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = el.value;
      } else {
        updateList.push({
          value: el.value,
          text: el.text,
          index: el.value,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.DateOfSale && this.transferor?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.sharedService.getTransDateStr(this.DateOfSale),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
          if (response && response[0]?.LE_NAME) {
            this.transferor!.ENTITY = response[0].LE_NAME; // override entity name
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
            console.log(error);
            this.dstSharedService.showAlert('error', error.message);
          }).add(() => {
          this.startLoader = false;
        });
    }else if(!this.DateOfSale) {
      this.nameChangeChecked = true;

    }
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.DateOfSale && this.transferee?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.sharedService.getTransDateStr(this.DateOfSale),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
          if (response && response[0]?.LE_NAME) {
            this.transferee!.ENTITY = response[0].LE_NAME; // override entity name
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
            console.log(error);
            this.dstSharedService.showAlert('error', error.message);
          }).add(() => {
          this.startLoader = false;
        });
    }else if(!this.DateOfSale) {
      this.nameChangeChecked = true;

    }
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData();
    if (data) {
      this.editMode = true;
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      data.children?.forEach((stmt: StmtAttrData) => {
        if (this.attMap[stmt.attributeName] == undefined) {
          this.attMap[stmt.attributeName] = [];
        }

        if (stmt.attributeValue !== undefined) {

          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        } else {
          this.attMap[stmt.attributeName]?.push('');
        }
      });
      if (this.attMap['EIN'] && this.attMap['EIN'][0]) {
        const updatedData = this.updateDropdownList(this.EINMissingReason1, this.attMap['EIN'][0]);
        this.EINMissingReason1 = updatedData.dropdownData;
        this.selectedMissingReason1 = updatedData.index;
      }
      if (this.attMap['Class1FMV'] && this.attMap['Class1FMV'][0]) {
        this.Class1FMV = this.attMap['Class1FMV'][0];
      }
      if (this.attMap['Class1ASP'] && this.attMap['Class1ASP'][0]) {
        this.Class1ASP = this.attMap['Class1ASP'][0];
      }
      if (this.attMap['Class2FMV'] && this.attMap['Class2FMV'][0]) {
        this.Class2FMV = this.attMap['Class2FMV'][0];
      }
      if (this.attMap['Class2ASP'] && this.attMap['Class2ASP'][0]) {
        this.Class2ASP = this.attMap['Class2ASP'][0];
      }
      if (this.attMap['Class3FMV'] && this.attMap['Class3FMV'][0]) {
        this.Class3FMV = this.attMap['Class3FMV'][0];
      }
      if (this.attMap['Class3ASP'] && this.attMap['Class3ASP'][0]) {
        this.Class3ASP = this.attMap['Class3ASP'][0];
      }
      if (this.attMap['Class4FMV'] && this.attMap['Class4FMV'][0]) {
        this.Class4FMV = this.attMap['Class4FMV'][0];
      }
      if (this.attMap['Class4ASP'] && this.attMap['Class4ASP'][0]) {
        this.Class4ASP = this.attMap['Class4ASP'][0];
      }
      if (this.attMap['Class5FMV'] && this.attMap['Class5FMV'][0]) {
        this.Class5FMV = this.attMap['Class5FMV'][0];
      }
      if (this.attMap['Class5ASP'] && this.attMap['Class5ASP'][0]) {
        this.Class5ASP = this.attMap['Class5ASP'][0];
      }
      if (this.attMap['Class6N7FMV'] && this.attMap['Class6N7FMV'][0]) {
        this.Class6N7FMV = this.attMap['Class6N7FMV'][0];
      }
      if (this.attMap['Class6N7ASP'] && this.attMap['Class6N7ASP'][0]) {
        this.Class6N7ASP = this.attMap['Class6N7ASP'][0];
      }
      if (this.attMap['TotalFMV'] && this.attMap['TotalFMV'][0]) {
        this.TotalFMV = this.attMap['TotalFMV'][0];
      }
      if (this.attMap['TotalASP'] && this.attMap['TotalASP'][0]) {
        this.TotalASP = this.attMap['TotalASP'][0];
      }
      if (this.attMap['Class1PPR'] && this.attMap['Class1PPR'][0]) {
        this.Class1PPR = this.attMap['Class1PPR'][0];
      }
      if (this.attMap['Class1RASP'] && this.attMap['Class1RASP'][0]) {
        this.Class1RASP = this.attMap['Class1RASP'][0];
      }
      if (this.attMap['Class2PPR'] && this.attMap['Class2PPR'][0]) {
        this.Class2PPR = this.attMap['Class2PPR'][0];
      }
      if (this.attMap['Class2RASP'] && this.attMap['Class2RASP'][0]) {
        this.Class2RASP = this.attMap['Class2RASP'][0];
      }
      if (this.attMap['Class3PPR'] && this.attMap['Class3PPR'][0]) {
        this.Class3PPR = this.attMap['Class3PPR'][0];
      }
      if (this.attMap['Class3RASP'] && this.attMap['Class3RASP'][0]) {
        this.Class3RASP = this.attMap['Class3RASP'][0];
      }
      if (this.attMap['Class4PPR'] && this.attMap['Class4PPR'][0]) {
        this.Class4PPR = this.attMap['Class4PPR'][0];
      }
      if (this.attMap['Class4RASP'] && this.attMap['Class4RASP'][0]) {
        this.Class4RASP = this.attMap['Class4RASP'][0];
      }
      if (this.attMap['Class5PPR'] && this.attMap['Class5PPR'][0]) {
        this.Class5PPR = this.attMap['Class5PPR'][0];
      }
      if (this.attMap['Class5RASP'] && this.attMap['Class5RASP'][0]) {
        this.Class5RASP = this.attMap['Class5RASP'][0];
      }
      if (this.attMap['Class6N7PPR'] && this.attMap['Class6N7PPR'][0]) {
        this.Class6N7PPR = this.attMap['Class6N7PPR'][0];
      }
      if (this.attMap['Class6N7RASP'] && this.attMap['Class6N7RASP'][0]) {
        this.Class6N7RASP = this.attMap['Class6N7RASP'][0];
      }
      if (this.attMap['TotalPPR'] && this.attMap['TotalPPR'][0]) {
        this.TotalPPR = this.attMap['TotalPPR'][0];
      }
      if (this.attMap['TotalRASP'] && this.attMap['TotalRASP'][0]) {
        this.TotalRASP = this.attMap['TotalRASP'][0];
      }
      if (this.attMap['Class1IncDec'] && this.attMap['Class1IncDec'][0]) {
        this.Class1IncDec = this.attMap['Class1IncDec'][0];
      }
      if (this.attMap['Class2IncDec'] && this.attMap['Class2IncDec'][0]) {
        this.Class2IncDec = this.attMap['Class2IncDec'][0];
      }
      if (this.attMap['Class3IncDec'] && this.attMap['Class3IncDec'][0]) {
        this.Class3IncDec = this.attMap['Class3IncDec'][0];
      }
      if (this.attMap['Class4IncDec'] && this.attMap['Class4IncDec'][0]) {
        this.Class4IncDec = this.attMap['Class4IncDec'][0];
      }
      if (this.attMap['Class5IncDec'] && this.attMap['Class5IncDec'][0]) {
        this.Class5IncDec = this.attMap['Class5IncDec'][0];
      }
      if (this.attMap['Class6N7IncDec'] && this.attMap['Class6N7IncDec'][0]) {
        this.Class6N7IncDec = this.attMap['Class6N7IncDec'][0];
      }
      if (this.attMap['NameOfOP'] && this.attMap['NameOfOP'][0]) {
        this.NameOfOP = this.attMap['NameOfOP'][0];
      }
      if (this.attMap['PurchaserOrSeller'] && this.attMap['PurchaserOrSeller'][0]) {
        this.PurchaserOrSeller = this.attMap['PurchaserOrSeller'][0];
      }
      if (this.attMap['Choice204e'] && this.attMap['Choice204e'][0]) {
        this.Choice204e = this.attMap['Choice204e'][0];
      }
      if (this.attMap['Choice204f'] && this.attMap['Choice204f'][0]) {
        this.Choice204f = this.attMap['Choice204f'][0];
      }
      if (this.attMap['NameOfOP'] && this.attMap['NameOfOP'][0]) {
        this.NameOfOP = this.attMap['NameOfOP'][0];
      }
      if (this.attMap['einSsnRadio'] && this.attMap['einSsnRadio'][0]) {
        this.einSsnRadio = this.attMap['einSsnRadio'][0];
      }
      if (this.attMap['EinOfOtherParty1'] && this.attMap['EinOfOtherParty1'][0]) {
        this.EinOfOtherParty1 = this.attMap['EinOfOtherParty1'][0];
        this.einSsnRadio = 'ein';
      }
      if (this.attMap['SSNOfOtherParty1'] && this.attMap['SSNOfOtherParty1'][0]) {
        this.SSNOfOtherParty1 = this.attMap['SSNOfOtherParty1'][0];
        this.einSsnRadio = 'ssn';
      }
      if (this.attMap['AddressOfOtherParty1'] && this.attMap['AddressOfOtherParty1'][0]) {
        this.AddressOfOtherParty1 = this.attMap['AddressOfOtherParty1'][0];
      }
      if (this.attMap['AddressOfOtherParty2'] && this.attMap['AddressOfOtherParty2'][0]) {
        this.AddressOfOtherParty2 = this.attMap['AddressOfOtherParty2'][0];
      }
      if (this.attMap['CityOfOtherParty'] && this.attMap['CityOfOtherParty'][0]) {
        this.CityOfOtherParty = this.attMap['CityOfOtherParty'][0];
      }
      if (this.attMap['state_name'] && this.attMap['state_name'][0]) {
        this.ProvOrStateOP = this.attMap['state_name'][0];
      }
      if (this.attMap['DateOfSale'] && this.attMap['DateOfSale'][0]) {
        this.DateOfSale = new Date(this.attMap['DateOfSale'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['TotalSalesPrice'] && this.attMap['TotalSalesPrice'][0]) {
        this.TotalSalesPrice = this.attMap['TotalSalesPrice'][0];
      }
      if (this.attMap['Choice204a'] && this.attMap['Choice204a'][0]) {
        this.Choice204a = this.attMap['Choice204a'][0];
      }
      if (this.attMap['Choice204b'] && this.attMap['Choice204b'][0]) {
        this.Choice204b = this.attMap['Choice204b'][0];
      }
      if (this.attMap['Choice204c'] && this.attMap['Choice204c'][0]) {
        this.Choice204c = this.attMap['Choice204c'][0];
      }
      if (this.attMap['ConsiderationCmptStatement'] && this.attMap['ConsiderationCmptStatement'][0]) {
        this.ConsiderationCmptStatement = this.attMap['ConsiderationCmptStatement'][0];
      }
      if (this.attMap['Choice204d'] && this.attMap['Choice204d'][0]) {
        this.Choice204d = (this.attMap['Choice204d'][0] === 'Yes' || this.attMap['Choice204d'][0] === 'true');
      }
      if (this.attMap['taxyear1'] && this.attMap['taxyear1'][0]) {
        this.taxyear1 = this.attMap['taxyear1'][0];
      }
      if (this.attMap['form1'] && this.attMap['form1'][0]) {
        this.form1 = this.attMap['form1'][0];
      }
      if (this.attMap['ReasonIncDec'] && this.attMap['ReasonIncDec'][0]) {
        this.RsnForIncOrDec = this.attMap['ReasonIncDec'][0];
      }
      if (this.attMap['ZIPCode5'] && this.attMap['ZIPCode5'][0]) {
        this.ZIPCodeOfOtherParty = this.attMap['ZIPCode5'][0];
      }
      this.cdref.detectChanges();
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
            case 1:
              if (data.secondaryleid) {
                element.fillLEID(data.secondaryleid);
              }
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      });
      this.cdref.detectChanges();
    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
      });
    }else{
      this.nameChangeChecked = true;
    }
  }


  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': this.NameOfOP,
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };
      const jsonObject = {
        'comments': this.NameOfOP,
        'nameOnReturn': this.dcnName.toString() || '',
        'identifyingNo': this.dcnIDNum.toString() || '',
        'primaryLeid': this.transferor ? this.transferor?.LEID.toString() : '',
        'PurchaserOrSeller': this.PurchaserOrSeller.toString(),
        'Choice204e': this.Choice204e.toString(),
        'secondaryLeid': this.transferee ? this.transferee?.LEID.toString() : '',
        'Choice204f': this.Choice204f.toString(),
        'NameOfOP': this.NameOfOP.toString(),
        'einSsnRadio': this.einSsnRadio.toString(),
        'EinOfOtherParty1': this.einSsnRadio === 'ein' ? this.EinOfOtherParty1.toString() : '',
        'SSNOfOtherParty1': this.einSsnRadio === 'ssn' ? this.SSNOfOtherParty1.toString() : '',
        'EIN': this.selectedMissingReason1.toString(),
        'AddressOfOtherParty1': this.AddressOfOtherParty1.toString(),
        'AddressOfOtherParty2': this.AddressOfOtherParty2.toString(),
        'CityOfOtherParty': this.CityOfOtherParty.toString(),
        'state_Us': this.selectedState === -1 ? '' : this.selectedState.toString(),
        'state_name': this.ProvOrStateOP.toString(),
        'country1': this.selectedCountry === -1 ? '' : this.selectedCountry.toString(),
        'ZIPCode5': this.ZIPCodeOfOtherParty.toString(),
        'DateOfSale': (this.DateOfSale && typeof this.DateOfSale !== 'object') ? this.DateOfSale.toString() : (this.DateOfSale?.startDate?.format('YYYY-MM-DD') || ''),
        'TotalSalesPrice': this.TotalSalesPrice.toString(),
        'Class1FMV': this.Class1FMV?.toString(),
        'Class1ASP': this.Class1ASP?.toString(),
        'Class2FMV': this.Class2FMV?.toString(),
        'Class2ASP': this.Class2ASP?.toString(),
        'Class3FMV': this.Class3FMV?.toString(),
        'Class3ASP': this.Class3ASP?.toString(),
        'Class4FMV': this.Class4FMV?.toString(),
        'Class4ASP': this.Class4ASP?.toString(),
        'Class5FMV': this.Class5FMV?.toString(),
        'Class5ASP': this.Class5ASP?.toString(),
        'Class6N7FMV': this.Class6N7FMV?.toString(),
        'Class6N7ASP': this.Class6N7ASP?.toString(),
        'TotalFMV': this.TotalFMV?.toString(),
        'TotalASP': this.TotalASP?.toString(),
        'Choice204a': this.Choice204a.toString(),
        'Choice204b': this.Choice204b.toString(),
        'Choice204c': this.Choice204c.toString(),
        'ConsiderationCmptStatement': this.ConsiderationCmptStatement.toString(),
        'Choice204d': this.Choice204d ? 'Yes' : 'No',
        'taxyear1': this.taxyear1.toString(),
        'form1': this.form1.toString(),
        'Class1PPR': this.Class1PPR?.toString(),
        'Class1RASP': this.Class1RASP?.toString(),
        'Class2PPR': this.Class2PPR?.toString(),
        'Class2RASP': this.Class2RASP?.toString(),
        'Class3PPR': this.Class3PPR?.toString(),
        'Class3RASP': this.Class3RASP?.toString(),
        'Class4PPR': this.Class4PPR?.toString(),
        'Class4RASP': this.Class4RASP?.toString(),
        'Class5PPR': this.Class5PPR?.toString(),
        'Class5RASP': this.Class5RASP?.toString(),
        'Class6N7PPR': this.Class6N7PPR?.toString(),
        'Class6N7RASP': this.Class6N7RASP?.toString(),
        'TotalPPR': this.TotalPPR?.toString(),
        'TotalRASP': this.TotalRASP?.toString(),
        'Class1IncDec': this.Class1IncDec?.toString(),
        'Class2IncDec': this.Class2IncDec?.toString(),
        'Class3IncDec': this.Class3IncDec?.toString(),
        'Class4IncDec': this.Class4IncDec?.toString(),
        'Class5IncDec': this.Class5IncDec?.toString(),
        'Class6N7IncDec': this.Class6N7IncDec?.toString(),
        'ReasonIncDec': this.RsnForIncOrDec?.toString(),
        'theBranchLEID': this.branchEntity ? this.branchEntity?.LEID.toString() : ''
      };

      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });

    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  dummyFunc(event: Event): void {

  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    const splits: string[] = consolGroup.DCNNAME.split('- (');
    this.dcnName = splits[0].trim();
   // this.dcnIDNum = splits[1].slice(0, -1).trim();
    this.dcnIDNum = splits[1].split(')')[0].trim();
    this.dcnId = consolGroup.DCNID;
  }

  onFormOptionChanged(selectedOptionID: number): void {
    this.selectedOptionID = selectedOptionID;
  }

  updateValue(event: FormFieldType, index: number) {
    switch (index) {
      case 1:
        this.Class1FMV = this.Class1ASP = event.value;
        break;
      case 2:
        this.Class2FMV = this.Class2ASP = event.value;
        break;
      case 3:
        this.Class3FMV = this.Class3ASP = event.value;
        break;
      case 4:
        this.Class4FMV = this.Class4ASP = event.value;
        break;
      case 5:
        this.Class5FMV = this.Class5ASP = event.value;
        break;
      case 6:
        this.Class6N7FMV = this.Class6N7ASP = event.value;
        break;
      case 7:
        this.Class1ASP = event.value;
        break;
      case 8:
        this.Class2ASP = event.value;
        break;
      case 9:
        this.Class3ASP = event.value;
        break;
      case 10:
        this.Class4ASP = event.value;
        break;
      case 11:
        this.Class5ASP = event.value;
        break;
      case 12:
        this.Class6N7ASP = event.value;
        break;
      default:

        break;
    }

    this.TotalFMV = [
      this.Class1FMV,
      this.Class2FMV,
      this.Class3FMV,
      this.Class4FMV,
      this.Class5FMV,
      this.Class6N7FMV
    ].reduce(this.aggregateStrings);

    this.TotalASP = [
      this.Class1ASP,
      this.Class2ASP,
      this.Class3ASP,
      this.Class4ASP,
      this.Class5ASP,
      this.Class6N7ASP
    ].reduce(this.aggregateStrings);
    // this.TotalFMV = (this.Class1FMV !== '' ? parseFloat(this.Class1FMV) : 0) + (this.Class2FMV !== '' ? parseFloat(this.Class2FMV) : 0) + (this.Class3FMV !== '' ? parseFloat(this.Class3FMV) : 0) + (this.Class4FMV !== '' ? parseFloat(this.Class4FMV) : 0) + (this.Class5FMV !== '' ? parseFloat(this.Class5FMV) : 0) + (this.Class6N7FMV !== '' ? parseFloat(this.Class6N7FMV) : 0) + '';
    // this.TotalASP = (this.Class1ASP !== '' ? parseFloat(this.Class1ASP) : 0) + (this.Class2ASP !== '' ? parseFloat(this.Class2ASP) : 0) + (this.Class3ASP !== '' ? parseFloat(this.Class3ASP) : 0) + (this.Class4ASP !== '' ? parseFloat(this.Class4ASP) : 0) + (this.Class5ASP !== '' ? parseFloat(this.Class5ASP) : 0) + (this.Class6N7ASP !== '' ? parseFloat(this.Class6N7ASP) : 0) + '';
  }

  updateValue2(event: FormFieldType, index: number) {
    switch (index) {
      case 1:
        this.Class1PPR = event.value;
        this.Class1RASP = this.aggregateStrings(this.Class1PPR,this.Class1IncDec);
        break;
      case 2:
        this.Class2PPR = event.value;
        this.Class2RASP = this.aggregateStrings(this.Class2PPR,this.Class2IncDec);
        break;
      case 3:
        this.Class3PPR = event.value;
        this.Class3RASP = this.aggregateStrings(this.Class3PPR,this.Class3IncDec);
        break;
      case 4:
        this.Class4PPR = event.value;
        this.Class4RASP = this.aggregateStrings(this.Class4PPR,this.Class4IncDec);
        break;
      case 5:
        this.Class5PPR = event.value;
        this.Class5RASP = this.aggregateStrings(this.Class5PPR,this.Class5IncDec);
        break;
      case 6:
        this.Class6N7PPR = event.value;
        this.Class6N7RASP =this.aggregateStrings(this.Class6N7PPR,this.Class6N7IncDec);
        break;
      case 7:
        this.Class1RASP = event.value;
        break;
      case 8:
        this.Class2RASP = event.value;
        break;
      case 9:
        this.Class3RASP = event.value;
        break;
      case 10:
        this.Class4RASP = event.value;
        break;
      case 11:
        this.Class5RASP = event.value;
        break;
      case 12:
        this.Class6N7RASP = event.value;
        break;

      case 13:
        this.Class1IncDec = event.value;
        this.Class1RASP = this.aggregateStrings(this.Class1PPR,this.Class1IncDec);
        break;
      case 14:
        this.Class2IncDec = event.value;
        this.Class2RASP = this.aggregateStrings(this.Class2PPR,this.Class2IncDec);
        break;
      case 15:
        this.Class3IncDec = event.value;
        this.Class3RASP = this.aggregateStrings(this.Class3PPR,this.Class3IncDec);
        break;
      case 16:
        this.Class4IncDec = event.value;
        this.Class4RASP = this.aggregateStrings(this.Class4PPR,this.Class4IncDec);
        break;
      case 17:
        this.Class5IncDec = event.value;
        this.Class5RASP = this.aggregateStrings(this.Class5PPR,this.Class5IncDec);
        break;
      case 18:
        this.Class6N7IncDec = event.value;
        this.Class6N7RASP = this.aggregateStrings(this.Class6N7PPR,this.Class6N7IncDec);
        break;


      default:

        break;
    }
    this.TotalPPR = [
      this.Class1PPR,
      this.Class2PPR,
      this.Class3PPR,
      this.Class4PPR,
      this.Class5PPR,
      this.Class6N7PPR
    ].reduce(this.aggregateStrings);

    this.TotalRASP = [
      this.Class1RASP,
      this.Class2RASP,
      this.Class3RASP,
      this.Class4RASP,
      this.Class5RASP,
      this.Class6N7RASP
    ].reduce(this.aggregateStrings);
    // this.TotalPPR = (this.Class1PPR !== '' ? parseFloat(this.Class1PPR) : 0) + (this.Class2PPR !== '' ? parseFloat(this.Class2PPR) : 0) + (this.Class3PPR !== '' ? parseFloat(this.Class3PPR) : 0) + (this.Class4PPR !== '' ? parseFloat(this.Class4PPR) : 0) + (this.Class5PPR !== '' ? parseFloat(this.Class5PPR) : 0) + (this.Class6N7PPR !== '' ? parseFloat(this.Class6N7PPR) : 0) + '';
    // this.TotalRASP = (this.Class1RASP !== '' ? parseFloat(this.Class1RASP) : 0) + (this.Class2RASP !== '' ? parseFloat(this.Class2RASP) : 0) + (this.Class3RASP !== '' ? parseFloat(this.Class3RASP) : 0) + (this.Class4RASP !== '' ? parseFloat(this.Class4RASP) : 0) + (this.Class5RASP !== '' ? parseFloat(this.Class5RASP) : 0) + (this.Class6N7RASP !== '' ? parseFloat(this.Class6N7RASP) : 0) + '';
  }

  private aggregateStrings(a: string,b: string): string{
    const floatA =parseFloat(a);
    const floatB =parseFloat(b);
    const sum = ((isNaN(floatA) ? 0: floatA) + (isNaN(floatB) ? 0: floatB));
    return  sum === 0 ? '' : sum.toString();
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.DateOfSale = event.startDate.format('YYYY-MM-DD');

        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.sharedService.getTransDateStr(this.DateOfSale),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });
        break;
      default:
        break;
    }
  }

  missingReason1(event: any) {
    this.selectedMissingReason1 = event.selected[0].value;

  }

  stateChange(event: any) {
    this.selectedState = event.selected[0].value;

  }

  countryChange(event: any) {
    this.selectedCountry = event.selected[0].value;

  }

  check1c(val: string) {
    this.PurchaserOrSeller = val;
  }

  check1(val: string) {
    this.Choice204e = val;
  }

  check1b(val: string) {
    this.Choice204f = val;
  }

  checkEINorSSN(val: string) {
    this.einSsnRadio = val;
  }

  check5a(val: string) {
    this.Choice204a = val;
  }

  check5b(val: string) {
    this.Choice204b = val;
  }

  check6(val: string) {
    this.Choice204c = val;
  }

  check7(val: any) {
    this.Choice204d = !this.Choice204d;
  }

  isFormValid(placeholder: boolean): boolean {
    if (!this.transferor) {
      this.dstSharedService.showAlert('error', 'Please specify the LEID of the Purchaser/Seller');
      return false;
    }
    // skip further validation if created as a placeholder
    if (placeholder) {
      return true;
    }
    if (this.PurchaserOrSeller === '') {
      this.dstSharedService.showAlert('error', 'Please select check-box to indicate entity as Purchaser or Seller');
      return false;
    }
    if (this.Choice204e === '') {
      this.dstSharedService.showAlert('error', 'Please select check-box to indicate entity as Gold or Not');
      return false;
    }
    if (this.Choice204e === 'Yes' && !this.transferee) {
      this.dstSharedService.showAlert('error', 'Please specify a LEID in Part I, Line 1');
      return false;
    }
    if (this.Choice204e === 'No') {
      if (this.Choice204f === '') {
        this.dstSharedService.showAlert('error', 'Please select check-box to indicate other party  as US or not');
        return false;
      }
      if (this.NameOfOP === '') {
        this.dstSharedService.showAlert('error', 'Please enter the name of other party of the transaction');
        return false;
      }
      if(this.EinOfOtherParty1 !== '' && this.einSsnRadio === 'ein' && !this.isValidNumber(this.EinOfOtherParty1)){
        this.dstSharedService.showAlert('error', 'Please enter Valid EIN');
        return false;
      }
      if(this.EinOfOtherParty1 !== '' && this.einSsnRadio === 'ein' && this.EinOfOtherParty1.length !== 9){
        this.dstSharedService.showAlert('error', 'Please enter 9 digits EIN Number');
        return false;
      }
      if (this.AddressOfOtherParty1 === '') {
        this.dstSharedService.showAlert('error', 'Please enter Address of the Other Party to the Transaction');
        return false;
      }
      if (this.CityOfOtherParty === '') {
        this.dstSharedService.showAlert('error', 'Please enter City of the Other Party to the Transaction');
        return false;
      }
      if (this.ZIPCodeOfOtherParty === '') {
        this.dstSharedService.showAlert('error', 'Please fill the ZIP Code');
        return false;
      }
      if((this.einSsnRadio === 'ein' && this.selectedMissingReason1 === '' && this.EinOfOtherParty1 === '')  ||
          (this.einSsnRadio === 'ssn' && this.selectedMissingReason1 === '' && (this.SSNOfOtherParty1 === '' || this.SSNOfOtherParty1 == null))) {
        this.dstSharedService.showAlert('error', 'Please input EIN/SSN Missing Reason for the Other Party to the Transaction');
        return false;
      }

      if (this.Choice204f === 'No') {
        if (this.selectedCountry === -1) {
          this.dstSharedService.showAlert('error', 'Please select the country of the Other Party to the Transaction');
          return false;
        }
      } else {
        if (this.selectedState === -1) {
          this.dstSharedService.showAlert('error', 'Please select the state of the Other Party to the Transaction');
          return false;
        }
      }
    }
    if (this.DateOfSale === '' || this.DateOfSale === null) {
      this.dstSharedService.showAlert('error', 'Please enter the date of the transaction');
      return false;
    }

    if (this.TotalSalesPrice !== '' && this.TotalSalesPrice != this.TotalASP && this.TotalSalesPrice != this.TotalRASP) {
      this.dstSharedService.showAlert('error', 'Total Sales Price must equal the TotalSales Price Allocation from either Part II Line 4 or Part III Line 8, as applicable');
      return false;
    }

    return true;
  }

  isValidNumber(input: string) {
    const regex = /^\d+$/;
    input = input+'';
    if(regex.test(input)){
      return true;
    }
    else return false;
  }

}
