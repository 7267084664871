<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form217">
    <dst-template-header [parentFormGroup]="form217" (formOptionChanged)="onFormOptionChanged($event)"
                         (consolGroupChanged)="OnConsolGroupChanged($event)"></dst-template-header>
    <div class="section-content">
      <div class="row" style="margin: 5px 0;">
        <div class="col-md-4 row">Name as shown on return</div>
        <div class="col-md-4 pr-0" style="margin-left: 40px;margin-right: -30px;"><span class="template-color-blue">{{ dcnName }}</span>
        </div>

        <div class="col-md-4 p-0">
          Identifying number:
          <span class="template-color-blue pl-3">{{ dcnIDNum }}</span>
        </div>
      </div>
      <div  class="row" style="margin: 5px 0;">
        <div class="d-flex align-items-center col-md-4 row">
          <span>Please specify the LEID of the Purchaser/Seller</span>
        </div>
        <div class="col-md-4" style="margin-top: -20px;margin-left: 40px;margin-right: -30px;">
          <dst-template-leid-selector [label]="''" [labelId]="'100'"
                                      (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field pl-3">
          </dst-template-leid-selector>
        </div>
      </div>


      <div class="row" style="margin: 5px 0;">
        <div class="col-md-4 row"><span>Name of Purchaser/Seller:</span></div>
        <div class="col-md-8 pl-3" style="margin-left: 40px;margin-right: -30px;"><span class="template-color-blue" *ngIf="transferor">{{transferor.ENTITY}}</span></div>
      </div>

      <div class="row"  style="margin: 5px 0;">
        <div class="col-md-4 row">
          Check the box that identifies you:
        </div>
        <div class="col-md-8 pl-3" style="margin-left: 40px;margin-right: -30px;">
          <ap-radio [name]="'radio-group-1'" [text]="'Purchaser'" [checked]="editMode && PurchaserOrSeller === 'Purchaser'"
                    (onCheck)="check1c('Purchaser')" class="required-field-radio">
          </ap-radio>
          &nbsp;&nbsp;
          <ap-radio [name]="'radio-group-1'" [text]="'Seller'" [checked]="editMode && PurchaserOrSeller === 'Seller'"
                    (onCheck)="check1c('Seller')" class="required-field-radio">
          </ap-radio>
        </div>
      </div>
      <br/>
      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I</strong>&nbsp;&nbsp;&nbsp;&nbsp; <strong>General Information</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div formGroupName="partOne" class="section-content ap-form-field-my-wrapper">
        <div class="section-content">
          <div class="row">
            <div class="col-md-4 pr-0">
              Is the Other Party to the Transaction in Legal Entity Database?
            </div>
            <div class="col-md-8" style="margin-left: 7px;margin-right: -30px;">
              <ap-radio [name]="'radio-group-2'" [text]="'Yes'" [checked]="editMode && Choice204e === 'Yes'"
                        (onCheck)="check1('Yes'); Choice204f = ''" class="required-field-radio">
              </ap-radio>
              &nbsp;&nbsp;
              <ap-radio [name]="'radio-group-2'" [text]="'No'" [checked]="editMode && Choice204e === 'No'"
                        (onCheck)="check1('No')" class="required-field-radio">
              </ap-radio>
            </div>
          </div>
          <br />

          <div [hidden]="!(Choice204e === 'Yes')">
            <br/>
            <div class="row">
              <div class="col-md-4">
                Please specify the LEID of the other party to the transaction
              </div>
              <div class="col-md-8" style="margin-left: 7px;margin-right: -30px;">
                <dst-template-leid-selector [label]="''"
                                            [labelId]="'100'" class="required-field" (onSelectedLEChanged)="onTransfereeChanged($event)">
                </dst-template-leid-selector>
              </div>
            </div>
            <ol class="list-content">
              <li>
                <div class="entity-details">
                  <span class="col-md-4 pl-0">Name of other party to the transaction:</span>
                  <span class="template-color-blue col-md-8">{{!transferee ? '' : transferee.ENTITY}}</span>
                </div>
                <div class="entity-details">
                  <span class="col-md-4 pl-0">Identifying number:</span>
                  <span class="template-color-blue col-md-8">{{!transferee ? '' : (transferee.EIN ? transferee.EIN : 'N/A')}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">Address Line1</span>
                  <span class="template-color-blue col-md-8">{{transferee.ADDRESS1}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">Address Line2</span>
                  <span class="template-color-blue col-md-8">{{transferee.ADDRESS2}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">{{ transferee.COUNTRY === 'US' ? 'City:' : 'City / Town:'}}</span>
                  <span class="template-color-blue col-md-8">{{transferee.CITY}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">{{transferee.COUNTRY === 'US' ? 'State': 'Province Or State'}}</span>
                  <span class="template-color-blue col-md-8">{{ transferee.COUNTRY === 'US' ? transferee.STATE : (transferee.STATEINC || 'N/A')}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">Country</span>
                  <span class="template-color-blue col-md-8">{{transferee.COUNTRYNAME}}</span>
                </div>
                <div class="entity-details" *ngIf="transferee">
                  <span class="col-md-4 pl-0">{{transferee.COUNTRY === 'US' ? 'Zip Code:' : 'Postal Code:'}}</span>
                  <span class="template-color-blue col-md-8">{{transferee.ZIP}}</span>
                </div>
              </li>
            </ol>
          </div>
          <div [hidden]="!(Choice204e === 'No')">
            <div class="row">
              <div class="col-md-4">
                Is the Other Party a US Entity?
              </div>
              <div class="col-md-8" style="margin-left: 7px;margin-right: -30px;">
                <ap-radio [name]="'radio-group-3'" [text]="'Yes'" [checked]="editMode && Choice204f === 'Yes'"
                          (onCheck)="check1b('Yes')" class="required-field-radio">
                </ap-radio>
                &nbsp;&nbsp;
                <ap-radio [name]="'radio-group-3'" [text]="'No'" [checked]="editMode && Choice204f === 'No'"
                          (onCheck)="check1b('No')" class="required-field-radio">
                </ap-radio>
              </div>
            </div>
            <br/>
            <div [hidden]="(Choice204f === '')">
              <ol class="list-content m-0">
                <li>
                  <div class="row">
                    <div class="col-md-4">
                      Name of other party to the transaction
                    </div>
                    <div class="col-md-8">
                      <dst-custom-input class="required-field custom-input-100 w-100"
                                        [label]="''" [maxLength]="150" [value]="NameOfOP" (valChange)="NameOfOP = $event;">
                      </dst-custom-input>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col-md-4">
                      Identifying Number
                    </div>
                    <div class="col-md-8">
                      <ap-radio [name]="'radio-group-4'" [text]="'EIN or'" [checked]="editMode && einSsnRadio === 'ein'"
                                (onCheck)="checkEINorSSN('ein')">
                      </ap-radio>
                      <ap-radio class="a-mx-10" [name]="'radio-group-4'" [text]="'SSN?'" [checked]="editMode && einSsnRadio === 'ssn'"
                                (onCheck)="checkEINorSSN('ssn')">
                      </ap-radio>
                    </div>
                  </div>

                  <div class="row pb-4">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4" *ngIf="einSsnRadio === 'ein'">
                     <dst-custom-input class="required-field" [label]="''" [maxLength]="9" [value]="EinOfOtherParty1"
                                        (valChange)="EinOfOtherParty1 = $event;">
                      </dst-custom-input>
                    </div>
                    <div class="col-md-4" *ngIf="einSsnRadio === 'ssn'" style="padding-left: 0px;">
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="SSNOfOtherParty1"
                        [(ngModel)]="SSNOfOtherParty1" class="required-field" [id]="'1'" [type]="'inputNumber'" [placeholder]="''" [funType]="'typeTwo'">
                      </ap-form-field>
                     </div>
                  </div>

                  <!-- <div class="row pb-2" *ngIf="Choice204f !== 'Yes'">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4" style="padding-left: 0px;">
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="EinOfOtherParty1"
                        [(ngModel)]="EinOfOtherParty1" class="required-field" [id]="'1'" [type]="'inputNumber'" [placeholder]="''"
                        [funType]="'typeTwo'">
                      </ap-form-field>

                    </div>
                  </div> -->
                  <div class="row pb-4" *ngIf="(EinOfOtherParty1 === '' && einSsnRadio === 'ein') || ((SSNOfOtherParty1 === '' || SSNOfOtherParty1 == null) && einSsnRadio === 'ssn')">
                    <div class="col-md-4">
                      EIN Missing Reason:
                    </div>
                    <div class="col-md-4">
                      <ap-selector [items]="EINMissingReason1" [type]="'Dropdown'" class="required-field-dropdown ein-reason-field"
                                   [placeholder]="'** Please select the missing reason **'"
                                   (onSelectedChange)="missingReason1($event)">
                      </ap-selector>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      Address Line 1
                    </div>
                    <div class="col-md-8">
                      <dst-custom-input class="required-field custom-input-100 w-100"
                                        [label]="''" [maxLength]="35" [value]="AddressOfOtherParty1"
                                        (valChange)="AddressOfOtherParty1 = $event;">
                      </dst-custom-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      Address Line 2
                    </div>
                    <div class="col-md-8">
                      <dst-custom-input class="custom-input-100 w-100" [label]="''" [maxLength]="35" [value]="AddressOfOtherParty2"
                                        (valChange)="AddressOfOtherParty2 = $event;"></dst-custom-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      City
                    </div>
                    <div class="col-md-8">
                      <dst-custom-input class="required-field custom-input-100 w-100" [label]="''" [maxLength]="50" [value]="CityOfOtherParty"
                                        (valChange)="CityOfOtherParty = $event;"></dst-custom-input>
                    </div>
                  </div>
                  <div class="row" *ngIf="Choice204f === 'Yes'">
                    <div class="col-md-4">
                      State
                    </div>
                    <div class="col-md-8">
                      <ap-selector [items]="StateOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                   [placeholder]="'** Please select a state name **'" class="required-field-dropdown field-full-width"
                                   (onSelectedChange)="stateChange($event)">
                      </ap-selector>
                    </div>
                  </div>
                  <div class="row" *ngIf="Choice204f === 'No'">
                    <div class="col-md-4">
                      Province or State
                    </div>
                    <div class="col-md-8">
                      <dst-custom-input [label]="''" class="custom-input-100 w-100" [maxLength]="50" [value]="ProvOrStateOP" (valChange)="ProvOrStateOP = $event;"></dst-custom-input>
                    </div>
                  </div>
                  <div class="row" *ngIf="Choice204f === 'No'">
                    <div class="col-md-4">
                      Country
                    </div>
                    <div class="col-md-8">
                      <ap-selector [items]="CountryOptions" [type]="'SingleSelector'" [searchMethod]="'inline'" [placeholder]="'** Select Country **'"
                                   (onSelectedChange)="countryChange($event)" class="required-field-dropdown field-full-width">
                      </ap-selector>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-4">
                      Zip code
                    </div>
                    <div class="col-md-8 custom-input-wrapper">
                      <dst-custom-input class="required-field" [label]="''" [maxLength]="50" [value]="ZIPCodeOfOtherParty"
                                        (valChange)="ZIPCodeOfOtherParty = $event;"></dst-custom-input>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <ol class="list-content m-0" start="2">
            <li style="counter-reset: count 1;">
              <div class="row">
                <div class="col-md-4">
                  Date of sale
                </div>
                <div class="col-md-8 date-align" style="margin-left: 7px;margin-right: -30px;">
                  <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                  [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()" formControlName="DateOfSale"
                                  [(ngModel)]="DateOfSale" (onChange)="dateChange($event, 1)" class="required-field">
                  </ap-date-picker>

                </div>
              </div>
            </li>
            <li style="counter-reset: count 2;">
              <div class="row">
                <div class="col-md-4">
                  Total sales price (consideration)(For e-filing purposes, must be in USD)
                </div>
                <div class="col-md-3" style="margin-left: 0px;margin-right: -30px;padding-left: 0px;">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalSalesPrice"
                                 [(ngModel)]="TotalSalesPrice" class="required-field total-sales number-field w-80" [id]="'1'" [type]="'inputNumber'" [placeholder]="''"
                                 [funType]="'typeTwo'">
                  </ap-form-field>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td>
            <strong>&nbsp;&nbsp;Part II</strong> &nbsp;&nbsp;
            <strong>Original Statement of Assets Transferred</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div formGroupName="partTwo" class="section-content ap-form-field-my-wrapper">
        <ol class="section-content list-content" start="4">
          <li style="counter-reset: count 3;">
            <div style="vertical-align: text-top;">
              <table class="table-layout w-100">
                <thead>
                <tr>
                  <td class="col-md-4 pt-0">
                    Assets
                  </td>
                  <td class="col-md-4 pt-0">
                    Aggregate fair market value<br/>
                    (actual amount for Class I)<br/>
                    (For e-filing purposes, must be in USD)
                  </td>
                  <td class="col-md-4 pt-0">
                    Allocation of sales price<br/>
                    (For e-filing purposes, must be in USD)
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class I
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1FMV"
                                     [(ngModel)]="Class1FMV" [id]="'2'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 1)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1ASP"
                                     [(ngModel)]="Class1ASP" (onChangeEvent)="updateValue($event, 7)" [id]="'3'"
                                     [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class II
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2FMV"
                                     [(ngModel)]="Class2FMV" [id]="'4'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 2)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2ASP"
                                     [(ngModel)]="Class2ASP" [id]="'5'" [type]="'inputNumber'" class="number-field w-80"
                                     (onChangeEvent)="updateValue($event, 8)" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class III
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3FMV"
                                     [(ngModel)]="Class3FMV" [id]="'6'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 3)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3ASP"
                                     [(ngModel)]="Class3ASP" [id]="'7'" [type]="'inputNumber'" class="number-field w-80"
                                     (onChangeEvent)="updateValue($event, 9)" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class IV
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4FMV"
                                     [(ngModel)]="Class4FMV" [id]="'8'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 4)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4ASP"
                                     [(ngModel)]="Class4ASP" [id]="'9'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 10)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class V
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5FMV"
                                     [(ngModel)]="Class5FMV" [id]="'10'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 5)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5ASP"
                                     [(ngModel)]="Class5ASP" [id]="'11'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 11)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Class VI and VII
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7FMV"
                                     [(ngModel)]="Class6N7FMV" [id]="'12'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 6)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7ASP"
                                     [(ngModel)]="Class6N7ASP" [id]="'13'" [type]="'inputNumber'" class="number-field w-80" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue($event, 12)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-4 align-text-left">
                    Total
                  </td>
                  <td class="col-md-4 pr-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalFMV" [isDisabled]="true"
                        [(ngModel)]="TotalFMV" [id]="'14'" [type]="'inputNumber'" class="number-field w-80 total-field" [funType]="'typeTwo'"
                        [placeholder]="'0'">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-4 pl-0">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalASP" [isDisabled]="true"
                        [(ngModel)]="TotalASP" [id]="'15'" [type]="'inputNumber'" class="number-field w-80 total-field" [funType]="'typeTwo'"
                        [placeholder]="'0'">
                      </ap-form-field>

                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li style="counter-reset: count 4;">
            <div class="row">
              <div class="col-md-9">
                Did the purchaser and seller provide for an allocation of the sales price in the sales contract or in
                another written document signed by both parties?

              </div>
              <div class="col-md-3">
                <ap-radio [name]="'radio-group-5'" [text]="'Yes'" [checked]="editMode && Choice204a === 'Yes'"
                          (onCheck)="check5a('Yes')" class="a-mx-10">
                </ap-radio>
                <ap-radio [name]="'radio-group-5'" [text]="'No'" [checked]="editMode && Choice204a === 'No'"
                          (onCheck)="check5a('No')" class="a-mx-10">
                </ap-radio>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-9">
                If "Yes," are the aggregate fair market values (FMV) listed for each of asset Classes I, II, III, IV, V,
                VI, and VII the amounts agreed upon in your sales contract or in a separate written document?

              </div>
              <div class="col-md-3">
                <ap-radio [name]="'radio-group-6'" [text]="'Yes'" [checked]="editMode && Choice204b === 'Yes'"
                          (onCheck)="check5b('Yes')" class="a-mx-10">
                </ap-radio>

                <ap-radio [name]="'radio-group-6'" [text]="'No'" [checked]="editMode && Choice204b === 'No'"
                          (onCheck)="check5b('No')" class="a-mx-10">
                </ap-radio>
              </div>
            </div>
          </li>
          <br/>
          <li style="counter-reset: count 5;">
            <div class="row">
              <div class="col-md-9">
                In the purchase of the group of assets (or stock), did the purchaser also purchase a license or a
                covenant not to compete, or enter into a lease agreement, employment contract, management contract, or
                similar arrangement with the seller (or managers, directors, owners, or employees of the seller)?

              </div>
              <div class="col-md-3">
                <ap-radio [name]="'radio-group-7'" [text]="'Yes'" [checked]="editMode && Choice204c === 'Yes'"
                          (onCheck)="check6('Yes')" class="a-mx-10">
                </ap-radio>
                <ap-radio [name]="'radio-group-7'" [text]="'No'" [checked]="editMode && Choice204c === 'No'"
                          (onCheck)="check6('No')" class="a-mx-10">
                </ap-radio>
              </div>
            </div>
            <div class="section-content" [hidden]="!(Choice204c === 'Yes')">
              <div class="row">
                <div class="col-md-12 pl-0">
                  <span>
                    If Yes, attach a schedule that specifies (a) the type of agreement and (b) the maximum amount of consideration (not including interest) paid or to be paid under the agreement. See instructions.
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 p-0">
                  <dst-custom-input class="custom-input-100 input-width w-100"
                                    [type]="'textarea'" [label]="''" [maxLength]="1000" [value]="ConsiderationCmptStatement"
                                    (valChange)="ConsiderationCmptStatement = $event;">
                  </dst-custom-input>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III</strong> &nbsp;&nbsp;
            <strong>Supplemental Statement</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <div formGroupName="partThree" class="section-content ap-form-field-my-wrapper">
        <div class="row">
          <div class="col-md-9 pr-0">
            Did you amend an original statement or previously filed supplemental statement because of an increase or
            decrease in consideration?
          </div>
          <div class="col-md-2">
            <ap-checkbox [text]="'Yes'" [checked]="editMode && Choice204d" (onCheck)="check7($event)" class="a-mx-10">
            </ap-checkbox>
          </div>
        </div>
        <ol class="section-content list-content m-0" start="7" [hidden]="Choice204d !== true">
          <li style="counter-reset: count 6;">
            <div class="d-flex justify-content-between row">
              <div class="col-md-8 pr-0">
                Tax year and tax return form number with the original Form 8594 and any supplemental statements were
                filed.
              </div>
              <div class="col-md-2 d-flex">
                <span>Year</span>
                <ap-form-field [inlineBlock]="true" [label]="''" formControlName="taxyear1" [(ngModel)]="taxyear1"
                  class="pl-2" [id]="'16'" [type]="'input'" [placeholder]="''" style="width: 100px;">
                </ap-form-field>
              </div>
              <div class="col-md-2 d-flex">
                <span>Form</span>
                <ap-form-field [inlineBlock]="true" [label]="''" formControlName="form1" [(ngModel)]="form1"
                  class="pl-2" [id]="'17'" [type]="'input'" [placeholder]="''" style="width: 100px;">
                </ap-form-field>
              </div>
            </div>
          </li>
          <br>
          <li style="counter-reset: count 7;">
            <div class="" style="vertical-align: text-top;">
              <table class="table-layout">
                <thead>
                <tr>
                  <td class="col-md-2 align-text-left">
                    Assets
                  </td>
                  <td class="col-md-3 p-0">
                    Allocation of sales price as previously reported<br/>
                    (For e-filing purposes, must be in USD)
                  </td>
                  <td class="col-md-3 p-0">
                    Increase or (decrease)<br/>
                    (For e-filing purposes, must be in USD)
                  </td>
                  <td class="col-md-4 p-0">
                    Redetermination of allocation of sales price<br/>
                    (For e-filing purposes, must be in USD)
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="col-md-3 align-text-left">
                    Class I
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1PPR"
                                     [(ngModel)]="Class1PPR" [id]="'18'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 1)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1IncDec"
                                     [(ngModel)]="Class1IncDec" [id]="'19'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 13)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1RASP"
                                     [(ngModel)]="Class1RASP" (onChangeEvent)="updateValue2($event, 7)" [id]="'20'"
                                     [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3  align-text-left">
                    Class II
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2PPR"
                                     [(ngModel)]="Class2PPR" [id]="'21'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 2)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2IncDec"
                                     [(ngModel)]="Class2IncDec" [id]="'22'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 14)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2RASP"
                                     [(ngModel)]="Class2RASP" [id]="'23'" [type]="'inputNumber'" class="number-field w-100"
                                     (onChangeEvent)="updateValue2($event, 8)" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3  align-text-left">
                    Class III
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3PPR"
                                     [(ngModel)]="Class3PPR" [id]="'24'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 3)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3IncDec"
                                     [(ngModel)]="Class3IncDec" [id]="'25'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 15)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3RASP"
                                     [(ngModel)]="Class3RASP" [id]="'26'" [type]="'inputNumber'" class="number-field w-100"
                                     (onChangeEvent)="updateValue2($event, 9)" [funType]="'typeTwo'" [placeholder]="''">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3  align-text-left">
                    Class IV
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4PPR"
                                     [(ngModel)]="Class4PPR" [id]="'27'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 4)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4IncDec"
                                     [(ngModel)]="Class4IncDec" [id]="'28'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 16)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4RASP"
                                     [(ngModel)]="Class4RASP" [id]="'29'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 10)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3  align-text-left">
                    Class V
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5PPR"
                                     [(ngModel)]="Class5PPR" [id]="'30'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 5)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5IncDec"
                                     [(ngModel)]="Class5IncDec" [id]="'31'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 17)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5RASP"
                                     [(ngModel)]="Class5RASP" [id]="'32'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 11)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3  align-text-left">
                    Class VI and VII
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7PPR"
                                     [(ngModel)]="Class6N7PPR" [id]="'33'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 6)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7IncDec"
                                     [(ngModel)]="Class6N7IncDec" [id]="'34'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 18)">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7RASP"
                                     [(ngModel)]="Class6N7RASP" [id]="'35'" [type]="'inputNumber'" class="number-field w-100" [funType]="'typeTwo'"
                                     [placeholder]="''" (onChangeEvent)="updateValue2($event, 12)">
                      </ap-form-field>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="col-md-3 align-text-left">
                    Total
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalPPR" [isDisabled]="true"
                        [(ngModel)]="TotalPPR" [id]="'36'" [type]="'inputNumber'" class="number-field w-100 total-field" [funType]="'typeTwo'"
                        [placeholder]="'0'">
                      </ap-form-field>
                    </div>
                  </td>
                  <td class="col-md-3">

                  </td>
                  <td class="col-md-3">
                    <div class="d-flex ">
                      $
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalRASP" [isDisabled]="true"
                        [(ngModel)]="TotalRASP" [id]="'37'" [type]="'inputNumber'" class="number-field w-100 total-field" [funType]="'typeTwo'"
                        [placeholder]="'0'">
                      </ap-form-field>

                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
        <ol start="9" class="section-content list-content m-0">
          <li style="counter-reset: count 8;">
            <dst-custom-input class="custom-input-100 input-width w-100"
                              [type]="'textarea'" [label]="''" [maxLength]="1000" [value]="RsnForIncOrDec"
                              (valChange)="RsnForIncOrDec = $event;">
            </dst-custom-input>
          </li>
        </ol>
      </div>
      <dst-template-leid-selector [isBranchEntity]="true" [label]="'Please specify the LEID of the Branch Entity:'"
        [labelId]="'3'" (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue branch-entity-align">{{!branchEntity ? '' :
                                                                            branchEntity.ENTITY}}</span>
      </div>
      <div class="leid-list d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor"> /
          </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
        </div>
      </div>
      <div>
          <span style="height: 100px;"></span>
      </div>
    </div>
  </div>
</div>
