import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DfMeDropdownChanges} from './tree-node/df-me-dropdown-node/df-me-dropdown-node.component';
import {DfMeDropdownNode} from './models/DfMeDropdownNode';
import {DfMeDropdownService} from './df-me-dropdown.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'gtw-df-me-dropdown',
  templateUrl: './df-me-dropdown.component.html',
  styleUrls: ['./df-me-dropdown.component.scss']
})
export class DfMeDropdownComponent implements OnInit {

  data: DfMeDropdownNode[];
  loading!: boolean;
  selected: Set<string>;
  selectedParentMe!: DfMeDropdownNode;
  showDropContainer: boolean;
  showRightMenu: boolean;

  @Input() customActionCode: number | null; // DST App might need a standalone action code
  @Output() selectedMes: EventEmitter<string>;

  constructor(private meService: DfMeDropdownService) {
    this.data = [];
    this.selected = new Set<string>();
    this.showDropContainer = false;
    this.showRightMenu = false;
    this.customActionCode = null;
    this.selectedMes = new EventEmitter<string>();
  }

  ngOnInit(): void {
    // this.data.forEach((parentMe: DfMeDropdownNode) => {
    //   this.countChildren(parentMe);
    // });
  }

  loadMEsCustom(taxYear: string): void {
    this.data = [];
    this.selected.clear();
    this.showDropContainer = false;
    this.showRightMenu = false;
    this.loading = true;
    try {
      this.meService.loadMEs(taxYear, this.customActionCode)
        .subscribe((data: CustomMENodeResponse[]) => {
          data.forEach((c: CustomMENodeResponse) => {
            const meNode: DfMeDropdownNode = JSON.parse(c.JSON_TEST);
            this.countChildren(meNode);
            this.data.push(meNode);
          });
        }, (error: HttpErrorResponse) => {

        }, () => {
          this.loading = false;
        });
      // TODO add loading status
    } catch (e: any) {
      if (e instanceof SyntaxError) {
        console.log('JSON parse Syntax Error' + e);
        // TODO show alert message
      }
      this.loading = false;
    }
  }

  private countChildren(node: DfMeDropdownNode): number {
    if (!node.children) {
      return 1;
    }
    node.numSelected = 0;
    node.totalChildrenCount = 0;
    node.children.forEach((child: DfMeDropdownNode) => {
      node.totalChildrenCount! += this.countChildren(child);
    });
    return node.totalChildrenCount + 1;
  }

  toggleMenu(): void {
    this.showDropContainer = !this.showDropContainer;
  }

  selectAll(): void {
    this.data.forEach((parentMe: DfMeDropdownNode) => {
      parentMe.selected = true;
      this.selectParentMe(parentMe);
    });
    this.emitChanges();
  }

  unselectAll(): void {
    this.clear();
    this.emitChanges();
  }

  clear(): void {
    this.data.forEach((parentMe: DfMeDropdownNode) => {
      parentMe.selected = false;
      this.selectParentMe(parentMe, false);
    });
  }

  onParentMeSelected(parentMe: DfMeDropdownNode): void {
    this.selectParentMe(parentMe, parentMe.selected);
    this.emitChanges();
  }

  private selectParentMe(parentMe: DfMeDropdownNode, select: boolean = true): void {
    let counter = {value: 0};
    if (select) {
      this.selected.add(parentMe.key);
    } else {
      this.removeFromSelected(parentMe.key);
    }
    this.setChildren(parentMe, counter, select);
    parentMe.numSelected = !select ? 0 : counter.value;
  }

  // set children selection recursively
  private setChildren(node: DfMeDropdownNode, counter: any, select: boolean = true): void {
    if (!node.children) {
      return;
    }
    node.children.forEach((child: DfMeDropdownNode) => {
      child.selected = select;
      if (select) {
        this.selected.add(child.key);
        node.numSelected!++;
        counter.value++;
      } else {
        this.removeFromSelected(child.key);
        node.numSelected!--;
      }
      this.setChildren(child, counter, select);
    });
  }

  private removeFromSelected(key: string): void {
    this.selected.delete(key);
  }

  expandParentME(item: DfMeDropdownNode): void {
    this.selectedParentMe = item;
    this.showRightMenu = true;
  }

  onChildrenSelectionChanged(changes: DfMeDropdownChanges): void {
    if (changes.select) {
      this.selectedParentMe.numSelected! += changes.changedNodes.length;
      if (this.selectedParentMe.numSelected === this.selectedParentMe.totalChildrenCount) {
        this.selectedParentMe.selected = true;
        this.selected.add(this.selectedParentMe.key); // add parent ME node when selection changed
      }
      changes.changedNodes.forEach((node: DfMeDropdownNode) => {
        this.selected.add(node.key); // add changed children nodes
      });
    } else {
      this.selectedParentMe.numSelected! -= changes.changedNodes.length;
      this.selectedParentMe.selected = false;
      changes.changedNodes.forEach((changedNode: DfMeDropdownNode) => {
        this.removeFromSelected(changedNode.key); // remove all unselected children nodes
      });
      this.removeFromSelected(this.selectedParentMe.key); // remove the parent ME node
    }
    this.emitChanges();
  }

  private emitChanges(): void {
    this.selectedMes.emit(Array.from(this.selected).join(','));
  }
}

interface CustomMENodeResponse {
  JSON_TEST: string;
}
