<div *ngIf="pannelPosition == 1">
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: tasksList }">
    </ng-container>
</div>

<ng-template #recursiveListTmpl let-list="list">
    <ap-accordions [isGreyBackground]="false" [isTransparentAccordion]="false">
        <ng-container *ngFor="let task of list; let i = index" >
            <ap-accordions-children-item id="accordion-nest" class="accordion-nest" 
                [ngClass]="{'screen-no-show': task.screens && !mSetting.showScreen && !task.isClicked,
                            'screen-no-show-clicked': task.screens && !mSetting.showScreen && task.isClicked}"
                [isIconLeft]="true" [index]="task.id" [open]="isAccordionOpen || task.isOpen"
                (onItemChange)="itemChange($event)" [title]="task.label" (click)="retainOpenTask($event, task)">    
                <div class="a-accordion-content">
                    <div class="a-pb-12 sub-gray-container" 
                        *ngIf="task['children'] && task.children.length>0">
                        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: task.children }">
                        </ng-container>
                    </div>
                    <div *ngIf="task['screens'] && mSetting.showScreen" >
                        <gtw-activity-and-screen [selected_menu]='task.screens'
                            (showScreensEvent)="showScreens($event)">
                        </gtw-activity-and-screen>
                    </div>
                </div>
            </ap-accordions-children-item>
        </ng-container> 
    </ap-accordions>
</ng-template>


<div *ngIf="pannelPosition != 1">
    <ng-container *ngFor="let category of tasksList">
        <ap-accordions [isGreyBackground]="false" [isTransparentAccordion]="false" *ngIf="category.id >= 0 && category['children']">
            <ap-accordions-children-item  [open]="isAccordionOpen || category.isOpen"
                [isIconLeft]="true" [index]="category.id" (click)="retainOpenTask($event, category)"
                (onItemChange)="itemChange2($event)" [title]="category.label">
                <div class="a-accordion-content">
                    <div>
                        <gtw-activity-and-screen [selected_menu]='category.children'
                            (showScreensEvent)="showScreens($event)">
                        </gtw-activity-and-screen>
                    </div>
                </div>
            </ap-accordions-children-item>
        </ap-accordions>
    </ng-container>
</div>
