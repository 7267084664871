import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { throwError, concat, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntityChartUpdateService {

  private getEntityChartList = '/getEntityChartDetails';
  private getSourceSystemChartCodes = '/getSourceSystemChartCodes';
  private updateEntityChart = '/saveJsonObject?action_code=lfnyth';
  private getTransCountURL = '/loadJsonObject?action_code=mjnska';
  
  durationInSeconds = 4; 

  constructor(private httpClient:HttpClient,
              private _snackBar: MatSnackBar) { }

  getAllEntityChartList(baseURL:string,
                        taxYear:number, scenario:number, jcdKey:number, 
                        ccList:string, filingGroup:number):Observable<Object[]> {

    const options = {
                      withCredentials: true
                    };
    
    const url = `${baseURL}${this.getEntityChartList}?taxYear=${taxYear}&scenario=${scenario}&jcdKey=${jcdKey}&ccList=${ccList}&filingGroup=${filingGroup}`;
    return this.httpClient.get(url, options)
              .pipe(
                map((entityChartMapList:any) => { 
                      if(entityChartMapList.callSuccess == "1"){
                        return entityChartMapList.data;
                      } 
                      this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
                      throwError(new Error('oops!'));
                      return null;
                    }
                ));

  }

  getChartCodes(baseURL:string,
                taxYear:number,scenario:number, jcdKey:number,
                sourceSystem:string  ){
    
    const options = {
                      withCredentials: true
                    };

    const url = `${baseURL}${this.getSourceSystemChartCodes}?taxYear=${taxYear}&scenario=${scenario}&jcdKey=${jcdKey}&sourceSystem=${sourceSystem}`;
    
    return this.httpClient.get(url, options).pipe(
      map((codes:any) => {
                    if(codes.callSuccess == "1"){
                      return codes.data;
                    } 
                    this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
                    throwError(new Error('oops!'));
                    return null;
                }
      ));
  }

  getTransCount(baseURL:string,
    taxYear:number, scenario:number, jcdKey:number, 
    ccList:string){
      const options = {
        withCredentials: true
      };

      const url = `${baseURL}${this.getTransCountURL}&tax_year=${taxYear}&scenario=${scenario}&jcd_key=${jcdKey}&combination_key=${ccList}`;
      return this.httpClient.get(url, options)
              .pipe(
                map((list:any) => { 
                      if(list.callSuccess == "1"){
                        return list.jsonObject;
                      } 
                      this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
                      throwError(new Error('oops!'));
                      return null;
                    }
                ));

  }


  updateEntityChartCodes(baseURL:string,taxYear:number,processName:string,settings:any, entities:any,baseGlobalParams: any, checkLockedbj:any ){
    const options={
        withCredentials: true
    };
    const url = `${baseURL}${this.updateEntityChart}`;
    entities = JSON.parse(JSON.stringify(entities));
    entities.map((entity:any) => {delete entity.groupItems});
    const data:any = {
      "data": JSON.stringify(entities),
      "settings":JSON.stringify(settings),
      "tax_year":taxYear,//taxYear 
      "processName":processName,
      "scenario":settings.scenario,
      "jcd_key":settings.jcd_key,
      "checkLockedbj": checkLockedbj,
      "scenarioCode": baseGlobalParams.scenarioCode,
		  "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		  "issue_id": baseGlobalParams.issue_id,
		  "issue_short_desc": baseGlobalParams.issue_short_desc
    };

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return this.httpClient.post<any>(url, formData,options);
              
  }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }


}
