<div clss="a-text-editor-box ui-widget ui-editor-container ui-corner-all" (keydown)="keydownEditorEvent($event)">
  <div class="ui-editor-toolbar a-text-editor-box">


          <div class="btn-toolbar" id="toolbar{{editorId}}">
            <div class="btn-group">
                <a href='#' onclick="event.preventDefault()" (keydown)="onKeyDownEvent($event)" class="btn ql-font dropdown-toggle fa fa-font" name="selFontName" [tooltip-container]="'Font'" [placement]="'top'" unselectable="on" (click)="selectFont()" (clickOutside)="hideSelectFont()"><b class="caret"></b></a>
                <ul class="dropdown-menu" [class.show]="isFontShow">
                  <li *ngFor="let fontName of fonts" id="li-{{fontName.value}}" [ngClass]="{'btn-info': selectedFont === fontName.name}" [ngStyle]="{'font-family': fontName.name}" (click)="selectFontByName(fontName.name)">{{fontName.name}}</li>
                </ul>
            </div>
            <div class="btn-group">
                <a href='#' onclick="event.preventDefault()" (keydown)="onKeyDownEvent($event)" class="btn ql-size dropdown-toggle fa fa-text-height" [tooltip-container]="'Font Size'" [placement]="'top'" name="selFontSize" unselectable="on" (click)="selectFontSize()" (clickOutside)="hideSelectFontSize()">&nbsp;<b class="caret"></b></a>
                <ul class="dropdown-menu" [class.show]="isFontSizeShow">
                  <li data-edit="fontSize 5" [ngClass]="{'btn-info': selectedSize === 5}" (click)="selectFontSizeByNumber(5)">
                      <span class="text-font-huge">Huge</span>
                  </li>
                  <li data-edit="fontSize 3" [ngClass]="{'btn-info': selectedSize === 3}" (click)="selectFontSizeByNumber(3)">
                      <span class="text-font-normal">Normal</span>
                  </li>
                  <li data-edit="fontSize 1" [ngClass]="{'btn-info': selectedSize === 1}" (click)="selectFontSizeByNumber(1)">
                      <span class="text-font-small">Small</span>
                  </li>
              </ul>
            </div>
            <span class="btn-group">
                <button aria-label="Bold" class="btn ql-bold fa fa-bold" data-original-title="Bold (Ctrl+B)" [tooltip-container]="'Bold (Ctrl+B)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Italic" class="btn ql-italic fa fa-italic" data-original-title="Italic (Ctrl+I)" [tooltip-container]="'Italic (Ctrl+I)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button [hidden]="!isFull" aria-label="Strike" class="btn ql-strike fa fa-strikethrough" data-original-title="Strikethrough" [tooltip-container]="'Strikethrough'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button [hidden]="!isFull" aria-label="Underline" class="btn ql-underline fa fa-underline" data-original-title="Underline (Ctrl+U)" [tooltip-container]="'Underline (Ctrl+U)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
            </span>
            <span class="btn-group" [hidden]="!isFull">
                <button aria-label="Unordered List" class="btn ql-list fa fa-list-ul" data-original-title="Bullet list" [tooltip-container]="'Bullet list'" [placement]="'top'" value="bullet" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Ordered List" class="btn ql-list fa fa-list-ol" data-original-title="Number list" [tooltip-container]="'Number list'" [placement]="'top'" value="ordered" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Outdent List" class="btn ql-indent fa fa-outdent" data-original-title="Reduce indent (Shift+Tab)" [tooltip-container]="'Reduce indent (Shift+Tab)'" [placement]="'top'" value="-1" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Indent List" class="btn ql-indent fa fa-indent" data-original-title="Indent (Tab)" [tooltip-container]="'Indent (Tab)'" [placement]="'top'" value="+1" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
            </span>
            <span class="btn-group">
                <button aria-label="Align" class="btn ql-align fa fa-align-left" data-original-title="Align Left (Ctrl+L)" [tooltip-container]="'Align Left (Ctrl+L)'" [placement]="'top'" value="" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Align" class="btn ql-align fa fa-align-center" data-original-title="Center (Ctrl+E)" [tooltip-container]="'Center (Ctrl+E)'" [placement]="'top'" value="center" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)" ></button>
                <button aria-label="Align" class="btn ql-align fa fa-align-right" data-original-title="Align Right (Ctrl+R)" [tooltip-container]="'Align Right (Ctrl+R)'" [placement]="'top'" value="right" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Align" class="btn ql-align fa fa-align-justify" data-original-title="Justify (Ctrl+J)" [tooltip-container]="'Justify (Ctrl+J)'" [placement]="'top'" value="justify" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
            </span>
            <span class="btn-group" [hidden]="!isFull">
                <button aria-label="Insert Link" class="btn ql-link fa fa-unlink" data-original-title="Hyperlink" [tooltip-container]="'Hyperlink'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)"></button>
                <button aria-label="Insert Image" class="btn ql-image fa fa-picture-o" data-original-title="Insert picture (or just drag &amp; drop)" [tooltip-container]="'Insert picture (or just drag &amp; drop)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
            </span>
            <span class="btn-group" [hidden]="!isFull">
                <button aria-label="Remove Styles" class="btn fa fa-undo" data-original-title="Undo (Ctrl+Z)" [tooltip-container]="'Undo (Ctrl+Z)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
                <button aria-label="Redo Styles" class="btn fa fa-repeat" data-original-title="Redo (Ctrl+Y)" [tooltip-container]="'Redo (Ctrl+Y)'" [placement]="'top'" (click)="onContentChange(targetEdit)" (focus)="onTextAreaFocus($event)" (keydown.enter)="enterFocus($event)"></button>
            </span>
        </div>



        <div class="editor-container">
            <div class="ui-editor-content appkit-editor" id="editorContainer{{editorId}}" #divInput
                (paste)="isFocus = true"
                (click)="onTextAreaFocus($event, true)">
            </div>
            <span class="editor-placeholder" [class.d-none]="isFocus||isEdited" (click)="onClickPlaceHolder($event)">{{placeholderText}}</span>
        </div>

</div>
