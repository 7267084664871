<!-- <dst-create-disclosure-actions [disabled]="false" (onSaveClick)="save()" (onCancelClick)="cancel()"></dst-create-disclosure-actions> -->
<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form184">
    <dst-template-header [parentFormGroup]="form184" [overwriteFormName]="'STATEMENT PURSUANT TO SECTION 1.351-3'"
      [allowOptional]="true" [dropDownOptions]="headerOptions" (formOptionChanged)="onFormOptionChanged($event)"
      (consolGroupChanged)="OnConsolGroupChanged($event)"></dst-template-header>
    <ol style="margin: 15px;">
      <li class="one-point-four">The following property was transferred from
        <dst-template-leid-selector #mainLeidSelector [label]="'Please specify the LEID of the Transferor'"
          [labelId]="'100'" (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field entity-leid-label">
        </dst-template-leid-selector>
        <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
          Please specify the Transferor
        </div> -->
        <div class="entity-details">
          <span>Name of Transferor:</span><span class="template-color-blue">{{!transferor ? '' :
            transferor.ENTITY}}</span>
        </div>
        <div class="entity-details">
          <span>EIN:</span><span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ? transferor.EIN : 'N/A')}}</span><span>, a
            Transferor</span>
        </div>
        <div class="entity-details">
          <span></span><span class="template-color-blue">{{!transferor ? '' : transferor.COUNTRYINC +
            (transferor | taxtype: transferor?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>&nbsp;to</span>
        </div>
        <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee'" [labelId]="'101'"
          (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field entity-leid-label">
        </dst-template-leid-selector>
        <!-- <div class="a-form-error error-leid" *ngIf="transferee === undefined">
          Please specify the Transferee
        </div> -->
        <div class="entity-details">
          <span>Name of Transferee:</span><span class="template-color-blue">{{!transferee ? '' :
            transferee.ENTITY}}</span>
        </div>
        <div class="entity-details">
          <span>EIN:</span><span class="template-color-blue">{{!transferee ? '' : (transferee.EIN ? transferee.EIN : 'N/A')}}</span><span>,&nbsp;a
            Transferee</span>
        </div>
        <div class="entity-details">
          <span></span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYINC }}</span> &nbsp;<span>Corporation.</span>
          <!-- <span></span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYINC +
            (transferee | taxtype: transferee?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>.</span> -->
        </div>
      </li>
      <li class="one-point-four">The date(s) of the transfer(s) of assets:
        <div formGroupName="datesTransfer">
          <input type="hidden" formControlName="tran1">
          <input type="hidden" formControlName="tran2">
          <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)" [(ngModel)]="defaultDate"
            (click)="onDPOpen()" formControlName="dateTransfer1" class="required-field" required>
          </ap-date-picker>
          <!-- <div class="a-form-error"
            *ngIf="defaultDate === null && liquidationDate2 === null && liquidationDate3 === null">
            Please specify the Transaction date
          </div> -->
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 2)" [(ngModel)]="liquidationDate2"
            (click)="onDPOpen()" formControlName="dateTransfer2">
          </ap-date-picker>
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 3)" [(ngModel)]="liquidationDate3"
            (click)="onDPOpen()" formControlName="dateTransfer3">
          </ap-date-picker>
        </div>
      </li>
      <li><span class="one-point-four">Property Transferred:</span>
        <div formGroupName="propertiesDetails">
          <p class="one-point-four">
            The fair market value and basis of the property transferred by such transferor in the exchange, determined
            immediately before the transfer and
            aggregated as follows:
          </p>
          <ol class="inner-list">
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <span class="appkiticon icon-information-fill red-icon" [tooltip-container]="template1" [placement]="'right'"
                  [isWhite]="true"></span>
                <span class="appkiticon icon-information-fill red-icon" [tooltip-container]="template2" [placement]="'right'"
                  [isWhite]="true"></span>
                <ap-form-field
                  [label]="'Importation property transferred in a loss importation transaction, as defined in Sec. 1.362-3(c)(2) and (3), respectively;'"
                  [id]="'310'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true" (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                  name="importationProp" class="text-box" formControlName="importationProp" [(ngModel)]="importationProp">
                </ap-form-field>
                <!-- <print-error [control]="this.form184.get('propertiesDetails')?..get('importationProp')"></print-error> -->
              </div>
            </li>
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field [label]="'Loss duplication property as defined in Sec. 1.362-4(g)(1);'" [id]="'320'"
                  [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]="" [withButton]="true"
                  (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)" class="text-box"
                  formControlName="lossDupProp" [(ngModel)]="lossDupProp">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field
                  [label]="'Property with respect to which any gain or loss was recognized on the transfer (without regard to whether such property is also identified in paragraph (a)(3)(i) or (ii) of this Section);'"
                  [id]="'330'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]="" class="text-box"
                  [withButton]="true" (onChangeEvent)="dummyFunc($event)" (onClickButtonEvent)="dummyFunc($event)"
                  formControlName="gainLossAffectedProp" [(ngModel)]="gainLossAffectedProp">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content">
                <span class="a-form-label d-inline-block" style="cursor: default; font-size: inherit;">Property not described in paragraph (a)(3)(i), (ii), or (iii) of this Section;</span>

                <table class="table-layout properties-table">
                  <tbody>
                    <tr>
                      <td colspan="3">
                        <ap-button [btnText]="'Import Domestic or Foreign Trial Balance Data'"  *ngIf="showImport"
                          class="import-btn a-mr-10" [config]="{ isSecondary:true }" (onBtnClick)="import()" [isDisabled]="!defaultDate">
                        </ap-button>
                        <ap-checkbox [text]="'Report TB Data on Total Net Assets basis'" class="a-mx-10 import-btn"
                          (onCheck)="total($event)" *ngIf="showTotal">
                        </ap-checkbox>
                      </td>
                      <td>
                        <ap-selector [items]="propertyValBasisOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select a basis **'" (onSelectedChange)="selectBasisOption($event)"
                          formControlName="propertyValBasis" class="a-dropdown show-label required-field-dropdown field-full-width">
                        </ap-selector>
                        <!-- <div class="a-form-error" *ngIf="selectedBasis === -1">
                        Please select a Basis section
                      </div> -->
                      </td>
                      <td class="amount-column"></td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td><span class="thirteen">{{ selectedBasisName }} Basis to the Transferor</span></td>
                      <td><span class="thirteen">Fair Market Value on Date of Transfer</span></td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td class="header-column"><span>Description</span></td>
                      <td>
                        <ap-selector [items]="currencyOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyOneChange($event)"
                          formControlName="currency1" class="a-dropdown show-label required-field-dropdown currency-dropdown field-full-width">
                        </ap-selector>
                        <!-- <div class="a-form-error" *ngIf="selectedCurrency === 0">
                          Please select Fair Market value Currency type
                        </div> -->
                      </td>
                      <td>
                        <ap-selector [items]="currencyOptions2" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyTwoChange($event)"
                          formControlName="currency2" class="a-dropdown show-label required-field-dropdown currency-dropdown field-full-width">
                        </ap-selector>
                      </td>
                    </tr>
                    <ng-container formArrayName="additionalPropsDetails">
                      <tr *ngFor="let p of additionalPropsDetails.controls; let i = index;">
                        <ng-container [formGroupName]="i">
                          <td style="width: 3%;">
                            <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                              [btnSize]="'small'" *ngIf="i > 0" (onBtnClick)="removeAdditionalPropDetailsAt(i)">
                              <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                            </ap-button>
                          </td>
                          <td style="width: 3%;">
                            <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                              [btnSize]="'small'" (onBtnClick)="addAdditionalPropDetails()">
                              <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                            </ap-button>
                          </td>
                          <td style="width: 50%;">
                            <ap-form-field [label]="''" [id]="i + 'a'" [type]="'input'" formControlName="description">
                            </ap-form-field>
                          </td>
                          <td style="width: 22%;">
                            <ap-form-field [label]="''" [id]="i + 'b'" [type]="'inputNumber'" [funType]="'typeTwo'"
                              formControlName="transferorBasis" class="number-field"
                              (onChangeEvent)="onChangeNumber($event, i)">
                            </ap-form-field>
                          </td>
                          <td style="width: 22%;">
                            <ap-form-field [label]="''" [id]="i + 'c'" class="number-field" [type]="'inputNumber'"
                              [funType]="'typeTwo'" formControlName="fairMarketVal">
                            </ap-form-field>
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
                <div class="note-banner">
                  <span class="appkiticon icon-alert-outline"></span>&nbsp;
                  <span class="one-point-four">Note: Share/change in ownership information is not required by the
                    regulations and is optional information to be disclosed.
                    If share/ownership information is not provided, then this paragraph will not display when
                    saved.</span>
                </div>
                <div class="inline-paragraphs" formGroupName="shareChangeSec">
                  <p class="one-point-four">
                    <span>There were&nbsp;</span>
                    <ap-form-field [inlineBlock]="true" [id]="'380'" [type]="'inputNumber'" [funType]="'typeTwo'"
                      (onChangeEvent)="dummyFunc($event)" [minNum]="0" class="number-field"
                      formControlName="sharesBeforeTransfer" [(ngModel)]="sharesBeforeTransfer">
                    </ap-form-field>
                    <span>&nbsp;and&nbsp;</span>
                    <ap-form-field [inlineBlock]="true" [id]="'381'" [type]="'inputNumber'" [funType]="'typeTwo'"
                      (onChangeEvent)="dummyFunc($event)" [minNum]="0" class="number-field"
                      formControlName="sharesAfterTransfer" [(ngModel)]="sharesAfterTransfer">
                    </ap-form-field>
                    <span>&nbsp;shares of capital stock of <span class="template-color-blue">{{!transferee ? '' :
                      transferee.ENTITY}}</span> issued and outstanding immediately prior to and after the Transfer
                    respectively.</span>
                    <span class="template-color-blue">{{!transferor ? '' : transferor.ENTITY}}</span> owned
                    <ap-form-field [inlineBlock]="true" [id]="'382'" [type]="'inputNumber'" [funType]="'typeTwo'"
                      [maxNum]="100" [minNum]="0" (onChangeEvent)="dummyFunc($event)" class="number-field"
                      formControlName="stockBeforeTransfer" [(ngModel)]="stockBeforeTransfer"
                      [ngClass]="sharesBeforeTransfer && sharesAfterTransfer && sharesBeforeTransfer != sharesAfterTransfer && !stockBeforeTransfer? 'required-field' : ''">
                    </ap-form-field>
                    <span>&nbsp;% and&nbsp;</span>
                    <ap-form-field [inlineBlock]="true" [id]="'383'" [type]="'inputNumber'" [funType]="'typeTwo'"
                      [maxNum]="100" [minNum]="0" (onChangeEvent)="dummyFunc($event)" class="number-field"
                      formControlName="stockAfterTransfer" [(ngModel)]="stockAfterTransfer"
                      [ngClass]="sharesBeforeTransfer && sharesAfterTransfer && sharesBeforeTransfer != sharesAfterTransfer && !stockAfterTransfer? 'required-field' : ''">
                    </ap-form-field>
                    <span>&nbsp;% of the</span>
                    <ap-selector [items]="stockType" [type]="'SingleSelector'" [searchMethod]="'inline'" [placeholder]="'** select stock type **'"
                      (onSelectedChange)="selectStockType($event)" class="inline-dropdown show-label required-field-dropdown field-full-width">
                    </ap-selector>
                    <span>stock issued and outstanding of <span class="template-color-blue">{{!transferee ? '' :
                      transferee.ENTITY}}</span> before and after the Transfer respectively.</span>
                  </p>
                  <p>
                    <ap-checkbox class="checkbox-font"
                      [text]="'Check here if this is a Section 351 transfer related to a Section 304 transaction.'"
                      (onCheck)="check304Section()" [checked]="isSection304Related === true">
                    </ap-checkbox>
                  </p>
                  <div *ngIf="isSection304Related" formGroupName="section304Details">
                    <p class="inline-paragraphs one-point-two">
                      The above described transfer is a Section 304(a) exchange that is treated as one to which Section
                      351 applies. The following information
                      further describes the Transfer: <span class="template-color-blue">{{!transferor ? '' :
                        transferor.ENTITY}}</span> (a Significant Transferor), transferred&nbsp;
                      <ap-form-field [inlineBlock]="true" [id]="'384'" [type]="'inputNumber'" [funType]="'typeTwo'"
                        (onChangeEvent)="dummyFunc($event)" [minNum]="0" [(ngModel)]="amtFromSigTransferor"
                        formControlName="amtFromSigTransferor" class="number-field required-field mr-3">
                      </ap-form-field>
                      <ap-selector [items]="stockType304" [type]="'SingleSelector'" [searchMethod]="'inline'" [placeholder]="'** select stock type **'"
                        (onSelectedChange)="selectStockType304($event)" formControlName="stockType304"
                        class="inline-dropdown show-label field-full-width required-field-dropdown">
                      </ap-selector>
                      shares in the capital of
                    </p>
                    <!-- <div class="a-form-error" *ngIf="isSection304Related && (this.form184.get('propertiesDetails')?.get('shareChangeSec')?.get('section304Details')?.get('amtFromSigTransferor')?.errors?.required)">
                      Please specify Number Of Shares for 304 transactions
                    </div> -->
                    <!-- <print-error [control]="this.form184.get('propertiesDetails')?.get('shareChangeSec')?.get('section304Details')?.get('amtFromSigTransferor')?.errors.required"></print-error> -->
                    <div>
                      <div class="ap-form-field-my-wrapper">
                        <dst-template-leid-selector
                          [label]="'Please specify the LEID of the Target/Acquired Corporation:'" [labelId]="'3'"
                          class="required-field entity-label" (onSelectedLEChanged)="onTransferLEIDChanged($event)">
                        </dst-template-leid-selector>
                        <!-- <div class="a-form-error error-leid" *ngIf="isSection304Related && (targetLEID === undefined)">
                          Please specify Target/Acquired LEID for 304 transactions
                        </div> -->
                        <!-- <ap-form-field [label]="'Please specify the LEID of the Target/Acquired Corporation'" [id]="'385'" [type]="'input'"
                                       formControlName="targetLEID">
                        </ap-form-field> -->
                      </div>
                      <p class="target-entity-details one-point-two inline-paragraphs">
                        <span>Target/Acquired Corporation:</span><span class="template-color-blue">{{!targetLEID ? '' :
                          targetLEID.ENTITY}}</span>, EIN: <span class="template-color-blue">{{!targetLEID ? '' :
                          (targetLEID.EIN ? targetLEID.EIN : 'N/A')}}</span>, a <span class="template-color-blue">{{!targetLEID ? '' :
                          targetLEID.COUNTRYINC + (targetLEID | taxtype: targetLEID?.TAXTYPECODE: entityNameChangeOnDate)}}</span>, to <span
                          class="template-color-blue">{{!transferee ? '' : transferee.ENTITY}}</span>, (the Transferee
                        ) in exchange for
                        <ap-selector [items]="section304ExchangeType" [type]="'Dropdown'"
                          (onSelectedChange)="selectExchangeType($event)" formControlName="exchangeType" class="required-field">
                        </ap-selector>
                        in the amount of
                        <span class="d-flex">
                          <ap-form-field [inlineBlock]="true" [id]="'386'" [type]="'inputNumber'" [funType]="'typeTwo'"
                            (onChangeEvent)="dummyFunc($event)" class="number-field required-field" formControlName="amtInExchangeFor"
                            [(ngModel)]="amtInExchangeFor">
                          </ap-form-field>
                          <ap-selector [items]="currencyOptions3" [type]="'SingleSelector'" [searchMethod]="'inline'"
                            (onSelectedChange)="selectedCurrency3($event)" formControlName="currencyType"
                            style="margin-left: 10px; width: 25%;" [placeholder]="'** Select Currency **'"
                            class="show-label currency-dropdown field-full-width required-field-dropdown">
                          </ap-selector>
                        </span>
                        <ap-form-field [id]="'387'" [type]="'textarea'" [value]="" (onChangeEvent)="dummyFunc($event)"
                          formControlName="comment" [(ngModel)]="Para">
                        </ap-form-field>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </li>
      <li class="one-point-four">The date and control number of any private letter ruling(s) issued by IRS in connection
        with the section 351 exchange:
        <div id="step4" formGroupName="privateLetterRul">
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 4)" [(ngModel)]="liquidationDate4"
            (click)="onDPOpen()" formControlName="dateIssued">
          </ap-date-picker>
          <ap-form-field [id]="'401'" [type]="'input'" formControlName="controlNum" [(ngModel)]="controlNum">
          </ap-form-field>
        </div>
      </li>
      <li class="one-point-four">The following liabilities were assumed by <span
          class="template-color-blue">{{!transferee ? '' : transferee.ENTITY}}</span> in the Transfer:
        <div formGroupName="liabilitiesAssumed">
          <table class="table-layout">
            <tbody>
              <tr>
                <td colspan="2"></td>
                <td class="header-column"><span>Description</span></td>
                <td class="header-column"><span>Amount</span></td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td><span>(input liabilities as negative amounts)</span></td>
              </tr>
              <ng-container formArrayName="liabilityDetails">
                <tr *ngFor="let l of liabilityDetails.controls; let i = index;">
                  <ng-container [formGroupName]="i">
                    <td style="width: 3%;">
                      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                        [btnSize]="'small'" *ngIf="i > 0" (onBtnClick)="removeLiabilityDetailsAt(i)">
                        <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                      </ap-button>
                    </td>
                    <td style="width: 3%;">
                      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                        [btnSize]="'small'" (onBtnClick)="addLiabilityDetails()">
                        <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                      </ap-button>
                    </td>
                    <td style="width: 70%;">
                      <ap-form-field [label]="''" [id]="(500 + i).toString() + 'a'" [type]="'input'"
                        formControlName="description">
                      </ap-form-field>
                    </td>
                    <td>
                      <ap-form-field [label]="''" [id]="(500 + i).toString() + 'b'" [type]="'inputNumber'" [maxNum]="-1"
                        [funType]="'typeTwo'" formControlName="amount" class="number-field">
                      </ap-form-field>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="supp-info-wrapper" formGroupName="suppInfo">
            <div><span class="appkiticon icon-information-fill" [tooltip-container]="template3" [placement]="'right'"
                [isWhite]="true"></span>Supplemental Information:</div>
            <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" (onChangeEvent)="dummyFunc($event)"
              formControlName="info" [(ngModel)]="info">
            </ap-form-field>
          </div>
          <dst-template-leid-selector class="one-point-two" [isBranchEntity]="true"
            [label]="'Please specify the LEID of the Branch Entity:'" [labelId]="'4'"
            (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
          </dst-template-leid-selector>
          <div class="entity-details one-point-two">
            <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' :
              branchEntity.ENTITY}}</span>
          </div>
          <!--          <div class="ap-form-field-my-wrapper" formGroupName="branchInfo">-->
          <!--            <ap-form-field [label]="'Please specify the LEID of the Branch Entity'" [id]="'551'" [type]="'input'" formControlName="branchLEID">-->
          <!--            </ap-form-field>-->
          <!--          </div>-->
          <!--          <div class="branch-entity-details">-->
          <!--            <span>Branch Entity Name:</span><span></span>-->
          <!--          </div>-->
          <div class="leid-list one-point-four" style="margin-top: 15px;">
            <span>LEIDs: </span>
            <div>
              <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor">
                / </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
            </div>
          </div>
        </div>
      </li>
    </ol>

  </div>
</div>
<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      ***Please note, DST will automatically default to NONE in the boxes that specifically apply to loss importation
      property if §362(e)(1) and §334(b)(1)(B) DO NOT apply to the transaction. Please leave these fields blank if
      §362(e)(1) and §334(b)(1)(B) DO NOT apply to the transaction.***
    </div>
  </div>
</ng-template>
<ng-template #template2>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Final regulations under §362(e)(1) and §334(b)(1)(B) apply to certain loss built-in loss transactions under §§
      351, 368 and 332 that would result in the importation of a loss into the U.S. Federal tax system. For the above
      mentioned in-bound nonrecognition transfers, the rules require identification of "importation property" on
      asset-by-asset basis using a hypothetical sale approach. Thus if the transferors gain or loss on the "sale" would
      not subject to federal tax but transferee/acquiring would be subject to federal tax on the sale of the transferred
      property, then the property is "importation property". If the aggregate basis of the importation property exceeds
      the aggregate FMV, then the transaction is a "loss importation transaction" and is subject to the anti-loss
      importation rules (i.e. basis is reduced to FMV). The final regulations generally apply to transactions occurring
      after March 27, 2016
      For further information, please refer to the Current Year Updates under Training in the DST.
    </div>
  </div>
</ng-template>
<ng-template #template3>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Additional information to further describe the transaction may be added in text box
    </div>
  </div>
</ng-template>
