import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class S3FolderManagerService {

  private attachments$ = new BehaviorSubject<any[]>([]);
  private searchAttachments$ = new BehaviorSubject<string>('');

  getAttachments(): Observable<any[]> {
    return combineLatest([
      this.searchAttachments$,
      this.attachments$
    ]).pipe(
      map(([searchQuery, attachments]) => attachments.filter(file => file.FILE_NAME.includes(searchQuery)))
    );
  }

  loadAttachments(attachments: any[]) {
    this.attachments$.next(attachments);
  }

  searchAttachments(searchText: string) {
    this.searchAttachments$.next(searchText);
  }

}
