<div>
  <div class="template-header-wrapper container">
    <div class="row">
      <ap-selector [items]="consolGroups" [type]="'Dropdown'" [placeholder]="placeholderText"
                   (onSelectedChange)="onConsolGroupChanged($event)"></ap-selector>
    </div>
    <div class="row" *ngIf="!isBulk">
      <span class="tax-return-desc one-point-four">{{taxYear}} {{taxReturnDetails}}</span>
    </div>
    <div class="row" *ngIf="!isBulk">
      <span class="form-name one-point-four">{{formName}}</span>
      <span class="optional" *ngIf="allowOptional">
        <ap-selector class="option-selection" [items]="dropDownOptions" [type]="'Dropdown'" [placeholder]="' '"
                     (onSelectedChange)="onOptionChanged($event)"></ap-selector>
      </span>
      <span class="form-name same-line">&nbsp;{{sameLineText}}</span>
    </div>
    <div class="row" *ngIf="extraLine">
      <span class="form-name one-point-four">{{extraLine}}</span>
    </div>
  </div>
</div>
