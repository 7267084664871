<div mat-dialog-content>
    <div class="confirm-message">
        <span class="msg-title"><i class="appkiticon icon-logout-fill"></i>Confirm </span> <br>
        Are you sure, you want to run Settlement Process ?
    </div>
  
</div>
<div mat-dialog-actions>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">
        <ap-button [btnText]="'Cancel'" class="a-mr-10 a-btn-transparent a-btn-gray" [isDisabled]="!enableButton" [btnType]="'a-btn-gray'"
            [config]="{isPrimary: true}" (onBtnClick)="cancel()"></ap-button>
        <ap-button [btnText]="'Yes'" class="a-mr-10 a-btn-transparent" [isDisabled]="!enableButton" [config]="{isPrimary: true}"
        (onBtnClick)="syncTestedUnits()"><i class="fa fa-spin fa-spinner" *ngIf="!enableButton" ></i></ap-button>

    </div>
  
</div>