import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gtw-sub-tasks-container',
  templateUrl: './sub-tasks-container.component.html',
  styleUrls: ['./sub-tasks-container.component.scss']
})
export class SubTasksContainerComponent implements OnInit {

  @Input('tax-type-name')
  set _taxTypeName(taxTypeName: any){
    this.taxTypeName = taxTypeName;
  }
  @Input('task-list')
  set _taskList(taskList: any){
    this.taskList = taskList;
  }
  @Input('pannel-position') pannelPosition:number = 0;

  isAccordionOpen:boolean =false;
  @Input('is-accordion-open')
  set _isAccordionOpen(value:boolean){
    this.isAccordionOpen =value;
  } 

  mSetting:any={showScreen:true};
  @Input('m-setting')
  set _mSetting(value : any){
    this.mSetting = value;
  }

  @Output() showScreensEvent = new EventEmitter<any>();
  @Output() panelClose = new EventEmitter();

  taxTypeName = '';
  taskList = []; // example {taskName: [Activity List]}

  searchText ='';

  constructor() { }

  ngOnInit(): void {
  }

  closePanel(){
    this.panelClose.emit();
  }

  showScreens(screen : any){
    this.showScreensEvent.emit(screen);
  }
}
