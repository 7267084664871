<div class="main-container">
  <div class="grid-container" id="dc-report-grid-container" style="height:calc(100% - 55px);">
    <div class="top-toolbar">
      <div class="float-left ml-2">
        <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
          (onChange)="onTaxYearChange()">
        </p-dropdown>

        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
      </div>
    </div>

    <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName"
      [loading]="loading" [scrollable]="true" scrollHeight="calc(100vh - 270px)" responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
      class="data-collect-table" [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">

      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'45px'">
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
        <tr [style.height]="'45px'">
          <th></th>
          <th>
            <input pInputText type="text" [(ngModel)]="fStatementId"
              (input)="filterCol($event, 'STATEMENTID', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fStatementName"
              (input)="filterCol($event, 'STATEMENTNAME', 'contains')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fActionFlag"
              (input)="filterCol($event, 'DATACOLLECT_ACTION_FLAG', 'startsWith')" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" [(ngModel)]="fActionRequired"
              (input)="filterCol($event, 'DATACOLLECT_ACTION_EXPL', 'contains')" class="p-column-filter">
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr style="height: 70px;">
          <td style="text-align: center; width: 60px;">
            <span>{{ rowData.TAXYEAR }}</span>
          </td>
          <td style="overflow: visible; width: 100px;">
            <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENTID }}</span></a>
          </td>
          <td class="text-pre-wrap" style="width: 290px;">
            <span>{{ rowData.STATEMENTNAME }}</span>
          </td>
          <td style="text-align: center; width: 125px;">
            <span>{{ rowData.DATACOLLECT_ACTION_FLAG }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 280px;">
            <span>{{ rowData.DATACOLLECT_ACTION_EXPL }}</span>
          </td>
          <td class="item-aligned" style="width: 240px;">
            <div class="row" *ngIf="rowData.DATACOLLECT_ACTION_FLAG === 'Yes' && rowData.REVIEWNAME !== 'N/A'">
              <div class="col-md-10 pr-0 rev-Expl" *ngIf="rowData.REVIEWNAME !== 'N/A'">
                <ap-selector class="field-full-width" [items]="rowData['revExplanations_' + rowData.id]" 
                  [type]="'SingleSelector'" [searchMethod]="'inline'" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled"
                  (onSelectedChange)="reviewerExplanationChange($event, rowData)"></ap-selector>
              </div>
              <div class="col-md-2 pl-0" *ngIf="rowData.REVIEWNAME === 'Other: Explain'">
                <span class="appkiticon icon-pencil-outline pt-2"
                  (click)="otherExplanation(rowData)"></span>
              </div>
            </div>
            <span *ngIf="rowData.REVIEWNAME === 'N/A'">N/A</span>
          </td>
          <td class="item-aligned" style="width: 130px;">
            <ap-checkbox class="a-mx-10" [checked]="rowData.ISREVIEWED === 'Reviewed'"
              (onCheck)="statementReviewedChange($event, rowData)" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">
            </ap-checkbox>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>


  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>

  <dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
  </dst-disc-modal-viewer>

</div>

<ap-modal #otherExplanationModal *ngIf="showOtherExplanationModal" appModalId="rev-other-exp">
  <div #modalRef2 slot="content">
    <div class="modal-dialog" style="max-width: 31.25rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">{{otherExplActiveRow.STATEMENTID}} - Other: Explain</span>
          </div>
        </div>
        <div class="a-modal-body">
          <p>Please Enter Other Explanation to Save.</p>
          <ap-form-field class="other-expl-height" [inlineBlock]="true" [type]="'textarea'" 
            [(ngModel)]="otherExplanationText" [isDisabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">
          </ap-form-field>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
            (click)="onOtherExplanationSave('rev-other-exp')" [disabled]="(dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled">Save
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
            (click)="onOtherExplanationCancel('rev-other-exp')">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
