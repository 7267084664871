import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng-lts/api';
import { PartnershipDashboardService } from './partnership-dashboard.service';
import { DashboardFilterService } from '../../../diagnostic-dashboard/dashboard-filter/dashboard-filter.service';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface Column {
    field: string;
    header: string;
}

@Component({
    selector: 'gtw-partnership-dashboard',
    templateUrl: './partnership-dashboard.component.html',
    styleUrls: ['./partnership-dashboard.component.scss']
})

export class PartnershipDashboardComponent implements OnInit {
    loadTable: boolean = true;
    


    constructor(public partnershipDashboardService: PartnershipDashboardService,
        public dashboardFilterService: DashboardFilterService,
        private cdr: ChangeDetectorRef) {
        this.partnerships = [];
        this.partners = [];
    }


    scenarioCode: string = '';
    globalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
    private loadHoDetailsSubject = new Subject<any>();
    private loadProcsSubject = new Subject<any>();

    @Input('global-params')
    set _global_params(globalParams: string) {
        try {
            let params = JSON.parse(globalParams);
            if (params != undefined) {
                this.globalParams = params;
            }
        } catch (e) {
            console.log("Global Params not stable. Still waiting to get resolved.");
        }
    }

    @Input('tax-years')
    set _taxYears(taxYears: string) {
        try {
            this.taxYears = taxYears;
        } catch (e) {
            console.log('Input not stable yet');
        }
    }
    taxYears: any;
    baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '/gtw', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
    clientKey: any;

    @Input('base-urls')
    set _base_urls(baseUrls: string) {
        try {
            this.baseURLs = JSON.parse(baseUrls);
        } catch (e) {
            console.log('Input not stable yet');
        }
    }

    @Input('client-key')
    set _clientKey(clientKey: string) {
        try {
            this.clientKey = clientKey;
        } catch (e) {
            console.log('Input not stable yet');
        }
    }

    credits_card_details: any = {};
    sourcing_card_details: any = {};
    ti_card_details: any = {};

    selectedFilterParams: any;
    hoDetails: any;

    tableData: any;


    gridTitle = this.partnershipDashboardService.gridTitle;

    switch(status: any) {
        console.log(status);
    }

    baseValue!: string;
    filterValue!: string;
    selectedPartnership: any;
    selectedPartner: any;

    partnerships: SelectItem[] = [];
    partners: any;
    partnersOptions: SelectItem[] = [];

    filterBase(event: any) {
        console.log(event);
        this.filterValue = event;
    }

    saveFilters(event: any) {
        if (event.screen_key == 11588) {
            console.log('dashboard loading', event);

            this.selectedFilterParams = event
            this.partnershipDashboardService.updateSelectedFilterParams(this.selectedFilterParams);

            let params = {
                filing_key: this.selectedFilterParams.filing_key,
                scenario: this.selectedFilterParams.scenario,
                tax_year: this.selectedFilterParams.tax_year,
                jcd_key: this.selectedFilterParams.jcd_key,
                parent_me_string: this.selectedFilterParams.parent_me_string
            }

            this.loadDashboard(params);
        }
    }

    loadDashboard(params: any) {
        this.loadHoDetails(params);

    }

    hoLoading: boolean = false;
    contentLoading: boolean = false;

    cols!: Column[];

    public primaryConfig = {
        isPrimary: true
    }


    loading: boolean = false;

    isClickable = false;

    ngOnInit(): void {
        if (this.partnershipDashboardService.hoLoading !== undefined) {
            this.hoLoading = this.partnershipDashboardService.hoLoading;
        } else {
            this.hoLoading = true;
        }
        if (this.partnershipDashboardService.contentLoading !== undefined) {
            this.contentLoading = this.partnershipDashboardService.contentLoading;
        } else {
            this.contentLoading = true;
        }
        this.partnershipsMap = this.partnershipDashboardService.getPartnershipsMap();
        if (this.partnershipsMap) {
            this.partnerships = Object.keys(this.partnershipsMap).map(key => {
                return {
                    label: key,
                    value: this.partnershipsMap[key]
                };
            });
        }

        if (this.partnershipDashboardService.selectedFilterParams) {
            this.selectedFilterParams = this.partnershipDashboardService.getSelectedFilterParams();
          }

        this.selectedPartnership = this.partnershipDashboardService.getSelectedPartnership() || {};
        this.partners = this.partnershipDashboardService.getPartners() || [];
        this.partnersOptions = this.partners.map((partner: any) => {
            return {
                label: partner,
                value: partner
            };
        });

        this.partnershipDashboardService.getCardDetails().subscribe(cardDetails => {
            if (cardDetails) {
                this.credits_card_details = cardDetails['ho_credits'];
                this.sourcing_card_details = cardDetails['ho_sourcing'];
                this.ti_card_details = cardDetails['ho_ti'];
            }
        });

        this.tableData = this.partnershipDashboardService.getTableData() || [{}];

        this.loadHoDetailsSubject.pipe(
            switchMap(filterParams => this.partnershipDashboardService.getHoDetails('/gtw/', filterParams))
        ).subscribe((data: any) => {
            if (!data.jsonObject || data.jsonObject.length === 0) {
                console.log('data.jsonObject is null or empty');
                return;
            }
            this.partnershipsMap = {} as any;
            this.partnerships = [];
            for (let obj of data.jsonObject) {
                if (!this.partnershipsMap[obj.LEID]) {
                    this.partnershipsMap[obj.LEID] = {
                        leidMap: {},
                        ho_combination_key: obj.HO_COMBINATION_KEY,
                        le_name: obj.LE_NAME,
                        year_begin: obj.TAX_YEAR_BEGIN,
                        year_end: obj.TAX_YEAR_END,
                        ein_num: obj.EIN
                    };
                }

                if (this.partnershipsMap[obj.LEID].leidMap[obj.LEID]) {
                    this.partnershipsMap[obj.LEID].leidMap[obj.LEID].push(obj.PTR_LEID);
                } else {
                    this.partnershipsMap[obj.LEID].leidMap[obj.LEID] = [obj.PTR_LEID];
                }
            }

            this.partnerships = Object.keys(this.partnershipsMap).map(key => {
                return {
                    label: key,
                    value: this.partnershipsMap[key]
                };
            });

            let selectedPartnership = this.partnerships[0];

            this.partnershipDashboardService.updatePartnershipsMap(this.partnershipsMap);
            //this.partnershipDashboardService.updatePartnerships(partnerships);
            this.partnershipDashboardService.updateSelectedPartnership(selectedPartnership);

            this.selectPartnership({ value: selectedPartnership.value });
            this.hoLoading = false;
            this.partnershipDashboardService.hoLoading = this.hoLoading;
        });

        this.loadProcsSubject.pipe(
            switchMap(params => this.partnershipDashboardService.loadProcs('/gtw/', params.filterParams, params.ho_combination_key))
        ).subscribe(() => {
            this.populateCards();
            this.selectCard({
                currentTarget: {
                    id: "ti"
                }
            });
            this.contentLoading = false;
            this.partnershipDashboardService.contentLoading = this.contentLoading;
        });


    }
    objectKeys(obj: any) {
        return Object.keys(obj);
    }


    leidMap: { [key: string]: string[] } = {};
    partnershipsMap: any;
    loadHoDetails(filterParams: any) {
        this.hoLoading = true;
        this.partnershipDashboardService.hoLoading = this.hoLoading;
        this.loadHoDetailsSubject.next(filterParams);
        console.log("filterParams: " + filterParams);
    }

    selectPartnership(event: any) {
            this.partners = [];
            this.partnersOptions = [];
            this.selectedPartnership = event.value;
            this.contentLoading = true;
            this.partnershipDashboardService.contentLoading = this.contentLoading;
            let leidMapKeys = Object.keys(this.selectedPartnership.leidMap);
            if (leidMapKeys.length > 0) {
                this.partners = this.selectedPartnership.leidMap[leidMapKeys[0]];
                this.partnershipDashboardService.updatePartners(this.partners);
            }
            this.partnersOptions = this.partners.map((partner: any) => {
                return {
                    label: partner,
                    value: partner
                };
            });

            this.partnershipDashboardService.updateSelectedPartnership(this.selectedPartnership);

            this.loadProcsSubject.next({
                filterParams: this.selectedFilterParams,
                ho_combination_key: this.selectedPartnership.ho_combination_key
            });
    }
    

    switchTableView(key: string) {
        console.log("switched to: " + key);
        const jsonObject = this.partnershipDashboardService.screenDetails[key];

        if (!jsonObject || jsonObject.length === 0) {
            console.log(`jsonObject associated with key "${key}" is null or empty`);
            this.cols = [];
            this.tableData = [{}];
            this.partnershipDashboardService.updateTableData(this.tableData);
            return;
        }

        this.cols = Object.keys(jsonObject[0]).map(key => ({ field: key, header: key }));

        this.tableData = jsonObject;
        this.partnershipDashboardService.updateTableData(this.tableData);

    }

    selectCard(event: any) {
        let divs = document.getElementsByClassName('cards-spacing card-details');

        for (let i = 0; i < divs.length; i++) {
            divs[i].classList.remove('selectedCard');
        }

        let element;
        if (event.currentTarget && event.currentTarget.parentNode) {
            element = event.currentTarget.parentNode;
        } else if (event.currentTarget.id) {
            element = document.getElementById(event.id);
        }

        if (element) {
            element.classList.add('selectedCard');
        }

        let id = event.currentTarget ? event.currentTarget.id : event.id;
        switch (id) {
            case "ti":
                this.gridTitle = "Taxable Income";
                this.partnershipDashboardService.gridTitle = this.gridTitle;
                this.switchTableView("ho_ti_details");
                break;
            case "creds":
                this.gridTitle = "Credits";
                this.partnershipDashboardService.gridTitle = this.gridTitle;
                this.switchTableView("ho_credit_details");
                break;
            case "source":
                this.gridTitle = "Sourcing";
                this.partnershipDashboardService.gridTitle = this.gridTitle;
                this.switchTableView("ho_sourcing_details");
                break;

        }

    }

    populateCards() {
        this.partnershipDashboardService.populateCards();
    }


    isShow: boolean = true;

    showFilter() {
        this.isShow = !this.isShow;
        this.dashboardFilterService.notifyOther({ screen_key: 11588, isEnabled: true });
    }

    closeFilterPanel(event: any) {
        this.isShow = true
    }

    onMouseOver(event: any) {
        event.currentTarget.classList.add('hovered');
        this.changeCursor(true);
    }

    onMouseOut(event: any) {
        event.currentTarget.classList.remove('hovered');
        this.changeCursor(false);
    }

    changeCursor(isHovering: boolean): void {
        this.isClickable = isHovering;
    }


}
