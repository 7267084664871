import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BatchSourcingDefaultService {

	url = {
		saveSourcingData : '/saveJsonObject?action_code=pq78v0'
	}
  	durationInSeconds= 5;

  	constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	saveSourcingInfoData(baseURL : string, jsonData : any, baseGlobalParams: any, checkLockedObj: any, selectedMeCodes: any) {
		const url = `${baseURL}${this.url.saveSourcingData}`;
		const options={
		  	withCredentials: true
		};
	
		const data:any = {
			"tax_year":jsonData.TAX_YEAR.toString(),
			"scenario":jsonData.SCENARIO.toString(),
			"jcd_key":jsonData.JCD_KEY.toString(),
			"sso_id":jsonData.SSO_ID,
			"is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
			"clob_settings": JSON.stringify([jsonData]),
			"clob_data": JSON.stringify({"me_code" : selectedMeCodes.toString()}),
			"scenarioCode":baseGlobalParams.scenarioCode,
			"checkLockedbj": checkLockedObj,
			"me_code": selectedMeCodes.toString()
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));

		return this.http.post<any>(url, formData,options);
	}
	
  	openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
  	}
}
