<div class="modal-body">
    <div class="panel-body">

            <div class="confirm-message">
                <span class="msg-title"><i class="appkiticon icon-logout-fill"></i>Confirm </span> <br>
                Are you sure you want to delete the record?
            </div>

            <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">
                <ap-button [btnText]="'Delete'" class="a-mr-10 a-btn-transparent" [config]="{isPrimary: true}"
                    (onBtnClick)="deleteData()"></ap-button>

                <ap-button [btnText]="'Cancel'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
                    [config]="{isPrimary: true}" (onBtnClick)="modalClose()"></ap-button>
        </div>
    </div>
</div>