<mat-progress-bar 
          *ngIf="inProgress"
          color="primary"
          mode="indeterminate">
</mat-progress-bar>
<form class="screen-video-upload-form" [formGroup]="uploadScreenVideoForm"  (ngSubmit)="uploadScreenVideo()">
    <mat-form-field class="field-full-width">
      <mat-label>Video Name</mat-label>
      <input matInput placeholder="Traing video name" formControlName="videoName">
    </mat-form-field>
    
    <mat-form-field class="field-full-width">
      <mat-label>Video Description</mat-label>
      <textarea matInput placeholder="Traing video description" formControlName="videoDescription"></textarea>
    </mat-form-field>
    
    <div class="field-full-width video-section" cellspacing="0">  
        <div>
            <button class="video-button" type="button" mat-raised-button 
                 (click)="videoFileInput.click()">
                    {{screenVideoFileName || 'Choose File'}}
            </button>
            
            <input style="display:none;" placeholder="Traing video name" (change)="onFileSelected()" 
                #videoFileInput type="file" id="file" accept="video/*" required>
            
            <section *ngIf="screenVideoFileName">
                <mat-slider min="1" step="1" 
                    [max]="selectedVideoDuration" 
                    thumbLabel 
                    [value]="selectedTime"
                    (change)="selectFrame($event)">
                </mat-slider>
                <span class="chooseThumbnail">Choose a thumbnail...</span>
            </section>
            <section class="is_client_specific">
                <mat-checkbox id="is_client_specific" formControlName="is_client_specific" color="primary">
                    Client Specific?
                </mat-checkbox>
                
            </section>
        </div>             
        <span class="poster-file" id="thumbnail" *ngIf="screenVideoFileName">
            <img src="" #posterFile  width="200" height="125">
        </span>
    </div>

    <section class=' gtw-web-components upload-doc-actions'>

        <button style="margin-right:10px" class=" gtw-ele a-btn a-btn-primary a-btn-lg" type="submit" [disabled]="!uploadScreenVideoForm.valid">
            Save
        </button>
        <!-- type="reset" class="reset_button" -->
        <button style="margin-right:10px" id="reset_button" class="gtw-ele a-btn a-btn-primary a-btn-lg" (click)="resetUploadScreenVideoForm()">
            Clear
        </button>
    </section>
    
</form>