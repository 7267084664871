import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textDate'
})
export class TextDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe){
  }

  transform(value: string): string | null {
    
    if(!value) return "NA";
    
    let miliSeconds = (new Date(value)).getTime();
    let now = new Date();
    let diffMiliSeconds = now.getTime() - miliSeconds;
    
    if( diffMiliSeconds <= (30 * 60 * 1000) ){
      return 'just now';
    }else if( diffMiliSeconds <= (24 * 60 * 60 * 1000) ){
      return 'today';
    }
    let tempDate = new Date(value);
    return this.datePipe.transform(tempDate, 'fullDate');
  }

}
