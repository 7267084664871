<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" [disabledS3]="s3Uploading"
  (onSaveClick)="save()" (onCancelClick)="cancel()" [disableS3Upload]="false" 
  [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"
  (onS3UploadClick)="s3Upload()" [statementId]="stmtData?.statementid"></dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp367bform195">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS
      </span>
    </B>
  </div>
  <br />
  <div [formGroup]="form195" class="section-content ap-form-field-my-wrapper">
    Parties:
    <ol>
      <li>
        <span class="template-color-blue" *ngIf="loaded">{{secondaryEntityName}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryein == null? 'N/A': stmtData.secondaryein}}</span><span>, Recipient, a</span>
        <span *ngIf="secEntityNull">Transferee </span><span class="template-color-blue" *ngIf="loaded">
          {{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}
        </span><span>; and</span>
      </li>
      <li>
        <span class="template-color-blue" *ngIf="loaded">{{primaryEntityName}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryein == null? 'N/A': stmtData.primaryein}}</span><span>, Transferor, a</span>
        <span class="template-color-blue" *ngIf="loaded">
          <span *ngIf="prEntityNull">Transferor </span>
          {{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} {{primaryCorpTypeTxt}}
        </span><span>.</span>
        <ol class="inner-list" *ngIf="loaded">
          <li>
            The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the &quot;Code&quot;) applies.
          </li>
          <li>
            Pursuant to Treasury Reg. Sec. 301.7701-3(c)(1)(iii),
            <span class="template-color-blue" *ngIf="loaded">
              <span *ngIf="prEntityNull">Transferor </span>{{primaryEntityName}}
            </span>, an eligible entity with a single owner, elected via Form 8832 - Entity Classification Election to be disregarded as a separate entity for U.S. tax purposes, effective
            {{attMap['TransferDate'] == undefined ? '' : attMap['TransferDate'][0]}}.
            This election constitutes a deemed liquidation of the Corporation to which Code Sections 332, 367(b), and Treasury Reg. Sec. 1.367(b)-1(c)(4) apply.
          </li>
          <li>Not applicable.</li>
          <li>
            As a result of the liquidation, pursuant to Code Section 381(a) and Regulations under Section 367(b),
            <span *ngIf="secEntityNull">Transferee </span><span class="template-color-blue" *ngIf="loaded">
             {{secondaryEntityName}}
            </span>will either succeed to the amount and character of the earnings and profits of
            <span class="template-color-blue" *ngIf="loaded">
              <span *ngIf="prEntityNull">Transferor </span>
              {{primaryEntityName}}
            </span>(with appropriate adjustments under &sect; 1.367(b)-7, as applicable), or include any amount required to be taken into income or loss or as an adjustment to basis, or any other tax attribute, as applicable.
          </li>
          <li>
            For information required pursuant to regulations under Code Section 332, see the separate statement.
          </li>
          <li>
            Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or the regulations under those sections has been provided with this tax return on Form 5471, Information Return of U.S. Persons with Respect to Certain Foreign Corporations.
          </li>
          <li>
            Not applicable.
          </li>
        </ol>
      </li>
    </ol>
    <div class="row" *ngIf="loaded">
      <div>Additional Information:</div>
      <textarea rows="5" cols="60" readonly class="txtborder w-100">
        {{attMap['additionaltext'] == undefined ? '' : attMap['additionaltext'][0]}}
      </textarea>
    </div>

    <table style="width:100%">
      <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs:  <span *ngIf="prEntityNull">TransferorLEID </span> <span class="template-color-blue">{{stmtData.primaryleid == null? '': stmtData.primaryleid}}</span> / <span *ngIf="secEntityNull">TransfereeLEID </span> <span class="template-color-blue">{{stmtData.secondaryleid == null? '': stmtData.secondaryleid}}</span>
          </td>
          <td class="footer-right" *ngIf="stmtData.branchleid">
            Branch LEID: <span class="template-color-blue">{{stmtData.branchleid == null? '': stmtData.branchleid}}</span>
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
