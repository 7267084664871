
<div class="home-page-wrapper">
    <!-- [selectedTabIndex]="index"  -->
    <div class="filter-details">
        <div class='align-filter' >
            <div style="flex: 1;">
                <gtw-home-page-filter [tabList]="taxTypesList" [index]="taxTypeIndex"  (selectedTab)= "filterData()" style="display: block;"></gtw-home-page-filter>
                <gtw-home-page-filter class="tab-set-2"   [index]="catIndex"  [tabList]="categoryList" (selectedTab)= "filterData()" style="display: block;"></gtw-home-page-filter>
            </div>
            <div class="gtw-web-components a-search-wrapper col-12 no-gutters" style="margin-left: 18px; padding: 5px 0px; width: 100%; flex: 1;">
                <div>
                    <div class="a-input-with-icon a-input-md a-lf">
                        <input aria-label="Search" [(ngModel)]="tagName" placeholder="Ex ( Reclass, Book, Tax Adj, CbCr, 5471 )" class="a-text-input" style="box-shadow: 0 0 0 0.125rem var(--theme-color-control);">
                        <span class="material-icons srch-icn">search</span>
                    </div>
                </div>
            </div>
        </div>
        <app-loader [loaded]="isLoaded">
            <div class="change-view-icns">
                <div>
                    Results
                </div>
                <!-- (click)="orderByKey({field:'SCREEN_NAME'})" -->
                <div class="gtw-web-components sort-links">
                    <li (click)="isCard=!isCard"><span class="appkiticon" [ngClass]="{'icon-3by3grid-outline' : !isCard,  'icon-list-view-fill': isCard}"></span></li>
                    <li><span class="appkiticon icon-sorting-outline"></span>
                        <ap-selector [items]="homePageSortList" class="sort-drp-dwn" [type]="'Dropdown'" (onSelectedChange)="onHomePageListChanged($event)"></ap-selector>
                    </li>
                    <li><span class="appkiticon icon-refresh-outline"></span></li>
                </div>
            </div>
                  
            <div>
                <ng-container *ngIf="(filteredScreens | datafilter : {SCREEN_NAME:tagName, TAG_NAME:tagName, SCREEN_DESC:tagName,BRDCUMB:tagName} ) as newFilteredScreens">
                    <div *ngIf="newFilteredScreens.length > 0">
                        <div>
                            <cdk-virtual-scroll-viewport itemSize="100" class="viewport" *ngIf='!isCard'>
                                <div *cdkVirtualFor="let screen of newFilteredScreens; let i =index">        
                                    
                                    <gtw-screen-details class="screen-details" [(screen-details)]="newFilteredScreens[i]" [base-url-admin]="baseURLs.admin" 
                                        [client-key]="clientKey" [is-card]="isCard" (load-screen)="loadClickedScreen(screen)" 
                                        [base-url]="baseURLs.api" [scenario]="scenario" [tax_year]="tax_year">
                                    </gtw-screen-details>

                                </div>
                            </cdk-virtual-scroll-viewport>
                            <cdk-virtual-scroll-viewport itemSize="100" class="viewport" *ngIf='isCard'>
                                <div *cdkVirtualFor="let screen of newFilteredScreens; let i = index"> 
                                    <div *ngIf="i % 3 == 0" class="card-view">

                                        <gtw-screen-details class="screen-details" [(screen-details)]="newFilteredScreens[i]" [base-url-admin]="baseURLs.admin" 
                                            [client-key]="clientKey" [is-card]="isCard" (load-screen)="loadClickedScreen(screen)"
                                            [base-url]="baseURLs.api" [scenario]="scenario" [tax_year]="tax_year">
                                        </gtw-screen-details>

                                        <gtw-screen-details *ngIf="i + 1 < newFilteredScreens.length" class="screen-details" 
                                            [(screen-details)]="newFilteredScreens[i+1]" [base-url-admin]="baseURLs.admin" 
                                            [client-key]="clientKey" [is-card]="isCard" (load-screen)="loadClickedScreen(screen)"
                                            [base-url]="baseURLs.api" [scenario]="scenario" [tax_year]="tax_year">
                                        </gtw-screen-details>

                                        <gtw-screen-details *ngIf="i + 2 < newFilteredScreens.length" class="screen-details" 
                                            [(screen-details)]="newFilteredScreens[i+2]" [base-url-admin]="baseURLs.admin" 
                                            [client-key]="clientKey" [is-card]="isCard" (load-screen)="loadClickedScreen(screen)"
                                            [base-url]="baseURLs.api" [scenario]="scenario" [tax_year]="tax_year">
                                        </gtw-screen-details>

                                    </div>       
                                </div>
                            </cdk-virtual-scroll-viewport>

                        </div>
                    </div>

                    <div *ngIf="newFilteredScreens.length==0">
                        No Records Found...
                    </div>
                </ng-container>
                <div *ngIf="filteredScreens.length==0">
                    No Records Found...
                </div>
            </div>
       
        </app-loader>
    </div>

    
    <div class="screen-updates">
        <div>
            <gtw-home-page-screens-updates 
                [base-url] = "baseURLs.api" 
                [all-screens] ="allScreens"
                [active-screen-keys] = "activeScreenKeys"
                (load-screen)="loadClickedScreen($event)"
                [client-key]="clientKey">
            </gtw-home-page-screens-updates>
        </div>
    </div>
    
</div>
    