<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"></dst-create-disclosure-actions>
<div class="form-template-wrapper">
  <div align="center">
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        Form 8594 - Asset Allocation Statement Under Section 1060
      </span>
    </B>
  </div>
  <br />
  <div [formGroup]="form217" class="section-content" *ngIf="loaded">
    <div class="row" style="margin: 5px 0;">
      <div class="col-md-4 pl-0">Name as shown on return</div>
      <div class="col-md-4" style="margin-left: 20px;margin-right: -30px;"><span class="template-color-blue">{{ attMap['nameOnReturn'] == undefined ? '' :
          attMap['nameOnReturn'][0] }}</span></div>

      <div class="col-md-4 p-0">
        Identifying number:
        <span class="pl-3 template-color-blue">{{ attMap['identifyingNo'] == undefined ? '' : attMap['identifyingNo'][0] }}</span>
      </div>
    </div>

    <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
        Please specify the LEID of the Purchaser/Seller
      </div> -->
    <div class="entity-details">
      <span class="col-md-4 pl-0">Name of Purchaser/Seller:</span>
      <span class="template-color-blue col-md-4" style="margin-left: 20px;margin-right: -30px;">{{stmtData.primaryentity == null? '':
        stmtData.primaryentity}}</span>
    </div>

    <div class="row">
      <div class="col-md-4 pr-0">
        Check the box that identifies you:&nbsp;
          <!-- <span>{{ attMap['PurchaserOrSeller'] == undefined ? '' : attMap['PurchaserOrSeller'][0] }}</span> -->
      </div>
      <div class="col-md-3" style="margin-left: 20px;margin-right: -30px;">
        <ap-radio [name]="'radio-group-1'" [text]="'Purchaser'" [disabled]="true"
          [checked]="attMap['PurchaserOrSeller'] == undefined ? false :  attMap['PurchaserOrSeller'][0] === 'Purchaser' ? true : false"
          class="a-mx-10 required-field-radio radio-label-color">
        </ap-radio>
        <ap-radio [name]="'radio-group-1'" [text]="'Seller'" [disabled]="true"
          [checked]="attMap['PurchaserOrSeller'] == undefined ? false :  attMap['PurchaserOrSeller'][0] !== 'Purchaser' ? true : false"
          class="a-mx-10 required-field-radio radio-label-color">
        </ap-radio>
      </div>
      <!-- <div class="a-form-error error-leid" *ngIf="PurchaserOrSeller === ''">
          Please select check-box to indicate entity as Purchaser or Seller
        </div> -->
    </div>
    <br />
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td>
            <strong>&nbsp;&nbsp;Part I</strong>&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>General Information</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper">
      <div class="section-content pb-0">
        <div [hidden]="!(attMap['Choice204e'] == undefined ? false :  attMap['Choice204e'][0] === 'Yes')">
          <ol>
            <li>
              <div class="entity-details">
                <span class="col-md-4 pl-0">Name of other party to the transaction:</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondaryentity == undefined? '':
                  stmtData.secondaryentity}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">Identifying number:</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondaryein == undefined ? 'N/A':
                  stmtData.secondaryein}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">Address Line1</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondaryaddress1 == undefined ? '':
                  stmtData.secondaryaddress1}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">Address Line2</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondaryaddress2 == undefined ? '':
                  stmtData.secondaryaddress2}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">{{stmtData.secondarycountry && stmtData.secondarycountry === 'US' ? 'City': 'City / Town'}}</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondarycity == undefined ? '':
                  stmtData.secondarycity}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">{{stmtData.secondarycountry && stmtData.secondarycountry === 'US' ? 'State':'Province Or State'}}</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondarystate == undefined ? 'N/A':
                  stmtData.secondarystate}}</span>
              </div>
              <div class="entity-details" *ngIf="stmtData.secondarycountry && stmtData.secondarycountry !== 'US'">
                <span class="col-md-4 pl-0">Country</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondarycountryincdesc == undefined ? '':
                  stmtData.secondarycountryincdesc}}</span>
              </div>
              <div class="entity-details">
                <span class="col-md-4 pl-0">{{stmtData.secondarycountry && stmtData.secondarycountry === 'US' ? 'Zip Code' : 'Postal Code'}}</span>
                <span class="template-color-blue col-md-8">{{stmtData.secondaryzip == undefined ? '':
                  stmtData.secondaryzip}}</span>
              </div>
            </li>
          </ol>

        </div>
        <div [hidden]="!(attMap['Choice204e'] == undefined ? false :  attMap['Choice204e'][0] === 'No')">
          <div>
            <ol>
              <li>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    Name of other party to the transaction
                  </div>
                  <div class="col-md-8">
                    {{ attMap['NameOfOP'] == undefined ? '' : attMap['NameOfOP'][0] }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    Identifying Number
                  </div>
                  <div class="col-md-8">
                    {{ attMap['SSNOfOtherParty1'] == undefined ? '' : attMap['SSNOfOtherParty1'][0] }}
                    {{ attMap['EinOfOtherParty1'] == undefined ? '' : attMap['EinOfOtherParty1'][0] }}
                    {{ attMap['SSNOfOtherParty1'] !== undefined && !attMap['SSNOfOtherParty1'][0] && attMap['EinOfOtherParty1'] !== undefined && !attMap['EinOfOtherParty1'][0] ? 'N/A': ''}}

                  </div>
                </div>
                <div class="d-flex" *ngIf="SSNOfOtherParty1 === '' && EinOfOtherParty1 === ''">
                  <div class="col-md-4 pl-0">
                    EIN Missing Reason:
                  </div>
                  <div class="col-md-8">
                    {{ attMap['EIN'] == undefined ? '' : attMap['EIN'][0] }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    Address Line 1
                  </div>
                  <div class="col-md-8">
                    {{ attMap['AddressOfOtherParty1'] == undefined ? '' : attMap['AddressOfOtherParty1'][0] }}

                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    Address Line 2
                  </div>
                  <div class="col-md-8">
                    {{ attMap['AddressOfOtherParty2'] == undefined ? '' : attMap['AddressOfOtherParty2'][0] }}

                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    City
                  </div>
                  <div class="col-md-8">
                    {{ attMap['CityOfOtherParty'] == undefined ? '' : attMap['CityOfOtherParty'][0] }}

                  </div>
                </div>
                <div class="d-flex" *ngIf="attMap['Choice204f'] == undefined ? false : attMap['Choice204f'][0] === 'Yes' ? true : false">
                  <div class="col-md-4 pl-0">
                    State
                  </div>
                  <div class="col-md-8">
                    {{ stateValue }}
                  </div>
                </div>
                <div class="d-flex" *ngIf="attMap['Choice204f'] == undefined ? false :  attMap['Choice204f'][0] === 'No' ? true : false">
                  <div class="col-md-4 pl-0">
                    Province or State
                  </div>
                  <div class="col-md-8">
                    {{ attMap['ProvOrStateOP'] == undefined ? '' : attMap['ProvOrStateOP'][0] }}

                  </div>
                </div>
                <div class="d-flex" *ngIf="attMap['Choice204f'] == undefined ? false :  attMap['Choice204f'][0] === 'No' ? true : false">
                  <div class="col-md-4 pl-0">
                    Country
                  </div>
                  <div class="col-md-8">
                    {{ countryValue }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-md-4 pl-0">
                    Zipcode
                  </div>
                  <div class="col-md-8">
                    {{ attMap['ZIPCode5'] == undefined ? '' : attMap['ZIPCode5'][0] }}

                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <ol start="2">
          <li>
            <div class="d-flex">
              <div class="col-md-4 pl-0">
                Date of sale
              </div>
              <div class="col-md-8">
                {{ attMap['DateOfSale'] == undefined ? '' : attMap['DateOfSale'][0] }}

              </div>
            </div>
            <!-- <div class="a-form-error error-fields" *ngIf="DateOfSale === '' || DateOfSale === null">
              Please enter the date of the transaction
            </div> -->
          </li>
          <li>
            <div class="d-flex">
              <div class="col-md-4 pl-0">
                Total Sales Price (Consideration)
              </div>
              <div class="col-md-8">
                {{ attMap['TotalSalesPrice'] == undefined ? '' : '$' + (attMap['TotalSalesPrice'][0] | amount) }}
              </div>
            </div>

            <!-- <div class="a-form-error error-fields" *ngIf="TotalSalesPrice !== '' && TotalSalesPrice != TotalASP && TotalSalesPrice != TotalPPR && TotalSalesPrice !== TotalRASP">
              Total Sales Price must equal the TotalSales Price Allocation from either Part II Line 4 or Part III Line 8, as applicable
            </div> -->
          </li>
        </ol>
      </div>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td>
            <strong>&nbsp;&nbsp;Part II</strong> &nbsp;&nbsp;
            <strong>Original Statement of Assets Transferred</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper">
      <ol class="section-content pb-0" start="4">
        <li>
          <div style="vertical-align: text-top;">
            <table class="table-layout w-100">
              <thead>
              <tr>
                <td class="col-md-3 pt-0">
                  Assets
                </td>
                <td class="col-md-3 pt-0 pr-5">
                  Aggregate fair market value<br />
                  (actual amount for Class I)
                </td>
                <td class="col-md-3 pt-0 pl-5">
                  Allocation of sales price
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class I
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class1FMV'] == undefined || attMap['Class1FMV'][0] == '' ? '' : '$' + (attMap['Class1FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class1ASP'] == undefined || attMap['Class1ASP'][0] == '' ? '' : '$' + (attMap['Class1ASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class II
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class2FMV'] == undefined || attMap['Class2FMV'][0] == '' ? '' : '$' + (attMap['Class2FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class2ASP'] == undefined || attMap['Class2ASP'][0] == '' ? '' : '$' + (attMap['Class2ASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class III
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class3FMV'] == undefined || attMap['Class3FMV'][0] == '' ? '' : '$' + (attMap['Class3FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class3ASP'] == undefined || attMap['Class3ASP'][0] == '' ? '' : '$' + (attMap['Class3ASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class IV
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class4FMV'] == undefined || attMap['Class4FMV'][0] == '' ? '' : '$' + (attMap['Class4FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class4ASP'] == undefined || attMap['Class4ASP'][0] == '' ? '' : '$' + (attMap['Class4ASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class V
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class5FMV'] == undefined || attMap['Class5FMV'][0] == '' ? '' : '$' + (attMap['Class5FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class5ASP'] == undefined || attMap['Class5ASP'][0] == '' ? '' : '$' + (attMap['Class5ASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class VI and VII
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="">
                    <span>{{ attMap['Class6N7FMV'] == undefined || attMap['Class6N7FMV'][0] == '' ? '' : '$' + (attMap['Class6N7FMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="">
                    <span>{{ attMap['Class6N7ASP'] == undefined || attMap['Class6N7ASP'][0] == '' ? '' : '$' + (attMap['Class6N7ASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Total
                </td>
                <td class="col-md-3 text-align-end pr-5">
                  <div class="" style="height: 1rem; border-top: 1px solid;">
                    <span>{{ attMap['TotalFMV'] == undefined || attMap['TotalFMV'][0] == '' ? ' ' : '$' + (attMap['TotalFMV'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end pl-5">
                  <div class="" style="height: 1rem; border-top: 1px solid;">
                    <span>{{ attMap['TotalASP'] == undefined || attMap['TotalASP'][0] == '' ? ' ' : '$' + (attMap['TotalASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <br>
        <li>
          <div class="d-flex">
            <div class="col-md-11">
              Did the purchaser and seller provide for an allocation of the sales price in the sales contract or in
              another written document signed by both parties?

            </div>
            <div class="col-md-1">
              {{ attMap['Choice204a'] == undefined ? '' : attMap['Choice204a'][0] }}

            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="col-md-11">
              If "Yes," are the aggregate fair market values (FMV) listed for each of asset Classes I, II, III, IV, V,
              VI, and VII the amounts agreed upon in your sales contract or in a separate written document?

            </div>
            <div class="col-md-1">
              {{ attMap['Choice204b'] == undefined ? '' : attMap['Choice204b'][0] }}

            </div>
          </div>
        </li>
        <br />
        <li>
          <div class="d-flex">
            <div class="col-md-11">
              In the purchase of the group of assets (or stock), did the purchaser also purchase a license or a
              covenant not to compete, or enter into a lease agreement, employment contract, management contract, or
              similar arrangement with the seller (or managers, directors, owners, or employees of the seller)?

            </div>
            <div class="col-md-1">
              {{ attMap['Choice204c'] == undefined ? '' : attMap['Choice204c'][0] }}

            </div>
          </div>
          <div class="section-content"
            [hidden]="!(attMap['Choice204c'] == undefined ? '' : attMap['Choice204c'][0] === 'Yes' ? true : false)">
            <!-- <ap-form-field [inlineBlock]="true"
              [label]="'If Yes, attach a schedule that specifies (a) the type of agreement and (b) the maximum amount of consideration (not including interest) paid or to be paid under the agreement. See instructions.'"
              [id]="'1'" [type]="'textarea'" class="text-box" [placeholder]="''" [isDisabled]="true"
              [value]="attMap['ConsiderationCmptStatement'] == undefined ? '' : attMap['ConsiderationCmptStatement'][0]">
            </ap-form-field> -->
            <div class="mt-2 mb-2"><span>{{ attMap['ConsiderationCmptStatement'] == undefined ? '' : attMap['ConsiderationCmptStatement'][0] }}</span></div>
          </div>
        </li>
      </ol>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td>
            <strong>&nbsp;&nbsp;Part III</strong> &nbsp;&nbsp;
            <strong>Supplemental Statement</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper">
      <div class="row">
        <div class="col-md-11">
          Did you amend an original statement or previously filed supplemental statement because of an increase or
          decrease in consideration?
        </div>
        <div class="col-md-1">
          <span>{{ attMap['Choice204d'] == undefined ? 'No' : attMap['Choice204d'][0] === true || attMap['Choice204d'][0] === 'Yes' ? 'Yes' : 'No' }}</span>
        </div>
      </div>
      <ol class="section-content" start="7"
        [hidden]="attMap['Choice204d'] == undefined ? true :  attMap['Choice204d'][0] === 'false' || attMap['Choice204d'][0] === 'No'">
        <li>
          <div class="d-flex">
            <div class="col-md-8">
              Tax year and tax return form number with the original Form 8594 and any supplemental statements were
              filed.
            </div>
            <div class="col-md-2">
              <span>Year: </span>
              <span>{{ attMap['taxyear1'] == undefined ? '' : attMap['taxyear1'][0] }}</span>
            </div>
            <div class="col-md-2 pl-0">
              <span>Form: </span>
              <span>{{ attMap['form1'] == undefined ? '' : attMap['form1'][0] }}</span>
            </div>
          </div>
        </li>
        <br>
        <li>
          <div class="d-inline-block supp-table" style="vertical-align: text-top;">
            <table class="table-layout">
            <thead>
              <tr>
                <td class="col-md-3 pt-0">
                  Assets
                </td>
                <td class="col-md-3 pt-0">
                  Allocation of sales price as previously reported
                </td>
                <td class="col-md-3 pt-0">
                  Increase or (decrease)
                </td>
                <td class="col-md-3 pt-0">
                  Redetermination of allocation of sales price
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class I
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class1PPR'] == undefined || attMap['Class1PPR'][0] == '' ? '' : '$' + (attMap['Class1PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class1IncDec'] == undefined || attMap['Class1IncDec'][0] == '' ? '' : '$' + (attMap['Class1IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class1RASP'] == undefined || attMap['Class1RASP'][0] == '' ? '' : '$' + (attMap['Class1RASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class II
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class2PPR'] == undefined || attMap['Class2PPR'][0] == '' ? '' : '$' + (attMap['Class2PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class2IncDec'] == undefined || attMap['Class2IncDec'][0] == '' ? '' : '$' + (attMap['Class2IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class2RASP'] == undefined || attMap['Class2RASP'][0] == '' ? '' : '$' + (attMap['Class2RASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class III
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class3PPR'] == undefined || attMap['Class3PPR'][0] == '' ? '' : '$' + (attMap['Class3PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class3IncDec'] == undefined || attMap['Class3IncDec'][0] == '' ? '' : '$' + (attMap['Class3IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class3RASP'] == undefined || attMap['Class3RASP'][0] == '' ? '' : '$' + (attMap['Class3RASP'][0] | amount) }}</span>
                  </div>

                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class IV
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class4PPR'] == undefined || attMap['Class4PPR'][0] == '' ? '' : '$' + (attMap['Class4PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class4IncDec'] == undefined || attMap['Class4IncDec'][0] == '' ? '' : '$' + (attMap['Class4IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class4RASP'] == undefined || attMap['Class4RASP'][0] == '' ? '' : '$' + (attMap['Class4RASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class V
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class5PPR'] == undefined || attMap['Class5PPR'][0] == '' ? '' : '$' + (attMap['Class5PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class5IncDec'] == undefined || attMap['Class5IncDec'][0] == '' ? '' : '$' + (attMap['Class5IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class5RASP'] == undefined || attMap['Class5RASP'][0] == '' ? '' : '$' + (attMap['Class5RASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Class VI and VII
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class6N7PPR'] == undefined || attMap['Class6N7PPR'][0] == '' ? '' : '$' + (attMap['Class6N7PPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class6N7IncDec'] == undefined || attMap['Class6N7IncDec'][0] == '' ? '' : '$' + (attMap['Class6N7IncDec'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="">
                    <span>{{ attMap['Class6N7RASP'] == undefined || attMap['Class6N7RASP'][0] == '' ? '' : '$' + (attMap['Class6N7RASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="col-md-3 align-text-left">
                  Total
                </td>
                <td class="col-md-3 text-align-end">
                  <div class="" style="height: 1rem; border-top: 1px solid;">
                    <span>{{ attMap['TotalPPR'] == undefined || attMap['TotalPPR'][0] == '' ? ' ' : '$' + (attMap['TotalPPR'][0] | amount) }}</span>
                  </div>
                </td>
                <td class="col-md-3 text-align-end">

                </td>
                <td class="col-md-3 text-align-end">
                  <div class="" style="height: 1rem; border-top: 1px solid;">
                    <span>{{ attMap['TotalRASP'] == undefined || attMap['TotalRASP'][0] == '' ? ' ' : '$' + (attMap['TotalRASP'][0] | amount) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </li>
      </ol>
      <ol start="9" class="section-content">
        <li>
          <span>Reason(s) for increase or decrease.</span>
          <!-- <ap-form-field [inlineBlock]="true" [isDisabled]="true"
            [value]="attMap['ReasonIncDec'] == undefined ? '' :  attMap['ReasonIncDec'][0]"
            class="d-flex text-box" [id]="'22'" [type]="'textarea'">
          </ap-form-field> -->
          <div class="mt-2 mb-2"><span>{{ attMap['ReasonIncDec'] == undefined ? '' :  attMap['ReasonIncDec'][0] }}</span></div>
        </li>
      </ol>
    </div>
    <table style="width:100%">
      <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs: <span class="template-color-blue"> {{stmtData.primaryleid == null? '': stmtData.primaryleid}} </span>
            / <span class="template-color-blue"> {{stmtData.secondaryleid == null? '': stmtData.secondaryleid}} </span>
          </td>
        </tr>
        <tr>
          <td class="footer-left" *ngIf="stmtData.branchleid">
            Branch LEID: <span class="template-color-blue"> {{stmtData.branchleid == null? '': stmtData.branchleid}}
            </span>
          </td>
          <td class="footer-left" *ngIf="stmtData.branchleid">
            Branch Entity Name: <span class="template-color-blue"> {{stmtData.branchentity == null? '': stmtData.branchentity}}
            </span>
            
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
        <tr style="height: 60px;">
          <td class="footer-left"><br></td>
        </tr>
        
      </tbody>
    </table>
  </div>
</div>
