import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkService {
  
  URLs = {
          getExternalApps:"/getExternalApps"
        }
  private options:any;
  private clientKey = sessionStorage.getItem('client_key') || '';

  constructor(private http: HttpClient) { 
    let headers = new HttpHeaders({
      'client_key': this.clientKey
    });
    this.options= {withCredentials:true}
  }

  getExternalApplications(baseUrl:string) {
    return this.http.get(`${baseUrl}${this.URLs.getExternalApps}`)
          .pipe(map((res:any) => res.data));
  }

}
