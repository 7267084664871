<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [disableS3Upload]="false" (onS3UploadClick)="s3Upload()" 
  [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar" [statementId]="stmtData?.statementid"></dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp368form188">
  <div>
    <div align=center>
      <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
      </b>
    </div>
    <div align="center">
      <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br/>
        STATEMENT PURSUANT TO SECTION 1.368-3 <b>{{attMap['statementOption'] == undefined ? '' : attMap['statementOption'][0]}}</b>&nbsp;
        <br/>
        BY A CORPORATION A PARTY TO A REORGANIZATION AND BY A SIGNIFICANT SHAREHOLDER
      </span>
      </B>
    </div>
    <br/>
    Pursuant to Section 1.368-3 of the Regulations, the following information is furnished:
    <!-- The names and employer identification numbers (if any) of all such parties: -->
    <div [formGroup]="form188">
      <!-- <dst-template-header [parentFormGroup]="form184" (formOptionChanged)="onFormOptionChanged($event)"></dst-template-header>-->
      <ol style="margin: 15px;">
        <li>
          The following:<br>
          <b>Target Corporation </b>
          <span class="template-color-blue" *ngIf="loaded">{{primaryEntityName}}</span>, EIN:
          <span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryein == null ? 'N/A' : stmtData.primaryein}}</span>, a
          <span class="template-color-blue" *ngIf="loaded">
            <span *ngIf="prEntityNull">Target </span>
              {{stmtData.primarycountryincdesc == null ? '' : stmtData.primarycountryincdesc}}
          </span><span class="template-color-blue" *ngIf="loaded">{{primaryCorpTypeTxt}}</span>,<br>
          <b>Acquirer </b>
          <span class="template-color-blue" *ngIf="loaded">{{secondaryEntityName}}</span>, EIN:
          <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryein == null ? 'N/A' : stmtData.secondaryein}}</span>, a
          <span class="template-color-blue" *ngIf="loaded">
            <span *ngIf="secEntityNull">Acquirer </span>
              {{stmtData.secondarycountryincdesc == null ? '' : stmtData.secondarycountryincdesc}}
          </span><span class="template-color-blue" *ngIf="loaded">{{secondaryCorpTypeTxt}}</span>, <br>
          <div *ngIf="shdspLeid">
            <b>Significant Holder </b>
            <span class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].ENTITY}}</span> EIN: <span
            class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].EIN}}</span>, a
            <span class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].COUNTRYNAME}}</span><span class="template-color-blue" *ngIf="loaded">{{shCorpTypeTxt}}</span>,
            <br>
          </div>
          <span class="template-color-blue" *ngIf="loaded && attMap['Para1'] !== undefined && attMap['Para1'][0]">
            {{attMap['Para1'][0]}}<br/>
          </span>
          are parties in a transaction that qualified as a reorganization
          under Code Section <span>{{pickListVal}}</span>
        </li>
        <li>
          The date of the reorganization was<br/>
          <span *ngIf="loaded">
          {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][0]}}
        </span>
        </li>
        <li>
          <!--<u>Disclosure required for Target/Corporation:</u>-->
          The value and basis of the assets, stock or securities of the target corporation transferred in the
          transaction, determined immediately before the transfer and aggregated as follows:
          <ol class="inner-list">
            <li>
              <div class="list-content">
                Importation property transferred in a loss importation transaction, as defined in Section 1.362-3(c)(2) and (3), respectively:<br/>
                <span class="template-color-blue" *ngIf="loaded">
                  {{attMap['importationPropertyDesc'] == undefined ? '' : (attMap['importationPropertyDesc'][0] ? attMap['importationPropertyDesc'][0] : 'None.')}}
                </span>
              </div>
            </li>
            <li>
              <div class="list-content">
                Loss duplication property as defined in Section 1.362-4(g)(1):<br/>
                <span class="template-color-blue" *ngIf="loaded">
                  {{attMap['lossDuplicationPropertyDesc'] == undefined ? '' : (attMap['lossDuplicationPropertyDesc'][0] ? attMap['lossDuplicationPropertyDesc'][0] : 'None.')}}
                </span>
              </div>
            </li>
            <li>
              <div class="list-content">
                Property with respect to which any gain or loss was recognized on the transfer (without regard to whether such property is also identified in
                paragraph (a)(3)(i) or (ii) of this section)<br/>
                <span class="template-color-blue" *ngIf="loaded">
                  {{attMap['gainLossPropertyDesc'] == undefined ? '' : (attMap['gainLossPropertyDesc'][0] ? attMap['gainLossPropertyDesc'][0] : 'None.')}}
                </span>
              </div>
            </li>
            <li>
              <div class="list-content">
                Property not described in paragraph (a)(3)(i), (ii), or (iii) of this section;
                <table class="table-view-layout">
                  <tbody *ngIf="loaded">
                  <tr>
                    <td colspan="3"></td>
                    <td><span>{{selBasisTxt}} Basis to the Transferor Corporation</span></td>
                    <td><span>Fair Market Value on Date of Transfer</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td class="header-column con-left"><span><b>Description</b></span></td>
                    <td>
                      <span><b><u>{{getSelCurrency(0)}}</u></b></span>
                    </td>
                    <td>
                      <span><b><u>{{getSelCurrency(1)}}</u></b></span>
                    </td>
                  </tr>
                  <ng-container ng-if="attMap['Desc'].length >= 1">
                    <tr *ngFor="let val of attMap['Desc'];let i=index">
                      <td colspan="2" style="width: 6%;"></td>
                      <td class="con-left" style="width: 50%;"><span>{{val}} </span></td>
                      <td style="text-align: right; width: 22%;">
                        <span>{{attMap['BasisAmt'] == undefined ? '' : attMap['BasisAmt'][i] | number}}</span>
                      </td>
                      <td style="text-align: right; width: 22%;">
                        <span>{{attMap['FMVAmt'] == undefined ? '' : attMap['FMVAmt'][i] | number}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td></td>
                      <td align="center">
                        <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                      </td>
                      <td align="center">
                        <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td class="con-left"><span>Total</span></td>
                      <td style="text-align: right">
                        <span>{{totalAmt('BasisAmt') | number}}</span>
                      </td>
                      <td style="text-align: right">
                        <span>{{totalAmt('FMVAmt') | number}}</span>
                      </td>
                    </tr>
                  </ng-container>
  
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
          <ng-container *ngIf="shdspLeid">
            <p><u>Disclosure required for Significant Holder</u> (if applicable):</p>
            <p>The value and basis of all the stock or securities of the target corporation held by the significant holder that is transferred in the
              transaction and such holder's basis in that stock or securities, determined immediately before the transfer and aggregated as follows:</p>
            <ol class="inner-list">
              <li>
                Stocks and securities with respect to which an election is made under Section 362(e)(2)(C):
                <span class="template-color-blue" *ngIf="loaded">
              {{attMap['stocksSecurityPropertyDesc'] == undefined ? '' : attMap['stocksSecurityPropertyDesc'][0]}}
            </span>
              </li>
              <li>
                Stocks and securities not described in Section 1.368-3(b)(3)(i):
                <table class="table-view-layout">
                  <tbody *ngIf="loaded">
                  <tr>
                    <td colspan="3"></td>
                    <td><span>{{selBasisTxtOthr}} Basis to the Significant Holder</span></td>
                    <td><span>Fair Market Value on Date of Transfer</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td class="header-column con-left"><span><b>Description</b></span></td>
                    <td>
                      <span><b>{{getSelCurrency(2)}}</b></span>
                    </td>
                    <td>
                      <span><b>{{getSelCurrency(3)}}</b></span>
                    </td>
                  </tr>
                  <ng-container *ngIf="attMap['Desc1'].length >= 1">

                    <tr *ngFor="let val of attMap['Desc1'];let i=index">
                      <td colspan="2" style="width: 6%;"></td>
                      <td class="con-left" style="width: 50%;"><span>{{val}} </span></td>
                      <td style="text-align: right; width: 22%;">
                        <span>{{attMap['BasisAmt1'] == undefined ? '' : attMap['BasisAmt1'][i] | number}}</span>
                      </td>
                      <td style="text-align: right; width: 22%;">
                        <span>{{attMap['FMVAmt1'] == undefined ? '' : attMap['FMVAmt1'][i] | number}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td></td>
                      <td align="center">
                        <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                      </td>
                      <td align="center">
                        <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td class="con-left"><span>Total</span></td>
                      <td style="text-align: right">
                        <span>{{totalAmt('BasisAmt1') | number}}</span>
                      </td>
                      <td style="text-align: right">
                        <span>{{totalAmt('FMVAmt1') | number}}</span>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </li>
            </ol>
          </ng-container>
        </li>
        <li>
          The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in connection with this reorganization:
          <span *ngIf="loaded">
          {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][1]}}  {{attMap['PLR'] == undefined ? 'NONE' : attMap['PLR'][0]}}
        </span>
        </li>
      </ol>
      <div>
        <div>Supplemental Information:</div>
        <div *ngIf="loaded">
          <div class="a-textarea-box a-field-length-validation form-inlines">
            <textarea rows="5" cols="60" readonly class="txtborder w-100" *ngIf="loaded">
              {{attMap['section4text'] == undefined ? '' : attMap['section4text'][0]}}
            </textarea>
          </div>
        </div>
      </div>
      <table style="width:100%">
        <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs: <span *ngIf="prEntityNull">Target </span> <span
            class="template-color-blue">{{stmtData.primaryleid == null ? '' : stmtData.primaryleid}}</span> / <span *ngIf="secEntityNull">Acquirer </span> <span
            class="template-color-blue">{{stmtData.secondaryleid == null ? '' : stmtData.secondaryleid}}</span> / <span
            class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].LEID}}</span>
          </td>
          <td class="footer-right">

          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
