import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { SourcingProjectTaggingService } from './sourcing-project-tagging.service';
import lodfilter from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from "rxjs";

@Component({
	selector: 'gtw-sourcing-project-tagging',
	templateUrl: './sourcing-project-tagging.component.html',
	styleUrls: ['./sourcing-project-tagging.component.scss']
})
export class SourcingProjectTaggingComponent implements OnInit {


	baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string; } | undefined;
	baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '/gtw', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
	private baseURL = '/custom';
	private baseURLGtw = '/gtw';
	private checkLockedObj: any = [];
	private taggingParamObj: any = [];
	private taxYear: number = 0;
	private scenario: number = 0;
	private jcdKey: number = 0;
	private ssoId: number = 0;
	basket_id_value: any;
	ProjectTagAmt: any;
	rows: any = [];
	tableData = false;
	isModalLoading = false;
	autoRunDataLoad = false;
	tableLoadingData = false;
	FirstDiv = true;
	TwoDiv = false;
	basket_object: any = {};
	amt_exceed_obj: any = {};


	@Input('base-urls')
	set _base_urls(baseUrls: string) {
		try {
			let urls: GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if (Object.keys(urls).length > 0) {
				this.baseURLs = urls;
				//this.init();
			}
		} catch (e) {
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string) {
		try {
			let params = JSON.parse(globalParams);
			if (params != undefined) {
				this.baseGlobalParams = params;
			}
			//this.init();
		} catch (e) {
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}


	@Input('check-locked-obj')
	set _checkLockedObj(checkLockedObj: any) {
		this.checkLockedObj = checkLockedObj;
	}

	@Input('tax-year')
	set _taxYear(taxYear: number) {
		try {
			this.taxYear = taxYear;
		} catch (error) {
			console.log("Not an valid object");
		}
	}


	@Input('scenario')
	set _scenario(scenario: number) {
		this.scenario = scenario;
		this.init();
	}

	@Input('jcd-key')
	set _jcdKey(jcdKey: number) {
		this.jcdKey = jcdKey;
		this.init();
	}

	@Input('sso-id')
	set _ssoId(ssoId: number) {
		this.ssoId = ssoId;
		this.init();
	}




	@Input('tagging-param-obj')
	set _taggingParamObj(taggingParamObj: any) {
		try {
			let params = JSON.parse(taggingParamObj);
			if (params != undefined) {
				this.taggingParamObj = params;
				this.init();
			}
			//this.init();
		} catch (e) {
			console.log("Tagging Params");
		}
	}


	@Output('open-sourcing-project-tagging-info')
	openSourcingTagInfo = new EventEmitter<string>();

	@Output('save-sourcing-project-tagging-info')
	saveSourcingTagInfo = new EventEmitter<string>();

	subscriptions: Subscription = new Subscription();




	constructor(private SourcingProjectTaggingService: SourcingProjectTaggingService, private _snackBar: MatSnackBar) { }

	SavedData: any = [];
	basketIdList: any = [{ data: [] }];
	projectIdList: any = [{ data: [] }];
	selectedBasketid: any = [];
	selectedProjectid: any = [];
	durationInSeconds = 5;
	CheckBasketFlag = false;
	tempGroupData: any = {};
	isResetBasketFlag = false;
	isResetProjectFlag = false;
	invalid_flag = true;
	isViewOnly: boolean = false;
	total_amt_exceed = false;



	checkInputType(): boolean {
		if (Number.isInteger(parseInt(this.taxYear + "")) &&
			Number.isInteger(parseInt(this.scenario + '')) &&
			Number.isInteger(parseInt(this.jcdKey + '')) &&
			Number.isInteger(parseInt(this.ssoId + '')) &&
			this.taggingParamObj &&
			this.checkLockedObj
		) {
			return true;
		}

		return false;
	}


	ngOnInit(): void {
	}

	init() {
		if (this.checkInputType()) {
			this.getBaksetIdData();
		}
	}


	modalClose() {
		console.log("click close");
		this.openSourcingTagInfo.emit('open-sourcing-project-tagging-info');
	}


	setBasketId(group: any, row: any): void {
		row['SELECTEDBASKETID'] = group.selected[0].value;
	}

	setProjectId(group: any, row: any): void {
		row['SELECTEDPROJECTID'] = group.selected[0].value;
	}


	getBaksetIdData() {
		if(!this.isModalLoading) {
			this.isModalLoading = true;
		this.basketIdList = [{ data: [] }];
		this.SourcingProjectTaggingService.getBaksetIdData(this.baseURLs.api, this.taxYear, this.scenario, this.jcdKey, this.taggingParamObj).subscribe((data: any) => {
			if (data) {
				let tempIssueId: any = [];
				data.forEach((value: any, i: any) => {
					let tempChannel = {
						value: value.BASKET_ID,
						index: i,
						text: value.BASKET_NAME,
						selected: false,						
						total_amt: value.BASKET_AMT
					}
					tempIssueId.push(tempChannel);
				});
				this.basketIdList[0].data = tempIssueId;
				console.log(this.basketIdList);
			}
		}, error => {
			console.log(error);
		});

		this.projectIdList = [{ data: [] }];
		this.SourcingProjectTaggingService.getProjectIdData(this.baseURLs.api, this.taxYear, this.scenario).subscribe((data: any) => {
			if (data) {
				let tempProjectId: any = [];
				data.forEach((value: any, i: any) => {
					let tempChannel = {
						value: value.PROJECT_ID,
						index: i,
						text: value.PROJECT_NAME,
						selected: false
					}
					tempProjectId.push(tempChannel);
				});
				this.projectIdList[0].data = tempProjectId;
				this.getModalSaveData();
				console.log(this.projectIdList);
			}
		}, error => {
			this.isModalLoading = false;
			console.log(error);
		});

	}
}

	// getProjectIdData() {
	// 	this.projectIdList = [{ data: [] }];
	// 	this.SourcingProjectTaggingService.getProjectIdData(this.baseURLs.api, this.taxYear, this.scenario).subscribe((data: any) => {
	// 		if (data) {
	// 			let tempProjectId: any = [];
	// 			data.forEach((value: any, i: any) => {
	// 				let tempChannel = {
	// 					value: value.PROJECT_ID,
	// 					index: i,
	// 					text: value.PROJECT_NAME,
	// 					selected: false
	// 				}
	// 				tempProjectId.push(tempChannel);
	// 			});
	// 			this.projectIdList[0].data = tempProjectId;
	// 			this.getModalSaveData();
	// 			console.log(this.projectIdList);
	// 		}
	// 	}, error => {
	// 		console.log(error);
	// 	});


	// }



	getModalSaveData() {
		if (this.SavedData.length == 0 && !this.autoRunDataLoad) {
			this.autoRunDataLoad = true;
			this.SourcingProjectTaggingService.getSourceTaggingModalData(this.baseURLs.api, this.taxYear, this.scenario, this.jcdKey, this.taggingParamObj).subscribe((data: any) => {
				if (data.length > 0) {
					this.autoRunDataLoad = false;
					this.tableLoadingData = true;
					this.tableData = true;
					console.log(data);
					this.SavedData = data;
				} else {
					this.tableLoadingData = true;
					this.tableData = false;
					this.SavedData = [];
				}
			},
				error => {
					this.isModalLoading = false;
					this.autoRunDataLoad = false;
					console.log(error);
				}, () => {
					this.isModalLoading = false;
					this.autoRunDataLoad = false;
					console.log('Completed loading');
				});
		}
	}



	addRow() {
		this.TwoDiv = true;
		this.rows.push({
			index: this.rows.length + 1,
			SELECTEDBASKETID: '',
			SELECTEDPROJECTID: '',
			PROJECT_AMT: '',
			basketList: _.cloneDeep(this.basketIdList),
			projectList: _.cloneDeep(this.projectIdList),
			HO_COMBINATION_KEY: this.taggingParamObj.HO_COMBINATION_KEY,
			COMBINATION_KEY: this.taggingParamObj.COMBINATION_KEY,
			TP_KEY: this.taggingParamObj.TP_KEY,
			LOCAL_ACCT_KEY: this.taggingParamObj.LOCAL_ACCT_KEY,
			CHART_KEY: this.taggingParamObj.CHART_KEY,
		});

	}
	onClick() {
		this.FirstDiv = !this.FirstDiv;
	}

	deleteRow(index: any) {
		this.rows.splice(index, 1);
		if (this.rows.length > 0) {
			this.TwoDiv = true;
		}
		else {
			this.TwoDiv = false;
		}
	}

	saveData() {
		console.log(this.rows);

		let saveObject = _.cloneDeep(this.rows)
		if (saveObject.length > 0) {
			for (let i = 0; i < saveObject.length; i++) {
				delete saveObject[i].basketList;
				delete saveObject[i].projectList;
				delete saveObject[i].index;
			}
		}
		if (this.SavedData.length > 0) {
			this.SavedData.forEach((dataValue: any, i: any) => {
				saveObject.push(dataValue)
			});
		}

		for (let i = 0; i < saveObject.length; i++) {
			this.invalid_flag = false;
			if ( (saveObject[i].SELECTEDBASKETID === '' || saveObject[i].SELECTEDBASKETID === null || saveObject[i].SELECTEDBASKETID === undefined)
					|| (saveObject[i].SELECTEDPROJECTID === '' || saveObject[i].SELECTEDPROJECTID === null || saveObject[i].SELECTEDPROJECTID === undefined)
					|| (saveObject[i].PROJECT_AMT === '' || saveObject[i].PROJECT_AMT === null || saveObject[i].PROJECT_AMT === undefined)  
			){
				this.invalid_flag = true;
				break;
			}
			else {
				this.invalid_flag = false;
				
			}

		}
		if (this.invalid_flag) {
			this.SourcingProjectTaggingService.openSnackBar('Basket / Project Tag / Amount are required field', 'gtw-snackbar--red');
			return;
		}

		/* let basket_validation = _.mapValues(_.groupBy(saveObject, 'SELECTEDBASKETID'),clist => clist.map(car => _.omit(car, 'SELECTEDBASKETID')));
		let total_value : number;		
		this.basketIdList[0].data.forEach((basket: any, i: any) => {
			total_value = 0;
			if (basket_validation[basket.value]!== undefined)
			{
				basket_validation[basket.value].forEach((basket_group: any, i: any)=> {	
					total_value += basket_group.PROJECT_AMT;
					
				});
				this.basket_object[basket.value] = total_value 

			}	
				
			
			//this.basket_object[basket.value] = total_value 
		});
		
		console.log(this.basket_object);

		for (let i = 0; i < this.basketIdList[0].data.length; i++) {
			this.total_amt_exceed = false;
			if (this.basket_object[this.basketIdList[0].data[i].value] > this.basketIdList[0].data[i].total_amt){
				this.total_amt_exceed = true;
				this.amt_exceed_obj = this.basketIdList[0].data[i];
				break;
			}else {
				this.total_amt_exceed = false;
				
			}

		}

		if (this.total_amt_exceed) {
			this.SourcingProjectTaggingService.openSnackBar('Project Tag Amount for ' + ''+ '('+ this.amt_exceed_obj.text + '' +')'+ ' basket cannot be greater than  ' +  '' + '' + this.amt_exceed_obj.total_amt, 'gtw-snackbar--red');
			return;
		}
 */
		console.log(saveObject);
		let updateSubscription = this.SourcingProjectTaggingService.saveSourcingTaggingData(this.baseURLs.api, saveObject, this.taxYear, this.scenario, this.jcdKey, this.ssoId, this.baseGlobalParams, this.taggingParamObj, this.checkLockedObj).subscribe(
			(data) => {
				if (data.callSuccess == "1") {
					this.SourcingProjectTaggingService.openSnackBar('Data Saved Successfully', 'gtw-snackbar--green');
					this.saveSourcingTagInfo.emit('save-sourcing-project-tagging-info');
				} else {
					this.SourcingProjectTaggingService.openSnackBar('Error Saving Data. ' + data.errorMessage, 'gtw-snackbar--red');
				}
			}, error => { this.SourcingProjectTaggingService.openSnackBar('Save failed. ' + error.error.errorMessage, 'gtw-snackbar--red'); },
			() => { console.log("Update Completed"); }
		);
		this.subscriptions.add(updateSubscription);

	}

}
