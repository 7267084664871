import { Injectable } from '@angular/core';
import { Submission } from '../models/submission.model';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {

  submissionTabs: Submission[] = [];
  activeSubmissionTabIndex: number = 0;
  activeSubmission!: Submission;

  constructor() { }


  activateSubmission(item: Submission) : number{
    let tabIndex = this.submissionTabs.findIndex(
      (sub:Submission)=>sub.SUBMISSION_ID === item.SUBMISSION_ID
        );

    if(tabIndex === -1){
      this.submissionTabs.push(item);
      this.activeSubmissionTabIndex = this.submissionTabs.length;
    }else{
      this.activeSubmissionTabIndex = tabIndex + 1;//+1 to exclude submissions tab
    }

    return this.activeSubmissionTabIndex;
  }
  activateSubmissionByIndex(index: number) : void{
    if(index === 0) return;
    this.activeSubmissionTabIndex = index;
    this.activeSubmission = this.submissionTabs[index - 1];
  }
}
