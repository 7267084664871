import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GtwElementsBaseUrlsModel } from '../../_models/gtw-elements-base-urls.model';
import { DSTSharedService } from '../../../DST/shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class FormReivewPdfService {

  constructor(private httpClient: HttpClient,
    private dstSharedService: DSTSharedService) {
  }

  getPDFData(taxYear: number, submissionId: number, keyId: number, irsFormNo: string, formSub: string
    , s3_url: string, jcdKey: string, scenario: string, formSubCat: string, leid: string, parentLeId: string
    , cdrNo: string, rptPeriod: string, systemFlag: string, wpIrsFormNo: string, schedule: string, line: string
    , partNo: string, leName: string, leType: string, ein: string, jobId: string, srcType: string): Observable<any> {
    
      const params: { [param: string]: string } = {
        'taxYear':  taxYear.toString(),
        'submissionId': submissionId.toString(),
        'keyId': keyId.toString(),
        'irsFormNo': irsFormNo,
        'formSub': formSub,
        's3Url': s3_url,
        'formSubCat': formSubCat || '',
        'leid': leid || '',
        'parentLeId': parentLeId || '',
        'cdrNo': cdrNo || '',
        'rptPeriod': rptPeriod || '',
        'systemFlag': systemFlag || '',
        'wpIrsFormNo': wpIrsFormNo || '',
        'schedule': schedule || '',
        'line': line || '',
        'partNo': partNo || '',
        'leName': leName || '',
        'leType': leType || '',
        'ein': ein || '',
        'jcdKey': jcdKey || '',
        'scenario': scenario || '',
        'jobId': jobId || '',
        'srcType': srcType || '',
      };
    const url = `${this.dstSharedService.baseURLs.pdfEngine}/fillForm`;

    return this.httpClient.get(url,
      { 
        responseType: 'arraybuffer',
        withCredentials: true,
        params: params,
      }).pipe(map((data: any) => {
        return data;
      }));
  }

  doesTaxFormExist(taxYear: number, formName: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const params = new HttpParams()
      .set('tax_year', taxYear.toString())
      .set('irs_form_no', formName);
    const url = `${this.dstSharedService.baseURLs.pdfEngine}/doesTaxFormExist`;

    return this.httpClient.post(url, {}, {
        withCredentials: true, params: params, headers: headers
      }).pipe(map((response: any) => {
        return response;
      }));
  }
  
  getDSTAttachmentsData(fileName: string, s3Url: string): Observable<string> {
    const headers = new HttpHeaders();
    headers.append('client_key', this.dstSharedService.clientKey.toString());
    const params = new HttpParams()
      .set('s3_url', s3Url)
      .set('actualFileName', fileName);

    return this.httpClient.get(this.dstSharedService.baseURLs.admin + '/getDSTAttachmentBytes', {
      withCredentials: true, params: params, headers: headers, responseType: 'arraybuffer'
      }).pipe(map((data: any) => {
        return data;
      }));
  }

}

export interface FormReivewPdf {
  FORMTYPE: string;
  TAX_YEAR: number;
  SUBMISSION_ID: number;
  KEY_ID: number;
  SCENARIO: string;
  JCD_KEY: string;
  IRSFORM: string;
  FORM_SUB: string;
  FORM_SUB_CAT: string;
  LEID: string;
  PARENT_LEID: string;
  CDR_NO: string;
  RPT_PERIOD: string;
  SYSTEM_FLAG: string;
  WP_IRS_FORM_NO: string;
  SCHEDULE: string;
  LINE: string;
  PART_NO: string;
  LE_NAME: string;
  LE_TYPE: string;
  EIN: string;
  JOB_ID: string;
  SRC_TYPE: string;
  ATTACHMENT_PATH: string;
  ATTACHMENT_NAME: string;
  HTML_ATTACHMENT_PATH: string;
  HTML_ATTACHMENT_NAME: string;
}
