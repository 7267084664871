import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { SourcingAmountsService } from '../../services/sourcing-amounts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from "rxjs";
import { SourcingAmountsObj, SourcingAmountsRow } from '../../models/sourcing-amounts.model';

@Component({
  selector: 'gtw-sourcing-amounts',
  templateUrl: './sourcing-amounts.component.html',
  styleUrls: ['./sourcing-amounts.component.scss']
})
export class SourcingAmountsComponent implements OnInit {

  private baseURL = '/gtw';
  private checkLockedObj: string[] = [];
  private taxYear: number = 0;
  private scenario: number = 0;
  private jcdKey: number = 0;
  private ssoId: string = '';
  private combinationKey: number = 0;
  private processName: string = '';
  private scenarioCode: string = '';
  private isIssueKeyEnabled: string = '';
  private isLocked: string = '';
  private issueKey: number = 0;
  public secondaryConfig = {
    isSecondary: true
  }
  public floatingConfig = {
    isFloating: true
  }

  clob_settings: { tax_year: number; scenario: number; jcd_key: number; sso_id: string; combination_key: number; } = {
    tax_year: 0,
    scenario: 0,
    jcd_key: 0,
    sso_id: '',
    combination_key: 0,
  };
  clob_data: SourcingAmountsRow[] = [];
  sourcingAmountsData: SourcingAmountsRow[] = [];

  public finalData: SourcingAmountsObj = {};

  public validationErrMsg: string = '';

  subscriptions: Subscription = new Subscription();
  isLoading = false;
  isSaving = false;
  firstRow: SourcingAmountsRow[] = [];

  objectKeys = Object.keys;

  @Input('base-url')
  set _baseURL(baseURL: string) {
    this.baseURL = baseURL;
  }

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj: string[]) {
    this.checkLockedObj = checkLockedObj;
  }

  @Input('is-locked')
  set _isLocked(isLocked: string) {
    this.isLocked = isLocked;
    if (this.isLocked === 'Y')
      this.isSaving = true;
    else
      this.isSaving = false;
  }

  @Input('tax-year')
  set _taxYear(taxYear: number) {
    this.taxYear = taxYear;
    this.init();
  }

  @Input('scenario')
  set _scenario(scenario: number) {
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey: number) {
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input("combination-key")
  set _combinationKey(combinationKey: number) {
    this.combinationKey = combinationKey;
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId: string) {
    this.ssoId = ssoId;
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
    this.init();
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
    this.init();
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
    this.init();
  }

  @Output('cancel-sourcing-amts')
  cancelSourcingAmts = new EventEmitter<string>();

  @Output('save-sourcing-amts')
  saveSourcingAmts = new EventEmitter<string>();

  constructor(private sourcingAmtsService: SourcingAmountsService, private _snackBar: MatSnackBar) { }
  checkInputType(): boolean {
    if (Number.isInteger(parseInt(this.taxYear + "")) &&
      Number.isInteger(parseInt(this.scenario + '')) &&
      Number.isInteger(parseInt(this.jcdKey + '')) &&
      Number.isInteger(parseInt(this.combinationKey + '')) &&
      Number.isInteger(parseInt(this.issueKey + '')) &&
      (!this.scenarioCode.includes("{{")) &&   // this.scenarioCode === "RAF" || this.scenarioCode === "EXAM"
      (!this.isIssueKeyEnabled.includes("{{")) // this.isIssueKeyEnabled == "Y" || this.isIssueKeyEnabled == "N"
    ) {
      return true;
    }

    return false;
  }

  init() {
    this.isLoading = true;
    if (this.checkInputType()) {
      this.getModalData();

    }
  }
  ngOnInit(): void {
  }

  groupData(): void {
    let index = 0;
    this.sourcingAmountsData.forEach((data: SourcingAmountsRow) => {
      let currentKey = data.PTR_LE;
      if (currentKey in this.finalData) {
        this.finalData[data.PTR_LE].push({ ...data, IS_DIRTY: 'N', INDEX: index });
      }
      else {
        ++index;
        this.finalData[currentKey] = [{ ...data, IS_DIRTY: 'N', INDEX: index, display: true }];

      }
    })
  }

  getModalData(): void {
    console.log('Get Sourcing Amts Popup Data Called.');
    if (this.sourcingAmountsData.length == 0) {

      this.isLoading = true;

      this.sourcingAmtsService.getSourcingPopupData(this.baseURL, this.taxYear, this.scenario, this.jcdKey, this.combinationKey, this.ssoId).subscribe((data: SourcingAmountsRow[]) => {
        if (data) {
          this.sourcingAmountsData = data;
          this.firstRow = [this.sourcingAmountsData[0]];
          this.firstRow[0].TAX_YEAR_BEGIN = this.getFormattedDate(new Date(this.firstRow[0].TAX_YEAR_BEGIN));
          this.firstRow[0].TAX_YEAR_END = this.getFormattedDate(new Date(this.firstRow[0].TAX_YEAR_END));
          this.groupData();
          console.log("final table data:  ", this.finalData)
        }
        else {
          this.sourcingAmtsService.openSnackBar('No records found!', 'gtw-snackbar--red');
        }


      }, error => {
        this.isLoading = false;
        console.log(error);
      }, () => {
        this.isLoading = false;
        console.log('Completed all tabs');
      })
    }
  }

  expandCollapse(key: string): void {
    this.finalData[key][0].display = !this.finalData[key][0].display;
  }

  getFormattedDate(date: Date): string {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  updateBasket(event: any, val: string, body: SourcingAmountsRow) {
    if (!isNaN(+val)) {
      body.BRANCH = parseInt(body.PTR_AMT_PSHIP_CURR) - parseInt(body.GENLIM) - parseInt(body.PASSIVE) - parseInt(body.TREATY) - parseInt(body['901j']);
      body.IS_DIRTY = 'Y';
    }
  }

  cancel(): void {
    this.cancelSourcingAmts.emit('cancel-sourcing-amts');
  }
  saveEntries(): void {
    this.isLoading = true;
    this.clob_data = [];
    this.clob_settings = {
      tax_year: this.taxYear,
      scenario: this.scenario,
      jcd_key: this.jcdKey,
      sso_id: this.ssoId,
      combination_key: this.combinationKey,
    };
    let isClean = true;
    Object.values(this.finalData).forEach((partner: any) => {
      partner.forEach((account: SourcingAmountsRow) => {
        if (account.IS_DIRTY == 'Y') {
          if (isNaN(+account.BRANCH) || isNaN(+account.GENLIM) || isNaN(+account.PASSIVE) || isNaN(+account.TREATY) || isNaN(+account['901j'])) {
            isClean = false;
          } else {
            this.clob_data.push({
              ...account,
              basket: [
                {
                  name: 'BRANCH', value: account.BRANCH
                },
                {
                  name: 'GENLIM', value: account.GENLIM
                },
                {
                  name: 'PASSIVE', value: account.PASSIVE
                },
                {
                  name: 'TREATY', value: account.TREATY
                },
                {
                  name: '901j', value: account['901j']
                }
              ]
            })
          }
          
        }

      })
    });
    if (this.clob_data.length > 0 && isClean) {
      this.sourcingAmtsService.saveSplAllocationData(this.baseURL, this.taxYear, this.scenario, this.jcdKey, this.ssoId, this.processName, this.checkLockedObj, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey, this.clob_settings, this.clob_data).subscribe((response: any) => {
        this.isLoading = false;
        this.isSaving = false;
        if (response.callSuccess == "1") {
          this.sourcingAmtsService.openSnackBar('Sourcing Amounts Saved', 'gtw-snackbar--green');
          this.saveSourcingAmts.emit('save-sourcing-amts');
        } else {
          this.sourcingAmtsService.openSnackBar('Error Saving Sourcing Data. ' + response.errorMessage, 'gtw-snackbar--red');
        }
      });
    }
    else {
      this.sourcingAmtsService.openSnackBar(isClean ? 'No value changed!' : 'Please enter correct number values', 'gtw-snackbar--red');
      this.isLoading = false;
      this.isSaving = false;
    }

  }

}
