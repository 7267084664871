import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, expand, map } from 'rxjs/operators';
import { ScreenVideoDialogModel } from './screen-video-dialog-model';

interface VideoElement extends HTMLVideoElement{
  requestPictureInPicture(): any;
}

@Component({
  selector: 'gtw-screen-video-dialog',
  templateUrl: './screen-video-dialog.component.html',
  styleUrls: ['./screen-video-dialog.component.scss']
})
export class ScreenVideoDialogComponent implements OnInit,AfterViewInit {

  mediaSource!:MediaSource ;
  sourceBuffer!:SourceBuffer;
  /** 
   * /stream/{fileName}/{videoId}/{screenKey}/{clientKey}
   */
  streamURL = '/stream/';

  @ViewChild('screenVideo')
  screenVideo!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ScreenVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScreenVideoDialogModel,
    private domSanitizer:DomSanitizer,
    private http:HttpClient
  ) { 
    this.streamURL += `${data.file_name}/${data.video_id}/${data.screen_key}/${data.client_key}?client_key=${data.client_key}`;
    this.streamURL = data.baseURL+ this.streamURL;
    console.log(this.streamURL);
  }
  ngAfterViewInit(): void {
    // if(this.isMediaSourceSupported()){
    //   // this.screenVideo.nativeElement.srcObject = this.mediaSource;
    //   // Avoid using this in new browsers, as it is going away.
    //   this.screenVideo.nativeElement.src = URL.createObjectURL(this.mediaSource);
    // }

    const videoElem: VideoElement = this.screenVideo.nativeElement;
    videoElem.addEventListener('enterpictureinpicture', this.hideDialog);
    videoElem.addEventListener('leavepictureinpicture', this.showDialog);

  }

  ngOnInit(): void {
  }

  closeVideoModal(){
    this.dialogRef.close();
  }

  enablePipMode(){
    console.log("pip mode");
    const video:  VideoElement = this.screenVideo.nativeElement;
    video.requestPictureInPicture();
    
  }

  private isMediaSourceSupported(mimeCodec:string='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'): boolean{
    
    if(MediaSource.isTypeSupported(mimeCodec)){
      this.mediaSource = new MediaSource();
      this.mediaSource.addEventListener('sourceopen',()=>this.prepareMediaBuffer(),false);
      return true;
    }
    
    console.error("Unsupported media support");
    return false;  
  }

  private prepareMediaBuffer(mimeCodec:string='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'){
    this.sourceBuffer = this.mediaSource.addSourceBuffer(mimeCodec);
    this.mediaSource.duration = this.data.video_duration;
    this.fetchVideoSegmentandAppend();
  }

  private fetchVideoSegmentandAppend(){
    let end = this.data.chunkSize;
    let start = 0;
    this.fetchVideoArrayBuffer(start, end).subscribe(
      (res:any) =>{
        this.sourceBuffer.appendBuffer(res);
      },
      (error:any) => {
        console.log('Error while downloading video', error);
        this.sourceBuffer.appendBuffer(error.text);
      },
      () => {
        console.log('download completed');
      }

    );
  }

  private fetchVideoArrayBuffer(start:number, end:number ):Observable<any>{
    // console.log('Range: bytes='+start+'-'+end,);
    let options = { 
                    headers:{
                      'Range':'bytes='+start+'-'+end,
                      'responseType': 'blob'                      
                    },
                  };

    return this.http.get(this.streamURL,options).pipe(
        expand( (res:any) => {
            if(res['status'] === 206){
              start = end;
              if( parseInt(this.data.file_size) - end > this.data.chunkSize  ){
                end = start + this.data.chunkSize;
              }else if( parseInt(this.data.file_size) - end < this.data.chunkSize  ){
                end = parseInt(this.data.file_size) - 1;
              }else{
                return EMPTY;
              }
              return this.fetchVideoArrayBuffer(start,end);
            } else {
              return EMPTY;
            }
        }),
        map(res => {
          if (res.status === 206) {
            console.log("res: 206");
          }
          return res;
        }),
        catchError(err => {
          console.log('Handling error locally and rethrowing it...', err);
          if(err['status'] === 206){
            start = end;
            if( parseInt(this.data.file_size) - end > this.data.chunkSize  ){
              end = start + this.data.chunkSize;
            }else if( parseInt(this.data.file_size) - end < this.data.chunkSize  ){
              end = parseInt(this.data.file_size) - 1;
            }else{
              return EMPTY;
            }
            return this.fetchVideoArrayBuffer(start,end);
          } else {
            return EMPTY;
          }
      })
    );
  }

  private hideDialog(){
    const dialog = document.querySelector('div.cdk-overlay-container') as HTMLDivElement; 
    if(dialog && (dialog.style.display == 'block' || dialog.style.display == '' )){
      dialog.style.display = 'none';
    }
  }

  private showDialog(){
    const dialog = document.querySelector('div.cdk-overlay-container') as HTMLDivElement; 
    if(dialog && dialog.style.display == 'none'){
      dialog.style.display = 'block';
    }
  }

  safeURL(){
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,'+ this.data.imageAsBase64String);
  }

}