import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FavoriteButtonService {

  private URLs = {
    updateFavoriteScreen : '/saveJsonObject?action_id=31777 ',
  };

  constructor(private httpClient: HttpClient) { }

  updateFavorite(baseURL:string,client_key:number,screen_key:string,is_fav:string){
    
    const options = {withCredentials: true};
    
    const data = new FormData();
    data.append('sso_id',"");
    data.append('screen_key',screen_key.toString());
    data.append('client_key',client_key.toString());
    data.append('is_fav',is_fav.toString());
    
    const url = `${baseURL}${this.URLs.updateFavoriteScreen}`;
    
    return this.httpClient.post(url,data,options).pipe(map((data: any) => { return data; }));

  }
}
