<mat-card [ngClass]="{'card_view': isCard}">
    <mat-card-header>
        <div class="status-labels">
            <!-- <li>Favorite: <gtw-favorite-button *ngIf="screenDetails.HASACCESS" 
                [client-key]="clientKey" [base-url]="baseUrl"
                [(screen)]="screenDetails">
                </gtw-favorite-button>
            </li> -->
            <!-- <li *ngIf="!isCard" style="border-right: 1px solid #d5d5d5;">Times viewed:</li> -->
            <!-- <li *ngIf="!isCard">Last Viewed: </li> -->
            <li class="label-icn-wrapper" [ngClass]="{'card-view-labels': isCard}">
                <span class="material-icons screen-icon" style="color: #d04904;" 
                    (click)="downloadTemplate()" *ngIf="checkImportScreen()">
                    file_download
                </span>
                <!-- style="color: #adb5bd;" -->
                <span class="material-icons screen-icon" style="color: #adb5bd;"         
                [ngClass]="{'doc-btn-bg': screenDetails.HAS_DOCUMENT != '0' || screenDetails.HAS_VIDEO != '0'}"

                (click)="openDocumentTemplate()">description</span>
                <gtw-favorite-button [screen]="screenDetails" [base-url]="baseUrl" [client-key]="clientKey"></gtw-favorite-button>
                <!-- <span class="material-icons screen-icon" style="color: #d04904;">push_pin</span> -->
            </li>
        </div>
        <mat-card-title>
            <img class= "img-class" src="assets/img/tile-view-icons/Batch-Import.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "2"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/Dashboards.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "9"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/Diagnostics.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "7"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/Form-schedule.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "3"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/Report.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "1"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/setup-mapping.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "6"'/>
            <img class= "img-class" src="assets/img/tile-view-icons/sourcing.png" *ngIf='screenDetails.SCREENS_CATEGORY_LIST == "5"'/>
            <h5 class="title-class" *ngIf='screenDetails.HASACCESS' (click)="loadClickedScreen(screenDetails)" [ngClass]="{'screen-name': isCard}">{{screenDetails.CATEGORY_NAME? screenDetails.CATEGORY_NAME[0]: ''}}</h5>
            <ng-container *ngIf='!screenDetails.HASACCESS'><h5 class="title-class">{{screenDetails.CATEGORY_NAME}}</h5></ng-container>
            
        </mat-card-title>
        <div class="title-view-body" [ngClass]="{'tile-view-body-wrapper': isCard}">
            <div>
                <div class="bread-crumbs"><span *ngFor= "let breadCrumb of screenDetails.BREADCRUMBSARR">{{breadCrumb}}</span></div>
                <!-- <div class="bread-crumbs"><span >{{screenDetails.BREADCRUMB}}</span></div> -->

            </div>
            <div style="padding-top: 5px;">
                <!-- <div *ngIf="isCard" class="activity-name" [ngClass]="{'card_view_text': isCard}">{{screenDetails.ACTIVITY_NAME}}<span>Times viewed: | Last Viewed: </span></div> -->
                <div class="activity-name" [ngClass]="{'card_view_text': isCard}" (click)="loadClickedScreen(screenDetails)">{{screenDetails.SCREEN_NAME}}</div>
                <div class='screen_description' [ngClass]="{'desc-wrapper': isCard}">
                    {{screenDetails.SCREEN_DESC}}
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content *ngIf="!screenDetails.HASACCESS">
        <!-- <p *ngIf="isCard">
            {{screenDetails.SCREEN_DESC}}
        </p> -->
        <div class='user_screen_actions'>
            <gtw-request-access-button *ngIf="!screenDetails.HASACCESS"
            [client-key]="clientKey" [base-admin-url]="baseUrlAdmin"
            [screen]="screenDetails"></gtw-request-access-button>
            <!-- <gtw-favorite-button *ngIf="screenDetails.HASACCESS && isCard" 
                [client-key]="clientKey" [base-url]="baseUrl"
                [(screen)]="screenDetails">
            </gtw-favorite-button> -->
        </div>
    </mat-card-content>
</mat-card>

<!-- <p>screen-details works!    
    <gtw-request-access-button></gtw-request-access-button>
    <gtw-favorite-button></gtw-favorite-button>
</p> -->

