import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {ModalComponent} from 'appkit-angular/modal';
import {CopyReplicateService} from '../copy-replicate/copy-replicate.service';
import {DSTFormOption, StatementTrackerService} from '../statement-tracker.service';
import {CreateDisclosureSharedService} from '../create-disclosure/shared/create-disclosure-shared.service';

@Component({
  selector: 'dst-create-options',
  templateUrl: './create-options.component.html',
  styleUrls: ['./create-options.component.scss']
})
export class CreateOptionsComponent implements OnInit {

  @Input() rowObject!: DSTStatement;
  @Input() options: DSTFormOption[] = [];

  @Output() afterModalClosed: EventEmitter<DSTFormOption>;

  @ViewChild('baseModal', {static: false}) baseModal!: ModalComponent;
  @ViewChild('modalRef') modalRef!: ElementRef;

  constructor(private cRService: CopyReplicateService,
              private trackerService: StatementTrackerService,
              private sharedService: CreateDisclosureSharedService) {
    this.afterModalClosed = new EventEmitter<DSTFormOption>();
  }

  ngOnInit(): void {
  }

  onSelect(option: DSTFormOption): void {
    if (window.confirm(option.CONFIRMMESSAGE)) {
      this.afterModalClosed.emit(option);
    }
  }

  showMore(id: string): void {
    this.baseModal.showMore(id);
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="create-options"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-lg');
    });
  }

  modalClose(): void {
    this.notifyAfterModalClosed();
  }

  modalDetermine(): void {
    this.notifyAfterModalClosed();
  }

  // closeModal(id: string): void {
  //   this.baseModal.closeModal(id);
  //   this.notifyAfterModalClosed();
  // }

  closeModalOk(id: string): void {
    this.baseModal.closeModalOk(id);
    this.notifyAfterModalClosed();
  }

  private notifyAfterModalClosed(): void {
    // this.sharedService.setFormData({});
    this.afterModalClosed.emit();
  }

}
