import {AfterViewInit, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {CreateDisclosureSharedService} from '../../create-disclosure-shared.service';
import {TemplateAttachedService} from './template-attached.service';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {
  CreateDisclosureTemplateViewportComponent
} from '../../create-disclosure-template-viewport/create-disclosure-template-viewport.component';
import {DSTFormState} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'gtw-template-attached',
  templateUrl: './template-attached.component.html',
  styleUrls: ['../template-shared.scss', './template-attached.component.scss']
})
export class TemplateAttachedComponent implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent) headerComponent!: TemplateHeaderComponent;
  @ViewChildren(TemplateLeidSelectorComponent) leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formAttached!: FormGroup;
  createdFormData: any;
  formType!: string;
  formId!: number;
  fileAcceptStr!: string;
  fileAcceptShort!: string[];
  dcnId!: number;
  primaryEntity!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  file!: File;
  isValidAttachment!: boolean; // true if selected attachment file extension name is valid
  validatingPdf: boolean;
  isValidPdf!: boolean; // true if selected pdf file has NO fillable fields
  displayContent!: string;
  saving!: boolean;

  constructor(private formBuilder: FormBuilder,
              private viewportComponent: CreateDisclosureTemplateViewportComponent,
              private attachedService: TemplateAttachedService,
              private trackerTabsService: TrackerTabsService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService) {
    this.validatingPdf = false;
    this.formType = this.sharedService.getActiveFormState().formType;
    this.formId = this.sharedService.getActiveFormState().formId;
    this.createdFormData = this.sharedService.getFormData();
    (this.formType === undefined) && (this.formType = this.createdFormData?.formtype); //custom reports pop-up edge case: formtype undefined
    if (this.formType === 'PDF') {
      this.fileAcceptStr = 'application/pdf';
      this.fileAcceptShort = ['pdf'];
    } else if (this.formType === 'XML Text Box') {
      this.fileAcceptStr = 'application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12';
      this.fileAcceptShort = ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    } else if (this.formType === 'Data Collect') {
      this.fileAcceptStr = 'application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12';
      this.fileAcceptShort = ['pdf', 'doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    }
  }

  ngOnInit(): void {
    this.formAttached = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      fileUploader: this.formBuilder.group({
        filingDataTxtCtrl: [''],
        file: ['', Validators.required]
      })
    });
  }

  ngAfterViewInit(): void {
    if (this.createdFormData && this.createdFormData.primaryleid) {
      setTimeout(() => {
        this.loadSaved();
      });
      return;
    }
    if (this.sharedService.leid !== '') {
      setTimeout(() => {
        const compsArr: TemplateLeidSelectorComponent[] = this.leidComponents.toArray();
        compsArr[0].fillLEID(this.sharedService.leid);
      });
    }
  }

  get fileCtrl(): (AbstractControl | null) | undefined {
    return this.formAttached.get('fileUploader')?.get('file');
  }

  private loadSaved(): void {
    if (!this.createdFormData || !this.createdFormData.primaryleid) {
      return;
    }
    const compsArr: TemplateLeidSelectorComponent[] = this.leidComponents.toArray();
    compsArr[0].fillLEID(this.createdFormData.primaryleid);
    compsArr[1].fillLEID(this.createdFormData.branchleid);
    this.dcnId = this.createdFormData.dcnid;
    this.isValidAttachment = !!this.createdFormData.downloadUrl;
    this.isValidAttachment && (this.displayContent = this.createdFormData.comments || '');
  }

  getCorpType(): string {
    if (!this.primaryEntity) {
      return '';
    }
    if (this.primaryEntity.TAXTYPE === 'FPS' || this.primaryEntity.TAXTYPE === 'DPS') {
      return ' Partnership';
    } else if (this.primaryEntity.TAXTYPE == 'TRT' || this.primaryEntity.TAXTYPE == 'ITR') {
      return ' Trust';
    }
    return ' Corporation';
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onPrimaryEntityChanged(selectedEntity: DSTEntityDetails | null): void {
    this.primaryEntity = selectedEntity;
    if (this.primaryEntity) {
      if (this.primaryEntity.DISREGARDED_ENTITY === '1' && this.formId !== 2 ) { //don't show dre msg for Form 8832(form id: 2)
        const dreMsg = this.sharedService.areEntitiesDisregarded([this.primaryEntity]);
        this.dstSharedService.showAlert('warning', dreMsg, 10000);
      }
      this.headerComponent.loadConsolGroups(this.primaryEntity.LEID,this.dcnId);
    }
  }

  onBranchEntityChanged(selectedEntity: DSTEntityDetails | null): void {
    this.branchEntity = selectedEntity;
  }

  onFileChanged(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.file = files[0];
      this.isValidAttachment = this.checkFileExt();
      if (this.formType === 'PDF') {
        this.checkFillable();
      } else if (this.formType === 'XML Text Box') {
        const extName = this.file.name.substring(this.file.name.lastIndexOf('.') + 1);
        this.attachedService.readFileContentFromFile(this.file, extName)
          .subscribe((displayFile: string) => {
            setTimeout(() => {
              this.displayContent = displayFile;
            });
          }, (e: Error) => {
            console.error(e);
            this.dstSharedService.showAlert('error', e.message);
          }).add(() => {
          this.displayContent = '';
        });
      }
    }
  }

  private checkFileExt(): boolean {
    const extName = this.file.name.substring(this.file.name.lastIndexOf('.') + 1);
    return this.fileAcceptShort.indexOf(extName) >= 0;
  }

  private checkFillable(): void {
    this.validatingPdf = true;
    this.attachedService.validatePdfAttch(this.file)
      .subscribe((isValid: boolean) => {
        this.isValidPdf = isValid;
      }, (e: Error) => {
        console.error(e);
        this.isValidPdf = false;
      }).add(() => {
      this.validatingPdf = false;
    });
  }

  download(): void {
    this.attachedService.getDSTPresignedUrl(this.createdFormData.attachmentpath, this.createdFormData.attachmentname)
      .subscribe((presignedS3Url: string) => {
        window.open(presignedS3Url, '_blank');
      });
  }

  deleteFileUrl(): void {
    this.createdFormData.downloadUrl = '';
    this.isValidAttachment = false;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    if (!this.displayContent && this.formType === 'XML Text Box') {
      this.dstSharedService.showAlert('error', 'Please populate text into the textbox');
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    const formState: DSTFormState = this.sharedService.getActiveFormState();
    this.saving = true;
    this.sharedService.saveAttachedStmt(formState.taxYear,
      formState.taxYearId,
      formState.returnPeriodId,
      this.dcnId,
      formState.formId,
      formState.statementId.toString(),
      this.primaryEntity!.ENTITYID,
      this.branchEntity ? this.branchEntity.ENTITYID : null,
      formState.formTypeId,
      isPlaceholderChecked,
      this.file ? this.file.name : this.createdFormData.attachmentname,
      this.file,
      formState.csStatementId?.toString() || '0',
      this.displayContent
    ).subscribe((data: any) => {
      this.sharedService.setSavedStatementId(data.statementId);
      this.viewportComponent.switchTemplateMode(true);
      this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
      this.dstSharedService.showAlert('success', 'Form saved successfully!', 4000);
    }, (e: Error) => {
      this.dstSharedService.showAlert('error', e.message);
    }).add(() => {
      this.saving = false;
    });
  }

  cancel(): void {
    if (this.createdFormData?.primaryleid) {
      this.viewportComponent.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

}
