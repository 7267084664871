import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenDocumentService } from '../screen-video-container/screen-document-upload/screen-document.service';
import { User } from '../screen-video-container/user';
import { UserService } from '../screen-video-container/user.service';
import { ScreenDocsDetails } from './screen-docs-details.model';
import _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScreenVideo } from '../screen-video-container/screen-video-list/screen-video';

@Component({
  selector: 'gtw-screen-documents',
  templateUrl: './screen-documents.component.html',
  styleUrls: ['./screen-documents.component.scss']
})
export class ScreenDocumentsComponent implements OnInit{
  @Output('close-model') closeModal = new EventEmitter<any>();

  data: string = '';
  hdKey: number | undefined;
  durationInSeconds = 5;
  text1: string = ``;
  @Input('base-url')
  baseURL = '/admin';

  private _screenKey:number =0;
  private _clientKey:number =0;
  public docTitle: string = '';
  public editData: any;
  isEditorShow: boolean = false;
  targetEdit!: HTMLElement | null;
  isLoaded: boolean = false;
  fromHome: boolean = false;
  isShowing: boolean = false;
  user_access: number = 0;
  showTabContent: number = 0;
  isLoading=false;



  @Input() attachmentList: any = [];
  @Input() fromUpload: boolean  = false;
  
  @Input('screen-key')
  set screenKey(screenKey:number){
    this._screenKey = parseInt(screenKey+"");
    
  }

  get screenKey():number{
    return this._screenKey;
  }

  @Input('screen-name')
  screenName: string = ''

  @Input('client-key')
  set clientKey(clientKey:number){
    this._clientKey = parseInt(clientKey+"");
    if(!isNaN(this._clientKey)){
      this.init()
    }
  }
  get clientKey():number{
    return this._clientKey;
  }
  @Input('user')
  set user(user:string){
    try{
      let tmp_user = JSON.parse(user ) as User;
      this.userService.setUser(tmp_user);
      this.user_access = parseInt(tmp_user.system_access_level);
    }catch(error){
      console.log("Not an valid object");
    }
    
  }

  @Output('document-modal-cancel')
  cancelDocModal = new EventEmitter<string>();

  @Input('base-url')
  baseUrl = 'http://localhost:8087';

  documentData = {} as ScreenDocsDetails;

  constructor(private userService:UserService,private _snackBar: MatSnackBar,
    private screenDocumentService:ScreenDocumentService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    @Optional() public dialogRef: MatDialogRef<ScreenDocumentsComponent>) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('sampleChange',changes);
  }
  
  ngOnInit(): void {
    
      console.log('dialogData',this.dialogData);
      if(this.dialogData && this.dialogData.screen_key){
        this._screenKey = this.dialogData.screen_key;
        this._clientKey = this.dialogData.client_key;
        this.screenName = this.dialogData.screenName;
        this.baseUrl = this.dialogData.baseUrl;
        this.fromHome = true;
        this.init();
      }
  }
  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }
  
  init(){
    console.log('this.user',this.user);
    this.getDocument(); 
  }

  openTab(tab: number) {
    this.showTabContent = tab;
  }

  htmlEncode(string : string) {
		return string.replace(/&/g, '&amp;')
  			.replace(/</g, '&lt;')
  			.replace(/>/g, '&gt;')
  			.replace(/'/g, '&#39;')
  			.replace(/"/g, '&#34;')
  			.replace(/\//, '&#x2F;');
	}

	htmlDecode(input : string) {
		let doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
  }

  getDocument() {
    this.isEditorShow = false;
    this.isLoading = true;
    this.screenDocumentService.getAllDocumentsList(this.baseUrl, this._clientKey, this._screenKey).subscribe(
      res => {
        console.log("this the response",res);
       // this.inProgress=false;
        
        if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
          this.attachmentList = res.data;
          if(this.attachmentList && this.attachmentList.length>0){
            let decodedHtml = this.htmlDecode(this.attachmentList[0]['hd_body']);
            this.documentData['hd_body'] = decodedHtml || "";
            this.documentData['hd_key'] = this.attachmentList[0]['hd_key'];
            // this.isEditorShow = true;
          }else{
            this.documentData['hd_body'] = '';
            this.isEditorShow = false;

          }
          this.documentData['hd_msg_title'] = this.screenName;

          this.isLoading = false;
          } else {
            this.isLoading = false;

        }
      },
      error => {
      });
  
    this.isLoaded = true;
  
  }
  onChange(newValue: any){
     this.text1 = newValue;
     this.editData = this.text1;
    //  this.documentData['hd_body'] = this.text1;
  }
  onChange1(newValue:any){
    this.text1 = newValue;
    this.editData = this.text1;
  }

  saveDocument(event:any,text1:any){
    let el = document.querySelector('.ql-editor');
    console.log('el',el);
    if(el){
      let encodedHtml = this.htmlEncode(el.innerHTML);
      this.documentData['hd_body'] = encodedHtml;
    }
   this.docTitle=this.screenName;
    this.screenDocumentService.uploadDocument(this.baseUrl,this.clientKey, this.screenKey, this.documentData.hd_msg_title, 
      this.documentData.hd_body, this.documentData.hd_key).subscribe( 
      res => {
        console.log("this the response",res);
        
        if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
          this.docTitle = '';
          this.text1 = '';
          this.openSnackBar("Document Saved Successfully !!");

          this.getDocument();
          this.isEditorShow = false;

          } else {
            this.openSnackBar(res.errorMessage);

        }
      },
      error => {
      });
  }
  clearContent(event:any){
    this.text1 = '';
  }
  editMode(event:any){
    this.isEditorShow = true;
    if(this.attachmentList && this.attachmentList.length>0){
      this.documentData['hd_body'] = '';//this.attachmentList[0]['hd_body'];
      this.text1 =  this.attachmentList[0]['hd_body'];
    }
    
  }

  deleteDoc(): void{
    let hdKey =this.documentData['hd_key']?this.documentData['hd_key']:null;
    if(hdKey){
      this.screenDocumentService.deleteDocumentById(this.baseUrl, this.clientKey, this.screenKey, hdKey).subscribe(
          res => {
            console.log("this the response",res);            
            if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
              this.openSnackBar("Document Deleted Successfully !!");
              this.getDocument()
              } else {
                this.openSnackBar(res.errorMessage);
            }
          },
          error => {
          });
      }
    }
  cancel(){
    /* let element = document.getElementById("documentId");
     element?.parentNode?.removeChild(element); */
     this.isShowing = true;
     if(this.fromHome){
      this.dialogRef?.close();

     }else{
      this.closeModal.emit('true');
      this.cancelDocModal.emit('document-modal-cancel');
     }

  }
  isValidUser(sso:string){
    const allowedRoles = ['admin','super admin','developer'];
    if(this.userService.isValidUser(['admin']) && this.userService.getUser()?.sso == sso){
      return true;
    }
    if(this.userService.isValidUser(['super admin','developer'])){
      return true;
    }
    
    return false;    
  }
}


