<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="doc-modal-title-wrapper">
        <h1 mat-dialog-title>{{documentData?documentData.hd_msg_title: ''}}</h1>
        <button mat-icon-button mat-dialog-close aria-label="Drag video close" style="color: #fff;">
            <mat-icon >close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class=" doc-view-modal">

        <!-- <iframe id="frame" sandbox="allow-same-origin allow-scripts" seamless="true" srcdoc="{{documentData?documentData.hd_body: ''}}" style="width:100%; border: none;"></iframe> -->
        <iframe id="frame" sandbox="allow-same-origin allow-scripts" seamless="true" srcdoc="{{documentData?documentData.hd_body: ''}}" style="width:100%;height:100%"></iframe>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>