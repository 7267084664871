import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { rowData, saveParams } from './naics-maintenance.component';


@Injectable({
  providedIn: 'root'
})
export class NaicsMaintenanceService {
	url ={
		insertNaicsCode: '/saveJsonObject?action_code=sppc78'
	}
	durationInSeconds= 5;
	constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	saveNaicsCode(baseURL : string, params: saveParams, jsonSettings: saveParams, jsonData: rowData[], scenarioCode: string, isIssueKeyEnabled: string, issueKey: number) {

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([jsonSettings]));
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year ? params.tax_year.toString() : '');
		body.append('scenario', params.scenario ? params.scenario.toString() : '');
		body.append('jcd_key', params.jcd_key ? params.jcd_key.toString() : '');
        body.append('process_name', 'save-naics-data');
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		body.append('issue_key', issueKey.toString());
		body.append('scenarioCode', scenarioCode);

		const url = `${baseURL}${this.url.insertNaicsCode}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					if(res.errorMessage != "null"){
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.openSnackBar("Success!", 'gtw-snackbar--green');
					return res;
				}else{
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					return "error";
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}


    openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}
}
