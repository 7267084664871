<mat-progress-bar *ngIf="isLoading"
                  color="primary"
                  mode="indeterminate">
</mat-progress-bar>

<div class="max-height">
  <section class="a-p-20">
    <div class="a-p-20">
      <table class="table table-hover table-bordered table-striped table-condensed">
        <thead class="a-thead ">
        <th><b>LEID</b></th>
        <th><b>Tax Code</b></th>
        <th><b>Reporting Period</b></th>
        <th><b>Entity Name</b></th>
        <th><b>Functional Currency</b></th>
        <th><b>Exchange Rate</b></th>
        <th><b>Account Type</b></th>
        </thead>
        <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="firstList.length > 0">
        <tr *ngFor="let item of firstList">
          <td><b>{{ item.HO_LEID }}</b></td>
          <td><b>{{ item.HO_CDR_NO }}</b></td>
          <td><b>{{ item.HO_REPORTING_PERIOD }}</b></td>
          <td><b>{{ item.LE_NAME }}</b></td>
          <td><b>{{ item.HO_FUNCTIONAL_CURRENCY }}</b></td>
          <td><b>{{ item.US_WTD_AVG_RATE }}</b></td>
          <td><b>{{ item.ACCT_TYPE }}</b></td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<div class="max-height">
  <section class="a-p-20">
    <div class="a-p-20">
      <table class="table table-hover table-bordered table-condensed">
        <thead class="a-thead">
        <th style="width:2%"  class="headerALign"></th>
        <th style="width:20%" class="headerALign"><b>Issue ID -- Issue Description</b></th>
        <th style="width:13%" class="headerALign"><b>Total</b></th>
        <th style="width:13%" class="headerALign"><b>Branch</b></th>
        <th style="width:13%" class="headerALign"><b>General</b></th>
        <th style="width:13%" class="headerALign"><b>FSI</b></th>
        <th style="width:13%" class="headerALign"><b>Passive</b></th>
        <th style="width:13%" class="headerALign"><b>US</b></th>
        </thead>
        <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="sourcingList.length > 0">
        <!--<ng-container>-->
          <tr>
            <td>
              <i *ngIf="myScenarioCode === 'EXAM'" class="fa fa-plus-circle color-green fa-lg pull-left"  style="cursor: pointer; margin-top: 4px;" data-toggle="tooltip" data-placement="right"  [attr.title]="toolTipMsgAdd"  data-trigger="hover" (click)="addSourcingRecord()"></i>
            </td>
          </tr>
          <tr *ngFor="let item of sourcingList; index as i">
            <td>
              <div *ngIf="item.ISSUE_KEY === 0" ></div>
                <div *ngIf="item.ISSUE_KEY !== 0 && item.TOTAL === 0 "><i class="fa fa-minus-circle color-red fa-lg pull-right" style="cursor: pointer; margin-top: 4px;" data-toggle="tooltip" data-placement="right" [attr.title]="toolTipMsgRemove"  data-trigger="hover" (click)="removeSourcingRecord(i)"></i></div>
            </td>
            <td class="headerALign">
              <div *ngIf="item.OP_MODE === 'edit' " ><div *ngIf="item.ISSUE_ID_SHORT_DESC === '0--RAF Issue' "><label>&nbsp;</label></div><div *ngIf="item.ISSUE_ID_SHORT_DESC !== '0--RAF Issue' "><label>{{item.ISSUE_ID_SHORT_DESC}}</label></div></div>
              <div *ngIf="item.OP_MODE === 'add' ">
                <mat-form-field class="field-full-width font-black-color"> <!-- class="font-black-color field-full-width mat-form-field-wrapper" -->

                  <mat-select name="issueDropdown" (selectionChange) = "onIssueChange($event, i)">
                    <ng-container *ngFor="let option of issueList">
                      <mat-option  [value]="option.ISSUE_ID">
                        {{option.ISSUE_ID_SHORT_DESC}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td class="amountALign"><label>{{item.TOTAL | currency:"$":'symbol': "1.0"}}</label></td>
            <td class="amountALign"><label>{{ item.BRANCH | currency:"$":'symbol': "1.0"}}</label></td>
            <!--<td><ap-form-field class="number-field" [type]="'inputNumber'"  [label]="'$'" [funType]="'typeTwo'" [(ngModel)]="item.GENERAL" matInputCommified></ap-form-field> </td>-->
            <!--<td><ap-form-field class="number-field" [type]="'inputNumber'"  [label]="'$'" [funType]="'typeTwo'" [(ngModel)]="item.FSI"></ap-form-field> </td>-->
            <!--<td><ap-form-field class="number-field" [type]="'inputNumber'"  [label]="'$'" [funType]="'typeTwo'" [(ngModel)]="item.PASSIVE"></ap-form-field> </td>-->
            <!--<td><ap-form-field class="number-field" [type]="'inputNumber'"  [label]="'$'" [funType]="'typeTwo'" [(ngModel)]="item.US"></ap-form-field> </td>-->
            <td class="amountALign">
              <div *ngIf="myScenarioCode === 'EXAM' && item.ISSUE_KEY === 0" ><label>{{item.GENERAL | currency:"$":'symbol': "1.0"}}</label></div>
              <div *ngIf="(myScenarioCode === 'RAF') || (myScenarioCode === 'EXAM' && item.ISSUE_KEY !== 0) "><input matInput class="number-field a-text-input" style="text-align: right !important;" type="text" [(ngModel)]="item.GENERAL" (change)="makeDirty(i)"  (blur)="refreshSourcingTotal('GENERAL', i)" (keypress)="keyPressNumbers($event)" matInputCommified></div> <!-- ngModelChange -->
            </td>
            <td class="amountALign">
              <div *ngIf="myScenarioCode === 'EXAM' && item.ISSUE_KEY === 0" ><label>{{item.FSI | currency:"$":'symbol': "1.0"}}</label></div>
              <div *ngIf="(myScenarioCode === 'RAF') || (myScenarioCode === 'EXAM' && item.ISSUE_KEY !== 0) "><input matInput class="number-field a-text-input" style="text-align: right !important;" type="text" [(ngModel)]="item.FSI" (change)="makeDirty(i)" (blur)="refreshSourcingTotal('FSI', i)" (keypress)="keyPressNumbers($event)" matInputCommified></div>
            </td>
            <td class="amountALign">
              <div *ngIf="myScenarioCode === 'EXAM' && item.ISSUE_KEY === 0" ><label>{{item.PASSIVE | currency:"$":'symbol': "1.0"}}</label></div>
              <div *ngIf="(myScenarioCode === 'RAF') || (myScenarioCode === 'EXAM' && item.ISSUE_KEY !== 0) "><input matInput class="number-field a-text-input" style="text-align: right !important;" type="text" [(ngModel)]="item.PASSIVE" (change)="makeDirty(i)" (blur)="refreshSourcingTotal('PASSIVE',i)" (keypress)="keyPressNumbers($event)" matInputCommified></div>
            </td>
            <td class="amountALign">
              <div *ngIf="myScenarioCode === 'EXAM' && item.ISSUE_KEY === 0" ><label>{{item.US | currency:"$":'symbol': "1.0"}}</label></div>
              <div *ngIf="(myScenarioCode === 'RAF') || (myScenarioCode === 'EXAM' && item.ISSUE_KEY !== 0) "><input matInput class="number-field a-text-input" style="text-align: right !important;" type="text" [(ngModel)]="item.US" (blur)="refreshSourcingTotal('US',i)" (change)="makeDirty(i)" (keypress)="keyPressNumbers($event)" matInputCommified></div>
            </td>
          </tr>
        <!--Total row-->
          <tr class="totalRow">
            <td></td>
            <td class="amountALign"><b>Total Basket Splits for all issues</b></td>
            <td class="amountALign"><b><label>{{calcTotal('TOTAL') | currency:"$":'symbol': "1.0"}}</label></b></td>
            <td class="amountALign"><b><label>{{calcTotal('BRANCH') | currency:"$":'symbol': "1.0"}}</label></b></td>
            <td class="amountALign"><b><label>{{calcTotal('GENERAL') | currency:"$":'symbol': "1.0"}}</label></b></td>
            <td class="amountALign"><b><label>{{calcTotal('FSI') | currency:"$":'symbol': "1.0"}}</label></b></td>
            <td class="amountALign"><b><label>{{calcTotal('PASSIVE') | currency:"$":'symbol': "1.0"}}</label></b></td>
            <td class="amountALign"><b><label>{{calcTotal('US') | currency:"$":'symbol': "1.0"}}</label></b></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<div  class="row">
  <div class="col-md-9"></div>
  <div class="col-md-3">
    <ap-button [btnText]="'Save'" class="a-mr-10"
               [config]="primaryConfig"
               (onBtnClick)="saveF8865M2K1SourcingData()"></ap-button>
    <ap-button [btnText]="'Cancel'" class="a-mr-10"
               [btnType]="'a-btn-gray'"
               [config]="primaryConfig"
               (onBtnClick)="modalClose()">
    </ap-button>

  </div>
</div>


