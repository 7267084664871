<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" [disabledS3]="s3Uploading"
  (onSaveClick)="save()" (onCancelClick)="cancel()" [disableS3Upload]="false"
  [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"
   (onS3UploadClick)="s3Upload()" [statementId]="stmtData?.statementid"></dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp351form184">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br/>
        STATEMENT PURSUANT TO SECTION 1.351-3 <b> {{attMap['statementOption'] == undefined ? '' : attMap['statementOption'][0]}}</b>&nbsp;
        <br/>
        <span class="template-color-blue" *ngIf="loaded">BY {{secondaryEntityName}}, EIN: {{stmtData.secondaryein == null ? 'N/A' : stmtData.secondaryein}}, A SIGNIFICANT TRANSFEROR</span>
      </span>
    </B>
  </div>
  <br/>
  <div [formGroup]="form184">
    <!-- <dst-template-header [parentFormGroup]="form184" (formOptionChanged)="onFormOptionChanged($event)"></dst-template-header>-->
    <ol>
      <li>
        The following property was transferred from
        <!-- <span class="template-color-blue" *ngIf="loaded"> {{stmtData.statementid}} </span>-->
        <span class="template-color-blue" *ngIf="loaded">{{secondaryEntityName}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryein == null? 'N/A': stmtData.secondaryein}}</span><span>, a significant transferor and a&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}</span>&nbsp;to
        <span class="template-color-blue" *ngIf="loaded">{{primaryEntityName}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryein == null? 'N/A': stmtData.primaryein}}</span><span>, transferee, a&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} </span> Corporation
        <!-- <span class="template-color-blue" *ngIf="loaded">{{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span> -->

      </li>
      <li>
        The date(s) of the transfer(s) of assets <br>
        <div *ngIf="loaded && attMap['LiquidationDate'] != undefined">
          <span class="template-color-blue" *ngIf="attMap['LiquidationDate'][0]"> {{attMap['LiquidationDate'][0]}} <br/></span>
          <span class="template-color-blue" *ngIf="attMap['LiquidationDate'][1]"> {{attMap['LiquidationDate'][1]}} <br/></span>
          <span class="template-color-blue" *ngIf="attMap['LiquidationDate'][2]"> {{attMap['LiquidationDate'][2]}} <br/></span>
        </div>

      </li>
      <li>
        Property Transferred:
        <div>
          <p>
            The fair market value and basis of the property transferred by such transferor in the exchange, determined immediately before the transfer and aggregated as follows:
          </p>
          <ol class="inner-list">
            <li>
              <div class="list-content">
                Importation property transferred in a loss importation transaction, as defined in sec.  1.362-3(c)(2) and (3), respectively;
                <br/><span *ngIf="loaded" class="template-color-blue">{{attMap['importationPropertyDesc'] == undefined ? 'None' : (attMap['importationPropertyDesc'][0] ? attMap['importationPropertyDesc'][0] : 'None')}}</span>
              </div>
            </li>
            <li>
              <div class="list-content">
                Loss duplication property as defined in sec.  1.362-4(g)(1);
                <br/><span *ngIf="loaded" class="template-color-blue">{{attMap['lossDuplicationPropertyDesc'] == undefined ? 'None' : (attMap['lossDuplicationPropertyDesc'][0] ? attMap['lossDuplicationPropertyDesc'][0] : 'None')}}</span>
              </div>
            </li>
            <li>
              <div class="list-content">
                Property with respect to which any gain or loss was recognized on the transfer (without regard to whether such property is also identified in paragraph (a)(3)(i) or (ii) of this Section);
                <br/><span *ngIf="loaded" class="template-color-blue">{{attMap['gainLossPropertyDesc'] == undefined ? 'None' : (attMap['gainLossPropertyDesc'][0] ? attMap['gainLossPropertyDesc'][0] : 'None')}}</span>
              </div>
            </li>
            <li>
              <div class="list-content">
                <span>Property not described in paragraph (a)(3)(i), (ii), or (iii) of this Section;</span>
                <table class="table-view-layout">
                  <tbody *ngIf="loaded">
                  <tr>
                    <td colspan="3"></td>
                    <td style="width: 22%;"><span>{{selBasisTxt}} Basis to the Transferor</span></td>
                    <td style="width: 22%;"><span>Fair Market Value on Date of Transfer</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td class="header-column"><span><b>Description</b></span></td>
                    <td>
                      <span><b><u>{{getSelCurrency(0)}}</u></b></span>
                    </td>
                    <td>
                      <span><b><u>{{getSelCurrency(1)}}</u></b></span>
                    </td>
                  </tr>
                  <ng-container ng-if="attMap['Desc'].length >= 1">

                    <tr *ngFor="let val of attMap['Desc'];let i=index">
                      <td colspan="2"></td>
                      <td class="con-left"><span>{{val}} </span></td>
                      <td class="con-right">
                        <span>{{attMap['BasisAmt'] == undefined ? '' : attMap['BasisAmt'][i] | number}}</span>
                      </td>
                      <td class="con-right">
                        <span>{{attMap['BasisAmt'] == undefined ? '' : attMap['FMVAmt'][i] | number}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td></td>
                      <td>
                        <hr align="center" style="border-top: 1px solid;">
                      </td>
                      <td>
                        <hr align="center" style="border-top: 1px solid;">
                      </td>
                    </tr>
                    <tr *ngIf="descTotalAvl">
                      <td colspan="2"></td>
                      <td class="con-left"><span>Total</span></td>
                      <td class="con-right">
                        <span>{{totalAmt('BasisAmt') | number}}</span>
                      </td>
                      <td class="con-right">
                        <span>{{totalAmt('FMVAmt') | number}}</span>
                      </td>
                    </tr>
                  </ng-container>

                  </tbody>
                </table>
                <div *ngIf="loaded && displaySharedOwnershipInfo">
                  There were <span class="template-color-blue">{{attMap['ShareX'] == undefined ? '' : (attMap['ShareX'][0] | number)}}</span> shares of capital stock of
                  <span class="template-color-blue">{{primaryEntityName}}</span> issued and outstanding immediately
                  prior to the Transfer. There were <span class="template-color-blue">{{attMap['ShareY'] == undefined ? '' : (attMap['ShareY'][0] | number)}}</span> shares
                  of capital stock of <span class="template-color-blue">{{primaryEntityName}}</span> issued and
                  outstanding immediately after the Transfer. <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span
                  class="template-color-blue">{{attMap['PercentageX'] == undefined ? '' : attMap['PercentageX'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued
                  and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> before the
                  Transfer.
                  <span class="template-color-blue">{{secondaryEntityName}}</span> owned <span
                  class="template-color-blue">{{attMap['PercentageY'] == undefined ? '' : attMap['PercentageY'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and
                  outstanding of
                  <span class="template-color-blue">{{primaryEntityName}}</span> after the Transfer.
                </div>
                
                <div *ngIf="loaded && noAddShares;">
                  No additional shares of capital stock of <span class="template-color-blue">{{primaryEntityName}}</span> were issued with respect to Transfer. 
                  
                  <span *ngIf="attMap['PercentageX'] !== undefined && attMap['PercentageY'] !== undefined && attMap['PercentageX'][0] !== '' && attMap['PercentageY'][0] !== ''; else noPercent">
                    <span *ngIf="attMap['PercentageX'][0] === attMap['PercentageY'][0]">
                        <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span class="template-color-blue">{{attMap['PercentageX'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> before and after the Transfer.
                    </span>
                    <span *ngIf="attMap['PercentageX'][0] !== attMap['PercentageY'][0]">
                        <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span class="template-color-blue">{{attMap['PercentageX'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> before the Transfer.
                        <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span class="template-color-blue">{{attMap['PercentageY'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> after the Transfer.
                    </span>
                  </span>
                  <ng-template #noPercent>
                    <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span class="template-color-blue">{{attMap['PercentageX'][0] == undefined ? '': attMap['PercentageX'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> before the Transfer.
                    <span class="template-color-blue">{{secondaryEntityName }}</span> owned <span class="template-color-blue">{{attMap['PercentageY'][0] == undefined ? '': attMap['PercentageY'][0]}}</span>% of the <span class="template-color-blue">{{selStockTypeTxt}}</span> stock issued and outstanding of <span class="template-color-blue">{{primaryEntityName}}</span> after the Transfer.
                  </ng-template>
                </div>

                <div *ngIf="sec304Check" class="div-top">
                  The above described transfer is a Section 304(a) exchange that is treated as one to which Section 351 applies. The following information
                  further describes the
                  Transfer: <span class="template-color-blue">{{secondaryEntityName}}</span> (a Significant
                  Transferor), transferred <span
                  class="template-color-blue">{{attMap['NoOfShares'] == undefined ? '' : (attMap['NoOfShares'][0] | number)}} {{getSelShares()}}</span> shares
                  in the capital of <span class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].ENTITY}}</span>, EIN: <span
                  class="template-color-blue">{{!lookupdata[0] ? '' : (lookupdata[0].EIN ? lookupdata[0].EIN : 'N/A')}}</span>, (the "Company"),
                  a <span class="template-color-blue">{{!lookupdata[0] ? '' : lookupdata[0].COUNTRYNAME}} {{shCorpTypeTxt}}</span>, to <span
                  class="template-color-blue">{{primaryEntityName}}</span>, (the Transferee corporation) in exchange
                  for <span class="template-color-blue">{{pickListVal}}</span> in the amount of <span
                  class="template-color-blue">{{attMap['AmtFor351Transfer'] == undefined ? '' : (attMap['AmtFor351Transfer'][0] | number)}}</span> {{getSelCurrency(2)}} .
                  <br/><br/><span class="template-color-blue"> {{attMap['Para'] == undefined ? '' : attMap['Para'][0]}}</span>
                </div>

              </div>
            </li>
          </ol>
        </div>
      </li>

      <li>
        The date and control number of any private letter ruling(s) issued by IRS in connection with the section 351 exchange: <br/>
        <span class="template-color-blue"
              *ngIf="loaded"> {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][3]}} {{attMap['PLR'] == undefined ? 'None' : (attMap['PLR'][0] == undefined ? 'None' : attMap['PLR'][0]) }}</span>

        <table class="table-view-layout">
          <tbody *ngIf="loaded && basisAmt1Total">
          <tr>
            <td colspan="2"></td>
            <td><b>Description</b></td>
            <td style="width: 22%;"><b>Amount</b></td>
            <td style="width: 22%;"></td>
          </tr>
          <ng-container ng-if="attMap['Desc1'].length >= 1">
            <tr *ngFor="let val of attMap['Desc1'];let i=index">
              <td colspan="2"></td>
              <td class="con-left">{{val}}</td>
              <td class="con-right" >{{attMap['BasisAmt1'] == undefined ? '' : attMap['BasisAmt1'][i] | number}}</td>
              <td ></td>
            </tr>
            <tr *ngIf="basisAmt1Total">
              <td colspan="2"></td>
              <td></td>
              <td>
                <hr align="center" style="border-top: 1px solid;">
              </td>
              <td></td>
            </tr>
            <tr *ngIf="basisAmt1Total">
              <td colspan="2"></td>
              <td class="con-left"><b>Total</b></td>
              <td class="con-right"><b>{{totalAmt('BasisAmt1') | number}}</b></td>
              <td></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </li>
    </ol>
    <div class="supp-info-wrapper">
      <div>Supplemental Information:</div>
      <!-- <textarea rows="5" cols="80" readonly class="txtborder w-100" *ngIf="loaded">{{attMap['section4text'] == undefined ? '' : attMap['section4text'][0]}}</textarea> -->
      <div class="mt-2 mb-2" *ngIf="loaded">
        {{attMap['section4text'] == undefined ? '' : attMap['section4text'][0]}}
      </div>

    </div>
    <table style="width:100%">
      <tbody *ngIf="loaded">
      <tr>
        <td class="footer-left">
          LEIDs: <span class="template-color-blue"> {{stmtData.secondaryleid == null ? '' : stmtData.secondaryleid}}</span> / <span
          class="template-color-blue"> {{stmtData.primaryleid == null ? '' : stmtData.primaryleid}}</span>
        </td>
        <td class="footer-right" *ngIf="stmtData.branchleid">
          Branch LEID: <span class="template-color-blue"> {{stmtData.branchleid == null ? '' : stmtData.branchleid}}</span>
        </td>
      </tr>
      <tr>
        <td class="footer-left">
          Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
