 <mat-progress-bar *ngIf="isModalLoading" color="primary" mode="indeterminate"> 
</mat-progress-bar>
<div class="modal-body no-body-scroll" *ngIf = "!isModalLoading">
	<div class="panel-body">
		 <mat-progress-bar *ngIf="!tableLoadingData" color="primary" mode="indeterminate"></mat-progress-bar> 
		<div class="modal-body no-body-scroll" *ngIf="tableData">
			<div class="divShadow1">
				<div class="table table-bordered table-condensed table-hover boldText" style="border:none;">
					<ap-table #basicTable [data]="SavedData" [showPage]="false"
						[pageSize]="SavedData.length" [striped]="true">
						<thead>
							<tr>
								<th colspan="6"> Edit Project Tag
									<i *ngIf="!FirstDiv" class="fa fa-plus-square color-white  fa-lg pull-right"
										style="cursor: pointer; margin-top: 9px;" (click)="onClick()"></i>
									<i *ngIf="FirstDiv" class="fa fa-plus-square color-white  fa-lg pull-right"
										style="cursor: pointer; margin-top: 9px;" (click)="onClick()"></i>
								</th>
							</tr>
						</thead>
						<tbody *ngIf="FirstDiv">
							<td style="background-color: white; text-align: center !important;"><b>Project Tag</b></td>
							<td style="background-color: white; text-align: center !important;"><b>Project Tag Amount</b></td>
                            <td style="background-color: white; text-align: center !important;"><b>Action</b></td>

							<tr *ngFor="let item of basicTable.data; let i = index;"
								style=" border: 1px solid #ddd;padding: 4px; background-color: white;">
								<td style="text-align: center !important">{{item.PROJECT_NAME}}</td>
								<td style="text-align: center !important">
									<input type="number" [(ngModel)]="item.PROJECT_AMT"
										style="text-align: right !important;border: 1px solid lightgrey;">
								</td>
                                <td style="text-align: center !important">
									<!-- <button class = "btn btn-danger" (click)="deleteRecord(item.SEQUENCE_KEY)" style = 'cursor:pointer; width: 75px !important;background-color: #d04a02 !important;'>Delete</button> -->
                                    <button type="button" (click)="deleteRecord(item.SEQUENCE_KEY)" class="btn btn-xs btn-danger " style="line-height: 0px;">
                                    <span class="fa fa-times"></span>
                                    </button>
								</td>
							</tr>
						</tbody>
					</ap-table>
				</div>
			</div>
		</div>

		<div class="addNewTable">
			<table *ngIf="TwoDiv" class="table table-bordered text">
			<thead>
				<tr>
					
					<th>Project Tag</th>
					<th>Project Tag Amount</th>
					<th>Action</th>
				</tr>
			</thead>
				<tbody>
					<tr *ngFor="let row of rows; let index = index">						
						<td>
							<div class="p-3 ProjectTaggingDropdown">
								<ap-selector [items]="row.projectList" [type]="'SingleSelector'"
									[searchMethod]="'inline'" [placeholder]="'Select Project Tag'"
									(onSelectedChange)="setProjectId($event, row)">
								</ap-selector>
							</div>
						</td>
						<td>
							<div class="p-3 ProjectTaggingDropdown">
								<input type="number" [(ngModel)]="row.PROJECT_AMT"
								class="numberRecord">
							</div>
						</td>
						<td>
							<div class="p-3 ProjectTaggingDropdown">
								<!-- <button class = "btn btn-danger" (click)="deleteRow(index)" style = 'cursor:pointer; width: 75px !important;background-color: #d04a02 !important;'>Remove</button> -->
                                <button type="button" (click)="deleteRow(index)" class="btn btn-xs btn-danger " style="line-height: 0px;">
                                <span class="fa fa-times"></span>
                                </button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<div style="float: left;">
				<ap-button [btnText]="'Add New Project Tag'" class="a-mr-10 mr-2" [config]="{isPrimary: true}"  [isDisabled]="isViewOnly"
					(onBtnClick)="addRow()"></ap-button>
			</div>
			<div style="float: right;">
				<ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [isDisabled]="isViewOnly"
					[config]="{isPrimary: true}" (onBtnClick)="saveData()"></ap-button>
				<ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [config]="{isPrimary: true}"
					(onBtnClick)="modalClose()"></ap-button>
			</div>
		</div>
	</div>
</div>