import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenDocumentsComponent } from './screen-documents.component';
import { EditorModule } from '../shared/components/editor/editor.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { ScreenVideosComponent } from './screen-videos/screen-videos.component';
import { VideoItemComponent } from './screen-videos/video-item/video-item.component';
import { VideoUploadComponent } from './screen-videos/video-upload/video-upload.component';


@NgModule({
  declarations: [ScreenDocumentsComponent, ScreenVideosComponent, VideoItemComponent, VideoUploadComponent],
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppNgMaterialModule,
  ],
  exports:[
    ScreenDocumentsComponent
  ]
})
export class ScreenDocsModule { }
