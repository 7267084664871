import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppNgMaterialModule } from '../app-ng-material.module';

import { EntityChartUpdateComponent } from './entity-chart-update/entity-chart-update.component';
import { DeleteTransactionModalComponent } from './entity-chart-update/delete-transaction-modal/delete-transaction-modal.component';
import { AppNgAppkitModule } from '../app-ng-appkit.module';
import {ButtonGroupModule} from 'appkit-angular/button-group';


@NgModule({
  declarations: [EntityChartUpdateComponent, DeleteTransactionModalComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgMaterialModule,
    AppNgAppkitModule,
    ButtonGroupModule
  ],
  exports:[
    EntityChartUpdateComponent
  ]
})
export class TrialBalanceInventoryModule { }
