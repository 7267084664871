import {Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DSTConsolGroup, TemplateHeaderComponent} from '../../template-header/template-header.component';
import {DSTSavePayload} from '../../../dst-dynamic-component-resolver.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../../template-leid-selector/template-leid-selector.component';
import {
  CreateDisclosureTemplateViewportComponent
} from '../../../create-disclosure-template-viewport/create-disclosure-template-viewport.component';
import {DSTSavedAttachment, TemplateAttachedService} from '../template-attached.service';
import {TrackerTabsService} from '../../../../../tracker-tabs.service';
import {CreateDisclosureSharedService} from '../../../create-disclosure-shared.service';
import {SaveActionResponse} from '../../../../../../../shared/_models/save-action-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState} from '../../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-bulk-upload',
  templateUrl: './template-bulk-upload.component.html',
  styleUrls: ['../../template-shared.scss', './template-bulk-upload.component.scss']
})
export class TemplateBulkUploadComponent implements OnInit {

  @ViewChild(TemplateHeaderComponent) headerComponent!: TemplateHeaderComponent;
  @ViewChildren(TemplateLeidSelectorComponent) leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formBulkUpload!: FormGroup;
  formType!: string;
  fileAcceptShort!: string[];
  dcnId!: number | null;
  primaryEntity!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  saving!: boolean;
  isValidPdf!: boolean;
  file!: File | null;
  savedFiles: DSTSavedAttachment[] = [];

  constructor(private formBuilder: FormBuilder,
              private viewportComponent: CreateDisclosureTemplateViewportComponent,
              private attachedService: TemplateAttachedService,
              private trackerTabsService: TrackerTabsService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService) {
    this.formType = this.sharedService.getActiveFormState().formType;
    if (this.formType === 'PDF') {
      this.fileAcceptShort = ['pdf'];
    } else if (this.formType === 'XML Text Box') {
      this.fileAcceptShort = ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    } else if (this.formType === 'Data Collect') {
      this.fileAcceptShort = ['pdf', 'doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    }
  }

  ngOnInit(): void {
    this.formBulkUpload = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup ? consolGroup.DCNID : null;
  }

  onPrimaryEntityChanged(selectedEntity: DSTEntityDetails | null): void {
    this.primaryEntity = selectedEntity;
    if (this.primaryEntity) {
      this.headerComponent.loadConsolGroups(this.primaryEntity.LEID,this.dcnId!);
    }
  }

  onBranchEntityChanged(selectedEntity: DSTEntityDetails | null): void {
    this.branchEntity = selectedEntity;
  }

  onMaxLenExceeded(): void {
    this.dstSharedService.showAlert('error', 'You are only allowed to upload 1 attachment each time!');
  }

  onExtNotMet(): void {
    this.dstSharedService.showAlert('error', 'Please make sure you only upload the allowed file types!');
  }

  onFilesDropped(files: FileList): void {
    if (!this.primaryEntity || this.dcnId === undefined || this.dcnId === null) {
      this.dstSharedService.showAlert('error', 'Please enter Primary LEID to save statement.');
      return;
    }

    this.file = files[0];
    if (this.formType === 'PDF') {
      this.checkFillableAndUpload();
    } else {
      this.upload();
    }
  }

  private checkFillableAndUpload(): void {
    this.saving = true;
    this.attachedService.validatePdfAttch(this.file!)
      .subscribe((isValid: boolean) => {
        this.isValidPdf = isValid;
        if (!this.isValidPdf) {
          this.saving = false;
          return;
        }
        this.upload();
      }, (e: Error) => {
        console.error(e);
        this.dstSharedService.showAlert('error', e.message);
        this.saving = false;
      });
  }

  private upload(): void {
    const formState: DSTFormState = this.sharedService.getActiveFormState();
    this.saving = true;
    this.sharedService.saveAttachedStmt(formState.taxYear,
      formState.taxYearId,
      formState.returnPeriodId,
      this.dcnId!,
      formState.formId,
      formState.statementId.toString(),
      this.primaryEntity!.ENTITYID,
      this.branchEntity ? this.branchEntity.ENTITYID : null,
      formState.formTypeId,
      false,
      this.file!.name,
      this.file!,
      formState.csStatementId?.toString() || '0',
    ).subscribe((data: any) => {
      this.savedFiles.push(new DSTSavedAttachment(data.statementId, this.primaryEntity?.LEID.toString() || '',
        this.branchEntity?.LEID.toString() || '', this.file!.name, data.userName));
      this.headerComponent.clear();
      this.leidComponents.forEach((lc: TemplateLeidSelectorComponent) => {
        lc.clear();
      });
      this.file = null;
    }, (e: Error) => {
      this.dstSharedService.showAlert('error', e.message);
    }).add(() => {
      this.saving = false;
    });
  }

  delete(file: DSTSavedAttachment): void {
    this.saving = true;
    this.attachedService.deleteSavedAttachment(file.STATEMENTID).subscribe((response: SaveActionResponse) => {
      if (response.callSuccess === '1') {
        const index: number = this.savedFiles.findIndex(f => f.STATEMENTID === file.STATEMENTID);
        this.savedFiles.splice(index, 1);
      } else {
        this.dstSharedService.showAlert('error', 'Something went wrong while deleting the statement data, please try again!');
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while deleting the statement data, please try again!');
    }).add(() => {
      this.saving = false;
    });
  }

  save(): void {
    let statementIds: string = this.savedFiles.map(f => f.STATEMENTID).join(',');
    this.saving = true;
    this.attachedService.activateBulkStatements(statementIds).subscribe((response: SaveActionResponse) => {
      if (response.callSuccess === '1') {
        this.dstSharedService.showAlert('success', 'Bulk statements saved successfully!');
        this.onCancel.emit();
      } else {
        this.dstSharedService.showAlert('error', 'Something went wrong while saving the statement data, please try again!');
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while saving the statement data, please try again!');
    }).add(() => {
      this.saving = false;
    });
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
