<div *ngIf="loader" style="padding: 149px !important;">
    <ap-loading id="overlay" [size]="'24px'" [colorName]="'a-primary'">
    </ap-loading>
</div>
<div class="mainContainer">
    <div style="width: 97.5% !important;" class="card m-3" *ngIf="!loader">
        <div class="card-body scrollable-form">
            <form [formGroup]="form" novalidate>
                <div formArrayName="rows">
                    <div class="row flex-nowrap">
                        <div class="col-md-2 col-label">
                            <label class="a-form-label col-label-text">Partnership Name</label>
                        </div>
                        <div class="col-md-2 col-label">
                            <label class="a-form-label col-label-text">Partner Name</label>
                        </div>
                        <div class="col-md-1 col-label">
                            <label class="a-form-label col-label-text">Partnership EIN</label>
                        </div>
                        <div class="col-md-1 col-label">
                            <label class="a-form-label col-label-text">Partner EIN</label>
                        </div>
                        <div class="col-md-2 col-label">
                            <label class="a-form-label col-label-text">Select Partnership</label>
                        </div>
                        <div class="col-md-2 col-label">
                            <label class="a-form-label col-label-text">Select Partner</label>
                        </div>
                        <div class="col-md-2 col-label">
                            <label class="a-form-label col-label-text">Posting Partner</label>
                        </div>

                    </div>
                    <div *ngFor="let row of formRows.controls; let i = index" [formGroupName]="i">
                        <div class="row flex-nowrap">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <ap-form-field [inlineBlock]="true" [id]="'InvestmentName'" [type]="'input'"
                                        formControlName="InvestmentName" [isDisabled]=false>
                                    </ap-form-field>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group">
                                    <ap-form-field [inlineBlock]="true" [id]="'InvestorName'" [type]="'input'"
                                        formControlName="InvestorName">
                                    </ap-form-field>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <div class="form-group">
                                    <ap-form-field [inlineBlock]="true" [id]="'InvestmentTaxId'" [type]="'input'"
                                        formControlName="InvestmentTaxId" [isDisabled]=false>
                                    </ap-form-field>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <ap-form-field [inlineBlock]="true" [id]="'InvestorTaxId'" [type]="'input'"
                                        formControlName="InvestorTaxId">
                                    </ap-form-field>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <select id="Pship_CC_Key" formControlName="Pship_CC_Key" class="form-control"
                                    (change)="onPshipDropdownChange($event, i)">
                                        <option *ngFor="let item of pshipDropdown" [value]="item.PSHIP_CC_KEY">
                                            {{item.PSHIP_DTLS}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <ap-loading *ngIf="isLoadingMap[i]" [size]="'12px'" [colorName]="'a-primary'">
                                    </ap-loading>
                                    <select *ngIf="!isLoadingMap[i]" id="Ptr_CC_key_{{i}}" formControlName="Ptr_CC_key" class="form-control"
                                        (change)="onSelectChange($event, i)">
                                        <option *ngFor="let item of ptrDropdownMap[i] || []; let j = index" [value]="item.PTR_CC_KEY">
                                            {{item.PTR_DTLS}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <ap-form-field [id]="'Posting_Ptr'" [type]="'input'" formControlName="Posting_Ptr"
                                        [isDisabled]=true>
                                    </ap-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

        <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
            [config]="secondaryConfig" (onBtnClick)="cancel()">
        </ap-button>
        <div>
            <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                (onBtnClick)="save()"></ap-button>
        </div>

    </div>
</div>