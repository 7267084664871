  <form [formGroup]="quartersForm" >
    <div class="form-group">
      <label for="tax-year">Tax Year</label>
      <select class="form-control" id="tax-year" formControlName="TAX_YEAR" required>
        <option [value]="taxYear.label" *ngFor="let taxYear of gtwInput?.taxYears">{{taxYear.label}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="quarter">Quarter</label>
      <select class="form-control" id="quarter" formControlName="QUARTER" required>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
    </div>
    <div class="form-group">
      <label for="active">Active</label>
      <input type="checkbox" class="ml-2" formControlName="ACTIVE" id="active">
    </div>
    <div class="full-width text-right">
      <ap-button [btnText]="'Submit'" [isDisabled]="quartersForm.invalid" [config]="{isPrimary: true}" (onBtnClick)="onSubmit()"></ap-button>
    </div>
  </form>

