import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from 'appkit-angular/modal';
import {CreateDisclosureSharedService} from '../../../statement-tracker/create-disclosure/shared/create-disclosure-shared.service';
import {TrackerTabsService, TrackerTabState} from '../../../statement-tracker/tracker-tabs.service';
import {StatementTrackerService} from '../../../statement-tracker/statement-tracker.service';
import {DSTChildFormState} from '../../models/dst-child-form-state.model';
import {DSTStatement} from '../../models/dst-statement.model';

@Component({
  selector: 'dst-disc-modal-viewer',
  templateUrl: './disc-modal-viewer.component.html',
  styleUrls: ['./disc-modal-viewer.component.scss']
})
export class DiscModalViewerComponent implements OnInit {

  @Input() rowObject!: DSTStatement;
  @Output() afterModalClosed: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('baseModal', {static: false}) baseModal!: ModalComponent;
  @ViewChild('modalRef') modalRef!: ElementRef;

  ready: boolean = false;

  constructor(private sharedService: CreateDisclosureSharedService,
              private trackerTabsService: TrackerTabsService) {
  }

  ngOnInit(): void {
  }

  private createModalTab(childFormStates: DSTChildFormState[]): void {
    const modalTab: TrackerTabState = new TrackerTabState(StatementTrackerService.ID_MODAL);
    // create parent form state
    modalTab.addFormState(
      this.rowObject.TAXYEAR,
      this.rowObject.taxYearId,
      0, // hard coded 0,
      this.rowObject.statementId,
      this.rowObject.formId,
      this.rowObject.formName,
      this.rowObject.ATTFORMTYPEDESC!,
      this.rowObject.FORMTYPEID!,
      this.rowObject.cseId,
      false,
      true,
      undefined, undefined, this.rowObject.csStatementId,
      (this.rowObject.statementId === '0' && this.rowObject.codeSectionId === 24));
    // create child form states
    childFormStates.forEach((childFormState: DSTChildFormState) => {
      modalTab.addFormState(
        childFormState.TAXYEAR,
        childFormState.TAXYEARID,
        childFormState.RETURNPERIODID,
        childFormState.STATEMENTID.toString(),
        childFormState.FORMID,
        childFormState.STATEMENTNAME,
        childFormState.FORMTYPE,
        childFormState.FORMTYPEID,
        childFormState.CSEID || 0,
        true,
        true);
    });
    modalTab.isPlaceholderChecked = this.rowObject.placeHolder === 1;
    console.log(this.rowObject);
    this.trackerTabsService.createTrackerTab(modalTab);
    this.sharedService.setLeId('');
    this.trackerTabsService.setActiveTrackerTabChildIndex(0);
    this.ready = true;
  }

  showMore(id: string, childFormStates: DSTChildFormState[]): void {
    this.createModalTab(childFormStates);
    this.baseModal.showMore(id);
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="dst-disclosure-viewer"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-lg');
    });
  }

  modalClose(): void {
    this.notifyAfterModalClosed();
  }

  modalDetermine(): void {
    this.notifyAfterModalClosed();
  }

  // closeModal(id: string): void {
  //   this.baseModal.closeModal(id);
  //   this.notifyAfterModalClosed();
  // }

  closeModalOk(id: string): void {
    this.baseModal.closeModalOk(id);
    this.notifyAfterModalClosed();
  }

  private notifyAfterModalClosed(): void {
    // this.sharedService.setFormData({});
    this.afterModalClosed.emit();
  }

}
