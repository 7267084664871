<div class="main-container">
    <div class="grid-container" id="diag-report-grid-container" style="height: calc(100vh - 124px);">
      <div class="top-toolbar">
        <div class="float-left ml-2">
          <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
            (onChange)="onTaxYearChange()">
          </p-dropdown>
          <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
          (selectedMes)="selectedMeChanged($event)">
        </gtw-df-me-dropdown>
          
          <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
          <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
        </div>
      </div>
  
      <p-table #dt [columns]="cols" [value]="data"  [loading]="loading"
        [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
        styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
        [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">
  
        <ng-template pTemplate="header" let-columns>
          <tr [style.height]="'45px'">
            <ng-container *ngFor="let col of columns; index as i">
              <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>
          </tr>
        </ng-template>
  
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr style="height: 70px;">
            <td style="width: 145px;">
              <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"> 
                <span>{{ rowData.STATEMENTID }}</span>
              </a> 
            </td>
            <td class="text-pre-wrap" style="width: 370px;">
              <span>{{ rowData.ATTACHMENTNAME }}</span>
            </td>
            <td style="width: 145px;">
              <span>{{ rowData.ATTFORMTYPEDESC }}</span>
            </td>
            <td class="text-pre-wrap" style="width: 100px;">
              <span>{{ rowData.TAXYEAR }}</span>
            </td>
            <td class="text-pre-wrap" style="width: 300px;">
              <span>{{ rowData.STATEMENTNAME }}</span>
            </td>
            <td style="width: 300px;">
              <span>{{ rowData.DCNNAME }}</span>
            </td>
            <td style="width: 300px;">
              <span>{{ rowData.PRIMARYBUSINESS }}</span>
            </td>
            <td class="text-pre-wrap" style="width: 300px;">
              <span>{{ rowData.SECONDARYBUSINESS }}</span>
            </td>
            <td style="width: 155px;">
              <span>{{ rowData.PRIMARYLEID }}</span>
            </td>
            <td style="width: 300px;">
              <span>{{ rowData.PRIMARYENTITY }}</span>
            </td>
            <td style="width: 150px;">
              <span>{{ rowData.PRIMARYGEGECS }}</span>
            </td>
            <td style="width: 150px;">
              <span>{{ rowData.SECONDARYGEGECS }}</span>
            </td>
            <td style="width: 400px;">
                <span class="text-color-primary">Created By: </span>
                {{rowData.CREATEDBY}}&nbsp;
                {{rowData.CREATEDON ? '(' + rowData.CREATEDON + ')' : ''}}<br>
                <span class="text-color-primary" style="font-style: italic">Modified By: </span>
                <span style="font-style: italic">
                  {{rowData.MODIFIEDBY}}&nbsp;
                  {{rowData.MODIFIEDON ? '(' + rowData.MODIFIEDON + ')' : ''}}
                </span>
              </td>
          </tr>
        </ng-template>
  
      </p-table>
    </div>
  </div>
  
  <dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
  </dst-disc-modal-viewer>