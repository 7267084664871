import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iif } from 'rxjs';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'gtw-sub-menus',
  templateUrl: './sub-menus.component.html',
  styleUrls: ['./sub-menus.component.scss']
})
export class SubMenusComponent implements OnInit {
  
  @Output() showScreensEvent = new EventEmitter<any>();
  
  @Input('selected_sub_task')
  set _taskskList(val:any){
    this.tasksList=val;
  } 
  tasksList:any;

  @Input('pannel-position') 
  set _pannelPosition(val:number){
    this.pannelPosition =val;
  }
  
  isAccordionOpen:boolean =false;
  @Input('is-accordion-open')
  set _isAccordionOpen(value:boolean){
    this.isAccordionOpen =value;
  } 

  mSetting:any={showScreen:true};
  @Input('m-setting')
  set _mSetting(value : any){
    this.mSetting = value;
  }

  pannelPosition=0;
  activityList:any = null;

  getActivtiesFromTask(task:any[]){
    return this.navigationService.getActivityFromTasks(task);
  }
  itemChange($event : any) {
  }
  itemChange2($event : any) {
  }
  constructor(private navigationService:NavigationService) { }

  ngOnInit(): void {
  }

  showScreens(screen : any){
    this.showScreensEvent.emit(screen);
  }

  retainOpenTask(event:Event, task:any){
    task.isOpen = !task.isOpen;
    if(!this.mSetting.showScreen){
      task.isClicked = true;
    }

    if(task.screens && !this.mSetting.showScreen){
      task.screens[0].isActive = true;
      this.showScreens(task.screens[0]);
    }
    event.stopPropagation();
  }
}
