<!-- <div class="container-fluid">
    <div class="row mt-3 mb-3">
        <div class="col-md-6">
            <h2>Detail Diagnostics <span class="appkiticon icon-star-outline"></span></h2>
        </div>
        <div class="col-md-4">
            <ap-search [isPrimary]="true" [isBase]="true" [(ngModel)]="baseValue" (onKeyUp)="filterBase($event)" placeholder="Search">
            </ap-search>
        </div>
        <div class="col-md-2">
            <div class="row">
                <div class="col-md-3">
                    <div class="iconContainer">
                        <span class="appkiticon icon-filter-outline icon"></span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="iconContainer">
                        <span class="appkiticon icon-comment-outline icon"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-2">
                    <label>Views</label>
                </div>
                <div class="col-md-4">
                    <ap-selector [items]="viewList" [type]="'Dropdown'" (onSelectedChange)="setView($event);"></ap-selector>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <ap-selector [items]="actionsList" [type]="'Dropdown'" (onSelectedChange)="selectedAction($event);"></ap-selector>
                        </div>
                        <div class="col-md-6">
                            <ap-button [btnText]="'Manage View'" class="a-mr-10" [btnType]="'a-btn-gray'" [config]="secondaryConfig" (onBtnClick)="btnClick()">
                            </ap-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="iconContainer">
                                <span class="appkiticon icon-comment-outline icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->

    <!-- <div class="row">
        <div class="col-md-12">
            <table>
                <tr>
                  <th *ngFor="let header of tableData.headers; let i = index">{{header.lable}}</th>
                </tr>
                <tr *ngFor="let row of tableData.Data; let j = index">
                  <td *ngFor="let col of row">{{col.value}}</td>
                </tr>
              </table>
        </div>
    </div> -->

   <!--<div class="row">
        <div class="col-md-12">
         <gtw-detail-diagnostic-data-table style="width:100%;margin-top:15px" [table-sheet]=this.detail_summary_data_sheets[0]></gtw-detail-diagnostic-data-table> 
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <table>
                <thead>
                    <tr>
                        <th><ap-checkbox> </ap-checkbox></th>
                        <th>LEID</th>
                        <th>ME Code</th>
                        <th>TB Loaded <span class="appkiticon icon-wifi-fill wifiIcon"></span><span class="appkiticon icon-information-outline"></span></th>
                        <th>TB Net To Zero</th>
                        <th>PY TB Loaded</th>
                        <th>Adjust PY Net Zero</th>
                        <th>Entity Begin</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableData.Data">
                        <td><ap-checkbox> </ap-checkbox></td>
                        <td>{{row.LEID}}</td>
                        <td>{{row.ME_Code}}</td>
                        <td><span *ngIf="row.TB_Loaded=='Y'" class="appkiticon icon-link-outline"></span><span *ngIf="row.TB_Loaded=='Y'" class="error">Error</span> <span *ngIf="row.TB_Loaded=='N'" class="noError">No Error</span></td>
                        <td><span *ngIf="row.TB_Net_To_Zero=='Y'" class="appkiticon icon-link-outline"></span><span *ngIf="row.TB_Net_To_Zero=='Y'" class="error">Error</span> <span *ngIf="row.TB_Net_To_Zero=='N'" class="noError">No Error</span></td>
                        <td><span *ngIf="row.PY_TB_Loaded=='Y'" class="appkiticon icon-link-outline"></span><span *ngIf="row.PY_TB_Loaded=='Y'" class="error">Error</span> <span *ngIf="row.PY_TB_Loaded=='N'" class="noError">No Error</span></td>
                        <td><span *ngIf="row.Adjust_PY_Net_Zero=='Y'" class="appkiticon icon-link-outline"></span><span *ngIf="row.Adjust_PY_Net_Zero=='Y'" class="error">Error</span> <span *ngIf="row.Adjust_PY_Net_Zero=='N'" class="noError">No Error</span></td>
                        <td>{{row.Entity_Begin}}</td>
                    </tr>
                </tbody>
              </table>
        </div>
    </div>
</div> -->
<div class="container-fluid">
    <div class="row mt-3 mb-3">
        <div class="col-md-11 screen-label">
            <span>Detail Diagnostics <span class="icon fa fa-star-o" style="vertical-align: middle; font-size: 20px;"></span></span>
        </div>
        <div class="col-md-1">
            <div class="row">
                <div class="col-md-12">
                    <div class="iconContainer">
                        <span class="appkiticon icon-filter-outline customList" (click)="showFilter()">
                            <i *ngIf="selectedParams!=undefined" class="fa fa-circle" 
                            style="font-size: 8px;color: #D04A02;padding-left: 2px;
                            padding-bottom: 6px"></i>

                        </span>
                    </div>
                        <div [hidden]="isShow">
                        <!-- <div *ngIf="isShow"> -->
                            <gtw-dashboard-filter [filter-group-key]="701" [screen-key]="11460" 
                                [client-key]="clientKey" [base-urls]="baseURLs" [global-params]="globalParams"
                                [tax-years]="taxYears"
                                (close-dashboard-filter)="closeFilterPanel($event)"
                                (filterSelectedEmitter)="loadDetailGrid($event)"
                               ></gtw-dashboard-filter>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>