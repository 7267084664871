import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionService } from '../../shared/_services/action.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionResponse } from '../../shared/_models/action-response.model';
import { DSTSharedService } from '../shared/dst-shared.service';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    constructor(private httpClient: HttpClient,
        private actionService: ActionService,
        private dstSharedService: DSTSharedService) {
    }

    loadSection351Report(taxYear: string, parent_me_string: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'parent_me_string': parent_me_string,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32511, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wron while getting the Reports- Section 351 reports data, please try again!');
                })
            );
    }

    loadSection332Report(taxYear: string, parent_me_string: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'parent_me_string': parent_me_string,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32513, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wron while getting the Reports- Section 332 reports data, please try again!');
                })
            );
    }

    loadSection368Report(taxYear: string, parent_me_string: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'parent_me_string': parent_me_string,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32510, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wron while getting the Reports- Section 368 reports data, please try again!');
                })
            );
    }

    loadForm926Report(taxYear: string, parent_me_string: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'parent_me_string': parent_me_string,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32608, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wron while getting the Reports- Section 926 reports data, please try again!');
                })
            );
    }

  loadForm8594Report(taxYear: string, parent_me_string: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYear,
        'parent_me_string': parent_me_string,
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32611, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wron while getting the Reports- Section 926 reports data, please try again!');
        })
      );
  }

  loadForm8883Report(taxYear: string, parent_me_string: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYear,
        'parent_me_string': parent_me_string,
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32610, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wron while getting the Reports- Section 926 reports data, please try again!');
        })
      );
  }

  loadStatementsByYearandBusiness(taxYear: string, business_id: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYear,
        'business_id': business_id,
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32918, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wrong while getting the Reports- Statements List By Year and Business, please try again!');
        })
      );
  }

  loadCountByBusiness(taxYear: string, previousTaxYear: string, business_id: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYear,
        'previous_tax_year': previousTaxYear,
        'business_id': business_id,
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32919, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
            console.log('data : ', data)
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wrong while getting the Reports- Count by Business, please try again!');
        })
      );
  }

  loadBusinesses(taxYear: string, previousTaxYear : string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year' : taxYear,
        'previous_year' : previousTaxYear
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32920, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
            console.log('data : ', data)
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wrong while getting the Reports- Get Businesses, please try again!');
        })
      );
  }

  loadDiagnosticsReport(taxYear: string, me_string: string): Observable<any> {
    const params = new HttpParams({
        fromObject: {
            'sso_id': this.dstSharedService.user?.ssoId,
            'tax_year': taxYear,
            'me_string':me_string,
        }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32944, this.dstSharedService.clientKey, params)
        .pipe(
            map((data: ActionResponse) => {
                if (data.callSuccess === '1') {
                    return data.jsonObject;
                }
                return throwError('Something went wrong while getting the Reports- Diagnostics reports data, please try again!');
            })
        );
  }

  loadDisregardedEntities(taxYear: string, me_string: string): Observable<any> {
    const params = new HttpParams({
        fromObject: {
            'sso_id': this.dstSharedService.user?.ssoId,
            'tax_year': taxYear,
            'me_string':me_string,
        }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 33001, this.dstSharedService.clientKey, params)
        .pipe(
            map((data: ActionResponse) => {
                if (data.callSuccess === '1') {
                    return data.jsonObject;
                }
                return throwError('Something went wrong while getting the Reports- Disregarded Entities reports data, please try again!');
            })
        );
  }

}
