<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" [disabled]="saveDisabled" (onSaveClick)="save()" (onCancelClick)="cancel()"
                               [displayNotification]="showDREMsg" [notifMsg]="DREMsg">
</dst-create-disclosure-actions>

<div class="form-template-wrapper li-apdix-wrapper-temp" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form236">
    <dst-template-header [parentFormGroup]="form236" (consolGroupChanged)="onConsolGroupChanged($event)">
    </dst-template-header>

    <div class="mt-3" formGroupName="sec0">
      <dst-template-leid-selector class="required-field" [label]="'Please specify the LEID of the Shareholder'"
                                  [labelId]="'1'" (onSelectedLEChanged)="onShareholderChanged($event)">
      </dst-template-leid-selector>

      <div class="entity-details">
        <span class="ed-label">Entity Name:</span>
        <span class="template-color-blue">{{!shareholder ? '' : shareholder.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span class="ed-label">Federal ID:</span>
        <span class="template-color-blue">{{!shareholder ? '' : (shareholder.EIN ? shareholder.EIN : 'N/A')}}</span>
      </div>

      <div class="entity-details" *ngIf="shareholder && shareholder.EIN === undefined">
        <span class="ed-label">EIN Missing Reason:</span>
        <div class="d-inline-block">
          <ap-selector class="ap-form-field-my-wrapper" [items]="reasonsEIN" [type]="'Dropdown'"
                       (onSelectedChange)="onSHEINMissingReasonChanged($event)" required>
          </ap-selector>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">Shareholder Tax Year</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ap-radio class="a-mx-10" [name]="'radio-group-1'" [text]="'Specify By Calendar Year'" [checked]="true" (onCheck)="check1($event, 'Year')">
          </ap-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ap-radio class="a-mx-10" [name]="'radio-group-1'" [text]="'Specify By Date Range'" (onCheck)="check1($event, 'Range')">
          </ap-radio>
        </div>
      </div>
      <div class="row" *ngIf="shareholderTaxYearType === 'Year'">
        <div class="col-md-12">
          <div class="entity-details">
            <span class="mt-2 ml-3" style="width: 265px;">Calendar Year</span>
            <div class="d-inline-block">
              <ap-selector class="ap-form-field-my-wrapper field-full-width select-dropdown"
                           [items]="taxYearsSH" [type]="'SingleSelector'" [searchMethod]="'inline'"
                           (onSelectedChange)="onShareholderTYChanged($event)"></ap-selector>
            </div>
          </div>
        </div>
      </div>
      <div class="row ap-form-field-my-wrapper mt-1" *ngIf="shareholderTaxYearType === 'Range'">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="entity-details">
                <span style="width: 260px;">Tax Year Beginning</span>
                <div class="d-inline-block">
                  <ap-date-picker class="date-field date-field-margin" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                [maxDate]="endDate" [isTitleLeft]="true" formControlName="shareholderBeginningTaxYear" [(ngModel)]="shareholderBeginningTY"
                                (onChange)="dateChange($event, 2)" (click)="onDPOpen()">
                  </ap-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="entity-details">
                <span style="width: 260px;">Tax Year Ending</span>
                <div class="d-inline-block">
                  <ap-date-picker class="date-field date-field-margin" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                [maxDate]="endDate" [isTitleLeft]="true" formControlName="shareholderEndingTaxYear" [(ngModel)]="shareholderEndingTY"
                                (onChange)="dateChange($event, 3)" (click)="onDPOpen()">
                  </ap-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          Check type of shareholder filing the return:
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'Individual'" (onCheck)="onShareholderTypeChecked($event, 'Individual')"
                       [checked]="shareholderFiling === 'Individual'">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'Corporation'" (onCheck)="onShareholderTypeChecked($event, 'Corporation')"
                       [checked]="shareholderFiling === 'Corporation'">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'Partnership'" (onCheck)="onShareholderTypeChecked($event, 'Partnership')"
                       [checked]="shareholderFiling === 'Partnership'">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'S Corporation'" (onCheck)="onShareholderTypeChecked($event, 'SCorporation')"
                       [checked]="shareholderFiling === 'SCorporation'">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'Nongrantor Trust'" [checked]="shareholderFiling === 'NongrantorTrust'"
                       (onCheck)="onShareholderTypeChecked($event, 'NongrantorTrust')">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10" [text]="'Estate'" (onCheck)="onShareholderTypeChecked($event, 'Estate')"
                       [checked]="shareholderFiling === 'Estate'">
          </ap-checkbox>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="d-flex">
            <div>
              Qualifying Insurance Corporation Election - I, a shareholder of stock of a foreign corporation, elect to treat such stock as the stock of a
              Qualifying Insurance Corporation under the alternative facts and circumstances test within the meaning of section 1297(f)(2). See instructions
              <span class="appkiticon icon-information-fill red-icon" [tooltip-container]="tooltip1" [placement]="'right'"></span>
            </div>
            <ap-checkbox class="a-mx-10" [checked]="corpElectionChecked" (onCheck)="onCorpElectionChecked()">
            </ap-checkbox>
          </div>
        </div>
      </div>

      <!-- PFIC Information -->
      <div class="row mt-4">
        <div class="col-md-3">
          <span>Is PFIC in Legal Entity Database?</span>
        </div>
        <div class="col-md-3">
          <ap-radio class="a-mx-10" [name]="'radio-group-2'" [text]="'Yes'"
                    [checked]="isPFICInDB === 'Yes'" (onCheck)="check2('Yes')">
          </ap-radio>
          <ap-radio class="a-mx-10" [name]="'radio-group-2'" [text]="'No'"
                    [checked]="isPFICInDB === 'No'" (onCheck)="check2('No')">
          </ap-radio>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <dst-template-leid-selector class="required-field" [label]="'Please specify the LEID of the PFIC'"
                                      [labelId]="'4'" (onSelectedLEChanged)="onPFICChanged($event)">
          </dst-template-leid-selector>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="entity-details">
            <span class="ed-label">Name of PFIC:</span>
            <span class="template-color-blue">{{!PFIC ? '' : PFIC.ENTITY}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="entity-details">
            <span class="ed-label">Identifying Number:</span>
            <span class="template-color-blue">{{!PFIC ? '' : (PFIC.EIN ? PFIC.EIN : 'N/A')}}</span>
          </div>
        </div>
      </div>
      <div class="entity-details" *ngIf="PFIC && PFIC.EIN === undefined">
        <span class="ed-label">EIN Missing Reason:</span>
        <div class="d-inline-block">
          <ap-selector class="ap-form-field-my-wrapper" [items]="missingReasonsEIN" [type]="'Dropdown'"
                       (onSelectedChange)="onPFICEINMissingReasonChanged($event)" required >
          </ap-selector>
        </div>
      </div>
      <!-- PFIC Address Details -->
      <div class="mt-3 pl-3" [style.display]="PFIC ? 'block' : 'none'">
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details">
              <span class="ed-label sub-ed-label">Country:</span>
              <div class="d-inline-block">
                <ap-selector class="ap-form-field-my-wrapper field-full-width" [items]="countries" [type]="'SingleSelector'"
                             [searchMethod]="'inline'" (onSelectedChange)="onPFICCountryChanged($event)">
                </ap-selector>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details">
              <span class="ed-label sub-ed-label">Address Line 1:</span>
              <div class="d-inline-block">
                <ap-form-field class="ap-form-field-my-wrapper" formControlName="addrLn1" [label]="''" [id]="'5'"
                               [type]="'input'" [value]="PFICAdd1" (onChangeEvent)="onPFICAddrChanged($event, 1)">
                </ap-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details">
              <span class="ed-label sub-ed-label">Address Line 2:</span>
              <div class="d-inline-block">
                <ap-form-field class="ap-form-field-my-wrapper" formControlName="addrLn2" [label]="''" [id]="'6'"
                               [type]="'input'" [value]="PFICAdd2" (onChangeEvent)="onPFICAddrChanged($event, 2)">
                </ap-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details">
              <span class="ed-label sub-ed-label">{{ PFICUS ? 'City:' : 'City / Town:'}}</span>
              <div class="d-inline-block">
                <ap-form-field class="ap-form-field-my-wrapper" formControlName="city" [label]="''" [id]="'7'"
                               [type]="'input'" [value]="PFICCity" (onChangeEvent)="onPFICCityChanged($event)">
                </ap-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details" *ngIf="PFICUS">
              <span class="ed-label sub-ed-label">State:</span>
              <div class="d-inline-block">
                <ap-selector class="ap-form-field-my-wrapper field-full-width" [items]="states" [type]="'SingleSelector'"
                             [searchMethod]="'inline'" (onSelectedChange)="onPFICStateChanged($event)"></ap-selector>
              </div>
            </div>
            <div class="entity-details" *ngIf="!PFICUS">
              <span class="ed-label sub-ed-label">Province or State:</span>
              <div class="d-inline-block">
                <ap-form-field class="ap-form-field-my-wrapper" formControlName="stateProv" [label]="''" [id]="'8'"
                               [type]="'input'" [value]="PFICStateProv" (onChangeEvent)="onPFICStateProvChanged($event)">
                </ap-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="entity-details">
              <span class="ed-label sub-ed-label">{{ PFICUS ? 'Zip:' : 'Postal Code:'}}</span>
              <div class="d-inline-block">
                <ap-form-field class="ap-form-field-my-wrapper" formControlName="zip" [label]="''" [id]="'9'"
                               [type]="'input'" [value]="PFICZip" (onChangeEvent)="onPFICZipChanged($event)">
                </ap-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PFIC Tax Years -->
      <div class="row mt-4">
        <div class="col-md-12">PFIC/QEF Tax Year</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ap-radio class="a-mx-10" [name]="'radio-group-3'" [text]="'Specify By Calendar Year'" [checked]="true" (onCheck)="check3($event, 'Year')">
          </ap-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ap-radio class="a-mx-10" [name]="'radio-group-3'" [text]="'Specify By Date Range'" (onCheck)="check3($event, 'Range')">
          </ap-radio>
        </div>
      </div>
      <div class="row" *ngIf="PFICTaxYearType === 'Year'">
        <div class="col-md-12">
          <div class="entity-details">
            <span class="mt-2 ml-3" style="width: 265px;">Calendar Year</span>
            <div class="d-inline-block">
              <ap-selector class="ap-form-field-my-wrapper field-full-width select-dropdown" [items]="taxYearsPFIC" [type]="'SingleSelector'" [searchMethod]="'inline'"
                           (onSelectedChange)="onPFICTYChanged($event)"></ap-selector>
            </div>
          </div>
        </div>
      </div>
      <div class="row ap-form-field-my-wrapper mt-1" *ngIf="PFICTaxYearType === 'Range'">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="entity-details">
                <span style="width: 260px;">Tax Year Beginning</span>
                <div class="d-inline-block">
                  <ap-date-picker class="date-field date-field-margin" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                [maxDate]="endDate" [isTitleLeft]="true" formControlName="companyOrFundBeginningTaxYear" [(ngModel)]="companyOrFundBeginningTY"
                                (onChange)="dateChange($event, 4)" (click)="onDPOpen()">
                  </ap-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="entity-details">
                <span style="width: 260px;">Tax Year Ending</span>
                <div class="d-inline-block">
                  <ap-date-picker class="date-field date-field-margin" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                [maxDate]="endDate" [isTitleLeft]="true" formControlName="companyOrFundEndingTaxYear" [(ngModel)]="companyOrFundEndingTY"
                                (onChange)="dateChange($event, 5)" (click)="onDPOpen()">
                  </ap-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <br/>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part I&nbsp;&nbsp;</strong>
          <strong>Summary of Annual Information</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="" formGroupName="partOne">
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="section-content list-content m-0 pb-0" start="1">

          <li>
            <div class="row">
              <div class="pl-3">
                <label [for]="'sharesDesc'">Description of each class of shares held by the shareholder: </label>
              </div>
              <div class="col-md-3" style="padding-left: 11px;">
                <ap-form-field [inlineBlock]="true" class="text-box d-flex"
                               [id]="'sharesDesc'" [type]="'input'" formControlName="shareHoldersSharesDesc" [(ngModel)]="shareHoldersSharesDesc">
                </ap-form-field>
              </div>
            </div>
            <ap-checkbox class="a-mx-10" [text]="'Check if shares jointly owned with spouse.'"
                         formControlName="sharesOwnedWithSpouse" [(ngModel)]="sharesOwnedWithSpouse">
            </ap-checkbox>
          </li>

          <li class="pt-2">
            <div class="row">
              <div class="pl-3">
                Date shares acquired during the taxable year, if applicable:
              </div>
              <div class="col-md-3" style="padding-left: 16px;">
                <ap-date-picker class="date-field" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
                                [maxDate]="endDate" [isTitleLeft]="true" formControlName="dateSharesAcq" [(ngModel)]="dateSharesAcq"
                                (onChange)="dateChange($event, 1)" (click)="onDPOpen()">
                </ap-date-picker>
              </div>
            </div>
          </li>

          <li class="pt-2">
            <div class="row">
              <div class="col-md-4">
                <label [for]="'noOfSharesYrEnd'">Number of shares held at the end of the taxable year: </label>
              </div>
              <div class="col-md-3">
                <ap-form-field [inlineBlock]="true" [id]="'noOfSharesYrEnd'" class="number-field no-label number-field-input amount-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="noOfSharesYearEnd" [(ngModel)]="noOfSharesYearEnd">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="pt-2">
            <div class="row">
              <div class="col-md-12">
                Value of shares held at the end of the taxable year (check the appropriate box, if applicable):
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-3 required-field-checkbox">
                <b>(a)</b>
                <ap-checkbox class="a-mx-10" [checked]="shareValueTaxyearEnd === 0" [text]="'$0-50,000'"
                             (onCheck)="onShareValueRangeChanged($event, 0)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 required-field-checkbox">
                <b>(b)</b>
                <ap-checkbox class="a-mx-10" [checked]="shareValueTaxyearEnd === 50001" [text]="'$50,001 - 100,000'"
                             (onCheck)="onShareValueRangeChanged($event, 50001)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 required-field-checkbox">
                <b>(c)</b>
                <ap-checkbox class="a-mx-10" [checked]="shareValueTaxyearEnd === 100001" [text]="'$100,001 - 150,000'"
                             (onCheck)="onShareValueRangeChanged($event, 100001)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 required-field-checkbox">
                <b>(d)</b>
                <ap-checkbox class="a-mx-10" [checked]="shareValueTaxyearEnd === 150001" [text]="'$150,001 - 200,000'"
                             (onCheck)="onShareValueRangeChanged($event, 150001)">
                </ap-checkbox>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-12 d-flex">
                <b class="pr-2">(e)</b> if more than $200,000, list value:
                <ap-form-field [inlineBlock]="true" [id]="'valueOfSharesYearEnd'" class="number-field required-field amount-input number-field-input" [funType]="'typeTwo'"
                  [type]="'inputNumber'" formControlName="valueOfSharesYearEnd" [(ngModel)]="valueOfSharesYearEnd">
                </ap-form-field>
              </div>
            </div>

          </li>

          <li class="pt-2">
            <div class="row">
              <div class="col-md-12">
                Type of PFIC and amount of any excess distribution or gain treated as an excess distribution under section 1291, inclusion under section 1293,
                or inclusion or deduction under section 1296:
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 p-2 required-field-checkbox">
                (a)
                <ap-checkbox class="a-mx-10" [text]="'Section 1291'" [checked]="pficType === 1"
                             (onCheck)="onPficTypeChanged($event, 1)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 p-2 required-field" style="padding-left: 15px !important;">
                <ap-form-field [inlineBlock]="true" [id]="'pficType1Amount'" class="number-field amount-input number-field-input" [type]="'inputNumber'"
                               [label]="'$'" [funType]="'typeTwo'" formControlName="pficType1Amount" [(ngModel)]="pficType1Amount">
                </ap-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 p-2 required-field-checkbox">
                (b)
                <ap-checkbox class="a-mx-10" [text]="'Section 1293 (Qualified Election Fund)'" [checked]="pficType === 2"
                             (onCheck)="onPficTypeChanged($event, 2)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 p-2 required-field" style="padding-left: 15px !important;">
                <ap-form-field [inlineBlock]="true" [id]="'pficType2Amount'" class="number-field amount-input number-field-input" [type]="'inputNumber'"
                               [label]="'$'" [funType]="'typeTwo'" formControlName="pficType2Amount" [(ngModel)]="pficType2Amount">
                </ap-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 p-2 required-field-checkbox">
                (c)
                <ap-checkbox class="a-mx-10" [text]="'Section 1296 (Mark to Market)'" [checked]="pficType === 3"
                             (onCheck)="onPficTypeChanged($event, 3)">
                </ap-checkbox>
              </div>
              <div class="col-md-3 p-2 required-field" style="padding-left: 15px !important;">
                <ap-form-field [inlineBlock]="true" [id]="'pficType3Amount'" class="number-field amount-input number-field-input" [type]="'inputNumber'"
                               [label]="'$'" [funType]="'typeTwo'" formControlName="pficType3Amount" [(ngModel)]="pficType3Amount">
                </ap-form-field>
              </div>
            </div>
          </li>

        </ol>
      </div>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part II&nbsp;&nbsp;</strong>
          <strong>Elections</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="" formGroupName="partTwo">
      <p class="m-0 pt-3 pb-1">Check the applicable box for this type of election</p>
      <div class="section-content list-content list-spacing pt-0">
        <ol class="list-upper-alpha m-0">
          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'electionToTreat'"
                           [(ngModel)]="electionToTreat" formControlName="electionToTreat">
              </ap-checkbox>
              <p>Election To Treat the PFIC as a QEF: I, a shareholder of a PFIC, elect to treat the PFIC as a QEF. Complete lines 6a through 7c of Part
                III.</p>
            </div>
          </li>

          <li class="row m-0 p-0">
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'electionToExtend'"
                           [(ngModel)]="electionToExtend" formControlName="electionToExtend">
              </ap-checkbox>
              <p class="mb-0">Election To Extend Time For Payment of Tax: I, a shareholder of a QEF, elect to extend the time for payment of tax on the
                undistributed earnings and profits of the QEF until this election is terminated. Complete lines 8a through 9c of Part III to calculate the tax
                that may be deferred. Note: If any portion of line 6a or line 7a of Part III is includible under section 951, you may not make this election.
                Also, see sections 1294(c) and 1294(f) and the related regulations for events that terminate this election.</p>
            </div>
            <div class="col-md-3 pb-2" style="padding-left: 35px !important;">
              <ap-form-field [inlineBlock]="true" class="text-box" formControlName="electionToExtendDesc"
                             [id]="'electionToExtendDesc'" [type]="'input'" [(ngModel)]="electionToExtendDesc">
              </ap-form-field>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'electionToMarkToMarket'"
                           [(ngModel)]="electionToMarkToMarket" formControlName="electionToMarkToMarket">
              </ap-checkbox>
              <p>Election To Mark-to-Market PFIC Stock: I, a shareholder of a PFIC, elect to mark-to-market the PFIC stock that is marketable within the meaning
                of section 1296(e). Complete Part IV.</p>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'deemedSalesElection'"
                           [(ngModel)]="deemedSalesElection" formControlName="deemedSalesElection">
              </ap-checkbox>
              <p>Deemed Sale Election: I, a shareholder on the first day of a PFIC's first tax year as a QEF, elect to recognize gain on the deemed sale of my
                interest in the PFIC. Enter gain or loss on line 15f of Part V.</p>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'deemedDividentElection'"
                           [(ngModel)]="deemedDividentElection" formControlName="deemedDividentElection">
              </ap-checkbox>
              <p>Deemed Dividend Election: I, a shareholder on the first day of a PFIC's first tax year as a QEF that is a controlled foreign corporation (CFC),
                elect to treat an amount equal to my share of the post-1986 earnings and profits of the CFC as an excess distribution. Enter this amount on line
                15e of Part V. If the excess distribution is greater than zero, also complete line 16 of Part V</p>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'electionToRecognizeGain'"
                           [(ngModel)]="electionToRecognizeGain" formControlName="electionToRecognizeGain">
              </ap-checkbox>
              <p>Election To Recognize Gain on Deemed Sale of PFIC: I, a shareholder of a former PFIC or a PFIC to which section 1297(d) applies, elect to treat
                as an excess distribution the gain recognized on the deemed sale of my interest in the PFIC, or, if I qualify, my share of the PFIC's post-1986
                earnings and profits deemed distributed, on the last day of its last tax year as a PFIC under section 1297(a). Enter gain on line 15f of Part
                V.</p>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'deemedDividendElectionWrtSection1297'"
                           [(ngModel)]="deemedDividendElectionWrtSection1297" formControlName="deemedDividendElectionWrtSection1297">
              </ap-checkbox>
              <p>Deemed Dividend Election With Respect to a Section 1297(e) PFIC. I, a shareholder of a section 1297(e) PFIC, within the meaning of Regulations
                section 1.1297-3(a), elect to make a deemed dividend election with respect to the Section 1297(e) PFIC. My holding period in the stock of the
                Section 1297(e) PFIC includes the CFC qualification date, as defined in Regulations section 1.1297-3(d). Enter the excess distribution on line
                15e, Part V. If greater than zero, also complete line 16, Part V.</p>
            </div>
          </li>

          <li>
            <div class="d-flex">
              <ap-checkbox class="a-mx-10" [id]="'deemedDividendElectionWrtFormerPfic'"
                           [(ngModel)]="deemedDividendElectionWrtFormerPfic" formControlName="deemedDividendElectionWrtFormerPfic">
              </ap-checkbox>
              <p>Deemed Dividend Election With Respect to a Former PFIC. I, a shareholder of a former PFIC, within the meaning of Regulations section
                1.1298-3(a), elect to make a deemed dividend election with respect to the former PFIC. My holding period in the stock of the former PFIC
                includes the termination date, as defined in Regulations section 1.1298-3(d). Enter the excess distribution on line 15e, Part V. If excessive
                distribution is greater than zero, also complete line 16, Part V.</p>
            </div>
          </li>

        </ol>
      </div>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part III&nbsp;&nbsp;</strong>
          <strong>Income From a Qualified Electing Fund (QEF)</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="" formGroupName="partThree">
      <p class="m-0 pt-3">All QEF shareholders complete lines 6a through 7c. If you are making Election B, also complete lines 8a through 9c. (See
        instructions.)</p>
      <div class="section-content pt-2 ap-form-field-my-wrapper">
        <ol class="list-content list-spacing m-0" start="6">

          <li class="li-apdix-a" style="counter-reset: count 5;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter your pro rata share of the ordinary earnings of the QEF.</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': (electionToTreat || electionToExtend)}">
                <ap-form-field [inlineBlock]="true" [id]="'proRateShares'" class="number-field amount-input amount-input-container number-field-input"
                               [type]="'inputNumber'" [funType]="'typeTwo'" formControlName="proRateShares" [(ngModel)]="proRateShares"
                               (onChangeEvent)="updateValue($event, 'partThreeLine6', 0, 'proRateShares')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 5;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the portion of line 6a that is included in income under section 951 or that may be excluded under section 1293(g)</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': (electionToTreat || electionToExtend)}">
                <ap-form-field [inlineBlock]="true" [id]="'portionLine6a'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="portionLine6a" [(ngModel)]="portionLine6a"
                               (onChangeEvent)="updateValue($event, 'partThreeLine6', 0, 'portionLine6a')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 5;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Subtract line 6b from line 6a. Enter this amount on your tax return as ordinary income</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ OrdinaryIncome }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-a" style="counter-reset: count 6;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter your pro rata share of the total net capital gain of the QEF</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': (electionToTreat || electionToExtend)}">
                <ap-form-field [inlineBlock]="true" [id]="'proRateSharesNetGain'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="proRateSharesNetGain" [(ngModel)]="proRateSharesNetGain"
                               (onChangeEvent)="updateValue($event, 'partThreeLine7', 0, 'proRateSharesNetGain')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 6;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the portion of line 7a that is included in income under section 951 or that may be excluded under section 1293(g)</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': (electionToTreat || electionToExtend)}">
                <ap-form-field [inlineBlock]="true" [id]="'portionLine7a'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="portionLine7a" [(ngModel)]="portionLine7a"
                               (onChangeEvent)="updateValue($event, 'partThreeLine7', 0, 'portionLine7a')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 6;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Subtract line 7b from line 7a. This amount is a net long-term capital gain. Enter this amount in Part III of the Schedule D used for your
                  income tax return.(See instructions.)</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ NetCapitalGain }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-a" style="counter-reset: count 7;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Add lines 6c and 7c</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ Line6cAnd7cSum }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 7;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the total amount of cash and the fair market value of other property distributed or deemed distributed to you during the tax year of
                  the QEF. (See instructions)</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToExtend}">
                <ap-form-field [inlineBlock]="true" [id]="'cashPropertyDeemedDistributedCash'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="cashPropertyDeemedDistributedCash" [(ngModel)]="cashPropertyDeemedDistributedCash"
                               (onChangeEvent)="updateValue($event, 'partThreeLine8', 0, 'cashPropertyDeemedDistributedCash')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 7;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the portion of line 8a not already included in line 8c that is attributable to shares in the QEF that you disposed of, pledged, or otherwise
                  transferred during the tax year</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToExtend}">
                <ap-form-field [inlineBlock]="true" [id]="'portionLine8c'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="portionLine8c" [(ngModel)]="portionLine8c"
                               (onChangeEvent)="updateValue($event, 'partThreeLine8', 0, 'portionLine8c')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-d" style="counter-reset: count 7;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Add lines 8b and 8c</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ Line8bAnd8cSum }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-e" style="counter-reset: count 7;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Subtract line 8d from line 8a, and enter the difference (if zero or less, enter amount in brackets)
                  Important: If line 8e is greater than zero, and no portion of line 6a or 7a is includible in income under section 951, you may make Election B
                  with respect to the amount on line 8e</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ Line8dAnd8aSubt }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-a" style="counter-reset: count 8;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the total tax for the tax year. (See instructions.)</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToExtend}">
                <ap-form-field [inlineBlock]="true" [id]="'totalTax'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="totalTax" [(ngModel)]="totalTax"
                               (onChangeEvent)="updateValue($event, 'partThreeLine9', 0, 'totalTax')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 8;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Enter the total tax for the tax year determined without regard to the amount entered on line 8e.</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToExtend}">
                <ap-form-field [inlineBlock]="true" [id]="'totalTaxWithoutRegard'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="totalTaxWithoutRegard" [(ngModel)]="totalTaxWithoutRegard"
                               (onChangeEvent)="updateValue($event, 'partThreeLine9', 0, 'totalTaxWithoutRegard')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 8;">
            <div class="row pl-3">
              <div class="col-md-10">
                <p>Subtract line 9b from line 9a. This is the deferred tax, the time for payment of which is extended by making Election B. (See
                  instructions.)</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ Line9bAnd9aSubt }}</span>
              </div>
            </div>
          </li>

        </ol>
      </div>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part IV&nbsp;&nbsp;</strong>
          <strong>Gain or (Loss) From Mark-to-Market Election (See instructions.)</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="" formGroupName="partFour">
      <div class="section-content ap-form-field-my-wrapper">
        <ol class="list-content list-spacing m-0" start="10">

          <li class="li-apdix-a" style="counter-reset: count 9;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter the fair market value of your PFIC stock at the end of the tax year.</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToMarkToMarket}">
                <ap-form-field [inlineBlock]="true" [id]="'fairMarketValue'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="fairMarketValue" [(ngModel)]="fairMarketValue"
                               (onChangeEvent)="updateValue($event, 'partFourLine10', 0, 'fairMarketValue')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 9;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter your adjusted basis in the stock at the end of the tax year</p>
              </div>

              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'adjustedBasisStock'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="adjustedBasisStock" [(ngModel)]="adjustedBasisStock"
                               (onChangeEvent)="updateValue($event, 'partFourLine10', 0, 'adjustedBasisStock')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 9;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Subtract line 10b from line 10a. If a gain, do not complete lines 11 and 12. Include this amount as ordinary income on your tax return. If a
                  loss, go to line 11.</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ OrdinaryIncomeOnTaxReturn }}</span>
              </div>
            </div>
          </li>

          <li>
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter any unreversed inclusions (as defined in section 1296(d))</p>
              </div>
              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToMarkToMarket}">
                <ap-form-field [inlineBlock]="true" [id]="'unreversedInclusion'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="unreversedInclusion" [(ngModel)]="unreversedInclusion">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li>
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter the loss from line 10c, but only to the extent of unreversed inclusions on line 11. Include this amount as an ordinary loss on your tax
                  return</p>
              </div>
              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'lossLine10c'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="lossLine10c" [(ngModel)]="lossLine10c">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li>
            <b class="pl-4">If you sold or otherwise disposed of any section 1296 stock (see instructions) during the tax year:</b>
          </li>

          <li class="li-apdix-a mt-2" style="counter-reset: count 12;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p> Enter the fair market value of the stock on the date of sale or disposition</p>
              </div>

              <div class="col-md-2 p-0" [ngClass]="{'required-field': electionToMarkToMarket}">
                <ap-form-field [inlineBlock]="true" [id]="'fairMarketDispositionValue'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="fairMarketDispositionValue" [(ngModel)]="fairMarketDispositionValue"
                               (onChangeEvent)="updateValue($event, 'partFourLine13', 0, 'fairMarketDispositionValue')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 12;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter the adjusted basis of the stock on the date of sale or disposition</p>
              </div>

              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'adjustedBasisDispositionStock'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="adjustedBasisDispositionStock" [(ngModel)]="adjustedBasisDispositionStock"
                               (onChangeEvent)="updateValue($event, 'partFourLine13', 0, 'adjustedBasisDispositionStock')">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 12;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Subtract line 13b from line 13a. If a gain, do not complete line 14. Include this amount as ordinary income on your tax return. If a loss, go
                  to line 14</p>
              </div>

              <div class="col-md-2 p-0 text-align-end">
                <span>{{ Line13bAnd13aSubt }}</span>
              </div>
            </div>
          </li>

          <li class="li-apdix-a" style="counter-reset: count 13;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p> Enter any unreversed inclusions (as defined in section 1296(d))</p>
              </div>

              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'unreversedInclusionSection1296'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="unreversedInclusionSection1296" [(ngModel)]="unreversedInclusionSection1296">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-b" style="counter-reset: count 13;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter the loss from line 13c, but only to the extent of unreversed inclusions on line 14a. Include this amount as an ordinary loss on your
                  tax return. If the loss on line 8c exceeds unreversed inclusions on line 14a, complete line 14c</p>
              </div>

              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'lossLine13c'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="lossLine13c" [(ngModel)]="lossLine13c">
                </ap-form-field>
              </div>
            </div>
          </li>

          <li class="li-apdix-c" style="counter-reset: count 13;">
            <div class="row pl-4">
              <div class="col-md-10">
                <p>Enter the amount by which the loss on line 13c exceeds unreversed inclusions on line 14a. Include this amount on your tax return according to
                  the rules generally applicable for losses provided elsewhere in the Code and regulations
                  Note: See instructions in case of multiple dispositions.
                </p>
              </div>

              <div class="col-md-2 p-0">
                <ap-form-field [inlineBlock]="true" [id]="'lossLine13cExceedInclusion'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                               [funType]="'typeTwo'" formControlName="lossLine13cExceedInclusion" [(ngModel)]="lossLine13cExceedInclusion">
                </ap-form-field>
              </div>
            </div>
          </li>

        </ol>
      </div>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part V&nbsp;&nbsp;</strong>
          <strong>Distributions From and Dispositions of Stock of a Section 1291 Fund</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="tab-section" formGroupName="partFive">
      <p class="pt-3">Complete a separate Part IV for each excess distribution</p>
      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
                 (onBtnClick)="addTab('partFive')">
        <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
      </ap-button>

      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
                 [btnSize]="'small'" [isDisabled]="part5Tabs.length > 1 && part5Selected.value === 0" (onBtnClick)="removeTab(part5Selected.value, 'partFive')">
        <span class="appkiticon a-btn-icon icon-minus-fill"></span> Remove
      </ap-button>

      <mat-tab-group [selectedIndex]="part5Selected.value" (selectedIndexChange)="selectTab($event, 'partFive')" animationDuration="0ms">
        <mat-tab *ngFor="let tab of part5Tabs; let index = index" [label]="tab.tab">
          <div class="tab-section-content section-content ap-form-field-my-wrapper pl-4">

            <ol class="list-content m-0" start="15">

              <li class="li-apdix-a" style="counter-reset: count 14;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Enter your total distributions from the section 1291 fund during the current tax year with respect to the applicable stock. If the
                      holding period of the stock began in the current tax year, see instructions</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'totalDistribution'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="totalDistribution" [(ngModel)]="totalDistribution"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine15', index, 'totalDistribution');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-b" style="counter-reset: count 14;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Enter the total distributions (reduced by the portions of such distributions that were excess distributions but not included in income
                      under section 1291(a)(1)(B)) made by the fund with respect to the applicable stock for each of the 3 years preceding the current tax year
                      (or if shorter, the portion of the shareholder's holding period before the current tax year)</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'totalDistributionByFund'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="totalDistributionByFund" [(ngModel)]="totalDistributionByFund"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine15', index, 'totalDistributionByFund');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-c" style="counter-reset: count 14;">
                <div class="row mb-2 pl-2">
                  <p class="col-md-12 mb-2">Divide line 15b by 3. Enter number of preceding tax years if less than 3 years (See instructions)</p>
                  <div class="col-md-3 d-flex">
                    <ap-form-field [inlineBlock]="true" [id]="'precedingTaxYear'" class="number-field no-label amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="precedingTaxYear" [(ngModel)]="precedingTaxYear"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine15', index, 'precedingTaxYear');">
                    </ap-form-field>
                  </div>
                  <div class="col-md-3"></div>
                  <div class="col-md-3"></div>
                  <div class="col-md-3 p-0 text-align-end">
                    <span>{{ Divide15bBy3 }}</span>
                  </div>
                </div>
              </li>

              <li class="li-apdix-d" style="counter-reset: count 14;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Multiply line 15c by 125% (1.25)</p>
                  </div>

                  <div class="col-md-2 p-0 text-align-end">
                    <span>{{ Multiply15cBy125percent }}</span>
                  </div>
                </div>
              </li>

              <li class="li-apdix-e" style="counter-reset: count 14;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Subtract line 15d from line 15a. This amount, if more than zero, is the excess distribution with respect to the applicable stock. If zero
                      or less and you did not dispose of stock during the tax year, do not complete the rest of Part V. See instructions if you received more
                      than one distribution during the current tax year. Also, see instructions for rules for reporting a nonexcess distribution on your income
                      tax return</p>
                  </div>

                  <div class="col-md-2 p-0 text-align-end">
                    <span>{{ Subt15dFrom15a }}</span>
                  </div>
                </div>
              </li>

              <li class="li-apdix-f" style="counter-reset: count 14;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Enter gain or loss from the disposition of stock of a section 1291 fund or former section 1291 fund. If a gain, complete line 16. If a
                      loss, show it in brackets and do not complete line 16</p>
                  </div>

                  <div class="col-md-2 p-0" [ngClass]="{'required-field': (deemedSalesElection || electionToRecognizeGain)}">
                    <ap-form-field [inlineBlock]="true" [id]="'gainOrLossDistributionSection1291'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="gainOrLossDistributionSection1291" [(ngModel)]="gainOrLossDistributionSection1291"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine15', index, 'gainOrLossDistributionSection1291');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-a" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-12">
                    <p class="m-0">Attach a statement for each distribution and disposition. Show your holding period for each share of stock or block of shares held.
                      Allocate the excess distribution to each day in you holding period. Add all amounts that are allocated to days in each tax year</p>
                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="a-textarea-box a-field-length-validation form-inlines">
                      <ap-form-field [inlineBlock]="true" [maxLength]="100" [withNumberValidation]="true"
                                     [type]="'textarea'" formControlName="stmtForDistAndDisp" [(ngModel)]="stmtForDistAndDisp" [withButton]="true"
                                     (onChangeEvent)="updateValue($event, 'partFiveLine16', index, 'stmtForDistAndDisp');">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </li>

              <li class="li-apdix-b" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Enter the total of the amounts determined in line 16a that are allocable to the current tax year and tax years before the foreign
                      corporation became a PFIC (pre-PFIC tax years). Enter these amounts on your income tax return as other income.</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'totalAmountLine16a'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="totalAmountLine16a" [(ngModel)]="totalAmountLine16a"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine16', index, 'totalAmountLine16a');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-c" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Enter the aggregate increases in tax (before credits) for each tax year in your holding period (other than the current tax year and
                      pre-PFIC years). (See instructions.)</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'aggIncreaseInTax'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="aggIncreaseInTax" [(ngModel)]="aggIncreaseInTax"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine16', index, 'aggIncreaseInTax');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-d" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Foreign tax credit. (See instructions.)</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'ftc'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="ftc" [(ngModel)]="ftc"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine16', index, 'ftc');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li class="li-apdix-e" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Subtract line 16d from line 16c. Enter this amount on your income tax return as "additional tax". (See instructions.)</p>
                  </div>

                  <div class="col-md-2 p-0 text-align-end">
                    <span>{{ Subt16dFrom16c }}</span>
                  </div>
                </div>
              </li>

              <li class="li-apdix-f" style="counter-reset: count 15;">
                <div class="row pl-2">
                  <div class="col-md-10">
                    <p>Determine interest on each net increase in tax determined on line 16e using the rates and methods of section 6621. Enter the aggregate
                      amount of interest here. (See instructions.)</p>
                  </div>

                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'interestTaxNetIncrease'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="interestTaxNetIncrease" [(ngModel)]="interestTaxNetIncrease"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine16', index, 'interestTaxNetIncrease');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

            </ol>

          </div>
        </mat-tab>
      </mat-tab-group>

    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part VI&nbsp;&nbsp;</strong>
          <strong>Status of Prior Year Section 1294 Elections and Termination of Section 1294 Elections</strong>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="tab-section" formGroupName="partSix">
      <p class="pt-3">Complete a separate column for each outstanding election. Complete lines 17 through 20 to report the status of outstanding prior year
        section 1294 elections. Complete lines 21 through 24 only if a section 1294 election is terminated in the current year. Complete lines 25 and 26 only if
        there is a partial termination of a section 1294 election in the current tax year.</p>
      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
                 (onBtnClick)="addTab('partSix')">
        <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
      </ap-button>

      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
                 [btnSize]="'small'" [isDisabled]="part6Tabs.length > 1 && part6Selected.value === 0" (onBtnClick)="removeTab(part6Selected.value, 'partSix')">
        <span class="appkiticon a-btn-icon icon-minus-fill"></span> Remove
      </ap-button>

      <mat-tab-group [selectedIndex]="part6Selected.value" (selectedIndexChange)="selectTab($event, 'partSix')" animationDuration="0ms">
        <mat-tab *ngFor="let tab of part6Tabs; let index = index" [label]="tab.tab">
          <div class="tab-section-content section-content ap-form-field-my-wrapper pl-4">

            <ol class="section-content list-content m-0 pb-0" start="17">
              <li style="counter-reset: count 16;">
                <div class="row">
                  <div class="col-md-10">
                    <p>Tax Year of outstanding election</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-selector class="ap-form-field-my-wrapper field-full-width select-dropdown select-dropdown-container mt-0"
                                 [id]="'electionYear'" [items]="taxYearsOE" [type]="'SingleSelector'" [searchMethod]="'inline'"
                                 (onSelectedChange)="onOutstandingElectionTYChanged($event, index)" formControlName="electionYear">
                    </ap-selector>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Undistributed earnings to which the election relates</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'unDistEarnings'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="unDistEarnings" [(ngModel)]="unDistEarnings"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine18', index, 'unDistEarnings');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Deferred Tax</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'deferredTax'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="deferredTax" [(ngModel)]="deferredTax"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine19', index, 'deferredTax');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Interest accrued on deferred tax (line 19) as of the filing date</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'deferredTaxInterest'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="deferredTaxInterest" [(ngModel)]="deferredTaxInterest"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine20', index, 'deferredTaxInterest');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Event terminating election</p>
                  </div>
                  <div class="col-md-2 pl-0 pr-0 pb-2">
                    <ap-form-field [inlineBlock]="true" class="text-box amount-input-container" [id]="'evtTermElection'" [type]="'input'"
                                   formControlName="evtTermElection" [(ngModel)]="evtTermElection"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine21',  index, 'evtTermElection');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Earnings distributed or deemed distributed during the tax year</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'earnDistOrDeemed'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="earnDistOrDeemed" [(ngModel)]="earnDistOrDeemed"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine22', index, 'earnDistOrDeemed');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Deferred tax due with this return</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'deferredTaxDue'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="deferredTaxDue" [(ngModel)]="deferredTaxDue"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine23', index, 'deferredTaxDue');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Accrued interest due with this return</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'accrdInterestDue'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="accrdInterestDue" [(ngModel)]="accrdInterestDue"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine24', index, 'accrdInterestDue');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Deferred tax outstanding after partial termination of election</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'deferredTaxAftTerm'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="deferredTaxAftTerm" [(ngModel)]="deferredTaxAftTerm"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine25', index, 'deferredTaxAftTerm');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

              <li>
                <div class="row">
                  <div class="col-md-10">
                    <p>Interest accrued after partial termination of election</p>
                  </div>
                  <div class="col-md-2 p-0">
                    <ap-form-field [inlineBlock]="true" [id]="'accrdInterestAftTerm'" class="number-field amount-input amount-input-container number-field-input" [type]="'inputNumber'"
                                   [funType]="'typeTwo'" formControlName="accrdInterestAftTerm" [(ngModel)]="accrdInterestAftTerm"
                                   (onChangeEvent)="updateValue($event, 'partFiveLine26', index, 'accrdInterestAftTerm');">
                    </ap-form-field>
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>

    <div class="leid-list d-flex mt-2">
      <span>LEIDs: </span>
      <div>
        <span *ngIf="shareholder" class="template-color-blue">{{ shareholder.LEID }}</span>
      </div>
    </div>

  </div>
</div>

<ap-modal #pficLegalEntityDatabase *ngIf="showPficLegalEntityDBModal" appModalId="pfic-entity-db">
  <div #modalRef2 slot="content" (click)="$event.stopPropagation();" style="height: 100%; width: 100%; padding-top: 40px;">
    <div class="modal-dialog" style="max-width: 31.25rem; margin: 0 auto;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">Is PFIC in Legal Entity Database?</span>
          </div>
        </div>
        <div class="a-modal-body">
          <p>PFIC Form 8621 prepared by 3rd party should be attached as a PDF</p>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
                  (click)="onpficLegalEntityDatabaseOK('pfic-entity-db')">OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>

<ng-template #tooltip1>
  <div class="tooltip-inner a-p-10 d-block a-min-width-360">
    <div class="customize-tooltip-text font-weight-normal text-left">
      Who may make the election. A U.S. person that is a shareholder of a corporation that fails to qualify as a qualifying insurance corporation (QIC) (as
      defined in section 1297(f)(1)) solely because its applicable insurance liabilities make up 25% or less of its total assets may elect to treat the stock as
      stock of a qualifying insurance corporation if: 1. The corporations applicable insurance liabilities make up at least 10% of its total assets; and 2.
      Based on the applicable facts and circumstances, the corporation is predominantly engaged in an insurance business, and its failure to satisfy the 25%
      threshold is due solely to runoff-related or rating-related circumstances involving such insurance business. The preamble to the proposed regulations
      provides that until the regulations are published as final, an electing U.S. person may continue to follow the instructions for Form 8621, which require
      the U.S. person to check a box regarding the qualifying insurance corporation exception, and provide the name, address, and taxpayer ID number for the
      foreign corporation. A U.S. person need not complete any other part of Form 8621 if the U.S. person is only filing the form to make the election.
    </div>
  </div>
</ng-template>
