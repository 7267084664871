import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { EntityChartMapCleanUpService } from './entity-chart-map-clean-up.service';

@Component({
  selector: 'gtw-entity-chart-map-clean-up',
  templateUrl: './entity-chart-map-clean-up.component.html',
  styleUrls: ['./entity-chart-map-clean-up.component.scss']
})
export class EntityChartMapCleanUpComponent implements OnInit, AfterViewInit {

  @Output('request-grid-update') requestGridUpdate = new EventEmitter<string>();

  public secondaryConfig = {
    isSecondary: true
  }

  public primaryConfig = {
    isPrimary: true
  }

  public floatingConfig = {
    isFloating: true
  }
  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
  gridData: GridType[] = [];

  isLoading = true;
  enableButton: boolean = false;

  clob_data: GridData[] = [];

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string; } | undefined;

  @Input('transfer-data')
  set _transfer_data(transferData: string) {
    try {

      let params = JSON.parse(transferData);

      if (params != undefined) {
        this.gridData = JSON.parse(params.gridData);
        this.baseURLs = JSON.parse(params.baseURLs);
        this.baseGlobalParams = JSON.parse(params.globalParams);
        this.getEntityData();

      }
    } catch (e) {
      console.log("Params not stable. Still waiting to get resolved.");
    }
  }

  allEntityDetails: GridData[] = [];
  optionsTransCount: Array<TransCountType> = [];

  constructor(private entityChartMapCleanUpService: EntityChartMapCleanUpService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  expandRow(index: number) {
    this.allEntityDetails.forEach((elem: GridData) => {
      if (this.allEntityDetails[index].INPUT_LE_KEY == elem.INPUT_LE_KEY) {
        elem.display = true;
      }
    });
    this.allEntityDetails[index].PLUS = false;
  }

  collapseRow(index: number) {
    this.allEntityDetails.forEach((elem: GridData, idx: number) => {
      if (index !== idx && this.allEntityDetails[index].INPUT_LE_KEY == elem.INPUT_LE_KEY) {
        elem.display = false;
      }
    });
    this.allEntityDetails[index].PLUS = true;
  }

  getEntityData(): void {
    let params = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key
    }
    let clob_data: GridData[] = [];
    this.gridData.forEach((current: GridType) => {
      let temp = {
        LE_KEY: current.data.LE_KEY,
        LE_TYPE: current.data.LE_TYPE
      }
      clob_data.push(temp);
    })

    this.entityChartMapCleanUpService.getEntityDetails(this.baseURLs.api, params, clob_data).subscribe((data) => {
      let allData: GridData[] = data.jsonObject;
      if (allData) {
        let allLEKeys: { LE_KEY?: string, AGG_COMBINATION_KEY?: string }[] = [];
        allData.forEach((value: GridData, index: number) => {

          allLEKeys.push({ LE_KEY: value.LE_KEY, AGG_COMBINATION_KEY: value.AGG_COMBINATION_KEY });
          if (value.IS_PARENT === 'N') {
            let msg = '';
            if (value.IS_CKB_DISABLED === 'Y') {
              msg = 'This entity cannot be selected for deletion as it is parent to another entity!';
            }
            else if (value.TP_KEY_DTLS_COUNT != 0 || value.PSHIP_DTLS_COUNT != 0) {
              msg = 'Partnership/Trading Partner Details found, cannot delete!';
            }
            else {
              msg = 'This entity can be selected for deletion!';
            }
            this.allEntityDetails.push({ ...value, display: false, message: msg, PLUS: false });
          } else {
            this.allEntityDetails.push({ ...value, display: true, message: 'Click plus to expand', PLUS: true });
          }
        });
        this.allEntityDetails.sort((a: GridData, b: GridData) => {
          if (a.INPUT_LE_KEY == b.INPUT_LE_KEY) {
            return b.display - a.display;
          }
          return a.INPUT_LE_KEY > b.INPUT_LE_KEY ? 1 : -1;
        })
        this.entityChartMapCleanUpService.getTransCount(this.baseURLs.api, this.baseGlobalParams?.tax_year, this.baseGlobalParams?.scenario, this.baseGlobalParams?.jcd_key, allLEKeys).subscribe((data) => {
          this.optionsTransCount = data;

          this.isLoading = false;
        });
      }
      else {
        this.isLoading = false;
      }

    }, () => {
      console.log("Error getting data");
    });
  }

  cancel(): void {
    this.requestGridUpdate.emit('');
  }

  selectEntity(event: EventEmitter<Object> | any, item: GridData) {
    if (event.srcElement.checked) {
      this.clob_data.push(item);
    }
    else {
      this.clob_data.forEach((data: GridData, index: number) => {
        if (item.agg_combination_key == data.AGG_COMBINATION_KEY && item.le_key == data.LE_KEY) {
          this.clob_data.splice(index, 1);
        }
      })
    }

    if (this.clob_data.length > 0) {
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }
  }

  deleteEntities(): void {
    this.isLoading = true;
    let params = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      scenarioCode: this.baseGlobalParams?.scenarioCode,
    }

    this.entityChartMapCleanUpService.deleteEntities(this.baseURLs.api, params, this.clob_data).subscribe((data) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.isLoading = false;
      }
      else {
        console.log("Error while saving");
        this.isLoading = false;
      }

    }, () => {
      console.log("Error while saving");
    });
  }



}
export type GridData = any;
// export type GridData = {

//   display?: any;
//   SOURCE_SYSTEM_FLAG?: string;
//   LEID?: any;
//   LE_KEY?: any;
//   CDR_NO?: any;
//   LE_TYPE?: any;
//   ME_CODE?: string;
//   REPORTING_PERIOD?: string;
//   PARENT_LE_KEY?: string;
//   PARENT_TRANS_COUNT?: any;
//   PARENT_TP_KEY_DTLS_COUNT?: any;
//   PARENT_PSHIP_DTLS_COUNT?: any;
//   CHILD_LE_KEY?: string;
//   CHILD_LE_TYPE?: string;
//   CHILD_LEID?: string;
//   CHILD_CDR_NO?: string;
//   CHILD_ME_CODE?: string;
//   CHILD_RPT_PERIOD?: string;
//   CHILD_TRANS_COUNT?: string;
//   CHILD_TP_KEY_DTLS_COUNT?: string;
//   CHILD_PSHIP_DTLS_COUNT?: string;
//   IS_CHILD?: any;
//   message?: string;
//   PLUS?: boolean;
// }

export type GridType = {
  data: GridData;
}

export type TransCountType = {
  LE_KEY: string;
  TRANS_DETAILS: string;
  TRANS_TYPE: string;
  TRANS_TYPE_COUNT: number;
  TRANS_TYPE_DESC: string;
  TRANS_TYPE_KEY: number;
  AGG_COMBINATION_KEY: string;
}
