import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loaded = false;

  @Input('loaded')
  set _loaded(_loaded:boolean){
    this.loaded = _loaded;
  }

  constructor() {};

  ngOnInit(): void {};

}
