<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div>
    <dst-template-header (formOptionChanged)="onFormOptionChanged($event)" (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <!-- Part I  U.S. Transferor Information -->
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;Part I&nbsp;&nbsp;U.S. Transferor Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="section-content">
      <div style="margin: 15px 0 20px 71px;">
        <dst-template-leid-selector [label]="'Please specify the LEID of the Transferor'" [labelId]="'100'"
                                    (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field ent-label">
        </dst-template-leid-selector>
        <div class="entity-details">
          <span style="width: 270px;">Name of Transferor:</span><span class="template-color-blue">{{!transferor ? '' : transferor.ENTITY}}</span>
        </div>
        <div class="entity-details">
          <span style="width: 270px;">Identifying number:</span><span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ? transferor.EIN : 'N/A')}}</span>
        </div>
        <div class="entity-details" *ngIf="transferor && transferor.EIN === undefined">
          <span class="ed-label" style="width: 270px;">EIN Missing Reason:</span>
          <div class="d-inline-block">
            <ap-selector [placeholder]="'** Please select the missing reason **'" [items]="EINMissingReason" [type]="'Dropdown'"
                         (onSelectedChange)="onTransferorEINMissingChange($event)" required>
            </ap-selector>
          </div>
        </div>
      </div>
      <ol class="main-section li-width-span-100 valign-top">
        <!-- 1 -->
        <li>
          <div class="row">
            <div class="col-md-8">
              Is the transferee a specified 10%-owned foreign corporation that is not a controlled foreign corporation?
              <span class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'" [isWhite]="true"></span>
            </div>
            <div class="col-md-4" style="padding-left: 23px;">
              <ap-radio [name]="'radio-group-1'" [text]="'Yes'" [checked]="transfereeForeignCorp" (onCheck)="check1(true)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-1'" [text]="'No'" [checked]="transfereeForeignCorp === undefined ? false : !transfereeForeignCorp"
                        (onCheck)="check1(false)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 2 -->
        <li>
          <span class="d-inline-block" style="padding-left: 15px;">If the transferor was a corporation, complete questions 2a through 2d.</span>
          <ol class="inner-list-alp" style="padding-left: 21px;">
            <!-- a -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  If the transfer was a section 361(a) or (b) transfer, was the transferor controlled (under section
                  368(c)) by 5 or fewer domestic corporations?
                  <span class="appkiticon icon-information-fill" [tooltip-container]="template2" [placement]="'right'" [isWhite]="true"></span>
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-2'" [text]="'Yes'" [checked]="Choice1a" (onCheck)="check2a(true)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-2'" [text]="'No'" [checked]="Choice1a === undefined ? false : !Choice1a" (onCheck)="check2a(false)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- b -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  Did the transferor remain in existence after the transfer?
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-3'" [text]="'Yes'" [checked]="Choice1b" (onCheck)="existenceCheck(true)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-3'" [text]="'No'" [checked]="Choice1b === undefined ? false : !Choice1b" (onCheck)="existenceCheck(false)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
              <div *ngIf="showControllingShareHolders" class="section-content" style="padding: 0; margin-left: 36px;">
                If not, list the controlling shareholder(s) and their identifying number(s):
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-3">
                    <ap-form-field [inlineBlock]="true" [label]="'Controlling shareholder'" [id]="'11'" [type]="'input'"
                                   [(ngModel)]="controllingShareholder" class="required-field heavy-label">
                    </ap-form-field>
                  </div>
                  <div class="col-md-3">
                    <ap-form-field [inlineBlock]="true" [label]="'Identifying number'" [id]="'11'" [type]="'input'"
                                   [(ngModel)]="identifyingNumber" class="heavy-label">
                    </ap-form-field>
                  </div>
                  <div class="col-md-3">
                    <ap-radio class="a-mx-10 required-field-radio" [name]="'radio-group-11'" [text]="'EIN'" [checked]="EinOrSsn === 'EIN'"
                              (onCheck)="checkEINorSSN('EIN')">
                    </ap-radio>
                    <ap-radio class="a-mx-10 required-field-radio" [name]="'radio-group-11'" [text]="'SSN'" [checked]="EinOrSsn === 'SSN'"
                              (onCheck)="checkEINorSSN('SSN')">
                    </ap-radio>
                  </div>
                </div>
                <br/>
                <div *ngIf="identifyingNumber === ''" style="padding-bottom: 10px;">
                  <div class="row">
                    <div class="col-md-2">
                      EIN Missing Reason:
                    </div>
                    <div class="col-md-2">
                      <ap-selector [items]="EINMissingReason1" [type]="'Dropdown'"
                                   [placeholder]="'** Please select the missing reason **'" (onSelectedChange)="missingReason1($event)"
                                   class="required-field-dropdown">
                      </ap-selector>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- c -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  If the transferor was a member of an affiliated group filing a consolidated return, was it the parent corporation?
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-4'" [text]="'Yes'" [checked]="Choice1c === true" (onCheck)="parentCoorporationCheck($event, true)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-4'" [text]="'No'" [checked]="Choice1c === false"
                            (onCheck)="parentCoorporationCheck($event, false)"
                            class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
              <div *ngIf="Choice1c === false" style="margin-left: 36px;">
                <span class="d-inline-block">
                  If not, list the name of the employer identification number (EIN) of the parent corporation:
                </span>
              </div>
              <div *ngIf="Choice1c === false" class="section-content" style="margin-left: 21px;">
                <div class="entity-details">
                  <span style="width: 270px;">Name of Parent Corporation</span>
                  <span>{{dcnName}}</span>
                </div>
                <div class="entity-details">
                  <span style="width: 270px;">EIN of Parent Corporation</span>
                  <span class="template-color-blue">{{dcnIDNum}}</span>
                </div>
              </div>
            </li>
            <!-- d -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  Have basis adjustments under section 367(a)(4) been made? <span
                  class="appkiticon icon-information-fill" [tooltip-container]="template3" [placement]="'right'"
                  [isWhite]="true"></span>
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-5'" [text]="'Yes'" [checked]="Choice1d" (onCheck)="check2d(true)" class="a-mx-10">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-5'" [text]="'No'" [checked]="Choice1d === undefined ? false : !Choice1d" (onCheck)="check2d(false)"
                            class="a-mx-10">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
          </ol>
        </li>
        <!-- 3 -->
        <li>
          <span class="d-inline-block" style="padding-left: 15px;">
            If the transferor was a partner in a partnership that was the actual transferor (but is not treated as such under section 367), complete questions 3a through 3d.
          </span>
          <ol class="inner-list-alp" style="padding-left: 22px;">
            <!-- a -->
            <li class="valign-base">
              <div class="d-inline-block" style="padding-left: 15px;">
                <dst-template-leid-selector class="label-adjust" [label]="'Please specify the LEID of the Partnership'" [labelId]="'101'"
                                            (onSelectedLEChanged)="onPartnershipChanged($event)">
                </dst-template-leid-selector>
              </div>
              <div class="entity-details label-adjust" style="margin-left: 36px;">
                <span>Name of Partnership:</span><span class="template-color-blue">{{!partnership ? '' : partnership.ENTITY}}</span>
              </div>
              <div class="entity-details label-adjust" style="margin-left: 36px;">
                <span>EIN of Partnership:</span><span class="template-color-blue">{{!partnership ? '' : (partnership.EIN ? partnership.EIN : 'N/A')}}</span>
              </div>
              <div class="entity-details" style="margin-left: 36px !important;" *ngIf="partnership && !partnership.EIN">
                <span style="width: 270px;">EIN Missing Reason:</span>
                <ap-selector class="required-field-dropdown"
                             [items]="EINMissingReason4" [type]="'Dropdown'" [placeholder]="'** Please select the missing reason **'"
                             (onSelectedChange)="missingReasonPart($event)">
                </ap-selector>
              </div>
            </li>
            <br>
            <!-- b -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  Did the partner pick up its pro rata share of gain on the transfer of partnership assets?
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-6'" [text]="'Yes'" [checked]="Choice2b" (onCheck)="check3b(true)" class="a-mx-10">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-6'" [text]="'No'" [checked]="Choice2b === undefined ? false : !Choice2b" (onCheck)="check3b(false)"
                            class="a-mx-10">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- c -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  Is the partner disposing of its entire interest in the partnership?
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-7'" [text]="'Yes'" [checked]="Choice2c" (onCheck)="check3c(true)" class="a-mx-10">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-7'" [text]="'No'" [checked]="Choice2c === undefined ? false : !Choice2c" (onCheck)="check3c(false)"
                            class="a-mx-10">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- d -->
            <li>
              <div class="row">
                <div class="col-md-8">
                  Is the partner disposing of an interest in a limited partnership that is regularly traded on an
                  established securities market?
                </div>
                <div class="col-md-4">
                  <ap-radio [name]="'radio-group-8'" [text]="'Yes'" [checked]="Choice2d" (onCheck)="check3d(true)" class="a-mx-10">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-8'" [text]="'No'" [checked]="Choice2d === undefined ? false : !Choice2d" (onCheck)="check3d(false)"
                            class="a-mx-10">
                  </ap-radio>
                </div>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <!-- Part II  Transferee Foreign Corporation Information -->
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;Part II&nbsp;&nbsp;Transferee Foreign Corporation Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="section-content" style="padding-bottom: 0;">
      <div class="d-flex">
        <div class="" [ngStyle]="{'width': showTransfereeManual ? '395px' : (showTransferee ? '428px': '')}">
          Is Foreign Corporation in Legal Entity Database?
        </div>
        <div class="">
          <ap-radio [name]="'radio-group-9'" [text]="'Yes'" [checked]="IsForeignCorpInGold" (onCheck)="checkTransferee($event)"
                    class="a-mx-10 required-field-radio">
          </ap-radio>
        </div>
        <div class="">
          <ap-radio class="a-mx-10 required-field-radio" [name]="'radio-group-9'" [text]="'No'"
                    [checked]="IsForeignCorpInGold === undefined ? false : !IsForeignCorpInGold"
                    (onCheck)="checkTransfereeManual($event)">
          </ap-radio>
        </div>
      </div>
      <!-- Transeree in Database -->
      <div *ngIf="showTransferee" class="section-content" style="padding-bottom: 0;">
        <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee'" [labelId]="'102'"
                                    (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field label-adjust-395">
        </dst-template-leid-selector>
        <ol class="inner-list-dec section-content-inner" style="margin-bottom: 0; padding-left: 0;counter-reset: count 3;" >
          <!-- 4 -->
          <li >
            <div class="entity-details d-inline-flex">
              <span>Name of the transferee:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ENTITY}}</span>
            </div>
          </li>
          <!-- 5 -->
          <li>
            <div class="entity-details d-inline-flex">
              <span>Identifying number, if any:</span><span class="template-color-blue">{{!transferee ? '' : (transferee.EIN ? transferee.EIN : 'N/A')}}</span>
            </div>
            <div class="entity-details" style="margin-left: 36px !important;" *ngIf="transferee && !transferee.EIN">
              <span>EIN Missing Reason:</span>
              <ap-selector class="required-field-dropdown"
                           [items]="EINMissingReason3" [type]="'Dropdown'" [placeholder]="'** Please select the missing reason **'"
                           (onSelectedChange)="missingReason3($event)">
              </ap-selector>
            </div>
          </li>
          <!-- 6 -->
          <li>
            <div class="entity-details d-inline-flex">
              <span>Address Line1:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ADDRESS1}}</span>
            </div>
            <div class="entity-details" style="padding-left: 21px;">
              <span>Address Line2:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ADDRESS2}}</span>
            </div>
            <div class="entity-details" style="padding-left: 21px;">
              <span>City:</span><span class="template-color-blue">{{!transferee ? '' : transferee.CITY}}</span>
            </div>
            <div class="entity-details" style="padding-left: 21px;">
              <span>Province or State:</span><span class="template-color-blue">{{!transferee ? '' : stateValue}}</span>
            </div>
            <div class="entity-details" style="padding-left: 21px;">
              <span>Country:</span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYNAME}}</span>
            </div>
            <div class="entity-details" style="padding-left: 21px;">
              <span>Postal Code:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ZIP}}</span>
            </div>
          </li>
          <!-- 7 -->
          <li>
            <div class="entity-details d-inline-flex">
              <span>Country of incorporation or organization:</span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYINC}}</span>
            </div>
          </li>
          <!-- 8 -->
          <li>
            <div class="entity-details d-inline-flex">
              <span>Foreign law characterization:</span><span class="template-color-blue">{{!transferee ? '' : transferee.TAXTYPE}}</span>
            </div>
          </li>
        </ol>
      </div>
      <!-- Transferee Not in Database -->
      <div *ngIf="showTransfereeManual">
        <ol class="inner-list-dec section-content-inner valign-top" style="margin-bottom: 0; padding-top: 15px; padding-left: 36px;counter-reset: count 3;" >
          <!-- 4 -->
          <li >
            <dst-custom-input class="required-field" [label]="'Name of transferee'" [maxLength]="150" [value]="name" (valChange)="name = $event;">
            </dst-custom-input>
          </li>
          <!-- 5 -->
          <li class="d-flex">
            <dst-custom-input id="custom-input-ein" [label]="'Identifying number, if any'" [maxLength]="9" [value]="id"
                              (valChange)="id = $event;"></dst-custom-input>
            <div class="d-inline-flex" *ngIf="id === ''">
              <span style="padding-right: 5px; width: 200px; text-align: end;">
                EIN Missing Reason:
              </span>
              <ap-selector id="ein-reason-dd-2" class="required-field-dropdown" [items]="EINMissingReason2" [type]="'Dropdown'"
                           [placeholder]="'** Please select the missing reason **'"
                           (onSelectedChange)="missingReason2($event)">
              </ap-selector>
            </div>
          </li>
          <!-- 6 -->
          <li>
            <dst-custom-input class="required-field" [label]="'Address Line1'" [maxLength]="35" [value]="address1" (valChange)="address1 = $event;">
            </dst-custom-input>
            <div class="custom-input-wrapper">
              <dst-custom-input [label]="'Address Line2'" [maxLength]="35" [value]="address2" (valChange)="address2 = $event;"></dst-custom-input>
            </div>
            <div class="custom-input-wrapper">
              <dst-custom-input [label]="'City'" [maxLength]="50" [value]="city" (valChange)="city = $event;"></dst-custom-input>
            </div>
            <div class="custom-input-wrapper">
              <dst-custom-input [label]="'Province or State'" [maxLength]="50" [value]="state" (valChange)="state = $event;"></dst-custom-input>
            </div>
            <div class="d-flex custom-sd-wrapper">
              <span style="width: 330px;">Country</span>
              <ap-selector class="required-field-dropdown field-full-width"
                           [items]="countryDropdown1" [type]="'SingleSelector'" [placeholder]="'** Select Country **'" [searchMethod]="'inline'"
                           (onSelectedChange)="selectCountry1($event)">
              </ap-selector>
            </div>
            <div class="custom-input-wrapper">
              <dst-custom-input [label]="'Postal Code'" [maxLength]="50" [value]="postal" (valChange)="postal = $event;"></dst-custom-input>
            </div>
          </li>
          <!-- 7 -->
          <li>
            <div class="d-inline-flex custom-sd-wrapper" style="padding-left: 15px;">
              <span style="width: 330px;">Country of incorporation or organization</span>
              <ap-selector class="required-field-dropdown field-full-width"
                           [items]="countryDropdown2" [type]="'SingleSelector'" [placeholder]="'** Select Country **'" [searchMethod]="'inline'"
                           (onSelectedChange)="selectCountry2($event)">
              </ap-selector>
            </div>
          </li>
          <!-- 8 -->
          <li>
            <dst-custom-input class="required-field" [label]="'Foreign law characterization'" [maxLength]="35" [value]="foreign"
                              (valChange)="foreign = $event;">
            </dst-custom-input>
          </li>
        </ol>
      </div>
      <ol class="inner-list-dec section-content-inner" style="padding-top: 5px !important;counter-reset: count 8;" [ngStyle]="{'padding-left': showTransfereeManual ? '36px' : ''}" >
        <!-- 9 -->
        <li style=" margin-top: 0;">
          <div class="d-inline-flex">
            <div class="entity-details">
              Is the transferee foreign corporation a controlled foreign corporation?
            </div>
            <ap-radio [name]="'radio-group-10'" [text]="'Yes'" [checked]="IsTransfereeForeignCorpCtrld" (onCheck)="check9(true)"
                      class="a-mx-10 required-field-radio" style="margin-top: 4px;">
            </ap-radio>
            <ap-radio [name]="'radio-group-10'" [text]="'No'" [checked]="IsTransfereeForeignCorpCtrld === undefined ? false : !IsTransfereeForeignCorpCtrld"
                      (onCheck)="check9(false)"
                      class="a-mx-10 required-field-radio" style="margin-top: 4px;">
            </ap-radio>
          </div>
        </li>
      </ol>
    </div>
    <!-- Part III  Information Regarding Transfer of Property (see instructions) -->
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;Part III&nbsp;&nbsp;Information Regarding Transfer of Property (see instructions)</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- Tabs -->
    <div class="tab-section section-content">
      <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
                 (onBtnClick)="addTab()">
        <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
      </ap-button>
      <ap-button class="btn-plus-minus" style="margin-left: 14px;" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
                 [btnSize]="'small'" [isDisabled]="selected.value === 0" (onBtnClick)="removeTab(selected.value)">
        <span class="appkiticon a-btn-icon icon-minus-fill"></span>
        Remove
      </ap-button>
      <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selectTab($event)" animationDuration="0">
        <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab.label">
          <div class="tab-section-content pb-0">
            <ol class="inner-list-alp valign-top">
              <!--  -->
              <li class="hide-ll">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Type of Property
                  </span>
                  <ap-selector [items]="tab.propertyTypeOptions" [type]="'Dropdown'"
                               [placeholder]="'** Select Property Type **'" class="required-field-dropdown" style="min-width: 400px;"
                               (onSelectedChange)="propertyTypeChange($event, index)">
                  </ap-selector>
                </div>
              </li>
              <!-- a -->
              <li class="valign-base" style="counter-reset: count 0;">
                <div class="d-inline-flex" style="align-items: center;">
                  <span class="property-desc-text">
                    Date of transfer
                  </span>
                  <ap-date-picker class="required-field" format="yyyy-MM-DD" type="singleCalendar" size="medium"
                                  [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true" [(ngModel)]="tab.dateObj"
                                  (click)="onDPOpen()" (onChange)="dateChange($event, index)">
                  </ap-date-picker>
                </div>
              </li>
              <!-- b -->
              <li style="width: calc(100% - 21px);">
                <div class="d-inline-flex" style="width: inherit;">
                  <span class="property-desc-text">
                    Description of Property
                    <span class="appkiticon icon-information-fill" [tooltip-container]="template5" [placement]="'right'" [isWhite]="true"></span>
                  </span>
                  <dst-custom-input class="custom-input-f1" style="padding-left: 10px;"
                                    [ngClass]="tab.propertyType && tab.propertyType !== 'cash' ? 'required-field' : ''"
                                    [type]="'textarea'" [label]="''" [maxLength]="100" [value]="tab.description"
                                    (valChange)="onValueChange($event, index, 'property')">
                  </dst-custom-input>
                </div>
              </li>
              <!-- c (type !== propdescribed367d4) -->
              <li *ngIf="tab.propertyType !== 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Fair market value on date of transfer (For e-filing purposes, must be in USD)
                  </span>
                  <ap-form-field [label]="''" [id]="index+'fair'" [(ngModel)]="tab.fairMarketVal"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- d (type !== cash && type !== propdescribed367d4) -->
              <li *ngIf="tab.propertyType !== 'cash' && tab.propertyType !== 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Cost or other basis
                  </span>
                  <ap-form-field [label]="''" [id]="index+'cost'" [(ngModel)]="tab.cost"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- e (type !== cash && type !== propdescribed367d4) -->
              <li *ngIf="tab.propertyType !== 'cash' && tab.propertyType !== 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Gain recognized on transfer
                  </span>
                  <ap-form-field [label]="''" [id]="index+'gain'" [(ngModel)]="tab.gain"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- c (type === propdescribed367d4) -->
              <li *ngIf="tab.propertyType === 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Useful life
                  </span>
                  <ap-form-field [label]="''" [id]="index+'useful'" [(ngModel)]="tab.useful"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- d (type === propdescribed367d4) -->
              <li *ngIf="tab.propertyType === 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Arm's length price on date of transfer
                  </span>
                  <ap-form-field [label]="''" [id]="index+'armsLength'" [(ngModel)]="tab.armLength"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- e (type === propdescribed367d4) -->
              <li *ngIf="tab.propertyType === 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Cost or other basis
                  </span>
                  <ap-form-field [label]="''" [id]="index+'cost'" [(ngModel)]="tab.cost"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
              <!-- f (type === propdescribed367d4) -->
              <li *ngIf="tab.propertyType === 'propdescribed367d4'">
                <div class="d-inline-flex">
                  <span class="property-desc-text">
                    Income inclusion for year of transfer (See instructions)
                  </span>
                  <ap-form-field [label]="''" [id]="index+''" [(ngModel)]="tab.income"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [minNum]="0">
                  </ap-form-field>
                </div>
              </li>
            </ol>
          </div>
        </mat-tab>
      </mat-tab-group>

      <ol class="main-section section-content li-width-span-100 valign-top" style="padding-left: 36px; padding-right: 0; margin-bottom: 0;counter-reset: count 9;" >
        <!-- 10 -->
        <li class="w-100" >
          <div class="row" style="width: inherit;">
            <div class="col-md-8">
              <span>Was cash the only property transferred?</span>
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-12'" [text]="'Yes'" [checked]="cashonlytransfered" (onCheck)="checkSection($event)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-12'" [text]="'No'" [checked]="cashonlytransfered === undefined ? false : !cashonlytransfered"
                        (onCheck)="checkSection($event)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
          <div class="d-flex" style="padding-left: 35px;">
            <span>If "yes" skip the remainder of Part III and go to Part IV.</span>
          </div>
        </li>
        <!-- 11 -->
        <li *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              Did the transferor transfer stock or securities subject to section 367(a) with respect to which a gain
              recognition agreement was filed?
              <span class="appkiticon icon-information-fill" [tooltip-container]="template6" [placement]="'right'" [isWhite]="true"></span>
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-13'" [text]="'Yes'" [checked]="transferunder367a" (onCheck)="check11(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-13'" [text]="'No'" [checked]="transferunder367a === undefined ? false : !transferunder367a"
                        (onCheck)="check11(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 12a -->
        <li class="li-apdix-a" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              <span>
                Were any assets of a foreign branch (including a branch that is a foreign disregarded entity)
                transferred to a foreign corporation?
              </span>
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-14'" [text]="'Yes'" [checked]="foreignassets" (onCheck)="checkForeign(true)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-14'" [text]="'No'" [checked]="foreignassets === undefined ? false : !foreignassets"
                        (onCheck)="checkForeign(false)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
          <div class="d-flex" style="padding-left: 35px;">
            <span>If "Yes," go to line 12b.</span>
          </div>
        </li>
        <!-- 12b -->
        <li class="li-apdix-b" style="counter-reset: count 11;" *ngIf="!hideSections && isForeignCorporation">
          <div class="row">
            <div class="col-md-8">
              Was the transferor a domestic corporation that transferred substantially all of the assets of a
              foreign branch (including a branch that is a foreign disregarded entity) to a specified 10%-owned
              foreign corporation? <span class="appkiticon icon-information-fill"
                                         [tooltip-container]="template7" [placement]="'right'" [isWhite]="true"></span> <br/>
              If "Yes," continue to line 12c. If "No," skip lines 12c and 12d, and go to line 13.
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-20'" [text]="'Yes'" [checked]="domesticcorptransfer" (onCheck)="checkForeign2(true)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-20'" [text]="'No'" [checked]="domesticcorptransfer === undefined ? false : !domesticcorptransfer"
                        (onCheck)="checkForeign2(false)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 12c -->
        <li class="li-apdix-c" style="counter-reset: count 11;" *ngIf="!hideSections && isForeignCorporation && isForeignCorporation2">
          <div class="row">
            <div class="col-md-8">
              Immediately after the transfer, was the domestic corporation a U.S. shareholder with respect to
              the transferee foreign corporation??<br/>
              If "Yes," continue to line 12d. If "No," skip line 12d, and go to line 13.
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-21'" [text]="'Yes'" [checked]="transfereeshareholder" (onCheck)="checkForeign3(true)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-21'" [text]="'No'" [checked]="transfereeshareholder === undefined ? false : !transfereeshareholder"
                        (onCheck)="checkForeign3(false)"
                        class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 12d -->
        <li class="li-apdix-d" style="counter-reset: count 11;" *ngIf="!hideSections && isForeignCorporation && isForeignCorporation2 && isForeignCorporation3">
          <div class="row">
            <div class="col-md-8">
              Enter the transferred loss amount included in the gross income as required under section 91
            </div>
            <div class="col-md-4">
              <ap-form-field class="number-field span-match-rgroup required-field" [label]="''" [id]="'22'" [type]="'inputNumber'" [placeholder]="'$'"
                             [funType]="'typeTwo'" [minNum]="0" [(ngModel)]="transferredLoss">
              </ap-form-field>
            </div>
          </div>
        </li>
        <!-- 13 -->
        <li style="counter-reset: count 12;" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              Did the transferor transfer property described in section 367(d)(4)?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-15'" [text]="'Yes'" [checked]="intangiblepropsec936h" (onCheck)="check13(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-15'" [text]="'No'" [checked]="intangiblepropsec936h === undefined ? false : !intangiblepropsec936h"
                        (onCheck)="check13(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 14a -->
        <li class="li-apdix-a" style="counter-reset: count 13;" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              Did the transferor transfer any intangible property that, at the time of transfer, had a useful life reasonably anticipated to exceed 20
              years?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-16'" [text]="'Yes'" [checked]="intangibleusefullife" (onCheck)="check14a(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-16'" [text]="'No'" [checked]="intangibleusefullife === undefined ? false : !intangibleusefullife"
                        (onCheck)="check14a(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 14b -->
        <li class="li-apdix-b" style="counter-reset: count 13;" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              At the time of the transfer, did any of the transferred intangible property have an indefinite useful life?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-18'" [text]="'Yes'" [checked]="indefiniteusefullife" (onCheck)="check14b(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-18'" [text]="'No'" [checked]="indefiniteusefullife === undefined ? false : !indefiniteusefullife"
                        (onCheck)="check14b(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 14c -->
        <li class="li-apdix-c" style="counter-reset: count 13;" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              Did the transferor choose to apply the 20-year inclusion period provided under Regulations section
              1.367(d)-1(c)(3)(ii) for any intangible property?
              <span class="appkiticon icon-information-fill" [tooltip-container]="template8" [placement]="'right'" [isWhite]="true"></span>
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-19'" [text]="'Yes'" [checked]="intangiblepropinclusion" (onCheck)="check14c(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-19'" [text]="'No'" [checked]="intangiblepropinclusion === undefined ? false : !intangiblepropinclusion"
                        (onCheck)="check14c(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 14d -->
        <li class="li-apdix-d" style="counter-reset: count 13;" *ngIf="!hideSections && intangiblepropinclusion">
          <div class="row">
            <div class="col-md-8">
              If the answer to line 14c is "Yes," enter the total estimated anticipated income or cost reduction attributable to the intangible
              property's or properties, as applicable, use(s) beyond the 20-year period described in Regulations section 1.367(d)-1(c)(3)(ii)
              <span class="appkiticon icon-information-fill" [tooltip-container]="template99" [placement]="'right'" [isWhite]="true"></span>
            </div>
            <div class="col-md-4 d-flex">
              $<ap-form-field [label]="''" [id]="'22'" [type]="'inputNumber'" [placeholder]="'$'" class="number-field span-match-rgroup required-field"
                             [funType]="'typeTwo'" [minNum]="0" [(ngModel)]="regulationAmt">
              </ap-form-field>
            </div>
          </div>
        </li>
        <!-- 15 -->
        <li style="counter-reset: count 14;" *ngIf="!hideSections">
          <div class="row">
            <div class="col-md-8">
              Was any intangible property transferred considered or anticipated to be, at the time of the transfer or
              at any time thereafter, a platform contribution as defined in Regulations section 1.482-7(c)(1)?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-17'" [text]="'Yes'" [checked]="anticipatedtransfer" (onCheck)="check15(true)" class="a-mx-10">
              </ap-radio>
              <ap-radio [name]="'radio-group-17'" [text]="'No'" [checked]="anticipatedtransfer === undefined ? false : !anticipatedtransfer"
                        (onCheck)="check15(false)"
                        class="a-mx-10">
              </ap-radio>
            </div>
          </div>
        </li>
      </ol>
      <div class="list-content a-textarea-box" style="padding-left: 36px;">
        <div style="margin-bottom: 10px;">
          <span class="part3-supp-info-desc">Supplemental Part III Information Required To Be Reported (see instructions) </span>
          <span class="appkiticon icon-information-fill" [tooltip-container]="template9" [placement]="'right'" [isWhite]="true"></span>
        </div>
        <dst-custom-input class="custom-input-100" style="padding-left: 36px;"
                          [type]="'textarea'" [label]="''" [maxLength]="1000" [value]="suppInfo"
                          (valChange)="suppInfo = $event;">
        </dst-custom-input>
      </div>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part IV&nbsp;&nbsp;Additional Information Regarding Transfer of Property</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <ol class="main-section section-content li-width-span-100 valign-top" style="padding-left: 51px;counter-reset: count 15;" >
      <!-- 16 -->
      <li >
        <div class="row">
          <div class="col-md-12">
            Enter the transferor's interest in the foreign transferee corporation before and after the transfer:
          </div>
        </div>
        <div class="d-flex" style="padding-left: 36px;">
          <div class="d-flex" style="margin-right: 30px;">
            <span>(a) Before&nbsp;</span>
            <ap-form-field [inlineBlock]="true" [id]="'before'" [type]="'inputNumber'" class="number-field number-field-w100" [funType]="'typeTwo'"
                           [minNum]="0" [maxNum]="100" [label]="''" [(ngModel)]="before">
            </ap-form-field>
            <span>&nbsp;%</span>
          </div>
          <div class="d-flex">
            <span>(b) After&nbsp;</span>
            <ap-form-field [inlineBlock]="true" [id]="'after'" [type]="'inputNumber'" class="number-field number-field-w100" [funType]="'typeTwo'"
                           [minNum]="0" [maxNum]="100" [label]="'(b) After'" [(ngModel)]="after">
            </ap-form-field>
            <span>&nbsp;%</span>
          </div>
        </div>
      </li>
      <!-- 17 -->
      <li>
        <div class="d-inline-flex" style="padding-left: 15px;">
          <div class="">
            <span>Type of nonrecognition transaction </span>
            <span class="appkiticon icon-information-fill" [tooltip-container]="template10" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <dst-custom-input id="custom-input-trans" class="required-field" [maxLength]="20" [value]="transaction" (valChange)="transaction = $event;">
          </dst-custom-input>
        </div>
      </li>
      <!-- 18 -->
      <li>
        <span style="padding-left: 15px;">Indicate whether any transfer reported in Part III is subject to any of the following:</span>
      </li>
      <!-- 18a -->
      <li class="li-apdix-a" style="counter-reset: count 17;">
        <div class="row">
          <div class="col-md-8">
            Gain recognition under section 904(f)(3)
            <span class="appkiticon icon-information-fill" [tooltip-container]="template11" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-22'" [text]="'Yes'" [checked]="gainunder904f3" (onCheck)="check18a(true)" class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-22'" [text]="'No'" [checked]="gainunder904f3 === undefined ? false : !gainunder904f3"
                      (onCheck)="check18a(false)"
                      class="a-mx-10">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18b -->
      <li class="li-apdix-b" style="counter-reset: count 17;">
        <div class="row">
          <div class="col-md-8">
            Gain recognition under section 904(f)(5)(F)
            <span class="appkiticon icon-information-fill" [tooltip-container]="template12" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-23'" [text]="'Yes'" [checked]="gainunder904f5" (onCheck)="check18b(true)" class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-23'" [text]="'No'" [checked]="gainunder904f5 === undefined ? false : !gainunder904f5"
                      (onCheck)="check18b(false)"
                      class="a-mx-10">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18c -->
      <li class="li-apdix-c" style="counter-reset: count 17;">
        <div class="row">
          <div class="col-md-8">
            Recapture under section 1503(d)
            <span class="appkiticon icon-information-fill" [tooltip-container]="template13" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-24'" [text]="'Yes'" [checked]="recapunder1503d" (onCheck)="check18c(true)" class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-24'" [text]="'No'" [checked]="recapunder1503d === undefined ? false : !recapunder1503d"
                      (onCheck)="check18c(false)"
                      class="a-mx-10">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18d -->
      <li class="li-apdix-d" style="counter-reset: count 17;">
        <div class="row">
          <div class="col-md-8">
            Exchange gain under section 987
            <span class="appkiticon icon-information-fill" [tooltip-container]="template14" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-25'" [text]="'Yes'" [checked]="exchangeunder987" (onCheck)="check18d(true)" class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-25'" [text]="'No'" [checked]="exchangeunder987 === undefined ? false : !exchangeunder987"
                      (onCheck)="check18d(false)"
                      class="a-mx-10">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 19 -->
      <li>
        <div class="row">
          <div class="col-md-8">
            Did this transfer result from a change in entity classification?
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-26'" [text]="'Yes'" [checked]="forcorptransferee" (onCheck)="check19(true)" class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-26'" [text]="'No'" [checked]="forcorptransferee === undefined ? false : !forcorptransferee"
                      (onCheck)="check19(false)"
                      class="a-mx-10">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 20a -->
      <li class="li-apdix-a">
        <div class="row d-inline-flex" style="padding-left: 15px;">
          <div class="col-md-8">
            <span>Did a domestic corporation make a distribution of property covered by section 367(e)(2) (see instructions) </span>
            <span class="appkiticon icon-information-fill" [tooltip-container]="template15" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-27'" [checked]="domesticcorpsec367e" (onCheck)="checkDomesticCorporation($event, 'Yes')" [text]="'Yes'"
                      class="a-mx-10">
            </ap-radio>
            <ap-radio [name]="'radio-group-27'" [checked]="domesticcorpsec367e === undefined ? false : !domesticcorpsec367e"
                      (onCheck)="checkDomesticCorporation($event, 'No')"
                      [text]="'No'" class="a-mx-10">
            </ap-radio>
          </div>
        </div>
        <div class="row" style="padding-left: 36px;">
          <div class="col-md-12">
            If "Yes," complete lines 20b and 20c.
          </div>
        </div>
      </li>
      <!-- 20b -->
      <li class="li-apdix-b" style="counter-reset: count 19;" *ngIf="showDomesticCorporation">
        <div class="row">
          <div class="col-md-8">
            Enter the total amount of gain or loss recognized pursuant to Regulations section 1.367(e)-2(b)
          </div>
          <div class="col-md-4">
            <ap-form-field class="required-field number-field span-match-rgroup" [inlineBlock]="true" [id]="'amt'" [type]="'inputNumber'" [funType]="'typeTwo'"
                           [minNum]="0" [label]="''" [(ngModel)]="gainOrLoss">
            </ap-form-field>
          </div>
        </div>
      </li>
      <!-- 20c -->
      <li class="li-apdix-c" style="counter-reset: count 19;" *ngIf="showDomesticCorporation">
        <div class="row">
          <div class="col-md-8">
            Did the domestic corporation not recognize gain or loss on the distribution of property because the
            property was used in the conduct of U.S. trade of business under Regulations section 1.367(e)-2(b)(2)?
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-28'" [text]="'Yes'" [checked]="propusedinustrade" (onCheck)="check20c(true)"
                      class="a-mx-10 required-field-radio">
            </ap-radio>
            <ap-radio [name]="'radio-group-28'" [text]="'No'" [checked]="propusedinustrade === undefined ? false : !propusedinustrade"
                      (onCheck)="check20c(false)"
                      class="a-mx-10 required-field-radio">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 21 -->
      <li>
        <div class="row">
          <div class="col-md-8">
            Did a domestic corporation make a section 355 distribution of stock in a foreign controlled corporation
            covered by section 367(e)(1)? See instructions
          </div>
          <div class="col-md-4">
            <ap-radio [name]="'radio-group-29'" [text]="'Yes'" [checked]="sec355stock" (onCheck)="check21(true)" class="a-mx-10 required-field-radio">
            </ap-radio>
            <ap-radio [name]="'radio-group-29'" [text]="'No'" [checked]="sec355stock === undefined ? false : !sec355stock" (onCheck)="check21(false)"
                      class="a-mx-10 required-field-radio">
            </ap-radio>
          </div>
        </div>
      </li>
    </ol>
    <div class="leid-list d-flex" style="margin-top: 15px;">
      <span>LEIDs: </span>
      <div>
        <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor"> /
        </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      A specified 10%-owned foreign corporation is defined in section 245A(b)(1) as any foreign corporation with respect
      to which any domestic corporation is
      a U.S. shareholder. A controlled foreign corporation is defined in section 957(a) as any foreign corporation if,
      on any day during the tax year of such foreign
      corporation, U.S. shareholders own (within the meaning of section 958(a)), or are considered to own by applying
      the rules of ownership of section 958(b), more
      than 50% of (1) the total combined voting power of all classes of stock of such corporation entitled to vote, or
      (2) the total value of the stock of such
      corporation.
    </div>
  </div>
</ng-template>
<ng-template #template2>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If you answered 'Yes' to question 2a and the asset is stock, section 367(a)(4) may require basis adjustments.
      If you answered 'No' to question 2a and the asset is a tangible asset, the transfer is taxable under sections
      367(a)(1) and (a)(4).
      If the asset transferred is an intangible asset, see section 367(d) and its regulations.
    </div>
  </div>
</ng-template>
<ng-template #template3>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to line 2d is 'Yes,' and if the asset is transferred in an exchange described in section 361(a) or
      (b), attach the following.
      A statement that the conditions set forth in the second sentence of section 367(a)(4) and any regulations under
      that section have been satisfied.
      An explanation of any basis or other adjustments made pursuant to section 367(a)(4) and any regulations
      thereunder.
    </div>
  </div>
</ng-template>
<ng-template #template5>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Provide a description of the property transferred. The description must be divided into the specified categories,
      and must include the estimated fair market value and adjusted basis of the property.
    </div>
  </div>
</ng-template>
<ng-template #template6>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the line 11 question is 'Yes,' for any stock or securities transferred, provide a general
      description of the corporation issuing the stock or securities. See Regulations section 1.6038B-1(c)(4)(ii).
      Please report in the Supplemental Part III Information Required To Be Reported section.
    </div>
  </div>
</ng-template>
<ng-template #template7>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to lines 12b and 12c is 'Yes,' provide the following information. (1) Describe the foreign branch
      whose property is transferred. Describe the property of the foreign branch, including its adjusted basis and fair
      market value. (2) Set forth a detailed calculation of
      the transferred loss amount. Provide, on a year-by-year basis, amounts of the losses generated by such foreign
      branch after December 31, 2017, as well as any income amounts
      generated after such loss year. (3) Provide the amount, if any, recognized under section 904(f)(3) on account of
      the transfer. (4) Set forth a detailed summary of the gain
      (other than the section 91 transferred loss amount) recognized by the transferor, including any section 367(a)(1)
      gain recognized on the transfer of property. See section
      91(c). (5) Set forth a calculation of the net sum of the previously deducted losses incurred by such foreign
      branch for tax years before January 1, 2018, that would have
      been recaptured under section 367(a)(3)(C), as determined without regard to the repeal of the section 367(a)(3)
      active trade or business exception. See the transition rule
      provided by TCJA section 14102(d)(4).
    </div>
  </div>
</ng-template>
<ng-template #template8>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the line 14c question is 'Yes,' describe the property for which the transferor
      chose to apply the 20-year inclusion period. See Regulations sections 1.6038B-1(d)(1)(iv) and 1.367(d)-1(c)
      (3)(ii). Explain how you figured the increase to the deemed payment
      rate for property transferred. See Regulations sections 1.6038B-1(d)(1) (iv) and 1.367(d)-1(c)(3)(ii). The
      explanation should include how you figured the deemed payment rate for
      each period of the useful life of the intangible property and the 20-year inclusion period.
    </div>
  </div>
</ng-template>
<ng-template #template9>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Enter any information from Part III that is required to be reported in greater
      detail. Identify the applicable column number next to the information
      entered in this section. In addition, if you contributed property to a
      foreign corporation as part of a wider transaction, briefly describe
      the entire transaction.
    </div>
  </div>
</ng-template>
<ng-template #template10>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      List the type of nonrecognition transaction that gave rise to the reporting obligation (e.g. Section 332, 351,
      354, 356, or 361)
    </div>
  </div>
</ng-template>
<ng-template #template11>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If gain recognized was required with respect to any transfer reported in Part III under Section 904(f)(3), attach
      a statement identifying the transfer and the amount of gain recognized
    </div>
  </div>
</ng-template>
<ng-template #template12>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If gain recognized was required with respect to any transfer reported in Part III under Section 904(f)(5)(F),
      attach a statement identifying the transfer and the amount of gain recognized
    </div>
  </div>
</ng-template>
<ng-template #template13>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If recapture was required with respect to any transfer reported in Part III under Section 1503(d) dual
      consolidated loss), attach a statement identifying the transfer and the amount of the recapture. Refer to Sec.
      1503(d)
    </div>
  </div>
</ng-template>
<ng-template #template14>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If exchange gain recognition was required with respect to any transfer reported in Part III under Section 987,
      attach a statement identifying the transfer and the amount of the exchange gain recognized. Refer to Sec. 1.987-5
    </div>
  </div>
</ng-template>
<ng-template #template15>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to line 20a is 'Yes,' complete lines 20b and 20c and provide the following
      information in the Supplemental Part III Information Required To Be Reported section. Preface this supplemental
      information on the form with the heading 'Section 367(e)(2)
      Information.' A description, including the adjusted tax basis and fair market value, of all property distributed
      by the distributing corporation (regardless of whether
      the distribution of the property qualifies for nonrecognition treatment). If the answer to line 20c is 'Yes,' an
      identification of the items of property for which nonrecognition
      treatment is claimed under Regulations section 1.367(e)-2(b)(2)(ii) or (iii), as applicable.
    </div>
  </div>
</ng-template>
<ng-template #template99>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the question on line 14c is 'Yes,' explain
      how you estimated the anticipated income or cost reduction attributable to the property's (or properties') use beyond the 20-year period.
      See Regulations section 1.6038B-1(d)(1) (iv).
    </div>
  </div>
</ng-template>
