import {Injectable} from '@angular/core';
import {GtwElementsBaseUrlsModel} from '../../shared/_models/gtw-elements-base-urls.model';
import {Alert} from '../statement-tracker/statement-tracker.service';
import {Observable} from 'rxjs';
import {ActionResponse} from '../../shared/_models/action-response.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ActionService} from '../../shared/_services/action.service';
import {DSTGridFilter, DSTGridFilterVal} from './models/dst-grid-filter.model';
import {DSTStatement} from './models/dst-statement.model';
import {DSTUser} from './models/dst-user.model';

@Injectable({
  providedIn: 'root'
})
export class DSTSharedService {

  clientKey!: number;
  user!: DSTUser;
  gtwTaxYear!: string;
  gtwScenario!: string;
  alert: Alert;
  tableActionStatus: DSTTableActionStatus;
  baseURLs!: GtwElementsBaseUrlsModel;
  s3uploadButtonAccessUsers: string[];
  isSystemLock: boolean;
  isFiled: boolean;

  constructor(private httpClient: HttpClient,
              private actionService: ActionService) {
    this.user = {
      ssoId: '',
      roleLevel: 0
    };
    this.baseURLs = {
      api: '/gtw',
      custom: '/custom',
      admin: '/admin',
      efile: '/gtw-efile-api',
      pdfEngine: '/pdf-engine',
      bulkPdfEngine: '/bulk-pdf-engine'
    };
    this.alert = new Alert();
    this.tableActionStatus = new DSTTableActionStatus();
    this.s3uploadButtonAccessUsers = [];
    this.isSystemLock = false;
    this.isFiled = false;
  }

  setClientKey(clientKey: string): void {
    this.clientKey = Number.parseInt(clientKey);
  }

  setDSTUser(ssoId: string, roleLevel: number, roleDesc: string = ''): void {
    // if (this.user) {
    //   return;
    // }
    this.user = {ssoId, roleLevel, roleDesc};
  }

  setTaxYearScenario(taxYear: string, scenario: string): void {
    this.gtwTaxYear = taxYear;
    this.gtwScenario = scenario;
  }

  setBaseURLs(baseURLs: GtwElementsBaseUrlsModel): void {
    this.baseURLs = baseURLs;
  }

  showAlert(type: string, message: string, timeout?: number): void {
    this.alert.type = type;
    this.alert.message = message;
    if (typeof timeout === 'number') {
      this.alert.timeout = timeout;
    }
    this.alert.display = true;
  }

  setTableActionStatus(saving: boolean): void {
    this.tableActionStatus.inProgress = saving;
  }

  getExcelExportDateString(): string {
    const now: Date = new Date();
    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}`;
  }

  setS3uploadButtonAccessUsers(users: string[]): void {
    this.s3uploadButtonAccessUsers = users;
  }

  /**
   * sets System lock status & sets user role level to "read-only" except for "Default unlock" & "Admin" roles
   * @param isLocked indicates if system is locked/unlocked
   * @param roleLevel is the user role level
   */
  setSystemLockUnlockStatus(isLocked: boolean, roleLevel: number): void {
    this.isSystemLock = isLocked;
    (isLocked && ((roleLevel !== 5) && (roleLevel !== 4)) ) && (this.user.roleLevel = 1);
  }

  setIsFiled(isFiled: boolean): void {
    this.isFiled = isFiled;
  }

  /**
   * Converts prime multi-selectors' selected options into a comma separated string
   * @param filterVals are an array of user-selected options
   * @param key is the key in a filterVal object that should be extracted to produce the result string
   * @param convert is an optional callback function which should convert the filterVals array into another filterVals array in which
   *                the elements can be extracted, based on caller-defined rules
   * @return a comma separated string representing the user-selected options or an empty string if none is selected
   */
  getMultiSelectFilterInputAsString(filterVals: any[], key: string, convert?: (vals: DSTGridFilterVal[]) => DSTGridFilterVal[]): string {
    let result: string = '';
    if (convert) {
      try {
        filterVals = convert(filterVals);
      } catch (e: any) {
        console.log(e);
        throw Error('Something went wrong while converting the filterVals using provided converter!');
      }
    }
    filterVals?.forEach((fV: any) => {
      result += fV[key].toString() + ',';
    });
    // remove last comma
    if (result) {
      result = result.substring(0, result.length - 1);
    }
    return result;
  }

  getGridFilterOptions(actionId: number): Observable<DSTGridFilter[]> {
    return this.actionService.getActionData(this.baseURLs.api, actionId, this.clientKey, new HttpParams())
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            try {
              return JSON.parse(data.jsonObject[0].FILTERS);
            } catch (e: any) {
              if (e instanceof SyntaxError) {
                console.log('JSON parse Syntax Error' + e);
                throw Error('Error loading DST grid filters, please try again!');
              }
            }
          } else {
            throw Error('Error loading DST grid filters, please try again!');
          }
        })
      );
  }

  getGridData(gridId: number, filtersObj: object): Observable<DSTStatement[]> {
    const params = new HttpParams({
      fromObject: {
        grid_id: gridId.toString()
      }
    });
    const url = `${this.baseURLs.admin}/loadGenericDSTGridData`;
    const formData: FormData = new FormData();
    formData.append('filterObject', JSON.stringify(filtersObj));
    return this.httpClient.post<any>(url, formData, {withCredentials: true, params: params}).pipe(
      map((data: ActionResponse) => {
        if (data.callSuccess !== '1') {
          throw Error('Error loading DST grid data!');
        } else {
          return data.jsonObject;
        }
      })
    );
  }

}

export class DSTTableActionStatus {
  private _inProgress: boolean;

  constructor() {
    this._inProgress = false;
  }

  set inProgress(value: boolean) {
    this._inProgress = value;
  }

  get inProgress(): boolean {
    return this._inProgress;
  }
}
