import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {ModalComponent} from 'appkit-angular/modal';
import {Alert, StatementTrackerService} from '../statement-tracker.service';
import {MatchTrackerService, MatchTrackerStatement} from './match-tracker.service';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';
import {DSTSharedService} from '../../shared/dst-shared.service';

@Component({
  selector: 'dst-match-tracker',
  templateUrl: './match-tracker.component.html',
  styleUrls: ['./match-tracker.component.scss']
})
export class MatchTrackerComponent implements OnInit {

  @Input() rowObject!: DSTStatement;
  @Output() afterModalClosed: EventEmitter<null>;
  @Output() onSaveCompleted: EventEmitter<null>;

  @ViewChild('baseModal', {static: false}) baseModal!: ModalComponent;
  @ViewChild('modalRef') modalRef!: ElementRef;

  tableTitle: any[] = [
    {
      title: 'Action'
    },
    {
      title: 'Statement Id',
      sort: {
        sortBy: 'STATEMENT_ID',
        descendant: false,
        defaultSorted: true
      }
    },
    {
      title: 'Primary Entity',
      sort: {
        sortBy: 'PRIMARYENTITY',
      }
    },
    {
      title: 'Statement Name',
      sort: {
        sortBy: 'STATEMENTNAME',
      }
    },
    {
      title: 'Secondary Entity',
      sort: {
        sortBy: 'SECONDARYENTITY',
      }
    },
    {
      title: 'Created By',
      sort: {
        sortBy: 'CREATEDBY',
      }
    }
  ];
  tableData!: MatchTrackerStatement[];
  statementId: string = '';
  loadingStatements: boolean = false;
  saving: boolean = false;
  alert!: Alert;

  constructor(private mTService: MatchTrackerService,
              private dstSharedService: DSTSharedService) {
    this.afterModalClosed = new EventEmitter<null>();
    this.onSaveCompleted = new EventEmitter<null>();
    this.alert = this.dstSharedService.alert;
  }

  ngOnInit(): void {
    this.tableData = [];
    this.loadingStatements = true;
    this.mTService.getExistingStatements(this.rowObject.csStatementId)
      .subscribe((data: MatchTrackerStatement[]) => {
        this.tableData = data;
      }, (eMsg: string) => {
        this.dstSharedService.showAlert('error', eMsg);
      })
      .add(() => {
        this.loadingStatements = false;
      });
    // ** remove below when Appkit provides onModalDismissed API **
    // notify tracker to destroy modal when dismissed (user clicked on the backdrop)
    setTimeout(() => {
      const modalEle: HTMLCollectionOf<Element> = document.getElementsByClassName('modal');
      if (modalEle[0]) {
        modalEle[0].addEventListener('click', () => {
          this.notifyAfterModalClosed();
        });
      }
    });
  }

  onStatementIdChange(event: any): void {
    this.statementId = event.value;
  }

  matchTo(row: MatchTrackerStatement): void {
    this.save(row.STATEMENT_ID.toString());
  }

  save(matchToStatementId?: string): void {
    const statementId: string = matchToStatementId ? matchToStatementId : this.statementId;
    this.saving = true;
    this.mTService.save(this.rowObject.csStatementId, statementId)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess === '1') {
          this.dstSharedService.showAlert('success', 'Statement matched successfully!', 4000);
          this.modalDetermine();
          this.onSaveCompleted.emit();
        } else {
          this.dstSharedService.showAlert('error', response.errorMessage);
        }
      })
      .add(() => {
        this.saving = false;
      });
  }

  showMore(id: string): void {
    this.baseModal.showMore(id);
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="match-tracker"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-lg');
    });
  }

  modalClose(): void {
    this.notifyAfterModalClosed();
  }

  modalDetermine(): void {
    this.notifyAfterModalClosed();
  }

  // closeModal(id: string): void {
  //   this.baseModal.closeModal(id);
  //   this.notifyAfterModalClosed();
  // }

  closeModalOk(id: string): void {
    this.baseModal.closeModalOk(id);
    this.notifyAfterModalClosed();
  }

  private notifyAfterModalClosed(): void {
    this.afterModalClosed.emit();
  }

}

