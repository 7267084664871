import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { FgnOverrides163JComponentService } from './fgn-overrides163j.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';
import { GtwSnackbarService  } from '../../../shared/_services/gtw-snackbar.service';

@Component({
  selector: 'gtw-fgn-overrides163j',
  templateUrl: './fgn-overrides163j.component.html',
  styleUrls: ['./fgn-overrides163j.component.scss']
})
export class FgnOverrides163jComponent implements OnInit {

  baseGlobalParams:any = {};
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private ssoId: string = '';
  private checkLockedObj: any = [];
  private rowData: any = {};
  private scenarioCode: string = '';
  private filterParams : any= {};
  isViewOnly: boolean = false;
  enableButton: boolean = true;
  private modalName: string = '';
  clob_settings: { tax_year: number; scenario: number; jcd_key: number; } = {
    tax_year: 0,
    scenario: 0,
    jcd_key: 0
  };
  clob_data: any = [];


  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}
  @Input('row-data')
  set _rowData(rowData:any)
  {
    if(rowData !== "{{ctrl.rowData}}" && rowData.length > 0)
    {
      let params = JSON.parse(rowData);
      if(params != undefined){
        this.rowData = params;
        this.init();
      }
    }
  }

  @Input('modal-name')
  set _modalName(modalName:string){
    this.modalName = modalName;
    console.log("modalName---" , modalName);
    this.init();
  }

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
  @Input('filter-params')
	set _filter_params(filterParams: string){
    try{
			let params = JSON.parse(filterParams);
			if(params != undefined){
				this.filterParams = params;
			}
			this.init();
		}catch(e){
			console.log("Filter Params not stable. Still waiting to get resolved.");
		}
	}
  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    }catch(error){
		console.log("Not an valid object");
		}
    this.init();
  }

  @Output('close-fir163joverridesconfirm-info')
  closefir163joverridesconfirmInfo = new EventEmitter<string>();

  @Output('save-fir163joverridessave-info')
  savefir163joverridessaveInfo = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;

  constructor(private FgnOverrides163JComponentService: FgnOverrides163JComponentService, private _snackBar: MatSnackBar, private alertService:GtwSnackbarService) { 
     }

  ngOnInit(): void {
  }

  checkInputType():boolean{
		if(
		  Number.isInteger(parseInt(this.ssoId+"") ) &&
      this.rowData != '{}' && 
      (this.modalName != null && this.modalName != '{{ctrl.modalName}}') &&
      JSON.stringify(this.filterParams) != '{}'
    )
		{
		  return true;
		}
		return false;
	}

  init() {   
    if(this.checkInputType()){
    }
  } 

    save(){ 
      this.enableButton = false;
      this.clob_data = [];
      this.clob_settings.tax_year = this.filterParams.tax_year;
      this.clob_settings.scenario = this.filterParams.scenario;
      this.clob_settings.jcd_key = this.baseGlobalParams.jcd_key;
      var tempObj = {
        COMBINATION_KEY : this.rowData.COMBINATION_KEY,
        HO_COMBINATION_KEY : this.rowData.HO_COMBINATION_KEY,
        ACCT_KEY : this.rowData.ACCT_KEY,
        CHART_KEY : this.rowData.CHART_KEY,
        TRANS_HEADER_KEY : (this.rowData.TRANS_HEADER_KEY == undefined) ? 0 : this.rowData.TRANS_HEADER_KEY,
        TRANS_DETAILS_KEY : (this.rowData.TRANS_DETAILS_KEY == undefined) ? 0 : this.rowData.TRANS_DETAILS_KEY
     }
     this.clob_data.push(tempObj);
     this.FgnOverrides163JComponentService.saveData(this.baseURLs.api, this.clob_data, this.clob_settings, this.baseGlobalParams, this.checkLockedObj, this.filterParams, this.ssoId).subscribe(
      (data) => 
      {
        if (data.callSuccess == "1") {
          this.FgnOverrides163JComponentService.openSnackBar('Data Saved Successfully','gtw-snackbar--green');
          this.savefir163joverridessaveInfo.emit('save-fir163joverridessave-info');
        } else {
          this.enableButton = true;
          this.FgnOverrides163JComponentService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
        }
      }, () => {
        console.log("Error while saving");
    });
 }

  cancel() {
    console.log("click close");
    this.closefir163joverridesconfirmInfo.emit('close-firf5471h1confirm-info');
    }

}

