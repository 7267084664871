import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
/***
 * 
 * WARNING: Will be Replaced with Redux Store
 * 
 */


@Injectable({
  providedIn: 'root'
})
export class ActiveScreensService {

  url={
		getScreenBreadcrumbs : '/loadJsonObject?action_code=ephav3'
	}

  private activeScreens:Array<any>=[];
  appNavigationList:Array<any>=[];
  public domainName$: BehaviorSubject<string> = new BehaviorSubject<string>('Home');
  screenBreadcrumbList : any = [];

  constructor(private http:HttpClient) { }

  setScreens(screen:any){
    this.activeScreens = this.activeScreens.filter(aScreen => {
      if(aScreen.id) {
        return  aScreen.id != screen.id;
      }
      return false;
    });
    this.activeScreens.push(screen);
  }

  getScreenBreadcrumbsData(baseUrl:string){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseUrl}${this.url.getScreenBreadcrumbs}`;
		
		return this.http.get(url, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
          this.screenBreadcrumbList = res.jsonObject;
					return res.jsonObject;
				}
			}
		));
	}

  getCachedBreadcrumbList(){
    return this.screenBreadcrumbList;
  }

  getActiveScreens(){
    return this.activeScreens;
  }
  clickScreen(index:number){
    let clickedScreen=this.activeScreens.splice(index,1);
    this.activeScreens.push(clickedScreen[0]);
    return clickedScreen[0];
  }

  goBack(){
    let i= this.activeScreens.length-2;//second last
    return this.clickScreen(i);
  }

  markOpenSelectedScreen(selectedScreen:any){
    if(selectedScreen.SCREEN_KEY == 10906){
      this.domainName$.next('Home');
      return;
    }
    let path = selectedScreen.BREADCRUMB.trim().split('>>').map((p:string) => p.trim());
    let tempNav = this.appNavigationList;
    for(let j=0; j < path.length+1 ; j++){  
      for(let i=0; i< tempNav.length; i++){
        if(j != path.length && tempNav[i].label.trim().toLowerCase() == path[j].trim().toLowerCase()){
          tempNav[i].isOpen = true;
          if(tempNav[i].hasOwnProperty('children') && tempNav[i].children.length >0){
            tempNav =tempNav[i].children;
            i=0;
          }else if(tempNav[i].hasOwnProperty('screens') && tempNav[i].screens.length > 0){
            tempNav = tempNav[i].screens;
            i=0;
          }
          break;
        }
        if(j == path.length && tempNav[i].id == selectedScreen.SCREEN_KEY){
          tempNav[i].isActive=true;
          this.domainName$.next(path[0]);
          break;
        }
      }
    };
  }

}
