<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded"
  (onSaveClick)="save()" (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar">
</dst-create-disclosure-actions>

<div class="form-template-wrapper li-apdix-wrapper-temp">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>

  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        Form 8621 - Return by a Shareholder of a Passive Foreign Investment Company or Qualified Electing Fund
        <br />
      </span>
    </B>
  </div>
  <br />

  <div [formGroup]="form236" class="section-content" *ngIf="loaded">
    <div class="section-content">

      <div class="ap-form-field-my-wrapper">

        <div class="row">
          <div class="col-md-3">
            <span>Entity Name:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primaryentity == null? '': stmtData.primaryentity}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>Federal ID:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primaryein == null ? (attMap['TransferorEinMissingReason'] ? attMap['TransferorEinMissingReason'][0] : '') : stmtData.primaryein}}
            </span>
          </div>
        </div>
        <div class="row mt-1" *ngIf="stmtData.primaryein == null">
          <div class="col-md-3">
            <span>EIN Missing Reason:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ attMap['MissingEINReason'] ? attMap['MissingEINReason'][0] : '' }}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>Address Line 1:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primaryaddress1 == null? '': stmtData.primaryaddress1}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>Address Line 2:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primaryaddress2 == null? '' : stmtData.primaryaddress2}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>{{stmtData.primarycountry === 'US' ? 'City:': 'City / Town:'}}</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primarycity == null? '' : stmtData.primarycity}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>{{stmtData.primarycountry === 'US' ? 'State:': 'Province Or State:'}}</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stateValue}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span>Country:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primarycountryname == null? '' : stmtData.primarycountryname}}
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
            <span> {{stmtData.primarycountry === 'US' ? 'Zip Code:': 'Postal Code:'}} </span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{stmtData.primaryzip == null? '' : stmtData.primaryzip}}
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">Shareholder Tax Year</div>
      </div>
      <div class="row" *ngIf="shareholderTaxYearType === 'Year'">
        <div class="col-md-3">
          <span>Calendar Year: </span>
        </div>
        <div class="col-md-4">
          <span> {{shareholderTaxYear}} </span>
        </div>
      </div>
      <div *ngIf="shareholderTaxYearType === 'Range'">
        <div class="row">
          <div class="col-md-3">
            <span>Tax Year Beginning </span>
          </div>
          <div class="col-md-4">
            <span> {{shareholderBeginningTaxYear}} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <span>Tax Year Ending </span>
          </div>
          <div class="col-md-4">
            <span> {{shareholderEndingTaxYear}} </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          Check type of shareholder filing the return:
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Individual'"
            [checked]="shareholderFiling === 'Individual'" [disabled]="true">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Corporation'"
            [checked]="shareholderFiling === 'Corporation'" [disabled]="true">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Partnership'"
            [checked]="shareholderFiling === 'Partnership'" [disabled]="true">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'S Corporation'"
            [checked]="shareholderFiling === 'SCorporation'" [disabled]="true">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Nongrantor Trust'"
            [checked]="shareholderFiling === 'NongrantorTrust'" [disabled]="true">
          </ap-checkbox>
        </div>
        <div class="col-md-2">
          <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Estate'"
            [checked]="shareholderFiling === 'Estate'" [disabled]="true">
          </ap-checkbox>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="d-flex">
            <div>
              Qualifying Insurance Corporation Election - I, a shareholder of stock of a foreign corporation, elect to treat such stock as the stock of a Qualifying Insurance Corporation under the alternative facts and circumstances test within the meaning of section 1297(f)(2). See instructions
              <span class="appkiticon icon-information-fill red-icon" [tooltip-container]="tooltip1" [placement]="'right'"></span>
            </div>
            <ap-checkbox class="a-mx-10" [checked]="corpElectionChecked" [disabled]="true">
            </ap-checkbox>
          </div>
        </div>
      </div>

      <!-- PFIC Information -->
      <div class="">
        <div class="row mt-3">
          <div class="col-md-3">
            <span>Name of the PFIC or QEF:</span>
          </div>
          <div class="col-md-6">
            <span class="template-color-blue">{{ stmtData.secondaryentity == null? '': stmtData.secondaryentity }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-3">
              <span>Identifying Number:</span>
          </div>
          <div class="col-md-6">
              <span class="template-color-blue">{{ stmtData.secondaryein == null? 'N/A':stmtData.secondaryein }}</span>
          </div>
        </div>
        <div class="row mt-1" *ngIf="stmtData.secondaryein == null">
          <div class="col-md-3">
            <span>EIN Missing Reason:</span>
          </div>
          <div class="col-md-6">
              <span class="template-color-blue">{{ attMap['EINMissingReason'] && attMap['EINMissingReason'][0] != -1 ?
                 attMap['EINMissingReason'][0]  : '' }}</span>
          </div>
        </div>
        <!-- PFIC Address Details -->
        <div class="">
          <div class="row mt-1">
            <div class="col-md-3">
              <span>Address Line 1:</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{ PFICAdd1 }}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span>Address Line 2:</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{ PFICAdd2 }}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span>{{pficAddress === 'US' ? 'City:' : 'City / Town:'}}</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{ PFICCity }}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span>{{pficAddress === 'US' ? 'State:' : 'Province or State:'}}</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{secoundaryStateValue}}</span>
            </div>
          </div>
          <div class="row mt-1" *ngIf="pficAddress === 'Foreign'">
            <div class="col-md-3">
              <span>Country:</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{PFICCountry}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span>{{pficAddress === 'US' ? 'Zip:' : 'Postal Code:'}}</span>
            </div>
            <div class="col-md-6">
              <span class="template-color-red">{{PFICZip}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- PFIC Tax Years -->
      <div class="row mt-3">
        <div class="col-md-12">PFIC/QEF Tax Year</div>
      </div>
      <div class="row" *ngIf="PFICTaxYearType === 'Year'">
        <div class="col-md-3">
          <span>Calendar Year: </span>
        </div>
        <div class="col-md-4">
          <span> {{PFICTaxYear}} </span>
        </div>
      </div>
      <div *ngIf="PFICTaxYearType === 'Range'">
        <div class="row">
          <div class="col-md-3">
            <span>Tax Year Beginning </span>
          </div>
          <div class="col-md-4">
            <span> {{companyOrFundBeginningTaxYear}} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <span>Tax Year Ending </span>
          </div>
          <div class="col-md-4">
            <span> {{companyOrFundEndingTaxYear}} </span>
          </div>
        </div>
      </div>

      <br/>
      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I&nbsp;&nbsp;</strong>
            <strong>Summary of Annual Information</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="partOne">
        <div class="section-content ap-form-field-my-wrapper">
          <ol class="section-content list-content m-0" start="1">

            <li>
              <div class="row mb-1">
                <div class="col-md-5">
                  <span>Description of each class of shares held by the shareholder: </span>
                </div>
                <div class="col-md-4">
                  <span> {{ shareHoldersSharesDesc }}</span>
                </div>
              </div>
              <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Check if shares jointly owned with spouse.'"
                [checked]="sharesOwnedWithSpouse" [disabled]="true" style="margin-left: 0px !important;">
              </ap-checkbox>
            </li>

            <li class="pt-2">
              <div class="row">
                <div class="col-md-5">
                  Date shares acquired during the taxable year, if applicable:
                </div>
                <div class="col-md-4">
                  <span [id]="'dateSharesAcq'"> {{ dateSharesAcq }} </span>
                </div>
              </div>
            </li>

            <li class="pt-2">
              <div class="row">
                <div class="col-md-5">
                  <label  [for]="'noOfSharesYearEnd'">Number of shares held at the end of the taxable year: </label>
                </div>
                <div class="col-md-4">
                  <span [id]="'noOfSharesYearEnd'"> {{ noOfSharesYearEnd | number}} </span>
                </div>
              </div>
            </li>

            <li class="pt-2">
              <div class="row">
                <div class="col-md-12">
                  Value of shares held at the end of the taxable year (check the appropriate box, if applicable):
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-3 pr-0">
                  <b>(a)</b>
                  <ap-checkbox class="a-mx-10 checkbox-font" [checked]="shareValueTaxyearEnd === 0" [text]="'$0-50,000'"
                    [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-3 p-0">
                  <b>(b)</b>
                  <ap-checkbox class="a-mx-10 checkbox-font" [checked]="shareValueTaxyearEnd === 50001" [text]="'$50,001 - 100,000'"
                    [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-3 p-0">
                  <b>(c)</b>
                  <ap-checkbox class="a-mx-10 checkbox-font" [checked]="shareValueTaxyearEnd === 100001" [text]="'$100,001 - 150,000'"
                    [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-3 p-0">
                  <b>(d)</b>
                  <ap-checkbox class="a-mx-10 checkbox-font" [checked]="shareValueTaxyearEnd === 150001" [text]="'$150,001 - 200,000'"
                    [disabled]="true">
                  </ap-checkbox>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-6 d-flex">
                  <b>(e)</b> <span class="ml-2">if more than $200,000, list value: </span>
                  <span class="ml-2" [id]="'valueOfSharesYearEnd'"> {{ valueOfSharesYearEnd | number }} </span>
                </div>
              </div>

            </li>

            <li class="pt-2">
              <div class="row">
                <div class="col-md-12">
                  Type of PFIC and amount of any excess distribution or gain treated as an excess distribution under section 1291, inclusion under section 1293, or inclusion or deduction under section 1296:
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  (a)
                  <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Section 1291'" [checked]="pficType === 1" [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-4 pl-0">
                  <p [id]="'pficType1Amount'"> $ {{ pficType1Amount | number}} </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  (b)
                  <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Section 1293 (Qualified Election Fund)'"  [checked]="pficType === 2"
                    [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-4 pl-0">
                  <p [id]="'pficType2Amount'"> $ {{ pficType2Amount | number }} </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  (c)
                  <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Section 1296 (Mark to Market)'"  [checked]="pficType === 3" [disabled]="true">
                  </ap-checkbox>
                </div>
                <div class="col-md-4 pl-0">
                  <p [id]="'pficType3Amount'"> $ {{ pficType3Amount | number }} </p>
                </div>
              </div>
            </li>

          </ol>
        </div>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part II&nbsp;&nbsp;</strong>
            <strong>Elections</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="partTwo">
        <p class="m-0 pt-3 pb-1">Check the applicable box for this type of election</p>
        <div class="section-content list-content list-spacing pt-0">
          <ol class="list-upper-alpha">
            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'electionToTreat'" [checked]="electionToTreat" [disabled]="true">
                </ap-checkbox>
                <p>Election To Treat the PFIC as a QEF: I, a shareholder of a PFIC, elect to treat the PFIC as a QEF. Complete lines 6a through 7c of Part III.</p>
              </div>
            </li>

            <li class="row m-0 p-0">
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'electionToExtend'" [checked]="electionToExtend" [disabled]="true">
                </ap-checkbox>
                <p class="mb-0">Election To Extend Time For Payment of Tax: I, a shareholder of a QEF, elect to extend the time for payment of tax on the undistributed earnings and profits of the QEF until this election is terminated. Complete lines 8a through 9c of Part III to calculate the tax that may be deferred. Note: If any portion of line 6a or line 7a of Part III is includible under section 951, you may not make this election. Also, see sections 1294(c) and 1294(f) and the related regulations for events that terminate this election.</p>
              </div>
              <div class="col-md-4 pb-2" style="padding-left: 35px !important;">
                <p [id]="'electionToExtendDesc'"> {{ electionToExtendDesc }} </p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'electionToMarkToMarket'" [checked]="electionToMarkToMarket" [disabled]="true">
                </ap-checkbox>
                <p>Election To Mark-to-Market PFIC Stock: I, a shareholder of a PFIC, elect to mark-to-market the PFIC stock that is marketable within the meaning of section 1296(e). Complete Part IV.</p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'deemedSalesElection'" [checked]="deemedSalesElection" [disabled]="true">
                </ap-checkbox>
                <p>Deemed Sale Election: I, a shareholder on the first day of a PFIC's first tax year as a QEF, elect to recognize gain on the deemed sale of my interest in the PFIC. Enter gain or loss on line 15f of Part V.</p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'deemedDividentElection'" [checked]="deemedDividentElection" [disabled]="true">
                </ap-checkbox>
                <p>Deemed Dividend Election: I, a shareholder on the first day of a PFIC's first tax year as a QEF that is a controlled foreign corporation (CFC), elect to treat an amount equal to my share of the post-1986 earnings and profits of the CFC as an excess distribution. Enter this amount on line 15e of Part V. If the excess distribution is greater than zero, also complete line 16 of Part V</p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'electionToRecognizeGain'" [checked]="electionToRecognizeGain" [disabled]="true">
                </ap-checkbox>
                <p>Election To Recognize Gain on Deemed Sale of PFIC: I, a shareholder of a former PFIC or a PFIC to which section 1297(d) applies, elect to treat as an excess distribution the gain recognized on the deemed sale of my interest in the PFIC, or, if I qualify, my share of the PFIC's post-1986 earnings and profits deemed distributed, on the last day of its last tax year as a PFIC under section 1297(a). Enter gain on line 15f of Part V.</p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'deemedDividendElectionWrtSection1297'"
                  [checked]="deemedDividendElectionWrtSection1297" [disabled]="true">
                </ap-checkbox>
                <p>Deemed Dividend Election With Respect to a Section 1297(e) PFIC. I, a shareholder of a section 1297(e) PFIC, within the meaning of Regulations section 1.1297-3(a), elect to make a deemed dividend election with respect to the Section 1297(e) PFIC. My holding period in the stock of the Section 1297(e) PFIC includes the CFC qualification date, as defined in Regulations section 1.1297-3(d). Enter the excess distribution on line 15e, Part V. If greater than zero, also complete line 16, Part V.</p>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <ap-checkbox class="a-mx-10" [id]="'deemedDividendElectionWrtFormerPfic'"
                  [checked]="deemedDividendElectionWrtFormerPfic" [disabled]="true">
                </ap-checkbox>
                <p>Deemed Dividend Election With Respect to a Former PFIC. I, a shareholder of a former PFIC, within the meaning of Regulations section 1.1298-3(a), elect to make a deemed dividend election with respect to the former PFIC. My holding period in the stock of the former PFIC includes the termination date, as defined in Regulations section 1.1298-3(d). Enter the excess distribution on line 15e, Part V. If excessive distribution is greater than zero, also complete line 16, Part V.</p>
              </div>
            </li>

          </ol>
        </div>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III&nbsp;&nbsp;</strong>
            <strong>Income From a Qualified Electing Fund (QEF)</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="partThree">
        <p class="m-0 pt-3">All QEF shareholders complete lines 6a through 7c. If you are making Election B, also complete lines 8a through 9c. (See instructions.)</p>
        <div class="section-content pt-2 ap-form-field-my-wrapper">
          <ol class="list-content list-spacing" start="6">

            <li class="li-apdix-a" style="counter-reset: count 5;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter your pro rata share of the ordinary earnings of the QEF.</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'proRateShares'"> {{ proRateShares | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 5;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the portion of line 6a that is included in income under section 951 or that may be excluded under section 1293(g)</p>
                </div>
                <div class="col-md-2" >
                  <span [id]="'portionLine6a'"> {{ portionLine6a | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 5;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Subtract line 6b from line 6a. Enter this amount on your tax return as ordinary income</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'ordinaryIncome'">{{ OrdinaryIncome | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-a" style="counter-reset: count 6;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter your pro rata share of the total net capital gain of the QEF</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'proRateSharesNetGain'"> {{ proRateSharesNetGain | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 6;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the portion of line 7a that is included in income under section 951 or that may be excluded under section 1293(g)</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'portionLine7a'"> {{ portionLine7a | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 6;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Subtract line 7b from line 7a. This amount is a net long-term capital gain. Enter this amount in Part III of the Schedule D used for your income tax return.(See instructions.)</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'netCapitalGain'">{{ NetCapitalGain | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-a" style="counter-reset: count 7;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Add lines 6c and 7c</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'line6cAnd7cSum'">{{ Line6cAnd7cSum | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 7;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the total amount of cash and the fair market value of other property distributed or deemed distributed to you during the tax year of the QEF. (See instructions)</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'cashPropertyDeemedDistributedCash'">
                    {{ cashPropertyDeemedDistributedCash | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 7;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the portion of line 8a not already included in line 8c that is attributable to shares in the QEF that you disposed of, pledged, or otherwise transferred during the tax year</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'portionLine8c'"> {{ portionLine8c | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-d" style="counter-reset: count 7;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Add lines 8b and 8c</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'line8bAnd8cSum'">{{ Line8bAnd8cSum | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-e" style="counter-reset: count 7;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Subtract line 8d from line 8a, and enter the difference (if zero or less, enter amount in brackets)
                  Important: If line 8e is greater than zero, and no portion of line 6a or 7a is includible in income under section 951, you may make Election B with respect to the amount on line 8e</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'line8dAnd8aSubt'">{{ Line8dAnd8aSubt | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-a" style="counter-reset: count 8;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the total tax for the tax year. (See instructions.)</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'totalTax'"> {{ totalTax | currency: '$':true: '1.0-0' }}
                  </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 8;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Enter the total tax for the tax year determined without regard to the amount entered on line 8e.</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'totalTaxWithoutRegard'"> {{ totalTaxWithoutRegard | currency: '$':true: '1.0-0' }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 8;">
              <div class="row pl-3">
                <div class="col-md-10">
                  <p>Subtract line 9b from line 9a. This is the deferred tax, the time for payment of which is extended by making Election B. (See instructions.)</p>
                </div>
                <div class="col-md-2">
                  <span class="pl-3" [id]="'line9bAnd9aSubt'">{{ Line9bAnd9aSubt | currency: '$':true: '1.0-0' }}</span>
                </div>
              </div>
            </li>

          </ol>
        </div>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part IV&nbsp;&nbsp;</strong>
            <strong>Gain or (Loss) From Mark-to-Market Election (See instructions.)</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="partFour">
        <div class="section-content ap-form-field-my-wrapper">
          <ol class="list-content list-spacing" start="10">

            <li class="li-apdix-a" style="counter-reset: count 9;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter the fair market value of your PFIC stock at the end of the tax year.</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'fairMarketValue'"> {{ fairMarketValue | number }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 9;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter your adjusted basis in the stock at the end of the tax year</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'adjustedBasisStock'"> {{ adjustedBasisStock | number }} </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 9;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Subtract line 10b from line 10a. If a gain, do not complete lines 11 and 12. Include this amount as ordinary income on your tax return. If a loss, go to line 11.</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'ordinaryIncomeOnTaxReturn'">{{ OrdinaryIncomeOnTaxReturn | number }}</span>
                </div>
              </div>
            </li>

            <li>
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter any unreversed inclusions (as defined in section 1296(d))</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'unreversedInclusion'"> {{ unreversedInclusion | number }} </span>
                </div>
              </div>
            </li>

            <li>
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter the loss from line 10c, but only to the extent of unreversed inclusions on line 11. Include this amount as an ordinary loss on your tax return</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'lossLine10c'"> {{ lossLine10c | number }}
                  </span>
                </div>
              </div>
            </li>

            <li>
              <b class="pl-4">If you sold or otherwise disposed of any section 1296 stock (see instructions) during the tax year:</b>
            </li>

            <li class="li-apdix-a mt-2" style="counter-reset: count 12;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>	Enter the fair market value of the stock on the date of sale or disposition</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'fairMarketDispositionValue'"> {{ fairMarketDispositionValue | number }}
                  </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 12;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter the adjusted basis of the stock on the date of sale or disposition</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'adjustedBasisDispositionStock'"> {{ adjustedBasisDispositionStock | number }}
                  </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 12;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Subtract line 13b from line 13a. If a gain, do not complete line 14. Include this amount as ordinary income on your tax return. If a loss, go to line 14</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'line13bAnd13aSubt'">{{ Line13bAnd13aSubt | number }}</span>
                </div>
              </div>
            </li>

            <li class="li-apdix-a" style="counter-reset: count 13;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>	Enter any unreversed inclusions (as defined in section 1296(d))</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'unreversedInclusionSection1296'"> {{ unreversedInclusionSection1296 | number }}
                  </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-b" style="counter-reset: count 13;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter the loss from line 13c, but only to the extent of unreversed inclusions on line 14a. Include this amount as an ordinary loss on your tax return. If the loss on line 8c exceeds unreversed inclusions on line 14a, complete line 14c</p>
                </div>
                <div class="col-md-2">
                  <span [id]="'lossLine13c'"> {{ lossLine13c | number }}
                  </span>
                </div>
              </div>
            </li>

            <li class="li-apdix-c" style="counter-reset: count 13;">
              <div class="row pl-4">
                <div class="col-md-10">
                  <p>Enter the amount by which the loss on line 13c exceeds unreversed inclusions on line 14a. Include this amount on your tax return according to the rules generally applicable for losses provided elsewhere in the Code and regulations
                    Note: See instructions in case of multiple dispositions.
                  </p>
                </div>
                <div class="col-md-2">
                  <span [id]="'lossLine13cExceedInclusion'" > {{ lossLine13cExceedInclusion | number }}
                  </span>
                </div>
              </div>
            </li>

          </ol>
        </div>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part V&nbsp;&nbsp;</strong>
            <strong>Distributions From and Dispositions of Stock of a Section 1291 Fund</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="row partFive">
        <ng-container ng-if="attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'].length >= 1">
          <div *ngFor="let val of attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'];let i=index" class="propDiv">

            <div class="tab-section-content section-content propTbl pl-5">

              <ol class="list-content" start="15">

                <li class="li-apdix-a" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Enter your total distributions from the section 1291 fund during the current tax year with respect to the applicable stock. If the holding period of the stock began in the current tax year, see instructions</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-b" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Enter the total distributions (reduced by the portions of such distributions that were excess distributions but not included in income under section 1291(a)(1)(B)) made by the fund with respect to the applicable stock for each of the 3 years preceding the current tax year (or if shorter, the portion of the shareholder's holding period before the current tax year)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockDistributionsIn3PrecedingTY'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockDistributionsIn3PrecedingTY'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-c" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p class="mb-2">Divide line 15b by 3. Enter number of preceding tax years if less than 3 years --->
                        <span class="p-1">{{ attMap['precedingYears'] == undefined ? ''
                        : (attMap['precedingYears'][i] | number) }}</span> (See instructions)</p>

                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockAvgDistribution3PrecedingTY'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockAvgDistribution3PrecedingTY'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-d" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Multiply line 15c by 125% (1.25)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-e" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Subtract line 15d from line 15a. This amount, if more than zero, is the excess distribution with respect to the applicable stock. If zero or less and you did not dispose of stock during the tax year, do not complete the rest of Part V. See instructions if you received more than one distribution during the current tax year. Also, see instructions for rules for reporting a nonexcess distribution on your income tax return</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockTotalExcessDistribution'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockTotalExcessDistribution'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-f" style="counter-reset: count 14;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Enter gain or loss from the disposition of stock of a section 1291 fund or former section 1291 fund. If a gain, complete line 16. If a loss, show it in brackets and do not complete line 16</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockGainLossFromDispositionOfStock'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockGainLossFromDispositionOfStock'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-a" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-12">
                      <span>Attach a statement for each distribution and disposition. Show your holding period for each share of stock or block of shares held. Allocate the excess distribution to each day in you holding period. Add all amounts that are allocated to days in each tax year</span>
                    </div>
                    <div class="col-md-12 mt-2 mb-2">
                      <!-- <textarea rows="5" cols="80" readonly class="txtborder w-100">
                        {{ attMap['TotalCashAndDistributionsreferenceDocumentId'] == undefined ? '' : attMap['TotalCashAndDistributionsreferenceDocumentId'][i] }}
                      </textarea> -->
                      <div>
                        {{ attMap['TotalCashAndDistributionsreferenceDocumentId'] == undefined ? '' : attMap['TotalCashAndDistributionsreferenceDocumentId'][i] }}
                      </div>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-b" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Enter the total of the amounts determined in line 16a that are allocable to the current tax year and tax years before the foreign corporation became a PFIC (pre-PFIC tax years). Enter these amounts on your income tax return as other income.</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-c" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Enter the aggregate increases in tax (before credits) for each tax year in your holding period (other than the current tax year and pre-PFIC years). (See instructions.)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-d" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Foreign tax credit. (See instructions.)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockForeignTaxCredit'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockForeignTaxCredit'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-e" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Subtract line 16d from line 16c. Enter this amount on your income tax return as "additional tax". (See instructions.)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

                <li class="li-apdix-f" style="counter-reset: count 15;">
                  <div class="row pl-2">
                    <div class="col-md-10">
                      <p>Determine interest on each net increase in tax determined on line 16e using the rates and methods of section 6621. Enter the aggregate amount of interest here. (See instructions.)</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax'] == undefined ? ''
                        : (attMap['DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax'][i] | currency: '$':true: '1.0-0') }}</span>
                    </div>
                  </div>
                </li>

              </ol>
            </div>

          </div>
        </ng-container>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part VI&nbsp;&nbsp;</strong>
            <strong>Status of Prior Year Section 1294 Elections and Termination of Section 1294 Elections</strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="row partSix">
        <ng-container ng-if="attMap['ElectionStatusTaxYearOfOutstandingElection'].length >= 1">
          <div *ngFor="let val of attMap['ElectionStatusTaxYearOfOutstandingElection'];let i=index" class="propDiv">

            <div class="tab-section-content section-content propTbl pl-5">

              <ol class="list-content" start="17">
                <li style="counter-reset: count 16;">
                  <div class="row">
                    <div class="col-md-10">
                      <p>Tax Year of outstanding election</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusTaxYearOfOutstandingElection'] == undefined ? ''
                        : attMap['ElectionStatusTaxYearOfOutstandingElection'][i] }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Undistributed earnings to which the election relates</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusUndistributedEarnings'] == undefined ? ''
                        : attMap['ElectionStatusUndistributedEarnings'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Deferred Tax</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusDeferredTax'] == undefined ? ''
                        : attMap['ElectionStatusDeferredTax'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Interest accrued on deferred tax (line 19) as of the filing date</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusInterestAccruedOnDeferredTax'] == undefined ? ''
                        : attMap['ElectionStatusInterestAccruedOnDeferredTax'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Event terminating election</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusEventTerminatingElection'] == undefined ? ''
                        : attMap['ElectionStatusEventTerminatingElection'][i] }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Earnings distributed or deemed distributed during the tax year</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusEarningsDistributedDuringTheTY'] == undefined ? ''
                        : attMap['ElectionStatusEarningsDistributedDuringTheTY'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Deferred tax due with this return</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusDeferredTaxDueWithThisReturn'] == undefined ? ''
                        : attMap['ElectionStatusDeferredTaxDueWithThisReturn'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Accrued interest due with this return</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusAccruedInterestDueThisReturn'] == undefined ? ''
                        : attMap['ElectionStatusAccruedInterestDueThisReturn'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Deferred tax outstanding after partial termination of election</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusDeferredTaxAfterPartialTerm'] == undefined ? ''
                        : attMap['ElectionStatusDeferredTaxAfterPartialTerm'][i] | number }}</span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="row">
                    <div class="col-md-10">
                      <p>Interest accrued after partial termination of election</p>
                    </div>
                    <div class="col-md-2">
                      <span>{{ attMap['ElectionStatusIntAccruedAfterPartialTerm'] == undefined ? ''
                        : attMap['ElectionStatusIntAccruedAfterPartialTerm'][i] | number }}</span>
                    </div>
                  </div>
                </li>

              </ol>

            </div>

          </div>
        </ng-container>
      </div>

      <table style="width:100%">
        <tbody>
          <tr>
            <td class="footer-left">
              LEIDs: <span class="template-color-blue"> {{stmtData.primaryleid == null ? '': stmtData.primaryleid}} </span>
              / <span class="template-color-blue"> {{stmtData.secondaryleid == null ? '': stmtData.secondaryleid}} </span>
            </td>
            <td class="footer-right"></td>
          </tr>
          <tr>
            <td class="footer-left">
              Statement ID: <span class="template-color-blue"> {{stmtData.statementid}} </span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

<ng-template #tooltip1>
  <div class="tooltip-inner a-p-10 d-block a-min-width-360">
    <div class="customize-tooltip-text font-weight-normal text-left">
      Who may make the election. A U.S. person that is a shareholder of a corporation that fails to qualify as a qualifying insurance corporation (QIC) (as defined in section 1297(f)(1)) solely because its applicable insurance liabilities make up 25% or less of its total assets may elect to treat the stock as stock of a qualifying insurance corporation if: 1. The corporations applicable insurance liabilities make up at least 10% of its total assets; and 2. Based on the applicable facts and circumstances, the corporation is predominantly engaged in an insurance business, and its failure to satisfy the 25% threshold is due solely to runoff-related or rating-related circumstances involving such insurance business. The preamble to the proposed regulations provides that until the regulations are published as final, an electing U.S. person may continue to follow the instructions for Form 8621, which require the U.S. person to check a box regarding the qualifying insurance corporation exception, and provide the name, address, and taxpayer ID number for the foreign corporation. A U.S. person need not complete any other part of Form 8621 if the U.S. person is only filing the form to make the election.
    </div>
  </div>
</ng-template>
