<div class="d-flex">
    <div class="a-navigation navigation-fourth-bar">
        <gtw-screens (load-screens)="loadHistoryScreen($event)"></gtw-screens>
        <mat-progress-bar *ngIf="appNavigation.length == 0"
            color="primary"
            mode="indeterminate">
        </mat-progress-bar>
        <div class="custom-scroll-container">
            <ul class="navigation-list custom-scroll" #ullist>
                <li class="navigation-list-item" 
                    [ngClass]="{'active' : selectedDomainName == 'Home'}" 
                    (click)="onClickHomeDomain()" 
                    tabindex="0" (keydown)="onClickHomeDomain()">
                        
                    <span [class]="'z-one-icon appkiticon icon-home-fill'"></span>
                    <div>Home</div>    
                </li>
                <ng-container  *ngFor="let domain of appNavigation" >
                    <li class="navigation-list-item" *ngIf="domain.label != 'Home'"
                        [ngClass]="{'active' : selectedDomainName == domain.label}" 
                        (click)="onClickDomain(domain, domain.label)" 
                        tabindex="0" (keydown)="onKeyDown(domain, $event)">
                            
                            <span *ngIf="domain.icon_css"
                            [class]="'z-one-icon appkiticon ' + domain.icon_css.toLowerCase()"></span>
                            <span *ngIf="!domain.icon_css" [class]="'z-one-icon appkiticon icon-ticket-outline'"></span>
                            <div>{{domain.label}}</div>    
                    </li>
                </ng-container>
            </ul>
        </div>
        <!-- <gtw-app-footer></gtw-app-footer> -->
        <div class="a-footer">
            <div class="d-flex align-items-center">
                <div class="a-panel-wrapper p-2">
                    <div class="a-pwc-logo-grid a-md"></div>
                </div>
            </div>
        </div>
        
    </div>    
<div>


