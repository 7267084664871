import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[getMenuPos]'
})
export class SmartMenuPosDirective {

  @Input() menuPosMode: string = 'fixed'; // position fixed or absolute
  @Output() menuClick: EventEmitter<MenuPosPayload>;

  constructor(private elementRef: ElementRef) {
    this.menuClick = new EventEmitter<MenuPosPayload>();
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();

    const top: number = this.menuPosMode === 'fixed' ? this.elementRef.nativeElement.getBoundingClientRect().top : 10;
    const left: number = this.menuPosMode === 'fixed' ? this.elementRef.nativeElement.getBoundingClientRect().left : 0;
    if (window.innerHeight - event.clientY > 200) {
      this.menuClick.emit({expandAbove: false, offsetTop: top + 15, offsetLeft: left, mode: this.menuPosMode});
    } else {
      this.menuClick.emit({expandAbove: true, offsetTop: top + 15, offsetLeft: left, mode: this.menuPosMode});
    }
  }
}

export interface MenuPosPayload {
  expandAbove: boolean;
  offsetTop: number;
  offsetLeft: number;
  mode: string;
}
