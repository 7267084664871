import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CreateDisclosureSharedService} from '../../create-disclosure-shared.service';
import {TemplateHeaderService} from './template-header.service';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-header',
  templateUrl: './template-header.component.html',
  styleUrls: ['./template-header.component.scss']
})
export class TemplateHeaderComponent implements OnInit, OnChanges {

  @Input() parentFormGroup!: FormGroup;
  @Input() allowOptional!: boolean; // optional currently limited to section 351 - form id 184
  @Input() isBulk!: boolean; // set true for bulk upload and bulk supersede
  @Input() overwriteFormName!: string;
  @Input() extraLine!: string;
  @Input() dropDownOptions!: any[];
  @Input() sameLineText!: string;
  @Output() consolGroupChanged: EventEmitter<DSTConsolGroup>;
  @Output() formOptionChanged: EventEmitter<any>;

  consolGroups: any[] = [
    {
      data: []
    }
  ];

  taxYear!: number;
  formName!: string;
  placeholderText: string;
  taxReturnDetails: string;

  constructor(private headerService: TemplateHeaderService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService) {
    this.consolGroupChanged = new EventEmitter<DSTConsolGroup>();
    this.formOptionChanged = new EventEmitter<any>();
    this.placeholderText = 'Please specify a valid reporting Entity to determine the Consolidated Group';
    this.taxReturnDetails = 'CONSOLIDATED FEDERAL INCOME TAX RETURN';
  }

  ngOnInit(): void {
    this.taxYear = this.sharedService.getActiveFormState()?.taxYear;
    this.formName = this.sharedService.getActiveFormState()?.formName;
    if (this.overwriteFormName) {
      this.formName = this.overwriteFormName;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  loadConsolGroups(leid: string,dcnId: number): void {
    this.headerService.getHeaderDropdown(leid).subscribe((data: DSTConsolGroup[]) => {
      data.forEach((d: DSTConsolGroup, index: number) => {
        d.value = d.DCNID;
        d.text = d.DCNNAME;
        d.selected = ((dcnId === null || dcnId === undefined) ? (index === 0) : dcnId === d.DCNID) ; // always select first element by default
      });
      this.consolGroups = [{data: data}];
      const selected: DSTConsolGroup = this.consolGroups[0].data.find((c: DSTConsolGroup, index: number) => c.selected);
      this.consolGroupChanged.emit(selected);
    }, (e: Error) => {
      this.dstSharedService.showAlert('error', e.message);
    });
  }

  clear(): void {
    this.consolGroups = [
      {
        data: []
      }
    ];
    this.consolGroupChanged.emit(undefined);
  }

  onConsolGroupChanged(event: any): void {
    this.consolGroupChanged.emit(event.selected[0]);
  }

  onOptionChanged(event: any): void {
    this.formOptionChanged.emit(event.selected[0].id);
  }

}

export interface DSTConsolGroup {
  DCNID: number;
  DCNNAME: string;
  DCNYEARID: number;
  TAXYEAR: number;
  TAXYEARID: number;
  selected?: boolean;
  value?: number;
  text?: string;
}
