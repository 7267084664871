import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreensComponent } from './screens.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {ButtonModule} from 'appkit-angular/button';
import { ListModule } from 'appkit-angular/list';
import { ReverseArrayPipe } from '../shared/pipes/reverse-array.pipe';
import { GtwDirectivesModule } from '../shared/_directives/gtw-directives.module';

@NgModule({
  declarations: [
    ScreensComponent,
    ReverseArrayPipe],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    ButtonModule,
    ListModule,
    GtwDirectivesModule
  ],
  exports: [ScreensComponent]
})
export class ScreensModule { }
