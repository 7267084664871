import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec367b-id-192',
  templateUrl: './template-sec367b-id-192.component.html',
  styleUrls: ['../template-shared.scss', './template-sec367b-id-192.component.scss']
})
export class TemplateSec367bIdMinus192Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formMinus192!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  beginDate!: Date;
  endDate!: Date;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  target!: DSTEntityDetails | null;
  DisplayDate: any = '';
  section4text: string = '';
  showDREMsg: boolean = false;
  DREMsg: string = '';
  shares: string = '';
  stockType304: string = '';
  entityNameChangeOnDate: boolean = false;
    nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.editMode = false;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.formMinus192 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        DisplayDate: [''],
        section4text: [''],
      }),
    });
  }

  dummyFunc(event: any): void {

  }

  ngAfterViewInit(): void {
    if (this.sharedService.getFormStateByFormId(-192)?.isChild) {
      this.sharedService.getLoadStatementData(-192).subscribe((data: StmtData) => {
        this.sharedService.setFormData(data, -192);
        this.loadStmtData();
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      });
    } else {
      this.loadStmtData();
    }
  }

  private loadStmtData(): void {
    const data: StmtData | undefined = this.sharedService.getFormData(-192);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);

      this.editMode = true;

      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.secondaryleid);
              break;
            case 1:
              element.fillLEID(data.primaryleid);
              break;
            case 2:
              if (this.attMap['SHdspTransferorLEID'] && this.attMap['SHdspTransferorLEID'][0]) {
                this.sharedService.getDSTEntityInfo(this.attMap['SHdspTransferorLEID'][0])
                  .subscribe((data: any) => {
                    element.fillLEID(data[0].LEID);
                  }, () => {

                  }, () => {

                  });
              }
              break;
            case 3:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      },1000);

      this.populateFields(data);

    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;
      setTimeout(() => {
        this.leidComponents.toArray()[1].fillLEID(this.sharedService.leid);
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      }
    });
    if (this.attMap['section4text'] && this.attMap['section4text'][0]) {
      this.section4text = this.attMap['section4text'][0];
    }
    if (this.attMap['DisplayDate'] && this.attMap['DisplayDate'][0] && this.attMap['DisplayDate'][0] !== '') {
      this.DisplayDate = new Date(this.attMap['DisplayDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    }
    if (this.attMap['Shares'] && this.attMap['Shares'][0]) {
      this.shares = this.attMap['Shares'][0];
    }
    if (this.attMap['StockType304'] && this.attMap['StockType304'][0]) {
      this.stockType304 = this.attMap['StockType304'][0];
    }

    this.cdref.detectChanges();
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.DisplayDate = event.startDate.format('YYYY-MM-DD');
        this.entityNameChangeOnDate = true;
        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!, this.target!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.sharedService.checkForEntityNameChange(this.sharedService.getFormData()?.statementid.toString(),
          leids,
          this.DisplayDate,
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!, this.target!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });
        break;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!, this.target!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.DisplayDate && this.transferor?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.DisplayDate,
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferor!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferor!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    if (!selectedTransferor) {
      return;
    }
    if (this.DisplayDate && this.transferee?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.DisplayDate,
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferee!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferee!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.DisplayDate){
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!, this.target!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onTargetChanged(selectedTarget: DSTEntityDetails | null): void {
    this.target = selectedTarget;
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.target!, this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;
    if (this.DisplayDate && this.target?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.target.LEID,
        this.DisplayDate,
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.target!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.target!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.DisplayDate){
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee, this.target])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': '192',
        'primaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': '',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'attachmentFileName': '',
      };

      const jsonObject = {
        'CheckFor351Transfer': '1',
        'DisplayDate': this.DisplayDate !== null && this.DisplayDate !== '' ? this.DisplayDate.startDate ? this.DisplayDate.startDate.format('YYYY-MM-DD') : this.DisplayDate : '',
        'section4text': this.section4text.toString(),
        'shTransferorLeid': this.target ? this.target?.LEID.toString() : '',
        'SHdspTransferorLEID': this.target ? this.target?.ENTITYID.toString() : '',
        'Shares': this.shares || '',
        'StockType304': this.stockType304 || '',
      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }

  }

  isFormValid(placeholder: boolean): boolean {
    if (placeholder) {
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }
    } else {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }
      if (this.DisplayDate === '' || this.DisplayDate === null) {
        this.dstSharedService.showAlert('error', 'Please Enter a Date');
        return false;
      }
    }

    return true;
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }
}


