<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" (onSaveClick)="save()" (onCancelClick)="cancel()"
  [disableS3Upload]="false" (onS3UploadClick)="s3Upload()" 
  [isFiled]="formData?.isfiled" [isQAR]="formData?.isqar"
  [disabledS3]="s3Uploading" [statementId]="formData?.statementid">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp367bform193">
  <div>
    <div align=center>
      <b>
      <span class="template-color-blue">
        {{formData?.dcnname}}
      </span>
      </b>
    </div>
    <div align="center">
      <B>
      <span class="template-color-blue">
        {{formData?.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br/>
        STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS
      </span>
      </B>
    </div>
    <br/>
    <div class="statement-body">
      <p style="margin-bottom: 4rem;">
        Parties: <br>
        <span class="template-color-blue">{{primaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.primaryein == null ? 'N/A': formData?.primaryein}}</span><span> ,Transferor, a </span><span class="template-color-blue">{{formData?.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span><span>; and</span><br>
        <span class="template-color-blue">{{secondaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.secondaryein == null ? 'N/A': formData?.secondaryein}}</span><span> ,Transferee, a </span><span class="template-color-blue">{{formData?.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}</span><span>.</span>
      </p>
      <ol class="ol-lower-roman">
        <li>
          <span class="position-relative">The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the "Code") applies.</span>
        </li>
        <li>
          <span class="position-relative">The following is a description of the exchange: <br><span class="template-color-blue">{{(attMap.PointII && attMap.PointII[0] !== '') ? attMap.PointII[0] : 'None.'}}</span></span>
        </li>
        <li>
          <span class="position-relative">The following is a description of any stock, securities, or other consideration transferred or received in the exchange: <br><span class="template-color-blue">{{(attMap.PointIII && attMap.PointIII[0] !== '') ? attMap.PointIII[0] : 'None.'}}</span></span>
        </li>
        <li>
          <span class="position-relative">The following describes any amount(s) required under the section 367(b) regulations to be taken into account as income or loss or as an adjustment (including an adjustment under Section 1.367(b)-7 or 1.367(b)-9, as applicable) to basis, earnings and profits, or other tax attributes as a result of the exchange: <br><span class="template-color-blue">{{attMap.PointIV}}</span></span>
        </li>
        <li>
          <span class="position-relative">For information required pursuant to regulations under Code Section 368, see the separate statement.</span>
        </li>
        <li>
          <span class="position-relative">Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or the regulations under those sections has been provided with this tax return on Form 5471, Information Return of U.S. Persons with Respect to Certain Foreign Corporations.</span>
        </li>
        <li>
          <span class="position-relative">Not applicable.</span>
        </li>
      </ol>
    </div>
    
    <table style="width:100%">
      <tbody>
      <tr>
        <td class="footer-left">
          <span>LEIDs: </span>
          <span class="template-color-blue">{{formData?.primaryleid}} / {{formData?.secondaryleid}}</span>
        </td>
        <td class="footer-right" *ngIf="formData?.branchleid">
          <span>Branch LEID: </span>
          <span class="template-color-blue">{{formData?.branchleid}}</span>
        </td>
      </tr>
      <tr>
        <td class="footer-left">
          <span>Statement ID: </span>
          <span class="template-color-blue"> {{formData?.statementid}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
