import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Submission } from "../../models/submission.model";



@Component({
  selector: 'submission-detail',
  templateUrl: './submission-detail.component.html',
  styleUrls: ['./submission-detail.component.scss']
})
export class SubmissionDetailComponent implements OnInit, OnDestroy {
  @Input() submission!: Submission;
  @Input() taxYear: string = "2020";

  listName: any;

  ngOnInit(): void { }

  ngOnDestroy(): void {
  }

  collapseClick(): void {

    let elem: any = document.getElementsByClassName('menu-collapsed');
    for (let i = 0; i < elem.length; i++) {
      elem[i].classList.toggle('d-none');
    }
    elem = document.getElementsByClassName('sidebar-submenu');
    for (let i = 0; i < elem.length; i++) {
      elem[i].classList.toggle('d-none')
    }
    elem = document.getElementsByClassName('submenu-icon');
    for (let i = 0; i < elem.length; i++) {
      elem[i].classList.toggle('d-none')
    }
    elem = document.getElementById('sidebar-container');
    elem.classList.toggle('sidebar-collapsed');
    elem.classList.toggle('sidebar-expanded');
  }
  
    getDynamicRoute(route:string){
      return JSON.parse(`{ "outlets": { "${this.submission.SUBMISSION_ID}": "${route}"} }`);
    }
}