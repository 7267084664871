<ng-template [ngIf]="gtwInput && gtwInput.type === 'add'" [ngIfElse]="deleteConfirmation">
  <form [formGroup]="categoriesForm" >
    <div class="form-group">
      <label for="category" class="a-form-label">Category</label>
      <input type="text" class="a-text-input a-input-rp" id="category" formControlName="category" required>
    </div>
    <div class="form-group">
      <label for="cbc_id" class="a-form-label">CBC ID</label>
      <input type="text" class="a-text-input a-input-rp" id="cbc_id" formControlName="cbc_id" required>
    </div>
    <div class="full-width text-right">
      <ap-button [btnText]="'Submit'" [isDisabled]="categoriesForm.invalid" [config]="{isPrimary: true}" (onBtnClick)="onSubmit()"></ap-button>
    </div>
  </form>

</ng-template>
<ng-template #deleteConfirmation>

  <h4 class="a-h4">Are you sure you want to delete this category?</h4>

  <div class="full-width text-right">
    <ap-button [btnText]="'Yes'" class="a-mr-10" [config]="{isPrimary: true}" (onBtnClick)="delete()"></ap-button>
    <ap-button [btnText]="'No'"  [config]="{isPrimary: true}" [btnType]="'a-btn-gray'" (onBtnClick)="cancel()"></ap-button>
  </div>
</ng-template>
