import { HttpResponse } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { isObject } from 'lodash';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { S3FileUploadService } from './s3-file-upload.service';

export interface S3FileUploadInput extends S3FileUploadRequest {
  baseUrl: string;
  readOnlyS3Paths: string;
  canEditPath: boolean;
  onlyCore: boolean;
  hidePath: boolean;
  title?:string;
}

interface S3FileUploadRequest {
  fileKey?: string;
  path: string;
  rootFolder: string;
  clientKey?: string;
}

@Component({
  selector: 'gtw-s3-file-upload',
  templateUrl: './s3-file-upload.component.html',
  styleUrls: ['./s3-file-upload.component.scss'],
})
export class S3FileUpload implements OnInit {
  rootFolder: string = 'gtw';
  isNewFile: boolean = false;
  alertDisplay: boolean = false;
  alertSuccessDisplay: boolean = false;
  private readonly DEFAULT_TITLE = 'Upload file to S3';
  title: string = this.DEFAULT_TITLE;

  @Output('on-s3-file-upload')
  onFileUpload: EventEmitter<string> = new EventEmitter<string>();

  @Input('s3FileUploadInput')
  set setS3FileUploadInput(s3FileUploadInput: string | S3FileUploadInput) {

    if(isObject(s3FileUploadInput)){
      this.s3FileUploadInput = s3FileUploadInput;
    }else{
      try {
        this.s3FileUploadInput = JSON.parse(s3FileUploadInput);
        this.init(this.s3FileUploadInput);
      } catch (e) {
        console.log(
          'base URLs Params not stable. Still waiting to get resolved.'
        );
      }
    }

  }
  s3FileUploadInput: S3FileUploadInput = {
    baseUrl: '/admin',
    clientKey: '0',
    path: '/preformatteddisclosure/',
    rootFolder: this.rootFolder,
    readOnlyS3Paths: '/common/tax-forms/pdf,/gtw/binaries',
    canEditPath:false,
    onlyCore: false,
    hidePath: false,
    title: this.DEFAULT_TITLE
  };

  uploadAPIEndPoint: string = '';
  breadCrumbLinks: string[] = [];
  newFolder: string = '';

  description: string = '';
  fileuploadConfig = {
    multiple: false,
    size: false,
    trigger: true,
    isGreyBackground: false,
  };
  private readonly REPLACE_TEXT =
    'Replace an existing file to the path shown above';
  private readonly NEW_UPLOAD_TEXT =
    'Upload a new file to the path shown above';

  s3FileUploadRequest!: S3FileUploadRequest;
  disabledText: string | null = null;
  isClientSpecificFile: boolean = true;

  constructor(private s3UploadService: S3FileUploadService) {}

  ngOnInit(): void {
    //local only
    // this.init(this.s3FileUploadInput);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (typeof changes.setS3FileUploadInput.currentValue !== 'string') {
      try {
        if (this.s3FileUploadInput) this.init(this.s3FileUploadInput);
      } catch (err) {
        console.error('JSON parse Syntax Error', err);
      }
    }

  }

  private init(s3FileUploadInput: S3FileUploadInput) {
    this.title = s3FileUploadInput.title || this.DEFAULT_TITLE;

    this.description = s3FileUploadInput.fileKey
      ? this.REPLACE_TEXT
      : this.NEW_UPLOAD_TEXT;

    this.isNewFile = !s3FileUploadInput.fileKey;
    this.isClientSpecificFile = !s3FileUploadInput.onlyCore;

    this.rootFolder =
      s3FileUploadInput.path?.split('/').filter((e) => e != '')[0] ||
      this.rootFolder;

    this.breadCrumbLinks = s3FileUploadInput.path
      ? s3FileUploadInput.path
          .split('/')
          .filter(
            (e: string) =>
              e !== '' &&
              e !== this.rootFolder &&
              e != this.s3FileUploadInput.fileKey?.toString()
          )
      : [];

    this.uploadAPIEndPoint =
      s3FileUploadInput.baseUrl + '/uploadOrReplaceFileinS3';

    this.s3FileUploadRequest = {
      path: this.breadCrumbLinks.join('/'),
      fileKey: s3FileUploadInput.fileKey || '',
      rootFolder: this.rootFolder,
      clientKey: s3FileUploadInput.clientKey
    };

    if(!this.isClientSpecificFile || s3FileUploadInput.clientKey == null){
      delete this.s3FileUploadRequest.clientKey;
    }

    this.checkValidation();
  }

  onClickButtonEvent(event: PointerEvent) {
    if (!this.newFolder) return;

    this.breadCrumbLinks.push(this.newFolder);
    this.newFolder = '';

    this.s3FileUploadRequest.path = this.breadCrumbLinks.join('/');

    this.checkValidation();
  }

  private checkValidation() {
    if (
      this.s3FileUploadInput.readOnlyS3Paths
        .split(',')
        .some((path) => ('/' + this.rootFolder + '/' +this.s3FileUploadRequest.path).includes(path))
    )
      this.disabledText =
        'This is a restricted folder. Please use some other path.';
    else if (this.breadCrumbLinks.length === 0)
      this.disabledText = 'Please enter a subfolder to upload the file';
    else if (
      !this.s3FileUploadRequest.path.includes(
        this.s3FileUploadInput.clientKey?.toString() || ''
      ) &&
      this.rootFolder !== this.s3FileUploadInput.clientKey?.toString() &&
      this.isClientSpecificFile &&
      this.isNewFile
    ) {
      this.disabledText = 'Please include client key in the path';
    } else {
      this.disabledText = null;
    }
  }

  onClientSpecificFlagChanged($event: any) {
    this.isClientSpecificFile = !this.isClientSpecificFile; // applying value as the checkbox change event is called before value is changed

    if(!this.isClientSpecificFile || this.s3FileUploadInput.clientKey == null){
      delete this.s3FileUploadRequest.clientKey;
    }else{
      this.s3FileUploadRequest.clientKey = this.s3FileUploadInput.clientKey;
    }

    this.checkValidation();
  }

  navigateToMapping(event: { uploader: any; fileInfoList: any[] }) {

    if(!this.isNewFile && event.fileInfoList.length > 0){
      this.alertSuccessDisplay = true;
    }

    this.onFileUpload.emit(event.fileInfoList[0]);

  }

  erroredFun(event: any) {
    console.log(event);
  }

  downloadFileFromS3() {
    this.s3UploadService
      .downloadFile(
        this.s3FileUploadInput.fileKey,
        this.s3FileUploadInput.clientKey,
        this.s3FileUploadInput.baseUrl
      )
      .subscribe((response: HttpResponse<any>) => {
        if (response.body?.callSuccess === '0') {
          this.alertDisplay = true;
          return;
        }
      },()=>this.alertDisplay = true);
  }
}
