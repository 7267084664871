<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" [disabled]="false" (onSaveClick)="save($event);"
  (onCancelClick)="cancel()">
</dst-create-disclosure-actions>

<div class="form-template-wrapper">
  <div [id]="'form235'">
    <dst-template-header (consolGroupChanged)="onConsolGroupChanged($event)">
    </dst-template-header>

    <!-- Section 0 -->
    <div id="part0" style="margin-top: 15px;">
      <dst-template-leid-selector class="required-field" [label]="'Please specify the LEID of the reporting entity '"
        [labelId]="'1'" (onSelectedLEChanged)="onReportingEntityChanged($event)">
      </dst-template-leid-selector>

      <div class="entity-details">
        <span class="ent-label">Entity Name:</span>
        <span class="template-color-blue">{{!transferor ? '' : transferor.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span class="ent-label">Federal ID:</span>
        <span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ? transferor.EIN : 'N/A')}}</span>
      </div>
    </div>

    <br />
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <!-- Section 1 -->
    <div class="section-content ap-form-field-my-wrapper" id="part1">

      <table>
        <tbody>

          <tr>
            <td class="statement-number-tbl-td">A</td>
            <td colspan="2">
              If you are filing more than one Form
              8886 with your tax return, sequentially number each Form 8886 and
              enter the statement number for this Form 8886.
              <span>
                (Sequential numbering for each Form 8886 and total number of forms filed will be completed
                by Corporate Tax).
              </span>
            </td>
          </tr>

          <tr>
            <td> </td>
            <td style="width: 75%;"> <div class="col-md-12 label-align"> Statement Number </div> </td>
            <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" class="number-field label-none"
              [(ngModel)]="formModel.statementNumber" [id]="'statementNumber'" [type]="'inputNumber'">
            </ap-form-field>
          </tr>

          <tr>
            <td></td>
            <td> <div class="col-md-12 label-align"> of </div> </td>
            <td class="pt-2">
              <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" class="number-field label-none"
                [(ngModel)]="formModel.totalStatementNumber" [id]="'totalStatementNumber'" [type]="'inputNumber'">
              </ap-form-field>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td">B</td>
            <td>
              <div class="col-md-12 pl-0">
                Enter the form number of the tax return to which this form is attached or related.
              </div>
            </td>
            <td class="pt-2">
              <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" class="number-field label-none"
                [(ngModel)]="formModel.formNumberOfTaxReturn" [id]="'formNumberOfTaxReturn'" [type]="'inputNumber'">
              </ap-form-field>
            </td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td>Enter the year of the tax return
              identified above.</td>
            <td class="pt-2">
              <ap-selector class="field-full-width" [items]="taxReturnYears"
                (onSelectedChange)="onForTaxYearChanged($event)" [type]="'SingleSelector'" [searchMethod]="'inline'">
              </ap-selector>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <div class="col-md-12 pl-0">
                Is this Form 8886 being filed with an amended tax return?
              </div>
            </td>
            <td class="pt-2">
              <ap-radio class="a-mx-10" [text]="'Yes'" [checked]="AttachedToAmendedReturn === true"
                (onCheck)="check2(true)" [name]="'radio-group-4'">
              </ap-radio>
              <ap-radio class="a-mx-10" [text]="'No'" [checked]="AttachedToAmendedReturn === false"
                (onCheck)="check2(false)" [name]="'radio-group-4'">
              </ap-radio>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td">C</td>
            <td class="pt-2">
              Check the box(es) that apply:
              <ap-checkbox class="a-mx-10" [text]="'Initial Year Filer'"
                [(ngModel)]="formModel.initialYearFiler">
              </ap-checkbox>
              <ap-checkbox class="a-mx-10" [text]="'Protective Disclosure'"
                [(ngModel)]="formModel.protectiveDisclosure">
              </ap-checkbox>
            </td>
            <td></td>
            <td></td>
          </tr>

        </tbody>
      </table>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <!-- Section 2 -->
    <div class="section-content ap-form-field-my-wrapper" id="part2">
      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">1a</td>
            <td>Name of reportable transaction</td>
          </tr>

          <tr>
            <td></td>
            <td colspan="2">
              <div class="a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field [inlineBlock]="true" [maxLength]="100" [withNumberValidation]="true"
                  [type]="'textarea'" [id]="'reportableTransactionInfoNameOfReportableTransaction'"
                  [(ngModel)]="formModel.reportableTransactionInfoNameOfReportableTransaction">
                </ap-form-field>
              </div>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td">1b</td>
            <td style="width: 75%;">Initial year participated in transaction</td>
            <td class="pt-2">
              <ap-selector class="field-full-width" [items]="taxYearsPT"
                (onSelectedChange)="onInitialYearParticipatedInTrChanged($event)" [type]="'SingleSelector'" [searchMethod]="'inline'">
              </ap-selector>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td" style="vertical-align: middle;">1c</td>
            <td style="vertical-align: middle;">Reportable transaction or tax shelter
              registration number (9 digits or 11 digits)</td>
            <td>
              <dst-custom-input class="pl-0 pt-2" [type]="'input'" [value]="formModel.reportableTransactionInfoTransactionOrTaxShelterNumber"
                [id]="'reportableTransactionInfoTransactionOrTaxShelterNumber'" [maxLength]="11"
                (valChange)="formModel.reportableTransactionInfoTransactionOrTaxShelterNumber = $event;">
              </dst-custom-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <!-- Section 3 -->
    <div class="section-content ap-form-field-my-wrapper" id="part3">
      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">2</td>
            <td class="pb-2">Identify the type of reportable transaction
              below. Check all boxes that apply.</td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td"></td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td>
                      a
                      <ap-checkbox class="a-mx-10" [text]="'Listed'" [(ngModel)]="formModel.listed">
                      </ap-checkbox>
                    </td>
                    <td>
                      c
                      <ap-checkbox class="a-mx-10" [text]="'Contractual Protection'"
                        [(ngModel)]="formModel.contractualProtection">
                      </ap-checkbox>
                    </td>
                    <td>
                      e
                      <ap-checkbox class="a-mx-10" [text]="'Transaction of interest'"
                        [(ngModel)]="formModel.transactionOfInterest">
                      </ap-checkbox>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      b
                      <ap-checkbox class="a-mx-10" [text]="'Confidential'"
                        [(ngModel)]="formModel.confidential">
                      </ap-checkbox>
                    </td>
                    <td>
                      d
                      <ap-checkbox class="a-mx-10" [text]="'Loss'"
                        [(ngModel)]="formModel.loss">
                      </ap-checkbox>
                    </td>
                    <td> &nbsp; </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td">3</td>
            <td style="width: 75%">
              If you checked box 2a or 2e, enter the published guidance number for the listed transaction or transaction of interest
            </td>
            <td>
              <ap-form-field [inlineBlock]="true" [id]="'publishedGuidanceNumber'" class="form-field"
                [(ngModel)]="formModel.publishedGuidanceNumber" [type]="'input'"
                [isDisabled]="formModel.listed !== true && formModel.transactionOfInterest !== true">
              </ap-form-field>
            </td>
          </tr>

          <tr>
            <td class="statement-number-tbl-td">4</td>
            <td>Enter the number of "same as or
              substantially
              similar" transactions reported on this form</td>
            <td class="pt-2">
              <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" [type]="'inputNumber'"
                [(ngModel)]="formModel.numberOfSameOrSimilarTr" [id]="'numberOfSameOrSimilarTr'"
                class="number-field label-none">
              </ap-form-field>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <!-- Section 4 -->
    <div class="section-content ap-form-field-my-wrapper" id="part4">

      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">5</td>
            <td>
              If you participated in the transaction through another entity, check all applicable boxes and provide the information below for the entity
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tab-section section-content">
        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
          (onBtnClick)="addTab('partFour')">
          <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
        </ap-button>

        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
          [btnSize]="'small'" [isDisabled]="partFourTabs.length > 1 && (partFourSelected.value === 0)"  (onBtnClick)="removeTab(partFourSelected.value, 'partFour')">
          <span class="appkiticon a-btn-icon icon-minus-fill"></span> Remove
        </ap-button>

        <mat-tab-group [selectedIndex]="partFourSelected.value" (selectedIndexChange)="selectTab($event, 'partFour', false)" animationDuration="0ms">
          <mat-tab *ngFor="let tab of partFourTabs; let index = index" [label]="tab.tab">

            <div class="tab-section-content">

              <table>
                <tbody>
                  <tr>
                    <td class="statement-number-tbl-td">a</td>
                    <td>Type of entity</td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <ap-checkbox class="a-mx-10" [id]="'typeOfEntityInformationPartnership'" [text]="'Partnership'"
                                [(ngModel)]="tab.typeOfEntityInformationPartnership">
                              </ap-checkbox>
                            </td>

                            <td>
                              <ap-checkbox class="a-mx-10" [id]="'typeOfEntityInformationSCorporation'" [text]="'S corporation'"
                                [(ngModel)]="tab.typeOfEntityInformationSCorporation">
                              </ap-checkbox>
                            </td>

                            <td>
                              <ap-checkbox class="a-mx-10" [id]="'typeOfEntityInformationTrust'" [text]="'Trust'"
                                [(ngModel)]="tab.typeOfEntityInformationTrust">
                              </ap-checkbox>
                            </td>

                            <td>
                              <ap-checkbox class="a-mx-10" [id]="'typeOfEntityInformationForeign'" [text]="'Foreign'"
                                [(ngModel)]="tab.typeOfEntityInformationForeign">
                              </ap-checkbox>
                            </td>

                          </tr>
                        </tbody>
                      </table>

                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>Please specify the LEID of the Entity that Participated in the Transaction</td>
                    <td>
                      <dst-template-leid-selector [labelId]="'participatingEntity_'+index" [id]="'participatingEntity'"
                        [label]="''" (onSelectedLEChanged)="onParticipatingLEIDChanged($event, index)" required
                        [ngClass]="{ 'required-field': tab.typeOfEntityInformationPartnership || tab.typeOfEntityInformationSCorporation
                          || tab.typeOfEntityInformationTrust || tab.typeOfEntityInformationForeign }">
                      </dst-template-leid-selector>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">b</td>
                    <td>Entity Name:</td>
                    <td>
                      <span class="template-color-blue"> {{!tab.participatingEntity ? '' : tab.participatingEntity.ENTITY}} </span>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">c</td>
                    <td>Employer identification number (EIN):</td>
                    <td>
                      <span class="template-color-blue"> {{!tab.participatingEntity ? '' : (tab.participatingEntity.EIN ? tab.participatingEntity.EIN : 'N/A')}} </span>
                    </td>
                  </tr>

                  <tr *ngIf="tab.participatingEntity && tab.participatingEntity.EIN === undefined">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>EIN Missing Reason:</td>
                    <td>
                      <ap-selector class="required-field ap-form-field-my-wrapper" [id]="'typeOfEntityInformationMissingEINReason'" [searchMethod]="'inline'"
                        [items]="reasonsEIN" [type]="'SingleSelector'" (onSelectedChange)="onInvInTrMissingEINChange($event, index)">
                      </ap-selector>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">d</td>
                    <td>
                      On the next two rows, enter either the Date Schedule K-1 received from entity or "none" if Schedule K-1 not received
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Date Schedule K-1 received from entity</td>
                    <td class="d-flex">
                      <ap-date-picker format="yyyy-MM-DD" type="singleCalendar"
                        size="medium" [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true"
                        (click)="onDPOpen()" [(ngModel)]="tab.typeOfEntityInformationScheduleK1ReceivedDate"
                        (onChange)="dateChange($event, index)" *ngIf="showDate">
                      </ap-date-picker>
                      <span *ngIf="tab.typeOfEntityInformationScheduleK1ReceivedDate" class="appkiticon icon-close-outline pl-2" title="Clear" (click)="clearDate(index)"></span>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td> If no Schedule K-1 was received from the entity enter "NONE" </td>
                    <td>
                      <ap-form-field [inlineBlock]="true" [id]="'noneEntity'" [type]="'input'"
                        [isSecondary]="true" [isDisabled]="true" class="disabled-input"
                        [(ngModel)]="tab.typeOfEntityInformationNoScheduleK1Received">
                      </ap-form-field>
                    </td>
                  </tr>
                </tbody>
              </table>

              <ap-button class="a-mr-10" [config]="{isSecondary: true}" [btnType]="'a-btn-gray'"
                [btnSize]="'small'" (onBtnClick)="clearEntity('partFour', index)">
                <span class="appkiticon a-btn-icon icon-refresh-fill"></span>Clear this Entry
              </ap-button>

            </div>

          </mat-tab>
        </mat-tab-group>

      </div>

    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <!-- Section 5 -->
    <div class="section-content ap-form-field-my-wrapper" id="part5">

      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">6</td>
            <td>
              Enter below the name and address of each
              individual or entity to whom you paid a fee with regard to the
              transaction if that individual or entity promoted, solicited, or
              recommended your participation in the transaction, or provided tax
              advice related to the transaction.
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tab-section section-content">
        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
          (onBtnClick)="addTab('partFive')">
          <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
        </ap-button>
        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
          [btnSize]="'small'" [isDisabled]="partFiveTabs.length > 1 && partFiveSelected.value === 0" (onBtnClick)="removeTab(partFiveSelected.value, 'partFive')">
          <span class="appkiticon a-btn-icon icon-minus-fill"></span> Remove
        </ap-button>

        <mat-tab-group [selectedIndex]="partFiveSelected.value" (selectedIndexChange)="selectTab($event, 'partFive', false)"
          animationDuration="0ms">
          <mat-tab *ngFor="let tab of partFiveTabs; let index = index" [label]="tab.tab">
            <div class="tab-section-content">

              <div class="row mt-2 w-100">
                <div class="col-md-4">
                  <span>Name</span>
                </div>
                <div class="col-md-8 pl-2">
                  <ap-form-field [inlineBlock]="true" [type]="'input'" class="label-none"
                    [(ngModel)]="tab.personsYouPaidAFeeInfoNameOfPersonYouPaidFee" [id]="'personNameYouPaid'">
                  </ap-form-field>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-4">
                  <span>Identifying Number: SSN (If Known)</span>
                </div>
                <div class="col-md-8 pl-2">
                  <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                    [funType]="'typeTwo'" [id]="'personSSNYouPaid'" (onChangeEvent)="personsYouPaidAFeeInfoEINChange($event, index)"
                    [(ngModel)]="tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee">
                  </ap-form-field>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-4">
                  <span>Identifying Number: EIN (If Known)</span>
                </div>
                <div class="col-md-8 pl-2" *ngIf="!USForeignFeesRad">
                  <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                    [funType]="'typeTwo'" [id]="'personEINYouPaid'" (onChangeEvent)="personsYouPaidAFeeInfoEINChange($event, index)"
                    [(ngModel)]="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee">
                  </ap-form-field>
                  <!-- <dst-custom-input class="dst-field" id="personEINYouPaid" [label]="''" [maxLength]="9" [value]="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee" 
                    (valChange)="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee = $event;" (onChangeEvent)="personsYouPaidAFeeInfoEINChange($event, index)">
                  </dst-custom-input> -->
                </div>
                <div class="col-md-8 pl-0" *ngIf="USForeignFeesRad">
                  <!-- <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                    [funType]="'typeTwo'" [id]="'personEINYouPaid'" (onChangeEvent)="personsYouPaidAFeeInfoEINChange($event, index)"
                    [(ngModel)]="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee">
                  </ap-form-field> -->
                  <dst-custom-input class="dst-field" id="personEINYouPaid" [label]="''" [maxLength]="9" [value]="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee" 
                    (valChange)="tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee = $event;" (onChangeEvent)="personsYouPaidAFeeInfoEINChange($event, index)">
                  </dst-custom-input>
                </div>
              </div>

              <div class="row mt-2 w-100" *ngIf="(tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee === '' || tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee == null) && (tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee === '' || tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee == null) ">
                <div class="col-md-4 pr-0">
                  <span>If no SSN/EIN, specify reason for missing tax ID Number</span>
                </div>
                <div class="col-md-8 pt-1 pl-2">
                  <ap-selector class="ap-form-field-my-wrapper" [id]="'personsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'"
                    [items]="partFiveReasonsEIN" [type]="'SingleSelector'" [searchMethod]="'inline'"
                    (onSelectedChange)="personsYouPaidAFeeInfoMissingEINReasonChange($event, index)"
                    [ngClass]="{ 'required-field': (tab.personsYouPaidAFeeInfoNameOfPersonYouPaidFee != '') && ((tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee == '' || tab.personsYouPaidAFeeInfoSSNNumberPersonPaidFee == null)
                      && (tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee == '' || tab.personsYouPaidAFeeInfoEINNumberPersonPaidFee == null)) }">
                  </ap-selector>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-4">
                  <span>Fees Paid (Enter amount in US Dollars)</span>
                </div>
                <div class="col-md-8 pl-2">
                  <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                    [funType]="'typeTwo'"
                    [(ngModel)]="tab.personsYouPaidAFeeInfoFeesPaid" [id]="'personFeeYouPaid'">
                  </ap-form-field>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-4">
                  <span>Were Fees paid to a US or Foreign Entity?</span>
                </div>
                <div class="col-md-4 pl-0">
                  <ap-radio class="a-mx-10" name="radio-group-3_{{ tab.tab }}" [text]="'US'" [checked]="true"
                    (onCheck)="check3(true, index)" [(ngModel)]="tab.USFeesRad">
                  </ap-radio>
                  <ap-radio class="a-mx-10" name="radio-group-3_{{ tab.tab }}" [text]="'Foreign'"
                    (onCheck)="check3(false, index)" [(ngModel)]="tab.ForeignFeesRad">
                  </ap-radio>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label">Address Line 1:</span>
                    <div class="col-md-8 pr-1">
                      <ap-form-field [id]="'personsYouPaidAFeeInfoAddressLine1'" [type]="'input'"
                        [(ngModel)]="tab.personsYouPaidAFeeInfoAddressLine1">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label">Address Line 2:</span>
                    <div class="col-md-8 pr-1">
                      <ap-form-field [id]="'personsYouPaidAFeeInfoAddressLine2'" [type]="'input'"
                        [(ngModel)]="tab.personsYouPaidAFeeInfoAddressLine2">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label">{{ USForeignFeesRad ? 'City:' : 'City / Town:'}}</span>
                    <div class="col-md-8 pr-1">
                      <ap-form-field [id]="'personsYouPaidAFeeInfoCity'" [type]="'input'"
                        [(ngModel)]="tab.personsYouPaidAFeeInfoCity">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details" *ngIf="USForeignFeesRad">
                    <span class="ed-label">State:</span>
                    <div class="col-md-8  pt-1 pr-1">
                      <ap-selector [items]="states" [type]="'SingleSelector'" [searchMethod]="'inline'"
                        (onSelectedChange)="personsYouPaidAFeeInfoStateChange($event, index)">
                      </ap-selector>
                    </div>
                  </div>
                  <div class="entity-details" *ngIf="!USForeignFeesRad">
                    <span class="ed-label">Province or State:</span>
                    <div class="col-md-8 pr-1">
                      <ap-form-field [type]="'input'" [id]="'personsYouPaidAFeeInfoProvinceOrState'"
                        [(ngModel)]="tab.personsYouPaidAFeeInfoProvinceOrState">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="!USForeignFeesRad">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label">Country:</span>
                    <div class="col-md-8 pt-1 pr-1">
                      <ap-selector [items]="countries" [type]="'SingleSelector'"
                        [searchMethod]="'inline'" (onSelectedChange)="personsYouPaidAFeeInfoCountryChange($event, index)">
                      </ap-selector>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label">{{ USForeignFeesRad ? 'Zip Code:' : 'Postal Code:'}}</span>
                    <div class="col-md-8 pr-1">
                      <ap-form-field [id]="'zip'" [type]="'input'"
                        [(ngModel)]="tab.personsYouPaidAFeeInfoZip">
                      </ap-form-field>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </mat-tab>
        </mat-tab-group>

      </div>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>

        </tr>
      </tbody>
    </table>

    <!-- Section 6 -->
    <div class="section-content ap-form-field-my-wrapper" id="part6">

      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">7</td>
            <td>Facts</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <div style="padding-left: 48px;">

        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">a</td>
              <td>
                Identify the type of tax benefit generated by the transaction. Check all the boxes that apply.
              </td>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td"></td>
              <td>
                <table>
                  <tbody>

                    <tr>
                      <td>
                        <ap-checkbox class="a-mx-10" id="Deductions" [text]="'Deductions'"
                          [(ngModel)]="formModel.deductions">
                        </ap-checkbox>
                      </td>

                      <td>
                        <ap-checkbox class="a-mx-10" id="ExclusionsFromGrossIncome"
                          [text]="' Exclusions From Gross Income'" [(ngModel)]="formModel.exclusionsFromGrossIncome">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ap-checkbox class="a-mx-10" id="capitalLoss" [text]="'Capital Loss'"
                          [(ngModel)]="formModel.capitalLoss">
                        </ap-checkbox>
                      </td>

                      <td>
                        <ap-checkbox class="a-mx-10" id="nonrecognitionOfGain" [text]="'Nonrecognition of gain'"
                          [(ngModel)]="formModel.nonrecognitionOfGain">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ap-checkbox class="a-mx-10" id="ordinaryLoss" [text]="'Ordinary Loss'"
                          [(ngModel)]="formModel.ordinaryLoss">
                        </ap-checkbox>
                      </td>

                      <td>
                        <ap-checkbox class="a-mx-10" id="adjustmentsToBasis" [text]="'Adjustments to basis'"
                          [(ngModel)]="formModel.adjustmentsToBasis">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ap-checkbox class="a-mx-10" [id]="'line7aOther'" [text]="'Other'"
                          [(ngModel)]="formModel.line7aOther">
                        </ap-checkbox>
                      </td>

                      <td width="50%">
                        <ap-form-field *ngIf="formModel.line7aOther" [id]="'line7aOtherText'" [type]="'input'"
                          [(ngModel)]="formModel.line7aOtherText" class="ap-form-field-my-wrapper other-text-input">
                        </ap-form-field>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td class="statement-number-tbl-td">&nbsp;</td>
                      <td>
                        <ap-checkbox class="a-mx-10" id="taxCredits" [text]="' Tax Credits'"
                          [(ngModel)]="formModel.taxCredits">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td class="statement-number-tbl-td">&nbsp;</td>
                      <td>
                        <ap-checkbox class="a-mx-10" id="deferral" [text]="'Deferral'"
                          [(ngModel)]="formModel.deferral">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td class="statement-number-tbl-td">&nbsp;</td>
                      <td>
                        <ap-checkbox class="a-mx-10" id="absenceOfAdjustmentsToBasis"
                          [text]="'Absence of adjustments to basis'" [(ngModel)]="formModel.absenceOfAdjustmentsToBasis">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td class="statement-number-tbl-td">&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>

                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">b</td>

              <td class="pt-2">
                Enter the total dollar amount of your tax benefits identified in 7a. See instructions
              </td>

              <td class="col-md-3 pb-2">
                <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" [type]="'inputNumber'"
                  [(ngModel)]="formModel.totalTaxBenefitAmt" [id]="'benefitsAmt'" [placeholder]="'$'"
                  class="number-field required-field number-field-box" required>
                </ap-form-field>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">c</td>

              <td>
                Enter the anticipated number of years the transaction provides the tax benefits stated in 7b. See instructions
              </td>

              <td class="col-md-3 pb-2">
                <ap-form-field [inlineBlock]="true" [funType]="'typeTwo'" [id]="'anticipatedAmt'"
                  [(ngModel)]="formModel.taxBnftAnticipatedYrCnt" [type]="'inputNumber'"
                  class="number-field required-field number-field-box" required>
                </ap-form-field>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">d</td>

              <td>
                Enter your total investment or basis in the transaction. See instructions
              </td>

              <td class="col-md-3 pb-2">
                <ap-form-field [inlineBlock]="true"[funType]="'typeTwo'" [id]="'invOrBasisAmt'"
                  [(ngModel)]="formModel.totalInvestmentOrBasisAmt" [type]="'inputNumber'"
                  [placeholder]="'$'" class="number-field required-field number-field-box" required>
                </ap-form-field>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">e</td>
              <td width="65%">Please specify a Word Document that further
                describes the amount and nature of the expected tax treatment and
                expected tax benefits generated by the transaction for all affected
                years. Include facts of each step of the transaction that relate to
                the expected tax benefits including the amount and nature of your
                investment. Include in your description your participation in the
                transaction and all related transactions regardless of the year in
                which they were entered into. Also, include a description of any tax
                result protection with respect to the transaction
              </td>
              <td>&nbsp;</td>
            </tr>

          </tbody>
        </table>

      </div>

      <div class="row mt-2 justify-content-center">
        <button id="uploadPdf" class="file-upload-btn">
          <input type="file" [accept]="fileAcceptStr" (change)="onFileChanged($event)" #fileUpload style="height: 1.7rem !important;">
        </button>
        <div class="col-md-8 text-align-center" *ngIf="downloadUrl">
          <span><a class="attachment" (click)="download()"> {{ attachmentname }} </a></span>
          <span class="appkiticon icon-delete-fill ml-2" (click)="deleteFileUrl()"></span>
        </div>
      </div>

    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>

        </tr>
      </tbody>
    </table>

    <!-- Section 7 -->
    <div class="section-content ap-form-field-my-wrapper" id="part7">

      <table>
        <tbody>
          <tr>
            <td class="statement-number-tbl-td">8</td>
            <td>
              Identify all tax-exempt, foreign, and related
              entities and individuals involved in the transaction. Check the
              appropriate box(es). Include their name(s), identifying number(s),
              address(es), and a brief description of their involvement. For each
              foreign entity, identify its country of incorporation or existence.
              For each related entity, explain how it is related.
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tab-section section-content">
        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}" [btnSize]="'small'"
          (onBtnClick)="addTab('partSeven')">
          <span class="appkiticon a-btn-icon icon-plus-fill"></span> Add
        </ap-button>
        <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isSecondary: true}"
          [btnSize]="'small'" [isDisabled]="partSevenTabs.length > 1 && partSevenSelected.value === 0" (onBtnClick)="removeTab(partSevenSelected.value, 'partSeven')">
          <span class="appkiticon a-btn-icon icon-minus-fill"></span> Remove
        </ap-button>

        <mat-tab-group [selectedIndex]="partSevenSelected.value" (selectedIndexChange)="selectTab($event, 'partSeven', false)"
          animationDuration="0ms">
          <mat-tab *ngFor="let tab of partSevenTabs; let index = index" [label]="tab.tab">

            <div class="tab-section-content mt-2">

              <table>
                <tbody>
                  <tr>
                    <td class="statement-number-tbl-td">a</td>
                    <td>Type of Entity:</td>
                    <td class="pl-2" style="width: 55%;">
                      <ap-checkbox class="a-mx-10" [text]="'Tax-exempt'" [id]="'identifyAllInvolvedInTrTaxExempt'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrTaxExempt">
                      </ap-checkbox>
                      <ap-checkbox class="a-mx-10" [text]="'Foreign'" [id]="'identifyAllInvolvedInTrForeign'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrForeign">
                      </ap-checkbox>
                      <ap-checkbox class="a-mx-10" [text]="'Related'" [id]="'identifyAllInvolvedInTrRelated'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrRelated">
                      </ap-checkbox>
                    </td>
                  </tr>

                  <br />
                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>Is Participating Entity in Legal Entity Database?</td>
                    <td class="pl-2">
                      <ap-radio class="a-mx-10" name="radio-group-2_{{ tab.tab }}" id="inGOLD_Yes" [text]="'Yes'"
                        (onCheck)="check4(true, index)" [(ngModel)]="tab.inGOLDYes">
                      </ap-radio>
                      <ap-radio class="a-mx-10" name="radio-group-2_{{ tab.tab }}" id="inGOLD_No" [text]="'No'"
                        (onCheck)="check4(false, index)" [(ngModel)]="tab.inGOLDNo">
                      </ap-radio>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDYes">
                    <td class="statement-number-tbl-td"></td>
                    <td>Please specify the LEID of the Entity that Participated in the Transaction</td>
                    <td class="pl-3">
                      <dst-template-leid-selector [labelId]="'identifyAllInvolvedInTrLEID_'+index" [id]="'involvedTrEntity'"
                        [label]="''" (onSelectedLEChanged)="onInvolvedTrEntityChanged($event, index)"  required
                        [ngClass]="{ 'required-field': (tab.identifyAllInvolvedInTrTaxExempt || tab.identifyAllInvolvedInTrForeign
                          || tab.identifyAllInvolvedInTrRelated) && tab.inGOLDYes }">
                      </dst-template-leid-selector>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDYes">
                    <td class="statement-number-tbl-td">b</td>
                    <td>Entity Name:</td>
                    <td>
                      <span class="template-color-blue">{{!tab.involvedTrEntity ? '' : tab.involvedTrEntity.ENTITY}}</span>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDYes">
                    <td class="statement-number-tbl-td">c</td>
                    <td>Employer identification number (EIN):</td>
                    <td>
                      <span class="template-color-blue">{{!tab.involvedTrEntity ? '' : (tab.involvedTrEntity.EIN ? tab.involvedTrEntity.EIN : 'N/A')}}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDYes) && tab.involvedTrEntity && (tab.involvedTrEntity.EIN === undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>EIN Missing Reason:</td>
                    <td class="pt-2 pl-0 col-md-3">
                      <ap-selector class="ap-form-field-my-wrapper required-field" [id]="'identifyAllInvolvedInTrMissingEINReason'"
                        [items]="partSevenReasonsEIN" [type]="'SingleSelector'" required [searchMethod]="'inline'"
                        (onSelectedChange)="missingEINReasonInGoldInvolvedInTrChange($event, index)">
                      </ap-selector>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Name of Entity</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field [inlineBlock]="true" [type]="'input'" class="required-field" required
                        [(ngModel)]="tab.nameInvolvedInTransactionBusinessNameLine1" [id]="'nameInvolvedInTransactionBusinessNameLine1'"
                        [ngClass]="{ 'required-field': (tab.identifyAllInvolvedInTrTaxExempt || tab.identifyAllInvolvedInTrForeign
                          || tab.identifyAllInvolvedInTrRelated) && (tab.nameInvolvedInTransactionBusinessNameLine1 == '') }">
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Name Entity Line 2</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field [inlineBlock]="true" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2">
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Identifying Number SSN</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                        [funType]="'typeTwo'" [id]="'ssnNumberInvolvedInTransaction'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction">
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo && InvolvedUSForeignEntity">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Identifying Number EIN</td>
                    <td class="col-md-3 pt-2" style="padding-left: 7px;">
                      <dst-custom-input class="dst-field" id="einNumberInvolvedInTransaction" [label]="''" [maxLength]="9" [value]="tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction" 
                        (valChange)="tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction = $event;" >
                      </dst-custom-input>
                      <!-- <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                        [funType]="'typeTwo'" [id]="'einNumberInvolvedInTransaction'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction">
                      </ap-form-field> -->
                    </td>
                  </tr>
                  <tr *ngIf="tab.inGOLDNo && !InvolvedUSForeignEntity">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Identifying Number EIN</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field [inlineBlock]="true" [type]="'inputNumber'" class="label-none"
                        [funType]="'typeTwo'" [id]="'einNumberInvolvedInTransaction'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction">
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo && ((tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction == '' || tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction == null) && (tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction == '' || tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction == null))">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>If no Identifying Number, specify reason</td>
                    <td class="col-md-3 pt-2">
                      <ap-selector class="ap-form-field-my-wrapper" [id]="'missingEINReasonInvolvedInTr'" required [searchMethod]="'inline'"
                        [items]="partSevenInvolvedReasonsEIN" [type]="'SingleSelector'" (onSelectedChange)="missingEINReasonInvolvedInTrChange($event, index)"
                        [ngClass]="{ 'required-field': (tab.identifyAllInvolvedInTrTaxExempt || tab.identifyAllInvolvedInTrForeign || tab.identifyAllInvolvedInTrRelated)
                          && (tab.nameInvolvedInTransactionBusinessNameLine1 != '') && ((tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction == '' || tab.identifyAllInvolvedInTrSSNNumberInvolvedInTransaction == null)
                          && (tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction == '' || tab.identifyAllInvolvedInTrEINNumberInvolvedInTransaction == null)) }">
                      </ap-selector>
                    </td>
                  </tr>

                  <tr *ngIf="tab.inGOLDNo">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Is this a US or Foreign Entity?</td>
                    <td class="pt-2 pl-2">
                      <ap-radio class="a-mx-10" name="radio-group-1_{{ tab.tab }}" [text]="'US'" [id]="'usForPart8Rad'"
                        (onCheck)="check5(true, index)" [(ngModel)]="tab.USForPart8Rad">
                      </ap-radio>
                      <ap-radio class="a-mx-10" name="radio-group-1_{{ tab.tab }}" [text]="'Foreign'" [id]="'foreignForPart8Rad'"
                        (onCheck)="check5(false, index)" [(ngModel)]="tab.ForeignForPart8Rad">
                      </ap-radio>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity !== undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 1:</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field class="required-field ap-form-field-my-wrapper" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrAddressLine1'" required
                        [(ngModel)]="tab.identifyAllInvolvedInTrAddressLine1"> <!--, 'required-field': USForPart8Rad.value -->
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity !== undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 2:</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field class="ap-form-field-my-wrapper" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrAddressLine2'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrAddressLine2">
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity !== undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ InvolvedUSForeignEntity ? 'City:' : 'City / Town:'}}</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field class="required-field ap-form-field-my-wrapper" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrCity'" required
                        [(ngModel)]="tab.identifyAllInvolvedInTrCity"> <!--, 'required-field': USForPart8Rad.value -->
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity === true)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>State:</td>
                    <td class="col-md-3 pt-2">
                      <ap-selector class="required-field-dropdown" [items]="involvedTrStates"
                        [searchMethod]="'inline'" [id]="'identifyAllInvolvedInTrState'" [type]="'SingleSelector'"
                        (onSelectedChange)="identifyAllInvolvedInTrStateChange($event, index)"> <!--, 'required-field-dropdown': USForPart8Rad.value -->
                      </ap-selector>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity === false)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Province or State:</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field class="required-field ap-form-field-my-wrapper" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrProvinceOrState'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrProvinceOrState" required> <!--, 'required-field': USForPart8Rad.value -->
                      </ap-form-field>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity === false)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Country:</td>
                    <td class="col-md-3 pt-2">
                      <ap-selector class="required-field-dropdown" [items]="involvedTrCountries"
                        [searchMethod]="'inline'" [id]="'identifyAllInvolvedInTrCountry'" [type]="'SingleSelector'"
                        (onSelectedChange)="identifyAllInvolvedInTrCountryChange($event, index)"> <!--, 'required-field-dropdown': USForPart8Rad.value -->
                      </ap-selector>
                    </td>
                  </tr>

                  <tr *ngIf="(tab.inGOLDNo) && (InvolvedUSForeignEntity !== undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ InvolvedUSForeignEntity ? 'Zip Code:' : 'Postal Code:'}}</td>
                    <td class="col-md-3 pt-2">
                      <ap-form-field class="required-field ap-form-field-my-wrapper" [type]="'input'"
                        [id]="'identifyAllInvolvedInTrPostalCode'"
                        [(ngModel)]="tab.identifyAllInvolvedInTrPostalCode" required> <!--, 'required-field': USForPart8Rad.value -->
                      </ap-form-field>
                    </td>
                  </tr>

                  <br />
                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>Description of Involvement</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td colspan="3">
                      <div class="a-textarea-box a-field-length-validation form-inlines">
                        <ap-form-field [inlineBlock]="true" [maxLength]="100" [withNumberValidation]="true"
                          [type]="'textarea'" [id]="'identifyAllInvolvedInTrDescriptionOfInvolvement'"
                          [(ngModel)]="tab.identifyAllInvolvedInTrDescriptionOfInvolvement">
                        </ap-form-field>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>

              <ap-button class="a-mr-10" [config]="{isSecondary: true}" [btnType]="'a-btn-gray'"
                [btnSize]="'small'" (onBtnClick)="clearEntity('partSeven', index)">
                <span class="appkiticon a-btn-icon icon-refresh-fill"></span>Clear this Entry
              </ap-button>

            </div>

          </mat-tab>
        </mat-tab-group>

      </div>

    </div>

  </div>
</div>
