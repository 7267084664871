import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SaveActionResponse} from '../../../../../../shared/_models/save-action-response.model';
import {ActionService} from '../../../../../../shared/_services/action.service';
import {ActionResponse} from '../../../../../../shared/_models/action-response.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateAttachedService {

  constructor(private httpClient: HttpClient,
              private dstSharedService: DSTSharedService,
              private actionService: ActionService) {
  }

  validatePdfAttch(file: File): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('attachment', file);
    return this.httpClient.post(this.dstSharedService.baseURLs.admin + '/validateAttStatementFile', formData)
      .pipe(map((data: any) => {
        return !!data.callSuccess;
      }));
  }

  deleteSavedAttachment(statementId: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statementId': statementId,
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32347, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  activateBulkStatements(statementIds: string): Observable<SaveActionResponse> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statementIds': statementIds,
      }
    });

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32348, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  updateSupersedeStatements(statementID: string, entityId: string, updateType: string): Observable<SaveActionResponse> {
    const params = new HttpParams()
      .set('statementId', statementID)
      .set('entityId', entityId)
      .set('updateType', updateType)
      .set('sso_id', this.dstSharedService.user?.ssoId);

    return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32354, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: SaveActionResponse) => {
          return data;
        })
      );
  }

  updateAttachedFileForStatement(fileName: string, statementId: string, file: File, formTypeId: string): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('attachmentFileName', fileName);
    formData.append('statementId', statementId);
    formData.append('attachmentPath', file);
    formData.append('formTypeId', formTypeId);
    formData.append('client_key', this.dstSharedService.clientKey.toString());
    return this.httpClient.post(this.dstSharedService.baseURLs.admin + '/updateAttachedFileForStatement', formData)
      .pipe(map((data: any) => {
        return !!data.callSuccess;
      }));
  }

  getBulkStatements(taxYear: string, formId: string): Observable<DSTSavedAttachment[]> {
    const params = new HttpParams()
      .set('sso_id', this.dstSharedService.user?.ssoId)
      .set('tax_year', taxYear)
      .set('formId', formId);

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32352, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('Something went wrong while retrieving the statements data, please try again!');
          }
        })
      );
  }

  getDSTPresignedUrl(s3Url: string, fileName: string): Observable<string> {
    const params = new HttpParams()
      .set('client_key', this.dstSharedService.clientKey.toString())
      .set('s3_url', s3Url)
      .set('actualFileName', fileName);

    return this.httpClient.get(this.dstSharedService.baseURLs.admin + '/getDSTPresignedUrl', {withCredentials: true, params: params})
      .pipe(map((data: any) => {
        if (data.callSuccess === '1') {
          return data.presignedS3Url;
        } else {
          throw Error('Something went wrong while getting the attached file download url!');
        }
      }));
  }

  readFileContentFromFile(attachmentFile: File, fileNameExtension: string): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', attachmentFile);
    formData.append('fileNameExtension', fileNameExtension);
    return this.httpClient.post(this.dstSharedService.baseURLs.admin + '/readFileContentFromFile', formData)
      .pipe(map((data: any) => {
        if (data.callSuccess === '1') {
          return data.displayFile;
        } else {
          throw Error('Something went wrong while reading the file, please try to upload the file again!');
        }
      }));
  }
}

export class DSTSavedAttachment {
  STATEMENTID: string;
  PRIMARYLEID: string;
  BRANCHENTITY: string;
  FILENAME: string;
  PROVIDEDBY: string;
  isEdit?: boolean;
  hideFileName?: boolean;

  constructor(statementId: string, primaryLeid: string, branchEntity: string, filename: string, providedBy: string) {
    this.STATEMENTID = statementId;
    this.PRIMARYLEID = primaryLeid;
    this.BRANCHENTITY = branchEntity;
    this.FILENAME = filename;
    this.PROVIDEDBY = providedBy;
    this.isEdit = false;
    this.hideFileName = false;
  }
}
