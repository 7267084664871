import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HomePageContainerService } from '../home-page/home-page-container.service';
import { NavigationModel } from "./navigation.model";
import { HomeScreenObject } from './tasks/home-screen.object';
import { HomePageObject } from '../home-page/home-page';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  
  private url ={
    fetchPanelOne:'/v2/getUserNavigator'
  };
  private allCategories:Array<any> = [];
  private options:any;
  private clientKey = sessionStorage.getItem('client_key') || '';

  private allScreenList :Array<any> = [];
  HOME_SCREEN_OBJECT =HomeScreenObject;
  HOME_PAGE_MODULE_OBJECT = HomePageObject;
  
  constructor(private http:HttpClient, private homeService:HomePageContainerService) { 
    
		let headers = new HttpHeaders({
			'client_key': this.clientKey
		});
		this.options= {withCredentials:true}
  }
  
  navObject:{} = {};

  getUserNavigatorV2(baseUrl: string, globalParams ?: any){
    let url = baseUrl+this.url.fetchPanelOne;
     if(globalParams && globalParams.channel){
      url += `?channel_key=${globalParams.channel}&tax_year=${globalParams.taxYear}&scenario=${globalParams.scenarioKey}&scenario_type_code=${globalParams.scenarioCode}`;
     }
    return this.http.get(url)
        .pipe(
          map((res:any)=>{
            this.allScreenList = this.getAllScreens(res[0]);
            return this.transformNavigationResponseObject(res[0]);
          }
        ));
  }
  
  transformNavigationResponseObject(navResponse:NavigationModel){
    let navigation:NavigationModel[] = []; 
    navResponse.children.forEach((domain:NavigationModel) => {
      if(domain.parentTaskKey == null && domain.icon_css != null){
        navigation.push(domain);  
      }
    });

    if(navigation && navigation){
      const homeObj = navigation.filter((obj) => obj.child_id == 22505321);
      if(homeObj.length == 0 && this.HOME_PAGE_MODULE_OBJECT['home-navigator'] !=undefined){
        //navigation.push(this.HOME_PAGE_MODULE_OBJECT['home-navigator']);
        let homeNavObj = this.HOME_PAGE_MODULE_OBJECT['home-navigator'];
        navigation.push(homeNavObj);
      }
    }
    console.log('navigation,',navigation);
    this.recursiveNavigationTasks(navigation,navResponse);
    this.navObject = this.navigationModuleObject(navigation,navResponse);
    return navigation;
  }
  navigationModuleObject(navigation:any, navResponse:any){
    let finalNavigation = {children:[]};
    finalNavigation = navResponse;
    finalNavigation['children'] = navigation;
    return finalNavigation;
  }

  recursiveNavigationTasks(navigation:NavigationModel[], navResponse:NavigationModel){

    navigation.forEach((domain:NavigationModel)=>{
      navResponse.children.forEach((subTask:NavigationModel)=>{
        if(domain.id == subTask.parentTaskKey){
          try{
            domain.children.push(subTask);
          }catch(e){
            console.error(e);
          }
        }
      });
      if(domain.children){
        this.recursiveNavigationTasks(domain.children, navResponse)
      }
    });
  }

  getAllCategories(adminUrl:string){

    return this.homeService.getTaxTypesCategories(adminUrl,parseInt(this.clientKey))
              .pipe(map(
                (res:any)=>{
                  this.allCategories = res.categoryList;
                }));
  }

  getAllScreens(navResponse:NavigationModel){
    let allScreens: Array<any> = [];

    let filterFunc = (task:any)=>{
      if(task.hasOwnProperty('children')){
        task.children.filter(filterFunc);
      }else if(task.hasOwnProperty('screens')){
        allScreens = allScreens.concat(task.screens);
      }
    };
    navResponse.children.filter(filterFunc);
    return allScreens;
  }

  getAllCategoryScreens(tasks:NavigationModel){

    let allScreens = this.getAllScreens(tasks);
    let allCategoryScreens = JSON.parse(JSON.stringify(this.allCategories));
    for(let category of allCategoryScreens){
      allScreens.forEach((screen)=>{
          if(screen["screen_categories"] && 
             screen["screen_categories"].indexOf(category.id) >= 0 ){
            category.children = category.children ||[];
            category.children.push(screen);
          }
      })
    };
    return allCategoryScreens;
  }
  
  getActivityFromTasks(task:any[]){
    let activity:  any[]=[];
    task.forEach(task=>{
      activity = activity.concat(task.children);
    })
    return activity;
  }
  
  filterBySearch(listObj:any, searchText:string,type:'tax-type'|'categories'){
    if(searchText.trim() == '') return listObj;

    listObj= this.recursiveFilterMethod(listObj, searchText);
    
    return listObj;
  }

  recursiveFilterMethod(list:any, searchText:string){
    function copy(o:any){
      return Object.assign({},o);
    }

    let f = (o:any) =>{
      if(o.label.toLowerCase().includes(searchText.toLowerCase())) return true;
      if(o.children){
        return (o.children = o.children.filter(f)).length;
      }else if(o.screens){
        return (o.screens = o.screens.filter(f)).length;
      }
    }
    return list.filter(f);
  }

  getCurrentScreen(currentScreen:{currentState:string}){
    let filteredScreen:any = [];
    this.allScreenList.forEach( screen => {
      console.log(screen.link+ '  ' + currentScreen.currentState);
     if( screen.link == currentScreen.currentState){
      filteredScreen.push(screen);
     }
    })
    if(filteredScreen.length == 0){
      filteredScreen[0]=this.HOME_SCREEN_OBJECT;
      return filteredScreen;
    }

    return [];
  }
}
