import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrackerTabsService, TrackerTabState } from '../../../../tracker-tabs.service';
import { CreateDisclosureSharedService, EntityNameChange } from '../../create-disclosure-shared.service';
import { DSTSavePayload } from '../../dst-dynamic-component-resolver.service';
import { DSTConsolGroup, TemplateHeaderComponent } from '../template-header/template-header.component';
import { TemplateHeaderService } from '../template-header/template-header.service';
import { DSTEntityDetails, TemplateLeidSelectorComponent } from '../template-leid-selector/template-leid-selector.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DSTFormState, StmtAttrData } from '../../../../../shared/models/dst-form-state.model';
import { DSTSharedService } from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec8824-id237',
  templateUrl: './template-sec8824-id237.component.html',
  styleUrls: ['../template-shared.scss', './template-sec8824-id237.component.scss']
})
export class TemplateSec8824Id237Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form237!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;

  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  beginDate!: Date;
  endDate!: Date;
  DescOfLikeKindPropertyGivenUp: string = '';
  DescriptionOfOtherPropertyGivenUp: string = '';
  DescOfLikeKindPropertyReceived: string = '';
  DescriptionOfOtherPropertyRcvd: string = '';
  DateLikeKindPropertyGivenUpAcq: string | Date = '';
  DatePropActuallyTransferred: string | Date = '';
  DateLikeKindPropRcvdIdentified: string | Date = '';
  DatePropertyActuallyReceived: string | Date = '';
  ExchangeMadeWithRelatedParty!: boolean | undefined;

  RelationshipToYou: string = '';
  RelatedPartySoldPropReceived!: boolean;
  YouSoldPropertyReceived!: boolean;
  DisposWasAfterDeathRltdParties: boolean = false;
  DisposWasInvoluntaryConversion: boolean = false;
  ExchangeDisposNotTaxAvoidance: boolean = false;
  PrincipalPrpsRltdPartyExchStmtExplanation: string = '';

  GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount: string = '';
  GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation: string = '';
  FMVOfOtherPropertyGivenUp: string = '';
  AdjBasisOfOtherPropertyGivenUp: string = '';
  GainLossOnOtherPropertyGivenUp: string = '';
  CashFMVAndNetLiabRedByExpenses: string = '';
  FMVOfLikeKindPropertyReceived: string = '';
  AmountRealized: string = '';
  AdjBasisOfLikeKindPropGivenUp: string = '';
  RealizedGainOrLoss: string = '';
  SmallerGainOrLoss: string = '';
  OrdinaryIncmUndRecaptureRules: string = '';
  SmallerGainLossLessOrdnryIncm: string = '';
  RecognizedGain: string = '';
  DeferredGainOrLoss: string = '';
  BasisOfLikeKindPropertyRcvd: string = '';
  BasisOfLikeKindIntagblePropertyRcvd: string = '';
  BasisOfLikeKindSect1245PropertyRcvd: string = '';
  BasisOfLikeKindSect1250PropertyRcvd: string = '';

  showDREMsg: boolean = false;
  DREMsg: string = '';
  taxYear: string;
  nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private dstSharedService: DSTSharedService,
    private sharedService: CreateDisclosureSharedService,
    private headerService: TemplateHeaderService,
    private trackerTabsService: TrackerTabsService,
    private cdref: ChangeDetectorRef) {
    this.taxYear = this.sharedService.getActiveFormState().taxYear.toString();
  }

  ngOnInit(): void {
    this.editMode = true;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form237 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      partOne: this.formBuilder.group({
        DescOfLikeKindPropertyGivenUp: ['', Validators.required],
        DescriptionOfOtherPropertyGivenUp: [''],
        DescOfLikeKindPropertyReceived: ['', Validators.required],
        DescriptionOfOtherPropertyRcvd: [''],
        DateLikeKindPropertyGivenUpAcq: [''],
        DatePropActuallyTransferred: [''],
        DateLikeKindPropRcvdIdentified: [''],
        DatePropertyActuallyReceived: [''],
        ExchangeMadeWithRelatedParty: [this.ExchangeMadeWithRelatedParty],

      }),
      partTwo: this.formBuilder.group({
        RelationshipToYou: [''],
        RelatedPartySoldPropReceived: [''],
        YouSoldPropertyReceived: [''],
        DisposWasAfterDeathRltdParties: [''],
        DisposWasInvoluntaryConversion: [''],
        ExchangeDisposNotTaxAvoidance: [''],
        PrincipalPrpsRltdPartyExchStmtExplanation: [''],
      }),
      partThree: this.formBuilder.group({
        GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount: [''],
        GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation: [''],
        FMVOfOtherPropertyGivenUp: [''],
        AdjBasisOfOtherPropertyGivenUp: [''],
        GainLossOnOtherPropertyGivenUp: [''],
        CashFMVAndNetLiabRedByExpenses: [''],
        FMVOfLikeKindPropertyReceived: [''],
        AmountRealized: [''],
        AdjBasisOfLikeKindPropGivenUp: [''],
        RealizedGainOrLoss: [''],
        SmallerGainOrLoss: [''],
        OrdinaryIncmUndRecaptureRules: [''],
        SmallerGainLossLessOrdnryIncm: [''],
        RecognizedGain: [''],
        DeferredGainOrLoss: [''],
        BasisOfLikeKindPropertyRcvd: [''],
        BasisOfLikeKindIntagblePropertyRcvd: [''],
        BasisOfLikeKindSect1245PropertyRcvd: [''],
        BasisOfLikeKindSect1250PropertyRcvd: ['']
      })
    });
  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData();
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.editMode = true;
      data.children?.forEach((stmt: StmtAttrData) => {
        if (this.attMap[stmt.attributeName] == undefined) {
          this.attMap[stmt.attributeName] = [];
        }

        if (stmt.attributeValue !== undefined) {

          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        }
      });
      if (this.attMap['DescOfLikeKindPropertyGivenUp']) {
        this.DescOfLikeKindPropertyGivenUp = this.attMap['DescOfLikeKindPropertyGivenUp'][0];
      }
      if (this.attMap['DescriptionOfOtherPropertyGivenUp']) {
        this.DescriptionOfOtherPropertyGivenUp = this.attMap['DescriptionOfOtherPropertyGivenUp'][0];
      }
      if (this.attMap['DescOfLikeKindPropertyReceived']) {
        this.DescOfLikeKindPropertyReceived = this.attMap['DescOfLikeKindPropertyReceived'][0];
      }
      if (this.attMap['DescriptionOfOtherPropertyRcvd']) {
        this.DescriptionOfOtherPropertyRcvd = this.attMap['DescriptionOfOtherPropertyRcvd'][0];
      }
      if (this.attMap['RelationshipToYou']) {
        this.RelationshipToYou = this.attMap['RelationshipToYou'][0];
      }
      if (this.attMap['PrincipalPrpsRltdPartyExchStmtExplanation']) {
        this.PrincipalPrpsRltdPartyExchStmtExplanation = this.attMap['PrincipalPrpsRltdPartyExchStmtExplanation'][0] !== undefined ? this.attMap['PrincipalPrpsRltdPartyExchStmtExplanation'][0] : '';
      }
      if (this.attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount']) {
        this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount = this.attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount'][0];
      }
      if (this.attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation']) {
        this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation = this.attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation'][0];
      }
      if (this.attMap['FMVOfOtherPropertyGivenUp']) {
        this.FMVOfOtherPropertyGivenUp = this.attMap['FMVOfOtherPropertyGivenUp'][0];
      }
      if (this.attMap['AdjBasisOfOtherPropertyGivenUp']) {
        this.AdjBasisOfOtherPropertyGivenUp = this.attMap['AdjBasisOfOtherPropertyGivenUp'][0];
      }
      if (this.attMap['GainLossOnOtherPropertyGivenUp']) {
        this.GainLossOnOtherPropertyGivenUp = this.attMap['GainLossOnOtherPropertyGivenUp'][0];
      }
      if (this.attMap['CashFMVAndNetLiabRedByExpenses']) {
        this.CashFMVAndNetLiabRedByExpenses = this.attMap['CashFMVAndNetLiabRedByExpenses'][0];
      }
      if (this.attMap['FMVOfLikeKindPropertyReceived']) {
        this.FMVOfLikeKindPropertyReceived = this.attMap['FMVOfLikeKindPropertyReceived'][0];
      }
      if (this.attMap['AmountRealized']) {
        this.AmountRealized = this.attMap['AmountRealized'][0];
      }
      if (this.attMap['AdjBasisOfLikeKindPropGivenUp']) {
        this.AdjBasisOfLikeKindPropGivenUp = this.attMap['AdjBasisOfLikeKindPropGivenUp'][0];
      }
      if (this.attMap['RealizedGainOrLoss']) {
        this.RealizedGainOrLoss = this.attMap['RealizedGainOrLoss'][0];
      }
      if (this.attMap['SmallerGainOrLoss']) {
        this.SmallerGainOrLoss = this.attMap['SmallerGainOrLoss'][0];
      }
      if (this.attMap['OrdinaryIncmUndRecaptureRules']) {
        this.OrdinaryIncmUndRecaptureRules = this.attMap['OrdinaryIncmUndRecaptureRules'][0];
      }
      if (this.attMap['SmallerGainLossLessOrdnryIncm']) {
        this.SmallerGainLossLessOrdnryIncm = this.attMap['SmallerGainLossLessOrdnryIncm'][0];
      }
      if (this.attMap['RecognizedGain']) {
        this.RecognizedGain = this.attMap['RecognizedGain'][0];
      }
      if (this.attMap['DeferredGainOrLoss']) {
        this.DeferredGainOrLoss = this.attMap['DeferredGainOrLoss'][0];
      }
      if (this.attMap['BasisOfLikeKindPropertyRcvd']) {
        this.BasisOfLikeKindPropertyRcvd = this.attMap['BasisOfLikeKindPropertyRcvd'][0];
      }
      if (this.attMap['BasisOfLikeKindSect1250PropertyRcvd']) {
        this.BasisOfLikeKindSect1250PropertyRcvd = this.attMap['BasisOfLikeKindSect1250PropertyRcvd'][0];
      }
      if (this.attMap['BasisOfLikeKindSect1245PropertyRcvd']) {
        this.BasisOfLikeKindSect1245PropertyRcvd = this.attMap['BasisOfLikeKindSect1245PropertyRcvd'][0];
      }
      if (this.attMap['BasisOfLikeKindIntagblePropertyRcvd']) {
        this.BasisOfLikeKindIntagblePropertyRcvd = this.attMap['BasisOfLikeKindIntagblePropertyRcvd'][0];
      }
      this.onCalculateAll();

      if (this.attMap['DateLikeKindPropertyGivenUpAcq'] && this.attMap['DateLikeKindPropertyGivenUpAcq'][0]) {
        this.DateLikeKindPropertyGivenUpAcq = new Date(this.attMap['DateLikeKindPropertyGivenUpAcq'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['DatePropActuallyTransferred'] && this.attMap['DatePropActuallyTransferred'][0]) {
        this.DatePropActuallyTransferred = new Date(this.attMap['DatePropActuallyTransferred'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['DateLikeKindPropRcvdIdentified'] && this.attMap['DateLikeKindPropRcvdIdentified'][0]) {
        this.DateLikeKindPropRcvdIdentified = new Date(this.attMap['DateLikeKindPropRcvdIdentified'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['DatePropertyActuallyReceived'] && this.attMap['DatePropertyActuallyReceived'][0]) {
        this.DatePropertyActuallyReceived = new Date(this.attMap['DatePropertyActuallyReceived'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }

      if (this.attMap['ExchangeMadeWithRelatedParty'] && this.attMap['ExchangeMadeWithRelatedParty'][0]) {
        this.ExchangeMadeWithRelatedParty = (this.attMap['ExchangeMadeWithRelatedParty'][0] === '1') ? true : (this.attMap['ExchangeMadeWithRelatedParty'][0] == '0' ? false : undefined);
      }

      if (this.attMap['RelatedPartySoldPropReceived'] && this.attMap['RelatedPartySoldPropReceived'][0]) {
        this.RelatedPartySoldPropReceived = this.attMap['RelatedPartySoldPropReceived'][0] === '1';
      }
      if (this.attMap['DisposWasAfterDeathRltdParties'] && this.attMap['DisposWasAfterDeathRltdParties'][0]) {
        this.DisposWasAfterDeathRltdParties = this.attMap['DisposWasAfterDeathRltdParties'][0] === '1';
      }
      if (this.attMap['YouSoldPropertyReceived'] && this.attMap['YouSoldPropertyReceived'][0]) {
        this.YouSoldPropertyReceived = this.attMap['YouSoldPropertyReceived'][0] === '1';
      }
      if (this.attMap['DisposWasInvoluntaryConversion'] && this.attMap['DisposWasInvoluntaryConversion'][0]) {
        this.DisposWasInvoluntaryConversion = this.attMap['DisposWasInvoluntaryConversion'][0] === '1';
      }
      if (this.attMap['ExchangeDisposNotTaxAvoidance'] && this.attMap['ExchangeDisposNotTaxAvoidance'][0]) {
        this.ExchangeDisposNotTaxAvoidance = this.attMap['ExchangeDisposNotTaxAvoidance'][0] === '1';
      }
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
            case 1:
              if (data.secondaryleid) {
                element.fillLEID(data.secondaryleid);
              }
              break;
          }
        });
      });
      this.cdref.detectChanges();
    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
      });
    }else{
      this.nameChangeChecked = true;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!, this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.DatePropActuallyTransferred && this.transferor?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.sharedService.getTransDateStr(this.DatePropActuallyTransferred),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]?.LE_NAME) {
          this.transferor!.ENTITY = response[0].LE_NAME; // override entity name
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.DatePropActuallyTransferred) {
      this.nameChangeChecked = true;

    }
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    if (!selectedTransferor) {
      return;
    }

    if (this.DatePropActuallyTransferred && this.transferee?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.sharedService.getTransDateStr(this.DatePropActuallyTransferred),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]?.LE_NAME) {
          this.transferee!.ENTITY = response[0].LE_NAME; // override entity name
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'secondaryEntityId': this.ExchangeMadeWithRelatedParty === true ? this.transferee ? this.transferee?.ENTITYID.toString() : '' : '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };

      const jsonObject = {
        'TransferorLEID': this.transferor ? this.transferor?.LEID.toString() : '',
        'TransfereeLEID': this.ExchangeMadeWithRelatedParty === true ? this.transferee ? this.transferee?.LEID.toString() : '' : '',
        'DescOfLikeKindPropertyGivenUp': this.DescOfLikeKindPropertyGivenUp !== null ? this.DescOfLikeKindPropertyGivenUp : '',
        'DescriptionOfOtherPropertyGivenUp': this.DescriptionOfOtherPropertyGivenUp !== null ? this.DescriptionOfOtherPropertyGivenUp : '',
        'DescOfLikeKindPropertyReceived': this.DescOfLikeKindPropertyReceived !== null ? this.DescOfLikeKindPropertyReceived : '',
        'DescriptionOfOtherPropertyRcvd': this.DescriptionOfOtherPropertyRcvd !== null ? this.DescriptionOfOtherPropertyRcvd : '',
        'DateLikeKindPropertyGivenUpAcq': this.DateLikeKindPropertyGivenUpAcq !== null ? this.DateLikeKindPropertyGivenUpAcq : '',
        'DatePropActuallyTransferred': this.DatePropActuallyTransferred !== null ? this.DatePropActuallyTransferred : '',
        'DateLikeKindPropRcvdIdentified': this.DateLikeKindPropRcvdIdentified !== null ? this.DateLikeKindPropRcvdIdentified : '',
        'DatePropertyActuallyReceived': this.DatePropertyActuallyReceived !== null ? this.DatePropertyActuallyReceived : '',
        'ExchangeMadeWithRelatedParty': this.ExchangeMadeWithRelatedParty !== undefined ? this.ExchangeMadeWithRelatedParty === true ? '1' : '0' : '',
        'MissingEINReason': '',
        'RelationshipToYou': this.ExchangeMadeWithRelatedParty === true ? this.RelationshipToYou !== null ? this.RelationshipToYou : '' : '',
        'RelatedPartySoldPropReceived': this.ExchangeMadeWithRelatedParty === true ? this.RelatedPartySoldPropReceived !== undefined ? this.RelatedPartySoldPropReceived === true ? '1' : '0' : '0' : '',
        'YouSoldPropertyReceived': this.ExchangeMadeWithRelatedParty === true ? this.YouSoldPropertyReceived !== undefined ? this.YouSoldPropertyReceived === true ? '1' : '0' : '0' : '',
        'DisposWasAfterDeathRltdParties': this.ExchangeMadeWithRelatedParty === true ? this.DisposWasAfterDeathRltdParties ? '1' : '0' : '',
        'DisposWasInvoluntaryConversion': this.ExchangeMadeWithRelatedParty === true ? this.DisposWasInvoluntaryConversion ? '1' : '0' : '',
        'ExchangeDisposNotTaxAvoidance': this.ExchangeMadeWithRelatedParty === true ? this.ExchangeDisposNotTaxAvoidance ? '1' : '0' : '',
        'PrincipalPrpsRltdPartyExchStmtExplanation': this.ExchangeMadeWithRelatedParty === true ? this.PrincipalPrpsRltdPartyExchStmtExplanation : '',
        'GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount': this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount + '',
        'GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation': this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation,
        'FMVOfOtherPropertyGivenUp': this.FMVOfOtherPropertyGivenUp + '',
        'AdjBasisOfOtherPropertyGivenUp': this.AdjBasisOfOtherPropertyGivenUp + '',
        'GainLossOnOtherPropertyGivenUp': this.GainLossOnOtherPropertyGivenUp + '',
        'CashFMVAndNetLiabRedByExpenses': this.CashFMVAndNetLiabRedByExpenses + '',
        'FMVOfLikeKindPropertyReceived': this.FMVOfLikeKindPropertyReceived + '',
        'AmountRealized': this.AmountRealized + '',
        'AdjBasisOfLikeKindPropGivenUp': this.AdjBasisOfLikeKindPropGivenUp + '',
        'RealizedGainOrLoss': this.RealizedGainOrLoss + '',
        'SmallerGainOrLoss': this.SmallerGainOrLoss + '',
        'OrdinaryIncmUndRecaptureRules': this.OrdinaryIncmUndRecaptureRules + '',
        'SmallerGainLossLessOrdnryIncm': this.SmallerGainLossLessOrdnryIncm + '',
        'RecognizedGain': this.RecognizedGain + '',
        'DeferredGainOrLoss': this.DeferredGainOrLoss + '',
        'BasisOfLikeKindPropertyRcvd': this.BasisOfLikeKindPropertyRcvd + '',
        'BasisOfLikeKindIntagblePropertyRcvd': this.BasisOfLikeKindIntagblePropertyRcvd + '',
        'BasisOfLikeKindSect1245PropertyRcvd': this.BasisOfLikeKindSect1245PropertyRcvd + '',
        'BasisOfLikeKindSect1250PropertyRcvd': this.BasisOfLikeKindSect1250PropertyRcvd + ''

      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  check7(val: boolean) {
    this.ExchangeMadeWithRelatedParty = val;

  }

  check9(val: boolean) {
    this.RelatedPartySoldPropReceived = val;
  }

  check10(val: boolean) {
    this.YouSoldPropertyReceived = val;
  }

  check11a() {
    this.DisposWasAfterDeathRltdParties = !this.DisposWasAfterDeathRltdParties;
  }

  check11b() {
    this.DisposWasInvoluntaryConversion = !this.DisposWasInvoluntaryConversion;
  }

  check11c() {
    this.ExchangeDisposNotTaxAvoidance = !this.ExchangeDisposNotTaxAvoidance;
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.DateLikeKindPropertyGivenUpAcq = event.startDate.format('YYYY-MM-DD');
        break;
      case 2:
        this.DatePropActuallyTransferred = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.sharedService.getTransDateStr(this.DatePropActuallyTransferred),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });

        break;

      case 3:
        this.DateLikeKindPropRcvdIdentified = event.startDate.format('YYYY-MM-DD');
        break;

      case 4:
        this.DatePropertyActuallyReceived = event.startDate.format('YYYY-MM-DD');
        break;


      default:
        break;
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  updateValue12(event: FormFieldType): void {
    this.FMVOfOtherPropertyGivenUp = event.value;
    this.onCalculateAll();
  }

  updateValue13(event: FormFieldType): void {
    this.AdjBasisOfOtherPropertyGivenUp = event.value;
    this.onCalculateAll();
  }

  updateValue15(event: FormFieldType): void {
    this.CashFMVAndNetLiabRedByExpenses = event.value;
    this.onCalculateAll();
  }

  updateValue16(event: FormFieldType): void {
    this.FMVOfLikeKindPropertyReceived = event.value;
    this.onCalculateAll();
  }

  updateValue18(event: FormFieldType): void {
    this.AdjBasisOfLikeKindPropGivenUp = event.value;
    this.onCalculateAll();
  }

  updateValue21(event: FormFieldType): void {
    this.OrdinaryIncmUndRecaptureRules = event.value;
    this.onCalculateAll();
  }

  onCalculateAll(): void {
    if (this.FMVOfOtherPropertyGivenUp !== '' || this.AdjBasisOfOtherPropertyGivenUp !== '') {
      if (this.FMVOfOtherPropertyGivenUp === '') {
        this.GainLossOnOtherPropertyGivenUp = 0 - parseFloat(this.AdjBasisOfOtherPropertyGivenUp) + '';
      } else if (this.AdjBasisOfOtherPropertyGivenUp === '') {
        this.GainLossOnOtherPropertyGivenUp = parseFloat(this.FMVOfOtherPropertyGivenUp) - 0 + '';
      } else {
        this.GainLossOnOtherPropertyGivenUp = parseFloat(this.FMVOfOtherPropertyGivenUp) - parseFloat(this.AdjBasisOfOtherPropertyGivenUp) + '';
      }

    }

    if (this.CashFMVAndNetLiabRedByExpenses !== '' || this.FMVOfLikeKindPropertyReceived !== '') {
      if (this.CashFMVAndNetLiabRedByExpenses === '') {
        this.AmountRealized = 0 + parseFloat(this.FMVOfLikeKindPropertyReceived) + '';
      } else if (this.FMVOfLikeKindPropertyReceived === '') {
        this.AmountRealized = parseFloat(this.CashFMVAndNetLiabRedByExpenses) + 0 + '';
      } else {
        this.AmountRealized = parseFloat(this.CashFMVAndNetLiabRedByExpenses) + parseFloat(this.FMVOfLikeKindPropertyReceived) + '';
      }

    }

    if (this.AmountRealized && this.AdjBasisOfLikeKindPropGivenUp && this.AmountRealized !== '' && this.AdjBasisOfLikeKindPropGivenUp !== '') {
      this.RealizedGainOrLoss = parseFloat(this.AmountRealized) - parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + '';
    }

    if (this.CashFMVAndNetLiabRedByExpenses && this.RealizedGainOrLoss && this.CashFMVAndNetLiabRedByExpenses !== '' && this.RealizedGainOrLoss !== '') {
      if (parseFloat(this.CashFMVAndNetLiabRedByExpenses) < parseFloat(this.RealizedGainOrLoss)) {
        if (parseFloat(this.CashFMVAndNetLiabRedByExpenses) > 0) {
          this.SmallerGainOrLoss = this.CashFMVAndNetLiabRedByExpenses;
        } else {
          this.SmallerGainOrLoss = 0 + '';
        }
      } else {
        if (parseFloat(this.RealizedGainOrLoss) > 0) {
          this.SmallerGainOrLoss = this.RealizedGainOrLoss;
        } else {
          this.SmallerGainOrLoss = 0 + '';
        }
      }
    }

    if (this.SmallerGainOrLoss !== '' || this.OrdinaryIncmUndRecaptureRules !== '') {
      if (this.SmallerGainOrLoss === '') {
        const calc = 0 - parseFloat(this.OrdinaryIncmUndRecaptureRules);
        if (calc < 0) {
          this.SmallerGainLossLessOrdnryIncm = 0 + '';
        } else {
          this.SmallerGainLossLessOrdnryIncm = calc + '';
        }
      } else if (this.OrdinaryIncmUndRecaptureRules === '') {
        const calc = parseFloat(this.SmallerGainOrLoss) - 0;
        if (calc < 0) {
          this.SmallerGainLossLessOrdnryIncm = 0 + '';
        } else {
          this.SmallerGainLossLessOrdnryIncm = calc + '';
        }
      } else {
        const calc = parseFloat(this.SmallerGainOrLoss) - parseFloat(this.OrdinaryIncmUndRecaptureRules);
        if (calc < 0) {
          this.SmallerGainLossLessOrdnryIncm = 0 + '';
        } else {
          this.SmallerGainLossLessOrdnryIncm = calc + '';
        }
      }

    }

    if (this.SmallerGainLossLessOrdnryIncm !== '' || this.OrdinaryIncmUndRecaptureRules !== '') {
      if (this.SmallerGainLossLessOrdnryIncm === '') {
        this.RecognizedGain = 0 + parseFloat(this.OrdinaryIncmUndRecaptureRules) + '';
      } else if (this.OrdinaryIncmUndRecaptureRules === '') {
        this.RecognizedGain = parseFloat(this.SmallerGainLossLessOrdnryIncm) + 0 + '';
      } else {
        this.RecognizedGain = parseFloat(this.SmallerGainLossLessOrdnryIncm) + parseFloat(this.OrdinaryIncmUndRecaptureRules) + '';
      }


    }

    if (this.RecognizedGain !== '' || this.RealizedGainOrLoss !== '') {
      if (this.RecognizedGain === '') {
        this.DeferredGainOrLoss = parseFloat(this.RealizedGainOrLoss) - 0 + '';
      } else if (this.RealizedGainOrLoss === '') {
        this.DeferredGainOrLoss = 0 - parseFloat(this.RecognizedGain) + '';
      } else {
        this.DeferredGainOrLoss = parseFloat(this.RealizedGainOrLoss) - parseFloat(this.RecognizedGain) + '';
      }

    }

    if (this.AdjBasisOfLikeKindPropGivenUp !== '' || this.CashFMVAndNetLiabRedByExpenses !== '' || this.RecognizedGain !== '') {
      if (this.AdjBasisOfLikeKindPropGivenUp === '') {
        if (this.CashFMVAndNetLiabRedByExpenses === '') {
          this.BasisOfLikeKindPropertyRcvd = (0 + parseFloat(this.RecognizedGain)) - 0 + '';
        } else if (this.RecognizedGain === '') {
          this.BasisOfLikeKindPropertyRcvd = 0 - parseFloat(this.CashFMVAndNetLiabRedByExpenses) + '';
        } else {
          this.BasisOfLikeKindPropertyRcvd = (0 + parseFloat(this.RecognizedGain)) - parseFloat(this.CashFMVAndNetLiabRedByExpenses) + '';
        }
      } else if (this.CashFMVAndNetLiabRedByExpenses === '') {
        if (this.AdjBasisOfLikeKindPropGivenUp === '') {
          this.BasisOfLikeKindPropertyRcvd = (0 + parseFloat(this.RecognizedGain)) - 0 + '';
        } else if (this.RecognizedGain === '') {
          this.BasisOfLikeKindPropertyRcvd = (parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + 0) - 0 + '';
        } else {
          this.BasisOfLikeKindPropertyRcvd = (parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + parseFloat(this.RecognizedGain)) - 0 + '';
        }
      } else if (this.RecognizedGain === '') {
        if (this.CashFMVAndNetLiabRedByExpenses === '') {
          this.BasisOfLikeKindPropertyRcvd = (parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + 0) - 0 + '';
        } else if (this.AdjBasisOfLikeKindPropGivenUp === '') {
          this.BasisOfLikeKindPropertyRcvd = 0 - parseFloat(this.CashFMVAndNetLiabRedByExpenses) + '';
        } else {
          this.BasisOfLikeKindPropertyRcvd = (parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + 0) - parseFloat(this.CashFMVAndNetLiabRedByExpenses) + '';
        }
      } else {
        this.BasisOfLikeKindPropertyRcvd = (parseFloat(this.AdjBasisOfLikeKindPropGivenUp) + parseFloat(this.RecognizedGain)) - parseFloat(this.CashFMVAndNetLiabRedByExpenses) + '';
      }

    }
  }

  isFormValid(placeholder: boolean): boolean {
    if (!this.transferor) {
      this.dstSharedService.showAlert('error', 'Please specify the LEID for the Reporting Entity');
      return false;
    }
    if (placeholder) {
      return true;
    }
    if (this.DescOfLikeKindPropertyGivenUp === '' || this.DescOfLikeKindPropertyReceived === '') {
      this.dstSharedService.showAlert('error', 'Please specify the Description of the Like-Kind Property Given Up');
      return false;
    }
    if (this.ExchangeMadeWithRelatedParty) {
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify LEID the of Related Party');
        return false;
      }
      if (this.ExchangeDisposNotTaxAvoidance && this.PrincipalPrpsRltdPartyExchStmtExplanation === '') {
        this.dstSharedService.showAlert('error', 'Please specify an explanation');
        return false;
      }
    }
    if (this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount !== '' && this.GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation === '') {
      this.dstSharedService.showAlert('error', 'Please specify the details of the calculation');
      return false;
    }

    return true;
  }
}

export type FormFieldType = {
  event: Event;
  value: string;
}
