<h1 mat-dialog-title></h1>
<div mat-dialog-content>
    <div class="confirm-message">
        Are you sure you want to delete all non Mef entities?
    </div>
  
</div>
<div mat-dialog-actions>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">
        <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
            [config]="secondaryConfig" (onBtnClick)="cancel()"></ap-button>
        <ap-button [btnText]="'MEF SYNC DELETE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
        (onBtnClick)="sync('MEF_SYNC_DELETE')"></ap-button>

    </div>
  
</div>