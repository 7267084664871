import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { DataSheets } from '../data-sheet-model';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import _, { filter } from 'lodash';
// import { AddEditComponent } from '../../add-edit/add-edit.component';
import { SummaryDiagnosticService } from '../summary-diagnostic.service';
import { DetailDiagViewComponent } from './detail-diag-view/detail-diag-view.component';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
// import { table } from 'console';

@Component({
  selector: 'gtw-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges {

  constructor(public summaryDiagService: SummaryDiagnosticService) { }
  
  @Input('table-sheet')
  tableSheet:any;
  expand:boolean = true;
  checked:boolean =false;
  color = 'accent';
  scrollBarHidden: boolean = false;
  // @ViewChild(AddEditComponent) addEditModal !: AddEditComponent;
  @ViewChild(DetailDiagViewComponent) detailModal !: DetailDiagViewComponent;
  showAddEditModalViewer: boolean = false;
  drillDownObj: any;
  @Input('index')
  index:any;

  @Input('searchValue')
  searchValue:any;

  @Input('kpiFilter')
  kpiFilter:any;

  @Input('selectedFilterParams')
  selectedFilterParams:any

  @Input('hideResolvedDiag')
  hideResolvedDiag:boolean = false;

  @Input('expandCollapseAll')
  expandCollapseAll: boolean = false;
  
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};


  @Input('globalParams')
  globalParams:any
  @Output() loadDashboardScreen = new EventEmitter<any>();

  cols: any[] = [
    { header:'Diagnostic Description', field:'DIAG_DESC'},
    { header:'Not Loaded/Failed', field:'FAILCNT'},
    { header:'System', field:'DIAG_SOURCE_SYSTEM_FLAG'},
    { header:' Criticality/Priority', field:'DIAG_MSG'},
  ]
  ngOnInit(): void {

    console.log('data component',this.tableSheet);
    console.log('Index',this.index);

    /* if(this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['isToggled'] !=undefined){
      this.checked = this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['isToggled'];
      this.toggle();
    } */
    if( this.summaryDiagService.dashboardPool[0]['hideResolvedDiag']){
      this.hideResolvedDiag = this.summaryDiagService.dashboardPool[0]['hideResolvedDiag'];
      this.toggle();
    }
    if( this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['expanded']!=undefined){
      this.expand =  this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['expanded'];
    }
    if(this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['kpiFilter']!=undefined){
      this.kpiFilter = this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['kpiFilter'];
      this.filterTableData();
    }
  }
  visibleKeysArray:any=[];
  ngOnChanges(changes: SimpleChanges): void {
      console.log('filter changes',changes);
      if(changes.searchValue && changes.searchValue.currentValue && changes.searchValue.currentValue.query != ''){ 
        this.tableSheet[0].sheet.data =   this.tableSheet[0].sheet.safeCopyArr.filter((o:any) => Object.values(o).some((v:any) => 
            _.includes(v,changes.searchValue.currentValue.query)
        ))
      }else{
        this.tableSheet[0].sheet.data = _.cloneDeep(this.tableSheet[0].sheet.safeCopyArr);
      }
      let searchaValue = this.searchValue;
      let kpiFilter = this.kpiFilter;
      // hide resolved
      if((changes && changes.hideResolvedDiag) || this.summaryDiagService.dashboardPool[0]['hideResolvedDiag']){
        this.hideResolvedDiag = changes.hideResolvedDiag? changes.hideResolvedDiag.currentValue : this.summaryDiagService.dashboardPool[0]['hideResolvedDiag'];
        this.toggle();
      }
      if((changes && changes.expandCollapseAll) || this.summaryDiagService.dashboardPool[0]['expandCollapseAll'] !=undefined){
        this.expand = changes.expandCollapseAll ? changes.expandCollapseAll.currentValue : this.summaryDiagService.dashboardPool[0]['expandCollapseAll'];
        this.expandClose();
      }
      this.filterTableData();

  }
  filterTableData(){
    if(this.kpiFilter != 'All' && this.kpiFilter != ""){
      this.tableSheet[0].sheet.data = this.tableSheet[0].sheet.data.filter((o:any) => {
        return o.DIAG_MSG == this.kpiFilter
       })
    }
    this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['kpiFilter'] = this.kpiFilter;
  }
  toggle(){
      console.log('toggle checked',event);
      console.log('data',this.tableSheet);
      if(this.hideResolvedDiag){
        this.tableSheet[0].sheet.data = _.filter(this.tableSheet[0].sheet.data,function(i){return i['FAILCNT']>0});
      }else{
        this.tableSheet[0].sheet.data = this.tableSheet[0].sheet.safeCopyArr;
      }
      this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['isToggled'] = this.checked;
      this.filterTableData()
    }
  expandClose(){
    this.expand = !this.expand;
    this.summaryDiagService.dashboardPool[0]['components'][this.index][0]['expanded'] = this.expand;
    console.log('expand',this.expand);
  }
  changed(){
    console.log(this.checked)

  }
  showEditComponent(tableData: any,base_url:any){
   this.showAddEditModalViewer = true;
   let tableConfigData = this.summaryDiagService.dashboardPool[0]['components'][this.index][0];
   setTimeout(() => {
    this.detailModal.showMore('show-deatil-diag',tableConfigData,tableData,base_url,this.selectedFilterParams,this.globalParams);
   })
  }
  afterModalClosed(){
    this.showAddEditModalViewer = false;

  }
   gridFilters :any = {
    gridFilters:[],
    drillDownRefresh:0,
    filterObj:{},
    screenFilter:[]
};
  redirectDrillDown(rowData:any ,i: any){
    console.log('selectedFilterParams',this.selectedFilterParams);
    let obj = JSON.parse(this.tableSheet[0].extraOptionsJSON.componentSettings.drilldownFilterJson);

    _.each(obj.screenFilters,(i:any) => {
      if(i.name){
        i.value = this.selectedFilterParams[i.name];
      }
    });
    this.drillDownObj = obj;
    this.gridFilters.screenFilter = obj;
     /* let object:any = {};
     'DIAG_DESC'
      object.predicate = value;
     object.value = [];
     object.type = "array";
     object.reverse = false;
     object.operator = "[]";
    object.value.push(_value); */
    console.log('i',i);
    /*  drillDown: any = {
    isDrillDown: false,
    selectedFilterParms:{}
  }; */
    this.summaryDiagService.drillDown.isDrillDown = true;
    this.summaryDiagService.drillDown.selectedFilterParms = this.selectedFilterParams;
    this.gridFilters.filterParams = this.selectedFilterParams;
    
    this.loadDashboardScreen.emit(this.gridFilters);
  }

  loadDrillDownScreen(event:any){
    this.loadDashboardScreen.emit(event);

  }

}

