import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActiveScreensService } from '../../../../shared/_services/active-screens.service';
@Component({
  selector: 'gtw-activity-and-screen',
  templateUrl: './activity-and-screen.component.html',
  styleUrls: ['./activity-and-screen.component.scss']
})
export class ActivityAndScreenComponent implements OnInit{
  
  screenList:any = [];
  
  @Input('selected_menu')
  set _selectedMenu(selectedActivity: any){
    this.screenList = selectedActivity;
  }
  
  @Output() showScreensEvent = new EventEmitter<any>();

  constructor(private activeScreenService:ActiveScreensService) { }

  ngOnInit(): void {
  }

  showScreen(screen: any) {
    this.activeScreenService.setScreens(screen);
    screen.isActive = true;
    this.showScreensEvent.emit(screen);
  }
}
