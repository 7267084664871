<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form215" class="ap-form-field-my-wrapper">
    <dst-template-header [parentFormGroup]="form215" (formOptionChanged)="onFormOptionChanged($event)"
                         (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part I &nbsp;&nbsp;</strong>
          <strong>Filer's Identifying Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partOne" class="section-content ap-form-field-my-wrapper">
      <ol class="section-content mb-0">
        <li>
          <ol class="inner-list">
            <li>
              <dst-template-leid-selector class="required-field" [label]="'Please specify the LEID of the Filer'" [labelId]="'100'"
                                          (onSelectedLEChanged)="onTransferorChanged($event)">
              </dst-template-leid-selector>
              <div class="entity-details">
                <span>Name of Filer:</span><span class="template-color-blue">{{!transferor ? '' :
                                                                               transferor.ENTITY}}</span>
              </div>
            </li>
            <li>
              <div class="entity-details">
                <span>Identifying number:</span><span class="template-color-blue">{{!transferor ? '' :
                                                                                    (transferor.EIN ? transferor.EIN : 'N/A')}}</span>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-4 align-field-text">
                  Check applicable box
                </div>
                <div class="col-md-7 p-0">
                  <ap-radio [name]="'radio-group-1'" [text]="'Old target'" [checked]="editMode && Choice1cTarget ? true : false"
                            (onCheck)="check1c(true)" class="a-mx-10 required-field-radio" style="margin-left: 0px !important;">
                  </ap-radio>
                  &nbsp;&nbsp;
                  <ap-radio [name]="'radio-group-1'" [text]="'New target'" [checked]="editMode && !Choice1cTarget ? true : false"
                            (onCheck)="check1c(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-4 align-field-text">
                  Was a valid and timely Form 8023 filed?
                </div>
                <div class="col-md-7 p-0">
                  <ap-radio [name]="'radio-group-2'" [text]="'Yes'" [checked]="editMode && Choice1dDate ? true : false"
                            (onCheck)="check1d(true)" class="a-mx-10 required-field-radio" style="margin-left: 0px !important;">
                  </ap-radio>
                  &nbsp;&nbsp;
                  <ap-radio [name]="'radio-group-2'" [text]="'No'" [checked]="editMode && !Choice1dDate ? true : false"
                            (onCheck)="check1d(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="col-md-4 align-field-text">
                  If yes, enter date filed.
                </div>
                <div class="col-md-7 p-0">
                  <ap-date-picker *ngIf="nameChangeChecked" [hidden]="Choice1dDate !== true" format="yyyy-MM-DD" type="singleCalendar" size="medium"
                                  [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()" class="required-field"
                                  formControlName="Yes1dDateText" [(ngModel)]="Yes1dDateText" (onChange)="dateChange($event, 1)">
                  </ap-date-picker>
                </div>
              </div>

            </li>
          </ol>
        </li>
      </ol>

    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part II &nbsp;&nbsp; Other Party's Identifying Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partTwo" class="section-content ap-form-field-my-wrapper">
      <div class="d-flex">
        <div class="col-md-4">
          Is the Other Party a US Entity ?
        </div>
        <div class="col-md-3 pl-0">
          <ap-radio [name]="'radio-group-3'" [text]="'Yes'" [checked]="editMode && OtherPartyUSEnt" (onCheck)="check2(true)"
                    class="a-mx-10 required-field-radio">
          </ap-radio>
          &nbsp;&nbsp;
          <ap-radio [name]="'radio-group-3'" [text]="'No'" [checked]="editMode && !OtherPartyUSEnt" (onCheck)="check2(false)"
                    class="a-mx-10 required-field-radio">
          </ap-radio>
        </div>
      </div>
      <br/>
      <ol class="section-content" start="2" [hidden]="OtherPartyUSEnt === undefined">
        <li>
          <ol class="inner-list">
            <li>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  Name of other party to the transaction
                </div>
                <div class="col-md-8 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="OtherParty" [(ngModel)]="OtherParty"
                                 [id]="'1'" [type]="'input'" [placeholder]="''" class="required-field">
                  </ap-form-field>
                </div>
              </div>
            </li>
            <li>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  EIN
                </div>
                <div class="col-md-3 radio-align mt-1">
                  <ap-radio [name]="'radio-group-4'" [text]="'EIN or'" [checked]="editMode && einSsnRadio"
                            (onCheck)="check2b(true)" class="a-mx-10">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-4'" [text]="'SSN?'" [checked]="editMode && !einSsnRadio"
                            (onCheck)="check2b(false)" class="a-mx-10">
                  </ap-radio>
                </div>
              </div>
              <div class="part-two d-flex mt-1">
                <div class="col-md-4">
                </div>
                <div class="col-md-3 radio-align" *ngIf="OtherPartyUSEnt">
                  <!-- <ap-form-field [hidden]="einSsnRadio !== true" [inlineBlock]="true" [label]="''"
                                 formControlName="EINAppOP" [(ngModel)]="EINAppOP" class="required-field" [id]="'2'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>   -->
                  <dst-custom-input class="required-field dst-field" [hidden]="einSsnRadio !== true" [type]="'inputNumber'" id="2" [label]="''" [maxLength]="9" [value]="EINAppOP" (valChange)="EINAppOP = $event;"></dst-custom-input>
                  <ap-form-field [hidden]="einSsnRadio !== false" [inlineBlock]="true" [label]="''"
                                 formControlName="SNAppOP" [(ngModel)]="SNAppOP" class="required-field" [id]="'3'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
                <div class="col-md-3 radio-align" *ngIf="!OtherPartyUSEnt">
                  <ap-form-field [hidden]="einSsnRadio !== true" [inlineBlock]="true" [label]="''"
                                 formControlName="EINAppOP" [(ngModel)]="EINAppOP" class="required-field" [id]="'2'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                  <!-- <dst-custom-input [hidden]="einSsnRadio !== true && OtherPartyUSEnt === true" [type]="'inputNumber'" id="2" [label]="''" [maxLength]="9" [value]="EINAppOP" (valChange)="EINAppOP = $event;"></dst-custom-input> -->
                  <ap-form-field [hidden]="einSsnRadio !== false" [inlineBlock]="true" [label]="''"
                                 formControlName="SNAppOP" [(ngModel)]="SNAppOP" class="required-field" [id]="'3'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
            </li>
            <li class="mt-3">
              <div class="part-two d-flex" *ngIf="(!EINAppOP && einSsnRadio) || (!SNAppOP && !einSsnRadio) ">
                <div class="col-md-4">
                  EIN Missing Reason:
                </div>
                <div class="col-md-3 radio-align">
                  <ap-selector [items]="EINMissingReason1" [type]="'Dropdown'"
                               [placeholder]="'** Please select the missing reason **'"
                               (onSelectedChange)="missingReason1($event)" class="required-field-dropdown">
                  </ap-selector>
                </div>
              </div>

              <br/>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  Address Line 1
                </div>
                <div class="col-md-8 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="AddressOfOtherParty1"
                                 [(ngModel)]="AddressOfOtherParty1" class="required-field" [id]="'4'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
              <br/>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  Address Line 2
                </div>
                <div class="col-md-8 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="AddressOfOtherParty2"
                                 [(ngModel)]="AddressOfOtherParty2" [id]="'5'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
              <br/>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  City
                </div>
                <div class="col-md-8 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="CityOfOtherParty"
                                 [(ngModel)]="CityOfOtherParty" class="required-field" [id]="'6'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
              <br/>
              <div class="part-two d-flex" *ngIf="OtherPartyUSEnt">
                <div class="col-md-4">
                  State
                </div>
                <div class="col-md-8 radio-align">
                  <ap-selector [items]="StateOptions" [type]="'SingleSelector'" [searchMethod]="'inline'" class="required-field-dropdown field-full-width"
                               [placeholder]="'** Please select a state name **'" (onSelectedChange)="stateChange($event)">
                  </ap-selector>
                </div>
              </div>
              <br *ngIf="OtherPartyUSEnt"/>
              <div class="part-two d-flex" *ngIf="!OtherPartyUSEnt">
                <div class="col-md-4">
                  Province or State
                </div>
                <div class="col-md-3 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="ProvOrStateOP"
                                 [(ngModel)]="ProvOrStateOP" [id]="'7'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
              <br *ngIf="!OtherPartyUSEnt"/>
              <div class="part-two d-flex" *ngIf="!OtherPartyUSEnt">
                <div class="col-md-4">
                  Country
                </div>
                <div class="col-md-8 radio-align">
                  <ap-selector class="required-field-dropdown field-full-width" [items]="CountryOptions" [type]="'SingleSelector'"
                               [searchMethod]="'inline'" [placeholder]="'** Select Country **'"
                               (onSelectedChange)="countryChange($event)">
                  </ap-selector>
                </div>
              </div>
              <br *ngIf="!OtherPartyUSEnt"/>
              <div class="part-two d-flex">
                <div class="col-md-4">
                  Zip Code
                </div>
                <div class="col-md-3 radio-align">
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="ZIPCodeOfOtherParty"
                                 [(ngModel)]="ZIPCodeOfOtherParty" class="required-field" [id]="'8'" [type]="'input'" [placeholder]="''">
                  </ap-form-field>
                </div>
              </div>
              <br/>
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part III &nbsp;&nbsp; Target Corporation's Identifying Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partThree" class="section-content ap-form-field-my-wrapper">
      <div class="d-flex">
        <div class="col-md-4">
          Is the Target a US Entity?
        </div>
        <div class="col-md-2 pl-0">
          <ap-radio [name]="'radio-group-5'" [text]="'Yes'" [checked]="TargetCorpUSEnt === true" (onCheck)="check3(true)"
                    class="a-mx-10 required-field-radio">
          </ap-radio>
          &nbsp;&nbsp;
          <ap-radio [name]="'radio-group-5'" [text]="'No'" [checked]="TargetCorpUSEnt === false" (onCheck)="check3(false)"
                    class="a-mx-10 required-field-radio">
          </ap-radio>
        </div>
      </div>
      <ol class="section-content" start="3" [hidden]="TargetCorpUSEnt === undefined">
        <li>
          <ol class="inner-list">
            <li>
              <dst-template-leid-selector #targetLeidComp [label]="'Please specify the LEID of the target corporation'"
                                          [labelId]="'100'" class="required-field" (onSelectedLEChanged)="onTransfereeChanged($event)">
              </dst-template-leid-selector>
              <div class="entity-details">
                <span>Name of Target Corporation:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ENTITY}}</span>
              </div>
            </li>
            <li>
              <div class="entity-details">
                <span>Employer identification number:</span><span class="template-color-blue">{{!transferee ? '' : (transferee.EIN ? transferee.EIN : 'N/A')}}</span>
              </div>
              <div class="entity-details" *ngIf="transferee && !transferee.EIN">
                <span> EIN Missing Reason: </span>
                <div class="col-md-3 pl-0">
                  <ap-selector [items]="EINMissingReason" [type]="'Dropdown'"
                    [placeholder]="'** Please select the missing reason **'"
                    (onSelectedChange)="missingReason($event)" class="required-field-dropdown">
                  </ap-selector>
                </div>
              </div>
            </li>
            <li>
              <div class="entity-details">
                <span>Address Line 1:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ADDRESS1}}</span>
              </div>
              <div class="entity-details">
                <span>Address Line 2:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ADDRESS2 }}</span>
              </div>
              <div class="entity-details">
                <span *ngIf="TargetCorpUSEnt === true">State:</span><span *ngIf="TargetCorpUSEnt === false">Province or
                  State:</span><span class="template-color-blue">{{!transferee ? '' : stateNamePart3 }}</span>
              </div>
              <div class="entity-details">
                <span>City / Town:</span><span class="template-color-blue">{{!transferee ? '' : transferee.CITY}}</span>
              </div>
              <div class="entity-details">
                <span>Country:</span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYNAME}}</span>
              </div>
              <div class="entity-details">
                <span *ngIf="TargetCorpUSEnt === true">Zip Code:</span><span *ngIf="TargetCorpUSEnt === false">Postal
                  Code:</span><span class="template-color-blue">{{!transferee ? '' : transferee.ZIP}}</span>
              </div>
              <div class="entity-details">
                <span>State of Incorportation:</span><span class="template-color-blue">{{!transferee ? '' : transferee.STATEINC}}</span>
              </div>
              <div class="entity-details">
                <span>Country of Incorporation:</span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYINC}}</span>
              </div>
            </li>
          </ol>
        </li>
      </ol>
      <br/>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part IV</strong>
          &nbsp;&nbsp;
          <strong>General Information</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partFour" class="section-content ap-form-field-my-wrapper">
      <ol class="section-content" start="4">
        <li>
          <ol class="inner-list">
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  Acquisition Date
                </div>
                <div class="col-md-4 align-date">
                  <ap-date-picker format="yyyy-MM-DD" class="required-field acqt-date" type="singleCalendar" size="medium" [minDate]="beginDate"
                                  [maxDate]="endDate" [isTitleLeft]="true" (click)="onDPOpen()" formControlName="AcquisionDate"
                                  [(ngModel)]="AcquisionDate" (onChange)="dateChange($event, 2)">
                  </ap-date-picker>
                </div>
              </div>
            </li>
            <li>What percentage of target corporation stock was purchased:
              <ol class="third-list">
                <li>
                  <div class="d-flex">
                    <div class="col-md-8">
                      During the 12-month acquisition period
                    </div>
                    <div class="col-md-4 d-flex part-two">
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="PctDuring12Month"
                                     [(ngModel)]="PctDuring12Month" [id]="'9'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                     [placeholder]="''" [maxNum]="100" [minNum]="0">
                      </ap-form-field>&nbsp;%
                    </div>
                  </div>
                </li>
                <br/>
                <li>
                  <div class="d-flex">
                    <div class="col-md-8">
                      On the acquisition date
                    </div>
                    <div class="col-md-4 d-flex part-two">
                      <ap-form-field [inlineBlock]="true" [label]="''" formControlName="PctOnAcquisition"
                                     [(ngModel)]="PctOnAcquisition" [id]="'10'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                     [placeholder]="''" [maxNum]="100" [minNum]="0">
                      </ap-form-field>&nbsp;%

                    </div>
                  </div>
                </li>
                <br/>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <ol class="inner-list">
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  Stock price
                </div>
                <div class="col-md-4 d-flex part-two">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="StockPrice"
                                 [(ngModel)]="StockPrice" [id]="'11'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                 [placeholder]="''">
                  </ap-form-field>

                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  Acquisition costs/Selling costs
                </div>
                <div class="col-md-4 d-flex part-two">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="AcquisitionCostSC"
                                 [(ngModel)]="AcquisitionCostSC" [id]="'12'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                 [placeholder]="''">
                  </ap-form-field>

                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  Target liabilities
                </div>
                <div class="col-md-4 d-flex part-two">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TargetLiabilities"
                                 [(ngModel)]="TargetLiabilities" [id]="'13'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                 [placeholder]="''">
                  </ap-form-field>

                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  AGUB/ADSP
                </div>
                <div class="col-md-4 d-flex part-two">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="AGUBorADSP"
                                 [(ngModel)]="AGUBorADSP" [id]="'14'" [type]="'inputNumber'" class="number-field required-field" [funType]="'typeTwo'"
                                 [placeholder]="''">
                  </ap-form-field>

                </div>
              </div>
            </li>
            <br/>
          </ol>
        </li>
        <br/>
        <li>
          <div class="d-flex">
            <div class="col-md-8">
              Was the filer listed in Part I, above a member of an affiliated group of corporation before the
              acquisition date?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-6'" [text]="'Yes'" [checked]="editMode && Choice6 ? true : false"
                        (onCheck)="check6(true)" class="a-mx-10 align-radio required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-6'" [text]="'No'" [checked]="editMode && !Choice6 ? true : false"
                        (onCheck)="check6(false)" class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
        </li>
        <br/>

        <li>
          <div class="d-flex">
            <div class="col-md-8">
              Was the target corporation a member of an affiliated group before the acquisition date?
            </div>
            <div class="col-md-4">
              <ap-radio [name]="'radio-group-7'" [text]="'Yes'" [checked]="editMode && Choice7 ? true : false"
                        (onCheck)="check7(true)" class="a-mx-10 align-radio required-field-radio">
              </ap-radio>
              <ap-radio [name]="'radio-group-7'" [text]="'No'" [checked]="editMode && !Choice7 ? true : false"
                        (onCheck)="check7(false)" class="a-mx-10 required-field-radio">
              </ap-radio>
            </div>
          </div>
        </li>
        <br/>
        <li>Is the target corporation or any target affiliate:
          <ol class="inner-list section-content">
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A controlled foreign corporation?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-8'" [text]="'Yes'" [checked]="editMode && Choice8a ? true : false"
                            (onCheck)="check8a(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-8'" [text]="'No'" [checked]="editMode && !Choice8a ? true : false"
                            (onCheck)="check8a(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
              <br/>
              <div class="d-flex">
                <div class="col-md-8">
                  If "No", check here if it was a CFC at any time during the preceding 5 years
                </div>
                <div class="col-md-4 pl-4">
                  <ap-checkbox [text]="''" [checked]="editMode && IsCFCPrev5Yrs ? true : false"
                               (onCheck)="check8abox($event)" class="a-mx-10">
                  </ap-checkbox>
                </div>
              </div>

            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A foreign corporation with income, gain, or loss effectively connected with the conduct of a trade or
                  business within the United States (including U.S. real property interests)?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-9'" [text]="'Yes'" [checked]="editMode && Choice8b ? true : false"
                            (onCheck)="check8b(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-9'" [text]="'No'" [checked]="editMode && !Choice8b ? true : false"
                            (onCheck)="check8b(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A qualifying foreign target under Regulations section 1.338-2(e)(1)(iii)
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-10'" [text]="'Yes'" [checked]="editMode && Choice8c ? true : false"
                            (onCheck)="check8c(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-10'" [text]="'No'" [checked]="editMode && !Choice8c ? true : false"
                            (onCheck)="check8c(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A corporation electing under section 1504(d) or section 953(d)?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-11'" [text]="'Yes'" [checked]="editMode && Choice8e ? true : false"
                            (onCheck)="check8e(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-11'" [text]="'No'" [checked]="editMode && !Choice8e ? true : false"
                            (onCheck)="check8e(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A domestic international sales corporation (DISC)?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-12'" [text]="'Yes'" [checked]="editMode && Choice8f ? true : false"
                            (onCheck)="check8f(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-12'" [text]="'No'" [checked]="editMode && !Choice8f ? true : false"
                            (onCheck)="check8f(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li>
              <div class="d-flex">
                <div class="col-md-8">
                  A passive foreign investment company (PFIC)?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-13'" [text]="'Yes'" [checked]="editMode && Choice8g ? true : false"
                            (onCheck)="check8g(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-13'" [text]="'No'" [checked]="editMode && !Choice8g ? true : false"
                            (onCheck)="check8g(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
            <li [hidden]="Choice8g !== true">
              <div class="d-flex">
                <div class="col-md-8">
                  If the answer to item 8f is "Yes", is the PFIC a pedigreed qualified electing fund?
                </div>
                <div class="col-md-4 pl-4">
                  <ap-radio [name]="'radio-group-14'" [text]="'Yes'" [checked]="editMode && Choice8h ? true : false"
                            (onCheck)="check8h(true)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-14'" [text]="'No'" [checked]="editMode && !Choice8h ? true : false"
                            (onCheck)="check8h(false)" class="a-mx-10 required-field-radio">
                  </ap-radio>
                </div>
              </div>
            </li>
            <br/>
          </ol>
        </li>
      </ol>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part V</strong>
          &nbsp;&nbsp;
          <strong>Original Statement of Assets Transferred</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partFive" class="section-content ap-form-field-my-wrapper">
      <ol class="section-content list-content" start="9">
        <li style="counter-reset: count 8;">
          <table class="table-layout w-100 part5-table">
            <thead>
            <tr>
              <td class="col-md-4">
                Assets
              </td>
              <td class="col-md-4">
                Aggregate fair market value<br/>
                (actual amount for Class I)<br/>
                (For e-filing purposes, must be in USD)
              </td>
              <td class="col-md-4">
                Allocation of AGUB or ADSP<br/>
                (For e-filing purposes, must be in USD)
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="col-md-4 row-label">
                Class I
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1AFMV"
                                 [(ngModel)]="Class1AFMV" [id]="'15'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 1)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1AGUBOrADSP"
                                 [(ngModel)]="Class1AGUBOrADSP" (onChangeEvent)="updateValue($event, 7)" [id]="'16'"
                                 [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Class II
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2AFMV"
                                 [(ngModel)]="Class2AFMV" [id]="'17'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 2)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2AGUBOrADSP"
                                 [(ngModel)]="Class2AGUBOrADSP" [id]="'18'" [type]="'inputNumber'" class="number-field w-70"
                                 (onChangeEvent)="updateValue($event, 8)" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Class III
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3AFMV"
                                 [(ngModel)]="Class3AFMV" [id]="'19'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 3)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3AGUBOrADSP"
                                 [(ngModel)]="Class3AGUBOrADSP" [id]="'20'" [type]="'inputNumber'" class="number-field w-70"
                                 (onChangeEvent)="updateValue($event, 9)" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Class IV
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4AFMV"
                                 [(ngModel)]="Class4AFMV" [id]="'21'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 4)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4AGUBOrADSP"
                                 [(ngModel)]="Class4AGUBOrADSP" [id]="'22'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 10)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Class V
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5AFMV"
                                 [(ngModel)]="Class5AFMV" [id]="'23'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 5)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5AGUBOrADSP"
                                 [(ngModel)]="Class5AGUBOrADSP" [id]="'24'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 11)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Class VI and VII
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7AFMV"
                                 [(ngModel)]="Class6N7AFMV" [id]="'25'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 6)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7AGUBOrADSP"
                                 [(ngModel)]="Class6N7AGUBOrADSP" [id]="'26'" [type]="'inputNumber'" class="number-field w-70" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue($event, 12)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-4 row-label">
                Total
              </td>
              <td class="col-md-4 pr-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalAFMV"
                                 [(ngModel)]="TotalAFMV" [id]="'27'" [type]="'inputNumber'" class="number-field w-70 total-field" [funType]="'typeTwo'"
                                 [placeholder]="'0'" [isDisabled]="true">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-4 pl-0">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalAGUB"
                                 [(ngModel)]="TotalAGUB" [id]="'28'" [type]="'inputNumber'" class="number-field w-70 total-field" [funType]="'typeTwo'"
                                 [placeholder]="'0'" [isDisabled]="true">
                  </ap-form-field>

                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </li>
      </ol>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
      <tr>
        <td class="header-text">
          <strong>&nbsp;&nbsp;Part VI&nbsp;&nbsp;</strong>
          <strong>Supplemental Statement of Assets Transferred</strong>
        </td>
      </tr>
      </tbody>
    </table>
    <div formGroupName="partSix" class="section-content ap-form-field-my-wrapper">
      <ol class="section-content list-content" start="10">
        <li style="counter-reset: count 9;">
          <div class="d-flex">
            <div class="col-md-8">
              Enter the tax year and tax return form number with the original Form 8023 or Form 8883 and any
              supplemental statements that were filed.
            </div>
            <div class="col-md-2 d-flex">
              <span class="mr-2">Year</span>
              <ap-form-field [inlineBlock]="true" [label]="''" class="text-box" formControlName="TaxYear"
                [(ngModel)]="TaxYear" [id]="'29'" [type]="'input'" [placeholder]="''">
              </ap-form-field>
            </div>
            <div class="col-md-2 d-flex">
              <span class="mr-2">Form</span>
              <ap-form-field [inlineBlock]="true" [label]="''" class="text-box" formControlName="TaxReturnFormNumber"
                [(ngModel)]="TaxReturnFormNumber" [id]="'30'" [type]="'input'" [placeholder]="''">
              </ap-form-field>
            </div>
          </div>
        </li>
        <li style="counter-reset: count 10;">
          <table class="table-layout">
            <thead>
            <tr>
              <td class="col-md-3">
                Assets
              </td>
              <td class="col-md-3">
                Allocation of sales price as previously reported<br/>(For e-filing purposes, must be in USD)
              </td>
              <td class="col-md-3">
                Increase or (decrease)<br/>
                (For e-filing purposes, must be in USD)
              </td>
              <td class="col-md-3">
                Redetermination of allocation of AGUB or ADSP<br/>
                (For e-filing purposes, must be in USD)
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="col-md-3 row-label">
                Class I
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1ASPPR"
                                 [(ngModel)]="Class1ASPPR" [id]="'31'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 1)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1IncDec"
                                 [(ngModel)]="Class1IncDec" [id]="'32'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 13)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class1RtnAllAGUB"
                                 [(ngModel)]="Class1RtnAllAGUB" (onChangeEvent)="updateValue2($event, 7)" [id]="'33'"
                                 [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Class II
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2ASPPR"
                                 [(ngModel)]="Class2ASPPR" [id]="'34'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 2)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2IncDec"
                                 [(ngModel)]="Class2IncDec" [id]="'35'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 14)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class2RtnAllAGUB"
                                 [(ngModel)]="Class2RtnAllAGUB" [id]="'36'" [type]="'inputNumber'" class="number-field"
                                 (onChangeEvent)="updateValue2($event, 8)" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Class III
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3ASPPR"
                                 [(ngModel)]="Class3ASPPR" [id]="'37'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 3)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3IncDec"
                                 [(ngModel)]="Class3IncDec" [id]="'38'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 15)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class3RtnAllAGUB"
                                 [(ngModel)]="Class3RtnAllAGUB" [id]="'39'" [type]="'inputNumber'" class="number-field"
                                 (onChangeEvent)="updateValue2($event, 9)" [funType]="'typeTwo'" [placeholder]="''">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Class IV
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4ASPPR"
                                 [(ngModel)]="Class4ASPPR" [id]="'40'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 4)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4IncDec"
                                 [(ngModel)]="Class4IncDec" [id]="'41'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 16)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class4RtnAllAGUB"
                                 [(ngModel)]="Class4RtnAllAGUB" [id]="'42'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 10)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Class V
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5ASPPR"
                                 [(ngModel)]="Class5ASPPR" [id]="'43'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 5)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5IncDec"
                                 [(ngModel)]="Class5IncDec" [id]="'44'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 17)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class5RtnAllAGUB"
                                 [(ngModel)]="Class5RtnAllAGUB" [id]="'45'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 11)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Class VI and VII
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7ASPPR"
                                 [(ngModel)]="Class6N7ASPPR" [id]="'46'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 6)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7IncDec"
                                 [(ngModel)]="Class6N7IncDec" [id]="'47'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 18)">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="Class6N7RtnAllAGUB"
                                 [(ngModel)]="Class6N7RtnAllAGUB" [id]="'48'" [type]="'inputNumber'" class="number-field" [funType]="'typeTwo'"
                                 [placeholder]="''" (onChangeEvent)="updateValue2($event, 12)">
                  </ap-form-field>
                </div>

              </td>
            </tr>
            <tr>
              <td class="col-md-3 row-label">
                Total
              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalASPPR"
                                 [(ngModel)]="TotalASPPR" [id]="'49'" [type]="'inputNumber'" class="number-field total-field" [funType]="'typeTwo'"
                                 [placeholder]="'0'" [isDisabled]="true">
                  </ap-form-field>
                </div>
              </td>
              <td class="col-md-3">

              </td>
              <td class="col-md-3">
                <div class="d-flex">
                  $
                  <ap-form-field [inlineBlock]="true" [label]="''" formControlName="TotalRtnAllAGUB"
                                 [(ngModel)]="TotalRtnAllAGUB" [id]="'50'" [type]="'inputNumber'" class="number-field total-field" [funType]="'typeTwo'"
                                 [placeholder]="'0'" [isDisabled]="true">
                  </ap-form-field>

                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </li>
        <li style="counter-reset: count 11;">
          <span>Reason(s) for increase or decrease:</span>
          <ap-form-field [inlineBlock]="true" class="text-box" formControlName="RsnForIncOrDec" [(ngModel)]="RsnForIncOrDec"
                         [id]="'51'" [type]="'textarea'">
          </ap-form-field>
        </li>
      </ol>
      <div class="leid-list d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span><span *ngIf="transferee"> /
          </span><span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
