import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../screen-video-container/user';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { ScreenDetailsModel } from '../screen-details-model';
import { RequestAccessButtonService } from './request-access-button.service';

@Component({
  selector: 'gtw-request-access-button',
  templateUrl: './request-access-button.component.html',
  styleUrls: ['./request-access-button.component.scss']
})
export class RequestAccessButtonComponent implements OnInit {

  @Input() hasAccess: boolean = true;
	@Input() userObject: Object = {};

  @Input()
  screen: ScreenDetailsModel = {};

  @Input('base-admin-url')
  baseUrlAdmin = '/admin';

  @Input('client-key')
  clientKey: number = 0;

  @Input()
  user:User= {sso:'000000000', role:'none',system_access_level:'0'};

  sso_id?: string;


  constructor(private requestAccess: RequestAccessButtonService,
              private _gtwSnackbarService:GtwSnackbarService) { }

  ngOnInit(): void {
  }

  //Need to call api here, for requesting the access..
  public reqAccess() {
    this.requestAccess.sendRequestAccess(this.baseUrlAdmin,this.clientKey,this.screen.SCREEN_KEY!,this.screen.ACTION_KEY!)
          .subscribe((data:any) => {
            if(data && data.callSuccess!=1){
              this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
            }
          },(error)=>{
            this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
          });
  }

}
