import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(data: any[], searchInput: Object): any {
    if (!data){
        return [];
    }
    if (!searchInput){
        return data;
    }

    return data.filter(obj => {
        return Object.keys(obj).reduce((acc, curr) => {
            return acc || obj[curr].toString().toLowerCase().includes(searchInput.toString().toLowerCase());
        }, false);
    });
  }

}