import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppFooterService {
	url ={
		acceptCookie : '/cookieAcceptance'
	};

	constructor(private httpClient: HttpClient, private http:HttpClient) { }

	acceptCookieApi(baseURL:string, clientkey:string, value:string) {
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.acceptCookie}`;

		let body = new FormData();
		body.append('client_key', clientkey);
		body.append('user_response', value);
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				}
				throwError(new Error('oops!'));
				return null;
			}
		));;
	}

	acceptCookieApi1(baseURL:string, clientkey:string, value:string) {
		const options = {withCredentials: true};
		let params = new HttpParams();
		params = params.append('client_key', clientkey);
		params = params.append('user_response', value);

		return this.http.post(baseURL + this.url.acceptCookie, {params})
	}
}
