import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
//import { AppService } from '../app.service';
import { AppFooterService } from './app-footer.service';

@Component({
  selector: 'gtw-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

	public cookieAcceptanceContent = "This site uses cookies to collect information about your browsing activities in order to provide you with more relevant content and promotional materials, and help us understand your interests and enhance the site. By continuing to browse this site you agree to the use of cookies.";
	public primaryConfig = {
			isPrimary: true
		}
	currentTaxYear = new Date().getFullYear();
	showCookieBlock = true;
	baseURLs: any={};
	globalData: any = {};
	footerContent = "";

	@Input('header-data')
	set headerData(headerData : any){
		try{
			this.globalData = JSON.parse(headerData);
			//this.footerContent = "© " + this.globalData.taxYearScenarioData.selected.value + " PwC. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.";
			this.footerContent = "© " + this.currentTaxYear + " PwC. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.";
			if(this.globalData.userSettingsData.user.cookie_acceptance && this.globalData.userSettingsData.user.cookie_acceptance == 'yes'){
				this.showCookieBlock = false;
			}else{
				this.showCookieBlock = true;
			}
		}catch(e){			
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	//public footerContent = "© 2022 PwC. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.";

	// clientData = {
	// 	clients : this.appService.USER_SETTINGS.user.clients,
	// 	client : this.appService.USER_SETTINGS.user.client
	// }
	constructor(private appFooterService: AppFooterService) { }

	ngOnInit(): void {
		//this.baseURLs = environment;
	}

	iUnderstand() {
		this.appFooterService.acceptCookieApi(this.globalData.generalConfigData.base_url, this.globalData.clientData.client.client_key , 'yes').subscribe((data: any) => {
			if (data.callSuccess == 1) {
				this.showCookieBlock = false;
            }
		},
		error => {
			console.log(error);
		},() => {
			console.log('Gost Console progress bar');  
		});
  }
}
