import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FileDownloadResponse} from './models/FileDownloadResponse';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubmitConfirmationService {

  efileApiUrl!: string;

  private readonly XML_COMPARISION_REPORT = '/generateXMLComparisonReport';

  constructor(private httpClient: HttpClient,
  ) {
    this.efileApiUrl = 'http://localhost:8098';
  }

  xmlComparision(submissionId: string, clientKey: string) {
    const params = new HttpParams({
      fromObject: {
        submissionId
      }
    });

    return this.httpClient.get<FileDownloadResponse>(this.efileApiUrl + this.XML_COMPARISION_REPORT, {
      params,
      headers: {client_key: clientKey}
    }).pipe(
      tap((response) => {
        if (response.callSuccess === '1') {
          //download file from file url using achor tag
          const a = document.createElement('a');
          a.href = response.fileUrl;
          a.download = 'XML_Comparison_Report.xslx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

        }
      })
    );
  }
}
