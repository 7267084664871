import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionService } from '../../../shared/_services/action.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionResponse } from '../../../shared/_models/action-response.model';
import { DSTSharedService } from '../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {

  constructor(private httpClient: HttpClient,
    private actionService: ActionService,
    private dstSharedService: DSTSharedService) {
  }

  loadDisclosures(): Observable<DisclosureByTypesResponse[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32374, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          throw Error('Something went wron while getting the reports data, please try again!');
        })
      );
  }

  loadCustomReports(taxYears: string, formIds: string, mes: string, filingStatus: string, qarStatus: string, signatureFlag: string,
    electionFlag: string, statementId: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYears,
        'formIds': formIds,
        'maintOwner': mes,
        'filingStatus': filingStatus,
        'qarStatus': qarStatus,
        'signatureFlag': signatureFlag,
        'electionFlag': electionFlag,
        'statementId': statementId
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32375, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          throw Error('Something went wron while getting the reports data, please try again!');
        })
      );
  }

}

export interface DisclosureByTypesResponse {
  DISCLOSURETYPES: string;
}


