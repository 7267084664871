import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { merge, Observable, Subject } from 'rxjs';
import { scan, startWith } from 'rxjs/operators';
import { ScreenVideo } from '../../screen-video-container/screen-video-list/screen-video';
import { ScreenVideoDeleteComponent } from '../../screen-video-container/screen-video-list/screen-video-delete.component';
import { ScreenVideoDialogComponent } from '../../screen-video-container/screen-video-list/screen-video-dialog/screen-video-dialog.component';
import { ScreenVideoListService } from '../../screen-video-container/screen-video-list/screen-video-list.service';
import { User } from '../../screen-video-container/user';
import { UserService } from '../../screen-video-container/user.service';
import { ScreenDocumentsComponent } from '../screen-documents.component';

@Component({
  selector: 'gtw-screen-videos',
  templateUrl: './screen-videos.component.html',
  styleUrls: ['./screen-videos.component.scss']
})
export class ScreenVideosComponent implements OnInit {

  dialogRef:MatDialogRef<ScreenVideoDialogComponent, any> | undefined ;
  videoList$!:Observable<any>;  
  merged$!: Observable<any>;  
  deleteSubject = new Subject();
  isLoading=false;
  durationInSeconds = 5;
  currentUser:string = '';

  @Output() 
  updateScreensVideoEvent= new EventEmitter();

  @Input('screen-key')
  screenKey!: number;
  
  @Input('client-key')
  clientKey!: number;
  
  @Input('base-url')
  baseUrl = 'http://localhost:8087';

  @Input('from-home')
  fromHome:boolean = false;

  @Output('close-model') closeModal = new EventEmitter<any>();


 /*  @Input('user')
  set user(user:string){
    try{
      let tmp_user = JSON.parse(user ) as User;
      this.currentUser = tmp_user;
    }catch(error){
      console.log("Not an valid object");
    }
    
  } */

  @Input('refresh')
  set refresh(value:boolean){
    if(value){
      this.getAllVideos();
    }      
  }
  
  @Output()
  cancelDocModal = new EventEmitter<string>();

  constructor(private screenVideoListService: ScreenVideoListService,
              public matDialog: MatDialog,
              private userService:UserService,
              private _snackBar: MatSnackBar,
              @Optional() public dialogDocRef: MatDialogRef<ScreenDocumentsComponent>) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getUser()?.sso || '';
    if(!isNaN(this.clientKey) && !isNaN(this.screenKey)){
      this.getAllVideos();
    }

  }

  private getAllVideos(){
    this.isLoading=true;
    this.videoList$ = this.screenVideoListService.getAllVideos(this.baseUrl, this.clientKey, this.screenKey);
    this.merged$ = merge(this.videoList$, this.deleteSubject)
                    .pipe(
                        startWith({}),
                        scan((videoList,data) => {
                          if(data && Object.keys(data).length > 0){
                            this.isLoading=false;
                          }
                          if(data.operation && data.operation == "delete"){
                            var index = videoList.data.findIndex((elt:ScreenVideo) => elt.video_id === data.id);
                            videoList.data.splice(index, 1);
                            return videoList;
                          }
                          return data;
                        })
                    )
  }

  async openVideoDialog(video:ScreenVideo){

    // if(this.dialogRef){
    //   document.exitPictureInPicture().catch(error => {
    //     console.log("No Picture in Picture element.");
    //   });
    //   document.querySelector('video')?.removeEventListener("enterpictureinpicture",  this.hideDialog, true);
    //   document.querySelector('video')?.removeEventListener("leavepictureinpicture", this.showDialog, true);
    //   this.dialogRef.close();
    // }
    if(this.dialogDocRef){
      this.dialogDocRef?.close();
    }
    this.cancelDocModal.emit('cancel-document-modal');

    this.matDialog.closeAll();
    this.dialogRef?.close();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {...video,...{baseURL:this.baseUrl}};
    dialogConfig.panelClass ='remove-dialog-css';
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = '30vw';
    dialogConfig.position= { right: '0px', top: '55vh' },
    dialogConfig.id = video.video_id.toString();

    this.dialogRef = undefined;
    this.dialogRef = this.matDialog.open(ScreenVideoDialogComponent,dialogConfig);
    const event = new Event('playvideo');
    document.dispatchEvent(event);

    // document.querySelector('video#screen_video')!.addEventListener('enterpictureinpicture', this.hideDialog);
    // document.querySelector('video#screen_video')!.addEventListener('leavepictureinpicture', this.showDialog);

  }
  isVideoEditable = false;
  //updateScreensVideoInput
  updateScreensVideoInput!: ScreenVideo;

  updateScreenVideo(video:ScreenVideo): void{
    console.log("update index is ", video.video_id);
    this.isVideoEditable = true;
    this.updateScreensVideoInput = video;
    this.updateScreensVideoEvent.emit(video);
  }

  deleteScreenVideo(id:number):void{
    console.log("delete index is ", id);
    this.isLoading=true;
    this.screenVideoListService.deleteScreenVideo(this.baseUrl,this.clientKey, id).subscribe(
      (res:any) =>{
        this.isLoading =false;
        if(res.callSuccess == 1 || parseInt(res.callSuccess)==1 ){
          this.deleteSubject.next({'operation':'delete', 'id':id});
          this.openSnackBar("Video deleted Successfully !!");
        }else{
          this.openSnackBar("Video delete failed: "+ res.errorMessage);
        }
        this.cancelDocModal.emit('cancel-document-modal');
      },
      error=>{
        this.openSnackBar("Video delete Failed");
        console.log(error);
      }
    );    
  }

  isValidUser(sso:string){
    const allowedRoles = ['admin','super admin','developer'];
    if(this.userService.isValidUser(['admin']) && this.userService.getUser()?.sso == sso){
      return true;
    }
    if(this.userService.isValidUser(['super admin','developer'])){
      return true;
    }
    
    return false;    
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }

  confirmDelete(id:number):void{
   /*  const dialogRef = this.matDialog.open(ScreenVideoDeleteComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        this.deleteScreenVideo(id);
      }
    }); */
     this.deleteScreenVideo(id);
  }

  refreshScreenVideoList(event:boolean){
    this.isVideoEditable = false;

    if(!isNaN(this.clientKey) && !isNaN(this.screenKey)){
    this.getAllVideos()
      setTimeout(()=>{                           
      }, 300);
    }     
  }
  cancel(){
    this.closeModal.emit('true');
    this.cancelDocModal.emit('document-modal-cancel');
   }

}
