import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActionService} from '../../../../shared/_services/action.service';
import {Observable, zip} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {ActionResponse} from '../../../../shared/_models/action-response.model';
import {DSTSharedService} from '../../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class CreateDisclosureService {

  constructor(private httpClient: HttpClient,
              private actionService: ActionService,
              private dstSharedService: DSTSharedService) {

  }

  getTaxYears(): Observable<DSTTaxYear[]> {
    const params = new HttpParams({
      fromObject: {}
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32172, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('Something went wrong while retrieving the tax years, please try again!');
          }
        }),
      );
  }

  getTaxReturnPeriods(): Observable<DSTTaxYearPeriod[]> {
    const params = new HttpParams({
      fromObject: {}
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32173, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('Something went wrong while retrieving the tax return periods, please try again!');
          }
        }),
      );
  }

  getDisclosureTypes(): Observable<DSTDisclosureType[]> {
    const params = new HttpParams({
      fromObject: {}
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32174, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('');
          }
        }),
      );
  }

  getFilterByTypes(): Observable<DSTFilterByType[]> {
    const params = new HttpParams({
      fromObject: {}
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32438, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('');
          }
        }),
      );
  }

  getFilterValues(): Observable<any[]> {
    return zip(this.getTaxYears(), this.getTaxReturnPeriods(), this.getDisclosureTypes(), this.getFilterByTypes()).pipe(
      take(1),
      catchError((e: any, ob: Observable<any[]>) => {
        throw Error('Something went wrong while retrieving the filter values, please try again!');
      })
    );
  }

  // getFormList(taxYear: number, disclosureTypeId: number): Observable<FormsTemplateResponse[]> {
  //   const params = new HttpParams({
  //     fromObject: {
  //       'tax_year': taxYear.toString(),
  //       'p_disclosure_type': disclosureTypeId.toString(),
  //       'p_filter': ''
  //     }
  //   });

  //   return this.actionService.getActionData(this.statementTrackerService.baseURLs.api, 32171, this.statementTrackerService.clientKey, params)
  //     .pipe(
  //       map((data: ActionResponse) => {
  //         if (data.callSuccess === '1') {
  //           return data.jsonObject;
  //         } else {
  //           throw Error('');
  //         }
  //       }),
  //       catchError((e: any, ob: Observable<FormsTemplateResponse[]>) => {
  //         console.log(e.message);
  //         throw Error('Something went wrong while retrieving the forms, please try again!');
  //       })
  //     );
  // }

  getFlattenedFormList(taxYear: number, disclosureTypeId: string, filterByTypeId: string, statementType: string): Observable<any[]> {
    const params = new HttpParams({
      fromObject: {
        'tax_year': taxYear.toString(),
        'p_disclosure_type': disclosureTypeId,
        'p_filter': filterByTypeId,
        'statement_type': statementType
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32439, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('');
          }
        }),
        catchError((e: any, ob: Observable<any[]>) => {
          console.log(e.message);
          throw Error('Something went wrong while retrieving the forms, please try again!');
        })
      );
  }

  getDisclosureDownloadFile(s3FileUrl: string): Observable<any> {
    const params = new HttpParams()
      .set('file_key', s3FileUrl.split('/')[s3FileUrl.split('/').length - 1])
      .set('s3FileUrl', s3FileUrl)

    const url = `${this.dstSharedService.baseURLs.admin}/downloadDSTFiles`;

    const headers = new HttpHeaders();
    headers.append('client_key', this.dstSharedService.clientKey.toString());
    
    console.log(url);
    return this.httpClient.get(url, {
      withCredentials: true, params: params, headers: headers,responseType:'arraybuffer'
    }).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

}

export interface DSTTaxYear {
  CURRENTFLAG: string,
  FILEDFLAG: string,
  text: number,
  id: number,
  selected?: boolean
}

export interface DSTTaxYearPeriod {
  ISACTIVE: number,
  text: string,
  id: number,
  selected?: boolean
}

export interface DSTDisclosureType {
  text: string,
  id: number,
  selected?: boolean
}

export interface DSTFilterByType {
  text: string,
  id: number,
  selected?: boolean
}
