import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import {CreateDisclosureSharedService, EntityNameChange} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'dst-view-template-sec367b-id194',
  templateUrl: './view-template-sec367b-id194.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec367b-id194.component.scss']
})
export class ViewTemplateSec367bId194Component implements OnInit {
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();
  startLoader: boolean = false;
  disableActions: boolean = false;
  stmtData!: StmtData;
  stmtAttrData: any = [];
  loaded: boolean = false;
  attrData: any = [];
  form194!: FormGroup;
  currencyOptions: any = [{ data: [] }];
  propertyValBasisOptions: any = [{ data: [] }];
  attMap: any;
  prEntityNull: boolean = false;
  secEntityNull: boolean = false;
  s3Uploading: boolean = false;
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  primaryEntityName: string = '';
  secondaryEntityName: string = '';
  ownedPctField :boolean = false;
  
  constructor(private formBuilder: FormBuilder,
    private sharedService: CreateDisclosureSharedService,
    private dstSharedService: DSTSharedService,
    private taxTypePipe: TaxTypePipe) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false; // reset immediately after consumed
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form194 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });
    //boolean for 2023 new line
    let taxYear = this.sharedService.getActiveFormState().taxYear;
    if (taxYear >= 2023){
      this.ownedPctField = true;
    }else{
      this.ownedPctField = false;
    }


    //load Stmt data
    this.sharedService.getLoadStatementData(194)
      .subscribe((response: StmtData) => {
        this.stmtData = response;

        this.primaryEntityName = this.stmtData.primaryentity || '';
        this.secondaryEntityName = this.stmtData.secondaryentity || '';
        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }


        });
        console.log('After loop Attr data' + JSON.stringify(this.attMap));
        //check primary and secondary
        if (this.stmtData.primaryentityid === null || this.stmtData.primaryentityid === undefined) {
          this.prEntityNull = true;
        }
        if (this.stmtData.secondaryentityid === null || this.stmtData.secondaryentityid === undefined) {
          this.secEntityNull = true;
        }
       
        forkJoin([

          this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1), //corpTypeTxt
          this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2) //corpTypeTxt
        
        ]).subscribe(([response1, response2]) => {
          setTimeout(() => {
            const html = document.querySelector('#temp367bform194');
            const htmlContent = html!.outerHTML;
            this.sharedService.getFormStateByFormId(194)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
          });
        }, (error: HttpErrorResponse) => {        
          console.log(error);        
          this.dstSharedService.showAlert('error', error.message);        
        }).add(() => {
          this.loaded = true;
          this.startLoader = false;
        });

      }, (error: HttpErrorResponse) => {
        console.log(error);        
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
      });
      // .add(() => {
      //   this.loaded = true;
      //   this.startLoader = false;
        
      //   setTimeout(() => {    
      //     const html = document.querySelector('#temp367bform194');
      //     const htmlContent = html!.outerHTML;
          
      //     this.sharedService.getFormStateByFormId(194)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
      //   });
      // })
      

  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   this.startLoader = true;
  //   this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
  //     leid,
  //     this.sharedService.getTransDateStr(this.attMap['AdoptedDate'][0],1),
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //       if (entityType === 1) { //primary entity
  //         (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
  //         this.primaryCorpTypeTxt = corpType;
  //       } else if (entityType === 2) { //secondary entity
  //         (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
  //         this.secondaryCorpTypeTxt = corpType;
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.startLoader = false;
  //   });
  // }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    if (leid!){
      this.startLoader = true;
      return this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
        leid,
        this.sharedService.getTransDateStr(this.attMap['AdoptedDate'][0],1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).pipe(
        tap({
          next: (response => {
            if (response && response[0]) {
        //override entity type
        const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
        if (entityType === 1) { //primary entity
          (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
          this.primaryCorpTypeTxt = corpType;
        } else if (entityType === 2) { //secondary entity
          (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
          this.secondaryCorpTypeTxt = corpType;
        }
        }
          })
        })
      )
    }else{
      return EMPTY;
    }
    
  }
  
  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!,
      'Section367b-StatementPursuant332Standard.pdf', this.stmtData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading =  false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp367bform194');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  dummyFunc(event: any): void {

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }


}
