import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {F8865SchM2K1SourcingService} from '../../services/f8865-sch-m2-k1-sourcing.service';
import {F8865SchM2K1SourcingModel} from '../../models/f8865-sch-m2-k1-sourcing-model';
import {GtwElementsBaseUrlsModel} from '../../../shared/_models/gtw-elements-base-urls.model';
import {UserService} from '../../../screen-video-container/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {CurrencyPipe} from "@angular/common";
import {IssueModel} from "../../models/issue-model";
import {cloneDeep, isBoolean, toNumber} from "lodash";
import {Subscription} from "rxjs";
import _ from 'lodash';



@Component({
  selector: 'gtw-f8865-sch-m2-k1-sourcing',
  templateUrl: './f8865-sch-m2-k1-sourcing.component.html',
  styleUrls: ['./f8865-sch-m2-k1-sourcing.component.scss'],
  providers: [ CurrencyPipe ],
  encapsulation: ViewEncapsulation.None
})
export class F8865SchM2K1SourcingComponent implements OnInit, OnDestroy {

  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private hoCombinationKey: number = 0;
  private acctType: string = '';
  private scenarioCode : string = '';
  private isIssueKeyEnabled: string = '';

  private totalTOTAL :number = 0;
  private totalBRANCH :number = 0;
  private totalGENERAL :number = 0;
  private totalFSI :number = 0;
  private totalPASSIVE :number = 0;
  private totalUS :number = 0;
  private checkLockedObj: any = [];

  public toolTipMsgAdd = "Add New Sourcing";
  public toolTipMsgRemove = "Remove Existing Sourcing";

  clob_settings:any = {};
  clob_data:any = [];

  public validationErrMsg:string = '';

  private route: string = '';
  isLoading = false;

  @Input('base-url')
  set _baseURL(baseURL:string){
    if(!_.startsWith(baseURL, "{{")) {
      this.baseURL = baseURL;
    }
    
     //this.init();
  }

  @Input('base-url-gtw-api')
  set _baseURLGtw(baseURL:string){
    // this.baseURLGtw = baseURL;
    if(!_.startsWith(baseURL, "{{")) {
      this.baseURLGtw = baseURL;
    }
    //this.init();
  }

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    // this.checkLockedObj = checkLockedObj;
    if(!_.startsWith(checkLockedObj, "{{")) {
      this.checkLockedObj = checkLockedObj;
    }
  }

  @Input('tax-year')
  set _taxYear(taxYear:any){
    if(!_.startsWith(taxYear, "{{")) {
      this.taxYear = taxYear;
    }
    this.init();
  }

  @Input('scenario')
  set _scenario(scenario:any){
    if(!_.startsWith(scenario, "{{")) {
      this.scenario = scenario;
    }
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:any){
    if(!_.startsWith(jcdKey, "{{")) {
      this.jcdKey = jcdKey;
    }
    this.init();
  }

  @Input('screen-key')
  set _screenKey(screenKey:any) {
    if(!_.startsWith(screenKey, "{{")) {
      this.screenKey = screenKey;
    }
    this.init();
  }

  @Input("ho-combination-key")
  set _hoCombinationKey(hoCombinationKey:any) {
    if(!_.startsWith(hoCombinationKey, "{{")) {
      this.hoCombinationKey = hoCombinationKey;
    }
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    if(!_.startsWith(ssoId, "{{")) {
      this.ssoId = ssoId;
    }
    this.init();
  }

  @Input("acct-type")
  set _acctType(acctType:string) {
    if(!_.startsWith(acctType, "{{")) {
      this.acctType = acctType;
    }
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode:string){
    if(!_.startsWith(scenarioCode, "{{")) {
      this.scenarioCode = scenarioCode;
    }
    this.init();
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled:string){
    if(!_.startsWith(isIssueKeyEnabled, "{{")) {
      this.isIssueKeyEnabled = isIssueKeyEnabled;
    }
    this.init();
  }


  @Output('open-sourcing-basket-list')
  openSourcingBasketList = new EventEmitter<string>();

  @Output('save-partnership-sourcing')
  savePartnershipSourcing = new EventEmitter<string>();


  sourcingList : any = [];

  issueList : Array<IssueModel> = [];

  firstList:any = [];

  row:any = [];

  durationInSeconds = 5;

  myScenarioCode: string = '';

  rowsEdited:any = [];
  rowsAdded:any = [];
  rowsDeleted:any = [];

  subscriptions:Subscription = new Subscription() ;


  constructor(private userService:UserService, private f8865SchM2K1SourcingService: F8865SchM2K1SourcingService, private _snackBar: MatSnackBar, location: Location, router: Router, private currencyPipe: CurrencyPipe) {

    console.log('... constructor called ...');
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.route = location.path();
      } else {
        this.route = 'Home'
      }
      console.log(' this.route = ', this.route);
    });

  }

  public primaryConfig = {
    isPrimary: true
  };

  ngOnInit(): void {
    console.log(' ... ngOnInit() invoked ... ')
    this.init();
  }

  init() {
    if(this.checkInputType()){
    this.getIssueList();
    this.getSourcingDetails();
    }
  }

  addDollarSign(text: string) {
    return '$ ' + text
  }

  // Only Integer Numbers
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 and minus
    if(charCode == 45 || charCode == 48 || charCode == 49 || charCode == 50 || charCode == 51 || charCode == 52 || charCode == 53 || charCode == 54 || charCode == 55 || charCode == 56 || charCode == 57) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }

  }

  checkInputType():boolean{
    if(Number.isInteger(parseInt(this.taxYear+"")) &&
      Number.isInteger(parseInt(this.scenario+'')) &&
      Number.isInteger(parseInt(this.jcdKey +'')) &&
      Number.isInteger(parseInt(this.screenKey+'')) &&
      Number.isInteger(parseInt(this.hoCombinationKey+'')) &&
        (this.acctType === "ASSETS" || this.acctType === "INCOME" || this.acctType === "DEDUCTIONS" || this.acctType === "PRIOR_ASSETS") &&
      (this.scenarioCode === "RAF" || this.scenarioCode === "QAR" || this.scenarioCode === "RAF1") &&
      (this.isIssueKeyEnabled == "Y" || this.isIssueKeyEnabled == "N")
    )
    {
      return true;
    }

    return false;
  }

  getIssueList() {

    this.f8865SchM2K1SourcingService.getIssueList(this.baseURLGtw, this.taxYear, this.scenario, this.jcdKey).subscribe((data: Array<IssueModel>) => {
        if (data) {
          this.issueList = data;
          console.log(data, 'issue data');

        }
      },
      error => {
        this.isLoading = false;
        console.log(error);
      }, () => {
        this.isLoading = false;
      });
  }


  getSourcingDetails() {
    console.log('Get Console Data Called.');
    if(this.sourcingList.length == 0 && !this.isLoading) {
      this.isLoading = true;

      this.f8865SchM2K1SourcingService.getF8865M2K1SourcingList(this.baseURLGtw, this.taxYear, this.scenario, this.jcdKey, this.screenKey, this.hoCombinationKey, this.acctType, this.ssoId).subscribe((data: F8865SchM2K1SourcingModel) => {
          if (data) {
            this.isLoading = false;

            this.sourcingList = data;
            this.firstList.push(this.sourcingList[0]);
            this.myScenarioCode = this.sourcingList[0].SCENARIO_CODE;
            console.log(data, 'data');

            //this.hoCombinationKey = this.firstList[0].COMBINATION_KEY;
            //this.acctType = this.firstList[0].ACCT_TYPE;
          }
        },
        error => {
          this.isLoading = false;
          console.log(error);
        }, () => {
          this.isLoading = false;
          console.log('Completed all tabs');
        });

    }

  }

   isValidNumber(input: string) {
    input = input+'';
    if( (input.match(new RegExp("-", "g")) || []).length === 0 || ( (input.match(new RegExp("-", "g")) || []).length === 1 && input.indexOf('-') == 0 ) ) {
      return true;
    }
    else return false;
  }

  makeDirty(row: number) {
    this.sourcingList[row].IS_DIRTY = 'Y';
  }


  refreshSourcingTotal(basket:string, row : number) {

    for(let i = 0; i < this.sourcingList.length; i++) {

      if(i === row) {

        if(basket === "GENERAL") {
          if(this.sourcingList[i].GENERAL === '' || this.sourcingList[i].GENERAL === '-' || !this.isValidNumber(this.sourcingList[i].GENERAL)) {
            this.sourcingList[i].GENERAL = "0";
          }
        }
        else if(basket == "FSI") {
          if(this.sourcingList[i].FSI === '' || this.sourcingList[i].FSI === '-' || !this.isValidNumber(this.sourcingList[i].FSI))
            this.sourcingList[i].FSI = "0";
        }
        else if(basket == "PASSIVE") {
          if(this.sourcingList[i].PASSIVE === '' || this.sourcingList[i].PASSIVE === '-' || !this.isValidNumber(this.sourcingList[i].PASSIVE))
            this.sourcingList[i].PASSIVE = "0";
        }
        else if(basket == "US") {
          if(this.sourcingList[i].US === '' || this.sourcingList[i].US === '-' || !this.isValidNumber(this.sourcingList[i].US))
            this.sourcingList[i].US = "0";
        }
        this.sourcingList[i].BRANCH = this.sourcingList[i].TOTAL - ( Number(this.sourcingList[i].GENERAL) +  Number(this.sourcingList[i].FSI) + Number(this.sourcingList[i].PASSIVE) + Number(this.sourcingList[i].US) );
        break;
      }
    }
    this.calcTotal(basket);
    this.calcTotal('BRANCH');
  }


  calcTotal(basket:string){

    if(basket === "TOTAL")
      this.totalTOTAL = 0;
    if(basket === "BRANCH")
      this.totalBRANCH = 0;
    if(basket === "GENERAL")
      this.totalGENERAL = 0;
    if(basket === "FSI")
      this.totalFSI = 0;
    if(basket === "PASSIVE")
      this.totalPASSIVE = 0;
    if(basket === "US")
      this.totalUS = 0;

    for (let i = 0; i < this.sourcingList.length; i++) {
      this.row = this.sourcingList[i];
      if(basket === "TOTAL") {
        this.totalTOTAL = Number(this.totalTOTAL) + Number(this.row.TOTAL);
      }
      if(basket === "BRANCH") {
        this.totalBRANCH = Number(this.totalBRANCH) + Number(this.row.BRANCH);
      }
      if(basket === "GENERAL") {
        this.totalGENERAL = Number(this.totalGENERAL) + Number(this.row.GENERAL);
      }
      if(basket === "FSI") {
        this.totalFSI = Number(this.totalFSI) + Number(this.row.FSI);
      }
      if(basket === "PASSIVE") {
        this.totalPASSIVE = Number(this.totalPASSIVE) + Number(this.row.PASSIVE);
      }
      if(basket === "US") {
        this.totalUS = Number(this.totalUS) + Number(this.row.US);
      }

    }
    if(basket === "TOTAL") {
      return this.totalTOTAL;
    }
    if(basket === "BRANCH") {
      return this.totalBRANCH;
    }
    if(basket === "GENERAL") {
      return this.totalGENERAL;
    }
    if(basket === "FSI") {
      return this.totalFSI;
    }
    if(basket === "PASSIVE") {
      return this.totalPASSIVE;
    }
    if(basket === "US") {
      return this.totalUS;
    }
    else {
      return 0;
    }
  }

  addSourcingRecord() {
    let sourcingRecord:any = {};
    sourcingRecord = cloneDeep(this.sourcingList[0]);

    sourcingRecord['OP_MODE'] = 'add';
    sourcingRecord['IS_DIRTY'] = 'Y';
    sourcingRecord['ISSUE_KEY'] = -1;
    sourcingRecord['ISSUE_SHORT_DESC'] = '';
    sourcingRecord['ISSUE_ID_SHORT_DESC']= '';
    sourcingRecord['TOTAL'] = 0;
    sourcingRecord['BRANCH'] = 0;
    sourcingRecord['GENERAL'] = 0;
    sourcingRecord['FSI'] = 0;
    sourcingRecord['PASSIVE'] = 0;
    sourcingRecord['US'] = 0;


    console.log('sourcingRecord = ', sourcingRecord);
    this.sourcingList.push(sourcingRecord);
  }

  removeSourcingRecord(row:number) {
    let sourcingRecord:any = {};
    sourcingRecord = cloneDeep(this.sourcingList[row]);
    if(sourcingRecord.OP_MODE === 'edit') {
      sourcingRecord.OP_MODE = 'delete';
      sourcingRecord.IS_DIRTY = 'Y';
      this.rowsDeleted.push(sourcingRecord);
    }
    this.sourcingList.splice(row,1);

  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }

  onIssueChange(event: any, row:number) {
    console.log('Issue changed...');
    let selectedIssue = event.value;
    console.log(selectedIssue);
    this.sourcingList[row].ISSUE_KEY = selectedIssue;
  }

  saveF8865M2K1SourcingData() {
    console.log('Save button clicked.');

    this.clob_settings = {};
    this.clob_data = [];
    this.rowsAdded = [];
    this.rowsEdited = [];

    this.clob_settings['tax_year'] = this.taxYear;
    this.clob_settings['scenario'] = this.scenario;
    this.clob_settings['jcd_key'] = this.jcdKey;
    this.clob_settings['screen_key'] = this.screenKey;
    this.clob_settings['sso_id'] = this.ssoId;

    this.clob_settings['ho_combination_key'] =  this.hoCombinationKey;
    this.clob_settings['acct_type'] = this.acctType;

    for(let i = 0; i < this.sourcingList.length; i++) {
      let sourcingRecord: any = {};
      sourcingRecord = this.sourcingList[i];
      console.log(' sourcingRecord = ', sourcingRecord);
      if (sourcingRecord.OP_MODE === 'edit' && sourcingRecord.IS_DIRTY === 'Y') {
        this.rowsEdited.push(sourcingRecord);
        console.log('this.rowsEdited = ', this.rowsEdited);
      }
      else if(sourcingRecord.OP_MODE === 'add' && sourcingRecord.IS_DIRTY === 'Y') {
        this.rowsAdded.push(sourcingRecord);
        console.log('this.rowsAdded = ', this.rowsAdded);
      }
    }

    if(this.rowsAdded.length > 0) {
      for(let i = 0; i < this.rowsAdded.length; i++) {
        this.clob_data.push(this.rowsAdded[i]);
      }
    }

    if(this.rowsEdited.length > 0) {
      for(let i = 0; i < this.rowsEdited.length; i++) {
        this.clob_data.push(this.rowsEdited[i]);
      }
    }

    if(this.rowsDeleted.length > 0) {
      for(let i = 0; i < this.rowsDeleted.length; i++) {
        this.clob_data.push(this.rowsDeleted[i]);
      }
    }


    console.log('clob_settings = ' , this.clob_settings);
    console.log('clob_data = ' , this.clob_data);

    if (this.validate() == true) {

      let updateSubscription = this.f8865SchM2K1SourcingService.saveF8865M2K1SourcingData(this.baseURLGtw,this.taxYear,this.scenario, this.jcdKey, this.screenKey, this.ssoId, this.scenarioCode, this.isIssueKeyEnabled, this.checkLockedObj, this.clob_settings, this.clob_data).subscribe(
        (response)=>{
          this.isLoading =false;
          if(response.callSuccess == "1") {
            this.f8865SchM2K1SourcingService.openSnackBar('Sourcing Data Saved','gtw-snackbar--green');
            this.savePartnershipSourcing.emit('save-partnership-sourcing');
          } else {
            this.f8865SchM2K1SourcingService.openSnackBar('Error Saving Sourcing Data. '+response.errorMessage, 'gtw-snackbar--red');
          }
        },
        error => { this.isLoading =false;this.f8865SchM2K1SourcingService.openSnackBar('Entity Chart update failed. '+error.error.errorMessage, 'gtw-snackbar--red'); },
        () => { console.log("Update Completed");this.isLoading =false; }
      );
      this.subscriptions.add(updateSubscription);

    }
    else {
      this.f8865SchM2K1SourcingService.openSnackBar('Validation Error: . '+ this.validationErrMsg, 'gtw-snackbar--yellow');
    }

  }

  validate() {

    let isValid: boolean = true;
    let record: any = {};
    let issueIdList: any = [];

    this.validationErrMsg = '';

    for(let i = 0; i < this.clob_data.length; i++) {
      record = this.clob_data[i];
      console.log('record = ', record);
      if (record.ISSUE_KEY === -1) {
        isValid = false;
        this.validationErrMsg = 'Please select issue from the dropdown for record: ' + toNumber(i+1) + '' ;
        break;
      }
      else if(record.GENERAL+'' === '0' && record.FSI+'' === '0' && record.PASSIVE+'' === '0' && record.US+'' === '0') {
        isValid = false;
        this.validationErrMsg = 'At least one of GENERAL, FSI, PASSIVE or US basket amount should be non-zero for record '+ toNumber(i+1) + '';
        break;
      }
    }
    if ( this.validationErrMsg === '') {
      for(let i = 0; i < this.sourcingList.length; i++) {
        record = this.sourcingList[i];
        if(record.ISSUE_KEY !== 0)
          issueIdList.push(record.ISSUE_KEY);
      }

      if(this.checkForDuplicates(issueIdList) === true) {
        isValid = false;
        this.validationErrMsg = 'Duplicate Issue(s) selected for saving, Please check.';

      }
    }

    return isValid;
  }

  checkForDuplicates(array:any) {
    return new Set(array).size !== array.length
  }

  modalClose() {
    console.log("click close");
    this.openSourcingBasketList.emit('open-sourcing-basket-list');
  }


  ngOnDestroy(): void {

    console.log('Destroyed');
  }

}
