import {Component, OnInit} from '@angular/core';
import {CreateDisclosureService, DSTDisclosureType, DSTFilterByType, DSTTaxYear, DSTTaxYearPeriod} from './create-disclosure.service';
import {StatementTrackerService} from '../../statement-tracker.service';
import {CreateDisclosureSharedService} from '../shared/create-disclosure-shared.service';
import {TrackerTabsService, TrackerTabState} from '../../tracker-tabs.service';
import _ from 'lodash';
import {DSTSharedService} from '../../../shared/dst-shared.service';

@Component({
  selector: 'dst-create-disclosure',
  templateUrl: './create-disclosure.component.html',
  styleUrls: ['./create-disclosure.component.scss']
})
export class CreateDisclosureComponent implements OnInit {

  selectedIndex = 0;
  selectedFilterId=0;
  btnGroupConfig = [
    {
      buttonText: 'Create'
    },
    {
      buttonText: 'Bulk Upload'
    },
    {
      buttonText: 'Bulk Supersede'
    }
  ];

  taxYears: any[] = [
    {
      data: []
    }
  ];
  selectedTaxYear!: DSTTaxYear;

  taxYearPeriods: any[] = [
    {
      data: []
    }
  ];
  selectedPeriod!: DSTTaxYearPeriod;

  disclosureTypes: any[] = [
    {
      data: []
    }
  ];
  selectedDisclosureType!: DSTDisclosureType;

  filterByTypes: any[] = [
    {
      data: []
    }
  ];
  selectedfilterByType!: DSTFilterByType;
  selectorDefaultText: string = 'Loading...';

  searchTerm: string;
  loadingData: boolean = true;
  showCreateContainer!: boolean;
  hideLeftPane!: boolean;
  forms!: DSTCreateTemplate[];
  formsFiltered!: any[];

  constructor(
    private dstSharedSerivce: DSTSharedService,
    private createDisclosureService: CreateDisclosureService,
    private statementTrackerService: StatementTrackerService,
    private trackerTabsService: TrackerTabsService,
    private sharedService: CreateDisclosureSharedService) {
    this.searchTerm = '';
  }

  ngOnInit(): void {
    this.createDisclosureService.getFilterValues()
      .subscribe((response: any[]) => {
        this.taxYears[0].data = response[0];
        this.taxYearPeriods[0].data = response[1];
        this.disclosureTypes[0].data = response[2];
        this.filterByTypes[0].data = response[3];
        this.selectDefaults();
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedSerivce.showAlert('error', eMsg);
      })
      .add(() => {
        this.selectorDefaultText = 'Select here';
      });
  }

  loadNewTab(form: DSTCreateTemplate, isPrimary: boolean): void {
    this.showCreateContainer = false;

    // let angular rerender the shared component and template viewport
    setTimeout(() => {
      const createNewTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
      if (createNewTab?.id === StatementTrackerService.ID_CREATE_NEW) {
        let uploadType: string | undefined = undefined;
        if (this.selectedIndex === 1) {
          uploadType = 'Bulk Upload';
        } else if (this.selectedIndex === 2) {
          uploadType = 'Bulk Supersede';
        }

        createNewTab.addFormState(this.selectedTaxYear.text, this.selectedTaxYear.id, this.selectedPeriod.id, '0',
        (isPrimary) ? form.FORMID: form.SECONDARY_FORM_ID, form.FORMNAME, (isPrimary) ? form.PRIMARYFORMTYPEDESC2 : form.SECONDARYFORMTYPEDESC,
          (isPrimary) ? form.PRIMARY_FORM_TYPE_ID : form.SECONDARY_FORM_TYPE_ID, form.CSEID || 0, false, false, true, uploadType);
        createNewTab.isPlaceholderChecked = false;
        createNewTab.activeTabIndex = 0;
        this.sharedService.setLeId('');
        // this.sharedService.setFormData({});
        this.hideLeftPane = true;
        this.showCreateContainer = true;
      }
    });
  }

  downloadFile(form: DSTCreateTemplate): void {
    console.log(form);
    var s3FileUrl = form.PRIMARYFORMTYPEDESC;
    var formName = form.FORMNAME;
    this.createDisclosureService.getDisclosureDownloadFile(s3FileUrl).subscribe((response: any) => {
      let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (formName)
                downloadLink.setAttribute('download', formName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
    })

  }

  btnChange(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
    this.getFormList();
  }

  selectDefaults(): void {
    this.taxYears[0].data.forEach((element: any, index: number) => {
      if (element.text === parseInt(this.dstSharedSerivce.gtwTaxYear)) {
        this.taxYears[0].data[index].selected = true;
        this.taxYears = JSON.parse(JSON.stringify(this.taxYears));
        this.selectedTaxYear = this.taxYears[0].data[index];
      }
    });
    this.taxYearPeriods[0].data.forEach((element: any, index: number) => {
      this.taxYearPeriods[0].data[0].selected = true;
      this.taxYearPeriods = JSON.parse(JSON.stringify(this.taxYearPeriods));
      this.selectedPeriod = this.taxYearPeriods[0].data[0];
    });
    this.disclosureTypes[0].data.forEach((element: any, index: number) => {
      this.disclosureTypes[0].data[0].selected = true;
      this.disclosureTypes = JSON.parse(JSON.stringify(this.disclosureTypes));
      this.selectedDisclosureType = this.disclosureTypes[0].data[0];
    });

    //Set Template Form as default
    this.filterByTypes[0].data.find((item: { id: number; }) => item.id === 1).selected = true;
    this.selectedfilterByType = this.filterByTypes[0].data.find((item: { id: number; }) => item.id === 1);
    this.filterByTypes = JSON.parse(JSON.stringify(this.filterByTypes));

    this.getFormList();
  }

  onTaxYearChanged(event: any): void {
    this.selectedTaxYear = event.selected[0];
    this.getFormList();
  }

  onPeriodChanged(event: any): void {
    this.selectedPeriod = event.selected[0];
    this.getFormList();
  }

  onDisclosureTypeChanged(event: any): void {
    this.selectedDisclosureType = event.selected[0];
    this.getFormList();
  }

  onFilterByTypeChanged(event: any): void {
    this.selectedfilterByType = event.selected[0];
    this.selectedFilterId = this.selectedfilterByType.id;
    this.getFormList();
  }

  getFormList(): void {
    if (!this.selectedTaxYear || !this.selectedPeriod || !this.selectedDisclosureType || !this.selectedfilterByType) {
      return;
    }
    this.loadingData = true;
    this.forms = [];
    this.formsFiltered = [];
    this.showCreateContainer = false;
    this.createDisclosureService.getFlattenedFormList(this.selectedTaxYear.text,
      (this.selectedIndex === 0 ? this.selectedDisclosureType.id.toString() : ''),
      (this.selectedIndex === 0 ? this.selectedfilterByType.id.toString() : ''),
      (this.selectedIndex === 1 ? '2' : ''))
      .subscribe((response: DSTCreateTemplate[]) => {
        response.forEach((data: DSTCreateTemplate) => {
          if (data.IS304) {
            data.FORMID = -192;
          }
        });
        this.forms = _.sortBy(response, function(item) {
          return item.FORMORDER;
        });
        this.formsFiltered = [...this.forms];
      }, (errorMsg: string) => {
        this.dstSharedSerivce.showAlert('error', errorMsg);
      }).add(() => {
      this.loadingData = false;
    });
  }

}

// export interface FormsTemplateResponse {
//   FORMSLIST: string;
// }

export interface DSTCreateTemplate {
  FORMID: number,
  FORMNAME: string,
  STATEMENT_TYPE_ID: number,
  PRIMARYFORMTYPEDESC: string,
  PRIMARYFORMTYPEDESC2: string,
  FORMORDER: number,
  PRIMARY_FORM_ACTIVE: number,
  SECONDARY_FORM_ID: number,
  SECONDARYFORMTYPEDESC: string,
  SIGNATURE_FLAG: string,
  ELECTION_FLAG: string,
  DATACOLLECT_ACTION_FLAG: string,
  PRIMARY_FORM_TYPE_ID: number,
  SECONDARY_FORM_TYPE_ID: number,
  IS304: number,
  CSEID: number
}
