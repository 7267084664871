<div class="main-container">
    <div class="grid-container" id="351report-grid-container" style="height: calc(100vh - 124px);">
      <div class="top-toolbar">
        <div class="float-left ml-2">
            <span>Tax Year</span>
          <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
            (onChange)="onTaxYearChange()">
          </p-dropdown>
          <span>Previous Tax Year</span>
          <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="previousTaxYears" optionLabel="text" [(ngModel)]="previousTaxYear"
            (onChange)="onPreviousTaxYearChange()">
          </p-dropdown>
          <span>Business Name</span>
          <!-- <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="businessesNameObj" optionLabel="name" [(ngModel)]="selectedBusinessObj" placeholder="All Business Name" >
          </p-dropdown>  -->
          <span><i class="fa fa-spin fa-spinner" *ngIf="loading"></i></span>
          <p-multiSelect styleClass="text-black" placeholder="All Business Name" [options]="businessesNameObj" [maxSelectedLabels]="2" [appendTo]="'body'" [(ngModel)]="selectedBusinessObj" optionLabel="name"></p-multiSelect>  
          <!-- <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button> -->
          <span><i class="fa fa-spin fa-spinner" *ngIf="isLoadingExcel"></i></span>
          <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
        </div>
      </div>
  
      
    </div>
  </div>
  <dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
  </dst-disc-modal-viewer>