<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>

<div class="viewport-wrapper">
  <span *ngIf="noDataAvail" class="no-pdf-data">
    No Data Available!
  </span>
  <span *ngIf="formNotAvail" class="no-pdf-data">
    {{ formNotAvailText }}
  </span>
  <iframe [src]="fileContent" *ngIf="pdfLoaded && !noDataAvail && fileContent" 
    style="width: 100%;" [ngStyle]="{ 'height': divHeight }" allowfullscreen>
  </iframe>
</div>