import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChildren, QueryList
} from '@angular/core';
import {SplAllocService} from '../../services/spl-alloc.service';
import {SplAlloc} from '../../models/spl-alloc.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalComponent} from "appkit-angular/modal";
import {cloneDeep} from "lodash";
import { RadiobuttonModule } from 'appkit-angular/radiobutton';
import {FormFieldComponent} from "appkit-angular/form-field";
import {Subscription} from "rxjs";



@Component({
  selector: 'gtw-pship-spl-alloc',
  templateUrl: './spl-alloc.component.html',
  styleUrls: ['./spl-alloc.component.scss']
})
export class SplAllocComponent implements OnInit, OnDestroy {

  private baseURL = '/gtw';
  private checkLockedObj: any = [];
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private ssoId: string = '';
  private combinationKey: number = 0;
  private processName: string = '';
  private scenarioCode : string = '';
  private isIssueKeyEnabled: string = '';
  private isLocked: string = '';
  private issueKey : number = 0;

  clob_settings:any = {};
  clob_data:any = [];
  rowsEdited:any = [];

  public validationErrMsg:string = '';

  subscriptions:Subscription = new Subscription() ;

  @ViewChildren('ratioOverrides') ratioOverrides!: QueryList<FormFieldComponent>;
  @ViewChildren('amountOverrides') amountOverrides!: QueryList<FormFieldComponent>;


  isLoading = false;
  isSaving = false;

  splAllocPopupData : any = [];
  ptrRatioData: any = [];
  firstRow : any = [];
  headers : any = [];
  ptrHeaders : any = [];
  partnerRatioHeaders : any = [];

  ptrRatioJson : any = {};
  ptrRatioArray : any = [];

  @Input('base-url')
  set _baseURL(baseURL:string){
    this.baseURL = baseURL;
  }

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }

  @Input('is-locked')
  set _isLocked(isLocked:string) {
    this.isLocked = isLocked;
    if(this.isLocked === 'Y')
      this.isSaving = true;
    else
      this.isSaving = false;
  }

  @Input('tax-year')
  set _taxYear(taxYear:number){
    this.taxYear = taxYear;
    this.init();
  }

  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input("combination-key")
  set _combinationKey(combinationKey:number) {
    this.combinationKey = combinationKey;
    this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    this.ssoId = ssoId;
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode:string){
    this.scenarioCode = scenarioCode;
    this.init();
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled:string){
    this.isIssueKeyEnabled = isIssueKeyEnabled;
    this.init();
  }

  @Input("issue-key")
  set _issueKey(issueKey:number) {
    this.issueKey = issueKey;
    this.init();
  }

  @Output('cancel-pship-spl-alloc')
  cancelPshipSplAlloc = new EventEmitter<string>();

  @Output('save-pship-spl-alloc')
  savePshipSplAlloc = new EventEmitter<string>();



  constructor(private splAllocService: SplAllocService, private _snackBar: MatSnackBar) {
    this.processName = 'Save Partnership Special Allocation';
  }

  checkInputType():boolean{
    if(Number.isInteger(parseInt(this.taxYear+"")) &&
      Number.isInteger(parseInt(this.scenario+'')) &&
      Number.isInteger(parseInt(this.jcdKey +'')) &&
      Number.isInteger(parseInt(this.combinationKey+'')) &&
      Number.isInteger(parseInt(this.issueKey+'')) &&
      (!this.scenarioCode.includes("{{")) &&   // this.scenarioCode === "RAF" || this.scenarioCode === "EXAM"
      (!this.isIssueKeyEnabled.includes("{{")) // this.isIssueKeyEnabled == "Y" || this.isIssueKeyEnabled == "N"
    )
    {
      return true;
    }

    return false;
  }

  init() {
    if(this.checkInputType()){
      this.getSplAllocPopupData();
    }
  }

  public primaryConfig = {
    isPrimary: true
  };

  ngOnInit(): void {
    console.log(' ... ngOnInit() invoked ... ')
    //this.init();
  }

  getSplAllocPopupData() {
    console.log('Get Spl Alloc Popup Data Called.');
    if(this.splAllocPopupData.length == 0 && !this.isLoading) {

      this.isLoading = true;

      this.splAllocService.getSplAllocPopupData(this.baseURL, this.taxYear, this.scenario, this.jcdKey, this.combinationKey, this.ssoId).subscribe((data: any[]) => {
        let index;
        let headerObj = {key:'', value:''};
        let headerObjCloned = {key:'', value:''};

        let ptrHeaderObj = {key:'', value:''};
        let ptrHeaderObjCloned = {key:'', value:''};

        if (data) {
            this.isLoading = false;
            this.splAllocPopupData = data;
            console.log('splAllocPopupData = ', this.splAllocPopupData);

            this.firstRow.push(this.splAllocPopupData[0]);
            console.log('firstRow = ', this.firstRow);

            for(let key in this.firstRow[0]) {
              index = Object.keys(this.firstRow[0]).indexOf(key);
              console.log('index = ', index);
              if(index > 16) {
                if(key !== 'COMBINATION_KEY' && key !== 'ACCT_KEY' && key !== 'DISPLAY_ORDER' && key !== 'TRADING_PTR') {
                  headerObjCloned = cloneDeep(headerObj);
                  headerObjCloned.key = key;
                  if(key === 'ACCT')
                    headerObjCloned.value = 'Account No.';
                  else if(key === 'ACCT_DESC')
                    headerObjCloned.value = 'Account Description';
                  else if(key === 'TRADING_PARTNER')
                    headerObjCloned.value = 'Trading Partner';
                  else if(key === 'ISSUE_KEY')
                    headerObjCloned.value = 'Issue ID';
                  else if(key === 'PSHIP_AMT')
                    headerObjCloned.value = 'Partnership Amount';
                  else if(key === 'PSHIP_AMT_USD')
                    headerObjCloned.value = 'Partnership Amount (USD)';
                  else if(key === 'VARIANCE')
                    headerObjCloned.value = 'Variance';
                  else if(key === 'OVERRIDE_TYPE')
                    headerObjCloned.value = 'Override Type';
                  else if(key === 'TOTAL_ALLOCATION')
                    headerObjCloned.value = 'Total Allocation';
                  else if(key.includes('/')){
                    ptrHeaderObjCloned = cloneDeep(ptrHeaderObj);
                    ptrHeaderObjCloned.key = key;
                    ptrHeaderObjCloned.value = key;
                  }

                  if(key.includes('/'))
                    this.ptrHeaders.push(ptrHeaderObjCloned);
                   else
                    this.headers.push(headerObjCloned);
                }
              }
            }
            console.log('headers = ', this.headers);
            console.log('ptrHeaders = ', this.ptrHeaders);

            this.getPtrRatio();

           }
        },
        error => {
          this.isLoading = false;
          console.log(error);
        }, () => {
          this.isLoading = false;
          console.log('Completed all tabs');
        }

      );
    }

  }

  getPtrRatio() {

    console.log('Get getPtrRatio () Called.');

    if(this.ptrRatioData.length == 0) {
        this.splAllocService.getPtrRatio(this.baseURL, this.taxYear, this.scenario, this.jcdKey, this.combinationKey, this.ssoId).subscribe ((data: any[]) => {
          if (data) {
            this.ptrRatioData = data;

            console.log('ptrRatioData = ', this.ptrRatioData);

            this.ptrRatioJson = this.ptrRatioData[0];
            console.log('ptrRatioJson = ', this.ptrRatioJson);

            for(let key in this.ptrRatioData[0]) {
              if(key.includes('/')) {
                this.partnerRatioHeaders.push(key);
                this.ptrRatioArray.push(this.ptrRatioJson[key]);
              }
            }
            console.log('ptrRatioArray = ', this.ptrRatioArray);
            console.log('partnerRatioHeaders = ', this.partnerRatioHeaders);
          }

        },
        error => {
          this.isLoading = false;
          console.log(error);
        }, () => {
          this.isLoading = false;
          console.log('Completed all tabs');
        }

      );
    }

  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 and minus
    if(charCode == 45 || charCode == 45 || charCode == 48 || charCode == 49 || charCode == 50 || charCode == 51 || charCode == 52 || charCode == 53 || charCode == 54 || charCode == 55 || charCode == 56 || charCode == 57) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }

  }

  keyPressDecimal(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 , minus and dot
    if(charCode == 45 || charCode == 46 || charCode == 45 || charCode == 48 || charCode == 49 || charCode == 50 || charCode == 51 || charCode == 52 || charCode == 53 || charCode == 54 || charCode == 55 || charCode == 56 || charCode == 57) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }

  }

  setOverrideOption(overrideOption: any, index: any) { //

    console.log(overrideOption, index);
    console.log(this.ratioOverrides);
    console.log(this.amountOverrides);

    if(overrideOption === 'ratio') {

      this.ratioOverrides.forEach(component => {
        console.log(component.id);
        if (component.id.startsWith('ratio-override-' + index)) {
          component.isDisabled = false;
        }
      });

      this.amountOverrides.forEach(component => {
        console.log(component.id);
        if (component.id.startsWith('amount-override-' + index)) {
          component.isDisabled = true;
        }
      });

    }

    if(overrideOption === 'amount') {

      this.ratioOverrides.forEach(component => {
        console.log(component.id);
        if (component.id.startsWith('ratio-override-' + index)) {
          component.isDisabled = true;
        }
      });

      this.amountOverrides.forEach(component => {
        console.log(component.id);
        if (component.id.startsWith('amount-override-' + index)) {
          component.isDisabled = false;
        }
      });

    }

  }

  calcVariance(event: any, rowIndex: number, keyCol: any, overrideType: any) {

    let defaultAlloc;
    let splAllocAmt;
    let splAllocAmtOrg;
    let splAllocRatio;
    let splAllocRatioOrg;
    let totalAllocCalc = 0;
    let currRow = this.splAllocPopupData[rowIndex];
    let totalAllocation = currRow.TOTAL_ALLOCATION;
    let pshipAmt = currRow.PSHIP_AMT;
    let updatedPartnerAmts;
    let calcAmt;

    if (event.value.length === 1) {
      if(event.value == '-') return;
    }
    else if(event.value.length > 1) {
      if((event.value.match(/-/g) || []).length > 1) return;
    }

    currRow.IS_DIRTY = 'Y';

    for(let key in currRow) {

      if(key.includes('/') ) {
        console.log('--- key = ', key);

        defaultAlloc = currRow[key].split(',')[0];
        console.log('defaultAlloc = ', defaultAlloc);

        splAllocAmtOrg = currRow[key].split(',')[1];
        console.log('splAllocAmtOrg = ', splAllocAmtOrg);

        //splAllocAmt = currRow[key].split(',')[1];
        //console.log('splAllocAmt = ', splAllocAmt);

        splAllocRatioOrg = currRow[key].split(',')[2];
        console.log('splAllocRatioOrg = ', splAllocRatioOrg);

        //splAllocRatio = currRow[key].split(',')[2];
        //console.log('splAllocRatio = ', splAllocRatio);

        /*if(splAllocRatio === undefined) {
          splAllocRatio = '';
        }*/

        if(key === keyCol) {

          if(overrideType === 'ratio') {
            splAllocRatio = event.value;
            splAllocAmt = pshipAmt * ((splAllocRatio === '') ? 0: splAllocRatio / 100);
            updatedPartnerAmts = defaultAlloc + "," + splAllocAmt + ((splAllocRatio !== '') ? ',' + splAllocRatio : '');
          }
          else if(overrideType === 'amount') {
            splAllocAmt = event.value;
            updatedPartnerAmts = defaultAlloc + ((splAllocAmt !== '') ? "," + splAllocAmt: '');
          }

         /* if(overrideType === 'ratio') {
            updatedPartnerAmts = defaultAlloc + "," + splAllocAmt +  ',' + splAllocRatio;
          }
          else if(overrideType === 'amount') {
            updatedPartnerAmts = defaultAlloc + "," + splAllocAmt;
          }*/
          console.log('updatedPartnerAmts = ', updatedPartnerAmts);
          currRow[key] = updatedPartnerAmts;

          /*if(event.value !== null && event.value !== '') {

            if(overrideType === 'ratio') {
              splAllocRatio = event.value;
              splAllocAmt = totalAllocation * (splAllocRatio / 100);
            }
            else if(overrideType === 'amount') {
              splAllocAmt = event.value;
            }

            updatedPartnerAmts = defaultAlloc + "," + splAllocAmt +  ',' + splAllocRatio;
            console.log('updatedPartnerAmts = ', updatedPartnerAmts);
            currRow[key] = updatedPartnerAmts;
          }
          else
            splAllocAmt = splAllocAmtOrg;*/
        }
        else {
          splAllocAmt = splAllocAmtOrg;
        }

        console.log('splAllocAmt = ', splAllocAmt);


        if (splAllocAmt === undefined || splAllocAmt === '') {
          calcAmt = defaultAlloc;
        }
        /*else if(splAllocAmt === '') {
          calcAmt = '0';
        }*/
        else {
          calcAmt = splAllocAmt;
        }
        console.log('calcAmt = ', calcAmt);

        totalAllocCalc = Number(totalAllocCalc) + Number(calcAmt);
        console.log(' totalAllocCalc for key : ', key , ' = ' , totalAllocCalc);
      }
    }
    console.log(' final totalAllocCalc = ', totalAllocCalc);

    let variance = Number(pshipAmt) - totalAllocCalc;
    console.log('variance = ', variance);
    currRow.VARIANCE= variance;

  }

  saveSplAllocData() {
    console.log('Save button clicked.');



    let ptr_spl_details : any = {'ptr':'', 'def_alloc':'', 'spl_alloc': '', 'ratio': ''};
    let value :any = '';

    this.clob_settings = {};
    this.clob_data = [];

    this.rowsEdited = [];

    this.clob_settings['tax_year'] = this.taxYear;
    this.clob_settings['scenario'] = this.scenario;
    this.clob_settings['jcd_key'] = this.jcdKey;
    this.clob_settings['sso_id'] = this.ssoId;

    this.clob_settings['combination_key'] =  this.combinationKey;
    //this.clob_settings['process_name'] =  'Partnership Special Allocation Save';

    for(let i = 0; i < this.splAllocPopupData.length; i++) {
      let splAllocRecord: any = {};
      splAllocRecord = this.splAllocPopupData[i];
      console.log(' splAllocRecord = ', splAllocRecord);
      if (splAllocRecord.IS_DIRTY === 'Y') {
        this.rowsEdited.push(splAllocRecord);
        console.log('this.rowsEdited = ', this.rowsEdited);
      }
    }

    for(let i = 0; i < this.splAllocPopupData.length; i++) {
      let splAllocRecord: any = {};
      splAllocRecord = this.splAllocPopupData[i];
      console.log(' splAllocRecord = ', splAllocRecord);
      if (splAllocRecord.IS_DIRTY === 'N' && splAllocRecord.VARIANCE !== 0) {
        this.rowsEdited.push(splAllocRecord);
        console.log('this.rowsEdited = ', this.rowsEdited);
      }
    }

    if(this.rowsEdited.length > 0) {
      for(let i = 0; i < this.rowsEdited.length; i++) {
        this.clob_data.push(this.rowsEdited[i]);
      }
    }
    console.log('clob_settings = ' , this.clob_settings);
    console.log('clob_data = ' , this.clob_data);

    if(this.clob_data.length > 0) {

      if (this.validate() == true) {

        this.isSaving = true;

        console.log('valid data ... doing save server call...');

        for(let i = 0; i < this.clob_data.length; i++) {
          let singleRecord = this.clob_data[i];
          console.log('singleRecord = ', singleRecord);
          let ptr_spl_details_array : any = [];
          let ptr_spl_details_cloned : any = {'ptr':'', 'def_alloc':'', 'spl_alloc': '', 'ratio': ''};

          for(let key in singleRecord) {

            if(key.includes('/')) {
              value = singleRecord[key];
              console.log('value = ', value);
              ptr_spl_details.ptr = key;
              ptr_spl_details.def_alloc = (value.split(',')[0] === undefined) ? '': value.split(',')[0];
              ptr_spl_details.spl_alloc = (value.split(',')[1] === undefined) ? '': value.split(',')[1];
              ptr_spl_details.ratio = (value.split(',')[2] === undefined) ? '': value.split(',')[2];

              ptr_spl_details_cloned = cloneDeep(ptr_spl_details);
              ptr_spl_details_array.push(ptr_spl_details_cloned);

            }
          }
          this.clob_data[i]['ptr_amt_ratio'] = ptr_spl_details_array;
        }
        console.log('clob_data = ' , this.clob_data);

        let updateSubscription = this.splAllocService.saveSplAllocationData(this.baseURL,this.taxYear,this.scenario, this.jcdKey, this.ssoId, this.processName, this.checkLockedObj, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey, this.clob_settings, this.clob_data).subscribe(
          (response)=>{
            this.isLoading =false;
            this.isSaving = false;
            if(response.callSuccess == "1") {
              this.splAllocService.openSnackBar('Special Allocation Saved','gtw-snackbar--green');
              this.savePshipSplAlloc.emit('save-pship-spl-alloc');
            } else {
              this.splAllocService.openSnackBar('Error Saving Sourcing Data. '+response.errorMessage, 'gtw-snackbar--red');
            }
          },
          error => { this.isLoading = false; this.isSaving = false; this.splAllocService.openSnackBar('Special Allocation Save Failed. '+error.error.errorMessage, 'gtw-snackbar--red'); },
          () => { console.log("Update Completed");this.isLoading =false; this.isSaving = false;}
        );
        this.subscriptions.add(updateSubscription);
      }
      else {
        this.isSaving = false; this.splAllocService.openSnackBar('Validation Error: . '+ this.validationErrMsg, 'gtw-snackbar--yellow');
      }
    }
    else {
      this.isSaving = false;
    }
  }

  validate() {
    let isValid: boolean = true;
    //return isValid;

    let record: any = {};
    this.validationErrMsg = '';

    for(let i = 0; i < this.clob_data.length; i++) {
      record = this.clob_data[i];
      let tradingPartner;
      console.log('record = ', record);
      if(record.VARIANCE != 0) {
        isValid = false;
        if(record.TRADING_PARTNER !== '0') {
          tradingPartner = ' and Trading Partner : ' + record.TRADING_PARTNER;
        }
        else {
          tradingPartner = '';
        }
        this.validationErrMsg = 'Variance must be zero for Account : ' +  record.ACCT + ' - ' + record.ACCT_DESC + tradingPartner;
        console.log('validationErrMsg = ', this.validationErrMsg);
        break;
      }
    }
     return isValid;
  }

  modalClose() {
    console.log("click close");
    this.cancelPshipSplAlloc.emit('cancel-pship-spl-alloc')
  }

  ngOnDestroy(): void {
    console.log('Destroyed');
  }
}
