import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { EntityChartUpdateService } from './entity-chart-update.service';
import { ChartCodeModel } from './chart-code-model';
import { DeleteTransactionModalComponent } from './delete-transaction-modal/delete-transaction-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gtw-entity-chart-update',
  templateUrl: './entity-chart-update-mat.component.html',
  styleUrls: ['./entity-chart-update.component.scss']
})
export class EntityChartUpdateComponent implements OnInit, OnDestroy {

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;

  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private ccList :string = "";//code combination
  private filingGroup :number = 0;
  private processName :string = 'UPDATE_ENTITY_CHART_PROCESS';
  private checkLockedbj: any = [];// locking object;
  private scenarioCode: string = '';
  private ssoId: string = '';
  private isIssueKeyEnabled: string = '';

  @Input('base-url')
  set _baseURL(baseURL:string){
    this.baseURL = baseURL;
    // this.init();
  }

  @Input('base-url-gtw-api')
  set _baseURLGtw(baseURL:string){
    this.baseURLGtw = baseURL;
    // this.init();
  }

  @Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
  
  @Input('tax-year')
  set _taxYear(taxYear:number){
    this.taxYear = taxYear;
    this.init();
  }
  
  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input('cc-list')
  set _ccList(ccList:string){
    this.ccList = ccList;
    this.init();
  }

  @Input('filing-group')
  set _filingGroup(filingGroup:number){
    this.filingGroup = filingGroup;
    this.init();
  }

  @Input('process-name')
  set _processName(processName:string){
    this.processName = processName;
    this.init();
  }

  @Input('check-locked-bj')
  set _checkLockedbj(checkLockedbj:any){
    this.checkLockedbj = checkLockedbj;
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    this.ssoId = ssoId;
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode:string) {
    this.scenarioCode = scenarioCode;
    this.init();
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled:string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
    this.init();
  }

  @Output('cancel-chart-update')
  cancelChartUpdate = new EventEmitter<string>();

  disableEntity = true;
  
  allEntityDetails:any =[];
  allEntityDetails_backup:any =[];

  isLoading = false;
  isLoadingDomestic = false;
  isLoadingForeign = false;
  subscriptions:Subscription = new Subscription() ;
  
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;

  allEntitiesChartCodeFormControls:Array<FormControl> = []; //new FormControl();

  optionsDomestic:Array<ChartCodeModel> = [];
  optionsForeign:Array<ChartCodeModel> = [];
  optionsTransCount:Array<any>=[];
  
  allFilteredOptions: Array<Observable<ChartCodeModel[]>> = [];

  focusEntity !:any;
  focusEntityI!:number;
  focusEntityJ!:number;
  updateEntityList:Array<any> = [];
  delayConfirm = false;

  type  = {delete: 'delete', save: 'save'};
  onChartChangeHeader = 'Delete Transaction(s)?';
  onChartChangeMessage = 'To update chart, existing transaction(s) should be removed. Please confirm deletion of transaction(s)?';
  onSaveHeader = 'Confirm';
  onSave = 'Chart will be updated and transaction(s) will be deleted, do you want to continue?';
  deleteLabel = 'Transaction will be deleted.';
  
  disableDelete = false;
  backupEntityObject: any ={};

  modalName = 'show-custom-modal';

  public primaryConfig = {
    isPrimary: true
  };

  constructor(private entityChartUpdateService:EntityChartUpdateService,
              public dialog: MatDialog) { }
  
  ngOnInit(): void {
    this.init();
  }

  init(){
    if(this.checkInputType()){
      
      this.getAllEntityDetails();
      this.getAllChartCodes("D");
      this.getAllChartCodes("F");
      this.getAllTransCount();

    }
  }

  checkInputType():boolean{
    if(Number.isInteger(parseInt(this.taxYear+"")) && 
       Number.isInteger(parseInt(this.scenario+'')) && 
       Number.isInteger(parseInt(this.jcdKey +'')) && 
       Number.isInteger(parseInt(this.filingGroup+''))){
      return true;
    }

    return false;
  }

  getAllEntityDetails (){

    if((this.allEntityDetails == null || this.allEntityDetails.length == 0) && !this.isLoading) {
      this.isLoading = true;
      let getAllSubscription = this.entityChartUpdateService.getAllEntityChartList(this.baseURL,this.taxYear,this.scenario,this.jcdKey,this.ccList,this.filingGroup)
      .subscribe(
        (entities:any) => {
          this.isLoading = false;
          this.allEntityDetails = entities;
          this.allEntityDetails_backup = JSON.parse(JSON.stringify(entities));
          this.setupDistinctEntities();
        },
        error => {
          console.error(error);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          console.log("Completed all entities");
        }
        );
      this.subscriptions.add(getAllSubscription);
    }
  }

  getAllTransCount(){
    this.entityChartUpdateService
      .getTransCount(this.baseURLGtw,this.taxYear,this.scenario,this.jcdKey,this.ccList)
      .subscribe(
        (res:any)=>{console.log(res)
          this.optionsTransCount = res;
        },
        (err)=>console.log(err)
      );
  }
  
  setupDistinctEntities(){
    this.allEntityDetails.map((entity:any, i:number)=>{
      entity.selection =false;
      this.setFilteredOption(entity,i);
    });
  }

  getAllChartCodes(type:string) {
    
    if((this.optionsDomestic.length == 0 && type == 'D' && !this.isLoadingDomestic) || (this.optionsForeign.length == 0 && type == 'F' && !this.isLoadingForeign)) {
      if(type == 'D'){
        this.isLoadingDomestic = true;
      } else {
        this.isLoadingForeign = true;
      }
      let getChartCodeSubscription = this.entityChartUpdateService.getChartCodes(this.baseURL,this.taxYear,this.scenario,this.jcdKey,type)
        .subscribe(
          (codes:Array<ChartCodeModel>) => {
            console.log(codes);
            if(type == 'D'){
              this.optionsDomestic = codes;
              this.isLoadingDomestic = false;
            }else{
              this.optionsForeign = codes;
              this.isLoadingForeign = false;
            }
          },
          (error)=>console.error(error),
          () => console.log("Completed chart code")
        )
      this.subscriptions.add(getChartCodeSubscription);
    }
  }

  private _filter(value: any,type:string):ChartCodeModel[]{

    if(value && typeof value != "object"){
      const filterValue = value?.toLowerCase();
      if(type== 'D'){
        return this.optionsDomestic.filter((option:ChartCodeModel) => option.code.toLowerCase().includes(filterValue));
      }
      return this.optionsForeign.filter((option:ChartCodeModel) => option.code.toLowerCase().includes(filterValue));
    }
    return [];
  }

  backupEntity(entity:any,i:number,j:number){
    if(i!=this.focusEntityI || j != this.focusEntityJ || entity['source_SYSTEM_FLAG'] != this.backupEntityObject['source_SYSTEM_FLAG']){
      this.focusEntity = entity;
      this.focusEntityI = i;
      this.focusEntityJ = j;
      this.backupEntityObject = JSON.parse(JSON.stringify(this.allEntityDetails_backup[i]));
    }
  }

  selectedOption(chartCode:any){
    console.log(chartCode.value);
    this.focusEntity['chart_CODE'] = chartCode.value['code']  ;
    this.focusEntity['chart_KEY'] = chartCode.value['key'] ;
    this.confirmDelete('show-custom-modal',this.focusEntity,this.focusEntityI,this.focusEntityJ);
  }

  displayFn(option:ChartCodeModel): string {
    return (option)? option.code : '';
  }

  checkboxClicked(entity:any,i:number,j:number) {
    this.backupEntity(entity,i,j);
    this.openDialog(this.onChartChangeHeader,this.onChartChangeMessage, this.type.delete);
  }

  clearButtonClicked(event:Event,entity:any,i:number,j:number) {
    this.backupEntity(entity,i,j);
    this.cancelUpdate(this.modalName);
    event?.stopPropagation();
    
  }

  confirmDelete(id: string, entity:any,i:number,j:number) {
    
    if(parseInt(entity.groupItems[j].trans_COUNT) <= 0){
      this.proceedWithUpdate(id);
    } else {
      if(entity.chart_CODE != this.backupEntityObject?.chart_CODE){
        this.baseModalCustom?.showMore(id);
        this.openDialog(this.onChartChangeHeader,this.onChartChangeMessage, this.type.delete);
      }
    }    
  }

  cancelUpdate(id: string) {
      this.baseModalCustom?.closeModal(id);
      this.focusEntity['chart_CODE'] = this.backupEntityObject['chart_CODE'];
      this.focusEntity['chart_KEY'] = this.backupEntityObject['chart_KEY'];
      this.focusEntity['groupItems'][this.focusEntityJ]['selection']= false;  
      this.setFilteredOption(this.focusEntity,this.focusEntityI);
  }

  proceedWithUpdate(id: string) {
    this.focusEntity['groupItems'][this.focusEntityJ]['selection'] = true;
    this.setFilteredOption(this.focusEntity,this.focusEntityI);
    this.baseModalCustom?.closeModalOk(id);
  }

  private setFilteredOption(entity:any,i:number){
    if(entity && entity['source_SYSTEM_FLAG'] == 'D'){
      let fc = new FormControl({key:entity.chart_KEY,code:entity.chart_CODE});
      this.allFilteredOptions[i] = fc.valueChanges
          .pipe(
            startWith(''),
            map((value:string) => this._filter(value,'D'))
          );
      this.allEntitiesChartCodeFormControls[i] =fc; 
    } else {
      let fc = new FormControl({key:entity.chart_KEY,code:entity.chart_CODE});
      this.allFilteredOptions[i]= fc.valueChanges
          .pipe(
            startWith(''),
            map((value:string) => this._filter(value,'F'))
          );
      this.allEntitiesChartCodeFormControls[i]=fc; 
    }
  }

  updateEntityChart(){
    this.isLoading =true;
    var settings = {
      "tax_year": this.taxYear,
      "scenario": this.scenario,
      "jcd_key": this.jcdKey
    };
    this.updateEntityList = this.allEntityDetails.filter((entity:any) => !!entity['groupItems'][0]['selection']);
    if(!this.updateEntityList || this.updateEntityList.length < 1){
      this.isLoading =false;
      this.entityChartUpdateService.openSnackBar('No TB chart selected to update', 'gtw-snackbar--yellow');
      return;
    }
    let updateSubscription = this.entityChartUpdateService.updateEntityChartCodes(this.baseURLGtw,this.taxYear,this.processName,settings,this.updateEntityList,this.baseGlobalParams,this.checkLockedbj).subscribe(
      (response)=>{
            this.isLoading =false;
            if(response.callSuccess == "1") {
              this.entityChartUpdateService.openSnackBar('Entity Chart updated','gtw-snackbar--green');
              this.cancelChartUpdate.emit('refresh-chart-update');
            } else {
              this.entityChartUpdateService.openSnackBar('Entity Chart update failed. '+response.errorMessage, 'gtw-snackbar--red');
            }
      },
      error => { this.isLoading =false;this.entityChartUpdateService.openSnackBar('Entity Chart update failed. '+error.error.errorMessage, 'gtw-snackbar--red'); },
      () => { console.log("Update Completed");this.isLoading =false; }
    );
    this.subscriptions.add(updateSubscription);
  }

  // this is for google material component

  openDialog(header:string = this.onChartChangeHeader, msg:string = this.onChartChangeMessage, type: string = 'delete') {
    const data = {
      data: {
        message : msg,
        header: header,
        type: type
      }
    };
    const dialogRef = this.dialog.open(DeleteTransactionModalComponent,data);

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
      if(result == 'delete'){
        this.proceedWithUpdate(this.modalName);
      }else if(result == 'save'){
        this.updateEntityChart();
      }else {
        this.cancelUpdate(this.modalName);
      }
      
    });
  }

  // appkit html
  modalDetermine() {
    console.log("click ok");
  }

  modalClose() {
      console.log("click close");
      this.cancelChartUpdate.emit('cancel-chart-update');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    console.log('Destroyed');
  }

}
