import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from 'appkit-angular/modal';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {CopyReplicateService} from './copy-replicate.service';
import {DSTTaxYear, DSTTaxYearPeriod} from '../create-disclosure/create-new/create-disclosure.service';
import {Alert, StatementTrackerService} from '../statement-tracker.service';
import {CreateDisclosureSharedService} from '../create-disclosure/shared/create-disclosure-shared.service';
import {TrackerTabsService, TrackerTabState} from '../tracker-tabs.service';
import { StmtData } from '../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../shared/dst-shared.service';

@Component({
  selector: 'dst-copy-replicate',
  templateUrl: './copy-replicate.component.html',
  styleUrls: ['./copy-replicate.component.scss']
})
export class CopyReplicateComponent implements OnInit {

  @Input() rowObject!: DSTStatement;
  @Input() mode!: string;
  @Output() afterModalClosed: EventEmitter<null>;

  @ViewChild('baseModal', {static: false}) baseModal!: ModalComponent;
  @ViewChild('modalRef') modalRef!: ElementRef;

  taxYears: any[] = [
    {
      data: []
    }
  ];
  selectedTaxYear!: DSTTaxYear;
  taxYearPeriods: any[] = [
    {
      data: []
    }
  ];
  selectedPeriod!: DSTTaxYearPeriod;
  selectorDefaultText: string = 'Loading...';
  showDisclosure: boolean = false;
  copiedStatementId!: string;
  filtersLoaded: boolean = false;
  loadingStatementId: boolean = false;
  loadingStatementData: boolean = false;
  alert!: Alert;

  constructor(private cRService: CopyReplicateService,
              private trackerTabsService: TrackerTabsService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.afterModalClosed = new EventEmitter<null>();
    this.alert = this.dstSharedService.alert;
  }

  ngOnInit(): void {
    this.cRService.getFilterValues()
      .subscribe((response: any[]) => {
        this.taxYears[0].data = response[0];
        this.taxYearPeriods[0].data = response[1];
        this.selectDefaults();
        this.filtersLoaded = true;
        // ** remove below when Appkit provides onModalDismissed API **
        // notify tracker to destroy modal when dismissed (user clicked on the backdrop)
        setTimeout(() => {
          const backdropEle: HTMLCollectionOf<Element> = document.getElementsByClassName('modal');
          if (backdropEle[0]) {
            backdropEle[0].addEventListener('click', () => {
              this.notifyAfterModalClosed();
            });
          }
        });
      }, (eMsg: string) => {
        this.dstSharedService.showAlert('error', eMsg);
      })
      .add(() => {
        this.selectorDefaultText = 'Select here';
      });
    if (this.rowObject) {
      this.copiedStatementId = this.rowObject.statementId;
    }
  }

  private selectDefaults(): void {
    const taxYearFromRow: number = this.rowObject?.TAXYEAR;
    const index: number = this.taxYears[0].data.findIndex((year: DSTTaxYear) => year.text === taxYearFromRow) || 0;
    this.selectedTaxYear = this.taxYears[0].data[index];
    this.selectedTaxYear.selected = true;
    this.selectedPeriod = this.taxYearPeriods[0].data[0];
    this.selectedPeriod.selected = true;
    // trigger change detection in the Appkit Selector Component
    this.changeDetectorRef.detectChanges();
  }

  onTaxYearChanged(event: any): void {
    this.selectedTaxYear = event.selected[0];
  }

  onPeriodChanged(event: any): void {
    this.selectedPeriod = event.selected[0];
  }

  continue(): void {
    if (this.mode === 'Copy') {
      this.createModalTab(this.rowObject.statementId);
      this.loadStatementData();
    } else {
      this.loadingStatementId = true;
      this.cRService.getNewStatementId(this.rowObject.statementId, this.selectedTaxYear.id, this.selectedPeriod.id)
        .subscribe((newStatementId: string) => {
          this.createModalTab(newStatementId);
          this.showDisclosure = true;
        }, (eMsg: string) => {
          this.dstSharedService.showAlert('error', eMsg);
        })
        .add(() => {
          this.loadingStatementId = false;
        });
    }
  }

  private loadStatementData(): void {
    // this.sharedService.setSavedStatementId(this.rowObject.statementId);
    this.loadingStatementData = true;
    this.sharedService.getLoadStatementData().subscribe((data: StmtData) => {
      const statementData = data;
      statementData.isCopiedStatement = true;
      this.sharedService.getActiveFormState().formData = statementData;
      this.showDisclosure = true;
    }, (eMsg: string) => {
      console.error(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.loadingStatementData = false;
    });
  }

  private createModalTab(statementId: string): void {
    const modalTab: TrackerTabState = new TrackerTabState(StatementTrackerService.ID_MODAL);
    modalTab.addFormState(this.selectedTaxYear.text,
      this.selectedTaxYear.id,
      this.selectedPeriod.id,
      statementId,
      this.rowObject.formId,
      this.rowObject.formName,
      this.rowObject.ATTFORMTYPEDESC!,
      this.rowObject.FORMTYPEID!,
      this.rowObject.cseId,
      false,
      this.mode === 'Replicate');
    this.trackerTabsService.createTrackerTab(modalTab);
  }

  showMore(id: string): void {
    this.baseModal.showMore(id);
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="copy-replicate"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-lg');
    });
  }

  modalClose(): void {
    this.notifyAfterModalClosed();
  }

  modalDetermine(): void {
    this.notifyAfterModalClosed();
  }

  // closeModal(id: string): void {
  //   this.baseModal.closeModal(id);
  //   this.notifyAfterModalClosed();
  // }

  closeModalOk(id: string): void {
    this.baseModal.closeModalOk(id);
    this.notifyAfterModalClosed();
  }

  private notifyAfterModalClosed(): void {
    // this.sharedService.setFormData({});
    this.afterModalClosed.emit();
  }

}
