<ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="create-options">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div>
            <span class="a-h4">Create Disclosure</span>
          </div>
        </div>
        <div class="a-modal-body">
          <div *ngIf="true">
            <div class="flex-container">
              <div *ngFor="let option of options">
                <ap-button [btnText]="option.FORMNAME" class="a-mr-10" [btnType]="'a-btn-gray'" [config]="{isPrimary: true}" (onBtnClick)="onSelect(option)">
                </ap-button>
              </div>
            </div>
          </div>
          <div class="viewport-wrapper" *ngIf="false">
            <dst-create-disclosure-template-viewport [rowObject]="rowObject"></dst-create-disclosure-template-viewport>
          </div>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('copy-replicate')">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
  <!--  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">-->
  <!--    {{alert.message}}-->
  <!--  </ap-alert>-->
</ap-modal>
