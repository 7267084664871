import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject,merge } from 'rxjs';
import { startWith, scan } from 'rxjs/operators';

import { ScreenVideoListService } from './screen-video-list.service';
import { ScreenVideoDialogComponent } from './screen-video-dialog/screen-video-dialog.component';
import { ScreenVideo } from './screen-video';
import { UserService } from '../user.service';
import { User } from '../user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenVideoDeleteComponent } from './screen-video-delete.component';

interface ScreenDocument extends Document {
  pictureInPictureElement:any;
  requestPictureInPicture(): any;
  exitPictureInPicture: (() => Promise<any>) ;
}

@Component({
  selector: 'gtw-screen-video-list',
  templateUrl: './screen-video-list.component.html',
  styleUrls: ['./screen-video-list.component.scss'],
  providers:[ScreenVideoListService]
})
export class ScreenVideoListComponent implements OnInit {

  dialogRef:MatDialogRef<ScreenVideoDialogComponent, any> | undefined ;
  videoList$!:Observable<any>;  
  merged$!: Observable<any>;  
  deleteSubject = new Subject();
  isLoading=false;
  durationInSeconds = 5;

  @Output() 
  updateScreenVideoEvent= new EventEmitter();

  @Input('screen-key')
  screenKey!: number;
  
  @Input('client-key')
  clientKey!: number;
  
  @Input('base-url')
  baseUrl = 'http://localhost:8087';

  @Input('refresh')
  set refresh(value:boolean){
    if(value){
      this.getAllVideos();
    }      
  }
  
  constructor(private screenVideoListService: ScreenVideoListService,
              public matDialog: MatDialog,
              private userService:UserService,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    if(!isNaN(this.clientKey) && !isNaN(this.screenKey)){
      this.getAllVideos();
    }
      

  }

  private getAllVideos(){
    this.isLoading=true;
    this.videoList$ = this.screenVideoListService.getAllVideos(this.baseUrl, this.clientKey, this.screenKey);
    this.merged$ = merge(this.videoList$, this.deleteSubject)
                    .pipe(
                        startWith({}),
                        scan((videoList,data) => {
                          if(data && Object.keys(data).length > 0){
                            this.isLoading=false;
                          }
                          if(data.operation && data.operation == "delete"){
                            var index = videoList.data.findIndex((elt:ScreenVideo) => elt.video_id === data.id);
                            videoList.data.splice(index, 1);
                            return videoList;
                          }
                          return data;
                        })
                    )
  }

  async openVideoDialog(video:ScreenVideo){

    // if(this.dialogRef){
    //   document.exitPictureInPicture().catch(error => {
    //     console.log("No Picture in Picture element.");
    //   });
    //   document.querySelector('video')?.removeEventListener("enterpictureinpicture",  this.hideDialog, true);
    //   document.querySelector('video')?.removeEventListener("leavepictureinpicture", this.showDialog, true);
    //   this.dialogRef.close();
    // }
    
    this.matDialog.closeAll();
    this.dialogRef?.close();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {...video,...{baseURL:this.baseUrl}};
    dialogConfig.panelClass ='remove-dialog-css';
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = '30vw';
    dialogConfig.position= { right: '0px', top: '55vh' },
    dialogConfig.id = video.video_id.toString();

    if ((document as ScreenDocument).pictureInPictureElement) {
      await ((document as ScreenDocument).exitPictureInPicture());
    }
    
    this.dialogRef = undefined;
    this.dialogRef = this.matDialog.open(ScreenVideoDialogComponent,dialogConfig);
    const event = new Event('playvideo');
    document.dispatchEvent(event);

    // document.querySelector('video#screen_video')!.addEventListener('enterpictureinpicture', this.hideDialog);
    // document.querySelector('video#screen_video')!.addEventListener('leavepictureinpicture', this.showDialog);

  }

  updateScreenVideo(video:ScreenVideo): void{
    console.log("update index is ", video.video_id);
    this.updateScreenVideoEvent.emit(video);
  }

  deleteScreenVideo(id:number):void{
    console.log("delete index is ", id);
    this.isLoading=true;
    this.screenVideoListService.deleteScreenVideo(this.baseUrl,this.clientKey, id).subscribe(
      (res:any) =>{
        this.isLoading =false;
        if(res.callSuccess == 1 || parseInt(res.callSuccess)==1 ){
          this.deleteSubject.next({'operation':'delete', 'id':id});
          this.openSnackBar("Video deleted Successfully !!");
        }else{
          this.openSnackBar("Video delete failed: "+ res.errorMessage);
        }
      },
      error=>{
        this.openSnackBar("Video delete Failed");
        console.log(error);
      }
    );
    
  }

  isValidUser(sso:string){
    const allowedRoles = ['admin','super admin','developer'];
    if(this.userService.isValidUser(['admin']) && this.userService.getUser()?.sso == sso){
      return true;
    }
    if(this.userService.isValidUser(['super admin','developer'])){
      return true;
    }
    
    return false;    
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }

  confirmDelete(id:number):void{
    const dialogRef = this.matDialog.open(ScreenVideoDeleteComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        this.deleteScreenVideo(id);
      }
    });
  }
}
