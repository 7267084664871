import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { BadgeModule } from 'appkit-angular/badge';
import { SelectorModule } from 'appkit-angular/selector';
import { CheckboxModule } from 'appkit-angular/checkbox';
import { GtwDirectivesModule } from '../shared/_directives/gtw-directives.module';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { TooltipModule } from 'appkit-angular/tooltip';
import { BreadcrumbsModule } from 'appkit-angular/breadcrumbs';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [HeaderComponent, ExternalLinkComponent],
  imports: [
    CommonModule,
    BadgeModule,
    SelectorModule,
    CheckboxModule,
    GtwDirectivesModule,
    TooltipModule,
    BreadcrumbsModule,
    DragDropModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
