<div>
    <div class="panel-wrapper">
        <div class="acivity-list-panel">
            <label>Last viewed Screen</label>
                <span class="material-icons remove-icn" (click)="showViewedScreenList = !showViewedScreenList">
                    {{showViewedScreenList ? 'expand_less': 'expand_more'}}
                </span>
        </div>
        <div class="acivity-list-panel-body" [ngClass]="{'viewed-screen-list-panel-body': !showViewedScreenList}">
            <ng-container *ngIf="(lasViewedScreens$ | async) as lastViewed; else loading">
                <ul *ngFor="let viewedScreen of lastViewed">
                    <li (click)="loadClickedScreen(viewedScreen)" class="screen-name">
                        <span [ngClass]="{'acivity-list-status-grey' : !viewedScreen.isActive,'acivity-list-status-green': viewedScreen.isActive}"></span>
                        <span style="margin-left: 15px;">{{viewedScreen.screenName}}</span>
                    </li>
                    <li style="margin-left: 12px;">{{viewedScreen.timeStamp | textDate}}</li>
                    <li style="margin-left: 12px;" *ngIf='viewedScreen.isActive'>Record Count: {{viewedScreen.records}}</li>
                </ul>
            </ng-container>
            <!-- <ul *ngFor="let topScreen of topFiveScreens; index as i">
                <li><span class="acivity-list-status"></span>{{topScreen.name}}</li>
                <li>{{topScreen.value}}</li>
                <li>{{viewedScreen.records}}</li>
            </ul> -->
        </div>
    </div>

    <div class="panel-wrapper">
        <div class="acivity-list-panel">
            <label>Pinned</label>
            <span class="material-icons remove-icn" (click)="showFavModScreenList = !showFavModScreenList">
                {{showFavModScreenList ? 'expand_less': 'expand_more'}}
            </span>
        </div>
        <div class="acivity-list-panel-body" [ngClass]="{'fav-mod-list-body': !showFavModScreenList}">
            <ul *ngFor="let screen of favScreens">
                <li  class="screen-name">
                    <span class="material-icons push-pin"  (click)="favBtnCheck(screen);">push_pin</span>
                    <span (click)="loadClickedScreen(screen)">{{screen.SCREEN_NAME}}</span>
                </li>
            </ul>
            <app-loader [loaded]='false' *ngIf="favScreens.length == 0">
                <p>Loading</p>
            </app-loader>
        </div>
    </div>

    <!-- <div class="panel-wrapper">
        <div class="acivity-list-panel">
            <label>Top Diagnostics</label>
            <span class="material-icons remove-icn" (click)="showTopDiagScreenList = !showTopDiagScreenList">
                {{showTopDiagScreenList ? 'add': 'remove'}}
            </span>
        </div>
        <div class="acivity-list-panel-body" [ngClass]="{'top-diag-list-body': !showTopDiagScreenList}">
            <ng-container *ngIf="(recentScreensActivityList$ | async) as topDiagScreen; else loading">
                <ul *ngFor="let topDiag of topDiagScreen">
                    <li (click)="loadClickedScreen(topDiag)" class="screen-name">
                        <span class="acivity-list-status-grey"></span>{{topDiag.name}}
                    </li>
                    <li>Records Loaded: {{topDiag.value}}</li>
                </ul>
            </ng-container>
        </div>  
    </div> -->
</div>

<ng-template #loading>
    <app-loader [loaded]='false'>
        <p>Loading</p>
    </app-loader>
</ng-template>