<div class="loader-overlay" *ngIf="saving">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [noPlaceholder]="true" [disabled]="!savedFiles.length" (onSaveClick)="save()" (onCancelClick)="cancel()"></dst-create-disclosure-actions>
<div class="form-template-wrapper">
  <div [formGroup]="formBulkUpload">
    <dst-template-header [isBulk]="true" [parentFormGroup]="formBulkUpload" (consolGroupChanged)="OnConsolGroupChanged($event)"></dst-template-header>
    <div style="margin-top: 30px;">
      <dst-template-leid-selector [label]="'Please specify the primary LEID'" [labelId]="'1'"
                                  (onSelectedLEChanged)="onPrimaryEntityChanged($event)">
      </dst-template-leid-selector>
      <div class="a-form-error" style="padding-left: 400px;" *ngIf="!primaryEntity">
        <div>Please specify the Primary Entity</div>
      </div>
      <div class="entity-details">
        <span>Primary Name:</span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span>EIN:</span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.EIN}}</span><span>, a</span>
      </div>
      <div class="entity-details">
        <span></span><span class="template-color-blue">{{!primaryEntity ? '' : primaryEntity.COUNTRYINC + ' Corporation'}}</span>
      </div>
      <dst-template-leid-selector [label]="'Please specify the LEID of the Branch Entity'" [labelId]="'2'" [isBranchEntity]="true"
                                  (onSelectedLEChanged)="onBranchEntityChanged($event)">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.ENTITY}}</span>
      </div>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' : branchEntity.EIN}}</span>
      </div>
    </div>
    <div class="file-upload-wrapper a-mt-30">
      <button id="uploadPdf" class="file-upload-btn"
              [ngClass]="{'error': isValidPdf === false}"
              dragAndDropUpload [maxLength]="1" [allowedExts]="fileAcceptShort"
              (maxLenExceeded)="onMaxLenExceeded()" (extNotMet)="onExtNotMet()" (filesDropped)="onFilesDropped($event)">
        Drag and drop the file to be uploaded into this box. Only 1 of {{fileAcceptShort?.join(',')}} file is accepted.
      </button>
      <div class="a-form-error" *ngIf="isValidPdf === false">
        You have selected an invalid pdf file, please choose one without any fillable fields!
      </div>
    </div>
    <div class="file-list-container a-mt-30">
      <div class="file-details" *ngFor="let file of savedFiles">
        <div class="delete-box">
          <span class="appkiticon icon-close-outline" (click)="delete(file)"></span>
        </div>
        <table>
          <thead>
          <tr>
            <th>Statement Id</th>
            <th>Primary LEID</th>
            <th>Branch Entity</th>
            <th>File Name</th>
            <th>Provided By</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{file.STATEMENTID}}</td>
            <td>{{file.PRIMARYLEID}}</td>
            <td>{{file.BRANCHENTITY}}</td>
            <td>{{file.FILENAME}}</td>
            <td>{{file.PROVIDEDBY}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
