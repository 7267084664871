import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithCommas'
})
export class numberWithCommasPipe implements PipeTransform {

  constructor(){
  }

  transform(x: any): string | null {
    
    if( x == 0 || typeof x === 'undefined' || x === '' || isNaN(x)){
        return x;
    }
    var isNegative = false;
    if (x < 0) {
        isNegative = true;
        x = Math.abs(x);
    }
    var c = parseFloat(x).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isNegative) {
        c = "-$" + c;
    } else {
        c = "$" + c;
    }
    return c;
  }
}