<div class="main-container">
  <div class="grid-container" id="368report-grid-container" style="height: calc(100vh - 124px);">
      <div class="top-toolbar">
          <div class="float-left ml-2">
            <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear" 
              (onChange)="onTaxYearChange()">
            </p-dropdown>
            <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136" (selectedMes)="selectedMeChanged($event)">
            </gtw-df-me-dropdown>
            <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
            <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
          </div>
      </div>
  
      <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName"
        [loading]="loading" [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
        styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
        [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">
  
          <ng-template pTemplate="header" let-columns>
              <tr [style.height]="'45px'">
                <ng-container *ngFor="let col of columns; index as i">
                  <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
          </ng-template>
  
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="height: 70px;">
                  <td style="width: 145px;">
                    <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENTID }}</span></a>
                  </td>
                  <td style="width: 105px;">
                    <span>{{ rowData.PROJECT_NAME }}</span>
                  </td>
                  <td style="width: 130px;">
                    <span>{{ rowData.PRIMARYLEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARYENTITY }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARYBUSINESS }}</span>
                  </td>
                  <td style="width: 145px;">
                    <span>{{ rowData.FILLING_GROUP }}</span>
                  </td>
                  <td style="width: 165px;">
                    <span>{{ rowData.SECONDARYLEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.SECONDARYENTITY }}</span>
                  </td>
                  <td style="width: 175px;">
                    <span>{{ rowData.SIGNIFICANT_HOLDER }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.SIGNIFICANTHOLDER_NAME }}</span>
                  </td>
                  <td style="width: 220px;">
                    <span> {{ rowData.DATE_OF_REORGANIZATION | date: 'yyyy-MM-dd' }} </span>
                  </td>
                  <td style="width: 195px;">
                    <span> {{ rowData.REORGANIZATIONCODESECTION}} </span>
                  </td>
                  <td style="width: 275px;">
                    <span>{{ rowData.IMPORTATIONPROPERTYDESC }}</span>
                  </td>
                  <td style="width: 236px;">
                    <span>{{ rowData.LOSSDUPLICATIONPROPERTDESC }}</span>
                  </td>
                  <td style="width: 335px;">
                    <span>{{ rowData.GAINLOSSPROPERTYDESC }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 375px;">
                    <span  gtwLineSplitter [inputString]="rowData.DESCRIPTION" [sepearator]="'||'">
                      {{ rowData.DESCRIPTION }} 
                    </span>
                  </td>
                  <td style="width: 155px; text-align: end;">
                    <span  gtwLineSplitter [inputString]="rowData.BASIS_AMOUNT" [sepearator]="'||'" [formatter]="'decimal'"> 
                      {{ rowData.BASIS_AMOUNT}} 
                    </span>
                  </td>
                  <td  style="width: 155px;">
                    <span>{{ rowData.BASIS_CURRENCY }}</span>
                  </td>
                  <td style="width: 125px;">
                    <span>{{ rowData.BASISTYPE }}</span>
                  </td>
                  <td style="width: 150px; text-align: end;">
                    <span  gtwLineSplitter [inputString]="rowData.FMV_AMOUNT" [sepearator]="'||'" [formatter]="'decimal'"> 
                      {{ rowData.FMV_AMOUNT}}
                    </span>
                  </td>
                  <td style="width: 155px;">
                    <span>{{ rowData.FMV_CURRENCY }}</span>
                  </td>
                  <td style="width: 435px;">
                    <span> {{ rowData.STOCKSSECURITYPROPERTYDESC }} </span>
                  </td>
                  <td style="width: 340px;">
                    <span  gtwLineSplitter [inputString]="rowData.STOCKSSECURITYNOTDESC" [sepearator]="'||'"> 
                      {{ rowData.STOCKSSECURITYNOTDESC}} 
                    </span>
                  </td>
                  <td style="width: 155px; text-align: end;">
                    <span  gtwLineSplitter [inputString]="rowData.BASIS_AMOUNT1" [sepearator]="'||'" [formatter]="'decimal'"> 
                      {{ rowData.BASIS_AMOUNT1}} 
                    </span>
                  </td>
                  <td style="width: 155px;">
                    <span> {{ rowData.BASIS_CURRENCY1 }} </span>
                  </td>
                  <td style="width: 125px;">
                    <span> {{ rowData.BASISTYPE1 }} </span>
                  </td>
                  <td style="width: 150px; text-align: end;">
                    <span  gtwLineSplitter [inputString]="rowData.FMV_AMOUNT1" [sepearator]="'||'" [formatter]="'decimal'"> 
                      {{ rowData.FMV_AMOUNT1}}
                    </span>
                  </td>
                  <td style="width: 155px;">
                    <span>{{ rowData.FMV_CURRENCY1 }}</span>
                  </td>
                  <td style="width: 200px;">
                    <span>{{ rowData.PLRCONTROLNUMBER }}</span>
                  </td>
                  <td style="width: 185px;">
                    <span>{{ rowData.ADOPTEDDATE_VALUE }}</span>
                  </td>
              </tr>
          </ng-template>
  
      </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>