<div class="loader-overlay" *ngIf="loading">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" (onSaveClick)="save()" (onCancelClick)="cancel()"
  [disableS3Upload]="false" (onS3UploadClick)="s3Upload()" 
  [isFiled]="formData?.isfiled" [isQAR]="formData?.isqar"
  [disabledS3]="s3Uploading" [statementId]="formData?.statementid">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp367bform_192">
  <div>
    <div align=center>
      <b>
      <span class="template-color-blue">
        {{formData?.dcnname}}
      </span>
      </b>
    </div>
    <div align="center">
      <B>
      <span class="template-color-blue">
        {{formData?.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br/>
        STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS
      </span>
      </B>
    </div>
    <br/>
    <div class="statement-body">
      <p>
        Parties:
      </p>
      <ol class="ol-decimal">
        <li>
          <span class="content-spacing" style="display: initial;">
            <span class="template-color-blue">{{secondaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.secondaryein == null? 'N/A': formData?.secondaryein}}</span><span>, Transferor, a </span><span class="template-color-blue">{{formData?.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}</span><span>; and</span>
          </span>
        </li>
        <li>
          <span class="content-spacing" style="display: initial;">
            <span class="template-color-blue">{{primaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.primaryein == null? 'N/A': formData?.primaryein}}</span><span>, Transferee, a </span>
            <span class="template-color-blue">{{formData?.primarycountryincdesc}} </span> <span>Corporation</span>
            <!-- <span class="template-color-blue">{{formData?.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span> -->
            <span>.</span>
          </span>
        </li>
      </ol>
      <ol class="ol-lower-roman">
        <li>
          <span class="content-spacing">The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the "Code") applies.</span>
        </li>
        <li>
          <span class="content-spacing">
            <span>On </span><span class="template-color-blue">{{attMap.DisplayDate}}</span><span>, pursuant to 304(a), </span><span class="template-color-blue">{{secondaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.secondaryein == null? 'N/A': formData?.secondaryein}}</span><span>, a </span><span class="template-color-blue">{{formData?.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}</span>
            <span>, (the "Significant Transferor"), is treated as transferring  <span class="template-color-blue">{{attMap.Shares ? attMap.Shares[0] : ''}}&nbsp;{{selStockTypeTxt}}</span> shares of </span><span class="template-color-blue">{{shEntity.entity}}</span><span>, EIN: </span><span class="template-color-blue">{{shEntity.ein == null? 'N/A': shEntity.ein}}</span><span>, a </span><span class="template-color-blue">{{shEntity.countryInc}} {{shCorpTypeTxt}}</span>
            <span>, (the "Company") to </span><span class="template-color-blue">{{primaryEntityName}}</span><span>, EIN: </span><span class="template-color-blue">{{formData?.primaryein == null? 'N/A': formData?.primaryein}}</span><span>, (the "Transferee"), a </span><span class="template-color-blue">{{formData?.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span>
            <span> in an exchange pursuant to Sec. 351 of the Code.</span>
          </span>
        </li>
      </ol>
      <p style="margin-bottom: 4rem;">
        Additional Information: <br>
        <span>{{(attMap.section4text && attMap.section4text[0] !== '') ? attMap.section4text[0] : ''}}</span>
      </p>
      <ol class="ol-lower-roman" start="3">
        <li>
          <span class="content-spacing">Pursuant to 304(a), shares of the Transferee were deemed issued to the Transferor in the exchange. The Transferee in turn is treated as redeeming the stock deemed issued to the Transferor in the exchange.</span>
        </li>
        <li>
          <span class="content-spacing">None.</span>
        </li>
        <li>
          <span class="content-spacing">For information required pursuant to regulations under Code Section 351, see the separate statement.</span>
        </li>
        <li>
          <span class="content-spacing">Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or the regulations under those sections has been provided with this tax return on Form 5471, Information Return of U.S. Persons with Respect to Certain Foreign Corporations.</span>
        </li>
        <li>
          <span class="content-spacing">Not applicable.</span>
        </li>
      </ol>
    </div>

    <table style="width:100%">
      <tbody>
      <tr>
        <td class="footer-left">
          <span>LEIDs: </span>
          <span class="template-color-blue">{{formData?.primaryleid}} / {{formData?.secondaryleid}}</span>
        </td>
        <td class="footer-right" *ngIf="formData?.branchleid">
          <span>Branch LEID: </span>
          <span class="template-color-blue">{{formData?.branchleid}}</span>
        </td>
      </tr>
      <tr>
        <td class="footer-left">
          <span>Statement ID: </span>
          <span class="template-color-blue">{{formData?.statementid}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
