<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" [disabledS3]="s3Uploading"
  (onSaveClick)="save()" (onCancelClick)="cancel()" [disableS3Upload]="false" (onS3UploadClick)="s3Upload()"
   [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"></dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp332">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        STATEMENT REQUIRED PURSUANT TO SECTION 332 <b> {{attMap['statementOption'] == undefined? '' : attMap['statementOption'][0]}}</b>&nbsp;BY A CORPORATION RECEIVING A LIQUIDATING DISTRIBUTION
        <br />
      </span>
    </B>
  </div>
  <br />
  <div [formGroup]="form189">
    <ol>
      <li>
        Parties:<br />
        <ol class="inner-list" *ngIf="loaded">
          <li>
            <span class="position-relative" style="left: 15px;">
              <span class="template-color-blue">{{secondaryEntityName}}</span><span>, EIN:&nbsp;</span>
              <span class="template-color-blue">{{stmtData.secondaryein == null? 'N/A':stmtData.secondaryein}}</span><span>, Recipient, a&nbsp;</span>
              <span class="template-color-blue">{{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}} </span><span> Corporation and</span>
            </span>
          </li>
          <li>
            <span class="position-relative" style="left: 15px;">
              <span class="template-color-blue">{{primaryEntityName}}</span><span>, EIN:&nbsp;</span>
              <span class="template-color-blue">{{stmtData.primaryein == null? 'N/A' : stmtData.primaryein}}</span><span>, Transferor, a&nbsp;</span>
              <span class="template-color-blue">{{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} </span><span>Corporation;</span>
            </span>
          </li>
        </ol>
        Pursuant to Code Section 332, <span class="template-color-blue">
          {{stmtData == null ? '': stmtData.primaryentityid == null? 'Transferor' : primaryEntityName}}
        </span>
        was liquidated during the current tax year into its Parent, <span class="template-color-blue">{{stmtData == null ? '': stmtData.secondaryentityid == null? 'Recipient' : secondaryEntityName}}</span>.
      </li>

      <li>
        The date(s) of all distribution(s) (whether or not under the plan) by the liquidating corporation during the current tax year;
        <br /><span class="template-color-blue" *ngIf="loaded">
              {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][0]}} <br />
              {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][1]}} <br />
              {{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][2]}}
             </span>
      </li>
      <li>
        Properties Transferred: <br />
        The fair market value and basis of assets of the liquidating corporation that have been or will be transferred to any recipient corporation, aggregated as follows:
        <ol class="inner-list list-parenth" *ngIf="loaded">
          <li>
            Importation property distributed in a loss importation transaction, as defined in Section 1.362-3(c)(2) and (3) (except that "section 332 liquidation" is substituted for "section 362 transaction"), respectively:
            <br /><span>{{attMap['importationPropertyDesc'] == undefined ? '' : (attMap['importationPropertyDesc'][0] ? attMap['importationPropertyDesc'][0] : 'None.')}}</span>
          </li>
          <li>
            Property with respect to which gain or loss was recognized on the distribution:
            <br /><span>{{attMap['gainLossPropertyDesc'] == undefined ? '' : (attMap['gainLossPropertyDesc'][0] ? attMap['gainLossPropertyDesc'][0] : 'None.')}}</span>
          </li>
          <li>
            Property not described in Section 1.332-6(a)(3)(i) or (ii):
            <br /><br />
            Properties Transferred
            <table class="table-view-layout">
              <tbody *ngIf="loaded">
                <tr>
                  <td colspan="3" style="width: 56%;"></td>
                  <td style="width: 22%;"><span>{{selBasisTxt}} Basis to the Corporation, the liquidating company</span></td>
                  <td style="width: 22%;"><span>Fair Market Value on Date of Distribution</span></td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td class="header-column"><span><b>Description</b></span></td>
                  <td>
                    <span><b><u>{{getSelCurrency(0)}}</u></b></span>
                  </td>
                  <td>
                    <span><b><u>{{getSelCurrency(1)}}</u></b></span>
                  </td>
                </tr>
                <ng-container ng-if="attMap['Desc'].length >= 1">
                  <tr *ngFor="let val of attMap['Desc'];let i=index">
                    <td colspan="2"></td>
                    <td class="con-left"><span>{{val}}</span></td>
                    <td style="text-align: right;">
                      <span>{{attMap['BasisAmt'][i] | number}}</span>
                    </td>
                    <td style="text-align: right;">
                      <span>{{attMap['FMVAmt'][i] | number}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="descTotalAvl">
                    <td colspan="2"></td>
                    <td> </td>
                    <td align="center">
                      <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                    </td>
                    <td align="center">
                      <hr style="width: 100%; border-top: 1px solid rgba(0,0,0,.5);"/>
                    </td>
                  </tr>
                  <tr *ngIf="descTotalAvl">
                    <td colspan="2"></td>
                    <td class="con-left"><span>Total</span></td>
                    <td style="text-align: right;">
                      <span>{{totalAmt('BasisAmt') | number}}</span>
                    </td>
                    <td style="text-align: right;">
                      <span>{{totalAmt('FMVAmt') | number}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </li>
        </ol>
      </li>
      <li>
        The date and control number of any private letter ruling(s) issued by IRS in connection with the section 332 liquidation:
        <span *ngIf="loaded" class="template-color-blue">{{attMap['LiquidationDate'] == undefined ? '' : attMap['LiquidationDate'][3]}} {{attMap['PLR'] == undefined ? 'None' : (attMap['PLR'][0] == undefined ? 'None' : attMap['PLR'][0]) }}</span>
      </li>
      <li>
        The plan of complete liquidation was adopted on <span *ngIf="loaded" class="template-color-blue">{{attMap['AdoptedDate'] == undefined ? '' : attMap['AdoptedDate'][0]}}</span>
      </li>
      <li>
        The recipient corporation hereby represents that: <span *ngIf="loaded" class="template-color-blue"> {{pickListVal}} </span><br />
        <span *ngIf="liqCompleted; else liqNotComplete">The liquidation was completed on <span *ngIf="loaded" class="template-color-blue">{{attMap['AcquiredDate'] == undefined ? '' : attMap['AcquiredDate'][0]}}</span></span>
        <ng-template #liqNotComplete>
          <span>The liquidation is not complete and the taxpayer has timely filed Form 952, Consent to Extend the Time to Assess Tax Under Section 332(b).</span>
        </ng-template>
        <br />
      </li>
    </ol>
    <div class="supp-info-wrapper">
      <div>Additional Information:</div>
      <textarea rows="5" cols="80" readonly class="txtborder w-100" *ngIf="loaded">{{attMap['stndadditionaltext'] == undefined ? '' : (attMap['stndadditionaltext'][0] ? attMap['stndadditionaltext'][0] : '')}}</textarea>
    </div>

    <table style="width:100%">
      <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs: <span class="template-color-blue"> {{stmtData.primaryleid == null? '': stmtData.primaryleid}} </span> / <span class="template-color-blue"> {{stmtData.secondaryleid == null? '': stmtData.secondaryleid}} </span>
          </td>
          <td class="footer-right" *ngIf="stmtData.branchleid">
            Branch LEID: <span class="template-color-blue"> {{stmtData.branchleid == null? '': stmtData.branchleid}}</span>
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
