
<div class="modal-body">
    <div class="panel-body" > 
                    <div *ngIf= "acctType == '5' || acctType == '6'">
                     <div>
                            <span>Basket Name</span>
                       </div>
                       <div class="p-3 SourcingDropdown">
                           <ap-selector [items]="basketIdList" [type]="'SingleSelector'" [searchMethod]="'inline'" [placeholder]="'** Select Basket Name **'" (onSelectedChange)="setBasketId($event)" >
                           </ap-selector>
                       </div>                       
                       
                    </div>
                    <div *ngIf= "acctType == '7' || acctType == '8'">
                        <div>
                            <span>Apportionment Base</span>
                        </div>
                        <div class="p-3 SourcingDropdown">
                            <ap-selector [items]="baseIdList" [type]="'SingleSelector'" [searchMethod]="'inline'" [placeholder]="'** Select Apportionment Base **'" (onSelectedChange)="setBaseId($event)" >
                            </ap-selector>
                        </div>
                    </div>                   
                    <div *ngIf = "CheckBasketFlag"> 
                        <span *ngIf= "acctType == '7' || acctType == '8'" class="noRecord">
                              Please select an Apportionment Base
                        </span>
                        <span *ngIf= "acctType == '5' || acctType == '6'" class="noRecord">
                            Please select a Basket
                      </span>
                    </div> 
                   
        <div>
            <div class="col-md-4 col-md-offset-8" >
                <div>
                    <ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="saveData()"></ap-button>
                    <ap-button [btnText]="'Reset'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="resetData()"></ap-button>
                    <ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="modalClose()"></ap-button>
                </div>  
            </div>
        </div>
    
    </div>
 </div>

<!-- 
<div class="p-3 a-bg-light custom-selector">
    <ap-selector [items]="searchInlineList" [type]="'SingleSelector'" [searchMethod]="'inline'">
    </ap-selector>
</div> -->
