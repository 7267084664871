import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SourcingProjectTaggingService {

  url ={
		getBaksetIdData : '/loadJsonObject?action_code=rf44cw',
		getProjectIdData : '/loadJsonObject?action_code=s1rn3u',
		saveSourcingTaggingData :'/saveJsonObject?action_code=tetir8',
		getTagModal : '/loadJsonObject?action_code=ccp8d8',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }


  getBaksetIdData(baseURL:string, tax_year: any,scenario:any,jcd_key:any, taggingParam:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getBaksetIdData}`;

		let body = new FormData();
		body.append('tax_year', tax_year.toString());
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcd_key.toString());
		body.append('filing_group', taggingParam.FILING_GROUP);
		body.append('ho_combination_key', taggingParam.HO_COMBINATION_KEY);
		body.append('combination_key', taggingParam.COMBINATION_KEY);
		body.append('acct_key', taggingParam.LOCAL_ACCT_KEY);
		body.append('tp_key', taggingParam.TP_KEY);
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}


	getProjectIdData(baseURL:string, tax_year: any,scenario:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getProjectIdData}`;

		let body = new FormData();
		body.append('tax_year', tax_year.toString());
		body.append('scenario', scenario.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getSourceTaggingModalData(baseURL:string, tax_year: any,scenario:any,jcd_key:any, taggingParam:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getTagModal}`;

		let body = new FormData();
		body.append('tax_year', tax_year.toString());
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcd_key.toString());
		body.append('filing_group', taggingParam.FILING_GROUP);
		body.append('ho_combination_key', taggingParam.HO_COMBINATION_KEY);
		body.append('combination_key', taggingParam.COMBINATION_KEY);
		body.append('acct_key', taggingParam.LOCAL_ACCT_KEY);
		body.append('tp_key', taggingParam.TP_KEY);
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	saveSourcingTaggingData(baseURL : string, jsonData : any, tax_year: any,scenario:any,jcd_key:any,sso_id:any,baseGlobalParams: any, taggingParam:any,checkLockedObj: any) {
		const url = `${baseURL}${this.url.saveSourcingTaggingData}`;
		const options={
		  withCredentials: true
		};
	
		const data:any = {
		"tax_year":tax_year,
		"scenario":scenario,
		"jcd_key":jcd_key,
		"sso_id":sso_id,
		"filing_key": taggingParam.FILING_GROUP,
		"is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		"clob_data": JSON.stringify(jsonData),
		"scenarioCode":baseGlobalParams.scenarioCode,
		"checkLockedbj": checkLockedObj,
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
	
		console.log('formData =', formData);
		console.log('data =', data);
	
	
		console.log('tax_year = ', formData.get("tax_year"));
		console.log('scenario = ', formData.get("scenario"));
		console.log('jcd_key = ', formData.get("jcd_key"));
		console.log('screen_key =', formData.get("screen_key"));
		console.log('sso_id =', formData.get("sso_id"));
		console.log('clob_data = ', formData.get("clob_data"));
	
	
	
		return this.http.post<any>(url, formData,options);
	  }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }

}
