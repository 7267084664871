import { Component, Input, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { EFileService } from '../../efile.service';
import { ApiResponse } from '../../models/api-response.model';
import { Submission } from '../../models/submission.model';
import { SubmissionService } from '../submission.service';

@Component({
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {

  //Properties
  TEST = 'TEST';
  PRODUCTION = 'PRODUCTION';
  btnPingText:string = 'Ping IRS';
  btnSubmit: string = 'Submit';
  btnAck: string = 'Get Acknowledgement';
  envType:string = this.TEST;
  primaryConfig = {
    isPrimary: true
  }
  secondaryConfig = {
    isSecondary: true
  }
  pinging: boolean = false;
  submitting: boolean = false;
  submission: Submission;
  acking: boolean = false;


  constructor(private efileService:EFileService,
    private gtwSnackbarService:GtwSnackbarService,
    private submissionSerivce:SubmissionService) { 
      this.submission = this.submissionSerivce.activeSubmission;
    }
    
    ngOnInit(): void {
  }

  envSelected(envType:string) : void{
    this.envType = envType;
  }

  pingIRS(): void{
    if(this.pinging) return;

    this.btnPingText = '';
    this.pinging = true;
    this.efileService.pingIrsServer(0,this.envType)
    .subscribe((response:ApiResponse) =>{
      this.pinging = false;
      this.btnPingText = 'Ping IRS';
      if(response.callSuccess === "1"){
          this.gtwSnackbarService.openSnackBar("Successfully Pinged IRS Server!","gtw-snackbar--green");
        }else{
          this.gtwSnackbarService.openSnackBar("Failed to Ping to IRS!","gtw-snackbar--red");

        }
    })
  }
  submit(): void{
    if(this.submitting) return;

    this.btnSubmit = '';
    this.submitting = true;
    this.efileService.submitToIRS(0,this.envType,this.submission.SUBMISSION_ID.toString())
    .subscribe((response:ApiResponse) =>{
      this.submitting = false;
      this.btnSubmit = 'Submit';
      if(response.callSuccess === "1"){
          this.gtwSnackbarService.openSnackBar("Successfully Submitted to IRS!","gtw-snackbar--green");
        }else{
          this.gtwSnackbarService.openSnackBar("Failed to Submit to IRS!","gtw-snackbar--red");

        }
    })
  }
  pollAck(): void{
    if(this.acking) return;

    this.btnAck = '';
    this.acking = true;
    this.efileService.pollAck(0,this.submission.SUBMISSION_ID.toString())
    .subscribe((response:ApiResponse) =>{
      this.acking = false;
      this.btnAck = 'Get Acknowledgement';
      if(response.callSuccess === "1"){
          this.gtwSnackbarService.openSnackBar("Successfully fetched Acknowledgement!","gtw-snackbar--green");
        }else{
          this.gtwSnackbarService.openSnackBar("Failed to fetch Acknowledgement!","gtw-snackbar--red");

        }
    })
  }

}
