<ap-loading id="overlay" *ngIf="loader" [size]="'24px'" [colorName]="'a-primary'">
</ap-loading>
<div class="mainContainer">
    <ap-responsive-tab (onTabChange)="onTabChanged($event)">
        <ap-res-tab [label]="'Delete Filing and Sync Mef/Efile Tables'">
            <div class="card m-3">

                <div class="card-body">
                    <div class="a-tab-container a-overflow-visible">
                        <div>
                            <div class="row container-md form-group">
                                <div class="col-md-6">
                                    <ap-checkbox [text]="'TGT_MEF_CONSOL_ENTITIES'"
                                        [disabled]="allCheckboxesDisable || mefTablesDisable"
                                        (onCheck)="clicked($event, 'TGT_MEF_CONSOL_ENTITIES',1)" class="a-mx-10">
                                    </ap-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <ap-checkbox [text]="'TGT_MEF_CONSOL_GROUP'"
                                        [disabled]="!isParent || allCheckboxesDisable || mefTablesDisable"
                                        class="a-mx-10" (onCheck)="clicked($event, 'TGT_MEF_CONSOL_GROUP',2)">
                                    </ap-checkbox>
                                </div>

                            </div>
                            <div class="row container-md form-group">
                                <div class="col-md-6">
                                    <ap-checkbox [text]="'TGT_FILING_ENTITY_ASSIGN'" [disabled]="allCheckboxesDisable"
                                        class="a-mx-10" (onCheck)="clicked($event, 'TGT_FILING_ENTITY_ASSIGN',3)">
                                    </ap-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <ap-checkbox [text]="'TGT_FILING_GROUP'"
                                        [disabled]="!isParent || allCheckboxesDisable" class="a-mx-10"
                                        (onCheck)="clicked($event, 'TGT_FILING_GROUP',4)">
                                    </ap-checkbox>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <!-- <div *ngIf="loader" class="text-center rounded"><span class="a-loading a-primary" style="width: 24px; height: 24px; margin: 2px;"></span></div> -->
            <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

                <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
                    [config]="secondaryConfig" (onBtnClick)="cancel()"></ap-button>
                <div>
                    <ap-button [btnText]="'DELETE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                        (onBtnClick)="deleteMefEfileData()"></ap-button>
                </div>
                <!-- <ap-button [btnText]="'MEF SYNC DELETE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                        (onBtnClick)="sync('MEF_SYNC_DELETE')" [isDisabled]="fgEmpty || !syncButtonsEnabled"></ap-button> -->
                <ap-button [btnText]="'MEF SYNC DELETE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                    (onBtnClick)="openDialog()" [isDisabled]="fgEmpty || !syncButtonsEnabled"></ap-button>
                <ap-button [btnText]="'MEF SYNC'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                    (onBtnClick)="sync('MEF_SYNC')" [isDisabled]="fgEmpty || !syncButtonsEnabled"></ap-button>
                <ap-button [btnText]="'EFILE SYNC'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
                    (onBtnClick)="sync('EFILE_SYNC')" [isDisabled]="fgEmpty || !syncButtonsEnabled"></ap-button>

            </div>
        </ap-res-tab>
        <ap-res-tab [label]="'Insert Filing Data'">
            <div class="card m-3">
                <div class="nestedContainer">
                    <ap-responsive-tab class="nestedTab" [(selectedIndex)]="indexZero">
                        <ap-res-tab [label]="'TGT_FILING_GROUP'">
                            <div class="card-body">
                                <form [formGroup]="formOne" novalidate>
                                    <div class="container">

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'1'" [type]="'input'"
                                                        [label]="'Consol Group Key'" class="not-disabled"
                                                        formControlName="consolGroupKey"
                                                        [isInputError]="formOne.controls.consolGroupKey.status === 'INVALID'"
                                                        [errorMessage]="'This is a required field'">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'2'" [type]="'input'"
                                                        [isDisabled]="disabled" [label]="'Filing Group Name'"
                                                        formControlName="filingGroupName">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'3'" [type]="'input'"
                                                        [label]="'Parent LE Key'" [isDisabled]="disabled"
                                                        formControlName="parentLeKey">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label class="a-form-label" id="label3">Return Date</label>
                                                    <ap-date-picker class="date-content" format="MM/DD/yyyy"
                                                        class="not-disabled" type="singleCalendar" size="medium"
                                                        [minDate]="beginDate" [isTitleLeft]="true"
                                                        formControlName="returnDate" (click)="onDPOpen()">
                                                    </ap-date-picker>
                                                    <div *ngIf="formOne.controls.returnDate.status === 'INVALID'"
                                                        class="a-form-error">This is a required field</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'5'" [type]="'input'"
                                                        class="not-disabled" [label]="'IRS Center Name'"
                                                        formControlName="irsCenterName"
                                                        [isInputError]="formOne.controls.irsCenterName.status === 'INVALID'"
                                                        [errorMessage]="'This is a required field'">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label class="a-form-label" id="label3">Filing Tax Year
                                                        Begin</label>
                                                    <ap-date-picker class="date-content" format="MM/DD/yyyy"
                                                        class="not-disabled" type="singleCalendar" size="medium"
                                                        [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true"
                                                        formControlName="filterTaxYearBegin" (click)="onDPOpen()">
                                                    </ap-date-picker>
                                                    <div *ngIf="formOne.controls.filterTaxYearBegin.status === 'INVALID'"
                                                        class="a-form-error">This is a required field</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label class="a-form-label" id="label3">Filing Tax Year End</label>
                                                    <ap-date-picker class="date-content" format="MM/DD/yyyy"
                                                        type="singleCalendar" size="medium" [minDate]="beginDate"
                                                        [maxDate]="endDate" [isTitleLeft]="true" class="not-disabled"
                                                        formControlName="filterTaxYearEnd" (click)="onDPOpen()">
                                                    </ap-date-picker>
                                                    <div *ngIf="formOne.controls.filterTaxYearEnd.status === 'INVALID'"
                                                        class="a-form-error">This is a required field</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

                                <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray"
                                    [btnType]="'a-btn-gray'" [config]="secondaryConfig" (onBtnClick)="cancel()">
                                </ap-button>
                                <div>
                                    <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent"
                                        [config]="secondaryConfig" [isDisabled]="disableSave"
                                        (onBtnClick)="insertFilingData()"></ap-button>
                                </div>

                            </div>
                        </ap-res-tab>
                        <ap-res-tab [label]="'TGT_FILING_ENTITY_ASSIGN'">

                            <div class="card-body">
                                <form [formGroup]="formTwo" novalidate>
                                    <div class="container">

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'5'" [type]="'input'"
                                                        [label]="'Filing Group Key'" class="not-disabled"
                                                        formControlName="filingGroupKey"
                                                        [isInputError]="formTwo.controls.filingGroupKey.status === 'INVALID'"
                                                        [errorMessage]="'This is a required field'">
                                                    </ap-form-field>

                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'1'" [type]="'input'"
                                                        [label]="'LE Key'" formControlName="leKey"
                                                        [isDisabled]="disabled">
                                                    </ap-form-field>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label class="a-form-label" id="label3">Date Filed</label>
                                                    <ap-date-picker class="date-content" format="MM/DD/yyyy"
                                                        type="singleCalendar" size="medium" [minDate]="beginDate"
                                                        [maxDate]="endDate" [isTitleLeft]="true" class="not-disabled"
                                                        formControlName="dateFiled" (click)="onDPOpen()">
                                                    </ap-date-picker>
                                                    <div *ngIf="formTwo.controls.dateFiled.status === 'INVALID'"
                                                        class="a-form-error">This is a required field</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'2'" [type]="'input'"
                                                        [isDisabled]="disabled" [label]="'Lock Y N'"
                                                        formControlName="lockYN">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'5'" [type]="'input'"
                                                        [label]="'FGN Dom Flag'" [isDisabled]="disabled"
                                                        formControlName="fgnDomFlag">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'5'" [type]="'input'"
                                                        [label]="'LE Consol Type Flag'" class="not-disabled"
                                                        formControlName="leConsolTypeFlag">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <ap-form-field [inlineBlock]="true" [id]="'2'" [type]="'input'"
                                                        [isDisabled]="disabled" [label]="'Filer Status'"
                                                        formControlName="filerStatus">
                                                    </ap-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

                                <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray"
                                    [btnType]="'a-btn-gray'" [config]="secondaryConfig" (onBtnClick)="cancel()">
                                </ap-button>
                                <div>
                                    <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent"
                                        [config]="secondaryConfig" (onBtnClick)="insertEntityAssignData()"></ap-button>
                                </div>

                            </div>

                        </ap-res-tab>
                    </ap-responsive-tab>
                </div>

            </div>

        </ap-res-tab>
    </ap-responsive-tab>
</div>