<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form193" class="ap-form-field-my-wrapper">
    <dst-template-header [parentFormGroup]="form193" [overwriteFormName]="'STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS'" (formOptionChanged)="onFormOptionChanged($event)"
      (consolGroupChanged)="OnConsolGroupChanged($event)">
    </dst-template-header>
    <div formGroupName="main" class="section-content ap-form-field-my-wrapper">

      <div class="">
        Parties:
      </div>

      <dst-template-leid-selector [label]="'Please specify the LEID of the Transferor'" [labelId]="'100'"
        (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field entity-label">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
        Please specify a LEID of transferor
      </div> -->
      <div class="">
        <span class="template-color-blue">{{!transferor ? '' : transferor.ENTITY + ' '}}</span><span>EIN: </span><span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ? transferor.EIN : 'N/A')}}</span><span>&nbsp;a </span><span class="template-color-blue">{{!transferor ? '' : transferor.COUNTRYINC + (transferor | taxtype: transferor?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>;</span>
      </div>
      <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee'" [labelId]="'100'"
        (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field entity-label">
      </dst-template-leid-selector>
      <!-- <div class="a-form-error error-leid" *ngIf="transferee === undefined">
        Please specify a LEID of Transferee
      </div> -->
      <div class="">
        <span class="template-color-blue">{{!transferee ? '' : transferee.ENTITY + ' '}}</span><span>EIN: </span><span class="template-color-blue">{{!transferee ? '' : (transferee.EIN ? transferee.EIN : 'N/A')}}</span><span>&nbsp;a </span><span class="template-color-blue">{{!transferee ? '' : transferee.COUNTRYINC + (transferee | taxtype: transferee?.TAXTYPECODE: entityNameChangeOnDate)}}</span><span>;</span>
      </div>
      <br>

      <ol class="section-content">
        <li>
          The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the "Code")
          applies.
        </li>
        <li>
          <div>The following is a description of the exchange:</div>
          <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" formControlName="PointII"
            [(ngModel)]="PointII">
          </ap-form-field>
        </li>
        <li>
          <div>The following is a description of any stock, securities, or other consideration transferred or received
            in the exchange:</div>
          <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" formControlName="PointIII"
            [(ngModel)]="PointIII">
          </ap-form-field>
        </li>
        <li>
          <div>The following describes any amount(s) required under the section 367(b) regulations to be taken into
            account as income or loss or as an adjustment (including an adjustment under Section 1.367(b)-7 or
            1.367(b)-9, as applicable) to basis, earnings and profits, or other tax attributes as a result of the
            exchange:</div>
          <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'" formControlName="PointIV"
            [(ngModel)]="PointIV">
          </ap-form-field>
        </li>
        <li>
          For information required pursuant to regulations under Code Section 368, see the separate statement.
        </li>
        <li>
          Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046, or
          the regulations under those sections has been provided with this tax return on Form 5471, Information Return
          of U.S. Persons with Respect to Certain Foreign Corporations.
        </li>
        <li>
          Not applicable.
        </li>
      </ol>
      <dst-template-leid-selector [isBranchEntity]="true" [label]="'Please specify the LEID of the Branch Entity:'"
        [labelId]="'3'" (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
      </dst-template-leid-selector>
      <div class="entity-details">
        <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' :
          branchEntity.ENTITY}}</span>
      </div>
      <div class="d-flex" style="margin-top: 15px;">
        <span>LEIDs: </span>
        <div>
          <span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span><span *ngIf="transferee">
            / </span><span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
