<!-- else loading -->
<mat-progress-bar *ngIf="isLoading"
        color="primary"
        mode="indeterminate">
</mat-progress-bar>

<div *ngIf="merged$ | async as response">
    <div class="wrapper" *ngFor="let video of response.data ; index as i">
        <gtw-screen-video-item (click)="openVideoDialog(video)" [video]=video></gtw-screen-video-item>
        <div class="overlay" *ngIf="isValidUser(video.sso_id)">
            <mat-card>
                <div class="edit-buttons">
                    <button mat-icon-button aria-label="Update button" (click)="updateScreenVideo(video)">
                        <mat-icon>create</mat-icon>
                    </button>
                </div>
                <div class="edit-buttons">
                    <button mat-icon-button aria-label="Delete button" (click)="confirmDelete(video.video_id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="noVideos" *ngIf="response.callSuccess == 1 && response.data.length == 0">
        No help videos.
    </div>
    <div *ngIf="response.callSuccess == 0">
        {{response.errorMessage}}
    </div>
</div>
