import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { SourcingDeleteRecordsService } from './sourcing-delete-records.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";

@Component({
  selector: 'gtw-sourcing-delete-records',
  templateUrl: './sourcing-delete-records.component.html',
  styleUrls: ['./sourcing-delete-records.component.scss']
})
export class SourcingDeleteRecordsComponent implements OnInit {

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private checkLockedObj: any = [];
  private deletePramObj: any = [];
  private scenarioCode: string = '';

  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('delete-param-obj')
  set _deletePramObj(deletePramObj:any){
    this.deletePramObj = deletePramObj;
    this.init();
  }


  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    this.init();
    }catch(error){
		console.log("Not an valid object");
		}
  }
  
  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }

  @Output('open-sourcing-delete-info')
  openModalInfo = new EventEmitter<string>();

  @Output('save-sourcing-delete-info')
  deleteModalInfo = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;

  constructor(private SourcingDeleteRecordsService:SourcingDeleteRecordsService ,private _snackBar: MatSnackBar) {
  }

  
  checkInputType():boolean{
		if(Number.isInteger(parseInt(this.taxYear+"")) &&
		  Number.isInteger(parseInt(this.scenario+'')) &&
		  Number.isInteger(parseInt(this.jcdKey +'')) &&
		  Number.isInteger(parseInt(this.screenKey+'')) &&
		  Number.isInteger(parseInt(this.ssoId+""))&&
      this.deletePramObj
		  )
		{
		  return true;
		}
	
		return false;
	}


  ngOnInit(): void {
  }

  init() {   
    if(this.checkInputType()){
      }
    } 


    modalClose() {
      console.log("click close");
      this.openModalInfo.emit('open-sourcing-delete-info');
      }


  deleteData(){
      let tempData = {
        TAX_YEAR : this.baseGlobalParams?.tax_year,
        SCENARIO : this.baseGlobalParams?.scenario,
        JCD_KEY : this.baseGlobalParams?.jcd_key,
        SSO_ID: this.ssoId,
        DEL_PARAMS: this.deletePramObj
      }

     let updateSubscription = this.SourcingDeleteRecordsService.deleteSourcingData(this.baseURLs.api, tempData, this.baseGlobalParams, this.checkLockedObj).subscribe(
           (data) => {
             if (data.callSuccess == "1") {
               this.SourcingDeleteRecordsService.openSnackBar('Sourcing Data deleted successfully','gtw-snackbar--green');
               this.deleteModalInfo.emit('save-sourcing-delete-info');
             } else {
               this.SourcingDeleteRecordsService.openSnackBar('Error Deleting Sourcing Data. '+data.errorMessage, 'gtw-snackbar--red');
             }
           },  error => { this.SourcingDeleteRecordsService.openSnackBar('Error Deleting Data. '+error.error.errorMessage, 'gtw-snackbar--red'); },
           () => { console.log("Update Completed");}
         );
           this.subscriptions.add(updateSubscription);
   };

}
