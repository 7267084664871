import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestAccessButtonService {
  private URLs = {
    screenRequestAccess : '/getScreenAccessRequest',
  };
  constructor(private httpClient: HttpClient) { }

  sendRequestAccess(baseURL:string,client_key:number,screen_key:string,activity_key:string){
    
    

    const params = new HttpParams()
         .set('client_key',client_key.toString())
         .set('screenKey',screen_key.toString())
         .set('activityKey',activity_key.toString());
    
    const options = {withCredentials: true,params};
    
    const url = `${baseURL}${this.URLs.screenRequestAccess}`;
    
    return this.httpClient.get(url,options).pipe(
      map((data: any) => { return data; }
    ));

  }
}
