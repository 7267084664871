<div (gtwClickOutside)="closeMenuPanel($event)">
    <div class="nav-panel a-navigation">
        <gtw-tasks 
            [app_navigation]="appNavigation" 
            (clickDomain)="showTasksAndCategories($event)"
            (load-screens)="showScreens($event)">
        </gtw-tasks>
    </div>
    
    <div class="nav-panel sub-task-panel" *ngIf="!isMenuPanelClosed">    
        <div class="a-bg-light menuSearch">
            <span> {{domainName}} </span>
        
            <div class="a-search-wrapper col-12 no-gutters searchContainer" style="width: calc(100% - 100px);">
                <div class="">
                    <div class="a-input-with-icon a-input-md a-lf">
                        <input aria-label="Search" class="a-text-input" placeholder="Search"
                                (input)="filterScreen($event, 'tax-type')">
                        <span class="a-icon appkiticon icon-search-outline"></span>
                    </div>
                </div>
            </div>
            <div class="a-logout show" (gtwClickOutside)="menuSettings = false;">
                <span class="appkiticon icon-toggle-outline settingDropdown" (click)="menuSettings = true"></span>
                <div class="dropdown-menu dropdown-menu-right a-shadow-sm border-0 a-user-setting-panel-one"
                    [ngClass]="{'show':menuSettings}">
                    <div class="dropdown-title a-font-12">
                        Menu Settings
                    </div>
                    <div class="user-logout">
                        <ap-toggle (onCheck)="switch($event)" class="a-m-3 a-toggle a-toggle-small" [checked]="mSetting.showScreen">
                            Show Screen
                        </ap-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-scroll-container">
            <div class="row mt-2">
                <div class="col-6">
                </div>
                <div class="col-6">
                    <div class="a-font-12 font-weight-small text-truncate expand-collapse"
                        (click)="toggleAccordion(1)">
                        Expand All / Collapse All
                    </div>
                </div>
            </div>
            <div class="custom-scroll">
                <gtw-sub-tasks-container *ngIf="isAccordion1Open"
                    (showScreensEvent)="showScreens($event)" 
                    [task-list]="taskList" 
                    [tax-type-name]="domainName" 
                    [pannel-position]=1
                    [is-accordion-open]="isAccordion1Open"
                    (panelClose)="closeMenuPanel($event)" 
                    [m-setting]="mSetting">
                </gtw-sub-tasks-container>

                <gtw-sub-tasks-container *ngIf="!isAccordion1Open"
                    (showScreensEvent)="showScreens($event)" 
                    [task-list]="taskList" 
                    [tax-type-name]="domainName" 
                    [pannel-position]=1
                    [is-accordion-open]="isAccordion1Open"
                    (panelClose)="closeMenuPanel($event)" 
                    [m-setting]="mSetting">
                </gtw-sub-tasks-container>
            </div>
            
        </div>      
    </div>    
    
    <div class="nav-panel screen-panel" *ngIf="!isMenuPanelClosed">   
        <div class="a-bg-light menuSearch">
            <span> Categories </span>
        
            <div class="a-search-wrapper col-12 no-gutters searchContainer" style="width: calc(100% - 100px);">
                <div class="">
                    <div class="a-input-with-icon a-input-md a-lf">
                        <input aria-label="Search" class="a-text-input" placeholder="Search" 
                                (input)="filterScreen($event, 'categories')">
                        <span class="a-icon appkiticon icon-search-outline"></span>
                    </div>
                </div>
            </div>
        
            <span class="appkiticon icon-circle-delete-outline cancelPanel" 
                  (click)="closeMenuPanel($event);">
            </span>
        </div>
        <div class="custom-scroll-container">
            <div class="row mt-2">
                <div class="col-5">
                </div>
                <div class="col-7">
                    <div class="a-font-12 font-weight-small text-truncate expand-collapse"
                        (click)="toggleAccordion(2)" >
                        Expand All / Collapse All
                    </div>
                </div>
            </div>
            <div class="custom-scroll"> 
                <gtw-sub-tasks-container *ngIf="isAccordion2Open"
                    (showScreensEvent)="showScreens($event)" 
                    [task-list]="categoriesList" 
                    [tax-type-name]="'Categories'" 
                    [pannel-position]=2
                    [is-accordion-open]="isAccordion2Open"
                    (panelClose)="closeMenuPanel($event)">
                </gtw-sub-tasks-container>
                <gtw-sub-tasks-container *ngIf="!isAccordion2Open"
                    (showScreensEvent)="showScreens($event)" 
                    [task-list]="categoriesList" 
                    [tax-type-name]="'Categories'" 
                    [pannel-position]=2
                    [is-accordion-open]="isAccordion2Open"
                    (panelClose)="closeMenuPanel($event)">
                </gtw-sub-tasks-container>
            </div>
        </div>
    </div>
</div>



