import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class F8865SchM2K1SourcingService {

  durationInSeconds= 5;

  private getF8865M2K1SourcingData = '/loadJsonObject?action_code=3w9uby';
  private getIssueDropdown = '/loadJsonObject?action_code=t748jd';
  private saveSourcingData = '/saveJsonObject?action_code=nt14vr';

  constructor(private http:HttpClient, private _snackBar: MatSnackBar) { }

  getF8865M2K1SourcingList(baseURL:string, taxYear:number, scenario:number, jcdKey:number, screenKey:number, hoCombinationKey:number, acctType:string, ssoId:string ) {
    const options = {withCredentials: true};

    const url = `${baseURL}${this.getF8865M2K1SourcingData}&tax_year=${taxYear}&scenario=${scenario}&jcd_key=${jcdKey}&screen_key=${screenKey}&ho_combination_key=${hoCombinationKey}&acct_type=${acctType}&sso_id=${ssoId}`;

    console.log('url = ', url);

    return this.http.get(url, options).pipe(
      map((res: any) => {
          if (res.callSuccess == '1') {
            console.log('callSuccess:: getF8865M2K1SourcingList() ' , res.jsonObject);
            return res.jsonObject;
          }
          this.openSnackBar('Unable to fetch data.', 'gtw-snackbar--red');
          throwError(new Error('oops!'));
          return null;
        }
      ));
  }

  getIssueList(baseURL:string, taxYear:number, scenario:number, jcdKey:number) {
    const options = {withCredentials: true};
    const url = `${baseURL}${this.getIssueDropdown}&tax_year=${taxYear}&scenario=${scenario}&jcd_key=${jcdKey}`;
    console.log('url = ', url);
    return this.http.get(url, options).pipe(
      map((res: any) => {
          if (res.callSuccess == '1') {
            console.log('callSuccess:: getIssueList() ' , res.jsonObject);
            return res.jsonObject;
          }
          this.openSnackBar('Unable to fetch data.', 'gtw-snackbar--red');
          throwError(new Error('oops!'));
          return null;
        }
      ));
  }

  saveF8865M2K1SourcingData(baseURL:string, taxYear:number, scenario:number, jcdKey:number, screenKey:number, ssoId:string, scenarioCode: string, isIssueKeyEnabled: string,checkLockedObj: any, clobSettings:any, clobData:any) {
    const url = `${baseURL}${this.saveSourcingData}`;
    const options={
      withCredentials: true
    };

    const data:any = {
      "tax_year":taxYear,
      "scenario":scenario,
      "jcd_key":jcdKey,
      "screen_key":screenKey,
      "sso_id":ssoId,
      "checkLockedbj": checkLockedObj,
      "is_issue_key_enabled":isIssueKeyEnabled,
      "scenarioCode":scenarioCode,
      "jsonSettings":JSON.stringify(clobSettings),
      "jsonObj": JSON.stringify(clobData)
    };

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    console.log('formData =', formData);
    console.log('data =', data);


    console.log('tax_year = ', formData.get("tax_year"));
    console.log('scenario = ', formData.get("scenario"));
    console.log('jcd_key = ', formData.get("jcd_key"));
    console.log('screen_key =', formData.get("screen_key"));
    console.log('sso_id =', formData.get("sso_id"));
    console.log('checkLockedObj = ', formData.get("checkLockedbj"));
    console.log('jsonSettings =', formData.get("jsonSettings"));
    console.log('jsonObj = ', formData.get("jsonObj"));



    return this.http.post<any>(url, formData,options);
  }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }
}
