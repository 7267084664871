
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '../../../shared/_services/action.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

interface Category {
  CATEGORY: string;
  CBC_ID: string;
  ID: string;
  PIVOT_MAP: string;
}
interface GtwInput {
  baseUrl: string;
  ssoId: string;
  clientKey: number;
  type: 'add' | 'delete'
  data: Category;
}
@Component({
  selector: 'gtw-cbc-beps-categories',
  templateUrl: './beps-categories.component.html'
})
export class BepsCategoriesComponent implements OnInit {

  @Input('gtw-input')
  set gtwInputStr(input: string) {
    try {
      this.gtwInput = JSON.parse(input);
    } catch (e) {
      console.error('Error parsing gtwInput', e);
    }
  };

  gtwInput!: GtwInput;

  categoriesForm!: FormGroup;

  @Output('close-modal')
  closeModal = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private actionService: ActionService,
    private alertService: GtwSnackbarService
  ) { }

  ngOnInit(): void {
    this.categoriesForm = this.formBuilder.group({
      category: ['', Validators.required],
      cbc_id: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.categoriesForm.valid) {
      const params = new HttpParams({
        fromObject: this.categoriesForm.value
      });
      this.actionService.saveActionData(this.gtwInput.baseUrl, 33281, this.gtwInput.clientKey, params).subscribe((response: any) => {

        if (response.callSuccess === "1") {
          this.alertService.openSnackBar("Data saved successfully", "gtw-snackbar--green");
        } else {
          this.alertService.openSnackBar("Failed to save data", "gtw-snackbar--red");
        }
        this.cancel();
      });
    }
  }

  delete() {

    const params = new HttpParams({
      fromObject: {
        id: this.gtwInput.data.ID
      }
    });
    this.actionService.saveActionData(this.gtwInput.baseUrl, 33282, this.gtwInput.clientKey, params).subscribe((response: any) => {

      if (response.callSuccess === "1") {
        this.alertService.openSnackBar("Data deleted successfully", "gtw-snackbar--green");
      } else {
        this.alertService.openSnackBar("Failed to delete data", "gtw-snackbar--red");
      }
      this.cancel();
    });
  }

  cancel() {
    this.closeModal.emit();
  }
}
