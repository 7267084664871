import { Injectable } from '@angular/core';
import 'rxjs/operators'
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface Message {
  type: string;
  statementId: string;
  payload: any;
}

interface TabEventSubscription {
  type: string;
  subscription: Subscription;
}

type MessageCallback = (payload: any) => void;

@Injectable({
  providedIn:'root'
})
export class MessageService {
  private handler = new Subject<Message>();
  private observables : { [statementId:string]:TabEventSubscription[]} = {};

  broadcast(statementId: string, type: string, payload: any = {}) {
    console.debug("message broadcast--->",{ statementId, type, payload });
    this.handler.next({ statementId, type, payload });
  }

  subscribe(statementId: string, type: string, callback: MessageCallback): void {
    console.debug("subscribed--->",{ statementId, type });
    this.observables[statementId] = this.observables[statementId] || [];

    let tabSub = this.observables[statementId].find(e=>e.type === type);
    if(tabSub) return;

    const subscription: Subscription  = this.handler
    .pipe(
      filter(message => message.type === type && message.statementId === statementId),
      map(message => message.payload) 
    ).subscribe(callback);

    const tabEventSubscription = {
      subscription,
      type
    } as TabEventSubscription;
    
    
    this.observables[statementId].push(tabEventSubscription);

  }

  unsubscribe(statementId: string, type: string){
    console.debug("unsubscribed--->",{ statementId, type });
    let tabSub = this.observables[statementId].find(e=>e.type === type);
    if(!tabSub) return;

    tabSub.subscription.unsubscribe();
    this.observables[statementId].splice(this.observables[statementId].indexOf(tabSub),1);

    console.debug("remaining subscriptions for statement "+statementId + "--->", this.observables[statementId]);
  }
}
