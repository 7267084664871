import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild,ElementRef, ViewChildren } from '@angular/core';
import { SummaryDiagnosticService } from '../summary-diagnostic.service';
import _ from 'lodash';

@Component({
  selector: 'gtw-kpi-box',
  templateUrl: './kpi-box.component.html',
  styleUrls: ['./kpi-box.component.scss']
})
export class KpiBoxComponent implements AfterViewInit {

  @Input("label")
  label !:string;

  @Input("amount")
  amount : number = 0;
 

  @Input("icon")
  icon !:string;
  @Input('kpi-data')
  kpi_data:any

  @Input('kpiFilter')
  kpiFilter:any;

  @ViewChildren('Kpi')
  kpiQueryList!: ElementRef

  constructor(private summaryDiagnosticService: SummaryDiagnosticService) { 
    console.log('euqere',this.kpiQueryList);
    
  }
  ngAfterViewInit(): void {

  }
    
  }
