import { TableTitle } from "../models/table.model";

let tablePageDropdownList3 = [
    {
      data:[
        {
          value: 5,
          text: '5 / Page'
        },
        {
          value: 7,
          text: '7 / Page'
        },
        {
          value: 10,
          text: '10 / Page'
        },
      ]
    }
  ];
export let taxYears = [
    {
      data:[
        {
          value: 2021,
          text: '2021'
        },
        {
          value: 2020,
          text: '2020'
        },
        {
          value: 2019,
          text: '2019'
        }
      ]
    }
  ];
 
  export let tableColumnConfig: TableTitle[] = [
    {
      title:'',
      colHeader: 'SID',
      sort:{
        sortBy: 'SUBMISSION_ID',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      colHeader: 'Return Type',
      sort:{
        sortBy: 'RETURN_TYPE',
        descendant: true,
        defaultSorted: false
      }
    },
    // {
      // title:'',
      //   colHeader: 'Filer Type',
      
      // },
      {
      title:'',
      colHeader: 'Tax Payer Name',
      sort:{
        sortBy: 'TAX_PAYER_NAME',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      colHeader: 'Tax Payer',
      sort:{
        sortBy: 'TAX_PAYER',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      colHeader: 'Entity Count',
      sort:{
        sortBy: 'ENTITY_COUNT',
        descendant: true,
        defaultSorted: false
      }
    },
    
    // {
    //   title:'',
    //   colHeader: 'Diagnostics',
    // },
    {
      title:'Extension Details',
      colSpan:4,
      colHeader: 'Created Date',
      sort:{
        sortBy: 'E_CREATED_TS',
        descendant: true,
        defaultSorted: false
      }
      
    },
    {
      title:'Extension Details',
      hidden:true,
      colHeader: 'Error Count',
      sort:{
        sortBy: 'EXTENSION_ERROR_COUNT',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      hidden:true,
      colHeader: 'Status',
      sort:{
        sortBy: 'E_SUBMISSION_STATUS',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      hidden:true,
      colHeader: 'Submitted By',
      sort:{
        sortBy: 'E_CREATED_BY',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'Submission Details',
      colSpan:4,
      colHeader: 'Created Date',
      sort:{
        sortBy: 'CREATED_TS',
        descendant: true,
        defaultSorted: false
      }
      
    },
    {
      title:'',
      hidden:true,
      colHeader: 'Error Count',
      sort:{
        sortBy: 'RETURN_ERROR_COUNT',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      hidden:true,
      colHeader: 'Status',
      sort:{
        sortBy: 'SUBMISSION_STATUS',
        descendant: true,
        defaultSorted: false
      }
    },
    {
      title:'',
      hidden:true,
      colHeader: 'Submitted By',
      sort:{
        sortBy: 'CREATED_BY',
        descendant: true,
        defaultSorted: false
      }
    }
  ];