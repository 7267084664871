import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TrackerTabsService, TrackerTabState} from '../../../tracker-tabs.service';
import {DSTSharedService} from '../../../../shared/dst-shared.service';
import { CreateDisclosureSharedService } from '../create-disclosure-shared.service';
import { MessageService } from '../../../../shared/services/message.service';
import { Observable } from 'rxjs';
import { StatementTrackerService } from '../../../statement-tracker.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { FormReivewPdf } from 'projects/gtw-elements/src/app/shared/components/form-review-pdf/form-review-pdf.service';
import {ModalComponent} from 'appkit-angular/modal';
import { Location } from '@angular/common';
import { S3FileUploadInput } from 'projects/gtw-elements/src/app/admin/components/s3-file-upload/s3-file-upload.component';
import { ActionService } from 'projects/gtw-elements/src/app/shared/_services/action.service';
import { S3FileUploadService } from 'projects/gtw-elements/src/app/admin/components/s3-file-upload/s3-file-upload.service';


@Component({
  selector: 'dst-create-disclosure-actions',
  templateUrl: './create-disclosure-actions.component.html',
  styleUrls: ['./create-disclosure-actions.component.scss']
})
export class CreateDisclosureActionsComponent implements OnInit, OnChanges {

  @Input() statementId: number | undefined = 0;
  @Input() isView!: boolean;
  @Input() disabled!: boolean;
  @Input() disableAll!: boolean;
  @Input() noPlaceholder!: boolean;
  @Input() displayNotification: boolean = false;
  @Input() notifMsg!: string;
  @Input() disableS3Upload: boolean = true;
  @Input() disabledS3: boolean = false;
  @Input() hideBackBtn: boolean = true;
  @Input() isFiled: string | undefined;
  @Input() isQAR: string | undefined;
  @Output() onSaveClick: EventEmitter<null>;
  @Output() onCancelClick: EventEmitter<null>;
  @Output() onS3UploadClick: EventEmitter<null>;
  @Output() onBackClick: EventEmitter<null>;

  disablePlaceholderForChildStatements!: boolean;
  isPlaceholderChecked!: boolean;
  saveEditBtnText: string = 'Save';
  cancelCloseBtnText: string = 'Cancel';
  trackerTab!: TrackerTabState | null;
  loadingPdf: boolean = false;
  loadingSS: boolean = false;
  showPDFViewerModal!: boolean;
  @ViewChild('pdfViewerModal') pdfViewerModal!: ModalComponent;
  @ViewChild('uploadModal') uploadModal!: ModalComponent;
  dstProformaData!: FormReivewPdf;
  stmtDetailsLoaded: boolean = false;
  private readonly NOT_FILED_STATUS_MSG = 'THIS STATEMENT WILL BE FILED WITH THE TAX RETURN';
  private readonly FILED_STATUS_MSG = 'THIS STATEMENT WAS FILED WITH THE TAX RETURN';
  private readonly NOT_FILED_QAR_STATUS_MSG = 'THE TAX RETURN HAS ALREADY BEEN FILED. THIS STATEMENT WILL BE FILED WITH THE QAR';
  private readonly FILED_QAR_STATUS_MSG = 'THIS STATEMENT WAS FILED QAR';
  filedStatusTxt: string = '';
  s3FileUploadInput: S3FileUploadInput | string = '';
  secondaryAttachments$: Observable<any>;
  uploadTabIndex: number = 0;
  isDownloading: boolean = false;
  isDeleting: boolean = false;
  showUploadModal: boolean = false;
  ssoId: string = '';

  constructor(public dstSharedService: DSTSharedService,
              private trackerTabsService: TrackerTabsService,
              private createDisclosureSharedService: CreateDisclosureSharedService,
              private statementTrackerService: StatementTrackerService,
              private messageService: MessageService,
              private location: Location,
              private actionService: ActionService,
              private s3Service: S3FileUploadService) {
    this.onSaveClick = new EventEmitter<null>();
    this.onCancelClick = new EventEmitter<null>();
    this.onS3UploadClick = new EventEmitter<null>();
    this.onBackClick = new EventEmitter<null>();
    this.ssoId = this.dstSharedService.user.ssoId;

    this.secondaryAttachments$ = this.createDisclosureSharedService.getSecondaryAttachments();

  }


  ngOnInit(): void {
    this.saveEditBtnText = this.isView ? 'Edit' : 'Save';
    this.cancelCloseBtnText = this.isView ? 'Close' : 'Cancel';
    this.trackerTab = this.trackerTabsService.getActiveTrackerTab();
    if (this.trackerTab) {
      this.disablePlaceholderForChildStatements = this.trackerTab.activeTabIndex > 0;
      this.isPlaceholderChecked = this.trackerTab.isPlaceholderChecked;
    }
    if (!this.dstSharedService.s3uploadButtonAccessUsers.includes(this.dstSharedService.user.ssoId)) { //disable S3 upload button
      this.disableS3Upload = true;
    }
    setTimeout(()=>{
      if(this.isCreateNew() &&
          this.createDisclosureSharedService.getActiveIndex() > 0 && !this.isView){
        this.messageService.broadcast(this.trackerTabsService.getActiveTrackerTab()?.id!,"is-child-statement-edit",true);
      }
    },500);

    this.s3FileUploadInput = {
      rootFolder: '',
      baseUrl: this.dstSharedService.baseURLs.admin,
      clientKey: this.dstSharedService.clientKey.toString(),
      path:`/gtw/dst/${this.dstSharedService.clientKey}/secondaryAttachments/${this.trackerTabsService.getActiveTrackerTab()?.id}`,
      readOnlyS3Paths: '...',
      canEditPath:false,
      onlyCore:false,
      hidePath: true
    };

  }

  openAttachmentModal(id:string) {

    if(this.statementId)
      this.createDisclosureSharedService.loadSecondaryAttachments(this.statementId?.toString());

    this.showUploadModal = true;
    setTimeout(() => {
      this.uploadModal.showMore(id);
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="show-upload-modal"]');
      modalEle?.classList.add('gtw-web-components');
    });
  }

  onFileUpload(uploadResponseString: string){

    if(!uploadResponseString) return;

    const uploadResponse:{ fileName: string, callSuccess: string, filePath: string} = JSON.parse(uploadResponseString);
    const pathChunks = uploadResponse.filePath.split("/");

    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id':this.statementId?.toString()!,
        'file_key':pathChunks[pathChunks.length - 1]
      }
    });

    this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 33307, this.dstSharedService.clientKey, params)
      .subscribe((response) => {
          if(response.callSuccess === '1'){
            this.dstSharedService.showAlert('success', 'File Uploaded Successfully!');
            this.createDisclosureSharedService.loadSecondaryAttachments(this.statementId?.toString()!);
            this.uploadTabIndex = 0;
          }
          else{
            this.dstSharedService.showAlert('error', 'Something went wrong');
          }
      });
  }

  downloadFileFromS3(file: any) {
    file.isDownloading = true;
    this.s3Service
      .downloadFile(
        file.FILE_KEY,
        this.dstSharedService.clientKey.toString(),
        this.dstSharedService.baseURLs.admin
      ) .subscribe(()=>{
            file.isDownloading = false;
      });
  }

  deactivateAttachment(file: any){

    file.isDeleting = true;
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'attachment_id':file.STATEMENT_ATTACHMENT_ID.toString(),
      }
    });

    this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 33308, this.dstSharedService.clientKey, params)
      .subscribe((response) => {
        if(response.callSuccess === '1'){
          this.dstSharedService.showAlert('success', 'File Deleted Successfully!');
          this.createDisclosureSharedService.loadSecondaryAttachments(this.statementId?.toString()!);
        }
        else{
          this.dstSharedService.showAlert('error', 'Something went wrong');
        }
        file.isDeleting = false;
      });
  }

  searchAttachments(searchText:string){
    this.createDisclosureSharedService.searchAttachments(searchText);
  }

  closeUploadModal(id: string){
    this.uploadModal.closeModalOk(id);
    setTimeout(() => {
      this.showUploadModal = false;
    });
  }


  private isCreateNew() {
    return this.trackerTabsService.getActiveTrackerTab()?.id !== this.createDisclosureSharedService.getActiveFormState().statementId &&
            this.trackerTabsService.getActiveTrackerTab()?.id !== this.createDisclosureSharedService.getActiveFormState().formData?.parentstatementid?.toString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // expand Appkit notification by default
    if (changes.displayNotification?.currentValue) {
      setTimeout(() => {
        const notifExpandIconEle: HTMLElement | null = document.querySelector('#dre-msg > div.a-notification-show span.a-expand-toggle');
        notifExpandIconEle?.click();
      }, 300);
    }

    if(changes.isFiled?.currentValue !== null && changes.isFiled?.currentValue !== undefined){
      if(!this.isFiled && !this.isQAR){
        this.filedStatusTxt = this.NOT_FILED_STATUS_MSG;
      }
      if(this.isFiled && !this.isQAR){
        this.filedStatusTxt = this.FILED_STATUS_MSG;
      }
      if(!this.isFiled && this.isQAR){
        this.filedStatusTxt = this.NOT_FILED_QAR_STATUS_MSG;
      }
      if(this.isFiled && this.isQAR){
        this.filedStatusTxt = this.FILED_QAR_STATUS_MSG;
      }
    }
  }

  cancel(): void {
    if(this.isCreateNew() &&
      this.createDisclosureSharedService.getActiveIndex() > 0 &&
      !this.createDisclosureSharedService.getActiveFormState().isView){
      //show confirmation popup
      this.messageService.broadcast(this.trackerTabsService.getActiveTrackerTab()?.id!,"show-data-loss-confirmation","close");

      return;
    }
    if(this.createDisclosureSharedService.getActiveIndex() > 0)
      this.messageService.broadcast(this.trackerTabsService.getActiveTrackerTab()?.id!,"is-child-statement-edit",false);
    this.onCancelClick.emit();
  }

  save(): void {
    if(this.isCreateNew() &&
        this.createDisclosureSharedService.getActiveIndex() > 0 && !this.isView){
      this.messageService.broadcast(this.trackerTabsService.getActiveTrackerTab()?.id!,"is-child-statement-edit",false);
    }
    this.onSaveClick.emit();
  }

  saveToS3(): void {
    this.onS3UploadClick.emit();
  }

  viewPdf(): void{
    this.loadingPdf = true;
    this.showPDFView('pdf-viewer',this.statementId?.toString()!);
  }

  showPDFView(id: string, statementId: string): void {
    this.showPDFViewerModal = true;
    this.getPDFProformaParams(statementId);
    setTimeout(() => {
      this.pdfViewerModal.showMore(id);// styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="pdf-viewer"]');
      modalEle?.classList.add('gtw-web-components');
    });

  }

  getPDFProformaParams(statementId: string): void {

    this.stmtDetailsLoaded = false;
    this.statementTrackerService.getPDFProformaParams(statementId).subscribe((data: FormReivewPdf[]) => {
      if (data.length < 1) {
        throw Error('PDF Proforma Params not found for the statementId: ' + statementId);
      } else {
        this.dstProformaData = data[0];
        this.dstProformaData.FORM_SUB_CAT = 'DIFF_PDF'; //Default values
        this.dstProformaData.SRC_TYPE = 'DOM';
        this.dstProformaData.JOB_ID = '0';
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while getting statement details, please try again!');
    }).add(() => {
      this.stmtDetailsLoaded = true;
    });
  }


  closePDFViewerModalOk(id: string): void {
    this.pdfViewerModal.closeModalOk(id);
    this.loadingPdf = false;
    setTimeout(() => {
      this.showPDFViewerModal = false;
    });
  }

  getFullUrl() {
    const path = this.location.prepareExternalUrl(this.location.path());
    return window.location.origin + path;
  }

  viewStyleSheet(): void{
    this.loadingSS = true;
    this.createDisclosureSharedService.getEfileAttribsByStatementId(this.statementId!)
    .subscribe((data: any) =>{

      if(!data){
        this.loadingSS = false;
        this.dstSharedService.showAlert('error', 'Data have not been pushed to Efile for this statement yet!');
        return;
      }
      this.createDisclosureSharedService.getStyleSheetHTML(data.FORM_ID,data.SUBMISSION_ID)
      .subscribe((html: string)=>{
            this.loadingSS = false;
            //let baseUrl1 = window.location.protocol + "//" + window.location.host + window.location.pathname;
            let baseUrl = this.getFullUrl();
            html =html.replace(/mef\//g, baseUrl+"/assets/img/efile/mef/")
            const winUrl = URL.createObjectURL(
              new Blob([html], { type: "text/html" })
            );
            var win = window.open(winUrl, "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=800");
          },()=>this.loadingSS = false)


      },()=>this.loadingSS = false)
  }

  back(): void {
    this.onBackClick.emit();
  }

  onPlaceholderChecked(event: any): void {
    if (this.trackerTab) {
      this.trackerTab.isPlaceholderChecked = this.isPlaceholderChecked = !this.isPlaceholderChecked;
    }
  }

}
