import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../screen-video-container/user';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { ScreenDetailsModel } from '../screen-details-model';
import { FavoriteButtonService } from './favorite-button.service';

@Component({
  selector: 'gtw-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss']
})
export class FavoriteButtonComponent implements OnInit {

  @Output()
  screenChange = new EventEmitter<ScreenDetailsModel>();
      
  @Input()
  screen: ScreenDetailsModel = {};

  @Input('base-url')
  baseUrl = '/gtw';

  @Input('client-key')
  clientKey: number = 0;

  @Input()
  user:User= {sso:'000000000', role:'none',system_access_level:'0'};

  sso_id?: string;

  constructor(private favoriteSrvice : FavoriteButtonService,
              private _gtwSnackbarService:GtwSnackbarService) {}

  ngOnInit(): void {
  }

  public favBtnCheck (): void {
    let temp  = 'N';
    if(this.screen.IS_FAV === 'Y') {
      temp = 'N';
    } else {
      temp = 'Y';
    }
    
    this.favoriteSrvice.updateFavorite(this.baseUrl,this.clientKey,this.screen.SCREEN_KEY!,temp)
      .subscribe((data:any) => {
        if(data && data.callSuccess==1){
          this.screen.IS_FAV = temp;
          this.screenChange.emit(this.screen);
        }else{
          this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
        }
      },(error)=>{
        this._gtwSnackbarService.openSnackBar('Sorry!! Something went wrong. Please try again later.', "gtw-snackbar--red");
      });
  }

}
