import { Directive, ElementRef, Input } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

@Directive({
  selector: '[gtwLineSplitter]'
})
export class LineSplitterDirective {

  @Input() inputString!: string;
  @Input() sepearator!: string;
  @Input() formatter?: string;

  constructor(private Element: ElementRef,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe) {
  }

  ngOnInit(): void {

    if (this.inputString === undefined) {
      return;
    }

    this.Element.nativeElement.innerText = '';

    const childInputs = this.inputString.split(this.sepearator);
    for (let i = 0; i < childInputs.length; i++) {
      const brElement = document.createElement("br");
      const spanElement = document.createElement("span");

      const spanText = this.getFormattedText(childInputs[i]);
      const spanTextElement = document.createTextNode(spanText);

      spanElement.appendChild(spanTextElement);
      this.Element.nativeElement.appendChild(spanElement);
      this.Element.nativeElement.appendChild(brElement);
    }

  }

  getFormattedText(text: string): string {

    var formattedText = text;
    if (this.formatter === undefined) {
      return formattedText;
    }

    switch (this.formatter) {
      case 'decimal': formattedText = ('$' + this.decimalPipe.transform(text)) || '';
        return formattedText;
        break;
      case 'number': formattedText = this.decimalPipe.transform(text) || '';
        return formattedText;
        break;
      case 'percent': formattedText = formattedText + '%';
        return formattedText;
        break;
      case 'date': formattedText = this.datePipe.transform(formattedText, 'yyyy-MM-dd') || '';
        return formattedText;
        break;
      default:
        break;
    }
    return formattedText;
  }

}
