import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng-lts/table';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { DfMeDropdownComponent } from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import { JSONExportHeader, JSONExportService } from '../../../shared/_services/JSONExport.service';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { DSTDynamicComponentResolverService } from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import { TrackerTabsService } from '../../statement-tracker/tracker-tabs.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'gtw-sec368-report',
  templateUrl: './sec-368-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './sec-368-report.component.scss']
})
export class Sec368ReportComponent implements OnInit, OnChanges {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;
  
  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTID',
      header: 'Statement Id',
      hidden: false,
      width: '145px'
    },
    {
      field: 'PROJECT_NAME',
      header: 'Project',
      hidden: false,
      width: '105px'
    },
    {
      field: 'PRIMARYLEID',
      header: 'Target (Primary LEID)',
      hidden: false,
      width: '130px'
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Target Name',
      hidden: false,
      width: '260px'
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Target Business/ME',
      hidden: false,
      width: '260px'
    },
    {
      field: 'FILLING_GROUP',
      header: 'Filling group',
      hidden: false,
      width: '145px'
    },
    {
      field: 'SECONDARYLEID',
      header: 'Acquirer (Secondary LEID)',
      hidden: false,
      width: '165px'
    },
    {
      field: 'SECONDARYENTITY',
      header: 'Acquirer Name',
      hidden: false,
      width: '260px'
    },
    {
      field: 'SIGNIFICANT_HOLDER',
      header: 'Significant Holder (LEID)',
      hidden: false,
      width: '175px'
    },
    {
      field: 'SIGNIFICANTHOLDER_NAME',
      header: 'Significant Holder Name',
      hidden: false,
      width: '260px'
    },
    {
      field: 'DATE_OF_REORGANIZATION',
      header: 'Date of Reorganization',
      hidden: false,
      width: '220px'
    },
    {
      field: 'REORGANIZATIONCODESECTION',
      header: 'Reorganization Code Section',
      hidden: false,
      width: '195px'
    },
    {
      field: 'IMPORTATIONPROPERTYDESC',
      header: 'Importation property transferred in a loss importation transaction',
      hidden: false,
      width: '275px'
    },
    {
      field: 'LOSSDUPLICATIONPROPERTDESC',
      header: 'Loss duplication property',
      hidden: false,
      width: '236px'
    },
    {
      field: 'GAINLOSSPROPERTYDESC',
      header: 'Property with respect to which any gain or loss was recognized on the transfer',
      hidden: false,
      width: '335px'
    },
    {
      field: 'DESCRIPTION',
      header: 'Target Property Transferred',
      hidden: false,
      width: '375px'
    },
    {
      field: 'BASIS_AMOUNT',
      header: 'Target Basis Amount',
      hidden: false,
      width: '155px'
    },
    {
      field: 'BASIS_CURRENCY',
      header: 'Target Basis Currency',
      hidden: false,
      width: '155px'
    },
    {
      field: 'BASISTYPE',
      header: 'Target Basis Type',
      hidden: false,
      width: '125px'
    },
    {
      field: 'FMV_AMOUNT',
      header: 'Target FMV Amount',
      hidden: false,
      width: '150px'
    },
    {
      field: 'FMV_CURRENCY',
      header: 'Target FMV Currency',
      hidden: false,
      width: '155px'
    },
    {
      field: 'STOCKSSECURITYPROPERTYDESC',
      header: 'Significant Holder: Stocks and securities with respect to which an election is made under Section 362(e)(2)(C)',
      hidden: false,
      width: '435px'
    },
    {
      field: 'STOCKSSECURITYNOTDESC',
      header: 'Significant Holder: Stocks and securities not described in Section 1.368-3(b)(3)(i) ',
      hidden: false,
      width: '340px'
    },
    {
      field: 'BASIS_AMOUNT1',
      header: 'Significant Holder Basis Amount',
      hidden: false,
      width: '155px'
    },
    {
      field: 'BASIS_CURRENCY1',
      header: 'Significant Holder Basis Currency',
      hidden: false,
      width: '155px'
    },
    {
      field: 'BASISTYPE1',
      header: 'Significant Holder Basis Type',
      hidden: false,
      width: '125px'
    },
    {
      field: 'FMV_AMOUNT1',
      header: 'Significant Holder FMV Amount',
      hidden: false,
      width: '150px'
    },
    {
      field: 'FMV_CURRENCY1',
      header: 'Significant Holder FMV Currency',
      hidden: false,
      width: '155px'
    },
    {
      field: 'PLRCONTROLNUMBER',
      header: 'PLR Control Number',
      hidden: false,
      width: '200px'
    },
    {
      field: 'ADOPTEDDATE_VALUE',
      header: 'PLR Date',
      hidden: false,
      width: '185px'
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
    private dstSharedService: DSTSharedService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerTabsService: TrackerTabsService,
    private dCRService: DSTDynamicComponentResolverService,
    private jsonExportService: JSONExportService,
    private reportsService: ReportsService) {

    this.excelExportFileName = 'Reports-368_Report_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
    // this.dstSharedService.setTaxYearScenario('2020', '40');
  }

  ngOnInit(): void {

     //this.dstSharedService.setDSTUser('400394467', 5);
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2020');
    //   this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
    //   this.loadReport();
    // });

    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });

  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.reportsService.loadSection368Report(this.taxYear?.text.toString()!, this.selectedMes || '').subscribe((data: any) => {
      this.data = data;
      this.data?.forEach((element) => { //params for opening statement
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;
      });
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the N/A Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'STATEMENTID',
        header: 'Statement Id',
      },
      {
        field: 'PROJECT_NAME',
        header: 'Project',
      },
      {
        field: 'PRIMARYLEID',
        header: 'Target (Primary LEID)',
      },
      {
        field: 'PRIMARYENTITY',
        header: 'Target Name',
      },
      {
        field: 'PRIMARYBUSINESS',
        header: 'Target Business/ME',
      },
      {
        field: 'FILLING_GROUP',
        header: 'Filling group',
      },
      {
        field: 'SECONDARYLEID',
        header: 'Acquirer (Secondary LEID)',
      },
      {
        field: 'SECONDARYENTITY',
        header: 'Acquirer Name',
      },
      {
        field: 'SIGNIFICANT_HOLDER',
        header: 'Significant Holder (LEID)',
      },
      {
        field: 'SIGNIFICANTHOLDER_NAME',
        header: 'Significant Holder Name',
      },
      {
        field: 'DATE_OF_REORGANIZATION',
        header: 'Date of Reorganization',
      },
      {
        field: 'REORGANIZATIONCODESECTION',
        header: 'Reorganization Code Section',
      },
      {
        field: 'IMPORTATIONPROPERTYDESC',
        header: 'Importation property transferred in a loss importation transaction',
      },
      {
        field: 'LOSSDUPLICATIONPROPERTDESC',
        header: 'Loss duplication property',
      },
      {
        field: 'GAINLOSSPROPERTYDESC',
        header: 'Property with respect to which any gain or loss was recognized on the transfer',
      },
      {
        field: 'DESCRIPTION',
        header: 'Target Property Transferred',
      },
      {
        field: 'BASIS_AMOUNT',
        header: 'Target Basis Amount',
        format: 'number',
      },
      {
        field: 'BASIS_CURRENCY',
        header: 'Target Basis Currency',
      },
      {
        field: 'BASISTYPE',
        header: 'Target Basis Type',
      },
      {
        field: 'FMV_AMOUNT',
        header: 'Target FMV Amount',
        format: 'number',
      },
      {
        field: 'FMV_CURRENCY',
        header: 'Target FMV Currency',
      },
      {
        field: 'STOCKSSECURITYPROPERTYDESC',
        header: 'Significant Holder: Stocks and securities with respect to which an election is made under Section 362(e)(2)(C)',
      },
      {
        field: 'STOCKSSECURITYNOTDESC',
        header: 'Significant Holder: Stocks and securities not described in Section 1.368-3(b)(3)(i)',
      },
      {
        field: 'BASIS_AMOUNT1',
        header: 'Significant Holder Basis Amount',
        format: 'number',
      },
      {
        field: 'BASIS_CURRENCY1',
        header: 'Significant Holder Basis Currency',
      },
      {
        field: 'BASISTYPE1',
        header: 'Significant Holder Basis Type',
      },
      {
        field: 'FMV_AMOUNT1',
        header: 'Significant Holder FMV Amount',
        format: 'number',
      },
      {
        field: 'FMV_CURRENCY1',
        header: 'Significant Holder FMV Currency',
      },
      {
        field: 'PLRCONTROLNUMBER',
        header: 'PLR Control Number',
      },
      {
        field: 'ADOPTEDDATE_VALUE',
        header: 'PLR Date',
      }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
