import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import { CreateDisclosureSharedService, DropDownType } from '../../../create-disclosure-shared.service';

@Component({
  selector: 'gtw-view-template-form8621-id236',
  templateUrl: './view-template-form8621-id236.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-form8621-id236.component.scss']
})
export class ViewTemplateForm8621Id236Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  startLoader: boolean = false;
  disableActions: boolean = false;
  loaded: boolean = false;
  stmtData!: StmtData;
  form236!: FormGroup;
  attMap: any;
  shareholderTaxYear: string = '';
  shareholderTaxYearType: string = '';
  shareholderBeginningTaxYear: string = '';
  shareholderEndingTaxYear: string = '';
  shareholderFiling: string = '';
  PFICTaxYear: string = '';
  PFICTaxYearType: string = '';
  companyOrFundBeginningTaxYear: string = '';
  companyOrFundEndingTaxYear: string = '';
  shareHoldersSharesDesc: string = '';
  dateSharesAcq: string = '';
  noOfSharesYearEnd: string = '';
  shareValueTaxyearEnd!: number | undefined;
  valueOfSharesYearEnd: string = '';
  pficType!: number;
  pficType1Amount: string = '';
  pficType2Amount: string = '';
  pficType3Amount: string = '';
  electionToTreat: boolean = false;
  electionToExtendDesc: string = '';
  electionToExtend: boolean = false;
  electionToMarkToMarket: boolean = false;
  deemedSalesElection: boolean = false;
  deemedDividentElection: boolean = false;
  electionToRecognizeGain: boolean = false;
  deemedDividendElectionWrtSection1297: boolean = false;
  deemedDividendElectionWrtFormerPfic: boolean = false;
  proRateShares: string = '';
  portionLine6a: string = '';
  OrdinaryIncome: string = '';
  proRateSharesNetGain: string = '';
  portionLine7a: string = '';
  NetCapitalGain: string = '';
  Line6cAnd7cSum: string = '';
  cashPropertyDeemedDistributedCash: string = '';
  portionLine8c: string = '';
  Line8bAnd8cSum: string = '';
  Line8dAnd8aSubt: string = '';
  totalTax: string = '';
  totalTaxWithoutRegard: string = '';
  Line9bAnd9aSubt: string = '';
  fairMarketValue: string = '';
  adjustedBasisStock: string = '';
  OrdinaryIncomeOnTaxReturn: string = '';
  unreversedInclusion: string = '';
  lossLine10c: string = '';
  fairMarketDispositionValue: string = '';
  adjustedBasisDispositionStock: string = '';
  Line13bAnd13aSubt: string = '';
  unreversedInclusionSection1296: string = '';
  lossLine13c: string = '';
  lossLine13cExceedInclusion: string = '';
  corpElectionChecked: boolean = false;
  sharesOwnedWithSpouse: boolean = false;
  pficAddress: string = '';
  pficEin: string = '';
  PFICAdd1: string = '';
  PFICAdd2: string = '';
  PFICCity: string = '';
  PFICCountry: string = '';
  PFICStateProv: string = '';
  PFICZip: string = '';
  stateValue: string = '';
  StateOptions: any[] = [{
    data: []
  }];
  CountryOptions: any[] = [{
    data: []
  }];
  secoundaryStateValue: string = '';

  constructor(private formBuilder: FormBuilder,
    private sharedService: CreateDisclosureSharedService) {

    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false;
  }

  ngOnInit(): void {
    this.form236 = this.formBuilder.group({
      header: this.formBuilder.group({
          consolGroup: ['', Validators.required],
          options: ['']
      })
    });

    this.startLoader = true;
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
    });

    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
      });

    //load Stmt data
    this.sharedService.getLoadStatementData(236)
      .subscribe((response: StmtData) => {
        this.stmtData = response;

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {
            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }
        });

        this.loadData();

      }).add(() => {
        this.loaded = true;
        this.startLoader = false;
    });
  }

  loadData(): void {
    if(this.stmtData.primarystate) {
      const primaryState: any = this.StateOptions[0].data.find((s: any) => s.value.toString() === this.stmtData.primarystate);
      primaryState && (this.stateValue = primaryState.text || '');
    }


    if (this.attMap['ShareholderTaxYear'] !== undefined && this.attMap['ShareholderTaxYear'][0] !== '') {
      this.shareholderTaxYearType = 'Year';
      this.shareholderTaxYear = this.attMap['ShareholderTaxYear'][0];
    }

    if (this.attMap['ShareholderBeginningTaxYear'] !== undefined && this.attMap['ShareholderBeginningTaxYear'][0] !== ''
      && this.attMap['ShareholderEndingTaxYear'] !== undefined && this.attMap['ShareholderEndingTaxYear'][0] !== '') {
      this.shareholderTaxYearType = 'Range';
      this.shareholderBeginningTaxYear = this.attMap['ShareholderBeginningTaxYear'][0];
      this.shareholderEndingTaxYear = this.attMap['ShareholderEndingTaxYear'][0];
    }

    if (this.attMap['IndividualShareholder'] !== undefined && this.attMap['IndividualShareholder'][0] !== '') {
      this.shareholderFiling = 'Individual';
    }
    if (this.attMap['CorporationShareholder'] !== undefined && this.attMap['CorporationShareholder'][0] !== '') {
      this.shareholderFiling = 'Corporation';
    }
    if (this.attMap['PartnershipShareholder'] !== undefined && this.attMap['PartnershipShareholder'][0] !== '') {
      this.shareholderFiling = 'Partnership';
    }
    if (this.attMap['SCorporationShareholder'] !== undefined && this.attMap['SCorporationShareholder'][0] !== '') {
      this.shareholderFiling = 'SCorporation';
    }
    if (this.attMap['NongrantorTrustShareholder'] !== undefined && this.attMap['NongrantorTrustShareholder'][0] !== '') {
      this.shareholderFiling = 'NongrantorTrust';
    }
    if (this.attMap['EstateShareholder'] !== undefined && this.attMap['EstateShareholder'][0] !== '') {
      this.shareholderFiling = 'Estate';
    }

    if (this.attMap['QualifiedInsuranceCorpElectInd'] !== undefined && this.attMap['QualifiedInsuranceCorpElectInd'][0] !== '') {
      this.corpElectionChecked = true;
    }

    //PFIC
    if (this.attMap['USAddressOfPFICOrQEFAddressLine1'] !== undefined && this.attMap['USAddressOfPFICOrQEFAddressLine1'][0] !== '') {
      this.pficAddress = 'US';
      this.PFICAdd1 = this.attMap['USAddressOfPFICOrQEFAddressLine1'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFAddressLine2'] !== undefined && this.attMap['USAddressOfPFICOrQEFAddressLine2'][0] !== '') {
      this.pficAddress = 'US';
      this.PFICAdd2 = this.attMap['USAddressOfPFICOrQEFAddressLine2'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFCity'] !== undefined && this.attMap['USAddressOfPFICOrQEFCity'][0] !== '') {
      this.pficAddress = 'US';
      this.PFICCity = this.attMap['USAddressOfPFICOrQEFCity'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFState'] !== undefined && this.attMap['USAddressOfPFICOrQEFState'][0] !== '') {
      this.pficAddress = 'US';
      this.PFICStateProv = this.attMap['USAddressOfPFICOrQEFState'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFZIPCode'] !== undefined && this.attMap['USAddressOfPFICOrQEFZIPCode'][0] !== '') {
      this.pficAddress = 'US';
      this.PFICZip = this.attMap['USAddressOfPFICOrQEFZIPCode'][0];
    }

    if (this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICAdd1 = this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICAdd2 = this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFCity'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFCity'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICCity = this.attMap['ForeignAddressOfPFICOrQEFCity'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICStateProv = this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'][0];
    }
    if(this.stmtData.secondarystate){
      const secondaryState: any = this.StateOptions[0].data.find((s: any) => s.value.toString() === (this.PFICStateProv || this.stmtData.secondarystate));
      secondaryState && (this.secoundaryStateValue = secondaryState.text || '');
    }else{
      this.secoundaryStateValue = this.PFICStateProv || '';
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFCountry'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFCountry'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICCountry = this.attMap['ForeignAddressOfPFICOrQEFCountry'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFPostalCode'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFPostalCode'][0] !== '') {
      this.pficAddress = 'Foreign';
      this.PFICZip = this.attMap['ForeignAddressOfPFICOrQEFPostalCode'][0];
    }

    if (this.attMap['TaxYearOfCompanyOrFund'] !== undefined && this.attMap['TaxYearOfCompanyOrFund'][0] !== '') {
      this.PFICTaxYearType = 'Year';
      this.PFICTaxYear = this.attMap['TaxYearOfCompanyOrFund'][0];
    }

    if (this.attMap['CompanyOrFundBeginningTaxYear'] !== undefined && this.attMap['CompanyOrFundBeginningTaxYear'][0] !== ''
      && this.attMap['CompanyOrFundEndingTaxYear'] !== undefined && this.attMap['CompanyOrFundEndingTaxYear'][0] !== '') {
      this.PFICTaxYearType = 'Range';
      this.companyOrFundBeginningTaxYear = this.attMap['CompanyOrFundBeginningTaxYear'][0];
      this.companyOrFundEndingTaxYear = this.attMap['CompanyOrFundEndingTaxYear'][0];
    }
    //1, 1 checkbox
    if (this.attMap['DescribeEachClassOfShares'] !== undefined && this.attMap['DescribeEachClassOfShares'][0] !== '') {
      this.shareHoldersSharesDesc = this.attMap['DescribeEachClassOfShares'][0];
    }
    if (this.attMap['SharesJointlyOwnedWithSpouse'] !== undefined && this.attMap['SharesJointlyOwnedWithSpouse'][0] !== '') {
      this.sharesOwnedWithSpouse = true;
    }
    //2
    if (this.attMap['DateSharesAcquiredTxblYr'] !== undefined && this.attMap['DateSharesAcquiredTxblYr'][0] !== '') {
      this.dateSharesAcq = this.attMap['DateSharesAcquiredTxblYr'][0];
    }
    //3
    if (this.attMap['NumberOfSharesAtEndOfTxblYr'] !== undefined && this.attMap['NumberOfSharesAtEndOfTxblYr'][0] !== '') {
      this.noOfSharesYearEnd = this.attMap['NumberOfSharesAtEndOfTxblYr'][0];
    }
    //4
    if (this.attMap['ValueOfSharesRangeA'] !== undefined && this.attMap['ValueOfSharesRangeA'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeA'][0] === '1' ? 0 : undefined;
    }
    if (this.attMap['ValueOfSharesRangeB'] !== undefined && this.attMap['ValueOfSharesRangeB'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeB'][0]=== '1' ? 50001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeC'] !== undefined && this.attMap['ValueOfSharesRangeC'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeC'][0]=== '1' ? 100001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeD'] !== undefined && this.attMap['ValueOfSharesRangeD'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeD'][0]=== '1' ? 150001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeE'] !== undefined && this.attMap['ValueOfSharesRangeE'][0] !== '') {
      this.valueOfSharesYearEnd = this.attMap['ValueOfSharesRangeE'][0];
    }
    //5
    if (this.attMap['Section1291'] !== undefined && this.attMap['Section1291'][0] !== '') {
      this.pficType = this.attMap['Section1291'][0] === '1' ? 1: 0;
    }
    if (this.attMap['Section1293'] !== undefined && this.attMap['Section1293'][0] !== '') {
      this.pficType = this.attMap['Section1293'][0] === '1' ? 2: 0;
    }
    if (this.attMap['Section1296'] !== undefined && this.attMap['Section1296'][0] !== '') {
      this.pficType = this.attMap['Section1296'][0] === '1' ? 3: 0;
    }
    if (this.attMap['Section1291Amt'] !== undefined && this.attMap['Section1291Amt'][0] !== '') {
      this.pficType1Amount = this.attMap['Section1291Amt'][0];
    }
    if (this.attMap['Section1293Amt'] !== undefined && this.attMap['Section1293Amt'][0] !== '') {
      this.pficType2Amount = this.attMap['Section1293Amt'][0];
    }
    if (this.attMap['Section1296Amt'] !== undefined && this.attMap['Section1296Amt'][0] !== '') {
      this.pficType3Amount = this.attMap['Section1296Amt'][0];
    }
    //A, B, B textbox, C, D, E, F, G, H
    if (this.attMap['ElectionToTreatThePFICAsQEF'] !== undefined && this.attMap['ElectionToTreatThePFICAsQEF'][0] !== 0) {
      this.electionToTreat = this.attMap['ElectionToTreatThePFICAsQEF'][0] === '1' ? true: false;
    }
    if (this.attMap['ElectToExtendTimeForPymtOfTax'] !== undefined && this.attMap['ElectToExtendTimeForPymtOfTax'][0] !== 0) {
      this.electionToExtend = this.attMap['ElectToExtendTimeForPymtOfTax'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDividendElectionreferenceDocumentId'] !== undefined && this.attMap['DeemedDividendElectionreferenceDocumentId'][0] !== '') {
      this.electionToExtendDesc = this.attMap['DeemedDividendElectionreferenceDocumentId'][0];
    }
    if (this.attMap['ElectToMarkToMarketPFICStock'] !== undefined && this.attMap['ElectToMarkToMarketPFICStock'][0] !== 0) {
      this.electionToMarkToMarket = this.attMap['ElectToMarkToMarketPFICStock'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedSaleElection'] !== undefined && this.attMap['DeemedSaleElection'][0] !== 0) {
      this.deemedSalesElection = this.attMap['DeemedSaleElection'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDividendElection'] !== undefined && this.attMap['DeemedDividendElection'][0] !== 0) {
      this.deemedDividentElection = this.attMap['DeemedDividendElection'][0] === '1' ? true: false;
    }
    if (this.attMap['ElectToRecognizeGainOnPFICSale'] !== undefined && this.attMap['ElectToRecognizeGainOnPFICSale'][0] !== 0) {
      this.electionToRecognizeGain = this.attMap['ElectToRecognizeGainOnPFICSale'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDivElectSec1297ePFICInd'] !== undefined && this.attMap['DeemedDivElectSec1297ePFICInd'][0] !== 0) {
      this.deemedDividendElectionWrtSection1297 = this.attMap['DeemedDivElectSec1297ePFICInd'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDivElectFrmrPFICInd'] !== undefined && this.attMap['DeemedDivElectFrmrPFICInd'][0] !== 0) {
      this.deemedDividendElectionWrtFormerPfic = this.attMap['DeemedDivElectFrmrPFICInd'][0] === '1' ? true: false;
    }
    //6
    if (this.attMap['ProRataShareOfQEFOrdinaryEarn'] !== undefined && this.attMap['ProRataShareOfQEFOrdinaryEarn'][0] !== '') {
      this.proRateShares = this.attMap['ProRataShareOfQEFOrdinaryEarn'][0];
    }
    if (this.attMap['IncomePortionOfOrdinaryEarn'] !== undefined && this.attMap['IncomePortionOfOrdinaryEarn'][0] !== '') {
      this.portionLine6a = this.attMap['IncomePortionOfOrdinaryEarn'][0];
    }
    if (this.attMap['DividendIncome'] !== undefined && this.attMap['DividendIncome'][0] !== '') {
      this.OrdinaryIncome = this.attMap['DividendIncome'][0];
    }
    //7
    if (this.attMap['ProRataShareOfTotalNetCapGain'] !== undefined && this.attMap['ProRataShareOfTotalNetCapGain'][0] !== '') {
      this.proRateSharesNetGain = this.attMap['ProRataShareOfTotalNetCapGain'][0];
    }
    if (this.attMap['IncomePortionOfNetCapitalGain'] !== undefined && this.attMap['IncomePortionOfNetCapitalGain'][0] !== '') {
      this.portionLine7a = this.attMap['IncomePortionOfNetCapitalGain'][0];
    }
    if (this.attMap['NetLongTermCapitalGain'] !== undefined && this.attMap['NetLongTermCapitalGain'][0] !== '') {
      this.NetCapitalGain = this.attMap['NetLongTermCapitalGain'][0];
    }
    //8
    if (this.attMap['DividendIncomeAndNetLTCG'] !== undefined && this.attMap['DividendIncomeAndNetLTCG'][0] !== '') {
      this.Line6cAnd7cSum = this.attMap['DividendIncomeAndNetLTCG'][0];
    }
    if (this.attMap['TotalCashAndDistributions'] !== undefined && this.attMap['TotalCashAndDistributions'][0] !== '') {
      this.cashPropertyDeemedDistributedCash = this.attMap['TotalCashAndDistributions'][0];
    }
    if (this.attMap['PortionOfProRataOrdnryEarnings'] !== undefined && this.attMap['PortionOfProRataOrdnryEarnings'][0] !== ''){
      this.portionLine8c = this.attMap['PortionOfProRataOrdnryEarnings'][0];
    }
    if (this.attMap['TotalCashAndPortionOfProRata'] !== undefined && this.attMap['TotalCashAndPortionOfProRata'][0] !== '') {
      this.Line8bAnd8cSum = this.attMap['TotalCashAndPortionOfProRata'][0];
    }
    if (this.attMap['ProRataLessCashAndPortion'] !== undefined && this.attMap['ProRataLessCashAndPortion'][0] !== '') {
      this.Line8dAnd8aSubt = this.attMap['ProRataLessCashAndPortion'][0];
    }
    //9
    if (this.attMap['TotalTaxForTheTaxYear'] !== undefined && this.attMap['TotalTaxForTheTaxYear'][0] !== '') {
      this.totalTax = this.attMap['TotalTaxForTheTaxYear'][0];
    }
    if (this.attMap['TotTaxWithoutProRataLessCash'] !== undefined && this.attMap['TotTaxWithoutProRataLessCash'][0] !== '') {
      this.totalTaxWithoutRegard = this.attMap['TotTaxWithoutProRataLessCash'][0];
    }
    if (this.attMap['DeferredTax'] !== undefined && this.attMap['DeferredTax'][0] !== '') {
      this.Line9bAnd9aSubt = this.attMap['DeferredTax'][0];
    }
    //10
    if (this.attMap['FairMarketValueOfPFICStock'] !== undefined && this.attMap['FairMarketValueOfPFICStock'][0] !== '') {
      this.fairMarketValue = this.attMap['FairMarketValueOfPFICStock'][0];
    }
    if (this.attMap['AdjustedBasisInStockEndOfTY'] !== undefined && this.attMap['AdjustedBasisInStockEndOfTY'][0] !== '') {
      this.adjustedBasisStock = this.attMap['AdjustedBasisInStockEndOfTY'][0];
    }
    if (this.attMap['Excess'] !== undefined && this.attMap['Excess'][0] !== '') {
      this.OrdinaryIncomeOnTaxReturn = this.attMap['Excess'][0];
    }
    //11
    if (this.attMap['UnreversedInclusions'] !== undefined && this.attMap['UnreversedInclusions'][0] !== '') {
      this.unreversedInclusion = this.attMap['UnreversedInclusions'][0];
    }
    //12
    if (this.attMap['ExcessOrUnreservedInclusions'] !== undefined && this.attMap['ExcessOrUnreservedInclusions'][0] !== '') {
      this.lossLine10c = this.attMap['ExcessOrUnreservedInclusions'][0];
    }
    //13
    if (this.attMap['FMVStkOnDtSaleOrDisposAmt'] !== undefined && this.attMap['FMVStkOnDtSaleOrDisposAmt'][0] !== '') {
      this.fairMarketDispositionValue = this.attMap['FMVStkOnDtSaleOrDisposAmt'][0];
    }
    if (this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'] !== undefined && this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'][0] !== ''){
      this.adjustedBasisDispositionStock = this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'][0];
    }
    if (this.attMap['OrdinaryIncomeFromPFICStkAmt'] !== undefined && this.attMap['OrdinaryIncomeFromPFICStkAmt'][0] !== '') {
      this.Line13bAnd13aSubt = this.attMap['OrdinaryIncomeFromPFICStkAmt'][0];
    }
    //14
    if (this.attMap['StkSaleUnreversedInclusionsAmt'] !== undefined && this.attMap['StkSaleUnreversedInclusionsAmt'][0] !== ''){
      this.unreversedInclusionSection1296 = this.attMap['StkSaleUnreversedInclusionsAmt'][0];
    }
    if (this.attMap['LossLimitedByOrdinaryIncomeAmt'] !== undefined && this.attMap['LossLimitedByOrdinaryIncomeAmt'][0] !== ''){
      this.lossLine13c = this.attMap['LossLimitedByOrdinaryIncomeAmt'][0];
    }
    if (this.attMap['LossExcessOfUnrvrsdInclsnAmt'] !== undefined && this.attMap['LossExcessOfUnrvrsdInclsnAmt'][0] !== '') {
      this.lossLine13cExceedInclusion = this.attMap['LossExcessOfUnrvrsdInclsnAmt'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFCountry'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFCountry'][0] !== '') {
      const selectedCountry: any = this.CountryOptions[0].data.find((s: any) => s.value.toString() === this.attMap['ForeignAddressOfPFICOrQEFCountry'][0]);
      selectedCountry && (this.PFICCountry = selectedCountry.text || '');
    }

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
