import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gtw-delete-transaction-modal',
  templateUrl: './delete-transaction-modal.component.html',
  styleUrls: ['./delete-transaction-modal.component.scss']
})
export class DeleteTransactionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {  }

}
