<div class="main-container">
    <div class="grid-container" id="nareport-grid-container" style="height:calc(100% - 55px);">
        <div class="top-toolbar">
            <div class="float-left ml-2">
              <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear" 
                (onChange)="onTaxYearChange($event)">
              </p-dropdown>
              <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136" (selectedMes)="selectedMeChanged($event)"></gtw-df-me-dropdown>
              <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="refresh()"></ap-button>
              <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
            </div>
        </div>
    
        <p-table #dt [columns]="cols" [value]="data" [rows]="rows" dataKey="ID" [exportFilename]="excelExportFileName"
          [loading]="loading" [scrollable]="true" scrollHeight="calc(100vh - 225px)" responsiveLayout="scroll"
          styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="34"
          [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">
    
            <ng-template pTemplate="header" let-columns>
                <tr [style.height]="'45px'">
                  <ng-container *ngFor="let col of columns; index as i">
                    <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                      {{ col.header }}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </ng-container>
                </tr>
            </ng-template>
    
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="height: 70px;">
                    <td style="width: 80px;">
                      <span>{{ rowData.TAXYEAR }}</span>
                    </td>
                    <td style="overflow: visible; width: 100px;">
                        <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENTID }}</span></a>
                    </td>
                    <td class="text-pre-wrap" style="width: 290px;">
                      <span>{{ rowData.STATEMENTNAME }}</span>
                    </td>
                    <td style="width: 180px;">
                      <span>{{ rowData.PRIMARYBUSINESS }}</span>
                    </td>
                    <td style="width: 100px;">
                      <span>{{ rowData.PRIMARYLEID }}</span>
                    </td>
                    <td style="width: 220px;">
                        <span>{{ rowData.PRIMARYENTITY }}</span>
                      </td>
                    <td class="text-pre-wrap" style="width: 180px;">
                      <span>{{ rowData.SECONDARYBUSINESS }}</span>
                    </td>
                    <td style="width: 100px;">
                      <span>{{ rowData.SECONDARYLEID }}</span>
                    </td>
                    <td style="width: 180px;">
                        <span>{{ rowData.SECONDARYENTITY }}</span>
                      </td>
                    <td class="text-pre-wrap" style="width: 90px;">
                      <span>{{ rowData.ISQARSTR }}</span>
                    </td>
                    <td class="text-pre-wrap" style="width: 180px;">
                        <span>{{ rowData.MODIFIEDBY }}</span>
                    </td>
                    <td class="text-pre-wrap" style="width: 180px;">
                    <span>{{ rowData.MODIFIEDON  | date:'yyyy/MM/dd' }}</span>
                    </td>
                    <td class="text-pre-wrap" style="width: 180px;">
                        <span>{{ rowData.CREATEDBY }}</span>
                    </td>
                    <td class="text-pre-wrap" style="width: 180px;">
                        <span>{{ rowData.CREATEDON  | date:'yyyy/MM/dd' }}</span>
                    </td>
                </tr>
            </ng-template>
    
        </p-table>
    </div>
    
      <dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
      </dst-disc-modal-viewer>
  </div>