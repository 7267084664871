/*NOTE: f1118-schd-c component is common for CDE schedules, making changes here will affect all the CDE schedules. 
Incase adhoc feature is required for E or any change of requirement in C/D, consider spinning a new component for the schd*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class F1118SchdCDEService {
   
  url ={
		getLineColDtls : '/loadJsonObject?action_code=zsil7v',
		saveF1118SchdCDEAction : '/saveJsonObject?action_code=6m2il6',
		getBasketList : '/loadJsonObject?action_code=1x4adz',
		getDataByUniqueKeyAction: '/loadJsonObject?action_code=fnzb0z'
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	getLineColDtlsData(baseURL:string, params: any, taxYear: any, scenario: any, jcdKey: any, ccKey:any, schdCode: any, ssoid:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getLineColDtls}`;

		let body = new FormData();
		body.append('sso_id', ssoid);
		body.append('tax_year', taxYear);
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcdKey.toString());
		body.append('schd_code', schdCode);
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch getLineColDtlsData data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getDataByUniqueKey(baseURL:string, params: any, taxYear: any, scenario: any, jcdKey: any, 
		filerLeKey:any, ssoid:any, schdCode: any, uniqueKey: any){
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getDataByUniqueKeyAction}`;

		let body = new FormData();
		body.append('sso_id', ssoid);
		body.append('tax_year', taxYear);
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcdKey.toString());
		body.append('schd_code', schdCode);
		body.append('p_filer_entity_key', filerLeKey);
		body.append('p_unique_key', uniqueKey);
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch getDataByUniqueKey data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getBasketList(baseURL:string, taxYear: any, scenario: any, jcdKey: any, ssoid:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		
		const url = `${baseURL}${this.url.getBasketList}`; 

		let body = new FormData();
		body.append('sso_id', ssoid);
		body.append('tax_year', taxYear);
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcdKey.toString());


		  let headers = new HttpHeaders();
		  headers.append('client_key', '0');//FIXME:K REMOVE HARDCODED CLIENT KEY
  
		  headers.append('withCredentials', 'true');

		return this.http.post(url, body, {headers}).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch getBasketList data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

  openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}

	saveF1118SchdCDEData(baseURL : string, jsonData : any, clobSettings : any, baseGlobalParams: any, checkLockedObj: any) {
		let clientKey = sessionStorage.getItem('client_key') || '';
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('sso_id', clobSettings.SSO_ID);
	    body.append('tax_year', clobSettings.TAX_YEAR.toString());
		body.append('scenario', clobSettings.SCENARIO.toString());
		body.append('jcd_key', clobSettings.JCD_KEY.toString());
		body.append('filer_entity_key', clobSettings.FILER_ENTITY_KEY);
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('clob_settings', JSON.stringify([clobSettings]));
		body.append('scenarioCode', baseGlobalParams.scenarioCode);
		body.append('is_issue_key_enabled', baseGlobalParams.is_issue_key_enabled);
		body.append('issue_id', baseGlobalParams.issue_id);
		body.append('issue_short_desc', baseGlobalParams.issue_short_desc);
		body.append('checkLockedObj', checkLockedObj);

		const url = `${baseURL}${this.url.saveF1118SchdCDEAction}`;

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
                    return res;
				}else{
					//this.openSnackBar(res.errorMessage, 'gtw-snackbar--green');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

}
