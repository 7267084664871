import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild,Renderer2, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import {ModalComponent} from 'appkit-angular/modal';
import { SummaryDiagnosticService } from '../../summary-diagnostic.service';
import { GtwElementsBaseUrlsModel } from 'projects/gtw-elements/src/app/shared/_models/gtw-elements-base-urls.model';
import { Table } from 'primeng-lts/table';
import _ from 'lodash';
import { MultiSelect } from 'primeng-lts/multiselect';
import { DashboardFilterService } from '../../../dashboard-filter/dashboard-filter.service';


@Component({
  selector: 'gtw-detail-diag-view',
  templateUrl: './detail-diag-view.component.html',
  styleUrls: ['./detail-diag-view.component.scss']
})
export class DetailDiagViewComponent implements OnInit {
  @ViewChild('baseModalLargest ', { static: false }) baseModalLargest !: ModalComponent;
  @ViewChild('modalRef') modalRef!: ElementRef;
  @ViewChild('viewHeight') modalContentHeight!: ElementRef;

  @Output() afterModalClosed: EventEmitter<null> = new EventEmitter<null>();
  @ViewChild('dt') table!: Table;
  // @ViewChild('mutlSelect') mutlSelect!: MultiSelect;
  @ViewChildren('mutlSelect') mutlSelect!: QueryList<MultiSelect>;

   @Output() loadDrillDownScreen = new EventEmitter<any>();


  //baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  gridLoading = false;
  headerMap:any;
  selectedFilterParams :any;

  tableRowData!:any;
  scrollBarHidden: boolean = false;
  drillDownObj: any;
  scrollheight:number = 0;
/*   @Input('base-urls')
  set _base_urls(baseUrls: string){
    try{
      this.baseURLs = JSON.parse(baseUrls);
    }catch(e){
      console.log('Input not stable yet');
    }
  } */
  show = false;
  show_resolution = false;

  showMore(id: string, tableConfigData: any, tableData: any,baseUrls:any,selectedFilterParams:any,globalParams:any) {
    console.log('tableData',tableData);
    this.tableRowData = tableData;
    //this.baseURLs = baseUrls;
    this.gridLoading = true;
    this.selectedFilterParams = selectedFilterParams;
//    this.scrollheight = window.innerHeight *0.70 + 'px';

    let globalParamsObj:any;
    if(!_.isEmpty(globalParams)){
      globalParamsObj = globalParams;//JSON.parse(globalParams)
    }
    this.getDetailDiagData(tableConfigData,tableData,baseUrls,selectedFilterParams,globalParamsObj)
    
    this.baseModalLargest.showMore(id);
    setTimeout(() => {
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="show-deatil-diag"]');
      modalEle?.classList.add('gtw-web-components', 'show-deatil-diag');
    });
  }
  closeModalCustom(id: string) {
    this.baseModalLargest.closeModal(id);
    this.afterModalClosed.emit();
  }
  public primaryConfig = {
    isPrimary: true
  }
  constructor(private summaryDiagService: SummaryDiagnosticService, public el: ElementRef,
    private render:Renderer2, private dataFilterService: DashboardFilterService) {

   }

  ngOnInit() {
  }
  
  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }
  diagData:any ={
    header:[],
    data:[]
  };
  getDetailDiagData(tableConfigData:any,tableData:any,baseURLs:any,selectedFilterParams:any,globalParams:any){
//    this.scrollheight = window.innerHeight *0.70 + 'px';
    console.log('selectedFilterParams',selectedFilterParams);
    if(_.isEmpty(selectedFilterParams)){
      let params:any = {};
      params['tax_year']= tableData.TAX_YEAR;
      params['scenario'] = tableData.SCENARIO;
      params['system']= 'A';
      params['filing_key'] = 1;
     let me:any={}
      me = _.find(this.dataFilterService.screenFilters,(i:any)=>{ 
              if(i.screen_key == globalParams.screen_key){ 
                return i.selectedBusiness;
              }
            });
     params['parent_me_string'] = _.map(me['selectedBusiness'],'VALUE').join(',');
     selectedFilterParams = params;
     this.selectedFilterParams = selectedFilterParams;
    }
    this.summaryDiagService.getDiagData(tableConfigData,baseURLs.api,tableData,selectedFilterParams,globalParams).subscribe((data:any)=>{
      console.log('data',data);
      let diag_index = 0;
    if( tableData.DIAG_NAME){
      _.find(data.header,(key:any,index:any) =>{
        if(key.map == tableData.DIAG_NAME){
          key.label = tableData.UI_AMT_COLUMN_NAME ? tableData.UI_AMT_COLUMN_NAME : key.label;
          key.display = 'y';
          diag_index = index;
        }
        key.width = key.width + 25;
      });
      let header:any;
      //data.header = _.sortBy(data.header,({map})=> map === tableData.DIAG_NAME ? 0 : 1)//data.header.unshift(data.header.splice(diag_index, 1)[0]);
      data.header = this.swap(data.header,diag_index,7);
      
      console.log('data header',data.header);
    }
    
   
    let aboveDivHeigth = 0;
    if(this.modalContentHeight.nativeElement && this.modalContentHeight.nativeElement.children[1]){
      _.each(this.modalContentHeight.nativeElement.children[1].children,(ii)=>{
        if(ii.className == 'row'){
          aboveDivHeigth = aboveDivHeigth + ii.clientHeight
        }
      })
      if(aboveDivHeigth>0){
        this.scrollheight = this.modalContentHeight.nativeElement.offsetHeight - (aboveDivHeigth + document.getElementsByClassName('a-modal-header')[0].clientHeight+150);

      }else{
        this.scrollheight = 300;
      }
      
    }

    this.diagData.header = data.header;
    this.diagData.settings = data.settings;
    this.diagData.safeCopyArr = _.cloneDeep(data.data);
    this.diagData.data = data.data;
    _.each(this.diagData.header,(ii:any)=>{
      if(ii.display == 'y' &&( ii.map != 'object_id')){
        this.createFilterOptions(ii);

      }
    })
      this.gridLoading = false;
      setTimeout(() => {
      // this.changeScrollBarStyling();
      });

    })
  }
  swap(data:any,from:any,to:any){
        var f = data.splice(from, 1)[0];
    // insert stored item into position `to`
        data.splice(to, 0, f);
        return data;
    }
  gridFilters :any = {
    gridFilters:[],
    drillDownRefresh:0,
    filterObj:{},
    screenFilter:[]
};
  checkCondition(obj:any,rowData:any){
      if(obj.rule['operator']=='=' && obj.rule['value'] == rowData[obj.rule['map']]){
        return true;
      }
      else if(obj.rule['operator']=='!=' && obj.rule['value'] != rowData[obj.rule['map']]){
        return true;
      }
      return false;
  }
  redirectDrillDown(rowData:any ,i: any){
    console.log('selectedFilterParams',this.selectedFilterParams);
    let drillobject = _.find(this.diagData.header,{'map':rowData.DIAG_NAME});
    if(drillobject == undefined){
      drillobject = _.find(this.diagData.header,(i)=>{ return i.drilldown != null;})
    }
    let drillDownRuleObj = JSON.parse(drillobject.drilldown);
    let obj:any;
    if(_.isArray(drillDownRuleObj)){
      let isFound = false;
        _.each(drillDownRuleObj,(ii:any)=>{
         let  isFound = this.checkCondition(ii,rowData);
           if(isFound){
              obj = ii.drillDown;
           }
        })
    }else{
      obj = drillDownRuleObj;
    }

    _.each(obj.screenFilter,(i:any) => {
      if(i.name){
        if(i.row_data_key=='TAX_YEAR'){
          i.value = rowData[i.row_data_key]+'';
        }else if(rowData[i.row_data_key]){
            i.value = rowData[i.row_data_key];
        }
      }
    });
    let gridFilter:any= [];
    if (obj.filters && obj.filters.length) {
      
      _.each(obj.filters,(value:any, key:any) => {
          let _value:any;
              _value = rowData[value];

          if (_value && (_value.length || _.isNumber(_value))) {
              let obj:any = {};
              obj.predicate = value;
              obj.value = [];
              obj.type = "array";
              obj.reverse = false;
              obj.operator = "[]";
              obj.value.push(_value);
              this.gridFilters.gridFilters.push(obj);
          }
      });
      obj['gridFilters'] = this.gridFilters.gridFilters;
    }
    this.drillDownObj = obj;
    //this.gridFilters.gridFilters = obj.filter;
    this.gridFilters = obj;
    console.log('i',i);
    this.summaryDiagService.drillDown.isDrillDown = true;
    this.summaryDiagService.drillDown.selectedFilterParms = this.selectedFilterParams;
    this.gridFilters.filterParams = this.selectedFilterParams;
    
    this.loadDrillDownScreen.emit(this.gridFilters);
  }
  filterOptions:any =[];
  createFilterOptions(col:any){
    let filterArray:any = [];
    console.log('col',col);
    let  obj:any = {};
      _.each(this.diagData.data,(i:any)=>{
          if(i[col.map] && col.map != 'object_id'){
            if(filterArray.length == 0 || _.filter(filterArray,{value:i[col.map]}).length==0){
              filterArray.push({'key':col.map,'value':i[col.map]} );
          }
        }
      });
      if(this.filterOptions && (this.filterOptions.length==0 || _.filter(this.filterOptions,{key:col.map}).length == 0)){
        let obj:any = {};
          obj['selected_'+col.map] = [];
          obj['key'] = col.map;
          obj['values']= filterArray;
          obj['width']= col.width;
          obj['filterUserInput'] = '';
        this.filterOptions.push(obj);

      }
      console.log('filterOptions',this.filterOptions);
  }
  headerFilteroptions:any;
  colSearch:any = [];
  filterMultiSelectCol(event:any,col:any,index:any){
      let filtered:any=[];
      filtered = _.cloneDeep(this.diagData.safeCopyArr);
      console.log('event',event);
      if(this.mutlSelect['_results'][index].overlayVisible){
        setTimeout(()=>{
          this.mutlSelect['_results'][index].hide();
        },10)
      }
      console.log('quesr list',this.mutlSelect);
      if(event.value && event.value.length>0){
        if(this.colSearch && (this.colSearch.length==0 || _.filter(this.colSearch,(ii:any)=> {return event.itemValue ?  ii.predicate == event.itemValue.key : ii.predicate == event.value[0].key}).length ==0)){
          let obj:any = {};
          obj['predicate'] = event.itemValue ? event.itemValue.key : event.value[0].key;
          obj['value'] = [];
          _.each(event.value,(o:any)=>{
            obj.value.push(o.value);
          })
          this.colSearch.push(obj);
        }else{
          _.each(this.colSearch,(ii:any)=>{
           
            let key:any;
            key = event.itemValue ?  event.itemValue.key : event.value[0].key
            if(ii.predicate == key){
              //ii.value = event.value;
              ii.value = [];
              _.each(event.value,(kk:any)=>{
                ii.value.push(kk.value)
              })
            }
          })
        }
      }else{
          this.colSearch = _.filter(this.colSearch,(kk:any)=>{ return kk.predicate != col.key});
      }
      console.log('this.colSear',this.colSearch);
      if(this.colSearch && this.colSearch.length>0){
        _.each(this.colSearch,(kk:any)=>{
          filtered = this.findByValues(filtered, kk.predicate,kk.value)
        });
      }
      
        this.diagData.data = filtered;         
        console.log('filtered',this.diagData) ;   
        /* _.each(this.filterOptions,(ii)=>{         

          //if((ii.key != this.colSearch[0]['predicate']) || ii.key != undefined ){
          if(((this.colSearch && this.colSearch.length>0)&& (ii.key != this.colSearch[0]['predicate']))){
            let filterArray:any = [];
            _.each(this.diagData.data,(row)=>{
              if(_.filter(filterArray,{value:row[ii.key]}).length==0){
                filterArray.push({'key':ii.key,'value':row[ii.key]} );

              }
            })
            ii.values = filterArray;
          }
        })  */
  }

  getFilterOptionByID(event:any,col: any) {
   let filterArray:any = [];
    _.each(this.diagData.data,(i:any)=>{
          if(i[col.key]){
            if(filterArray.length == 0 || _.filter(filterArray,{value:i[col.key]}).length==0){
              filterArray.push({'key':col.key,'value':i[col.key]} );
          }
        }
      });
      return filterArray;
  }
  findByValues(collection:any, property:any, values:any){
      return _.filter(collection, function(item) {
        return _.includes(values, item[property]);
    });
  }


  onModalClick(){
      if(this.isDropDownOpen){
        console.log('I am clicked on modal');
        _.each(this.mutlSelect['_results'],(i)=>{
          if(i.overlayVisible){
              i.hide();
          }
        })
      }
  }
  isDropDownOpen:boolean = true;
  openMultiDropDown(index:any){
    this.isDropDownOpen= !this.isDropDownOpen;
  }
}


