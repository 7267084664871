import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActionResponse} from '../_models/action-response.model';
import {SaveActionResponse} from '../_models/save-action-response.model';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private URLs = {
    actionUrl: '/loadJsonObject?action_id=',
    saveActionUrl: '/saveJsonObject?action_id='
  };

  constructor(private httpClient: HttpClient) {
  }

  getActionData(baseUrl: string, actionId: number, clientKey: number = 0, params: HttpParams): Observable<ActionResponse> {
    const headers = new HttpHeaders();
    headers.append('client_key', clientKey.toString());

    const url = `${baseUrl}${this.URLs.actionUrl}${actionId}`;

    return this.httpClient.get<ActionResponse>(url, {
      withCredentials: true,
      params: params
    });
  }

  saveActionData(baseUrl: string, actionId: number, clientKey: number = 0, params: HttpParams): Observable<SaveActionResponse> {
    const headers = new HttpHeaders();
    const url = `${baseUrl}${this.URLs.saveActionUrl}${actionId}`;
    const formData = new FormData();
    headers.append('client_key', clientKey.toString());
    // currently, no save action calls requires below params,
    // however they are still required by the saveJsonObject API
    // Abdul: moved these hardcoded values before setting the params to be able to override them from the caller
    formData.set('tax_year', '2021');
    formData.set('scenario', '40');
    formData.set('jcd_key', '500');

    params.keys().forEach((key: string) => {
      const value = params.get(key);
      if (value) {
        formData.set(key, value);
      }
    });
    return this.httpClient.post<SaveActionResponse>(url, formData, {
      withCredentials: true
    });
  }
}
