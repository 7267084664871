import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ActionService} from '../../_services/action.service';
import {ActionResponse} from '../../_models/action-response.model';
import {DSTSharedService} from '../../../DST/shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class DfMeDropdownService {

  constructor(private httpClient: HttpClient,
              private actionService: ActionService,
              private dstSharedService: DSTSharedService) {
  }

  loadMEs(taxYear: string, customActionCode?: number | null): Observable<[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'tax_year': taxYear
      }
    });

    if (customActionCode) {
      return this.actionService.getActionData(this.dstSharedService.baseURLs.api, customActionCode, this.dstSharedService.clientKey, params)
        .pipe(
          map((data: ActionResponse) => {
            return data.jsonObject;
          })
        );
    } else {
      return new Observable<[]>();
    }
  }

}
