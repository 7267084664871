import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldModule } from 'appkit-angular/form-field';
import { FormsModule } from '@angular/forms';
import { SelectorModule } from 'appkit-angular/selector';
import { ProgressModule } from 'appkit-angular/progress';
import { FilterModule } from 'appkit-angular/filter';
import { ToggleModule } from 'appkit-angular/toggle';
import {ButtonModule} from 'appkit-angular/button';
import { DatePickerModule } from 'appkit-angular/date-picker';
import { TableModule } from 'appkit-angular/table';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { LoadingModule } from 'appkit-angular/loading';
import {ResponsiveTabModule} from 'appkit-angular/responsive-tab';
import { ModalModule } from 'appkit-angular/modal';
import { CheckboxModule } from 'appkit-angular/checkbox';
import { TooltipModule } from 'appkit-angular/tooltip';
import {AppNgAppkitModule} from '../app-ng-appkit.module';

import { F1118SchdCComponent } from './components/f1118-schd-c/f1118-schd-c.component';



@NgModule({
  declarations: [F1118SchdCComponent],
  imports: [
    CommonModule,
    FormFieldModule,
    FormsModule,
    SelectorModule,
    ProgressModule,
    FilterModule,
    ToggleModule,
    ButtonModule,
    DatePickerModule,
    TableModule,
    AppNgMaterialModule,
    LoadingModule,
    ResponsiveTabModule,
    ModalModule,
    CheckboxModule,
    TooltipModule,
    AppNgAppkitModule
  ],
  exports: [
    F1118SchdCComponent
  ]
})
export class FtcModule { }
