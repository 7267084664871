import { Injectable } from '@angular/core';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user:User|null = null;

  constructor() { }

  setUser(user:User){
    if(user.role && user.sso && user.sso.length > 0 && user.role.length >0 ){
      this.user = user;
    }
  }

  getUser(){
    return this.user;
  }

  isValidUser(roles:Array<string>){
    if(this.user && roles.indexOf(this.user.role.toLowerCase()) >= 0 ) {
      return true;
    } 
    return false;
  }

}
