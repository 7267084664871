<ap-tabs-group [(selectedIndex)]="uploadTabIndex">
  <ap-tab [label]="'Attachments List'">
    <div class="d-flex flex-wrap">
      <div class="flex-fill a-modal-mt-50">
        <div class="a-mb-20 d-flex">
          <input aria-label="value seven" class="a-text-input a-mr-10 w-auto flex-fill" #searchInput
            (input)="searchAttachments(searchInput.value)" placeholder="Search Attachments">
        </div>
        <div class="a-color-dark a-font-12 a-btn-md a-mt-30 a-mb-10">{{(attachments$ | async)?.length}}
          Attachments</div>
        <ul class="a-list a-pr-30">
          <li class="a-list-item d-flex align-items-center a-border-bt a-py-10 justify-content-between"
            *ngFor="let file of attachments$ | async">
            <div>
              <div class="a-h6">{{file.FILE_NAME + '.' + file.FILE_TYPE}}</div>
              <div class="a-h6 font-weight-normal text-truncate">Uploaded By: {{file.UPDATED_BY}}</div>
              <div class="a-text-caption a-text-9e9 font-weight-normal">Uploaded on: {{file.UPDATED_ON}}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <ap-button class="a-mr-10" [config]="{isPrimary: true}" (onBtnClick)="downloadFileFromS3(file)">
                  <span class="a-loading a-mr-10" *ngIf="file.isDownloading"></span>
                  <span class="appkiticon icon-download-light-fill a-btn-icon"
                    *ngIf="!file.isDownloading"></span>Download
                </ap-button>
                <ap-button class="a-mr-10" [config]="{isSecondary: true}" *ngIf="s3FolderManagerInput.canDelete"
                  (onBtnClick)="deleteAttachment(file)">
                  <span class="a-loading a-primary a-mr-10" *ngIf="file.isDeleting"></span>
                  <span class="appkiticon icon-delete-fill a-btn-icon" *ngIf="!file.isDeleting"></span>Delete
                </ap-button>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>
  </ap-tab>
  <ap-tab [label]="'Upload'"
    *ngIf="((attachments$ | async)?.length === 0 || s3FolderManagerInput.canUploadMultipleFiles) && (s3FolderManagerInput && !s3FolderManagerInput.isReadOnlyMode)">
    <gtw-s3-file-upload [s3FileUploadInput]='s3FileUploadInput'
      (on-s3-file-upload)="onFileUpload($event)"></gtw-s3-file-upload>
  </ap-tab>
</ap-tabs-group>
