import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenVideo } from '../../../screen-video-container/screen-video-list/screen-video';

@Component({
  selector: 'gtw-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss']
})
export class VideoItemComponent implements OnInit {

  @Input('video')
  video!:ScreenVideo;

  constructor(private domSanitizer:DomSanitizer) { }

  ngOnInit(): void {
  }

  safeURL(imageAsBase64String:string){
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, '+ imageAsBase64String);
  }


}
