<!--<div id="overlay">-->
<!--  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">-->
<!--  </ap-loading>-->
<!--</div>-->
<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="false" (onSaveClick)="save()" [disabled]="false" [displayNotification]="showDREMsg" [notifMsg]="DREMsg"
                               (onCancelClick)="clear()">
</dst-create-disclosure-actions>
<div class="form-template-wrapper" [ngClass]="showDREMsg ? 'msg-expanded' : ''">
  <div [formGroup]="form189">
    <dst-template-header [parentFormGroup]="form189" (formOptionChanged)="onFormOptionChanged($event)"
      [overwriteFormName]="'STATEMENT REQUIRED PURSUANT TO SECTION 332'" [dropDownOptions]="headerOptions"
      [sameLineText]="'BY A CORPORATION RECEIVING A LIQUIDATING DISTRIBUTION'" [allowOptional]="true"
      (consolGroupChanged)="OnConsolGroupChanged($event)"></dst-template-header>
    <ol style="margin: 15px;">
      <li>Parties
        <!-- <div formGroupName="transferorDetails"> -->
        <div class="ap-form-field-my-wrapper">
          <ol class="inner-list">
            <li>
              <dst-template-leid-selector [label]="'Please specify the LEID of the Transferee (Recipient)'"
                [labelId]="'101'" (onSelectedLEChanged)="onTransfereeChanged($event)" class="required-field">
              </dst-template-leid-selector>
              <!-- <div class="a-form-error error-leid" *ngIf="transferee === undefined">
              Please specify the Transferee
            </div> -->
              <div class="entity-details">
                <span>Name of Transferee:</span><span class="template-color-blue">{{!transferee ? '' :
                  transferee.ENTITY}}</span>
              </div>
              <div class="entity-details">
                <span>EIN:</span><span class="template-color-blue">{{!transferee ? '' :
                  (transferee.EIN ? transferee.EIN : 'N/A')}}</span><span>, Recipient&nbsp;a</span>
              </div>
              <div class="entity-details">
                <span></span><span class="template-color-blue">{{!transferee ? '' :
                  transferee.COUNTRYINC}}</span><span>; Corporation and</span>

              </div><!--  + (transferee | taxtype: transferee?.TAXTYPECODE: entityNameChangeOnDate) -->
            </li>
            <li>
              <dst-template-leid-selector
                [label]="'Please specify the LEID of the Transferor (the Liquidating Corporation)'" [labelId]="'100'"
                (onSelectedLEChanged)="onTransferorChanged($event)" class="required-field">
              </dst-template-leid-selector>

              <!-- </div> -->
              <!-- <div class="a-form-error error-leid" *ngIf="transferor === undefined">
              Please specify the Transferor
            </div> -->
              <div class="entity-details">
                <span>Name of Transferor:</span><span class="template-color-blue">{{!transferor ? '' :
                  transferor.ENTITY}}</span>
              </div>
              <div class="entity-details">
                <span>EIN:</span><span class="template-color-blue">{{!transferor ? '' : (transferor.EIN ? transferor.EIN : 'N/A')}}</span><span>,
                  the
                  Liquidating Corporation, a
                  Transferor</span>
              </div>
              <div class="entity-details">
                <span><span class="template-color-blue">{{!transferor ? '' : transferor.COUNTRYINC }}</span>&nbsp;Corporation</span>
              </div> <!-- +(transferor | taxtype: transferor?.TAXTYPECODE: entityNameChangeOnDate) -->
            </li>
          </ol>
        </div>
        <!-- <div formArrayName="transferors"> -->
        <!-- <div class="ap-form-field-my-wrapper" *ngFor="let transferor of transferors.controls; let i = index;"> -->


        <p>Pursuant to Code Section 332, <span class="template-color-blue">{{!transferor ? '' :
            transferor.ENTITY}}</span> liquidated into Recipient, <span class="template-color-blue">{{!transferee ? ''
            :
            transferee.ENTITY}}</span>.</p>
        <!-- </div> -->

        <!-- </div> -->
      </li>
      <li>The date(s) of all distribution(s) (whether or not pursuant to the plan) by the liquidating corporation during
        the current tax year;
        <div formGroupName="datesTransfer">
          <ap-date-picker *ngIf="nameChangeChecked" format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 1)" [(ngModel)]="defaultDate"
            formControlName="dateTransfer1" (click)="onDPOpen()" class="required-field">
          </ap-date-picker>
          <!-- <div class="a-form-error"
            *ngIf="defaultDate === null && liquidationDate2 === null && liquidationDate3 === null">
            Please specify the Liquidation Date
          </div> -->
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 2)" [(ngModel)]="liquidationDate2"
            (click)="onDPOpen()" formControlName="dateTransfer2">
          </ap-date-picker>
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event,3)" [(ngModel)]="liquidationDate3"
            (click)="onDPOpen()" formControlName="dateTransfer3">
          </ap-date-picker>
        </div>
      </li>
      <li>Properties Transferred:
        <div formGroupName="propertiesDetails">
          <p>
            The fair market value and basis of assets of the liquidating corporation that have been or will be
            transferred
            to any recipient corporation, aggregated as follows:
          </p>
          <ol class="inner-list">
            <li>
              <div class="list-content a-textarea-box a-field-length-validation form-inlines">
                <ap-form-field
                  [label]="'Importation property distributed in a loss importation transaction, as defined in Section 1.362-3(c)(2) and (3) (except that \'section 332 liquidation\' is substituted for \'section 362 transaction\'), respectively:'"
                  [id]="'310'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true"
                  [(ngModel)]="importationProp" formControlName="importationProp" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content a-textarea-box a-field-length-validation d-inline-block">
                <ap-form-field
                  [label]="'Property with respect to which gain or loss was recognized on the distribution:'"
                  [id]="'330'" [type]="'textarea'" [withNumberValidation]="true" [maxLength]="1000" [value]=""
                  [withButton]="true"
                  [(ngModel)]="gainLossPropertyDesc" formControlName="gainLossAffectedProp" class="text-box">
                </ap-form-field>
              </div>
            </li>
            <li>
              <div class="list-content">
                <span class="a-form-label d-inline-block" style="cursor: default; font-size: inherit;">Property not described in Section
                  1.332-6(a)(3)(i) or (ii):</span>

                <table class="table-layout">
                  <tbody>
                    <tr>
                      <td colspan="3">
                        <ap-button [btnText]="'Import Domestic or Foreign Trial Balance Data'" *ngIf="showImport"
                          class="import-btn a-mr-10" [config]="{ isSecondary:true }" (onBtnClick)="import()" [isDisabled]="!defaultDate">
                        </ap-button>
                        <ap-checkbox [text]="'Report TB Data on Total Net Assets basis'" class="a-mx-10 import-btn"
                          (onCheck)="total($event)" *ngIf="showTotal">
                        </ap-checkbox>
                      </td>
                      <td>
                        <ap-selector [items]="propertyValBasisOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select a basis **'" (onSelectedChange)="selectBasisOption($event)"
                          formControlName="propertyValBasis" class="show-label required-field-dropdown field-full-width">
                        </ap-selector>
                        <!-- <div class="a-form-error" *ngIf="selectedBasis === -1">
                          Please select a Basis section
                        </div> -->
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td><span>{{selectedBasisName}} Basis to the Corporation, the liquidating company</span></td>
                      <td><span>Fair Market Value on Date of Distribution</span></td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td class="header-column"><span>Description</span></td>
                      <td>
                        <ap-selector [items]="currencyOptions" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyOneChange($event)"
                          formControlName="currency1" class="show-label required-field-dropdown currency-dropdown field-full-width">
                        </ap-selector>
                        <!-- <div class="a-form-error" *ngIf="selectedCurrency === 0">
                          Please select Fair Market value Currency type
                        </div> -->
                      </td>
                      <td>
                        <ap-selector [items]="currencyOptions2" [type]="'SingleSelector'" [searchMethod]="'inline'"
                          [placeholder]="'** Select Currency **'" (onSelectedChange)="currencyTwoChange($event)"
                          formControlName="currency2" class="show-label required-field-dropdown currency-dropdown field-full-width">
                        </ap-selector>
                      </td>
                    </tr>
                    <ng-container formArrayName="additionalPropsDetails">
                      <tr *ngFor="let p of additionalPropsDetails.controls; let i = index;">
                        <ng-container [formGroupName]="i">
                          <td style="width: 3%;">
                            <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                              [btnSize]="'small'" *ngIf="i > 0" (onBtnClick)="removeAdditionalPropDetailsAt(i)">
                              <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                            </ap-button>
                          </td>
                          <td style="width: 3%;">
                            <ap-button class="btn-plus-minus" [btnType]="'a-btn-gray'" [config]="{isFloating: true}"
                              [btnSize]="'small'" (onBtnClick)="addAdditionalPropDetails()">
                              <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                            </ap-button>
                          </td>
                          <td style="width: 50%;">
                            <ap-form-field [label]="''" [id]="i + 'a'" [type]="'input'" formControlName="description">
                            </ap-form-field>
                          </td>
                          <td style="width: 22%;">
                            <ap-form-field [label]="''" [id]="i + 'b'" [type]="'inputNumber'" [funType]="'typeTwo'"
                              formControlName="transferorBasis" (onChangeEvent)="onChangeNumber($event, i)"
                              class="number-field amount-column">
                            </ap-form-field>
                          </td>
                          <td style="width: 22%;">
                            <ap-form-field [label]="''" [id]="i + 'c'" [type]="'inputNumber'" [funType]="'typeTwo'"
                              formControlName="fairMarketVal" class="number-field amount-column">
                            </ap-form-field>
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
        </div>
      </li>
      <li>The date and control number of any private letter ruling(s) issued by the Internal Revenue Service in
        connection
        with the liquidation:
        <div id="step4" formGroupName="privateLetterRul">
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 4)" (click)="onDPOpen()"
            [(ngModel)]="liquidationDate4" formControlName="dateIssued">
          </ap-date-picker>
          <ap-form-field [id]="'401'" [type]="'input'" formControlName="controlNum" [(ngModel)]="controlNum">
          </ap-form-field>
        </div>
      </li>
      <li>The plan of complete liquidation was adopted on
        <div formGroupName="liabilitiesAssumed">
          <ap-date-picker format="yyyy-MM-DD" type="singleCalendar" size="medium" [minDate]="beginDate"
            [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 6)" (click)="onDPOpen()"
            [(ngModel)]="adoptedDate" formControlName="dateTransfer1" class="required-field">
          </ap-date-picker>
        </div>
      </li>
      <li>
        <div formGroupName="liquidationState">
          <div class="liq-state-options">
            <span>The recipient corporation hereby represents that:</span>
            <div class="col-md-3">
              <ap-selector [items]="liquidationStateOptions" [type]="'SingleSelector'"
                [placeholder]="'** Please select the liquidation state **'" (onSelectedChange)="liquidationChange($event)"
                formControlName="liquidationState" class="show-li-label required-field-dropdown">
              </ap-selector>
            </div>
          </div>
          <!-- <div class="a-form-error" *ngIf="currentLiquidation === 0">
            Please specify Liquidation Status
          </div> -->
          <div *ngIf="currentLiquidation === 1" class="liquidation-line">
            <span class="pr-2">The liquidation was completed on </span>
            <ap-date-picker format="yyyy-MM-DD" (click)="onDPOpen()" type="singleCalendar" size="medium"
              [minDate]="beginDate" [maxDate]="endDate" [isTitleLeft]="true" (onChange)="dateChange($event, 5)"
              [(ngModel)]="acquiredDate" formControlName="liquidationStateDate">
            </ap-date-picker>

          </div>
          <div class="mt-2" *ngIf="currentLiquidation === 2">
            <span>
              The liquidation is not complete and the taxpayer has timely filed Form 952, Consent to Extend the Time to
              Assess Tax Under Section 332(b).
            </span>

          </div>
          <br />
          <div class="supp-info-wrapper" formGroupName="suppInfo">
            <div>Additional Information:</div>
            <ap-form-field [inlineBlock]="true" [id]="'550'" [type]="'textarea'"
              [(ngModel)]="info" formControlName="info">
            </ap-form-field>
          </div>
          <!-- <div class="ap-form-field-my-wrapper" formGroupName="branchInfo">
              <ap-form-field [label]="'Please specify the LEID of the Branch Entity'" [id]="'551'" [type]="'input'" formControlName="branchLEID">
              </ap-form-field>
            </div> -->
          <dst-template-leid-selector [isBranchEntity]="true" [label]="'Please specify the LEID of the Branch Entity:'"
            [labelId]="'3'" (onSelectedLEChanged)="onBranchEntityChanged($event)" class="entity-label">
          </dst-template-leid-selector>
          <div class="entity-details">
            <span>Branch Entity Name:</span><span class="template-color-blue">{{!branchEntity ? '' :
              branchEntity.ENTITY}}</span>
          </div>
          <div class="leid-list" style="margin-top: 15px;">
            <span>LEIDs: </span>
            <div>
              <span *ngIf="transferee" class="template-color-blue">{{ transferee.LEID }}</span><span *ngIf="transferor">
                / </span><span *ngIf="transferor" class="template-color-blue">{{ transferor.LEID }}</span>
            </div>
          </div>
        </div>
      </li>
    </ol>
  </div>
</div>
<ap-modal #showChildDisclosureOption *ngIf="showChildDisclosureOptionModal" appModalId="child-disclosure-option">
  <div #modalRef2 slot="content" (click)="$event.stopPropagation();" style="height: 100%; width: 100%; padding-top: 40px;">
    <div class="modal-dialog" style="max-width: 31.25rem; margin: 0 auto;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">Proceed to Form 926?</span>
          </div>
        </div>
        <div class="a-modal-body pt-0 pb-0">
          <p>
            This disclosure has been flagged as a potential outbound transfer of property to a Foreign Corporation requring the completion of Form 926. See Form 926 instructions and Treas. Reg. Sec.1.367(e)-2(b)(2)(i)(c)(2) or (iii)(D) for further details.
          </p>
          <p class="m-0">Proceed to Form 926?</p>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-gray a-mr-10" data-dismiss="modal"
                  (click)="onProceedToChildFormOK('false')">Cancel
          </button>
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
                  (click)="onProceedToChildFormOK('true')">OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
