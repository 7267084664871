<div class="main-container">
  <div class="grid-container" id="332report-grid-container" style="height: calc(100vh - 124px);">
      <div class="top-toolbar">
          <div class="float-left ml-2">
            <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear" 
              (onChange)="onTaxYearChange()">
            </p-dropdown>
            <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
              (selectedMes)="selectedMeChanged($event)">
            </gtw-df-me-dropdown>
            <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
            <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
          </div>
      </div>
  
      <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName"
        [loading]="loading" [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
        styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
        [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">
  
          <ng-template pTemplate="header" let-columns>
              <tr [style.height]="'45px'">
                <ng-container *ngFor="let col of columns; index as i">
                  <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
          </ng-template>
  
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="height: 70px;">
                  <td style="width: 145px;">
                    <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENT_ID }}</span></a>
                  </td>
                  <td style="width: 105px;">
                    <span>{{ rowData.PROJECT_NAME }}</span>
                  </td>
                  <td style="width: 155px;">
                    <span>{{ rowData.PRIMARY_LEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARY_ENTITY }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARY_BUSINESS }}</span>
                  </td>
                  <td style="width: 145px;">
                    <span>{{ rowData.FILLING_GROUP }}</span>
                  </td>
                  <td style="width: 175px;">
                    <span>{{ rowData.SECONDARY_LEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.SECONDARY_ENTITY }}</span>
                  </td>
                  <td style="width: 195px;">
                    <span gtwLineSplitter [inputString]="rowData.LIQUIDATIONDATE_VALUE" [sepearator]="'||'" [formatter]="'date'">
                      {{ rowData.LIQUIDATIONDATE_VALUE }}
                    </span>
                  </td>
                  <td style="width: 275px;">
                    <span>{{ rowData.IMPORTATIONPROPERTYDESC }}</span>
                  </td>
                  <td style="width: 335px;">
                    <span>{{ rowData.GAINLOSSPROPERTYDESC }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 375px;">
                    <span gtwLineSplitter [inputString]="rowData.DESCRIPTION" [sepearator]="'||'">
                      {{ rowData.DESCRIPTION }}
                    </span>
                  </td>
                  <td style="width: 155px; text-align: end;">
                    <span gtwLineSplitter [inputString]="rowData.BASISAMT_VALUE" [sepearator]="'||'" [formatter]="'decimal'">
                      {{ rowData.BASISAMT_VALUE }}
                    </span>
                  </td>
                  <td  style="width: 155px;">
                    <span>{{ rowData.BASIS_CURRENCY }}</span>
                  </td>
                  <td style="width: 125px;">
                    <span gtwLineSplitter [inputString]="rowData.BASIS_NAME" [sepearator]="'||'">
                      {{ rowData.BASIS_NAME }}
                    </span>
                  </td>
                  <td style="width: 150px; text-align: end;">
                    <span gtwLineSplitter [inputString]="rowData.FMVAMT_VALUE" [sepearator]="'||'" [formatter]="'decimal'">
                      {{ rowData.FMVAMT_VALUE }}
                    </span>
                  </td>
                  <td style="width: 155px;">
                    <span>{{ rowData.FMV_CURRENCY }}</span>
                  </td>
                  <td style="width: 200px;">
                    <span gtwLineSplitter [inputString]="rowData.PLR_VALUE" [sepearator]="'||'">
                      {{ rowData.PLR_VALUE }}
                    </span>
                  </td>
                  <td style="width: 185px;">
                    <span gtwLineSplitter [inputString]="rowData.ADOPTEDDATE_VALUE" [sepearator]="'||'" [formatter]="'date'">
                      {{ rowData.ADOPTEDDATE_VALUE }}
                    </span>
                  </td>
                  <td style="width: 235px;">
                    <span gtwLineSplitter [inputString]="rowData.ACQUIREDDATE_VALUE" [sepearator]="'||'" [formatter]="'date'">
                      {{ rowData.ACQUIREDDATE_VALUE }}
                    </span>
                  </td>
                  <td style="width: 180px;">
                    <span> {{ rowData.EFFECTIVEDATE_VALUE | date: 'yyyy-MM-dd' }} </span>
                  </td>
                  <td style="width: 220px;">
                    <span gtwLineSplitter [inputString]="rowData.LIQUIDATIONSTATUS" [sepearator]="'||'">
                      {{ rowData.LIQUIDATIONSTATUS }}
                    </span>
                  </td>
                  <td style="width: 220px;">
                    <span>{{ rowData.ADDITIONALTEXT }}</span>
                  </td>
              </tr>
          </ng-template>
  
      </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>