import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { MessageService } from 'projects/gtw-elements/src/app/DST/shared/services/message.service';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StmtAttrData, StmtData } from '../../../../../../shared/models/dst-form-state.model';
import { TrackerTabsService, TrackerTabState } from '../../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, dstLookupEntity, EntityNameChange} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'dst-view-template-sec368-id188',
  templateUrl: './view-template-sec368-id188.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec368-id188.component.scss']
})
export class ViewTemplateSec368Id188Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  startLoader: boolean = false;
  disableActions: boolean = false;
  stmtData!: StmtData;
  stmtAttrData: any = [];
  loaded: boolean = false;
  attrData: any = [];
  form188!: FormGroup;
  currencyOptions: any = [{data: []}];
  propertyValBasisOptions: any = [{data: []}];
  attMap: any;
  prEntityNull: boolean = false;
  secEntityNull: boolean = false;
  lookupdata: any = [];
  shdspLeid: boolean = false;
  pickListVal: string = '';
  selBasisTxt: string = '';
  descTotalAvl: boolean = false;
  selBasisTxtOthr: string = '';
  s3Uploading: boolean = false;
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  shCorpTypeTxt: string = '';
  primaryEntityName: string = '';
  secondaryEntityName: string = '';

  constructor(private formBuilder: FormBuilder,
              private sharedService: CreateDisclosureSharedService,
              private dstSharedService: DSTSharedService,
              private taxTypePipe: TaxTypePipe,
              private trackerTabsService: TrackerTabsService,
              private messageService: MessageService) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false; // reset immediately after consumed
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form188 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    //load currencies
    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.currencyOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });
    //PropertyValBasis Options
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        //console.log(data);
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //load Stmt data
    this.sharedService.getLoadStatementData(188)
      .subscribe((response: StmtData) => {
        this.stmtData = response;
        this.primaryEntityName = this.stmtData.primaryentity || '';
        this.secondaryEntityName = this.stmtData.secondaryentity || '';

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push(undefined);
          }

        });
        if(this.attMap['LiquidationDate'] && this.attMap['LiquidationDate'][0]){
          const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
  
          trackerTab!.parentTransDate = this.attMap['LiquidationDate'][0];

          this.messageService.broadcast(trackerTab?.id!,"368-on-transDate-set");
        }
        console.log('After loop Attr data' + JSON.stringify(this.attMap));

        //check primary and secondary
        if (this.stmtData.primaryentityid === null || this.stmtData.primaryentityid === undefined) {
          this.prEntityNull = true;
        }
        if (this.stmtData.secondaryentityid === null || this.stmtData.secondaryentityid === undefined) {
          this.secEntityNull = true;
        }
        //check totals are available
        if (this.attMap['Desc'] && this.attMap['Desc'].length >= 1) {
          this.descTotalAvl = true;
        }
        //if (this.attMap['Desc1'] && this.attMap['Desc1'].length >= 1) { this.basisAmt1Total = true }

        this.propertyValBasisOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['Basis'] !== undefined) && (val.id == this.attMap['Basis'][0])) {
            this.selBasisTxt = val.text;
          }
          if ((this.attMap['Basis'] !== undefined) && (val.id == this.attMap['Basis'][1])) {
            this.selBasisTxtOthr = val.text;
          }

        });
        //pickList val
        //Select pickList Val
        this.sharedService.getLiquidationStateDropdown(4)
          .subscribe((data: DropDownType[]) => {
            data.forEach(element => {
              if (this.attMap['CodeSection'] && element.id == this.attMap['CodeSection'][0]) {
                this.pickListVal = element.text;
              }

            });

            //shDsp transferor
            if (this.attMap['SHdspTransferorLEID'] && this.attMap['SHdspTransferorLEID'][0] !== undefined) {
              this.sharedService.getDSTEntityInfo(this.attMap['SHdspTransferorLEID'][0])
                .subscribe((data: dstLookupEntity[]) => {
                  if (data) {
                    this.shdspLeid = true;
                    this.lookupdata.push(data[0]);
                    this.updateEntitiesInfo(this.lookupdata[0].LEID, 3).subscribe(response => {
                      
                      // override primary entity name
                      this.loadOtherEntitiesInfoAndUploadPdf();
                      
                    }); //corpTypeTxt
                  }
                }, () => {

                }, () => {

                });
            }else{
               // override primary entity name
               this.loadOtherEntitiesInfoAndUploadPdf();
            }
          });
        

      }, () => {

      }, () => {

      }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    //   setTimeout(() => {    
    //     const html = document.querySelector('#temp368form188');
    //     const htmlContent = html!.outerHTML;
        
    //     this.sharedService.getFormStateByFormId(188)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
    //   });
    // 
    });

  }

  private loadOtherEntitiesInfoAndUploadPdf() {
    forkJoin([
      this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1),
      this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2)
    ]).subscribe(([response1, response2]) => {
      setTimeout(() => {
        const html = document.querySelector('#temp368form188');
        const htmlContent = html!.outerHTML;
        this.sharedService.getFormStateByFormId(188)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
      });
    }, (error: HttpErrorResponse) => {

      console.log(error);
      this.dstSharedService.showAlert('error', error.message);

    }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });
  }

  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 'Section368-StatementPursuant.pdf', this.stmtData.statementid)
    .subscribe((response: any) => {
      isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
      isS3upload && (this.s3Uploading =  false);
    }, (eMsg: string) => {
      console.log(eMsg);
      isS3upload && (this.dstSharedService.showAlert('error', eMsg));
    });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp368form188');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  //getCurrencyText
  getSelCurrency(valFor?: number): string {
    let retVal = '';
    this.currencyOptions[0].data.forEach((val: DropDownType) => {
      if (this.attMap['Currency'] && val.id == this.attMap['Currency'][0] && valFor == 0) {
        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][1] && valFor == 1) {

        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][2] && valFor == 2) {

        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][3] && valFor == 3) {

        retVal = val.text;
      }

    });

    return retVal;
  }

  totalAmt(amtFor?: String): number {
    let totalAmt = 0;
    if (amtFor == 'BasisAmt' && this.attMap['BasisAmt'] !== undefined) {
      this.attMap['BasisAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });

    } else if (amtFor == 'FMVAmt' && this.attMap['FMVAmt'] !== undefined) {
      this.attMap['FMVAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });
    } else if (amtFor == 'BasisAmt1' && this.attMap['BasisAmt1'] !== undefined) {

      this.attMap['BasisAmt1'].forEach((amt: number) => {
        //console.log(amt);
        totalAmt += Number(amt);
      });
    } else if (amtFor == 'FMVAmt1' && this.attMap['FMVAmt1'] !== undefined) {
      this.attMap['FMVAmt1'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });
    }
    return totalAmt;
  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   this.startLoader = true;
  //   this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
  //     leid,
  //     this.sharedService.getTransDateStr(this.attMap['LiquidationDate'][0],1),
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //       if (entityType === 1) { //primary entity
  //         (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
  //         this.primaryCorpTypeTxt = corpType;
  //       } else if (entityType === 2) { //secondary entity
  //         (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
  //         this.secondaryCorpTypeTxt = corpType;
  //       } else if (entityType === 3) { //share holder entity
  //         (response[0].LE_NAME) && (this.lookupdata[0].ENTITY = response[0].LE_NAME);
  //         this.shCorpTypeTxt = corpType;
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.startLoader = false;
  //   });
  // }
  
  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    this.startLoader = true;
    return this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
      leid,
      this.sharedService.getTransDateStr(this.attMap['LiquidationDate'][0],1),
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
          if (response && response[0]) {
              //override entity type
              const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
              if (entityType === 1) { //primary entity
                (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
                this.primaryCorpTypeTxt = corpType;
              } else if (entityType === 2) { //secondary entity
                (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
                this.secondaryCorpTypeTxt = corpType;
              } else if (entityType === 3) { //share holder entity
                (response[0].LE_NAME) && (this.lookupdata[0].ENTITY = response[0].LE_NAME);
                this.shCorpTypeTxt = corpType;
              }
		       }   
        })
      })
    )
  }

  dummyFunc(event: any): void {

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
