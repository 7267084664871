import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFooterComponent } from './app-footer.component';
import { FooterModule } from 'appkit-angular/footer';
import {ButtonModule} from 'appkit-angular/button';


@NgModule({
  declarations: [AppFooterComponent],
  imports: [
    CommonModule,
    FooterModule,
    ButtonModule
  ],
  exports: [AppFooterComponent]
})
export class AppFooterModule { }
