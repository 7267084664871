import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { F8865SchM2K1SourcingComponent } from './components/f8865-sch-m2-k1-sourcing/f8865-sch-m2-k1-sourcing.component';
import {AppNgMaterialModule} from '../app-ng-material.module';
import {AppNgAppkitModule} from '../app-ng-appkit.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SeparatorDirective } from './directives/separator.directive';
import { MatInputCommifiedDirective } from './directives/mat-input-commified.directive';
import { SplAllocComponent } from './components/spl-alloc/spl-alloc.component';
import {SixDigitDecimaNumberDirective} from "./directives/six-digit-decimal-number.directive";
import { SourcingAmountsComponent } from './components/sourcing-amounts/sourcing-amounts.component';
import { PartnershipDashboardComponent } from './components/partnership-dashboard/partnership-dashboard.component';
import { ToggleModule } from 'appkit-angular/toggle';
import { SelectorModule } from 'appkit-angular/selector';
import { TableModule } from 'primeng-lts/table';
import {MatListModule} from '@angular/material/list'
import {ListboxModule} from 'primeng-lts/listbox';
import { ButtonModule } from 'primeng-lts/button';
import { DiagnosticDashboardModule } from '../diagnostic-dashboard/diagnostic-dashboard.module';
import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { numberWithCommasPipe } from './components/partnership-dashboard/numbers-with-commas.component';
import { DfAssignEntityComponent } from './components/df-assign-entity/df-assign-entity.component';

@NgModule({
    declarations: [F8865SchM2K1SourcingComponent, SeparatorDirective, MatInputCommifiedDirective, SplAllocComponent, SixDigitDecimaNumberDirective, SourcingAmountsComponent, PartnershipDashboardComponent, numberWithCommasPipe, DfAssignEntityComponent],
    exports: [
        SplAllocComponent,
        SourcingAmountsComponent,
        PartnershipDashboardComponent
    ],
    imports: [
        CommonModule,
        AppNgMaterialModule,
        AppNgAppkitModule,
        FormsModule,
        ToggleModule,
        SelectorModule,
        TableModule,
        MatListModule,
        ListboxModule,
        DiagnosticDashboardModule,
        SharedComponentsModule,
        ButtonModule,
        ReactiveFormsModule
    ]
})
export class PartnershipModule { }
