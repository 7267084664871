import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../../app/shared/_models/gtw-elements-base-urls.model';
import { FxAmountSyncService } from './fx-amt-sync.service';
import lodfilter  from 'lodash/filter';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";


@Component({
  selector: 'gtw-fx-amt-sync',
  templateUrl: './fx-amt-sync.component.html',
  styleUrls: ['./fx-amt-sync.component.scss']
})
export class FxAmtSyncComponent implements OnInit {
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;

  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private filingKey :number = 0;
  private checkLockedObj: any = [];
  private gridData: any = [];
  private scenarioCode: string = '';
  enableButton: boolean = true;
  private modalName: string = '';

  clob_settings: { tax_year: number; scenario: number; jcd_key: number; batch_process: string;} = {
    tax_year: 0,
    scenario: 0,
    jcd_key: 0,
    batch_process: 'N'
  };
  clob_data: any = [];

  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('grid-data')
  set _gridData(gridData:any){
    try{
    this.gridData = JSON.parse(gridData);
  }catch(error){
		console.log("Not an valid object");
		}
  }

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }


  @Input('tax-year')
  set _taxYear(taxYear:number){
  try{
		this.taxYear = taxYear;
		}catch(error){
		console.log("Not an valid object");
		}
  }

  @Input('modal-name')
  set _modalName(modalName:string){
    this.modalName = modalName;
    //his.init();
  }

  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    //his.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    //this.init();
  }

  @Input('screen-key')
  set _screenKey(screenKey:number) {
    this.screenKey = screenKey;
    //this.init();
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    try{
    this.ssoId = ssoId;
    this.init();
    }catch(error){
		console.log("Not an valid object");
		}
  }

  @Output('close-fxamtconfirm-info')
  closeFxAmtconfirmInfo = new EventEmitter<string>();

  @Output('save-fxamtsync-info')
  saveFxAmtSyncInfo = new EventEmitter<string>();

  subscriptions:Subscription = new Subscription() ;

  constructor(private FxAmountSyncService: FxAmountSyncService, private _snackBar: MatSnackBar) { }

  checkInputType():boolean{
		if(Number.isInteger(parseInt(this.taxYear+"")) &&
		  Number.isInteger(parseInt(this.scenario+'')) &&
		  Number.isInteger(parseInt(this.jcdKey +'')) &&
		  Number.isInteger(parseInt(this.screenKey+'')) &&
		  Number.isInteger(parseInt(this.ssoId+""))&&
      Number.isInteger(parseInt(this.filingKey+""))
    )
		{
		  return true;
		}
	
		return false;
	}


  ngOnInit(): void {
  }

  init() {   
    if(this.checkInputType()){
      }
      console.log("gridData------",this.gridData);
    } 


    cancel() {
      console.log("click close");
      this.closeFxAmtconfirmInfo.emit('close-fxamtconfirm-info');
      }

    syncfxAmount(){
        this.enableButton = false;
        let processName = 'RUN_SYNC_TB_AMT_CURR_COL';
        this.clob_settings = {
          tax_year: this.taxYear,
          scenario: this.scenario,
          jcd_key: this.jcdKey,
          batch_process: 'N',
        }; 
        let gridObj = {};
        if (this.gridData != undefined && this.modalName == 'triggerFxAmtColumnSync') {
          
          for(var key in this.gridData){
            /*let gridObj : {object_id: string; combination_key: number;} =
            {
              object_id: '',
              combination_key : 0
            };
            
            gridObj["object_id"] = this.gridData[key].data.object_id;
            gridObj["combination_key"] = this.gridData[key].data.COMBINATION_KEY;
*/
                this.clob_data.push([{
                  object_id: this.gridData[key].data.object_id,
                  combination_key: this.gridData[key].data.COMBINATION_KEY
                }
              ]);
           }
        }
        else if(this.modalName == 'triggerFxAmtColumnBatchSync')
        {
            this.clob_settings.batch_process= 'Y';
        }
       
      let updateSubscription = this.FxAmountSyncService.syncFxAmountData(this.baseURLs.api, this.taxYear, this.scenario, this.jcdKey, this.ssoId, processName, 
                                                            this.clob_settings, this.clob_data, this.baseGlobalParams, this.checkLockedObj).subscribe(
            (data) => {
              if (data.callSuccess == "1") {
                this.FxAmountSyncService.openSnackBar('Data Synced Successfully','gtw-snackbar--green');
                this.saveFxAmtSyncInfo.emit('save-fxamtsync-info');
              } else {
                this.FxAmountSyncService.openSnackBar('Error syncing Data. '+data.errorMessage, 'gtw-snackbar--red');
                this.enableButton = true;
              }
            },  error => { this.FxAmountSyncService.openSnackBar('Error syncing Data. '+error.error.errorMessage, 'gtw-snackbar--red'); },
            () => { console.log("Update Completed");}
          );
            this.subscriptions.add(updateSubscription);
     };
}
