import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StatementTrackerService} from '../../../../statement-tracker.service';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';
import {ModalComponent} from 'appkit-angular/modal';

@Component({
  selector: 'dst-template-sec332-id189',
  templateUrl: './template-sec332-id189.component.html',
  styleUrls: ['../template-shared.scss', './template-sec332-id189.component.scss']
})
export class TemplateSec332Id189Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form189!: FormGroup;
  startLoader: boolean = false;
  attMap: any = {};
  statementId: string = '';
  formInvalid: boolean = true;
  selectedOption!: string;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  beginDate!: Date | string;
  endDate!: Date | string;
  defaultDate: any = '';
  isSection304Related: boolean;
  showImport: boolean = false;
  showTotal: boolean = false;
  totalCalc: number = 0;
  currentLiquidation: number = 0;
  copy: any = [];
  info: string = '';
  dcnId!: number;

  importationProp: string = '';
  gainLossPropertyDesc: string = '';
  controlNum: string = 'None.';

  liquidationDate2: any = '';
  liquidationDate3: any = '';
  liquidationDate4: any = '';
  acquiredDate: any = '';
  adoptedDate: any = '';

  selectedBasis: number = -1;
  selectedBasisName: string = '';

  propertyValBasisOptions: any = [{data: []}];

  currencyOptions: any = [{data: []}];
  currencyOptions2: any = [{data: []}];
  selectedCurrency: number = 0;
  selectedCurrency2: number = 0;

  showDREMsg: boolean = false;
  DREMsg: string = '';
  saveJsonObject: any;
  saveParams: { [param: string]: string } = {};
  showChildDisclosureOptionModal: boolean = false;
  @ViewChild('showChildDisclosureOption') showChildDisclosureOption!: ModalComponent;

  liquidationStateOptions: any[] = [{data: []}];

  section304ExchangeType: any[] = [{
    data: [{
      id: 0,
      text: 'cash'
    }, {
      id: 1,
      text: 'the Transferee\'s Notes'
    }]
  }];

  headerOptions: any[] = [
    {
      data: [
        {
          id: 0,
          text: '-'
        },
        {
          id: 1,
          text: '(A)'
        }
      ],
    }
  ];
  entityNameChangeOnDate: boolean = false;
    nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
    this.isSection304Related = false;
  }

  get transferors(): FormArray {
    return this.form189.get('transferorDetails')?.get('transferors') as FormArray;
  }

  get additionalPropsDetails(): FormArray {
    return this.form189.get('propertiesDetails')?.get('additionalPropsDetails') as FormArray;
  }

  get liabilityDetails(): FormArray {
    return this.form189.get('liabilitiesAssumed')?.get('liabilityDetails') as FormArray;
  }

  get currency1(): AbstractControl | undefined {
    return this.form189.get('propertiesDetails')?.get('currency1') as AbstractControl;
  }

  get currency2(): AbstractControl | undefined {
    return this.form189.get('propertiesDetails')?.get('currency2') as AbstractControl;
  }

  ngOnInit(): void {
    // default values for testing only
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    // this.defaultDate = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    // this.liquidationDate2 = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    // this.liquidationDate3 = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    // this.liquidationDate4 = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    // this.acquiredDate = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    // this.adoptedDate = new Date(this.sharedService.getActiveFormState()?.taxYear || 2020, 0, 1);
    this.form189 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      // transferorDetails: this.formBuilder.group({
      //   transferee: ['', Validators.required],
      //   transferors: this.formBuilder.array([
      //     this.formBuilder.control('')
      //   ])
      // }),
      datesTransfer: this.formBuilder.group({
        // default date setup here is not working in appkit date picker, use ngModel instead
        dateTransfer1: ['', Validators.required],
        dateTransfer2: [''],
        dateTransfer3: ['']
      }),
      propertiesDetails: this.formBuilder.group({
        importationProp: [''],
        gainLossAffectedProp: [''],
        propertyValBasis: [''],
        currency1: [''],
        currency2: [''],
        additionalPropsDetails: this.formBuilder.array([
          this.formBuilder.group({
            description: [''],
            transferorBasis: [''],
            fairMarketVal: ['']
          })
        ]),
      }),
      privateLetterRul: this.formBuilder.group({
        dateIssued: ['', Validators.required],
        controlNum: ['']
      }),
      liabilitiesAssumed: this.formBuilder.group({
        dateTransfer1: [''],
        controlNum: ['']
      }),
      liquidationState: this.formBuilder.group({
        liquidationState: [''],
        liquidationStateDate: [''],
        suppInfo: this.formBuilder.group({
          info: ['']
        }),
        branchInfo: this.formBuilder.group({
          branchLEID: ['']
        })
      })
    });

    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            value: element.id,
            text: element.text,
            selected: false //element.id === 3 // default to GAAP
          });
        });
        this.propertyValBasisOptions = JSON.parse(JSON.stringify(this.propertyValBasisOptions));
        // const gaapExist: boolean = this.propertyValBasisOptions[0].data.findIndex((d: any) => d.value === 3) >= 0;
        // if (gaapExist) {
        //   this.selectedBasis = 3;
        //   this.selectedBasisName = 'GAAP';
        // }
      }, () => {

      }, () => {
        if (this.attMap['Basis']) {
          const updatedData = this.updateDropdownList(this.propertyValBasisOptions, this.attMap['Basis'][0]);
          this.propertyValBasisOptions = updatedData.dropdownData;
          this.propertyValBasisOptions = JSON.parse(JSON.stringify(this.propertyValBasisOptions));
          this.selectedBasis = updatedData.index;
          // this.stockType = this.stockType));
        }
      });

    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach((element, index) => {
          this.currencyOptions[0].data.push({
            value: element.id,
            text: element.text,
            index: index,
          });
          this.currencyOptions2[0].data.push({
            value: element.id,
            text: element.text,
            index: index,
          });
        });
        const usdIndex: number = data.findIndex((c: any) => c.id === 84);
        if (usdIndex >= 0) {
          this.currencyOptions[0].data[usdIndex].selected = true;
          this.currencyOptions2[0].data[usdIndex].selected = true;
          this.currencyOptions = JSON.parse(JSON.stringify(this.currencyOptions));
          this.currencyOptions2 = JSON.parse(JSON.stringify(this.currencyOptions2));
          this.selectedCurrency = this.currencyOptions[0].data[usdIndex].value;
          this.selectedCurrency2 = this.currencyOptions2[0].data[usdIndex].value;
        }
      }, () => {

      }, () => {
        if (this.attMap['Currency']) {
          this.attMap['Currency'].forEach((element: any, index: number) => {
            switch (index) {
              case 0:
                let updatedData = this.updateDropdownList(this.currencyOptions, element);
                this.currencyOptions = updatedData.dropdownData;
                this.selectedCurrency = updatedData.index;
                break;
              case 1:
                const updatedData2 = this.updateDropdownList(this.currencyOptions2, element);
                this.currencyOptions2 = updatedData2.dropdownData;
                this.selectedCurrency2 = updatedData2.index;
                break;
            }
          });
        }
      });

    this.sharedService.getLiquidationStateDropdown(1)
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.liquidationStateOptions[0].data.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        if (this.attMap['LiquidationStatus']) {
          const updatedData = this.updateDropdownList(this.liquidationStateOptions, this.attMap['LiquidationStatus'][0]);
          this.liquidationStateOptions = updatedData.dropdownData;
          this.currentLiquidation = updatedData.index;
        }
      });

    this.loadStmtData();
  }

  ngAfterViewInit(): void {
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData(189);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      console.log("before timeout--->" + this.defaultDate);
      setTimeout(() => {
        console.log("inside timeout--->" + this.defaultDate);
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.secondaryleid);
              console.log("filled secondary leid--->" + this.defaultDate);
              break;
            case 1:
              element.fillLEID(data.primaryleid);
              console.log("ifilled primary leid--->" + this.defaultDate);
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      },1000);
      this.populateFields(data);
      console.log("populated fields--->" + this.defaultDate);

    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;

      setTimeout(() => {
        this.leidComponents.toArray()[1].fillLEID(this.sharedService.leid);
        this.sharedService.checkImportBtnRequired(this.sharedService.leid, taxYear, this.sharedService.csStatementId.toString())
          .subscribe((data: boolean) => {
            console.log(data);
            this.showImport = data;
          }, () => {

          }, () => {

          });
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }

      // if (stmt.attributeValue !== undefined) {

      this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      // }
    });
    if (this.attMap['statementOption'] && this.attMap['statementOption'][0]) {
      this.headerOptions = [
        {
          data: [
            {
              id: 0,
              text: '-'
            },
            {
              id: 1,
              text: '(A)',
              selected: this.attMap['statementOption'][0] === '(A)'
            }
          ],
        }
      ];
    }

    if (this.attMap['LiquidationDate']) {
      this.attMap['LiquidationDate'].forEach((element: any, index: number) => {
        switch (index) {
          case 0:
            if (element !== '' && element !== undefined) {
              this.defaultDate = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
              console.log("date is set--->" + this.defaultDate);
            }
            break;

          case 1:
            if (element !== '' && element !== undefined) {
              this.liquidationDate2 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;

          case 2:
            if (element !== '' && element !== undefined) {
              this.liquidationDate3 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;
          case 3:
            if (element !== '' && element !== undefined) {
              this.liquidationDate4 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;
        }
      });
    }
    if (this.attMap['AcquiredDate'] && this.attMap['AcquiredDate'][0]) {
      this.acquiredDate = new Date(this.attMap['AcquiredDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));

    }

    if (this.attMap['AdoptedDate'] && this.attMap['AdoptedDate'][0]) {
      this.adoptedDate = new Date(this.attMap['AdoptedDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    }

    if (this.attMap['Desc'] && this.attMap['Desc'].length) {
      this.additionalPropsDetails.clear();
      this.attMap['Desc'].forEach((element: string, index: number) => {
        this.additionalPropsDetails.push(this.formBuilder.group({
          description: [element],
          transferorBasis: [this.attMap['BasisAmt'][index]],
          fairMarketVal: [this.attMap['FMVAmt'][index]]
        }));
      });
    }


    if (this.attMap['importationPropertyDesc']) {
      this.importationProp = this.attMap['importationPropertyDesc'];
    }
    if (this.attMap['gainLossPropertyDesc']) {
      this.gainLossPropertyDesc = this.attMap['gainLossPropertyDesc'];
    }

    if (this.attMap['stndadditionaltext']) {
      this.info = this.attMap['stndadditionaltext'];
    }

    if (this.attMap['PLR']) {
      this.controlNum = this.attMap['PLR'];
    }
    this.cdref.detectChanges();
  }

  updateDropdownList(currentOptions: any, element: string) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      if (parseInt(element) === el.value) {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = el.value;
      } else {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  import(): void {
    this.startLoader = true;
    this.sharedService.getImport(this.transferor?.LEID, this.sharedService.getActiveFormState()?.taxYear, this.sharedService.getTransDateStr(this.defaultDate))
      .subscribe((data: any) => {
        if (data.length) {
          this.additionalPropsDetails.clear();
          data.forEach((element: any) => {
            this.additionalPropsDetails.push(this.formBuilder.group({
              description: [element.DESCRIPTION],
              transferorBasis: [element.AMOUNTS],
              fairMarketVal: [element.AMOUNTS]
            }));
            this.totalCalc += element.AMOUNTS;
          });
          this.showImport = false;
          this.showTotal = true;
          // this.propertyValBasisOptions[0].data[2].selected = true;

        }
        this.startLoader = false;
      }, () => {

      }, () => {

      });
  }

  total(event: any): void {
    this.additionalPropsDetails.clear();
    if (event.srcElement.checked) {
      this.additionalPropsDetails.push(this.formBuilder.group({
        description: ['Net Assets'],
        transferorBasis: [this.totalCalc],
        fairMarketVal: [this.totalCalc]
      }));
    } else {
      this.totalCalc = 0;
      this.import();
    }
  }

  onFormOptionChanged(selectedOptionID: number): void {
    const selected: any = this.headerOptions[0].data.find((o: any) => {
      return o.id === selectedOptionID;
    });
    this.selectedOption = selected.text;
  }

  addTransferor(): void {
    this.transferors.push(this.formBuilder.control(''));
  }

  removeTransferorAt(index: number): void {
    this.transferors.removeAt(index);
  }

  liquidationChange(event: any): void {
    this.currentLiquidation = event.selected[0].value;
    this.acquiredDate = (this.currentLiquidation === 1 && this.adoptedDate) ? new Date(this.adoptedDate.replace(/-/g, '\/').replace(/T.+/, '')): '';
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.showImport = true;
    this.showTotal = false;
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    if (this.transferor && this.transferee && (this.selectedBasis !== -1)) {
      this.formInvalid = false;
    }
    // check for DRE entities
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor, this.transferee]);
    this.showDREMsg = !!this.DREMsg;
    // check for name change
    console.log("ontrasnferor changed--->" + this.defaultDate);
    if (this.defaultDate && this.transferor?.LEID) {
      this.entityNameChangeOnDate = true;
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.sharedService.getFormData()?.statementid.toString(),
        this.transferor.LEID,
        this.sharedService.getTransDateStr(this.defaultDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferor!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferor!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.defaultDate) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onTransfereeChanged(selectedTransferee: DSTEntityDetails | null): void {
    this.transferee = selectedTransferee;
    // check for DRE entities
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee, this.transferor]);
    this.showDREMsg = !!this.DREMsg;
    // check for name change
    console.log("on transferee changed--->" + this.defaultDate);
    if (this.defaultDate && this.transferee?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.sharedService.getFormData()?.statementid.toString(),
        this.transferee.LEID,
        this.sharedService.getTransDateStr(this.defaultDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferee!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferee!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.defaultDate) {
      this.nameChangeChecked = true;

    } else {
      this.entityNameChangeOnDate = false;
    }
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  addAdditionalPropDetails(): void {
    this.additionalPropsDetails.push(this.formBuilder.group({
      description: [''],
      transferorBasis: [''],
      fairMarketVal: ['']
    }));
  }

  removeAdditionalPropDetailsAt(index: number): void {
    this.additionalPropsDetails.removeAt(index);
  }

  addLiabilityDetails(): void {
    this.liabilityDetails.push(this.formBuilder.group({
      description: [''],
      amount: ['']
    }));
  }

  removeLiabilityDetailsAt(index: number): void {
    this.liabilityDetails.removeAt(index);
  }

  currencyOneChange(event: any): void {
    this.selectedCurrency = event.selected[0].value;
    this.currencyOptions2[0].data = JSON.parse(JSON.stringify(event.items[0].data));
    this.selectedCurrency2 = event.selected[0].value;
  }

  currencyTwoChange(event: any): void {
    this.selectedCurrency2 = event.selected[0].value;
  }

  selectBasisOption(event: any) {
    this.selectedBasis = event.selected[0].value;
    this.selectedBasisName = event.selected[0].text;
    if (this.transferor && this.transferee && (this.selectedBasis !== -1)) {
      this.formInvalid = false;
    }
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferee, this.transferor])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      this.saveParams = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': this.form189.get('liquidationState')?.get('suppInfo')?.value.info,
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };
      const propertyDesc = this.importationProp;
      const gainLossPropertyDesc = this.gainLossPropertyDesc;
      const desc: string[] = [];
      const basisAmt: string[] = [];
      const fairMarketVal: string[] = [];
      this.additionalPropsDetails.controls.forEach(element => {
        desc.push(element.value.description);
        basisAmt.push(element.value.transferorBasis !== null ? element.value.transferorBasis.toString() : '');
        fairMarketVal.push(element.value.fairMarketVal !== null ? element.value.fairMarketVal.toString() : '');
      });
      const currencies = [this.selectedCurrency.toString(), this.selectedCurrency2.toString()];
      const plr = this.form189.get('privateLetterRul')?.value.controlNum;

      this.saveJsonObject = {
        'statementOption': this.selectedOption === '-' ? '' : this.selectedOption,
        'LiquidationDate': [this.defaultDate !== null ? this.defaultDate.startDate ? this.defaultDate.startDate.format('YYYY-MM-DD') : this.defaultDate : '', this.liquidationDate2 !== null ? this.liquidationDate2.startDate ? this.liquidationDate2.startDate.format('YYYY-MM-DD') : this.liquidationDate2 : '', this.liquidationDate3 !== null ? this.liquidationDate3.startDate ? this.liquidationDate3.startDate.format('YYYY-MM-DD') : this.liquidationDate3 : '', this.liquidationDate4 !== null ? this.liquidationDate4.startDate ? this.liquidationDate4.startDate.format('YYYY-MM-DD') : this.liquidationDate4 : ''],
        'importationPropertyDesc': propertyDesc[0] ? propertyDesc : '',
        'gainLossPropertyDesc': gainLossPropertyDesc[0] ? gainLossPropertyDesc : '',
        'Basis': this.selectedBasis.toString(),
        'Currency': currencies,
        'Desc': desc[0] ? desc : [''],
        'BasisAmt': basisAmt,
        'FMVAmt': fairMarketVal,
        'PLR': plr[0] ? plr : '',
        'AdoptedDate': this.adoptedDate !== null ? this.adoptedDate.startDate ? this.adoptedDate.startDate.toISOString().split('T')[0] : this.adoptedDate : '',
        'AcquiredDate': this.acquiredDate !== null ? this.acquiredDate.startDate ? this.acquiredDate.startDate.toISOString().split('T')[0] : this.acquiredDate : '',
        'LiquidationStatus': this.currentLiquidation + '',
        'stndadditionaltext': this.info || ''
      };

      // save as placeholder
      if (!this.transferee) {
        this.proceedToSave(this.saveParams, this.saveJsonObject);
      } else {
        // query database if the selected entities satisfy an optional 926 disclosure requirement
        this.sharedService.checkfor332To926AutoGenStmt(this.transferor!.LEID, this.transferee!.LEID, formState.taxYear.toString())
          .subscribe((response: any) => {
            if ((trackerTab.id === StatementTrackerService.ID_CREATE_NEW) && response[0]?.IS926REQUIRED === 'YES') {
              // prompt user whether they'd wish to fill out 926
              this.showChildDisclosureAlert();
            } else {
              this.saveJsonObject.f332OutBoundTo926 = 'false';
              this.proceedToSave(this.saveParams, this.saveJsonObject);
            }
          });
      }
    }
  }

  private proceedToSave(params: { [p: string]: string }, jsonObject: object): void {
    this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
      if (data) {
        this.dstSharedService.showAlert('success', 'Form Save Success!');
        this.startLoader = false;
        this.sharedService.setSavedStatementId(data.statementId);
        const childStatements = data.childStatement;
        if (childStatements?.length) {
          this.sharedService.openChildStatements(childStatements, this.onSave);
        } else {
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }
    }, () => {
      this.dstSharedService.showAlert('error', 'Error!');
      this.startLoader = false;
    });
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1: {
        this.defaultDate = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.transferor!, this.transferee!]);
        if (!leids) {
          return;
        }
        this.startLoader = true;
        this.entityNameChangeOnDate = true;
        this.sharedService.checkForEntityNameChange(this.sharedService.getFormData()?.statementid.toString(),
          leids,
          this.sharedService.getTransDateStr(this.defaultDate, 1),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferor!, this.transferee!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });
        break;
      }
      case 2: {
        this.liquidationDate2 = event.startDate.format('YYYY-MM-DD');
        break;
      }
      case 3: {
        this.liquidationDate3 = event.startDate.format('YYYY-MM-DD');
        break;
      }
      case 4: {
        this.liquidationDate4 = event.startDate.format('YYYY-MM-DD');
        this.controlNum = '';
        break;
      }
      case 5: {
        this.acquiredDate = event.startDate.format('YYYY-MM-DD');
        break;
      }
      case 6: {
        this.adoptedDate = event.startDate.format('YYYY-MM-DD');
        break;
      }
      default: {
        break;
      }
    }
  }

  cancel(): void {

  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  onChangeNumber(event: any, index: number): void {
    // let index = event.event.srcElement.id.charAt(6);
    this.additionalPropsDetails.at(index).setValue({
      description: this.additionalPropsDetails.at(index).value.description,
      transferorBasis: event.value,
      fairMarketVal: event.value
    });
    const val: any = (document.getElementById(`input-${index}c`));
    val.value = event.value;
  }

  isFormValid(placeholder: boolean): boolean {
    if (placeholder) {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify the Transferor');
        return false;
      }
    } else {
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify the Transferee');
        return false;
      }
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify the Transferor');
        return false;
      }
      if (this.defaultDate == null && this.liquidationDate2 == null && this.liquidationDate3 == null) {
        this.dstSharedService.showAlert('error', 'Please specify the Transaction date');
        return false;
      }
      if (this.selectedCurrency === 0) {
        this.dstSharedService.showAlert('error', 'Please select Fair Market value Currency type');
        return false;
      }
      if (this.selectedBasis === -1) {
        this.dstSharedService.showAlert('error', 'Please select a Basis section');
        return false;
      }
      if (this.currentLiquidation === 0) {
        this.dstSharedService.showAlert('error', 'Please specify Liquidation Status');
        return false;
      }
    }

    return true;
  }

  showChildDisclosureAlert() {
    this.showChildDisclosureOptionModal = true;
      setTimeout(() => {
        this.showChildDisclosureOption.showMore('child-disclosure-option');
        // styling fix for gtw-ui
        const modalEle: Element | null = document.querySelector('[ng-reflect-id="child-disclosure-option"]');
        modalEle?.classList.add('gtw-web-components', 'dst-modal-sm');
        modalEle?.children[1].classList.add('p-0');
      });
  }

  onProceedToChildFormOK(gen926: string) {
    this.showChildDisclosureOption.closeModalOk('child-disclosure-option');
    setTimeout(() => {
      this.saveJsonObject.f332OutBoundTo926 = gen926;
      this.proceedToSave(this.saveParams, this.saveJsonObject);
    });
  }

}
