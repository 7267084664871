import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActionResponse} from '../../../../../../shared/_models/action-response.model';
import {ActionService} from 'projects/gtw-elements/src/app/shared/_services/action.service';
import {DSTConsolGroup} from './template-header.component';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateHeaderService {

  constructor(private httpClient: HttpClient,
              private actionService: ActionService,
              private dstSharedService: DSTSharedService,
              private trackerTabsService: TrackerTabsService) {

  }

  getHeaderDropdown(leid: string | undefined): Observable<DSTConsolGroup[]> {
    const activeTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!activeTab) {
      throw Error('No active tab!');
    }

    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'leid': leid ? leid : '',
        'tax_year': activeTab.getActiveFormState().taxYear.toString()
      }
    });
    const actionKey = 32253;
    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, actionKey, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          } else {
            throw Error('Something went wrong while retrieving the filing groups, please try again!');
          }
        })
      );
  }
}
