<!-- <mat-progress-bar *ngIf="isLoading"
    color="primary"
    mode="indeterminate">
    </mat-progress-bar> -->
    <div id="documentId" style="background-color: #f2f1f1;">
        <div class="doc-modal-title-wrapper">
            <h4 class="title">{{documentData?documentData.hd_msg_title: ''}} (Documentation)</h4>
           <!--  <button *ngIf=" attachmentList.length>0  && !fromHome &&user_access>=3 && showTabContent == 0"class="gtw-web-components gtw-ele a-btn a-btn-secondary a-btn-lg editbutton"(click)=editMode($event)
            >  Edit</button>
            <button *ngIf="attachmentList.length==0  && !fromHome &&user_access>=3 && showTabContent == 0"class="gtw-web-components gtw-ele a-btn a-btn-secondary a-btn-lg editbutton"(click)=editMode($event)
                >  Create</button> -->
            <button *ngIf="!fromHome && user_access>=3 && showTabContent == 0"class="gtw-web-components gtw-ele a-btn a-btn-secondary a-btn-lg editbutton"(click)=editMode($event)
                    > {{ attachmentList.length>0 ? 'Edit' : 'Create'}} </button>
            <button  mat-icon-button aria-label="Drag Document close" style="color: #fff;">
                <mat-icon style="font-size: 30px" (click)="cancel()">close</mat-icon>
            </button>
        </div>
        <div class="upload-tabs-wrapper">
            <li (click)="openTab(0)" [ngClass]="{'active': showTabContent == 0}">Documentation</li>
            <li (click)="openTab(1)" [ngClass]="{'active': showTabContent == 1}">Videos</li>
        </div>
        <div class="doc-view-body-wrapper">
            <div *ngIf="!!documentData.hd_body && showTabContent == 0">
                <div class=" doc-view-modal" >
                    <iframe id="frame" sandbox="allow-same-origin allow-scripts" seamless="true" srcdoc="{{documentData?documentData.hd_body: ''}}" 
                    style="width: 100%; height: calc(100vh - 180px);"class="doc-view-body-wrapper"></iframe>
                </div>
            </div>
            <div *ngIf="(isEditorShow && !fromHome) && (showTabContent == 0)">
                <ap-editor class="doc-editor" [isFull]="true" [editorId]="'Editor1'" style="background: white"
                    [(ngModel)]="text1" (change)="onChange($event)"  aria-placeholder="Please add the document"    
                ></ap-editor>
            </div>
            <div *ngIf="showTabContent == 0 && attachmentList.length==0 && !isEditorShow">
                <p>No help Document. </p>
            </div>
            
            <ng-container *ngIf= "showTabContent == 1">
               <gtw-screen-videos [screen-key]=screenKey [client-key]=clientKey 
                    [base-url]=baseUrl 
                    (cancelDocModal)="cancel()"
                    [from-home]=fromHome></gtw-screen-videos>
            </ng-container>
        </div>
        <section class=' gtw-web-components upload-doc-actions' *ngIf="isEditorShow && !fromHome && showTabContent == 0">
            <button style="margin-right:10px" class=" gtw-ele a-btn a-btn-primary a-btn-lg" (click)="saveDocument($event,text1)">
                Save
            </button>
            <button style="margin-right:10px" class="gtw-ele a-btn a-btn-primary a-btn-lg" (click)="clearContent($event)">
                Clear
            </button>
             <button style="margin-right:10px" class="gtw-web-components gtw-ele a-btn a-btn-primary a-btn-lg"  (click)="deleteDoc()">
                Delete
            </button>
        </section>
    </div>

  



