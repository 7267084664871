import {Component, EventEmitter, Injectable, Input, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTStatement} from '../../../models/dst-statement.model';
import {StatementTrackerService} from '../../../../statement-tracker/statement-tracker.service';
import {SaveActionResponse} from 'projects/gtw-elements/src/app/shared/_models/save-action-response.model';
import {MenuPosPayload} from '../smartMenuPos.directive';
import {StmtIdBtnService} from '../stmt-id-btn.service';
import {DSTSharedService} from '../../../dst-shared.service';
import {DSTMarkAsOption} from '../dst-mark-as-template/dst-mark-as-template.component';

@Component({
  selector: 'dst-dropdown-menu',
  templateUrl: './dst-dropdown-menu.component.html',
  styleUrls: ['./dst-dropdown-menu.component.scss'],
  animations: [
    trigger('dropdownOpenClose', [
      state('true', style({width: '225px', height: 'unset'})),
      state('*', style({width: '0px', height: '0px'})),
      transition('* => true', animate(300))
    ]),
    trigger('createDropdownOpenClose', [
      state('true', style({width: '200px', height: 'unset'})),
      state('*', style({width: '0px', height: '0px'})),
      transition('* => true', animate(300))
    ]),
  ],
})
export class DSTDropdownMenuComponent implements OnInit {

  @Input('rowObject') row!: DSTStatement;
  @Input() mode!: string;
  @Input() menuPos!: MenuPosPayload;
  // @Input('modalTemplateRef') modalRef!: ModalComponent;
  @Output() showDeleteConfirm: EventEmitter<null>;
  @Output() showUnmatchConfirm: EventEmitter<null>;
  @Output() taggingProject: EventEmitter<DSTStatement>;
  @Output() copyReplicate: EventEmitter<string>;
  @Output() matchTracker: EventEmitter<number>;
  @Output() activeRowChange: EventEmitter<DSTStatement>;
  @Output() saveSuccess: EventEmitter<null>;

  dropdownMenuConfig: DSTDropdownMenuConfig[];
  createDropdownMenuConfig: DSTDropdownMenuConfig[];

  constructor(public dstSharedService: DSTSharedService,
              private statementTrackerService: StatementTrackerService,
              private stmtIdBtnService: StmtIdBtnService) {
    this.showDeleteConfirm = new EventEmitter<null>();
    this.showUnmatchConfirm = new EventEmitter<null>();
    this.taggingProject = new EventEmitter<DSTStatement>();
    this.copyReplicate = new EventEmitter<string>();
    this.matchTracker = new EventEmitter<number>();
    this.activeRowChange = new EventEmitter<DSTStatement>();
    this.saveSuccess = new EventEmitter<null>();
    // later initialized in ngOnInit() after template-bound input properties are set
    this.dropdownMenuConfig = [];
    this.createDropdownMenuConfig = [];
  }

  ngOnInit(): void {
    this.dropdownMenuConfig = [
      {
        desc: 'Reviewed',
        checkbox: true,
        iconClass: 'icon-check-mark-fill',
        actionCallback: () => {
          this.row.showDropdown = false;
          this.row.isReviewedVal = this.row.isReviewedVal === 1 ? 0 : 1;
          this.dstSharedService.setTableActionStatus(true);
          this.stmtIdBtnService.setReviewed(this.row.statementId, this.row.isReviewedVal)
            .subscribe((response: SaveActionResponse) => {
              if (response.callSuccess == 1) {
                this.dstSharedService.showAlert('success', 'Status change saved successfully!');
              } else {
                this.dstSharedService.showAlert('error', 'Something went wrong while saving your changes, please try again!');
              }
            }, (error: HttpErrorResponse) => {
              this.dstSharedService.showAlert('error', error.message);
            })
            .add(() => {
              this.dstSharedService.setTableActionStatus(false);
            });
        },
        hidden: this.mode !== 'statementTracker' || this.dstSharedService.user?.roleLevel < 2,
        disableIfFiled: this.row.IS_FILED
      }, {
        desc: 'Copy',
        iconClass: 'icon-duplicate-fill',
        actionCallback: () => {
          this.row.showDropdown = false;
          this.copyReplicate.emit('Copy');
          this.activeRowChange.emit(this.row);
        },
        hidden: this.dstSharedService.user?.roleLevel < 2,
        disableIfFiled: false
      }, {
        desc: 'Project',
        iconClass: 'icon-tag-fill',
        actionCallback: () => {
          this.row.showDropdown = false;
          this.taggingProject.emit(this.row);
          this.activeRowChange.emit(this.row);
        },
        hidden: (this.mode === 'other' || this.mode === 'diagnosticReport' || this.dstSharedService.user?.roleLevel < 2),
        disableIfFiled: this.row.IS_FILED
      }, {
        desc: 'Print',
        iconClass: 'icon-printer-fill',
        actionCallback: () => {
        },
        hidden: false,
        disableIfFiled: false
      }, {
        desc: 'Delete',
        iconClass: 'icon-delete-fill',
        actionCallback: () => {
          this.stmtIdBtnService.deleteAction = 'deactivate';
          this.activeRowChange.emit(this.row);
          this.showDSTDeleteConfirmDialog();
        },
        hidden: this.dstSharedService.user?.roleLevel < 3,
        disableIfFiled: this.row.IS_FILED
      }, {
        desc: 'Remove Tracker Match',
        iconClass: 'icon-tag-fill',
        actionCallback: () => {
          this.activeRowChange.emit(this.row);
          this.showDSTUnmatchConfirmDialog();
        },
        hidden: (this.mode !== 'statementTracker' || this.row.manualMatch !== 'Y' || this.dstSharedService.user?.roleLevel < 2),
        disableIfFiled: this.row.IS_FILED
      }, {
        desc: 'Replicate Statement(s)',
        iconClass: 'icon-tag-fill',
        actionCallback: () => {
          this.row.showDropdown = false;
          this.copyReplicate.emit('Replicate');
          this.activeRowChange.emit(this.row);
        },
        hidden: (this.mode === 'other' || this.mode === 'diagnosticReport' || this.dstSharedService.user?.roleLevel < 2),
        disableIfFiled: false
      }
    ];
    this.createDropdownMenuConfig = [
      {
        desc: 'Match Statement',
        iconClass: 'icon-convert-fill',
        actionCallback: () => {
          this.row.showDropdown = false;
          this.matchTracker.emit(this.row.csStatementId);
          this.activeRowChange.emit(this.row);
        },
        hidden: this.dstSharedService.user?.roleLevel < 2,
        disableIfFiled: this.row.IS_FILED
      }, {
        desc: 'Delete Requirement',
        iconClass: 'icon-delete-fill',
        actionCallback: () => {
          this.stmtIdBtnService.deleteAction = 'delete';
          this.activeRowChange.emit(this.row);
          this.showDSTDeleteConfirmDialog();
        },
        hidden: this.dstSharedService.user?.roleLevel < 3,
        disableIfFiled: this.row.IS_FILED
      }
    ];
  }

  ngOnDestroy() {
  }

  filterDropdownMenuConfig(config: DSTDropdownMenuConfig[]): DSTDropdownMenuConfig[] {
    return config.filter((c: DSTDropdownMenuConfig) => {
        if (c.desc === 'Remove Tracker Match') {
          return this.row.cseId;
        }
        return true;
      }
    );
  }

  showStatementOptions(): boolean {
    if (this.mode === 'customReport' || this.mode === 'diagnosticReport') {
      return true;
    } else if (!(this.row.placeHolder || this.row.statementId === '0' || this.row.ignoredId !== 0)) {
      return true;
    }
    return false;
  }

  showRequirementOptions(): boolean {
    if (this.mode === 'customReport' || this.mode === 'diagnosticReport') {
      return false;
    } else if (this.row.placeHolder || this.row.ignoredId !== 0 || this.row.statementId === '0') {
      return true;
    }
    return false;
  }

  showDSTDeleteConfirmDialog(): void {
    this.showDeleteConfirm.emit();
  }

  showDSTUnmatchConfirmDialog(): void {
    this.showUnmatchConfirm.emit();
  }

}

@Pipe({
  name: 'filterHidden'
})
@Injectable()
export class FilterPipe implements PipeTransform {

  transform(items: any[]): any[] {
    if (!items) {
      return [];
    }
    return items.filter(item => !item.hidden);
  }

}

interface DSTDropdownMenuConfig {
  desc: string;
  checkbox?: boolean;
  class?: string;
  iconClass: string;
  actionCallback: () => void;
  hidden?: boolean;
  disableIfFiled?: boolean;
}
