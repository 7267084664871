<div class="a-footer copyright" *ngIf="!showCookieBlock">
    <div class="align-items-center">
        <div class="a-panel-wrapper p-4">
            <div class="a-pwc-logo-grid a-md ml-3"></div>
            <div>
                <ap-footer [content]="footerContent"></ap-footer>
            </div>
        </div>
    </div>
</div>
<div class="a-footer cookieAcceptance" *ngIf="showCookieBlock">
    <div class="align-items-center">
        <div class="a-panel-wrapper p-3">
            <div>
                <ap-footer [content]="cookieAcceptanceContent"></ap-footer>
            </div>
            <div class="">
                <ap-button [btnText]="'I understand'" class="a-mr-10" [config]="primaryConfig" (onBtnClick)="iUnderstand()"></ap-button>
            </div>
        </div>
    </div>
</div>