import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './submission-data/dashboard/dashboard.component';
import { SubmitComponent } from './submission-data/submit/submit.component';

const routes: Routes = [
  { component:SubmitComponent, path:'submit' },
  { component:DashboardComponent, path:'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class EfileRoutingModule { }
