import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenVideo } from '../screen-video';

@Component({
  selector: 'gtw-screen-video-item',
  templateUrl: './screen-video-item.component.html',
  styleUrls: ['./screen-video-item.component.scss']
})
export class ScreenVideoItemComponent implements OnInit {

  @Input('video')
  video!:ScreenVideo;

  constructor(private domSanitizer:DomSanitizer) { }

  ngOnInit(): void {
  }

  safeURL(imageAsBase64String:string){
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, '+ imageAsBase64String);
  }

}
