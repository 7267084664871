import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import _ from 'lodash';
import { PostingPartnersService } from './posting-partners.service';
@Component({
  selector: 'gtw-posting-partners',
  templateUrl: './posting-partners.component.html',
  styleUrls: ['./posting-partners.component.scss']
})
export class PostingPartnersComponent implements OnInit {

  private scenarioCode: string = '';
  private isIssueKeyEnabled: string = '';
  private issueKey: number = 0;

  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();
  rowData: any;
  ssoId: string = '';
  private filterParams : any= {};
  public secondaryConfig = {
    isSecondary: true
  }
  public primaryConfig = {
    isPrimary: true
  }
  totalTransactions: any;
  totalLoading: boolean = true;

  @Input('filter-params')
	set _filter_params(filterParams: string){
    try{
			let params = JSON.parse(filterParams);
			if(params != undefined){
				this.filterParams = params;
        this.populateScreen();
			}
		}catch(e){
			console.log("Filter Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('row-data')
  set _row_data(rowData: string) {
    try {
      if (rowData != '{{ctrl.rowData}}') {
        this.initializeComplete = true;
      }
      let params = JSON.parse(rowData);

      if (params != undefined && params != '') {
        this.rowData = params;

      }
    } catch (e) {
      console.log("rowData Params not stable. Still waiting to get resolved.");
    }
  }


  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
  }

  @Input("sso-id")
  set _ssoId(ssoId: string) {
    if (!_.startsWith(ssoId, "{{")) {
      this.ssoId = ssoId;
    }
  }

  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
  form!: FormGroup;
  formData: any;
  

  constructor(private fb: FormBuilder,
    private gtwSnackbarService: GtwSnackbarService,
    private postingPartnersService: PostingPartnersService
  ) {

    this.form = this.fb.group({
      PSHIP_PTR_POST_KEY: ['', Validators.required],
      COMBINATION_KEY: ['', Validators.required],
      PTR_COMBINATION_KEY: ['', Validators.maxLength(22)],
      IS_CURRENT: ['', Validators.maxLength(250)],
      TAX_YEAR: ['', Validators.required]
    });
  }



  initializeComplete: boolean = true;
  loader: boolean = false;

  ngOnInit(): void {

  }

  populateScreen() {
    this.totalLoading = true;
    this.form.patchValue({
      PSHIP_PTR_POST_KEY: this.rowData.PSHIP_PTR_POSTING_KEY,
      COMBINATION_KEY: this.rowData.COMBINATION_KEY,
      PTR_COMBINATION_KEY: String(this.rowData.PTR_COMBINATION_KEY),
      IS_CURRENT: this.rowData.IS_CURRENT,
      TAX_YEAR: this.rowData.TAX_YEAR
    });
    let params = {
      tax_year: this.rowData.TAX_YEAR,
      scenario: this.filterParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key,
      sso_id: this.ssoId,
      pship_cc_key: this.rowData.COMBINATION_KEY,
      ptr_cc_key: this.rowData.PTR_COMBINATION_KEY
    }
    this.postingPartnersService.getTransactionCount(this.baseURLs.api, params).subscribe((data: any) => {
      if (data !== 'error') {
        console.log("Total trans data" + data.jsonObject);
        if (data.jsonObject[0].hasOwnProperty("COUNT(DISTINCTTRANS_HEADER_KEY)")){
          this.totalTransactions = data.jsonObject[0]["COUNT(DISTINCTTRANS_HEADER_KEY)"];
        }
        else{
          this.totalTransactions = 0;
        }
        this.totalLoading = false;
      }
      else {
        console.log("Error while fetching data");
        this.totalTransactions = 0;
        this.totalLoading = false;
      }
    });
  }

  save(): void {

    if (this.form.status === 'INVALID') {
      this.gtwSnackbarService.openSnackBar("Please check form details and try again", 'gtw-snackbar--red');
      return;
    }
    const formValues = this.form.value;
    delete formValues.tax_year;

    let clob_data = [{...formValues}];
    // formValues.UPDATED_ON = new Date();
    // formValues.UPDATED_BY = this.ssoId;
    let params: saveParams = {
      tax_year: this.rowData.TAX_YEAR,
      scenario: this.filterParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key
    }
    let clob_settings: saveParams = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: Number(params.jcd_key), };
    this.postingPartnersService.saveLineChanges(this.baseURLs.api, params, clob_settings, clob_data, this.ssoId, this.isIssueKeyEnabled, this.issueKey, this.scenarioCode).subscribe((data: any) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        console.log("Error while saving");
        this.loader = false;
      }
    }, () => {
      console.log("Error while saving");
      this.loader = false;
    });
  }

  cancel(): void {
    console.log("FormData: " + this.form.value);
    this.requestGridUpdate.emit('');
  }
}

export type saveParams = {
  tax_year: string | undefined;
  scenario: string | undefined;
  jcd_key: any;
}
