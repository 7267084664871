import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'dst-custom-input',
  templateUrl: './dst-custom-input.component.html',
  styleUrls: ['./dst-custom-input.component.scss']
})
export class DstCustomInputComponent implements OnInit, OnChanges {

  @Input() type: string = 'text';
  @Input() label: string = '';
  @Input() maxLength: number = 0;
  @Input() value: string | number = '';
  @Output() valChange: EventEmitter<string> = new EventEmitter<string>();

  currLength: number = 0;

  // private subject: Subject<string> = new Subject<string>();

  constructor() {
  }

  ngOnInit(): void {
    // this.subject.pipe(
    // debounceTime(300)
    // ).subscribe((value: string) => {
    //   this.valChange.emit(value);
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue !== undefined) {
      this.currLength = changes.value.currentValue.toString().length;
    }
  }

  onKeyUp(event: any): void {
    this.currLength = event.target.value?.length;
    // this.subject.next(event.target.value);
    this.valChange.emit(event.target.value);
  }

}
