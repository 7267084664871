import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionService } from '../../shared/_services/action.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionResponse } from '../../shared/_models/action-response.model';
import { DSTSharedService } from '../shared/dst-shared.service';
import { SaveActionResponse } from '../../shared/_models/save-action-response.model';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticsService {

    constructor(private httpClient: HttpClient,
        private actionService: ActionService,
        private dstSharedService: DSTSharedService) {
    }

    loadNAReport(taxYear: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32475, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- N/A reports data, please try again!');
                })
            );
    }

    loadDupsStmtReport(taxYear: string, selectedMes : string=''): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'me_string':selectedMes,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32929, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- N/A reports data, please try again!');
                })
            );
    }

    load304DiagStmtReport(taxYear: string, selectedMes : string=''): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
                'me_string':selectedMes,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32936, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- N/A reports data, please try again!');
                })
            );
    }

    loadDataCollectReport(taxYear: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32519, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- Data Collect reports data, please try again!');
                })
            );
    }

    loadTransfersReviewReport(taxYear: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'tax_year': taxYear,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32836, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- Transfers Review reports data, please try again!');
                })
            );
    }

    getDiagnosticsReviewOptions(reviewType: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'review_type': reviewType,
            }
        });

        return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32520, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: ActionResponse) => {
                    if (data.callSuccess === '1') {
                        return data.jsonObject;
                    }
                    return throwError('Something went wrong while getting the Diagnostics- Review Options data, please try again!');
                })
            );
    }

    dstUpdateReviewComment(statementId: string, reviewType: string, reviewTypeId: string, otherExpl: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                'sso_id': this.dstSharedService.user?.ssoId,
                'statementId': statementId,
                'reviewType': reviewType,
                'reviewTypeId': reviewTypeId,
                'otherExpl': otherExpl,
            }
        });

        return this.actionService.saveActionData(this.dstSharedService.baseURLs.api, 32521, this.dstSharedService.clientKey, params)
            .pipe(
                map((data: SaveActionResponse) => {
                    return data;
                })
            );
    }

}
