import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxtype'
})
export class TaxTypePipe implements PipeTransform {

  transform(data: any, taxTypeCode: string | undefined, nameChange: boolean = false): any {
    if (!data || !taxTypeCode){
      return '';
    }

    return this.getTaxTypeDesc(data, taxTypeCode.toString(), nameChange);
  }

  private getTaxTypeDesc(data: any, taxTypeCode: string, nameChange: boolean): string {
    if (taxTypeCode === 'TXN' && nameChange === true) {
      return ' Disregarded Entity';
    } else if ((taxTypeCode === 'FPS' || taxTypeCode === 'FCC' || taxTypeCode === 'ITR') && nameChange === false) {
      return ' ' + (data.ALTTAXTYPECODE === 'TXN' ? data.ALTTAXTYPE: ( data.ALTTAXTYPE?.substring(data.ALTTAXTYPE.lastIndexOf(' ')+1, data.ALTTAXTYPE.length) ) );
    } else if (taxTypeCode === 'DPS' || taxTypeCode == 'FPS') {
      return ' Partnership';
    } else if (taxTypeCode == 'TRT' || taxTypeCode == 'ITR') {
      return ' Trust';
    }
    return ' Corporation';
  }

}