<ap-notification id="dre-msg" [(show)]="displayNotification" [transition]="500" [notificationType]="'warning'">
  <ap-notification-content>
        <span class="text-left default-font">
          Warning: Diagnostic Alert. Action may be required.
        </span>
  </ap-notification-content>
  <ap-notification-expand>
    <div class="a-expanded-content a-transition-500ms" data-content="a-expand">
      <div class="a-padding-box a-shadow-bottom">
        <p class="details default-font">
          {{notifMsg}}
        </p>
      </div>
    </div>
  </ap-notification-expand>
</ap-notification>
<div class="center filled-status-text">
    {{filedStatusTxt}}
</div>
<div class="actions-container">
  <div class="left">
    <ap-button [btnText]="saveEditBtnText" class="a-mr-10 btn-save" [isDisabled]="disableAll || disabled || (dstSharedService.user.roleLevel < 2) || this.dstSharedService.isFiled"
               [config]="{isSecondary: true}"
               (onBtnClick)="save()"></ap-button>
    <ap-button [btnText]="cancelCloseBtnText" class="a-mr-10 btn-cancel" [isDisabled]="disableAll" [config]="{isSecondary: true}"
               (onBtnClick)="cancel()"></ap-button>
    <ap-button [btnText]="'S3 Upload'" class="a-mr-10 btn-upload" [config]="{isSecondary: true}" [isDisabled]="disableAll || disabledS3"
               *ngIf="!disableS3Upload && isView" (onBtnClick)="saveToS3()">

      <i class="fa fa-spin fa-spinner" *ngIf="disabledS3"></i>
    </ap-button>
    <!-- <ap-checkbox [text]="'Place Holder'" class="a-mx-10" [disabled]="isView || disablePlaceholderForChildStatements || disableAll || disabled"
                 [checked]="isPlaceholderChecked" *ngIf="!noPlaceholder"
                 (onCheck)="onPlaceholderChecked($event)">
    </ap-checkbox> -->
  </div>


  <div class="right">
    <ap-button class="a-mr-10 btn-upload" [config]="{isSecondary: true}" *ngIf="isView && (dstSharedService.user.roleLevel >= 3)"  (onBtnClick)="openAttachmentModal('show-upload-modal')">
                Add Signed Attachment
    </ap-button>
    <ap-button [btnText]="'PDF View'" class="a-mr-10 btn-print" [config]="{isSecondary: true}" [isDisabled]="disableAll || loadingPdf || statementId === 0"
               *ngIf="isView" (onBtnClick)="viewPdf()">

               <span style="padding-left: 5px;"><i class="fa fa-spin fa-spinner" *ngIf="loadingPdf"></i></span>
    </ap-button>
    <ap-button [btnText]="'Stylesheet View'" class="a-mr-10 btn-print" [config]="{isSecondary: true}" [isDisabled]="disableAll || loadingSS || statementId === 0"
               *ngIf="isView" (onBtnClick)="viewStyleSheet()">

               <span style="padding-left: 5px;"><i class="fa fa-spin fa-spinner" *ngIf="loadingSS"></i></span>
    </ap-button>
    <ap-button class="a-mr-10 btn-back" [config]="{isPrimary: true}" *ngIf="!hideBackBtn" (onBtnClick)="back()">
      <span class="appkiticon icon-arrow-left-outline"></span>&nbsp;Back
    </ap-button>
  </div>
</div>

<ap-modal #pdfViewerModal *ngIf="showPDFViewerModal" appModalId="pdf-viewer">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">

        <div class="a-modal-body" [style.height]="'80vh'">
          <gtw-form-review-pdf *ngIf="stmtDetailsLoaded" [rowData]="dstProformaData"
                               [divHeight]="'80vh'"></gtw-form-review-pdf>
        </div>

        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal"
                  (click)="closePDFViewerModalOk('pdf-viewer')">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>


<ap-modal #uploadModal *ngIf="showUploadModal" appModalId="show-upload-modal">
  <div slot="content">
    <div class="modal-dialog a-modal-xl" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">Upload Attachments</span>
          </div>
          <button aria-label="Close" class="icon-wrapper" data-dismiss="modal" (click)="closeUploadModal('show-upload-modal')">
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body">
          <ap-badge class="a-mr-10" [badgeText]="'Note: These files are not included while submitting to IRS.'" [badgeType]="'warning'"></ap-badge>
          <ap-tabs-group [(selectedIndex)]="uploadTabIndex">
            <ap-tab [label]="'Attachments List'" >
              <div class="d-flex flex-wrap">
                <div class="flex-fill a-modal-mt-50">
                  <div class="a-mb-20 d-flex">
                    <input aria-label="value seven" class="a-text-input a-mr-10 w-auto flex-fill" #searchInput (input)="searchAttachments(searchInput.value)" placeholder="Search Attachments">
                  </div>
                  <div class="a-color-dark a-font-12 a-btn-md a-mt-30 a-mb-10">{{(secondaryAttachments$ | async)?.length}}
                    Attachments</div>
                  <ul class="a-list a-pr-30">
                    <li class="a-list-item d-flex align-items-center a-border-bt a-py-10 justify-content-between" *ngFor="let file of secondaryAttachments$ | async">
                      <div>
                        <div class="a-h6">{{file.FILE_NAME + '.' + file.FILE_TYPE}}</div>
                        <div class="a-h6 font-weight-normal text-truncate">Uploaded By: {{file.UPDATED_BY}}</div>
                        <div class="a-text-caption a-text-9e9 font-weight-normal">Uploaded on: {{file.UPDATED_ON}}</div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <ap-button class="a-mr-10" [config]="{isPrimary: true}" (onBtnClick)="downloadFileFromS3(file)">
                            <span class="a-loading a-mr-10" *ngIf="file.isDownloading"></span>
                            <span class="appkiticon icon-download-light-fill a-btn-icon" *ngIf="!file.isDownloading"></span>Download
                          </ap-button>
                          <ap-button class="a-mr-10" [config]="{isSecondary: true}" *ngIf="dstSharedService.user.roleLevel === 5" (onBtnClick)="deactivateAttachment(file)">
                            <span class="a-loading a-primary a-mr-10" *ngIf="file.isDeleting"></span>
                            <span class="appkiticon icon-delete-fill a-btn-icon" *ngIf="!file.isDeleting"></span>Delete
                          </ap-button>
                        </div>
                      </div>

                    </li>
                  </ul>
                </div>
              </div>
            </ap-tab>
            <ap-tab [label]="'Upload'" *ngIf="(secondaryAttachments$ | async)?.length == 0 && (dstSharedService.user.roleLevel >= 3)">
              <gtw-s3-file-upload [s3FileUploadInput]='s3FileUploadInput' (on-s3-file-upload)="onFileUpload($event)"></gtw-s3-file-upload>
            </ap-tab>
          </ap-tabs-group>
        </div>
        <div class="a-modal-footer justify-content-end">
          <button aria-label="Close" class="a-btn a-btn-transparent a-btn-lg" data-dismiss="modal" (click)="closeUploadModal('show-upload-modal')">Close</button>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
