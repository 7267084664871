<div class="modal-body">
    <div class="panel-body">
        <div class="" style="margin-bottom: 12px;"> <b>Key:</b> <div class="badge btn-info " style="color: #fff;
            background-color: #31b0d5 !important;
            border-color: #269abc;
            font-size: 12px;
            margin-left: 10px;">PARENT ME NAME</div> </div>

        <div class="entitie-holder">
            <div class="btn-group dropup pull-left " style="margin-right: 6px; 
            margin-bottom: 6px;" *ngFor="let entity of entities; let i = index">
                <button type="button" class="btn btn-xs btn-default " style="width: 100%; 
                background: lightgray; 
                line-height: 0px;
                border-radius: 4px;"> {{ entity.data.PARENT_ME_NAME }} </button>
                <button type="button" (click)="removeEntity(i)" class="btn btn-xs btn-danger " style="line-height: 0px;">
                    <span class="fa fa-times"></span>
                </button>
            </div>
        </div>           
        <div>
            <div class="col-md-3 col-md-offset-9 mt-4">
                <div>
                    <ap-button [btnText]="'Save'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="saveData()" [isDisabled]="saveClicked"><i class="fa fa-spin fa-spinner" *ngIf="saveClicked" ></i></ap-button>
                    <ap-button [btnText]="'Cancel'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="modalClose()"></ap-button>
                </div>  
            </div>
        </div>
    </div>
 </div>