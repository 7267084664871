import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class ReleaseNotesService {
	url ={
        getReleaseNotesListData : '/loadJsonObject?action_code=8ikour',
		saveReleaseNotesListData: '/saveJsonObject?action_code=udp1lk',
		deleteReleaseNotesListData: '/saveJsonObject?action_code=3z6ega'
	}
	durationInSeconds= 5;
	constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

	getReleaseNotesList(baseURL:string) {
		// let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getReleaseNotesListData}`;
		
		return this.http.post(url, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));;
    }

    saveReleaseNotesList(baseURL : string, jsonData : any) {
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		//headers.append('client_key', clientKey ? clientKey : '');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_data', JSON.stringify([jsonData]));

		const url = `${baseURL}${this.url.saveReleaseNotesListData}`;

		//return this.httpClient.post(url, body, { headers });

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					if(res.errorMessage != "null"){
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					return res;
				}else{
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}
	
	deleteReleaseNotesList(baseURL : string, key : any) {
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		//headers.append('client_key', clientKey ? clientKey : '');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('rn_key', key);

		const url = `${baseURL}${this.url.deleteReleaseNotesListData}`;

		//return this.httpClient.post(url, body, { headers });

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					if(res.errorMessage != "null"){
						this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					return res;
				}else{
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
    }
    
    openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}
}




// GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=8ikour',