import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec367b-id192',
  templateUrl: './template-sec367b-id192.component.html',
  styleUrls: ['../template-shared.scss', './template-sec367b-id192.component.scss']
})
export class TemplateSec367bId192Component implements OnInit, AfterViewInit {

  // remove me after commit

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form192!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  beginDate!: Date;
  endDate!: Date;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  branchEntity!: DSTEntityDetails | null;
  DisplayDate: any = '';
  section4text: string = '';
  Shares: string = '';
  PercentageX: string = '';
  PercentageY: string = '';
  propertyValBasisOptions: any = [{data: []}];
  selectedBasis: number = -1;
  selectedCurrency: number = -1;
  currencyOptions: any = [{data: []}];
  stockType: any = [{data: []}];
  selectedStockType: number = -1;
  showDREMsg: boolean = false;
  DREMsg: string = '';
  entityNameChangeOnDate: boolean = false;
    nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  get additionalPropsDetails(): FormArray {
    return this.form192.get('main')?.get('additionalPropsDetails') as FormArray;
  }

  ngOnInit(): void {
    this.editMode = false;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form192 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        DisplayDate: [''],
        section4text: [''],
        propertyValBasis: [''],
        currency1: [''],
        Shares: [''],
        PercentageX: [''],
        PercentageY: [''],
        additionalPropsDetails: this.formBuilder.array([
          this.formBuilder.group({
            description: [''],
            transferorBasis: ['']
          })
        ]),
      }),
    });
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        console.log(data);
        data.forEach((element, index) => {
          this.propertyValBasisOptions[0].data.push({
            value: element.id,
            text: element.text,
            index: index
          });
        });
        this.propertyValBasisOptions = JSON.parse(JSON.stringify(this.propertyValBasisOptions));
      }, () => {

      }, () => {
        if (this.attMap['Basis']) {
          const updatedData = this.updateDropdownList(this.propertyValBasisOptions, this.attMap['Basis'][0]);
          this.propertyValBasisOptions = updatedData.dropdownData;
          this.selectedBasis = updatedData.index;
        }
      });

    this.sharedService.getStockType()
      .subscribe((data: DropDownType[]) => {
        console.log(data);
        data.forEach(element => {
          this.stockType[0].data.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        if (this.attMap['StockType']) {
          const updatedData = this.updateDropdownList(this.stockType, this.attMap['StockType'][0]);
          this.stockType = updatedData.dropdownData;
          this.selectedStockType = updatedData.index;
        }
      });
    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach((element, index) => {
          this.currencyOptions[0].data.push({
            value: element.id,
            text: element.text,
            index: index,
          });
        });
        const usdIndex: number = data.findIndex((c: any) => c.id === 84);
        if (usdIndex > 0) {
          this.currencyOptions[0].data[usdIndex].selected = true;
          this.currencyOptions = JSON.parse(JSON.stringify(this.currencyOptions));
          this.selectedCurrency = this.currencyOptions[0].data[usdIndex].value;
        }
      }, () => {

      }, () => {
        if (this.attMap['Currency']) {
          this.attMap['Currency'].forEach((element: any, index: number) => {
            switch (index) {
              case 0:
                let updatedData = this.updateDropdownList(this.currencyOptions, element);
                this.currencyOptions = updatedData.dropdownData;
                this.selectedCurrency = updatedData.index;
                break;
            }
          });
        }
      });
    this.statementId = this.sharedService.getFormData()?.statementid.toString() || '';
  }

  updateDropdownList(currentOptions: any, element: string) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      if (parseInt(element) === el.value) {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = el.value;
      } else {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  selectBasisOption(event: any) {
    this.selectedBasis = event.selected[0].value;
  }

  selectStockType(event: any) {
    this.selectedStockType = event.selected[0].value;
  }

  addAdditionalPropDetails(): void {
    this.additionalPropsDetails.push(this.formBuilder.group({
      description: [''],
      transferorBasis: [''],
    }));
  }

  removeAdditionalPropDetailsAt(index: number): void {
    this.additionalPropsDetails.removeAt(index);
  }

  dummyFunc(event: any): void {

  }

  currencyOneChange(event: any): void {
    this.selectedCurrency = event.selected[0].value;
  }

  ngAfterViewInit(): void {
    if (this.sharedService.getFormStateByFormId(192)?.isChild) {
      this.sharedService.getLoadStatementData(192).subscribe((data: StmtData) => {
        this.sharedService.setFormData(data, 192);
        this.loadStmtData();
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      });
    } else {
      this.loadStmtData();
    }
  }

  private loadStmtData(): void {
    const data: StmtData | undefined = this.sharedService.getFormData(192);
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.editMode = true;

      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.secondaryleid);
              break;
            case 1:
              element.fillLEID(data.primaryleid);
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      });

      this.populateFields(data);

    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;

      setTimeout(() => {
        this.leidComponents.toArray()[1].fillLEID(this.sharedService.leid);
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      }
    });
    if (this.attMap['section4text'] && this.attMap['section4text'][0]) {
      this.section4text = this.attMap['section4text'][0];
    }

    if (this.attMap['Shares'] && this.attMap['Shares'][0]) {
      this.Shares = this.attMap['Shares'][0];
    }

    if (this.attMap['PercentageX'] && this.attMap['PercentageX'][0]) {
      this.PercentageX = this.attMap['PercentageX'][0];
    }

    if (this.attMap['PercentageY'] && this.attMap['PercentageY'][0]) {
      this.PercentageY = this.attMap['PercentageY'][0];
    }

    if (this.attMap['DisplayDate'] && this.attMap['DisplayDate'][0] && this.attMap['DisplayDate'][0] !== '') {
      this.DisplayDate = new Date(this.attMap['DisplayDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    }

    if (this.attMap['Desc1'] && this.attMap['Desc1'].length) {
      this.additionalPropsDetails.clear();
      this.attMap['Desc1'].forEach((element: string, index: number) => {
        this.additionalPropsDetails.push(this.formBuilder.group({
          description: [element],
          transferorBasis: [this.attMap['BasisAmt1'][index]],
        }));
      });
    }

    if (this.attMap['Basis']) {
      const updatedData = this.updateDropdownList(this.propertyValBasisOptions, this.attMap['Basis'][0]);
      this.propertyValBasisOptions = updatedData.dropdownData;
      this.selectedBasis = updatedData.index;
    }

    if (this.attMap['Currency']) {
      this.attMap['Currency'].forEach((element: any, index: number) => {
        switch (index) {
          case 0:
            let updatedData = this.updateDropdownList(this.currencyOptions, element);
            this.currencyOptions = updatedData.dropdownData;
            this.selectedCurrency = updatedData.index;
            break;
        }
      });
    }

    if (this.attMap['StockType']) {
      const updatedData = this.updateDropdownList(this.stockType, this.attMap['StockType'][0]);
      this.stockType = updatedData.dropdownData;
      this.selectedStockType = updatedData.index;
    }

    this.cdref.detectChanges();
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.DisplayDate = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.entityNameChangeOnDate = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.DisplayDate,
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });

        break;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.DisplayDate && this.transferor?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.DisplayDate,
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferor!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferor!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.DisplayDate) {
      this.nameChangeChecked = true;

    }  else {
      this.entityNameChangeOnDate = false;
    }
  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    if (!selectedTransferor) {
      return;
    }

    if (this.DisplayDate && this.transferee?.LEID) {
      this.startLoader = true;
      this.entityNameChangeOnDate = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.DisplayDate,
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]) {
          (response[0]?.LE_NAME) && (this.transferee!.ENTITY = response[0].LE_NAME); // override entity name
          //override entity type
          this.transferee!.TAXTYPECODE = response[0]?.TAXTYPECODE!;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.DisplayDate) {
      this.nameChangeChecked = true;

    }  else {
      this.entityNameChangeOnDate = false;
    }
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onBranchEntityChanged(selectedBranch: DSTEntityDetails | null): void {
    this.branchEntity = selectedBranch;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'branchEntityId': this.branchEntity ? this.branchEntity?.ENTITYID.toString() : '',
        'comments': '',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'attachmentFileName': '',
      };

      const desc: string[] = [];
      const basisAmt: string[] = [];
      const fairMarketVal: string[] = [];
      this.additionalPropsDetails.controls.forEach(element => {
        desc.push(element.value.description);
        basisAmt.push(element.value.transferorBasis !== null ? element.value.transferorBasis.toString() : '');

      });
      const jsonObject = {
        'CheckFor351Transfer': '0',
        'DisplayDate': this.DisplayDate !== null && this.DisplayDate !== '' ? this.DisplayDate.startDate ? this.DisplayDate.startDate.format('YYYY-MM-DD') : this.DisplayDate : '',
        'section4text': this.section4text.toString(),
        'Desc1': desc[0] ? desc : [''],
        'BasisAmt1': basisAmt,
        'Currency': this.selectedCurrency.toString(),
        'Basis': this.selectedBasis.toString(),
        'Shares': this.Shares.toString(),
        'PercentageX': this.PercentageX.toString(),
        'PercentageY': this.PercentageY.toString(),
        'StockType': this.selectedStockType.toString(),
      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }
  }

  isFormValid(placeholder: boolean): boolean {
    if (placeholder) {
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }
    } else {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferor');
        return false;
      }
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID of Transferee');
        return false;
      }

      if (this.DisplayDate === '' || this.DisplayDate === null) {
        this.dstSharedService.showAlert('error', 'Please Enter a Date');
        return false;
      }

      if (this.selectedStockType === -1) {
        this.dstSharedService.showAlert('error', 'Please select Stock Type');
        return false;
      }
      if (this.Shares === '') {
        this.dstSharedService.showAlert('error', 'Please Enter number of Shares');
        return false;
      }
    }

    return true;
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }
}


