
<div class="kpi-box"  
    id="{{kpi_data.DIAG_MSG}}"
    #{{kpi_data.DIAG_MSG}}
    [ngStyle]="{'background': kpi_data.DIAG_MSG== kpiFilter ? '#FFF4E3' : 'white'}">
    <span class=" alter-icon appkiticon {{kpi_data.icon}}"
    [ngClass]="{'alter-icon': kpi_data.DIAG_MSG=='Critical',
                'info': kpi_data.DIAG_MSG=='Informational',
            'all-icon':kpi_data.DIAG_MSG=='All'}"
   ></span>
    <span class="title">{{kpi_data.DIAG_MSG}} Diagnostics</span>
    <span  class="amount float-right">
        <span *ngIf="kpi_data.EFFECTED_COUNT !=0 && kpi_data.DIAG_MSG == 'All'" style="color: #D04A02;"  [tooltip-container]="allEffected" [placement]="'bottom'">{{kpi_data.EFFECTED_COUNT}}</span> 
        <span *ngIf="kpi_data.EFFECTED_COUNT !=0 && kpi_data.DIAG_MSG == 'Critical'" style="color: #D04A02;"  [tooltip-container]="criticalEffected" [placement]="'bottom'">{{kpi_data.EFFECTED_COUNT}}</span> 
        <span *ngIf="kpi_data.EFFECTED_COUNT !=0 && kpi_data.DIAG_MSG == 'Informational'" style="color: #D04A02;"  [tooltip-container]="InfoEffected" [placement]="'bottom'">{{kpi_data.EFFECTED_COUNT}}</span>      
        <span *ngIf="kpi_data.EFFECTED_COUNT !=0"> of </span>
        <!-- <span> {{kpi_data.COUNT}}</span> -->
        <span *ngIf="kpi_data.DIAG_MSG=='All'" [tooltip-container]="allCount" [placement]="'top'"> {{kpi_data.COUNT}}</span>
        <span *ngIf="kpi_data.DIAG_MSG=='Critical'" [tooltip-container]="criticalTotal" [placement]="'top'"> {{kpi_data.COUNT}}</span>
        <span *ngIf="kpi_data.DIAG_MSG=='Informational'" [tooltip-container]="InfoTotal" [placement]="'top'"> {{kpi_data.COUNT}}</span>

    </span>
</div>
<ng-template #allCount>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of all resolved and unresolved
            diagnostics. It is a mixture of Critical
            and Informational diagnostics.
        </div>
    </div>
</ng-template>
<ng-template #allEffected>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of unresolved
            diagnostics. It is a mixture of Critical 
            and Informational diagnostics.
        </div>
    </div>
</ng-template>
<ng-template #criticalTotal>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of all resolved and
            unresolved Critical diagnostics.       
         </div>
    </div>
</ng-template>
<ng-template #criticalEffected>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of all unresolved
            Critical diagnostics.
        </div>
    </div>
</ng-template>
<ng-template #InfoTotal>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of all resolved and
            unresolved Informational diagnostics.        
        </div>
    </div>
</ng-template>
<ng-template #InfoEffected>
    <div class="tooltip-inner a-p-10 d-block a-min-width-300">
        <div class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-4">
            This is the count of all unresolved
            Informational diagnostics.
        </div>
    </div>
</ng-template>
