<mat-card>
    <mat-card-content>
        <div class="video-content">
            <img [src]="safeURL(video.imageAsBase64String)" alt="video.video_name"
                class="poster-file">
            <img src="./assets/img/play.jpg" alt="play button" class="play-button">
            <mat-card-header>
                <mat-card-title class="small-font-header">{{video.video_name}}</mat-card-title>
                <mat-card-subtitle>{{video.video_desc}}</mat-card-subtitle>
            </mat-card-header>
        </div>        
    </mat-card-content>
</mat-card>
