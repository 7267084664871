import {NgModule} from '@angular/core';
import {DfMeDropdownComponent} from './df-me-dropdown/df-me-dropdown.component';
import {DfMeDropdownNodeComponent} from './df-me-dropdown/tree-node/df-me-dropdown-node/df-me-dropdown-node.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedDirectivesModule} from '../_directives/shared-directives.module';
import {LoadingModule} from 'appkit-angular/loading';
import {FormReivewPdfComponent} from './form-review-pdf/form-review-pdf.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [DfMeDropdownComponent, DfMeDropdownNodeComponent, FormReivewPdfComponent,LoaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedDirectivesModule,
    LoadingModule,
  ],
  exports: [
    DfMeDropdownComponent, DfMeDropdownNodeComponent, FormReivewPdfComponent,
    LoaderComponent
  ]
})
export class SharedComponentsModule {
}
