import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SummaryDiagnosticService } from './summary-diagnostic.service';
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';
import { DataSheets } from './data-sheet-model';
import _, { each } from 'lodash';
import { observable } from 'rxjs';
import { DashboardFilterService } from '../dashboard-filter/dashboard-filter.service';
import { DataTableComponent } from './data-table/data-table.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';



@Component({
  selector: 'gtw-summary-diagnostic',
  templateUrl: './summary-diagnostic.component.html',
  styleUrls: ['./summary-diagnostic.component.scss']
})
export class SummaryDiagnosticComponent implements OnInit {

  /*  @Input('diag-name')*/
  diagName = 'Summary Diagnostics';
  label: string = "Taxable Income";
  all_amount: number = 345;
  critical_amount: number = 0;
  infomational_amount: number = 0;
  data:any = {};
 /*  @Input('client_key')
  clientKey : number = 0; */
  globalParams:any ={};
  @Input('global-params')
  set _global_params(params : any){
    try{
      this.globalParams = JSON.parse(params);
      if(_.isEmpty(this.summaryDiagnosticService.dashboardPool[0])){
       // this.init(this.globalParams);
      }
     /*  else if(this.summaryDiagnosticService.dashboardPool[0] && 
       ( this.summaryDiagnosticService.dashboardPool[0].selectedFilterParams.tax_year != this.globalParams.tax_year
        ||this.summaryDiagnosticService.dashboardPool[0].selectedFilterParams.scenario != this.globalParams.scenario )){
          this.init(this.globalParams);
        } */
      
    }catch(error){
      console.log("Not an valid object");
    }
  }
  @Input('tax-years')
  taxYears: any = [];
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  options:any = {};
  dashboard: any = {components:[]};
  kpi:any=[];
  kpiData:any = [];
  clientKey:any;


  baseValue!: string;
	baseLgValue!: string;
  filterValue!: string;
	filterBase(event: any) {
		console.log(event);
    // this.searchData.emit(event);
    this.filterValue = event;
	}
  
  loading: boolean = false;
  sheetCount:number = 0;
  checked:boolean =false;
  expand_collapse:boolean = false;

  @Output('load-detail-dashboard') loadDashboardScreen = new EventEmitter<any>();
  @Output('hide-resolved-diag') hideResolvedDiag = new EventEmitter<any>();
  @Output('expand-collapse') expandCollapseAll = new EventEmitter<any>();

  @Input('base-urls')
  set _base_urls(baseUrls: string){
    try{
      this.baseURLs = JSON.parse(baseUrls);
    }catch(e){
      console.log('Input not stable yet');
    }
  }
  @Input('client-key')
  set _clientKey(clientKey: string){
    try{
      this.clientKey = clientKey;
    }catch(e){
      console.log('Input not stable yet');
     // this.clientKey = 0; /// nee to remove
    }
  }
  constructor(public summaryDiagnosticService:SummaryDiagnosticService,
    public dashboardFilterService: DashboardFilterService) {
      this.summaryDiagnosticService.sheetsLoaded.subscribe((data:any) =>{
        console.log('adfadfa',this.summaryDiagnosticService.dashboardPool);
        this.calculateKpi(data);
      },() => {
      });
   }


/*   filterParams = {
    tax_year: 2020
    ,scenario: 40
    ,filing_key: 1
    ,parent_me_string: '99999999999999999999,6196'
    ,system: 'A'
 } */
  ngOnInit(): void {
    if(!_.isEmpty(this.summaryDiagnosticService.dashboardPool[0]) && this.summaryDiagnosticService.dashboardPool[0].kpiData && 
      this.summaryDiagnosticService.dashboardPool[0].kpiData.length>0){
      this.loading = false;

      this.dashboard = this.summaryDiagnosticService.dashboardPool[0];
      this.kpiData = this.dashboard.kpiData;
      this.selectedKpi = this.summaryDiagnosticService.dashboardPool[0]['selectedKpi'];
      this.selectedFilterParams= this.summaryDiagnosticService.dashboardPool[0]['selectedFilterParams'];
      this.checked =  this.summaryDiagnosticService.dashboardPool[0]['hideResolvedDiag'] ;
      this.expand_collapse = this.summaryDiagnosticService.dashboardPool[0]['expandCollapseAll']
      this.kpiLoading = true;
      this.loading = true;

    }else{    
      /* let filterParams = {
        tax_year: 2020
        ,scenario: 40
        ,filing_key: 1
        ,system: 'A'
     }  */

    
     // this.init(filterParams);
      

    }
  }
  selectedKpi:any ='All';
  kpiLoading:boolean = false;
  kpiFilter(kpi:any){
    this.selectedKpi = kpi.DIAG_MSG;
    console.log('this.selectedKpi',this.selectedKpi);
    this.summaryDiagnosticService.dashboardPool[0]['selectedKpi'] = this.selectedKpi
  }
  selectedFilterParams:any;
  loadDashbaord(event:any){
    if(event.screen_key == 11461){
      this.summaryDiagnosticService.drillDown.isDrillDown = false;
      this.summaryDiagnosticService.drillDown.selectedFilterParms = {};
    //if(event.screen_key = this.globalParams.screen_key){
      console.log('dashbaor adfadfadfadfevent', event);
      this.selectedKpi = 'All';
      this.baseValue = '';
     
      if(this.summaryDiagnosticService.dashboardPool.length>0){
        if(event.isFromFilter){
          this.init(event); 
          this.kpiData = [];
          this.kpiLoading = false;

        }else  if( !_.isEqual(event,this.summaryDiagnosticService.dashboardPool[0].selectedFilterParams)){         
          this.init(event); 
          this.kpiData = [];
          this.kpiLoading = false;

        }
      }else{
        this.init(event); 
        this.kpiData = [];
        this.kpiLoading = false;

      }
      this.selectedFilterParams= event // || this.filterParams// need to remove;
    }
      
  }
  init(filterParams:any){
    this.loading = false;
    this.summaryDiagnosticService.getDashboardComponent(this.baseURLs.api,this.clientKey)
    .subscribe((data:any) =>{
      this.options = data.dashboard;
      this.dashboard = this.summaryDiagnosticService.createOrUpdateDashabord(this.options.dashboard_name,
        this.options.dashboard_id,this.options.data_sheets,this.options.components);
        this.summaryDiagnosticService.dashboardPool[0]['selectedKpi'] = this.selectedKpi;
        this.summaryDiagnosticService.dashboardPool[0]['selectedFilterParams']= filterParams;
        this.checked = true;
        this.summaryDiagnosticService.dashboardPool[0]['hideResolvedDiag'] = true;
        this.summaryDiagnosticService.loadSheets(this.baseURLs.api,filterParams).subscribe((d:any)=>{
          this.dashboard = d;
          this.componentResolver(this.dashboard);
            this.loading = true;
       });
    },
     error => {
      console.error(error);
      },
      () => {
          console.log('dashboard Data api');
      });
      
  }

  

  componentResolver(dashboardPool:any){
    _.each(dashboardPool[0].components,function(com){
           _.each(com,function(ii){
              ii.extraOptionsJSON = JSON.parse(ii.extraOptions)
               let sheet = _.find(dashboardPool[0].dataSheets, function(s){return s.name == ii.name});
               ii.sheet = sheet;
            });
    });
    this.dashboard = dashboardPool[0];
    this.summaryDiagnosticService.setDashboardPool(dashboardPool);
  }
  obj = {DIAG_MSG: '',count:0};
  calculateKpi(data:any){
    console.log('this dashboar pool get', this.summaryDiagnosticService.getDashboardPool());
    this.sheetCount++;
      let sumByValue =  _(data.safeCopyArr).groupBy('DIAG_MSG')
                        .map((items:any, DIAG_MSG:any)=> ({DIAG_MSG: DIAG_MSG,COUNT: items.length, 
                            EFFECTED_COUNT: items.length ? _.filter(items,(i)=>{return i.FAILCNT >0 }).length:0,icon: DIAG_MSG =='Critical'?'icon-alert-fill':'icon-information-fill'
                        })).value();
     _.each(sumByValue,(i,key) =>{
      if(this.kpiData.length == 0){
          this.kpiData.push(sumByValue[key]);
      }else if(_.find(this.kpiData,{'DIAG_MSG': sumByValue[key].DIAG_MSG}) == undefined){
        this.kpiData.push(sumByValue[key]);
     }else{
        (_.find(this.kpiData,(ii,k) => {
            if(ii.DIAG_MSG == sumByValue[key].DIAG_MSG){
              ii['COUNT'] = ii['COUNT'] + sumByValue[key]['COUNT'];
              ii['EFFECTED_COUNT'] = ii['EFFECTED_COUNT'] + sumByValue[key]['EFFECTED_COUNT'];
              ii['effectedTemplate'] = ii.DIAG_MSG == 'Critical' ? 'criticalEffected':'InfoEffected'
              ii['totalTemplate'] = ii.DIAG_MSG == 'Critical' ? 'criticalTotal': 'InfoTotal'
            } 
        }));
      }
     });
     if(this.summaryDiagnosticService.dashboardPool[0].dataSheets.length == this.sheetCount){
        console.log('I am all KPI');
        //this.kpiData[0] ={'DIAG_MSG': 'All','COUNT': 0,'EFFECTED_COUNT':0,icon:'icon-spark-line-outline'};
        let allKpiObj:any = {'DIAG_MSG':'All','icon': 'icon-spark-line-outline','COUNT':0,'EFFECTED_COUNT':0};
        let COUNT=0;
        let EFFECTED_COUNT = 0;
        if(this.kpiData && this.kpiData.length>0){
          _.each(this.kpiData,(ii)=>{
              if(ii.COUNT != 'undefined'){
                COUNT = COUNT + ii.COUNT;
                EFFECTED_COUNT = EFFECTED_COUNT + ii.EFFECTED_COUNT;
              }
          });
        }
        
        allKpiObj['COUNT'] = COUNT;
        allKpiObj['EFFECTED_COUNT'] = EFFECTED_COUNT;
        allKpiObj['effectedTemplate']='allCount';
        allKpiObj['totalTemplate']='allEffected';
        this.kpiData.unshift(allKpiObj);
        this.sheetCount = 0;
        this.kpiLoading = true;

     }
      console.log('this.kpiData',this.kpiData);
      this.summaryDiagnosticService.dashboardPool[0].kpiData = this.kpiData;
  }
  drillDownDeatil(event:any){
    console.log('drillDown',event);
    this.loadDashboardScreen.emit(event);
  }
  isShow :boolean =  true;
  
  showFilter(){
    this.isShow = !this.isShow;
    // if(this.screenKe)
    this.dashboardFilterService.notifyOther({screen_key:11461,isEnabled:true});
  }
  closeFilterPanel(event:any){
    this.isShow = true//this.isShow;
  }
 

  toggle(event:MatSlideToggleChange){
    console.log('toggled',event.checked);

    this.checked = event.checked;
    this.summaryDiagnosticService.dashboardPool[0]['hideResolvedDiag'] = this.checked;
    console.log('toggled',this.checked);

  }
  expandCollapse(event:MatSlideToggleChange){
    console.log('expanded',event.checked);
    this.expand_collapse = event.checked;
    this.summaryDiagnosticService.dashboardPool[0]['expandCollapseAll']= this.expand_collapse;
  }
}

