import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[dstClickOutside]'
})
export class DSTClickOutsideDirective {

  // @Input() clickOutsideEnabled: boolean;
  // a string class name selector that represents the elements you want to ignore as the target of click event
  // @Input() clickOutsideIgnore: string | null;
  @Output() clickOutside: EventEmitter<void>;

  constructor(private elementRef: ElementRef) {
    // this.clickOutsideEnabled = true;
    // this.clickOutsideIgnore = null;
    this.clickOutside = new EventEmitter<void>();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    // if (!this.clickOutsideEnabled) {
    //   return;
    // }
    // if (this.clickOutsideIgnore) {
    //   if (target.classList.contains(this.clickOutsideIgnore)) {
    //     return;
    //   }
    // }
    if (this.elementRef.nativeElement.contains(target)) {
      return;
    }
    this.clickOutside.emit();
  }
}
