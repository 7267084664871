import {  Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { merge, Observable } from 'rxjs';
import { startWith, scan } from 'rxjs/operators';
import { ScreenDocumentList } from '../screen-document-upload/screen-document';
import { ScreenDocumentService } from '../screen-document-upload/screen-document.service';
import { ScreenVideo } from '../screen-video-list/screen-video';
import { UserService } from '../user.service';
import { DocumentViewDialogComponent } from './document-view-dialog/document-view-dialog.component';

@Component({
  selector: 'gtw-screen-document-list',
  templateUrl: './screen-document-list.component.html',
  styleUrls: ['./screen-document-list.component.scss']
})
export class ScreenDocumentListComponent implements OnInit {

  mergedlist$!: Observable<any>; 
  isLoading=false;
  durationInSeconds = 5;


  @Input('screen-key')
  screenKey!: number;
  
  @Input('client-key')
  clientKey!: number;
  
  @Input('base-url')
  baseUrl = 'http://localhost:8087';

  @Output()
  documentUrl = new EventEmitter<boolean>();

  @Output() 
  updateDocSelectedEvent= new EventEmitter();

  @Output()
  deleteDocSelectedEvent= new EventEmitter();


  attachmentList: any = [];
  @Input('refresh')
  set refresh(value:boolean){
    if(value){
      this.getAllDocuments();
    }      
  }
  constructor(private userService:UserService, 
    private screenDocumentService: ScreenDocumentService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar) { }


  ngOnInit(): void {
    if(!isNaN(this.clientKey) && !isNaN(this.screenKey)){
      this.getAllDocuments();
    }
  }

  getAllDocuments(){
     console.log('I am called');
    this.screenDocumentService.getAllDocumentsList(this.baseUrl, this.clientKey, this.screenKey).subscribe(
      res => {
        console.log("this the response",res);
       // this.inProgress=false;
        
        if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
          this.attachmentList = res.data;
          // this.refreshScreenDocumentList.emit(true);

          } else {
        }
      },
      error => {
      });
  }

  editDoc(updateDoc: ScreenDocumentList): void{
    this.updateDocSelectedEvent.emit(updateDoc);
  }
  deleteDoc(deleteDocKey: any): void{
    this.screenDocumentService.deleteDocumentById(this.baseUrl, this.clientKey, this.screenKey, deleteDocKey).subscribe(
      res => {
        console.log("this the response",res);
       // this.inProgress=false;
        
        if(res.callSuccess==1 || parseInt(res.callSuccess)==1) {
          this.openSnackBar("Document Deleted Successfully !!");

          this.getAllDocuments();

          } else {
            this.openSnackBar(res.errorMessage);

        }
      },
      error => {
      });
  }

 openDialog(selectedDoc: ScreenDocumentList) {

    const dialogRef = this.dialog.open(DocumentViewDialogComponent,  {
      data : selectedDoc
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  private openSnackBar(message: string) {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
    });
  }
}
