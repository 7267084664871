import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';
import { NavigationModel } from './navigation.model';
import { NavigationService } from './navigation.service';
import { forkJoin } from 'rxjs';
import { ActiveScreensService } from '../shared/_services/active-screens.service';
import _ from 'lodash';
@Component({
  selector: 'gtw-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  
  @Output('load-screens') showScreensEvent = new EventEmitter<any>();
  @Output('load-leftnav') loadleftNav = new EventEmitter<any>();

  
  @Input('base-urls')
  set _base_urls(baseUrls: string){
    try{
      let urls:GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
      if(Object.keys(urls).length > 0){
        this.baseURLs = urls;
        this.init();
      }
    }catch(e){
      console.log("Input Not yet stable. Still waiting to get resolved.");
    }
  }

  @Input('channel-key')
  set _channel_key(keys : any){
    try{
      this.globalAppParams = JSON.parse(keys);
      this.init();
    }catch(error){
      console.log("Not an valid object");
    }
  }

  @Input("current-screen")
  set _curentScreen(currentScreen:any){
    try{
      this.currentScreen = JSON.parse(currentScreen);      
  }catch(error){
      console.log("Not a valid screen ");
    }
  }

  @Output('load-current-screen') showCurrentScreensEvent = new EventEmitter<any>();
  
  currentScreen!:any;
  channelKey:number = NaN;
  globalAppParams : any = {};
  baseURLs: GtwElementsBaseUrlsModel ={'admin':'/admin','api':'/gtw',"custom":'','efile':'','pdfEngine': '','bulkPdfEngine':''};
  appNavigation:any=[];

  isMenuPanelClosed=true;
  isAccordion1Open=false;
  isAccordion2Open=false;
  taskList:NavigationModel[]= [];
  domainName ='';
  categoriesList:Array<any> = [];

  originalCategoriesList:Array<any>=[];
  originalTaskList:Array<any>=[];
  private modelChanged: Subject<{list:any,searchText:string, type:'tax-type'|'categories'}> 
                  = new Subject<{list:any,searchText:string, type:'tax-type'|'categories'}>();
	debounceTime = 500;

  categoriesInDomain:any = {};

  menuSettings = false;
  mSetting = {
    showScreen: true,
  }

  homePage:any = {};


  constructor(private navigationService:NavigationService,
              private activeScreenService: ActiveScreensService) { }

  ngOnInit(): void {

    this.modelChanged
      .pipe(
        debounceTime(this.debounceTime),
      )
      .subscribe((val) => {
        if(val.type == 'tax-type'){
          this.taskList = this.navigationService.filterBySearch(val.list, val.searchText,val.type);
        }else{
          this.categoriesList = this.navigationService.filterBySearch(val.list, val.searchText,val.type);
        }
      });
  }

  init(){
    this.appNavigation = [];
    forkJoin({appNavigation:this.navigationService.getUserNavigatorV2(this.baseURLs.api, this.globalAppParams), 
      categories: this.navigationService.getAllCategories(this.baseURLs.admin)})
    .subscribe((res:any)=>{      
        
       console.log('res.appNavigation',res.appNavigation);
  
        this.setInitialState(res.appNavigation);
        this.appNavigation = res.appNavigation;
        console.log('this.navi',this.navigationService.navObject);
       

        let menuData = this.setTreeParent([this.navigationService.navObject],'children')
        console.log('this.appNavigation, this.app navigation ',menuData);
        this.activeScreenService.appNavigationList = this.appNavigation;
        this.navigationService.navObject = menuData;
        this.loadleftNav.emit(menuData);
    },
      (err:any)=>{
    });
  }

  thisChildren:Array<any> = [];
  
  setTreeParent(_collection: any, _children:any){
    for(var i = 0;i<_collection.length;i++){
          
        this.thisChildren = _collection[i][_children];

        if (this.thisChildren && this.thisChildren.length) {
          _collection[i].children =  this.setParent(_collection[i],this.thisChildren, _children);
        }
    }
    return _collection;
  }
  thisMenu:Array<any> = [];
  setParent(_collection:any,data:any, _children:any){
    for(var i = 0;i<data.length;i++){
      this.thisMenu = data[i][_children];

      if(data[i].type == 'task'){
        data[i]['parent'] = _collection;
      }
      for(var ii in this.thisMenu){
        if(this.thisMenu[ii] !=undefined){
          this.thisMenu[ii]['parent'] = data[i];
          if (this.thisMenu[ii].type === 'activity' && this.thisMenu[ii].screens.length) {
            this.thisMenu[ii]['parent'] = data[i];
            var screens = this.thisMenu[ii].screens;
            for (var n in screens) {
                screens[n]['parent'] = this.thisMenu[ii];
            }
          }else if(this.thisMenu[ii].type == 'task'){
            this.thisMenu[ii] = iterateTask(this.thisMenu[ii],'children');            
          }
        }    
            
      }
      //SET SCREEN PARENT
        function iterateTask(_tasks: any,children: any){
           if(_tasks.type == 'task' && _tasks.children.length){
             var loopThroughTasks =  function(_taskData:any) {
                _.each(_taskData.children,function(ii,index){
                  if(ii.type == 'task'){
                     //
                     ii['parent'] = _taskData;
                      if(ii.children){
                        loopThroughTasks(ii);
                      }
                  }else{
                    if (ii.type === 'activity' && ii.screens.length) {
                      ii['parent'] = _taskData;
                      var screens = ii.screens;
                      for (var n in screens) {
                          screens[n]['parent'] = ii;
                      }
                    }
                  }
                });
             } 
              loopThroughTasks(_tasks);            
           }
           return _tasks;
        }           
      }
    return data;
  }

  showTasksAndCategories(selectedTaxType:{tasks:NavigationModel, domainName:string}) {
    this.isMenuPanelClosed = false;
    if((selectedTaxType.tasks.hasOwnProperty('children'))){
      if(this.categoriesInDomain.hasOwnProperty(selectedTaxType.domainName) ){
        this.categoriesList = this.categoriesInDomain[selectedTaxType.domainName];
      }else {
        this.categoriesInDomain[selectedTaxType.domainName] =
          this.navigationService.getAllCategoryScreens(selectedTaxType.tasks);;
        this.categoriesList = this.categoriesInDomain[selectedTaxType.domainName];
      }
      // this.categoriesList = this.navigationService.getAllCategoryScreens(selectedTaxType.tasks);
      this.taskList =  selectedTaxType.tasks.children;
    }
      //clone for backup
      this.originalTaskList = _.cloneDeep(selectedTaxType.tasks.children);//JSON.parse(JSON.stringify(selectedTaxType.tasks.children));
      this.originalCategoriesList = _.cloneDeep(this.categoriesList);//JSON.parse(JSON.stringify(this.categoriesList));
      this.domainName = selectedTaxType.domainName;
  }

  showScreens(screen : any){
    this.showScreensEvent.emit(screen);
    this.isMenuPanelClosed = true;

  }

  closeMenuPanel(event:any){
    this.isMenuPanelClosed = true;
  }

  filterScreen(event:any,type:'tax-type'|'categories'){
    let searchText = event.target.value;

    if(type == 'categories'){
      let tempList = _.cloneDeep(this.originalCategoriesList);//JSON.parse(JSON.stringify(this.originalCategoriesList));
      this.modelChanged.next({list:tempList, searchText: searchText,type:type});
      this.isAccordion2Open= (searchText.length > 0)?true:false;
    }else{
      let tempList = _.cloneDeep(this.originalTaskList);//JSON.parse(JSON.stringify(this.originalTaskList));
      this.modelChanged.next({list:tempList, searchText: searchText,type:type});
      this.isAccordion1Open= (searchText.length > 0)?true:false;
    }
  }

  toggleAccordion(index:number){
    if(index == 1){
      this.isAccordion1Open = this.isAccordion1Open ? false : true;
    }
    if(index == 2){
      this.isAccordion2Open = this.isAccordion2Open ? false : true;
    }
  }

  switch(status: any) {
    this.mSetting.showScreen = !this.mSetting.showScreen;
  }

  setInitialState(navData:any){
    let filteredScreen = this.navigationService.getCurrentScreen(this.currentScreen);
    if(filteredScreen.length != 0){
      if(this.globalAppParams.channel == 1060 || navData && navData[0].id == 5741){ // DST
        this.showCurrentScreensEvent.emit({  
          // state: 'workspace.dst-home-page',
          // url: '/workspace/dst-home-page',
          // screen: {activity_key: "7595",screen_key: "11396"}
          state: 'workspace.modules',
          url: '/workspace/modules',
          screen: filteredScreen[0]  
        })  
      }else{
        this.showCurrentScreensEvent.emit({  
          state: 'workspace.modules',
          url: '/workspace/modules',
          screen: filteredScreen[0]  
        })   
      }        
    }
    // this.activeScreenService.markOpenSelectedScreen(filteredScreen[0]);
  }
}
