<div class="req-access-btn-wrapper">
    <mat-icon
        class="lck-icn"
        matTooltip="No Access"
        matTooltipPosition="below">lock</mat-icon>
    <button class="req-access-btn"
        (click)="reqAccess();"
        mat-button>
            Request Access
    </button>
</div>
