<ap-modal #baseModal (onCheckModalClose)="modalClose()" (onCheckModalOk)="modalDetermine()" appModalId="match-tracker">
  <div #modalRef slot="content" (click)="$event.stopPropagation();">
    <div class="modal-dialog" style="max-width: 75rem;" role="document" tabindex="-1">
      <div class="modal-content">
        <div class="a-modal-header">
          <div>
            <span class="a-h4">Match with Tracker</span>
          </div>
        </div>
        <div class="a-modal-body">
          <div class="table-container">
            <div class="a-mt-5" *ngIf="loadingStatements">
              <span>
                <ap-loading [colorName]="'a-primary'" [size]="'20px'"></ap-loading>
              </span>
            </div>
            <ap-table #table *ngIf="!loadingStatements" [data]="tableData" [showPage]="false" [pageSize]="tableData.length" [striped]="true">
              <thead class="a-thead">
              <tr>
                <th *ngFor="let thItem of tableTitle" [sort]="thItem.sort">
                  <span>{{thItem.title}}</span>
                </th>
              </tr>
              </thead>
              <tbody class="a-tbody tbody-regular tbody-hover">
              <tr *ngFor="let item of tableData">
                <td>
                  <div>
                    <ap-button class="btn-match" [btnSize]="'small'" [config]="{isPrimary: true}" [isDisabled]="saving" (onBtnClick)="matchTo(item)">
                      <span class="appkiticon icon-plus-fill"></span>
                      Match to This
                    </ap-button>
                  </div>
                </td>
                <td>{{item.STATEMENT_ID}}</td>
                <td>{{item.PRIMARYENTITY}}</td>
                <td>{{item.STATEMENTNAME}}</td>
                <td>{{item.SECONDARYENTITY}}</td>
                <td>{{item.CREATEDBY}}</td>
              </tr>
              </tbody>
            </ap-table>
          </div>
        </div>
        <div class="a-modal-footer justify-content-end">
          <div class="footer-content">
            <div class="mt-label">If you don't find the statement ID above, please enter the statement ID here and save.</div>
            <ap-form-field style="margin-right: 10px;"
                           [inlineBlock]="true" [label]="''" [id]="'1'" [type]="'input'"
                           (onChangeEvent)="onStatementIdChange($event)">
            </ap-form-field>
            <div class="a-mt-5">
              <span *ngIf="saving">
                <ap-loading [colorName]="'a-primary'" [size]="'16px'"></ap-loading>
              </span>
            </div>
            <button class="a-btn a-btn-transparent" *ngIf="!saving" [disabled]="loadingStatements || statementId === ''" (click)="save()">
              <span class="appkiticon a-btn-icon icon-plus-fill"></span>SAVE
            </button>
            <button aria-label="Close" class="a-btn a-btn-transparent" data-dismiss="modal" (click)="closeModalOk('copy-replicate')">CLOSE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ap-alert [(show)]="alert.display" [alertType]="alert.type" [iconName]="'icon-alert-fill'" [timer]="alert.timeout">
    {{alert.message}}
  </ap-alert>
</ap-modal>
