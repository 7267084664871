import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {CreateDisclosureSharedService, DropDownType} from '../../create-disclosure-shared.service';
import {CreateDisclosureService, DSTTaxYear} from '../../../create-new/create-disclosure.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {FormFieldType} from '../template-sec8824-id237/template-sec8824-id237.component';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import _ from 'lodash';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {ModalComponent} from 'appkit-angular/modal';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-form8621-id236',
  templateUrl: './template-form8621-id236.component.html',
  styleUrls: ['../template-shared.scss', './template-form8621-id236.component.scss']
})
export class TemplateForm8621Id236Component implements OnInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('pficLegalEntityDatabase') pficLegalEntityDatabase!: ModalComponent;

  form236!: FormGroup;
  taxYearsSH: any[] = [{data: []}];
  taxYearsPFIC: any[] = [{data: []}];
  taxYearsOE: any[] = [{data: []}];
  countries: any[] = [{data: []}];
  states: any[] = [{data: []}];
  dcnId!: number;
  attMap: { [key: string]: string[] } = {};
  shareholder!: DSTEntityDetails | null;
  shareholderTaxYearType: string = 'Year';
  showPficLegalEntityDBModal: boolean = false;
  PFIC!: DSTEntityDetails | null;
  PFICUS: boolean = false;
  reasonEIN: string = '';
  PFICAdd1: string = '';
  PFICAdd2: string = '';
  PFICCity: string = '';
  PFICCountry: string = '';
  PFICCountryId: string = '';
  PFICStateProv: string = '';
  PFICStateProvId: string = '';
  PFICZip: string = '';
  PFICTaxYearType: string = 'Year';
  shareholderFiling: string = '';
  ShareholderTaxYear!: number | undefined;
  ShareholderTaxYearId!: number | undefined;
  PFICTaxYearId!: number | undefined;
  PFICTaxYear!: number | undefined;
  shareValueTaxyearEnd!: number | undefined;
  pficType: number = 0;
  reasonsEIN: any[] = [
    {
      data: [
        {
          id: 0,
          value: 1,
          text: 'APPLD FOR'
        }, 
        {
          id: 0,
          value: 2,
          text: 'FOREIGNUS'
        }
      ]
    }
  ];
  missingReasonsEIN: any[] = [
    {
      data: [
        {
          id: 0,
          value: 1,
          text: 'APPLD FOR'
        }, 
        {
          id: 0,
          value: 2,
          text: 'FOREIGNUS'
        }
      ]
    }
  ];
  MissingEINReason: string = '';
  isPFICInDB: string = '';
  loading: boolean = false;
  corpElectionChecked: boolean = false;
  sharesOwnedWithSpouse: boolean = false;
  beginDate!: Date | string;
  endDate!: Date | string;
  dateSharesAcq!: Date;
  dateSharesAcquired: string = '';
  OrdinaryIncome: string = '';
  shareHoldersSharesDesc: string = '';
  valueOfSharesYearEnd: string = '';
  pficType1Amount: string = '';
  pficType2Amount: string = '';
  pficType3Amount: string = '';
  electionToExtendDesc: string = '';
  noOfSharesYearEnd: string = '';
  proRateShares: string = '';
  portionLine6a: string = '';
  NetCapitalGain: string = '';
  proRateSharesNetGain: string = '';
  portionLine7a: string = '';
  Line6cAnd7cSum: string = '';
  Line8dAnd8aSubt: string = '';
  cashPropertyDeemedDistributedCash: string = '';
  portionLine8c: string = '';
  Line8bAnd8cSum: string = '';
  Line9bAnd9aSubt: string = '';
  totalTax: string = '';
  totalTaxWithoutRegard: string = '';
  OrdinaryIncomeOnTaxReturn: string = '';
  fairMarketValue: string = '';
  adjustedBasisStock: string = '';
  Line13bAnd13aSubt: string = '';
  unreversedInclusion: string = '';
  lossLine10c: string = '';
  fairMarketDispositionValue: string = '';
  adjustedBasisDispositionStock: string = '';
  unreversedInclusionSection1296: string = '';
  lossLine13c: string = '';
  lossLine13cExceedInclusion: string = '';
  gainOrLossDistribution: string = '';
  part5Tabs = [
    {
      tab: '1',
      selectedIndex: -1,
      totalDistribution: '',
      totalDistributionByFund: '',
      precedingTaxYear: '',
      gainOrLossDistributionSection1291: '',
      stmtForDistAndDisp: '',
      totalAmountLine16a: '',
      aggIncreaseInTax: '',
      ftc: '',
      interestTaxNetIncrease: '',
      Divide15bBy3: '',
      Multiply15cBy125percent: '',
      Subt15dFrom15a: '',
      Subt16dFrom16c: '',
    }
  ];
  part5Selected = new FormControl(0);
  part6Tabs = [
    {
      tab: '1',
      selectedIndex: -1,
      electionYear: '',
      unDistEarnings: '',
      deferredTax: '',
      deferredTaxInterest: '',
      evtTermElection: '',
      earnDistOrDeemed: '',
      deferredTaxDue: '',
      accrdInterestDue: '',
      deferredTaxAftTerm: '',
      accrdInterestAftTerm: '',
    }
  ];
  part6Selected = new FormControl(0);
  totalDistribution: string = '';
  totalDistributionByFund: string = '';
  precedingTaxYear: string = '';
  gainOrLossDistributionSection1291: string = '';
  stmtForDistAndDisp: string = '';
  totalAmountLine16a: string = '';
  aggIncreaseInTax: string = '';
  ftc: string = '';
  interestTaxNetIncrease: string = '';
  electionYear: string = '';
  unDistEarnings: string = '';
  deferredTax: string = '';
  deferredTaxInterest: string = '';
  evtTermElection: string = '';
  earnDistOrDeemed: string = '';
  deferredTaxDue: string = '';
  accrdInterestDue: string = '';
  deferredTaxAftTerm: string = '';
  accrdInterestAftTerm: string = '';
  selectedIndex: number = -1;
  Divide15bBy3: string = '';
  Multiply15cBy125percent: string = '';
  Subt15dFrom15a: string = '';
  Subt16dFrom16c: string = '';

  statementId: string = '';
  shareholderBeginningTY!: Date;
  shareholderBeginningTaxYear: string = '';
  shareholderEndingTY!: Date;
  shareholderEndingTaxYear: string = '';
  companyOrFundBeginningTY!: Date;
  companyOrFundBeginningTaxYear: string = '';
  companyOrFundEndingTY!: Date;
  companyOrFundEndingTaxYear: string = '';
  electionToTreat: boolean = false;
  electionToExtend: boolean = false;
  electionToMarkToMarket: boolean = false;
  deemedSalesElection: boolean = false;
  deemedDividentElection: boolean = false;
  electionToRecognizeGain: boolean = false;
  deemedDividendElectionWrtSection1297: boolean = false;
  deemedDividendElectionWrtFormerPfic: boolean = false;

  showDREMsg: boolean = false;
  DREMsg: string = '';
  saveDisabled: boolean;

  constructor(private formBuilder: FormBuilder,
              private cDService: CreateDisclosureService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private cdr: ChangeDetectorRef,
              private trackerTabsService: TrackerTabsService) {
    this.saveDisabled = false;
    this.form236 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      sec0: this.formBuilder.group({
        shareholderTY: [''],
        pficEin: [''],
        addrLn1: [''],
        addrLn2: [''],
        city: [''],
        stateProv: [''],
        zip: [''],
        shareholderBeginningTaxYear: [''],
        shareholderEndingTaxYear: [''],
        companyOrFundBeginningTaxYear: [''],
        companyOrFundEndingTaxYear: [''],
      }),
      partOne: this.formBuilder.group({
        shareHoldersSharesDesc: [''],
        sharesOwnedWithSpouse: [''],
        dateSharesAcq: [''],
        noOfSharesYearEnd: [''],
        valueOfSharesYearEnd: [''],
        pficType1Amount: [''],
        pficType2Amount: [''],
        pficType3Amount: [''],
      }),
      partTwo: this.formBuilder.group({
        electionToTreat: [''],
        electionToExtend: [''],
        electionToExtendDesc: [''],
        electionToMarkToMarket: [''],
        deemedSalesElection: [''],
        deemedDividentElection: [''],
        electionToRecognizeGain: [''],
        deemedDividendElectionWrtSection1297: [''],
        deemedDividendElectionWrtFormerPfic: [''],
      }),
      partThree: this.formBuilder.group({
        proRateShares: [''],
        portionLine6a: [''],
        proRateSharesNetGain: [''],
        portionLine7a: [''],
        cashPropertyDeemedDistributedCash: [''],
        portionLine8c: [''],
        totalTax: [''],
        totalTaxWithoutRegard: [''],
      }),
      partFour: this.formBuilder.group({
        fairMarketValue: [''],
        adjustedBasisStock: [''],
        unreversedInclusion: [''],
        lossLine10c: [''],
        fairMarketDispositionValue: [''],
        adjustedBasisDispositionStock: [''],
        unreversedInclusionSection1296: [''],
        lossLine13c: [''],
        lossLine13cExceedInclusion: [''],
      }),
      partFive: this.formBuilder.group({
        totalDistribution: [''],
        totalDistributionByFund: [''],
        precedingTaxYear: [''],
        gainOrLossDistributionSection1291: [''],
        stmtForDistAndDisp: [''],
        totalAmountLine16a: [''],
        aggIncreaseInTax: [''],
        ftc: [''],
        interestTaxNetIncrease: [''],
      }),
      partSix: this.formBuilder.group({
        electionYear: [''],
        unDistEarnings: [''],
        deferredTax: [''],
        deferredTaxInterest: [''],
        evtTermElection: [''],
        earnDistOrDeemed: [''],
        deferredTaxDue: [''],
        accrdInterestDue: [''],
        deferredTaxAftTerm: [''],
        accrdInterestAftTerm: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    const getTaxYears$: Observable<DSTTaxYear[]> = this.cDService.getTaxYears();
    const getCountries$: Observable<DropDownType[]> = this.sharedService.getCountryDropdown(true);
    const getStates$: Observable<DropDownType[]> = this.sharedService.getStateDropdown();
    zip(getTaxYears$, getCountries$, getStates$).pipe(take(1))
      .subscribe((data: [DSTTaxYear[], DropDownType[], DropDownType[]]) => {
        this.taxYearsSH = [{data: []}];
        this.taxYearsPFIC = [{data: []}];
        this.taxYearsOE = [{data: []}];
        this.countries = [{data: []}];
        this.states = [{data: []}];
        data[0].forEach(element => {
          this.taxYearsSH[0].data.push({
            id: element.id,
            text: element.text,
            selected: element.text === this.sharedService.getActiveFormState()?.taxYear
          });
          this.taxYearsPFIC[0].data.push({
            id: element.id,
            text: element.text,
            selected: element.text === this.sharedService.getActiveFormState()?.taxYear
          });
          this.taxYearsOE[0].data.push({
            id: element.id,
            text: element.text
          });
        });
        data[1].forEach(element => {
          this.countries[0].data.push({
            id: element.id,
            text: element.text
          });
        });
        data[2].forEach(element => {
          this.states[0].data.push({
            id: element.id,
            text: element.text
          });
        });
        this.ShareholderTaxYear = this.sharedService.getActiveFormState()?.taxYear;
        this.PFICTaxYear = this.sharedService.getActiveFormState()?.taxYear;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
      if (this.attMap['ElectionStatusTaxYearOfOutstandingElection']) {
        const updatedData = this.updateDropdownList(this.taxYearsOE
          , this.attMap['ElectionStatusTaxYearOfOutstandingElection'][0]);
        this.taxYearsOE = updatedData.dropdownData;
        this.electionYear = updatedData.index + '';
      }
      if (this.attMap['ShareholderTaxYear'] && this.attMap['ShareholderTaxYear'][0]) {
        const updatedData = this.updateDropdownList(this.taxYearsSH, this.attMap['ShareholderTaxYear'][0]);
        this.taxYearsSH = updatedData.dropdownData;
        this.ShareholderTaxYear = parseInt(this.attMap['ShareholderTaxYear'][0]);
      }
      if (this.attMap['ForeignAddressOfPFICOrQEFCountry'] && this.attMap['ForeignAddressOfPFICOrQEFCountry'][0]) {
        const updatedData = this.updateDropdownList(this.countries, this.attMap['ForeignAddressOfPFICOrQEFCountry'][0], true);
        this.countries = updatedData.dropdownData;
        this.PFICCountry = updatedData.index + '';
        this.PFICCountryId = this.attMap['ForeignAddressOfPFICOrQEFCountry'][0];
      }
      if (this.attMap['USAddressOfPFICOrQEFState'] && this.attMap['USAddressOfPFICOrQEFState'][0]) {
        const updatedData = this.updateDropdownList(this.states, this.attMap['USAddressOfPFICOrQEFState'][0], true);
        this.states = updatedData.dropdownData;
        this.PFICStateProv = updatedData.index + '';
        this.PFICStateProvId = this.attMap['USAddressOfPFICOrQEFState'][0];
      }
      if (this.attMap['TaxYearOfCompanyOrFund'] && this.attMap['TaxYearOfCompanyOrFund'][0]) {
        const updatedData = this.updateDropdownList(this.taxYearsPFIC, this.attMap['TaxYearOfCompanyOrFund'][0]);
        this.taxYearsPFIC = updatedData.dropdownData;
        this.PFICTaxYear = parseInt(this.attMap['TaxYearOfCompanyOrFund'][0]);
      }
      if (this.attMap['MissingEINReason']) {
        const updatedData = this.updateDropdownList(this.reasonsEIN, this.attMap['MissingEINReason'][0], false, true);
        this.reasonsEIN = updatedData.dropdownData;
        this.MissingEINReason= updatedData.index + '';
      }
      if (this.attMap['EINMissingReason']) {
        const updatedData = this.updateDropdownList(this.missingReasonsEIN, this.attMap['EINMissingReason'][0], false, true);
        this.missingReasonsEIN = updatedData.dropdownData;
        this.reasonEIN = updatedData.index + '';
      }

      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data: StmtData | undefined = this.sharedService.getFormData();
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      data.children?.forEach((stmt: StmtAttrData) => {
        if (this.attMap[stmt.attributeName] == undefined) {
          this.attMap[stmt.attributeName] = [];
        }

        if (stmt.attributeValue !== undefined) {
          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        } else {
          this.attMap[stmt.attributeName]?.push('');
        }
      });
      this.loadData();

      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
            case 1:
              if (data.secondaryleid) {
                element.fillLEID(data.secondaryleid);
              }
              break;
            case 2:
              if (data.branchleid) {
                element.fillLEID(data.branchleid);
              }
              break;
          }
        });
      });

      this.cdr.detectChanges();
    } else if (this.sharedService.leid !== '') {
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      setTimeout(() => {
        this.leidComponents.toArray()[0].fillLEID(this.sharedService.leid);
      });
    }

  }

  loadData(): void {
    if (this.attMap['ShareholderTaxYear'] !== undefined && this.attMap['ShareholderTaxYear'][0] !== '') {
      this.shareholderTaxYearType = 'Year';
    }

    if (this.attMap['ShareholderBeginningTaxYear'] !== undefined && this.attMap['ShareholderBeginningTaxYear'][0] !== ''
      && this.attMap['ShareholderEndingTaxYear'] !== undefined && this.attMap['ShareholderEndingTaxYear'][0] !== '') {
      this.shareholderTaxYearType = 'Range';
      this.shareholderBeginningTY = new Date(this.attMap['ShareholderBeginningTaxYear'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      this.shareholderBeginningTaxYear = this.attMap['ShareholderBeginningTaxYear'][0];
      this.shareholderEndingTY = new Date(this.attMap['ShareholderEndingTaxYear'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      this.shareholderEndingTaxYear = this.attMap['ShareholderEndingTaxYear'][0];
    }

    if (this.attMap['IndividualShareholder'] !== undefined && this.attMap['IndividualShareholder'][0] !== '') {
      this.shareholderFiling = 'Individual';
    }
    if (this.attMap['CorporationShareholder'] !== undefined && this.attMap['CorporationShareholder'][0] !== '') {
      this.shareholderFiling = 'Corporation';
    }
    if (this.attMap['PartnershipShareholder'] !== undefined && this.attMap['PartnershipShareholder'][0] !== '') {
      this.shareholderFiling = 'Partnership';
    }
    if (this.attMap['SCorporationShareholder'] !== undefined && this.attMap['SCorporationShareholder'][0] !== '') {
      this.shareholderFiling = 'SCorporation';
    }
    if (this.attMap['NongrantorTrustShareholder'] !== undefined && this.attMap['NongrantorTrustShareholder'][0] !== '') {
      this.shareholderFiling = 'NongrantorTrust';
    }
    if (this.attMap['EstateShareholder'] !== undefined && this.attMap['EstateShareholder'][0] !== '') {
      this.shareholderFiling = 'Estate';
    }

    if (this.attMap['QualifiedInsuranceCorpElectInd'] !== undefined && this.attMap['QualifiedInsuranceCorpElectInd'][0] !== '') {
      this.corpElectionChecked = true;
    }

    //PFIC
    if (this.attMap['PFICGOLD'] !== undefined && this.attMap['PFICGOLD'][0] !== '') {
      this.isPFICInDB = this.attMap['PFICGOLD'][0] === '1' ? 'Yes' : '';
    }
    if (this.attMap['USAddressOfPFICOrQEFAddressLine1'] !== undefined && this.attMap['USAddressOfPFICOrQEFAddressLine1'][0] !== '') {
      this.PFICUS = true;
      this.PFICAdd1 = this.attMap['USAddressOfPFICOrQEFAddressLine1'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFAddressLine2'] !== undefined && this.attMap['USAddressOfPFICOrQEFAddressLine2'][0] !== '') {
      this.PFICUS = true;
      this.PFICAdd2 = this.attMap['USAddressOfPFICOrQEFAddressLine2'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFCity'] !== undefined && this.attMap['USAddressOfPFICOrQEFCity'][0] !== '') {
      this.PFICUS = true;
      this.PFICCity = this.attMap['USAddressOfPFICOrQEFCity'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFState'] !== undefined && this.attMap['USAddressOfPFICOrQEFState'][0] !== '') {
      this.PFICUS = true;
      this.PFICStateProv = this.attMap['USAddressOfPFICOrQEFState'][0];
    }
    if (this.attMap['USAddressOfPFICOrQEFZIPCode'] !== undefined && this.attMap['USAddressOfPFICOrQEFZIPCode'][0] !== '') {
      this.PFICUS = true;
      this.PFICZip = this.attMap['USAddressOfPFICOrQEFZIPCode'][0];
    }

    if (this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'][0] !== '') {
      this.PFICUS = false;
      this.PFICAdd1 = this.attMap['ForeignAddressOfPFICOrQEFAddressLine1'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'][0] !== '') {
      this.PFICUS = false;
      this.PFICAdd2 = this.attMap['ForeignAddressOfPFICOrQEFAddressLine2'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFCity'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFCity'][0] !== '') {
      this.PFICUS = false;
      this.PFICCity = this.attMap['ForeignAddressOfPFICOrQEFCity'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'][0] !== '') {
      this.PFICUS = false;
      this.PFICStateProv = this.attMap['ForeignAddressOfPFICOrQEFProvinceOrState'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFCountry'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFCountry'][0] !== '') {
      this.PFICUS = false;
      this.PFICCountry = this.attMap['ForeignAddressOfPFICOrQEFCountry'][0];
    }
    if (this.attMap['ForeignAddressOfPFICOrQEFPostalCode'] !== undefined && this.attMap['ForeignAddressOfPFICOrQEFPostalCode'][0] !== '') {
      this.PFICUS = false;
      this.PFICZip = this.attMap['ForeignAddressOfPFICOrQEFPostalCode'][0];
    }

    if (this.attMap['TaxYearOfCompanyOrFund'] !== undefined && this.attMap['TaxYearOfCompanyOrFund'][0] !== '') {
      this.PFICTaxYearType = 'Year';
      // this.PFICTaxYear = this.attMap['TaxYearOfCompanyOrFund'][0];
    }

    if (this.attMap['CompanyOrFundBeginningTaxYear'] !== undefined && this.attMap['CompanyOrFundBeginningTaxYear'][0] !== ''
      && this.attMap['CompanyOrFundEndingTaxYear'] !== undefined && this.attMap['CompanyOrFundEndingTaxYear'][0] !== '') {
      this.PFICTaxYearType = 'Range';
      this.companyOrFundBeginningTY = new Date(this.attMap['CompanyOrFundBeginningTaxYear'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      this.companyOrFundBeginningTaxYear = this.attMap['CompanyOrFundBeginningTaxYear'][0];
      this.companyOrFundEndingTY = new Date(this.attMap['CompanyOrFundEndingTaxYear'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      this.companyOrFundEndingTaxYear = this.attMap['CompanyOrFundEndingTaxYear'][0];
    }
    //1, 1 checkbox
    if (this.attMap['DescribeEachClassOfShares'] !== undefined && this.attMap['DescribeEachClassOfShares'][0] !== '') {
      this.shareHoldersSharesDesc = this.attMap['DescribeEachClassOfShares'][0];
    }
    if (this.attMap['SharesJointlyOwnedWithSpouse'] !== undefined && this.attMap['SharesJointlyOwnedWithSpouse'][0] !== '') {
      this.sharesOwnedWithSpouse = true;
    }
    //2
    if (this.attMap['DateSharesAcquiredTxblYr'] !== undefined && this.attMap['DateSharesAcquiredTxblYr'][0] !== '') {
      this.dateSharesAcq = new Date(this.attMap['DateSharesAcquiredTxblYr'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      this.dateSharesAcquired = this.attMap['DateSharesAcquiredTxblYr'][0];
    }
    //3
    if (this.attMap['NumberOfSharesAtEndOfTxblYr'] !== undefined && this.attMap['NumberOfSharesAtEndOfTxblYr'][0] !== '') {
      this.noOfSharesYearEnd = this.attMap['NumberOfSharesAtEndOfTxblYr'][0];
    }
    //4
    if (this.attMap['ValueOfSharesRangeA'] !== undefined && this.attMap['ValueOfSharesRangeA'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeA'][0] === '1' ? 0 : undefined;
    }
    if (this.attMap['ValueOfSharesRangeB'] !== undefined && this.attMap['ValueOfSharesRangeB'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeB'][0]=== '1' ? 50001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeC'] !== undefined && this.attMap['ValueOfSharesRangeC'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeC'][0]=== '1' ? 100001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeD'] !== undefined && this.attMap['ValueOfSharesRangeD'][0] !== '') {
      this.shareValueTaxyearEnd = this.attMap['ValueOfSharesRangeD'][0]=== '1' ? 150001: undefined;
    }
    if (this.attMap['ValueOfSharesRangeE'] !== undefined && this.attMap['ValueOfSharesRangeE'][0] !== '') {
      this.valueOfSharesYearEnd = this.attMap['ValueOfSharesRangeE'][0];
    }
    //5
    if (this.attMap['Section1291'] !== undefined && this.attMap['Section1291'][0] !== '') {
      this.pficType = this.attMap['Section1291'][0] === '1' ? 1 : 0;
    }
    if (this.attMap['Section1293'] !== undefined && this.attMap['Section1293'][0] !== '') {
      this.pficType = this.attMap['Section1293'][0] === '1' ? 2 : 0;
    }
    if (this.attMap['Section1296'] !== undefined && this.attMap['Section1296'][0] !== '') {
      this.pficType = this.attMap['Section1296'][0] === '1' ? 3 : 0;
    }
    if (this.attMap['Section1291Amt'] !== undefined && this.attMap['Section1291Amt'][0] !== '') {
      this.pficType1Amount = this.attMap['Section1291Amt'][0];
    }
    if (this.attMap['Section1293Amt'] !== undefined && this.attMap['Section1293Amt'][0] !== '') {
      this.pficType2Amount = this.attMap['Section1293Amt'][0];
    }
    if (this.attMap['Section1296Amt'] !== undefined && this.attMap['Section1296Amt'][0] !== '') {
      this.pficType3Amount = this.attMap['Section1296Amt'][0];
    }
    //A, B, B textbox, C, D, E, F, G, H
    if (this.attMap['ElectionToTreatThePFICAsQEF'] !== undefined && this.attMap['ElectionToTreatThePFICAsQEF'][0] !== '0') {
      this.electionToTreat = this.attMap['ElectionToTreatThePFICAsQEF'][0] === '1' ? true: false;
    }
    if (this.attMap['ElectToExtendTimeForPymtOfTax'] !== undefined && this.attMap['ElectToExtendTimeForPymtOfTax'][0] !== '0') {
      this.electionToExtend = this.attMap['ElectToExtendTimeForPymtOfTax'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDividendElectionreferenceDocumentId'] !== undefined && this.attMap['DeemedDividendElectionreferenceDocumentId'][0] !== '') {
      this.electionToExtendDesc = this.attMap['DeemedDividendElectionreferenceDocumentId'][0];
    }
    if (this.attMap['ElectToMarkToMarketPFICStock'] !== undefined && this.attMap['ElectToMarkToMarketPFICStock'][0] !== '0') {
      this.electionToMarkToMarket = this.attMap['ElectToMarkToMarketPFICStock'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedSaleElection'] !== undefined && this.attMap['DeemedSaleElection'][0] !== '0') {
      this.deemedSalesElection = this.attMap['DeemedSaleElection'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDividendElection'] !== undefined && this.attMap['DeemedDividendElection'][0] !== '0') {
      this.deemedDividentElection = this.attMap['DeemedDividendElection'][0] === '1' ? true: false;
    }
    if (this.attMap['ElectToRecognizeGainOnPFICSale'] !== undefined && this.attMap['ElectToRecognizeGainOnPFICSale'][0] !== '0') {
      this.electionToRecognizeGain = this.attMap['ElectToRecognizeGainOnPFICSale'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDivElectSec1297ePFICInd'] !== undefined && this.attMap['DeemedDivElectSec1297ePFICInd'][0] !== '0') {
      this.deemedDividendElectionWrtSection1297 = this.attMap['DeemedDivElectSec1297ePFICInd'][0] === '1' ? true: false;
    }
    if (this.attMap['DeemedDivElectFrmrPFICInd'] !== undefined && this.attMap['DeemedDivElectFrmrPFICInd'][0] !== '0') {
      this.deemedDividendElectionWrtFormerPfic = this.attMap['DeemedDivElectFrmrPFICInd'][0] === '1' ? true: false;
    }
    //6
    if (this.attMap['ProRataShareOfQEFOrdinaryEarn'] !== undefined && this.attMap['ProRataShareOfQEFOrdinaryEarn'][0] !== '') {
      this.proRateShares = this.attMap['ProRataShareOfQEFOrdinaryEarn'][0];
    }
    if (this.attMap['IncomePortionOfOrdinaryEarn'] !== undefined && this.attMap['IncomePortionOfOrdinaryEarn'][0] !== '') {
      this.portionLine6a = this.attMap['IncomePortionOfOrdinaryEarn'][0];
    }
    if (this.attMap['DividendIncome'] !== undefined && this.attMap['DividendIncome'][0] !== '') {
      this.OrdinaryIncome = this.attMap['DividendIncome'][0];
    }
    //7
    if (this.attMap['ProRataShareOfTotalNetCapGain'] !== undefined && this.attMap['ProRataShareOfTotalNetCapGain'][0] !== '') {
      this.proRateSharesNetGain = this.attMap['ProRataShareOfTotalNetCapGain'][0];
    }
    if (this.attMap['IncomePortionOfNetCapitalGain'] !== undefined && this.attMap['IncomePortionOfNetCapitalGain'][0] !== '') {
      this.portionLine7a = this.attMap['IncomePortionOfNetCapitalGain'][0];
    }
    if (this.attMap['NetLongTermCapitalGain'] !== undefined && this.attMap['NetLongTermCapitalGain'][0] !== '') {
      this.NetCapitalGain = this.attMap['NetLongTermCapitalGain'][0];
    }
    //8
    if (this.attMap['DividendIncomeAndNetLTCG'] !== undefined && this.attMap['DividendIncomeAndNetLTCG'][0] !== '') {
      this.Line6cAnd7cSum = this.attMap['DividendIncomeAndNetLTCG'][0];
    }
    if (this.attMap['TotalCashAndDistributions'] !== undefined && this.attMap['TotalCashAndDistributions'][0] !== '') {
      this.cashPropertyDeemedDistributedCash = this.attMap['TotalCashAndDistributions'][0];
    }
    if (this.attMap['PortionOfProRataOrdnryEarnings'] !== undefined && this.attMap['PortionOfProRataOrdnryEarnings'][0] !== '') {
      this.portionLine8c = this.attMap['PortionOfProRataOrdnryEarnings'][0];
    }
    if (this.attMap['TotalCashAndPortionOfProRata'] !== undefined && this.attMap['TotalCashAndPortionOfProRata'][0] !== '') {
      this.Line8bAnd8cSum = this.attMap['TotalCashAndPortionOfProRata'][0];
    }
    if (this.attMap['ProRataLessCashAndPortion'] !== undefined && this.attMap['ProRataLessCashAndPortion'][0] !== '') {
      this.Line8dAnd8aSubt = this.attMap['ProRataLessCashAndPortion'][0];
    }
    //9
    if (this.attMap['TotalTaxForTheTaxYear'] !== undefined && this.attMap['TotalTaxForTheTaxYear'][0] !== '') {
      this.totalTax = this.attMap['TotalTaxForTheTaxYear'][0];
    }
    if (this.attMap['TotTaxWithoutProRataLessCash'] !== undefined && this.attMap['TotTaxWithoutProRataLessCash'][0] !== '') {
      this.totalTaxWithoutRegard = this.attMap['TotTaxWithoutProRataLessCash'][0];
    }
    if (this.attMap['DeferredTax'] !== undefined && this.attMap['DeferredTax'][0] !== '') {
      this.Line9bAnd9aSubt = this.attMap['DeferredTax'][0];
    }
    //10
    if (this.attMap['FairMarketValueOfPFICStock'] !== undefined && this.attMap['FairMarketValueOfPFICStock'][0] !== '') {
      this.fairMarketValue = this.attMap['FairMarketValueOfPFICStock'][0];
    }
    if (this.attMap['AdjustedBasisInStockEndOfTY'] !== undefined && this.attMap['AdjustedBasisInStockEndOfTY'][0] !== '') {
      this.adjustedBasisStock = this.attMap['AdjustedBasisInStockEndOfTY'][0];
    }
    if (this.attMap['Excess'] !== undefined && this.attMap['Excess'][0] !== '') {
      this.OrdinaryIncomeOnTaxReturn = this.attMap['Excess'][0];
    }
    //11
    if (this.attMap['UnreversedInclusions'] !== undefined && this.attMap['UnreversedInclusions'][0] !== '') {
      this.unreversedInclusion = this.attMap['UnreversedInclusions'][0];
    }
    //12
    if (this.attMap['ExcessOrUnreservedInclusions'] !== undefined && this.attMap['ExcessOrUnreservedInclusions'][0] !== '') {
      this.lossLine10c = this.attMap['ExcessOrUnreservedInclusions'][0];
    }
    //13
    if (this.attMap['FMVStkOnDtSaleOrDisposAmt'] !== undefined && this.attMap['FMVStkOnDtSaleOrDisposAmt'][0] !== '') {
      this.fairMarketDispositionValue = this.attMap['FMVStkOnDtSaleOrDisposAmt'][0];
    }
    if (this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'] !== undefined && this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'][0] !== '') {
      this.adjustedBasisDispositionStock = this.attMap['AdjBasisStkOnDtSaleOrDisposAmt'][0];
    }
    if (this.attMap['OrdinaryIncomeFromPFICStkAmt'] !== undefined && this.attMap['OrdinaryIncomeFromPFICStkAmt'][0] !== '') {
      this.Line13bAnd13aSubt = this.attMap['OrdinaryIncomeFromPFICStkAmt'][0];
    }
    //14
    if (this.attMap['StkSaleUnreversedInclusionsAmt'] !== undefined && this.attMap['StkSaleUnreversedInclusionsAmt'][0] !== '') {
      this.unreversedInclusionSection1296 = this.attMap['StkSaleUnreversedInclusionsAmt'][0];
    }
    if (this.attMap['LossLimitedByOrdinaryIncomeAmt'] !== undefined && this.attMap['LossLimitedByOrdinaryIncomeAmt'][0] !== '') {
      this.lossLine13c = this.attMap['LossLimitedByOrdinaryIncomeAmt'][0];
    }
    if (this.attMap['LossExcessOfUnrvrsdInclsnAmt'] !== undefined && this.attMap['LossExcessOfUnrvrsdInclsnAmt'][0] !== '') {
      this.lossLine13cExceedInclusion = this.attMap['LossExcessOfUnrvrsdInclsnAmt'][0];
    }

    //15, 16
    if (this.attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'] && this.attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'][0]) {
      this.part5Tabs = [];
      this.attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'].forEach((element: string, index: number) => {
        this.part5Tabs.push({
          tab: index + 1 + '',
          selectedIndex: index + 1,
          totalDistribution: this.attMap['DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY'][index],
          totalDistributionByFund: this.attMap['DistriAndDispositionOfStockDistributionsIn3PrecedingTY'][index],
          precedingTaxYear: this.attMap['precedingYears'][index],
          Divide15bBy3: this.attMap['DistriAndDispositionOfStockAvgDistribution3PrecedingTY'][index],
          Multiply15cBy125percent: this.attMap['DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct'][index],
          Subt15dFrom15a: this.attMap['DistriAndDispositionOfStockTotalExcessDistribution'][index],
          gainOrLossDistributionSection1291: this.attMap['DistriAndDispositionOfStockGainLossFromDispositionOfStock'][index],
          stmtForDistAndDisp: this.attMap['TotalCashAndDistributionsreferenceDocumentId'][index],
          totalAmountLine16a: this.attMap['DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY'][index],
          aggIncreaseInTax: this.attMap['DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY'][index],
          ftc: this.attMap['DistriAndDispositionOfStockForeignTaxCredit'][index],
          interestTaxNetIncrease: this.attMap['DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax'][index],
          Subt16dFrom16c: this.attMap['DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr'][index],
        });
      });
      this.selectTab(0, 'partFive');
    }

    //17, 18, 19, 20, 21, 22, 23, 24, 25, 26
    if (this.attMap['ElectionStatusTaxYearOfOutstandingElection'] && this.attMap['ElectionStatusTaxYearOfOutstandingElection'][0]) {
      this.part6Tabs = [];
      this.attMap['ElectionStatusTaxYearOfOutstandingElection'].forEach((element: string, index: number) => {
        this.part6Tabs.push({
          tab: index + 1 + '',
          selectedIndex: index + 1,
          electionYear: this.attMap['ElectionStatusTaxYearOfOutstandingElection'][index],
          unDistEarnings: this.attMap['ElectionStatusUndistributedEarnings'][index],
          deferredTax: this.attMap['ElectionStatusDeferredTax'][index],
          deferredTaxInterest: this.attMap['ElectionStatusInterestAccruedOnDeferredTax'][index],
          evtTermElection: this.attMap['ElectionStatusEventTerminatingElection'][index],
          earnDistOrDeemed: this.attMap['ElectionStatusEarningsDistributedDuringTheTY'][index],
          deferredTaxDue: this.attMap['ElectionStatusDeferredTaxDueWithThisReturn'][index],
          accrdInterestDue: this.attMap['ElectionStatusAccruedInterestDueThisReturn'][index],
          deferredTaxAftTerm: this.attMap['ElectionStatusDeferredTaxAfterPartialTerm'][index],
          accrdInterestAftTerm: this.attMap['ElectionStatusIntAccruedAfterPartialTerm'][index],
        });
      });
      this.selectTab(0, 'partSix');
    }

  }

  updateDropdownList(currentOptions: any, element: string, useId: boolean = false, useText: boolean = false) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      const elementValue = useText ? el.text.toString(): ( (el.value) ? el.value : (useId ? el.id.toString() : el.text.toString()) );
      if (element === elementValue) {
        updateList.push({
          id: el.id,
          value: elementValue,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = elementValue;
      } else {
        updateList.push({
          id: el.id,
          value: elementValue,
          text: el.text,
          index: elementValue,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  onPFICChanged(selectedPFIC: DSTEntityDetails | null): void {
    this.PFIC = selectedPFIC;
    console.log('PFIC CHANGE EVENT ---' + JSON.stringify(this.PFIC));
    if (!selectedPFIC) {
      this.clearPFICAddress();
      return;
    }
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.PFIC!, this.shareholder!]);
    this.showDREMsg = !!this.DREMsg;
    this.fillPFICData();
  }

  private clearPFICAddress() {
    this.PFICAdd1 = '';
    this.PFICAdd2 = '';
    this.PFICCountryId = '';
    this.PFICCity = '';
    this.PFICStateProvId = '';
    this.PFICZip = '';
  }

  private fillPFICData(): void {
    // fill country
    const selectedCountry: any = this.countries[0].data.find((c: any) => c.text === this.PFIC!.COUNTRYNAME);
    if (selectedCountry) {
      this.countries[0].data.forEach((c: any) => c.selected = false);
      selectedCountry.selected = true;
      this.PFICCountryId = selectedCountry.id;
    }
    // fill address line 1
    this.PFICAdd1 = this.PFICAdd1 || this.PFIC!.ADDRESS1;
    // fill address line 2
    this.PFICAdd2 = this.PFICAdd2 || this.PFIC!.ADDRESS2;
    // fill city / town
    this.PFICCity = this.PFICCity || this.PFIC!.CITY;
    // fill state / province
    if (this.PFIC!.COUNTRY === 'US' && !this.PFICStateProv) {
      this.PFICUS = true;
      const selectedState: any = this.states[0].data.find((s: any) => s.id === this.PFIC!.STATE);
      if (selectedState) {
        this.states[0].data.forEach((s: any) => s.selected = false);
        selectedState.selected = true;
        this.PFICStateProvId = selectedState.id;
      }
    }
     else {
      this.PFICStateProv = this.PFICStateProv || this.PFIC!.STATEINC;
    }
    // fill zip / postal code
    this.PFICZip = this.PFICZip || this.PFIC!.ZIP;
    this.cdr.detectChanges();
  }

  onConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onShareholderChanged(selectedShareholder: DSTEntityDetails | null): void {
    if (!selectedShareholder) {
      return;
    }
    this.shareholder = selectedShareholder;
    this.headerComponent.loadConsolGroups(this.shareholder.LEID,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.shareholder!, this.PFIC!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onSHEINMissingReasonChanged(event: any): void {
    this.MissingEINReason = event.selected[0].text;
  }

  onShareholderTYChanged(event: any): void {
    this.ShareholderTaxYear = event.selected[0].text;
    this.ShareholderTaxYearId = event.selected[0].id;
  }

  onShareholderTYBChanged(event: any): void {
    this.shareholderBeginningTaxYear = event.value;
  }

  onShareholderTYEChanged(event: any): void {
    this.shareholderEndingTaxYear = event.value;
  }

  onShareholderTypeChecked(event: any, type: string): void {
    this.shareholderFiling = (event.srcElement.checked) ? type : '';
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1: 
        this.dateSharesAcquired = event.startDate.format('YYYY-MM-DD');
        break;
      case 2: 
        this.shareholderBeginningTaxYear = event.startDate.format('YYYY-MM-DD');
        break;
      case 3: 
        this.shareholderEndingTaxYear = event.startDate.format('YYYY-MM-DD');
        break;
      case 4: 
        this.companyOrFundBeginningTaxYear = event.startDate.format('YYYY-MM-DD');
        break;
      case 5: 
        this.companyOrFundEndingTaxYear = event.startDate.format('YYYY-MM-DD');
        break;
      default: break;
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  onCorpElectionChecked(): void {
    this.corpElectionChecked = !this.corpElectionChecked;
  }

  onPFICEINMissingReasonChanged(event: any): void {
    this.reasonEIN = event.selected[0].text;
  }

  onPFICAddrChanged(event: any, lineNo: number): void {
    this.PFICAdd1 = lineNo === 1 ? event.event.target.value : this.PFICAdd1;
    this.PFICAdd2 = lineNo === 2 ? event.event.target.value : this.PFICAdd2;
  }

  onPFICCityChanged(event: any): void {
    this.PFICCity = event.event.target.value;
  }

  onPFICCountryChanged(event: any): void {
    this.PFICCountry = event.selected[0].text;
    this.PFICCountryId = event.selected[0].id;
    this.PFICUS = event.selected[0].id === 'US' ? true : false;
  }

  onPFICStateChanged(event: any): void {
    this.PFICStateProv = event.selected[0].text;
    this.PFICStateProvId = event.selected[0].id;
  }

  onPFICStateProvChanged(event: any): void {
    this.PFICStateProv = event.event.target.value;
    
  }

  onPFICZipChanged(event: any): void {
    this.PFICZip = event.event.target.value;
  }

  onPFICTYChanged(event: any): void {
    this.PFICTaxYear = event.selected[0].text;
    this.PFICTaxYearId = event.selected[0].id;
  }

  onPFICTYBChanged(event: any): void {
    this.companyOrFundBeginningTaxYear = event.value;
  }

  onPFICTYEChanged(event: any): void {
    this.companyOrFundEndingTaxYear = event.value;
  }

  check1(event: any, option: string): void {
    this.shareholderTaxYearType = option;
    if (option === 'Range' && event.srcElement.checked) {
      this.ShareholderTaxYear = undefined;
      this.ShareholderTaxYearId = undefined;
    } else if (option === 'Year' && event.srcElement.checked) {
      this.shareholderBeginningTaxYear = '';
      this.shareholderEndingTaxYear = '';
    }
  }

  check2(option: string): void {
    this.isPFICInDB = option;
    if (option === 'No') {
      this.showPficLegalEntityDBModal = true;
      setTimeout(() => {
        this.pficLegalEntityDatabase.showMore('pfic-entity-db');
        // styling fix for gtw-ui
        const modalEle: Element | null = document.querySelector('[ng-reflect-id="pfic-entity-db"]');
        modalEle?.classList.add('gtw-web-components', 'dst-modal-sm');
        modalEle?.children[1].classList.add('p-0');
      });
    } else {
      this.saveDisabled = false;
      this.showDREMsg = false;
    }
  }

  onpficLegalEntityDatabaseOK(id: string) {
    this.pficLegalEntityDatabase.closeModalOk(id);
    setTimeout(() => {
      this.showPficLegalEntityDBModal = false;
      this.DREMsg = '3rd Party prepared Forms 8621 should be attached as a PDF. This disclosure will not save under the existing fact pattern - PFIC not in the Legal Entity Database.';
      this.showDREMsg = !!this.DREMsg;
      this.saveDisabled = true;
    });
  }

  check3(event: any, option: string): void {
    this.PFICTaxYearType = option;
    if (option === 'Range' && event.srcElement.checked) {
      this.PFICTaxYearId = undefined;
      this.PFICTaxYear = undefined;
    } else if (option === 'Year' && event.srcElement.checked) {
      this.companyOrFundBeginningTaxYear = '';
      this.companyOrFundEndingTaxYear = '';
    }
  }

  updateValue(event: FormFieldType, partAndLine: string, index: number, field: string) {
    switch (field) {
      case 'proRateShares':
        this.proRateShares = event.value;
        break;
      case 'portionLine6a':
        this.portionLine6a = event.value;
        break;
      case 'proRateSharesNetGain':
        this.proRateSharesNetGain = event.value;
        break;
      case 'portionLine7a':
        this.portionLine7a = event.value;
        break;
      case 'cashPropertyDeemedDistributedCash':
        this.cashPropertyDeemedDistributedCash = event.value;
        break;
      case 'portionLine8c':
        this.portionLine8c = event.value;
        break;
      case 'totalTax':
        this.totalTax = event.value;
        break;
      case 'totalTaxWithoutRegard':
        this.totalTaxWithoutRegard = event.value;
        break;
      case 'fairMarketValue':
        this.fairMarketValue = event.value;
        break;
      case 'adjustedBasisStock':
        this.adjustedBasisStock = event.value;
        break;
      case 'fairMarketDispositionValue':
        this.fairMarketDispositionValue = event.value;
        break;
      case 'adjustedBasisDispositionStock':
        this.adjustedBasisDispositionStock = event.value;
        break;
      case 'totalDistribution':
        this.totalDistribution = event.value;
        this.part5Tabs[index].totalDistribution = event.value;
        break;
      case 'totalDistributionByFund':
        this.totalDistributionByFund = event.value;
        this.part5Tabs[index].totalDistributionByFund = event.value;
        break;
      case 'precedingTaxYear':
        this.precedingTaxYear = event.value;
        this.part5Tabs[index].precedingTaxYear = event.value;
        break;
      case 'gainOrLossDistributionSection1291':
        this.gainOrLossDistributionSection1291 = event.value;
        this.part5Tabs[index].gainOrLossDistributionSection1291 = event.value;
        break;
      case 'stmtForDistAndDisp':
        this.stmtForDistAndDisp = event.value;
        this.part5Tabs[index].stmtForDistAndDisp = event.value;
        break;
      case 'totalAmountLine16a':
        this.totalAmountLine16a = event.value;
        this.part5Tabs[index].totalAmountLine16a = event.value;
        break;
      case 'aggIncreaseInTax':
        this.aggIncreaseInTax = event.value;
        this.part5Tabs[index].aggIncreaseInTax = event.value;
        break;
      case 'ftc':
        this.ftc = event.value;
        this.part5Tabs[index].ftc = event.value;
        break;
      case 'interestTaxNetIncrease':
        this.interestTaxNetIncrease = event.value;
        this.part5Tabs[index].interestTaxNetIncrease = event.value;
        break;
      case 'unDistEarnings':
        this.unDistEarnings = event.value;
        this.part6Tabs[index].unDistEarnings = event.value;
        break;
      case 'deferredTax':
        this.deferredTax = event.value;
        this.part6Tabs[index].deferredTax = event.value;
        break;
      case 'deferredTaxInterest':
        this.deferredTaxInterest = event.value;
        this.part6Tabs[index].deferredTaxInterest = event.value;
        break;
      case 'evtTermElection':
        this.evtTermElection = event.value;
        this.part6Tabs[index].evtTermElection = event.value;
        break;
      case 'earnDistOrDeemed':
        this.earnDistOrDeemed = event.value;
        this.part6Tabs[index].earnDistOrDeemed = event.value;
        break;
      case 'deferredTaxDue':
        this.deferredTaxDue = event.value;
        this.part6Tabs[index].deferredTaxDue = event.value;
        break;
      case 'accrdInterestDue':
        this.accrdInterestDue = event.value;
        this.part6Tabs[index].accrdInterestDue = event.value;
        break;
      case 'deferredTaxAftTerm':
        this.deferredTaxAftTerm = event.value;
        this.part6Tabs[index].deferredTaxAftTerm = event.value;
        break;
      case 'accrdInterestAftTerm':
        this.accrdInterestAftTerm = event.value;
        this.part6Tabs[index].accrdInterestAftTerm = event.value;
        break;
        defualt: break;
    }

    switch (partAndLine) {
      case 'partThreeLine6':
        this.OrdinaryIncome = (this.proRateShares !== '' && this.portionLine6a !== '') ? (parseFloat(this.proRateShares) - parseFloat(this.portionLine6a)) + '' : '';
        this.Line6cAnd7cSum = (this.OrdinaryIncome !== '' && this.NetCapitalGain !== '') ? (parseFloat(this.OrdinaryIncome) + parseFloat(this.NetCapitalGain)) + '' : '';
        this.Line8dAnd8aSubt = (this.Line8bAnd8cSum !== '' && this.Line6cAnd7cSum !== '') ? (parseFloat(this.Line8bAnd8cSum) - parseFloat(this.Line6cAnd7cSum)) + '' : '';
        break;
      case 'partThreeLine7':
        this.NetCapitalGain = (this.proRateSharesNetGain !== '' && this.portionLine7a !== '') ? (parseFloat(this.proRateSharesNetGain) - parseFloat(this.portionLine7a)) + '' : '';
        this.Line6cAnd7cSum = (this.OrdinaryIncome !== '' && this.NetCapitalGain !== '') ? (parseFloat(this.OrdinaryIncome) + parseFloat(this.NetCapitalGain)) + '' : '';
        this.Line8dAnd8aSubt = (this.Line8bAnd8cSum !== '' && this.Line6cAnd7cSum !== '') ? (parseFloat(this.Line8bAnd8cSum) - parseFloat(this.Line6cAnd7cSum)) + '' : '';
        break;
      case 'partThreeLine8':
        this.Line8bAnd8cSum = (this.cashPropertyDeemedDistributedCash !== '' && this.portionLine8c !== '') ? (parseFloat(this.cashPropertyDeemedDistributedCash) + parseFloat(this.portionLine8c)) + '' : '';
        this.Line8dAnd8aSubt = (this.Line6cAnd7cSum !== '' && this.Line8bAnd8cSum !== '') ? (parseFloat(this.Line6cAnd7cSum) - parseFloat(this.Line8bAnd8cSum)) + '' : '';
        break;
      case 'partThreeLine9':
        this.Line9bAnd9aSubt = (this.totalTax !== '' && this.totalTaxWithoutRegard !== '') ? (parseFloat(this.totalTax) - parseFloat(this.totalTaxWithoutRegard)) + '' : '';
        break;
      case 'partFourLine10':
        this.OrdinaryIncomeOnTaxReturn = (this.fairMarketValue !== '' && this.adjustedBasisStock !== '') ? (parseFloat(this.fairMarketValue) - parseFloat(this.adjustedBasisStock)) + '' : '';
        break;
      case 'partFourLine13':
        this.Line13bAnd13aSubt = (this.fairMarketDispositionValue !== '' && this.adjustedBasisDispositionStock !== '') ? (parseFloat(this.fairMarketDispositionValue) - parseFloat(this.adjustedBasisDispositionStock)) + '' : '';
        break;
      case 'partFiveLine15':
        this.Divide15bBy3 = (this.totalDistributionByFund !== '') ? (parseFloat(this.totalDistributionByFund) / (!this.precedingTaxYear || parseFloat(this.precedingTaxYear) === 0 ? 3 : parseFloat(this.precedingTaxYear))).toFixed(3) + '' : '';
        this.Multiply15cBy125percent = (this.Divide15bBy3 !== '') ? (parseFloat(this.Divide15bBy3) * 1.25).toFixed(3) + '' : '';
        this.Subt15dFrom15a = (this.totalDistribution !== '' && this.Multiply15cBy125percent !== '') ? (parseFloat(this.totalDistribution) - parseFloat(this.Multiply15cBy125percent)).toFixed(2) + '' : '';

        (this.Divide15bBy3 !== '') && (this.part5Tabs[index].Divide15bBy3 = this.Divide15bBy3);
        (this.Multiply15cBy125percent !== '') && (this.part5Tabs[index].Multiply15cBy125percent = this.Multiply15cBy125percent);
        (this.Subt15dFrom15a !== '') && (this.part5Tabs[index].Subt15dFrom15a = this.Subt15dFrom15a);
        break;
      case 'partFiveLine16':
        this.Subt16dFrom16c = (this.aggIncreaseInTax !== '' && this.ftc !== '') ? (parseFloat(this.aggIncreaseInTax) - parseFloat(this.ftc)).toFixed(3) + '' : '';

        (this.Subt16dFrom16c !== '') && (this.part5Tabs[index].Subt16dFrom16c = this.Subt16dFrom16c);
        break;
    }

  }

  addTab(partName: string) {
    if (partName === 'partFive') {
      this.part5Tabs.push(
        {
          tab: this.part5Tabs.length + 1 + '',
          selectedIndex: -1,
          totalDistribution: '',
          totalDistributionByFund: '',
          precedingTaxYear: '',
          gainOrLossDistributionSection1291: '',
          stmtForDistAndDisp: '',
          totalAmountLine16a: '',
          aggIncreaseInTax: '',
          ftc: '',
          interestTaxNetIncrease: '',
          Divide15bBy3: '',
          Multiply15cBy125percent: '',
          Subt15dFrom15a: '',
          Subt16dFrom16c: '',
        });
      this.part5Selected.setValue(this.part5Tabs.length - 1);
    } else if (partName === 'partSix') {
      this.resetOutstandingElectionTY();
      this.part6Tabs.push(
        {
          tab: this.part6Tabs.length + 1 + '',
          selectedIndex: -1,
          electionYear: '',
          unDistEarnings: '',
          deferredTax: '',
          deferredTaxInterest: '',
          evtTermElection: '',
          earnDistOrDeemed: '',
          deferredTaxDue: '',
          accrdInterestDue: '',
          deferredTaxAftTerm: '',
          accrdInterestAftTerm: '',
        });
      this.part6Selected.setValue(this.part6Tabs.length - 1);
    }
  }

  removeTab(index: number, partName: string) {
    if (partName === 'partFive') {
      this.part5Tabs.splice(index, 1);
    } else if (partName === 'partSix') {
      this.part6Tabs.splice(index, 1);
    }
  }

  selectTab(event: any, partName: string) {
    if (partName === 'partFive') {
      this.part5Selected.setValue(event);
      // console.log(event);
      this.totalDistribution = this.part5Tabs[event].totalDistribution;
      this.totalDistributionByFund = this.part5Tabs[event].totalDistributionByFund;
      this.precedingTaxYear = this.part5Tabs[event].precedingTaxYear;
      this.precedingTaxYear = this.part5Tabs[event].precedingTaxYear;
      this.gainOrLossDistributionSection1291 = this.part5Tabs[event].gainOrLossDistributionSection1291;
      this.stmtForDistAndDisp = this.part5Tabs[event].stmtForDistAndDisp;
      this.totalAmountLine16a = this.part5Tabs[event].totalAmountLine16a;
      this.aggIncreaseInTax = this.part5Tabs[event].aggIncreaseInTax;
      this.ftc = this.part5Tabs[event].ftc;
      this.interestTaxNetIncrease = this.part5Tabs[event].interestTaxNetIncrease;
      this.Divide15bBy3 = this.part5Tabs[event].Divide15bBy3;
      this.Multiply15cBy125percent = this.part5Tabs[event].Multiply15cBy125percent;
      this.Subt15dFrom15a = this.part5Tabs[event].Subt15dFrom15a;
      this.Subt16dFrom16c = this.part5Tabs[event].Subt16dFrom16c;
    } else if (partName === 'partSix') {
      this.part6Selected.setValue(event);
      // console.log(event);
      this.resetOutstandingElectionTY();
      if (this.taxYearsOE[0].data[this.part6Tabs[event].selectedIndex]) {
        this.taxYearsOE[0].data[this.part6Tabs[event].selectedIndex].selected = true;
      }
      this.selectedIndex = this.part6Tabs[event].selectedIndex;
      this.electionYear = this.part6Tabs[event].electionYear;
      this.unDistEarnings = this.part6Tabs[event].unDistEarnings;
      this.deferredTax = this.part6Tabs[event].deferredTax;
      this.deferredTaxInterest = this.part6Tabs[event].deferredTaxInterest;
      this.evtTermElection = this.part6Tabs[event].evtTermElection;
      this.earnDistOrDeemed = this.part6Tabs[event].earnDistOrDeemed;
      this.deferredTaxDue = this.part6Tabs[event].deferredTaxDue;
      this.accrdInterestDue = this.part6Tabs[event].accrdInterestDue;
      this.deferredTaxAftTerm = this.part6Tabs[event].deferredTaxAftTerm;
      this.accrdInterestAftTerm = this.part6Tabs[event].accrdInterestAftTerm;
    }

  }

  resetOutstandingElectionTY(): void {
    _.each(this.taxYearsOE[0].data, function(item) {
      item.selected = false;
    });
  }

  onOutstandingElectionTYChanged(event: any, index: number): void {
    this.part6Tabs[index].electionYear = event.selected[0].text;
    this.selectedIndex = event.selected[0].index;
    this.electionYear = event.selected[0].text;
    this.part6Tabs[index].selectedIndex = event.selected[0].index;
  }

  onShareValueRangeChanged(event: any, shareValue: number) {
    this.shareValueTaxyearEnd = (event.srcElement.checked) ? shareValue : undefined;
  }

  onPficTypeChanged(event: any, type: number) {
    this.pficType = (event.srcElement.checked) ? type : 0;
  }

  isFormValid(placeholder: boolean): boolean {
    if (!this.shareholder) {
      this.dstSharedService.showAlert('error', 'Please specify the LEID for the Shareholder');
      return false;
    }

    // skip further validation if created as a placeholder
    if (placeholder) {
      return true;
    }

    if (this.shareholderTaxYearType === 'Range') {
      if (this.shareholderBeginningTaxYear === '') {
        this.dstSharedService.showAlert('error', 'Please specify beginning Tax year for the shareholder');
        return false;
      }
      if (this.shareholderEndingTaxYear === '') {
        this.dstSharedService.showAlert('error', 'Please specify ending Tax year for the shareholder');
        return false;
      }
    }

    if (this.PFICTaxYearType === 'Range') {
      if (this.companyOrFundBeginningTaxYear === '') {
        this.dstSharedService.showAlert('error', 'Please specify beginning Tax year for the PFIC/QEF');
        return false;
      }
      if (this.companyOrFundEndingTaxYear === '') {
        this.dstSharedService.showAlert('error', 'Please specify ending Tax year for the PFIC/QEF');
        return false;
      }
    }

    if (this.valueOfSharesYearEnd === '' && (this.shareValueTaxyearEnd === undefined)) {
      this.dstSharedService.showAlert('error', 'Please specify Share Value Range');
      return false;
    }

    if (this.pficType == 0 || (this.pficType === 1 && this.pficType1Amount === '')
      || (this.pficType === 2 && this.pficType2Amount === '') || (this.pficType === 3 && this.pficType3Amount === '')) {
      this.dstSharedService.showAlert('error', 'Please answer line 5 (a, b or c)');
      return false;
    }

    if (this.electionToTreat === true || this.electionToExtend === true) {
      if (this.proRateShares === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 6a');
        return false;
      }
      if (this.portionLine6a === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 6b');
        return false;
      }
      if (this.proRateSharesNetGain === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 7a');
        return false;
      }
      if (this.portionLine7a === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 7b');
        return false;
      }
    }

    if (this.electionToExtend === true) {
      if (this.cashPropertyDeemedDistributedCash === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 8b');
        return false;
      }
      if (this.portionLine8c === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 8c');
        return false;
      }
      if (this.totalTax === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 9a');
        return false;
      }
      if (this.totalTaxWithoutRegard === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 9b');
        return false;
      }
    }

    if (this.electionToMarkToMarket === true) {
      if (this.fairMarketValue === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 10'); //10a
        return false;
      }
      if (this.adjustedBasisStock === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 11'); //10b
        return false;
      }
      if (this.unreversedInclusion === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 13'); //11
        return false;
      }
    }

    if (this.deemedSalesElection === true || this.electionToRecognizeGain === true) {
      const notFilledLine15f = this.part5Tabs.find((item) => item.gainOrLossDistributionSection1291 === '');
      if (notFilledLine15f && notFilledLine15f.gainOrLossDistributionSection1291 === '') {
        this.dstSharedService.showAlert('error', 'Please specify Line 15f');
        return false;
      }
    }

    return true;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;

    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.shareholder, this.PFIC!])) {
      return;
    } else {
      this.loading = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId || '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.shareholder ? this.shareholder?.ENTITYID.toString()! : '',
        'secondaryEntityId': this.PFIC ? this.PFIC?.ENTITYID.toString()! : '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };

      const DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY: string[] = []; //15a
      const DistriAndDispositionOfStockDistributionsIn3PrecedingTY: string[] = [];//15b
      const precedingYears: string[] = [];//15c textbox
      const DistriAndDispositionOfStockAvgDistribution3PrecedingTY: string[] = [];//15c
      const DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct: string[] = [];//15d
      const DistriAndDispositionOfStockTotalExcessDistribution: string[] = [];//15e
      const DistriAndDispositionOfStockGainLossFromDispositionOfStock: string[] = [];//15f
      const TotalCashAndDistributionsreferenceDocumentId: string[] = [];//16a
      const DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY: string[] = [];//16b
      const DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY: string[] = [];//16c
      const DistriAndDispositionOfStockForeignTaxCredit: string[] = [];//16d
      const DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr: string[] = [];//16e
      const DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax: string[] = [];//16f
      this.part5Tabs.forEach((tab: any) => {
        DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY.push(tab.totalDistribution?.toString());
        DistriAndDispositionOfStockDistributionsIn3PrecedingTY.push(tab.totalDistributionByFund?.toString());
        precedingYears.push(tab.precedingTaxYear?.toString());
        DistriAndDispositionOfStockAvgDistribution3PrecedingTY.push(tab.Divide15bBy3?.toString());
        DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct.push(tab.Multiply15cBy125percent?.toString());
        DistriAndDispositionOfStockTotalExcessDistribution.push(tab.Subt15dFrom15a?.toString());
        DistriAndDispositionOfStockGainLossFromDispositionOfStock.push(tab.gainOrLossDistributionSection1291?.toString());
        TotalCashAndDistributionsreferenceDocumentId.push(tab.stmtForDistAndDisp?.toString());
        DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY.push(tab.totalAmountLine16a?.toString());
        DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY.push(tab.aggIncreaseInTax?.toString());
        DistriAndDispositionOfStockForeignTaxCredit.push(tab.ftc?.toString());
        DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr.push(tab.Subt16dFrom16c?.toString());
        DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax.push(tab.interestTaxNetIncrease?.toString());
      });

      const ElectionStatusTaxYearOfOutstandingElection: string[] = [];
      const ElectionStatusUndistributedEarnings: string[] = [];
      const ElectionStatusDeferredTax: string[] = [];
      const ElectionStatusInterestAccruedOnDeferredTax: string[] = [];
      const ElectionStatusEventTerminatingElection: string[] = [];
      const ElectionStatusEarningsDistributedDuringTheTY: string[] = [];
      const ElectionStatusDeferredTaxDueWithThisReturn: string[] = [];
      const ElectionStatusAccruedInterestDueThisReturn: string[] = [];
      const ElectionStatusDeferredTaxAfterPartialTerm: string[] = [];
      const ElectionStatusIntAccruedAfterPartialTerm: string[] = [];
      this.part6Tabs.forEach((tab: any) => {
        ElectionStatusTaxYearOfOutstandingElection.push(tab.electionYear?.toString());
        ElectionStatusUndistributedEarnings.push(tab.unDistEarnings?.toString());
        ElectionStatusDeferredTax.push(tab.deferredTax?.toString());
        ElectionStatusInterestAccruedOnDeferredTax.push(tab.deferredTaxInterest?.toString());
        ElectionStatusEventTerminatingElection.push(tab.evtTermElection?.toString());
        ElectionStatusEarningsDistributedDuringTheTY.push(tab.earnDistOrDeemed?.toString());
        ElectionStatusDeferredTaxDueWithThisReturn.push(tab.deferredTaxDue?.toString());
        ElectionStatusAccruedInterestDueThisReturn.push(tab.accrdInterestDue?.toString());
        ElectionStatusDeferredTaxAfterPartialTerm.push(tab.deferredTaxAftTerm?.toString());
        ElectionStatusIntAccruedAfterPartialTerm.push(tab.accrdInterestAftTerm?.toString());
      });

      const jsonObject = {
        'TransferorLEID': this.shareholder ? this.shareholder?.LEID.toString() : '',
        'Business': this.shareholder ? this.shareholder?.BUSINESSID.toString() : '',
        'MissingEINReason': this.MissingEINReason?.toString(),
        'ShareHolderTaxYearCal': this.shareholderTaxYearType === 'Year' ? '1' : '',
        'ShareHolderTaxYearDR': this.shareholderTaxYearType === 'Range' ? '1' : '',
        'ShareholderTaxYear': this.ShareholderTaxYear?.toString() || '',
        'ShareholderBeginningTaxYear': this.shareholderBeginningTaxYear?.toString() || '',
        'ShareholderEndingTaxYear': this.shareholderEndingTaxYear?.toString() || '',
        'QualifiedInsuranceCorpElectInd': this.corpElectionChecked === true ? '1' : '',
        'IndividualShareholder': this.shareholderFiling === 'Individual' ? '1' : '',
        'CorporationShareholder': this.shareholderFiling === 'Corporation' ? '1' : '',
        'PartnershipShareholder': this.shareholderFiling === 'Partnership' ? '1' : '',
        'SCorporationShareholder': this.shareholderFiling === 'SCorporation' ? '1' : '',
        'NongrantorTrustShr': this.shareholderFiling === 'NongrantorTrust' ? '1' : '',
        'EstateShareholder': this.shareholderFiling === 'Estate' ? '1' : '',
        'PFICGOLD': this.isPFICInDB === 'Yes' ? '1' : '',
        'TransfereeLEID': this.PFIC ? this.PFIC?.LEID.toString()! : '',
        'Business1': this.PFIC ? this.PFIC?.BUSINESSID.toString()! : '',
        'EINMissingReason': this.reasonEIN?.toString() || '',
        'Country': this.PFICCountryId?.toString() || '',
        'PFICUS': this.PFICUS ? '1' : '0',
        'USAddressOfPFICOrQEFAddressLine1': this.PFICUS ? this.PFICAdd1?.toString() : '',
        'USAddressOfPFICOrQEFAddressLine2': this.PFICUS ? this.PFICAdd2?.toString() : '',
        'USAddressOfPFICOrQEFCity': this.PFICUS ? this.PFICCity?.toString() : '',
        'USAddressOfPFICOrQEFState': this.PFICUS ? this.PFICStateProvId?.toString() : '',
        'USAddressOfPFICOrQEFZIPCode': this.PFICUS ? this.PFICZip?.toString() : '',
        'ForeignAddressOfPFICOrQEFAddressLine1': !this.PFICUS ? this.PFICAdd1?.toString() : '',
        'ForeignAddressOfPFICOrQEFAddressLine2': !this.PFICUS ? this.PFICAdd2?.toString() : '',
        'ForeignAddressOfPFICOrQEFCity': !this.PFICUS ? this.PFICCity?.toString() : '',
        'ForeignAddressOfPFICOrQEFProvinceOrState': !this.PFICUS ? this.PFICStateProv?.toString() : '',
        'ForeignAddressOfPFICOrQEFCountry': !this.PFICUS ? this.PFICCountryId?.toString() : '',
        'ForeignAddressOfPFICOrQEFPostalCode': !this.PFICUS ? this.PFICZip?.toString() : '',
        'TaxYearOfCompanyOrFund': this.PFICTaxYear?.toString() || '',
        'CompanyOrFundBeginningTaxYear': this.companyOrFundBeginningTaxYear?.toString() || '',
        'CompanyOrFundEndingTaxYear': this.companyOrFundEndingTaxYear?.toString() || '',
        'DescribeEachClassOfShares': this.shareHoldersSharesDesc?.toString() || '', //1
        'SharesJointlyOwnedWithSpouse': (this.sharesOwnedWithSpouse) ? '1' : '', //1 checkbox
        'DateSharesAcquiredTxblYr': this.dateSharesAcquired?.toString() || '', //2
        'NumberOfSharesAtEndOfTxblYr': this.noOfSharesYearEnd?.toString() || '', //3
        'ValueOfSharesRangeA': this.shareValueTaxyearEnd === 0 ? '1' : '', //4a
        'ValueOfSharesRangeB': this.shareValueTaxyearEnd === 50001 ? '1' : '', //4b
        'ValueOfSharesRangeC': this.shareValueTaxyearEnd === 100001 ? '1' : '', //4c
        'ValueOfSharesRangeD': this.shareValueTaxyearEnd === 150001 ? '1' : '', //4d
        'ValueOfSharesRangeE': this.valueOfSharesYearEnd?.toString() || '', //4e
        'Section1291': this.pficType === 1 ? '1' : '', //5a checkbox
        'Section1293': this.pficType === 2 ? '1' : '', //5b checkbox
        'Section1296': this.pficType === 3 ? '1' : '', //5c checkbox
        'Section1291Amt': this.pficType === 1 ? this.pficType1Amount?.toString() : '', //5a
        'Section1293Amt': this.pficType === 2 ? this.pficType2Amount?.toString() : '', //5b
        'Section1296Amt': this.pficType === 3 ? this.pficType3Amount?.toString() : '', //5c
        'ElectionToTreatThePFICAsQEF': this.electionToTreat === true ? '1' : '0', //A
        'ElectToExtendTimeForPymtOfTax': this.electionToExtend === true ? '1' : '0', //B
        'ElectToMarkToMarketPFICStock': this.electionToMarkToMarket === true ? '1' : '0', //C
        'DeemedSaleElection': this.deemedSalesElection === true ? '1' : '0', //D
        'DeemedDividendElection': this.deemedDividentElection === true ? '1' : '0', //E
        'ElectToRecognizeGainOnPFICSale': this.electionToRecognizeGain === true ? '1' : '0', //F
        'DeemedDivElectSec1297ePFICInd': this.deemedDividendElectionWrtSection1297 === true ? '1' : '0', //G
        'DeemedDivElectFrmrPFICInd': this.deemedDividendElectionWrtFormerPfic === true ? '1' : '0', //H
        'DeemedDividendElectionreferenceDocumentId': this.electionToExtendDesc?.toString() || '', //B textbox
        'ProRataShareOfQEFOrdinaryEarn': this.proRateShares?.toString() || '', //6a
        'IncomePortionOfOrdinaryEarn': this.portionLine6a?.toString() || '', //6b
        'DividendIncome': this.OrdinaryIncome?.toString() || '', //6c
        'ProRataShareOfTotalNetCapGain': this.proRateSharesNetGain?.toString() || '', //7a
        'IncomePortionOfNetCapitalGain': this.portionLine7a?.toString() || '', //7b
        'NetLongTermCapitalGain': this.NetCapitalGain?.toString() || '', //7c
        'DividendIncomeAndNetLTCG': this.Line6cAnd7cSum?.toString() || '', //8a
        'TotalCashAndDistributions': this.cashPropertyDeemedDistributedCash?.toString() || '', //8b
        'PortionOfProRataOrdnryEarnings': this.portionLine8c?.toString() || '', //8c
        'TotalCashAndPortionOfProRata': this.Line8bAnd8cSum?.toString() || '', //8d
        'ProRataLessCashAndPortion': this.Line8dAnd8aSubt?.toString() || '', //8e
        'TotalTaxForTheTaxYear': this.totalTax?.toString() || '', //9a
        'TotTaxWithoutProRataLessCash': this.totalTaxWithoutRegard?.toString() || '', //9b
        'DeferredTax': this.Line9bAnd9aSubt?.toString() || '', //9c
        'FairMarketValueOfPFICStock': this.fairMarketValue?.toString() || '', //10a
        'AdjustedBasisInStockEndOfTY': this.adjustedBasisStock?.toString() || '', //10b
        'Excess': this.OrdinaryIncomeOnTaxReturn?.toString() || '', //10c
        'UnreversedInclusions': this.unreversedInclusion?.toString() || '', //11
        'ExcessOrUnreservedInclusions': this.lossLine10c?.toString() || '', //12
        'FMVStkOnDtSaleOrDisposAmt': this.fairMarketDispositionValue?.toString() || '', //13a
        'AdjBasisStkOnDtSaleOrDisposAmt': this.adjustedBasisDispositionStock?.toString() || '', //13b
        'OrdinaryIncomeFromPFICStkAmt': this.Line13bAnd13aSubt?.toString() || '', //13c
        'StkSaleUnreversedInclusionsAmt': this.unreversedInclusionSection1296?.toString() || '', //14a
        'LossLimitedByOrdinaryIncomeAmt': this.lossLine13c?.toString() || '', //14b
        'LossExcessOfUnrvrsdInclsnAmt': this.lossLine13cExceedInclusion?.toString() || '', //14c
        'DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY': DistriAndDispositionOfStockTotalPFICDistriDuringCurrentTY, //15a
        'DistriAndDispositionOfStockDistributionsIn3PrecedingTY': DistriAndDispositionOfStockDistributionsIn3PrecedingTY, //15b
        'precedingYears': precedingYears, //15c textbox
        'DistriAndDispositionOfStockAvgDistribution3PrecedingTY': DistriAndDispositionOfStockAvgDistribution3PrecedingTY, //15c
        'DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct': DistriAndDispositionOfStockAverageDistri3PreviousTY125Pct, //15d
        'DistriAndDispositionOfStockTotalExcessDistribution': DistriAndDispositionOfStockTotalExcessDistribution, //15e
        'DistriAndDispositionOfStockGainLossFromDispositionOfStock': DistriAndDispositionOfStockGainLossFromDispositionOfStock, //15f
        'TotalCashAndDistributionsreferenceDocumentId': TotalCashAndDistributionsreferenceDocumentId, //16a
        'DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY': DistriAndDispositionOfStockTotAllocatableCurrAndPrePFICTY, //16b
        'DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY': DistriAndDispositionOfStockAggregateIncreaseInTaxEachTY, //16c
        'DistriAndDispositionOfStockForeignTaxCredit': DistriAndDispositionOfStockForeignTaxCredit, //16d
        'DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr': DistriAndDispositionOfStockAggregateIncrLessForeignTaxCr, //16e
        'DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax': DistriAndDispositionOfStockInterestOnEachNetIncreaseInTax, //16f
        'ElectionStatusTaxYearOfOutstandingElection': ElectionStatusTaxYearOfOutstandingElection, //17
        'ElectionStatusUndistributedEarnings': ElectionStatusUndistributedEarnings, //18
        'ElectionStatusDeferredTax': ElectionStatusDeferredTax, //19
        'ElectionStatusInterestAccruedOnDeferredTax': ElectionStatusInterestAccruedOnDeferredTax, //20
        'ElectionStatusEventTerminatingElection': ElectionStatusEventTerminatingElection, //21
        'ElectionStatusEarningsDistributedDuringTheTY': ElectionStatusEarningsDistributedDuringTheTY, //22
        'ElectionStatusDeferredTaxDueWithThisReturn': ElectionStatusDeferredTaxDueWithThisReturn, //23
        'ElectionStatusAccruedInterestDueThisReturn': ElectionStatusAccruedInterestDueThisReturn, //24
        'ElectionStatusDeferredTaxAfterPartialTerm': ElectionStatusDeferredTaxAfterPartialTerm, //25
        'ElectionStatusIntAccruedAfterPartialTerm': ElectionStatusIntAccruedAfterPartialTerm, //26
      };

      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', 'Error saving the statement!');
        this.loading = false;
      }).add(() => {
        this.loading = false;
      });
    }
  }

  cancel(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

}
