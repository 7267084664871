import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[dragAndDropUpload]'
})
export class DragAndDropUploadDirective {

  @Input() disabled: boolean = false;
  @Input() maxLength: number = 0; // maximum file length allowed per event, 0 is no limit
  @Input() allowedExts: string[] = []; // list of allowed file extension name, i.e., ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm']
  @Output() maxLenExceeded: EventEmitter<number> = new EventEmitter<number>();
  @Output() extNotMet: EventEmitter<null> = new EventEmitter<null>();
  @Output() filesDropped: EventEmitter<FileList> = new EventEmitter<FileList>(); // emitted when selected files are dropped, carrying a payload of the FileList
  @HostBinding('class.file-over') fileOver: boolean = false; // adding a class name to the host element when user drags files over it
  @HostBinding('class.error') hasError: boolean = false; // adding a class name to the host element on error

  constructor() { }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
    if (this.disabled) {
      event.dataTransfer && (event.dataTransfer.dropEffect = 'none');
    }
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;

    const files: FileList | undefined = event.dataTransfer?.files;
    if (this.disabled || !files) {
      return;
    }
    if (files.length > this.maxLength) {
      this.hasError = true;
      this.maxLenExceeded.emit(files.length);
      return;
    }
    if (!this.checkFileExt(files)) {
      this.hasError = true;
      this.extNotMet.emit();
      return;
    }
    this.hasError = false;
    this.filesDropped.emit(files);
  }

  private checkFileExt(files: FileList): boolean {
    for (let i = 0; i < files.length; i++) {
      const extName = files[i].name.substring(files[i].name.lastIndexOf('.') + 1);
      if (this.allowedExts.indexOf(extName) < 0) {
        return false;
      }
    }
    return true;
  }

}
