import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GtwSnackbarService {

  private durationInSeconds = 4;

  constructor(private _snackBar:MatSnackBar) { }

  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": panelClass
    });
  }
  


}
