
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionService } from '../../../shared/_services/action.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { pick } from 'lodash';
interface Category {
  TBEPS_TAX_QTR_KEY: number,
  TAX_YEAR: number,
  QUARTER: number,
  ACTIVE: number,
}
interface GtwInput {
  baseUrl: string;
  ssoId: string;
  clientKey: number;
  type: 'add' | 'edit'
  data: Category;
  taxYears: { label: string, value: string }[];
}
@Component({
  selector: 'gtw-cbc-beps-quarters',
  templateUrl: './beps-quarters.component.html'
})
export class BepsQuartersComponent implements OnInit {

  @Input('gtw-input')
  set gtwInputStr(input: string) {
    try {
      this.gtwInput = JSON.parse(input);

      if (this.gtwInput.type === 'edit') {
        this.quartersForm.setValue(pick(this.gtwInput.data, ["TAX_YEAR", "QUARTER", "ACTIVE"]));
        this.quartersForm.get('TAX_YEAR')?.disable();
        this.quartersForm.get('QUARTER')?.disable();
      }
      else
        this.quartersForm?.get("TAX_YEAR")?.setValue(this.gtwInput.taxYears[0].label);
    } catch (e) {
      console.error('Error parsing gtwInput', e);
    }
  };

  gtwInput!: GtwInput;

  quartersForm!: FormGroup;

  @Output('close-modal')
  closeModal = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private actionService: ActionService,
    private alertService: GtwSnackbarService
  ) { }

  ngOnInit(): void {
    this.quartersForm = this.formBuilder.group({
      TAX_YEAR: [this.gtwInput?.taxYears[0]?.label, Validators.required],
      QUARTER: [1, Validators.required],
      ACTIVE: [1, Validators.required],
    });

  }

  onSubmit(): void {
    if (this.quartersForm.valid) {
      const params = new HttpParams({
        fromObject: {
          ...this.quartersForm.value,
          ACTIVE: this.quartersForm.get("ACTIVE")?.value ? "1" : "0",
          TBEPS_TAX_QTR_KEY: this.gtwInput.type === 'edit' ? this.gtwInput.data.TBEPS_TAX_QTR_KEY : undefined
        }
      });

      this.actionService.saveActionData(this.gtwInput.baseUrl, 33283, this.gtwInput.clientKey, params).subscribe((response: any) => {

        if (response.callSuccess === "1") {
          this.alertService.openSnackBar("Data saved successfully", "gtw-snackbar--green");
        } else {
          this.alertService.openSnackBar("Failed to save data", "gtw-snackbar--red");
        }
        this.cancel();
      });
    }
  }

  cancel() {
    this.closeModal.emit();
  }
}
