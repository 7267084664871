import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActionService} from '../../shared/_services/action.service';
import {ActionResponse} from '../../shared/_models/action-response.model';
import {map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {DSTStatement} from '../shared/models/dst-statement.model';
import {DSTSharedService} from '../shared/dst-shared.service';
import { FormReivewPdf } from '../../shared/components/form-review-pdf/form-review-pdf.service';

@Injectable({
  providedIn: 'root'
})
export class StatementTrackerService {

  refresh: boolean = false; //  indicates if tracker needs a refresh when activated

  static readonly ID_CREATE_NEW = 'Create New';
  static readonly ID_TRACKER = 'Statement Tracker';
  static readonly ID_MODAL = 'Modal'; // only used in modals

  constructor(private httpClient: HttpClient,
              private actionService: ActionService,
              private dstSharedService: DSTSharedService) {
  }

  loadGrid(taxYear: string, parentMeString: string): Observable<DSTStatement[]> {
    const params = new HttpParams({
      fromObject: {
        'tax_year': taxYear,
        'parent_me_string': parentMeString
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32131, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  loadMarkAsOptions(): Observable<any> {
    const params = new HttpParams({});
    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32146, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  loadProjects(taxYear: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'tax_year': taxYear
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32148, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  loadBusinessTiers(taxYear: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        'tax_year': taxYear
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32149, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  getPDFProformaParams(statementId: string): Observable<FormReivewPdf[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id': statementId
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32578, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  getCreateStatements(csStatementId: number): Observable<DSTFormOption[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'cseId': csStatementId.toString()
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32317, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  getDstStmtData(statementId: string): Observable<DSTStatement[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statement_id': statementId
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32724, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          return data.jsonObject;
        })
      );
  }

  getDstStmtExportData(parent_me_string: string, statementId: string, taxYear: string, leId: string, disclosureShortName: string
    , creationSource: string, createdBy: string, projectName: string): Observable<DSTStatement[]> {
    const params = new HttpParams({
      fromObject: {
        'sso_id': this.dstSharedService.user?.ssoId,
        'statementId': statementId,
        'tax_year': taxYear,
        'parent_me_string': parent_me_string,
        'leId': leId,
        'disclosureShortName': disclosureShortName,
        'creationSource': creationSource,
        'createdBy': createdBy,
        'projectName': projectName,
      }
    });

    return this.actionService.getActionData(this.dstSharedService.baseURLs.api, 32835, this.dstSharedService.clientKey, params)
      .pipe(
        map((data: ActionResponse) => {
          if (data.callSuccess === '1') {
            return data.jsonObject;
          }
          return throwError('Something went wrong while getting the Statement Tracker Export data, please try again!');
        })
      );
  }

}

export class Alert {
  private _type: string;
  private _message: string;
  private _timeout: number;
  private _display: boolean;

  constructor() {
    this._type = 'success';
    this._message = '';
    this._timeout = 4000;
    this._display = false;
  }

  set type(type: string) {
    this._type = type;
  }

  set message(msg: string) {
    this._message = msg;
  }

  set timeout(timeout: number) {
    this._timeout = timeout;
  }

  set display(display: boolean) {
    this._display = display;
  }

  get type(): string {
    return this._type;
  }

  get message(): string {
    return this._message;
  }

  get timeout(): number {
    return this._timeout;
  }

  get display(): boolean {
    return this._display;
  }
}

export interface DSTFormOption {
  FORMID: string,
  FORMNAME: string,
  FORMTYPE: string,
  CONFIRMMESSAGE: string
}
