import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[cellHighlight]'
})
export class CellHighlightDirective implements OnInit {
    @Input() value: string = '';
    numToEval!: number;
    COLOR_DANGER: string = '#96DFA7';
    COLOR_SUCCESS: string = '#F2B09D';

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.numToEval = parseInt(this.value);
        this.renderer.setStyle(this.el.nativeElement,
            'color',
            'white');

        this.changeColor();
    }

    changeColor(): void {
        if (this.numToEval === 0) {
            this.renderer.setStyle(this.el.nativeElement,
                'background-color',
                this.COLOR_DANGER);
        }
        else if(this.numToEval > 0){
            this.renderer.setStyle(this.el.nativeElement,
                'background-color',
                this.COLOR_SUCCESS);
        }
    }
}