import { AfterViewInit, Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { StmtBtnActionPayload } from '../../shared/components/stmt-id-btn/stmt-id-btn.component';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import { TrackerTabsService } from '../../statement-tracker/tracker-tabs.service';
import { Alert } from '../../statement-tracker/statement-tracker.service';
import { ProjectTaggingComponent } from '../../shared/components/project-tagging/project-tagging.component';
import { CopyReplicateComponent } from '../../statement-tracker/copy-replicate/copy-replicate.component';
import { SaveActionResponse } from '../../../shared/_models/save-action-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomReportService, DisclosureByTypesResponse } from './custom-report.service';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { BehaviorSubject, Subscription, zip } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { Table } from 'primeng-lts/table';
import { DfMeDropdownComponent } from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import { DSTDynamicComponentResolverService } from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import { PtableColConfigModel } from '../../shared/models/ptable-col-config.model';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import _ from 'lodash';

@Component({
  selector: 'dst-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss']
})
export class CustomReportComponent implements OnInit, OnChanges, AfterViewInit {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild('dt') table!: Table;
  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(ProjectTaggingComponent) projectTaggingComponent!: ProjectTaggingComponent;
  @ViewChild(CopyReplicateComponent) copyRepComponent!: CopyReplicateComponent;
  @ViewChildren(DfMeDropdownComponent) dfMeDropdownComponents!: QueryList<DfMeDropdownComponent>;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;

  showProjectTaggingModal: boolean = false;
  showCopyRepModal: boolean = false;
  rows: number = 16;
  first: number = 0;
  data: any[] = [];
  cols: PtableColConfigModel[] = [
    {
      field: 'statementId',
      header: 'Stmt ID',
      width: '120px'
    },
    {
      field: 'TAXYEAR',
      header: 'Year',
      width: '80px'
    },
    {
      field: 'formName',
      header: 'Disclosure',
      width: '180px'
    },
    {
      field: 'disclosureType',
      header: 'Disclosure Type',
      width: '150px'
    },
    {
      field: 'primaryBusiness',
      header: 'Business',
      width: '280px'
    },
    {
      field: 'secondaryBusiness',
      header: 'Secondary Business',
      width: '0px'
    },
    {
      field: 'primaryLeid',
      header: 'LEID',
      width: '80px'
    },
    {
      field: 'secondaryLeid',
      header: 'Secondary LEID',
      width: '0px'
    },
    {
      field: 'primaryEntity',
      header: 'Entity Name',
      width: '300px'
    },
    {
      field: 'secondaryEntity',
      header: 'Secondary Entity Name',
      width: '0px'
    },
    {
      field: 'branchLeid',
      header: 'Branch Leid',
      width: '0px'
    },
    {
      field: 'createdBy',
      header: 'Created By',
      width: '0px'
    },
    {
      field: 'modifiedBy',
      header: 'Modifed By',
      width: '0px'
    },
    {
      field: 'createdOn',
      header: 'Created On',
      width: '0px'
    },
    {
      field: 'modifiedOn',
      header: 'Modified On',
      width: '0px'
    },
    {
      field: 'FilingGroup',
      header: 'Filing Group',
      width: '360px'
    },
    {
      field: 'FilingGroupReturnType',
      header: 'Filing Group Return Type',
      width: '60px'
    },
    {
      field: 'status',
      header: 'Status',
      width: '80px'
    },
    {
      field: 'isQar',
      header: 'Is QAR',
      width: '0px'
    },
    {
      field: 'projectName',
      header: 'Project',
      width: '280px'
    },
    {
      field: 'projectStepName',
      header: 'Project Steps',
      width: '0px'
    },
    {
      field: 'projectBslaName',
      header: 'Project BSLA',
      width: '0px'
    },
    {
      field: 'ignoredId', //
      header: 'Diagnostics Status',
      width: '180px'
    },

    {
      field: 'signatureFlag',
      header: 'Signature Flag',
      width: '180px'
    },
    {
      field: 'electionFlag',
      header: 'Election Flag',
      width: '180px'
    },
    {
      field: 'createdBy',
      header: 'User Activity',
      width: '300px'
    }
  ];
  childCols: any[] = [
    {
      field: 'statementId',
      header: 'Stmt ID'
    },
    {
      field: 'TAXYEAR',
      header: 'Year'
    },
    {
      field: 'formName',
      header: 'Disclosure'
    },
    {
      field: 'disclosureType',
      header: 'Disclosure Type',
    },
    {
      field: 'primaryBusiness',
      header: 'Business'
    },
    {
      field: 'primaryLeid',
      header: 'LEID'
    },
    {
      field: 'primaryEntity',
      header: 'Entity Name'
    },
    {
      field: 'FilingGroup',
      header: 'Filing Group'
    },
    {
      field: 'FilingGroupReturnType',
      header: 'Filing Group Return Type'
    },
    {
      field: 'createdBy',
      header: 'User Activity'
    },
    {
      field: 'status',
      header: 'Status'
    },
    {
      field: 'projectName',
      header: 'Project'
    }
  ];
  disclosureTypes: DisclosureByTypes[] = [];
  allDisclosures: Disclosure[] = [];
  taxYears: DSTTaxYear[] = [];
  fStatusList: any[] = [{ value: '', name: 'All' }, { value: '1', name: 'Filed' }, { value: '0', name: 'Not Filed' }];
  QARStatusList: any[] = [{ value: '', name: 'All' }, { value: '1', name: 'Qar' }, { value: '0', name: 'Not Qar' }];
  sigElecFlagList: any[] = [{ value: '', name: 'All' }, { value: '1', name: 'Yes' }, { value: '0', name: 'No' }];
  selectedTaxYearsObj: DSTTaxYear[] = [];
  selectedDisclosuresObj: Disclosure[] = [];
  selectedFStatus: any = this.fStatusList[0];
  selectedQARStatus: any = this.QARStatusList[0];
  selectedSigFlag: any = this.sigElecFlagList[0];
  selectedElecFlag: any = this.sigElecFlagList[0];
  fStatementId: string = '';
  fFormName: string = '';
  fDisclosureType: string = '';
  fPrimaryLeid: string = '';
  fPrimaryEntity: string = '';
  fFilingGroup: string = '';
  fFilingGroupReturnType: string = '';
  fCreatedBy: string = '';
  fStatus: string = '';
  fProjectName: string = '';
  fSignatureFlag: string = '';
  fElectionFlag: string = '';
  loading: boolean = false;
  showModalViewer: boolean = false;
  showGridFilters: boolean = false;
  taxyearLoaded: boolean = false;
  activeRow!: DSTStatement;
  copyMode: string = '';
  alert: Alert;

  private selectedTaxYears: string = '';
  private selectedMes: string = '';
  private selectedDisclosures: string = '';
  private onDisCancelSubs!: Subscription;
  taxYear: DSTTaxYear | undefined;
  private selectedCustomMes: string = '';
  statementFilterCriteria: string = '';
  private statementFilter = new BehaviorSubject<string | null>(null);
  statementFilterSubscription: Subscription;
  multipleTaxYears: string = '';

  constructor(private dstSharedService: DSTSharedService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerTabsService: TrackerTabsService,
    private cReportService: CustomReportService,
    private cDSerivce: CreateDisclosureService,
    private dCRService: DSTDynamicComponentResolverService
  ) {
    // local test only
    this.dstSharedService.setBaseURLs({
      api: '/gtw',
      custom: '/custom',
      admin: '/admin',
      efile: '/gtw-efile-api',
      pdfEngine: '/pdf-engine',
      bulkPdfEngine: '/bulk-pdf-engine'
    });
    this.dstSharedService.setClientKey('92');
    this.dstSharedService.setDSTUser('400396761', 5);
    this.dstSharedService.setS3uploadButtonAccessUsers(['400396761']);
    this.dstSharedService.setTaxYearScenario('2022', '52');
    this.selectedTaxYears = '2022';
    // // end of local test only
    this.alert = dstSharedService.alert;

    this.statementFilterSubscription = this.statementFilter.pipe(debounceTime(600)).subscribe((val: string | null) => {

      if (val === null) return;

      this.filterCol({ target: { value: val } }, 'statementId', 'contains')

      setTimeout(() => {
        if (!this.table.filteredValue || this.table.filteredValue.length === 0) {

          this.statementFilterCriteria = val;
          this.refresh();
        }

      })
    });
  }

  ngOnInit(): void {
    window.onresize = () => {
      this.viewportReady();
    };

    // this.populateTestData();
    // local test only
    this.data = [];
    this.loading = true;
    const getTaxYears$ = this.cDSerivce.getTaxYears();
    const getDisclosures$ = this.cReportService.loadDisclosures();
    zip(getTaxYears$, getDisclosures$).pipe(take(1)).subscribe((data: [DSTTaxYear[], DisclosureByTypesResponse[]]) => {
      this.taxYears = data[0];
      this.setTaxYear('2022');
      try {
        data[1].forEach((d: DisclosureByTypesResponse) => {
          const disclosureType = JSON.parse(d.DISCLOSURETYPES);
          this.disclosureTypes.push(disclosureType);
          this.allDisclosures = _.uniqBy(this.allDisclosures.concat(disclosureType.children), "formId");
        });
      } catch (e: any) {
        if (e instanceof SyntaxError) {
          console.log('JSON parse Syntax Error' + e);
        } else {
          console.error(e);
        }
      }
      this.refresh();
    });
    // end of local test only
    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
      this.showCopyRepModal = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        this.selectedTaxYears = this.dstSharedService.gtwTaxYear;
        this.disclosureTypes = [];
        this.allDisclosures = [];
        this.data = [];
        this.loading = true;
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        const getDisclosures$ = this.cReportService.loadDisclosures();
        zip(getTaxYears$, getDisclosures$).pipe(take(1)).subscribe((data: [DSTTaxYear[], DisclosureByTypesResponse[]]) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);

          // this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          try {
            data[1].forEach((d: DisclosureByTypesResponse) => {
              const disclosureType = JSON.parse(d.DISCLOSURETYPES);
              this.disclosureTypes.push(disclosureType);
              this.allDisclosures = _.uniqBy(this.allDisclosures.concat(disclosureType.children), "formId");
            });
          } catch (e: any) {
            if (e instanceof SyntaxError) {
              console.log('JSON parse Syntax Error' + e);
            } else {
              console.error(e);
            }
          }
          this.refresh();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  ngAfterViewInit(): void {
    this.viewportReady();
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
    this.statementFilterSubscription?.unsubscribe();
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  onTaxYearChange(): void {
    // this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
    // this.setScenarios(this.taxYear?.text.toString()!);
    this.selectedTaxYearsObj = [this.taxYear!];
    console.log(this.taxYear)
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
  }

  // calculate tbody max height and number of rows to fit inside
  private viewportReady(): void {
    const gridContainerHeight: number = document.getElementById('cr-grid-container')?.getBoundingClientRect().height || 0;
    const topToolbarHeight: number = 45;
    const tHeadHeight: number = 90;
    const paginatorHeight: number = 60;
    const tbodyMaxHeight: number = Math.floor(gridContainerHeight - topToolbarHeight - tHeadHeight - paginatorHeight);
    if (tbodyMaxHeight < 0) {
      return;
    }
    (document.getElementsByClassName('p-datatable-scrollable-body')[0] as HTMLElement).style.maxHeight = (tbodyMaxHeight - 1) + 'px';
    setTimeout(() => {
      this.rows = Math.ceil(tbodyMaxHeight / 70);
    });
  }

  resetGridFilters(): void {
    this.selectedTaxYearsObj = [this.taxYear!];
    this.selectedDisclosuresObj = [];
    this.selectedTaxYears = '';
    this.selectedDisclosures = '';
    this.dfMeDropdownComponents.forEach((d: DfMeDropdownComponent) => {
      d.clear();
    });
    this.selectedFStatus = this.fStatusList[0];
    this.selectedQARStatus = this.QARStatusList[0];
    this.selectedSigFlag = this.sigElecFlagList[0];
    this.selectedElecFlag = this.sigElecFlagList[0];
  }

  private resetColFilters(): void {
    this.fStatementId = '';
    this.fFormName = '';
    this.fDisclosureType = '';
    this.fPrimaryLeid = '';
    this.fPrimaryEntity = '';
    this.fFilingGroup = '';
    this.fFilingGroupReturnType = '';
    this.fCreatedBy = '';
    this.fStatus = '';
    this.fProjectName = '';
    this.fSignatureFlag = '';
    this.fElectionFlag = '';
    this.selectedTaxYearsObj = [this.taxYear!];
  }

  resetAllFilters(): void {
    this.resetGridFilters();
    this.refresh();

  }
  onTaxYearsFilterChange(event: any): void {
    this.selectedTaxYears = event.value.map((i: DSTTaxYear) => i.text).join(',');
    this.loadMes();
  }

  onDiscFilterChange(event: any): void {
    this.selectedDisclosures = event.value.map((d: Disclosure) => d.formId).join(',');
  }

  selectedMeFilterChanged(selected: string): void {
    this.selectedMes = selected;
    console.log(selected);
  }

  showFilters(): void {
    this.showGridFilters = true;
  }

  private loadMes(): void {
    this.dfMeDropdownComponents.forEach((d: DfMeDropdownComponent) => {
      d.loadMEsCustom(this.selectedTaxYears);
    });
  }

  filterByStatementId(event: Event) {

    this.statementFilter.next((event.target as HTMLInputElement).value);
  }
  refresh(): void {
    this.showGridFilters = false;
    this.data = [];
    this.loading = true;


    this.cReportService.loadCustomReports(this.multipleTaxYears || this.taxYear!?.text.toString(), this.selectedDisclosures, this.selectedMes, this.selectedFStatus.value,
      this.selectedQARStatus.value, this.selectedSigFlag.value, this.selectedElecFlag.value, this.statementFilterCriteria)
      .subscribe((data: any) => {
        try {
          data.forEach((d: any) => {
            const rowData: DSTStatement = JSON.parse(d.CUSTOMREPORTSLIST);
            if (rowData.is304) {
              rowData.formId = -192;
            }
            this.data.push(rowData);
          });
          //console.log(this.data);
          this.table.clear();
          this.resetColFilters();
          this.loadMes();
        } catch (e: any) {
          if (e instanceof SyntaxError) {
            console.log('JSON parse Syntax Error' + e);
          } else {
            console.error(e);
          }
        }
        this.resetGridFilters();
        const fromMultipleTaxYears = this.taxYears.filter((ty: DSTTaxYear) => this.multipleTaxYears.split(',').includes(ty.text.toString()));
        if(fromMultipleTaxYears.length > 0){
          this.selectedTaxYearsObj = fromMultipleTaxYears;
        } else
          this.selectedTaxYearsObj = [this.taxYear!];

      }, (e: any) => {
        console.error(e);
        this.dstSharedService.showAlert('error', e);
      })
      .add(() => {
        this.loading = false;
        this.fStatementId = this.statementFilterCriteria;
      });
  }


  loadCustomFilteringData() {
    this.showGridFilters = false;
    this.data = [];
    this.loading = true;
    this.cReportService.loadCustomReports(this.taxYear?.text.toString()!, this.selectedDisclosures, this.selectedCustomMes, this.selectedFStatus.value,
      this.selectedQARStatus.value, this.selectedSigFlag.value, this.selectedElecFlag.value, this.statementFilterCriteria)
      .subscribe((data: any) => {
        try {
          data.forEach((d: any) => {
            const rowData: DSTStatement = JSON.parse(d.CUSTOMREPORTSLIST);
            if (rowData.is304) {
              rowData.formId = -192;
            }
            this.data.push(rowData);
          });
          this.table.clear();
          this.resetColFilters();
          this.selectedTaxYearsObj = [this.taxYear!];
        } catch (e: any) {
          if (e instanceof SyntaxError) {
            console.log('JSON parse Syntax Error' + e);
          } else {
            console.error(e);
          }
        }
        this.multipleTaxYears = '';//empty this as we want the single tax years to be used for subsequent request unless the refresh request came from multi select dropdown.
      }, (e: any) => {
        console.error(e);
        this.dstSharedService.showAlert('error', e);
      })
      .add(() => {
        this.loading = false;
        this.fStatementId = this.statementFilterCriteria;
      });

  }

  selectedMeChanged(selected: string): void {
    const mesArray = selected.split(',');
    this.table.filter(mesArray, 'primaryMaintOwner', 'in');
  }

  selectedCustomMeChanged(selected: any): void {
    this.selectedCustomMes = selected;
    // console.log(selected);
  }

  filterCol(event: Event | { target?: { value: string } }, field: string, matchMode: string): void {
    this.table.filter((event.target as HTMLInputElement).value, field, matchMode);
  }

  onTaxYearsChange(event: any): void {
    const taxYears = event.value.map((i: DSTTaxYear) => i.text);
    this.table.filter(taxYears, 'TAXYEAR', 'in');


      setTimeout(() => {
        if (!this.table.filteredValue || this.table.filteredValue.length === 0) {

          this.multipleTaxYears = taxYears.join(',');
          this.refresh();
        }

      })
  }

  onActionClicked(payload: StmtBtnActionPayload): void {
    switch (payload.name) {
      case 'show-view-update': {
        this.activeRow = payload.data;
        this.dstSharedService.setTableActionStatus(true);
        this.dstSharedService.setIsFiled(this.activeRow.IS_FILED!);
        this.stmtIdBtnService.getChildStatements(this.activeRow.statementId).subscribe((childFormStates: DSTChildFormState[]) => {
          this.showModalViewer = true;
          setTimeout(() => {
            this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
          });
        }, (eMsg: string) => {
          console.log(eMsg);
          this.dstSharedService.showAlert('error', eMsg);
        }).add(() => {
          this.dstSharedService.setTableActionStatus(false);
        });
        break;
      }
      case 'tagging-project': {
        this.activeRow = payload.data;
        this.showProjectTaggingModal = true;
        setTimeout(() => {
          this.projectTaggingComponent.showMore('project-tagging');
        });
        break;
      }
      case 'copy': {
        this.activeRow = payload.data;
        this.showCopyRepModal = true;
        this.copyMode = 'Copy';
        setTimeout(() => {
          this.copyRepComponent.showMore('copy-replicate');
        });
        break;
      }
      case 'replicate': {
        this.activeRow = payload.data;
        this.showCopyRepModal = true;
        this.copyMode = 'Replicate';
        setTimeout(() => {
          this.copyRepComponent.showMore('copy-replicate');
        });
        break;
      }
      case 'deactivate': {
        this.activeRow = payload.data;
        if (window.confirm('Are you sure you want to delete this statement?')) {
          this.loading = true;
          this.data.splice(this.data.indexOf(this.activeRow), 1);
          // this.tableData.splice(this.tableData.indexOf(this.activeRow), 1);
          this.stmtIdBtnService.deactivateStatement(this.activeRow.statementId)
            .subscribe((response: SaveActionResponse) => {
              if (response.callSuccess == 1) {
                this.dstSharedService.showAlert('success', 'Statement has been deleted successfully!');
              } else {
                this.dstSharedService.showAlert('error', 'Something went wrong while processing your request, please try again!');
              }
            }, (error: HttpErrorResponse) => {
              this.dstSharedService.showAlert('error', error.message);
            })
            .add(() => {
              this.loading = false;
            });
        }
        this.activeRow.showDropdown = false;
        break;
      }
      default:
        return;
    }
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  afterProjectTaggingModalClosed(): void {
    this.showProjectTaggingModal = false;
  }

  afterCopyRepModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showCopyRepModal = false;
  }

  // testData: any[] = [];
  // testCols: any[] = [{
  //   field: 'i'
  // }];
  //
  // populateTestData(): void {
  //   for (let i = 0; i < 1000; i++) {
  //     this.testData.push({i: i});
  //   }
  // }

}

interface DisclosureByTypes {
  statementTypes: string;
  children: Disclosure[];
}

interface Disclosure {
  formId: string;
  formName: string;
}

