<div class="main-container">
  <div class="grid-container" id="8594report-grid-container" style="height: calc(100vh - 124px);">
    <div class="top-toolbar">
      <div class="float-left ml-2">
        <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
                    (onChange)="onTaxYearChange()">
        </p-dropdown>
        <!-- <p-dropdown *ngIf="scenarioLoaded" class="mr-2" [options]="scenarios" optionLabel="text" [(ngModel)]="scenario"
          (onChange)="onScenarioChange()">
        </p-dropdown> -->
        <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
                            (selectedMes)="selectedMeChanged($event)">
        </gtw-df-me-dropdown>
        <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
      </div>
    </div>

    <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName" [loading]="loading"
             [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
             styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
             [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">

      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'45px'">
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="height: 70px;">
          <td style="width: 145px;">
            <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)">
              <span>{{ rowData.STATEMENTID }}</span>
            </a>
          </td>
          <td style="width: 105px;">
            <span>{{ rowData.Project_name }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.PRIMARYLEID }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 180px;">
            <span>{{ rowData.PRIMARYENTITY }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 260px;">
            <span>{{ rowData.CHOICE1CTARGET_VAL }}</span>
          </td>
          <td style="width: 145px;">
            <span>{{ rowData.FILING_GROUP }}</span>
          </td>
          <td style="width: 175px;">
            <span>{{ rowData.OTHERPARTY_VAL }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 140px;">
            <span>{{ rowData.EINAPPOP_VAL }}</span>
          </td>
          <td style="width: 170px;">
            <span>{{ rowData.EINAPPMROP_VAL }}</span>
          </td>
          <td style="width: 275px;">
            <span>{{ rowData.ADDRESSOFOTHERPARTY1_VAL }}</span>
          </td>
          <td style="width: 236px;">
            <span>{{ rowData.ADDRESSOFOTHERPARTY2_VAL }}</span>
          </td>
          <td style="width: 130px;">
            <span>{{ rowData.CITYOFOTHERPARTY_VAL }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 375px;">
            <span>{{ rowData.PROVORSTATEOP_VAL }}</span>
          </td>
          <td style="width: 155px; text-align: end;">
            <span>{{ rowData.COUNTRYOFOP_VAL }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.ZIPCODEOFOTHERPARTY_VAL }}</span>
          </td>
          <td style="width: 125px;">
            <span>{{ rowData.SECONDARYLEID }}</span>
          </td>
          <td style="width: 150px;">
            <span>{{ rowData.SECONDARYENTITY }}</span>
          </td>
          <td style="width: 150px;">
            <span>{{ rowData.SECONDARYEIN }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.ACQUISIONDATE_VAL }}</span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.STOCKPRICE_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.STOCKPRICE_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.ACQUISITIONCOSTSC_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.ACQUISITIONCOSTSC_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.TARGETLIABILITIES_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TARGETLIABILITIES_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.AGUBORADSP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.AGUBORADSP_VAL }}
            </span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE6_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE7_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE8A_VAL }}</span>
          </td>
          <td style="width: 400px;">
            <span>{{ rowData.CHOICE8B_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE8C_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE8D_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE8E_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE8F_VAL }}</span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS1AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS1AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS2AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS2AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS3AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS3AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS4AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS4AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS5AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS5AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS6N7AFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS6N7AFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.TOTALAFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TOTALAFMV_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS1ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS1ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS2ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS2ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS3ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS3ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS4ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS4ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS5ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS5ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.CLASS6N7ASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS6N7ASPPR_VAL }}
            </span>
          </td>
          <td style="width: 180px;">
            <span gtwLineSplitter [inputString]="rowData.TOTALASPPR_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TOTALASPPR_VAL }}
            </span>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>
