import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DiscModalViewerComponent} from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {DfMeDropdownComponent} from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import {Table} from 'primeng-lts/table';
import {CreateDisclosureService, DSTTaxYear} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {Subscription, zip} from 'rxjs';
import {DSTSharedService} from '../../shared/dst-shared.service';
import {StmtIdBtnService} from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {JSONExportHeader, JSONExportService} from '../../../shared/_services/JSONExport.service';
import {ReportsService} from '../reports.service';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTChildFormState} from '../../shared/models/dst-child-form-state.model';

@Component({
  selector: 'gtw-form8883-report',
  templateUrl: './form-8883-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './form-8883-report.component.scss']
})
export class Form8883ReportComponent implements OnInit {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;

  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTID',
      header: 'Statement Id',
      hidden: false,
      width: '145px'
    },
    {
      field: 'Project_name',
      header: 'Project',
      hidden: false,
      width: '105px'
    },
    {
      field: 'PRIMARYLEID', //
      header: 'Filer LEID',
      hidden: false,
      width: '155px'
    },
    {
      field: 'PRIMARYENTITY', //
      header: 'Filer Name',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CHOICE1CTARGET_VAL', //
      header: 'Indicate if Old Target (Seller) or New Target (Purchaser)',
      hidden: false,
      width: '260px'
    },
    {
      field: 'FILING_GROUP',
      header: 'Filing Group',
      hidden: false,
      width: '145px'
    },
    {
      field: 'OTHERPARTY_VAL', //
      header: 'Other Party Name',
      hidden: false,
      width: '175px'
    },
    {
      field: 'EINAPPOP_VAL', //
      header: 'Other Party EIN',
      hidden: false,
      width: '140px'
    },
    {
      field: 'EINAPPMROP_VAL', //
      header: 'Other Party Missing EIN Reason',
      hidden: false,
      width: '170px'
    },
    {
      field: 'ADDRESSOFOTHERPARTY1_VAL', //
      header: 'Address Line 1',
      hidden: false,
      width: '275px'
    },
    {
      field: 'ADDRESSOFOTHERPARTY2_VAL', //
      header: 'Address Line 2',
      hidden: false,
      width: '236px'
    },
    {
      field: 'CITYOFOTHERPARTY_VAL', //
      header: 'City',
      hidden: false,
      width: '130px'
    },
    {
      field: 'PROVORSTATEOP_VAL', //
      header: 'US State (if applicable)',
      hidden: false,
      width: '375px'
    },
    {
      field: 'COUNTRYOFOP_VAL', //
      header: 'Country Code',
      hidden: false,
      width: '155px'
    },
    {
      field: 'ZIPCODEOFOTHERPARTY_VAL', //
      header: 'Postal Code',
      hidden: false,
      width: '155px'
    },
    {
      field: 'SECONDARYLEID', //
      header: 'Target LEID',
      hidden: false,
      width: '125px'
    },
    {
      field: 'SECONDARYENTITY', //
      header: 'Target Name',
      hidden: false,
      width: '150px'
    },
    {
      field: 'FMV_CURRENCY', // missing
      header: 'Target EIN',
      hidden: false,
      width: '150px'
    },
    {
      field: 'ACQUISIONDATE_VAL', //
      header: 'Acquisition Date',
      hidden: false,
      width: '155px'
    },
    {
      field: 'STOCKPRICE_VAL', //
      header: 'Stock Price',
      hidden: false,
      width: '180px'
    },
    {
      field: 'ACQUISITIONCOSTSC_VAL', //
      header: 'Acquisition Costs/ Selling Costs',
      hidden: false,
      width: '180px'
    },
    {
      field: 'TARGETLIABILITIES_VAL', //
      header: 'Target Liabilities',
      hidden: false,
      width: '180px'
    },
    {
      field: 'AGUBORADSP_VAL', //
      header: 'AGUB/ADSP',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CHOICE6_VAL', //
      header: 'Question 6: Was the filer a member of an affiliated group of corporation before the acquisition date? (Y/N)',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE7_VAL', //
      header: 'Question 7: Was the target corporation a member of an affiliated group before the acquisition date? (Y/N)',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE8A_VAL', //
      header: 'Question 8: Is target: (a) A controlled foreign corporation?',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE8B_VAL', //
      header: 'Question 8: Is target: (b) A foreign corporation with income, gain, or loss effectively connected with the conduct of a trade or business within the United States (including U.S. real property interests)?',
      hidden: false,
      width: '400px'
    },
    {
      field: 'CHOICE8C_VAL', //
      header: 'Question 8: Is target: (c) A qualifying foreign target under Regulations section 1.338-2(e)(1)(iii)?',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE8D_VAL', //
      header: 'Question 8: Is target: (d) A corporation electing under section 1504(d) or section 953(d)?',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE8E_VAL', //
      header: 'Question 8: Is target: (e) A domestic international sales corporation (DISC)?',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CHOICE8F_VAL', //
      header: 'Question 8: Is target: (f) A passive foreign investment company (PFIC)?',
      hidden: false,
      width: '200px'
    },
    {
      field: 'CLASS1AFMV_VAL', //
      header: 'FMV Class I',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS2AFMV_VAL', //
      header: 'FMV Class II',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS3AFMV_VAL', //
      header: 'FMV Class III',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS4AFMV_VAL', //
      header: 'FMV Class IV',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS5AFMV_VAL', //
      header: 'FMV Class V',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS6N7AFMV_VAL', //
      header: 'FMV Class VI & VII',
      hidden: false,
      width: '180px'
    },
    {
      field: 'TOTALAFMV_VAL', //
      header: 'Total FMV',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS1ASPPR_VAL', //
      header: 'Additional Class I',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS2ASPPR_VAL', //
      header: 'Additional Class II',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS3ASPPR_VAL', //
      header: 'Additional Class III',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS4ASPPR_VAL', //
      header: 'Additional Class IV',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS5ASPPR_VAL', //
      header: 'Additional Class V',
      hidden: false,
      width: '180px'
    },
    {
      field: 'CLASS6N7ASPPR_VAL', //
      header: 'Additional Class VI & VII',
      hidden: false,
      width: '180px'
    },
    {
      field: 'TOTALASPPR_VAL', //
      header: 'Total Allocation of AGUB/ADSP',
      hidden: false,
      width: '180px'
    }
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
              private dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private trackerTabsService: TrackerTabsService,
              private dCRService: DSTDynamicComponentResolverService,
              private jsonExportService: JSONExportService,
              private reportsService: ReportsService) {

    this.excelExportFileName = 'Reports-8883_Report_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setDSTUser('400394467', 2);
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
    // this.dstSharedService.setTaxYearScenario('2020', '40');
  }

  ngOnInit(): void {
    // this.dstSharedService.setDSTUser('400394467', 5);
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2020');
    //   this.loadReport();
    // });
    // end of local test only
    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          // this.setScenarios(param.taxYear);
          this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    console.log('Selected M/Es:- ' + this.selectedMes);
    this.reportsService.loadForm8883Report(this.taxYear?.text.toString()!, this.selectedMes || '').subscribe((data: any) => {
      this.data = data;
      this.data?.forEach((element) => { //params for opening statement
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;
      });
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the N/A Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.dstSharedService.setTableActionStatus(true);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'STATEMENTID',
        header: 'Statement Id',
      },
      {
        field: 'PROJECT_NAME',
        header: 'Project',
      },
      {
        field: 'PRIMARYLEID',
        header: 'Filer LEID',
      },
      {
        field: 'PRIMARYENTITY',
        header: 'Filer Name',
      },
      {
        field: 'CHOICE1CTARGET_VAL',
        header: 'Indicate if Old Target (Seller) or New Target (Purchaser)',
      },
      {
        field: 'FILLING_GROUP',
        header: 'Filing group',
      },
      {
        field: 'OTHERPARTY_VAL',
        header: 'Other Party Name',
      },
      {
        field: 'EINAPPOP_VAL',
        header: 'Other Party EIN',
      },
      {
        field: 'EINAPPMROP_VAL',
        header: 'Other Party Missing EIN Reason',
      },
      {
        field: 'ADDRESSOFOTHERPARTY1_VAL',
        header: 'Address Line 1',
      },
      {
        field: 'ADDRESSOFOTHERPARTY2_VAL',
        header: 'Address Line 2',
      },
      {
        field: 'CITYOFOTHERPARTY_VAL',
        header: 'City',
      },
      {
        field: 'PROVORSTATEOP_VAL',
        header: 'US State (if applicable)',
      },
      {
        field: 'COUNTRYOFOP_VAL',
        header: 'Country Code',
      },
      {
        field: 'ZIPCODEOFOTHERPARTY_VAL',
        header: 'Postal Code',
      },
      {
        field: 'SECONDARYLEID',
        header: 'Target LEID',
      },
      {
        field: 'SECONDARYENTITY',
        header: 'Target Name',
      },
      {
        field: 'SECONDARYEIN',        
        header: 'Target EIN',
      },
      {
        field: 'ACQUISIONDATE_VAL',
        header: 'Acquisition Date',
      },
      {
        field: 'STOCKPRICE_VAL',
        header: 'Stock Price',
        format: 'number',
      },
      {
        field: 'ACQUISITIONCOSTSC_VAL',
        header: 'Acquisition Costs/ Selling Costs',
        format: 'number',
      },
      {
        field: 'TARGETLIABILITIES_VAL',
        header: 'Target Liabilities',
        format: 'number',
      },
      {
        field: 'AGUBORADSP_VAL',
        header: 'AGUB/ADSP',
        format: 'number',
      },
      {
        field: 'CHOICE6_VAL',
        header: 'Question 6: Was the filer a member of an affiliated group of corporation before the acquisition date? (Y/N)',
      },
      {
        field: 'CHOICE7_VAL',
        header: 'Question 7: Was the target corporation a member of an affiliated group before the acquisition date? (Y/N)',
      },
      {
        field: 'CHOICE8A_VAL',
        header: 'Question 8: Is target: (a) A controlled foreign corporation?',
      },
      {
        field: 'CHOICE8B_VAL',
        header: 'Question 8: Is target: (b) A foreign corporation with income, gain, or loss effectively connected with the conduct of a trade or business within the United States (including U.S. real property interests)?',
      },
      {
        field: 'CHOICE8C_VAL',
        header: 'Question 8: Is target: (c) A qualifying foreign target under Regulations section 1.338-2(e)(1)(iii)?',
      },
      {
        field: 'CHOICE8D_VAL',
        header: 'Question 8: Is target: (d) A corporation electing under section 1504(d) or section 953(d)?',
      },
      {
        field: 'CHOICE8E_VAL',
      header: 'Question 8: Is target: (e) A domestic international sales corporation (DISC)?',
      },
      {
        field: 'CHOICE8F_VAL',
        header: 'Question 8: Is target: (f) A passive foreign investment company (PFIC)?',
      },
      {
        field: 'CLASS1AFMV_VAL',
        header: 'FMV Class I',
        format: 'number',
      },
      {
        field: 'CLASS2AFMV_VAL',
        header: 'FMV Class II',
        format: 'number',
      },
      {
        field: 'CLASS3AFMV_VAL',
        header: 'FMV Class III',
        format: 'number',
      },
      {
        field: 'CLASS4AFMV_VAL',
        header: 'FMV Class IV',
        format: 'number',
      },
      {
        field: 'CLASS5AFMV_VAL',
        header: 'FMV Class V',
        format: 'number',
      },
      {
        field: 'CLASS6N7AFMV_VAL',
        header: 'FMV Class VI & VII',
        format: 'number',
      },
      {
        field: 'TOTALAFMV_VAL',
        header: 'Total FMV',
        format: 'number',
      },
      {
        field: 'CLASS1ASPPR_VAL',
        header: 'Additional Class I',
        format: 'number',
      },
      {
        field: 'CLASS2ASPPR_VAL',
        header: 'Additional Class II',
        format: 'number',
      },
      {
        field: 'CLASS3ASPPR_VAL',
        header: 'Additional Class III',
        format: 'number',
      },
      {
        field: 'CLASS4ASPPR_VAL',
        header: 'Additional Class IV',
        format: 'number',
      },
      {
        field: 'CLASS5ASPPR_VAL',
        header: 'Additional Class V',
        format: 'number',
      },
      {
        field: 'CLASS6N7ASPPR_VAL',
        header: 'Additional Class VI & VII',
        format: 'number',
      },
      {
        field: 'TOTALASPPR_VAL',
        header: 'Total Allocation of AGUB/ADSP',
        format: 'number',
      }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
