import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng-lts/table';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { JSONExportHeader, JSONExportService } from '../../../shared/_services/JSONExport.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { StatementTrackerService } from '../../statement-tracker/statement-tracker.service';
import { DiagnosticsService } from '../diagnostics.service';
import {DfMeDropdownComponent} from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {Alert} from '../../statement-tracker/statement-tracker.service';
import {StmtBtnActionPayload} from '../../shared/components/stmt-id-btn/stmt-id-btn.component';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {CopyReplicateComponent} from '../../statement-tracker/copy-replicate/copy-replicate.component';
import {ModalComponent} from 'appkit-angular/modal';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';


@Component({
  selector: 'gtw-f304-diag-report',
  templateUrl: './f304-diag-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss','./f304-diag-report.component.scss']
})
export class F304DiagReportComponent implements OnInit {
   // params to be passed from gtw-ui
   @Input('gtw-param') gtwInputParam!: string;
   @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
   @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
   @ViewChild('dt') table!: Table;
   rows: number = 15;
   first: number = 0;
   data: any[] = [];
   cols: any[] = [
    {
      field: 'TAXYEAR',
      header: 'Year',
      hidden: false,
      width: '80px'
    },
    {
      field: 'STATEMENTID',
      header: 'Stmt Id',
      hidden: false,
      width: '100px'
    },
    {
      field: 'STATEMENTNAME',
      header: 'Statement_name',
      hidden: false,
      width: '250px'
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Business',
      hidden: false,
      width: '180px'
    },
    {
      field: 'PRIMARYLEID',
      header: 'LEID',
      hidden: false,
      width: '80px'
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Entity Name',
      hidden: false,
      width: '200px'
    },
    {
      field: 'PRIMARYGEGECS',
      header: 'IND/FIN',
      hidden: false,
      width: '80px'
    },
    {
      field: 'SECONDARYLEID',
      header: 'Secondary LEID',
      hidden: false,
      width: '80px'
    },
    {
      field: 'SECONDARYENTITY',
      header: 'Secondary Entity Name',
      hidden: false,
      width: '180px'
    },
    {
      field: 'TARGET_LEID',
      header: 'Target LEID?',
      hidden: false,
      width: '90px'
    },
    {
      field: 'SHAREX',
      header: 'No. of Shares Before Xfer',
      hidden: false,
      width: '90px'
    },
    {
      field: 'SHAREY',
      header: 'No. of Shares After Xfer',
      hidden: false,
      width: '90px'
    },
    {
      field: 'PERCENTAGEX',
      header: '% Of Stock Owned prior to Xfer',
      hidden: false,
      width: '90px'
    },
    {
      field: 'PERCENTAGEY',
      header: '% Of Stock Owned after Xfer',
      hidden: false,
      width: '90px'
    },
    {
      field: 'STOCK_TYPE_NAME',
      header: 'Stock Type',
      hidden: false,
      width: '120px'
    },
    {
      field: 'CURRENCY_NAME',
      header: 'Currency',
      hidden: false,
      width: '150px'
    },
    {
      field: 'DESCVAL',
      header: 'Descr. of Property Transferred',
      hidden: false,
      width: '200px'
    },
    {
      field: 'BASIS_NAME',
      header: 'Basis for Property Transferred',
      hidden: false,
      width: '180px'
    },
    {
      field: 'BASISAMT',
      header: 'Basis Amount',
      hidden: false,
      width: '180px'
    },
    {
      field: 'FMVAMT',
      header: 'FMV Amount',
      hidden: false,
      width: '180px'
    },
    {
      field: 'LIQUIDATIONDATE',
      header: 'Date of Transfer',
      hidden: false,
      width: '180px'
    },
    {
      field: 'ISREVIEWED',
      header: 'Reviewed?',
      hidden: false,
      width: '100px'
    },
    {
      field: 'ISQARSTR',
      header: 'QAR?',
      hidden: false,
      width: '90px'
    },
    {
      field: 'MODIFIEDBY',
      header: 'Modified By',
      hidden: false,
      width: '200px'
    },
    {
      field: 'MODIFIEDON',
      header: 'Modified On',
      hidden: false,
      width: '200px'
    },
    {
     field: 'CREATEDBY',
     header: 'Created By',
     hidden: false,
     width: '200px'
   },
   {
     field: 'CREATEDON',
     header: 'Created On',
     hidden: false,
     width: '200px'
   },
  ];
   loading: boolean = false;
   taxyearLoaded: boolean = false;
   excelExportFileName: string;
   scrollBarHidden: boolean = false;
   private selectedMes: string;
   activeRow!: DSTStatement;
   showModalViewer: boolean = false;
   alert: Alert;
 
   taxYears: DSTTaxYear[] = [];
   taxYear: DSTTaxYear | undefined;
   private taxYearVal: string;
   private onDisCancelSubs!: Subscription;

   constructor(private cDSerivce: CreateDisclosureService,
    public dstSharedService: DSTSharedService,
    private diagnosticsService: DiagnosticsService,
    private jsonExportService: JSONExportService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerService: StatementTrackerService,
    private trackerTabsService: TrackerTabsService,
    private dCRService: DSTDynamicComponentResolverService) { 
      
      this.excelExportFileName = 'f304_Stmt_Diagnostics_' + this.dstSharedService.getExcelExportDateString();
      this.selectedMes = '';
      this.taxYearVal = '';
      this.alert = dstSharedService.alert;
    }

  ngOnInit(): void {
    // local test only
    //  const getTaxYears$ = this.cDSerivce.getTaxYears();
    //  zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //    this.taxYears = data[0];
    //    this.setTaxYear('2021');
    //    this.selectedMes = '';
    //    this.loadReport();
    //  });
    //  this.dstSharedService.setDSTUser('400368836', 5);
    //  this.dfMeDropdownComponent.loadMEsCustom('2021');

     this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  refresh(): void {
    this.taxyearLoaded = true;
    this.loadReport();
  }
  
  statementReviewedChange(event: any, rowData: any): void {
    //Save- mark statement as reviewed change
    rowData.markStatementAsReviewed = event.srcElement.checked ? 1 : 0;
    this.stmtIdBtnService.setReviewed(rowData.STATEMENTID, rowData.markStatementAsReviewed)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Statement saved successfully for reviewed status.');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while reviewing status, please try again later!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        this.dfMeDropdownComponent.loadMEsCustom(param.taxYear);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          //this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  onTaxYearChange(object: any): void {
    this.taxyearLoaded = true;
    this.taxYearVal = object.value.text;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYearVal);
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.diagnosticsService.load304DiagStmtReport(this.taxYear?.text.toString()!, this.selectedMes).subscribe((data) => {
      this.data = data;
      this.data.forEach((element, index) => { //creating RevExpl array for each row
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.STATEMENTNAME;
        

        element.id = index + 1;
        
        
      });
      this.table.clear();
      
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the DST Duplicate Statements Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }
    
    showViewUpdateStatement(rowData: any): void {
      this.activeRow = rowData;
      this.dstSharedService.setTableActionStatus(true);
      this.dstSharedService.setIsFiled(rowData.IS_FILED!);
      this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
        this.showModalViewer = true;
        setTimeout(() => {
          this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
        });
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      }).add(() => {
        this.dstSharedService.setTableActionStatus(false);
      });
    }

    private changeScrollBarStyling(): void {
      const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
      if (cdkScrollerEles.length) {
        this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
      } else {
        this.scrollBarHidden = true;
      }
    }
    
    afterModalClosed(): void {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    }
    exportToExcel(): void {
      const headersMap: JSONExportHeader[] = [
        {
          field: 'TAXYEAR',
          header: 'Year',
          
        },
        {
          field: 'STATEMENTID',
          header: 'Stmt Id',
          
        },
        {
          field: 'STATEMENTNAME',
          header: 'Statement_name',
          
        },
        {
          field: 'PRIMARYBUSINESS',
          header: 'Business',
          
        },
        {
          field: 'PRIMARYLEID',
          header: 'LEID',
          
        },
        {
          field: 'PRIMARYENTITY',
          header: 'Entity Name',
    
        },
        {
          field: 'PRIMARYGEGECS',
          header: 'IND/FIN',
          
        },
        {
          field: 'SECONDARYLEID',
          header: 'Secondary LEID',
          
        },
        {
          field: 'SECONDARYENTITY',
          header: 'Secondary Entity Name',
          
        },
        {
          field: 'TARGET_LEID',
          header: 'Target LEID?',
          
        },
        {
          field: 'SHAREX',
          header: 'No. of Shares Before Xfer',
    
        },
        {
          field: 'SHAREY',
          header: 'No. of Shares After Xfer',
          
        },
        {
          field: 'PERCENTAGEX',
          header: '% Of Stock Owned prior to Xfer',
          
        },
        {
          field: 'PERCENTAGEY',
          header: '% Of Stock Owned after Xfer',
          
        },
        {
          field: 'STOCK_TYPE_NAME',
          header: 'Stock Type',
          
        },
        {
          field: 'CURRENCY_NAME',
          header: 'Currency',
          
        },
        {
          field: 'DESCVAL',
          header: 'Descr. of Property Transferred',
          
        },
        {
          field: 'BASIS_NAME',
          header: 'Basis for Property Transferred',
          
        },
        {
          field: 'BASISAMT',
          header: 'Basis Amount',
          
        },
        {
          field: 'FMVAMT',
          header: 'FMV Amount',
          
        },
        {
          field: 'LIQUIDATIONDATE',
          header: 'Date of Transfer',
          
        },
        {
          field: 'ISREVIEWED',
          header: 'Reviewed?',
          
        },
        {
          field: 'ISQARSTR',
          header: 'QAR?',
          
        },
        {
          field: 'MODIFIEDBY',
          header: 'Modified By',
          
        },
        {
          field: 'MODIFIEDON',
          header: 'Modified On',
          
        },
        {
         field: 'CREATEDBY',
         header: 'Created By',
         
       },
       {
         field: 'CREATEDON',
         header: 'Created On',
         
       }
      ];
  
      this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
    }
    

}
