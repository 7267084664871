import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GhostUpdateCombinationService {

	url ={
		syncTestedUnitsDataUrl : '/saveJsonObject?action_code=68rahg',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

  synctestedUnits(baseURL : string, jsonData : any, clob_settings : any, clob_data : any, processName: string, baseGlobalParams: any, checkLockedObj: any, ) {
		const url = `${baseURL}${this.url.syncTestedUnitsDataUrl}`;
		const options={
		  withCredentials: true
		};
        const data:any = {
            "tax_year":jsonData.TAX_YEAR.toString(),
            "scenario":jsonData.SCENARIO.toString(),
            "jcd_key":jsonData.JCD_KEY.toString(),
            "sso_id":jsonData.SSO_ID,
			"filing_key": jsonData.FILING_KEY.toString(),
            "process_name" : processName,
            "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
            "issue_id":baseGlobalParams.issue_id,
            "scenarioCode":baseGlobalParams.scenarioCode,
            "checkLockedbj": checkLockedObj,
            "clob_settings": JSON.stringify(clob_settings),
      	    "clob_data": JSON.stringify(clob_data)
          };

		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));

		return this.http.post<any>(url, formData,options);
	  }

	openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
		duration: this.durationInSeconds * 1000,
		"panelClass": [panelClass]
		});
	}
}
