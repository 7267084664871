<!-- [class.collapsed]="isPanelVisible" -->
<div class="overlay-side-panel" [class.collapsed]="isPanelVisible" id= {{screenKey}}filterSidePanel>
    <div class="overlay" 
      [hidden]="isPanelVisible" [@fadeInOut] (click)="close()">
    </div>
    <!-- style="z-index: 9999 !important;" -->
    <div class="side-panel" >
      <div class="close">
        <span class="float:left" style="font-size:20px">Filters</span>
        <span class="close-btn"(click)="close()">&times;</span>
      </div>

      <div class="body">
        <!-- this is filter body -->
        <div class="d-flex align-items-center justify-content-center">
          <div class="loader"   *ngIf="datafiltersLoading"></div>
       </div>
      <div *ngIf="!datafiltersLoading">
        <div class="filter-label">
          <span>Year</span>
          <div *ngIf="taxYearList[0].data.length>0">
              <ap-selector [items]="taxYearList" [type]="'SingleSelector'" 
              (onSelectedChange)="taxYearSelection($event,taxYearList,false)">
              </ap-selector>
          </div>
      </div>
      <div class="filter-label">
          <span>Scenario</span>
          <div *ngIf="scenarioList[0].data.length>0">
            <ap-selector [disabled]="!scenarioList[0].isLoaded" [items]="scenarioList" [type]="'SingleSelector'" 
            (onSelectedChange)="scenarioSelection($event)">
            </ap-selector>
          </div>

      </div>
     
      <div class="filter-label">
        <span>Company</span>
        <div *ngIf="filingGroupList[0].data.length>0">
          <ap-selector [disabled]="!filingGroupList[0].isLoaded" [items]="filingGroupList" 
           [type]="'SingleSelector'">
          </ap-selector>
        </div>
      </div>
      
      <div class="filter-label">
        <span>System</span>
        <!-- <span style="float:right;margin-left:10px;cursor: pointer;"(click)="unselectAllSystem()">Reset</span>

        <span style="float:right;cursor: pointer;"(click)="selectAllSystem()">Select All</span>
        <div  *ngIf="systemList[0].data.length>0">
          <div *ngFor="let system of systemList[0].data,index as i" >
            <ap-checkbox *ngIf="system.text != 'ALL'" [checked]="system.selected" class="" [text]="system.text" (onCheck)="selectedSystem($event, system, true,systemList[0].data,i)" >
            </ap-checkbox> 
          </div>
        </div> -->
        <ap-selector  [items]="systemList" 
         [type]="'SingleSelector'">
        </ap-selector>
      </div>     
      <div class="filter-label">
        <span>Business</span>
       
        <span style="float:right;margin-left:10px;cursor: pointer;"(click)="unselectAll()">Reset</span>
        <span style="float:right;cursor: pointer;"(click)="selectAll(1)">Select All</span>
        <div class="d-flex align-items-center justify-content-center" >
          <div class="loader"  *ngIf="!menuObj1.isLoaded" #bizzLoading></div>
        </div>
        <!-- grpobjkey *ngFor="let me of menuObj1; -->
        <div class="global-me-container" style="width: 41%;"   >
          <div class="me-container a-my-6" *ngFor="let me of menuObj1.data; let i = index">
              <ap-checkbox class="" [text]="me.name" [checked]="me.selected" [value]="me.selected" (onCheck)="selectedMe($event, me, true);">
              </ap-checkbox> <span class="subMeCount float-right" *ngIf="me.children && me.numSelected > 0">{{me.numSelected}}</span>
          </div>
          <div class="sub-me-container customScroll" *ngIf="showSubBusinessMenu">
              <div class="a-my-6" *ngFor="let subMe of childMenuObj; let ii = index">
                  <ap-checkbox class="a-mx-8" [text]="subMe.name" [checked]="subMe.selected" (click)="selectedMe($event, subMe, false);">
                  </ap-checkbox>
                  <div class="sub-me-child-container" *ngIf="subMe.children">
                      <div class="a-my-6" *ngFor="let subChildMe of subMe.children">
                          <ap-checkbox class="a-my-8" [text]="subChildMe.name" [checked]="subChildMe.selected" (click)="selectedMe($event, subChildMe, false);">
                          </ap-checkbox>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
      </div>
      </div>
           
      <div class="footer">
        <div class="action-items">
            <ap-button [btnText]="'CANCEL'" class="a-mr-10" [btnType]="'a-btn-gray'" [config]="primaryConfig" (onBtnClick)="close()">
            </ap-button>
            <ap-button [btnText]="'Filter'" class="a-mr-10" [config]="primaryConfig" (onBtnClick)="filerSave(true)">
            </ap-button>  
        </div>

           
    </div>

    </div>
  </div>