import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomePageContainerService {
  private URLs = {
    getAllScreens : '/loadJsonObject?action_id=31710 ',
    getTabsData : '/getScreenTaxCategories'
  };

  public screenCategorList: any;
  public screenList: any;
  public sortingFilterKey: any;
  constructor(private httpClient: HttpClient) { }

  getAllScreens(baseURL:string,clientKey:number){
    let headers = new HttpHeaders({
      'client_key': clientKey+''
    });
    const options= {withCredentials:true, headers:headers};

    const url = `${baseURL}${this.URLs.getAllScreens}`;
    return this.httpClient.get(url).pipe(map((data: any) => {
      data.jsonObject.forEach((item: any)=> {
        item['BREADCRUMBSARR'] = item.BREADCRUMB.split('>>');
         item['BRDCUMB'] = item.BREADCRUMB.replaceAll('>>','/');

         let screenCates = [];
         item['CATEGORY_NAME'] = [];
         screenCates =  item.SCREENS_CATEGORY_LIST.split(',');
        if(screenCates){
          screenCates.forEach((i: any)=>{
            if(this.screenCategorList) {
             let catLabel =[];
             catLabel =  this.screenCategorList.categoryList.filter((cat: any) => {return cat.id == i});
              if(catLabel[0]){
                item['CATEGORY_NAME'].push(catLabel[0].label);
              }
            }
          })
        }
      });
      this.screenList = data.jsonObject;
      return data.jsonObject; }));
  }

  getTaxTypesCategories(adminBaseURL:string,clientKey:number){
    let headers = new HttpHeaders({
      'client_key': clientKey+''
    });

    const options= {withCredentials:true, headers:headers};

    const url =  `${adminBaseURL}${this.URLs.getTabsData}`;
    
    return this.httpClient.post(url,null).pipe(map((data: any) => { 
      let taxType_Categories :{taxTypesList:{}[],categoryList:{}[]}={
        taxTypesList : [{id:-2, label: 'All', isSelected:false}],
        categoryList : [{id:-1, label: 'All', isSelected:false}]
      };
      
      data.tax_types_list.forEach((item: { id: any; label: any; }) => {
        taxType_Categories.taxTypesList.push({id: item.id, label: item.label, 
          SCREENS_TAX_TYPES_LIST: item.id, isSelected: false});
      });
      data.categories_list.forEach((item: { id: any; label: any; }) => {
        taxType_Categories.categoryList.push({id: item.id, label: item.label, 
          SCREENS_CATEGORY_LIST: item.id, isSelected: false});
      });
      this.screenCategorList = taxType_Categories;
      return taxType_Categories; 
    }));
  }

  getScreenTaxCateList(){
    return this.screenCategorList;
  }

  setScreenTaxCateList(data:any){
    this.screenCategorList = data;
  }
  getScreenList(){
    return this.screenList;
  }
  setScreenList(data:any){
    return this.screenList;
  }

}
