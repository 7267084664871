import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ScreenDocumentList } from './screen-document';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ScreenDocumentService {
 
  UPLOAD_URL = "/uploadScreenDocumentsContent";
  getDocumentURL = '/getScreenDocumentsContent';
  deleteDocument = "/deleteScreenDocumentById"

  constructor(private httpClient:HttpClient) { }

  savedDoc: Array<ScreenDocumentList> = [];

  set savedDocUploadList(savedDocList: ScreenDocumentList) {
    this.savedDoc.push(savedDocList);
  }

  uploadDocument(baseURL:string, clientKey:number, screenKey:number, docTitle: string, docBody: string, hdKey: any) {

    let url = "";
    let dataParams: Object = {};
    dataParams = {
      client_key: clientKey,
      screen_key: screenKey,
      hd_key: hdKey,
      hd_msg_title: docTitle,
      hd_body: docBody
    };

    const data = new FormData();
    data.append('data',JSON.stringify(dataParams));
    url = `${baseURL}${this.UPLOAD_URL}?clientKey=${clientKey}&screenKey=${screenKey}`;
  
    const headers = new HttpHeaders({
      client_key: clientKey.toString()
    });
    const options={
      withCredentials: true
    };
    console.log('tshiadfasd',data);
    return this.httpClient.post<any>(url, data,options);

  }
  
  getAllDocumentsList(baseUrl: string, clientKey: number, screenKey: number) {
    const options ={ 
       withCredentials: true
      };
    const data = new FormData();
    data.append('clientKey',clientKey.toString());
    data.append('screenKey',screenKey.toString());

    return this.httpClient.post(`${baseUrl}${this.getDocumentURL}`,data,options)
        .pipe(map((documentList:any) => documentList));
  }

  deleteDocumentById(baseUrl: string, clientKey: number, screenKey: number, hdKey: number) {
    const options ={ 
       withCredentials: true
      };
    const data = new FormData();
    data.append('clientKey',clientKey.toString());
    data.append('screenKey',screenKey.toString());
    data.append('hdKey',hdKey.toString());

    return this.httpClient.post<any>(`${baseUrl}${this.deleteDocument}`, data,options);

    // return this.httpClient.post(`${baseUrl}${this.deleteDocument}`,data,options))
       // .pipe(map((documentList:any) => documentList));
  }

}
