import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CreateDisclosureSharedService, EntityNameChange} from '../../../create-disclosure-shared.service';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { TrackerTabsService, TrackerTabState } from '../../../../../tracker-tabs.service';
import { MessageService } from 'projects/gtw-elements/src/app/DST/shared/services/message.service';
import { EMPTY, forkJoin, observable, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'view-template-sec367b-id193',
  templateUrl: './view-template-sec367b-id193.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec367b-id193.component.scss']
})
export class ViewTemplateSec367bId193Component implements OnInit {

  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formData!: StmtData;
  attMap: any = {};
  loading: boolean = false;
  s3Uploading: boolean = false;
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  primaryEntityName: string = '';
  secondaryEntityName: string = '';

  constructor(private sharedService: CreateDisclosureSharedService,
              private dstSharedService: DSTSharedService,
              private trackerTabsService: TrackerTabsService,
              private taxTypePipe: TaxTypePipe,
              private messageService: MessageService) {
    this.loading = true;
    this.sharedService.getLoadStatementData(193).subscribe(
      (data) => {
        this.formData = data;
        this.primaryEntityName = this.formData.primaryentity || '';
        this.secondaryEntityName = this.formData.secondaryentity || '';
        //load all attributes into new object for showing on UI as key value pair
        this.formData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }
          if (stmt.attributeValue !== undefined) {
            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            this.attMap[stmt.attributeName]?.push('');
          }
        });

        // this.updateEntitiesInfo(this.formData?.primaryleid!, 1);
        // this.updateEntitiesInfo(this.formData?.secondaryleid!, 2);
        //corpTypeTxt
      
        forkJoin([

          this.updateEntitiesInfo(this.formData?.primaryleid!, 1),
          this.updateEntitiesInfo(this.formData?.secondaryleid!, 2)

        ]).subscribe(([response1, response2]) => {
          setTimeout(() => {
            const html = document.querySelector('#temp367bform193');
            const htmlContent = html!.outerHTML;
            this.sharedService.getFormStateByFormId(193)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
          });
        }, (error: HttpErrorResponse) => {

          console.log(error);
          this.dstSharedService.showAlert('error', error.message);

        }).add(() => {
          this.loading = false;
        });
      
      // console.log(this.attMap);
      return this.sharedService.getDSTEntityInfo(this.attMap.SHdspTransferorLEID);

      }, (error: any) => {
        console.error(error);
        this.dstSharedService.showAlert('error', error.toString());
      }).add(() => {
        this.loading = false;
      });
      // .add(() => {
      //   this.loading = false;

      //   setTimeout(() => {    
      //     const html = document.querySelector('#temp367bform193');
      //     const htmlContent = html!.outerHTML;
          
      //     this.sharedService.getFormStateByFormId(193)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
      //   });
      // })
    
  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
  //   if (trackerTab && trackerTab.parentTransDate) { 
  //     this.loading = true;
  //     this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
  //       leid,
  //       this.sharedService.getTransDateStr(trackerTab.parentTransDate,1),
  //       this.sharedService.getActiveFormState().taxYear.toString()
  //     ).subscribe((response: EntityNameChange[]) => {
  //       if (response && response[0]) {
  //         //override entity type
  //         const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //         if (entityType === 1) { //primary entity
  //           (response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
  //           this.primaryCorpTypeTxt = corpType;
  //         } else if (entityType === 2) { //secondary entity
  //           (response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
  //           this.secondaryCorpTypeTxt = corpType;
  //         }
  //       }
  //     }, (error: HttpErrorResponse) => {
  //       console.log(error);
  //       this.dstSharedService.showAlert('error', error.message);
  //     }).add(() => {
  //       this.loading = false;
  //     });
  //   }

  // }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();	
    if (trackerTab && trackerTab.parentTransDate) { 
		this.loading = true;
		return this.sharedService.checkForEntityNameChange(this.formData?.statementid.toString(),
		  leid,
		    this.sharedService.getTransDateStr(trackerTab?.parentTransDate!,1),
        this.sharedService.getActiveFormState().taxYear.toString()
		).pipe(
		  tap({
			next: (response => {
			  if (response && response[0]) {
				  //override entity type
				  const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
				  if (entityType === 1) { //primary entity
					(response[0].LE_NAME) && (this.primaryEntityName = response[0].LE_NAME);
					this.primaryCorpTypeTxt = corpType;
				  } else if (entityType === 2) { //secondary entity
					(response[0].LE_NAME) && (this.secondaryEntityName = response[0].LE_NAME);
					this.secondaryCorpTypeTxt = corpType;
				  }
			    }
			})
		  })		  
		)
    }else{
      return EMPTY;
    }
  }
  
  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 
      'Section367b-StatementPursuant368Other.pdf', this.formData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading =  false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp367bform193');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  ngOnInit(): void {

    this.messageService.subscribe(this.trackerTabsService.getActiveTrackerTab()?.id!, "368-on-transDate-set",()=>{
      //corpTypeTxt      
      forkJoin([
        this.updateEntitiesInfo(this.formData?.primaryleid!, 1),
        this.updateEntitiesInfo(this.formData?.secondaryleid!, 2)
      ]).subscribe(response => {
        this.loading = false;
      })
      
      this.messageService.unsubscribe(this.trackerTabsService.getActiveTrackerTab()?.id!, "368-on-transDate-set");//immediately unsubscribe as this needs to run only once
    })
  }

  save(): void {
    this.sharedService.setFormData(this.formData);
    this.sharedService.setSavedStatementId(this.formData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
