import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSheets } from './detail-data-sheet-model';
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';
import { DashboardFilterService } from '../dashboard-filter/dashboard-filter.service';
import _ from 'lodash';

@Component({
  selector: 'gtw-detail-diagnostic',
  templateUrl: './detail-diagnostic.component.html',
  styleUrls: ['./detail-diagnostic.component.scss']
})
export class DetailDiagnosticComponent implements OnInit {
	
/* 	@Input('client_key')
	clientKey : number = 0; */
	@Input('global-params')
	globalParams:any;
	@Input('tax-years')
	taxYears: any = [];
	baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

	@Input('base-urls')
	set _base_urls(baseUrls: string){
	  try{
		let urls:GtwElementsBaseUrlsModel = JSON.parse(baseUrls);

		this.baseURLs = urls;
	  }catch(e){
		console.log('Input not stable yet');
	  }
	}
	clientKey:any;
	@Input('client-key')
	set _clientKey(clientKey: string){
		try{
		this.clientKey = clientKey;
		}catch(e){
		console.log('Input not stable yet');
		}
	}
	// selectedDrillDownFilterParams:any = {};
	@Input('selected-drilldown-filter-params')
	selectedDrillDownFilterParams:any = {};

	/* set _drillDownFilterParams(selectedDrillDownFilterParams: string){
		try{
			let params = JSON.parse(selectedDrillDownFilterParams);
			this.selectedDrillDownFilterParams = params;
		}catch(e){
			console.log('Input not stable yet');
		  }
	} */
	
	@Output('load-detail-diag-grid') loadDetailDiag = new EventEmitter<any>();
	constructor(public dashboardFilterService: DashboardFilterService) { 

	}
	
	selectedParams:any;
	ngOnInit(): void {
		console.log('this is detail filet');
		console.log('selectedFilterParams',this.selectedDrillDownFilterParams);
		let hasSelectedParams = _.find(this.dashboardFilterService.screenFilters,{screen_key:11460});
		if(hasSelectedParams && hasSelectedParams.filterParams != undefined){
			this.selectedParams = hasSelectedParams.filterParams;
		}
	}

	isShow :boolean =  true;
  
  showFilter(){
    this.isShow = !this.isShow;
    // if(this.screenKe)
	this.dashboardFilterService.notifyOther({screen_key:11460,isEnabled:true});
  }
  closeFilterPanel(event:any){
    this.isShow = true//this.isShow;
  }
  loadDetailGrid(event:any){
	if(event.screen_key = 11460){
		this.selectedParams = event;
		this.loadDetailDiag.emit(event);
	}
  }


	/* viewList : any = [

		{
			data: []
		}
	];
	actionsList : any = [
		{
			data: []
		}
	];
	tableData : any = [];
	baseValue!: string;
	baseLgValue!: string;
	public secondaryConfig = {
		isSecondary: true
	}

	filterBase(event: any) {
		console.log(event);
	}

	btnClick() {
		console.log('button is clicked')
	}
  	
	constructor() { }

	 
	detail_summary_data_sheets: DataSheets[] =  [ 
		{
			"data_api" : "loadJsonObject?action_code=qroj88",
			"data" : [ ],
			"action_id" : "/loadJsonObject?action_code=qroj88","sheet_name" : "Pship Form Diagnostics",
			"action_code" : "qroj88",
			"sheet_key" : "865","isLoaded" : false
		}, 
		{
			"data_api" : "loadJsonObject?action_code=z2u0rj",
			"data" : [ ],
			"action_id" : "/loadJsonObject?action_code=z2u0rj",
			"sheet_name" : "Detailing Balances Diagnostics",
			"action_code" : "z2u0rj","sheet_key" : "808",
			"isLoaded" : false
		}, 
		{
			"data_api" : "loadJsonObject?action_code=339s3e",
			"data" : [ ],"action_id" : "/loadJsonObject?action_code=339s3e",
			"sheet_name" : "FTC Diagnostics",
			"action_code" : "339s3e",
			"sheet_key" : "809","isLoaded" : false
		}, 
		{
			"data_api" : "loadJsonObject?action_code=e3f4gm",
			"data" : [ ],
			"action_id" : "/loadJsonObject?action_code=e3f4gm",
			"sheet_name" : "Pship Diagnostics","action_code" : "e3f4gm",
			"sheet_key" : "810","isLoaded" : false
		}, 
		{
			"data_api" : "loadJsonObject?action_code=cz0mqg",
			"data" : [ ],
			"action_id" : "/loadJsonObject?action_code=cz0mqg",
			"sheet_name" : "Book to Tax Diagnostics",
			"action_code" : "cz0mqg","sheet_key" : "806",
			"isLoaded" : false
		}];

		Pshipdata =  [ 
			{
				LEID : 'AH0561',
				ME_Code : 'AH0001',
				TB_Loaded : 'Y',
				TB_Net_To_Zero : 'N',
				PY_TB_Loaded : 'N',
				Adjust_PY_Net_Zero : 'Y',
				Entity_Begin : '01/01/2022'
			},{
				LEID : 'AH0562',
				ME_Code : 'AH0002',
				TB_Loaded : 'Y',
				TB_Net_To_Zero : 'N',
				PY_TB_Loaded : 'N',
				Adjust_PY_Net_Zero : 'Y',
				Entity_Begin : '02/01/2022'
			},{
				LEID : 'AH0563',
				ME_Code : 'AH0003',
				TB_Loaded : 'Y',
				TB_Net_To_Zero : 'N',
				PY_TB_Loaded : 'N',
				Adjust_PY_Net_Zero : 'Y',
				Entity_Begin : '03/01/2022'
			},{
				LEID : 'AH0564',
				ME_Code : 'AH0004',
				TB_Loaded : 'Y',
				TB_Net_To_Zero : 'N',
				PY_TB_Loaded : 'N',
				Adjust_PY_Net_Zero : 'Y',
				Entity_Begin : '04/01/2022'
			} ];


	ngOnInit(): void {
		this.detail_summary_data_sheets[0].data = this.Pshipdata;
		this.viewList = [
			{
				data: [
					{
						id: 1,
						text: 'Default View',
						selected: true
					},
					{
						id: 2,
						text: 'Grid View'
					}
				]
			}
		];
		this.actionsList = [
			{
				data: [
					{
						id: 1,
						text: 'Actions',
						selected: true
					},
					{
						id: 2,
						text: 'Action 1'
					},
					{
						id: 3,
						text: 'Action 2'
					},
					{
						id: 4,
						text: 'Action 3'
					}
				]
			}
		];
		this.tableData = {
			headers : [{
					lable : 'LEID'
				},{
					lable : 'ME_Code'
				},{
					lable : 'TB_Loaded'
				},{
					lable : 'TB_Net_To_Zero'
				},{
					lable : 'PY_TB_Loaded'
				},{
					lable : 'Adjust_PY_Net_Zero'
				},{
					lable : 'Entity_Begin'
				}],
			Data : [
				{
					LEID : 'AH0561',
					ME_Code : 'AH0001',
					TB_Loaded : 'Y',
					TB_Net_To_Zero : 'N',
					PY_TB_Loaded : 'N',
					Adjust_PY_Net_Zero : 'Y',
					Entity_Begin : '01/01/2022'
				},{
					LEID : 'AH0562',
					ME_Code : 'AH0002',
					TB_Loaded : 'Y',
					TB_Net_To_Zero : 'N',
					PY_TB_Loaded : 'N',
					Adjust_PY_Net_Zero : 'Y',
					Entity_Begin : '02/01/2022'
				},{
					LEID : 'AH0563',
					ME_Code : 'AH0003',
					TB_Loaded : 'Y',
					TB_Net_To_Zero : 'N',
					PY_TB_Loaded : 'N',
					Adjust_PY_Net_Zero : 'Y',
					Entity_Begin : '03/01/2022'
				},{
					LEID : 'AH0564',
					ME_Code : 'AH0004',
					TB_Loaded : 'Y',
					TB_Net_To_Zero : 'N',
					PY_TB_Loaded : 'N',
					Adjust_PY_Net_Zero : 'Y',
					Entity_Begin : '04/01/2022'
				}
			]
		};
	}

  	setView(viewType : any){
		console.log(viewType);
	}

	selectedAction(actionType : any){
		console.log(actionType);
	} */
}

