import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { TasksComponent } from './tasks/tasks.component';
import { SubTasksContainerModule } from './sub-tasks-container/sub-tasks-container.module';
import { MatTabsModule } from '@angular/material/tabs';

import { AppFooterModule } from '../app-footer/app-footer.module';
import { FormsModule } from '@angular/forms';
import { GtwDirectivesModule } from '../shared/_directives/gtw-directives.module';
import { ScreensModule } from '../screens/screens.module';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { ToggleModule } from 'appkit-angular/toggle';

@NgModule({
  declarations: [
    NavigationComponent, 
    TasksComponent, 
  ],
  imports: [
    CommonModule,
    SubTasksContainerModule,
    MatTabsModule,
    FormsModule,
    AppFooterModule,
    GtwDirectivesModule,
    ScreensModule,
    AppNgMaterialModule,
    ToggleModule
    
  ],
  exports: [NavigationComponent]
})
export class NavigationModule { }
