import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {
  CreateDisclosureSharedService,
  EntityNameChange
} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec368-id186',
  templateUrl: './template-sec368-id186.component.html',
  styleUrls: ['../template-shared.scss', './template-sec368-id186.component.scss']
})
export class TemplateSec368Id186Component implements OnInit, AfterViewInit {

  @ViewChild('headerComp1')
  private headerComponent1!: TemplateHeaderComponent;
  @ViewChild('headerComp2')
  private headerComponent2!: TemplateHeaderComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form186!: FormGroup;
  form186Two!: FormGroup;
  TransfereeName: string = '';
  selectedOptionID!: number;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;
  target!: DSTEntityDetails | null;
  beginDate!: Date | string;
  endDate!: Date | string;

  formState: number = 1;
  EffectiveDate: any = '';
  LiquidationDate1: any = '';
  dspTransfereeName: string = '';
  additionaltext: string = '';

  leidEntity: string = '';
  leidEIN: string = '';

  NameChangeDesc: string = '';
  PLR: string = 'None.';

  showDREMsg: boolean = false;
  DREMsg: string = '';
  nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private headerService: TemplateHeaderService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  get additionalPropsDetails(): FormArray {
    return this.form186.get('main')?.get('additionalPropsDetails') as FormArray;
  }

  get additionalPropsDetails2(): FormArray {
    return this.form186.get('main')?.get('additionalPropsDetails2') as FormArray;
  }

  ngOnInit(): void {
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form186 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        TransfereeName: [''],
        dspTransfereeName: [''],
        EffectiveDate: [''],
        additionaltext: [''],
        LiquidationDate1: [''],
        PLR: [''],
      })
    });
    this.form186Two = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      main: this.formBuilder.group({
        NameChangeDesc: [''],
        EffectiveDate: [''],
        LiquidationDate1: [''],
        PLR: [''],
        leidEntity: [''],
        leidEIN: [''],
      })
    });
    this.loadStmtData();
  }

  back(reorg: number): void {
    if (reorg === 1) {
      // reset form fields
      this.dcnId = 0;
      this.target = null;
      this.TransfereeName = '';
      this.PLR = 'None';
      this.dspTransfereeName = '';
      this.additionaltext = '';
      this.EffectiveDate = '';
      this.LiquidationDate1 = '';
      this.NameChangeDesc = '';
      this.leidEntity = '';
      this.leidEIN = '';
    }
    this.formState = reorg;
    setTimeout(() => {
      if (this.sharedService.leid !== '') {
        this.leidComponents.toArray()[0]?.fillLEID(this.sharedService.leid);
      }
    });
  }

  ngAfterViewInit(): void {
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData(186);
    if (data) {
      this.editMode = true;
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 0:
              element.fillLEID(data.primaryleid);
              break;
          }
        });
      },1000);
      this.populateFields(data);
    } else if (this.sharedService.leid !== '') {
      this.editMode = true;
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      const formId = this.sharedService.getActiveFormState().formId;
      const taxYear = (this.sharedService.getActiveFormState()?.taxYear || 2020).toString();
      const cseId = this.sharedService.getActiveFormState().cseId;

      setTimeout(() => {
        this.leidComponents.toArray()[0]?.fillLEID(this.sharedService.leid);
      });

      this.startLoader = true;
      this.sharedService.getDSTTrackerRequirementData(taxYear, cseId, formId!)
      .subscribe((data: StmtData) => {
        data && this.populateFields(data);
      }, () => { }, () => { this.startLoader = false; });
    }else{
      this.nameChangeChecked = true;
    }
  }

  populateFields(data: StmtData): void {
    data.children?.forEach((stmt: StmtAttrData) => {
      if (this.attMap[stmt.attributeName] == undefined) {
        this.attMap[stmt.attributeName] = [];
      }
      if (stmt.attributeValue !== undefined) {
        this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
      } else {
        this.attMap[stmt.attributeName]?.push('');
      }
    });
    if (this.attMap['TransfereeName'] && this.attMap['TransfereeName'][0]) {
      this.back(2);
    } else {
      this.back(3);
    }
    if (this.attMap['LiquidationDate']) {
      this.attMap['LiquidationDate'].forEach((element: any, index: number) => {
        switch (index) {
          case 0:
            if (element !== '' && element !== undefined) {
              this.LiquidationDate1 = new Date(element.replace(/-/g, '\/').replace(/T.+/, ''));
            }
            break;
        }
      });
    }

    if (this.attMap['PLR'] && this.attMap['PLR'][0]) {
      this.PLR = this.attMap['PLR'][0];
    }
    if (this.attMap['TransfereeName'] && this.attMap['TransfereeName'][0]) {
      this.TransfereeName = this.attMap['TransfereeName'][0];
      this.dspTransfereeName = this.TransfereeName;
    }
    if (this.attMap['dspTransfereeName'] && this.attMap['dspTransfereeName'][0]) {
      this.dspTransfereeName = this.attMap['dspTransfereeName'][0];
    }
    if (this.attMap['additionaltext'] && this.attMap['additionaltext'][0]) {
      this.additionaltext = this.attMap['additionaltext'][0];
    }
    if (this.attMap['NameChangeDesc'] && this.attMap['NameChangeDesc'][0]) {
      this.NameChangeDesc = this.attMap['NameChangeDesc'][0];
    }
    if (this.formState === 2 && this.attMap['EffectiveDate'] && this.attMap['EffectiveDate'][0]) {
      this.EffectiveDate = new Date(this.attMap['EffectiveDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    } else if (this.formState === 3 && this.attMap['EffectiveDate'] && this.attMap['EffectiveDate'][1]) {
      this.EffectiveDate = new Date(this.attMap['EffectiveDate'][1].replace(/-/g, '\/').replace(/T.+/, ''));
    }
    if (this.attMap['LiquidationDate'] && this.attMap['LiquidationDate'][0]) {
      this.LiquidationDate1 = new Date(this.attMap['LiquidationDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
    }
    this.cdref.detectChanges();
  }

  onTransfereeNameChanged(): void {
    this.dspTransfereeName = this.TransfereeName;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;
    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.target])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.target ? this.target?.ENTITYID.toString() : '',
        'secondaryEntityId': '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };

      const transDate: string = this.EffectiveDate !== null && this.EffectiveDate !== '' ? this.EffectiveDate.startDate ? this.EffectiveDate.startDate.format('YYYY-MM-DD') : this.EffectiveDate : '';
      const EffectiveDate: string[] = [this.formState === 2 ? transDate : '', this.formState === 3 ? transDate : ''];
      const jsonObject = {
        'TransfereeName': this.formState === 2 ? this.TransfereeName.toString() : '',
        'PLR': this.PLR.toString() || 'None.',
        'dspTransfereeName': this.dspTransfereeName.toString(),
        'additionaltext': this.additionaltext.toString(),
        'EffectiveDate': EffectiveDate,
        'LiquidationDate': this.LiquidationDate1 !== null && this.LiquidationDate1 !== '' ? this.LiquidationDate1.startDate ? this.LiquidationDate1.startDate.format('YYYY-MM-DD') : this.LiquidationDate1 : '',
        'NameChangeDesc': this.NameChangeDesc.toString()
      };

      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');
          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          const childStatements = data.childStatement;
          if (childStatements?.length) {
            this.sharedService.openChildStatements(childStatements, this.onSave);
            trackerTab.parentTransDate = transDate;
          } else {
            this.sharedService.switchTemplateMode(true);
            this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
          }
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;
      });
    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onTargetChanged(selectedTarget: DSTEntityDetails | null): void {
    this.target = selectedTarget;
    if (!selectedTarget) {
      return;
    }
    this.leidEIN = selectedTarget?.EIN ? selectedTarget.EIN : '';
    this.leidEntity = selectedTarget?.ENTITY ? selectedTarget.ENTITY : '';
    if (this.formState === 2) {
      this.headerComponent1.loadConsolGroups(selectedTarget.LEID,this.dcnId);
    } else if (this.formState === 3) {
      this.headerComponent2.loadConsolGroups(selectedTarget.LEID,this.dcnId);
    }
    // check for DRE
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.target!]);
    this.showDREMsg = !!this.DREMsg;
    // entity name change query
    if (this.EffectiveDate && this.target?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.target.LEID, this.EffectiveDate,
        //this.sharedService.getTransDateStr(this.EffectiveDate,1),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]?.LE_NAME) {
          if (this.formState === 2) {
            if(response[0].LE_NAME === this.target!.ENTITY){
              return;
            }
            this.target!.ENTITY = response[0].LE_NAME; // override entity name (YES)
            this.TransfereeName = response[0].LE_NAME;
            this.dspTransfereeName = this.TransfereeName;
          } else if (this.formState === 3) {
            response[0].LE_NAME && (this.leidEntity = response[0].LE_NAME); // override entity name (NO)
          }
        } else {
          this.TransfereeName = this.target!.ENTITY;
          this.dspTransfereeName = this.TransfereeName;
        }
          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.EffectiveDate) {
      this.nameChangeChecked = true;

    }
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  onFormOptionChanged(selectedOptionID: number): void {
    this.selectedOptionID = selectedOptionID;
  }

  dateChange(event: any, index: number): void {
    switch (index) {
      case 1: {
        this.EffectiveDate = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.target!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids, this.EffectiveDate,
          //this.sharedService.getTransDateStr(this.EffectiveDate,1),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response && response[0] && response[0].LE_NAME && response[0].EFFECTIVEDATE) {
            if (this.formState === 2) {
              // const nameChangedOn: string = response[0].EFFECTIVEDATE.split('T')[0];
              const nameChangedDate: Date = new Date(response[0].EFFECTIVEDATE);
              const transDate: Date = new Date(this.EffectiveDate.replace(/-/g, '\/'));
              // if transaction happens after/on the name change date, we need to pull the old name with (nameChangedDate - 1)
              if (transDate.getTime() >= nameChangedDate.getTime()) {
                this.sharedService.updateEntityName(response[0].LEID, response[0].LE_NAME, [this.target!], response[0].TAXTYPECODE);
                nameChangedDate.setDate(nameChangedDate.getDate() - 1);
                this.updateTargetFormerName(this.statementId, leids, nameChangedDate.toISOString().split('T')[0], this.sharedService.getActiveFormState().taxYear.toString());
              } else {
                this.sharedService.updateEntityName(response[0].LEID, response[0].LE_NAME, [this.target!], response[0].TAXTYPECODE);
                // update former entity name only when creating new
                if (!this.statementId) {
                  this.TransfereeName = response[0].LE_NAME;
                  this.dspTransfereeName = this.TransfereeName;
                }
              }
            } else if (this.formState === 3) {
              this.leidEntity = response[0].LE_NAME;
            }
          } else if (response && response[0] && !response[0].LE_NAME) {
            // update former entity name only when creating new
            if (!this.statementId) {
              this.TransfereeName = this.target!.ENTITY;
              this.dspTransfereeName = this.TransfereeName;
            }
          }
            this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });
        break;
      }
      case 2: {
        this.LiquidationDate1 = event.startDate.format('YYYY-MM-DD');
        this.PLR = '';
        break;
      }
      default: {
        break;
      }
    }
  }

  private updateTargetFormerName(statementID: string | undefined, leids: string, aDayPriorToNameChange: string, taxYear: string): void {
    this.startLoader = true;
    this.sharedService.checkForEntityNameChange(statementID, leids, aDayPriorToNameChange, taxYear)
      .subscribe((response: EntityNameChange[]) => {
        if (response) {
          // update former entity name only when creating new
          //if (!this.statementId) {TODO: Verify if comenting this is fine
            this.TransfereeName = response[0]?.LE_NAME!;
            this.dspTransfereeName = this.TransfereeName;
          //}
        }
        this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
      this.startLoader = false;
    });
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  isFormValid(placeholder: boolean): boolean {
    if (this.dcnId <= 0) {
      return false;
    }
    if (this.formState === 2) {
      if (!this.target) {
        this.dstSharedService.showAlert('error', 'Please specify the Transferor');
        return false;
      }
      if (placeholder) {
        return true;
      }
      if (this.EffectiveDate == null || this.EffectiveDate === '') {
        this.dstSharedService.showAlert('error', 'Please select the date from a calendar');
        return false;
      }
      if (this.TransfereeName === '') {
        this.dstSharedService.showAlert('error', 'Please enter the formerly known as name');
        return false;
      }
      if (this.dspTransfereeName === '') {
        this.dstSharedService.showAlert('error', 'Please enter the name');
        return false;
      }

    } else if (this.formState === 3) {
      if (!this.target) {
        this.dstSharedService.showAlert('error', 'Please specify the Transferor');
        return false;
      }
      if (placeholder) {
        return true;
      }
      if (this.EffectiveDate == null || this.EffectiveDate === '') {
        this.dstSharedService.showAlert('error', 'Please enter the date');
        return false;
      }
      if (this.NameChangeDesc === '') {
        this.dstSharedService.showAlert('error', 'Please complete the paragraph one');
        return false;
      }
    }

    return true;
  }

}
