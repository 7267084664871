import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HomePageContainerComponent } from './home-page-container.component';
import { HomePageFilterComponent } from './home-page-filter/home-page-filter.component';
import { ScreenDetailsComponent } from './screen-details/screen-details.component';
import { FavoriteButtonComponent } from './screen-details/favorite-button/favorite-button.component';
import { RequestAccessButtonComponent } from './screen-details/request-access-button/request-access-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HomePageScreensUpdatesComponent } from './home-page-screens-updates/home-page-screens-updates.component';

// import { AppNgAppkitModule } from '../app-ng-appkit.module';
import { AppNgMaterialModule } from '../app-ng-material.module';
// import { DataFilterPipe } from '../shared/pipes/datafilter.pipe';
import { FormsModule } from '@angular/forms';
// import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextDatePipe } from '../shared/pipes/text-date.pipe';
import { SelectorModule } from 'appkit-angular/selector';
import { SharedPipesModule } from '../shared/pipes/shared-pipe.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';


@NgModule({
  declarations: [
    HomePageContainerComponent, HomePageFilterComponent, ScreenDetailsComponent, 
    FavoriteButtonComponent, RequestAccessButtonComponent, 
    HomePageScreensUpdatesComponent, TextDatePipe],
  imports: [
    CommonModule,
    MatTooltipModule,
    // AppNgAppkitModule,
    AppNgMaterialModule,
    FormsModule,
    ScrollingModule,
    SelectorModule
    ,SharedPipesModule,
    SharedComponentsModule
  ],
  providers: [ DatePipe ],
  exports: [HomePageContainerComponent]
})
export class HomePageModule { }
