<mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate">
</mat-progress-bar>
<div class="a-mt-20 d-flex">
    <div class="col-12">
        <ap-panels-cards [withHeader]="true" [headerBtnName]="'Entity List'" [withSearchBar]="false">
            <div class="col-12 gray-container table-content">
                <div class="max-height">
                    <section>
                        <div>
                            <table class="table table-hover table-bordered table-striped table-condensed">
                                <thead class="a-thead">
                                    <tr>
                                        <th></th>
                                        <th>Source System</th>
                                        <th>LEID</th>
                                        <th>Tax Code</th>
                                        <th>Reporting Period</th>
                                        <th>ME Code</th>
                                        <!-- <th>LE Key</th> -->
                                        <th>LE Type</th>
                                        <th>Trading Partners</th>
                                        <th>Partner for Partnerships/ Partnership for Partners</th>
                                        <th>Transactions to be Deleted</th> <!-- Delete Transaction -->
                                        <th>Trans details Dropdown with Count</th>


                                    </tr>
                                </thead>
                                <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="allEntityDetails.length > 0">
                                    <ng-container *ngFor="let item of allEntityDetails; index as i">
                                        <ng-template #tooltip>
                                            <div class="tooltip-inner a-p-20 d-block a-min-width-340">
                                                <div
                                                    class="a-font-sm customize-tooltip-text font-weight-normal text-left a-mt-10">
                                                    {{ item.message }}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <tr [hidden]="item.display == false">
                                            <td class="parentCell">
                                                <span class="tooltip-td tooltip-inner a-p-20 a-min-width-340">{{ item.message }}</span> 
                                                <div *ngIf="item.IS_PARENT == 'N'">
                                                    <ap-checkbox
                                                        (onCheck)="selectEntity($event, item)"
                                                        [disabled]="item.IS_CKB_DISABLED === 'Y' || item.TP_KEY_DTLS_COUNT != 0 || item.PSHIP_DTLS_COUNT != 0 ? true : false">
                                                    </ap-checkbox>
                                                </div>
                                                <div *ngIf="item.IS_PARENT == 'Y' && item.PLUS == true">
                                                    <ap-button class="plus-btn" [btnSize]="'small'"
                                                        [config]="floatingConfig" (onBtnClick)="expandRow(i)">
                                                        <span class="appkiticon a-btn-icon icon-plus-fill"></span>
                                                    </ap-button>

                                                </div>

                                                <div *ngIf="item.IS_PARENT == 'Y' && item.PLUS == false">
                                                    <ap-button class="plus-btn" [btnSize]="'small'"
                                                        [config]="floatingConfig" (onBtnClick)="collapseRow(i)">
                                                        <span class="appkiticon a-btn-icon icon-minus-fill"></span>
                                                    </ap-button>

                                                </div>
                                            </td>
                                            <td>

                                                {{item.SOURCE_SYSTEM_FLAG =='D'? 'Domestic' : 'Foreign'}}

                                            </td>
                                            <td class="width-entity">

                                                {{item.LEID}}

                                            </td>
                                            <td>{{item.CDR_NO}}</td>
                                            <td>{{item.REPORTING_PERIOD}}</td>
                                            <td>{{item.ME_CODE ? item.ME_CODE : ''}}</td>
                                            <!-- <td>{{item.LE_KEY}}</td> -->
                                            <td>{{item.LE_TYPE}}</td>
                                            <td>{{item.TP_KEY_DTLS_COUNT ? item.TP_KEY_DTLS_COUNT : '0' }}
                                            </td>
                                            <td>{{ item.PSHIP_DTLS_COUNT ? item.PSHIP_DTLS_COUNT : '0' }}
                                            </td>
                                            <td>
                                                {{item.TRANS_COUNT}}
                                            </td>
                                            <td>
                                                <mat-select name="transCount" *ngIf="item.TRANS_COUNT != 0">
                                                    <ng-container *ngFor="let count of optionsTransCount">
                                                        <mat-option *ngIf="item.AGG_COMBINATION_KEY == count.AGG_COMBINATION_KEY;"
                                                            [value]="count.TRANS_TYPE_KEY">
                                                            {{count.TRANS_DETAILS}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <ng-container *ngIf="item.IS_PARENT == 'Y' && item.TRANS_COUNT == 0">
                                                    
                                                </ng-container>
                                                <ng-container *ngIf="item.IS_PARENT == 'N' && item.TRANS_COUNT == 0">
                                                    No transactions to Delete
                                                </ng-container>
                                            </td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody class="a-tbody tbody-regular tbody-hover"
                                    *ngIf="allEntityDetails.length == 0 && !isLoading">
                                    <tr>
                                        <td colspan="11" class="text-sm-center">No Record</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>

            </div>
        </ap-panels-cards>
        <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

            <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
                [config]="secondaryConfig" (onBtnClick)="cancel()"></ap-button>
            <div>
                <ap-button [btnText]="'DELETE'" class="a-mr-10 a-btn-transparent" [isDisabled]="!enableButton"
                    [config]="secondaryConfig" (onBtnClick)="deleteEntities()"></ap-button>
            </div>


        </div>
    </div>

</div>