<!-- <p>summary-diagnostic works!</p>
 -->
 <div class="container-fluid summary-diag">
  <div class="body">
    <div class="row">
        <div class="col-md-4 screen-label">
            <span>Summary Diagnostics  <span class="icon appkiticon icon-star-outline"></span></span>
            
        </div>
            <!-- <div class="col-md-3" style="padding: 10px 0px 10px 10px" >
        <ap-search [isPrimary]="true" [isBase]="true" [(ngModel)]="baseValue"
                [disabled]="loading"
                (onKeyUp)="filterBase($event)" placeholder="Search">
            </ap-search>
        </div> -->
        <div class="col-md-8 action-items" style="justify-content: end;" >
            <span style="width:36% !important">
                <ap-search [isPrimary]="true" [isBase]="true" [(ngModel)]="baseValue"
                [disabled]="loading"
                (onKeyUp)="filterBase($event)" placeholder="Search">
            </ap-search>
            </span>
   
            <span ><mat-slide-toggle [checked]="checked" (change)="toggle($event)"></mat-slide-toggle></span>
            <span> Hide Resolved Diagnostics</span>  
            <span ><mat-slide-toggle [checked]="expand_collapse" (change)="expandCollapse($event)"></mat-slide-toggle></span>
            <span> Expand All/Collapse All</span> 
            <span style="font-weight: 700;cursor:pointer" class="filter-icon" (click)="showFilter()">Filter
                <span class=" appkiticon icon-filter-outline" >               
                    <i *ngIf="selectedFilterParams!=undefined" class="fa fa-circle" style="font-size: 8px;color: #D04A02;padding-left: 2px;padding-bottom: 6px;padding-top: 0px;"></i>
                </span>  
            </span>
        </div>
       <!--  <div class="col-md-1 " style="padding: 10px 15px;" >
            <span class="filter-icon appkiticon icon-filter-outline"></span>    
        </div> -->
            <!-- <div class="col-md-2 action-items"> 
                <span ><mat-slide-toggle [(ngModel)]="checked" (change)="toggle()"></mat-slide-toggle></span>
                <span> Hide Resolved Diagnostics</span>  
               </div> -->
        <div [hidden]="isShow">
            
            <gtw-dashboard-filter [filter-group-key]="701" [screen-key]="11461" 
            [client-key]="clientKey" [base-urls]="baseURLs" [global-params]="globalParams"
            [tax-years]="taxYears"
            (close-dashboard-filter)="closeFilterPanel($event)"

            (loadDataTableEmitter)="loadDashbaord($event)"></gtw-dashboard-filter>
        </div>
    </div>
    <app-loader [loaded]="loading" style="position: relative;top:25%">

    </app-loader>
    <div  class="gg-loader" style="width: 89%; height: calc(100vh - 230px)!important;"*ngIf= "(dashboard && dashboard.components && dashboard.components.length==0)">
        <div class="gg-loader-text"  >
            <img src="assets/img/arrow-filter-sm.png" *ngIf="loading">
        </div>
    </div>
    <div *ngIf="loading">
     <div *ngIf="(dashboard && dashboard.components && dashboard.components.length>0)"
        [ngStyle]="{'opacity': !isShow ?'0.5' : '1',
                        'background': !isShow ? '#F2F2F2' :'#d1cfcf3b' }">
        <div class="row d-flex align-items-center justify-content-center">
            <!-- <div class="loader" *ngIf="kpiLoading"></div> -->
            <app-loader [loaded]="kpiLoading" style="position: relative;top:25%"></app-loader>
        </div>
        <div class="row kpi" *ngIf="kpiData.length>0 && kpiData[0].DIAG_MSG == 'All'">
              <div class="col-md-4" style="width: 100%;" *ngFor="let kpi of kpiData"
               (click)="kpiFilter(kpi)">
                    <gtw-kpi-box style="width: 100%;" *ngIf="kpi.DIAG_MSG != 'undefined'" [kpi-data]=kpi [kpiFilter]="selectedKpi"></gtw-kpi-box>
                </div>
        </div>

        <div class="row" style="margin: 12px -10px;" *ngFor="let item of dashboard.components;let i= index">
            <gtw-data-table style="width:100%;" (loadDashboardScreen)="drillDownDeatil($event)" 
                [index]="i" [table-sheet]=item [searchValue]="filterValue" [kpiFilter]="selectedKpi"
                [selectedFilterParams]="selectedFilterParams"  [globalParams]="globalParams"
                [hideResolvedDiag]="checked" 
                [expandCollapseAll]="expand_collapse"
            ></gtw-data-table>
        </div>
     </div>     
    </div>
  </div>
    
</div>