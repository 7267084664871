import { NgModule } from "@angular/core";
import { DataFilterPipe } from "./datafilter.pipe";

@NgModule({
    declarations: [DataFilterPipe],
    imports: [
    ],
    exports: [
      DataFilterPipe
    ]
  })
  export class SharedPipesModule {
  }