<ap-loading id="overlay" *ngIf="crudLoading" [size]="'24px'" [colorName]="'a-primary'">
</ap-loading>
<div *ngIf="!crudLoading">
    <form name="screenFrom" id="screenFrom" disabled="disabled" class="form-horizontal" novalidate>
        <div>

            <div style="padding-bottom: 0 !important;">


                <div id="form_header" class="BlackSmHdrBC" *ngIf="!crudLoading">
                    {{f8082Header.ATTRIB_DESCRIPTION}}
                </div>
                <table class="table table-bordered InnerTblBG" *ngIf="!crudLoading">

                    <!---1st loop for whole data --->
                    <tr>
                        <td></td>
                        <td colspan="1">{{ f8082FormData['O'][0].Line_data[1].ATTRIB_DESCRIPTION }}</td>
                        <td colspan="2">
                            <input [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="f8082FormData['O'][0].Line_data[2].ATTRIB_VALUE"
                                class="form-control input-md " type="text"
                                (change)="valuechanged(0, null, f8082FormData['O'][0].Line_data[2].LINE_NO, 2, 'O')"
                                [disabled]="f8082FormData['O'][0].Line_data[2].IS_EDITABLE_CELL==='N'">
                        </td>
                        <td colspan="1">{{ f8082FormData['O'][1].Line_data[1].ATTRIB_DESCRIPTION }}</td>
                        <td colspan="2">
                            <input [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="f8082FormData['O'][1].Line_data[2].ATTRIB_VALUE"
                                class="form-control input-md numText number-field" format="number" only-digits
                                type="text"
                                (change)="valuechanged(1, null, f8082FormData['O'][1].Line_data[2].LINE_NO, 2, 'O')"
                                [disabled]="f8082FormData['O'][1].Line_data[2].IS_EDITABLE_CELL==='N'">
                        </td>
                    </tr>
                    <tr>
                        <th colspan="7">Part I - General Information </th>
                    </tr>

                    <tr *ngFor="let row of f8082FormData['P1']; let $parent = index" class="">
                        <!---inner loop for line data with row_type TH--->

                        <td *ngFor="let item of row.Line_data; let $index = index" class=""
                            [hidden]="!(row.ROW_TYPE==='DT') || (row.LINE_NO == '4' && ((isUS && usIndex.includes($parent)) || (!isUS && foreignIndex.includes($parent))))"
                            [attr.colspan]="item.COLSPAN">
                            <p *ngIf="item.EDIT_TYPE==='textarea' && item.DATA_TYPE==='string' && item.LINE_NO != '4'">
                                <textarea style="width: 100%" class="form-control input-sm numText"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE" placeholder=""
                                    (change)="valuechanged($parent, null, item.LINE_NO, $index, 'P1')">
								</textarea>
                            </p>
                            <p *ngIf="item.EDIT_TYPE==='textarea' && item.DATA_TYPE==='string' && item.LINE_NO == '4'">
                                <textarea style="width: 100%" class="form-control input-sm numText"
                                    [maxLength]="maxLengthMap[item.LINE_DESCRIPTION]"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE" placeholder=""
                                    (change)="valuechanged($parent, null, item.LINE_NO, $index, 'P1')">
								</textarea>
                            </p>
                            <p *ngIf="item.EDIT_TYPE==='currency' && item.DATA_TYPE==='number'">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    class="form-control input-md numText number-field" format="number" only-digits
                                    type="text" (change)="valuechanged($parent, null, item.LINE_NO, $index, 'P1')"
                                    [disabled]="item.IS_EDITABLE_CELL==='N'">
                            </p>
                            <p *ngIf="item.EDIT_TYPE==='select' && item.DATA_TYPE==='string'">
                                <ap-selector [items]="dropdownOptions" [type]="'SingleSelector'"
                                    [searchMethod]="'panel'"
                                    (onSelectedChange)="dropdownSelect($event, $parent, $index)">
                                </ap-selector>
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    class="form-control input-md " type="text" disabled [disabled]="true">
                            </p>
                            <p
                                *ngIf="item.EDIT_TYPE==='text' && (item.DATA_TYPE==='string' || item.DATA_TYPE === 'number')">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    class="form-control input-md " type="text"
                                    (change)="valuechanged($parent, null, item.LINE_NO, $index, 'P1')"
                                    [disabled]="item.IS_EDITABLE_CELL==='N'">
                            </p>
                            <ng-container
                                *ngIf="item.EDIT_TYPE==='checkbox' && item.DATA_TYPE==='string' && row.Line_data.length == '3'">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    type="checkbox"
                                    (change)="item.ATTRIB_VALUE = item.ATTRIB_VALUE ? 1 : 0; valuechanged($parent, null, item.LINE_NO, $index, 'P1');">

                                <br />
                            </ng-container>
                            <ng-container
                                *ngIf="item.EDIT_TYPE==='checkbox' && item.DATA_TYPE==='string' && row.Line_data.length == '4'">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    type="checkbox"
                                    (change)="item.ATTRIB_VALUE = item.ATTRIB_VALUE ? 1 : 0; checkboxSwitch($parent, $index, item.ATTRIB_VALUE); valuechanged($parent, null, item.LINE_NO, $index, 'P1')">
                                <span *ngIf="$index == 2">Yes</span><span *ngIf="$index == 3">No</span>
                                <br />
                            </ng-container>
                            <p
                                *ngIf="row.LINE_NO !=='Header' && item.EDIT_TYPE==='label' && item.DATA_TYPE==='string' && item.ATTRIB_DESCRIPTION != 'N/A' && item.ATTRIB_DESCRIPTION != 'NA'">
                                <span *ngIf="row.LINE_NO != '4'">{{item.ATTRIB_DESCRIPTION}}</span>
                                <span *ngIf="row.LINE_NO == '4' && $index > 0">{{ addressMap[item.ATTRIB_DESCRIPTION]
                                    }}</span>
                                <span
                                    *ngIf="row.LINE_NO == '4' && $index == 0 && row.Line_data.length == 2">{{item.ATTRIB_DESCRIPTION}}</span>


                            </p>
                            <p *ngIf="item.EDIT_TYPE==='custom_date_various' && item.DATA_TYPE==='dateVarious'">
                                <ap-date-picker class="date-content" format="MM/DD/yyyy" class="not-disabled"
                                    type="singleCalendar" size="medium" [isTitleLeft]="true"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="item.ATTRIB_VALUE"
                                    (click)="onDPOpen();valuechanged($parent, null, item.LINE_NO, $index, 'P1');"
                                    [disabled]="item.IS_EDITABLE_CELL==='N'">
                                </ap-date-picker>
                            </p>
                        </td>

                    </tr>
                </table>
                <table class="table table-bordered InnerTblBG" *ngIf="!crudLoading" style="display: inline-block;">
                    <tr>
                        <th colspan="8">Part II - Inconsistent or Administrative Adjustment Request (AAR) Items </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th colspan="2">(b) Inconsistency is in,
                            or AAR is to correct
                            (check boxes that apply)</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                    <tr>
                        <th></th>
                        <th>(a) Description of inconsistent or AAR items
                            (see instructions) </th>
                        <th>Amount of
                            item</th>
                        <th>Treatment
                            of item </th>
                        <th>(c) Amount as shown on
                            Schedule K-1, Schedule Q, or
                            similar statement; a foreign
                            trust statement; or your return,
                            whichever applies (see
                            instructions) </th>
                        <th>(d) Amount you are reporting</th>
                        <th>(e) Difference between
                            (c) and (d)</th>
                        <th>(f) Explanations—Enter the Part II item number before each explanation. Show how the IU was
                            calculated and how modifications were applied.</th>
                    </tr>
                    <tbody *ngIf="showP2">
                        <tr>
                            <td colspan="8">
                                <i class="fa fa-plus-circle color-green fa-sm pull-left"
                                    style="cursor: pointer; margin-top: 4px;" data-toggle="tooltip"
                                    data-placement="right" data-trigger="hover" (click)="addP2Row()">Add Row</i>
                            </td>
                        </tr>
                        <tr *ngFor="let row of f8082FormData['P2'][0].JSON_DATA; let $parent = index" class="">
                            <!---inner loop for line data with row_type TH--->
                            <td class="">
                                <i class="fa fa-minus-circle color-red fa-sm pull-left"
                                    style="cursor: pointer; margin-top: 4px;" data-toggle="tooltip"
                                    data-placement="right" data-trigger="hover" (click)="deleteP2Row($parent)">{{
                                    $parent + 8 }}</i>
                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.A"
                                    class="form-control input-md " type="text">

                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.B" type="checkbox"
                                    (change)="row.B = row.B ? 1 : 0;">

                                <br />
                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.C" type="checkbox"
                                    (change)="row.C = row.C ? 1 : 0;">

                                <br />
                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.D"
                                    class="form-control input-md numText number-field" format="number" only-digits
                                    type="text" (blur)="updateAmount($parent)">
                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.E"
                                    class="form-control input-md numText number-field" format="number" only-digits
                                    type="text" (blur)="updateAmount($parent)">
                            </td>
                            <td class="">
                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="row.F"
                                    class="form-control input-md numText number-field" format="number" only-digits
                                    type="text">
                            </td>
                            <td class="">
                                <textarea style="width: 100%;" class="form-control input-sm numText"
                                    [ngModelOptions]="{standalone: true}" [maxLength]="4000" [(ngModel)]="row.G"
                                    placeholder="">
                                </textarea>
                            </td>

                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </form>
</div>
<br />
<br />
<br />
<div class="a-modal-footer modal-footer pad-zero"
    style="float: right; width: 100%; background-color: white; margin-top: -45px;">
    <button class="btn btn-info btn-sm" type="button" (click)="save()" style="width: 150px;">Save and Proceed
        <span *ngIf="crudLoading">
            <i class="fa fa-spinner fa-pulse"></i>
        </span>
    </button>
    <button class="btn btn-warning btn-sm" type="button" (click)="cancel()" style="width: 100px;">Cancel</button>
</div>