import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TreeModule} from '@circlon/angular-tree-component';
import {StatementTrackerComponent} from './statement-tracker/statement-tracker.component';
import {AppNgAppkitModule} from '../app-ng-appkit.module';
import {SharedComponentsModule} from '../shared/components/shared-components.module';
import {CreateDisclosureComponent} from './statement-tracker/create-disclosure/create-new/create-disclosure.component';
import {CreateDisclosureSharedComponent} from './statement-tracker/create-disclosure/shared/create-disclosure-shared.component';
import {
  CreateDisclosureActionsComponent
} from './statement-tracker/create-disclosure/shared/create-disclosure-actions/create-disclosure-actions.component';
import {
  CreateDisclosureTemplateViewportComponent
} from './statement-tracker/create-disclosure/shared/create-disclosure-template-viewport/create-disclosure-template-viewport.component';
import {
  TemplateSec351Id184Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec351-id184/template-sec351-id184.component';
import {
  TemplateSec332Id189Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec332-id189/template-sec332-id189.component';
import {
  TemplateSec332Id190Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec332-id190/template-sec332-id190.component';
import {
  TemplateSec926Id214Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec926-id214/template-sec926-id214.component';
import {
  TemplateSec8824Id237Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8824-id237/template-sec8824-id237.component';
import {
  CreateDisclosureTemplateHostDirective
} from './statement-tracker/create-disclosure/shared/create-disclosure-template-viewport/create-disclosure-template-host.directive';
import {TemplateHeaderComponent} from './statement-tracker/create-disclosure/shared/templates/template-header/template-header.component';
import {ProjectTaggingComponent} from './shared/components/project-tagging/project-tagging.component';
import {
  TemplateLeidSelectorComponent
} from './statement-tracker/create-disclosure/shared/templates/template-leid-selector/template-leid-selector.component';
import {SharedDirectivesModule} from '../shared/_directives/shared-directives.module';
import {
  ViewTemplateSec351Id184Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec351-id184/view-template-sec351-id184/view-template-sec351-id184.component';
import {
  ViewTemplateSec332Id189Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec332-id189/view-template-sec332-id189/view-template-sec332-id189.component';
import {
  TemplateAttachedComponent
} from './statement-tracker/create-disclosure/shared/templates/template-attached/template-attached.component';
import {
  ViewTemplateAttachedComponent
} from './statement-tracker/create-disclosure/shared/templates/template-attached/view-template-attached/view-template-attached.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {
  ViewTemplateSec332Id190Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec332-id190/view-template-sec332-id190/view-template-sec332-id190.component';
import {CopyReplicateComponent} from './statement-tracker/copy-replicate/copy-replicate.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatchTrackerComponent} from './statement-tracker/match-tracker/match-tracker.component';
import {
  TemplateSec8883Id215Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8883-id215/template-sec8883-id215.component';
import {CreateOptionsComponent} from './statement-tracker/create-options/create-options.component';
import {
  ViewTemplateForm926Id214Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec926-id214/view-template-form926-id214/view-template-form926-id214.component';
import {
  TemplateSec368Id188Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec368-id188/template-sec368-id188.component';
import {
  TemplateSec367bId195Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id195/template-sec367b-id195.component';
import {
  ViewTemplateForm8824Id237Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8824-id237/view-template-form8824-id237/view-template-form8824-id237.component';
import {
  TemplateBulkUploadComponent
} from './statement-tracker/create-disclosure/shared/templates/template-attached/template-bulk-upload/template-bulk-upload.component';
import {
  TemplateBulkSupersedeComponent
} from './statement-tracker/create-disclosure/shared/templates/template-attached/template-bulk-supersede/template-bulk-supersede.component';
import {
  TemplateSec367bId194Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id194/template-sec367b-id194.component';
import {
  TemplateSec367bId193Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id193/template-sec367b-id193.component';
import {
  TemplateSec367bId192Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id192/template-sec367b-id192.component';
import {
  TemplateSec367bIdMinus192Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id-192/template-sec367b-id-192.component';
import {
  TemplateSec368Id186Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec368-id186/template-sec368-id186.component';

import {
  TemplateSec8594Id217Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8594-id217/template-sec8594-id217.component';
import {
  ViewTemplateSec368Id188Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec368-id188/view-template-sec368-id188/view-template-sec368-id188.component';
import {
  ViewTemplateSec367bId195Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id195/view-template-sec367b-id195/view-template-sec367b-id195.component';
import {TableModule} from 'primeng-lts/table';
import {CustomReportComponent} from './reports/custom-report/custom-report.component';
import {StmtIdBtnComponent} from './shared/components/stmt-id-btn/stmt-id-btn.component';
import {DSTMarkAsTemplateComponent} from './shared/components/stmt-id-btn/dst-mark-as-template/dst-mark-as-template.component';
import {DSTDropdownMenuComponent, FilterPipe} from './shared/components/stmt-id-btn/dst-dropdown-menu/dst-dropdown-menu.component';
import {DiscModalViewerComponent} from './shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {MultiSelectModule} from 'primeng-lts/multiselect';
import {SmartMenuPosDirective} from './shared/components/stmt-id-btn/smartMenuPos.directive';
import {DialogModule} from 'primeng-lts/dialog';
import {DropdownModule} from 'primeng-lts/dropdown';
import {
  ViewTemplateSec367bId194Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id194/view-template-sec367b-id194/view-template-sec367b-id194.component';
import {
  ViewTemplateSec367bId192Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id192/view-template-sec367b-id192/view-template-sec367b-id192.component';
import {
  ViewTemplateSec368Id186Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec368-id186/view-template-sec368-id186/view-template-sec368-id186.component';
import {
  ViewTemplateSec367bIdNeg192Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id-192/view-template-sec367b-id-192/view-template-sec367b-id-192.component';
import {
  ViewTemplateSec367bId193Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec367b-id193/view-template-sec367b-id193/view-template-sec367b-id193.component';
import {
  ViewTemplateSec8594Id217Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8594-id217/view-template-form8594-id217/view-template-form8594-id217.component';
import {
  ViewTemplateForm8883Id215Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8883-id215/view-template-form8883-id215/view-template-form8883-id215.component';
import {AmountPipe} from './shared/pipes/amount.pipe';
import {SearchPipe} from './shared/pipes/search.pipe';
import {TaxTypePipe} from './shared/pipes/taxtype.pipe';
import {
  TemplateForm8621Id236Component
} from './statement-tracker/create-disclosure/shared/templates/template-form8621-id236/template-form8621-id236.component';
import {
  ViewTemplateForm8621Id236Component
} from './statement-tracker/create-disclosure/shared/templates/template-form8621-id236/view-template-form8621-id236/view-template-form8621-id236.component';
import {DstCustomInputComponent} from './shared/components/dst-custom-input/dst-custom-input.component';
import {InputTextModule} from 'primeng-lts/inputtext';
import {
  TemplateSec8886Id235Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8886-id235/template-sec8886-id235.component';
import {
  ViewTemplateForm8886Id235Component
} from './statement-tracker/create-disclosure/shared/templates/template-sec8886-id235/view-template-form8886-id235/view-template-form8886-id235.component';
import {NaReportComponent} from './diagnostics/na-report/na-report.component';
import {DataCollectReportComponent} from './diagnostics/data-collect-report/data-collect-report.component';
import {TransfersReviewReportComponent} from './diagnostics/transfers-review-report/transfers-review-report.component';
import {Sec351ReportComponent} from './reports/sec-351-report/sec-351-report.component';
import {Sec332ReportComponent} from './reports/sec-332-report/sec-332-report.component';
import {Sec368ReportComponent} from './reports/sec-368-report/sec-368-report.component';
import {Sec926ReportComponent} from './reports/sec-926-report/sec-926-report.component';
import {LineSplitterDirective} from './shared/directives/line-splitter.directive';
import {SyncTableRowHeightDirective} from './shared/directives/sync-table-row-height.directive';
import {ButtonModule} from 'primeng-lts/button';
import {Form8594ReportComponent} from './reports/form-8594-report/form-8594-report.component';
import {Form8883ReportComponent} from './reports/form-8883-report/form-8883-report.component';
import { CountByBusinessComponent } from './reports/count-by-business/count-by-business.component';
import { DupsStmtReportComponent } from './diagnostics/dups-stmt-report/dups-stmt-report.component';
import { F304DiagReportComponent } from './diagnostics/f304-diag-report/f304-diag-report.component';
import { DiagnosticsReportComponent } from './reports/diagnostics-report/diagnostics-report.component';
import { DisregardedEntitiesComponent } from './reports/disregarded-entities/disregarded-entities.component';
import { AdminModule } from '../admin/admin.module';

@NgModule({
  declarations: [
    StatementTrackerComponent,
    ProjectTaggingComponent,
    CreateDisclosureComponent,
    CreateDisclosureSharedComponent,
    CreateDisclosureActionsComponent,
    CreateDisclosureTemplateViewportComponent,
    TemplateSec351Id184Component,
    CreateDisclosureTemplateHostDirective,
    TemplateHeaderComponent,
    TemplateLeidSelectorComponent,
    TemplateAttachedComponent,
    ViewTemplateAttachedComponent,
    TemplateSec332Id189Component,
    TemplateSec332Id190Component,
    TemplateSec926Id214Component,
    TemplateSec8824Id237Component,
    TemplateSec8883Id215Component,
    TemplateSec368Id188Component,
    TemplateSec367bId195Component,
    TemplateSec367bId194Component,
    TemplateSec367bId193Component,
    TemplateSec367bId192Component,
    TemplateSec367bIdMinus192Component,
    TemplateSec368Id186Component,
    TemplateSec8594Id217Component,
    ViewTemplateSec351Id184Component,
    ViewTemplateSec332Id189Component,
    ViewTemplateSec332Id190Component,
    CopyReplicateComponent,
    MatchTrackerComponent,
    CreateOptionsComponent,
    ViewTemplateForm926Id214Component,
    ViewTemplateForm8824Id237Component,
    TemplateBulkUploadComponent,
    TemplateBulkSupersedeComponent,
    CustomReportComponent,
    StmtIdBtnComponent,
    DSTMarkAsTemplateComponent,
    DSTDropdownMenuComponent,
    FilterPipe,
    DiscModalViewerComponent,
    SmartMenuPosDirective,
    ViewTemplateSec368Id188Component,
    ViewTemplateSec367bId195Component,
    ViewTemplateSec367bId194Component,
    ViewTemplateSec367bId192Component,
    ViewTemplateSec368Id186Component,
    ViewTemplateSec8594Id217Component,
    ViewTemplateSec367bIdNeg192Component,
    ViewTemplateSec367bId193Component,
    ViewTemplateForm8883Id215Component,
    AmountPipe,
    SearchPipe,
    TaxTypePipe,
    TemplateForm8621Id236Component,
    ViewTemplateForm8621Id236Component,
    DstCustomInputComponent,
    TemplateSec8886Id235Component,
    ViewTemplateForm8886Id235Component,
    NaReportComponent,
    DataCollectReportComponent,
    TransfersReviewReportComponent,
    Sec351ReportComponent,
    LineSplitterDirective,
    SyncTableRowHeightDirective,
    Sec332ReportComponent,
    Sec368ReportComponent,
    Sec926ReportComponent,
    Form8594ReportComponent,
    Form8883ReportComponent,
    CountByBusinessComponent,
    DupsStmtReportComponent,
    F304DiagReportComponent,
    DiagnosticsReportComponent,
    DisregardedEntitiesComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgAppkitModule,
    TreeModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    ScrollingModule,
    MatTabsModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    AdminModule
  ],
  providers: [DecimalPipe, DatePipe, TaxTypePipe,],
  exports: [
    StatementTrackerComponent,
    CustomReportComponent,
    NaReportComponent,
    DataCollectReportComponent,
    TransfersReviewReportComponent,
    DupsStmtReportComponent,
    F304DiagReportComponent,
    Sec351ReportComponent,
    Sec332ReportComponent,
    Sec368ReportComponent,
    Sec926ReportComponent,
    Form8594ReportComponent,
    Form8883ReportComponent,
    CountByBusinessComponent,
    DiagnosticsReportComponent,
    DisregardedEntitiesComponent
  ]
})
export class DSTModule {

}
