import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrackerTabsService, TrackerTabState} from '../../../../tracker-tabs.service';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../create-disclosure-shared.service';
import {DSTSavePayload} from '../../dst-dynamic-component-resolver.service';
import {DSTConsolGroup, TemplateHeaderComponent} from '../template-header/template-header.component';
import {TemplateHeaderService} from '../template-header/template-header.service';
import {DSTEntityDetails, TemplateLeidSelectorComponent} from '../template-leid-selector/template-leid-selector.component';
import {FormFieldType} from '../template-sec8824-id237/template-sec8824-id237.component';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState, StmtAttrData, StmtData} from '../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../shared/dst-shared.service';

@Component({
  selector: 'dst-template-sec8883-id215',
  templateUrl: './template-sec8883-id215.component.html',
  styleUrls: ['../template-shared.scss', './template-sec8883-id215.component.scss']
})
export class TemplateSec8883Id215Component implements OnInit, AfterViewInit {

  @ViewChild(TemplateHeaderComponent)
  private headerComponent!: TemplateHeaderComponent;

  @ViewChild('targetLeidComp')
  private targetLeidComp!: TemplateLeidSelectorComponent;

  @ViewChildren(TemplateLeidSelectorComponent)
  private leidComponents!: QueryList<TemplateLeidSelectorComponent>;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  form215!: FormGroup;
  selectedOptionID!: Event;
  dcnId!: number;
  startLoader: boolean = false;
  statementId: string = '';
  attMap: { [key: string]: string[] } = {};
  editMode: boolean = false;

  beginDate!: Date;
  endDate!: Date;
  transferor!: DSTEntityDetails | null;
  transferee!: DSTEntityDetails | null;
  Choice1cTarget!: boolean;
  Choice1dDate!: boolean;
  Yes1dDateText: string | Date = '';

  OtherPartyUSEnt!: boolean;
  OtherParty: string = '';
  einSsnRadio!: boolean;
  EINAppOP: string = '';
  SNAppOP: string = '';
  selectedMissingReason1: string = '';
  selectedMissingReason: string = '';
  selectedCountry: number = -1;
  selectedState: number = -1;
  AddressOfOtherParty1: string = '';
  AddressOfOtherParty2: string = '';
  CityOfOtherParty: string = '';
  ZIPCodeOfOtherParty: string = '';
  ProvOrStateOP: string = '';

  AcquisionDate: string | Date = '';
  PctDuring12Month: string = '';
  PctOnAcquisition: string = '';
  StockPrice: string = '';
  AcquisitionCostSC: string = '';
  TargetLiabilities: string = '';
  AGUBorADSP: string = '';
  Choice6!: boolean;
  Choice7!: boolean;
  Choice8a!: boolean;
  Choice8b!: boolean;
  Choice8c!: boolean;
  Choice8e!: boolean;
  Choice8f!: boolean;
  Choice8g!: boolean;
  Choice8h!: boolean;
  IsCFCPrev5Yrs: boolean = false;

  Class1AFMV: string = '';
  Class1AGUBOrADSP: string = '';
  Class2AFMV: string = '';
  Class2AGUBOrADSP: string = '';
  Class3AFMV: string = '';
  Class3AGUBOrADSP: string = '';
  Class4AFMV: string = '';
  Class4AGUBOrADSP: string = '';
  Class5AFMV: string = '';
  Class5AGUBOrADSP: string = '';
  Class6N7AFMV: string = '';
  Class6N7AGUBOrADSP: string = '';
  TotalAFMV: string = '';
  TotalAGUB: string = '';

  TaxYear: string = '';
  TaxReturnFormNumber: string = '';
  RsnForIncOrDec: string = '';
  Class1ASPPR: string = '';
  Class1RtnAllAGUB: string = '';
  Class2ASPPR: string = '';
  Class2RtnAllAGUB: string = '';
  Class3ASPPR: string = '';
  Class3RtnAllAGUB: string = '';
  Class4ASPPR: string = '';
  Class4RtnAllAGUB: string = '';
  Class5ASPPR: string = '';
  Class5RtnAllAGUB: string = '';
  Class6N7ASPPR: string = '';
  Class6N7RtnAllAGUB: string = '';
  TotalASPPR: string = '';
  TotalRtnAllAGUB: string = '';
  Class1IncDec: string = '';
  Class2IncDec: string = '';
  Class3IncDec: string = '';
  Class4IncDec: string = '';
  Class5IncDec: string = '';
  Class6N7IncDec: string = '';

  TargetCorpUSEnt!: boolean;

  showDREMsg: boolean = false;
  DREMsg: string = '';

  EINMissingReason1: AppkitDropdownOptions[] = [{
    data: [
      { value: '', text: '** Please select the missing reason **' },
      {
        value: 'APPLD FOR',
        text: 'APPLD FOR'
      },
      {
        value: 'FOREIGNUS',
        text: 'FOREIGNUS'
      },
    ]
  }];
  EINMissingReason: AppkitDropdownOptions[] = [{
    data: [
      { value: '', text: '** Please select the missing reason **' },
      {
        value: 'APPLD FOR',
        text: 'APPLD FOR'
      },
      {
        value: 'FOREIGNUS',
        text: 'FOREIGNUS'
      },
    ]
  }];

  StateOptions: AppkitDropdownOptions[] = [{
    data: []
  }];

  CountryOptions: AppkitDropdownOptions[] = [{
    data: []
  }];
  stateNamePart3: string = '';
  nameChangeChecked: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private trackerTabsService: TrackerTabsService,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.editMode = false;
    this.beginDate = new Date(1700, 0, 1);
    this.endDate = new Date((this.sharedService.getActiveFormState()?.taxYear || 2021) + 2, 11, 31);
    this.form215 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      }),
      partOne: this.formBuilder.group({
        Yes1dDateText: [''],
      }),
      partTwo: this.formBuilder.group({
        OtherParty: [''],
        EINAppOP: [''],
        SNAppOP: [''],
        AddressOfOtherParty1: [''],
        AddressOfOtherParty2: [''],
        CityOfOtherParty: [''],
        ZIPCodeOfOtherParty: [''],
        ProvOrStateOP: [''],

      }),
      partThree: this.formBuilder.group({}),
      partFour: this.formBuilder.group({
        AcquisionDate: [''],
        PctDuring12Month: [''],
        PctOnAcquisition: [''],
        StockPrice: [''],
        AcquisitionCostSC: [''],
        TargetLiabilities: [''],
        AGUBorADSP: [''],
      }),
      partFive: this.formBuilder.group({
        Class1AFMV: [''],
        Class1AGUBOrADSP: [''],
        Class2AFMV: [''],
        Class2AGUBOrADSP: [''],
        Class3AFMV: [''],
        Class3AGUBOrADSP: [''],
        Class4AFMV: [''],
        Class4AGUBOrADSP: [''],
        Class5AFMV: [''],
        Class5AGUBOrADSP: [''],
        Class6N7AFMV: [''],
        Class6N7AGUBOrADSP: [''],
        TotalAFMV: [''],
        TotalAGUB: [''],

      }),
      partSix: this.formBuilder.group({
        TaxYear: [''],
        TaxReturnFormNumber: [''],
        RsnForIncOrDec: [''],
        Class1ASPPR: [''],
        Class1RtnAllAGUB: [''],
        Class2ASPPR: [''],
        Class2RtnAllAGUB: [''],
        Class3ASPPR: [''],
        Class3RtnAllAGUB: [''],
        Class4ASPPR: [''],
        Class4RtnAllAGUB: [''],
        Class5ASPPR: [''],
        Class5RtnAllAGUB: [''],
        Class6N7ASPPR: [''],
        Class6N7RtnAllAGUB: [''],
        TotalASPPR: [''],
        TotalRtnAllAGUB: [''],
        Class1IncDec: [''],
        Class2IncDec: [''],
        Class3IncDec: [''],
        Class4IncDec: [''],
        Class5IncDec: [''],
        Class6N7IncDec: [''],
      }),
    });
    this.sharedService.getCountryDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.CountryOptions[0].data?.push({
            value: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {
        if (this.attMap['CountryOfOP']) {
          const updatedData = this.updateDropdownList(this.CountryOptions, this.attMap['CountryOfOP'][0]);
          this.CountryOptions = updatedData.dropdownData;
          this.selectedCountry = updatedData.index;
        }
      });
    this.sharedService.getStateDropdown().subscribe((data: DropDownType[]) => {
      data.forEach(element => {
        this.StateOptions[0].data?.push({
          value: element.id,
          text: element.text
        });
      });
    }, () => {

    }, () => {
      if (this.attMap['StateNameOfOtherParty']) {
        const updatedData = this.updateDropdownList(this.StateOptions, this.attMap['StateNameOfOtherParty'][0]);
        this.StateOptions = updatedData.dropdownData;
        this.selectedState = updatedData.index;
      }
    });
  }

  updateDropdownList(currentOptions: any, element: string, isText: boolean = false) {
    const updateList: any[] = [];
    let selectedIndex = -1;
    currentOptions[0].data.forEach((el: any, index: number) => {
      const elementValue = isText ? el.text : el.value;
      if (element === elementValue) {
        updateList.push({
          value: el.value,
          text: el.text,
          index: index,
          selected: true,
        });
        selectedIndex = elementValue;
      } else {
        updateList.push({
          value: el.value,
          text: el.text,
          index: el.value,
        });
      }
    });
    return {dropdownData: [{data: updateList}], index: selectedIndex};
  }

  ngAfterViewInit(): void {
    this.loadStmtData();
  }

  private loadStmtData(): void {
    const data = this.sharedService.getFormData();
    if (data) {
      this.statementId = data.isCopiedStatement ? '' : data.statementid.toString();
      this.dcnId = parseInt(data.dcnid!);
      this.editMode = true;
      data.children?.forEach((stmt: StmtAttrData) => {
        if (this.attMap[stmt.attributeName] == undefined) {
          this.attMap[stmt.attributeName] = [];
        }
        if (stmt.attributeValue !== undefined) {
          this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
        }
      });
      if (this.attMap['Choice1cTarget'] && this.attMap['Choice1cTarget'][0]) {
        this.Choice1cTarget = this.attMap['Choice1cTarget'][0] === 'Old';
      }
      if (this.attMap['Choice1dDate'] && this.attMap['Choice1dDate'][0]) {
        this.Choice1dDate = this.attMap['Choice1dDate'][0] === 'Yes';
      }
      if (this.attMap['OtherPartyUSEnt'] && this.attMap['OtherPartyUSEnt'][0]) {
        this.OtherPartyUSEnt = this.attMap['OtherPartyUSEnt'][0] === 'Yes';
      }

      if (this.attMap['TargetCorpUSEnt'] && this.attMap['TargetCorpUSEnt'][0]) {
        this.TargetCorpUSEnt = this.attMap['TargetCorpUSEnt'][0] === 'Yes';
      }
      if (this.attMap['einSsnRadio'] && this.attMap['einSsnRadio'][0]) {
        this.einSsnRadio = this.attMap['einSsnRadio'][0] === 'ein';
      }
      // if(this.attMap['SNAppOP'] && this.attMap['SNAppOP'][0]){
      //   this.einSsnRadio = false;
      // }
      if (this.attMap['Choice6'] && this.attMap['Choice6'][0]) {
        this.Choice6 = this.attMap['Choice6'][0] === 'Yes';
      }
      if (this.attMap['Choice7'] && this.attMap['Choice7'][0]) {
        this.Choice7 = this.attMap['Choice7'][0] === 'Yes';
      }
      if (this.attMap['Choice8a'] && this.attMap['Choice8a'][0]) {
        this.Choice8a = this.attMap['Choice8a'][0] === 'Yes';
      }
      if (this.attMap['Choice8b'] && this.attMap['Choice8b'][0]) {
        this.Choice8b = this.attMap['Choice8b'][0] === 'Yes';
      }
      if (this.attMap['Choice8c'] && this.attMap['Choice8c'][0]) {
        this.Choice8c = this.attMap['Choice8c'][0] === 'Yes';
      }
      if (this.attMap['Choice8e'] && this.attMap['Choice8e'][0]) {
        this.Choice8e = this.attMap['Choice8e'][0] === 'Yes';
      }
      if (this.attMap['Choice8f'] && this.attMap['Choice8f'][0]) {
        this.Choice8f = this.attMap['Choice8f'][0] === 'Yes';
      }
      if (this.attMap['Choice8g'] && this.attMap['Choice8g'][0]) {
        this.Choice8g = this.attMap['Choice8g'][0] === 'Yes';
      }
      if (this.attMap['Choice8h'] && this.attMap['Choice8h'][0]) {
        this.Choice8h = this.attMap['Choice8h'][0] === 'Yes';
      }
      if (this.attMap['IsCFCPrev5Yrs'] && this.attMap['IsCFCPrev5Yrs'][0]) {
        this.IsCFCPrev5Yrs = this.attMap['IsCFCPrev5Yrs'][0] === 'Yes';
      }
      if (this.attMap['Yes1dDateText'] && this.attMap['Yes1dDateText'][0]) {
        this.Yes1dDateText = new Date(this.attMap['Yes1dDateText'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['AcquisionDate'] && this.attMap['AcquisionDate'][0]) {
        this.AcquisionDate = new Date(this.attMap['AcquisionDate'][0].replace(/-/g, '\/').replace(/T.+/, ''));
      }
      if (this.attMap['OtherParty'] && this.attMap['OtherParty'][0]) {
        this.OtherParty = this.attMap['OtherParty'][0];
      }
      if (this.attMap['EINAppOP'] && this.attMap['EINAppOP'][0]) {
        this.EINAppOP = this.attMap['EINAppOP'][0];
        this.einSsnRadio = true;
      }
      if (this.attMap['SNAppOP'] && this.attMap['SNAppOP'][0]) {
        this.SNAppOP = this.attMap['SNAppOP'][0];
        this.einSsnRadio = false;
      }
      if (this.attMap['AddressOfOtherParty1'] && this.attMap['AddressOfOtherParty1'][0]) {
        this.AddressOfOtherParty1 = this.attMap['AddressOfOtherParty1'][0];
      }
      if (this.attMap['AddressOfOtherParty2'] && this.attMap['AddressOfOtherParty2'][0]) {
        this.AddressOfOtherParty2 = this.attMap['AddressOfOtherParty2'][0];
      }
      if (this.attMap['CityOfOtherParty'] && this.attMap['CityOfOtherParty'][0]) {
        this.CityOfOtherParty = this.attMap['CityOfOtherParty'][0];
      }
      if (this.attMap['ProvOrStateOP'] && this.attMap['ProvOrStateOP'][0]) {
        this.ProvOrStateOP = this.attMap['ProvOrStateOP'][0];
      }
      if (this.attMap['ZIPCodeOfOtherParty'] && this.attMap['ZIPCodeOfOtherParty'][0]) {
        this.ZIPCodeOfOtherParty = this.attMap['ZIPCodeOfOtherParty'][0];
      }
      if (this.attMap['PctDuring12Month'] && this.attMap['PctDuring12Month'][0]) {
        this.PctDuring12Month = (this.attMap['PctDuring12Month'][0] !== '' ? (parseFloat(this.attMap['PctDuring12Month'][0]) * 100).toFixed(2) : '');
      }
      if (this.attMap['PctOnAcquisition'] && this.attMap['PctOnAcquisition'][0]) {
        this.PctOnAcquisition = (this.attMap['PctOnAcquisition'][0] !== '' ? (parseFloat(this.attMap['PctOnAcquisition'][0]) * 100).toFixed(2) : '');
      }
      if (this.attMap['StockPrice'] && this.attMap['StockPrice'][0]) {
        this.StockPrice = this.attMap['StockPrice'][0];
      }
      if (this.attMap['AcquisitionCostSC'] && this.attMap['AcquisitionCostSC'][0]) {
        this.AcquisitionCostSC = this.attMap['AcquisitionCostSC'][0];
      }
      if (this.attMap['TargetLiabilities'] && this.attMap['TargetLiabilities'][0]) {
        this.TargetLiabilities = this.attMap['TargetLiabilities'][0];
      }
      if (this.attMap['AGUBorADSP'] && this.attMap['AGUBorADSP'][0]) {
        this.AGUBorADSP = this.attMap['AGUBorADSP'][0];
      }
      if (this.attMap['Class1AFMV'] && this.attMap['Class1AFMV'][0]) {
        this.Class1AFMV = this.attMap['Class1AFMV'][0];
      }
      if (this.attMap['Class1AGUBOrADSP'] && this.attMap['Class1AGUBOrADSP'][0]) {
        this.Class1AGUBOrADSP = this.attMap['Class1AGUBOrADSP'][0];
      }
      if (this.attMap['Class2AFMV'] && this.attMap['Class2AFMV'][0]) {
        this.Class2AFMV = this.attMap['Class2AFMV'][0];
      }
      if (this.attMap['Class2AGUBOrADSP'] && this.attMap['Class2AGUBOrADSP'][0]) {
        this.Class2AGUBOrADSP = this.attMap['Class2AGUBOrADSP'][0];
      }
      if (this.attMap['Class3AFMV'] && this.attMap['Class3AFMV'][0]) {
        this.Class3AFMV = this.attMap['Class3AFMV'][0];
      }
      if (this.attMap['Class3AGUBOrADSP'] && this.attMap['Class3AGUBOrADSP'][0]) {
        this.Class3AGUBOrADSP = this.attMap['Class3AGUBOrADSP'][0];
      }
      if (this.attMap['Class4AFMV'] && this.attMap['Class4AFMV'][0]) {
        this.Class4AFMV = this.attMap['Class4AFMV'][0];
      }
      if (this.attMap['Class4AGUBOrADSP'] && this.attMap['Class4AGUBOrADSP'][0]) {
        this.Class4AGUBOrADSP = this.attMap['Class4AGUBOrADSP'][0];
      }
      if (this.attMap['Class5AFMV'] && this.attMap['Class5AFMV'][0]) {
        this.Class5AFMV = this.attMap['Class5AFMV'][0];
      }
      if (this.attMap['Class5AGUBOrADSP'] && this.attMap['Class5AGUBOrADSP'][0]) {
        this.Class5AGUBOrADSP = this.attMap['Class5AGUBOrADSP'][0];
      }
      if (this.attMap['Class6N7AFMV'] && this.attMap['Class6N7AFMV'][0]) {
        this.Class6N7AFMV = this.attMap['Class6N7AFMV'][0];
      }
      if (this.attMap['Class6N7AGUBOrADSP'] && this.attMap['Class6N7AGUBOrADSP'][0]) {
        this.Class6N7AGUBOrADSP = this.attMap['Class6N7AGUBOrADSP'][0];
      }
      if (this.attMap['TotalAFMV'] && this.attMap['TotalAFMV'][0]) {
        this.TotalAFMV = this.attMap['TotalAFMV'][0];
      }
      if (this.attMap['TotalAGUB'] && this.attMap['TotalAGUB'][0]) {
        this.TotalAGUB = this.attMap['TotalAGUB'][0];
      }
      if (this.attMap['TaxYear'] && this.attMap['TaxYear'][0]) {
        this.TaxYear = this.attMap['TaxYear'][0];
      }
      if (this.attMap['TaxReturnFormNumber'] && this.attMap['TaxReturnFormNumber'][0]) {
        this.TaxReturnFormNumber = this.attMap['TaxReturnFormNumber'][0];
      }
      if (this.attMap['RsnForIncOrDec'] && this.attMap['RsnForIncOrDec'][0]) {
        this.RsnForIncOrDec = this.attMap['RsnForIncOrDec'][0];
      }
      if (this.attMap['Class1ASPPR'] && this.attMap['Class1ASPPR'][0]) {
        this.Class1ASPPR = this.attMap['Class1ASPPR'][0];
      }
      if (this.attMap['Class1RtnAllAGUB'] && this.attMap['Class1RtnAllAGUB'][0]) {
        this.Class1RtnAllAGUB = this.attMap['Class1RtnAllAGUB'][0];
      }
      if (this.attMap['Class2ASPPR'] && this.attMap['Class2ASPPR'][0]) {
        this.Class2ASPPR = this.attMap['Class2ASPPR'][0];
      }
      if (this.attMap['Class2RtnAllAGUB'] && this.attMap['Class2RtnAllAGUB'][0]) {
        this.Class2RtnAllAGUB = this.attMap['Class2RtnAllAGUB'][0];
      }
      if (this.attMap['Class3ASPPR'] && this.attMap['Class3ASPPR'][0]) {
        this.Class3ASPPR = this.attMap['Class3ASPPR'][0];
      }
      if (this.attMap['Class3RtnAllAGUB'] && this.attMap['Class3RtnAllAGUB'][0]) {
        this.Class3RtnAllAGUB = this.attMap['Class3RtnAllAGUB'][0];
      }
      if (this.attMap['Class4ASPPR'] && this.attMap['Class4ASPPR'][0]) {
        this.Class4ASPPR = this.attMap['Class4ASPPR'][0];
      }
      if (this.attMap['Class4RtnAllAGUB'] && this.attMap['Class4RtnAllAGUB'][0]) {
        this.Class4RtnAllAGUB = this.attMap['Class4RtnAllAGUB'][0];
      }
      if (this.attMap['Class5ASPPR'] && this.attMap['Class5ASPPR'][0]) {
        this.Class5ASPPR = this.attMap['Class5ASPPR'][0];
      }
      if (this.attMap['Class5RtnAllAGUB'] && this.attMap['Class5RtnAllAGUB'][0]) {
        this.Class5RtnAllAGUB = this.attMap['Class5RtnAllAGUB'][0];
      }
      if (this.attMap['Class6N7ASPPR'] && this.attMap['Class6N7ASPPR'][0]) {
        this.Class6N7ASPPR = this.attMap['Class6N7ASPPR'][0];
      }
      if (this.attMap['Class6N7RtnAllAGUB'] && this.attMap['Class6N7RtnAllAGUB'][0]) {
        this.Class6N7RtnAllAGUB = this.attMap['Class6N7RtnAllAGUB'][0];
      }
      if (this.attMap['TotalASPPR'] && this.attMap['TotalASPPR'][0]) {
        this.TotalASPPR = this.attMap['TotalASPPR'][0];
      }
      if (this.attMap['TotalRtnAllAGUB'] && this.attMap['TotalRtnAllAGUB'][0]) {
        this.TotalRtnAllAGUB = this.attMap['TotalRtnAllAGUB'][0];
      }
      if (this.attMap['Class1IncDec'] && this.attMap['Class1IncDec'][0]) {
        this.Class1IncDec = this.attMap['Class1IncDec'][0];
      }
      if (this.attMap['Class2IncDec'] && this.attMap['Class2IncDec'][0]) {
        this.Class2IncDec = this.attMap['Class2IncDec'][0];
      }
      if (this.attMap['Class3IncDec'] && this.attMap['Class3IncDec'][0]) {
        this.Class3IncDec = this.attMap['Class3IncDec'][0];
      }
      if (this.attMap['Class4IncDec'] && this.attMap['Class4IncDec'][0]) {
        this.Class4IncDec = this.attMap['Class4IncDec'][0];
      }
      if (this.attMap['Class5IncDec'] && this.attMap['Class5IncDec'][0]) {
        this.Class5IncDec = this.attMap['Class5IncDec'][0];
      }
      if (this.attMap['Class6N7IncDec'] && this.attMap['Class6N7IncDec'][0]) {
        this.Class6N7IncDec = this.attMap['Class6N7IncDec'][0];
      }
      if (this.attMap['EINAppMROP'] && this.attMap['EINAppMROP'][0]) {
        const updatedData = this.updateDropdownList(this.EINMissingReason1, this.attMap['EINAppMROP'][0], true);
        this.EINMissingReason1 = updatedData.dropdownData;
        this.selectedMissingReason1 = this.attMap['EINAppMROP'][0];
      }
      if (this.attMap['EINAppTC'] && this.attMap['EINAppTC'][0]) {
        const updatedData = this.updateDropdownList(this.EINMissingReason, this.attMap['EINAppTC'][0], true);
        this.EINMissingReason = updatedData.dropdownData;
        this.selectedMissingReason = this.attMap['EINAppTC'][0];
      }
      setTimeout(() => {
        this.leidComponents.toArray().forEach((element: TemplateLeidSelectorComponent, index: number) => {
          switch (index) {
            case 1:
              element.fillLEID(data.primaryleid);
              break;
            case 0:
              if (data.secondaryleid) {
                element.fillLEID(data.secondaryleid);
              }
              break;
          }
        });
      });
      this.cdref.detectChanges();
    } else if (this.sharedService.leid !== '') {
      // created as placeholder
      this.statementId = (this.trackerTabsService.getActiveTrackerTab()?.getActiveFormState().statementId!).toString();
      this.sharedService.setSavedStatementId(this.statementId);
      this.sharedService.getLoadStatementData(215).subscribe((data: StmtData) => {
        const targetCorpIsUSEntity: StmtAttrData | undefined = data.children?.find((d: StmtAttrData) => d.attributeName === 'TargetCorpUSEnt');
        if (targetCorpIsUSEntity) {
          this.TargetCorpUSEnt = targetCorpIsUSEntity.attributeValue === 'Yes';
          setTimeout(() => {
            this.targetLeidComp?.fillLEID(this.sharedService.leid);
          }, 300);
        }
      });
    }else{
      this.nameChangeChecked = true;
    }
  }

  onTransferorChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferor = selectedTransferor;
    this.headerComponent.loadConsolGroups(selectedTransferor?.LEID!,this.dcnId);
    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferor!, this.transferee!]);
    this.showDREMsg = !!this.DREMsg;

    if (this.Yes1dDateText && this.transferor?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferor.LEID,
        this.sharedService.getTransDateStr(this.Yes1dDateText),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]?.LE_NAME) {
          this.transferor!.ENTITY = response[0].LE_NAME; // override entity name
        }

          this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.Yes1dDateText) {
      this.nameChangeChecked = true;

    }

  }

  onTransfereeChanged(selectedTransferor: DSTEntityDetails | null): void {
    this.transferee = selectedTransferor;
    if (!selectedTransferor) {
      return;
    }
    (this.transferee!.EIN) && (this.selectedMissingReason = '');
    const selectedState: any = this.StateOptions[0].data?.find((s: any) => s.value === this.transferee!.STATE);
    selectedState && (this.stateNamePart3 = selectedState.text);

    if (this.Yes1dDateText && this.transferee?.LEID) {
      this.startLoader = true;
      this.sharedService.checkForEntityNameChange(this.statementId,
        this.transferee.LEID,
        this.sharedService.getTransDateStr(this.Yes1dDateText),
        this.sharedService.getActiveFormState().taxYear.toString()
      ).subscribe((response: EntityNameChange[]) => {
        if (response && response[0]?.LE_NAME) {
          this.transferee!.ENTITY = response[0].LE_NAME; // override entity name
        }
                    this.nameChangeChecked = true;
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      }).add(() => {
        this.startLoader = false;
      });
    }else if(!this.Yes1dDateText) {
      this.nameChangeChecked = true;

    }


    this.DREMsg = this.sharedService.areEntitiesDisregarded([this.transferee!, this.transferor!]);
    this.showDREMsg = !!this.DREMsg;
  }

  onFormOptionChanged(selectedOptionID: Event): void {
    this.selectedOptionID = selectedOptionID;
  }

  OnConsolGroupChanged(consolGroup: DSTConsolGroup): void {
    this.dcnId = consolGroup.DCNID;
  }

  save(): void {
    const trackerTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
    if (!trackerTab) {
      return;
    }
    const isPlaceholderChecked: boolean = trackerTab.isPlaceholderChecked;

    if (!this.isFormValid(isPlaceholderChecked) || !this.sharedService.confirmDRE([this.transferor, this.transferee])) {
      return;
    } else {
      this.startLoader = true;
      const formState: DSTFormState = this.sharedService.getActiveFormState();
      const params: { [param: string]: string } = {
        'statementID': this.statementId !== '' ? this.statementId : '0',
        'taxYear': formState.taxYear.toString(),
        'taxYearId': formState.taxYearId.toString(),
        'dcnId': this.dcnId ? this.dcnId.toString() : '',
        'returnPeriodId': formState.returnPeriodId.toString(),
        'formId': formState.formId.toString(),
        'primaryEntityId': this.transferee ? this.transferee?.ENTITYID.toString() : '',
        'secondaryEntityId': this.transferor ? this.transferor?.ENTITYID.toString() : '',
        'branchEntityId': '',
        'comments': '',
        'csStatementId': formState.csStatementId?.toString() || '0',
        'placeHolder': isPlaceholderChecked ? '1' : '0',
        'attachmentFileName': '',
      };

      const jsonObject = {
        'TransfereeLEID': this.transferor ? this.transferor?.LEID.toString() : '',
        'TransferorLEID': this.transferee ? this.transferee?.LEID.toString() : '',
        'EINAppTC': this.selectedMissingReason.toString() || '',
        'Choice1cTarget': this.Choice1cTarget !== undefined ? this.Choice1cTarget ? 'Old' : 'New' : '',
        'Choice1dDate': this.Choice1dDate !== undefined ? this.Choice1dDate ? 'Yes' : 'No' : '',
        'Yes1dDateText': this.sharedService.getTransDateStr(this.Yes1dDateText),
        'OtherPartyUSEnt': this.OtherPartyUSEnt !== undefined ? this.OtherPartyUSEnt ? 'Yes' : 'No' : '',
        'OtherParty': this.OtherParty.toString(),
        'einSsnRadio': this.einSsnRadio !== undefined ? this.einSsnRadio ? 'ein' : 'ssn' : '',
        'EINAppOP': this.einSsnRadio ? this.EINAppOP.toString() : '',
        'SNAppOP': !this.einSsnRadio ? this.SNAppOP.toString() : '',
        'EINAppMROP': this.selectedMissingReason1.toString(),
        'AddressOfOtherParty1': this.AddressOfOtherParty1.toString(),
        'AddressOfOtherParty2': this.AddressOfOtherParty2.toString(),
        'CityOfOtherParty': this.CityOfOtherParty.toString(),
        'StateNameOfOtherParty': this.selectedState.toString(),
        'ProvOrStateOP': this.ProvOrStateOP,
        'CountryOfOP': this.selectedCountry.toString(),
        'ZIPCodeOfOtherParty': this.ZIPCodeOfOtherParty.toString(),
        'TargetCorpUSEnt': this.TargetCorpUSEnt !== undefined ? this.TargetCorpUSEnt ? 'Yes' : 'No' : '',
        'AcquisionDate': this.sharedService.getTransDateStr(this.AcquisionDate),
        'PctDuring12Month': ((this.PctDuring12Month !== '') ? (parseFloat(this.PctDuring12Month) / 100) + '' : ''),
        'PctOnAcquisition': ((this.PctOnAcquisition !== '') ? (parseFloat(this.PctOnAcquisition) / 100) + '' : ''),
        'StockPrice': this.StockPrice.toString(),
        'AcquisitionCostSC': this.AcquisitionCostSC.toString(),
        'TargetLiabilities': this.TargetLiabilities.toString(),
        'AGUBorADSP': this.AGUBorADSP.toString(),
        'Choice6': this.Choice6 !== undefined ? this.Choice6 ? 'Yes' : 'No' : '',
        'Choice7': this.Choice7 !== undefined ? this.Choice7 ? 'Yes' : 'No' : '',
        'Choice8a': this.Choice8a !== undefined ? this.Choice8a ? 'Yes' : 'No' : '',
        'Choice8b': this.Choice8b !== undefined ? this.Choice8b ? 'Yes' : 'No' : '',
        'Choice8c': this.Choice8c !== undefined ? this.Choice8c ? 'Yes' : 'No' : '',
        'Choice8e': this.Choice8e !== undefined ? this.Choice8e ? 'Yes' : 'No' : '',
        'Choice8f': this.Choice8f !== undefined ? this.Choice8f ? 'Yes' : 'No' : '',
        'Choice8g': this.Choice8g !== undefined ? this.Choice8g ? 'Yes' : 'No' : '',
        'Choice8h': this.Choice8h !== undefined ? this.Choice8h ? 'Yes' : 'No' : '',
        'IsCFCPrev5Yrs': this.IsCFCPrev5Yrs ? 'Yes' : 'No',
        'Class1AFMV': this.Class1AFMV?.toString(),
        'Class1AGUBOrADSP': this.Class1AGUBOrADSP?.toString(),
        'Class2AFMV': this.Class2AFMV?.toString(),
        'Class2AGUBOrADSP': this.Class2AGUBOrADSP?.toString(),
        'Class3AFMV': this.Class3AFMV?.toString(),
        'Class3AGUBOrADSP': this.Class3AGUBOrADSP?.toString(),
        'Class4AFMV': this.Class4AFMV?.toString(),
        'Class4AGUBOrADSP': this.Class4AGUBOrADSP?.toString(),
        'Class5AFMV': this.Class5AFMV?.toString(),
        'Class5AGUBOrADSP': this.Class5AGUBOrADSP?.toString(),
        'Class6N7AFMV': this.Class6N7AFMV?.toString(),
        'Class6N7AGUBOrADSP': this.Class6N7AGUBOrADSP?.toString(),
        'TotalAFMV': this.TotalAFMV?.toString(),
        'TotalAGUB': this.TotalAGUB?.toString(),
        'TaxYear': this.TaxYear.toString(),
        'TaxReturnFormNumber': this.TaxReturnFormNumber.toString(),
        'RsnForIncOrDec': this.RsnForIncOrDec.toString(),
        'Class1ASPPR': this.Class1ASPPR?.toString(),
        'Class1RtnAllAGUB': this.Class1RtnAllAGUB?.toString(),
        'Class2ASPPR': this.Class2ASPPR?.toString(),
        'Class2RtnAllAGUB': this.Class2RtnAllAGUB?.toString(),
        'Class3ASPPR': this.Class3ASPPR?.toString(),
        'Class3RtnAllAGUB': this.Class3RtnAllAGUB?.toString(),
        'Class4ASPPR': this.Class4ASPPR?.toString(),
        'Class4RtnAllAGUB': this.Class4RtnAllAGUB?.toString(),
        'Class5ASPPR': this.Class5ASPPR?.toString(),
        'Class5RtnAllAGUB': this.Class5RtnAllAGUB?.toString(),
        'Class6N7ASPPR': this.Class6N7ASPPR?.toString(),
        'Class6N7RtnAllAGUB': this.Class6N7RtnAllAGUB?.toString(),
        'TotalASPPR': this.TotalASPPR?.toString(),
        'TotalRtnAllAGUB': this.TotalRtnAllAGUB?.toString(),
        'Class1IncDec': this.Class1IncDec?.toString(),
        'Class2IncDec': this.Class2IncDec?.toString(),
        'Class3IncDec': this.Class3IncDec?.toString(),
        'Class4IncDec': this.Class4IncDec?.toString(),
        'Class5IncDec': this.Class5IncDec?.toString(),
        'Class6N7IncDec': this.Class6N7IncDec?.toString(),
      };
      this.sharedService.saveStatemenet(params, jsonObject).subscribe((data: any) => {
        if (data) {
          this.dstSharedService.showAlert('success', 'Form Save Success!');

          this.startLoader = false;
          this.sharedService.setSavedStatementId(data.statementId);
          this.sharedService.switchTemplateMode(true);
          this.onSave.emit(); // emit without payload -- only notifies tracker to refresh grid
        }
      }, () => {
        this.dstSharedService.showAlert('error', 'Error!');
        this.startLoader = false;

      });
    }
  }

  clear(): void {
    if (this.statementId !== '' && this.statementId !== '0') {
      this.sharedService.switchTemplateMode(true);
    } else {
      this.onCancel.emit();
    }
  }

  onDPOpen(): void { // styling fix for gtw-ui
    const modalEle: Element | null = document.querySelector('ngx-daterangepicker-material');
    modalEle?.classList.add('gtw-web-components');
  }

  check1c(val: boolean): void {
    this.Choice1cTarget = val;
  }

  check1d(val: boolean): void {
    this.Choice1dDate = val;
  }

  check2(val: boolean): void {
    this.OtherPartyUSEnt = val;
  }

  check2b(val: boolean): void {
    this.einSsnRadio = val;
  }

  check3(val: boolean): void {
    this.TargetCorpUSEnt = val;
  }

  check6(val: boolean): void {
    this.Choice6 = val;
  }

  check7(val: boolean): void {
    this.Choice7 = val;
  }

  check8a(val: boolean): void {
    this.Choice8a = val;
  }

  check8b(val: boolean): void {
    this.Choice8b = val;
  }

  check8c(val: boolean): void {
    this.Choice8c = val;
  }

  check8e(val: boolean): void {
    this.Choice8e = val;
  }

  check8f(val: boolean): void {
    this.Choice8f = val;
  }

  check8g(val: boolean): void {
    this.Choice8g = val;
  }

  check8h(val: boolean): void {
    this.Choice8h = val;
  }

  check8abox(event: Event): void {
    this.IsCFCPrev5Yrs = !this.IsCFCPrev5Yrs;
  }

  dateChange(event: any, index: number) {
    switch (index) {
      case 1:
        this.Yes1dDateText = event.startDate.format('YYYY-MM-DD');
        const leids: string = this.sharedService.concatLEIDs([this.transferee!, this.transferor!]);
        if (!leids) {
          break;
        }
        this.startLoader = true;
        this.sharedService.checkForEntityNameChange(this.statementId,
          leids,
          this.sharedService.getTransDateStr(this.Yes1dDateText),
          this.sharedService.getActiveFormState().taxYear.toString()
        ).subscribe((response: EntityNameChange[]) => {
          if (response) {
            for (let i = 0; i < leids.split(',').length; i++) {
              this.sharedService.updateEntityName(response[i].LEID,
                response[i].LE_NAME, [this.transferee!, this.transferor!], response[i].TAXTYPECODE);
            }
          }
          this.nameChangeChecked = true;
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.dstSharedService.showAlert('error', error.message);
        }).add(() => {
          this.startLoader = false;
        });

        break;

      case 2:
        this.AcquisionDate = event.startDate.format('YYYY-MM-DD');
        break;

      default:
        break;
    }
  }

  missingReason1(event: any) {
    this.selectedMissingReason1 = event.selected[0].value;
  }

  missingReason(event: any) {
    this.selectedMissingReason = event.selected[0].value;
  }

  stateChange(event: any) {
    this.selectedState = event.selected[0].value;
  }

  countryChange(event: any) {
    this.selectedCountry = event.selected[0].value;
  }

  updateValue(event: FormFieldType, index: number) {
    switch (index) {
      case 1:
        this.Class1AFMV = this.Class1AGUBOrADSP = event.value;
        break;
      case 2:
        this.Class2AFMV = this.Class2AGUBOrADSP = event.value;
        break;
      case 3:
        this.Class3AFMV = this.Class3AGUBOrADSP = event.value;
        break;
      case 4:
        this.Class4AFMV = this.Class4AGUBOrADSP = event.value;
        break;
      case 5:
        this.Class5AFMV = this.Class5AGUBOrADSP = event.value;
        break;
      case 6:
        this.Class6N7AFMV = this.Class6N7AGUBOrADSP = event.value;
        break;
      case 7:
        this.Class1AGUBOrADSP = event.value;
        break;
      case 8:
        this.Class2AGUBOrADSP = event.value;
        break;
      case 9:
        this.Class3AGUBOrADSP = event.value;
        break;
      case 10:
        this.Class4AGUBOrADSP = event.value;
        break;
      case 11:
        this.Class5AGUBOrADSP = event.value;
        break;
      case 12:
        this.Class6N7AGUBOrADSP = event.value;
        break;
      default:

        break;
    }
    this.TotalAFMV = [
      this.Class1AFMV,
      this.Class2AFMV,
      this.Class3AFMV,
      this.Class4AFMV,
      this.Class5AFMV,
      this.Class6N7AFMV
    ].reduce(this.aggregateStrings);

    this.TotalAGUB = [
      this.Class1AGUBOrADSP,
      this.Class2AGUBOrADSP,
      this.Class3AGUBOrADSP,
      this.Class4AGUBOrADSP,
      this.Class5AGUBOrADSP,
      this.Class6N7AGUBOrADSP
    ].reduce(this.aggregateStrings);
  }

  private aggregateStrings(a: string,b: string): string{
    const floatA =parseFloat(a);
    const floatB =parseFloat(b);
    const sum = ((isNaN(floatA) ? 0: floatA) + (isNaN(floatB) ? 0: floatB));
    return  sum === 0 ? '' : sum.toString();
  }

  updateValue2(event: FormFieldType, index: number) {
    switch (index) {
      case 1:
        this.Class1ASPPR = event.value;
        this.Class1RtnAllAGUB = this.aggregateStrings(this.Class1ASPPR,this.Class1IncDec);
        break;
      case 2:
        this.Class2ASPPR = event.value;
        this.Class2RtnAllAGUB = this.aggregateStrings(this.Class2ASPPR,this.Class2IncDec);
        break;
      case 3:
        this.Class3ASPPR = event.value;
        this.Class3RtnAllAGUB = this.aggregateStrings(this.Class3ASPPR,this.Class3IncDec);
        break;
      case 4:
        this.Class4ASPPR = event.value;
        this.Class4RtnAllAGUB = this.aggregateStrings(this.Class4ASPPR,this.Class4IncDec);
        break;
      case 5:
        this.Class5ASPPR = event.value;
        this.Class5RtnAllAGUB = this.aggregateStrings(this.Class5ASPPR,this.Class5IncDec);
        break;
      case 6:
        this.Class6N7ASPPR = event.value;
        this.Class6N7RtnAllAGUB = this.aggregateStrings(this.Class6N7ASPPR,this.Class6N7IncDec);
        break;
      case 7:
        this.Class1RtnAllAGUB = event.value;
        break;
      case 8:
        this.Class2RtnAllAGUB = event.value;
        break;
      case 9:
        this.Class3RtnAllAGUB = event.value;
        break;
      case 10:
        this.Class4RtnAllAGUB = event.value;
        break;
      case 11:
        this.Class5RtnAllAGUB = event.value;
        break;
      case 12:
        this.Class6N7RtnAllAGUB = event.value;
        break;

      case 13:
        this.Class1IncDec = event.value;
        this.Class1RtnAllAGUB = this.aggregateStrings(this.Class1ASPPR,this.Class1IncDec);
        break;
      case 14:
        this.Class2IncDec = event.value;
        this.Class2RtnAllAGUB = this.aggregateStrings(this.Class2ASPPR,this.Class2IncDec);
        break;
      case 15:
        this.Class3IncDec = event.value;
        this.Class3RtnAllAGUB = this.aggregateStrings(this.Class3ASPPR,this.Class3IncDec);
        break;
      case 16:
        this.Class4IncDec = event.value;
        this.Class4RtnAllAGUB = this.aggregateStrings(this.Class4ASPPR,this.Class4IncDec);
        break;
      case 17:
        this.Class5IncDec = event.value;
        this.Class5RtnAllAGUB = this.aggregateStrings(this.Class5ASPPR,this.Class5IncDec);
        break;
      case 18:
        this.Class6N7IncDec = event.value;
        this.Class6N7RtnAllAGUB = this.aggregateStrings(this.Class6N7ASPPR,this.Class6N7IncDec);
        break;


      default:

        break;
    }

    this.TotalASPPR = [
      this.Class1ASPPR,
      this.Class2ASPPR,
      this.Class3ASPPR,
      this.Class4ASPPR,
      this.Class5ASPPR,
      this.Class6N7ASPPR
    ].reduce(this.aggregateStrings);

    this.TotalRtnAllAGUB = [
      this.Class1RtnAllAGUB,
      this.Class2RtnAllAGUB,
      this.Class3RtnAllAGUB,
      this.Class4RtnAllAGUB,
      this.Class5RtnAllAGUB,
      this.Class6N7RtnAllAGUB
    ].reduce(this.aggregateStrings);
  }

  isFormValid(placehodler: boolean): boolean {
    if (placehodler) {
      if (this.TargetCorpUSEnt === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Target question');
        return false;
      }
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify the LEID of Target in Part III Line 3a');
        return false;
      }
      return true;
    } else {
      if (!this.transferor) {
        this.dstSharedService.showAlert('error', 'Please specify a LEID in Part 1 Line 1a');
        return false;
      }
      if (this.Choice1cTarget === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 1c');
        return false;
      }
      if (this.Choice1dDate === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 1d');
        return false;
      }
      if (this.Choice1dDate && (this.Yes1dDateText === '' || this.Yes1dDateText === null)) {
        this.dstSharedService.showAlert('error', 'Please enter the date Form 8023 was filed.');
        return false;
      }
      if (this.OtherPartyUSEnt === undefined) {
        this.dstSharedService.showAlert('error', 'Please make a selection in Part Two');
        return false;
      }
      if (this.OtherParty === '') {
        this.dstSharedService.showAlert('error', 'Please complete Part II Line 2a');
        return false;
      }
      if (this.SNAppOP === '' && this.EINAppOP === '' && this.selectedMissingReason1 == '') {
        this.dstSharedService.showAlert('error', 'Please select the EIN/SSN Missing reason or provide EIN/SSN');
        return false;
      }
      if(this.OtherPartyUSEnt){
        if(this.EINAppOP !== '' && !this.isValidNumber(this.EINAppOP)){
          this.dstSharedService.showAlert('error', 'Please enter Valid EIN');
          return false;
        }
        if(this.einSsnRadio && this.EINAppOP !== '' && this.EINAppOP.length !== 9){
          this.dstSharedService.showAlert('error', 'Please enter 9 digits EIN Number');
          return false;
        }
      }

      if (this.AddressOfOtherParty1 === '') {
        this.dstSharedService.showAlert('error', 'Please enter Address of the Other Party to the Transaction');
        return false;
      }
      if (this.CityOfOtherParty === '') {
        this.dstSharedService.showAlert('error', 'Please enter the City of the Other Party to the Transaction');
        return false;
      }
      if (this.ZIPCodeOfOtherParty === '') {
        this.dstSharedService.showAlert('error', 'Please enter the Zip Code of the Other Party to the Transaction');
        return false;
      }
      if (this.OtherPartyUSEnt && this.selectedState === -1) {
        this.dstSharedService.showAlert('error', 'Please enter the State of the Other Party to the Transaction');
        return false;
      }
      if (!this.OtherPartyUSEnt && this.selectedCountry === -1) {
        this.dstSharedService.showAlert('error', 'Please select the Country of the Other Party to the Transaction');
        return false;
      }
      if (this.TargetCorpUSEnt === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Target question');
        return false;
      }
      if (!this.transferee) {
        this.dstSharedService.showAlert('error', 'Please specify the LEID of Target in Part III Line 3a');
        return false;
      }
      if (this.transferee && !this.transferee.EIN && this.selectedMissingReason == '') {
        this.dstSharedService.showAlert('error', 'Please complete Part III Line 3b.');
        return false;
      }

      if (this.AcquisionDate === '' || this.AcquisionDate === null) {
        this.dstSharedService.showAlert('error', 'Please complete Line 4a - Acquisition Date');
        return false;
      }
      if (this.PctDuring12Month === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 4b');
        return false;
      }
      if (this.PctOnAcquisition === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 4b');
        return false;
      }
      if (this.StockPrice === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 5a');
        return false;
      }
      if (this.AcquisitionCostSC === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 5b');
        return false;
      }
      if (this.TargetLiabilities === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 5c');
        return false;
      }
      if (this.AGUBorADSP === '') {
        this.dstSharedService.showAlert('error', 'Please complete Line 5d');
        return false;
      }
      if (this.Choice6 === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 6');
        return false;
      }
      if (this.Choice7 === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 7');
        return false;
      }
      if (this.Choice8a === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8a');
        return false;
      }
      if (this.Choice8b === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8b');
        return false;
      }
      if (this.Choice8c === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8c');
        return false;
      }
      if (this.Choice8e === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8e');
        return false;
      }
      if (this.Choice8f === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8f');
        return false;
      }
      if (this.Choice8g === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8g');
        return false;
      }
      if (this.Choice8g && this.Choice8h === undefined) {
        this.dstSharedService.showAlert('error', 'Please complete Line 8h');
        return false;
      }

      if (this.RsnForIncOrDec === '' && this.TotalASPPR !== '' && this.TotalRtnAllAGUB !== '') {
        this.dstSharedService.showAlert('error', 'Please write the reason for increase or decrease');
        return false;
      }

      if (this.TotalAGUB !== '' && this.TotalAGUB.toString() !== this.AGUBorADSP.toString()) {
        this.dstSharedService.showAlert('error', 'Line 9 Total does not agree with Line 5d AGUB/ADSP');
        return false;
      }

      if (this.TotalRtnAllAGUB !== '' && this.TotalRtnAllAGUB.toString() !== this.AGUBorADSP.toString()) {
        this.dstSharedService.showAlert('error', 'Line 11 Total does not agree with Line 5d AGUB/ADSP');
        return false;
      }
      return true;
    }
  }

  isValidNumber(input: string) {
    const regex = /^\d+$/;
    input = input+'';
    if(regex.test(input)){
      return true;
    }
    else return false;
  }
}

export interface AppkitDropdownOptions {
  data?: (DataEntity)[] | null;
}

export interface DataEntity {
  value: number | string;
  text: string;
  index?: number;
}

