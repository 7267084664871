<div class="submit-container">
  <ul class="list-unstyled mt-3 mb-4">
    <li>
      <ap-radio
        [value]="TEST"
        [text]="TEST"
        [checked]="true"
        name="listName"
        class="col-md-6 submission-radio"
        (onCheck)="envSelected('TEST')"
      ></ap-radio>
    </li>
    <li>
      <ap-radio
        [value]="PRODUCTION"
        [text]="PRODUCTION"
        name="listName"
        class="col-md-6 submission-radio"
        (onCheck)="envSelected('PRODUCTION')"
      ></ap-radio>
    </li>
  </ul>
  <div class="buttons">
    <ap-button
      [btnText]="btnSubmit"
      class="a-mt-10"
      [config]="primaryConfig"
      [btnSize]="'large'"
      (onBtnClick)="submit()"
    >
    <span class="a-loading a-secondary" *ngIf="submitting"></span>
    </ap-button>
    {{submission.SUBMISSION_ID}}
    <ap-button
      [btnText]="btnPingText"
      class="a-mt-10 a-ml-10"
      [config]="secondaryConfig"
      (onBtnClick)="pingIRS()"
      [btnSize]="'large'"
    >
    <span class="a-loading a-primary" *ngIf="pinging"></span>
    </ap-button>
    <ap-button
      [btnText]="btnAck"
      class="a-mt-10 a-ml-10"
      [config]="secondaryConfig"
      (onBtnClick)="pollAck()"
      [btnSize]="'large'"
    >
    <span class="a-loading a-primary" *ngIf="acking"></span>
    </ap-button>
  </div>
</div>
