import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EFileService } from "../efile.service";
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';
import { Submission } from "../models/submission.model";
import { TableTitle } from "../models/table.model";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { IfStmt } from "@angular/compiler";
import { tableColumnConfig,taxYears } from "./dataStub";
import { SubmissionService } from "./submission.service";
import { GtwSnackbarService } from "../../shared/_services/gtw-snackbar.service";

@Component({
  selector: 'efile-submission',
  templateUrl: './submission-data.component.html',
  styleUrls: ['./submission-data.component.scss']
})
export class SubmissionDataComponent implements OnInit,OnDestroy {

  selectedSubmissions: Submission[];
  baseURLs: GtwElementsBaseUrlsModel = {
    api: '/gtw',
    custom: '/custom',
    admin: '/admin',
    efile:'/gtw-efile-api',
    pdfEngine: '/pdf-engine',
    bulkPdfEngine: '/bulk-pdf-engine'
  };
  isDataLoaded: boolean = false;
  page: number = 1;
  pageSize: number = 7;
  tableSorting: boolean = false;
  tableSearch: string = '';
  total: number = 19;
  taxYear: string = "2020";
  startLoader: boolean = false;
  submissions: Submission[] = [];
  submissionsSafeCopy: Submission[] = [];
  primaryConfig = {
    isPrimary: true
  }
  tableColumnConfig:TableTitle[] = tableColumnConfig;
  taxYears = taxYears;
  $subscription!: Subscription;
  selectedTab: number = 0;

  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let urls: GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
      if (Object.keys(urls).length > 0) {
        this.baseURLs = urls;
        this.efileService.setBaseUrls(this.baseURLs);
        this.loadSubmissions();
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Input('client-key')
  clientKey: number = 0;

  constructor(private efileService: EFileService,
    private submissionService: SubmissionService,
    private gtwSnackbarService:GtwSnackbarService) { 
      this.selectedSubmissions = submissionService.submissionTabs;
    }
  
  ngOnInit(): void { 

    
  }


  taxYearSelected(event: any): void {
    console.log(event);
    this.taxYear = event.change.value;
  }
  filterClick(): void {
    this.loadSubmissions();
  }

  



  loadSubmissions(): void {
    this.startLoader = true;
    this.isDataLoaded = false
    this.$subscription = this.efileService.getSubmissionData(
      this.clientKey,
      this.taxYear).subscribe(
        (data: Submission[]) => this.resolveSubmissions(data),
        (error: HttpErrorResponse) => this.handleError(error));
  }


  resolveSubmissions(data: Submission[]) {
    this.submissions = data;
    this.submissions.forEach((submission: Submission, index: number) => {
      submission.ICON = 'icon-hb-full';
      // submission.ErrorCount = index % 2 === 0 ? 0 : Math.floor(Math.random() * (10 - 0 + 1)) + 0;
      // submission.Diagnostics = index % 2 === 1 ? 0 : Math.floor(Math.random() * (10 - 0 + 1)) + 0;
    });

    this.submissionsSafeCopy = [...this.submissions];
    this.pageSize = this.submissions.length;
    this.isDataLoaded = true;
    this.startLoader = false;
  }

  handleError(error: HttpErrorResponse): void {
    this.isDataLoaded = true;
    this.startLoader = false;
    this.gtwSnackbarService.openSnackBar("Failed to load submissions!","gtw-snackbar--red");
  }


  tableSearchFun(event: { query: string }): void {
    this.page = 1;
    this.tableSearch = event.query;
    this.submissions = this.filterBase(this.submissionsSafeCopy, this.tableSearch);
  }

  filterBase(data: Submission[], tableSearch: string): Submission[] {
    const returnData = data.filter((item: Submission | any) => {
      const keys = Object.keys(item);
      return keys.some(key => {
        let itemKey = item[key];
        if (itemKey === undefined) {
          return false;
        }
        if (typeof (item[key]) !== 'string') {
          itemKey = item[key].toString();
        }

        return itemKey.toLowerCase().indexOf(tableSearch.toLowerCase()) !== -1;
      })
    });
    return returnData;
  }
  switchToTab(item: Submission){
    this.selectedTab = this.submissionService.activateSubmission(item);
    
    item.SHOW_DROPDOWN_MENU = false;
  }

  selectedTabChange($event: number){
    this.selectedTab = $event;

    this.submissionService.activateSubmissionByIndex($event);
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

}