<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>

<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar">
</dst-create-disclosure-actions>

<div class="form-template-wrapper" *ngIf="loaded">
  <div align=center>
    <b>
      <span class="template-color-blue">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>

  <div align="center">
    <B>
      <span class="template-color-blue">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br>
        Form 926 - Return by a U.S. Transferor of Property to a Foreign Corporation
        <br>
      </span>
    </B>
  </div>
  <br>

  <div [formGroup]="form214">
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I &nbsp;&nbsp;U.S. Transferor Information</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="section-content" style="margin-left: 26px;">
      <div class="ap-form-field-my-wrapper" style="margin-left: 41px;">
        <div class="entity-details">
          <div class="col-md-3">
            <span>Name of Transferor:</span>
          </div>
          <div class="col-md-4" style="padding-left: 5px;">
            <span class="template-color-blue">{{stmtData.primaryentity == null? '':
              stmtData.primaryentity}}</span>
          </div>
        </div>

        <div class="entity-details">
          <div class="col-md-3">
            <span>Identifying number:</span>
          </div>
          <div class="col-md-4" style="padding-left: 5px;">
            <span class="template-color-blue"> {{stmtData.primaryein == null ? 'N/A' : stmtData.primaryein}}</span>
          </div>
        </div>

        <div class="entity-details" *ngIf="!stmtData.primaryein">
          <div class="col-md-3">
            <span>EIN Missing Reason:</span>
          </div>
          <div class="col-md-4" style="padding-left: 5px;">
            <span class="template-color-blue"> {{attMap['TransferorEinMissingReason'] && attMap['TransferorEinMissingReason'][0] ? attMap['TransferorEinMissingReason'][0]: ''}}</span>
          </div>
        </div>
      </div>

      <ol class="main-section">
        <!-- 1 -->
        <li class="pb-0">
          <div class="my-form-row">
            <div class="row-label">
              Is the transferee a specified 10%-owned foreign corporation that is not a controlled foreign corporation?
              <span class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'"
                [isWhite]="true"></span>
            </div>
            <div class="row-content">
              <ap-radio [name]="'radio-group-1'" [text]="'Yes'" [checked]="attMap['transfereeForeignCorp'] && attMap['transfereeForeignCorp'][0] == '1'"
                class="a-mx-10 radio-font" [disabled]="true" style="margin-left: 5px !important;">
              </ap-radio>
              <ap-radio [name]="'radio-group-1'" [text]="'No'" [checked]="attMap['transfereeForeignCorp'] && attMap['transfereeForeignCorp'][0] == '0'"
                class="a-mx-10 radio-font" [disabled]="true">
              </ap-radio>
            </div>
          </div>
        </li>
        <!-- 2 -->
        <li class="pb-0 pt-1">
          <div class="my-form-row">
            <div class="row-label">
              If the transferor was a corporation, complete questions 2a through 2d.
            </div>
          </div>
          <ol class="inner-list-alp">
            <!-- 2a -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  If the transfer was a section 361(a) or (b) transfer, was the transferor controlled (under section
                  368(c)) by 5 or fewer domestic corporations? <span class="appkiticon icon-information-fill"
                    [tooltip-container]="template2" [placement]="'right'" [isWhite]="true"></span>
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-2'" [text]="'Yes'"
                    [checked]="attMap['Choice1a'] != undefined && attMap['Choice1a'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-2'" [text]="'No'"
                    [checked]="attMap['Choice1a'] != undefined && attMap['Choice1a'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- 2b -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  Did the transferor remain in existence after the transfer?
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-3'" [text]="'Yes'"
                    [checked]="attMap['Choice1b'] != undefined && attMap['Choice1b'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-3'" [text]="'No'"
                    [checked]="attMap['Choice1b'] != undefined && attMap['Choice1b'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
              <div *ngIf="choice1b" class="section-content pt-1 p-0">
                If not, list the controlling shareholder(s) and their identifying number(s):
                <div class="mt-0 ap-form-field-my-wrapper">
                  <div class="entity-details">
                    <div class="col-md-3">
                      <span><strong>Controlling shareholder</strong></span>
                    </div>
                    <div class="col-md-3">
                      <span><strong>Identifying number</strong></span>
                    </div>
                    <div class="col-md-3" *ngIf="attMap['sHolderEin'] && !attMap['sHolderEin'][0]">
                      <span><strong>EIN Missing Reason</strong></span>
                    </div>
                  </div>

                  <div class="entity-details">
                    <div class="col-md-3">
                      <span class="template-color-blue">{{attMap['sHolderName'] == undefined ? '' :
                        attMap['sHolderName'][0]}} </span>
                    </div>
                    <div class="col-md-3">
                      <span class="template-color-blue"> {{attMap['sHolderEin'] == undefined ? '' :
                        (attMap['sHolderEin'][0] ? attMap['sHolderEin'][0]: 'N/A')}}
                        &nbsp;&nbsp; {{identifyingType(attMap['EinOrSsn'] == undefined ? '' : attMap['EinOrSsn'][0])}}
                      </span>
                    </div>
                    <div class="col-md-3" *ngIf="attMap['sHolderEin'] && !attMap['sHolderEin'][0]">
                      <span class="template-color-blue">{{attMap['ShareholderEinMissingReason'] == undefined ? '' :
                        attMap['ShareholderEinMissingReason'][0]}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 2c -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  If the transferor was a member of an affiliated group filing a consolidated return, was it the parent
                  corporation?
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-4'" [text]="'Yes'"
                    [checked]="attMap['Choice1c'] != undefined && attMap['Choice1c'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-4'" [text]="'No'"
                    [checked]="attMap['Choice1c'] != undefined && attMap['Choice1c'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>

              <div *ngIf="showParentCorporation" class="section-content pl-0 pt-1">
                If not, list the name of the employer identification number (EIN) of the parent corporation:
                <div class="row mt-3" style="margin-left: 7px">
                  <div class="col-md-3">
                    Name of Parent Corporation
                  </div>
                  <div class="col-md-4 pl-0">
                    <span class="template-color-blue"> {{dcnName}} </span>
                  </div>
                </div>
                <div class="row mt-1" style="margin-left: 7px">
                  <div class="col-md-3">
                    EIN of Parent Corporation
                  </div>
                  <div class="col-md-4 pl-0">
                    <span class="template-color-blue"> {{dcnIDNum}} </span>
                  </div>
                </div>
              </div>
            </li>
            <!-- 2d -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  Have basis adjustments under section 367(a)(4) been made? <span
                    class="appkiticon icon-information-fill" [tooltip-container]="template3" [placement]="'right'"
                    [isWhite]="true"></span>
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-5'" [text]="'Yes'"
                    [checked]="attMap['Choice1d'] != undefined && attMap['Choice1d'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-5'" [text]="'No'"
                    [checked]="attMap['Choice1d'] != undefined && attMap['Choice1d'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
            </li>
          </ol>
        </li>
        <!-- 3 -->
        <li class="pb-0">
          <div class="my-form-row">
            <div class="row-label">
              If the transferor was a partner in a partnership that was the actual transferor (but is not treated as such
              under section 367), complete questions 3a through 3d.
            </div>
          </div>
          <ol class="inner-list-alp">
            <!-- 3a -->
            <li class="pt-1 pl-0 pb-0">
              <div class="my-form-row-p1 pl-0 pt-2 pb-2" style="flex-direction: column;">
                <div class="entity-details">
                  <span class="col-md-4 pl-0">Name of Partnership:</span>
                  <span class="col-md-8 pl-0 template-color-blue"> {{pshipName}}</span>
                </div>
                <div class="entity-details">
                  <span class="col-md-4 pl-0">EIN of Partnership:</span>
                  <span class="col-md-4 pl-0 template-color-blue"> {{pshipEin ? pshipEin : 'N/A'}} </span>
                </div>
                <div class="entity-details" *ngIf="!pshipEin">
                  <span class="col-md-4 pl-0">EIN Missing Reason:</span>
                  <span class="col-md-4 pl-0 template-color-blue">{{attMap['PartnershipEinMissingReason'] == undefined ? '' :
                    attMap['PartnershipEinMissingReason'][0]}} </span>
                </div>
              </div>
            </li>
            <!-- 3b -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  Did the partner pick up its pro rata share of gain on the transfer of partnership assets?
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-6'" [text]="'Yes'"
                    [checked]="attMap['Choice2b'] != undefined && attMap['Choice2b'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-6'" [text]="'No'"
                    [checked]="attMap['Choice2b'] != undefined && attMap['Choice2b'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- 3c -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  Is the partner disposing of its entire interest in the partnership?
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-7'" [text]="'Yes'"
                    [checked]="attMap['Choice2c'] != undefined && attMap['Choice2c'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-7'" [text]="'No'"
                    [checked]="attMap['Choice2c'] != undefined && attMap['Choice2c'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
            </li>
            <!-- 3d -->
            <li class="pt-1 pb-0">
              <div class="my-form-row-p1">
                <div class="row-label-p1">
                  Is the partner disposing of an interest in a limited partnership that is regularly traded on an
                  established securities market?
                </div>
                <div class="row-content-p1">
                  <ap-radio [name]="'radio-group-8'" [text]="'Yes'"
                    [checked]="attMap['Choice2d'] != undefined && attMap['Choice2d'][0] == 'Yes'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                  <ap-radio [name]="'radio-group-8'" [text]="'No'"
                    [checked]="attMap['Choice2d'] != undefined && attMap['Choice2d'][0] == 'No'" class="a-mx-10 radio-font"
                    [disabled]="true">
                  </ap-radio>
                </div>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part II Transferee Foreign Corporation Information</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="section-content" style="margin-left: 26px;">
      <ol class="inner-list-dec pl-0 section-content-inner" style="counter-reset: count 3;">
        <!-- Transferee Foreign Corporation Information in gold -->
        <div *ngIf="goldAddr">
          <!-- 4 -->
          <li>
            <div class="entity-details">
              <span>Name of the transferee:</span>
              <span>
                {{stmtData.secondaryentity == null? '': stmtData.secondaryentity}}
              </span>
            </div>
          </li>
          <!-- 5 -->
          <li>
            <div class="d-inline-flex flex-column">
              <div class="entity-details">
                <span>Identifying number, if any:</span>
                <span>
                  {{stmtData.secondaryein == null? 'N/A' : stmtData.secondaryein}}
                </span>
              </div>
              <div class="entity-details" *ngIf="!stmtData.secondaryein">
                <span>EIN Missing Reason:</span>
                <span>
                  {{stmtData.secondaryein == null? attMap['EinMissingReason'][0] : ''}}
                </span>
              </div>
            </div>
          </li>
          <!-- 6 -->
          <li>
            <div class="d-inline-flex flex-column">
              <div class="entity-details">
                <span>Address Line1:</span>
                <span>
                {{stmtData.secondaryaddress1 == null? '': stmtData.secondaryaddress1}}
              </span>
              </div>
              <div class="entity-details">
                <span>Address Line2:</span>
                <span>
                {{stmtData.secondaryaddress2 == null? '': stmtData.secondaryaddress2}}
              </span>
              </div>
              <div class="entity-details">
                <span>City:</span>
                <span>{{stmtData.secondarycity == null? '': stmtData.secondarycity}}</span>
              </div>
              <div class="entity-details">
                <span>Province or State:</span>
                <span>
                {{stmtData.secondarystate == null? '': stmtData.secondarystate}}
              </span>
              </div>
              <div class="entity-details">
                <span>Country:</span>
                <span>
                {{stmtData.secondarycountryname == null? '': stmtData.secondarycountryname}}
              </span>
              </div>
              <div class="entity-details">
                <span>Postal Code:</span>
                <span>{{stmtData.secondaryzip == null? '': stmtData.secondaryzip}}</span>
              </div>
            </div>
          </li>
          <!-- 7 -->
          <li>
            <div class="entity-details">
              <span>Country of incorporation or organization:</span>
              <span>
                {{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}}
              </span>
            </div>
          </li>
          <!-- 8 -->
          <li>
            <div class="entity-details">
              <span>Foreign law characterization:</span>
              <span>
                {{line8TaxType}}
              </span>
            </div>
          </li>
        </div>
        <!-- Transferee Foreign Corporation Information not in gold -->
        <div *ngIf="manualAddr">
          <!-- 4 -->
          <li>
            <div class="entity-details">
              <span>Name of the transferee:</span>
              <span>
                {{attMap['TransfereeName'] == undefined ? '' : attMap['TransfereeName'][0]}}
              </span>
            </div>
          </li>
          <!-- 5 -->
          <li>
            <div class="d-inline-flex flex-column">
              <div class="entity-details">
                <span>Identifying number, if any:</span>
                <span>
                  {{ (attMap['TransfereeId'] == undefined || !attMap['TransfereeId'][0]) ? 'N/A': attMap['TransfereeId'][0] }}
                </span>
              </div>
              <div class="entity-details" *ngIf="attMap['TransfereeId'] && !attMap['TransfereeId'][0]">
                <span>EIN Missing Reason:</span>
                <span>
                  {{ attMap['EinMissingReason1'] == undefined ? '' : attMap['EinMissingReason1'][0] }}
                </span>
              </div>
            </div>
          </li>
          <!-- 6 -->
          <li>
            <div class="d-inline-flex flex-column">
              <div class="entity-details">
                <span>Address Line1:</span>
                <span>
                  {{attMap['AddressLine1'] == undefined ? '' : attMap['AddressLine1'][0]}}
                </span>
              </div>
              <div class="entity-details">
                <span>Address Line2:</span>
                <span>
                  {{attMap['AddressLine2'] == undefined ? '' : attMap['AddressLine2'][0]}}
                </span>
              </div>
              <div class="entity-details">
                <span>City:</span>
                <span> {{attMap['City'] == undefined ? '' : attMap['City'][0]}}</span>
              </div>
              <div class="entity-details">
                <span>Province or State:</span><span>
                  {{attMap['ProvinceOrState'] == undefined ? '' : attMap['ProvinceOrState'][0]}}
                </span>
              </div>
              <div class="entity-details">
                <span>Country:</span>
                <span>
                  {{countryValue}}
                </span>
              </div>
              <div class="entity-details">
                <span>Postal Code:</span>
                <span> {{attMap['PostalCode'] == undefined ? '' : attMap['PostalCode'][0]}}</span>
              </div>
            </div>
          </li>
          <!-- 7 -->
          <li>
            <div class="entity-details">
              <span>Country of incorporation or organization:</span>
              <span>
                {{TrCountryValue}}
              </span>
            </div>
          </li>
          <!-- 8 -->
          <li>
            <div class="entity-details">
              <span>Foreign law characterization:</span>
              <span>
                {{attMap['ForeignLawCharacterization'] == undefined ? '' : attMap['ForeignLawCharacterization'][0]}}
              </span>
            </div>
          </li>
        </div>

        <!-- 9 -->
        <li>
          <div class="my-form-row">
            <div class="pl-3">
              Is the transferee foreign corporation a controlled foreign corporation?
            </div>
            <div class="row-content pl-0">
              <ap-radio [name]="'radio-group-12'" [text]="'Yes'"
                [checked]="attMap['IsTransfereeForeignCorpCtrld'] != undefined && attMap['IsTransfereeForeignCorpCtrld'][0] == 'Yes'"
                class="a-mx-10 radio-font" [disabled]="true" style="margin-left: 5px !important;">
              </ap-radio>
              <ap-radio [name]="'radio-group-12'" [text]="'No'"
                [checked]="attMap['IsTransfereeForeignCorpCtrld'] != undefined && attMap['IsTransfereeForeignCorpCtrld'][0] == 'No'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
            </div>
          </div>
        </li>
      </ol>
    </div>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III Information Regarding Transfer of Property (see instructions)</strong>
          </td>
        </tr>

      </tbody>
    </table>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0" style="margin-top:10px;">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Section A - Cash</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row">
      <ng-container ng-if="attMap['TypeOfProperty'].length >= 1">
        <div *ngFor="let val of attMap['TypeOfProperty'];let i=index" class="propDiv">
          <div *ngIf="attMap['TypeOfProperty'][i] == 'cash'">
            <div class="propTbl">
              <table class="w-100" style="border: 0">
                <tbody>
                  <tr>
                    <td class="propTbl-sub-td">{{propertySno(i)}}</td>
                    <td class="propTbl-desc-td">Type of Property</td>
                    <td>{{selDescForProp(attMap['TypeOfProperty'][i])}}</td>
                  </tr>
                  <tr>
                    <td>a</td>
                    <td>Date of transfer</td>
                    <td>{{attMap['DateOfTransfer'] == undefined ? '' : attMap['DateOfTransfer'][i]}}</td>
                  </tr>
                  <!-- <tr>
                    <td>b</td>
                    <td>Description of property</td>
                    <td>{{attMap['DescOfPropTransferred'] == undefined ? '' : attMap['DescOfPropTransferred'][i]}}</td>
                  </tr> -->
                  <tr>
                    <td>c</td>
                    <td>
                      Fair market value on date of transfer (For
                      e-filing purposes, must be in USD)
                    </td>
                    <td>${{attMap['FMVOnDateOfTransfer'] == undefined ? '' : attMap['FMVOnDateOfTransfer'][i] == '' ? 0
                      : attMap['FMVOnDateOfTransfer'][i] | number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ol class="inner-list-dec section-content-inner ml-4" style="counter-reset: count 9; margin-left: 10px !important;">
      <!-- 10 -->
      <li>
        <div class="my-form-row">
          <div class="row-label">
            Was cash the only property transferred?<br>
            If "yes" skip the remainder of Part III and go to Part IV.
          </div>
          <div class="rol-content">
            <ap-radio [name]="'radio-group-10'" [text]="'Yes'"
              [checked]="attMap['cashonlytransfered'] != undefined && attMap['cashonlytransfered'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-10'" [text]="'No'"
              [checked]="attMap['cashonlytransfered'] != undefined && attMap['cashonlytransfered'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
    </ol>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Section B - Other Property (other than intangible property subject to section 367(d))</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row mt-2">
      <ng-container ng-if="(attMap['cashonlytransfered'] == undefined ? true :  !(attMap['cashonlytransfered'][0] == '1')) && (attMap['TypeOfProperty'].length >= 1)">
        <div *ngFor="let val of attMap['TypeOfProperty'];let i=index" class="propDiv">
          <div
            *ngIf="attMap['TypeOfProperty'][i] == 'stockssecurities' || attMap['TypeOfProperty'][i] == 'inventory' || attMap['TypeOfProperty'][i] == 'otherProp926' || attMap['TypeOfProperty'][i] == 'lossProp'">
            <div class="propTbl">
              <table class="w-100" style="border: 0;">
                <tbody>
                  <tr>
                    <td class="propTbl-sub-td">{{propertySno(i)}}</td>
                    <td class="propTbl-desc-td">Type of Property</td>
                    <td>{{selDescForProp(attMap['TypeOfProperty'][i])}}</td>
                  </tr>
                  <tr>
                    <td>a</td>
                    <td>Date of transfer</td>
                    <td>{{attMap['DateOfTransfer'] == undefined ? '' : attMap['DateOfTransfer'][i]}}</td>
                  </tr>
                  <tr>
                    <td>b</td>
                    <td>Description of property</td>
                    <td>{{attMap['DescOfPropTransferred'] == undefined ? '' : attMap['DescOfPropTransferred'][i]}}</td>
                  </tr>
                  <tr>
                    <td>c</td>
                    <td>
                      Fair market value on date of transfer (For
                      e-filing purposes, must be in USD)
                    </td>
                    <td>${{attMap['FMVOnDateOfTransfer'] == undefined ? '' : attMap['FMVOnDateOfTransfer'][i] == '' ? 0
                      : attMap['FMVOnDateOfTransfer'][i] | number}}</td>
                  </tr>
                  <tr>
                    <td>d</td>
                    <td>
                      Cost or other basis
                    </td>
                    <td>${{attMap['CostBasis'] == undefined ? '' : attMap['CostBasis'][i] == '' ? 0 :
                      attMap['CostBasis'][i] | number}}</td>
                  </tr>
                  <tr
                    *ngIf="attMap['TypeOfProperty'][i] == 'stockssecurities' || attMap['TypeOfProperty'][i] == 'inventory' || attMap['TypeOfProperty'][i] == 'otherProp926'">
                    <td>e</td>
                    <td>
                      Gain recognized on transfer
                    </td>
                    <td>${{attMap['GainTransfer'] == undefined ? '' : attMap['GainTransfer'][i] == '' ? 0 :
                      attMap['GainTransfer'][i] | number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ol class="inner-list-dec section-content" style="counter-reset: count 10; margin-left: 10px !important; margin-top: 1rem !important;"
      [hidden]="(attMap['cashonlytransfered'] == undefined ? false :  attMap['cashonlytransfered'][0] == '1')">
      <!-- 11 -->
      <li>
        <div class="my-form-row">
          <div class="row-label">
            Did the transferor transfer stock or securities subject to section 367(a) with respect to which a gain
            recognition agreement was filed?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-13'" [text]="'Yes'"
              [checked]="attMap['transferunder367a'] != undefined && attMap['transferunder367a'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-13'" [text]="'No'"
              [checked]="attMap['transferunder367a'] != undefined && attMap['transferunder367a'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 12a -->
      <li class="pt-1 li-apdix-a">
        <div class="my-form-row">
          <div class="row-label">
            Were any assets of a foreign branch (including a branch that is a foreign disregarded entity)
            transferred to a foreign corporation?<br>
            If "Yes," go to line 12b.
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-14'" [text]="'Yes'"
              [checked]="attMap['foreignassets'] != undefined && attMap['foreignassets'][0] == '1'" class="a-mx-10 radio-font" style="margin-left: 5px !important;"
                      [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-14'" [text]="'No'"
                      [checked]="attMap['foreignassets'] != undefined && attMap['foreignassets'][0] == '0'" class="a-mx-10 radio-font"
                      [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 12b -->
      <li class="pt-1 li-apdix-b" *ngIf="line12b" style="counter-reset: count 11;">
        <div class="my-form-row">
          <div class="row-label">
            Was the transferor a domestic corporation that transferred substantially all of the assets of a
            foreign branch (including a branch that is a foreign disregarded entity) to a specified 10%-owned
            foreign corporation? <span class="appkiticon icon-information-fill" [tooltip-container]="template7"
              [placement]="'right'" [isWhite]="true"></span> <br />
            If "Yes," continue to line 12c. If "No," skip lines 12c and 12d, and go to line 13.
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-20'" [text]="'Yes'"
              [checked]="attMap['domesticcorptransfer'] != undefined && attMap['domesticcorptransfer'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-20'" [text]="'No'"
              [checked]="attMap['domesticcorptransfer'] != undefined && attMap['domesticcorptransfer'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 12c -->
      <li class="pt-1 li-apdix-c" *ngIf="line12c" style="counter-reset: count 11;">
        <div class="my-form-row">
          <div class="row-label">
            Immediately after the transfer, was the domestic corporation a U.S. shareholder with respect to
            the transferee foreign corporation??<br />
            If "Yes," continue to line 12d. If "No," skip line 12d, and go to line 13.
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-21'" [text]="'Yes'"
              [checked]="attMap['transfereeshareholder'] != undefined && attMap['transfereeshareholder'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-21'" [text]="'No'"
              [checked]="attMap['transfereeshareholder'] != undefined && attMap['transfereeshareholder'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 12d -->
      <li class="pt-1 li-apdix-d" *ngIf="line12d" style="counter-reset: count 11;">
        <div class="my-form-row">
          <div class="row-label">
            Enter the transferred loss amount included in the gross income as required under section 91
          </div>
          <div class="row-content text-align-end">
            <span class="template-color-blue">
              {{attMap['transferredlossamount'] == undefined ? '' : attMap['transferredlossamount'][0]}}
            </span>
          </div>
        </div>
      </li>
      <!-- 13 -->
      <li class="pt-1">
        <div class="my-form-row">
          <div class="row-label">
            Did the transferor transfer property described in section 367(d)(4)?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-15'" [text]="'Yes'"
              [checked]="attMap['intangiblepropsec936h'] != undefined && attMap['intangiblepropsec936h'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-15'" [text]="'No'"
              [checked]="attMap['intangiblepropsec936h'] != undefined && attMap['intangiblepropsec936h'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
    </ol>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Section C - Intangible property Subject to Section 367(d)</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row mt-2">
      <ng-container ng-if="(attMap['cashonlytransfered'] == undefined ? true :  !(attMap['cashonlytransfered'][0] == '1')) && (attMap['TypeOfProperty'].length >= 1)">
        <div *ngFor="let val of attMap['TypeOfProperty'];let i=index" class="propDiv">
          <div *ngIf="attMap['TypeOfProperty'][i] == 'propdescribed367d4'">
            <div class="propTbl">
              <table class="w-100" style="border: 0;">
                <tbody>
                  <tr>
                    <td class="propTbl-sub-td">{{propertySno(i)}}</td>
                    <td class="propTbl-desc-td">Type of Property</td>
                    <td>{{selDescForProp(attMap['TypeOfProperty'][i])}}</td>
                  </tr>
                  <tr>
                    <td>a</td>
                    <td>Date of transfer</td>
                    <td>{{attMap['DateOfTransfer'] == undefined ? '' : attMap['DateOfTransfer'][i]}}</td>
                  </tr>
                  <tr>
                    <td>b</td>
                    <td>Description of property</td>
                    <td>{{attMap['DescOfPropTransferred'] == undefined ? '' : attMap['DescOfPropTransferred'][i]}}</td>
                  </tr>
                  <tr>
                    <td>c</td>
                    <td>
                      Useful life
                    </td>
                    <td>${{attMap['usefulLife'] == undefined ? '' : attMap['usefulLife'][i] == '' ? 0 :
                      attMap['usefulLife'][i] | number}}</td>
                  </tr>
                  <tr>
                    <td>d</td>
                    <td>
                      Arm's length price on date of transfer
                    </td>
                    <td>${{attMap['ArmsOnDateOfTransfer'] == undefined ? '' : attMap['ArmsOnDateOfTransfer'][i] == '' ?
                      0 : attMap['ArmsOnDateOfTransfer'][i] | number}}</td>
                  </tr>
                  <tr>
                    <td>e</td>
                    <td>
                      Cost or other basis
                    </td>
                    <td>${{attMap['CostOtherBasis'] == undefined ? '' : attMap['CostOtherBasis'][i] == '' ? 0 :
                      attMap['CostOtherBasis'][i] | number}}</td>
                  </tr>
                  <tr>
                    <td>f</td>
                    <td>
                      Income inclusion for year of transfer
                    </td>
                    <td>${{attMap['IncomeInclusionOfTransfer'] == undefined ? '' :
                      attMap['IncomeInclusionOfTransfer'][i] == '' ? 0 : attMap['IncomeInclusionOfTransfer'][i] |
                      number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ol class="inner-list-dec section-content" style="margin-left: 10px !important; margin-top: 1rem !important;"
      [hidden]="(attMap['cashonlytransfered'] == undefined ? false :  attMap['cashonlytransfered'][0] == '1')">
      <!-- 14a -->
      <li class="li-apdix-a" style="counter-reset: count 13;">
        <div class="my-form-row">
          <div class="row-label">
            Did the transferor transfer any intangible property that, at the time of transfer, had a useful
            life
            reasonably anticipated to exceed 20 years?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-16'" [text]="'Yes'"
              [checked]="attMap['intangibleusefullife'] != undefined && attMap['intangibleusefullife'][0] == '1'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-16'" [text]="'No'"
              [checked]="attMap['intangibleusefullife'] != undefined && attMap['intangibleusefullife'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 14b -->
      <li class="pt-1 li-apdix-b" style="counter-reset: count 13;">
        <div class="my-form-row">
          <div class="row-label">
            At the time of the transfer, did any of the transferred intangible property have an indefinite
            useful life?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-18'" [text]="'Yes'"
              [checked]="attMap['indefiniteusefullife'] != undefined && attMap['indefiniteusefullife'][0] == '1'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-18'" [text]="'No'"
              [checked]="attMap['indefiniteusefullife'] != undefined && attMap['indefiniteusefullife'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 14c -->
      <li class="pt-1 li-apdix-c" style="counter-reset: count 13;">
        <div class="my-form-row">
          <div class="row-label">
            Did the transferor choose to apply the 20-year inclusion period provided under Regulations section
            1.367(d)-1(c)(3)(ii) for any intangible property? <span class="appkiticon icon-information-fill"
              [tooltip-container]="template8" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-19'" [text]="'Yes'"
              [checked]="attMap['intangiblepropinclusion'] != undefined && attMap['intangiblepropinclusion'][0] == '1'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-19'" [text]="'No'"
              [checked]="attMap['intangiblepropinclusion'] != undefined && attMap['intangiblepropinclusion'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 14d -->
      <li class="pt-1 li-apdix-d" style="counter-reset: count 13;" [hidden]="(attMap['intangiblepropinclusion'] == undefined ? false :  attMap['intangiblepropinclusion'][0] == '0')">
        <div class="my-form-row">
          <div class="row-label">
            If the answer to line 14c is "Yes," enter the total estimated anticipated income or cost reduction
            attributable to the intangible property's or properties, as applicable, use(s) beyond the 20-year period
            described in Regulations section 1.367(d)-1(c)(3)(ii)
            <span class="appkiticon icon-information-fill" [tooltip-container]="template99" [placement]="'right'"
              [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <span class="template-color-blue">
              {{attMap['totalanticipatedincome'] == undefined ? '' : attMap['totalanticipatedincome'][0]}}
            </span>
          </div>
        </div>
      </li>
      <!-- 15 -->
      <li class="pt-1" style="counter-reset: count 14;">
        <div class="my-form-row">
          <div class="row-label">
            Was any intangible property transferred considered or anticipated to be, at the time of the transfer
            or
            at any time thereafter, a platform contribution as defined in Regulations section 1.482-7(c)(1)?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-17'" [text]="'Yes'"
              [checked]="attMap['anticipatedtransfer'] != undefined && attMap['anticipatedtransfer'][0] == '1'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-17'" [text]="'No'"
              [checked]="attMap['anticipatedtransfer'] != undefined && attMap['anticipatedtransfer'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
    </ol>

    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Supplemental Part III Information Required To Be Reported</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="section-content mt-3 mb-3">
      <span class="template-color-blue">
        {{attMap['SupplementalInfo'] == undefined ? 'this is supplemental' :  attMap['SupplementalInfo'][0]}}
      </span>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part IV&nbsp;&nbsp;Additional Information Regarding Transfer of Property</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <ol class="inner-list-dec section-content" style="margin-left: 10px !important; margin-top: 1rem !important;" style="counter-reset: count 15;">
      <!-- 16 -->
      <li >
        <div class="my-form-row">
          <div class="row-label">
            Enter the transferor's interest in the foreign transferee corporation before and after the transfer:
          </div>
        </div>
        <br>
        <div class="row" style="padding-left: 35px !important;">
          <div class="col-md-2">
            (a) Before &nbsp;
            <span class="template-color-blue">
              {{attMap['transferinterestbefore'] == undefined ? '' : attMap['transferinterestbefore'][0]}}
            </span> &nbsp;%
          </div>
          <div class="col-md-2">
            (b)  After&nbsp;
            <span class="template-color-blue">
              {{attMap['transferinterestafter'] == undefined ? '' : attMap['transferinterestafter'][0]}}
            </span> &nbsp;%
          </div>
        </div>
      </li>
      <!-- 17 -->
      <li class="pt-1">
        <div class="my-form-row">
          <span class="row-label" style="width: unset;">
            Type of nonrecognition transaction&nbsp;
          </span>
          <span class="appkiticon icon-information-fill" [tooltip-container]="template10" [placement]="'right'" [isWhite]="true">
          </span>
          <span class="template-color-blue">
            &nbsp;&nbsp;{{attMap['NonrecognitionTransactionType'] == undefined ? '' : attMap['NonrecognitionTransactionType'][0]}}
          </span>
        </div>
      </li>
      <!-- 18 -->
      <li class="pt-1" style="counter-reset: count 17;">
        <div class="my-form-row">
          <span class="row-label">Indicate whether any transfer reported in Part III is subject to any of the following:</span>
        </div>
      </li>
      <!-- 18a -->
      <li class="pt-1 li-apdix-a" style="counter-reset: count 17;">
        <div class="my-form-row">
          <div class="row-label">
            Gain recognition under section 904(f)(3)
            <span class="appkiticon icon-information-fill" [tooltip-container]="template11" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-22'" [text]="'Yes'"
              [checked]="attMap['gainunder904f3'] != undefined && attMap['gainunder904f3'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-22'" [text]="'No'"
              [checked]="attMap['gainunder904f3'] != undefined && attMap['gainunder904f3'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18b -->
      <li class="pt-1 li-apdix-b" style="counter-reset: count 17;">
        <div class="my-form-row">
          <div class="row-label">
            Gain recognition under section 904(f)(5)(F) <span class="appkiticon icon-information-fill"
              [tooltip-container]="template12" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-23'" [text]="'Yes'"
              [checked]="attMap['gainunder904f5'] != undefined && attMap['gainunder904f5'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-23'" [text]="'No'"
              [checked]="attMap['gainunder904f5'] != undefined && attMap['gainunder904f5'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18c -->
      <li class="pt-1 li-apdix-c" style="counter-reset: count 17;">
        <div class="my-form-row">
          <div class="row-label">
            Recapture under section 1503(d) <span class="appkiticon icon-information-fill"
              [tooltip-container]="template13" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-24'" [text]="'Yes'"
              [checked]="attMap['recapunder1503d'] != undefined && attMap['recapunder1503d'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-24'" [text]="'No'"
              [checked]="attMap['recapunder1503d'] != undefined && attMap['recapunder1503d'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 18d -->
      <li class="pt-1 li-apdix-d" style="counter-reset: count 17;">
        <div class="my-form-row">
          <div class="row-label">
            Exchange gain under section 987 <span class="appkiticon icon-information-fill"
              [tooltip-container]="template14" [placement]="'right'" [isWhite]="true"></span>
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-25'" [text]="'Yes'"
              [checked]="attMap['exchangeunder987'] != undefined && attMap['exchangeunder987'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-25'" [text]="'No'"
              [checked]="attMap['exchangeunder987'] != undefined && attMap['exchangeunder987'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 19 -->
      <li class="pt-1" style="counter-reset: count 18;">
        <div class="my-form-row">
          <div class="row-label">
            Did this transfer result from a change in entity classification?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-26'" [text]="'Yes'"
              [checked]="attMap['forcorptransferee'] != undefined && attMap['forcorptransferee'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-26'" [text]="'No'"
              [checked]="attMap['forcorptransferee'] != undefined && attMap['forcorptransferee'][0] == '0'"
              [disabled]="true" class="a-mx-10 radio-font">
            </ap-radio>
          </div>
        </div>
      </li>
      <!-- 20a -->
      <li class="pt-1 li-apdix-a" style="counter-reset: count 19;">
        <div class="my-form-row">
          <div class="row-label">
            Did a domestic corporation make a distribution of property covered
            by section 367(e)(2) (see instructions) <span class="appkiticon icon-information-fill"
              [tooltip-container]="template15" [placement]="'right'" [isWhite]="true"></span><br /> If "Yes,"
            complete lines 20b and
            20c.
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-27'" [text]="'Yes'"
              [checked]="attMap['domesticcorpsec367e'] != undefined && attMap['domesticcorpsec367e'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true"> </ap-radio>
            <ap-radio [name]="'radio-group-27'" [text]="'No'"
              [checked]="attMap['domesticcorpsec367e'] != undefined && attMap['domesticcorpsec367e'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true"> </ap-radio>
          </div>
        </div>
      </li>
      <!-- 20b -->
      <li *ngIf="line21b" class="pt-1 li-apdix-b" style="counter-reset: count 19;">
        <div class="my-form-row">
          <div class="row-label">
            Enter the total amount of gain or loss
            recognized pursuant to Regulations section 1.367(e)-2(b)
          </div>
          <div class="row-content">
            <span class="template-color-blue">
              {{attMap['amountofgainorloss367e'] == undefined ? '' : attMap['amountofgainorloss367e'][0]}}
            </span>
          </div>
        </div>
      </li>
      <!-- 20c -->
      <li *ngIf="line21b" class="pt-1 li-apdix-c" style="counter-reset: count 19;">
        <div class="my-form-row">
          <div class="row-label">
            Did the domestic corporation not
            recognize gain or loss on the distribution of property because the
            property was used in the conduct of U.S. trade of business under
            Regulations section 1.367(e)-2(b)(2) ?
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-28'" [text]="'Yes'"
              [checked]="attMap['propusedinustrade'] != undefined && attMap['propusedinustrade'][0] == '1'"
              class="a-mx-10 radio-font" style="margin-left: 5px !important;" [disabled]="true"> </ap-radio>
            <ap-radio [name]="'radio-group-28'" [text]="'No'"
              [checked]="attMap['propusedinustrade'] != undefined && attMap['propusedinustrade'][0] == '0'"
              class="a-mx-10 radio-font" [disabled]="true"> </ap-radio>
          </div>
        </div>
      </li>
      <!-- 21 -->
      <li class="pt-1" style="counter-reset: count 20;">
        <div class="my-form-row">
          <div class="row-label">
            Did a domestic corporation make a section 355 distribution of stock in a foreign controlled corporation
            covered by section 367(e)(1)? See instructions
          </div>
          <div class="row-content">
            <ap-radio [name]="'radio-group-29'" [text]="'Yes'"
              [checked]="attMap['sec355stock'] != undefined && attMap['sec355stock'][0] == '1'" class="a-mx-10 radio-font" style="margin-left: 5px !important;"
              [disabled]="true">
            </ap-radio>
            <ap-radio [name]="'radio-group-29'" [text]="'No'"
              [checked]="attMap['sec355stock'] != undefined && attMap['sec355stock'][0] == '0'" class="a-mx-10 radio-font"
              [disabled]="true">
            </ap-radio>
          </div>
        </div>
      </li>
    </ol>

    <table style="width:100%">
      <tbody>
        <tr>
          <td class="footer-left">
            LEIDs: <span class="template-color-blue"> {{stmtData.primaryleid == null? '': stmtData.primaryleid}} </span>
            / <span class="template-color-blue"> {{stmtData.secondaryleid == null? '': stmtData.secondaryleid}} </span>
          </td>
          <td class="footer-right"></td>
        </tr>

        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      A specified 10%-owned foreign corporation is defined in section 245A(b)(1) as any foreign corporation with respect
      to which any domestic corporation is
      a U.S. shareholder. A controlled foreign corporation is defined in section 957(a) as any foreign corporation if,
      on any day during the tax year of such foreign
      corporation, U.S. shareholders own (within the meaning of section 958(a)), or are considered to own by applying
      the rules of ownership of section 958(b), more
      than 50% of (1) the total combined voting power of all classes of stock of such corporation entitled to vote, or
      (2) the total value of the stock of such
      corporation.
    </div>
  </div>
</ng-template>

<ng-template #template2>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If you answered 'Yes' to question 2a and the asset is stock, section 367(a)(4) may require basis adjustments.
      If you answered 'No' to question 2a and the asset is a tangible asset, the transfer is taxable under sections
      367(a)(1) and (a)(4).
      If the asset transferred is an intangible asset, see section 367(d) and its regulations.
    </div>
  </div>
</ng-template>

<ng-template #template3>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to line 2d is 'Yes,' and if the asset is transferred in an exchange described in section 361(a) or
      (b), attach the following.
      A statement that the conditions set forth in the second sentence of section 367(a)(4) and any regulations under
      that section have been satisfied.
      An explanation of any basis or other adjustments made pursuant to section 367(a)(4) and any regulations
      thereunder.
    </div>
  </div>
</ng-template>

<ng-template #template4>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to line 2d is 'Yes,' and if the asset is transferred in an exchange described in section 361(a) or
      (b), attach the following.
      A statement that the conditions set forth in the second sentence of section 367(a)(4) and any regulations under
      that section have been satisfied.
      An explanation of any basis or other adjustments made pursuant to section 367(a)(4) and any regulations
      thereunder.
    </div>
  </div>
</ng-template>

<ng-template #template5>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Provide a description of the property transferred. The description must be divided into the specified categories,
      and must include the estimated fair market value and adjusted basis of the property.
    </div>
  </div>
</ng-template>

<ng-template #template6>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the line 11 question is 'Yes,' for any stock or securities transferred, provide a general
      description of the corporation issuing the stock or securities. See Regulations section 1.6038B-1(c)(4)(ii).
      Please report in the Supplemental Part III Information Required To Be Reported section.
    </div>
  </div>
</ng-template>

<ng-template #template7>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to lines 12b and 12c is 'Yes,' provide the following information. (1) Describe the foreign branch
      whose property is transferred. Describe the property of the foreign branch, including its adjusted basis and fair
      market value. (2) Set forth a detailed calculation of
      the transferred loss amount. Provide, on a year-by-year basis, amounts of the losses generated by such foreign
      branch after December 31, 2017, as well as any income amounts
      generated after such loss year. (3) Provide the amount, if any, recognized under section 904(f)(3) on account of
      the transfer. (4) Set forth a detailed summary of the gain
      (other than the section 91 transferred loss amount) recognized by the transferor, including any section 367(a)(1)
      gain recognized on the transfer of property. See section
      91(c). (5) Set forth a calculation of the net sum of the previously deducted losses incurred by such foreign
      branch for tax years before January 1, 2018, that would have
      been recaptured under section 367(a)(3)(C), as determined without regard to the repeal of the section 367(a)(3)
      active trade or business exception. See the transition rule
      provided by TCJA section 14102(d)(4).
    </div>
  </div>
</ng-template>

<ng-template #template8>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the line 14c question is 'Yes,' describe the property for which the transferor
      chose to apply the 20-year inclusion period. See Regulations sections 1.6038B-1(d)(1)(iv) and 1.367(d)-1(c)
      (3)(ii). Explain how you figured the increase to the deemed payment
      rate for property transferred. See Regulations sections 1.6038B-1(d)(1) (iv) and 1.367(d)-1(c)(3)(ii). The
      explanation should include how you figured the deemed payment rate for
      each period of the useful life of the intangible property and the 20-year inclusion period.
    </div>
  </div>
</ng-template>

<ng-template #template9>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Enter any information from Part III that is required to be reported in greater
      detail. Identify the applicable column number next to the information
      entered in this section. In addition, if you contributed property to a
      foreign corporation as part of a wider transaction, briefly describe
      the entire transaction.
    </div>
  </div>
</ng-template>

<ng-template #template10>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      List the type of nonrecognition transaction that gave rise to the reporting obligation (e.g. Section 332, 351,
      354, 356, or 361)
    </div>
  </div>
</ng-template>

<ng-template #template11>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If gain recognized was required with respect to any transfer reported in Part III under Section 904(f)(3), attach
      a statement identifying the transfer and the amount of gain recognized
    </div>
  </div>
</ng-template>

<ng-template #template12>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If gain recognized was required with respect to any transfer reported in Part III under Section 904(f)(5)(F),
      attach a statement identifying the transfer and the amount of gain recognized
    </div>
  </div>
</ng-template>

<ng-template #template13>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If recapture was required with respect to any transfer reported in Part III under Section 1503(d) dual
      consolidated loss), attach a statement identifying the transfer and the amount of the recapture. Refer to Sec.
      1503(d)
    </div>
  </div>
</ng-template>

<ng-template #template14>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If exchange gain recognition was required with respect to any transfer reported in Part III under Section 987,
      attach a statement identifying the transfer and the amount of the exchange gain recognized. Refer to Sec. 1.987-5
    </div>
  </div>
</ng-template>

<ng-template #template15>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to line 20a is 'Yes,' complete lines 20b and 20c and provide the following
      information in the Supplemental Part III Information Required To Be Reported section. Preface this supplemental
      information on the form with the heading 'Section 367(e)(2)
      Information.' A description, including the adjusted tax basis and fair market value, of all property distributed
      by the distributing corporation (regardless of whether
      the distribution of the property qualifies for nonrecognition treatment). If the answer to line 20c is 'Yes,' an
      identification of the items of property for which nonrecognition
      treatment is claimed under Regulations section 1.367(e)-2(b)(2)(ii) or (iii), as applicable.
    </div>
  </div>
</ng-template>

<ng-template #template99>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      If the answer to the question on line 14c is 'Yes,' explain
      how you estimated the anticipated income or cost reduction attributable to the property's (or properties') use
      beyond the 20-year period.
      See Regulations section 1.6038B-1(d)(1) (iv).
    </div>
  </div>
</ng-template>
