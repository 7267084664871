<mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate">
</mat-progress-bar>

<div class="max-height">
  <div class="a-p-20" *ngIf="!isLoading">

    <ap-table #table [data]="firstRow" [showPage]="false" [pageSize]="1" [striped]="false">
      <thead class="a-thead">
        <tr>
          <th><span>LEID</span></th>
          <th><span>Tax Code</span></th>
          <th><span>Reporting Period</span></th>
          <th><span>Entity Name</span></th>
          <th><span>Tax Type</span></th>
          <th><span>ME Code</span></th>
          <th><span>Currency</span></th>
          <th><span>HO LEID</span></th>
          <th><span>HO Tax Code</span></th>
          <th><span>HO Reporting Period</span></th>
          <th><span>HO Entity Name</span></th>
          <th><span>HO Tax Type</span></th>
          <th><span>Tax Year Begin</span></th>
          <th><span>Tax Year End</span></th>
          <th><span>Source System Flag</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of firstRow">
          <td>{{ item.LEID }}</td>
          <td>{{ item.CDR_NO }}</td>
          <td>{{ item.REPORTING_PERIOD }}</td>
          <td>{{ item.LE_NAME }}</td>
          <td>{{ item.LE_TAX_TYPE }}</td>
          <td>{{ item.ME_CODE }}</td>
          <td>{{ item.CURR }}</td>
          <td>{{ item.HO_LEID }}</td>
          <td>{{ item.HO_CDR_NO }}</td>
          <td>{{ item.HO_REPORTING_PERIOD }}</td>
          <td>{{ item.HO_LE_NAME }}</td>
          <td>{{ item.HO_TAX_TYPE }}</td>
          <td>{{ item.TAX_YEAR_BEGIN }}</td>
          <td>{{ item.TAX_YEAR_END }}</td>
          <td>{{ item.SOURCE_SYSTEM_FLAG }}</td>
        </tr>
      </tbody>
    </ap-table>

  </div>
  <section class="a-p-20" *ngIf="!isLoading">

    <div *ngFor="let key of objectKeys(finalData)">
      <ap-panels-cards>
        <ap-button class="a-mr-10 btn-expand" *ngIf="finalData[key][0].display == false" [config]="floatingConfig"
          (onBtnClick)="expandCollapse(key)">
          <span class="appkiticon a-btn-icon icon-plus-fill"></span>
        </ap-button>
        <ap-button class="a-mr-10 btn-expand" *ngIf="finalData[key][0].display == true" [config]="floatingConfig"
          (onBtnClick)="expandCollapse(key)">
          <span class="appkiticon a-btn-icon icon-minus-fill"></span>
        </ap-button>
        <strong>Partner {{ finalData[key][0].INDEX }}: {{ finalData[key][0].PTR_LE }}</strong>
        <br />
        <br />
        <ap-table #basicTable *ngIf="!isLoading && finalData[key][0].display == true" [data]="finalData[key]"
          [pageSize]="finalData[key].length" [showPage]="false" [striped]="true">
          <thead class="a-thead">
            <tr>
              <th><label><b>Account</b></label></th>
              <th><label><b>Account Description</b></label></th>
              <th><label><b>Issue Key</b></label></th>
              <th><label><b>Country</b></label></th>
              <th><label><b>Trading Partner</b></label></th>
              <th><label><b>Partner Amount (Ptr Curr)</b></label></th>
              <th><label><b>Partner Amount (Pship Curr)</b></label></th>
              <th><label><b>BRANCH</b></label></th>
              <th><label><b>GENLIM</b></label></th>
              <th><label><b>PASSIVE</b></label></th>
              <th><label><b>TREATY</b></label></th>
              <th><label><b>901j</b></label></th>
            </tr>
          </thead>

          <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="finalData[key].length > 0">
            <tr *ngFor="let body of finalData[key]">
              <td><label>{{ body.ACCT }}</label></td>
              <td><label>{{ body.ACCT_DESC }}</label></td>
              <td><label>{{ body.ISSUE_KEY }}</label></td>
              <td><label>{{ body.COUNTRY_CODE }}</label></td>
              <td><label>{{ body.TRADING_PTR_LE ? body.TRADING_PTR_LE : '' }}</label></td>
              <td><label>{{ body.PTR_AMT_PTR_CURR | number }}</label></td>
              <td><label>{{ body.PTR_AMT_PSHIP_CURR | number }}</label></td>
              <td><label><ap-form-field class="ap-form-field-my-wrapper branch-ip" [isSecondary]="true" [type]="'input'"
                    [isDisabled]="true" [(ngModel)]="body.BRANCH"></ap-form-field></label></td>
              <td><label><ap-form-field class="ap-form-field-my-wrapper" [isSecondary]="true" [type]="'input'"
                    [isDisabled]="false" [(ngModel)]="body.GENLIM"
                    (onChangeEvent)="updateBasket($event, body.GENLIM, body)"></ap-form-field></label></td>
              <td><label><ap-form-field class="ap-form-field-my-wrapper" [isSecondary]="true" [type]="'input'"
                    [isDisabled]="false" [(ngModel)]="body.PASSIVE"
                    (onChangeEvent)="updateBasket($event, body.PASSIVE, body)"></ap-form-field></label></td>
              <td><label><ap-form-field class="ap-form-field-my-wrapper" [isSecondary]="true" [type]="'input'"
                    [isDisabled]="false" [(ngModel)]="body.TREATY"
                    (onChangeEvent)="updateBasket($event, body.TREATY, body)"></ap-form-field></label></td>
              <td><label><ap-form-field class="ap-form-field-my-wrapper" [isSecondary]="true" [type]="'input'"
                    [isDisabled]="false" [(ngModel)]="body['901j']"
                    (onChangeEvent)="updateBasket($event, body['901j'], body)"></ap-form-field></label></td>
            </tr>
          </tbody>
        </ap-table>
      </ap-panels-cards>

    </div>
  </section>

</div>
<div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

  <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [isDisabled]="isSaving"
    [btnType]="'a-btn-gray'" [config]="secondaryConfig" (onBtnClick)="cancel()"></ap-button>
  <div>
    <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent" [isDisabled]="isSaving" [config]="secondaryConfig"
      (onBtnClick)="saveEntries()"></ap-button>
  </div>


</div>