<div class="main-container">
  <div class="grid-container" id="8594report-grid-container" style="height: calc(100vh - 124px);">
    <div class="top-toolbar">
      <div class="float-left ml-2">
        <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
                    (onChange)="onTaxYearChange()">
        </p-dropdown>
        <!-- <p-dropdown *ngIf="scenarioLoaded" class="mr-2" [options]="scenarios" optionLabel="text" [(ngModel)]="scenario"
          (onChange)="onScenarioChange()">
        </p-dropdown> -->
        <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136"
                            (selectedMes)="selectedMeChanged($event)">
        </gtw-df-me-dropdown>
        <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
        <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
      </div>
    </div>

    <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName" [loading]="loading"
             [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
             styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
             [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">

      <ng-template pTemplate="header" let-columns>
        <tr [style.height]="'45px'">
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="height: 70px;">
          <td style="width: 145px;">
            <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)">
              <span>{{ rowData.STATEMENTID }}</span>
            </a>
          </td>
          <td style="width: 105px;">
            <span>{{ rowData.PROJECT_NAME }}</span>
          </td>
          <td style="width: 120px;">
            <span>{{ rowData.PRIMARYLEID }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 180px;">
            <span>{{ rowData.PRIMARYENTITY }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 120px;">
            <span>{{ rowData.PURCHASERORSELLER_VAL }}</span>
          </td>
          <td style="width: 145px;">
            <span>{{ rowData.FILING_GROUP }}</span>
          </td>
          <td style="width: 175px;">
            <span>{{ rowData.SECONDARYLEID }}</span>
          </td>
          <td class="text-pre-wrap" style="width: 180px;">
            <span>{{ rowData.EINOFOTHERPARTY1_VAL }}</span>
          </td>
          <td style="width: 170px;">
            <span>{{ rowData.EIN_VAL }}</span>
          </td>
          <td style="width: 275px;">
            <span>{{ rowData.ADDRESSOFOTHERPARTY1_VAL }}</span>
          </td>
          <td style="width: 236px;">
            <span>{{ rowData.ADDRESSOFOTHERPARTY2_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CITYOFOTHERPARTY_VAL }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.STATE_US_VAL }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.COUNTRY1_VAL }}</span>
          </td>
          <td style="width: 155px;">
            <span>{{ rowData.ZIPCODE5_VAL }}</span>
          </td>
          <td style="width: 125px;">
            <span>{{ rowData.DATEOFSALE_VAL }}</span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.TOTALSALESPRICE_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TOTALSALESPRICE_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS1FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS1FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS2FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS2FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS3FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS3FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS4FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS4FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;;">
            <span gtwLineSplitter [inputString]="rowData.CLASS5FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS5FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS6N7FMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS6N7FMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.TOTALFMV_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TOTALFMV_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS1ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS1ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS2ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS2ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS3ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS3ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS4ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS4ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS5ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS5ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.CLASS6N7ASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.CLASS6N7ASP_VAL }}
            </span>
          </td>
          <td style="width: 170px; text-align: end;">
            <span gtwLineSplitter [inputString]="rowData.TOTALASP_VAL" [sepearator]="','" [formatter]="'decimal'">
              {{ rowData.TOTALASP_VAL }}
            </span>
          </td>
          <td style="width: 170px;">
            <span>{{ rowData.CHOICE204A_VAL }}</span>
          </td>
          <td style="width: 200px;">
            <span>{{ rowData.CHOICE204B_VAL }}</span>
          </td>
          <td style="width: 300px;">
            <span>{{ rowData.CHOICE204C_VAL }}</span>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>
