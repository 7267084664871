<div class="drop-down-btn" [ngClass]="{'focus': showDropContainer, 'disabled': loading}" (click)="toggleMenu()" dstClickOutside
     (clickOutside)="showDropContainer = false;">
  <span>Selected ME(s)</span>
  <div class="flt-right">
    <div *ngIf="selected.size" class="badge a-bg-primary">{{selected.size}}</div>
    <span class="loader-container" *ngIf="loading">
    <ap-loading [colorName]="'a-primary'" [size]="'15px'"></ap-loading>
  </span>
    <span class="appkiticon chevron" [ngClass]="showDropContainer ? 'icon-up-chevron-outline' : 'icon-down-chevron-outline'"></span>
  </div>
  <div class="gt-drop-container" *ngIf="showDropContainer" (click)="$event.stopPropagation();">
    <div class="left-menu">
      <div class="toolbar">
        <span (click)="selectAll()" style="float: left; text-decoration: underline; cursor: pointer; ">Select All</span>
        <span (click)="unselectAll()" style="float: right; text-decoration: underline; cursor: pointer; ">Unselect All</span>
      </div>
      <ul class="parent-me-list">
        <li *ngFor="let item of data" (click)="expandParentME(item)" [ngClass]="{'selected' : item.active}">
          <input type="checkbox" [(ngModel)]="item.selected" (change)="onParentMeSelected(item)" (click)="$event.stopPropagation();">
          <span class="list-desc">{{item.name}}</span>
          <div *ngIf="item.numSelected! > 0" class="badge a-bg-primary">{{item.numSelected}}</div>
        </li>
      </ul>
    </div>
    <div class="right-container" *ngIf="showRightMenu">
      <gtw-df-me-dropdown-node *ngFor="let meNode of selectedParentMe.children" [node]="meNode"
                               (nodeSelectionChanged)="onChildrenSelectionChanged($event)"></gtw-df-me-dropdown-node>
    </div>
  </div>
</div>
