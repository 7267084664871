import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScreenDetailsService {

  private URLs = {
    downloadTemplate : '/downloadTemplate'
  };

  constructor(private httpClient: HttpClient) { }

  downloadTemplate(baseURL:string, screenKey:string,client_key:number, tax_year:string, scenario:string){
    const downloadURL = `${baseURL}${this.URLs.downloadTemplate}?screenkey=${screenKey}&client_key=${client_key}&tax_year=${tax_year}&scenario=${scenario}`;
    // const headers = {'Authorization': `Bearer ${authToken}`};
    this.httpClient.get(downloadURL, {observe:"response", responseType: 'blob'})
      .subscribe((response: HttpResponse<Blob>) => {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        a.href = url;
        //get name from content desposition
        const contentDisposition = response.headers.get('content-disposition') || '';
        a.download = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
        // a.download = 'template.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }
}
