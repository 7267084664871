
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionResponse } from '../../../shared/_models/action-response.model';
import { SaveActionResponse } from '../../../shared/_models/save-action-response.model';
import { ActionService } from '../../../shared/_services/action.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

interface BepsCountriesInput {
  taxYear: string;
  baseUrl: string;
  clientKey: number;
  rowData: {
    LEID: string;
    LE_Name: string;
    CbyC_TIN: string;
    Country_Incorp_: string;
  }
}

@Component({
  selector: 'gtw-cbc-beps-countries',
  templateUrl: './beps-countries.component.html'
})
export class BepsCountriesComponent {

  countries$: Observable<any> | null = null;
  gtwInput: BepsCountriesInput = { baseUrl: '/gtw', clientKey: 0, rowData: { LEID: '', LE_Name: '', CbyC_TIN: '', Country_Incorp_: '' }, taxYear: '' };
  request: {
    tin: string;
    country: string;
    leName: string;
    leid: string;
    taxYear: string;
  };
  preChangeRequest: { tin: string; country: string; leName: string; leid: string; taxYear: string; };

  @Output('close-modal')
  closeModal = new EventEmitter();

  @Input('gtwInput')
  set gtwInputStr(input: string) {
    try {
      this.gtwInput = JSON.parse(input);

      this.request.tin = this.gtwInput.rowData.CbyC_TIN;
      this.request.leName = this.gtwInput.rowData.LE_Name;
      this.request.country = this.gtwInput.rowData.Country_Incorp_;
      this.request.leid = this.gtwInput.rowData.LEID;
      this.request.taxYear = this.gtwInput.taxYear;

      this.preChangeRequest = { ...this.request };

      const params = new HttpParams({});

      this.countries$ = this.actionService.getActionData(this.gtwInput.baseUrl, 33311, this.gtwInput.clientKey, params).pipe(
        map((res: ActionResponse) => {
          return [{
            data: res.jsonObject.map((item: { ID: number, VALUE: string }) => {
              if (item.VALUE === this.request.country) {
                return { value: item.ID, text: item.VALUE, selected: true }
              }

              return { value: item.ID, text: item.VALUE }
            })
          }]
        })
      )
    } catch (e) {
      console.error('Error parsing gtwInput', e);
    }
  };

  constructor(
    private actionService: ActionService,
    private alertService: GtwSnackbarService
  ) {
    this.request = { leid: '', tin: '', country: '', leName: '' , taxYear: ''};
    this.preChangeRequest = { leid: '', tin: '', country: '', leName: '', taxYear: '' };
  }

  countrySelected(event: any) {
    this.request.country = event.change.text;
  }

  updateCountry() {

    if (isEqual(this.request, this.preChangeRequest)) {
      this.alertService.openSnackBar("No changes made", "gtw-snackbar--orange");
      return;
    }


    const params = new HttpParams({
      fromObject: {
        leid: this.request.leid,
        tax_year: this.request.taxYear,
        tin: this.request.tin === this.preChangeRequest.tin ? '' : this.request.tin,
        country: this.request.country === this.preChangeRequest.country ? '' : this.request.country,
        leName: this.request.leName === this.preChangeRequest.leName ? '' : this.request.leName
      }
    });

    this.actionService.saveActionData(this.gtwInput.baseUrl, 33312, this.gtwInput.clientKey, params).subscribe((response: SaveActionResponse) => {
      if (response.callSuccess === '1') {
        this.alertService.openSnackBar("Changes saved successfully", "gtw-snackbar--green");
      } else {
        this.alertService.openSnackBar(response.errorMessage, "gtw-snackbar--red");
      }
      this.closeModal.emit();
    })
  }

}
