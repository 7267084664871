import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Table} from 'primeng-lts/table';
import {CreateDisclosureService, DSTTaxYear} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import {Alert} from '../../statement-tracker/statement-tracker.service';
import {DiagnosticsService} from '../diagnostics.service';
import {Subscription, zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {StmtBtnActionPayload} from '../../shared/components/stmt-id-btn/stmt-id-btn.component';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {CopyReplicateComponent} from '../../statement-tracker/copy-replicate/copy-replicate.component';
import {ModalComponent} from 'appkit-angular/modal';
import {DSTSharedService} from '../../shared/dst-shared.service';
import {DSTChildFormState} from '../../shared/models/dst-child-form-state.model';
import {StmtIdBtnService} from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import {DiscModalViewerComponent} from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {SaveActionResponse} from '../../../shared/_models/save-action-response.model';
import {JSONExportHeader, JSONExportService} from '../../../shared/_services/JSONExport.service';

@Component({
  selector: 'gtw-transfers-review-report',
  templateUrl: './transfers-review-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './transfers-review-report.component.scss']
})
export class TransfersReviewReportComponent implements OnInit, OnChanges {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild('dt') table!: Table;
  @ViewChild('yesExplanationModal') yesExplanationModal!: ModalComponent;

  first: number = 0;
  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTNAME',
      header: 'Statement',
      hidden: false,
      width: '290px'
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Business',
      hidden: false,
      width: '250px'
    },
    {
      field: 'PRIMARYLEID',
      header: 'LEID',
      hidden: false,
      width: '110px'
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Entity Name',
      hidden: false,
      width: '280px'
    },
    {
      field: 'TRANS_TYPE',
      header: 'Transaction Type',
      hidden: false,
      width: '172px'
    },
    {
      field: 'LIQUIDATIONDATE',
      header: 'Date of Transfer',
      hidden: false,
      width: '115px'
    },
    {
      field: 'CURRENCYNAME',
      header: 'Currency',
      hidden: false,
      width: '115px'
    },
    {
      field: 'BASISDESC',
      header: 'Descr. of Property Transferred',
      hidden: false,
      width: '230px'
    },
    {
      field: 'BASISNAME',
      header: 'Basis for Property Transferred',
      hidden: false,
      width: '146px'
    },
    {
      field: 'BASISAMT',
      header: 'Basis Amount',
      hidden: false,
      width: '150px'
    },
    {
      field: 'LIABILITIES',
      header: 'Liabilities Assumed',
      hidden: false,
      width: '120px'
    },
    {
      field: 'NETAMOUNT',
      header: 'Net Amount',
      hidden: false,
      width: '150px'
    },
    {
      field: 'FMVAMT',
      header: 'FMV Amount',
      hidden: false,
      width: '150px'
    },
    {
      field: 'BASISFMVALERT',
      header: 'FMV = Basis Alert',
      hidden: false,
      width: '106px'
    },
    {
      field: 'IMPORTATIONPROPERTYDESC',
      header: 'Importation Property Description',
      hidden: false,
      width: '220px'
    },
    {
      field: 'LOSSDUPLICATIONPROPERTYDESC',
      header: 'Loss Duplication Property Description',
      hidden: false,
      width: '220px'
    },
    {
      field: 'GAINLOSSPROPERTYDESC',
      header: 'Recognized Gain/Loss Property Description',
      hidden: false,
      width: '220px'
    },
    {
      field: 'POTENTIAL_BIL_IMPORTATION',
      header: 'Potential BIL Loss Importation',
      hidden: false,
      width: '145px'
    },
    {
      field: 'POTENTIAL_362_E_ALERT',
      header: 'Potential 362(e) Alert',
      hidden: false,
      width: '135px'
    },
    {
      field: 'IS_362_LIMIT_MADE',//
      header: 'Is a Sec. 362(e)(2)(C) Election to Apply Sec. 362 Limitation to Transferor\'s Stock Basis being made?',
      hidden: false,
      width: '293px'
    },
    {
      field: 'REVIEW_EXPL',//
      header: 'Reviewer Explanation',
      hidden: false,
      width: '240px'
    },
    {
      field: 'ISREVIEWED',
      header: 'Mark Statement as Reviewed',
      hidden: false,
      width: '140px'
    },
    {
      field: 'ISQAR',
      header: 'QAR?',
      hidden: false,
      width: '100px'
    },
    {
      field: 'MODIFIEDBY',
      header: 'Modified By',
      hidden: true,
    },
    {
      field: 'MODIFIEDONFORMATTED',
      header: 'Modified On',
      hidden: true,
    },
    {
      field: 'CREATEDBY',
      header: 'Created By',
      hidden: true,
    },
    {
      field: 'CREATEDONFORMATTED',
      header: 'Created On',
      hidden: true,
    },
  ];
  frozenCols: any[] = [
    {
      field: 'TAXYEAR',
      header: 'Year',
      hidden: false,
      width: '81px'
    },
    {
      field: 'STATEMENTID',
      header: 'Stmt ID',
      hidden: false,
      width: '117px'
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;
  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;

  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;
  alert: Alert;
  fStatementId: string = '';
  fStatementName: string = '';
  fBusiness: string = '';
  fLeid: string = '';
  fEntityName: string = '';
  fTransactionType: string = '';
  fDateofTransfer: string = '';
  fCurrency: string = '';
  fDescPropTransfered: string = '';
  fBasisPropTransferred: string = '';
  fBasisAmount: string = '';
  fLiabilitiesAssumed: string = '';
  fNetAmount: string = '';
  fFmvAmount: string = '';
  fFmvBasisAlert: string = '';
  fImpPropDesc: string = '';
  fLossDupliPropDesc: string = '';
  fRecogGlPropDesc: string = '';
  fPotentialBilLossImport: string = '';
  fPotential362EAlert: string = '';
  fIsQar: string = '';

  revExplanations: any = [
    {
      data: [
        {text: 'Section Option', value: '', reviewTypeId: 0},
      ]
    }
  ];
  yesExplanationText: string = '';
  yesExplActiveRow: any;
  showYesExplanationModal: boolean = false;

  constructor(private cDSerivce: CreateDisclosureService,
              public dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private trackerTabsService: TrackerTabsService,
              private diagnosticsService: DiagnosticsService,
              private jsonExportService: JSONExportService,
              private dCRService: DSTDynamicComponentResolverService) {

    this.excelExportFileName = 'Transfers_Review_Diagnostics_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setTaxYearScenario('2021', '40');
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);

    this.alert = dstSharedService.alert;
  }

  ngOnInit(): void {
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data: any) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2021');
    //   this.loadReport();
    //   this.loadDiagReviewOptions();
    // });
    // this.dstSharedService.setDSTUser('400394467', 5);

    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data: any) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          this.loadReport();
          this.loadDiagReviewOptions();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.loadReport();
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.diagnosticsService.loadTransfersReviewReport(this.taxYear?.text.toString()!).subscribe((data) => {
      this.data = data;
      this.data.forEach((element, index) => {
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORM_NAME;
        element.FORMTYPEID = element.FORM_TYPE_ID;

        element.id = index + 1;
        element['revExplanations'] = [{data: []}];
        this.revExplanations[0].data.forEach((item: any) => {
          element['revExplanations'][0].data.push({
            text: item.text,
            value: item.value,
            reviewTypeId: item.reviewTypeId,
            selected: element.TRANSFER_REIVEW_NAME === item.value ? true : false,
          });
        });
      });
      this.table.clear();
      this.resetColFilters();
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the Data Collect grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  loadDiagReviewOptions(): void {
    this.diagnosticsService.getDiagnosticsReviewOptions('transferReview').subscribe((data) => {
      data.forEach((item: any) => {
        this.revExplanations[0].data.push({
          text: item.REVIEW_NAME,
          value: item.REVIEW_NAME,
          reviewTypeId: item.REVIEW_TYPE_ID,
        });
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while loading Review options, please try again!');
    }).add(() => {
    });
  }

  updateYesExplReviewComment(statementId: string, reviewType: string, reviewTypeId: string, otherExpl: string): void {
    this.diagnosticsService.dstUpdateReviewComment(statementId, reviewType, reviewTypeId, otherExpl)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Review Type Saved Successfully.');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving review type, please try again later!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      });
  }

  reviewerExplanationChange(event: any, rowData: any): void {
    rowData.REVIEWNAME = event.selected[0].value;
    rowData.REVIEWTYPEID = event.selected[0].reviewTypeId;

    if (rowData.REVIEWNAME === 'Yes (provide Stmt ID in the textbox)') { //open other expl pop-up when others is selected
      this.yesExplanation(rowData);
    } else {
      rowData.TRANSFER_REVIEW_EXPLANATION = '';
    }

    //Save reviewer Explanation change
    this.updateYesExplReviewComment(rowData.STATEMENTID, 'transferReview', event.selected[0].reviewTypeId, rowData.TRANSFER_REVIEW_EXPLANATION || '');
  }

  yesExplanation(rowData: any): void {
    this.showYesExplanationModal = true;
    const selected = rowData.revExplanations[0].data.find((r:any)=>r.selected);
    rowData.REVIEWNAME = selected.value;
    rowData.REVIEWTYPEID = selected.reviewTypeId;
    this.yesExplActiveRow = rowData;
    
    this.yesExplanationText = rowData.TRANSFER_REVIEW_EXPLANATION || '';
    setTimeout(() => {
      this.yesExplanationModal.showMore('rev-yes-exp');
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="rev-yes-exp"]');
      modalEle?.classList.add('gtw-web-components');
      modalEle?.classList.add('dst-modal-sm');
    });
  }

  onYesExplanationSave(id: string): void {
    this.yesExplActiveRow.TRANSFER_REVIEW_EXPLANATION = this.yesExplanationText || '';

    //Save other explanation text
    this.updateYesExplReviewComment(this.yesExplActiveRow.STATEMENTID, 'transferReview', this.yesExplActiveRow.REVIEWTYPEID, this.yesExplanationText || '');

    this.yesExplanationModal.closeModalOk(id);
    setTimeout(() => {
      this.showYesExplanationModal = false;
    });
  }

  onYesExplanationCancel(id: string): void {
    this.yesExplanationModal.closeModalOk(id);
    setTimeout(() => {
      this.showYesExplanationModal = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  filterCol(event: Event, field: string, matchMode: string): void {
    this.table.filter((event.target as HTMLInputElement).value, field, matchMode);
  }

  private resetColFilters(): void {
    this.fStatementId = '';
    this.fStatementName = '';
    this.fBusiness = '';
    this.fLeid = '';
    this.fEntityName = '';
    this.fTransactionType = '';
    this.fDateofTransfer = '';
    this.fCurrency = '';
    this.fDescPropTransfered = '';
    this.fBasisPropTransferred = '';
    this.fBasisAmount = '';
    this.fLiabilitiesAssumed = '';
    this.fNetAmount = '';
    this.fFmvAmount = '';
    this.fFmvBasisAlert = '';
    this.fImpPropDesc = '';
    this.fLossDupliPropDesc = '';
    this.fRecogGlPropDesc = '';
    this.fPotentialBilLossImport = '';
    this.fPotential362EAlert = '';
    this.fIsQar = '';
  }

  statementReviewedChange(event: any, rowData: any): void {
    //Save- mark statement as reviewed change
    rowData.markStatementAsReviewed = event.srcElement.checked ? 1 : 0;
    this.stmtIdBtnService.setReviewed(rowData.statementId, rowData.markStatementAsReviewed)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Statement saved successfully for reviewed status.');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while reviewing status, please try again later!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      });
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'TAXYEAR',
        header: 'Year',
      },
      {
        field: 'STATEMENTID',
        header: 'Stmt ID',
      },
      {
        field: 'STATEMENTNAME',
        header: 'Statement',
      },
      {
        field: 'PRIMARYBUSINESS',
        header: 'Business',
      },
      {
        field: 'PRIMARYLEID',
        header: 'LEID',
      },
      {
        field: 'PRIMARYENTITY',
        header: 'Entity Name',
      },
      {
        field: 'TRANS_TYPE',
        header: 'Transaction Type',
      },
      {
        field: 'LIQUIDATIONDATE',
        header: 'Date of Transfer',
      },
      {
        field: 'CURRENCYNAME',
        header: 'Currency',
      },
      {
        field: 'BASISDESC',
        header: 'Descr. of Property Transferred',
      },
      {
        field: 'BASISNAME',
        header: 'Basis for Property Transferred',
      },
      {
        field: 'BASISAMT',
        header: 'Basis Amount',
      },
      {
        field: 'LIABILITIES',
        header: 'Liabilities Assumed',
      },
      {
        field: 'NETAMOUNT',
        header: 'Net Amount',
      },
      {
        field: 'FMVAMT',
        header: 'FMV Amount',
      },
      {
        field: 'BASISFMVALERT',
        header: 'FMV = Basis Alert',
      },
      {
        field: 'IMPORTATIONPROPERTYDESC',
        header: 'Importation Property Description',
      },
      {
        field: 'LOSSDUPLICATIONPROPERTYDESC',
        header: 'Loss Duplication Property Description',
      },
      {
        field: 'GAINLOSSPROPERTYDESC',
        header: 'Recognized Gain/Loss Property Description',
      },
      {
        field: 'POTENTIAL_BIL_IMPORTATION',
        header: 'Potential BIL Loss Importation',
      },
      {
        field: 'POTENTIAL_362_E_ALERT',
        header: 'Potential 362(e) Alert',
      },
      {
        field: 'REVIEWNAME',//
        header: 'Is a Sec. 362(e)(2)(C) Election to Apply Sec. 362 Limitation to Transferor\'s Stock Basis being made?',
      },
      {
        field: 'REVIEW_EXPL',//
        header: 'Reviewer Explanation',
      },
      {
        field: 'ISREVIEWED',
        header: 'Mark Statement as Reviewed',
      },
      {
        field: 'ISQAR',
        header: 'QAR?',
      },
      {
        field: 'MODIFIEDBY',
        header: 'Modified By',
      },
      {
        field: 'MODIFIEDONFORMATTED',
        header: 'Modified On',
      },
      {
        field: 'CREATEDBY',
        header: 'Created By',
      },
      {
        field: 'CREATEDONFORMATTED',
        header: 'Created On',
      },
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
