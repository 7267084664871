import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridData } from './entity-chart-map-clean-up.component';

@Injectable({
	providedIn: 'root'
})
export class EntityChartMapCleanUpService {

	url = {
		retrieveEntityDetails: '/loadJsonObject?action_code=wzsvuk',
		getTransCountURL: '/loadJsonObject?action_code=e7zgay',
		deleteEntitites: '/saveJsonObject?action_code=n8tto3'
	}

	durationInSeconds = 5;
	constructor(private httpClient: HttpClient, private http: HttpClient, private _snackBar: MatSnackBar) { }

	getEntityDetails(baseURL: string, params: { tax_year: string | undefined; scenario: string | undefined; jcd_key: string | undefined; }, jsonData: GridData[]) {
		let clientKey = sessionStorage.getItem('client_key') || '';

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year!.toString());
		body.append('scenario', params.scenario!.toString());
		body.append('jcd_key', params.jcd_key!.toString());
		body.append('process_name', 'entity-details-modal-process');
		body.append('is_issue_key_enabled', 'N');

		const url = `${baseURL}${this.url.retrieveEntityDetails}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					return res;
				} else {
					this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					throwError(new Error('oops!'));
					return "error";
				}

			}
			));
	}

	getTransCount(baseURL: string,
		taxYear: string | undefined, scenario: string | undefined, jcdKey: string | undefined,
		leKey: { LE_KEY?: string }[]) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('le_key', JSON.stringify(leKey));
		body.append('tax_year', taxYear!.toString());
		body.append('scenario', scenario!.toString());
		body.append('jcd_key', jcdKey!.toString());
		body.append('process_name', 'entity-details-modal-process');
		body.append('is_issue_key_enabled', 'N');

		const url = `${baseURL}${this.url.getTransCountURL}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((list: any) => {
				if (list.callSuccess == "1") {
					return list.jsonObject;
				}
				this.openSnackBar(list.errorMessage, 'gtw-snackbar--red');
				throwError(new Error('oops!'));
				return "error";
			}
			));

	}

	deleteEntities(baseURL: string,
		params: { tax_year: string | undefined; scenario: string | undefined; jcd_key: string | undefined; scenarioCode: string | undefined; },
		data: GridData[]) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([params]));
		body.append('clob_data', JSON.stringify(data));
		body.append('tax_year', params.tax_year!.toString());
		body.append('scenario', params.scenario!.toString());
		body.append('jcd_key', params.jcd_key!.toString());
		body.append('scenarioCode', params.scenarioCode ? params.scenarioCode!.toString() : 'RAF');
		body.append('process_name', 'entity-delete-entites');
		body.append('is_issue_key_enabled', 'N');

		const url = `${baseURL}${this.url.deleteEntitites}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((list: any) => {
				if (list.callSuccess == "1") {
					this.openSnackBar("Success!", 'gtw-snackbar--green');
					return list;
				}
				this.openSnackBar(list.errorMessage, 'gtw-snackbar--red');
				throwError(new Error('oops!'));
				return 'error';
			}
			));

	}

	openSnackBar(message: string, panelClass: string = '') {
		this._snackBar.open(message, '', {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}

}
