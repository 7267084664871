import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActiveScreensService } from '../../shared/_services/active-screens.service';
import { NavigationModel } from '../navigation.model';
import { NavigationService} from '../navigation.service';
import { HomeScreenObject} from './home-screen.object';
@Component({
  selector: 'gtw-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, AfterViewInit {
  
  @Input('app_navigation') appNavigation:any[]=[];
  
  @Output() clickDomain = new EventEmitter<{tasks:NavigationModel, domainName:string}>();
  @Output('load-screens') loadScreen= new EventEmitter<any>();
  @ViewChild('ullist') ulList?: ElementRef ;

  selectedDomainName: string = 'Home';
  selectionDetails:{tasks:NavigationModel, domainName:string} = {
    tasks: {id:"", label:"",parentTaskKey:"",children:[]},
    domainName: 'Home'
  }

  height = 0;

  HOME_SCREEN_OBJECT =HomeScreenObject;

  constructor(private navigationService:NavigationService,private el:ElementRef,
              private activeScreensService:ActiveScreensService) {
    this.height = this.el.nativeElement.offsetHeight;
  }

  ngOnInit(): void {
    this.selectedDomainName = 'Home';
    this.setDomainNameFromHomePage();
  }

  ngAfterViewInit() {
    if(this.ulList){
      this.ulList.nativeElement.style.height = this.height-52 + 'px';    // set height
    }
  }

  setDomainNameFromHomePage(){//replace it with redux
    this.activeScreensService.domainName$.subscribe((name:string)=> {
      this.selectedDomainName=name;
    });
  }

  onClickDomain(domain: NavigationModel, domainName: string) {
    this.selectedDomainName = domainName;
    this.selectionDetails.tasks = domain;
    this.selectionDetails.domainName = domainName;
    this.clickDomain.emit(this.selectionDetails);
    
  }

  onKeyDown(item : any, event : any) {
    switch (event.which) {
      case 13:
        event.preventDefault();
        break;
    }
  }

  loadHistoryScreen(screen:any){
    this.loadScreen.emit(screen);
  }

  onClickHomeDomain() {
    this.selectedDomainName = 'Home';
    this.loadScreen.emit(this.HOME_SCREEN_OBJECT);
    this.activeScreensService.setScreens(this.HOME_SCREEN_OBJECT);
    
  }
}
