<div *ngIf="childTabIndex==1">
<!--  <div style="height: 52px;">
    <input style="display:none;" (change)="uploadDocument($event)" 
            #docFileInput type="file" id="file" multiple required>
</div> -->
<div style="margin-bottom: 10px;">
    <div><label>Document upload title:</label></div>
    <input type="text" class="doc-title-input" [(ngModel)]="docTitle" placeholder="Enter title" />
</div>
<div>
    <div><label>Document upload message:</label></div>
 <ap-editor class="doc-editor" [isFull]="true" [editorId]="'Editor1'" [(ngModel)]="text1"
    (change)="onChange($event)"></ap-editor>
</div>

<div class="doc-upload-list-wrapper">
    <div *ngFor="let attachment of attachmentList;index as i" class="doc-upload-list">
        <span class="material-icons icn">description</span>
        <ul class="uploaded-file-details">
            <li>Name: {{attachment.name}}</li>
            <li>Type: {{attachment.type}}</li>
            <li>Size: {{attachment.size}}</li>
        </ul>
        <ul class="document-actions">
            <li><span class="material-icons" style="color: #29c7f7; border-right: 1px solid #ccc; padding-right: 5px; cursor: pointer;">edit</span></li>
            <li><span class="material-icons" style="color: #767676; padding-left: 5px; cursor: pointer;" (click)="deleteDocument($event, attachment)">delete</span></li>
        </ul>
    </div>

</div> 

<section class='upload-doc-actions'>
    <button type="submit" class="doc-save-btn" mat-raised-button color="primary" (click)="saveDocument($event)">
        Save
    </button>
    <button class="doc-save-btn" mat-raised-button color="primary" (click)="clearContent($event)">
        Clear
    </button>
</section>
</div>
