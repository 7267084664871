<div class="main-container">
  <div class="grid-container" id="926report-grid-container" style="height: calc(100vh - 124px);">
      <div class="top-toolbar">
          <div class="float-left ml-2">
            <p-dropdown *ngIf="taxyearLoaded" class="mr-2" [options]="taxYears" optionLabel="text" [(ngModel)]="taxYear"
              (onChange)="onTaxYearChange()">
            </p-dropdown>
            <gtw-df-me-dropdown class="top-filter-select me-dropdown-label mr-2" [customActionCode]="32136" (selectedMes)="selectedMeChanged($event)">
            </gtw-df-me-dropdown>
            <ap-button [btnText]="'Filter'" class="a-mr-10 mr-2" [config]="{isPrimary: true}" (onBtnClick)="loadReport()"></ap-button>
            <span class="appkiticon icon-save-outline" (click)="exportToExcel()">&nbsp;Export to Excel</span>
          </div>
      </div>

      <p-table #dt [columns]="cols" [value]="data" [exportFilename]="excelExportFileName"
        [loading]="loading" [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll"
        styleClass="p-datatable-gridlines p-datatable-striped" [virtualScroll]="true" [virtualRowHeight]="70"
        [ngClass]="{ 'p-table-no-scroll': scrollBarHidden }">

          <ng-template pTemplate="header" let-columns>
              <tr [style.height]="'45px'">
                <ng-container *ngFor="let col of columns; index as i">
                  <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [width]="col.width">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>
              </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="height: 70px;">
                  <td style="width: 145px;">
                    <a href="javascript:void(0);" (click)="showViewUpdateStatement(rowData)"><span>{{ rowData.STATEMENTID }}</span></a>
                  </td>
                  <td style="width: 105px;">
                    <span>{{ rowData.PROJECT_NAME }}</span>
                  </td>
                  <td style="width: 155px;">
                    <span>{{ rowData.PRIMARYLEID }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARYENTITY }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 260px;">
                    <span>{{ rowData.PRIMARYBUSINESS }}</span>
                  </td>
                  <td style="width: 260px;">
                    <span>{{ rowData.FILING_GROUP }}</span>
                  </td>
                  <td style="width: 275px;">
                    <span>{{ rowData.LINE1_VAL }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 270px;">
                    <span>{{ rowData.CHOICE2A_VAL }}</span>
                  </td>
                  <td style="width: 235px;">
                    <span>{{ rowData.CHOICE2B_VAL }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 270px;">
                    <span>{{ rowData.SHOLDERNAME_VAL }}</span>
                  </td>
                  <td style="width: 305px;">
                    <span> {{ rowData.SHOLDEREIN_VAL }} </span>
                  </td>
                  <td style="width: 280px;">
                    <span> {{ rowData.CHOICE2C_VAL}} </span>
                  </td>
                  <td style="width: 275px;">
                    <span>{{ rowData.PARENTCORP_VAL }}</span>
                  </td>
                  <td style="width: 235px;">
                    <span>{{ rowData.PARENTCORPEIN_VAL }}</span>
                  </td>
                  <td style="width: 225px;">
                    <span>{{ rowData.CHOICE2D_VAL }}</span>
                  </td>
                  <td class="text-pre-wrap" style="width: 160px;">
                    <span> {{ rowData.NAMEOFPARTNERSHIP_VAL }} </span>
                  </td>
                  <td style="width: 175px;">
                    <span> {{ rowData.EINOFPARTNERSHIP_VAL }} </span>
                  </td>
                  <td  style="width: 295px;">
                    <span>{{ rowData.CHOICE3B_VAL }}</span>
                  </td>
                  <td style="width: 250px;">
                    <span>{{ rowData.CHOICE3C_VAL }}</span>
                  </td>
                  <td style="width: 455px;">
                    <span> {{ rowData.CHOICE3D_VAL }} </span>
                  </td>
                  <td style="width: 175px;">
                    <span>{{ rowData.secondaryLeid }}</span>
                  </td>
                  <td style="width: 260px;">
                    <span> {{ rowData.SECONDARYENTITY }} </span>
                  </td>
                  <td style="width: 390px;">
                    <span> {{ rowData.ISFOREIGNCORPINGOLD_VAL }} </span>
                  </td>
                  <td style="width: 240px;">
                    <span> {{ rowData.ISTRANSFEREEFOREIGNCORPCTRLD_VAL }} </span>
                  </td>
                  <td style="width: 170px;">
                    <span gtwLineSplitter [inputString]="rowData.DATEOFTRANSFER_VAL" [sepearator]="'||'" [formatter]="'date'">
                      {{ rowData.DATEOFTRANSFER_VAL }}
                    </span>
                  </td>
                  <td style="width: 300px;">
                    <span gtwLineSplitter [inputString]="rowData.TYPEOFPROPERTY_VAL" [sepearator]="'||'">
                      {{ rowData.TYPEOFPROPERTY_VAL }}
                    </span>
                  </td>
                  <td style="width: 235px;">
                    <span gtwLineSplitter [inputString]="rowData.FMVONDATEOFTRANSFER_VAL" [sepearator]="'||'" [formatter]="'decimal'">
                      {{ rowData.FMVONDATEOFTRANSFER_VAL }}
                    </span>
                  </td>
                  <td style="width: 190px;">
                    <span gtwLineSplitter [inputString]="rowData.COSTBASIS_VAL" [sepearator]="'||'" [formatter]="'decimal'">
                      {{ rowData.COSTBASIS_VAL }}
                    </span>
                  </td>
                  <td style="width: 257px;">
                    <span gtwLineSplitter [inputString]="rowData.GAINTRANSFER_VAL" [sepearator]="'||'" [formatter]="'decimal'">
                      {{ rowData.GAINTRANSFER_VAL }}
                    </span>
                  </td>
                  <td style="width: 150px;">
                    <span>{{ rowData.USEFULLIFE_VAL }}</span>
                  </td>
                  <td style="width: 316px;">
                    <span>{{ rowData.INCOMEINCLUSIONOFTRANSFER_VAL }}</span>
                  </td>
                  <td style="width: 286px;">
                    <span>{{ rowData.F926LINE11_VAL }}</span>
                  </td>
                  <td style="width: 291px;">
                    <span>{{ rowData.F926LINE12A_VAL }}</span>
                  </td>
                  <td style="width: 388px;">
                    <span>{{ rowData.F926LINE12B_VAL }}</span>
                  </td>
                  <td style="width: 331px;">
                    <span>{{ rowData.F926LINE12C_VAL }}</span>
                  </td>
                  <td style="width: 348px;">
                    <span>{{ rowData.F926LINE12D_VAL }}</span>
                  </td>
                  <td style="width: 273px;">
                    <span>{{ rowData.F926LINE13_VAL }}</span>
                  </td>
                  <td style="width: 361px;">
                    <span>{{ rowData.F926LINE14A_VAL }}</span>
                  </td>
                  <td style="width: 286px;">
                    <span>{{ rowData.F926LINE14B_VAL }}</span>
                  </td>
                  <td style="width: 367px;">
                    <span>{{ rowData.F926LINE14C_VAL }}</span>
                  </td>
                  <td style="width: 410px;">
                    <span>{{ rowData.F926LINE14D_VAL }}</span>
                  </td>
                  <td style="width: 473px;">
                    <span>{{ rowData.F926LINE15_VAL }}</span>
                  </td>
                  <td style="width: 400px;">
                    <span>{{ rowData.SUPPLEMENTALINFO_VAL }}</span>
                  </td>
                  <td style="width: 228px;">
                    <span>{{ rowData.F926LINE16A_VAL }}</span>
                  </td>
                  <td style="width: 217px;">
                    <span>{{ rowData.F926LINE16B_VAL }}</span>
                  </td>
                  <td style="width: 190px;">
                    <span>{{ rowData.F926LINE17_VAL }}</span>
                  </td>
                  <td style="width: 190px;">
                    <span>{{ rowData.F926LINE18A_VAL }}</span>
                  </td>
                  <td style="width: 190px;">
                    <span>{{ rowData.F926LINE18B_VAL }}</span>
                  </td>
                  <td style="width: 170px;">
                    <span>{{ rowData.F926LINE18C_VAL }}</span>
                  </td>
                  <td style="width: 240px;">
                    <span>{{ rowData.F926LINE18D_VAL }}</span>
                  </td>
                  <td style="width: 251px;">
                    <span>{{ rowData.F926LINE19_VAL }}</span>
                  </td>
                  <td style="width: 276px;">
                    <span>{{ rowData.F926LINE20A_VAL }}</span>
                  </td>
                  <td style="width: 276px;">
                    <span>{{ rowData.F926LINE20B_VAL }}</span>
                  </td>
                  <td style="width: 507px;">
                    <span>{{ rowData.F926LINE20C_VAL }}</span>
                  </td>
                  <td style="width: 350px;">
                    <span>{{ rowData.F926LINE21_VAL }}</span>
                  </td>
              </tr>
          </ng-template>

      </p-table>
  </div>
</div>

<dst-disc-modal-viewer *ngIf="showModalViewer" [rowObject]="activeRow" (afterModalClosed)="afterModalClosed()">
</dst-disc-modal-viewer>
