<div class="a-header gtw-header-nav ">
    <div class="a-hfn-header-container d-flex align-items-center justify-content-between  navigation-fourth-bar">

        <!-- left -->
        <div class="d-flex align-items-center">
            <div class="a-panel-wrapper">
                <!-- <img class="clientLogo" [src]="client_logo" height="36"> -->
                <!-- <img class="clientLogo" src='./assets/img/clientLogos/{{clientKey}}.png'> -->
                <img class="clientLogo" src='{{logo_client}}'>

                <ap-selector [items]="clientList" [type]="'Dropdown'" (onSelectedChange)="changeClient($event)" class="client-logo-dropdown">
                </ap-selector>
            </div>
            
            <div class="font-weight-medium a-nowrap gtw-brand-label-wrapper">{{name}}</div>

            <div class="meDropdownAndBreadcrumbsContainer">
                <div class="business-selection-container" (gtwClickOutside)="closeBusinessDropdown($event)">
                    <div class="business-selection-dropdown" [ngClass]="{'business-dropdown-active' : showBusinessMenu}" (click)="showBusinessMenu = !showBusinessMenu">
                        <span> {{selectedApplication}} </span> - <span> {{selectedTaxYear}} </span> - <span> {{selectedScenario.scenario_desc}} </span> - <span> <span class="totalMeCount">{{selected.length}}</span> Business Selected </span> <span *ngIf="!showDropdownMenu" class="appkiticon icon-down-chevron-outline business-chevron"></span> <span *ngIf="showDropdownMenu" class="appkiticon icon-up-chevron-outline business-chevron"></span>
                    </div>
                    <div class="business-selection-dropdown-data nav-panel a-navigation" *ngIf="showBusinessMenu">
                        <!-- Channel Dropdown -->
                        <div class="business-labels">
                            <span>Application</span>
                        </div>
                        <ap-selector [items]="applicationList" [type]="'SingleSelector'" (onSelectedChange)="channelSelection($event)">
                        </ap-selector>

                        <!-- Tax Year Dropdown -->
                        <div class="business-labels">
                            <span>Tax Year</span>
                        </div>
                        <ap-selector [items]="taxYearList" [type]="'SingleSelector'" (onSelectedChange)="taxYearSelection($event)">
                        </ap-selector>

                        <!-- Scenario Dropdown -->
                        <div class="business-labels">
                            <span>Scenario</span>
                        </div>
                        <ap-selector [items]="scenarioList" [type]="'SingleSelector'" (onSelectedChange)="scenarioSelection($event, true)">
                        </ap-selector>

                        <!-- Issue Id Dropdown -->
                        <div class="business-labels" *ngIf="selectedScenario.scenario_type_code == 'EXAM'">
                            <span>Issue Id</span>
                        </div>
                        <ap-selector *ngIf="selectedScenario.scenario_type_code == 'EXAM' && isIssueListLoaded" [items]="issuesList" [type]="'SingleSelector'" (onSelectedChange)="issueSelection($event)">
                        </ap-selector>
    
                        <div class="business-labels">
                            <span>Business</span> <span style="margin-left: 88px;cursor: pointer;" (click)="selectAll()">Select All</span> <span style="margin-left: 36px;cursor: pointer;" (click)="unselectAll()">Unselect All</span>
                        </div>
    
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="loader" *ngIf="filterss.loading"></div>
                        </div>
    
                        <div class="global-me-container" style="width: 41%;" *ngIf="!filterss.loading">
                            <div class="me-container a-my-6" *ngFor="let me of menuObj1">
                                <ap-checkbox class="" [text]="me.name" [checked]="me.selected" [value]="me.selected" (onCheck)="selectedMe($event, me, true);">
                                </ap-checkbox> <span class="subMeCount float-right" *ngIf="me.children && me.numSelected > 0">{{me.numSelected}}</span>
                            </div>
                            <div class="sub-me-container customScroll" *ngIf="showSubBusinessMenu">
                                <div class="a-my-6" *ngFor="let subMe of childMenuObj">
                                    <ap-checkbox class="a-mx-8" [text]="subMe.name" [checked]="subMe.selected" (click)="selectedMe($event, subMe, false);">
                                    </ap-checkbox>
                                    <div class="sub-me-child-container" *ngIf="subMe.children">
                                        <div class="a-my-6" *ngFor="let subChildMe of subMe.children">
                                            <ap-checkbox class="a-my-8" [text]="subChildMe.name" [checked]="subChildMe.selected" (click)="selectedMe($event, subChildMe, false);">
                                            </ap-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcrumbsBlock">
                    <!-- <ap-breadcrumbs type="page" [data]="breadcrumbsDataPage" [showHome]="true" (onClickItem)="clickItem($event)">
                    </ap-breadcrumbs> -->
                    <!-- {{breadCrumbLink}} -->
                <div class="gtw-app-nav-bread-crumbs">
                    <span *ngFor= "let label of breadCrumbLink;let i = index">
                        {{label}}
                    </span>
                </div>
                </div>
            </div>
            <div class="mask" (click)="showDropdownMenu = false" *ngIf="showDropdownMenu"></div>
        </div>

        <!-- right logo -->
        <div class="d-flex align-items-center a-pl-10" style="padding-right: 16px;" (gtwClickOutside)="isNotifPanel = false; showNotificationsPanel = false">
            <div class="d-sm-flex d-none">
                <gtw-external-link [base-urls]="baseURLs" [tooltip-container]="'External Apps'" [placement]="'top'"></gtw-external-link>
                <!--Workspace Icon-->
                <span class="link-icon" tabindex="0">
                    <span class="appkiticon icon-building-outline" (click)="isActive('client-landing-page')" [tooltip-container]="'Clients'" [placement]="'top'"></span>
                </span>
                <!--Messages Icon-->
                <span class="link-icon" tabindex="0" (click)="isActive('messages')">
                    <span class="appkiticon icon-email-outline" [tooltip-container]="'Messages'" [placement]="'top'"></span>
                    <ap-badge class="a-mr-10" [badgeText]="messageCount"></ap-badge>
                </span>
                <!--Bell Notification Icon-->
                <span class="link-icon" tabindex="0" (click)="showNotificationsPanel = !showNotificationsPanel" [tooltip-container]="'Favourites'" [placement]="'top'">
                    <!-- <span class="appkiticon icon-star-outline" [tooltip-container]="'Favourites'" [placement]="'top'"></span> -->
                    <!-- <svg id="pin-icon-outline" width="2rem" height="1.5rem" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;right: 2px;cursor:pointer;">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path fill="#cf3f22" id="Icon" d="m 19.933963,26.415565 c 2.717705,-2.718753 3.566746,-6.597866 2.545198,-10.041364 l 2.225241,-2.22531 2.214641,2.214713 4.429276,-4.429419 L 20.104543,0.69005442 15.675261,5.1194724 17.888942,7.3341813 15.664659,9.5594916 C 12.221275,8.5379113 8.3413166,9.3860157 5.6236122,12.10477 l -2.0450243,2.044121 6.1331454,6.133336 -8.77953412,8.779812 c -0.56474284,0.564761 -0.56474284,1.48033 0,2.044124 0.56474282,0.564758 1.48028172,0.564758 2.04502432,0 l 8.7795355,-8.779807 6.132183,6.133337 z" class="pin-outline-icon-stroke" style="fill: #cf3f22; fill-opacity: 1; stroke-width: 0.963736; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1; paint-order: fill markers stroke;">
                        </path>
                        </g>
                    </svg> -->

                    <svg width="2rem" height="1.6rem" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;right: 0px;cursor:pointer;">
                        <g stroke="gray" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path fill="#999999" id="Icon" d="m 19.933963,26.415565 c 2.717705,-2.718753 3.566746,-6.597866 2.545198,-10.041364 l 2.225241,-2.22531 2.214641,2.214713 4.429276,-4.429419 L 20.104543,0.69005442 15.675261,5.1194724 17.888942,7.3341813 15.664659,9.5594916 C 12.221275,8.5379113 8.3413166,9.3860157 5.6236122,12.10477 l -2.0450243,2.044121 6.1331454,6.133336 -8.77953412,8.779812 c -0.56474284,0.564761 -0.56474284,1.48033 0,2.044124 0.56474282,0.564758 1.48028172,0.564758 2.04502432,0 l 8.7795355,-8.779807 6.132183,6.133337 z" class="pin-outline-icon-stroke" style="fill: #999999; fill-opacity: 0; stroke-width: 0.963736; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1; paint-order: fill markers stroke;">
                            </path>
                        </g>
                    </svg>
                    <!-- <ap-badge class="a-mr-10" [badgeText]="'4'"></ap-badge> -->
                </span>
                <div class=" dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one" [ngClass]="{'show':showNotificationsPanel}" style="right: 100px !important; left: inherit; top: 3rem !important;">
                    <div class="dropdown-title">
                        My Pins
                    </div>
                    <ul class="user-infor-list" cdkDropList (cdkDropListDropped)="onDrop($event)">
                        <!-- <gtw-favorite-button
                        [client-key]="clientKey" [base-url]="baseUrl"
                        [(screen)]="screenDetails">
                        </gtw-favorite-button> -->
                        <div *ngFor="let screen of favScreens">
                            <li (click)="loadClickedScreen(screen)" class="user-infor-list-item" style="display: flex;" cdkDrag>
                                <div class="ellipsis-wrapper">
                                    <img class= "img-class" src="assets/img/tile-view-icons/Batch-Import.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "2"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/Dashboards.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "9"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/Diagnostics.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "7"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/Form-schedule.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "3"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/Report.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "1"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/setup-mapping.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "6"'/>
                                    <img class= "img-class" src="assets/img/tile-view-icons/sourcing.png" *ngIf='screen.SCREENS_CATEGORY_LIST == "5"'/>
                                    <span>{{screen.SCREEN_NAME}}</span>
                                </div>
                                <span class="actionIcons">
                                    <span>
                                        <span class="material-icons screen-icon pinIcon">push_pin</span>
                                    </span>
                                    <span class="appkiticon icon-menu-outline outlineIcon"></span>
                                </span>
                            </li>
                        </div>
                        <!-- <ng-container *ngIf="activeSysMsgData.length == 0">
                            <li class="user-infor-list-item">No notifications to display.</li>
                        </ng-container> -->
                    </ul>
                    <div class=""></div>
                </div>
                <!--Global Search Icon-->
                <!-- <span class="link-icon" tabindex="0">
                    <span class="appkiticon" gt-global-search [tooltip-container]="'Search'" [placement]="'top'"></span>
                </span> -->
                <!--Help Icon-->
                <span class="link-icon" tabindex="0">
                    <span class="appkiticon icon-help-question-outline" (click)="isActive('help')" [tooltip-container]="'Help'" [placement]="'top'"></span>
                </span>
                <!--Themes Icon-->
                <span class="link-icon" tabindex="0" [tooltip-container]="'Themes'" [placement]="'top'">
                    <span *ngIf="isDarkTheme" class="appkiticon icon-brightness-outline customMarginRight" (click)="setTheme('white')"></span>
                    <span *ngIf="!isDarkTheme" class="appkiticon material-icons customMargin" (click)="setTheme('black')">dark_mode</span>
                </span>
                
            </div>
            
            <div class="a-logout show">
                <span class="a-icon appkiticon icon-down-chevron-fill dp-menu-drop-icon" (click)="showLogoutMenu()"></span>
                
                <button class="user-name a-bg-primary size-25" (click)="showLogoutMenu()">{{userInitials}}
                    <!-- <img class="userLogo" [src]="user_logo" height="36"> -->
                    <!-- <img alt="User1" ng-if="user.avatar_path!=null" height="50"
                        width="60" cx="28" cy="26" r="20" x="46%" y="55%" font-size="20"
                        profile-image="{{userSettingsData.user.avatar_path}}" style="margin-right :8px;margin-top:0px"
                        title="User1"> -->
                </button>
                
                <div class="dropdown-menu dropdown-menu-right a-shadow-sm border-0 a-user-setting-panel-one"
                    [ngClass]="{'show':logoutMenu}">
                    <div class="dropdown-title">
                        Welcome {{display_name}}
                    </div>
                    <ul class="user-infor-list">
                        <ng-container *ngFor="let item of logoutMenuData">
                            <li class="user-infor-list-item" (click)="isActive(item.url)">{{item.title}}</li>
                        </ng-container>
                    </ul>
                    <div class="user-logout">
                        <button class="sign-out-btn a-btn a-btn-primary a-btn-sm" (click)="singOut()" (keydown)="logoutMenuClose($event)">LOG
                            OUT</button>
                    </div>
                </div>
                <div class="mask" (click)="logoutMenu = false" *ngIf="logoutMenu"></div>
            </div>
            <div class="a-nav-toggler d-sm-none d-flex">
                <a class="a-link-no-interaction a-nav-toggler-icon-wrapper text-right ml-2"
                    (click)="showNavTogglerDiv()" (keyup.enter)="showNavTogglerDiv()" tabindex="0" (keydown.tab)="navTogglerDivClose($event)">
                    <span class="appkiticon icon-vertical-more-fill"></span>
                </a>
                <ng-container *ngIf="navTogglerDiv">
                    <div class="dropdown-menu dropdown-menu-right a-shadow-sm a-user-setting-panel-one w-25"
                        style="display: flex;">
                        <ul class="user-infor-list border-0">
                            <li class="user-infor-list-item" (click)="activeNavToggler()">
                                <span class="appkiticon icon-help-question-outline"></span> Feature 1
                            </li>
                            <li class="user-infor-list-item" (click)="activeNavToggler()">
                                <span class="appkiticon icon-survey-outline"></span> Feature 2
                            </li>
                            <li class="user-infor-list-item" (click)="activeNavToggler()">
                                <span class="appkiticon a-font-14 icon-grid-view-outline"></span> Feature 3
                            </li>
                        </ul>
                    </div>
                    <div class="mask" (click)="navTogglerDiv = false"></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>