import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SubTasksContainerComponent } from './sub-tasks-container.component';
import { SubMenusComponent } from './sub-menus/sub-menus.component';
import { ActivityAndScreenComponent } from './sub-menus/activity-and-screen/activity-and-screen.component';
import { AccordionsModule } from 'appkit-angular/accordions';
import { AnimationModule } from 'appkit-angular/animation';
@NgModule({
  declarations: [
    SubTasksContainerComponent, 
    SubMenusComponent, 
    ActivityAndScreenComponent,
    
  ],
  imports: [
    CommonModule,
    AccordionsModule,
    AnimationModule,
    FormsModule
  ],
  providers:[],
  exports: [SubTasksContainerComponent],
})
export class SubTasksContainerModule { }
