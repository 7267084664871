import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GhostUpdateCombinationService } from './ghost-update-combination.service';

@Component({
	selector: 'gtw-ghost-update-combination',
	templateUrl: './ghost-update-combination.component.html',
	styleUrls: ['./ghost-update-combination.component.scss']
})
export class GhostUpdateCombinationComponent implements OnInit {
	baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;

	baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

	private baseURL = '/custom';
	private baseURLGtw = '/gtw';
	private ssoId: string = '';
	private checkLockedObj: any = [];
	private scenarioCode: string = '';
	private filterParams : any= [];
	enableButton: boolean = true;
	private modalName: string = '';
	clob_settings: { tax_year: number; scenario: number; jcd_key: number; parent_me_string: string; ho_leid_opt: string; filing_key: number;} = {
		tax_year: 0,
		scenario: 0,
		jcd_key: 0,
		parent_me_string: '',
		ho_leid_opt: '',
		filing_key: 0
	};
	clob_data: any = [];

	@Input('base-urls')
		set _base_urls(baseUrls: string){
			try{
				let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
				if(Object.keys(urls).length > 0){
					this.baseURLs = urls;
					//this.init();
				}
			}catch(e){
				console.log("Input Not yet stable. Still waiting to get resolved.");
			}
		}

		@Input('global-params')
		set _global_params(globalParams: string){
			try{
				let params = JSON.parse(globalParams);
				if(params != undefined){
					this.baseGlobalParams = params;
				}
				//this.init();
			}catch(e){
				console.log("Global Params not stable. Still waiting to get resolved.");
			}
		}
	@Input('filter-params')
		set _filter_params(filterParams: string){
		try{
				let params = JSON.parse(filterParams);
				if(params != undefined){
					this.filterParams = params;
				}
				//this.init();
			}catch(e){
				console.log("Filter Params not stable. Still waiting to get resolved.");
			}
		}
	@Input('check-locked-obj')
	set _checkLockedObj(checkLockedObj:any){
		this.checkLockedObj = checkLockedObj;
	}

	@Input('modal-name')
	set _modalName(modalName:string){
		this.modalName = modalName;
		//his.init();
	}

	@Input("sso-id")
	set _ssoId(ssoId:string) {
		try{
		this.ssoId = ssoId;
		this.init();
		}catch(error){
			console.log("Not an valid object");
			}
	}

	@Output('close-ghostUpdateCombinationconfirm-info')
	closefirTestedUnitsconfirmInfo = new EventEmitter<string>();

	@Output('save-ghostUpdateCombinationsync-info')
	savefirTestedUnitsSyncInfo = new EventEmitter<string>();

	subscriptions:Subscription = new Subscription() ;
	constructor(private GhostUpdateCombinationService: GhostUpdateCombinationService, private _snackBar: MatSnackBar) { }

  checkInputType():boolean{
		if(
		  Number.isInteger(parseInt(this.ssoId+"") )
    )
		{
		  return true;
		}
	
		return false;
	}


  ngOnInit(): void {
  }

  init() {   
    if(this.checkInputType()){
      }
    } 


    cancel() {
      console.log("click close");
      this.closefirTestedUnitsconfirmInfo.emit('close-ghostUpdateCombinationconfirm-info');
      }

    syncTestedUnits(){
        this.enableButton = false;
        let processName = 'RUN_SYNC_TESTED_UNITS';
        
        this.clob_settings = {
          tax_year: this.filterParams.tax_year,
          scenario: this.filterParams.scenario,
          jcd_key: this.filterParams.jcd_key,
          parent_me_string: this.filterParams.me_string == undefined ? null : this.filterParams.me_string,
          ho_leid_opt: this.filterParams.ho_leid_opt == undefined ? null : this.filterParams.ho_leid_opt,
          filing_key: this.filterParams.filing_key
        }; 
        let tempData = {
          TAX_YEAR : this.filterParams?.tax_year,
          SCENARIO : this.filterParams?.scenario,
          JCD_KEY : this.baseGlobalParams?.jcd_key,
          SSO_ID: this.ssoId,
		  FILING_KEY: this.filterParams.filing_key
        }
        
      let updateSubscription = this.GhostUpdateCombinationService.synctestedUnits(this.baseURLs.api, tempData, this.clob_settings, this.clob_data, processName, this.baseGlobalParams, this.checkLockedObj).subscribe(
            (data) => {
              if (data.callSuccess == "1") {
                this.GhostUpdateCombinationService.openSnackBar('Settlement Redirection has been successfully updated.','gtw-snackbar--green');
                this.savefirTestedUnitsSyncInfo.emit('save-ghostUpdateCombinationsync-info');
              } else {
                this.GhostUpdateCombinationService.openSnackBar('Error updating combination key. '+data.errorMessage, 'gtw-snackbar--red');
                this.enableButton = true;
				this.closefirTestedUnitsconfirmInfo.emit('close-ghostUpdateCombinationconfirm-info');
              }
            },  error => { this.GhostUpdateCombinationService.openSnackBar('Error updating combination key. '+error.error.errorMessage, 'gtw-snackbar--red'); },
            () => { console.log("Update Completed");}
          );
            this.subscriptions.add(updateSubscription);
     };

}
