
<ap-modal #baseModalLargest   appModalId="show-deatil-diag" >
    <div #modalRef slot="content" (click)="$event.stopPropagation();onModalClick()" class="deatil-view-dialog">
        <div class="modal-dialog a-modal-xl" role="document" tabindex="-1">
            <div #viewHeight class="modal-content" *ngIf="tableRowData !=undefined">
                <div class="a-modal-header">
                    <div class="flex-fill d-flex align-items-center">
                        <div style="display:flex">
                            <h4 style="padding-top:5px">{{tableRowData.DIAG_DESC}}</h4>
                            <span style="padding:7px 10px 3px 10px;font-size:17px"> - Criticality: <span *ngIf="(tableRowData.DIAG_MSG === 'Critical') || 
                                    (tableRowData.DIAG_MSG  === 'Informational')" class="appkiticon"     
                                        [ngClass]="{'icon-alert-fill': tableRowData.DIAG_MSG === 'Critical',
                                                    'icon-information-fill': tableRowData.DIAG_MSG === 'Informational'}"
                                        [ngStyle]="{'color': tableRowData.DIAG_MSG === 'Critical' ? '#D04A02' : '#212121'}">
                            </span>
                            <span  [ngStyle]="{'color': tableRowData.DIAG_MSG === 'Critical' ? '#D04A02' : '#212121'}">
                               {{ tableRowData.DIAG_MSG}} 
                            </span>
                            
                            </span>
                            <span style="padding: 7px 7px 7px 0px;font-size: 17px;"> - Tax Year: {{tableRowData.TAX_YEAR}} </span>
                        </div>
                    </div>
                </div>
                <!-- style="padding:10px;height:620px" -->
                <div class="a-modal-body pt-0"  style="overflow-y:auto;height:100%;">
                    <div class="row">
                        <div class="col-md-9 a-p4 a-mt-8 desc">
                             Diagnostic Description
                        </div>
                        <div class="col-md-3 a-p4" style="display: flex; justify-content: end;  padding: 8px;">
                        </div>
                        <!-- [class.show]="show" -->
                        <span class="detail-desc"  [class.show]="show">
                            <p style="margin-bottom:0.5rem;" > 
                                {{tableRowData.DIAG_DESCRIPTION != null ? tableRowData.DIAG_DESCRIPTION : 'Not Found'}}
                        
                            </p>
                            
                        </span>
                        <a style="font-weight: 600; background:transparent;padding: 8px 0px 0px 0px;cursor:pointer"
                                *ngIf="tableRowData.DIAG_DESCRIPTION != null"
                                (click)="show = !show">{{ show ? 'Read less': 'Read More' }}</a>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-9 a-p4 a-mt-8 resolution" >
                             Diagnostic Resolution
                        </div>
                        <div class="col-md-3 a-p4" style="display: flex; justify-content: end;  padding: 8px;">
                        </div>
                        <!-- [class.show_resolution]="show_resolution" -->
                        <span class="detail-resolution"  [class.show_resolution]="show_resolution">
                           <p style="margin-bottom:0.5rem;" > 
                                {{tableRowData.DIAG_RESOLUTION != null ? tableRowData.DIAG_RESOLUTION : 'Not Found'}}
                            </p>
                           
                        </span>
                        <a style="font-weight: 600;background:transparent;padding: 8px 0px 0px 0px;cursor:pointer"
                            *ngIf="tableRowData.DIAG_RESOLUTION != null"
                            (click)="show_resolution = !show_resolution">{{ show_resolution ? 'Read less': 'Read More' }}</a>
                       
                    </div>

                    <div class="row" >
                        <div class="col-md-7 a-p4 a-mt-8 title">Entitle Population Failing {{tableRowData.DIAG_DESC}} Out Diagnostic</div>
                        <div class="col-md-5" style="display: flex;
                        justify-content: flex-end;">
                            <!-- <ap-button [btnText]="'Clear Diagnostic'" class="a-mr-10" [config]="primaryConfig"
                            (onBtnClick)="closeModalCustom('show-custom-modal')">
                            </ap-button> -->
                            <ap-button [btnText]="'CANCEL'" class="a-mr-10" [config]="primaryConfig" [btnType]="'a-btn-gray'" 
                            (onBtnClick)="closeModalCustom('show-custom-modal')">
                            </ap-button>
                        </div>
                    </div>
                    <div class="row" style="margin-top:8px" *ngIf="diagData && diagData.safeCopyArr">
                        <span>Total Records: {{diagData.safeCopyArr.length}}</span>
                        <span style="margin-left: 20px;" *ngIf="colSearch.length>0">Filterd: {{diagData.data.length}}</span>
                    </div>

                    <!-- [loading]="!gridLoading" scrollHeight="750px"-->
                    <div style="margin-top:20px;display:block">
                        <p *ngIf="gridLoading">Loading grid data...</p>
                        <ap-loading id="overlay" *ngIf="gridLoading" [size]="'24px'" [colorName]="'a-primary'">
                        </ap-loading>                         

                    <p-table *ngIf="!gridLoading" 
                        #dt                       
                        [value]="diagData.data"
                        [scrollable]="true"
                        [columns]="diagData.header"
                        scrollDirection="both" 
                        styleClass="p-datatable-gridlines p-datatable-striped" 
                        [virtualScroll]="true" [virtualRowHeight]="40"
                        [scrollHeight]= "scrollheight + 'px'"
                        scrollWidth="100%"
                    >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <!-- 'table-width': tableRowData.DIAG_KEY == 40002 || tableRowData.DIAG_KEY == 40001 -->
                                <ng-container *ngFor="let col of columns; index as i" >
                                    <th [width]="col.width +'px'" style="overflow: visible;" *ngIf="col.display =='y' && col.map != 'object_id'" [pSortableColumn]="col.map"
                                        [ngClass]="{'color-red': tableRowData.DIAG_NAME == col.map}">
                                        <span  style="padding: 5px 8px 0px 8px">{{ col.label }}</span>
                                        <p-sortIcon [field]="col.map"></p-sortIcon>
                                    </th>                                     
                                </ng-container>                            
                            </tr>
                            <tr>
                                <!--  (onBlur)="hideDropDown(i,$event)"  (gtwClickOutside)="hideDropDown(i,$event)" -->
                                <!-- [ngClass]="{'table-width': tableRowData.DIAG_KEY == 40002 || tableRowData.DIAG_KEY == 40001}" -->
                               <ng-container *ngFor="let col of filterOptions; index as i" >
                                <th [width]="col.width +'px'" style="overflow: visible;" *ngIf="col.key != 'object_id'"
                                     > 
                                    <p-multiSelect #mutlSelect styleClass="p-column-filter"  (onChange)="filterMultiSelectCol($event, col,i)" [virtualScroll]="true" [itemSize]="30"
                                        [options]="col.values" [(ngModel)]="filterOptions[i].filterUserInput" [filter]="true" optionLabel="value"    
                                        (click)="openMultiDropDown(i)">
                                    </p-multiSelect>
                                </th>
                               
                               </ng-container>
                            </tr>
                        </ng-template>
                      
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                            <tr >
                                <ng-container *ngFor="let col of columns; index as i" >
                                    <td  [width]="col.width +'px'"  *ngIf="col.display =='y' && col.map != 'object_id'" 
                                        [height]="diagData.settings.default_row_height <= 30 ? 38 : diagData.settings.default_row_height + 'px'"
                                        [ngClass]="{'text-align-right': col.data_type == 'number' && col.format == 'numberWithCommas'
                                                    }"
                                       >
                                        <span *ngIf="col.drilldown != null && col.format == 'color-dot'"style="padding: 5px 8px 0px 8px" class="appkiticon icon-link-outline link"
                                        (click)="redirectDrillDown(rowData,i)" >
                                            <!-- <ap-badge style="margin-left:5px"*ngIf="tableRowData.DIAG_NAME == col.map && rowData[col.map] == 1"class="a-mr-10" [badgeText]="'No Error'" [badgeType]="'success'"></ap-badge>
                                            <ap-badge style="margin-left:5px"*ngIf="tableRowData.DIAG_NAME == col.map && rowData[col.map] == 0"class="a-mr-10" [badgeText]="'Error'" [badgeType]="'error'"></ap-badge> -->
                                            <!-- <span [ngClass]="{'text-red': rowData[col.map] < 0 }">rowData.AMT | number:'1.0-0' </span>     -->
                                            <span *ngIf="tableRowData.DIAG_NAME == col.map" style="padding-left:5px;color:#495057">
                                                    <!-- {{rowData.AMT ? rowData.AMT: 'yes'}} -->
                                                    <span *ngIf="rowData.AMT != null" [ngClass]="{'text-red': rowData.AMT < 0 }"> {{rowData.AMT | number:'1.0-0'}}</span>
                                                    <span *ngIf="rowData.AMT == null" > Yes </span>
                                            </span>
                                        </span>
                                        <span *ngIf="col.drilldown != null && col.format != 'color-dot'">
                                            <span [ngClass]="{'appkiticon icon-link-outline link':col.drilldown!=null}"
                                                
                                                style="padding: 5px 8px 0px 8px"
                                                (click)="redirectDrillDown(rowData,i)"> </span>
                                               <span> {{rowData[col.map]}}    </span>                                 
                                           
                                        </span>
                                        <!-- && col.data_type != 'number' -->
                                        <span *ngIf="col.drilldown == null && col.format != 'numberWithCommas'">{{rowData[col.map]}} </span>
                                        <span *ngIf="col.drilldown == null && col.data_type == 'number' && col.format == 'numberWithCommas'"
                                                [ngClass]="{'text-red': rowData[col.map] < 0 }">
                                                {{rowData[col.map] | number:'1.0-0' }} </span>

                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="columns.length" style="text-align: center">
                                    No records found
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ap-modal>
