import { TableTitle } from "../models/table.model";

export let taxYears = [
    {
      data:[
        {
          value: 2020,
          text: '2020'
        },
        {
          value: 2019,
          text: '2019'
        }
      ]
    }
  ];

export let returnTypes = [
    {
      data:[
        {
          value: '1120',
          text: '1120'
        },
        {
          value: '1120F',
          text: '1120F'
        },
        {
          value: '1065',
          text: '1065'
        },
        {
          value: '7004',
          text: '7004'
        }
      ]
    }
  ];

  export let actionsDropDown = [
    {
      data:[
        {
          value: 'uploadIRSSchema',
          text: 'Upload IRS Schema'
        }
      ]
    }
  ];
 
  export let tableColumnConfig: TableTitle[] = [
    {
      colHeader: 'Schema Set ID',
      sort:{
        sortBy: 'SCHEMA_SET_ID',
        descendant: true,
        defaultSorted: true
      }
    },
    {
      colHeader: 'Tax Year',

    },
    {
      colHeader: 'Schema Version',

    },
    {
      colHeader: 'Return Type',

    },
    {
      colHeader: 'Schema Agency',
    },
    {
      colHeader: 'Schema Root',
    },
    {
      colHeader: 'Main Element',
    },
    {
      colHeader: 'Namespace',
    },
    {
      colHeader: 'Elements Count',
    }
  ];