import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isObject } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { S3FileUploadInput } from '../s3-file-upload/s3-file-upload.component';
import { S3FileUploadService } from '../s3-file-upload/s3-file-upload.service';
import { S3FolderManagerService } from './s3-folder-manager.service';

export interface S3FolderManagerInput {
  isReadOnlyMode: boolean;
  title?: string;
  clientKey: string;
  baseUrls: {
    admin:  string;
    api: string;
  };
  canDelete: boolean;
  canUploadMultipleFiles: boolean;
  path: string;
  readOnlyS3Paths: string;
}
@Component({
  selector: 'gtw-s3-folder-manager',
  templateUrl: './s3-folder-manager.component.html',
  styleUrls: ['./s3-folder-manager.component.scss']
})
export class S3FolderManagerComponent implements OnInit {

  s3FileUploadInput: S3FileUploadInput | string = '';
  attachments$: Observable<any>;
  uploadTabIndex: number = 0;
  isDownloading: boolean = false;
  isDeleting: boolean = false;
  showUploadModal: boolean = false;
  ssoId: string = '';
  s3FolderManagerInput!: S3FolderManagerInput;


  @Input('s3FolderManagerInput')
  set setS3FolderManagerInput(s3FolderManagerInput: string | S3FolderManagerInput) {
    if (isObject(s3FolderManagerInput)) {

      this.s3FolderManagerInput = s3FolderManagerInput;
      this.init();

    } else {
      try {
        this.s3FolderManagerInput = JSON.parse(s3FolderManagerInput);

        this.init();
      } catch (e) {
        console.log(
          'base URLs Params not stable. Still waiting to get resolved.'
        );
      }
    }
  }

  @Output('onFileUpload')
  onFileUploadEvent = new EventEmitter<string>();

  @Output('onFileDelete')
  onFileDeleteEvent = new EventEmitter<string>();

  constructor(
    private s3Service: S3FileUploadService,
    private s3FolderManagerService: S3FolderManagerService
  ) {
    this.s3FolderManagerInput = {
      isReadOnlyMode: true,
      clientKey: '',
      baseUrls: {
        admin: '',
        api: ''
      },
      path: '',
      readOnlyS3Paths:'',
      canDelete: false,
      canUploadMultipleFiles: false,
    }

    this.attachments$ = s3FolderManagerService.getAttachments().pipe(
      tap((attachments) => {
        if (attachments?.length !== 0 || this.s3FolderManagerInput.isReadOnlyMode)
          this.uploadTabIndex = 0;
        else
          this.uploadTabIndex = 1;
      })
    );
  }

  ngOnInit(): void {

  }

  init() {
    this.s3FileUploadInput = {
      rootFolder: 'gtw',
      baseUrl: this.s3FolderManagerInput.baseUrls.admin,
      clientKey: this.s3FolderManagerInput.clientKey,
      path: this.s3FolderManagerInput.path,
      readOnlyS3Paths: this.s3FolderManagerInput.readOnlyS3Paths,
      canEditPath: false,
      onlyCore: false,
      hidePath: true,
      title: this.s3FolderManagerInput.title
    };

  }

  downloadFileFromS3(file: any) {
    file.isDownloading = true;
    this.s3Service
      .downloadFile(
        file.FILE_KEY,
        this.s3FolderManagerInput.clientKey,
        this.s3FolderManagerInput.baseUrls.admin
      ).subscribe(() => {
        file.isDownloading = false;
      });
  }

  deleteAttachment(file: any) {
    this.onFileDeleteEvent.emit(file);
  }

  searchAttachments(searchText: string) {
    this.s3FolderManagerService.searchAttachments(searchText);
  }

  onFileUpload(uploadResponseString: string) {
    this.onFileUploadEvent.emit(uploadResponseString);
  }
}
