import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DfAssignEntityService {
	getUrls: { [key: string]: string } = {
		getDfEntity: '/loadJsonObject?action_code=8qf6lh',
		getPship: '/loadJsonObject?action_code=7lr0qp'
	}

	getPtrUrl = '/loadJsonObject?action_code=l8ewpa';

	url = {
		saveDfEntity: '/saveJsonObject?action_code=o8kizh',
	}

	constructor(private httpClient: HttpClient, private gtwSnackbarService: GtwSnackbarService) { }


	getEntities(baseURL: string, params: any): Observable<any[]> {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers = headers.append('withCredentials', 'true');

		let httpParams = new HttpParams();
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				httpParams = httpParams.set(key, params[key]);
			}
		}

		let observables: Observable<any>[] = [];
		for (const key in this.getUrls) {
			if (this.getUrls.hasOwnProperty(key)) {
				const url = `${baseURL}${this.getUrls[key]}`;
				observables.push(
					this.httpClient.get(url, { params: httpParams, headers: headers })
				);
			}
		}

		return forkJoin(observables);
	}

	fetchPtrDropdown(pshipCCKey: string, baseUrl: string, params: any): Observable<any>  {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers = headers.append('withCredentials', 'true');
		let httpParams = new HttpParams();
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				httpParams = httpParams.set(key, params[key]);
			}
		}
		httpParams = httpParams.set("pship_cc_key", pshipCCKey);
		const ptrDropdownUrl = `${baseUrl}${this.getPtrUrl}`;
		return this.httpClient.get(ptrDropdownUrl, { params: httpParams, headers: headers }).pipe(
			map(ptrDropdownResult => {
				return ptrDropdownResult;
			})
		);
	}

	saveEntityAssign(baseURL: string, params: any, jsonSettings: any, jsonData: any, scenarioCode: string, isIssueKeyEnabled: string) {

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('clob_settings', JSON.stringify([jsonSettings]));
		body.append('clob_data', JSON.stringify(jsonData));
		body.append('tax_year', params.tax_year ? params.tax_year.toString() : '');
		body.append('scenario', params.scenario ? params.scenario.toString() : '');
		body.append('jcd_key', params.jcd_key ? params.jcd_key.toString() : '');
		body.append('process_name', 'save_df_entity_assign');
		body.append('is_issue_key_enabled', isIssueKeyEnabled);
		body.append('scenarioCode', scenarioCode);


		const url = `${baseURL}${this.url.saveDfEntity}`;


		return this.httpClient.post(url, body, { headers }).pipe(
			map((res: any) => {
				if (res.callSuccess == "1") {
					if (res.errorMessage != "null") {
						this.gtwSnackbarService.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					}
					this.gtwSnackbarService.openSnackBar("Data Saved Successfully", 'gtw-snackbar--green');
					return res;
				} else {
					this.gtwSnackbarService.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					return "error";
				}
			}
			));
	}

}