<span class="link-icon" tabindex="0" (gtwClickOutside)="isShowing = false">
    <span (click)="isShowing = !isShowing">
        <span class="appkiticon icon-grid-view-outline"></span>
        <span class="a-icon appkiticon icon-down-chevron-fill dropIcon"></span>
    </span>
    <div class="dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one" 
        [ngClass]="{'show':isShowing}" >
        <!-- <div class="dropdown-title">
            External Applications
        </div> -->
        <ul class="app-info-list">
            <ng-container *ngFor="let app of externalAppList">
                <li class="app-info" (click)="openApp(app.app_url)">{{app.app_name}}</li>
            </ng-container>
            <ng-container *ngIf="externalAppList.length == 0">
                <li><i>No external application listed.</i></li>
            </ng-container>
        </ul>
        <div class=""></div>
    </div>
</span>
