/*NOTE: f1118-schd-c component is common for CDE schedules, making changes here will affect all the CDE schedules. 
Incase adhoc feature is required for E or any change of requirement in C/D, consider spinning a new component for the schd*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { F1118SchdCDEService } from '../f1118-schd-c.service';
import { ThComponent } from 'appkit-angular/table/public_api';

@Component({
  selector: 'gtw-f1118-schd-c',
  templateUrl: './f1118-schd-c.component.html',
  styleUrls: ['./f1118-schd-c.component.scss'],
  providers: [ CurrencyPipe ]
})
export class F1118SchdCComponent implements OnInit {


  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;

  baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
  
  private comboKey :number = 0;
  private baseURL = '/custom';
  private baseURLGtw = '/gtw';
  private taxYear :number = 0;
  private scenario :number = 0;
  private jcdKey :number = 0;
  private screenKey :number = 0;
  private ssoId: string = '';
  private scenarioCode: string = '';
  private checkLockedObj: any = [];
  private rowData: any;
  private schdType: string = '';
  private isInit: boolean = false;
  
  @Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}

  @Input('check-locked-obj')
  set _checkLockedObj(checkLockedObj:any){
    this.checkLockedObj = checkLockedObj;
  }

  @Input('row-data')
  set _rowData(rowData:any){
	try{
		let params = JSON.parse(rowData);
		if(params != undefined){
			this.rowData = params;
			this.init();
			console.log("rowData stable: " + this.rowData);
		}
	}catch(e){
		console.log("rowData not stable. Still waiting to get resolved.");
	}
  }

  @Input('schd-type')
  set _schdType(schdType:string){
	try{
		this.schdType = schdType;
	}catch(e){
		console.log("schdType not stable. Still waiting to get resolved.");
	}
  }

  @Input('panel-header')
  set _panelHeader(panelHeader:string){
	try{
		this.panelHeader = panelHeader;
	}catch(e){
		console.log("panelHeader not stable. Still waiting to get resolved.");
	}
  }
  

  @Input('tax-year')
  set _taxYear(taxYear:number){
    this.taxYear = taxYear;
    this.init();
  }

  @Input('scenario')
  set _scenario(scenario:number){
    this.scenario = scenario;
    this.init();
  }

  @Input('jcd-key')
  set _jcdKey(jcdKey:number){
    this.jcdKey = jcdKey;
    this.init();
  }

  @Input('cc-key')
	set _comboKey(comboKey:number){
	  this.comboKey = comboKey;
	  this.init();
	}

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    this.ssoId = ssoId;
    this.init();
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode:string) {
    this.scenarioCode = scenarioCode;
    this.init();
  }

  @Output('open-f1118-schd-c')
  openF1118SchdCNewModal = new EventEmitter<string>();

  @Output('save-f1118-schd-c')
  saveF1118SchdCInfo = new EventEmitter<string>();

  

  constructor(private f1118SchdCService: F1118SchdCDEService, private currencyPipe: CurrencyPipe , private _snackBar: MatSnackBar) { }

  issueIdList : any = [
		{
			data: []
		}
	];
  
  
	SavedData : any = [];
	lineColDtls: any = [];
	basketList: any = [{ data: [] }];
	selectedBasketId: any = 0;
	fieldsPerRow: any = 2;
	noOfFields: any = 40;//FIXME:K ASSIGN IT DYNAMIC
	tempGroupData : any = { };
	tableData = false;
	isCustomOverrideWindow:boolean = false;
	isEdit:boolean = false;
	isDeltaEdit:boolean = false;
	isViewOnly:boolean = false;
	processedTableData: any = [];
	isValidationError:boolean = false;
	errorMessages:any=[];
	editedSno:any = 0;
	isDataChanged:boolean = false;
	isTableLoading:boolean = false;
	isModalLoading = false;
	panelHeader:string = '';
	
	checkInputType():boolean{
		if(Number.isInteger(parseInt(this.taxYear+"")) &&
		  Number.isInteger(parseInt(this.scenario+'')) &&
		  Number.isInteger(parseInt(this.jcdKey +'')) &&
		  Number.isInteger(parseInt(this.screenKey+'')) &&
		  Number.isInteger(parseInt(this.comboKey+'')) &&
		  Number.isInteger(parseInt(this.ssoId+""))
		  && (this.rowData && this.rowData != undefined)
		 // && (this.scenarioCode === "RAF" || this.scenarioCode === "EXAM") 
		  )
		{
		  return true;
		}
	
		return false;
	}

  ngOnInit(): void {
	  this.init();
	  this.isInit = true;
  }

  init() {
		console.log('init method');
	    if(this.checkInputType()){ 
			console.log('before modal save data');
			this.getModalData();
		}
	}

  onChangeEvent(event: any) {
      console.log(event);
  }

  onClickButtonEvent(event: any) {
      console.log(event);
  }

  public floatingConfig = {
    isFloating: true
  }
  
  getTestData(){
	
  }

  getModalData(){

	if(!this.isModalLoading) {
	  this.isModalLoading = true;
	  if (this.rowData && this.rowData != undefined && this.rowData.object_id > 0) {
		this.isEdit = true;
	  	if (this.rowData.SOURCE == 'Custom Override') {
			this.isCustomOverrideWindow = true;
		} else {
			this.isCustomOverrideWindow = false;
		}
	  } else {
		this.isCustomOverrideWindow = true;
	  }

	  		
	  this.f1118SchdCService.getBasketList(this.baseURLs.api, this.taxYear, this.scenario, this.jcdKey, this.ssoId)
	    .subscribe((data: any) => {
		if (data.length > 0) {
          console.log('getBasketList'+ data);
		  data.forEach( (value : any) => {
			let dropDownFromatData = {
				value: value.BASKET_KEY,
				text: value.BASKET_DESC,
				selected: false
			}
			this.basketList[0].data.push(dropDownFromatData);
		  });
				
		}else{
			console.log("Problem lading basket dtls");
		}
		},
		error => {
			console.log(error);
		}, () => {
			console.log('Completed getBasketList loading');
		});

      this.f1118SchdCService.getLineColDtlsData(this.baseURLs.api, this.baseGlobalParams, 
        this.taxYear, this.scenario, this.jcdKey, this.comboKey, this.schdType, this.ssoId).subscribe((data: any) => {
				if (data.length > 0) {
					console.log('getLineColDtlsData'+ data);
					this.tableData = false;
					this.SavedData = [];
					this.lineColDtls = this.processLineColDtls(data);
					if(this.isEdit) {
						this.getDataByUniqueKey();
					}
				}else{
					this.tableData = false;
					this.SavedData = [];
				}
			},
			//error => { //NOTE:K COMMENTED FOR UNIT TESTING PURPOSE
			(error: any) => {
			  this.isModalLoading = false;
			  console.log(error);
			}, () => {
			  if(!this.isEdit) {
				this.isModalLoading = false;
			  }
			  console.log('Completed getLineColDtlsData loading');
			});

		

			
		}
	}

	getDataByUniqueKey() {
		this.isTableLoading = true;
		this.f1118SchdCService.getDataByUniqueKey(this.baseURLs.api, this.baseGlobalParams, 
			this.taxYear, this.scenario, this.jcdKey, this.comboKey,this.ssoId, this.schdType, this.rowData.ELEMENT_NAME_1).subscribe((data: any) => {
					console.log('getDataByUniqueKey'+ data);
					this.isTableLoading = false;
					if (data.length > 0) {
						this.setFieldData(data, this.lineColDtls, this.isCustomOverrideWindow);
						this.setBasketData(data);
						let processTableData = this.processData(data);
						this.processedTableData = processTableData;
					}
					else {
						this.processedTableData = [];
					}
				},
				error => {
					this.isModalLoading = false;
					this.isTableLoading = false;
					console.log(error);
				}, () => {
					this.isModalLoading = false;
					this.isTableLoading = false;
					console.log('Completed getDataByUniqueKey loading');
				});
	}

	setBasketData(source:any) {
		this.selectedBasketId  = source[0].BASKET_KEY;
		let selectedBasketObj = this.basketList[0].data.find((item: any) => {
			return item.value == this.selectedBasketId;
		  });
		selectedBasketObj.selected = true;
	}

	setFieldData(source:any, dest:any, isGetAllFields: boolean) {
		let matchingObj:any = {};
		
		for (var i = 0; i < dest.length; i++) {
			if (dest[i].IS_READ_ONLY || isGetAllFields) {
				matchingObj = source.find((item: any) => {
					return item.COL_LONG_DESC == dest[i].COL_LONG_DESC && item.LINE_CODE_SHORT_DESC == dest[i].LINE_CODE_SHORT_DESC;
				  });
				if (matchingObj && matchingObj.ELEMENT_VALUE) {
					dest[i].ELEMENT_VALUE = matchingObj.ELEMENT_VALUE;
				}
			}
		}
	}

	setBasketId(group : any) {
		this.selectedBasketId = group.selected[0].value;
	}

    processLineColDtls(data : any) {
		for (var i = 0; i < data.length; i++) {
			if (this.isCustomOverrideWindow || this.isInit) {
				data[i].IS_READ_ONLY = false;
			}
			else {
				data[i].IS_READ_ONLY = data[i].IS_UNIQUE_ID_COL == '1';
			}

			if (data[i].IS_TOTAL_LINE == '1') {
				data.splice(i, 1); // do not show the totals line
				i--;
			}
		}
		
		return data;
	}	

	viewICalcData(item: any) {
		this.isViewOnly = true;
		this.resetElementValueOfLineColDtls();
		this.setFieldData(item.items, this.lineColDtls, true);
	}

	editICalcDelta(item: any) {
		this.isViewOnly = false;
		this.editedSno = item.SNO;
		this.resetElementValueOfLineColDtls();
		this.isDeltaEdit = true;
		this.setFieldData(item.items, this.lineColDtls, true);
	}

	processData(formLines:any) {
		let tableRowTemplate = JSON.parse(JSON.stringify(this.lineColDtls));
		for (var i = 0; i < tableRowTemplate.length; i++) { // BY DEFAULT SET EMPTY VALUE
			tableRowTemplate[i].ELEMENT_VALUE = '';
			tableRowTemplate[i].BASKET_KEY = this.selectedBasketId;
		}

		return _.reduce(
			formLines, 
			function(memo:any, property) {
				// Find the correct bucket for the property
				let bucket: any = _.find(memo, {
					SCHD_KEY: property.SCHD_KEY,				        							        	
					SNO: property.SNO
				});		

				// Create a new bucket if needed.
				if (!bucket) {
					bucket = {
						SCHD_KEY: property.SCHD_KEY,				        							        	
						SNO: property.SNO,						        	
						items: JSON.parse(JSON.stringify(tableRowTemplate))};
					//if(bucket.LINE_NO != 'N/A'){
						memo.push(bucket);
					//}
				}
				
				let matchingCellObj: any = bucket.items.find((item: any) => {
					return item.COL_LONG_DESC == property.COL_LONG_DESC && item.LINE_CODE_SHORT_DESC == property.LINE_CODE_SHORT_DESC;
				});

				if (matchingCellObj) {
					matchingCellObj.ELEMENT_VALUE = property.ELEMENT_VALUE;
				}

				return memo;
			}, 
			[] // The starting memo
		);	
	}

	validateForm(lineColDtls: any) {

		this.errorMessages = [];
		let error:string = '';
		let einRefIdCount = 0;
		let isEinRefIdColumnExists = false;
		if (this.selectedBasketId == undefined || this.selectedBasketId == 0) {
			error = "Please select Basket";
			this.errorMessages.push(error);
		} 
		for (var i = 0; i < lineColDtls.length; i++) {

			if (lineColDtls[i].COL_LONG_DESC == 'FGN_CORP_EIN_REF_ID') {
				isEinRefIdColumnExists = true;
				if(lineColDtls[i].ELEMENT_VALUE && lineColDtls[i].ELEMENT_VALUE.trim().length > 0) {
					einRefIdCount++;
				}
			}
		}

		if (isEinRefIdColumnExists == true) {
			if (einRefIdCount == 0) {
				this.errorMessages.push("EIN or Ref ID is required");
			} else if (einRefIdCount > 1) {
				this.errorMessages.push("Enter only either EIN or Ref ID");
			}
		}
		
		if (this.errorMessages.length > 0) {
			this.isValidationError = true;
		}
		return this.isValidationError;
	}

	saveData() { 

	   this.isDataChanged = true;
	   let clobData:any = [];

	   if (!this.validateForm(this.lineColDtls)) {
		let operationType = '';
		if (this.isCustomOverrideWindow && this.isEdit) { //custom override edit
			operationType = 'CUST_OVERRIDE_UPDATE';
		} else if (this.isCustomOverrideWindow) {
			operationType = 'CUST_OVERRIDE_NEW';
		} else if (this.isEdit) {
				if(this.isDeltaEdit) {
					operationType = 'IC_DELTA_UPDATE';
				} else {
					operationType = 'IC_DELTA_NEW';
				}
		} else {
			console.log('Is some flag not set????');
		}
		
		let unquieId = this.isCustomOverrideWindow? this.composeUnquieId(this.lineColDtls) : this.rowData.ELEMENT_NAME_1;
		let originalUniqueId = this.isEdit? this.rowData.ELEMENT_NAME_1 : unquieId;
		let originalBasketKey = this.isEdit? this.rowData.BASKET_KEY : this.selectedBasketId;
		let dataToSave = this.composeDataToSave(this.lineColDtls, unquieId, operationType);

		let clobSettings:any  = {
			SSO_ID : this.ssoId,
			TAX_YEAR : this.taxYear,
			SCENARIO : this.scenario,
			JCD_KEY : this.jcdKey,
			FILER_ENTITY_KEY: this.comboKey,
			ORIGINAL_BASKET_KEY: originalBasketKey,
			ORIGINAL_UNIQ_KEY: originalUniqueId,
			OPERATION_TYPE: operationType
		};

		// this.setUniqueId(dataToSave, unquieId);
		//clobData.push(dataToSave);
		this.resetAll();

		this.f1118SchdCService.saveF1118SchdCDEData(this.baseURLs.api, dataToSave, clobSettings, this.baseGlobalParams, this.checkLockedObj).subscribe(
			(data) => {
				if (data.callSuccess == "1") {
					this.f1118SchdCService.openSnackBar('Data Saved Successfully','gtw-snackbar--green');
					// this.saveF1118SchdCInfo.emit('save-f1118-schd-c'); //NOTE:K Use this to close the modal if required
					if (this.isCustomOverrideWindow) {
						this.saveF1118SchdCInfo.emit('save-f1118-schd-c'); 
					}
					this.getDataByUniqueKey();
				} else {
					this.f1118SchdCService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
				}
			}, () => {
				console.log("Error while saving");
		});
	  }
	};

	composeDataToSave(lineColDtls:any [], unquieId:any, operationType: any) {
		let dataToSave:any = [];
		let maxSno = 0;
		for (i = 0; i < this.processedTableData.length; i++) { 
			if (this.processedTableData[i].SNO > maxSno) {
				maxSno = this.processedTableData[i].SNO ;
			}
		};

		let sno = operationType == 'IC_DELTA_UPDATE'? this.editedSno : (maxSno + 1); 
		
		for (var i = 0; i < this.lineColDtls.length; i++) {

			if (!this.isCustomOverrideWindow &&
				this.lineColDtls[i].IS_AMT == '1' && this.lineColDtls[i].IS_UNIQUE_ID_COL == '1') {
				continue;	// IF UNIQUE COL IS NUMBER THEN SKIP TO SAVE: should be saved on CustomOverrideWindow
			}
			dataToSave[i] = {};
			dataToSave[i].SCHD_KEY = this.lineColDtls[i].SCHD_KEY;
			dataToSave[i].LINE_KEY = this.lineColDtls[i].LINE_KEY;
			dataToSave[i].COL_KEY = this.lineColDtls[i].COL_KEY;
			dataToSave[i].BASKET_KEY = this.selectedBasketId;
			dataToSave[i].COL_LONG_DESC = this.lineColDtls[i].COL_LONG_DESC;
			dataToSave[i].IS_DEFAULT = this.lineColDtls[i].IS_DEFAULT;
			dataToSave[i].ELEMENT_VALUE = this.lineColDtls[i].ELEMENT_VALUE;
			dataToSave[i].COMMENT = this.lineColDtls[i].COMMENT;
			dataToSave[i].ELEMENT_NAME_1 = unquieId;
			dataToSave[i].SNO = sno;
		}
		this.doNotSaveNonDefaultLineIfEmpty(dataToSave);
		this.saveEinOrRefId(dataToSave);
		return dataToSave;
	}

	doNotSaveNonDefaultLineIfEmpty(dataToSave:any) {
		let indexOfEmptyField = -1;
		for (var i = 0; i < dataToSave.length; i++) {

			if (dataToSave[i]?.IS_DEFAULT == 'N') {
				if (dataToSave[i].ELEMENT_VALUE == undefined) {
					indexOfEmptyField = i;
				}
				else if(dataToSave[i].ELEMENT_VALUE.trim().length == 0) {
					indexOfEmptyField = i;
				}
				if (indexOfEmptyField > -1) {
					dataToSave.splice(indexOfEmptyField, 1);
					indexOfEmptyField = -1;
					i--;
					continue;
				}
			}
		}
	}

	saveEinOrRefId(dataToSave:any) {

		let indexOfEmptyField = -1;
		for (var i = 0; i < dataToSave.length; i++) {

			if (dataToSave[i]?.COL_LONG_DESC.includes("FGN_CORP_EIN_REF_ID")) {
				if (dataToSave[i].ELEMENT_VALUE == undefined) {
					indexOfEmptyField = i;
				}
				else if(dataToSave[i].ELEMENT_VALUE.trim().length == 0) {
					indexOfEmptyField = i;
				}
				if (indexOfEmptyField > -1) {
					dataToSave.splice(indexOfEmptyField, 1);
					indexOfEmptyField = -1;
					i--;
					continue;
				}
			}
		}
	}



	deleteData(deleteSno: any) {

		this.isDataChanged = true;
		let clobData:any = [];
		let operationType = '';
		let isCustomOverrideDelete = this.isEdit && this.isCustomOverrideWindow;
		if (isCustomOverrideDelete) {
		 operationType = 'CUST_OVERRIDE_DELETE';
		} else {
		 operationType = 'ICALC_DELTA_DELETE';
		}
		let originalUniqueId = this.rowData.ELEMENT_NAME_1;
		let originalBasketKey = this.rowData.BASKET_KEY;
 
		let clobSettings:any  = {
		 SSO_ID : this.ssoId,
		 TAX_YEAR : this.taxYear,
		 SCENARIO : this.scenario,
		 JCD_KEY : this.jcdKey,
		 FILER_ENTITY_KEY: this.comboKey,
		 ORIGINAL_BASKET_KEY: originalBasketKey,
		 ORIGINAL_UNIQ_KEY: originalUniqueId,
		 OPERATION_TYPE: operationType,
		 DELETE_SNO: deleteSno
		 //DELETE_SNO: item.SNO
		};
 
		let dataToSave:any = [];
		this.f1118SchdCService.saveF1118SchdCDEData(this.baseURLs.api, dataToSave, clobSettings, this.baseGlobalParams, this.checkLockedObj).subscribe(
		 (data) => {
			 if (data.callSuccess == "1") {
				 this.f1118SchdCService.openSnackBar('Data Deleted Successfully','gtw-snackbar--green');
				 //this.saveF1118SchdCInfo.emit('save-f1118-schd-c');
				 if (this.isCustomOverrideWindow) {
					this.modalClose();
				 } else {
					this.getDataByUniqueKey(); 
				 }
			 } else {
				 this.f1118SchdCService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
			 }
		 }, () => {
			 console.log("Error while saving");
		 });
	 }

	resetElementValueOfLineColDtls() {

		for (var i = 0; i < this.lineColDtls.length; i++) {
			if (this.lineColDtls[i].IS_UNIQUE_ID_COL != '1') { // reset only non unique fields
				this.lineColDtls[i].ELEMENT_VALUE = '';
			}
		}
	}

	resetAll() {
		this.resetElementValueOfLineColDtls();
		//this.selectedBasketId = 0;
		this.isDeltaEdit = false;
		this.isInit = false;
		this.isViewOnly = false;
		this.isValidationError = false;
	}

	composeUnquieId(dataToSave:any) {
		let uniqueId = '';
		let uniqueIdCount = 0;
		for (var i = 0; i < this.lineColDtls.length; i++) {
			if (this.lineColDtls[i].IS_UNIQUE_ID_COL == '1' && this.lineColDtls[i].ELEMENT_VALUE != undefined
			    && this.lineColDtls[i].COL_LONG_DESC != 'FGN_CORP_NAME') {//is a unique attrib
				if (uniqueIdCount == 0) {
					uniqueId = this.lineColDtls[i].ELEMENT_VALUE;
				} else {
					uniqueId = uniqueId + '_' + this.lineColDtls[i].ELEMENT_VALUE ;
				}
				uniqueIdCount++;
			}
		}
		if (uniqueIdCount == 0) {
			uniqueId = new Date().getTime() + '';
		}
		return uniqueId;
	}

	setUniqueId(dataToSave: any, unquieId:any) {
		for (var i = 0; i < this.lineColDtls.length; i++) {
			this.lineColDtls[i].ELEMENT_NAME_1 = unquieId;
		}
	}

	modalClose() {
		if (this.isDataChanged) {
			this.saveF1118SchdCInfo.emit('save-f1118-schd-c');
		}
		console.log("click close");
		this.openF1118SchdCNewModal.emit('cancel-schd-c-save');
	  }

}
