import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DSTSharedService } from 'projects/gtw-elements/src/app/DST/shared/dst-shared.service';
import { TaxTypePipe } from 'projects/gtw-elements/src/app/DST/shared/pipes/taxtype.pipe';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StmtAttrData, StmtData} from '../../../../../../shared/models/dst-form-state.model';
import {CreateDisclosureSharedService, DropDownType, EntityNameChange} from '../../../create-disclosure-shared.service';

@Component({
  selector: 'dst-view-template-sec367b-id192',
  templateUrl: './view-template-sec367b-id192.component.html',
  styleUrls: ['../../template-shared.scss', './view-template-sec367b-id192.component.scss']
})
export class ViewTemplateSec367bId192Component implements OnInit {
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();
  startLoader: boolean = false;
  disableActions: boolean = false;
  stmtData!: StmtData;
  stmtAttrData: any = [];
  loaded: boolean = false;
  attrData: any = [];
  form192!: FormGroup;
  currencyOptions: any = [{data: []}];
  propertyValBasisOptions: any = [{data: []}];
  stockTypeOptions: any = [{data: []}];
  attMap: any;
  prEntityNull: boolean = false;
  secEntityNull: boolean = false;
  basisAmt1Total: boolean = false;
  stockTypeName: string = '';
  selBasisTxt: string = '';
  primaryCorpTypeTxt: string = '';
  secondaryCorpTypeTxt: string = '';
  s3Uploading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private taxTypePipe: TaxTypePipe) {
    this.disableActions = this.sharedService.disableActions;
    this.sharedService.disableActions = false; // reset immediately after consumed
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.form192 = this.formBuilder.group({
      header: this.formBuilder.group({
        consolGroup: ['', Validators.required],
        options: ['']
      })
    });

    this.sharedService.getCurrencyDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.currencyOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //
    this.sharedService.getStockTypeListDropdown()
      .subscribe((data: DropDownType[]) => {
        data.forEach(element => {
          this.stockTypeOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //PropertyValBasis Options
    this.sharedService.getBasisDropdown()
      .subscribe((data: DropDownType[]) => {
        //console.log(data);
        data.forEach(element => {
          this.propertyValBasisOptions[0].data.push({
            id: element.id,
            text: element.text
          });
        });
      }, () => {

      }, () => {

      });

    //load Stmt data
    this.sharedService.getLoadStatementData(192)
      .subscribe((response: StmtData) => {
        this.stmtData = response;

        //load all attributes into new object for showing on UI as key value pair
        this.attMap = {};

        this.stmtData.children?.forEach((stmt: StmtAttrData) => {
          if (!this.attMap[stmt.attributeName]) {
            this.attMap[stmt.attributeName] = [];
          }

          if (stmt.attributeValue !== undefined) {

            this.attMap[stmt.attributeName]?.push(stmt.attributeValue);
          } else {
            //console.log('stmt.attributeName---'+ stmt.attributeName);
            this.attMap[stmt.attributeName]?.push('');
          }


        });
        console.log('After loop Attr data' + JSON.stringify(this.attMap));
        //check primary and secondary
        if (this.stmtData.primaryentityid === null || this.stmtData.primaryentityid === undefined) {
          this.prEntityNull = true;
        }
        if (this.stmtData.secondaryentityid === null || this.stmtData.secondaryentityid === undefined) {
          this.secEntityNull = true;
        }

        this.propertyValBasisOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['Basis'] !== undefined) && (val.id == this.attMap['Basis'][0])) {
            this.selBasisTxt = val.text;
          }

        });
        this.stockTypeOptions[0].data.forEach((val: DropDownType) => {

          if ((this.attMap['StockType'] !== undefined) && (val.id == this.attMap['StockType'][0])) {
            this.stockTypeName = val.text;
          }

        });
        if (this.attMap['Desc1'] && this.attMap['Desc1'].length >= 1) {
          this.basisAmt1Total = true;
        }
        //corpTypeTxt
        // this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1);
        // this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2);
        forkJoin([

          this.updateEntitiesInfo(this.stmtData?.primaryleid!, 1),
          this.updateEntitiesInfo(this.stmtData?.secondaryleid!, 2)
        
        ]).subscribe(([response1, response2]) => {
          setTimeout(() => {
            const html = document.querySelector('#temp367bform192');
            const htmlContent = html!.outerHTML;        
            this.sharedService.getFormStateByFormId(192)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
          });
        }, (error: HttpErrorResponse) => {
        
          console.log(error);        
          this.dstSharedService.showAlert('error', error.message);
        
        }).add(() => {
          this.loaded = true;
          this.startLoader = false;
        });

      }, () => {

      }, () => {

    }).add(() => {
      this.loaded = true;
      this.startLoader = false;
    });
    //.add(() => {
    //   this.loaded = true;
    //   this.startLoader = false;

    //   setTimeout(() => {    
    //     const html = document.querySelector('#temp367bform192');
    //     const htmlContent = html!.outerHTML;
        
    //     this.sharedService.getFormStateByFormId(192)!.doPDFSync && this.htmlToPdfConversion(htmlContent.toString());
    //   });
    // })   

  }
  
  htmlToPdfConversion(htmlContent: string, isS3upload: boolean = false): void {
    this.sharedService.saveHTMLToPDFStatement(htmlContent!, 
      'Section367b-StatementPursuant351.pdf', this.stmtData.statementid)
      .subscribe((response: any) => {
        isS3upload && (this.dstSharedService.showAlert('success', 'The Form got successfully uploaded to S3!'));
        isS3upload && (this.s3Uploading =  false);
      }, (eMsg: string) => {
        console.log(eMsg);
        isS3upload && (this.dstSharedService.showAlert('error', eMsg));
      });
  }

  s3Upload(): void {
    this.s3Uploading = true;
    const html = document.querySelector('#temp367bform192');
    const htmlContent = html!.outerHTML;
    this.htmlToPdfConversion(htmlContent, true);
  }

  dummyFunc(event: any): void {

  }

  save(): void {
    this.sharedService.setFormData(this.stmtData);
    this.sharedService.setSavedStatementId(this.stmtData.statementid.toString());
    this.sharedService.switchTemplateMode(false);
  }

  cancel(): void {
    this.onCancel.emit();
  }

  // updateEntitiesInfo(leid: string, entityType: number): void {
  //   this.startLoader = true;
  //   this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
  //     leid,
  //     this.attMap['DisplayDate'] != undefined ? this.attMap['DisplayDate'][0]: null,
  //     this.sharedService.getActiveFormState().taxYear.toString()
  //   ).subscribe((response: EntityNameChange[]) => {
  //     if (response && response[0]) {
  //       //override entity type
  //       const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
  //       if (entityType === 1) { //primary entity
  //         this.primaryCorpTypeTxt = corpType;
  //       } else if (entityType === 2) { //secondary entity
  //         this.secondaryCorpTypeTxt = corpType;
  //       }
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     console.log(error);
  //     this.dstSharedService.showAlert('error', error.message);
  //   }).add(() => {
  //     this.startLoader = false;
  //   });
  // }

  updateEntitiesInfo(leid: string, entityType: number): Observable<EntityNameChange[]> {
    this.startLoader = true;
    return this.sharedService.checkForEntityNameChange(this.stmtData?.statementid.toString(),
      leid,
      this.attMap['DisplayDate'] != undefined ? this.attMap['DisplayDate'][0]: null,
      this.sharedService.getActiveFormState().taxYear.toString()
    ).pipe(
      tap({
        next: (response => {
          if (response && response[0]) {
			//override entity type
			const corpType = this.taxTypePipe.transform(response[0], response[0].TAXTYPECODE, true);
			if (entityType === 1) { //primary entity
			  this.primaryCorpTypeTxt = corpType;
			} else if (entityType === 2) { //secondary entity
			  this.secondaryCorpTypeTxt = corpType;
			}
		  }
        })
      })
    )
  }

  getSelCurrency(valFor?: number): string {
    let retVal = '';
    this.currencyOptions[0].data.forEach((val: DropDownType) => {
      if (this.attMap['Currency'] && val.id == this.attMap['Currency'][0] && valFor == 0) {
        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][1] && valFor == 1) {

        retVal = val.text;
      } else if (this.attMap['Currency'] && val.id == this.attMap['Currency'][2] && valFor == 2) {

        retVal = val.text;
      }

    });

    return retVal;
  }

  totalAmt(amtFor?: String): number {
    let totalAmt = 0;
    if (amtFor == 'BasisAmt' && this.attMap['BasisAmt'] !== undefined) {
      this.attMap['BasisAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });

    } else if (amtFor == 'FMVAmt' && this.attMap['FMVAmt'] !== undefined) {
      this.attMap['FMVAmt'].forEach((amt: number) => {
        totalAmt += Number(amt);
      });
    } else if (amtFor == 'BasisAmt1' && this.attMap['BasisAmt1'] !== undefined) {

      this.attMap['BasisAmt1'].forEach((amt: number) => {
        //console.log(amt);
        totalAmt += Number(amt);
      });
    }
    return totalAmt;
  }
}
