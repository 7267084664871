<div class="loader-overlay" *ngIf="startLoader">
  <div class="backdrop"></div>
  <ap-loading class="loader" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar">
</dst-create-disclosure-actions>

<div class="form-template-wrapper">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{ stmtData.dcnname }}
      </span>
    </b>
  </div>

  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{ stmtData.taxyear }} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        Form 8886 - Reportable Transaction Disclosure Statement
        <br />
      </span>
    </B>
  </div>
  <br />

  <div [formGroup]="form235" class="section-content" *ngIf="loaded">
    <div class="section-content">
      
      <!-- Section 0 -->
      <div class="ap-form-field-my-wrapper">

        <div class="row entity-details">
          <div class="col-md-2">
            <span>Entity Name:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primaryentity == null? '': stmtData.primaryentity }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>Federal ID:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primaryein == null? 'N/A' : stmtData.primaryein }}
            </span>
          </div>
        </div>
        <div class="row entity-details" *ngIf="stmtData.primaryein == null">
          <div class="col-md-2">
            <span>EIN Missing Reason:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ attMap['TransferorEinMissingReason'] !== undefined ? attMap['TransferorEinMissingReason'][0] : '' }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>Address Line 1:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primaryaddress1 == null? '': stmtData.primaryaddress1 }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>Address Line 2:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primaryaddress2 == null? '' : stmtData.primaryaddress2 }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>City:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primarycity == null? '' : stmtData.primarycity }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>{{ stmtData.primarycountry === 'US' ? 'State:': 'Province or State:' }}</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stateValue ? stateValue : '' }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>Country:</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primarycountryname == null? '' : stmtData.primarycountryname }}
            </span>
          </div>
        </div>
        <div class="row entity-details">
          <div class="col-md-2">
            <span>{{ stmtData.primarycountry === 'US' ? 'Zip Code:': 'Postal Code:' }}</span>
          </div>
          <div class="col-md-4">
            <span class="template-color-blue">
              {{ stmtData.primaryzip == null? '' : stmtData.primaryzip }}
            </span>
          </div>
        </div>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>

          </tr>
        </tbody>
      </table>

      <!-- Section 1 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partOne'">
    
        <table>
          <tbody>
            
            <tr>
              <td class="statement-number-tbl-td">A</td>
              <td colspan="3">If you are filing more than one Form
                8886 with your tax return, sequentially number each Form 8886 and
                enter the statement number for this Form 8886.
              </td>
            </tr>
  
            <tr>
              <td> </td>
              <td> </td>
              <td>
                <span class="mr-2"> Statement Number </span>
                <span> {{ formModel.statementNumber }} </span>
                <span class="mr-2"> of </span>
                <span> {{ formModel.totalStatementNumber }} </span>
              </td>
            </tr>
  
            <tr>
              <td class="statement-number-tbl-td">B</td>
              <td class="pt-2" style="width: 55%;">
                Enter the form number of the tax return to which this form is attached or related.
              </td>
              <td>
                <span>{{ formModel.formNumberOfTaxReturn }}</span>
              </td>
            </tr>
  
            <tr>
              <td>&nbsp;</td>
              <td class="pt-2">
                Enter the year of the tax return identified above.
              </td>
              <td>
                <span>{{ formModel.forTaxYear }}</span>
              </td>
            </tr>
  
            <tr>
              <td></td>
              <td class="pt-2">
                Is this Form 8886 being filed with an amended tax return?
              </td>
              <td class="d-flex">
                <ap-radio class="mr-2 radio-font" name="attachedToAmendedReturn" [text]="'Yes'" 
                  [checked]="formModel.attachedToAmendedReturn === true" [disabled]="true">
                </ap-radio>
                <ap-radio class="a-mx-10 radio-font" name="attachedToAmendedReturn" [text]="'No'"
                  [checked]="formModel.attachedToAmendedReturn === false" [disabled]="true">
                </ap-radio>
              </td>
            </tr>
  
            <tr>
              <td class="statement-number-tbl-td">C</td>
              <td class="pt-2">
                Check the box(es) that apply:
              </td>
              <td>
                <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Initial Year Filer'" name="initialYearFiler"
                  [checked]="formModel.initialYearFiler === true" [disabled]="true" style="margin-left: 0px !important;">
                </ap-checkbox>
                <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Protective Disclosure'" name="protectiveDisclosure"
                  [checked]="formModel.protectiveDisclosure === true" [disabled]="true">
                </ap-checkbox>
              </td>
            </tr>
  
          </tbody>
        </table>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr></tr>
        </tbody>
      </table>
  
      <!-- Section 2 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partTwo'">
        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">1a</td>
              <td>Name of reportable transaction</td>
            </tr>
            
            <tr>
              <td></td>
              <td colspan="2">
                <div class="a-textarea-box a-field-length-validation form-inlines">
                  <ap-form-field [inlineBlock]="true" [maxLength]="100" [withNumberValidation]="true"
                    [type]="'textarea'" [id]="'reportableTransactionInfoNameOfReportableTransaction'"
                    name="reportableTransactionInfoNameOfReportableTransaction" [isDisabled]="true"
                    [value]="(attMap['ReportableTransactionInfoNameOfReportableTransaction'] != undefined ? attMap['ReportableTransactionInfoNameOfReportableTransaction'] : '')">
                  </ap-form-field>
                </div>
              </td>
            </tr>
  
            <tr>
              <td class="statement-number-tbl-td">1b</td>
              <td style="width: 32.5%;">Initial year participated in transaction</td>
              <td class="pt-2">
                <span>{{ formModel.reportableTransactionInfoInitialYearParticipated }}</span>
              </td>
            </tr>
  
            <tr>
              <td class="statement-number-tbl-td">1c</td>
              <td>Reportable transaction or tax shelter
                registration number (9 digits or 11 digits)</td>
              <td class="col-md-3 pl-0">
                <span>{{ formModel.reportableTransactionInfoTransactionOrTaxShelterNumber }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr></tr>
      </tbody>
      </table>

      <!-- Section 3 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partThree'">
        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">2</td>
              <td class="pb-2">Identify the type of reportable transaction
                below. Check all boxes that apply.</td>
            </tr>
            
            <tr>
              <td class="statement-number-tbl-td"></td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        a 
                        <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Listed'" [checked]="formModel.listed === true"
                          name="listed" [disabled]="true">
                        </ap-checkbox>
                      </td>
                      <td>
                        c 
                        <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Contractual Protection'" [disabled]="true"
                          name="contractualProtection" [checked]="formModel.contractualProtection === true">
                        </ap-checkbox>
                      </td>
                      <td>
                        e 
                        <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Transaction of interest'" [disabled]="true"
                          name="transactionOfInterest" [checked]="formModel.transactionOfInterest === true">
                        </ap-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        b 
                        <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Confidential'" [disabled]="true"
                          name="confidential" [checked]="formModel.confidential === true">                        
                        </ap-checkbox>
                      </td>
                      <td>
                        d 
                        <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Loss'" [disabled]="true"
                          name="loss" [checked]="formModel.loss === true">                        
                        </ap-checkbox>
                      </td>
                      <td> &nbsp; </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">3</td>
              <td>
                If you checked box 2a or 2e, enter the published guidance number for the listed transaction or transaction of interest
              </td>
              <td width="7%">
                <span> {{ formModel.publishedGuidanceNumber }} </span>
              </td>
            </tr>

            <tr>
              <td class="statement-number-tbl-td">4</td>
              <td>Enter the number of "same as or
                substantially
                similar" transactions reported on this form</td>
              <td class="pt-2" width="7%">
                <span> {{ formModel.numberOfSameOrSimilarTr }} </span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr></tr>
        </tbody>
      </table>

      <!-- Section 4 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partFour'">

        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">5</td>
              <td>
                If you participated in the transaction through another entity, check all applicable boxes and provide the information below for the entity
              </td>
            </tr>
          </tbody>
        </table>

        <ng-container ng-if="attMap['TypeOfEntityInformationPartnership'].length >= 1">
          <div *ngFor="let val of attMap['TypeOfEntityInformationPartnership'];let i=index" class="propDiv">

            <div class="tab-section-content section-content propTbl pl-5">

              <table style="table-layout: fixed;">
                <tbody>
                  <tr>
                    <td class="statement-number-tbl-td">a</td>
                    <td>Type of entity</td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td colspan="2">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <ap-checkbox class="a-mx-10 checkbox-font" [id]="'typeOfEntityInformationPartnership'" [text]="'Partnership'"
                                [checked]="attMap['TypeOfEntityInformationPartnership'] == undefined ? false 
                                : attMap['TypeOfEntityInformationPartnership'][i] === '1'" [disabled]="true"> 
                              </ap-checkbox>
                            </td>
                            
                            <td>
                              <ap-checkbox class="a-mx-10 checkbox-font" [id]="'typeOfEntityInformationSCorporation'" [text]="'S corporation'"
                                [checked]="attMap['TypeOfEntityInformationSCorporation'] == undefined ? false 
                                : attMap['TypeOfEntityInformationSCorporation'][i] === '1'" [disabled]="true">
                              </ap-checkbox>
                            </td>
                            
                            <td>
                              <ap-checkbox class="a-mx-10 checkbox-font" [id]="'typeOfEntityInformationTrust'" [text]="'Trust'"
                                [checked]="attMap['TypeOfEntityInformationTrust'] == undefined ? false 
                                : attMap['TypeOfEntityInformationTrust'][i] === '1'" [disabled]="true">
                              </ap-checkbox>
                            </td>
                            
                            <td>
                              <ap-checkbox class="a-mx-10 checkbox-font" [id]="'typeOfEntityInformationForeign'" [text]="'Foreign'"
                                [checked]="attMap['TypeOfEntityInformationForeign'] == undefined ? false 
                                : attMap['TypeOfEntityInformationForeign'][i] === '1'" [disabled]="true">
                              </ap-checkbox>
                            </td>

                          </tr>
                        </tbody>
                      </table>
      
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>LEID of the Entity that Participated in the Transaction</td>
                    <td>
                      <span  class="template-color-blue">{{ attMap['ParticipatingLEID'] == undefined ? '' 
                        : attMap['ParticipatingLEID'][i] }}</span>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">b</td>
                    <td>Entity Name:</td>
                    <td>
                      <span class="template-color-blue"> {{ attMap['TypeOfEntityInformationEntityNameBusinessNameLine1'] == undefined ? '' 
                        : attMap['TypeOfEntityInformationEntityNameBusinessNameLine1'][i] }} </span>
                    </td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td">c</td>
                    <td>Employer identification number (EIN):</td>
                    <td>
                      <span class="template-color-blue"> {{ attMap['TypeOfEntityInformationEntityEIN'] == undefined ? 'N/A'
                        : attMap['TypeOfEntityInformationEntityEIN'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="attMap['TypeOfEntityInformationEntityEIN'] === undefined || attMap['TypeOfEntityInformationEntityEIN'][i] === ''">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>EIN Missing Reason:</td>
                    <td>
                      <span class="template-color-blue"> {{ attMap['TypeOfEntityInformationMissingEINReason'] == undefined ? 'N/A' 
                        : attMap['TypeOfEntityInformationMissingEINReason'][i] }} </span>
                    </td>
                  </tr>

                  <!-- <tr>
                    <td class="statement-number-tbl-td">d</td>
                    <td>
                      On the next two rows, enter either the Date Schedule K-1 received from entity or "none" if Schedule K-1 not received
                    </td>
                  </tr> -->

                  <tr>
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Date Schedule K-1 received from entity</td>
                    <td>
                      <span> {{ attMap['TypeOfEntityInformationScheduleK1ReceivedDate'] == undefined ? 'None' 
                        : (attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][i] ? attMap['TypeOfEntityInformationScheduleK1ReceivedDate'][i] : 'None') }} </span>
                    </td>
                  </tr>

                  <!-- <tr>
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td> If no Schedule K-1 was received from the entity enter "NONE" </td>
                    <td>
                      <span> {{ attMap['TypeOfEntityInformationNoScheduleK1Received'] == undefined ? '' 
                        : attMap['TypeOfEntityInformationNoScheduleK1Received'][i] }} </span>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>

      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr></tr>
        </tbody>
      </table>

      <!-- Section 5 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partFive'">

        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">6</td>
              <td>
                Enter below the name and address of each
                individual or entity to whom you paid a fee with regard to the
                transaction if that individual or entity promoted, solicited, or
                recommended your participation in the transaction, or provided tax
                advice related to the transaction.
              </td>
            </tr>
          </tbody>
        </table>

        <ng-container ng-if="attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'].length >= 1">
          <div *ngFor="let val of attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'];let i=index" class="propDiv">

            <div class="tab-section-content section-content propTbl" style="padding-left: 80px;">
              <div class="row w-100">
                <div class="col-md-6">
                  <span>Name</span>
                </div>
                <div class="col-md-6">
                  <span> {{ attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'] == undefined ? '' 
                    : attMap['PersonsYouPaidAFeeInfoNameOfPersonYouPaidFee'][i] }} </span>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-6">
                  <span>Identifying Number: SSN (If Known)</span>
                </div>
                <div class="col-md-6">
                  <span> {{ attMap['PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee'] == undefined ? 'N/A' 
                    : attMap['PersonsYouPaidAFeeInfoSSNNumberPersonPaidFee'][i] }} </span>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-6">
                  <span>Identifying Number: EIN (If Known)</span>
                </div>
                <div class="col-md-6">
                  <span> {{ attMap['PersonsYouPaidAFeeInfoEINNumberPersonPaidFee'] == undefined ? 'N/A' 
                    : attMap['PersonsYouPaidAFeeInfoEINNumberPersonPaidFee'][i] }} </span>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-6">
                  <span>If no SSN/EIN, specify reason for missing tax ID Number</span>
                </div>
                <div class="col-md-6">
                  <span> {{ attMap['PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'] == undefined ? 'N/A' 
                    : attMap['PersonsYouPaidAFeeInfoMissingEINReasonPersonPaidFee'][i] }} </span>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-6">
                  <span>Fees Paid (Enter amount in US Dollars)</span>
                </div>
                <div class="col-md-6">
                  <span> {{ attMap['PersonsYouPaidAFeeInfoFeesPaid'] == undefined ? 'N/A' 
                    : attMap['PersonsYouPaidAFeeInfoFeesPaid'][i] }} </span>
                </div>
              </div>

              <div class="row mt-2 w-100">
                <div class="col-md-6">
                  <span>Were Fees paid to a US or Foreign Entity?</span>
                </div>
                <div class="col-md-4 pl-2">
                  <ap-radio class="a-mx-10 radio-font" name="USForeignFeesRad_{{ i }}" [text]="'US'"
                    [checked]="attMap['USForeignFees'] == undefined ? false 
                    : attMap['USForeignFees'][i] === 'US'" [disabled]="true">
                  </ap-radio>
                  <ap-radio class="a-mx-10 radio-font" name="USForeignFeesRad_{{ i }}" [text]="'Foreign'"
                    [checked]="attMap['USForeignFees'] == undefined ? false 
                    : attMap['USForeignFees'][i] === 'FOREIGN'" [disabled]="true">
                  </ap-radio>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label" style="width: 381px !important;">Address Line 1:</span>
                    <div class="d-inline-block">
                      <span> {{ (attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US') ? (attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine1'][i]) : ((attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN') 
                        ? (attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine1'][i]): '') }} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label" style="width: 381px !important;">Address Line 2:</span>
                    <div class="d-inline-block">
                      <span> {{ (attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US') ? (attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeAddressLine2'][i]) : ((attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN') ? (attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeAddressLine2'][i]): '') }} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label" style="width: 381px !important;">{{ (attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US') ? 'City:' : 'City / Town:'}}</span>
                    <div class="d-inline-block">
                      <span> {{ (attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US') ? (attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeCity'][i]) : ((attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN') ? (attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCity'][i]): '') }} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details" *ngIf="attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US'">
                    <span class="ed-label" style="width: 381px !important;">State:</span>
                    <div class="d-inline-block">
                      <span> {{ attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState'] == undefined ? 'N/A' 
                        : attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeState'][i] }} </span>
                    </div>
                  </div>
                  <div class="entity-details" *ngIf="attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN'">
                    <span class="ed-label">Province or State:</span>
                    <div class="d-inline-block">
                      <span> {{ attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState'] == undefined ? 'N/A' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeProvinceOrState'][i] }} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN'">
                <div class="col-md-12">
                  <div class="entity-details" style="width: 381px !important;">
                    <span class="ed-label">Country:</span>
                    <div class="d-inline-block">
                      <span> {{ attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry'] == undefined ? 'N/A' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeeCountry'][i] }} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="entity-details">
                    <span class="ed-label" style="width: 381px !important;">{{ attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US' ? 'Zip Code:' : 'Postal Code:'}}</span>
                    <div class="d-inline-block">
                      <span> {{ (attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'US') ? (attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoUSAddressPersonPaidFeeZIPCode'][i]) : ((attMap['USForeignFees'] !== undefined && attMap['USForeignFees'][i] === 'FOREIGN') ? (attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode'] == undefined ? '' 
                        : attMap['PersonsYouPaidAFeeInfoForeignAddressPersonPaidFeePostalCode'][i]): '') }} </span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </ng-container>

      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>

          </tr>
        </tbody>
      </table>

      <!-- Section 6 -->
      <div class="section-content ap-form-field-my-wrapper" [id]="'partSix'">

        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">7</td>
              <td>Facts</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <div>
          
          <table>
            <tbody>
              <tr>
                <td class="statement-number-tbl-td">a</td>
                <td>
                  Identify the type of tax benefit generated by the transaction. Check all the boxes that apply.
                </td>
                <td>&nbsp;</td>
              </tr>
              
              <tr>
                <td class="statement-number-tbl-td"></td>
                <td colspan="2">
                  <table>
                    <tbody>
    
                      <tr>
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="Deductions" [text]="'Deductions'"
                            name="deductions" [checked]="formModel.deductions" [disabled]="true">                          
                          </ap-checkbox>
                        </td>
    
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="ExclusionsFromGrossIncome" name="exclusionsFromGrossIncome" 
                            [text]="' Exclusions From Gross Income'" [checked]="formModel.exclusionsFromGrossIncome"
                            [disabled]="true" style="margin-right: 0px !important;">
                          </ap-checkbox>
                        </td>

                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="taxCredits" [text]="' Tax Credits'"
                            [checked]="formModel.taxCredits" name="taxCredits" [disabled]="true" style="margin-left: 0px !important;">                          
                          </ap-checkbox>
                        </td>
                      </tr>
                    
                      <tr>
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="capitalLoss" [text]="'Capital Loss'"
                            [checked]="formModel.capitalLoss" name="capitalLoss" [disabled]="true">                          
                          </ap-checkbox>
                        </td>
                        
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="nonrecognitionOfGain" [text]="'Nonrecognition of gain'"
                            [checked]="formModel.nonrecognitionOfGain" name="nonrecognitionOfGain" [disabled]="true" style="margin-right: 0px !important;">                          
                          </ap-checkbox>
                        </td>

                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="deferral" [text]="'Deferral'"
                            [checked]="formModel.deferral" name="deferral" [disabled]="true" style="margin-left: 0px !important;">                          
                          </ap-checkbox>
                        </td>
                      </tr>
    
                      <tr>
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="ordinaryLoss" [text]="'Ordinary Loss'"
                            [checked]="formModel.ordinaryLoss" name="ordinaryLoss" [disabled]="true">                          
                          </ap-checkbox>
                        </td>
    
                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="adjustmentsToBasis" [text]="'Adjustments to basis'"
                            [checked]="formModel.adjustmentsToBasis" name="adjustmentsToBasis" [disabled]="true" style="margin-right: 0px !important;">                          
                          </ap-checkbox>
                        </td>

                        <td>
                          <ap-checkbox class="a-mx-10 checkbox-font" id="absenceOfAdjustmentsToBasis"
                            [text]="'Absence of adjustments to basis'" [checked]="formModel.absenceOfAdjustmentsToBasis"
                            name="absenceOfAdjustmentsToBasis" [disabled]="true" style="margin-left: 0px !important;">
                          </ap-checkbox>
                        </td>
                      </tr>
    
                      <tr>
                        <td>
                          <table style="width: 40%;">
                            <tr>
                              <td>
                                <ap-checkbox class="a-mx-10 checkbox-font" [id]="'line7aOther'" [text]="'Other'"
                                  [checked]="formModel.line7aOther" name="line7aOther" [disabled]="true">                          
                                </ap-checkbox>
                              </td>
          
                              <td *ngIf="formModel.line7aOther" style="position: relative;">
                                <span class="template-color-blue" style="position: absolute; bottom: -2px;">
                                  {{ formModel.line7aOtherText }}
                                </span>
                              </td>
                            </tr>
                        </table>
                        </td>
    
                        <!-- <td *ngIf="formModel.line7aOther">
                          <span class="template-color-blue"> {{ formModel.line7aOtherText }} </span>
                        </td> -->
                      </tr>
    
                    </tbody>
                  </table>
                </td>
              </tr>
    
              <tr>
                <td class="statement-number-tbl-td">b</td>
                
                <td>
                  Enter the total dollar amount of your tax benefits identified in 7a. See instructions
                </td>
    
                <td class="col-md-3 pb-2">
                  <span class="template-color-blue"> {{ formModel.totalTaxBenefitAmt ? (formModel.totalTaxBenefitAmt | number) : '' }} </span>
                </td>
              </tr>
    
              <tr>
                <td class="statement-number-tbl-td">c</td>
    
                <td>
                  Enter the anticipated number of years the transaction provides the tax benefits stated in 7b. See instructions
                </td>
    
                <td class="col-md-3 pb-2">
                  <span class="template-color-blue"> {{ formModel.taxBnftAnticipatedYrCnt ? (formModel.taxBnftAnticipatedYrCnt | number) : '' }} </span>
                </td>
              </tr>
    
              <tr>
                <td class="statement-number-tbl-td">d</td>
    
                <td>
                  Enter your total investment or basis in the transaction. See instructions
                </td>
    
                <td class="col-md-3 pb-2">
                  <span class="template-color-blue"> {{ formModel.totalInvestmentOrBasisAmt ? (formModel.totalInvestmentOrBasisAmt | number) : '' }} </span>
                </td>
              </tr>
    
              <tr>
                <td class="statement-number-tbl-td">e</td>
                <td width="65%">Please specify a Word Document that further
                  describes the amount and nature of the expected tax treatment and
                  expected tax benefits generated by the transaction for all affected
                  years. Include facts of each step of the transaction that relate to
                  the expected tax benefits including the amount and nature of your
                  investment. Include in your description your participation in the
                  transaction and all related transactions regardless of the year in
                  which they were entered into. Also, include a description of any tax
                  result protection with respect to the transaction
                </td>
                <td>&nbsp;</td>
              </tr>

              <tr *ngIf="stmtData?.downloadUrl">
                <td class="statement-number-tbl-td">&nbsp;</td>
                <td>
                  <div class="a-text-primary text-align-center">
                    <span>
                      <a class="attachment" (click)="download()">{{stmtData?.attachmentname }}</a>
                    </span>
                  </div>
                </td>
              </tr>
    
            </tbody>
          </table>
          
        </div>

      </div>

      <table class="statement-section-heading" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>

          </tr>
        </tbody>
      </table>

      <!-- Section 7 -->
      <div class="section-content ap-form-field-my-wrapper">

        <table>
          <tbody>
            <tr>
              <td class="statement-number-tbl-td">8</td>
              <td>
                Identify all tax-exempt, foreign, and related
                entities and individuals involved in the transaction. Check the
                appropriate box(es). Include their name(s), identifying number(s),
                address(es), and a brief description of their involvement. For each
                foreign entity, identify its country of incorporation or existence.
                For each related entity, explain how it is related.
              </td>
            </tr>
          </tbody>
        </table>

        <ng-container ng-if="attMap['IdentifyAllInvolvedInTrTaxExempt'].length >= 1">
          <div *ngFor="let val of attMap['IdentifyAllInvolvedInTrTaxExempt'];let i=index" class="propDiv">

            <div class="tab-section-content section-content propTbl pl-5">
              <table style="table-layout: fixed;">
                <tbody>
                  <tr>
                    <td class="statement-number-tbl-td">a</td>
                    <td>Type of Entity:</td>
                    <td colspan="2">
                      <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Tax-exempt'" [id]="'identifyAllInvolvedInTrTaxExempt'"
                        [checked]="attMap['IdentifyAllInvolvedInTrTaxExempt'] == undefined ? false 
                        : attMap['IdentifyAllInvolvedInTrTaxExempt'][i] === '1'" [disabled]="true">
                      </ap-checkbox>
                      <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Foreign'" [id]="'identifyAllInvolvedInTrForeign'"
                        [checked]="attMap['IdentifyAllInvolvedInTrForeign'] == undefined ? false 
                        : attMap['IdentifyAllInvolvedInTrForeign'][i] === '1'" [disabled]="true">
                      </ap-checkbox>
                      <ap-checkbox class="a-mx-10 checkbox-font" [text]="'Related'" [id]="'identifyAllInvolvedInTrRelated'"
                        [checked]="attMap['IdentifyAllInvolvedInTrRelated'] == undefined ? false 
                        : attMap['IdentifyAllInvolvedInTrRelated'][i] === '1'" [disabled]="true">
                      </ap-checkbox>
                    </td>
                    <td></td>
                  </tr>

                  <br />
                  <!-- <tr [hidden]="attMap['InGOLD8'] == undefined || attMap['InGOLD8'][i] === ''">
                    <td class="statement-number-tbl-td"></td>
                    <td>Is Participating Entity in Legal Entity Database?</td>
                    <td>
                      <ap-radio class="a-mx-10 radio-font" id="inGOLD_Yes" name="inGOLD_{{ i }}" [text]="'Yes'"  [disabled]="true"
                        [checked]="attMap['InGOLD8'][i] === '1'">
                      </ap-radio>
                      <ap-radio class="a-mx-10 radio-font" id="inGOLD_No" name="inGOLD_{{ i }}" [text]="'No'"  [disabled]="true"
                        [checked]="attMap['InGOLD8'][i] === '0'">
                      </ap-radio>
                    </td>
                    <td></td>
                    <td></td>
                  </tr> -->

                  <!-- <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td"></td>
                    <td>Please specify the LEID of the Entity that Participated in the Transaction</td>
                    <td>
                      <span class="template-color-blue">{{ attMap['IdentifyAllInvolvedInTrLEID'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrLEID'][i] }}</span>
                    </td>
                  </tr> -->

                  <!-- InGOLD8: 0 -->
                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">b</td>
                    <td>Entity Name:</td>
                    <td colspan="3">
                      <span class="pl-2 template-color-blue">{{ attMap['IdentifyAllInvolvedInTrEntityName'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrEntityName'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">c</td>
                    <td>Employer identification number (EIN):</td>
                    <td>
                      <span class="pl-2 template-color-blue">{{ attMap['IdentifyAllInvolvedInTrEntityEIN'] == undefined ? 'N/A' 
                        : attMap['IdentifyAllInvolvedInTrEntityEIN'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1') && (attMap['IdentifyAllInvolvedInTrEntityEIN'] === undefined || attMap['IdentifyAllInvolvedInTrEntityEIN'][i] === '')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>EIN Missing Reason:</td>
                    <td class="pt-2 pl-2 col-md-3">
                      <span class="template-color-blue">{{ attMap['IdentifyAllInvolvedInTrMissingEINReason'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrMissingEINReason'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 1:</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityAddressLine1'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrEntityAddressLine1'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 2:</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityAddressLine2'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrEntityAddressLine2'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['IdentifyAllInvolvedInTrEntityUSEntity'] !== undefined && attMap['IdentifyAllInvolvedInTrEntityUSEntity'][i] === true) ? 'City:' : 'City / Town:'}}</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityCity'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrEntityCity'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['IdentifyAllInvolvedInTrEntityUSEntity'] !== undefined && attMap['IdentifyAllInvolvedInTrEntityUSEntity'][i] === true) ? 'State:' : 'Province or State:'}}</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityState'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrEntityState'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1') && (attMap['IdentifyAllInvolvedInTrEntityUSEntity'] !== undefined && attMap['IdentifyAllInvolvedInTrEntityUSEntity'][i] === false)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Country:</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityCountry'] == undefined ? ''
                        : attMap['IdentifyAllInvolvedInTrEntityCountry'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '1')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['IdentifyAllInvolvedInTrEntityUSEntity'] !== undefined && attMap['IdentifyAllInvolvedInTrEntityUSEntity'][i] === true) ? 'Zip Code:' : 'Postal Code:'}}</td>
                    <td colspan="3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrEntityZIPCode'] == undefined ? ''
                        : attMap['IdentifyAllInvolvedInTrEntityZIPCode'][i] }} </span>
                    </td>
                  </tr>

                  <!-- InGOLD8: 0 -->
                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Name of Entity</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2">{{ attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine1'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Name Entity Line 2</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2">{{ attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrNameInvolvedInTransactionBusinessNameLine2'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Identifying Number SSN</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2">{{ attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'] == undefined ? 'N/A' 
                        : attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Identifying Number EIN</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2">{{ attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'] == undefined ? 'N/A' 
                        : attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') 
                    && ((attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'] !== undefined) && (attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'][i] == undefined || attMap['IdentifyAllInvolvedInTrEINNumberInvolvedInTransaction'][i] == '') 
                      || (attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'] !== undefined) && (attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'][i] == undefined || attMap['IdentifyAllInvolvedInTrSSNNumberInvolvedInTransaction'][i] == ''))">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>If no Identifying Number, specify reason</td>
                    <td class="col-md-3">
                      <span class="pl-2">{{ attMap['IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr'] == undefined ? 'N/A' 
                        : attMap['IdentifyAllInvolvedInTrMissingEINReasonInvolvedInTr'][i] }}</span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Is this a US or Foreign Entity?</td>
                    <td class="pt-2 pl-2">
                      <ap-radio class="a-mx-10 radio-font" name="USForPart8Rad_{{ i }}" [text]="'US'" [id]="'usForPart8Rad'" [disabled]="true"
                        [checked]="attMap['USForPart8'] != undefined && attMap['USForPart8'][i] === 'US'">
                      </ap-radio>
                      <ap-radio class="a-mx-10 radio-font" name="USForPart8Rad_{{ i }}" [text]="'Foreign'" [id]="'foreignForPart8Rad'" [disabled]="true"
                        [checked]="attMap['USForPart8'] != undefined && attMap['USForPart8'][i] === 'FOREIGN'">
                      </ap-radio>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 1:</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ (attMap['USForPart8'][i] === 'US') ? (attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine1'][i]) : ((attMap['USForPart8'][i] === 'FOREIGN') ? (attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine1'][i]): '') }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Address Line 2:</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ (attMap['USForPart8'][i] === 'US') ? (attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionAddressLine2'][i]) : ((attMap['USForPart8'][i] === 'FOREIGN') ? (attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrAddressLine2'][i]): '') }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['USForPart8'][i] === 'US') ? 'City:' : 'City / Town:'}}</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ (attMap['USForPart8'][i] === 'US') ? (attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionCity'][i]) : ((attMap['USForPart8'][i] === 'FOREIGN') ? (attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCity'][i]): '') }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['USForPart8'][i] === 'US') ? 'State:' : 'Province or State:'}}</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ (attMap['USForPart8'][i] === 'US') ? (attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionState'][i]) : ((attMap['USForPart8'][i] === 'FOREIGN') ? (attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrProvinceOrState'][i]): '') }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined) && (attMap['USForPart8'][i] === 'FOREIGN')">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>Country:</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry'] == undefined ? '': attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrCountry'][i] }} </span>
                    </td>
                  </tr>

                  <tr *ngIf="(attMap['InGOLD8'] !== undefined) && (attMap['InGOLD8'][i] === '0') && (attMap['USForPart8'] != undefined)">
                    <td class="statement-number-tbl-td">&nbsp;</td>
                    <td>{{ (attMap['USForPart8'][i] === 'US') ? 'Zip:' : 'Postal Code:'}}</td>
                    <td colspan="3" class="col-md-3">
                      <span class="pl-2"> {{ (attMap['USForPart8'][i] === 'US') ? (attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrUSAddressInvolvedInTransactionZIPCode'][i]) : ((attMap['USForPart8'][i] === 'FOREIGN') ? (attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode'] == undefined ? '' 
                        : attMap['IdentifyAllInvolvedInTrForeignAddressInvolvedInTrPostalCode'][i]): '') }} </span>
                    </td>
                  </tr>

                  <br />
                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td>Description of Involvement</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td class="statement-number-tbl-td"></td>
                    <td colspan="4">
                      <div class="a-textarea-box a-field-length-validation form-inlines">
                        <ap-form-field [inlineBlock]="true" [withNumberValidation]="false"
                          [type]="'textarea'" [withButton]="true" [isDisabled]="true"
                          [value]="attMap['IdentifyAllInvolvedInTrDescriptionOfInvolvement'] != undefined && attMap['IdentifyAllInvolvedInTrDescriptionOfInvolvement'][i]">
                        </ap-form-field>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </ng-container>

      </div>

      <table style="width:100%">
        <tbody>
          <tr>
            <td class="footer-left">
              LEIDs: <span class="template-color-blue"> {{ stmtData.primaryleid == null ? '': stmtData.primaryleid }} </span> 
              <span class="template-color-blue"> {{ stmtData.secondaryleid == null ? '': ' / ' + stmtData.secondaryleid }} </span>
            </td>
            <td class="footer-right"></td>
          </tr>
          <tr>
            <td class="footer-left">
              Statement ID: <span class="template-color-blue"> {{ stmtData.statementid }} </span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>