<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [statementId]="stmtData?.statementid" [isFiled]="stmtData?.isfiled"
  [isQAR]="stmtData?.isqar"></dst-create-disclosure-actions>
<div class="form-template-wrapper">
  <div align=center>
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        Form 8824 - Like-Kind Exchanges
        <br />
      </span>
    </B>
  </div>
  <br />
  <div [formGroup]="form237">
    <div class="section-content">
      <div class="ap-form-field-my-wrapper">

        <div class="entity-details">
          <span>Name as shown on tax return:</span><span class="template-color-blue"
            *ngIf="loaded">{{stmtData.primaryentity == null? '': stmtData.primaryentity}}</span>
        </div>
        <div class="entity-details">
          <span>Identifying Number</span><span class="template-color-blue" *ngIf="loaded"> {{stmtData.primaryein ==
            null? 'NA' : stmtData.primaryein}}</span>

        </div>
        <div class="entity-details">
          <span>Address Line 1:</span><span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryaddress1 ==
            null? '': stmtData.primaryaddress1}}</span>
        </div>
        <div class="entity-details">
          <span>Address Line 2:</span><span class="template-color-blue" *ngIf="loaded"> {{stmtData.primaryaddress2 ==
            null? '' : stmtData.primaryaddress2}}</span>
        </div>
        <div class="entity-details">
          <span>City:</span><span class="template-color-blue" *ngIf="loaded">{{stmtData.primarycity == null? '':
            stmtData.primarycity}}</span>
        </div>
        <div class="entity-details">
          <span>State:</span><span class="template-color-blue" *ngIf="loaded"> {{stmtData.primarystate == null? '' :
            stmtData.primarystate}}</span>
        </div>
        <div class="entity-details">
          <span>Country:</span><span class="template-color-blue" *ngIf="loaded">{{stmtData.primarycountry == null? '':
            stmtData.primarycountry}}</span>
        </div>
        <div class="entity-details">
          <span>Zip Code:</span><span class="template-color-blue" *ngIf="loaded"> {{stmtData.primaryzip == null? 'NA' :
            stmtData.primaryzip}}</span>
        </div>
      </div>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part I&nbsp;&nbsp;</strong>
            <strong>Information on the Like-Kind Exchange</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper">
      <div>Note: Generally, only real property should be described on line 1 or 2. However, you may describe personal
        and/or real property on line 1 or 2 if you are filling this form to report the disposition of property exchanged
        in a previously reported related part like-kind exchange. If the property described on line 1 or line 2 is real
        or personal property located outside the United States, indicate the country.</div>
      <ol class="section-content">
        <li>
          <div class="d-flex">
            <div class="col-md-12 pl-0">
              Description of Like-Kind Property Given Up
            </div>
          </div>
          <div class="d-flex">
            <div class="col-md-12 pl-0" *ngIf="loaded">
              <div class="a-textarea-box a-field-length-validation form-inlines">
                <span class="template-color-blue">{{attMap['DescOfLikeKindPropertyGivenUp'] == undefined ? '' :
                  attMap['DescOfLikeKindPropertyGivenUp'][0]}}</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-12 pl-0">
              Description of Like-Kind Property Received
            </div>
          </div>
          <div class="d-flex">
            <div class="col-md-12 pl-0" *ngIf="loaded">
              <div class="a-textarea-box a-field-length-validation form-inlines">
                <span class="template-color-blue">
                  {{attMap['DescOfLikeKindPropertyReceived'] == undefined ? '' :
                  attMap['DescOfLikeKindPropertyReceived'][0]}}
                </span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-8 pl-0">
              Date like-kind property given up was originally acquired
            </div>
            <div class="col-md-4 date-fields" *ngIf="loaded" style="text-align: start;">
              <span class="template-color-blue">
                {{attMap['DateLikeKindPropertyGivenUpAcq'] == undefined ? '' :
                attMap['DateLikeKindPropertyGivenUpAcq'][0]}}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-8 pl-0">
              Date you actually transferred your property to other party
            </div>
            <div class="col-md-4 date-fields" *ngIf="loaded" style="text-align: start;">
              <span class="template-color-blue">
                {{attMap['DatePropActuallyTransferred'] == undefined ? '' : attMap['DatePropActuallyTransferred'][0]}}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-8 pl-0">
              Date like-kind property you received was identified by written notice to another party. See instructions
              for 45-day written identification requirement
            </div>
            <div class="col-md-4 date-fields" *ngIf="loaded" style="text-align: start;">
              <span class="template-color-blue">
                {{attMap['DateLikeKindPropRcvdIdentified'] == undefined ? '' :
                attMap['DateLikeKindPropRcvdIdentified'][0]}}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-8 pl-0">
              Date you actually received the like-kind property from other party
            </div>
            <div class="col-md-4 date-fields" *ngIf="loaded" style="text-align: start;">
              <span class="template-color-blue">
                {{attMap['DatePropertyActuallyReceived'] == undefined ? '' : attMap['DatePropertyActuallyReceived'][0]}}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-8 pl-0">
              Was the exchange of the property given up or received made with a related party, either directly or
              indirectly (such as through an intermediary?)
              <br />
              If Yes complete Part II. If No go to Part III
            </div>
            <div class="col-md-4" *ngIf="loaded">
              <ap-radio [name]="'radio-group-1'" [text]="'Yes'"
                [checked]="attMap['ExchangeMadeWithRelatedParty'] != undefined && attMap['ExchangeMadeWithRelatedParty'][0] == '1'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
              <ap-radio [name]="'radio-group-1'" [text]="'No'"
                [checked]="attMap['ExchangeMadeWithRelatedParty'] != undefined && attMap['ExchangeMadeWithRelatedParty'][0] == '0'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
            </div>
          </div>
        </li>
      </ol>
    </div>
    <div>
      <strong>Note:</strong> <em>
        Do not file this form if a related party sold property into the exchange, directly or
        indirectly(such as through an intermediary); that property became your replacement property;and none of the
        exceptions in line 11 applies to the exchange, Instead, report the disposition of the property as if the
        exchange had been a sale. If one of the exceptions on line 11 applies to the exchange, complete Part II
      </em>
    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0" *ngIf="partII">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part II&nbsp;&nbsp;</strong>
            <strong>Related Party Exchange Information</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper" *ngIf="partII">
      <ol class="section-content" start="8" *ngIf="loaded">
        <li>
          <div class="entity-details">
            <span class="label-name">Please specify the LEID of the Related Party</span><span>
              {{stmtData.secondaryleid == null? '': stmtData.secondaryleid}}
            </span>
          </div>
          <div class="entity-details">
            <span class="label-name">Name of Related Party:</span><span class="template-color-blue">
              {{stmtData.secondaryentity == null? '': stmtData.secondaryentity}}
            </span>
          </div>
          <div class="entity-details">
            <span class="label-name">Identifying number:</span><span class="template-color-blue">
              {{stmtData.secondaryein == null? '': stmtData.secondaryein}}
            </span>
          </div>
          <div class="entity-details">
            <span class="label-name">EIN Missing Reason:</span><span class="template-color-blue">
              {{attMap['MissingEINReason'] == undefined ? '' : attMap['MissingEINReason'][0]}}
            </span>
          </div>
          <div class="entity-details">
            <span class="label-name">Relationship to you</span><span class="template-color-blue">
              {{attMap['RelationshipToYou'] == undefined ? '' : attMap['RelationshipToYou'][0]}}
            </span>
          </div>

        </li>
        <li>
          <div class="row">
            <div class="col-md-8">
              During this tax year (and before the date that is 2 years after the last transfer of property that was
              part of the exchange), did the related party sell or dispose of any part of the like-kind property
              received from you (or an intermediary) in the exchange ?
            </div>
            <div class="col-md-4" *ngIf="loaded">

              <ap-radio [name]="'radio-group-2'" [text]="'Yes'"
                [checked]="attMap['RelatedPartySoldPropReceived'] != undefined && attMap['RelatedPartySoldPropReceived'][0] == '1'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
              <ap-radio [name]="'radio-group-2'" [text]="'No'"
                [checked]="attMap['RelatedPartySoldPropReceived'] != undefined && attMap['RelatedPartySoldPropReceived'][0] == '0'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-md-8">
              During this tax year (and before the date that is 2 years after the last transfer of property that was
              part of the exchange) did you sell or dispose of any part of the like-kind property you received?
            </div>
            <div class="col-md-4" *ngIf="loaded">
              <ap-radio [name]="'radio-group-3'" [text]="'Yes'"
                [checked]="attMap['YouSoldPropertyReceived'] != undefined && attMap['YouSoldPropertyReceived'][0] == '1'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
              <ap-radio [name]="'radio-group-3'" [text]="'No'"
                [checked]="attMap['YouSoldPropertyReceived'] != undefined && attMap['YouSoldPropertyReceived'][0] == '0'"
                [disabled]="true" class="a-mx-10 radio-font">
              </ap-radio>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <br />
              If both lines 9 and 10 are No and this is the year of the exchange, go to Part III. If both lines 9 and
              10 are No and this is not the year of the exchange, stop here. If either line 9 or line 10 is Yes
              complete Part III and report on this year's tax return the deferred gain or (loss) from line 24 unless
              one of the exceptions on line 11 applies.
            </div>
          </div>
        </li>
        <li>
          If one of the exceptions below applies to the disposition, check (X) the applicable box below (a, b, or c)
          <ol class="inner-list section-content">
            <li>
              <div class="checkbox-list">
                <div class="col-md-1" *ngIf="loaded">
                  <ap-checkbox [text]="''"
                    [checked]="attMap['DisposWasAfterDeathRltdParties'] != undefined && attMap['DisposWasAfterDeathRltdParties'][0] == '1'"
                    [disabled]="true" class="a-mx-10 checkbox-font">
                  </ap-checkbox>
                </div>
                <div class="col-md-11 pl-0">
                  The disposition was after the death of either of the related parties
                </div>

              </div>
            </li>
            <li>
              <div class="checkbox-list">
                <div class="col-md-1" *ngIf="loaded">
                  <ap-checkbox [text]="''"
                    [checked]="attMap['DisposWasInvoluntaryConversion'] != undefined && attMap['DisposWasInvoluntaryConversion'][0] == '1'"
                    [disabled]="true" class="a-mx-10 checkbox-font">
                  </ap-checkbox>
                </div>
                <div class="col-md-11 pl-0">
                  The disposition was an involuntary conversion, and the threat of conversion occurred after the
                  exchange
                </div>

              </div>

            </li>
            <li>
              <div class="checkbox-list">
                <div class="col-md-1" *ngIf="loaded">
                  <ap-checkbox [text]="''"
                    [checked]="attMap['ExchangeDisposNotTaxAvoidance'] != undefined && attMap['ExchangeDisposNotTaxAvoidance'][0] == '1'"
                    [disabled]="true" class="a-mx-10 checkbox-font">
                  </ap-checkbox>
                </div>
                <div class="col-md-11 pl-0">
                  You can establish to the satisfaction of the IRS that nether the exchange nor the disposition had
                  tax avoidance as its principal purpose. If this box is checked, specify an explanation
                </div>

              </div>

            </li>
          </ol>
        </li>
      </ol>
      <textarea rows="5" cols="80" readonly class="txtborder w-100" *ngIf="loaded">
        {{attMap['PrincipalPrpsRltdPartyExchStmtExplanation'] == undefined ? '' :
        attMap['PrincipalPrpsRltdPartyExchStmtExplanation'][0]}}
      </textarea>

    </div>
    <table class="statement-section-heading" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="header-text">
            <strong>&nbsp;&nbsp;Part III&nbsp;&nbsp;</strong>
            <strong>Realized Gain or (Loss), Recognized Gain, and Basis of Like-Kind Property Received</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section-content ap-form-field-my-wrapper">
      <strong>Caution:&nbsp;</strong><span>If you transferred and received (a) more than one group of like-kind
        properties or (b) cash or other (not
        like-kind) property, see Reporting of multi-asset exchanges in the instructions.</span>
      <span class="appkiticon icon-information-fill" [tooltip-container]="template1" [placement]="'right'"
        [isWhite]="true"></span>
      <div class="d-flex">
        <div class="col-md-4 pl-0">
          Amount of the Gain on Multi-Asset Exchange
        </div>
        <div class="col-md-8" style="text-align: start;" *ngIf="loaded">
          <span class="template-color-blue">
            ${{attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount'] == undefined ? '' :
            (attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeAmount'][0] | amount)}}
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-between row">
        <div class="col-md-12">
          Include the details of the calculation of realized and recognized gain on multi-asset exchanges in text box
          below
        </div>
      </div>
      <div class="list-content a-textarea-box a-field-length-validation form-inlines" *ngIf="loaded">
        <span class="template-color-blue">
          {{attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation'] == undefined ? '' :
          attMap['GainInMultiAssetExchangesStmtGainInMultiAssetExchangeExplanation'][0]}}
        </span>
      </div>
      <div>
        Note: complete lines 12 through 14 ONLY if you gave up property that was not like-kind. Otherwise, go to line
        15.
      </div>
      <ol class="section-content part-three-fields" start="12">
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Fair market value (FMV) of other property given up
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['FMVOfOtherPropertyGivenUp'] == undefined ? '' : attMap['FMVOfOtherPropertyGivenUp'][0] |
                number}}
              </div>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex">
            <div class="col-md-10 pl-0">
              a. Description of other property given up
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['DescriptionOfOtherPropertyGivenUp'] == undefined ? '' :
                attMap['DescriptionOfOtherPropertyGivenUp'][0]}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Adjusted basis of other property given up
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['AdjBasisOfOtherPropertyGivenUp'] == undefined ? '' :
                attMap['AdjBasisOfOtherPropertyGivenUp'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Gain or (loss) recognized on other property given up. Subtract line 13 from line 12. Report the gain or
              (loss) in the same manner as if the exchange had been a sale
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['GainLossOnOtherPropertyGivenUp'] == undefined ? '' :
                attMap['GainLossOnOtherPropertyGivenUp'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Cash received, FMV of other property received, plus net liabilities assumed by other party, reduced (but
              not below zero) by any exchange expenses you incurred
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['CashFMVAndNetLiabRedByExpenses'] == undefined ? '' :
                attMap['CashFMVAndNetLiabRedByExpenses'][0] | number}}
              </div>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex">
            <div class="col-md-10 pl-0">
              a. Description of other property received
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['DescriptionOfOtherPropertyRcvd'] == undefined ? '' :
                attMap['DescriptionOfOtherPropertyRcvd'][0]}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              FMV of like-kind property you received
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['FMVOfLikeKindPropertyReceived'] == undefined ? '' : attMap['FMVOfLikeKindPropertyReceived'][0]
                | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Add lines 15 and 16
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['AmountRealized'] == undefined ? '' : attMap['AmountRealized'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Adjusted basis of like-kind property you gave up, net amounts paid to other party, plus any exchange
              expenses not used on line 15
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['AdjBasisOfLikeKindPropGivenUp'] == undefined ? '' : attMap['AdjBasisOfLikeKindPropGivenUp'][0]
                | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Realized gain or (loss). subtract line 18 from line 17
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['RealizedGainOrLoss'] == undefined ? '' : attMap['RealizedGainOrLoss'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Enter the smaller of line 15 or line 19, but not less than zero
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['SmallerGainOrLoss'] == undefined ? '' : attMap['SmallerGainOrLoss'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Ordinary income under recapture rule. Enter here and on Form 4797, line 16
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['OrdinaryIncmUndRecaptureRules'] == undefined ? '' : attMap['OrdinaryIncmUndRecaptureRules'][0]
                | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Subtract line 21 from line 20. If zero or less, enter -0-. If more than zero, enter here and on Schedule D
              or Form 4797, unless the installment method applies
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['SmallerGainLossLessOrdnryIncm'] == undefined ? '' : attMap['SmallerGainLossLessOrdnryIncm'][0]
                | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Recognized gain. Add lines 21 and 22
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['RecognizedGain'] == undefined ? '' : attMap['RecognizedGain'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Deferred gain or (loss). Subtract line 23 from line 19
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['DeferredGainOrLoss'] == undefined ? '' : attMap['DeferredGainOrLoss'][0] | number}}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex">
            <div class="col-md-10 pl-0">
              Basis of like-kind property received. Subtract line 15 from the sum of lines 18 and 23
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['BasisOfLikeKindPropertyRcvd'] == undefined ? '' : attMap['BasisOfLikeKindPropertyRcvd'][0] |
                number}}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div *ngIf="taxYear === '2023'" class="col-md-10 pl-0">
              a. Basis of Like-Kind section 1250 property recieved
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['BasisOfLikeKindSect1250PropertyRcvd'] == undefined ? '' :
                attMap['BasisOfLikeKindSect1250PropertyRcvd'][0] | number}}
              </div>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex">
            <div class="col-md-10 pl-0">
              b. Basis of like-kind section 1245 property recieved
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['BasisOfLikeKindSect1245PropertyRcvd'] == undefined ? '' :
                attMap['BasisOfLikeKindSect1245PropertyRcvd'][0]}}
              </div>
            </div>
          </div>
          <div *ngIf="taxYear === '2023'" class="d-flex">
            <div class="col-md-10 pl-0">
              c. Basis of like-kind intangible property recieved
            </div>
            <div class="col-md-2 pr-0 text-align-end" *ngIf="loaded">
              <div class="">
                {{attMap['BasisOfLikeKindIntagblePropertyRcvd'] == undefined ? '' :
                attMap['BasisOfLikeKindIntagblePropertyRcvd'][0]}}
              </div>
            </div>
          </div>
        </li>
      </ol>

    </div>
    <table style="width:100%">
      <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs: <span class="template-color-blue"> {{stmtData.primaryleid == null? '': stmtData.primaryleid}} </span>
            / <span class="template-color-blue"> {{stmtData.secondaryleid == null? '': stmtData.secondaryleid}} </span>
          </td>
          <td class="footer-right">
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #template1>
  <div class="tooltip-inner a-p-20 d-block a-min-width-340">
    <div class="a-h6 a-text-7d7 font-weight-normal text-left a-mt-10">
      Reporting of multi-asset exchanges. If you transferred and received (a) more than one group of like-kind
      properties or (b) cash or other (not like-kind) property, do not complete lines 12 through 18 of Form 8824.
      Instead, attach your own statement showing how you figured the realized and recognized gain, and enter the
      correct amount on lines 19 through 25. Report any recognized gains on your Schedule D; Form 4797, Sales of
      Business Property; or Form 6252, Installment Sale Income, whichever applies.
    </div>
  </div>
</ng-template>
