<div class="a-pl-10 a-pr-10 bread-crumbs">
    <div class="top-bar">
      <span class="appkiticon icon-like-outline icon-transform-outline structure"></span>
      <span class="structure">{{
        taxYear }} <span class="appkiticon icon-right-chevron-outline"></span> {{ submission.RETURN_TYPE }} <span class="appkiticon icon-right-chevron-outline"></span> {{ submission.TAX_PAYER_NAME }}</span>
    </div>
  </div><div class="grid-container">
    <div class="row bg-white" id="body-row">
      <div id="sidebar-container" class="sidebar-expanded d-none d-md-block">
        <ul class="list-group">

          <a href="#" data-toggle="sidebar-collapse"
            class="list-group-item list-group-item-action d-flex align-items-center">
            <div class="d-flex w-100 justify-content-start align-items-center">

              <span class="appkiticon icon-menu-fill icon-menu-outline" (click)="collapseClick()"></span>

              <div class="d-flex w-100 justify-content-start align-items-center search-box">
                <ap-search class="menu-collapsed" [isPrimary]="true" [isBase]="true" placeholder="Search">
                </ap-search>
              </div>
            </div>
          </a>

          <ap-list class="list-group-item list-group-item-action flex-column main-list align-items-start">
            <ap-list-item class="sidebar-submenu menu-collapsed" routerLinkActive="selected-nav">
              <div class="menu-collapsed" routerLink="/dashboard" [skipLocationChange]="true"> Dashboard </div>
            </ap-list-item>
            <ap-list-item class="sidebar-submenu">
              <div class="menu-collapsed"> Diagnostics </div>
            </ap-list-item>
            <ap-list-item-accordions type="basic" [show]="true" class="menu-collapsed">
              <div slot="header">
                <ap-list-item [itemLayout]="'block'" class="d-flex w-100 justify-content-start align-items-center">
                  <div class="text-truncate menu-collapsed d-flex align-items-center"> Form Support </div>
                </ap-list-item>
              </div>
              <div slot="children">
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> 851 </div>
                </ap-list-item>
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> 5713 </div>
                </ap-list-item>
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> 3800 </div>
                </ap-list-item>
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> 5472 </div>
                </ap-list-item>
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> Schedule 0 </div>
                </ap-list-item>
                <ap-list-item class="sidebar-submenu small-list">
                  <div class="menu-collapsed"> 8473 Signature </div>
                </ap-list-item>
              </div>
            </ap-list-item-accordions>
            <ap-list-item class="sidebar-submenu">
              <div class="menu-collapsed"> PDF Upload </div>
            </ap-list-item>
            <ap-list-item class="sidebar-submenu" routerLinkActive="selected-nav">
              <div class="menu-collapsed" routerLink="/submit" [skipLocationChange]="true"> Submission </div>
            </ap-list-item>
            <ap-list-item class="sidebar-submenu">
              <div class="menu-collapsed"> Setup </div>
            </ap-list-item>
          </ap-list>
        </ul>
      </div>
      <div class="main-content">
        <router-outlet ></router-outlet>
        <!-- <div class="card mb-4 box-shadow">
          <div class="card-body d-flex flex-column">
           
          </div>
        </div> -->
      </div>
    </div>
  </div>