import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gtw-diagnostic-filter',
  templateUrl: './diagnostic-filter.component.html',
  styleUrls: ['./diagnostic-filter.component.scss']
})
export class DiagnosticFilterComponent implements OnInit {

  constructor() { }
  @Input('diag-name')
  diagName !: string;

  ngOnInit(): void {
    console.log('diaName',this.diagName);
  }

}
