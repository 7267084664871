import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { NaicsMaintenanceService } from './naics-maintenance.service';

@Component({
  selector: 'gtw-naics-maintenance',
  templateUrl: './naics-maintenance.component.html',
  styleUrls: ['./naics-maintenance.component.scss']
})
export class NaicsMaintenanceComponent implements OnInit, AfterViewInit {

  public secondaryConfig = {
    isSecondary: true
  }

  public primaryConfig = {
    isPrimary: true
  }

  loader: boolean = false;
  naicsForm!: FormGroup;
  isEdit:boolean = false;
  initializeComplete:boolean = false;
  private scenarioCode: string = '';
  private isIssueKeyEnabled: string = '';
  private isLocked: string = '';
  private issueKey: number = 0;


  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();

  @Input('row-data')
  set _row_data(rowData: string) {
    try {
      if(rowData != '{{ctrl.rowData}}') {
        this.initializeComplete = true;
      }
      let params = JSON.parse(rowData);

      if (params != undefined && params != '') {
        this.rowData = params;
        this.initialize();

      }
    } catch (e) {
      console.log("rowData Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
  }

  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };
  rowData!: rowData;
  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;

  constructor(private naicsService: NaicsMaintenanceService) {
    this.naicsForm = new FormGroup({
      code: new FormControl('', Validators.compose([Validators.required, 
                              Validators.pattern("^[0-9]{6}$")
                            ])),
      codeDesc: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  initialize(): void {
    let desc = this.rowData.CODE_DESC ? this.rowData.CODE_DESC.substring(7) : '';
    this.naicsForm.patchValue({
      code: this.rowData.CODE_NAME,
      codeDesc: desc
    });
    this.isEdit = true;
  }

  insert(): void {
    if (this.naicsForm.status === 'INVALID') {
      this.naicsService.openSnackBar("Please enter all the required fields!", 'gtw-snackbar--red');
      return;
    }
    this.loader = true;
    let params : saveParams = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key
    }
    let clob_settings : saveParams = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
    let clob_data : rowData[] = [{
      CODE_SET_KEY: this.isEdit ? this.rowData.CODE_SET_KEY : null,
      CODE_NAME: this.naicsForm.value.code,
      CODE_DESC: `${this.naicsForm.value.code}-${this.naicsForm.value.codeDesc}`,
    }]
    this.naicsService.saveNaicsCode(this.baseURLs.api, params, clob_settings, clob_data, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey).subscribe((data) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        console.log("Error while saving");
        this.loader = false;
      }
    }, () => {
      console.log("Error while saving");
      this.loader = false;
    });
  }

  cancel(): void {
    this.requestGridUpdate.emit('');
  }

}

export type rowData = {
  CODE_SET_KEY: string | null | undefined;
  CODE_NAME: string | undefined;
  CODE_DESC: string | undefined;
}

export type saveParams = {
  tax_year: string | undefined;
  scenario: string | undefined;
  jcd_key: string | undefined;
}

