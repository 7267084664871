export class DSTFormState {
  taxYear: number;
  taxYearId: number;
  returnPeriodId: number;
  statementId: string; // each statement/form has a unique ID after creation
  formId: number; // a unique ID for each template form -- i.e. form 926, form 8883
  formName: string;
  formType: string;
  formTypeId: number; // a unique ID for each form type -- i.e. "Template Form", "PDF"
  cseId: number;
  isChild: boolean; // is created as a child disclosure
  isView: boolean;
  uploadType: string; // upload type could be "Bulk Upload" or "Bulk Supersede" or "" for attached templates
  formData: StmtData | undefined;
  doPDFSync: boolean;
  csStatementId!: number;
  is304: boolean;

  constructor(taxYear: number,
              taxYearId: number,
              returnPeriodId: number,
              statementId: string,
              formId: number,
              formName: string,
              formType: string,
              formTypeId: number,
              cseId: number,
              isChild: boolean = false,
              isView: boolean = false,
              uploadType: string = '',
              csStatementId: number = 0,
              is304: boolean = false,
              formData?: StmtData) {
    this.taxYear = taxYear;
    this.taxYearId = taxYearId;
    this.returnPeriodId = returnPeriodId;
    this.statementId = statementId;
    this.formId = formId;
    this.formName = formName;
    this.formType = formType;
    this.formTypeId = formTypeId;
    this.cseId = cseId;
    this.isChild = isChild;
    this.isView = isView;
    this.uploadType = uploadType;
    this.doPDFSync = false;
    formData && (this.formData = formData);
    this.csStatementId = csStatementId;
    this.is304 = is304;
  }

  setDoPDFSync(): void {
    this.doPDFSync = this.isView;
  }

  setFormData(data: StmtData | undefined): void {
    this.formData = data;
  }

  getFormData(): StmtData | undefined {
    return this.formData;
  }
}

export interface StmtData {
  isCopiedStatement: boolean;
  statementid: number;
  parentstatementid?: string;
  taxyearid?: string;
  taxyear?: string;
  comments?: string;
  returnperiodid?: string;
  returntypedescription?: string;
  dcnid?: string;
  dcnname?: string;
  primaryentityid?: string;
  primaryentity?: string;
  primaryleid?: string;
  primarymaintowner?: string;
  primaryein?: string;
  primarycountryinc?: string;
  primarycountryincdesc?: string;
  primarystateinc?: string;
  primarystateincdesc?: string;
  primaryaddress1?: string;
  primaryaddress2?: string;
  primarycity?: string;
  primarystate?: string;
  primarycountry?: string;
  primarycountryname?: string;
  primaryzip?: string;
  primarytaxtypeid?: string;
  primaryalttaxtypeid?: string;
  primarytaxtype?: string;
  primaryalttaxtype?: string;
  primarybusinessid?: string;
  primarybusiness?: string;
  primarygegecsid?: string;
  primarygegecs?: string;
  secondarybusinessid?: string;
  secondarybusiness?: string;
  secondaryentityid?: string;
  secondaryentity?: string;
  secondaryleid?: string;
  secondarymaintowner?: string;
  secondaryein?: string;
  secondarycountryinc?: string;
  secondarycountryincdesc?: string;
  secondarystateinc?: string;
  secondarystateincdesc?: string;
  secondaryaddress1?: string;
  secondaryaddress2?: string;
  secondarycity?: string;
  secondarystate?: string;
  secondarycountry?: string;
  secondarycountryname?: string;
  secondaryzip?: string;
  secondarytaxtypeid?: string;
  secondaryalttaxtypeid?: string;
  secondarytaxtype?: string;
  secondaryalttaxtype?: string;
  secondarygegecsid?: string;
  secondarygegecs?: string;
  branchentityid?: string;
  branchleid?: string;
  branchentity?: string;
  acknowledge?: string;
  attachmentname?: string;
  attachmentpath?: string;
  attachmenturl?: string;
  formid?: string;
  parentformid?: string;
  projectid?: string;
  projectstepid?: string;
  statementname?: string;
  displayorder?: string;
  sectionname?: string;
  statementtypeid?: string;
  statementtype?: string;
  primarytype?: string;
  secondarytype?: string;
  formtype?: string;
  formtypeid?: string;
  isactive?: string;
  isfiled?: string;
  issaved?: string;
  isqar?: string;
  isfreezed?: string;
  isreviewed?: string;
  modifiedbyid?: string;
  modifiedby?: string;
  modifiedon?: string;
  createdbyid?: string;
  createdby?: string;
  createdon?: string;
  reviewedon?: string;
  createdonformatted?: string;
  modifiedonformatted?: string;
  reviewedby?: string;
  projectbslacode?: string;
  projectbslaname?: string;
  filedstatus?: string;
  pfic_stmt_id?: string;
  pfic_entity_id?: string;
  statement_id?: string;
  pficleid?: string;
  children?: StmtAttrData[];
}

export interface StmtAttrData {
  statementId: number;
  attributeId: number;
  attributeName: string;
  attributeValue?: string;
  attributeValueOrder: number;
  formId: number;
}
