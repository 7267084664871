import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpotRatesAllCurrSyncService {

  url ={
		syncCurrDataUrl : '/saveJsonObject?action_code=0sgtrd',
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

  syncCurrData(baseURL : string, jsonData : any, baseGlobalParams: any, checkLockedObj: any) {
		const url = `${baseURL}${this.url.syncCurrDataUrl}`;
		const options={
		  withCredentials: true
		};
	
		const data:any = {
		  "tax_year":jsonData.TAX_YEAR.toString(),
		  "scenario":jsonData.SCENARIO.toString(),
		  "jcd_key":jsonData.JCD_KEY.toString(),
		  "sso_id":jsonData.SSO_ID,
		  "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		  "scenarioCode":baseGlobalParams.scenarioCode,
		  "checkLockedbj": checkLockedObj,
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
	
		console.log('formData =', formData);
		console.log('data =', data);
	
	
		console.log('tax_year = ', formData.get("tax_year"));
		console.log('scenario = ', formData.get("scenario"));
		console.log('jcd_key = ', formData.get("jcd_key"));
		console.log('screen_key =', formData.get("screen_key"));
		console.log('sso_id =', formData.get("sso_id"));
    
		return this.http.post<any>(url, formData,options);
	  }
	
  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }
}
