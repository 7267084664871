import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {TemplateHeaderComponent} from '../../template-header/template-header.component';
import {DSTEntityDetails} from '../../template-leid-selector/template-leid-selector.component';
import {DSTSavePayload} from '../../../dst-dynamic-component-resolver.service';
import {FormBuilder} from '@angular/forms';
import {
  CreateDisclosureTemplateViewportComponent
} from '../../../create-disclosure-template-viewport/create-disclosure-template-viewport.component';
import {DSTSavedAttachment, TemplateAttachedService} from '../template-attached.service';
import {TrackerTabsService} from '../../../../../tracker-tabs.service';
import {CreateDisclosureSharedService} from '../../../create-disclosure-shared.service';
import {SaveActionResponse} from '../../../../../../../shared/_models/save-action-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTFormState} from '../../../../../../shared/models/dst-form-state.model';
import {DSTSharedService} from '../../../../../../shared/dst-shared.service';

@Component({
  selector: 'gtw-template-bulk-supersede',
  templateUrl: './template-bulk-supersede.component.html',
  styleUrls: ['./template-bulk-supersede.component.scss']
})
export class TemplateBulkSupersedeComponent implements OnInit {

  @ViewChild(TemplateHeaderComponent) headerComponent!: TemplateHeaderComponent;

  @Output('onSave') onSave: EventEmitter<DSTSavePayload> = new EventEmitter<DSTSavePayload>();
  @Output('onCancel') onCancel: EventEmitter<null> = new EventEmitter<null>();

  formType!: string;
  fileAcceptShort!: string[];
  dcnId!: number | null;
  saving!: boolean;
  isValidPdf!: boolean;
  file!: File | null;
  statements: DSTSavedAttachment[] = [];

  constructor(private formBuilder: FormBuilder,
              private viewportComponent: CreateDisclosureTemplateViewportComponent,
              private attachedService: TemplateAttachedService,
              private trackerTabsService: TrackerTabsService,
              private dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService) {
    this.formType = this.sharedService.getActiveFormState().formType;
    if (this.formType === 'PDF') {
      this.fileAcceptShort = ['pdf'];
    } else if (this.formType === 'XML Text Box') {
      this.fileAcceptShort = ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    } else if (this.formType === 'Data Collect') {
      this.fileAcceptShort = ['pdf', 'doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm'];
    }
  }

  ngOnInit(): void {
    const formState: DSTFormState = this.sharedService.getActiveFormState();
    this.saving = true;
    this.attachedService.getBulkStatements(formState.taxYear.toString(), formState.formId.toString())
      .subscribe((data: DSTSavedAttachment[]) => {
        this.statements = data;
      }, (eMsg: string) => {
        console.error(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      })
      .add(() => {
        this.saving = false;
      });
  }

  onPrimaryEntityChanged(selectedEntity: DSTEntityDetails | null, statement: DSTSavedAttachment): void {
    if (!selectedEntity || selectedEntity.LEID === statement.PRIMARYLEID) {
      return;
    }
    this.updateSupersedeStatements(statement, selectedEntity.LEID, statement.STATEMENTID, selectedEntity.ENTITYID.toString(), 'primary');
  }

  onBranchEntityChanged(selectedEntity: DSTEntityDetails | null, statement: DSTSavedAttachment): void {
    if (!selectedEntity || selectedEntity.LEID === statement.BRANCHENTITY) {
      return;
    }
    this.updateSupersedeStatements(statement, selectedEntity.LEID, statement.STATEMENTID, selectedEntity.ENTITYID.toString(), 'secondary');
  }

  onMaxLenExceeded(): void {
    this.dstSharedService.showAlert('error', 'You are only allowed to upload 1 attachment each time!');
  }

  onExtNotMet(): void {
    this.dstSharedService.showAlert('error', 'Please make sure you only upload the allowed file types!');
  }

  onFilesDropped(files: FileList, statement: DSTSavedAttachment): void {
    this.file = files[0];
    if (this.formType === 'PDF') {
      this.checkFillableAndUpload(statement);
    } else {
      this.upload(statement);
    }
  }

  private checkFillableAndUpload(statement: DSTSavedAttachment): void {
    this.saving = true;
    this.attachedService.validatePdfAttch(this.file!)
      .subscribe((isValid: boolean) => {
        this.isValidPdf = isValid;
        if (!this.isValidPdf) {
          this.saving = false;
          this.dstSharedService.showAlert('error', 'Please select a pdf without any fillable fields and try again!');
          return;
        }
        this.upload(statement);
      }, (e: Error) => {
        console.error(e.message);
        this.dstSharedService.showAlert('error', e.message);
        this.saving = false;
      });
  }

  private upload(statement: DSTSavedAttachment): void {
    const formState: DSTFormState = this.sharedService.getActiveFormState();
    this.saving = true;
    this.attachedService.updateAttachedFileForStatement(this.file!.name, statement.STATEMENTID, this.file!, formState.formTypeId.toString())
      .subscribe((success: boolean) => {
        if (success) {
          statement.FILENAME = statement.STATEMENTID.toString().concat('_').concat(this.file!.name);
          statement.hideFileName = false;
          this.dstSharedService.showAlert('success', 'Attachment file updated successfully!');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving the attachment file, please try again!');
        }
      }, (error: HttpErrorResponse) => {
        console.error(error.message);
        this.dstSharedService.showAlert('error', error.message);
      })
      .add(() => {
        this.saving = false;
      });
  }

  private updateSupersedeStatements(statement: DSTSavedAttachment, leid: string, statementId: string, entityId: string, updateType: string): void {
    this.saving = true;
    this.attachedService.updateSupersedeStatements(statementId, entityId, updateType)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess === '1') {
          if (updateType === 'primary') {
            statement.PRIMARYLEID = leid;
          } else {
            statement.BRANCHENTITY = leid;
          }
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving the entity information, please try again!');
        }
      }, (e: HttpErrorResponse) => {
        console.error(e.message);
        this.dstSharedService.showAlert('error', e.message);
      })
      .add(() => {
        this.saving = false;
      });
  }

  toggle(statement: DSTSavedAttachment): void {
    statement.isEdit = !statement.isEdit;
    if (!statement.isEdit) {
      statement.hideFileName = false;
    }
  }

  delete(statement: DSTSavedAttachment): void {
    statement.hideFileName = true;
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
