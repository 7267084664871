
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DomesticProjectTaggingService {

  url ={
		getProjectIdData : '/loadJsonObject?action_code=s1rn3u',
		saveSourcingTaggingData :'/saveJsonObject?action_code=tvt6nl',
		getTagModal : '/loadJsonObject?action_code=ho5q8f',
    deleteSourcingTaggingData:'/saveJsonObject?action_code=90ek34'
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }


	getProjectIdData(baseURL:string, tax_year: any,scenario:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getProjectIdData}`;

		let body = new FormData();
		body.append('tax_year', tax_year.toString());
		body.append('scenario', scenario.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getSourceTaggingModalData(baseURL:string, tax_year: any,scenario:any,jcd_key:any, taggingParam:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getTagModal}`;

		let body = new FormData();
		body.append('tax_year', tax_year.toString());
		body.append('scenario', scenario.toString());
		body.append('jcd_key', jcd_key.toString());
		body.append('filing_group', taggingParam.FILING_GROUP);
		body.append('bsla_code', taggingParam.BSLA_CODE);
		body.append('country_code', taggingParam.COUNTRY_CODE);
		body.append('dcs_post_flip', taggingParam.DCS_POST_FLIP);
		body.append('fse_pre_flip', taggingParam.FSE_PRE_FLIP);
    body.append('fse_source_code', taggingParam.FSE_SOURCE_CODE);
    body.append('fsi_source_code', taggingParam.FSI_SOURCE_CODE);
    body.append('income_category', taggingParam.INCOME_CATEGORY);
    body.append('income_category_type', taggingParam.INCOME_CATEGORY_TYPE);
    body.append('payor_cdr', taggingParam.PAYOR_CDR);
    body.append('payor_leid', taggingParam.PAYOR_LEID);
    body.append('pti_subtype', taggingParam.PTI_SUBTYPE);
    body.append('recipient_cdr', taggingParam.RECIPIENT_CDR);
    body.append('recipient_leid', taggingParam.RECIPIENT_LEID);
    body.append('reporting_period', taggingParam.REPORTING_PERIOD);
    body.append('source', taggingParam.SOURCE);
    body.append('sch_m_template_id', taggingParam.SCH_M_TEMPLATE_ID);
    body.append('ownership_pct', taggingParam.OWNERSHIP_PCT)

		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	saveProjectTaggingData(baseURL : string, jsonData : any, tax_year: any,scenario:any,jcd_key:any,sso_id:any,baseGlobalParams: any, taggingParam:any,checkLockedObj: any) {
		const url = `${baseURL}${this.url.saveSourcingTaggingData}`;
		const options={
		  withCredentials: true
		};
	
		const data:any = {
		"tax_year":tax_year,
		"scenario":scenario,
		"jcd_key":jcd_key,
		"sso_id":sso_id,
		"filing_key": taggingParam.FILING_GROUP,
		"is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
		"clob_data": JSON.stringify(jsonData),
		"scenarioCode":baseGlobalParams.scenarioCode,
		"checkLockedbj": checkLockedObj,
		};
	
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
	
		console.log('formData =', formData);
		console.log('data =', data);
	
	
		console.log('tax_year = ', formData.get("tax_year"));
		console.log('scenario = ', formData.get("scenario"));
		console.log('jcd_key = ', formData.get("jcd_key"));
		console.log('screen_key =', formData.get("screen_key"));
		console.log('sso_id =', formData.get("sso_id"));
		console.log('clob_data = ', formData.get("clob_data"));
	
	
	
		return this.http.post<any>(url, formData,options);
	  }

    deleteProjectTaggingData(baseURL : string, seq_key : any, tax_year: any,scenario:any,jcd_key:any,sso_id:any,baseGlobalParams: any, taggingParam:any,checkLockedObj: any) {
      const url = `${baseURL}${this.url.deleteSourcingTaggingData}`;
      const options={
        withCredentials: true
      };
    
      const data:any = {
      "tax_year":tax_year,
      "scenario":scenario,
      "jcd_key":jcd_key,
      "sso_id":sso_id,
      "filing_key": taggingParam.FILING_GROUP,
      "is_issue_key_enabled":baseGlobalParams.is_issue_key_enabled,
      "seq_key": seq_key,
      "scenarioCode":baseGlobalParams.scenarioCode,
      "checkLockedbj": checkLockedObj,
      };
    
      const formData = new FormData();
      Object.keys(data).forEach((key) => formData.append(key, data[key]));
    
      console.log('formData =', formData);
      console.log('data =', data);
    
    
      console.log('tax_year = ', formData.get("tax_year"));
      console.log('scenario = ', formData.get("scenario"));
      console.log('jcd_key = ', formData.get("jcd_key"));
      console.log('screen_key =', formData.get("screen_key"));
      console.log('sso_id =', formData.get("sso_id"));
      console.log('clob_data = ', formData.get("seq_key"));
    
    
    
      return this.http.post<any>(url, formData,options);
      }



  openSnackBar(message: string, panelClass:string='') {
    this._snackBar.open(message, '' , {
      duration: this.durationInSeconds * 1000,
      "panelClass": [panelClass]
    });
  }

}
