import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SupplementalInfoService {
   
  url ={
		getIssueId : '/loadJsonObject?action_code=8r4vs8',
		savesupplData : '/saveJsonObject?action_code=u4q4uq',
		getSupplModal : '/loadJsonObject?action_code=xk81c3',
		deletesupplData : '/saveJsonObject?action_code=20vls5'
	}
  durationInSeconds= 5;

  constructor(private httpClient: HttpClient, private http:HttpClient, private _snackBar: MatSnackBar) { }

  getIssueIdData(baseURL:string, params: any, ssoid:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getIssueId}`;

		let body = new FormData();
		body.append('sso_id', ssoid);
		body.append('issue_tax_year', params.tax_year.toString());
		body.append('issue_scenario', params.scenario.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getSupplModalData(baseURL:string, params: any, ccKey:any, ssoid:any){
		let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getSupplModal}`;

		let body = new FormData();
		body.append('sso_id', ssoid);
		body.append('tax_year', params.tax_year.toString());
		body.append('scenario', params.scenario.toString());
		body.append('jcd_key', params.jcd_key.toString());
		body.append('combination_key', ccKey);
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res.jsonObject;
				} 
				this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	saveSupplInfoData(baseURL : string, jsonData : any, baseGlobalParams: any, checkLockedObj: any) {
		let clientKey = sessionStorage.getItem('client_key') || '';
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('sso_id', jsonData.SSO_ID);
	    body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());
		body.append('combination_key', jsonData.COMBINATION_KEY);
        body.append('clob_data', JSON.stringify([jsonData]));
		body.append('scenarioCode', baseGlobalParams.scenarioCode);
		body.append('is_issue_key_enabled', baseGlobalParams.is_issue_key_enabled);
		body.append('issue_id', baseGlobalParams.issue_id);
		body.append('issue_short_desc', baseGlobalParams.issue_short_desc);
		body.append('checkLockedObj', checkLockedObj);

		const url = `${baseURL}${this.url.savesupplData}`;

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					// this.openSnackBar('Data saved successfully.','gtw-snackbar--green');
					// if(res.errorMessage != "null"){
					// 	this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					// }
                    return res;
				}else{
					//this.openSnackBar(res.errorMessage, 'gtw-snackbar--green');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	deleteSupplInfoData(baseURL : string, jsonData : any, baseGlobalParams: any, checkLockedObj: any) {
		let clientKey = sessionStorage.getItem('client_key') || '';
		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('withCredentials', 'true');

		let body = new FormData();
		body.append('sso_id', jsonData.SSO_ID);
	    body.append('tax_year', jsonData.TAX_YEAR.toString());
		body.append('scenario', jsonData.SCENARIO.toString());
		body.append('jcd_key', jsonData.JCD_KEY.toString());
		body.append('combination_key', jsonData.COMBINATION_KEY);
        body.append('clob_data', JSON.stringify([jsonData]));
		body.append('scenarioCode', baseGlobalParams.scenarioCode);
		body.append('is_issue_key_enabled', baseGlobalParams.is_issue_key_enabled);
		body.append('issue_id', baseGlobalParams.issue_id);
		body.append('issue_short_desc', baseGlobalParams.issue_short_desc);
		body.append('checkLockedObj', checkLockedObj);

		const url = `${baseURL}${this.url.deletesupplData}`;

		return this.httpClient.post(url, body, { headers }).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
					//this.openSnackBar('Data Deleted successfully.','gtw-snackbar--green');
					//if(res.errorMessage != "null"){
						//this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
					//}
					return res;
				}else{
					//this.openSnackBar(res.errorMessage, 'gtw-snackbar--red');
				}
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

  openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}

}




