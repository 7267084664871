import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from "rxjs";
import { BatchSourcingDefaultService } from './batch-sourcing-default.service';
import _ from 'lodash';

@Component({
	selector: 'gtw-batch-sourcing-default',
	templateUrl: './batch-sourcing-default.component.html',
	styleUrls: ['./batch-sourcing-default.component.scss']
})
export class BatchSourcingDefaultComponent implements OnInit {
	
	baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; scenarioCode: string;} | undefined;
  	baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'/gtw',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};
	baseURL = '/custom';
	baseURLGtw = '/gtw';
	taxYear :number = 0;
	scenario :number = 0;
	jcdKey :number = 0;
	screenKey :number = 0;
	filingKey :number = 0;
	acctKey :number = 0;
	ssoId: string = '';
	acctType:string = '';
	parentMeKey :number = 0;
	scenarioCode: string = '';
	checkLockedObj: any = [];
	entities : any = [];
	basket_id_value: any;
	selected_me_codes: any = [];
	saveClicked : boolean = false;

	@Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				//this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			//this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
  
	@Input('check-locked-obj')
	set _checkLockedObj(checkLockedObj:any){
	  	this.checkLockedObj = checkLockedObj;
	}
	
	@Input('grid-data')
	set _gridData(gridData:any){
		try{
			let params = JSON.parse(gridData);
			if(params != undefined){
				this.entities = _.uniqBy(params, 'data.ME_CODE');
			}
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
  
	@Input('tax-year')
	set _taxYear(taxYear:number){
		try{
			this.taxYear = taxYear;
		}catch(error){
			console.log("Not an valid object");
		}
	}
  
	@Input('scenario')
	set _scenario(scenario:number){
	  	this.scenario = scenario;
	  	this.init();
	}
  
	@Input('jcd-key')
	set _jcdKey(jcdKey:number){
	  	this.jcdKey = jcdKey;
	  	this.init();
	}
  
	@Input('filing-key')
	set _filingKey(filingKey:number){
		this.filingKey = filingKey;	
	  	this.init();	
	}
  
	@Input('screen-key')
	set _screenKey(screenKey:number) {
		this.screenKey = screenKey;
		this.init();
	}
  
	@Input("sso-id")
	set _ssoId(ssoId:string) {
		try{
			this.ssoId = ssoId;
			this.init();
		}catch(error){
			console.log("Not an valid object");
		}
	}

	@Output('open-batch-sourcing-info')
	openSourcingInfo = new EventEmitter<string>();
  
	@Output('save-batch-sourcing-info')
	saveSourcingInfo = new EventEmitter<string>();
  
	subscriptions:Subscription = new Subscription() ;

	constructor(private BatchSourcingDefaultService: BatchSourcingDefaultService, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
	}

	init() {   
		
	} 

	saveData(){ 	
		this.saveClicked = true;
		this.saveSourcingInfo.emit('save-batch-sourcing-info');
		this.entities.forEach( (value : any) => {
			this.selected_me_codes.push(value.data.ME_CODE);
		});

	   	let tempData = {
			TAX_YEAR : this.baseGlobalParams?.tax_year,
			SCENARIO : this.baseGlobalParams?.scenario,
			JCD_KEY : this.baseGlobalParams?.jcd_key,
			SSO_ID: this.ssoId,
			ME_KEY : this.parentMeKey,
			ACCT_KEY : this.acctKey,
			FILING_KEY : this.filingKey
	   	}
		
	   	let updateSubscription = this.BatchSourcingDefaultService.saveSourcingInfoData(this.baseURLs.api, tempData, this.baseGlobalParams, this.checkLockedObj, _.uniq(this.selected_me_codes)).subscribe(
			(data) => {
				//this.saveClicked = false;
			   	if (data.callSuccess !== "1")  {
					this.saveClicked = false;
					this.BatchSourcingDefaultService.openSnackBar('Error Saving Data. '+data.errorMessage, 'gtw-snackbar--red');
					//this.saveSourcingInfo.emit('save-batch-sourcing-info');
				}else{
					this.saveClicked = false;
				}
			},  error => {
				this.saveClicked = false;
			},
			 	() => { console.log("Update Completed");}
		   	);
			this.subscriptions.add(updateSubscription);
			
	};

	removeEntity (_index : number){
		this.entities.splice(_index, 1);
		if( this.entities.length === 0 ){
			this.modalClose();
		}
	}

	modalClose() {
		console.log("click close");
		this.openSourcingInfo.emit('open-batch-sourcing-info');
	}
}
