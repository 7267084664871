import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng-lts/table';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { DfMeDropdownComponent } from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import { JSONExportHeader, JSONExportService } from '../../../shared/_services/JSONExport.service';
import { DiscModalViewerComponent } from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import { StmtIdBtnService } from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import { DSTSharedService } from '../../shared/dst-shared.service';
import { DSTChildFormState } from '../../shared/models/dst-child-form-state.model';
import { DSTStatement } from '../../shared/models/dst-statement.model';
import { CreateDisclosureService, DSTTaxYear } from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import { DSTDynamicComponentResolverService } from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import { TrackerTabsService } from '../../statement-tracker/tracker-tabs.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'gtw-diagnostics-report',
  templateUrl: './diagnostics-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './diagnostics-report.component.scss']
})
export class DiagnosticsReportComponent implements OnInit , OnChanges {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;

  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTID',
      header: 'Statement Id',
      hidden: false,
      width: '145px'
    },
    {
      field: 'ATTACHMENTNAME',
      header: 'Attachment Name',
      hidden: false,
      width: '370px'
    },
    {
      field: 'ATTFORMTYPEDESC',
      header: 'Form Type',
      hidden: false,
      width: '145px'
    },
    {
      field: 'TAXYEAR',
      header: 'Tax Year',
      hidden: false,
      width: '100px'
    },
    {
      field: 'STATEMENTNAME',
      header: 'Statement Name',
      hidden: false,
      width: '300px'
    },
    {
      field: 'DCNNAME',
      header: 'DCN Name',
      hidden: false,
      width: '300px'
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Transferee Business/ME',
      hidden: false,
      width: '300px'
    },
    {
      field: 'SECONDARYBUSINESS',
      header: 'Transferor Business/ME',
      hidden: false,
      width: '300px'
    },
    {
      field: 'PRIMARYLEID',
      header: 'Transferee (Primary LEID)',
      hidden: false,
      width: '155px'
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Transferee Name',
      hidden: false,
      width: '300px'
    },
    {
      field: 'PRIMARYGEGECS',
      header: 'Primary GEGECS',
      hidden: false,
      width: '150px'
    },
    
    
    {
      field: 'SECONDARYGEGECS',
      header: 'Secondary GEGECS',
      hidden: false,
      width: '150px'
    },
    
    {
      field: 'CREATEDBY',
      header: 'User Activity',
      hidden: false,
      width: '400px'
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  // scenarioLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;
  // taxYearScenarioData: any;
  // selectedChannelKey: any;
  // scenarios: any[] = [];
  // scenario: any | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
    private dstSharedService: DSTSharedService,
    private stmtIdBtnService: StmtIdBtnService,
    private trackerTabsService: TrackerTabsService,
    private dCRService: DSTDynamicComponentResolverService,
    private jsonExportService: JSONExportService,
    private reportsService: ReportsService) {

    this.excelExportFileName = 'Reports-Diagnostic_Report_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine',
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setDSTUser('400380474', 2);
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400380474']);
    // this.dstSharedService.setTaxYearScenario('2021', '42');
  }

  ngOnInit(): void {
    // this.dstSharedService.setDSTUser('400380474', 5);
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2021');
    //   this.dfMeDropdownComponent.loadMEsCustom('2021');
    //   this.loadReport();
    // });

    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });

  }

  ngOnDestory(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        // this.taxYearScenarioData = param.taxYearScenarioData;
        // this.selectedChannelKey = param.selectedChannelKey;
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          // this.setScenarios(param.taxYear);
          this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
    // this.setScenarios(this.taxYear?.text.toString()!);
  }

  // onScenarioChange(): void {
  // }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => { return item.text.toString() === taxYear; });
    this.taxyearLoaded = true;
  }

  // setScenarios(taxYear: string): void {
  //   this.scenarios = [ { text:'Select Scenario', value: null } ];

  //   const selectedTaxYear = this.taxYearScenarioData.values.find((item: any) => { return item.value.toString() === taxYear; });
  //   selectedTaxYear?.scenarios.filter((ele : any) => ele.is_locked === 'N' && ele.channel_key.toString() === this.selectedChannelKey).forEach((item : any) => {
  //     this.scenarios.push({
  //       text: item.scenario_desc,
  //       value: item.scenario,
  //       selected: item.selected
  //     });
  //   });
  //   this.scenario = this.scenarios.find((item: any) => { return item.value === selectedTaxYear?.scenario; });
  //   this.scenarioLoaded = true;
  // }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    console.log('Selected M/Es:- ' + this.selectedMes);
    this.reportsService.loadDiagnosticsReport(this.taxYear?.text.toString()!, this.selectedMes || '').subscribe((data: any) => {
      this.data = data;
      this.data?.forEach((element) => { //params for opening statement
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;
      });
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the N/A Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
      field: 'STATEMENTID',
      header: 'Statement Id',
    },
    {
      field: 'ATTACHMENTNAME',
      header: 'Attachment Name',
    },
    {
      field: 'ATTFORMTYPEDESC',
      header: 'Form Type',
    },
    {
      field: 'TAXYEAR',
      header: 'Tax Year',
    },
    {
      field: 'STATEMENTNAME',
      header: 'Statement Name',
    },
    {
      field: 'DCNNAME',
      header: 'DCN Name',
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Transferee Business/ME',
    },
    {
      field: 'SECONDARYBUSINESS',
      header: 'Transferor Business/ME',
    },
    {
      field: 'PRIMARYLEID',
      header: 'Transferee (Primary LEID)',
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Transferee Name',
    },
    {
      field: 'PRIMARYGEGECS',
      header: 'Primary GEGECS',
    },
    {
      field: 'SECONDARYGEGECS',
      header: 'Secondary GEGECS',
    },
    {
      field: 'CREATEDBY',
      header: 'User Activity',
    }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }


}
