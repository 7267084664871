import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormFieldModule} from 'appkit-angular/form-field';
import {SelectorModule} from 'appkit-angular/selector';
import {TableModule} from 'appkit-angular/table';
import {CheckboxModule} from 'appkit-angular/checkbox';
import {SearchModule} from 'appkit-angular/search';
import {ButtonModule} from 'appkit-angular/button';
import {LoadingModule} from 'appkit-angular/loading';
import {ListModule} from 'appkit-angular/list';
import {PanelsCardsModule} from 'appkit-angular/panels-cards';
import {RadiobuttonModule} from 'appkit-angular/radiobutton';

import {TabModule} from 'appkit-angular/tab';
import {ResponsiveTabModule} from 'appkit-angular/responsive-tab';
import {ModalModule} from 'appkit-angular/modal';
import {TooltipModule} from 'appkit-angular/tooltip';
import {AlertModule} from 'appkit-angular/alert';
import {ButtonGroupModule} from 'appkit-angular/button-group';
import {DatePickerModule} from 'appkit-angular/date-picker';
import {AnimationModule} from 'appkit-angular/animation';
import {FileuploaderModule} from 'appkit-angular/fileupload';
import {ToggleModule} from 'appkit-angular/toggle';
import {NotificationModule} from 'appkit-angular/notification';
import { AccordionsModule } from 'appkit-angular/accordions';
import { BadgeModule } from 'appkit-angular/badge';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormFieldModule,
    SelectorModule,
    TableModule,
    CheckboxModule,
    TabModule,
    ResponsiveTabModule,
    ModalModule,
    ButtonModule,
    ButtonGroupModule,
    SearchModule,
    AlertModule,
    LoadingModule,
    ListModule,
    DatePickerModule,
    AnimationModule,
    SearchModule,
    ButtonModule,
    LoadingModule,
    TooltipModule,
    FileuploaderModule,
    ListModule,
    PanelsCardsModule,
    RadiobuttonModule,
    ToggleModule,
    NotificationModule,
    ButtonGroupModule,
    AccordionsModule,
    BadgeModule
    ],
  exports: [
    CommonModule,
    FormFieldModule,
    SelectorModule,
    TableModule,
    CheckboxModule,
    TabModule,
    ResponsiveTabModule,
    ModalModule,
    ButtonModule,
    ButtonGroupModule,
    SearchModule,
    AlertModule,
    LoadingModule,
    ListModule,
    DatePickerModule,
    AnimationModule,
    SearchModule,
    ButtonModule,
    LoadingModule,
    TooltipModule,
    FileuploaderModule,
    ListModule,
    PanelsCardsModule,
    RadiobuttonModule,
    ToggleModule,
    NotificationModule,
    ButtonGroupModule,
    AccordionsModule,
    BadgeModule
  ]
})
export class AppNgAppkitModule {
}
