
<div>
    <div class="doc-upload-list-wrapper">
        <div *ngFor="let attachment of attachmentList;index as i" class="doc-upload-list">
            <ul class="uploaded-file-details">
                <li>Name: {{attachment.hd_msg_title}}</li>
                <li>Created By: {{attachment.display_name}}</li>
            </ul>
            <ul class="document-actions">
                <li><span class="material-icons" style="    color: #767676; border-right: 1px solid #ccc; padding: 0 5px; cursor: pointer;"
                    (click)="openDialog(attachment)">visibility</span></li>
                <li><span class="material-icons" style="color: #29c7f7; border-right: 1px solid #ccc; padding: 0 5px; cursor: pointer;"
                    (click)="editDoc(attachment)">edit</span></li>
                <li><span class="material-icons" style="color: #767676; padding-left: 5px; cursor: pointer;"
                    (click)="deleteDoc(attachment.hd_key)">delete</span></li>
            </ul>
        </div>
    </div>
</div>
