<div class="release-notes-content">
    <div class="release-note-wrapper">
        <div class="gtw-web-components list-header">
            <label>Release Notes</label>
            <input type="text" class="form-control" [(ngModel)]="searchReleaseNotes" placeholder="Search Notes..." />
            <button class="a-btn a-btn-secondary a-btn-lg notes-btn" *ngIf="access>= 4" (click)="addReleaseNotes()" >Add New Release Note</button>
        </div>
        <div class="notes-list">
            <ng-container *ngIf="(releaseNotesList | datafilter : {RN_TITLE:searchReleaseNotes} ) as notesList">
                <li *ngFor="let list of notesList" (click)="selectNotes(list)">{{list.RN_TITLE? list.RN_TITLE: ''}}</li>
            </ng-container>
        </div>
    </div>
    <div class="release-note-update-wrapper">
        <div *ngIf="releaseNotesDataBlockEnable">
            <div class="panel panel-default" style="margin-bottom: 0;">
                <div class="panel-heading gtw-web-components" style="display: flex;">
                    {{selectedNotes.RN_TITLE? selectedNotes.RN_TITLE: 'Add Release Notes'}} 
                    &nbsp;<span *ngIf="notesListSelected"> (Updated on {{selectedNotes.UPDATED_ON}})</span>
                    <button class="a-btn a-btn-secondary a-btn-lg notes-btn" style="margin-left: auto;" *ngIf="access>= 4 && notesListSelected" (click)="editNotes()">Edit Notes</button>
                    <button class="a-btn a-btn-secondary a-btn-lg notes-btn" style="margin-left: 10px;" *ngIf="access>= 4 && notesListSelected" (click)="deleteReleaseNotes()">Delete Notes</button>
                </div>
                <div class="panel-body">
                    <div *ngIf="!editorEnable">
                        <iframe id="frame" class="notes-view-class" sandbox="allow-same-origin allow-scripts" seamless="true" srcdoc="{{selectedNotes.RN_BODY? selectedNotes.RN_BODY: ''}}"></iframe>
                    </div>
                    <div *ngIf="editorEnable">
                        <div>
                            <label>Release Notes Title:</label>
                            <input type="text" class="form-control" [(ngModel)]="editedNotesTitle"/>
                        </div>
                        <div>
                            <label>Release Notes:</label>
                            <ap-editor [isFull]="true" class="rel-notes-editor" [editorId]="'Editor1'" style="background: white;"
                                [(ngModel)]="notesBody" (change)="changeNotesBody($event)" aria-placeholder="Please add the document"></ap-editor>
                        </div>
                        <!-- <div>
                            <label>Client Specific:</label>
                            <input type="checkbox" class="form-control" />
                        </div>
                        <div>
                            <label>Clients:</label>
                            <ap-selector
                                formControlName="reasonEIN"
                                [items]="reasonsEIN"
                                [type]="'Dropdown'"
                            >
                            </ap-selector>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="gtw-web-components notes-footer" *ngIf="enableSaveBtn">
                <button class="gtw-ele a-btn a-btn-primary a-btn-lg" style="margin-left: auto;" (click)="saveReleaseNoteData()" [disabled]="!((editedNotesTitle.length > 0) && (notesBody.length > 0))">Save</button>
            </div>
        </div>
        <div *ngIf="!releaseNotesDataBlockEnable" class="no-notes">
            <h4>Select/Add Release Notes to View</h4>
        </div>
    </div>
</div>