<div class="container">
  <div class="row a-mt-10">
    <div class="text-right w-100">
      <label class="font-weight-bold">Last Snapshot Date:</label> {{lastSnapshotDate}}
    </div>
  </div>

  <div class="row">
    <p *ngIf="lastSnapshotDate">We have found some changes between the current XML and the last snapshot. Click below
      to
      download the report. If you have already reviewed the report and would like to proceed with these differences then
      you can accept the differences by checking the box below and then proceed.</p>
    <p *ngIf="!lastSnapshotDate">There are no snapshots available at the moment. If you would like to proceed without
      any
      snapshot for review then
      you can accept it by checking the box below and then proceed</p>
  </div>

  <div class="row flex-row justify-content-end">
    <ap-checkbox [text]="'Accept changes'" [checked]="isAccepted" class="a-mx-10" (onCheck)="isAccepted = !isAccepted">
    </ap-checkbox>

    <ap-button [btnText]="'Cancel'" class="a-mr-10" [config]="{ isSecondary: true }" (onBtnClick)="cancel()">
    </ap-button>

    <ap-button [btnText]="'Download Report'" class="a-mr-10" [config]="{ isSecondary: true }"
      (onBtnClick)="downloadReport()">
    </ap-button>

    <ap-button [btnText]="'Proceed'" [config]="{ isPrimary: true }" [isDisabled]="!isAccepted" (onBtnClick)="proceed()">
    </ap-button>
  </div>
</div>
