<ap-loading id="overlay" *ngIf="loader" [size]="'24px'" [colorName]="'a-primary'">
</ap-loading>
<div class="card" *ngIf="initializeComplete">
    <div class="card-body container">
        <form [formGroup]="naicsForm" novalidate>
            <div class="row">
                <div class="col-6">
                    <ap-form-field [inlineBlock]="true" [id]="'1'" [type]="'input'" [label]="'Code'"
                        [isDisabled]="isEdit === true" formControlName="code" [ngClass]="{addBorder: naicsForm.controls.code.status === 'INVALID'}">
                    </ap-form-field>
                    <div *ngIf="naicsForm.controls.code.status === 'INVALID'" class="a-form-error">Enter a 6 digit numeric code</div>
                </div>
                <div class="col-6">
                    <ap-form-field [inlineBlock]="true" [id]="'2'" [type]="'input'" [label]="'Code Description'"
                        formControlName="codeDesc" [ngClass]="{addBorder: naicsForm.controls.codeDesc.status === 'INVALID'}">
                    </ap-form-field>
                    <div *ngIf="naicsForm.controls.codeDesc.status === 'INVALID'" class="a-form-error">Enter description (max 100 characters)</div>
                </div>
            </div>
        </form>

    </div>
</div>
<div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

    <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray" [btnType]="'a-btn-gray'"
        [config]="secondaryConfig" (onBtnClick)="cancel()">
    </ap-button>
    <div>
        <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent" [config]="secondaryConfig"
            (onBtnClick)="insert()" [isDisabled]="naicsForm.status === 'INVALID'"></ap-button>
    </div>

</div>