<mat-progress-bar *ngIf="isLoading"
        color="primary"
        mode="indeterminate">
</mat-progress-bar>

<div class="max-height">
    <section class="a-p-20">
        <div class="a-p-20">
            <table class="table table-hover table-bordered table-striped table-condensed">
                <thead class="a-thead">
                    <tr>
                        <th>Source System</th>
                        <th>LEID</th>
                        <th>Tax Code</th>
                        <th>Reporting Period</th>
                        <th>ME Code</th>
                        <th>Currency</th>
                        <th>Ledger</th>
                        <th>Current Chart Code</th>
                        <th>Transactions to be Deleted</th> <!-- Delete Transaction -->
                        <th>Trans details Dropdown with Count</th>
                        <th>Update Chart Code</th> <!-- Update Chart Code -->
                       
                    </tr>
                </thead>
                <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="allEntityDetails.length > 0">
                    <ng-container *ngFor="let entity of allEntityDetails; index as i">
                        <tr *ngFor="let item of entity.groupItems; index as j">
                            <td>
                                <ng-container *ngIf="j == 0">
                                    {{item.source_SYSTEM_FLAG =='D'? 'Domestic' : 'Foreign'}}
                                </ng-container>
                            </td>
                            <td class="width-entity">
                                <ng-container *ngIf="j == 0">
                                    {{entity.leid}}
                                </ng-container>
                            </td>
                            <td>{{item.cdr_NO}}</td>
                            <td>{{item.reporting_PERIOD}}</td>
                            <td>{{entity.me_CODE}}</td>
                            <td>{{item.curr}}</td>
                            <td>{{item.ledger}}</td>
                            <td>{{allEntityDetails_backup[i]['chart_CODE']}}</td>
                            <td>
                                <!-- <mat-checkbox class="font-black-color" [(ngModel)]="item.selection" 
                                    *ngIf="j == 0" [disabled]="item.selection"
                                    (click)='checkboxClicked(entity,i,j )'></mat-checkbox> -->
                                <div>
                                    {{item.trans_COUNT}}
                                    <span *ngIf='item.selection && item.trans_COUNT > 0' class='delete-msg' >{{deleteLabel}}</span>
                                    <button class='btn btn-warning btn-sm' aria-label="clear selection" *ngIf='item.selection && j == 0' (click)='clearButtonClicked($event,entity,i,j); item.selection= false;'>
                                        Cancel
                                    </button>
                                </div>
                                <!-- <span  style="padding-left: 35px;" *ngIf="j != 0">
                                    {{item.trans_COUNT}}
                                </span> -->
                            </td>
                            <td>
                                <!-- [(ngModel)]=""  -->
                                <mat-select name="transCount" *ngIf="item.trans_COUNT != 0">
                                    <ng-container *ngFor="let count of optionsTransCount">
                                        <mat-option  *ngIf="item.combination_KEY == count.COMBINATION_KEY"  [value]="count.TRANS_TYPE_KEY"> 
                                            {{count.TRANS_DETAILS}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <ng-container *ngIf="item.trans_COUNT == 0">
                                    No transactions to Delete
                                </ng-container>
                            </td>
                         
                            <td [attr.rowspan]="item.col_SPAN" *ngIf="j == 0"  style="vertical-align: middle;">
                                <ng-container >
                                    <mat-form-field class="font-black-color" style="margin-top: 5px;">
                                        <mat-label class="font-black-color">Chart Code</mat-label>
                                        <input type="text" class="font-black-color"
                                            placeholder="Filter Chart Code"
                                            aria-label="Enter chart code"
                                            (focus)="backupEntity(entity,i,j)"                                        
                                            matInput
                                            [formControl]="allEntitiesChartCodeFormControls[i]"
                                            [matAutocomplete]="auto">
                                        <mat-autocomplete class="" #auto="matAutocomplete" (optionSelected)="selectedOption($event.option)" [displayWith]="displayFn">
                                            <mat-optgroup [label]="'Preferred'" >
                                                <ng-container *ngFor="let option of allFilteredOptions[i] | async">
                                                    <mat-option class="" *ngIf="option.chart_type == entity.entity_TYPE" [value]="option">
                                                        {{option.code}}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-optgroup>
                                            <mat-optgroup [label]="'Non Preferred'" ></mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <!-- <ng-container *ngIf="j != 0">
                                    {{allEntitiesChartCodeFormControls[i].value.code}}
                                </ng-container> -->
                            </td>
                      
                        </tr>
                    </ng-container>    
                </tbody>
                <tbody class="a-tbody tbody-regular tbody-hover" *ngIf="allEntityDetails.length == 0 && !isLoading">
                    <tr>
                        <td colspan="11" class="text-sm-center">No Record</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>


<div  class="row">
    <div class="col-md-9"></div>
    <div class="col-md-3">
        <ap-button [btnText]="'Save'" class="a-mr-10" 
            [config]="primaryConfig" 
            (onBtnClick)="openDialog(onSaveHeader, onSave, type.save)"></ap-button>
        <ap-button [btnText]="'Cancel'" class="a-mr-10" 
            [btnType]="'a-btn-gray'" 
            [config]="primaryConfig" 
            (onBtnClick)="modalClose()">
        </ap-button>
        <!-- <button mat-flat-button color="primary" aria-label="Save" (click)="openDialog(onSaveHeader, onSave, type.save)">Save</button>
        <button mat-flat-button color="warn" aria-label="Close" (click)='modalClose()' style='margin-left: 10px;'>Cancel</button> -->
    </div>
</div>





