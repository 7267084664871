import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';
import { DfMappingChangesService } from './df-mapping-changes.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import _ from 'lodash';
@Component({
  selector: 'gtw-df-mapping-changes',
  templateUrl: './df-mapping-changes.component.html',
  styleUrls: ['./df-mapping-changes.component.scss']
})
export class DfMappingChangesComponent implements OnInit {

  private scenarioCode: string = '';
  private isIssueKeyEnabled: string = '';
  private issueKey: number = 0;

  @Output('request-grid-update') requestGridUpdate = new EventEmitter<any>();
  rowData: any;
  ssoId: string = '';

  public secondaryConfig = {
    isSecondary: true
  }
  public primaryConfig = {
    isPrimary: true
  }
  
  @Input('row-data')
  set _row_data(rowData: string) {
    try {
      if(rowData != '{{ctrl.rowData}}') {
        this.initializeComplete = true;
      }
      let params = JSON.parse(rowData);

      if (params != undefined && params != '') {
        this.rowData = params;
        this.populateScreen();

      }
    } catch (e) {
      console.log("rowData Params not stable. Still waiting to get resolved.");
    }
  }


  @Input('base-urls')
  set _base_urls(baseUrls: string) {
    try {
      let params = JSON.parse(baseUrls);
      if (params != undefined) {
        this.baseURLs = params;
      }
    } catch (e) {
      console.log("base URLs Params not stable. Still waiting to get resolved.");
    }
  }

  @Input('global-params')
  set _global_params(globalParams: string) {
    try {
      let params = JSON.parse(globalParams);
      if (params != undefined) {
        this.baseGlobalParams = params;
      }
    } catch (e) {
      console.log("Global Params not stable. Still waiting to get resolved.");
    }
  }

  @Input("scenario-code")
  set _scenarioCode(scenarioCode: string) {
    this.scenarioCode = scenarioCode;
  }

  @Input("is-issue-key-enabled")
  set _isIssueKeyEnabled(isIssueKeyEnabled: string) {
    this.isIssueKeyEnabled = isIssueKeyEnabled;
  }

  @Input("issue-key")
  set _issueKey(issueKey: number) {
    this.issueKey = issueKey;
  }

  @Input("sso-id")
  set _ssoId(ssoId:string) {
    if(!_.startsWith(ssoId, "{{")) {
      this.ssoId = ssoId;
    }
  }

  baseURLs: GtwElementsBaseUrlsModel = { 'admin': '', 'api': '', "custom": '', 'efile': '', 'pdfEngine': '', 'bulkPdfEngine': '' };

  baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; } | undefined;
  form!: FormGroup;
  formData: any;

  constructor(private fb: FormBuilder,
    private gtwSnackbarService: GtwSnackbarService,
    private dfMappingChangesService: DfMappingChangesService
  ) {

    this.form = this.fb.group({
      TAX_YEAR: ['', Validators.required],
      IDENTIFIER: ['', Validators.required],
      ITEM_GROUP: ['', Validators.maxLength(22)],
      ITEM_GROUP_DESCRIPTION: ['', Validators.maxLength(250)],
      ITEM_SUB_CODE: ['', Validators.maxLength(22)],
      ITEM_CODE: ['', Validators.maxLength(22)],
      ITEM_DESCRIPTION: ['', Validators.maxLength(250)],
      LINE_GROUPING: ['', Validators.maxLength(22)],
      LINE_NUMBER: ['', Validators.maxLength(22)]
    });
   }



  initializeComplete:boolean = true;
  loader: boolean = false;

  ngOnInit(): void {
    
  }

  populateScreen() {
    this.form.patchValue({
      TAX_YEAR: this.rowData.TAX_YEAR,
      IDENTIFIER: this.rowData.IDENTIFIER,
      ITEM_GROUP: this.rowData.ITEM_GROUP,
      ITEM_GROUP_DESCRIPTION: this.rowData.ITEM_GROUP_DESCRIPTION,
      ITEM_SUB_CODE: this.rowData.ITEM_SUB_CODE,
      ITEM_CODE: this.rowData.ITEM_CODE,
      ITEM_DESCRIPTION: this.rowData.ITEM_DESCRIPTION,
      LINE_GROUPING: this.rowData.LINE_GROUPING,
      LINE_NUMBER: this.rowData.LINE_NUMBER
    });
  }

  save(): void {

    if (this.form.status === 'INVALID') {
      this.gtwSnackbarService.openSnackBar("Please check form details and try again", 'gtw-snackbar--red');
      return;
    }
    const formValues = this.form.value;
    formValues.UPDATED_ON = new Date();
    formValues.UPDATED_BY = this.ssoId;
    let params : saveParams = {
      tax_year: this.baseGlobalParams?.tax_year,
      scenario: this.baseGlobalParams?.scenario,
      jcd_key: this.baseGlobalParams?.jcd_key
    }
    let clob_settings : saveParams = { tax_year: params.tax_year, scenario: params.scenario, jcd_key: params.jcd_key };
    let clob_data = [formValues];
    this.dfMappingChangesService.saveLineChanges(this.baseURLs.api, params, clob_settings, clob_data, this.scenarioCode, this.isIssueKeyEnabled, this.issueKey).subscribe((data: any) => {
      if (data !== 'error') {
        this.requestGridUpdate.emit('refresh');
        this.loader = false;
      }
      else {
        console.log("Error while saving");
        this.loader = false;
      }
    }, () => {
      console.log("Error while saving");
      this.loader = false;
    });
  }

  cancel(): void {
    this.requestGridUpdate.emit('');
  }
}

export type saveParams = {
  tax_year: string | undefined;
  scenario: string | undefined;
  jcd_key: string | undefined;
}

