import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';
import { GhostConsoleService } from './ghost-console.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gtw-ghost-console',
  templateUrl: './ghost-console.component.html',
  styleUrls: ['./ghost-console.component.scss']
})
export class GhostConsoleComponent implements OnInit {
	@ViewChild('baseModal', { static: false }) baseModal: any;
	@Output('set-compare-job-id') setCompareJobIds = new EventEmitter<any>();
	
	baseGlobalParams: { jcd_key: string; tax_year: string; scenario: string; client_key: string; issue_id: string, is_issue_key_enabled: string; scenarioCode: string} | undefined;
	

	showMore(id: string) {
		this.baseModal.showMore(id);
	}

	closeModal(id: string) {
		this.baseModal.closeModal(id);
	}

	closeModalOk(id: string) {
		this.baseModal.closeModalOk(id);
	}

	modalDetermine() {
		console.log("click ok");
	}

	modalClose() {
		console.log("click close");
	}
	@Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls : GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
				this.init();
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

@Input('global-params')
	set _global_params(globalParams: string){
		try{
			let params = JSON.parse(globalParams);
			if(params != undefined){
				this.baseGlobalParams = params;
			}
			this.init();
		}catch(e){
			console.log("Global Params not stable. Still waiting to get resolved.");
		}
	}
	baseURLs: GtwElementsBaseUrlsModel ={'admin':'','api':'',"custom":'','efile':'','pdfEngine':'','bulkPdfEngine':''};

	public secondaryConfig = {
		isSecondary: true
	}

	public minDate = new Date();
	public maxDate = new Date(2022, 2, 1)
	public time = new Date(2022, 0, 1, 5, 30);
	public dateTime = new Date(2022, 0, 1, 5, 30);
	public datePickerDate = { }

	public datePickerDate2 = new Date(2021, 8, 1);
	autoRunSaveBtn = false;
	autoRunSaveLoader = false;
	changeDetailsLoader = false;
	manualRunSaveBtn = false;
	manualRunSaveLoader = false;
	ghostConfigKey = 0;
	scenarioStatus: string = '';
	progressBar: { JOB_ID: number; PER_COMP: number; } | undefined;
	ownershipSetupReady: string = '';
	tableData : any = [{}];
	jobData = [{}];
	autoRunDataLoad = false;
	//compareRecords = false;
	compareIdCount = 0;
	compareBtn = true;
	compareJobsError = "";
	failedJobErrorMsg = "";
	showFailedMsg = false;
	selectedRequestId : number = 0;
	showJobData = false;
	fixedSchedule : any = {
		name : "At Fix Schedule",
		value : false
	}

	manualSetupOptions : any = [
		// {
		// 	name: "ownership",
		// 	text: "Ownership",
		// 	value : true
		// },
		{
			name: "lookThrough",
			text: "Look Through",
			value : false,
			isDisabled : false
		},
		{
			name: "ftcEligibility",
			text: "FTC Eligibility",
			value : false,
			isDisabled : false
		},
		{
			name: "mainCalcs",
			text: "Main Calcs",
			value : true,
			isDisabled : false
		},
		{
			name: "gilti",
			text: "GILTI",
			value : false,
			isDisabled : false
		}
	];

	autoRunSetupOptions : any = [
		{
			name: "AUTO_RUN",
			text: "Auto Run",
			value : false,
			isDisabled : false
		},
		// {
		// 	name: "OWNERSHIP",
		// 	text: "Ownership",
		// 	value : false
		// },
		{
			name: "LOOK_THROUGH",
			text: "Look Through",
			value : false,
			isDisabled : false
		},
		{
			name: "ELIGIBILITY",
			text: "FTC Eligibility",
			value : false,
			isDisabled : false
		},
		{
			name: "MAIN_CALCS",
			text: "Main Calcs",
			value : true,
			isDisabled : false
		},
		{
			name: "GILTI_FLAG",
			text: "GILTI",
			value : false,
			isDisabled : false
		}
	];

	dateRangeSelection : any = {
		START_DATE : '',
		END_DATE : ''
	};

	dropdownList : any = [
		{
			data: []
		}
	];

	hoursList : any = [
		{
			data: []
		}
	];

	secondsList : any = [
		{
			data: []
		}
	];

	noOfRunsManual : any = [
		{
			data: []
		}
	];

	noOfRunsAuto : any = [
		{
			data: []
		}
	];

	// taxYearList : any = [
	// 	{
	// 		data: []
	// 	}
	// ];

	// scenarioList : any = [
	// 	{
	// 		data: []
	// 	}
	// ];

	filingList : any = [
		{
			data: []
		}
	];

	schedulesList : any = [];
	selectedFilingGroup = 1;
	selectedHour : string = '01';
	selectedMinute : string = '01';
	manualSelectedRun : string = '2';
	autoSelectedRun : string = '2';
	scheduleMsg : string = "";
	showScheduler = false;
	durationInSeconds= 5;

	constructor(private ghostConsoleService: GhostConsoleService, private datePipe: DatePipe, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
		
	}

	init() {
		this.tableData = [{}];
		this.jobData = [{}];
		this.hoursList = [{ data : [] }];
		this.secondsList = [{ data : [] }];
		this.noOfRunsManual = [{data : [{id: '1', value: '1', selected: false}, {id: '2', value: '2', selected: true}] }];
		this.noOfRunsAuto = [{data : [{id: '1', value: '1', selected: false}, {id: '2', value: '2', selected: true}] }];
		this.createScheduleData();
		this.getGhostData();
		this.getGhostJobTableData();
		this.getGhostFilingData();
	}

	scheduleJob(data : any){
		console.log(data);
		this.showScheduler = !this.showScheduler;
		this.schedulesList = [];
	}

	addSchedules(){
		let time = this.selectedHour + ':' + this.selectedMinute;
		//if(this.schedulesList.length < 4){
			this.schedulesList.push(time);
		//}else{
			//this.scheduleMsg = "Only 4 Schedules allowed";
		//}
	}

	removeSchedules(index : number){
		this.schedulesList.splice(index, 1);
		this.scheduleMsg = "";
	}

	setHour(hour : any){
		this.selectedHour = hour.selected[0].value;
	}

	setMinute(min : any){
		this.selectedMinute = min.selected[0].value;
	}

	setRuns(run : any, type : string){
		if(type === 'manual'){
			this.manualSelectedRun = run.selected[0].value;
		}else{
			this.autoSelectedRun = run.selected[0].value;
		}
	}

	setFilingGroup(group : any){
		this.selectedFilingGroup = group.selected[0].value;
		this.getGhostData();
		this.getGhostJobTableData();
		//this.getGhostConsoleProgressBarData();
	}

	createScheduleData(){
		for(let i = 0; i <= 23; i++){
			this.hoursList[0].data.push({id: i < 10 ? '0' + i : i, value: i < 10 ? '0' + i : i, selected: i == 1 ? true : false});
		}

		for(let j = 1; j <= 60; j++){
			this.secondsList[0].data.push({id: j < 10 ? '0' + j : j, value: j < 10 ? '0' + j : j, selected: j == 1 ? true : false});
		}
	}

	getGhostData(){
		console.log("Get Console Data Called.");
		this.ghostConsoleService.getGhostAutoRunData(this.baseURLs.api, this.baseGlobalParams, this.selectedFilingGroup).subscribe((data: any) => {
			if (data.length > 0) {
				let tempGhostData = data[0];
				this.ghostConfigKey = tempGhostData.CONFIG_KEY;
				this.selectedFilingGroup = tempGhostData.FILING_GROUP;
				this.scenarioStatus = tempGhostData.SCENARIO_STATUS;
				this.ownershipSetupReady = tempGhostData.OWNERSHIP_SETUP_READY;
				this.schedulesList = tempGhostData.REPEAT_INTERVAL ? tempGhostData.REPEAT_INTERVAL.split(',') : [];
				this.showScheduler = this.schedulesList.length > 0 ? true : false;
				this.fixedSchedule.value = this.schedulesList.length > 0 ? true : false;

				this.dateRangeSelection.START_DATE = new Date(tempGhostData.START_DATE);
				this.dateRangeSelection.END_DATE = new Date(tempGhostData.END_DATE);

				this.autoRunSetupOptions.forEach( (options : any) => {
					options.value = tempGhostData[options.name] == 'Y' ? true : false;

					if(options.name == "ELIGIBILITY" && this.baseGlobalParams?.client_key == '12'){
						options.value = false;
					}
					if(options.name == "MAIN_CALCS" && tempGhostData.GILTI_FLAG == 'Y'){
						options.value = true;
					}
				});
            }else{
				this.ghostConfigKey = 0;
				//this.selectedFilingGroup = 1;
				this.scenarioStatus = '';
				this.ownershipSetupReady = '';
				this.schedulesList = [];
				this.showScheduler = false;
				this.fixedSchedule.value = false;

				// this.dateRangeSelection.START_DATE = '';
				// this.dateRangeSelection.END_DATE = '';

				// this.dateRangeSelection = {
				// 	START_DATE : '',
				// 	END_DATE : ''
				// };

				//this.dateRangeSelection = {};


				this.autoRunSetupOptions.forEach( (options : any) => {
					options.value = false;
				});
			}
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
	}

	getGhostConsoleProgressBarData() {
		this.ghostConsoleService.getGhostConsoleProgressData(this.baseURLs.api, this.baseGlobalParams, this.selectedFilingGroup).subscribe((data: any) => {
			if (data) {
				this.progressBar = data[0];
            }
		},
		error => {
			console.log(error);
		},() => {
			console.log('Gost Console progress bar');  
		});
	}

	getGhostJobTableData(){
		console.log("Get Console Data Called.");
		this.dropdownList = [{ data: [] }];
		this.autoRunDataLoad = true;
		this.ghostConsoleService.getGhostJobTableData(this.baseURLs.api, this.baseGlobalParams, this.selectedFilingGroup).subscribe((data: any) => {
			if (data) {
				data.forEach( (value : any) => {
					if(value.JOB_ID){
						let tempChannel = {
							value: value.JOB_ID,
							text: value.JOB_ID,
						}
						value.selected = false;
						this.dropdownList[0].data.push(tempChannel);
					}
				});
				this.tableData = data;
			}
			this.getGhostConsoleProgressBarData();
			this.autoRunDataLoad = false;
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
	}

	getGhostFilingData(){
		console.log("Get Ghost Filing Data Called.");
		this.filingList = [{ data: [] }];
		//this.autoRunDataLoad = true;
		this.ghostConsoleService.getGhostFilingData(this.baseURLs.api, this.baseGlobalParams).subscribe((data: any) => {
			if (data) {
				let tempFilingGroup : any = [];
				data.forEach( (value : any) => {
					//if(value.JOB_ID){
						let tempChannel = {
							value: value.VALUE,
							text: value.LABEL,
							selected: false
						}
						//value.selected = false;
						tempFilingGroup.push(tempChannel);
						//this.filingList[0].data.push(tempChannel);
						//selected: value.VALUE == this.selectedFilingGroup ? true : false
					//}
				});
				tempFilingGroup[0].selected = true;
				this.filingList[0].data = tempFilingGroup;
				// this.filingList[0].data.forEach( (value : any) => {
				// 	if(value.value == this.selectedFilingGroup){
				// 		value.selected = true;
				// 	}
				// });
				//this.tableData = data;
				console.log(this.filingList);
			}
			this.getGhostConsoleProgressBarData();
			//this.autoRunDataLoad = false;
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
	}

	setJobId(data : any){
		console.log(data.change.value);
		this.selectedRequestId = data.change.value;
	}

	viewDetails(){
		this.showJobData = false;
		this.changeDetailsLoader = true;
		console.log(this.selectedRequestId);
		this.ghostConsoleService.getRequestIdData(this.baseURLs.api, this.baseGlobalParams, this.selectedRequestId).subscribe((data: any) => {
			if (data.length > 0) {
				console.log(data);
				this.showJobData = true;
				this.jobData = data;
				// data.forEach( (job : any) => {
					
				// });
			}else{
				this.jobData = [];
				this.showJobData = false;
			}
			this.changeDetailsLoader = false;
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
	}

	removeJob(id : number){
		let tempData = {
			TAX_YEAR : this.baseGlobalParams?.tax_year,
			SCENARIO : this.baseGlobalParams?.scenario,
			JCD_KEY : this.baseGlobalParams?.jcd_key
		}
		this.ghostConsoleService.deleteGhostJob(this.baseURLs.api, id, tempData).subscribe((data: any) => {
			if (data.callSuccess == 1) {
				console.log(data);
				this.getGhostJobTableData();
			}
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');  
		});
	}

	compareData(){
		//this.compareRecords = true;
		let selectedJobIds : any = [];
		this.tableData.forEach( (job : any) => {
			if(job.selected){
				selectedJobIds.push(job.REQUEST_KEY);
			}
		});

		this.setCompareJobIds.emit(selectedJobIds);
	}

	selectedJobId(event: any, collection: any, i : any, value: boolean){
		console.log(value);
		//collection.selected = !collection.selected;

		//if(this.compareIdCount < 2){
			if(collection.selected){
				this.compareIdCount--;
			}else{
				this.compareIdCount++;
			}
	
			if(this.compareIdCount == 2){
				this.compareBtn = false;
				this.compareJobsError = "";
			}else if(this.compareIdCount > 2){
				this.compareBtn = true;
				//collection.selected = !collection.selected;
				//this.compareIdCount--;
				this.compareJobsError = "Only 2 job's can be selected for comparison";
			}else{
				this.compareBtn = true;
				this.compareJobsError = "2 job's should be selected for comparison";
			}
			//this.compareJobsError = "";


		// }else{
		// 	collection.selected = !collection.selected;
		// 	this.compareJobsError = "Only 2 job's can be selected for comparison";
		// }
	}

	showError(data : any){
		console.log(data);
		this.showFailedMsg = true;
		if(data.ERROR_MSG){
			this.failedJobErrorMsg = data.ERROR_MSG;
		}else{
			this.failedJobErrorMsg = "No Error";
		}
		setTimeout(()=>{
			this.failedJobErrorMsg = "";
			this.showFailedMsg = false;
		}, 4000);
	}

	// switchAutoRunToggle(selectedAutoRunSetup: any) {
	// 	// console.log(status);
	// 	console.log(this.autoRunSetupOptions);
	// 	if(selectedAutoRunSetup.text == 'Main Calcs') {
	// 		this.autoRunSetupOptions.filter((eachSetup: any)=> {
	// 			if(eachSetup.text == 'Gilti') {
	// 				eachSetup.display == false ? eachSetup.display = true : eachSetup.display = false;
	// 			}
	// 		});
	// 	}
	// }

	// switchManualRunToggle(selectedManualRunSetup: any) {
	// 	if(selectedManualRunSetup.text == 'Main Calcs') {
	// 		this.manualSetupOptions.filter((eachSetup: any)=> {
	// 			if(eachSetup.text == 'Gilti') {
	// 				eachSetup.display == false ? eachSetup.display = true : eachSetup.display = false;
	// 			}
	// 		});
	// 	}
	// }

	switch(status: any) {
		console.log(status);
		console.log(this.autoRunSetupOptions);
		if(status.name == "gilti"){
			if(!status.value){
				this.manualSetupOptions[2].value = true;
				this.manualSetupOptions[2].isDisabled = true;
			}else{
				this.manualSetupOptions[2].isDisabled = false;
			}
		}else if(status.name == "GILTI_FLAG"){
			if(!status.value){
				this.autoRunSetupOptions[3].value = true;
				this.autoRunSetupOptions[3].isDisabled = true;
			}else{
				this.autoRunSetupOptions[3].isDisabled = false;
			}
		}
	}

	public primaryConfig = {
		isPrimary: true
	}

	btnClick() {
		console.log('button is clicked')
	}

	saveManualRunData(){
		this.manualRunSaveBtn = true;
		this.manualRunSaveLoader = true;
		let tempManualRunData = Array.from(this.manualSetupOptions);
		let tempOption : any = {};
		tempManualRunData.forEach( (options : any) => {
			tempOption[options.name] = options.value;
		});

		let tempData = {
			TAX_YEAR : this.baseGlobalParams?.tax_year,
			SCENARIO : this.baseGlobalParams?.scenario,
			JCD_KEY : this.baseGlobalParams?.jcd_key,
			FILING_GROUP : this.selectedFilingGroup,
			//OWNERSHIP : tempOption.ownership ? 'Y' : 'N',
			OWNERSHIP : 'N',
			ELIGIBILITY : tempOption.ftcEligibility ? 'Y' : 'N',
			LOOK_THROUGH : tempOption.lookThrough ? 'Y' : 'N',
			MAIN_CALCS : tempOption.mainCalcs ? 'Y' : 'N',
			GILTI_FLAG : tempOption.gilti ? 'Y' : 'N',
			ISSUE_ID : this.baseGlobalParams?.issue_id,
			IS_ISSUE_KEY: this.baseGlobalParams?.is_issue_key_enabled,
			SCENARIO_CODE: this.baseGlobalParams?.scenarioCode,
			NO_OF_RUNS: this.manualSelectedRun
		}
		this.ghostConsoleService.saveGhostManualData(this.baseURLs.api, tempData).subscribe((data) => {
            if (data) {
				this.manualRunSaveBtn = false;
				this.manualRunSaveLoader = false;
				this.getGhostJobTableData();
            }
        }, () => {
            console.log("Error while saving");
        });
	};

	saveAutoRunData() {
		let isStartDate = false;
		let isEndDate = false;
		this.autoRunSaveBtn = true;
		this.autoRunSaveLoader = true;
		let tempAutoRunData = Array.from(this.autoRunSetupOptions);
		let tempOption : any = {};
		// let startDate = this.dateRangeSelection.startDate? this.dateRangeSelection.startDate.startDate._d: '';
		// let endDate = this.dateRangeSelection.endDate? this.dateRangeSelection.endDate.endDate._d: '';
		let startDate;
		let endDate;

		if(this.dateRangeSelection.START_DATE){
			if(this.dateRangeSelection.START_DATE.startDate){
				startDate = this.dateRangeSelection.START_DATE.startDate._d;
				isStartDate = true;
			}else{
				startDate = '';
				this.openSnackBar('Please select start date.','gtw-snackbar--red');
				this.manualRunSaveBtn = false;
				this.manualRunSaveLoader = false;
			}
		}
		
		if(this.dateRangeSelection.END_DATE){
			if(this.dateRangeSelection.END_DATE.endDate){
				endDate = this.dateRangeSelection.END_DATE.endDate._d;
				isEndDate = true;
			}else{
				endDate = '';
				this.openSnackBar('Please select end date.','gtw-snackbar--red');
				this.manualRunSaveBtn = false;
				this.manualRunSaveLoader = false;
			}
		}

		if(isStartDate && isEndDate){
			tempAutoRunData.forEach( (options : any) => {
				tempOption[options.name] = options.value;
			});
	
			let tempData = {
				CONFIG_KEY: this.ghostConfigKey,
				TAX_YEAR: this.baseGlobalParams?.tax_year,
				SCENARIO: this.baseGlobalParams?.scenario,
				JCD_KEY: this.baseGlobalParams?.jcd_key,
				FILING_KEY: this.selectedFilingGroup,
				AUTO_RUN: tempOption.AUTO_RUN ? 'Y' : 'N',
				START_DATE: this.datePipe.transform(startDate,"MM/dd/yyyy"),
				END_DATE: this.datePipe.transform(endDate,"MM/dd/yyyy"),
				//OWNERSHIP: tempOption.OWNERSHIP ? 'Y' : 'N',
				OWNERSHIP: 'N',
				ELIGIBILITY: tempOption.ELIGIBILITY ? 'Y' : 'N',
				LOOK_THROUGH: tempOption.LOOK_THROUGH ? 'Y' : 'N',
				MAIN_CALCS: tempOption.MAIN_CALCS ? 'Y' : 'N',
				GILTI_FLAG: tempOption.GILTI_FLAG ? 'Y' : 'N',
				REPEAT_INTERVAL: this.schedulesList.join(),
				IS_ISSUE_KEY: this.baseGlobalParams?.is_issue_key_enabled,
				SCENARIO_CODE: this.baseGlobalParams?.scenarioCode,
				NO_OF_RUNS: this.autoSelectedRun
			}
			console.log(tempData);
			this.ghostConsoleService.saveGhostAutoRunData(this.baseURLs.api, tempData).subscribe((data) => {
				if (data.callSuccess == 1) {
					this.autoRunSaveBtn = false;
					this.autoRunSaveLoader = false;
					this.getGhostData();
				}else{
					this.autoRunSaveBtn = false;
					this.autoRunSaveLoader = false;
				}
			}, () => {
				console.log("Error while saving");
			});
		}
	}

	datePickerChange(event : any) {
		console.log(event);
	}

	public tablePageDropdownList = [{
		data:[
		{
			value: 5,
			text: '5 / Page'
		},
		{
			value: 8,
			text: '8 / Page'
		},
		{
			value: 12,
			text: '12 / Page'
		},
		]
	}];

	tablePageChange(event : any) {
		console.log(event)
	}

	tablePageNumberChange(event : any) {
		console.log(event)
	}

	openSnackBar(message: string, panelClass:string='') {
		this._snackBar.open(message, '' , {
			duration: this.durationInSeconds * 1000,
			"panelClass": [panelClass]
		});
	}
}
