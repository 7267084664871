<ap-loading id="overlay" *ngIf="loader" [size]="'24px'" [colorName]="'a-primary'">
</ap-loading>
<div class="mainContainer">
    <div style="width: 97.5% !important" class="card m-3" *ngIf="initializeComplete">
        <div class="card-body">
            <form [formGroup]="form" novalidate>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'TAX_YEAR'" [type]="'input'"
                                    [label]="'Tax Year'" formControlName="TAX_YEAR" [isDisabled]=true>
                                </ap-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'IDENTIFIER'" [type]="'input'"
                                    [label]="'Identifier'" formControlName="IDENTIFIER" [isDisabled]=true>
                                </ap-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'ITEM_CODE'" [type]="'input'"
                                    [label]="'Item Code'" formControlName="ITEM_CODE">
                                </ap-form-field>
                            </div>
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'ITEM_SUB_CODE'" [type]="'input'"
                                    [label]="'Item Sub Code'" formControlName="ITEM_SUB_CODE">
                                </ap-form-field>
                            </div>
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" class="large-textbox" [id]="'ITEM_DESCRIPTION'" [type]="'input'"
                                    [label]="'Item Description'" formControlName="ITEM_DESCRIPTION">
                                </ap-form-field>
                            </div>
                        
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'LINE_GROUPING'" [type]="'input'"
                                    [label]="'Line Grouping'" formControlName="LINE_GROUPING">
                                </ap-form-field>
                            </div>
                            <div class="form-group">
                                <ap-form-field [inlineBlock]="true" [id]="'LINE_NUMBER'" [type]="'input'"
                                    [label]="'Line Number'" formControlName="LINE_NUMBER">
                                </ap-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="a-modal-footer modal-footer pad-zero" style="float: right; width: 100%;">

        <ap-button [btnText]="'CANCEL'" class="a-mr-10 a-btn-transparent a-btn-gray"
            [btnType]="'a-btn-gray'" [config]="secondaryConfig" (onBtnClick)="cancel()">
        </ap-button>
        <div>
            <ap-button [btnText]="'SAVE'" class="a-mr-10 a-btn-transparent"
                [config]="secondaryConfig" (onBtnClick)="save()"></ap-button>
        </div>

    </div>
</div>